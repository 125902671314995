import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import MainService from "../../Common/MainService";
import SellerAccountManagement from "../../documentComponents/Invoicecomponents/selleraccountmanagement/SellerAccountManagement";

const QuoteRequestModel = ({ openModel, closeModel, rfqId, componentName }) => {
  console.log("rfqId", rfqId);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openModel}
        onClose={closeModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <SellerAccountManagement
            proxyId={rfqId}
            componentName={componentName}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" onClick={closeModel}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default QuoteRequestModel;
