import React, { useState, useEffect, memo, Fragment } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Input,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";

function DeleteContentSubCategory({
  subCategories,
  modelShow,
  closeModel,
  reloadComponent,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [getSubcategoryName, setSubcategoryName] = useState("");

  /* Delete Sub-Category */

  const deleteSubCategoryHandler = () => {
    if (!getSubcategoryName) {
      NotificationManager.warning("Select Sub-Category");
      return;
    }
    MainService.deleteContentSubCategory(
      username,
      getSubcategoryName.replace(" ", "")
    )
      .then((res) => {
        closeModel();
        reloadComponent();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  /* EOD Delete Sub-Category */

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={modelShow}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Sub-Category"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Creator">Category Creator:</InputLabel>
            <Input id="Creator" name="Creator" type="text" value={username} />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="subcategory">Select Sub-Category:</InputLabel>
            <Select
              id="subcategory"
              name="subcategory"
              type="text"
              value={getSubcategoryName}
              onChange={(e) => {
                setSubcategoryName(e.target.value);
              }}
            >
              {subCategories &&
                subCategories.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj.contentsubcategoryid}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="registerBtn"
            variant="contained"
            onClick={() => {
              deleteSubCategoryHandler();
            }}
          >
            Submit
          </Button>
          <Button variant="contained" onClick={closeModel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default memo(DeleteContentSubCategory);
