import React from 'react';
import { Row, Col } from "react-bootstrap";
import { Container, Button } from "@mui/material";
import TickMark from "../../images/tick.png";

const ExploreTechnologyCards = ({data}) => {
  return (
    <>
    <Container>
      {data.map((e) => {
        return (
          <Row
            className="subSectionWrapper align-items-center pb-0"
            id={e.id}
          >
            <Col md={6}>
              <img className="techology-img mb-3 cursor-initial" src={e.image} />
              <h2 className="container-heading1 mt-0">{e.title}</h2>
              <p className="container-para mb-md-0 mb-3"> {e.description}</p>
              <Button
                variant="contained"
                color="primary"
                className="registerBtn--mod technologyBtn mb-md-0 mb-4 mob-vis"
                type="button"
              >
                <a href={e.href} target="_blank"> {e.test ? "Explore now" : "Download App"} </a>
              </Button>
            </Col>
            <Col md={6}>
              <ul className="list-unstyled mb-0">
                {e.features.map((e) => {
                  return (
                    <li className="d-flex">
                      <img
                        className="feauresIcon me-2"
                        src={TickMark}
                        alt="tick"
                      />
                      <p className="container-para">{e}</p>
                    </li>
                  );
                })}
              </ul>

              <Button
                variant="contained"
                color="primary"
                className="registerBtn--mod technologyBtn mb-md-0 mb-4 mob-hide  d-inline-block"
                type="button"
              >
                <a href={e.href} target="_blank"> {e.test ? "Explore now" : "Download App"} </a>
              </Button>
            </Col>
          </Row>
        );
      })}
    </Container>
  </>
  )
}

export default ExploreTechnologyCards