import React, { useState, useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import Main from "../../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import MarketProducts from "../../AssetDeveloperRole/Market/MarketProducts";
import '../../AssetDeveloperRole/MyOrders/MyOrderStyleComponent.css';

export default function AgroCareComponent(props) {
  const history = useHistory();
  const { user } = Main.getLocalData();
  const { username, parentId } = user;
  const [userTypeValue, setUserTypeValue] = useState("");
  const [activeComponent, setActiveComponent] = useState("AHP");
  const userType = localStorage.getItem("userType");
  
  useEffect(() => {
    getUserType();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBackButtonClick = () => {
    // Define paths based on user types
    const paths = {
      "AssetDeveloper": "./dashboardForAD",
      "CBO": "./dashboardForCBO"
    };
    // Get the path based on user type
    const path = paths[userType] || "./defaultDashboard"; // Default path if user type not recognized

    // Redirect to the determined path
    history.push(path);
  };
  const getUserType = () => {
    Main.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  return (
    <>
      <div className="container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            style={{ marginRight: "10px" }}
            onClick={handleBackButtonClick}
            edge="start"
            color="inherit"
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 style={{ margin: 0 }}>Agro Care</h2>
        </div>
       
          <div className="container" >
            <div className="topHeadersWrapper">
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => handleButtonClick("AHP")}
                  disabled={activeComponent === "AHP"}
                  style={{ marginRight: "10px" }}
                  className={activeComponent === "AHP" ? "activeButton" : ""}
                >
                  AHP
                </Button>
              </div>
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => handleButtonClick("PHP")}
                  disabled={activeComponent === "PHP"}
                  style={{ marginRight: "10px" }}
                  className={activeComponent === "PHP" ? "activeButton" : ""}
                >
                  PHP
                </Button>
              </div>
              
            </div>
          </div>
        
      </div>

      <div style={{ paddingTop: "20px" }}>       
    
        {activeComponent === "AHP" && <MarketProducts
                  categoryType="AHP"
                  compType="Store"
                  subCatType="Sub Category"
                  modelID="Product Model ID"
                  modelFN="Product Model Friendly Name"
                  dCost="Product Cost"
                  pUnit="Unit"
                  pDiscount="Discount"
                  dPrice="Discounted Price"
                />}
        {activeComponent === "PHP" && <MarketProducts
                  categoryType="PHP"
                  compType="Store"
                  subCatType="Sub Category"
                  modelID="Product Model ID"
                  modelFN="Product Model Friendly Name"
                  dCost="Product Cost"
                  pUnit="Unit"
                  pDiscount="Discount"
                  dPrice="Discounted Price"
                />}
       
      </div>
    </>
  );
}
