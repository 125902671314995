import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px 4px",
    display: "flex",
    alignItems: "center",
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "12px",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function SearchBar(props) {
  const classes = useStyles();
  const [searchVal, setSearchVal] = React.useState("");

  React.useEffect(() => {
    if (props.val) {
      setSearchVal(props.val);
    }
  }, [props.val]);

  const clearSearch = () => {
    setSearchVal(""); // Step 3: Clear the search input
    if (typeof props.onSubmit === "function") {
      props.onSubmit("", props.username); // Pass an empty string as searchVal and username to the onSubmit handler
    }
  };

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={props.placeholder}
        inputProps={{ "aria-label": "search google maps" }}
        value={searchVal}
        onChange={(evt) => setSearchVal(evt.target.value)}
      />

      <IconButton
        className={classes.iconButton}
        aria-label="search"
        // onClick={(typeof props.onSubmit == 'function') ? props.onSubmit.bind(this, searchVal) : ''}
        onClick={() => {
          if (typeof props.onSubmit === "function") {
            props.onSubmit(searchVal, props.username); // Pass searchVal and username to the onSubmit handler
          }
        }}
      >
        <SearchIcon />
      </IconButton>

      {searchVal && ( // Step 2: Render the clear icon when search input is not empty
        <IconButton
          className={classes.iconButton}
          aria-label="clear"
          onClick={clearSearch}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Paper>
  );
}
