import React from "react";
import { getFormattedTimestamp } from "../../OrderCommon";

const initialState = {
  rfqId: "",
  proformaInvoiceId: "",
  personalDetails: {
    networkId: "",
    parentId: "",
    username: "",
    no: "",
    siteName: "",
    name: "",
    phoneNo: Number(),
    emailId: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: Number(),
    landmark: "",
    panNumber: "",
    latitude: Number(),
    longitude: Number(),
  },
  companyAdditionalInformation: {
    companyName: "",
    businessType: "",
    country: "",
    yearEstablished: "",
    numberOfEmployees: "",
    annualSalesRevenue: "",
    companyWebite: "",
    myCompanyHasNoWebite: Boolean(),
    panNumber: "",
    gstin_Number: "",
    companyDescription: "",
  },
  deliveryAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  supplierDetails: {
    supplierId: "",
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    emailId: "",
    phoneNo: "",
    latitude: Number(),
    longitude: Number(),
    panNumber: Number(),
    gstin_Number: "",
  },
  pickUpAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: Number(),
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  assetDetails: [
    {
      assetVerticalId: "",
      assetVerticalFriendlyName: "",
      category: "",
      legacyAssetType: "",
      modelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      origin: "",
      description: "",
    },
  ],
  productDetails: [
    {
      productCategory: "",
      productSubCategory: "",
      productModelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      grade: "",
      origin: "",
      quantity: Number(),
      unit: "",
      price: "",
      discount: "",
      totalDiscountablePrice: Number(),
    },
  ],
  requirements: {
    assetPackage: "",
    provenance: "",
    agreement: "",
    organicCertificate: "",
    qualityGuidelineLink: "",
    optionalDocumentLink: "",
    fromDate: "",
    toDate: "",
  },
  totalAmount: Number(),
  paymentMode: "",
  requestType: "",
  timeStamp: getFormattedTimestamp(),
};

const initialstatecmproductorderreq = {
  rfqId: "",
  proformaInvoiceId: "",
  personalDetails: {
    networkId: "",
    parentId: "",
    username: "",
    no: "",
    siteName: "",
    name: "",
    phoneNo: Number(),
    emailId: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: Number(),
    landmark: "",
    panNumber: "",
    latitude: Number(),
    longitude: Number(),
  },
  companyAdditionalInformation: {
    companyName: "",
    businessType: "",
    country: "",
    yearEstablished: "",
    numberOfEmployees: "",
    annualSalesRevenue: "",
    companyWebite: "",
    myCompanyHasNoWebite: Boolean(),
    panNumber: "",
    gstin_Number: "",
    companyDescription: "",
  },
  deliveryAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  supplierDetails: {
    supplierId: "",
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    emailId: "",
    phoneNo: "",
    latitude: Number(),
    longitude: Number(),
    panNumber: Number(),
    gstin_Number: "",
  },
  manufacturingPlantAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: Number(),
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  assetDetails: [
    {
      assetVerticalId: "",
      assetVerticalFriendlyName: "",
      category: "",
      legacyAssetType: "",
      modelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      origin: "",
      description: "",
    },
  ],
  productDetails: [
    {
      productCategory: "",
      productSubCategory: "",
      productModelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      grade: "",
      origin: "",
      quantity: Number(),
      unit: "",
      price: Number(),
      discount: "",
      totalDiscountablePrice: Number(),
    },
  ],
  requirements: {
    assetPackage: "",
    provenance: "",
    agreement: "",
    transport: "",
    qualityGuidelineLink: "",
    packingAndLabelingIntructionLink: "",
    manufacturingGuideLineLink: "",
    optionalDocumentLink: "",
    fromDate: "",
    toDate: "",
  },
  totalAmount: Number(),
  paymentMode: "",
  requestType: "",
  timeStamp: getFormattedTimestamp(),
};

const initialStatecfagrirawproductsorderreq = {
  rfqId: "",
  proformaInvoiceId: "",
  personalDetails: {
    networkId: "",
    parentId: "",
    username: "",
    no: "",
    siteName: "",
    name: "",
    phoneNo: Number(),
    emailId: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: Number(),
    landmark: "",
    panNumber: "",
    latitude: Number(),
    longitude: Number(),
  },
  companyAdditionalInformation: {
    companyName: "",
    businessType: "",
    country: "",
    yearEstablished: "",
    numberOfEmployees: "",
    annualSalesRevenue: "",
    companyWebite: "",
    myCompanyHasNoWebite: Boolean(),
    panNumber: "",
    gstin_Number: "",
    companyDescription: "",
  },
  deliveryAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  supplierDetails: {
    supplierId: "",
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    emailId: "",
    phoneNo: "",
    latitude: Number(),
    longitude: Number(),
    panNumber: Number(),
    gstin_Number: "",
  },
  growerFarmingSiteAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: Number(),
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  assetDetails: [
    {
      assetVerticalId: "",
      assetVerticalFriendlyName: "",
      category: "",
      legacyAssetType: "",
      modelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      origin: "",
      description: "",
    },
  ],
  productDetails: [
    {
      productCategory: "",
      productSubCategory: "",
      productModelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      grade: "",
      origin: "",
      quantity: Number(),
      unit: "",
      price: "",
      discount: "",
      totalDiscountablePrice: Number(),
    },
  ],
  requirements: {
    assetPackage: "",
    organicCertificate: "",
    provenance: "",
    agreement: "",
    transport: "",
    qualityGuidelineLink: "",
    farmingGuideLineLink: "",
    optionalDocumentLink: "",
    fromDate: "",
    toDate: "",
  },
  totalAmount: Number(),
  paymentMode: "",
  requestType: "",
  timeStamp: getFormattedTimestamp(),
};

const transportproductorderreq = {
  rfqId: "",
  proformaInvoiceId: "",
  personalDetails: {
    networkId: "",
    parentId: "",
    username: "",
    no: "",
    siteName: "",
    name: "",
    phoneNo: Number(),
    emailId: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: Number(),
    landmark: "",
    panNumber: "",
    latitude: Number(),
    longitude: Number(),
  },
  companyAdditionalInformation: {
    companyName: "",
    businessType: "",
    country: "",
    yearEstablished: "",
    numberOfEmployees: "",
    annualSalesRevenue: "",
    companyWebite: "",
    myCompanyHasNoWebite: Boolean(),
    panNumber: "",
    gstin_Number: "",
    companyDescription: "",
  },
  deliveryAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  supplierDetails: {
    supplierId: "",
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    emailId: "",
    phoneNo: "",
    latitude: 0,
    longitude: 0,
    panNumber: "",
    gstin_Number: "",
  },
  pickUpAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: Number(),
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  assetDetails: [
    {
      assetVerticalId: "",
      assetVerticalFriendlyName: "",
      category: "",
      legacyAssetType: "",
      modelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      origin: "",
      description: "",
    },
  ],
  productDetails: [
    {
      productCategory: "",
      productSubCategory: "",
      productModelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      grade: "",
      origin: "",
      quantity: Number(),
      unit: "",
      price: "",
      discount: "",
      totalDiscountablePrice: Number(),
    },
  ],
  totalAmount: Number(),
  transportDetails: {
    transportType: "",
    modeOfTransport: "",
    preferredTransportCompany: "",
  },
  requirements: {
    assetPackage: "",
    vehicleInsurance: "",
    agreement: "",
    transportGuideLineLink: "",
    optionalDocumentLink: "",
    fromDate: "",
    toDate: "",
  },
  paymentMode: "",
  requestType: "",
  timeStamp: getFormattedTimestamp(),
};

const storageproductorderreq = {
  rfqId: "",
  proformaInvoiceId: "",
  personalDetails: {
    networkId: "",
    parentId: "",
    username: "",
    no: "",
    siteName: "",
    name: "",
    phoneNo: Number(),
    emailId: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: Number(),
    landmark: "",
    panNumber: "",
    latitude: Number(),
    longitude: Number(),
  },
  companyAdditionalInformation: {
    companyName: "",
    businessType: "",
    country: "",
    yearEstablished: "",
    numberOfEmployees: "",
    annualSalesRevenue: "",
    companyWebite: "",
    myCompanyHasNoWebite: Boolean(),
    panNumber: "",
    gstin_Number: "",
    companyDescription: "",
  },
  deliveryAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: Number(),
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  supplierDetails: {
    supplierId: "",
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    emailId: "",
    phoneNo: "",
    latitude: Number(),
    longitude: Number(),
    panNumber: "",
    gstin_Number: "",
  },
  pickUpAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: Number(),
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  assetDetails: [
    {
      assetVerticalId: "",
      assetVerticalFriendlyName: "",
      category: "",
      legacyAssetType: "",
      modelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      origin: "",
      description: "",
    },
  ],
  productDetails: [
    {
      productCategory: "",
      productSubCategory: "",
      productModelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      grade: "",
      origin: "",
      quantity: Number(),
      unit: "",
      price: "",
      discount: "",
      totalDiscountablePrice: Number(),
    },
  ],
  totalAmount: Number(),
  storageDetails: {
    city: "",
    descriptionOfGoods: "",
    preferredStartDate: "",
    expectedDurationOfStorage: "",
  },
  requirements: {
    assetPackage: "",
    storageInsurance: "",
    agreement: "",
    storageGuideLineLink: "",
    optionalDocumentLink: "",
    fromDate: "",
    toDate: "",
  },
  paymentMode: "",
  requestType: "",
  timeStamp: getFormattedTimestamp(),
};

export {
  initialState,
  initialstatecmproductorderreq,
  initialStatecfagrirawproductsorderreq,
  transportproductorderreq,
  storageproductorderreq,
};
