import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./SerializationWithIdComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import { createTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import socketIOClient from "socket.io-client";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function SerializationWithIdComponent(props) {
  const classes = useStyles();
  const theme = createTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [isLoading, setIsLoading] = useState(false);
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [assetsList, setAssetsList] = useState([]);
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [sitesList, setSitesList] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [assetPopup, setShowAssetPopup] = useState(false);
  const [getUserTypeValue, setUserTypeValue] = useState("");
  const [assetIdValue, setAssetIdValue] = useState("");
  const [legacyAssetFriendlyNameValue, setLegacyAssetFriendlyNameValue] =
    useState("");
  const [selectedVerticalName, setSelectedVerticalName] = useState("");
  const [assetDomainValue, setAssetDomainValue] = useState("");
  const [siteNameValue, setSiteNameValue] = useState("");
  const [zoneValue, setZoneValue] = useState("");
  const [lotNameValue, setLotNameValue] = useState("");
  const [manufacturerIdValue, setManufacturerIdValue] = useState(username);
  const [manufacturerUrlValue, setManufacturerUrlValue] = useState("");
  const [modelFriendlyNameValue, setModelFriendlyNameValue] = useState("");
  const [modelUrlValue, setModelUrlValue] = useState("");
  const [serialNoValue, setSerialNoValue] = useState("");
  const [udnValue, setUdnValue] = useState("");
  const [upcValue, setUpcValue] = useState("");
  const [motherBreedValue, setMotherBreedValue] = useState("");
  const [fatherBreedValue, setFatherBreedValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [widthValue, setWidthValue] = useState("");
  const [heightValue, setHeightValue] = useState("");
  const [depthValue, setDepthValue] = useState("");
  const [whiteLabelCostValue, setWhiteLabelCostValue] = useState("");
  const [sellingCostValue, setSellingCostValue] = useState("");
  const [acquireCostValue, setAcquireCostValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [altitudeValue, setAltitudeValue] = useState("");
  const [mapPopUp, setMapPopUp] = useState(false);
  const [mapSiteId, setMapSiteId] = useState("");
  const [mapAssetId, setMapAssetId] = useState("");
  const [mapCategoryType, setMapCategoryType] = useState("");
  const [legacyUpdate, setLegacyUpdate] = useState(false);
  const [assetVeriticalId, setAssetVerticalId] = useState("");
  const [assetTypeVal, setAssetTypeVal] = useState("");
  const [assetIdVal, setAssetIdVal] = useState("");
  const [assetFriName, setAssetFriName] = useState("");
  const [modelIdVal, setModelIdVal] = useState("");
  const [categoryVal, setCatgeryVal] = useState("");
  const [udnVal, setUdnVal] = useState("");
  const [whiteLabelVal, setWhiteLabelVal] = useState("");
  const [sellingCostVal, setSellingCostVal] = useState("");
  const [exAssetStatus, setExAssetStatus] = useState("");
  const [updateAssetStatus, setUpdateAssetStatus] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [assetsListView, setAssetsListView] = useState(false);
  const [assetsGridView, setAssetsGridView] = useState(true);
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  /* Asset Live Monoitoring */
  const [liveMonitorMapPopUp, setLiveMonitorMapPopUp] = useState(false);
  const [ssetMonitoringStatus, setAssetMonitoringStatus] = useState("");
  const [marker_position, setmarker_position] = useState({
    lat: 13.093426,
    lng: 77.655773,
  });
  const [ssetMonitorTimestamp, setAssetMonitorTimestamp] = useState("");
  const [assetMonitoringView, setAssetMonitoringView] = useState(false);
  const [markers, setMarkers] = useState([]);
  /*****************      Asset Info                  ***************/
  const [getAssetInfoObj, setAssetInfoObj] = useState({});
  const [assetInfoPopup, setAssetInfoPopUp] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState("");

  /* Sub Category */
  const [selectedCategoryName, setSelectedCategoryName] = useState("image");
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  const [subcategorydialog, setSubCategoryDialog] = React.useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [subCategoryNameValue, setSubCategoryNameValue] = useState("");
  const [deleteSubcategoryDialog, setDeleteSubCategoryDialog] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  /* QR Code */
  const [qrCodeFile, setQrCodeFile] = useState("");
  const [qrCodeFileDownload, setQrCodeFileDownload] = useState("");
  const [fgcolor, setFgcolor] = useState("000");
  const [bgcolor, setBgcolor] = useState("f0ffff");
  const [qrSizeValue, setQrSizeValue] = useState("400");
  

  const getAssetVerticalsIdList = () => {
    console.log("call 2");
    MainService.getAssetVerticalsIdList(window.AppConfigData.EnterpriseId)
      .then((data) => {
        if (data.length > 0) {
          setSelectedVerticalsId(data[0].assetVerticalId);
          setSelectedVerticalName(data[0].friendlyName);

          setVerticalsIdList(data);
        } else {
          setSelectedVerticalsId("");
          setSelectedVerticalName("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  useMemo(() => {
    getAssetVerticalsIdList();
    console.log("call 3");
  }, []);

  const getCategoryTypesList = () => {
    console.log("call 4");
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(selectedVerticalsId.replace(" ", ""),window.AppConfigData.EnterpriseId)
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setSelectedCategory("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    console.log("call 5");
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  const getAssetTypesList = () => {
    console.log("call 6");
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        window.AppConfigData.EnterpriseId
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
          } else {
            setSelectedAssertTypes("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    console.log("call 7");
    getAssetTypesList();
  }, [selectedCategory]);

  const getModelsList = () => {
    console.log("call 8");
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", ""),
        username
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedModel(data[0].modelid);
            setModelsList(data);
          } else {
            setSelectedModel("");

            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    console.log("call 9");
    getModelsList();
  }, [selectedAssertTypes]);

  useEffect(() => {
    getAllAssetsBasedonModel();
    console.log("call 10");
  }, [
    selectedVerticalsId,
    selectedCategory,
    selectedAssertTypes,
    selectedModel,
  ]);

  const getAllAssetsBasedonModel = () => {
    console.log("call 1");

    if (
      selectedVerticalsId &&
      selectedCategory &&
      selectedAssertTypes &&
      selectedModel
    ) {
      MainService.getAllAssetsBasedonModel(
        username,
        selectedAssertTypes.replace(" ", ""),
        selectedModel.replace(" ", ""),
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", "")
      )
        .then((data) => {
          if (data.length > 0) {
            setAssetsList(data);
            getFilterUsers(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    } else {
      setIsLoading(false);
    }
  };

  const getAllSites = () => {
    MainService.getAllSites(username)
      .then((data) => {
        if (data.length > 0) {
          setSelectedSite(data[0].siteid);
          setSitesList(data);
        } else {
          setSelectedSite("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getSubCategories = () => {
    if (selectedCategoryId !== "") {
      MainService.getSubCategoriesForAD(username, selectedCategoryId)
        .then((data) => {
          if (data.length > 0) {
            setSelectedSubCategory(data[0].contentsubcategoryname);
            setsubCategoriesList(data);
            setIsLoading(false);
          } else {
            setSelectedSubCategory("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  useEffect(() => {
    getSubCategories();
  }, [selectedCategoryId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const setSelectedVerticalsIdHandler = (e) => {
    setSelectedVerticalsId(e.target.value);
    let catname = verticalsIdList.filter((data) => {
      return data.assetVerticalId === e.target.value;
    });
  };

  const setSelectedCategoryHandler = (event) => {
    setSelectedCategory(event.target.value);
  };

  const setSelectedAssertTypeHandler = (event) => {
    setSelectedAssertTypes(event.target.value);
  };

  const setSelectedModelHandler = (event) => {
    setSelectedModel(event.target.value);
  };

  const createAssetClick = () => {
    console.log("call 18");
    let createAssetData = {
      assetFriendlyName: selectedVerticalName,
      assetVerticalId: selectedVerticalsId,
      categoryType: selectedCategory,
      legacyAssetType: selectedAssertTypes,
      assetDomain: assetDomainValue,
      siteId: selectedSite,
      siteName: siteNameValue,
      lotId: lotNameValue,
      lotName: lotNameValue,
      manufacturerId: manufacturerIdValue,
      manufacturUrl: manufacturerUrlValue,
      modelId: selectedModel,
      modelName: selectedModel,
      modelType: selectedModel,
      modelUrl: modelUrlValue,
      serialNo: serialNoValue,
      udn: udnValue,
      upc: upcValue,
      motherBreed: motherBreedValue,
      fatherBreed: fatherBreedValue,
      description: descriptionValue,
      whitelableCost: whiteLabelCostValue,
      sellingcost: sellingCostValue,
      acquireCost: acquireCostValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      actorType: getUserTypeValue,
      consumerAdminId: parentId,
    };
    const legacyAssetData = JSON.stringify(createAssetData);
    MainService.createLegacyAsset(legacyAssetData, username, assetIdValue)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    getAllAssetsBasedonModel();
    setShowAssetPopup(!assetPopup);
  };

  const assetCreateClcik = () => {
    setShowAssetPopup(!assetPopup);
    getAllSites();
    getUserType();
    console.log("call 15");
  };

  const handleClose = () => {};
  /* Sub category */
  const createSubCategoryClick = () => {
    setSubCategoryDialog(!subcategorydialog);
  };
  const deleteSubCategoryClick = () => {
    setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
  };
  const submitSubCategoryDeleteClick = () => {
    MainService.deleteContentSubCategory(
      username,
      subCategoryNameValue.replace(" ", "")
    )
      .then((res) => {
        getSubCategories();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
  };

  const submitSubCategoryClick = (evt) => {
    if (!subCategoryNameValue) {
      NotificationManager.warning("Please enter Sub-Category Name");
      return;
    }
    let subCategoryData = {
      categoryid: selectedCategoryId,
      categoryname: selectedCategoryName,
      contentsubcategoryname: subCategoryNameValue,
      parentid: parentId,
      contentsubcategorycreator: username,
    };
    const subCategoryDataString = JSON.stringify(subCategoryData);
    MainService.createContentSubCategory(subCategoryDataString)
      .then((res) => {
        getSubCategories();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setSubCategoryDialog(!subcategorydialog);
  };
  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetsList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetsList(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  /*Create Asset */
  const createAssetModal = (
    <span
      onClick={() => {
        setShowAssetPopup(!assetPopup);
      }}
    >
      X
    </span>
  );
  const titleCreateAsset = "Create Asset";
  const agreementBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetDeveloperId">AssetDeveloper ID:</InputLabel>
        <Input
          disabled
          id="assetDeveloperId"
          name="assetDeveloperId"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetId">Legacy Asset ID :</InputLabel>
        <Input
          id="assetId"
          name="assetId"
          type="text"
          placeholder="5533222"
          value={assetIdValue}
          onChange={(e) => {
            setAssetIdValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="legacyAssetFriendlyName">
          Legacy Asset Friendly Name :
        </InputLabel>
        <Input
          id="legacyAssetFriendlyName"
          name="legacyAssetFriendlyName"
          placeholder="Betal Sheep"
          type="text"
          value={legacyAssetFriendlyNameValue}
          onChange={(e) => {
            setLegacyAssetFriendlyNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>AssetVerticalId:</InputLabel>
        <Select
          id="assetVerticalID"
          name="assetVerticalID"
          type="text"
          value={selectedVerticalsId}
          onChange={(event) => {
            setSelectedVerticalsIdHandler(event);
          }}
        >
          {verticalsIdList.length > 0 ? (
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))
          ) : (
            <h4></h4>
          )}
        </Select>
      </FormControl>

      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Asset Vertical Friendly Name :</InputLabel>
        <Input
          disabled
          id="avName"
          name="avName"
          type="text"
          value={selectedVerticalName}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Category Type*</InputLabel>
        <Select
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
          }}
        >
          {categoryTypesList &&
            categoryTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.categoryType}>
                {idObj.categoryType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Asset Domain</InputLabel>
        <Select
          id="assetDomain"
          name="assetDomain"
          type="text"
          value={assetDomainValue}
          onChange={(e) => {
            setAssetDomainValue(e.target.value);
          }}
        >
          <MenuItem selected>Select Any</MenuItem>
          <MenuItem key="Farm" value="Farm">
            Farm
          </MenuItem>
          <MenuItem key="Field" value="Field">
            Field
          </MenuItem>
          <MenuItem key="Enterprise" value="Enterprise">
            Enterprise
          </MenuItem>
          <MenuItem key="Home" value="Home">
            Home
          </MenuItem>
          <MenuItem key="Personal" value="Personal">
            Personal
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Name*</InputLabel>
        <Select
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedSite}
          onChange={(e) => {
            setSelectedSite(e.target.value);
          }}
        >
          {sitesList &&
            sitesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.siteid}>
                {idObj.sitename}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="zone"> Zone (Lot Id):</InputLabel>
        <Input
          id="zone"
          name="zone"
          type="text"
          value={zoneValue}
          onChange={(e) => {
            setZoneValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="lotName"> Lot Name:</InputLabel>
        <Input
          id="lotName"
          name="lotName"
          type="text"
          value={lotNameValue}
          onChange={(e) => {
            setLotNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="manufacturerId"> Manufacturer Id *:</InputLabel>
        <Input
          disabled
          id="manufacturerId"
          name="manufacturerId"
          type="text"
          value={manufacturerIdValue}
          onChange={(e) => {
            setManufacturerIdValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="manufacturerUrl"> Manufacturer Url:</InputLabel>
        <Input
          id="manufacturerUrl"
          name="manufacturerUrl"
          type="text"
          value={manufacturerUrlValue}
          onChange={(e) => {
            setManufacturerUrlValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Asset Type</InputLabel>
        <Select
          id="selectedAssertTypes"
          value={selectedAssertTypes}
          onChange={(event) => setSelectedAssertTypes(event.target.value)}
          label="Select Type"
        >
          {assertTypesList &&
            assertTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetType}>
                {idObj.assetType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Model</InputLabel>
        <Select
          id="selectedModel"
          value={selectedModel}
          onChange={(event) => setSelectedModel(event.target.value)}
          label="Select Model"
        >
          {modelList &&
            modelList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.modelid}>
                {idObj.modelfriendlyname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {/* <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelId"> Model Id*:</InputLabel>
        <Input
          id="modelId"
          name="modelId"
          type="text"
          value={modelIdValue}
          onChange={(e)=>{
            setModelIdValue(e.target.value)
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelFriendlyName">  Model Friendly Name:</InputLabel>
        <Input
          disabled
          id="modelFriendlyName"
          name="modelFriendlyName"
          type="text"
          value={modelFriendlyNameValue}
          onChange={(e)=>{
            setModelFriendlyNameValue(e.target.value)
          }}
        />
      </FormControl> */}
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelType"> Model Type:</InputLabel>
        <Input
          disabled
          id="modelType"
          name="modelType"
          type="text"
          value={selectedModel}
          /* onChange={(e) => {
            setModelTypeValue(e.target.value);
          }} */
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelUrl"> Model Url:</InputLabel>
        <Input
          id="modelUrl"
          name="modelUrl"
          type="text"
          value={modelUrlValue}
          onChange={(e) => {
            setModelUrlValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="serialNo"> Serial No:</InputLabel>
        <Input
          id="serialNo"
          name="serialNo"
          type="text"
          value={serialNoValue}
          onChange={(e) => {
            setSerialNoValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn"> UDN*:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={udnValue}
          onChange={(e) => {
            setUdnValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="upc"> UPC:</InputLabel>
        <Input
          id="upc"
          name="upc"
          type="text"
          value={upcValue}
          onChange={(e) => {
            setUpcValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="motherBreed"> Mother Breed:</InputLabel>
        <Input
          id="motherBreed"
          name="motherBreed"
          type="text"
          value={motherBreedValue}
          onChange={(e) => {
            setMotherBreedValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="fatherBreed"> Father Breed:</InputLabel>
        <Input
          id="fatherBreed"
          name="fatherBreed"
          type="text"
          value={fatherBreedValue}
          onChange={(e) => {
            setFatherBreedValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="description"> Description:</InputLabel>
        <Input
          id="description"
          name="description"
          type="text"
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="width"> Width:</InputLabel>
        <Input
          id="width"
          name="width"
          type="text"
          value={widthValue}
          onChange={(e) => {
            setWidthValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="height"> Height:</InputLabel>
        <Input
          id="height"
          name="height"
          type="text"
          value={heightValue}
          onChange={(e) => {
            setHeightValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="depth"> Depth:</InputLabel>
        <Input
          id="depth"
          name="depth"
          type="text"
          value={depthValue}
          onChange={(e) => {
            setDepthValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="whiteLabelCost"> WhiteLabel Cost:</InputLabel>
        <Input
          id="whiteLabelCost"
          name="whiteLabelCost"
          type="text"
          value={whiteLabelCostValue}
          onChange={(e) => {
            setWhiteLabelCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sellingCost"> Selling Cost:</InputLabel>
        <Input
          id="sellingCost"
          name="sellingCost"
          type="text"
          value={sellingCostValue}
          onChange={(e) => {
            setSellingCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost"> Acquire Cost*:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={acquireCostValue}
          onChange={(e) => {
            setAcquireCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="latitude"> Latitude:</InputLabel>
        <Input
          id="latitude"
          name="latitude"
          type="text"
          value={latitudeValue}
          onChange={(e) => {
            setLatitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="longitude">Longitude:</InputLabel>
        <Input
          id="longitude"
          name="longitude"
          type="text"
          value={longitudeValue}
          onChange={(e) => {
            setLongitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="altitude"> Altitude:</InputLabel>
        <Input
          id="altitude"
          name="altitude"
          type="text"
          value={altitudeValue}
          onChange={(e) => {
            setAltitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={(e) => createAssetClick()}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowAssetPopup(!assetPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* Asset Live Monitoring */

  const assetMonitoringHandler = (siteId,assetId) => {
    setSelectedAssetId(assetId);
    setAssetsListView(false);
    setAssetsGridView(false);
    setAssetMonitoringView(true);

    MainService.assetCurrentLocation(parentId,username, assetId)
    .then((res) => {
      console.log("current Location monitor:", res);
      setSelectedAssetId(res.assetId);
      setAssetMonitoringStatus(res.status);
      setMarkers([
        ...markers,
        { geoLocation: res.geoLocation },
      ]);
      setAssetMonitorTimestamp(res.ts);

      if (marker_position !== undefined) {
        const ENDPOINT = window.socketUrl;
        const socket = socketIOClient(ENDPOINT);
        socket.on(`tt/solution/${username}/site/${siteId}/asset/${assetId}/LOC`, (data) => {
          console.log(JSON.parse(data.payload).geoLocation);
          setMarkers([
            ...markers,
            { geoLocation: JSON.parse(data.payload).geoLocation },
          ]);
        });
        return () => {
          socket.disconnect();
        };
      }
    })
    .catch((e) => {
      return NotificationManager.error("location not found");
    });
  };

  const AssetLiveGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={marker_position}
        center={marker_position}
      >
        <Marker
          position={{
            lat: parseFloat(marker_position.lat),
            lng: parseFloat(marker_position.lng),
          }}
        >
          <InfoWindow options={{ disableAutoPan: true }}>
            <div>{selectedAssetId}</div>
          </InfoWindow>
        </Marker>
      </GoogleMap>
    );
  });

  /* End of Asset Monitoring */
  /* Mapping Functionality */

  const mapPopUpHandler = (assetId, categoryType) => {
    setMapPopUp(!mapPopUp);
    setMapAssetId(assetId);
    setMapCategoryType(categoryType);
  };
  const closeMapModal = (
    <span
      onClick={() => {
        setMapPopUp(!mapPopUp);
      }}
    >
      X
    </span>
  );
  const createMapping = () => {
    const siteIdData = {
      siteid: mapSiteId,
    };
    const dataSiteId = JSON.stringify(siteIdData);

    MainService.createMapping(username, mapAssetId, mapCategoryType, dataSiteId)
      .then((res) => {
        setMapPopUp(!mapPopUp);
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const titleMapAsset = "Map Model";
  const mapModelBody = (
    <div>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Id</InputLabel>
        <Select
          id="selectedModel"
          value={mapSiteId}
          onChange={(event) => setMapSiteId(event.target.value)}
          label="Select Model"
        >
          {sitesList &&
            sitesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.siteid}>
                {idObj.sitename}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetId"> Asset Id:</InputLabel>
        <Input
          disabled
          id="assetId"
          name="assetId"
          type="text"
          value={mapAssetId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryType"> Category Type:</InputLabel>
        <Input
          disabled
          id="categoryType"
          name="categoryType"
          type="text"
          value={mapCategoryType}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={createMapping}
        >
          Create Mapping
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setMapPopUp(!mapPopUp);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* End Mapping Functionality */

  /* UnMap Asset Handler Functionality */
  const unMapAssetHandler = (assetId, categoryType, siteIds) => {
    const siteId = {
      siteid: siteIds,
    };
    const siteIdData = JSON.stringify(siteId);

    MainService.createUnMapping(assetId, categoryType, siteIdData, username)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /* End UnMap Asset Handler Functionality */

  /* Update Legacy Asset */
  const closeUpdateModel = (
    <span
      onClick={() => {
        setLegacyUpdate(!legacyUpdate);
      }}
    >
      X
    </span>
  );

  const updateAssetHandler = () => {
    const formData = {
      status: updateAssetStatus,
    };
    const statusData = JSON.stringify(formData);

    MainService.assetStatusUpdate(assetIdVal, statusData)
      .then((res) => {
        setLegacyUpdate(!legacyUpdate);
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const updateLegacyAssetHandler = (
    assetverticalId,
    assetType,
    assetId,
    friendlyName,
    modelId,
    categoryType,
    udn,
    whitelabelCost,
    sellingCost,
    assetStatus
  ) => {
    setLegacyUpdate(!legacyUpdate);
    setAssetVerticalId(assetverticalId);
    setAssetTypeVal(assetType);
    setAssetIdVal(assetId);
    setAssetFriName(friendlyName);
    setModelIdVal(modelId);
    setCatgeryVal(categoryType);
    setUdnVal(udn);
    setWhiteLabelVal(whitelabelCost);
    setSellingCostVal(sellingCost);
    setExAssetStatus(assetStatus);
  };

  /* Asset Info */
  const getAssetInfo = (assetid)=>{
    MainService.getAssetInfo(username, assetid)
    .then((res) => {
      setAssetInfoObj(res);
    })
    .catch((err) => {
      return NotificationManager.error("error");
    });
  }

  const assetInfoDialogClick = (assetid) => {
    getAssetInfo(assetid)
    setAssetInfoPopUp(!assetInfoPopup);
  };
  const closeSitAssetInfoModal = (
    <span
      onClick={() => {
        setAssetInfoPopUp(!assetInfoPopup);
      }}
    >
      X
    </span>
  );
  const assetInfoHeader = "Asset Details";
  const assetInfoBody = (
    <div style={{ paddingBottom: 50 }}>
      <p>
        <b>Asset ID:</b>
        {getAssetInfoObj.assetId}
      </p>
      <p>
        <b>AssetverticalId:</b>
        {getAssetInfoObj.assetverticalId}
      </p>
      <p>
        <b>Category:</b>
        {getAssetInfoObj.category}
      </p>
      <p>
        <b>Asset CategoryType:</b>
        {getAssetInfoObj.assetCategoryType}
      </p>
      <p>
        <b>AssetDomain:</b>
        {getAssetInfoObj.assetDomain}
      </p>
      <p>
        <b>Asset Type:</b>
        {getAssetInfoObj.assetType}
      </p>
      <p>
        <b>Friendly Name:</b>
        {getAssetInfoObj.friendlyName}
      </p>
      <p>
        <b>Manufacturer Id:</b>
        {getAssetInfoObj.manufacturerId}
      </p>
      <p>
        <b>Manufacturer:</b>
        {getAssetInfoObj.manufacturer}
      </p>
      <p>
        <b>Manufacturer URL:</b>
        {getAssetInfoObj.manufacturerURL}
      </p>
      <p>
        <b>ModelId:</b>
        {getAssetInfoObj.modelId}
      </p>
      <p>
        <b>Model Name:</b>
        {getAssetInfoObj.modelName}
      </p>
      <p>
        <b>Model Type:</b>
        {getAssetInfoObj.modelType}
      </p>
      <p>
        <b>Model URL:</b>
        {getAssetInfoObj.modelURL}
      </p>
      <p>
        <b>Serial Number:</b>
        {getAssetInfoObj.serialNumber}
      </p>
      <p>
        <b>UDN:</b>
        {getAssetInfoObj.udn}
      </p>
      <p>
        <b>UPC:</b>
        {getAssetInfoObj.upc}
      </p>
      <p>
        <b>Acquire Cost:</b>
        {getAssetInfoObj.acquirecost}
      </p>
      <p>
        <b>Whitelabel Cost:</b>
        {getAssetInfoObj.whitelabelcost}
      </p>
      <p>
        <b>Selling Cost:</b>
        {getAssetInfoObj.sellingcost}
      </p>
      <p>
        <b>Image Data:</b>
        {getAssetInfoObj.imagedata}
      </p>
      <p>
        <b>Asset Status:</b>
        {getAssetInfoObj.assetstatus}
      </p>
      <br />
      <Button
        variant="outlined"
        style={{ left: "45%" }}
        onClick={() => {
          setAssetInfoPopUp(!assetInfoPopup);
        }}
      >
        cancel
      </Button>
    </div>
  );

  /* End of Asset Info */
  /* Update Legacy Asset */
  const updateLegacyAsset = "Asset Information";
  const updateLegacyAssetData = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="solutionid">SOLUTION ID:</InputLabel>
        <Input
          disabled
          id="solutionid"
          name="solutionid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetverticalid">ASSET VERTICAL ID:</InputLabel>
        <Input
          disabled
          id="assetverticalid"
          name="assetverticalid"
          type="text"
          value={assetVeriticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assettype">ASSET TYPE:</InputLabel>
        <Input
          disabled
          id="assettype"
          name="assettype"
          type="text"
          value={assetTypeVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetid">ASSET ID:</InputLabel>
        <Input
          disabled
          id="assetid"
          name="assetid"
          type="text"
          value={assetIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetfname">ASSET FRIENDLY NAME:</InputLabel>
        <Input
          disabled
          id="assetfname"
          name="assetfname"
          type="text"
          value={assetFriName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelid">MODEL ID:</InputLabel>
        <Input
          disabled
          id="modelid"
          name="modelid"
          type="text"
          value={modelIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="category">CATEGORY:</InputLabel>
        <Input
          disabled
          id="category"
          name="category"
          type="text"
          value={categoryVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="manufacturerid">MANUFACTURER ID:</InputLabel>
        <Input
          disabled
          id="manufacturerid"
          name="manufacturerid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">UDN:</InputLabel>
        <Input disabled id="udn" name="udn" type="text" value={udnVal} />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
        <Input
          disabled
          id="whitecost"
          name="whitecost"
          type="text"
          value={whiteLabelVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
        <Input
          disabled
          id="sellingcost"
          name="sellingcost"
          type="text"
          value={sellingCostVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetstatus">EXISTING ASSET STATUS:</InputLabel>
        <Input
          disabled
          id="assetstatus"
          name="assetstatus"
          type="text"
          value={exAssetStatus}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>UPDATED ASSET STATUS</InputLabel>
        <Select
          id="selectedModel"
          value={updateAssetStatus}
          onChange={(event) => setUpdateAssetStatus(event.target.value)}
          label="Select Model"
        >
          <MenuItem selected>Select Any</MenuItem>
          <MenuItem key="PrematureSale" value="PrematureSale">
            Premature Sale
          </MenuItem>
          <MenuItem key="Lost" value="Lost">
            Lost
          </MenuItem>
          <MenuItem key="Damaged" value="Damaged">
            Damaged
          </MenuItem>
          <MenuItem key="UnderMaintenance" value="UnderMaintenance">
            Under-Maintenance
          </MenuItem>
        </Select>
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateAssetHandler}
        >
          Update Asset
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setLegacyUpdate(!legacyUpdate);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End Update Legacy Asset */

  /* Asset Delete Handler */

  const addedAssetDeleteHandler = (
    assetIds,
    assetTypes,
    modelIds,
    assetverticalIds,
    categoryTypes
  ) => {
    if (window.confirm("Are you sure to delete?")) {
      MainService.assetDelete(
        assetIds,
        assetTypes,
        modelIds,
        assetverticalIds,
        categoryTypes,
        username
      )
        .then((res) => {
          // return NotificationManager.success(res);
          return NotificationManager.success("successfully deleted");
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };
  /*End Asset Delete Handler */

  /* backToHome */
  const backToHome = () => {
    setAssetsListView(false);
    setAssetsGridView(true);
    setAssetMonitoringView(false);
  };
  /* Generate QR Code */
  const submitGenerateQr = (asset) => {
    getAssetInfo(asset.assetId);
    console.log("getAssetInfoObj.manufacturerURL:",getAssetInfoObj.manufacturerURL);
    
    if (getAssetInfoObj.manufacturerURL === "" || getAssetInfoObj.manufacturerURL === null) {
      let qrBody = {
        iwidth: qrSizeValue,
        fcolor: fgcolor,
        bcolor: bgcolor,
        cadminid: parentId,
        assetverticalid: asset.assetverticalId,
        avname: selectedVerticalName,
        acategory: asset.category,
        atype: asset.assetType,
        modelid: asset.modelId,
        bname: username,
        id: asset.assetId,
        url:""
      };
      console.log("qr body:", qrBody);
      const qrBodyStringify = JSON.stringify(qrBody);
      MainService.generateQrCodeWithId(qrBodyStringify)
        .then((data) => {
          var imageURL = data;
          var imageName = imageURL.match(/\w*(?=.\w+$)/);
          setSelectedAssetId(imageName[0]);
          console.log("image name:", imageName[0]);
          setQrCodeFile(data);
          getQrCodeForAssetId(asset.assetId);
          console.log("qr code file:", data);
        })
        .catch((err) => {
          console.error(err);
          return NotificationManager.error(err);
        });
    } else {
      setQrCodeFile(getAssetInfoObj.manufacturerURL);
      console.log("getAssetInfoObj.manufacturerURL:",getAssetInfoObj.manufacturerURL);
    }
  };

  const getQrCodeForAssetId = (assetId) => {
    MainService.getQrCodeForAsset(username, assetId)
      .then((data) => {
        console.log("qr download link:", data);
        if(data.length>0)
        {
          setQrCodeFileDownload(data["assetqrcodelink"]);
        }
        else{
          setQrCodeFileDownload({});
        }
       
      })
      .catch((err) => {
        console.error(err);
        return NotificationManager.error(err);
      });
  };
  /* End of Generate QR Code */

  return (
    <>
      <div className="container" id="mainContentList">
        {assetsListView || assetsGridView ? (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Asset"
            className={classes.fab}
          >
            <AddIcon onClick={() => assetCreateClcik()}></AddIcon>
          </Fab>
        ) : (
          <></>
        )}
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                setAssetsGridView(true);
                setAssetsListView(false);
                setAssetMonitoringView(false);
              }}
            ></Button>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                setAssetsGridView(false);
                setAssetsListView(true);
                setAssetMonitoringView(false);
              }}
            ></Button>
          </div>
          <div className="topHeaders"></div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Sector
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsIdHandler(event);
                }}
                label="Select Verticals"
              >
                {verticalsIdList.length > 0 ? (
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <h4></h4>
                )}
              </Select>
            </FormControl>
          </div>

          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Category
              </InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategoryHandler(event);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Asset Type
              </InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypeHandler(event);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Model
              </InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModelHandler(event);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                createSubCategoryClick();
              }}
            >
              Create Sub-Category
            </Button>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                deleteSubCategoryClick();
              }}
            >
              Delete Sub-Category
            </Button>
          </div>
        </div>
        <div className="MuiGrid-root">
          {/* Asset List */}
          {assetsListView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <div className="cardStyleForDashboardItems">
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <SearchBar
                      value={searched}
                      onChange={(searchVal) => requestSearch(searchVal)}
                      onCancelSearch={() => cancelSearch()}
                    />
                    {assetsList.length > 0 ? (
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={12}>
                                  Assets
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={1}>
                                  Asset Id
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Asset Status
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  AssetType
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Asset Friendly Name
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  AssetverticalId
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Blocking Status
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Category Type
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Gender
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  ModelId
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Model Name
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Model Type
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Model Url
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Selling Cost
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  SiteId
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  UDN
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Weight
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Whitelabel Cost
                                </TableCell>
                                <TableCell align="center" colSpan={5}>
                                  View
                                </TableCell>
                                <TableCell align="center" colSpan={6}>
                                  Operate
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {assetsList.length > 0 ? (
                                assetsList
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((assets, index) => {
                                    return (
                                      <TableRow hover tabIndex={-1} key={index}>
                                        <TableCell align="center">
                                          {assets.assetId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.assetStatus}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.assetType}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.friendlyName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.assetverticalId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.blockingStatus}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.category}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.gender}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelType}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelUrl}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.sellingCost}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.siteId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.udn}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.weight}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.whitelabelCost}
                                        </TableCell>
                                        <TableCell align="center" />
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              mapPopUpHandler(
                                                assets.assetId,
                                                assets.categoryType
                                              )
                                            }
                                          >
                                            Asset Info
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              assetMonitoringHandler(
                                                assets.siteId,
                                                assets.assetId
                                              )
                                            }
                                          >
                                            Asset Monitoring
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center" />
                                        <TableCell align="center" />
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              mapPopUpHandler(
                                                assets.assetId,
                                                assets.category
                                              )
                                            }
                                          >
                                            Map
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              unMapAssetHandler(
                                                assets.assetId,
                                                assets.category,
                                                assets.siteId
                                              )
                                            }
                                          >
                                            UnMap
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              updateLegacyAssetHandler(
                                                assets.assetverticalId,
                                                assets.assetType,
                                                assets.assetId,
                                                assets.friendlyName,
                                                assets.modelId,
                                                assets.category,
                                                assets.udn,
                                                assets.whitelabelCost,
                                                assets.sellingCost,
                                                assets.assetStatus
                                              )
                                            }
                                          >
                                            Update
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              addedAssetDeleteHandler(
                                                assets.assetId,
                                                assets.assetType,
                                                assets.modelId,
                                                assets.assetverticalId,
                                                assets.category
                                              )
                                            }
                                          >
                                            Delete
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                              ) : (
                                <TableRow align="center">
                                  <TableCell align="center" colSpan={12}>
                                    Assets not found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={assetsList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <h4>Assets not found.</h4>
                    )}
                  </Box>
                )}
              </div>
            </Box>
          ) : null}

          {assetsGridView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <SearchBar
                value={farmgridsearched}
                onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                onCancelSearch={() => cancelFarmGridSearch()}
              />
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    List Of Assets
                  </h4>
                  {assetsList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {assetsList.map((assets, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Id:</b>
                              {assets.assetId}
                            </p>
                            <p>
                              <b>Asset Status:</b>
                              {assets.assetStatus}
                            </p>
                            <p>
                              <b>AssetType:</b>
                              {assets.assetType}
                            </p>
                            <p>
                              <b>Asset Friendly Name:</b>
                              {assets.friendlyName}
                            </p>
                            <p>
                              <b>AssetverticalId:</b>
                              {assets.assetverticalId}
                            </p>
                            <p>
                              <b>Blocking Status:</b>
                              {assets.blockingStatus}
                            </p>
                            <p>
                              <b>Category Type:</b>
                              {assets.category}
                            </p>
                            <p>
                              <b>Gender:</b>
                              {assets.gender}
                            </p>
                            <p>
                              <b>ModelId:</b>
                              {assets.modelId}
                            </p>
                            <p>
                              <b>Model Name:</b>
                              {assets.modelName}
                            </p>
                            <p>
                              <b>Model Type:</b>
                              {assets.modelType}
                            </p>
                            <p>
                              <b>Model Url:</b>
                              {assets.modelUrl}
                            </p>
                            <p>
                              <b>Selling Cost:</b>
                              {assets.sellingCost}
                            </p>
                            <p>
                              <b>SiteId:</b>
                              {assets.siteId}
                            </p>
                            <p>
                              <b>UDN:</b>
                              {assets.udn}
                            </p>
                            <p>
                              <b>Weight:</b>
                              {assets.weight}
                            </p>
                            <p>
                              <b>Whitelabel Cost:</b>
                              {assets.whitelabelCost}
                            </p>
                            <img
                              alt=" "
                              src={`data:image/jpeg;base64,${assets.image}`}
                              width="200"
                              height="200"
                            />
                            {selectedAssetId === assets.assetId? (
                                <div>
                                  <img src={qrCodeFile} alt="img"></img>
                                  <br />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="registerBtn"
                                  >
                                    <a
                                      className="downloadDocument"
                                      href={qrCodeFileDownload}
                                      download
                                    >
                                      Download
                                    </a>
                                  </Button>
                                </div>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="registerBtn"
                                  type="submit"
                                  onClick={(e) => submitGenerateQr(assets)}
                                >
                                  Generate
                                </Button>
                              )}
                           

                            <h4 className="cardactiontitle">View</h4>
                            <div>
                              <div>
                                <Button
                                  variant="contained"
                                  type="button"
                                  onClick={() =>
                                    assetInfoDialogClick(assets.assetId)
                                  }
                                >
                                  Asset Info
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="contained"
                                  type="button"
                                  onClick={() =>
                                    assetMonitoringHandler(assets.siteId,assets.assetId)
                                  }
                                >
                                  Asset Monitoring
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <h4 className="cardactiontitle">Operate</h4>
                              <div>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    mapPopUpHandler(
                                      assets.assetId,
                                      assets.category
                                    )
                                  }
                                >
                                  Map
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    unMapAssetHandler(
                                      assets.assetId,
                                      assets.category,
                                      assets.siteId
                                    )
                                  }
                                >
                                  UnMap
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    updateLegacyAssetHandler(
                                      assets.assetverticalId,
                                      assets.assetType,
                                      assets.assetId,
                                      assets.friendlyName,
                                      assets.modelId,
                                      assets.category,
                                      assets.udn,
                                      assets.whitelabelCost,
                                      assets.sellingCost,
                                      assets.assetStatus
                                    )
                                  }
                                >
                                  Update
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    addedAssetDeleteHandler(
                                      assets.assetId,
                                      assets.assetType,
                                      assets.modelId,
                                      assets.assetverticalId,
                                      assets.category
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>No Assets Found.</h4>
                  )}
                </div>
              )}
            </Box>
          ) : null}
          {/* Asset Monitoring */}
          {assetMonitoringView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <h3>Asset Live Monitoring</h3>
              <br />
              <Button
                variant="contained"
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <AssetLiveGoogleMap
                containerElement={
                  <div style={{ height: `500px`, width: "100%" }} />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </Box>
          ) : null}
        </div>
      </div>

      {/* create sub-category */}
      <Dialog
        fullScreen={fullScreen}
        open={subcategorydialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Create Content Sub-Category"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="assetdeveloperid">
              {" "}
              Assetdeveloper Id:
            </InputLabel>
            <Input
              disabled
              id="assetdeveloperid"
              name="assetdeveloperid"
              type="text"
              value={username}
            />
          </FormControl>
          <FormControl
            variant="standard"
            fullWidth={true}
            className={classes.formControl}
          >
            <InputLabel htmlFor="category">Category</InputLabel>
            <Input
              disabled
              id="category"
              name="category"
              type="text"
              value={selectedCategoryId}
            ></Input>
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="subCategoryName">
              Sub-Category Name :
            </InputLabel>
            <Input
              id="subCategoryName"
              name="subCategoryName"
              type="text"
              value={subCategoryNameValue}
              onChange={(e) => {
                setSubCategoryNameValue(e.target.value);
              }}
            ></Input>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={(e) => {
              submitSubCategoryClick();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setSubCategoryDialog(!subcategorydialog);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* delete sub-category */}
      <Dialog
        fullScreen={fullScreen}
        open={deleteSubcategoryDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Content Sub-Category"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="assetdeveloperid">
              {" "}
              Assetdeveloper Id:
            </InputLabel>
            <Input
              disabled
              id="assetdeveloperid"
              name="assetdeveloperid"
              type="text"
              value={username}
            />
          </FormControl>
          <FormControl
            variant="standard"
            fullWidth={true}
            className={classes.formControl}
          >
            <InputLabel htmlFor="category">Category</InputLabel>
            <Input
              disabled
              id="category"
              name="category"
              type="text"
              value={selectedCategoryId}
            ></Input>
          </FormControl>
          <FormControl fullWidth={true}>
            <InputLabel>choose Sub-Category</InputLabel>
            <Select
              id="selectedSubCategory"
              value={subCategoryNameValue}
              onChange={(e) => {
                setSubCategoryNameValue(e.target.value);
              }}
              label="Select Sub-Category"
            >
              {subCategoriesList &&
                subCategoriesList.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj.contentsubcategoryname}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={(e) => {
              submitSubCategoryDeleteClick();
            }}
          >
            Delete
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Asset Monitoring */}
      <Dialog
        fullScreen={fullScreen}
        open={liveMonitorMapPopUp}
        onClose={() => {
          setLiveMonitorMapPopUp(!liveMonitorMapPopUp);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Asset Live Monitoring"}
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <AssetLiveGoogleMap
            containerElement={
              <div style={{ height: `500px`, width: "100%" }} />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setLiveMonitorMapPopUp(!liveMonitorMapPopUp);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <CustomPopup
        closeButton={closeSitAssetInfoModal}
        isOpen={assetInfoPopup}
        closeModal={assetInfoPopup}
        content={assetInfoBody}
        title={assetInfoHeader}
      />
      <CustomPopup
        closeButton={createAssetModal}
        isOpen={assetPopup}
        closeModal={assetPopup}
        content={agreementBody}
        title={titleCreateAsset}
      />
      <CustomPopup
        closeButton={closeMapModal}
        isOpen={mapPopUp}
        closeModal={mapPopUp}
        content={mapModelBody}
        title={titleMapAsset}
      />
      <CustomPopup
        closeButton={closeUpdateModel}
        isOpen={legacyUpdate}
        closeModal={legacyUpdate}
        content={updateLegacyAssetData}
        title={updateLegacyAsset}
      />
    </>
  );
}
