import React from "react";
import Iaa1 from "../../images/brands-icon/supplement.svg";
import Iaa2 from "../../images/brands-icon/drugs.svg";
import Iaa3 from "../../images/brands-icon/drinks.svg";
import Iaa4 from "../../images/brands-icon/brick.svg";
import Iaa5 from "../../images/brands-icon/dose.svg";
import Iaa6 from "../../images/brands-icon/wheat-sack.svg";


import { Row, Col } from "react-bootstrap";
import {Button, Container} from "@material-ui/core";
import { NavHashLink } from "react-router-hash-link";

export default function AnimalCare() {
  const Image = [
    { img: Iaa1, title:"Supplements"},
    { img: Iaa2, title:"Medicines" },
    { img: Iaa6, title:"Granules" },
    { img: Iaa3, title:"Mineral Mixture" },
    { img: Iaa4, title:"Mineral Lick block"},
    { img: Iaa5, title:"Bolus" },
  ];
  return (
    
    <>
   
        <Container className="mb-5">

          <Row className="justify-content-center">
          {Image.map((e, index) => (
            <Col lg={3} md={4} sm={6} className="md-md-0 mb-3" key={index}>
              {e.enabledProd ? (
                  <div className="imageHover Iaa-Cards Iaa-Cards-mod card-activated">
                    <img className="imgCursor mb-3" src={e.img} alt="icon" />
                    <h6 className="text-center">{e.title}</h6>
                  </div>
              
              ) : (
                <div className="Iaa-Cards Iaa-Cards-mod d-flex justify-content-center align-items-center flex-column card-deactivated">
                  <img  className="mb-3" src={e.img} alt="icon" />
                  <h6>{e.title}</h6>
                </div>
              )}
            </Col>
          ))}
        </Row>
        </Container>
    
      
    
    </>
  );
}
