import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

const PlaceholderCardList = ({ numberOfCards, heightVal }) => {
  const cards = Array.from({ length: numberOfCards }, (_, index) => index);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly", //left
        gap: "1rem",
        flexDirection: "row",
        minWidth: "100%", // Added this line to set minimum width to 100%
      }}
    >
      {cards.map((index) => (
        <Box
          key={index}
          sx={{
            minWidth: {
              xs: "100%",
              sm: "100%",
              md: "45%",
              lg: "45%",
              xl: "45%",
            }, // Adjust as needed
            borderRadius: "10px",
            padding: "10px",
            margin: "10px",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            textAlign: "center",
          }}
        >
          <Skeleton variant="rectangular" height={heightVal} animation="wave" />
        </Box>
      ))}
    </Box>
  );
};

export default PlaceholderCardList;
