import Typography from "@material-ui/core/Typography";
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import OrderProductManagement from "./OrderProductManagement";

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

const OrderProductMarket = ({
  categoryTypes,
  reqTypesBasedOnRoleandUserType,
}) => {
  console.log("categoryTypes", categoryTypes);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const orientation = isSmallScreen ? "vertical" : "horizontal";
  const userType = localStorage.getItem("userType");
  const userRole = localStorage.getItem("UserRole");
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (
      categoryTypes === undefined ||
      categoryTypes === null ||
      !Array.isArray(categoryTypes) ||
      categoryTypes.length === 0
    ) {
      setTabs([
        { label: "PROCESSED-PRODUCTS", categoryType: "PROCESSED-PRODUCTS" },
        { label: "RAW-PRODUCTS", categoryType: "RAW-PRODUCTS" },
        { label: "SEMI-PROCESSED", categoryType: "SEMI-PROCESSED" },
      ]);
    } else {
      setTabs(categoryTypes);
    }
  }, [categoryTypes]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <Box
          sx={{
            width: "100%",
            marginBottom: 30,
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "center" },
          }}
        >
          {Array.isArray(tabs) && (
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
              }}
            >
              <Tabs
                orientation={orientation}
                value={value}
                onChange={handleChange}
                aria-label="Market"
              >
                {tabs &&
                  tabs?.map((tab, index) => (
                    <Tab
                      key={index}
                      sx={{ alignItems: "flex-start" }}
                      label={tab.label}
                      {...a11yProps(index)}
                      value={index}
                    />
                  ))}
              </Tabs>
            </Box>
          )}
          {Array.isArray(tabs) && (
            <Box sx={{ display: "flex", width: "100%" }}>
              {tabs &&
                tabs?.map((tab, index) => (
                  <TabPanel
                    key={index}
                    value={value}
                    index={index}
                    style={{ width: "100%" }}
                  >
                    <OrderProductManagement
                      categoryType={tab.categoryType}
                      requestTypes={tab.reqTypes}
                      reqTypesBasedOnRoleandUserType={
                        reqTypesBasedOnRoleandUserType
                      }
                    />
                  </TabPanel>
                ))}
            </Box>
          )}
        </Box>
      </div>
    </>
  );
};
export default memo(OrderProductMarket);
