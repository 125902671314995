import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import LorryReceipt from './lorryReceipt/LorryReceipt';
import EwayBill from './ewaybill/EwayBill';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MainService from '../../Common/MainService';

const TransportDocumentManagenment = () => {
  const queryClient = new QueryClient();
  const [activeButton, setActiveButton] = useState('lorryreceipt');
  const [userDetails, setUserDetails] = useState({});

  const {
    user: { parentId, username },
  } = MainService.getLocalData();

  const fetchUserData = async () => {
    const data = await MainService.getConsumerUserInfo(parentId, username);

    const {
      username: userName,
      firstname,
      lastname,
      email,
      phone,
      address,
      city,
      state,
      pancard,
    } = data[0];

    const tempData = {
      buyerName: `${firstname} ${lastname}`,
      buyer_cell: {
        buyer_cell1: phone,
        buyer_cell2: '',
      },
      buyer_panNo: pancard,
      buyer_email: email,
      buyer_gstin: '',
      buyerAdressline1: address,
      buyerAdressline2: city,
      buyerAdressline3: state,
    };

    setUserDetails(tempData);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '0.7rem',
          mb: '1rem',
          justifyContent: { xs: 'center', md: 'flex-end' },
        }}
      >
        <Button
          variant={activeButton === 'lorryreceipt' ? 'contained' : 'outlined'}
          onClick={() => setActiveButton('lorryreceipt')}
        >
          Lorry Receipt
        </Button>
        <Button
          variant={activeButton === 'ewaybill' ? 'contained' : 'outlined'}
          onClick={() => setActiveButton('ewaybill')}
        >
          e-Way bill
        </Button>
      </Box>
      {activeButton === 'lorryreceipt' && (
        <QueryClientProvider client={queryClient}>
          <LorryReceipt userDetails={userDetails} />
        </QueryClientProvider>
      )}
      {activeButton === 'ewaybill' && <EwayBill />}
    </Box>
  );
};

export default TransportDocumentManagenment;
