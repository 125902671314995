import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";

function UploadContent({
  props,
  modelShow,
  parent,
  closeModel,
  reloadComponent,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  /* Upload Content */
  const [postFile, setPostFile] = useState(null);
  const [getCategoryNameValueForForm, setCategoryNameValueForForm] =
    useState("");
  const [getCategoryName, setCategoryName] = useState("");
  const [subCategoryNameValueForForm, setSubCategoryNameValueForForm] =
    useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [getExpiryDateValue, setExpiryDateValue] = useState("");
  const [getContentName, setContentName] = useState("");
  const [getListOfCategories, setListOfCategories] = useState([]);
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  // State for error messages
  const [productNameError, setProductNameError] = useState("");
  const [fileError, setFileError] = useState("");

  useEffect(() => {
    if (!getCategoryNameValueForForm) return;
    getSubCategories();
  }, [getCategoryNameValueForForm]);

  /* Upload Content */
  useEffect(() => {
    getCategorys();
  }, []);

  const getCategorys = () => {
    MainService.getListOfCategorys()
      .then((res) => {
        const filterValues = ["PRODUCT-CONTENTS"];
        const filteredData = res?.filter((data) =>
          filterValues.includes(data?.categoryname)
        );
        if (filteredData.length > 0) {
          const firstFilteredItem = filteredData[0];
          setCategoryNameValueForForm(firstFilteredItem?.categoryid);
          setCategoryName(firstFilteredItem?.categoryname);
          setListOfCategories(filteredData);
        } else {
          // Handle the case when no matching category is found
          NotificationManager.warning("No matching category found");
        }
      })
      .catch((e) => {
        NotificationManager.error("Error fetching categories");
      });
  };

  const getSubCategories = () => {
    MainService.getSubCategoriesForAD(parent, getCategoryNameValueForForm)
      .then((data) => {
        if (data?.length > 0) {
          setSubCategoryNameValueForForm(data[0]?.contentsubcategoryname);
          setsubCategoriesList(data);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
  };

  const setCategoryNameValueForFormHandler = (e) => {
    setCategoryNameValueForForm(e.target.value);
    const categoryNameVale = getListOfCategories?.filter((data) => {
      return data.categoryid.includes(e.target.value);
    });
    setCategoryName(categoryNameVale[0]?.categoryname);
  };

  const submitUploadContent = () => {
    // Validate Product Name
    if (!getContentName.trim()) {
      setProductNameError("Product Name is required");
      return;
    } else {
      setProductNameError(""); // Clear the error if the field is valid
    }
    // Validate File
    if (!postFile) {
      setFileError("File is required");
      return;
    } else {
      setFileError(""); // Clear the error if the field is valid
    }
    // Validate File Size
    const fileSizeInMB = postFile.size / (1024 * 1024);
    if (fileSizeInMB > 2) {
      setFileError("File size should not exceed 2 MB");
      return;
    } else {
      setFileError(""); // Clear the error if the field is valid
    }
    // Validate File Format (Allow only image formats)
    const allowedImageFormats = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedImageFormats.includes(postFile.type)) {
      setFileError("Only image formats (JPEG, PNG, GIF) are allowed");
      return;
    } else {
      setFileError(""); // Clear the error if the field is valid
    }
    const postData = new FormData();
    postData.append("file", postFile);
    postData.append("categoryid", getCategoryNameValueForForm);
    postData.append("categoryname", getCategoryName.toUpperCase());
    postData.append("contentname", getContentName);
    postData.append("consumeradminid", props.consumeradminid);
    postData.append("contentdescription", descriptionValue);
    postData.append("contentcreator", props.businessid);
    postData.append("mimetype", "image/*");
    postData.append("contentformat", "IMAGE");
    postData.append("price", props.productcost);
    postData.append("productid", props.productid);
    postData.append("contentownerid", props.productid);
    postData.append("expirydate", getExpiryDateValue);
    postData.append(
      "contentsubcategory",
      subCategoryNameValueForForm.toUpperCase()
    );
    postData.append("unit", props.unit);
    postData.append("discountedprice", props.discountedprice);

    MainService.uploadProductContents(postData, parent, username)
      .then((res) => {
        closeModel();
        reloadComponent();
        return NotificationManager.success("Content Uploaded");
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
  };

  /* EOD Upload Content */

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modelShow}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Upload Content"}
        </DialogTitle>
        <DialogContent>
          {/* <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Select Category"
              name="selectcategory"
              select
              value={getCategoryNameValueForForm}
              onChange={(e) => {
                setCategoryNameValueForFormHandler(e);
              }}
            >
              {getListOfCategories &&
                getListOfCategories?.map((idObj, index) => (
                  <MenuItem key={`${index} `} value={idObj?.categoryid}>
                    {idObj?.categoryname}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl> */}
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Sub Category"
              name="subCategoryName"
              select
              value={subCategoryNameValueForForm}
              onChange={(e) => {
                setSubCategoryNameValueForForm(e.target.value);
              }}
            >
              {subCategoriesList &&
                subCategoriesList?.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj?.contentsubcategoryname}
                  >
                    {idObj?.contentsubcategoryname}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>
          {/* <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Product Id"
              name="productid"
              type="text"
              value={props.productid}
              disabled
            />
          </FormControl> */}
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Product Name"
              name="postName"
              type="text"
              value={getContentName}
              onChange={(e) => {
                setContentName(e.target.value);
                setProductNameError("");
              }}
            />
            {productNameError && (
              <Typography variant="caption" color="error">
                {productNameError}
              </Typography>
            )}
          </FormControl>
          {/* <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Content Owner"
              name="contentOwner"
              type="text"
              value={props.productid}
              disabled
            />
          </FormControl> */}
          {/* <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Content Uploader"
              name="contentUploader"
              type="text"
              value={props.businessid}
              disabled
            />
          </FormControl> */}
          {/* <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Content Approver"
              name="contentApprover"
              type="text"
              value={props.consumeradminid}
              disabled
            />
          </FormControl> */}
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Description"
              name="description"
              type="text"
              value={descriptionValue}
              onChange={(e) => {
                setDescriptionValue(e.target.value);
              }}
            />
          </FormControl>
          {/* <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Price"
              name="price"
              type="text"
              value={props.productcost}
              disabled
            />
          </FormControl> */}
          {/* <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Discounted Price"
              name="discountprice"
              type="text"
              value={props.discountedprice}
              disabled
            />
          </FormControl> */}
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Expiry Date"
              name="expiryDate"
              type="date"
              value={getExpiryDateValue}
              onChange={(e) => {
                setExpiryDateValue(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          {/* <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Unit"
              name="unit"
              type="text"
              value={props.unit}
              disabled
            />
          </FormControl> */}
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="File"
              name="doc"
              type="file"
              onChange={(e) => {
                const selectedFile = e.target.files[0];
                setPostFile(selectedFile);
                setFileError(""); // Clear error when a new file is selected
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {fileError && (
              <Typography variant="caption" color="error">
                {fileError}
              </Typography>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            color="primary"
            variant="contained"
            onClick={() => {
              submitUploadContent();
            }}
          >
            Submit
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            color="error"
            variant="outlined"
            onClick={closeModel}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(UploadContent);
