import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainService from "../../Common/MainService";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import "./ViewExecutivesComponent.css";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from "react-google-maps";
import SearchBar from "material-ui-search-bar";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import { Card, CardContent, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";
import UploadKYCDocumentsComponent from "../UploadKYCDocuments/UploadKYCDocumentsComponent";
import sha256 from "sha256";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function ViewExecutivesComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [setListOpe, getListOpe] = useState(false);
  const [setGridOpe, getGridOpe] = useState(true);
  const [setMapOpe, getMapOpe] = useState(false);
  const [setViewSites, getViewSites] = useState(false);
  const [setGridSites, getGridSites] = useState(false);
  const [setMapSites, getMapSites] = useState(false);
  const [setUsers, getUsers] = useState([]);
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [setmarker_position, getmarker_position] = useState({ lat: 0, lng: 0 });
  /* Executive Information */
  const [setUserName, getUserName] = useState("");
  const [setFName, getFName] = useState("");
  const [setLName, getLName] = useState("");
  const [setDomineName, getDomineName] = useState("");
  const [setUserType, getUserType] = useState("");
  const [setEmail, getEmail] = useState("");
  const [setPhone, getPhone] = useState("");
  const [setAddress, getAddress] = useState("");
  const [setCity, getCity] = useState("");
  const [setState, getState] = useState("");
  const [setNominee, getNominee] = useState("");
  const [setPanCard, getPanCard] = useState("");
  const [setLongitude, getLongitude] = useState("");
  const [setLatitude, getLatitude] = useState("");
  const [setMessage, getMessage] = useState("");
  /* End Of Executive Information */
  /*Creator Executive */
  const [consumerUserDialog, setConsumerUserDialog] = useState(false);
  const [usernameValue, setUsernameValue] = useState("");
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [domainNameValue, setDomainNameValue] = useState("");
  const [userTypeValue, setUserTypeValue] = useState("Executive");
  const [email, setConsumerEmail] = useState("");
  const [phoneNumerValue, setPhoneNumerValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [cityNameValue, setCityNameValue] = useState("");
  const [stateNameValue, setStateNameValue] = useState("");
  const [nomineeValue, setNomineeValue] = useState("");
  const [pancardValue, setPancardValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  /*End Creator Executive */
  /*Delete Executive */
  const [consumerUserDeleteDialog, setConsumerUserDeleteDialog] =
    useState(false);
  const [selectedConsumerUserId, setSelectedConsumerUserId] = useState("");
  /*End Of Delete Executive */
  /*Executive Password Reset*/
  const [consumerUserResetPasswordDialog, setconsumerUserResetPasswordDialog] =
    useState(false);
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [passwordConfirmationValue, setPasswordConfirmationValue] =
    useState("");
  /*End Of Executive Password Reset*/
  /*Executive Update*/
  const [consumerUserUpdateDialog, setConsumerUserUpdateDialog] =
    useState(false);
  const [consumerUserUserId, setConsumerUserUserId] = useState("");
  const [consumerUserUserName, setConsumerUserUserName] = useState("");
  const [consumerUserFirstName, setConsumerUserFirstName] = useState("");
  const [consumerUserLastName, setConsumerUserLastName] = useState("");
  const [consumerUserEmail, setConsumerUserEmail] = useState("");
  const [consumerUserPhone, setConsumerUserPhone] = useState("");
  const [consumerUserAddress, setConsumerUserAddress] = useState("");
  const [consumerUserCity, setConsumerUserCity] = useState("");
  const [consumerUserState, setConsumerUserState] = useState("");
  const [consumerUserNominee, setConsumerUserNominee] = useState("");
  const [consumerUserPancard, setConsumerUserPancard] = useState("");
  const [consumerUserLongitude, setConsumerUserLongitude] = useState("");
  const [consumerUserLatitude, setConsumerUserLatitude] = useState("");
  const [consumerUserMessage, setConsumerUserMessage] = useState("");
  const [consumerUserUserType, setConsumerUserUserType] = useState("");
  const [consumerUserUserDomainName, setConsumerUserUserDomainName] =
    useState("");
  /*End Of Executive Update*/
  /*Update Node Status */
  const [
    consumerUserUpdateNodeStatusDialog,
    setConsumerUserUpdateNodeStatusDialog,
  ] = useState(false);
  const [selectedNodeNameValue, setSelectedNodeNameValue] = useState("");
  const [nodeStatusValue, setNodeStatusValue] = useState("");
  /*End Of Update Node Status */
  /*Node Info */
  const [consumerUserViewNodeInfoDialog, setConsumerUserViewNodeInfoDialog] =
    useState(false);
  const [nodeUserName, setNodeUserName] = useState("");
  const [nodeUserNodeId, setNodeUserNodeId] = useState("");
  const [nodeUserAccountType, setNodeUserAccountType] = useState("");
  const [nodeUserUserType, setNodeUserUserType] = useState("");
  const [nodeUserAssetType, setNodeUserAssetType] = useState("");
  const [nodeUserNumberOfAssets, setNodeUserNumberOfAssets] = useState("");
  const [nodeUserAreaSize, setNodeUserAreaSize] = useState("");
  const [nodeUserGrazingType, setNodeUserGrazingType] = useState("");
  const [nodeUserFeedType, setNodeUserFeedType] = useState("");
  const [nodeUserNumberOfKids, setNodeUserNumberOfKids] = useState("");
  const [nodeUserMaleGenderNumber, setNodeUserMaleGenderNumber] = useState("");
  const [nodeUserFemaleGenderNumber, setNodeUserFemaleGenderNumber] =
    useState("");
  const [nodeUserOverallAge, setNodeUserOverallAge] = useState("");
  const [nodeUserBreed, setNodeUserBreed] = useState("");
  const [nodeUserFarmingExperience, setNodeUserFarmingExperience] =
    useState("");
  const [nodeUserNodeStatus, setNodeUserNodeStatus] = useState("");
  const [nodeUserContentUrl, setNodeUserContentUrl] = useState("");
  const [nodeUserNodeType, setNodeUserNodeType] = useState("");
  const [nodeUserAssetverticalId, setNodeUserAssetverticalId] = useState("");
  const [
    nodeUserAssetverticalFriendlyName,
    setNodeUserAssetverticalFriendlyName,
  ] = useState("");
  const [nodeUserFirstName, setNodeUserFirstName] = useState("");
  const [nodeUserLastName, setNodeUserLastName] = useState("");
  const [nodeUserEmail, setNodeUserEmail] = useState("");
  const [nodeUserPhone, setNodeUserPhone] = useState("");
  const [nodeUserAddress, setNodeUserAddress] = useState("");
  const [nodeUserCity, setNodeUserCity] = useState("");
  const [nodeUserState, setNodeUserState] = useState("");
  const [nodeUserDistrict, setNodeUserDistrict] = useState("");
  const [nodeUserPincode, setNodeUserPincode] = useState("");
  const [nodeUserLatitude, setNodeUserLatitude] = useState("");
  const [nodeUserLongitude, setNodeUserLongitude] = useState("");
  const [nodeUserTimestamp, setNodeUserTimestamp] = useState("");
  /*End Of Node Info */
  const [getType, setType] = useState("");
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");

  /* Feedback  start*/
  const [customerRate, setCustomerRate] = React.useState(0);
  const [customerRateHover, setCustomerRateHover] = React.useState(-1);
  const [productRate, setProductRate] = React.useState(0);
  const [productRateHover, setProductRateHover] = React.useState(-1);
  const [supportRate, setSupportRate] = React.useState(0);
  const [supportRateHover, setSupportRateHover] = React.useState(-1);
  const [othersRate, setOthersRate] = React.useState(0);
  const [othersRateHover, setOthersRateHover] = React.useState(-1);
  const [remarks, setRemarks] = useState("");
  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);
  const [customerExp, setcustomerExp] = useState("");
  const [deliveryExp, setDeliveryExp] = useState("");
  const [productExp, setProductExp] = useState("");
  const [supportExp, setSupportExp] = useState("");
  const [othersExp, setOthersExp] = useState("");
  const [executiveFeedbackDialog, setExecutiveFeedbackDialog] = useState(false);
  const [selectedExecutiveUserId, setSelectedExecutiveUserId] = useState("");
  const [selectedExecutiveName,setSelectedExecutiveName] = useState("");

  const labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }
  function getLabelTextCustomer(customerRate) {
    return `${customerRate} Star${customerRate !== 1 ? "s" : ""}, ${
      labels[customerRate]
    }`;
  }
  function getLabelTextProduct(productRate) {
    return `${productRate} Star${productRate !== 1 ? "s" : ""}, ${
      labels[productRate]
    }`;
  }
  function getLabelTextSupport(supportRate) {
    return `${supportRate} Star${supportRate !== 1 ? "s" : ""}, ${
      labels[supportRate]
    }`;
  }
  function getLabelTextOthers(othersRate) {
    return `${othersRate} Star${othersRate !== 1 ? "s" : ""}, ${
      labels[othersRate]
    }`;
  }
  /* Feedback end */
  /* Agreent view and update status */
  const [isLoading,setIsLoading] = useState(false);
  const [viewSignedAgreementDialog, setViewSignedAgreementDialog] =
  useState(false);
const [viewSignedDocumentList, setViewSignedDocumentList] = useState([]);
const [AgreementsDocumentLink, setAgreementsDocumentLink] = useState(null);
const [agreementDocumentStatus, setAgreementDocumentStatus] = useState("");
const [updateAgreementStatusDialog, setUpdateAgreementStatusDialog] =
  useState(false);
const [agreementStatusValue, setAgreementStatusValue] = useState("");
const [selectedConsumerUserName, setSelectedConsumerUserName] = useState("");
const [viewKYCDialog, setViewKYCDialog] =  useState(false);
/*End of  Agreent view and update status */


  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    getUsers(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    getUsers(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(() => {
    listOfExecutives();
  }, [getUsers]);

  useEffect(() => {
    return navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        getmarker_position(pos);
      }
    );
  }, []);

  /* Pagination */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* End Pagination */

  const listOfExecutives = (Type) => {
    setType(Type);
    if (Type === "List") {
      getListOpe(true);
      getGridOpe(false);
      getMapOpe(false);

      getViewSites(false);
      getGridSites(false);
      getMapSites(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(true);
      getMapOpe(false);

      getViewSites(false);
      getGridSites(false);
      getMapSites(false);
    } else if (Type === "Map") {
      getListOpe(false);
      getGridOpe(false);
      getMapOpe(true);

      getViewSites(false);
      getGridSites(false);
      getMapSites(false);
    }

    MainService.viewListOfExecutives(username)
      .then((res) => {
        if(res !== undefined)
        {
          getUsers(res.users);
        getFilterUsers(res.users);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const consumerInfo = (name, Type) => {
    if (Type === "List") {
      getListOpe(true);
      getGridOpe(false);
      getMapOpe(false);

      getViewSites(true);
      getGridSites(false);
      getMapSites(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(true);
      getMapOpe(false);

      getViewSites(true);
      getGridSites(false);
      getMapSites(false);
    } else if (Type === "Map") {
      getListOpe(false);
      getGridOpe(false);
      getMapOpe(true);

      getViewSites(false);
      getGridSites(false);
      getMapSites(false);
    }
    MainService.getConsumerInfoRequest(name, username)
      .then((res) => {
        getUserName(res.requestInfo[0].username);
        getFName(res.requestInfo[0].firstname);
        getLName(res.requestInfo[0].lastname);
        getDomineName(res.requestInfo[0].domain);
        getUserType(res.requestInfo[0].usertype);
        getEmail(res.requestInfo[0].email);
        getPhone(res.requestInfo[0].phone);
        getAddress(res.requestInfo[0].address);
        getCity(res.requestInfo[0].city);
        getState(res.requestInfo[0].state);
        getLongitude(res.requestInfo[0].longitude);
        getLatitude(res.requestInfo[0].latitude);
        getMessage(res.requestInfo[0].message);
        getNominee(res.requestInfo[0].userid);
        getPanCard(res.requestInfo[0].status);
        console.log("Executive Based Responce:", res);
      })
      .catch((err) => {
        console.log("console.error", err);
        return NotificationManager.error("err");
      });
  };

  /*************** Create, Update, Delete, NodeStatus, Node Info Executive ***************/

  const createconsumerUsersubmit = () => {
    setIsLoading(true);
    let consumerUserData = {
      username: usernameValue,
      firstname: firstNameValue,
      lastname: lastNameValue,
      domain: username,
      usertype: userTypeValue,
      phone: phoneNumerValue,
      address: addressValue?.replace(/\n/g, ''),
      city: cityNameValue,
      state: stateNameValue,
      nominee: nomineeValue,
      pancard: pancardValue,
      longitude: longitudeValue,
      latitude: latitudeValue,
      message: messageValue?.replace(/\n/g, ''),
      email: email,
    };
    const consumerUserDataString = JSON.stringify(consumerUserData);
    if(usernameValue && firstNameValue && lastNameValue && phoneNumerValue)
    { 
      if (usernameValue === email) {
    MainService.createConsumerUserOfIndividual(
      consumerUserDataString,
      username,
      usernameValue,
      email
    )
      .then((res) => {
        listOfExecutives(getType);
        setIsLoading(false);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        setIsLoading(false);
        return NotificationManager.error(err);
      });
    }else {
      return NotificationManager.error("username and email should be same");
    }

    }else{
      setIsLoading(false);
      return NotificationManager.error("Please fill all required fields");
    }
    setConsumerUserDialog(!consumerUserDialog);
  };

  const deleteSubmitClick = () => {
    MainService.deleteConsumerUserIndividual(
      username,
      selectedConsumerUserName,
      selectedConsumerUserId
    )
      .then((res) => {
        listOfExecutives(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
  };

  const deleteDialogClick = (consumerUserName, consumerUserId) => {
    setSelectedConsumerUserName(consumerUserName);
    setSelectedConsumerUserId(consumerUserId);

    setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
  };

  const resetPasswordDialogClick = (consumerUserId) => {
    setSelectedConsumerUserId(consumerUserId);
    setconsumerUserResetPasswordDialog(!consumerUserResetPasswordDialog);
  };

  const resetPasswordSubmit = () => {
    if (newPasswordValue === "") {
      return NotificationManager.error("please provide password");
    }
    if (newPasswordValue === passwordConfirmationValue) {
      let resetPwdData = {
        type: "password",
        value:sha256(newPasswordValue),
        temporary: false,
      };
      const resetPwdDataString = JSON.stringify(resetPwdData);
      MainService.resetConsumerUserPasswordIndividual(
        resetPwdData,
        username,
        selectedConsumerUserId
      )
        .then((res) => {
          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
      setconsumerUserResetPasswordDialog(!consumerUserResetPasswordDialog);
    } else {
      return NotificationManager.error(
        " Confirmation Password is not match with New password"
      );
    }
  };

  const consumerUserUpdateDialogClick = (consumerUser) => {
    setSelectedConsumerUserName(consumerUser.username);
    setSelectedConsumerUserId(consumerUser.userid);

    setConsumerUserUserId(consumerUser.userid);
    setConsumerUserUserName(consumerUser.username);
    setConsumerUserFirstName(consumerUser.firstname);
    setConsumerUserLastName(consumerUser.lastname);
    setConsumerUserEmail(consumerUser.email);
    setConsumerUserPhone(consumerUser.phone);
    setConsumerUserAddress(consumerUser.address);
    setConsumerUserCity(consumerUser.city);
    setConsumerUserState(consumerUser.state);
    setConsumerUserNominee(consumerUser.nominee);
    setConsumerUserPancard(consumerUser.pancard);
    setConsumerUserLongitude(consumerUser.longitude);
    setConsumerUserLatitude(consumerUser.latitude);
    setConsumerUserMessage(consumerUser.message);
    setConsumerUserUserType(consumerUser.usertype);
    setConsumerUserUserDomainName(consumerUser.domain);

    setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
  };

  const consumerUserUpdateSubmit = () => {
    let consumerUserUpdateData = {
      firstname: consumerUserFirstName,
      lastname: consumerUserLastName,
      phone: consumerUserPhone,
      address: consumerUserAddress?.replace(/\n/g, ''),
      city: consumerUserCity,
      state: consumerUserState,
      nominee: consumerUserNominee,
      pancard: consumerUserPancard,
      longitude: consumerUserLongitude,
      latitude: consumerUserLatitude,
      message: consumerUserMessage?.replace(/\n/g, ''),
      email: consumerUserEmail,
    };
    const consumerUserUpdateDataString = JSON.stringify(consumerUserUpdateData);
    MainService.updateConsumerUserInfoIndividual(
      username,
      consumerUserUpdateDataString,
      selectedConsumerUserId,
      selectedConsumerUserName
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
  };

  const updateNodeStatusClick = (consumerUserName) => {
    setSelectedNodeNameValue(consumerUserName);
    setConsumerUserUpdateNodeStatusDialog(!consumerUserUpdateNodeStatusDialog);
  };

  const updateNodeStatusSubmit = () => {
    if (nodeStatusValue === "") {
      return NotificationManager.error("please select status");
    }

    MainService.updateConsumerNodeStatus(selectedNodeNameValue, nodeStatusValue)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserUpdateNodeStatusDialog(!consumerUserUpdateNodeStatusDialog);
  };

  const viewNodeInfoClick = (consumerUserName) => {
    MainService.getConsumerNodeInfo(consumerUserName)
      .then((res) => {
        setNodeUserName(res[0].username);
        setNodeUserNodeId(res[0].nodeid);
        setNodeUserAccountType(res[0].accounttype);
        setNodeUserUserType(res[0].usertype);
        setNodeUserAssetType(res[0].assettype);
        setNodeUserNumberOfAssets(res[0].numberofassets);
        setNodeUserAreaSize(res[0].areasize);
        setNodeUserGrazingType(res[0].grazingtype);
        setNodeUserFeedType(res[0].feedtype);
        setNodeUserNumberOfKids(res[0].numberofkids);
        setNodeUserMaleGenderNumber(res[0].malegendernumber);
        setNodeUserFemaleGenderNumber(res[0].femalegendernumber);
        setNodeUserOverallAge(res[0].overallage);
        setNodeUserBreed(res[0].breed);
        setNodeUserFarmingExperience(res[0].farmingexperience);
        setNodeUserNodeStatus(res[0].nodestatus);
        setNodeUserContentUrl(res[0].contenturl);
        setNodeUserNodeType(res[0].nodetype);
        setNodeUserAssetverticalId(res[0].assetverticalid);
        setNodeUserAssetverticalFriendlyName(res[0].assetverticalfriendlyname);
        setNodeUserFirstName(res[0].firstname);
        setNodeUserLastName(res[0].lastname);
        setNodeUserEmail(res[0].email);
        setNodeUserPhone(res[0].phone);
        setNodeUserAddress(res[0].address);
        setNodeUserCity(res[0].city);
        setNodeUserState(res[0].state);
        setNodeUserDistrict(res[0].district);
        setNodeUserPincode(res[0].pincode);
        setNodeUserLatitude(res[0].latitude);
        setNodeUserLongitude(res[0].longitude);
        setNodeUserTimestamp(res[0].timestamp);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserViewNodeInfoDialog(!consumerUserViewNodeInfoDialog);
  };
  /*************** End Of Create, Update, Delete, NodeStatus, NodeInfo Executive ***************/

  /* Sigend Agreemnts view and update status */
  const viewKYCClick = (user)=>{
    setSelectedConsumerUserName(user);
    setViewKYCDialog(!viewKYCDialog);
  }
const viewSignedAgreementClick = (user,from) => {
  MainService.getContentsBasedonCreator( user, "Ep2AUBOYK0")
  .then((res) => {
    if (res.length > 0) {
      setAgreementsDocumentLink(res[0].texturl);
      //setAgreementDocumentStatus(res[0].documentstatus);
      setIsLoading(false);
    } else {
      console.log("setViewSignedDocumentList", res);
      setAgreementsDocumentLink(null);
      //setAgreementDocumentStatus("");
      setIsLoading(false);
    }
  })
    .catch((err) => {
      return NotificationManager.error(err);
    });
  setIsLoading(false);
    if(from !== "fromupdatebtn")
    {
      setViewSignedAgreementDialog(!viewSignedAgreementDialog);
    }
  
};
const updateAgreementStatusClick = (user) => {
  viewSignedAgreementClick(user,"fromupdatebtn")
  setSelectedConsumerUserName(user);
  setUpdateAgreementStatusDialog(!updateAgreementStatusDialog);
};
const updateAgreementStatusHandler = () => {
  MainService.updateDocumentStatus(
    agreementStatusValue,
    username,
    selectedConsumerUserName,
    "ExecutiveAgreementDocument"
  )
    .then((res) => {
      return NotificationManager.success(res);
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
  setUpdateAgreementStatusDialog(!updateAgreementStatusDialog);
};
/* End of Agreemnet view and update status */
/* Feedback */
/*Start of Feedback */

const feedbackDialogClick = (selectedUserId, selectedUserName) => {
  console.log("selectedUserName",selectedUserName)
  setSelectedExecutiveUserId(selectedUserId);
  setSelectedExecutiveName(selectedUserName);
  setExecutiveFeedbackDialog(!executiveFeedbackDialog);
};
const executiveFeedbackClick = () => {
  let addFeedback = {
    executiveid: selectedExecutiveName,
    //  recorder: username,
    "how_would_you_rate_your_overall_customer_experience?": customerExp,
    "how_satisfied_were_you_with_the_timeliness_of_delivery?": deliveryExp,
    "how_satisfied_were_you_with_the_product?": productExp,
    "how_satisfied_were_you_with_the_customer_support?": supportExp,
    "would_you_recommend_our_product_or_service_to_others?": othersExp,
    remark: remarks,
  };
  const addFeedbackData = JSON.stringify(addFeedback);
  MainService.executiveFeedback(addFeedbackData, username)
    .then((res) => {
      return NotificationManager.success(res);
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
    setExecutiveFeedbackDialog(!executiveFeedbackDialog);
};

/* end of Feedback */
/* end of Feedback */
  /*************** Executives Map *************/
  const ExecutivesGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={setUsers.index}
        defaultZoom={13}
        defaultCenter={setmarker_position}
        center={setmarker_position}
      >
        {setUsers.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                {/* <button type="button" onClick={()=>{roleNameOfExecutive(value.username,"Map")}}> */}
                <div>{value.username}</div>
                {/* </button> */}
              </InfoWindow>
            </Marker>
          ) : (
            ""
          );
        })}

        {/* <Polyline path={props.polyPath} defaultZoom={2} editable={false} /> */}
      </GoogleMap>
    );
  });

  const AssetsGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={setNominee}
        defaultZoom={13}
        defaultCenter={setmarker_position}
        center={setmarker_position}
      >
        <Marker
          key={setNominee}
          position={{
            lat: parseFloat(setLatitude),
            lng: parseFloat(setLongitude),
          }}
        >
          <InfoWindow options={{ disableAutoPan: true }}>
            <div>{setUserName}</div>
          </InfoWindow>
        </Marker>

        {/* <Polyline path={props.polyPath} defaultZoom={2} editable={false} /> */}
      </GoogleMap>
    );
  });
  /*************** End Of Executives Map *************/

  const handleClose = () => {};

  return (
    <React.Fragment>
      <div className="container" id="mainContentList">
        <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Add Executive"
          className={classes.fab}
          onClick={() => {
            setConsumerUserDialog(!consumerUserDialog);
          }}
        >
          <AddIcon></AddIcon>
        </Fab>

        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                listOfExecutives("List");
              }}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                listOfExecutives("Grid");
              }}
            ></Button>
            <Button
              size="large"
              startIcon={<MapIcon />}
              onClick={() => {
                listOfExecutives("Map");
              }}
            ></Button>
          </div>
        </div>
        {setListOpe === true ? (
          <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
            {setUsers.length > 0 ? (
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 450 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          LIST OF EXECUTIVES
                        </TableCell>
                      </TableRow>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          User Id
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          User Name
                        </TableCell>
                        <TableCell align="center" colSpan={3}>
                          Option
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          Operate
                        </TableCell>
                        <TableCell align="center" colSpan={3}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {setUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((user, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {user.userid}
                              </TableCell>
                              <TableCell align="center">
                                {user.username}
                              </TableCell>

                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    consumerInfo(user.username, "List");
                                  }}
                                >
                                  View Info
                                </Button>
                              </TableCell>
                              {/* <TableCell align="center">
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    viewSignedAgreementClick(
                                      user.username
                                    );
                                  }}
                                >
                                  View Signed Agreements
                                </Button>
                              </TableCell>
                              <TableCell>
                              <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                viewKYCClick(user.username);
                              }}
                            >
                              View KYC
                            </Button>
                              </TableCell> */}
                              
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    resetPasswordDialogClick(user.userid);
                                  }}
                                >
                                  Reset Password
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    consumerUserUpdateDialogClick(user);
                                  }}
                                >
                                  Update
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    deleteDialogClick(
                                      user.username,
                                      user.userid
                                    );
                                  }}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    feedbackDialogClick(
                                      user.userid,
                                      user.username
                                    );
                                  }}
                                >
                                  Feedback
                                </Button>
                              </TableCell>
                              {/* <TableCell align="center">
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    updateAgreementStatusClick(
                                      user.username
                                    );
                                  }}
                                >
                                  Update Agreement Status
                                </Button>
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={setUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <h4>No EXECUTIVES Found</h4>
            )}
          </Box>
        ) : null}

        {setGridOpe === true ? (
          <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
            <SearchBar
              value={farmgridsearched}
              onChange={(searchVal) => requestFarmGridSearch(searchVal)}
              onCancelSearch={() => cancelFarmGridSearch()}
            />
            {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
            <div className="cardStyleForDashboardItems">
              <h4  style={{ width: "100%", textAlign: "center" }}>LIST OF EXECUTIVES</h4>
              {setUsers.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setUsers.map((ope, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>User Id:</b>
                          {ope.userid}
                        </p>
                        <p>
                          <b>User Name:</b>
                          {ope.username}
                        </p>
                        <p>
                          <b>Email:</b>
                          {ope.email}
                        </p>
                        <h4 className="cardactiontitle">View</h4>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ marginRight: "10px" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              consumerInfo(ope.username, "Grid");
                            }}
                          >
                            View Info
                          </Button>
                          &nbsp;
                         {/*  <Button
                            type="button"
                            variant="contained"
                            onClick={() => {
                              viewSignedAgreementClick(ope.username);
                            }}
                          >
                            View Signed Agreements
                          </Button> */}
                          <div>
                            &nbsp;&nbsp;
                            {/* <div>
                            <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                viewKYCClick(ope.username);
                              }}
                            >
                              View KYC
                            </Button>
                            </div> */}
                            <h4 className="cardactiontitle">Operate</h4>
                            
                           </div>
                        </div>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <h4 className="cardactiontitle">Actions</h4>
                          <div>
                            <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                resetPasswordDialogClick(ope.userid);
                              }}
                            >
                              Reset Password
                            </Button>
                            <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                consumerUserUpdateDialogClick(ope);
                              }}
                            >
                              Update
                            </Button>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                deleteDialogClick(ope.username, ope.userid);
                              }}
                            >
                              Delete
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                feedbackDialogClick(ope.userid, ope.username);
                              }}
                            >
                              Feedback
                            </Button>
                          </div>
                          {/* &nbsp;&nbsp;
                          <div>
                            <Button
                              type="button"
                              variant="contained"
                              onClick={() => {
                                updateAgreementStatusClick(ope.username);
                              }}
                            >
                              Update Agreement Status
                            </Button>
                          </div> */}
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Executives Found.</h4>
              )}
            </div>
                  )}
          </Box>
        ) : null}

        {setMapOpe === true ? (
          <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
            {setUsers.length > 0 ? (
              <ExecutivesGoogleMap
                containerElement={
                  <div style={{ height: `500px`, width: "100%" }} />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            ) : (
              <h4>No Executive Found</h4>
            )}
          </Box>
        ) : null}

        {setMapSites === true ? (
          <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
            {/* {setAsset.length > 0 ? */}
            <AssetsGoogleMap
              containerElement={
                <div style={{ height: `500px`, width: "100%" }} />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
            {/* : <h4>No Executives Found</h4>} */}
          </Box>
        ) : null}
        {/*Executives View Info */}
        <Dialog
          fullScreen={fullScreen}
          open={setViewSites}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Executive Information"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">User Name:</InputLabel>
              <Input
                id="username"
                name="username"
                placeholder="User Name"
                type="text"
                value={setUserName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstname">First Name:</InputLabel>
              <Input
                id="firstname"
                name="firstname"
                placeholder="First Name"
                type="text"
                value={setFName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastname">Last Name:</InputLabel>
              <Input
                id="lastname"
                name="lastname"
                placeholder="Last Name"
                type="text"
                value={setLName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="domainename">Domain Name:</InputLabel>
              <Input
                id="domainename"
                name="domainename"
                placeholder="Domain Name"
                type="text"
                value={setDomineName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="usertype">User Type:</InputLabel>
              <Input
                id="usertype"
                name="usertype"
                placeholder="User Type"
                type="text"
                value={setUserType}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">Email Id:</InputLabel>
              <Input
                id="email"
                name="email"
                placeholder="Email Id"
                type="text"
                value={setEmail}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="phone">Phone:</InputLabel>
              <Input
                id="phone"
                name="phone"
                placeholder="Phone"
                type="text"
                value={setPhone}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="adderss">Address:</InputLabel>
              <Input
                id="adderss"
                name="adderss"
                placeholder="Address"
                type="text"
                value={setAddress}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City:</InputLabel>
              <Input
                id="city"
                name="city"
                placeholder="City"
                type="text"
                value={setCity}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State:</InputLabel>
              <Input
                id="state"
                name="state"
                placeholder="State"
                type="text"
                value={setState}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">User Id:</InputLabel>
              <Input
                id="nominee"
                name="nominee"
                placeholder="Nominee"
                type="text"
                value={setNominee}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pancard">Status:</InputLabel>
              <Input
                id="pancard"
                name="pancard"
                placeholder="Pan Card Number"
                type="text"
                value={setPanCard}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude:</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                placeholder="Longitude"
                type="text"
                value={setLongitude}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude:</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                placeholder="Latitude"
                type="text"
                value={setLatitude}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="message">Message:</InputLabel>
              <Input
                id="message"
                name="message"
                placeholder="Message"
                type="text"
                value={setMessage}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            {/* <Button
            color="primary"
            className="registerBtn"
            variant="contained"
            // onClick={(e) => {
            //   submitSubCategoryClick();
            // }}
          >
            Submit
          </Button> */}
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                getViewSites(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/*End Of Executives View Info */}
        {/* create Consumer User Executive */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserDialog}
          // onClose={handleClose}
          aria-labelledby="craete Consumer User"
        >
          <DialogTitle id="createConsumerUser">
            {"Create Executive"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">Consumer UserName *:</InputLabel>
              <Input
                id="username"
                name="username"
                type="email"
                value={usernameValue}
                onChange={(e) => {
                  setUsernameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstName">First Name *:</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                value={firstNameValue}
                onChange={(e) => {
                  setFirstNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastName">Last Name *:</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                value={lastNameValue}
                onChange={(e) => {
                  setLastNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="domainName">Domain Name *:</InputLabel>
              <Input
                id="domainName"
                name="domainName"
                type="text"
                value={username}
                
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="userType">User Type *:</InputLabel>
              <Select
                id="userType"
                name="userType"
                value={userTypeValue}
                onChange={(e) => {
                  setUserTypeValue(e.target.value);
                }}
              >
                <MenuItem selected value="Executive">
                  Executive
                </MenuItem>
                
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id *:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setConsumerEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number *:</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Nominee :</InputLabel>
              <Input
                id="nominee"
                name="nominee"
                type="text"
                value={nomineeValue}
                onChange={(e) => {
                  setNomineeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pancard">Pancard Number :</InputLabel>
              <Input
                id="pancard"
                name="pancard"
                type="text"
                value={pancardValue}
                onChange={(e) => {
                  setPancardValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                createconsumerUsersubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setIsLoading(false);
                setConsumerUserDialog(!consumerUserDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Create Consumer User Executive */}
        {/* Delete Executive */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserDeleteDialog}
          // onClose={handleClose}
          aria-labelledby="deleteConsumerUser"
        >
          <DialogTitle id="deleteConsumerUser">
            {"Delete Executive User"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to delete?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Delete Executive */}
        {/* Reset Password Of Executive */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserResetPasswordDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserResetPassword"
        >
          <DialogTitle id="consumerUserResetPassword">
            Executive Password Reset
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="newPassword">New Password:</InputLabel>
              <Input
                id="newPassword"
                name="newPassword"
                type="text"
                value={newPasswordValue}
                onChange={(e) => {
                  setNewPasswordValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="passwordConfirmation">
                Password Confirmation:
              </InputLabel>
              <Input
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="text"
                value={passwordConfirmationValue}
                onChange={(e) => {
                  setPasswordConfirmationValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                resetPasswordSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setconsumerUserResetPasswordDialog(
                  !consumerUserResetPasswordDialog
                );
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Reset Password Executive */}
        {/* Update Executive*/}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserUpdateDialog}
          // onClose={handleClose}
          aria-labelledby="UpdateConsumerUser"
        >
          <DialogTitle id="UpdateConsumerUser">
            {"Update Executive"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">Consumer User Name :</InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={consumerUserUserName}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstName">First Name :</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                value={consumerUserFirstName}
                onChange={(e) => {
                  setConsumerUserFirstName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastName">Last Name :</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                value={consumerUserLastName}
                onChange={(e) => {
                  setConsumerUserLastName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="domainName">Domain Name :</InputLabel>
              <Input
                disabled
                id="domainName"
                name="domainName"
                type="text"
                value={consumerUserUserDomainName}
                onChange={(e) => {
                  setConsumerUserUserDomainName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="userType">User Type</InputLabel>
              <Select
                disabled
                id="userType"
                name="userType"
                value={consumerUserUserType}
              ></Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={consumerUserEmail}
                onChange={(e) => {
                  setConsumerUserEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={consumerUserPhone}
                onChange={(e) => {
                  setConsumerUserPhone(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={consumerUserAddress}
                onChange={(e) => {
                  setConsumerUserAddress(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={consumerUserCity}
                onChange={(e) => {
                  setConsumerUserCity(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={consumerUserState}
                onChange={(e) => {
                  setConsumerUserState(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Nominee :</InputLabel>
              <Input
                id="nominee"
                name="nominee"
                type="text"
                value={consumerUserNominee}
                onChange={(e) => {
                  setConsumerUserNominee(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pancard">Pancard Number :</InputLabel>
              <Input
                id="pancard"
                name="pancard"
                type="text"
                value={consumerUserPancard}
                onChange={(e) => {
                  setConsumerUserPancard(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={consumerUserLongitude}
                onChange={(e) => {
                  setConsumerUserLongitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={consumerUserLatitude}
                onChange={(e) => {
                  setConsumerUserLatitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={consumerUserMessage}
                onChange={(e) => {
                  setConsumerUserMessage(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                consumerUserUpdateSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Update Executive*/}
        {/* Node Status Update*/}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserUpdateNodeStatusDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserNodeUpdate"
        >
          <DialogTitle id="consumerUserNodeUpdate">
            Update Node Status
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nodeName">Node Name :</InputLabel>
              <Input
                disabled
                id="nodeName"
                name="nodeName"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="nodeStatus">Update Node Status</InputLabel>
              <Select
                id="nodeStatus"
                name="nodeStatus"
                value={nodeStatusValue}
                onChange={(e) => {
                  setNodeStatusValue(e.target.value);
                }}
              >
                <MenuItem value="AssetOEM">Authentication Pending</MenuItem>
                <MenuItem value="AssetSeller">Authenticated</MenuItem>
                <MenuItem value="AssetDeveloper">Approved</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateNodeStatusSubmit();
              }}
            >
              Update
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserUpdateNodeStatusDialog(
                  !consumerUserUpdateNodeStatusDialog
                );
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Node Status Update*/}
        {/*Node Info */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserViewNodeInfoDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserNodeInfo"
        >
          <DialogTitle id="consumerUserNodeInfo">View Node Info</DialogTitle>
          <DialogContent>
            <p>
              <b>User Name : </b>
              {nodeUserName}
            </p>
            <p>
              <b>Node ID : </b>
              {nodeUserNodeId}
            </p>
            <p>
              <b>Account Type : </b>
              {nodeUserAccountType}
            </p>
            <p>
              <b>User Type : </b>
              {nodeUserUserType}
            </p>
            <p>
              <b>Asset Type : </b>
              {nodeUserAssetType}
            </p>
            <p>
              <b>Number Of Assets : </b>
              {nodeUserNumberOfAssets}
            </p>
            <p>
              <b>Area Size : </b>
              {nodeUserAreaSize}
            </p>
            <p>
              <b>Grazing Type : </b>
              {nodeUserGrazingType}
            </p>
            <p>
              <b>Feed Type : </b>
              {nodeUserFeedType}
            </p>
            <p>
              <b>Number Of Kids : </b>
              {nodeUserNumberOfKids}
            </p>
            <p>
              <b>Male Gender Number : </b>
              {nodeUserMaleGenderNumber}
            </p>
            <p>
              <b>Female Gender Number : </b>
              {nodeUserFemaleGenderNumber}
            </p>
            <p>
              <b>Over All Age : </b>
              {nodeUserOverallAge}
            </p>
            <p>
              <b>Breed : </b>
              {nodeUserBreed}
            </p>
            <p>
              <b>Farming Experience : </b>
              {nodeUserFarmingExperience}
            </p>
            <p>
              <b>Node Status : </b>
              {nodeUserNodeStatus}
            </p>
            <p>
              <b>Content Url : </b>
              {nodeUserContentUrl}
            </p>
            <p>
              <b>Node Type : </b>
              {nodeUserNodeType}
            </p>
            <p>
              <b>Assetvertical Id : </b>
              {nodeUserAssetverticalId}
            </p>
            <p>
              <b>Assetvertical Friendly Name: </b>
              {nodeUserAssetverticalFriendlyName}
            </p>
            <p>
              <b>Firstname : </b>
              {nodeUserFirstName}
            </p>
            <p>
              <b>Last Name : </b>
              {nodeUserLastName}
            </p>
            <p>
              <b>Email : </b>
              {nodeUserEmail}
            </p>
            <p>
              <b>Phone : </b>
              {nodeUserPhone}
            </p>
            <p>
              <b>State : </b>
              {nodeUserState}
            </p>
            <p>
              <b>City : </b>
              {nodeUserCity}
            </p>
            <p>
              <b>District : </b>
              {nodeUserDistrict}
            </p>
            <p>
              <b>Pincode : </b>
              {nodeUserPincode}
            </p>
            <p>
              <b>Latitude : </b>
              {nodeUserLatitude}
            </p>
            <p>
              <b>Longitude : </b>
              {nodeUserLongitude}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserViewNodeInfoDialog(
                  !consumerUserViewNodeInfoDialog
                );
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/*End Of Node Info */}
        {/* Send Feedback */}
        <Dialog
          fullScreen={fullScreen}
          open={executiveFeedbackDialog}
          onClose={handleClose}
          aria-labelledby="sendFeedback"
        >
          <DialogTitle id="sendFeedback">Send Feedback</DialogTitle>
          <DialogContent>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  {selectedExecutiveName} Feedback
                </Typography>
                <br/>
                <Typography variant="body2" component="span">
                  how would you rate your overall experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="customer-feedback"
                      value={customerRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextCustomer}
                      onChange={(event, customerRate) => {
                        setCustomerRate(customerRate);
                        setcustomerExp(labels[customerRate]);
                      }}
                      onChangeActive={(event, customerRateHover) => {
                        setCustomerRateHover(customerRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {customerRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            customerRateHover !== -1
                              ? customerRateHover
                              : customerRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your product experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="product-feedback"
                      value={productRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextProduct}
                      onChange={(event, productRate) => {
                        setProductRate(productRate);
                        setProductExp(labels[productRate]);
                      }}
                      onChangeActive={(event, productRateHover) => {
                        setProductRateHover(productRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {productRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            productRateHover !== -1
                              ? productRateHover
                              : productRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your delivery experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="delivery-feedback"
                      value={value}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelText}
                      onChange={(event, value) => {
                        setValue(value);
                        setDeliveryExp(labels[value]);
                      }}
                      onChangeActive={(event, customerRateHover) => {
                        setHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {value !== null && (
                      <Box sx={{ ml: 2 }}>
                        {labels[hover !== -1 ? hover : value]}
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your customer Support: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="support-feedback"
                      value={supportRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextSupport}
                      onChange={(event, supportRate) => {
                        setSupportRate(supportRate);
                        setSupportExp(labels[supportRate]);
                      }}
                      onChangeActive={(event, supportRateHover) => {
                        setSupportRateHover(supportRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {supportRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            supportRateHover !== -1
                              ? supportRateHover
                              : supportRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your other experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="others-feedback"
                      value={othersRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextOthers}
                      onChange={(event, othersRate) => {
                        setOthersRate(othersRate);
                        setOthersExp(labels[othersRate]);
                      }}
                      onChangeActive={(event, othersRateHover) => {
                        setOthersRateHover(othersRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {othersRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            othersRateHover !== -1
                              ? othersRateHover
                              : othersRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <br />
                <Typography variant="body2" component="span">
                  Remarks: <br />
                  <TextareaAutosize
                    rowsMin={5}
                    aria-label="minimum height"
                    placeholder="Any remarks"
                    style={{ minWidth: "260px" }}
                    value={remarks}
                    onChange={(event) => {
                      setRemarks(event.target.value);
                    }}
                  />
                </Typography>
                <br />
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
              className="registerBtn"
              type="button"
              onClick={() => executiveFeedbackClick()}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setExecutiveFeedbackDialog(!executiveFeedbackDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* View signed Agreeement */}
        <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={viewSignedAgreementDialog}
          aria-labelledby="viewAgreements"
        >
          <DialogTitle id="viewAgreements">
            {"View Signed Agreement"}
          </DialogTitle>
          <DialogContent>
            {AgreementsDocumentLink ? (
              <>
                {isLoading ? (
                  true
                ) : (
                  <embed
                    accept="image/*,.pdf"
                    className="viewWidthHeight"
                    src={AgreementsDocumentLink}
                  ></embed>
                )}
              </>
            ) : (
              <h4>No Agreement Found</h4>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setViewSignedAgreementDialog(!viewSignedAgreementDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* update signed Agreeement status*/}
        <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={updateAgreementStatusDialog}
          aria-labelledby="viewAgreements"
        >
          <DialogTitle id="viewAgreements">
            {"View Signed Agreement"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="existingStatus">
                Existing Status :
              </InputLabel>
              <Input
                disabled
                id="existingStatus"
                name="existingStatus"
                type="text"
                value={agreementDocumentStatus}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="status">Choose Status *</InputLabel>
              <Select
                id="status"
                value={agreementStatusValue}
                label="Choose Status"
                onChange={(e) => {
                  setAgreementStatusValue(e.target.value);
                }}
              >
                <MenuItem value="Agreement-Verification-In-Progress">
                  Agreement-Verification-In-Progress
                </MenuItem>
                <MenuItem value="Agreement-Verified">
                  Agreement-Verified
                </MenuItem>
                <MenuItem value="Agreement-Rejected">
                  Agreement-Rejected
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateAgreementStatusHandler();
              }}
            >
              Update
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUpdateAgreementStatusDialog(!updateAgreementStatusDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* View KYC documents*/}
        <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={viewKYCDialog}
          aria-labelledby="viewKYC"
        >
          <DialogContent>
            <UploadKYCDocumentsComponent selectedUser={selectedConsumerUserName}/>
          </DialogContent>
          <DialogActions>
            
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setViewKYCDialog(!viewKYCDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
