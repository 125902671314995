import React from 'react'
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar'

export default function AgroCareMarketPlaceNavbar() {
    const navData = [
    {to:"/agrocare-marketplace", link:"For Farmers"},
    {to:"/for-agro-dealers", link:"For Agro-Dealers"},
  ]
//   const navData2 = [{to:"/AgriBusiness", link:"Agri-Business"},
//   {to:"/Brands", link:"Brands"},
// ]
  return (
    <><SecondaryNavbar props={navData}  /></>
  )
}
