import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NotificationManager } from 'react-notifications';
import MainService from '../../Common/MainService';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import './AssetHealthStatusComponent.css';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from 'react-google-maps';
import ListIcon from '@material-ui/icons/List';
import GridIcon from '@material-ui/icons/GridOnOutlined';
import MapIcon from '@material-ui/icons/MapRounded';
import { Grid } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CustomPopup from '../../Common/CustomPopup/CustomPopupComponent';
import Paper from '@mui/material/Paper';
import Box from '@material-ui/core/Box';
import SearchBar from 'material-ui-search-bar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import readyForWhitelabelImg from '../../../../resources/img/readyForWhitelabel.png';
import whitelabelImg from '../../../../resources/img/whitelabel.png';
import sellableImg from '../../../../resources/img/sellable.jpg';
import soldImg from '../../../../resources/img/sold.png';
import leasedImg from '../../../../resources/img/leased.jpg';
import Map from '../../Common/googleMap/Map';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // set last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: '#fff',
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
  },
}));

export default function AssetHealthStatusComponent() {
  const classes = useStyles();
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isLoading, setIsLoading] = useState(true);
  const [allSites, setAllSites] = useState([]);
  const [geoLocation, setGeoLocation] = useState({
    lat: 13.093426,
    lng: 77.655773,
  });
  const [markers, setMarkers] = useState([]);
  const [marker_position, setmarker_position] = useState({
    lat: 13.093426,
    lng: 77.655773,
  });
  const [selectedVerticalsId, setSelectedVerticalsId] = useState('');
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalName, setSelectedVerticalName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [good, setGoodList] = useState([]);
  const [average, setAverageList] = useState([]);
  const [critical, setCriticalList] = useState([]);
  const [bad, setBadList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [markersList, setMarkersList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState('');
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState('');

  const [assestsMarker, setAssestsMarker] = useState([]);

  useEffect(() => {
    getAssetVerticalsIdList();
    getAllSites();
  }, []);

  useEffect(() => {
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  useEffect(() => {
    getAssetTypesList();
  }, [selectedVerticalsId, selectedCategory]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getAssetHealthStatusExe();
    }, 1000);
    return () => clearTimeout(timer);
  }, [
    selectedVerticalsId,
    selectedCategory,
    selectedAssertTypes,
    selectedSiteId,
  ]);

  const getAllSites = () => {
    MainService.getAllSites(parentId)
      .then((data) => {
        if (data.length > 0) {
          setAllSites(data);
          setSelectedSiteId(data[0].siteid);
        } else {
          setAllSites([]);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList('consumer2')
      .then((data) => {
        if (data.length > 0) {
          setSelectedVerticalsId(data[0].assetVerticalId);
          setSelectedVerticalName(data[0].friendlyName);
          setVerticalsIdList(data);
        } else {
          setSelectedVerticalsId('');
          setIsLoading(false);
          setVerticalsIdList([]);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== '') {
      MainService.getCategoryTypesList(
        selectedVerticalsId.replace(' ', ''),
        'consumer2'
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setSelectedCategory('');
            setIsLoading(false);
            setCategoryTypesList([]);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== '' && selectedCategory !== '') {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(' ', ''),
        selectedCategory.replace(' ', ''),
        'consumer2'
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
          } else {
            setSelectedAssertTypes('');
            setIsLoading(false);
            setAssertTypesList([]);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getAssetHealthStatusExe = () => {
    if (
      selectedVerticalsId !== '' &&
      selectedCategory !== '' &&
      selectedAssertTypes !== '' &&
      selectedSiteId !== ''
    ) {
      MainService.getAssetHealthStatusExe(
        parentId,
        selectedVerticalsId,
        selectedCategory,
        selectedAssertTypes,
        selectedSiteId
      )
        .then((res) => {
          console.log('process health  data:', res);
          setIsLoading(false);
          setTagsList(res[0].tags);
          setGoodList(res[0].Good);
          setAverageList(res[0].Average);
          setCriticalList(res[0].Critical);
          setBadList(res[0].Bad);
          console.log('funcction call:', res);

          const obj = res[0].tags.reduce((accumulator, currentValue) => {
            const filteredData = res[0][currentValue].filter((item) =>
              Object.values(item).every((li) => li !== 'null')
            );
            if (filteredData.length > 0) {
              const filterProperties = filteredData.map(({ lat, log }) => ({
                geoLocation: {
                  lat: parseFloat(lat),
                  lng: parseFloat(log),
                },
              }));
              accumulator.push(...filterProperties);
            }
            return accumulator;
          }, []);
          setAssestsMarker(obj);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
    }
  };

  const getMarkerPositions = (tag1) => {
    for (let i = 0; i < tag1.length; i++) {
      var mlat = tag1[i].lat;
      var mlog = tag1[i].log;
      const position = { lat: mlat, lng: mlog };
      // markersList.push(position);
      setMarkers([...markers, { geoLocation: position }]);
    }

    //console.log("markersList:", markersList)
  };
  const setSelectedVerticalsIdHandler = (e) => {
    setSelectedVerticalsId(e.target.value);
    let catname = verticalsIdList.filter((data) => {
      return data.assetVerticalId === e.target.value;
    });
    setSelectedVerticalName(catname[0].friendlyName);
  };

  const categoryTypeHandler = (event) => {
    setSelectedCategory(event.target.value);
  };
  /*************** Site Management Map *************/
  const SiteManagementGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={markersList.index}
        defaultZoom={4}
        defaultCenter={marker_position}
        center={marker_position}
      >
        {markersList.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.lat),
                lng: parseFloat(value.log),
              }}
            >
              {/* <InfoWindow options={{ disableAutoPan: true }}>
                <button
                  type="button"
                  onClick={() => {
                    viewMappedAssets(value.siteid, "Map", value.sitename);
                  }}
                >
                  <div>{value.sitename}</div>
                </button>
              </InfoWindow> */}
            </Marker>
          ) : (
            ''
          );
        })}
      </GoogleMap>
    );
  });

  const markerSelectHandler = (props) => {
    const data = props
      .map((item) => {
        if (item.lat !== 'null' && item.log !== 'null') {
          return {
            geoLocation: {
              lat: parseFloat(item.lat),
              lng: parseFloat(item.log),
            },
          };
        }
      })
      .filter(Boolean);

    setAssestsMarker(data);
  };

  return (
    <React.Fragment>
      <div className='container' id='mainContentList'>
        <div className='topHeadersWrapper'>
          <div className='topHeaders'>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel
                style={{
                  transform: 'translate(14px, -6px) scale(0.75)',
                  color: 'rgb(43, 144, 99)',
                }}
              >
                Sector
              </InputLabel>
              <Select
                id='selectedVerticalsId'
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsIdHandler(event);
                }}
                label='Select Verticals'
              >
                {verticalsIdList.length > 0 ? (
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <h4>Sector not found</h4>
                )}
              </Select>
            </FormControl>
          </div>

          <div className='topHeaders'>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel
                style={{
                  transform: 'translate(14px, -6px) scale(0.75)',
                  color: 'rgb(43, 144, 99)',
                }}
              >
                Category
              </InputLabel>
              <Select
                id='selectedCategory'
                value={selectedCategory}
                onChange={(event) => {
                  categoryTypeHandler(event);
                }}
                label='Select Category'
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className='topHeaders'>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel
                style={{
                  transform: 'translate(14px, -6px) scale(0.75)',
                  color: 'rgb(43, 144, 99)',
                }}
              >
                Asset Type
              </InputLabel>
              <Select
                id='selectedAssertTypes'
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label='Select Type'
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className='topHeaders'>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel
                style={{
                  transform: 'translate(14px, -6px) scale(0.75)',
                  color: 'rgb(43, 144, 99)',
                }}
              >
                Site
              </InputLabel>
              <Select
                id='site'
                value={selectedSiteId}
                onChange={(event) => {
                  setSelectedSiteId(event.target.value);
                }}
                label='Select Verticals'
              >
                {allSites.length > 0 ? (
                  allSites.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.siteid}>
                      {idObj.sitename}
                    </MenuItem>
                  ))
                ) : (
                  <h4>Sector not found</h4>
                )}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className='MuiGrid-root'>
          <Paper>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align='center'
                      colSpan={3}
                      onClick={() => markerSelectHandler(good)}
                    >
                      <img src={readyForWhitelabelImg} />
                      <br />
                      <font size='2'>Good ({good.length})</font>
                    </TableCell>
                    <TableCell
                      align='center'
                      colSpan={3}
                      className='imgContainer'
                      onClick={() => markerSelectHandler(average)}
                    >
                      <img src={whitelabelImg} />
                      <br />
                      <font size='2'>
                        Average({average.length})
                        <br />
                      </font>
                    </TableCell>
                    <TableCell
                      align='center'
                      colSpan={3}
                      className='imgContainer'
                      onClick={() => markerSelectHandler(critical)}
                    >
                      <img src={sellableImg} />
                      <br />
                      <font size='2'>
                        Critical({critical.length})
                        <br />
                      </font>
                    </TableCell>
                    <TableCell
                      align='center'
                      colSpan={3}
                      className='imgContainer'
                      onClick={() => markerSelectHandler(bad)}
                    >
                      <img src={sellableImg} />
                      <br />
                      <font size='2'>
                        Bad({bad.length})
                        <br />
                      </font>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Box sx={{ width: '100%', marginBottom: 30, marginTop: 30 }}>
            {/* <SiteManagementGoogleMap
              containerElement={
                <div style={{ height: `500px`, width: '100%' }} />
              }
              mapElement={<div style={{ height: `100%` }} />}
            /> */}
            <Map routeLocationData={assestsMarker}></Map>
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
}
