import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DeviceCloudServerManagement from '../DeviceCloudServerManagement/DeviceCloudServerManagement';
import OTACloudServerManagement from '../OTACloudServer/OTACloudServerManagement';
import VerticalDeveloper from '../VerticalDeveloper/VerticalDeveloper';
import VerticalUsecaseDeveloper from '../VerticalUsecaseDeveloper/VerticalUsecaseDeveloper';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const ServerManagement = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Device Cloud Server" {...a11yProps(0)} />
            <Tab label="OTA Configuration Server" {...a11yProps(1)} />
            <Tab label="Vertical Developer Server" {...a11yProps(2)} />
            <Tab label="Vertical Usecase Developer Server" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
        <DeviceCloudServerManagement/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
        <OTACloudServerManagement/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
        <VerticalDeveloper/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
         <VerticalUsecaseDeveloper/>
        </CustomTabPanel>
      </Box>
    );
}

export default ServerManagement