import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@mui/material";
import sha256 from "sha256";
import DialogContentText from '@mui/material/DialogContentText';



  const renderKeyValue = (key, value) => {
    if (typeof value === 'object' && value !== null) {
      return (
        <div key={key}>
          <strong>{key}:</strong>
          <div style={{ marginLeft: '20px' }}>
            {Object.entries(value).map(([nestedKey, nestedValue]) =>
              renderKeyValue(nestedKey, nestedValue)
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div key={key}>
          <strong>{key}:</strong> {JSON.stringify(value)}
        </div>
      );
    }
  };

  
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

/* end of tab panel */

export default function RoleUpdationRequestComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [consumerUsersList, setConsumerUsersList] = useState([]);
  const [state, setState] = useState({ data: [] });
  const [requestInfoDialog, setRequestInfoDialog] = useState(false);
  const [requestInfoObj, setRequestInfoObj] = useState({});
  const [requestStatusObj, setRequestStatusObj] = useState({});
  const [requestStatusDialog, setRequestStatusDialog] = useState(false);
  const [registerUserRequestsList, setRegisterUserRequestsList] = useState([]);
  const [ownRequestsList, setOwnRequestsList] = useState([]);
  const [allRolesList, setAllRolesList] = useState([]);
  const [filteredRolesList, setFilteredRolesList] = useState([]);
  const [roleIdValue, setRoleIdValue] = useState("");
  const [roleNameValue, setRoleNameValue] = useState("");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [consumerUserDialog, setConsumerUserDialog] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [
    filteredRegisterUserRequestsList,
    setFilteredRegisterUserRequestsList,
  ] = useState([]);
  const [
    filteredOwnRequestsList,
    setFilteredOwnRequestsList,
  ] = useState([]);

  const [searched, setSearched] = useState("");
  const [listRequetsView, setListRequetsView] = useState(false);
  const [gridRequetsView, setGridRequetsView] = useState(true);
  const [userType, setUserType] = useState("");
  const [userTypeRole, setUserTypeRole] = useState("");
  const [resourceNameValue, setResourceNameValue] = useState("");
  const [resourceIdValue, setResourceIdValue] = useState("");
  const [resourceTypeValue, setResourceTypeValue] = useState("");
  const [roleDescriptionValue, setRoleDescriptionValue] = useState("");
  const [existingRoleData, setExistingRoleData] = useState([]);
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [roleDeleteDialog, setRoleDeleteDialog] = useState(false);
  const [roleMapDialog, setRoleMapDialog] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedRequestId, setSelectedRequestId] = useState("");
  const [roleUnMapDialog, setRoleUnMapDialog] = useState(false);
  const [consumerUserIdValue, setConsumerUserIdValue] = useState("");
  const [consumerUserNameValue, setConsumerUserNameValue] = useState("");
  const [consumerUserParentValue, setConsumerUserParentValue] = useState("");
  const [allMappedRolesList, setAllMappedRolesList] = useState([]);
  const [mappedRolesView, setMappedRolesView] = useState(false);
  const [allRolesView, setAllRolesView] = useState(true);
  const [updateMappedRoleDialog, setUpdateMappedRoleDialog] = useState(false);
  const [roleMappedDeleteDialog, setRoleMappedDeleteDialog] = useState(false);
  const [filteredMappedRolesList, setFilteredMappedRolesList] = useState([]);
  const [filteredConsumerUsersList, setFilteredConsumerUsersList] = useState(
    []
  );
  const [roleClickForMap, setRoleClickForMap] = useState(false);
  const [consumerClickForMap, setConsumerClickForMap] = useState(false);
  const [existingRoleName, setExistingRoleName] = useState("");
  const [existingRoleId, setExistingRoleId] = useState("");
  const [existingRoleDescription, setExistingRoleDescription] = useState("");
  const [existingRoleComposite, setExistingRoleComposite] = useState("");
  const [existingRoleClient, setExistingRoleClient] = useState("");
  const [existingRoleContainerId, setExistingRoleContainerId] = useState("");
  const [mapRolesList, setMapRolesList] = useState([]);
  const [ownRequestsView,setOwnRequestsView] = useState(true);
  const [usersRequestsView,setUsersRequestsView] = useState(false);

  useEffect(() => {
    getOwnRoleUpgradationRequests();
    getUsersRoleUpgradationRequests();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getOwnRoleUpgradationRequests = () => {
    setIsLoading(true);
    MainService.getUsersRoleRequests(username)
      .then((res) => {
        if (res?.length > 0) {
          // Filter requests where requesttype is "roleUpgradation"
          const roleUpgradationRequests = res.filter(
            (request) => request.requesttype === "roleUpgradation"
          );
  
          setOwnRequestsList(roleUpgradationRequests);
          setFilteredOwnRequestsList(roleUpgradationRequests);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setOwnRequestsList([]);
          setFilteredOwnRequestsList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  
  const getUsersRoleUpgradationRequests = () => {
    setIsLoading(true);
    MainService.getRegisterAccountRequests(username)
      .then((res) => {
        if (res?.length > 0) {
          const roleUpgradationRequests = res.filter(
            (request) => request.requesttype === "roleUpgradation"
          );
          setRegisterUserRequestsList(roleUpgradationRequests);
          setFilteredRegisterUserRequestsList(roleUpgradationRequests);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setRegisterUserRequestsList([]);
          setFilteredRegisterUserRequestsList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const getProfile = (parentId, username) => {
    MainService.getConsumerUserInfo(parentId, username)
      .then((data) => {
        setIsLoading(false);
        setState({ ...state, data: data });
        setUserType(data[0].usertype);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error("No profile data found");
      });
  };

  const getUserTypeRole = (parentId, username) => {
    MainService.getConsumerUserTypeRole(parentId, username)
      .then((data) => {
        if (data?.length > 0) {
          console.log("getConsumerUserTypeRole data:", data);
          setExistingRoleName(data[0].name);
          setExistingRoleClient(data[0].clientRole);
          setExistingRoleComposite(data[0].composite);
          setExistingRoleDescription(data[0].description);
          setExistingRoleContainerId(data[0].containerId);
          setExistingRoleId(data[0].id);
        } else {
          setExistingRoleName("No Role Exists");
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });

    /* MainService.getConsumerUserTypeRole(parentId, username)
      .then((data) => {
        if (data.length === 0) {
          setUserTypeRole("NULL");
        } else {
          setUserTypeRole(data[0].name);
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      }); */
  };

  const getAllRolesList = (parentId) => {
    setIsLoading(true);
    MainService.getSuperRoles(parentId)
      .then((res) => {
        console.log("roles res:", res);
        if (res?.length > 0) {
          setIsLoading(false);
          setAllRolesList(res);
          setFilteredRolesList(res);
        } else {
          setIsLoading(false);
          setAllRolesList([]);
          setFilteredRolesList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const getAllConsumerUsers = (parentId, username) => {
    MainService.getAllConsumerUsers(parentId, username)
      .then((res) => {
        console.log("all consumer userslist:", res);
        if (res?.length > 0) {
          setConsumerUsersList(res);
          setFilteredConsumerUsersList(res);
        } else {
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const handleClose = () => {};
  /* consumer IAM */

  const setSelectedRoleHandler = (e) => {
    setRoleIdValue(e.target.value);
    let roleId = allRolesList.filter((data) => {
      return data?.id === e.target.value;
    });
    setRoleNameValue(roleId[0]?.name);
  };
  const roleUpdationRequestSubmit = () => {
    if(roleNameValue !== "")
    {
      let consumerUserData = {
        username: username,
        usertype:userType,
        source: username,
        destination: parentId,
        role: roleNameValue,
        requesttype: "roleUpgradation",
        json:{}
      };
      const consumerUserDataString = JSON.stringify(consumerUserData);
      setIsLoading(true);
      MainService.upgradeRoleRequest(parentId, consumerUserDataString)
        .then((res) => {
          getOwnRoleUpgradationRequests();
          setIsLoading(false);
          return NotificationManager.success(
            "Role upgradation request sent Successfully"
          );
        })
        .catch((err) => {
          setIsLoading(false);
          return NotificationManager.error(err);
        });
  
      setConsumerUserDialog(!consumerUserDialog);
    }else{
      return NotificationManager.error("Please choose role");
    }
    
  };

  const requestSearchForUserRequests = (searchedVal) => {
    const filteredRows = filteredRegisterUserRequestsList.filter((row) => {
      return row.usertype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRegisterUserRequestsList(filteredRows);
  };
  const cancelSearchForUserRequests = () => {
    setSearched("");
    requestSearchForUserRequests(searched);
  };

  const listUsersViewClick = () => {
    setListRequetsView(true);
    setGridRequetsView(false);
  };
  const gridRequetsViewClick = () => {
    setListRequetsView(false);
    setGridRequetsView(true);
  };

  const getRoleHandler = (selectedusername) => {
    setConsumerUserNameValue(selectedusername);
    let idvalue = consumerUsersList.filter((data) => {
      return data?.username === selectedusername;
    });
    setConsumerUserIdValue(idvalue[0]?.userid);
    setConsumerUserParentValue(idvalue[0]?.domain);
    console.log("setConsumerUserIdValue:", idvalue[0]?.userid);

    MainService.getConsumerUserTypeRole(username, selectedusername)
      .then((data) => {
        if (data?.length > 0) {
          console.log("getConsumerUserTypeRole data:", data);
          setExistingRoleName(data[0].name);
          setExistingRoleClient(data[0].clientRole);
          setExistingRoleComposite(data[0].composite);
          setExistingRoleDescription(data[0].description);
          setExistingRoleContainerId(data[0].containerId);
          setExistingRoleId(data[0].id);
        } else {
          setExistingRoleName("No Role Exists");
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const roleMapSubmitClick = (consumerUserId) => {
    let createRoleData = {
      id: roleIdValue,
      name: roleNameValue,
      description: roleDescriptionValue,
      composite: false,
      clientRole: false,
      containerId: consumerUserParentValue,
    };

    const roleStringifyData = JSON.stringify(createRoleData);
    setMapRolesList([...mapRolesList, roleStringifyData]);
    if (
      roleNameValue !== "" &&
      consumerUserParentValue !== "" &&
      roleIdValue !== "" &&
      consumerUserId !== ""
    ) {
      MainService.mapSuperRole(
        [createRoleData],
        consumerUserParentValue,
        consumerUserId
      )
        .then((res) => {
          setResourceTypeValue("");
          setResourceNameValue("");
          setConsumerUserNameValue("");
          setConsumerUserIdValue("");
          let data = {
            requestid: selectedRequestId,
            status: "upgraded",
          };
          MainService.updateRegistrationRequestStatus(data,parentId)
            .then((res) => {
              return NotificationManager.success(res);
            })
            .catch((err) => {
              return NotificationManager.error(err);
            });
      
          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
      setConsumerUserNameValue("");
    } else {
      return NotificationManager.error("please choose user and role");
    }

    setRoleMapDialog(!roleMapDialog);
  };

  const roleUnMapSubmitClick = (consumerUserId) => {
    let createRoleData = {
      id: existingRoleId,
      name: existingRoleName,
      description: existingRoleDescription,
      composite: existingRoleComposite,
      clientRole: existingRoleClient,
      containerId: existingRoleContainerId,
    };

    const roleStringifyData = JSON.stringify(createRoleData);
    setMapRolesList([...mapRolesList, roleStringifyData]);
    if (consumerUserId !== "") {
      MainService.unmapSuperRole(
        [createRoleData],
        consumerUserParentValue,
        consumerUserId
      )
        .then((res) => {
          setResourceTypeValue("");
          setResourceNameValue("");
          setConsumerUserNameValue("");
          setConsumerUserIdValue("");
          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
    } else {
      return NotificationManager.error("please choose user");
    }

    setConsumerUserNameValue("");
    setRoleUnMapDialog(!roleUnMapDialog);
  };

  const roleUpgradationClick = (usertype, selectedusername, requestId) => {
    setRoleMapDialog(!roleMapDialog);
    setSelectedUserName(selectedusername);
    setSelectedRequestId(requestId);
    if (
      usertype === "AssetDeveloper" ||
      usertype === "CBO" ||
      usertype === "FMCDealer" ||
      usertype === "PMCDealer" ||
      usertype === "OFC" ||
      usertype === "FMC" ||
      usertype === "PMC" ||
      usertype === "FOOD-PROCESSOR"
    ) {
      getAllRolesList(parentId);
      getAllConsumerUsers(parentId, parentId);
      getRoleHandler(selectedusername);
      getUserTypeRole(parentId, selectedusername);
    } else {
      getAllRolesList(username);
      getAllConsumerUsers(parentId, username);
      getRoleHandler(selectedusername);
      getUserTypeRole(username, selectedusername);
    }
  };
  const roleUnMapClick = (usertype, selectedusername) => {
    setRoleUnMapDialog(!roleUnMapDialog);
    setSelectedUserName(selectedusername);
    getRoleHandler(selectedusername);

    if (
      usertype === "AssetDeveloper" ||
      usertype === "CBO" ||
      usertype === "FMCDealer" ||
      usertype === "PMCDealer" ||
      usertype === "OFC" ||
      usertype === "FMC" ||
      usertype === "PMC" ||
      usertype === "FOOD-PROCESSOR"
    ) {
      getUserTypeRole(parentId, selectedusername);
    } else {
      getUserTypeRole(username, selectedusername);
    }
  };

  const requestInfoClick = (requestId) => {
    MainService.requestInfoBasedonRequestid(parentId, requestId)
      .then((res) => {
        if (res) {
          setRequestInfoObj(res);
        } else {
          setRequestInfoObj({});
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setRequestInfoDialog(!requestInfoDialog);
  };
  const requestStatusClick = (requestId) => {
    MainService.requestStatusBasedonRequestid(parentId,requestId)
      .then((res) => {
        if (res) {
          setRequestStatusObj(res);
        } else {
          setRequestStatusObj({});
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setRequestStatusDialog(!requestStatusDialog);
  };

  const roleUpgradationRequestClick = () => {
    getProfile(parentId, username);
    getAllRolesList(parentId);
    setConsumerUserDialog(!consumerUserDialog);
  };
  const ownRequestsHandler =()=>{
    getOwnRoleUpgradationRequests();
    setOwnRequestsView(true);
    setUsersRequestsView(false);
  }
  const usersRequestsHandler = ()=>{
    getUsersRoleUpgradationRequests();
    setOwnRequestsView(false);
    setUsersRequestsView(true);
  }
  return (
    <React.Fragment>
      <div className="container" id="mainContentList">
        <h2>Role Upgradation Requests</h2>
        {ownRequestsView &&
         <Fab
         color="primary"
         size={"large"}
         aria-label="add"
         title="Upgrade Role Request"
         className={classes.fab}
         onClick={() => roleUpgradationRequestClick()}
       >
         <AddIcon></AddIcon>
       </Fab>}
       
        <Grid>
        <div className="topHeadersWrapper">
            <div className="topHeaders">
                <Button
                  color="primary"
                  className="registerBtn"
                  variant="contained"
                  onClick={() => {
                    ownRequestsHandler();
                  }}
                >
                  Own Requests
                </Button>
              </div>
              &nbsp;&nbsp;
            <div className="topHeaders">
                <Button
                  color="primary"
                  className="registerBtn"
                  variant="contained"
                  onClick={() => {
                    usersRequestsHandler();
                  }}
                >
                  Users Requests
                </Button>
              </div>
              
              </div>
        </Grid>
        <Box sx={{ width: "100%", marginBottom: 30 }}>
          {(listRequetsView === true || gridRequetsView === true) && (
            <Grid>
              <div style={{ float: "right" }}>
                <Button
                  startIcon={<ListIcon />}
                  size="large"
                  onClick={listUsersViewClick}
                ></Button>
                <Button
                  startIcon={<GridIcon />}
                  size="large"
                  onClick={gridRequetsViewClick}
                ></Button>
              </div>
            </Grid>
          )}

          <br />
          {usersRequestsView &&
          <Grid container spacing={1}>
          {listRequetsView && (
            <Paper sx={{ width: "100%" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal) =>
                  requestSearchForUserRequests(searchVal)
                }
                onCancelSearch={() => cancelSearchForUserRequests()}
              />
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell
                        className="headerFontSize"
                        align="center"
                        colSpan={12}
                      >
                        List of Users Role Upgradation Requests
                      </TableCell>
                    </TableRow>
                    <TableRow align="center">
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Source
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        User Name
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Destination
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        User Type
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Request Type
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Role
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Request Id
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Timestamp
                      </TableCell>

                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={4}
                      >
                        Operate
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registerUserRequestsList.length > 0 ? (
                      registerUserRequestsList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((consumerUsers, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {consumerUsers.source}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.username}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.destination}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.usertype}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.requesttype}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.role}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.requestid}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.timestamp}
                              </TableCell>
                              <TableCell />
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    requestInfoClick(consumerUsers.requestid)
                                  }
                                >
                                  Request Info
                                </Button>
                              </TableCell>
                              {consumerUsers?.requesttype ===
                              "roleUpgradation" ? (
                                <>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() =>
                                        roleUpgradationClick(
                                          consumerUsers.usertype,
                                          consumerUsers.username,
                                          consumerUsers.requestid
                                        )
                                      }
                                    >
                                      Role Upgradation
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() =>
                                        roleUnMapClick(
                                          consumerUsers.usertype,
                                          consumerUsers.username
                                        )
                                      }
                                    >
                                      Role Unmap
                                    </Button>
                                  </TableCell>
                                </>
                              ) : (
                                <>
                                  <TableCell align="center"></TableCell>
                                </>
                              )}
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          Role Upgradation Requests Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={registerUserRequestsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          {gridRequetsView && (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div>
                    <SearchBar
                      value={searched}
                      onChange={(searchVal) =>
                        requestSearchForUserRequests(searchVal)
                      }
                      onCancelSearch={() => cancelSearchForUserRequests()}
                    />
                    <h4 style={{ textAlign: "center" }}>
                      List of Users Role Upgradation Requests
                    </h4>
                  </div>
                  <br />
                  <div>
                    {registerUserRequestsList.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {registerUserRequestsList.map(
                          (consumerUsers, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <p>
                                <b>Source:</b>
                                {consumerUsers.source}
                              </p>
                              <p>
                                <b>User Name:</b>
                                {consumerUsers.username}
                              </p>
                              <span>
                                <p>
                                  <b>Destination :</b>
                                  {consumerUsers.destination}
                                </p>
                              </span>
                              <p>
                                <b>User Type:</b>
                                {consumerUsers.usertype}
                              </p>
                              <p>
                                <b>Request Type:</b>
                                {consumerUsers.requesttype}
                              </p>
                              <p>
                                <b>Role:</b>
                                {consumerUsers.role}
                              </p>
                              <p>
                                <b>Request Id:</b>
                                {consumerUsers.requestid}
                              </p>
                              <p>
                                <b>Timestamp:</b>
                                {consumerUsers.timestamp}
                              </p>
                              &nbsp;
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  requestInfoClick(consumerUsers.requestid)
                                }
                              >
                                Request Info
                              </Button>
                              {consumerUsers?.requesttype ===
                                "roleUpgradation" && (
                                <div>
                                  <h4 className="cardactiontitle">Operate</h4>
                                  &nbsp;&nbsp;
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      roleUpgradationClick(
                                        consumerUsers.usertype,
                                        consumerUsers.username,
                                        consumerUsers.requestid
                                      )
                                    }
                                  >
                                    Role Upgradation
                                  </Button>
                                  &nbsp;&nbsp;
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      roleUnMapClick(
                                        consumerUsers.usertype,
                                        consumerUsers.username
                                      )
                                    }
                                  >
                                    Role Unmap
                                  </Button>
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <h4 style={{ textAlign: "center" }}>
                        Register Users Requets not found.
                      </h4>
                    )}
                  </div>
                </>
              )}
            </Box>
          )}
        </Grid>}
        {ownRequestsView &&
        <Grid container spacing={1}>
        {listRequetsView && (
          <Paper sx={{ width: "100%" }}>
            <SearchBar
              value={searched}
              onChange={(searchVal) =>
                requestSearchForUserRequests(searchVal)
              }
              onCancelSearch={() => cancelSearchForUserRequests()}
            />
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow align="center">
                    <TableCell
                      className="headerFontSize"
                      align="center"
                      colSpan={12}
                    >
                      List of Own Role Upgradation Requests
                    </TableCell>
                  </TableRow>
                  <TableRow align="center">
                    <TableCell
                      className="subHeaderFontSize"
                      align="center"
                      colSpan={1}
                    >
                      Source
                    </TableCell>
                    <TableCell
                      className="subHeaderFontSize"
                      align="center"
                      colSpan={1}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      className="subHeaderFontSize"
                      align="center"
                      colSpan={1}
                    >
                      Destination
                    </TableCell>
                    <TableCell
                      className="subHeaderFontSize"
                      align="center"
                      colSpan={1}
                    >
                      User Type
                    </TableCell>
                    <TableCell
                      className="subHeaderFontSize"
                      align="center"
                      colSpan={1}
                    >
                      Request Type
                    </TableCell>
                    <TableCell
                      className="subHeaderFontSize"
                      align="center"
                      colSpan={1}
                    >
                      Role
                    </TableCell>
                    <TableCell
                      className="subHeaderFontSize"
                      align="center"
                      colSpan={1}
                    >
                      Request Id
                    </TableCell>
                    <TableCell
                      className="subHeaderFontSize"
                      align="center"
                      colSpan={1}
                    >
                      Timestamp
                    </TableCell>

                    <TableCell
                      className="subHeaderFontSize"
                      align="center"
                      colSpan={3}
                    >
                      Operate
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ownRequestsList.length > 0 ? (
                    ownRequestsList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((consumerUsers, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell align="center">
                              {consumerUsers.source}
                            </TableCell>
                            <TableCell align="center">
                              {consumerUsers.username}
                            </TableCell>
                            <TableCell align="center">
                              {consumerUsers.destination}
                            </TableCell>
                            <TableCell align="center">
                              {consumerUsers.usertype}
                            </TableCell>
                            <TableCell align="center">
                              {consumerUsers.requesttype}
                            </TableCell>
                            <TableCell align="center">
                              {consumerUsers.role}
                            </TableCell>
                            <TableCell align="center">
                              {consumerUsers.requestid}
                            </TableCell>
                            <TableCell align="center">
                              {consumerUsers.timestamp}
                            </TableCell>
                            <TableCell />
                            <TableCell align="center">
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  requestInfoClick(consumerUsers.requestid)
                                }
                              >
                                Request Info
                              </Button>
                            </TableCell>
                            {consumerUsers?.requesttype ===
                            "roleUpgradation" ? (
                              <>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      requestStatusClick(
                                        consumerUsers.requestid
                                      )
                                    }
                                  >
                                    Request Status
                                  </Button>
                                </TableCell>
                               
                              </>
                            ) : (
                              <>
                                <TableCell align="center"></TableCell>
                              </>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow align="center">
                      <TableCell align="center" colSpan={12}>
                        Role Upgradation Requests Not Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={ownRequestsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
        {gridRequetsView && (
          <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
            {isLoading ? (
              <div className="cardStyleForDashboardItems">
                <CircularProgress />
              </div>
            ) : (
              <>
                <div>
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) =>
                      requestSearchForUserRequests(searchVal)
                    }
                    onCancelSearch={() => cancelSearchForUserRequests()}
                  />
                  <h4 style={{ textAlign: "center" }}>
                    List of Own Role Upgradation Requests
                  </h4>
                </div>
                <br />
                <div>
                  {ownRequestsList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {ownRequestsList.map(
                        (consumerUsers, index) => (
                          <div
                            key={index}
                            className="cardStyleForDashboardItemsBody"
                          >
                            <p>
                              <b>Source:</b>
                              {consumerUsers.source}
                            </p>
                            <p>
                              <b>User Name:</b>
                              {consumerUsers.username}
                            </p>
                            <span>
                              <p>
                                <b>Destination :</b>
                                {consumerUsers.destination}
                              </p>
                            </span>
                            <p>
                              <b>User Type:</b>
                              {consumerUsers.usertype}
                            </p>
                            <p>
                              <b>Request Type:</b>
                              {consumerUsers.requesttype}
                            </p>
                            <p>
                              <b>Role:</b>
                              {consumerUsers.role}
                            </p>
                            <p>
                              <b>Request Id:</b>
                              {consumerUsers.requestid}
                            </p>
                            <p>
                              <b>Timestamp:</b>
                              {consumerUsers.timestamp}
                            </p>
                            &nbsp;
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() =>
                                requestInfoClick(consumerUsers.requestid)
                              }
                            >
                              Request Info
                            </Button>
                            {consumerUsers?.requesttype ===
                              "roleUpgradation" && (
                              <div>
                                <h4 className="cardactiontitle">Operate</h4>
                                &nbsp;&nbsp;
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    requestStatusClick(
                                      consumerUsers.requestid
                                    )
                                  }
                                >
                                  Request Status
                                </Button>
                               
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <h4 style={{ textAlign: "center" }}>
                      Register Users Requets not found.
                    </h4>
                  )}
                </div>
              </>
            )}
          </Box>
        )}
      </Grid>}
          
        </Box>

        {/* Role Upgradation Request */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserDialog}
          onClose={handleClose}
          aria-labelledby="UpgradeRoleRequest"
        >
          <DialogTitle id="UpgradeRoleRequest">
            {"Role Upgradation Request"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">Consumer User Name * :</InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={username}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Role *:</InputLabel>
              <Select
                id="roleName"
                name="roleName"
                type="text"
                value={roleIdValue}
                onChange={(e) => {
                  setSelectedRoleHandler(e);
                }}
              >
                {allRolesList?.length > 0 &&
                  allRolesList?.map((roles, index) => (
                    <MenuItem key={`${index} `} value={roles.id}>
                      {roles.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                roleUpdationRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserDialog(!consumerUserDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/*  Map Role */}
        <Dialog
          fullScreen={fullScreen}
          open={roleMapDialog}
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby="mapRole"
        >
          <DialogTitle id="mapRole">{"Map Role"}</DialogTitle>
          <DialogContent>
            {/*  <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="consumerUserName1">
                Consumer User Name :
              </InputLabel>
              <Select
                id="consumerUserName1"
                name="consumerUserName1"
                type="text"
                 value={consumerUserIdValue}
                 onChange={(e) => {
                  getRoleHandler(e);
                  
                }}
              >
                {consumerUsersList.length > 0 &&
                  consumerUsersList.map((consumerUsers, index) => (
                    <MenuItem key={`${index} `} value={consumerUsers.userid}>
                      {consumerUsers.username}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="userName">UserName :</InputLabel>
              <Input
                id="userName"
                name="userName"
                type="text"
                value={selectedUserName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Existing Role :</InputLabel>
              <Input
                id="roleName"
                name="roleName"
                type="text"
                value={existingRoleName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Role Name :</InputLabel>
              <Select
                id="roleName"
                name="roleName"
                type="text"
                value={roleIdValue}
                onChange={(e) => {
                  setSelectedRoleHandler(e);
                }}
              >
                {allRolesList.length > 0 &&
                  allRolesList.map((roles, index) => (
                    <MenuItem key={`${index} `} value={roles.id}>
                      {roles.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Role Description :</InputLabel>
              <Input
                id="roleName"
                name="roleName"
                type="text"
                value={roleDescriptionValue}
                onChange={(e) => {
                  setRoleDescriptionValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserNameValue("");
                setConsumerUserIdValue("");
                roleMapSubmitClick(consumerUserIdValue);
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setRoleMapDialog(!roleMapDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/*  UnMap Role */}
        <Dialog
          fullScreen={fullScreen}
          open={roleUnMapDialog}
          fullWidth={true}
          aria-labelledby="unmapRole"
        >
          <DialogTitle id="unmapRole">{"UnMap Role"}</DialogTitle>
          <DialogContent>
            {/* <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="consumerUserName">
                Consumer User Name :
              </InputLabel>

              <Select
                id="consumerUserName"
                name="consumerUserName"
                type="text"
                value={consumerUserIdValue}
                onChange={(e) => {
                  getRoleHandler(e);
                  
                }}
              >
                {consumerUsersList.length > 0 &&
                  consumerUsersList.map((consumerUsers, index) => (
                    <MenuItem key={`${index} `} value={consumerUsers.userid}>
                      {consumerUsers.username}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="userName">UserName :</InputLabel>
              <Input
                id="userName"
                name="userName"
                type="text"
                value={selectedUserName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Existing Role :</InputLabel>
              <Input
                id="roleName"
                name="roleName"
                type="text"
                value={existingRoleName}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="roleName">Role Description :</InputLabel>
              <Input
                id="roleName"
                name="roleName"
                type="text"
                value={existingRoleDescription}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                roleUnMapSubmitClick(consumerUserIdValue);
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserNameValue("");
                setConsumerUserIdValue("");
                setRoleUnMapDialog(!roleUnMapDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Request Info */}
        <Dialog
          fullScreen={fullScreen}
          open={requestInfoDialog}
          fullWidth={true}
          aria-labelledby="requestInfo"
        >
          <DialogTitle id="requestInfo">{"Request Info"}</DialogTitle>
          <DialogContent>
          {Object.keys(requestInfoObj).length > 0 ? (
            Object.entries(requestInfoObj).map(([key, value]) => renderKeyValue(key, value))
          ) : (
            <p>No Data Found</p>
          )}
                      
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setRequestInfoDialog(!requestInfoDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Request Status */}
        <Dialog
          fullScreen={fullScreen}
          open={requestStatusDialog}
          fullWidth={true}
          aria-labelledby="requestInfo"
        >
          <DialogTitle id="requestInfo">{"Request Status"}</DialogTitle>
          <DialogContent>
          {Object.keys(requestStatusObj).length > 0 ? (
            Object.entries(requestStatusObj).map(([key, value]) => renderKeyValue(key, value))
          ) : (
            <p>No Data Found</p>
          )}
                      
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setRequestStatusDialog(!requestStatusDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
