import React, { Component } from "react";
import "./SiteUpdates.css";
import ReactPaginate from "react-paginate";
import $, { event } from "jquery";
import OutsideClickHandler from "react-outside-click-handler";
// import "../../Components/Config";
import { Row, Col } from "react-bootstrap";
import { FiThumbsUp } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { Container } from "@material-ui/core";

class SiteUpdates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumeruserparent: "consumer2",
      tenders: [],
      tendersFilter: [],
      offset: 0,
      tensdersPageCount: 1,
      perPage: 3,
      currentPage: 0,
      checked: false,
      postCategoryIdVlaue: "",
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      subCategoriesList: [],
      copyOfTenders: [],
      contentOwners:[],
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.postCategoryIdValue();
  }

  postCategoryIdValue() {
    fetch(
      `${window.appConfig.getContentCategories}${window.appConfig.Relam}${window.appConfig.Relam}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        const postid = result.categoryList.filter((data) => {
          return data.categoryname === "SITE-UPDATES";
        });
        this.viewAllSiteUpdates(postid[0].categoryid);
        this.setState({
          postCategoryIdVlaue: postid[0].categoryid,
        });
        this.adminsDropdownClick(postid[0].categoryid);
        this.getCategorySubcategories(postid[0].categoryid);
        this.ownerDropdownClick(postid[0].categoryid);

      })
      .catch((err) => console.log("err", err));
  }

  viewAllSiteUpdates(postid) {
    fetch(
      `${window.appConfig.getContentList}${window.appConfig.Relam}category/${postid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log(result, "farm updtaessssssssssssssss")
        this.setState({
          tenders: result.contentList,
          copyOfTenders: result.contentList,
          tensdersPageCount: Math.ceil(
            result.contentList.length / this.state.perPage
          ),
        });
      });
  }

  adminsDropdownClick(catID) {
    fetch(
      // `${window.appConfig.getAllContentOwnersBasedOnCategory}${catID}`,
      `${window.appConfig.getContentCreator}${window.appConfig.Relam}contentcreators/${catID}`,
       {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.InitialAccessToken,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const unique = [
          ...new Map(
            result.contentcreatorsList.map((data) => [data.contentcreatorid, data])
          ).values(),
        ];
        this.setState({
          tendersFilter: unique,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  organizationsDropdownClick() {
    $("#dropdownSite").toggle();
  }

  organizationsOnClick(Creator) {
    fetch(
      `${window.appConfig.getContentonContentCreator}${window.appConfig.Relam}contentcreator/${Creator}/${this.state.postCategoryIdVlaue}`,
      // `${window.appConfig.getContentList}${window.appConfig.Relam}contentowner/${Owner}/${this.state.postCategoryIdVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          tenders: result.contentList,
          tensdersPageCount: Math.ceil(
            result.contentList.length / this.state.perPage
          ),
        });
      })
      .catch((err) => console.log("err", err));
    $("#dropdownSite").hide();
  }

  getCategorySubcategories(catID) {
    fetch(
      `${window.appConfig.getSubCategoriesBasedonCategoryID}${window.appConfig.Relam}contentSubCategories/${catID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          subCategoriesList: result.contentsubcategories,
        });
      })
      .catch((err) => console.log("err", err));
  }

  subCategoriesDropdownClick() {
    $("#subCategoriesOfSiteUpdates").toggle();
  }

  subCategoriesOnClick(subCategory) {
    const subCategoriesOfAdsFilter = this.state.copyOfTenders.filter(
      (data, index) => {
        return data.contentsubcategory
          .toLowerCase()
          .includes(subCategory.toLowerCase());
      }
    );
    this.setState({
      tenders: subCategoriesOfAdsFilter,
      tensdersPageCount: Math.ceil(
        subCategoriesOfAdsFilter.length / this.state.perPage
      ),
    });
    $("#subCategoriesOfSiteUpdates").hide();
  }
  likeCount(contentid) {
    fetch(
      `${window.appConfig.contentLikeCount}${window.appConfig.Relam}${contentid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.text())
      .then((result) => {
        this.postCategoryIdValue();
        return NotificationManager.success(result);
      })
      .catch((err) => console.log("err", err));
  }


  // Content Owner
  ownerDropdownClick(catID) {
    fetch(
      `${window.appConfig.getAllContentOwnersBasedOnCategory}${catID}`, 
      // `${window.appConfig.getContentCreator}${window.appConfig.Relam}contentcreators/${catID}`,
      {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.InitialAccessToken,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        
        console.log(result, "qwertyuio1598598489489498494888o")
        const unique = [
          ...new Map(
            result.contentownerList.map((data) => [data.contentowner, data])
          ).values(),
        ];
        this.setState({
          contentOwners: unique,
        });
      })
      .catch((err) => console.log("err", err));
  };

  contentOwnerDropdownClick(){
    $("#ContentOwnerOfSiteUpdates").toggle();
  };

  getContentOwnerContent(Owner) {
    fetch(
      // `${window.appConfig.getContentonContentCreator}${window.appConfig.Relam}contentcreator/${Creator}/${this.state.postCategoryIdVlaue}`,
      `${window.appConfig.getContentList}${window.appConfig.Relam}contentowner/${Owner}/${this.state.postCategoryIdVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "setstarrrrrrrrrrrrrrrrrr")
        this.setState({
          tenders: result.contentList,
          tensdersPageCount: Math.ceil(
            result.contentList.length / this.state.perPage
          ),
        });
      })
      .catch((err) => console.log("err", err));
    $("#dropdownOrganizations").hide();
  };

  // Content Owner End
  /*Pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {}
    );
  };
  /*End of pagination */

  render() {
    const { tenders, subCategoriesList, contentOwners } = this.state;
    const siteUpdatesList = tenders.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    return (
      <Container
        id="tendersContainer"
        className="gbr-engage-container container-fluid mb-lg-5 mb-4 mt-4"
      >
        {/* <h3 class="container-title">Farm Updates</h3> */}

        <div className="mb-4">
          <div className="dropdown">
            <div className="navbarRight">
              <button
                class="btn btn-success homepageBtn"
                type="button"
                onClick={() =>
                  this.viewAllSiteUpdates(this.state.postCategoryIdVlaue)
                }
              >
                All Farm Update's
              </button>
            </div>
          </div>
          <div className="dropdown">
            <div class="dropdown homepage-nav-item">
              <div className="navbarRight">
                <button
                  class="btn btn-success homepageBtn"
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.organizationsDropdownClick()}
                >
                  Publisher
                </button>
              </div>
              <div
                class="dropdown-menu"
                id="dropdownSite"
                aria-labelledby="dropdownSite"
              >
                {this.state.tendersFilter &&
                  this.state.tendersFilter.map((filter, i) => (
                    <li>
                      <a
                        className="dropdown-item"
                        key={i}
                        role="button"
                        onClick={() =>
                          this.organizationsOnClick(filter.contentcreatorid)
                        }
                      >
                        {filter.contentcreatorid}
                      </a>
                    </li>
                  ))}
              </div>
            </div>
          </div>
          <div className="dropdown">
            <div class="dropdown homepage-nav-item">
              <div className="navbarRight">
                <button
                  class="btn btn-success homepageBtn"
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.subCategoriesDropdownClick()}
                >
                  Sub Categories
                </button>
              </div>
              <div
                class="dropdown-menu"
                id="subCategoriesOfSiteUpdates"
                aria-labelledby="subCategoriesOfSiteUpdates"
              >
                {subCategoriesList &&
                  subCategoriesList.map((filter, i) => (
                    <li>
                      <a
                        className="dropdown-item"
                        key={i}
                        role="button"
                        onClick={() =>
                          this.subCategoriesOnClick(
                            filter.contentsubcategoryname
                          )
                        }
                      >
                        {filter.contentsubcategoryname}
                      </a>
                    </li>
                  ))}
              </div>
            </div>
          </div>


          

          <div className="dropdown">
            <div class="dropdown homepage-nav-item">
              <div className="navbarRight">
                <button
                  class="btn btn-success homepageBtn"
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.contentOwnerDropdownClick()}
                >
                  Farm Name
                </button>
              </div>
              <div
                class="dropdown-menu"
                id="ContentOwnerOfSiteUpdates"
                aria-labelledby="ContentOwnerOfSiteUpdates"
              >
                {contentOwners &&
                  contentOwners.map((filter, i) => (
                    <li>
                      <a
                        className="dropdown-item"
                        key={i}
                        role="button"
                        onClick={() =>
                          this.getContentOwnerContent(
                            filter.contentowner
                          )
                        }
                      >
                        {filter.contentowner}
                      </a>
                    </li>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="adminTendersDiv">
          {/* View All siteUpdates */}
          {siteUpdatesList.length !== 0 ? (
            <Row className="justify-content-center">
              {/* {siteUpdatesList.map((tend, index) => (
                <div className="videosWrapper card">
                  <div class="cardContent">
                    <div class="card-body card-body-wrapper">
                      <p>
                        <b>Assetdeveloperid: </b>{tend.parentid}
                      </p>
                      <p><b>Content Id: </b>{tend.contentid}</p>
                      <p><b>Category Id: </b>{tend.categoryid}</p>
                      <p><b>Content Uploader: </b>{tend.contentcreator}</p>
                      <p><b>Category: </b>{tend.categoryname}</p>
                      <p><b>Organization: </b>{tend.contentowner}</p>
                      <p><b>Sub Categoryname: </b>{tend.contentsubcategory}</p>
                      <h6>{tend.contentdescription}</h6>
                      <img  className="img-thumbnail" src={tend.locationurl} width="100%" height="250" alt=""/>
                    </div>
                  </div>
                </div>
              ))} */}

              {siteUpdatesList.map((tend, index) => (
                <Col md={6} lg={4} sm={12} xs={12} className="mb-4">
                  <div class="community-cards w-100">
                    <div className="bordered-div"></div>
                    <div className="pb-2">
                      <div className="scrollable-container-mod scrollable-container">
                        <div class="community-card-body">
                          {/* <div className="mt-2 mb-2">
                            <span className="d-block">
                              {tend.contentsubcategory}
                            </span>
                            <span>{tend.timestamp}</span>
                          </div>
                       
                        <h2 className="mb-2">Posted by {tend.contentowner}</h2>
                        <h6 className="mb-3">{tend.contentdescription}</h6> */}

                          <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
                            <span className=" d-block ">
                              {tend.contentsubcategory}
                            </span>
                            <p className="products-like mb-0 d-flex align-items-end">
                              <span
                                className="d-inline-block"
                                onClick={() => {
                                  this.likeCount(tend.contentid);
                                }}
                              >
                                <FiThumbsUp size={30} />
                              </span>
                              <small>{tend.likecount}</small>
                            </p>
                          </div>
                          <h2 className="mb-0">{tend.contentname}</h2>

                          <h5 className="mb-2">
                            Posted by <span>{tend.contentcreatorid}</span>,{" "}
                            {tend.timestamp}
                          </h5>
                          <a className="text-decoration-none" href={tend.texturl} target="_blank"> <h6 className="mb-3">{tend.contentdescription}</h6>
                          </a>
                          {/* <p>
                          <b>Assetdeveloperid:</b> {tend.parentid}
                        </p>
                        <p>
                          <b>Content Id:</b> {tend.contentid}
                        </p>
                        <p>
                          <b>Category Id:</b> {tend.categoryid}
                        </p> */}
                          {/* <p>
                          <b>Content Uploader:</b> {tend.contentowner}
                        </p> */}
                          {/* <p>
                        <b>Category:</b> {tend.categoryname}
                      </p> */}
                          {/* <p>
                        <b>Organization:</b> {tend.contentowner}
                      </p> */}
                          {/* <p>
                          <b>Sub Categoryname:</b> {tend.contentsubcategory}
                        </p> */}

                          <img
                            className=" img-thumbnail"
                            src={tend.locationurl}
                            width="100%"
                            height="250"
                            alt=""
                          />

                          {/* <h6>{tend.contentdescription}</h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
              <div className="paginationComponent">
                <ReactPaginate
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.tensdersPageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </Row>
          ) : (
            <div className="card">
              <p className="card-body container-heading2">
                <b>Farm Update's Not Found</b>
              </p>
            </div>
          )}
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            $("#dropdownSite").hide();
            $("#subCategoriesOfSiteUpdates").hide();
            $("#ContentOwnerOfSiteUpdates").hide();

          }}
        ></OutsideClickHandler>
      </Container>
    );
  }
}

export default SiteUpdates;
