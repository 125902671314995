import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import img4 from "../../images/manufacturerBenefits/no-preservatives.svg";
import img5 from "../../images/brands-icon/expert.svg"
import img6 from "../../images/brands-icon/reliability.svg"
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function ManufacturersBenefits() {
  const benefits = [
    {
      img: img1,
      title: "Quality",
      bodycopy: "The company's strong commitment to quality guarantees the delivery of top-notch products to its customers",
    },
    {
      img: img2,
      title: "Organic",
      bodycopy: "Products grown and produced in harmony with nature through its organic approach",
    },
    {
      img: img3,
      title: "Traceability",
      bodycopy:
        "Embracing an organic approach ensures traceability, letting customers track their product's natural journey.",
    },
    {
      img: img4,
      title: "Chemical Preservative Free",
      bodycopy:
        "Products are free from chemical preservatives, ensuring a natural and healthy choice for customers.        ",
    },
    {
      img: img5,
      title: "Expertise",
      bodycopy:
        "Proficient in quality management, demonstrating expertise in maintaining high standards.",
    },
    {
      img: img6,
      title: "Reliability",
      bodycopy:
        "Dependable and trustworthy, ensuring consistent and reliable products.",
    },
  ];
  return (
    <div className=" ">
      <h2 className="text-center container-title mb-4">
        Brands Benefits
      </h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
