import React from "react";
import "./TechStack.css";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
export default function TechStack() {
  return (
    <>
      <div className="stack-banner mx-auto">
        <h1 className="text-center text-white mb-4">Our Tech Stack</h1>
        <Container className="text-center text-white d-flex align-items-center flex-column stack-container">
          <NavLink to="/ExploreTechnology">Explore APPs</NavLink>
          <NavLink to="/ExploreNetwork">Explore Network</NavLink>

          <NavLink to="/ExploreAPIs">Explore APIs</NavLink>
          <NavLink to="/ComingSoon">Explore 3rd Party Tech</NavLink>
        </Container>
      </div>
    </>
  );
}
