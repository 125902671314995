import React from "react";
import ClubMainPage from "../Club/ClubMainPage";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

export default function AgriBusinessCompany() {
  const userTypes = ["AssetDeveloper"];

  return (
    <>
      <UpdatesBanner
        className="socialMarketplace text-white"
        bannerText="Agri Asset Management Company"
      />
      <div className="subSectionWrapper">
        <ClubMainPage type={userTypes} />
      </div>
    </>
  );
}
