import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  MenuItem,
  Grid,
  Button,
  Container,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  accordion: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  container: {
    backgroundColor: "#f0f0f0",
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
}));

const BookingRequestForm = (props) => {
  const classes = useStyles();
  const [orderInfo, setOrderInfo] = useState({});
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
    

  // Fetch order info from API
  useEffect(() => {
    // Replace 'fetchOrderInfoFromAPI' with your actual API call function
    fetchOrderInfoFromAPI()
     
  }, []);

  // Function to fetch order info from API (Replace this with your actual API call function)
  const fetchOrderInfoFromAPI = async () => {
    MainService.getOrderInfo(
        props.orderId,
        parentId,
        username
    )
    .then((data) => {
        console.log("order info:", data);
        // Exclude orderId and orderStatus from the data
        const { orderId, orderStatus, ...filteredData } = data["requestDetails"];
        setOrderInfo(filteredData);
    })
    .catch((err) => {
        return NotificationManager.error(err);
    });
};

  
 
  // Handle form submission
  const handleSubmit = () => {
    // Combine all form data for submission
    const currentTimestamp = new Date().toISOString();
    const formData = {
      ...orderInfo,
      timeStamp: currentTimestamp,
    };
    // Replace this with your form submission logic
    MainService.sendBookingRequest(
      formData,
      "transport-asset-order-req",
      parentId,
      username
     )
       .then((data) => {
         return NotificationManager.success(data["orderId"]);
       })
       .catch((err) => {
         return NotificationManager.error(err);
       });
   
  };
  return (
    <Container className={classes.container}>
      <Typography variant="h6" gutterBottom style={{ textAlign: "center" }}>
        Booking Request Form
      </Typography>
      <div className={classes.root}>
        {orderInfo && (
          <>
            {/* RFQ/Proforma Invoice */}
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="rfq-proforma-invoice-content"
                id="rfq-proforma-invoice-header"
              >
                <Typography>RFQ/Proforma Invoice</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="RFQ ID"
                      value={orderInfo.rfqId}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Proforma Invoice ID"
                      value={orderInfo.proformaInvoiceId}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {/* Personal Details */}
            {orderInfo && orderInfo.personalDetails && (
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="personal-details-content"
                id="personal-details-header"
              >
                <Typography>Personal Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {Object.entries(orderInfo?.personalDetails).map(
                    ([key, value]) => (
                      <Grid item xs={12} sm={6} key={key}>
                        <TextField
                          label={key}
                          value={value}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            )}
            {/* Company Additional Information */}
            {orderInfo && orderInfo.companyAdditionalInformation && (
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="company-additional-info-content"
                id="company-additional-info-header"
              >
                <Typography>Company Additional Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {Object.entries(orderInfo.companyAdditionalInformation).map(
                    ([key, value]) => (
                      <Grid item xs={12} sm={6} key={key}>
                        <TextField
                          label={key}
                          value={value}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            )}
            {/* Delivery Address */}
            {orderInfo && orderInfo.deliveryAddress && (
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="delivery-address-content"
                id="delivery-address-header"
              >
                <Typography>Delivery Address</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {Object.entries(orderInfo.deliveryAddress).map(
                    ([key, value]) => (
                      <Grid item xs={12} sm={6} key={key}>
                        <TextField
                          label={key}
                          value={value}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            )}
            {/* Supplier Details */}
            {orderInfo && orderInfo.supplierDetails && (
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="supplier-details-content"
                id="supplier-details-header"
              >
                <Typography>Supplier Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {Object.entries(orderInfo.supplierDetails).map(
                    ([key, value]) => (
                      <Grid item xs={12} sm={6} key={key}>
                        <TextField
                          label={key}
                          value={value}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            )}
            {/* Pick-up Address */}
            {orderInfo && orderInfo.pickUpAddress && (
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pick-up-address-content"
                id="pick-up-address-header"
              >
                <Typography>Pick-up Address</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {Object.entries(orderInfo.pickUpAddress).map(
                    ([key, value]) => (
                      <Grid item xs={12} sm={6} key={key}>
                        <TextField
                          label={key}
                          value={value}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            )}
            {/* Asset Details */}
            {orderInfo && orderInfo.assetDetails && (
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="asset-details-content"
                id="asset-details-header"
              >
                <Typography>Asset Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {orderInfo.assetDetails.map((asset, index) => (
                    <Grid item xs={12} key={index}>
                      <Typography>{`Asset ${index + 1}`}</Typography>
                      <Grid container spacing={2}>
                        {Object.entries(asset).map(([key, value]) => (
                          <Grid item xs={12} sm={6} key={key}>
                            <TextField
                              label={key}
                              value={value}
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
            )}
            {/* Transport Details */}

            {orderInfo && orderInfo.transportDetails && (
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="transportDetails-content"
                id="transportDetails-header"
              >
                <Typography>Transport Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {Object.entries(orderInfo.transportDetails).map(
                    ([key, value]) => (
                      <Grid item xs={12} sm={6} key={key}>
                        <TextField
                          label={key}
                          value={value}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            )}
            {/* Requirements */}
            {orderInfo && orderInfo.requirements && (
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="requirements-content"
                id="requirements-header"
              >
                <Typography>Requirements</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {Object.entries(orderInfo.requirements).map(
                    ([key, value]) => (
                      <Grid item xs={12} sm={6} key={key}>
                        <TextField
                          label={key}
                          value={value}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            )}
            {/* Payment Details */}
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="payment-details-content"
                id="payment-details-header"
              >
                <Typography>Payment Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Payment Mode"
                      name="paymentMode"
                      value={orderInfo.paymentMode || ""}
                      fullWidth
                      
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Request Type"
                      name="requestType"
                      value={orderInfo.requestType || ""}
                      fullWidth
                      
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Total Amount"
                      name="totalAmount"
                      type="number"
                      value={orderInfo.totalAmount || ""}
                      fullWidth
                      
                    />
                  </Grid>
                </Grid>
               
              </AccordionDetails>
            </Accordion>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </Container>
  );
};

export default BookingRequestForm;
