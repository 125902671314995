import GridIcon from "@material-ui/icons/GridOnOutlined";
import ListIcon from "@material-ui/icons/List";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import ContentDynamicCard from "../../Common/CommonContent/ContentDynamicCard";
import ContentDynamicTable from "../../Common/CommonContent/ContentDynamicTable";
import { getCategoryID } from "../../Common/CommonContent/ContentedRelatedCommonFun";
import DeleteContent from "../../Common/CommonContent/DeleteContent";
import ViewSubcategories from "../../Common/CommonContent/ViewSubcategories";
import MainService from "../../Common/MainService";
import PlaceholderCardList from "../ModelProductManagement/PlaceHolderCardList";

const textSize = {
  sx: {
    textTransform: "none",
    background: "#3ECF8E",
    margin: "2px",
    width: { xs: "100%", sm: "70%", md: "auto" },
    "&:hover": {
      background: "#3ECF8E",
    },
  },
};

const dynamicKey = [
  "parentid",
  "contentid",
  "categoryid",
  "contentcreatorid",
  "categoryname",
  "contentowner",
  "contentsubcategory",
  "contentdescription",
  "locationurl",
];

const dynamicKeyTable = [
  "parentid",
  "contentid",
  "categoryid",
  "contentcreatorid",
  "categoryname",
  "contentowner",
  "contentsubcategory",
];

export default function Advertisement() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("ABN-ADS");
  const [subCategoryNameValue, setSubCategoryNameValue] = useState("");
  const [postList, setPostList] = useState([]);
  const [filteredPostList, setFilteredPostList] = useState([]);
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [deleteContentDialog, setDeleteContentDialog] = useState(false);
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  /* delete content */
  const [selectedContentId, setSelectedContentId] = useState("");
  const [getDeleteContentCreator, setDeleteContentCreator] = useState("");
  const [getDeleteContentCategory, setDeleteContentCategory] = useState("");
  // sub categories model condition
  const [openSubCategoryModel, closeSubCategoryModel] = useState(false);

  useMemo(async () => {
    await getCategoryID(selectedCategoryName)
      .then((data) => {
        setSelectedCategoryId(data);
      })
      .catch((err) => {
        return NotificationManager.error("something went wrong");
      });
  }, []);

  useEffect(() => {
    if (!selectedCategoryId) return;
    getSubCategories();
  }, [selectedCategoryId]);

  const getSubCategories = () => {
    MainService.getSubCategoriesForAD(username, selectedCategoryId)
      .then((data) => {
        setsubCategoriesList(data);
      })
      .catch((err) => {
        return NotificationManager.error("something went wrong");
      });
  };

  const getAllContentsList = () => {
    setIsLoading(true);
    MainService.getContentsBasedonContentCreator(
      username,
      selectedCategoryId,
      "contentowner"
    )
      .then((data) => {
        setPostList(data);
        setFilteredPostList(data);
        setIsLoading(false);
      })
      .catch((err) => {
        return NotificationManager.error("something went wrong");
      });
  };

  useMemo(() => {
    if (!selectedCategoryId) return;
    if (!username) return;
    getAllContentsList();
  }, [selectedCategoryId]);

  /* Delete Content */
  const deleteContentDialogClick = (post) => {
    if (post?.contentcreatorid === username) {
      setSelectedContentId(post.contentid);
      setDeleteContentCreator(post.contentcreatorid);
      setDeleteContentCategory(post.categoryid);
      setDeleteContentDialog(!deleteContentDialog);
    } else {
      return NotificationManager.error(
        "You can't delete other creator content"
      );
    }
  };

  const closeDeleteContent = () => {
    setDeleteContentDialog(!deleteContentDialog);
  };
  /* EOD Delete Content */

  const viewAllPostsClick = () => {
    getAllContentsList();
    setSubCategoryNameValue("");
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };

  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  const actions = [
    {
      label: "Delete",
      click: (item) => deleteContentDialogClick(item),
    },
  ];

  // view subcategories
  const subCategoriesHandler = () => {
    closeSubCategoryModel(!openSubCategoryModel);
  };

  return (
    <>
      <Box className="container" id="mainContentList">
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Button
            {...textSize}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              subCategoriesHandler();
            }}
          >
            Sub Categories
          </Button>
          <FormControl variant="outlined" className="postdropdown" size="small">
            <InputLabel
              style={{
                color: "rgb(43, 144, 99)",
                fontSize: "15px",
              }}
            >
              Sub Category Filter
            </InputLabel>
            <Select
              id="selectedSubCategory"
              value={subCategoryNameValue}
              label="Select Sub-Category"
              onChange={(e) => {
                setSubCategoryNameValue(e.target.value);
                const filteredRows = filteredPostList.filter((row) => {
                  return row.contentsubcategory
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase());
                });
                setPostList(filteredRows);
              }}
            >
              {subCategoriesList &&
                subCategoriesList.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj.contentsubcategoryname}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {subCategoryNameValue !== "" && (
            <Button
              variant="none"
              onClick={() => {
                viewAllPostsClick();
              }}
            >
              Clear Filter
            </Button>
          )}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={listViewClick}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={gridViewClick}
            ></Button>
          </Box>
        </Box>

        <Box className="MuiGrid-root">
          {listView &&
            (isLoading ? (
              <Box>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
            ) : (
              <ContentDynamicTable
                columns={dynamicKeyTable}
                data={postList}
                actions={actions}
              />
            ))}
          {gridView &&
            (isLoading ? (
              <PlaceholderCardList numberOfCards={4} heightVal={500} />
            ) : (
              <ContentDynamicCard
                dynamicKey={dynamicKey}
                data={postList}
                actions={actions}
              />
            ))}
        </Box>

        <DeleteContent
          reLoad={getAllContentsList}
          contentId={selectedContentId}
          contentCreator={getDeleteContentCreator}
          contentCategory={getDeleteContentCategory}
          onOpen={deleteContentDialog}
          onClose={closeDeleteContent}
        />

        <ViewSubcategories
          subCategories={subCategoriesList}
          modelShow={openSubCategoryModel}
          closeModel={subCategoriesHandler}
          reloadComponent={getSubCategories}
          userName={username}
          categoryId={selectedCategoryId}
          categoryName={selectedCategoryName}
          parentID={parentId}
          reloadContent={getAllContentsList}
          contentFormate="video/*"
        />
      </Box>
    </>
  );
}
