import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainService from "../../Common/MainService";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import "./ViewOperatorsComponent.css";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import SearchBar from "material-ui-search-bar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from "react-google-maps";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import { Card, CardContent, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";
import socketIOClient from "socket.io-client";
import Map from "../../Common/googleMap/Map";
import UserFeedbackComponent from "../../Common/Feedback/UserFeedbackComponent";
import UploadKYCDocumentsComponent from "../UploadKYCDocuments/UploadKYCDocumentsComponent";
import sha256 from "sha256";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function ViewOperatorsComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [setUsers, getUsers] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [roleResourcesList, setRoleResourcesList] = useState([]);
  const [roleResourceAssetsList, setRoleResourceAssetsList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [markers, setMarkers] = useState([]);

  /*Creator Operator */
  const [operatorsDialog, setOperatorsDialog] = useState(false);
  const [usernameValue, setUsernameValue] = useState("");
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [domainNameValue, setDomainNameValue] = useState("");
  const [userTypeValue, setUserTypeValue] = useState("Operator");
  const [email, setEmail] = useState("");
  const [phoneNumerValue, setPhoneNumerValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [cityNameValue, setCityNameValue] = useState("");
  const [stateNameValue, setStateNameValue] = useState("");
  const [nomineeValue, setNomineeValue] = useState("");
  const [pancardValue, setPancardValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  /*End Creator Operator */
  /*Delete Operator */
  const [operatorsDeleteDialog, setOperatorsDeleteDialog] = useState(false);
  const [selectedOperatorsName, setSelectedOperatorsName] = useState("");
  const [selectedOperatorsId, setSelectedOperatorsId] = useState("");
  /*End Of Delete Operator */
  /*Operator Password Reset*/
  const [operatorsResetPasswordDialog, setoperatorsResetPasswordDialog] =
    useState(false);
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [passwordConfirmationValue, setPasswordConfirmationValue] =
    useState("");
  /*End Of Operator Password Reset*/
  /*Operator Update*/
  const [operatorsUpdateDialog, setOperatorsUpdateDialog] = useState(false);
  const [operatorsUserId, setOperatorsUserId] = useState("");
  const [operatorsUserName, setOperatorsUserName] = useState("");
  const [operatorsFirstName, setOperatorsFirstName] = useState("");
  const [operatorsLastName, setOperatorsLastName] = useState("");
  const [operatorsEmail, setOperatorsEmail] = useState("");
  const [operatorsPhone, setOperatorsPhone] = useState("");
  const [operatorsAddress, setOperatorsAddress] = useState("");
  const [operatorsCity, setOperatorsCity] = useState("");
  const [operatorsState, setOperatorsState] = useState("");
  const [operatorsNominee, setOperatorsNominee] = useState("");
  const [operatorsPancard, setOperatorsPancard] = useState("");
  const [operatorsLongitude, setOperatorsLongitude] = useState("");
  const [operatorsLatitude, setOperatorsLatitude] = useState("");
  const [operatorsMessage, setOperatorsMessage] = useState("");
  const [operatorsUserType, setOperatorsUserType] = useState("");
  const [operatorsUserDomainName, setOperatorsUserDomainName] = useState("");
  /*End Of Operator Update*/
  /*Update Node Status */
  const [operatorsUpdateNodeStatusDialog, setOperatorsUpdateNodeStatusDialog] =
    useState(false);
  const [selectedNodeNameValue, setSelectedNodeNameValue] = useState("");
  const [nodeStatusValue, setNodeStatusValue] = useState("");
  /*End Of Update Node Status */
  /*Node Info */
  const [operatorsViewNodeInfoDialog, setOperatorsViewNodeInfoDialog] =
    useState(false);
  const [nodeUserName, setNodeUserName] = useState("");
  const [nodeUserNodeId, setNodeUserNodeId] = useState("");
  const [nodeUserAccountType, setNodeUserAccountType] = useState("");
  const [nodeUserUserType, setNodeUserUserType] = useState("");
  const [nodeUserAssetType, setNodeUserAssetType] = useState("");
  const [nodeUserNumberOfAssets, setNodeUserNumberOfAssets] = useState("");
  const [nodeUserAreaSize, setNodeUserAreaSize] = useState("");
  const [nodeUserGrazingType, setNodeUserGrazingType] = useState("");
  const [nodeUserFeedType, setNodeUserFeedType] = useState("");
  const [nodeUserNumberOfKids, setNodeUserNumberOfKids] = useState("");
  const [nodeUserMaleGenderNumber, setNodeUserMaleGenderNumber] = useState("");
  const [nodeUserFemaleGenderNumber, setNodeUserFemaleGenderNumber] =
    useState("");
  const [nodeUserOverallAge, setNodeUserOverallAge] = useState("");
  const [nodeUserBreed, setNodeUserBreed] = useState("");
  const [nodeUserFarmingExperience, setNodeUserFarmingExperience] =
    useState("");
  const [nodeUserNodeStatus, setNodeUserNodeStatus] = useState("");
  const [nodeUserContentUrl, setNodeUserContentUrl] = useState("");
  const [nodeUserNodeType, setNodeUserNodeType] = useState("");
  const [nodeUserAssetverticalId, setNodeUserAssetverticalId] = useState("");
  const [
    nodeUserAssetverticalFriendlyName,
    setNodeUserAssetverticalFriendlyName,
  ] = useState("");
  const [nodeUserFirstName, setNodeUserFirstName] = useState("");
  const [nodeUserLastName, setNodeUserLastName] = useState("");
  const [nodeUserEmail, setNodeUserEmail] = useState("");
  const [nodeUserPhone, setNodeUserPhone] = useState("");
  const [nodeUserAddress, setNodeUserAddress] = useState("");
  const [nodeUserCity, setNodeUserCity] = useState("");
  const [nodeUserState, setNodeUserState] = useState("");
  const [nodeUserDistrict, setNodeUserDistrict] = useState("");
  const [nodeUserPincode, setNodeUserPincode] = useState("");
  const [nodeUserLatitude, setNodeUserLatitude] = useState("");
  const [nodeUserLongitude, setNodeUserLongitude] = useState("");
  const [nodeUserTimestamp, setNodeUserTimestamp] = useState("");
  /*End Of Node Info */
  const [getType, setType] = useState("");

  /* Asset Live Monoitoring */

  const [ssetMonitoringStatus, setAssetMonitoringStatus] = useState("");
  const [marker_position, setmarker_position] = useState({
    lat: 0,
    lng: 0,
  });
  const [assetPostionList, setAssetPositionList] = useState({});
  const [ssetMonitorTimestamp, setAssetMonitorTimestamp] = useState("");
  const [assetMonitoringView, setAssetMonitoringView] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState("");
  /* Update Asset Status */
  const [selectedExistingStatus, setSelectedExistingStatus] = useState("");
  const [updateAssetStatusValue, setUpdateAssetStatusValue] = useState("");
  const [assetUpdateStatusPopup, setAssetUpdateStatusPopup] = useState("");
  /* View */
  const [listViewAssets, setListViewAssets] = useState(false);
  const [listViewSites, setListViewSites] = useState(false);
  const [gridViewAssets, setGridViewAssets] = useState(false);
  const [gridViewSites, setGridViewSites] = useState(false);
  const [gridViewSiteIssues, setGridViewSiteIssues] = useState(false);
  const [gridViewSiteReports, setGridViewSiteReports] = useState(false);
  const [gridViewAssetIssues, setGridViewAssetIssues] = useState(false);
  const [gridViewAssetAttendance, setGridViewAssetAttendance] = useState(false);
  const [gridViewAssetHealth, setGridViewAssetHealth] = useState(false);
  const [gridViewAssetReports, setGridViewAssetreports] = useState(false);
  const [gridViewAssetPatrol, setGridViewAssetPatrol] = useState(false);
  const [gridViewAssetFeedback, setGridViewAssetFeedback] = useState(false);
  const [gridViewAssetENV, setGridViewAssetENV] = useState(false);
  const [setListOpe, getListOpe] = useState(false);
  const [setGridOpe, getGridOpe] = useState(true);
  const [setMapOpe, getMapOpe] = useState(false);
  const [mapViewAssets, setMapViewAssets] = useState(false);
  const [mapViewSites, setMapViewSites] = useState(false);
  const [resourceId, setResourceId] = useState("");
  const [siteIssuesList, setSiteIssuesList] = useState([]);
  const [siteReportsList, setSiteReportsList] = useState([]);
  const [assetAttendanceOnj, setAssetAttendanceOnj] = useState({});
  const [assetHealthList, setAssetHealthList] = useState([]);
  const [assetIssuesList, setAssetIssuesList] = useState([]);
  const [assetAttendanceDialog, setAssetAttendanceDialog] = useState(false);
  const [resourceName, setResourceName] = useState("");
  const [assetReportsList, setAssetReportsList] = useState([]);
  const [assetPatrolList, setAssetPatrolList] = useState({});
  const [assetFeedbackList, setAssetFeedbackList] = useState([]);
  const [assetMortalityPopup, setAssetMortalityPopup] = useState(false);
  const [assetMortalityInfo, setAssetMortalityInfo] = useState({});
  const [assetEnvInfo, setAssetEnvInfo] = useState({});
  const [assetEnvPopup, setAssetEnvPopup] = useState(false);
  const [assetEnvData, setAssetEnvData] = useState({});
  const [siteEnvPopup, setSiteEnvPopup] = useState(false);
  const [siteEnvData, setSiteEnvData] = useState({});
  const [selectedSiteType, setSelectedSiteType] = useState("");
  const [assetPatrolPopup, setAssetPatrolPopup] = useState(false);
  const [assetHelathRecordInfoPopup, setAssetHelathRecordInfoPopup] =
    useState(false);
  const [assetHealthRecord, setAssetHealthRecord] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  /* Start Feedback */
  const [feedbackDialog, setFeedbackDialog] = useState(false);

  /* Feedback end */
  /* Agreent view and update status */
  const [viewSignedAgreementDialog, setViewSignedAgreementDialog] =
    useState(false);
  const [viewSignedDocumentList, setViewSignedDocumentList] = useState([]);
  const [AgreementsDocumentLink, setAgreementsDocumentLink] = useState(null);
  const [agreementDocumentStatus, setAgreementDocumentStatus] = useState("");
  const [updateAgreementStatusDialog, setUpdateAgreementStatusDialog] =
    useState(false);
  const [agreementStatusValue, setAgreementStatusValue] = useState("");
  const [selectedConsumerUserName, setSelectedConsumerUserName] = useState("");
  const [viewKYCDialog, setViewKYCDialog] = useState(false);
  /*End of  Agreent view and update status */

  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    getUsers(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    getUsers(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(() => {
    listOfOperators();
  }, [getUsers]);

  /* useEffect(() => {
    assetDataHandler(resourceId);
  }, [resourceId]); */

  useEffect(() => {
    return navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setmarker_position(pos);
      }
    );
  }, []);

  /* Pagination */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* End Pagination */

  const sitesListViewClick = () => {
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setListViewSites(true);
    setGridViewSites(false);
    setListViewAssets(false);
    setGridViewAssets(false);
    setGridViewSiteIssues(false);
    setAssetMonitoringView(false);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(false);
    setMapViewAssets(false);
    setMapViewSites(false);
    setGridViewAssetHealth(false);
    setGridViewAssetreports(false);
    setAssetMonitoringView(false);

    setGridViewAssetFeedback(false);
  };
  const sitesGridViewClick = () => {
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(true);
    setListViewSites(false);
    setMapViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetreports(false);
    setGridViewSiteReports(false);
    setMapViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewAssetHealth(false);
    setAssetMonitoringView(false);

    setGridViewAssetFeedback(false);
  };
  const sitesMapViewClick = () => {
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetreports(false);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(false);
    setMapViewSites(true);
    setGridViewAssetHealth(false);
    setAssetMonitoringView(false);

    setGridViewAssetFeedback(false);
  };
  const assetsListViewClick = (resourceId) => {
    setResourceId(resourceId);

    assetDataHandler(resourceId);
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setMapViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(true);
    setGridViewSiteIssues(false);
    setGridViewAssetreports(false);
    setGridViewSiteReports(false);
    setMapViewAssets(false);
    setGridViewAssetIssues(false);
    setGridViewAssetHealth(false);
    setAssetMonitoringView(false);

    setGridViewAssetFeedback(false);
  };
  const assetsGridViewClick = (resourceId) => {
    setResourceId(resourceId);

    assetDataHandler(resourceId);
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setMapViewSites(false);
    setGridViewAssets(true);
    setListViewAssets(false);
    setMapViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetreports(false);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(false);
    setGridViewAssetHealth(false);
    setAssetMonitoringView(false);

    setGridViewAssetFeedback(false);
  };
  const assetsMapViewClick = (resourceId) => {
    setResourceId(resourceId);

    assetDataHandler(resourceId);
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setMapViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetreports(false);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(false);
    setMapViewAssets(true);
    setGridViewAssetHealth(false);
    setAssetMonitoringView(false);

    setGridViewAssetFeedback(false);
  };
  const backToHome = () => {
    getListOpe(false);
    getGridOpe(true);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setMapViewAssets(false);
    setMapViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetreports(false);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(false);
    setMapViewAssets(false);
    setGridViewAssetHealth(false);
    setAssetMonitoringView(false);

    setGridViewAssetFeedback(false);
  };
  const backToSites = () => {
    setResourceId("");
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setMapViewAssets(false);
    setMapViewSites(false);
    setGridViewSites(true);
    setListViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetreports(false);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(false);
    setMapViewAssets(false);
    setGridViewAssetHealth(false);
    setAssetMonitoringView(false);

    setGridViewAssetFeedback(false);
    setMarkers([]);
  };
  const backToAssets = () => {
    setMarkers([]);
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setMapViewAssets(false);
    setMapViewSites(false);
    setGridViewAssets(true);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetreports(false);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(false);
    setMapViewAssets(false);
    setGridViewAssetHealth(false);
    setAssetMonitoringView(false);

    setGridViewAssetFeedback(false);
    setMarkers([]);
  };
  const listOfOperators = (Type) => {
    setType(Type);
    if (Type === "List") {
      getListOpe(true);
      getGridOpe(false);
      getMapOpe(false);
      setGridViewSites(false);
      setListViewSites(false);
      setMapViewSites(false);
      setGridViewAssets(false);
      setListViewAssets(false);
      setGridViewSiteIssues(false);
      setGridViewAssetreports(false);
      setGridViewSiteReports(false);
      setGridViewAssetIssues(false);
      setMapViewAssets(false);
      setGridViewAssetHealth(false);
      setAssetMonitoringView(false);

      setGridViewAssetFeedback(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(true);
      getMapOpe(false);
      setGridViewSites(false);
      setListViewSites(false);
      setMapViewSites(false);
      setGridViewAssets(false);
      setListViewAssets(false);
      setGridViewSiteIssues(false);
      setGridViewAssetreports(false);
      setGridViewSiteReports(false);
      setGridViewAssetIssues(false);
      setMapViewAssets(false);
      setGridViewAssetHealth(false);
      setAssetMonitoringView(false);

      setGridViewAssetFeedback(false);
    } else if (Type === "Map") {
      getListOpe(false);
      getGridOpe(false);
      getMapOpe(true);
      setGridViewSites(false);
      setListViewSites(false);
      setMapViewSites(false);
      setGridViewAssets(false);
      setListViewAssets(false);
      setGridViewSiteIssues(false);
      setGridViewAssetreports(false);
      setGridViewSiteReports(false);
      setGridViewAssetIssues(false);
      setMapViewAssets(false);
      setGridViewAssetHealth(false);
      setAssetMonitoringView(false);

      setGridViewAssetFeedback(false);
    }

    MainService.viewListOfOperators(username)
      .then((res) => {
        getUsers(res.users);
        getFilterUsers(res.users);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const siteIssuesClick = (siteId) => {
    setResourceId(siteId);

    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setMapViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(true);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(false);
    setMapViewAssets(false);

    setGridViewAssetFeedback(false);
    MainService.getSiteIssueData(username, siteId)
      .then((res) => {
        console.log("site issues data", res);
        if (res !== undefined) {
          if (res.length > 0) {
            setSiteIssuesList(res);
          } else setSiteIssuesList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const siteReportsClick = (siteId) => {
    setResourceId(siteId);
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setMapViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewAssetreports(false);
    setGridViewSiteReports(true);
    setGridViewAssetIssues(false);
    setMapViewAssets(false);
    setGridViewAssetHealth(false);
    setAssetMonitoringView(false);

    setGridViewAssetFeedback(false);

    MainService.dailyReportDetails(resourceName, siteId)
      .then((res) => {
        if (res !== undefined) {
          if (res.length > 0) {
            setSiteReportsList(res);
          } else setSiteReportsList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const assetAttenanceClick = (assetId) => {
    MainService.getAttendanceLocationInfo(assetId)
      .then((res) => {
        console.log("attendance  innfo:", res);
        setAssetAttendanceOnj(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAssetAttendanceDialog(!assetAttendanceDialog);
  };

  const assetIssuesClick = (assetId) => {
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setMapViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(true);
    setMapViewAssets(false);
    setGridViewAssetreports(false);
    setGridViewAssetHealth(false);
    setAssetMonitoringView(false);

    setGridViewAssetFeedback(false);

    MainService.getAssetIssues(username, resourceId, assetId)
      .then((res) => {
        if (res !== undefined) {
          if (res.length > 0) {
            setAssetIssuesList(res);
          } else setAssetIssuesList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* const assetHealthClick = (assetId) => {
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setMapViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(false);
    setMapViewAssets(false);
    setGridViewAssetreports(false);
    setGridViewAssetHealth(true);
    setAssetMonitoringView(false);

    setGridViewAssetFeedback(false);

    MainService.getHealthStatusBasedOnAssetId(username)
      .then((res) => {
        console.log("asset health:", res);
        if (res !== undefined) {
          if (res.length > 0) {
            setAssetHealthList(res);
          } else setAssetHealthList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  }; */
  const assetHealthClick = (siteId, assetid) => {
    healthRecordsHandler(assetid);
    setAssetHelathRecordInfoPopup(!assetHelathRecordInfoPopup);
  };

  const healthRecordsHandler = (assetid) => {
    setIsLoading(true);
    MainService.getAssetHealthInfo(assetid)
      .then((res) => {
        console.log("asset health info:", res);
        setIsLoading(false);
        setAssetHealthRecord(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const assetReportsClick = (assetId) => {
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setMapViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(false);
    setMapViewAssets(false);
    setGridViewAssetreports(true);
    setGridViewAssetHealth(false);

    setGridViewAssetFeedback(false);

    MainService.dailyReportDetails(resourceName, assetId)
      .then((res) => {
        console.log("asset Reorts by opr:", res);
        if (res !== undefined) {
          if (res.length > 0) {
            setAssetReportsList(res);
          } else setAssetReportsList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const assetPatrolClick = (assetId) => {
    setAssetPatrolPopup(!assetPatrolPopup);

    MainService.getAssetPatrollingDetails(assetId)
      .then((res) => {
        console.log("asset patrol:", res);
        if (res !== undefined) {
          setAssetPatrolList(res);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const assetFeedbackClick = (assetId) => {
    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setMapViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(false);
    setMapViewAssets(false);
    setGridViewAssetreports(false);
    setGridViewAssetHealth(false);

    setGridViewAssetFeedback(true);

    MainService.getAssetFeedback(username, assetId)
      .then((res) => {
        console.log("asset Feedback:", res);
        if (res !== undefined) {
          if (res.length > 0) {
            setAssetFeedbackList(res);
          } else setAssetFeedbackList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const assetEnvClick = (assetId, deviceType) => {
    setAssetEnvData({});
    MainService.getAssetEnvInfo(parentId, username, assetId, deviceType)
      .then((res) => {
        console.log("asset env data:", res);
        if (Object.keys(res).length === 0) {
          setAssetEnvData({});
        } else setAssetEnvData(res);
      })
      .catch((e) => {
        return NotificationManager.error("Env data not found");
      });

    /* console.log("socket url:", window.socketUrl);
    const ENDPOINT = window.liveEnvUrl;
    const socket = socketIOClient(ENDPOINT);
    console.log("socket :", socket);
    socket.on("livedata", (data) => {
      console.log("from api data:", data);
      if(data.length>0)
      {
        setAssetEnvData(data);
      }else{
        setAssetEnvData({});
      }
      
    }); */
    setAssetEnvPopup(!assetEnvPopup);
  };
  const siteEnvClick = (siteid) => {
    MainService.siteInfo(siteid)
      .then((res) => {
        setSelectedSiteType(res?.subdivision);
        if (res?.subdivision !== undefined) {
          console.log("selectedSiteType", selectedSiteType);
          MainService.getSiteEnvInfo(parentId, username, siteid, res.selectedSiteType)
            .then((res) => {
              console.log("live Site Env monitor:", res);
              setSiteEnvData(res);
            })
            .catch((e) => {
              return NotificationManager.error("Env not found");
            });
        }else{
          return NotificationManager.error("Sitetype not found");
        }
      })
      .catch((e) => {
        NotificationManager.error(e);
      });

    
/* need to implement later */
    /* console.log("socket url:", window.socketUrl);
    const ENDPOINT = window.liveEnvUrl;
    const socket = socketIOClient(ENDPOINT);
    console.log("socket :", socket);
    socket.on("livedata", (data) => {
      console.log("from api data:", data);
      setSiteEnvData(data);
    }); */
    setSiteEnvPopup(!siteEnvPopup);
  };
  const assetMortalityClick = (assetId) => {
    MainService.getMortalityInfo(assetId)
      .then((res) => {
        console.log("getMortalityInfo:", res);
        setAssetMortalityInfo(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAssetMortalityPopup(!assetMortalityPopup);
  };
  const assetUpdateStatusClick = (assetId, status) => {
    setSelectedAssetId(assetId);
    setSelectedExistingStatus(status);
    setAssetUpdateStatusPopup(!assetUpdateStatusPopup);
  };
  const assetUpdateStatusSubmitClick = () => {
    const assetStatusData = {
      status: updateAssetStatusValue,
    };
    const assetStatusDataStringify = JSON.stringify(assetStatusData);

    MainService.assetStatusUpdate(selectedAssetId, assetStatusDataStringify)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setAssetUpdateStatusPopup(!assetUpdateStatusPopup);
  };
  const roleNameOfOperator = (name, Type) => {
    setResourceName(name);
    MainService.getRole(username, name)
      .then((res) => {
        getOperatorRoles(res.role, Type);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const getOperatorRoles = (role, Type) => {
    setType(Type);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      getMapOpe(false);
      setGridViewSites(false);
      setListViewSites(true);
      setMapViewSites(false);
      setGridViewAssets(false);
      setListViewAssets(false);
      setGridViewSiteIssues(false);

      setGridViewAssetIssues(false);
      setMapViewAssets(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      getMapOpe(false);
      setGridViewSites(true);
      setListViewSites(false);
      setMapViewSites(false);
      setGridViewAssets(false);
      setListViewAssets(false);
      setGridViewSiteIssues(false);

      setGridViewAssetIssues(false);
      setMapViewAssets(false);
    } else if (Type === "Map") {
      getListOpe(false);
      getGridOpe(false);
      getMapOpe(false);
      setGridViewSites(false);
      setListViewSites(false);
      setMapViewSites(true);
      setGridViewAssets(false);
      setListViewAssets(false);
      setGridViewSiteIssues(false);
      setGridViewAssetIssues(false);
      setMapViewAssets(false);
    }
    MainService.getRoleResourceId(role, username)
      .then((res) => {
        if (res !== undefined) {
          if (res.length > 0) {
            setRoleResourcesList(res);
          } else {
            setRoleResourcesList([res]);
          }
        }
      })
      .catch((err) => {
        return NotificationManager.error("err");
      });
  };

  const assetDataHandler = (resourceId) => {
    MainService.getRoleResourceAssets(username, resourceId)
      .then((res) => {
        console.log("role resources assets:", res);
        if (res !== undefined) {
          if (res.length > 0) {
            setRoleResourceAssetsList(res);
          } else {
            setRoleResourceAssetsList([]);
          }
        }
      })
      .catch((err) => {
        return NotificationManager.error("err");
      });
  };

  /* View info */
  const viewInfoClick = (operatorsName) => {
    MainService.getConsumerInfoRequest(operatorsName, username)
      .then((res) => {
        setNodeUserName(res.requestInfo[0].username);
        setNodeUserNodeId(res.requestInfo[0].nodeid);
        setNodeUserAccountType(res.requestInfo[0].accounttype);
        setNodeUserUserType(res.requestInfo[0].usertype);
        setNodeUserAssetType(res.requestInfo[0].assettype);
        setNodeUserNumberOfAssets(res.requestInfo[0].numberofassets);
        setNodeUserAreaSize(res.requestInfo[0].areasize);
        setNodeUserGrazingType(res.requestInfo[0].grazingtype);
        setNodeUserFeedType(res.requestInfo[0].feedtype);
        setNodeUserNumberOfKids(res.requestInfo[0].numberofkids);
        setNodeUserMaleGenderNumber(res.requestInfo[0].malegendernumber);
        setNodeUserFemaleGenderNumber(res.requestInfo[0].femalegendernumber);
        setNodeUserOverallAge(res.requestInfo[0].overallage);
        setNodeUserBreed(res.requestInfo[0].breed);
        setNodeUserFarmingExperience(res.requestInfo[0].farmingexperience);
        setNodeUserNodeStatus(res.requestInfo[0].nodestatus);
        setNodeUserContentUrl(res.requestInfo[0].contenturl);
        setNodeUserNodeType(res.requestInfo[0].nodetype);
        setNodeUserAssetverticalId(res.requestInfo[0].assetverticalid);
        setNodeUserAssetverticalFriendlyName(
          res.requestInfo[0].assetverticalfriendlyname
        );
        setNodeUserFirstName(res.requestInfo[0].firstname);
        setNodeUserLastName(res.requestInfo[0].lastname);
        setNodeUserEmail(res.requestInfo[0].email);
        setNodeUserPhone(res.requestInfo[0].phone);
        setNodeUserAddress(res.requestInfo[0].address);
        setNodeUserCity(res.requestInfo[0].city);
        setNodeUserState(res.requestInfo[0].state);
        setNodeUserDistrict(res.requestInfo[0].district);
        setNodeUserPincode(res.requestInfo[0].pincode);
        setNodeUserLatitude(res.requestInfo[0].latitude);
        setNodeUserLongitude(res.requestInfo[0].longitude);
        setNodeUserTimestamp(res.requestInfo[0].timestamp);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setOperatorsViewNodeInfoDialog(!operatorsViewNodeInfoDialog);
  };
  /*************** Create, Update, Delete, NodeStatus, Node Info Operator ***************/

  const createoperatorssubmit = () => {
    setIsLoading(true);
    let operatorsData = {
      username: usernameValue,
      firstname: firstNameValue,
      lastname: lastNameValue,
      domain: username,
      usertype: userTypeValue,
      phone: phoneNumerValue,
      address: addressValue?.replace(/\n/g, ''),
      city: cityNameValue,
      state: stateNameValue,
      nominee: nomineeValue,
      pancard: pancardValue,
      longitude: longitudeValue,
      latitude: latitudeValue,
      message: messageValue?.replace(/\n/g, ''),
      email: email,
    };
    const operatorsDataString = JSON.stringify(operatorsData);
    if (usernameValue && firstNameValue && lastNameValue && phoneNumerValue) {
      if (usernameValue === email) {
        MainService.createConsumerUserOfIndividual(
          operatorsDataString,
          username,
          usernameValue,
          email
        )
          .then((res) => {
            setIsLoading(false);
            listOfOperators(getType);
            return NotificationManager.success(res);
          })
          .catch((err) => {
            setIsLoading(false);
            return NotificationManager.error(err);
          });
      } else {
        return NotificationManager.error("username and email should be same");
      }
    } else {
      setIsLoading(false);
      return NotificationManager.error("Please fill all required fields");
    }
    setOperatorsDialog(!operatorsDialog);
  };
  const deleteSubmitClick = () => {
    MainService.deleteConsumerUserIndividual(
      username,
      selectedOperatorsName,
      selectedOperatorsId
    )
      .then((res) => {
        listOfOperators(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setOperatorsDeleteDialog(!operatorsDeleteDialog);
  };

  const deleteDialogClick = (operatorsName, operatorsId) => {
    setSelectedOperatorsName(operatorsName);
    setSelectedOperatorsId(operatorsId);

    setOperatorsDeleteDialog(!operatorsDeleteDialog);
  };

  const resetPasswordDialogClick = (operatorsId) => {
    setSelectedOperatorsId(operatorsId);
    setoperatorsResetPasswordDialog(!operatorsResetPasswordDialog);
  };

  const resetPasswordSubmit = () => {
    if (newPasswordValue === "") {
      return NotificationManager.error("please provide password");
    }
    if (newPasswordValue === passwordConfirmationValue) {
      let resetPwdData = {
        type: "password",
        value: sha256(newPasswordValue),
        temporary: false,
      };
      MainService.resetConsumerUserPasswordIndividual(
        resetPwdData,
        username,
        selectedOperatorsId
      )
        .then((res) => {
          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
      setoperatorsResetPasswordDialog(!operatorsResetPasswordDialog);
    } else {
      return NotificationManager.error(
        " Confirmation Password is not match with New password"
      );
    }
  };

  const operatorsUpdateDialogClick = (operators) => {
    setSelectedOperatorsName(operators.username);
    setSelectedOperatorsId(operators.userid);

    setOperatorsUserId(operators.userid);
    setOperatorsUserName(operators.username);
    setOperatorsFirstName(operators.firstname);
    setOperatorsLastName(operators.lastname);
    setOperatorsEmail(operators.email);
    setOperatorsPhone(operators.phone);
    setOperatorsAddress(operators.address);
    setOperatorsCity(operators.city);
    setOperatorsState(operators.state);
    setOperatorsNominee(operators.nominee);
    setOperatorsPancard(operators.pancard);
    setOperatorsLongitude(operators.longitude);
    setOperatorsLatitude(operators.latitude);
    setOperatorsMessage(operators.message);
    setOperatorsUserType(operators.usertype);
    setOperatorsUserDomainName(operators.domain);

    setOperatorsUpdateDialog(!operatorsUpdateDialog);
  };

  const operatorsUpdateSubmit = () => {
    let operatorsUpdateData = {
      firstname: operatorsFirstName,
      lastname: operatorsLastName,
      phone: operatorsPhone,
      address: operatorsAddress?.replace(/\n/g, ''),
      city: operatorsCity,
      state: operatorsState,
      nominee: operatorsNominee,
      pancard: operatorsPancard,
      longitude: operatorsLongitude,
      latitude: operatorsLatitude,
      message: operatorsMessage?.replace(/\n/g, ''),
      email: operatorsEmail,
    };
    const operatorsUpdateDataString = JSON.stringify(operatorsUpdateData);
    MainService.updateConsumerUserInfoIndividual(
      username,
      operatorsUpdateDataString,
      selectedOperatorsId,
      selectedOperatorsName
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setOperatorsUpdateDialog(!operatorsUpdateDialog);
  };

  /*Start of Feedback */
  const feedbackDialogClick = (selectedUserName) => {
    setFeedbackDialog(!feedbackDialog);
    setSelectedOperatorsName(selectedUserName);
    getUserType(selectedUserName);
  };
  const closeFeedbackDialog = () => {
    setFeedbackDialog(!feedbackDialog);
  };
  const getUserType = (currentuser) => {
    MainService.getConsumerUserType(username, currentuser)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /* end of Feedback */

  /*************** End Of Create, Update, Delete, NodeStatus, NodeInfo Operator ***************/

  /* Sigend Agreemnts view and update status */
  const viewKYCClick = (user) => {
    setSelectedConsumerUserName(user);
    setViewKYCDialog(!viewKYCDialog);
  };
  const viewSignedAgreementClick = (user, from) => {
    MainService.getContentsBasedonCreator( user, "Ep2AUBOYK0")
      .then((res) => {
        if (res.length > 0) {
          setAgreementsDocumentLink(res[0].texturl);
          //setAgreementDocumentStatus(res[0].documentstatus);
          setIsLoading(false);
        } else {
          console.log("setViewSignedDocumentList", res);
          setAgreementsDocumentLink(null);
          //setAgreementDocumentStatus("");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setIsLoading(false);
    if (from !== "fromupdatebtn") {
      setViewSignedAgreementDialog(!viewSignedAgreementDialog);
    }
  };
  const updateAgreementStatusClick = (user) => {
    viewSignedAgreementClick(user, "fromupdatebtn");
    setSelectedConsumerUserName(user);
    setUpdateAgreementStatusDialog(!updateAgreementStatusDialog);
  };
  const updateAgreementStatusHandler = () => {
    MainService.updateDocumentStatus(
      agreementStatusValue,
      username,
      selectedConsumerUserName,
      "OperatorAgreementDocument"
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setUpdateAgreementStatusDialog(!updateAgreementStatusDialog);
  };
  /* End of Agreemnet view and update status */
  /*************** Operators Map *************/
  const OperatorsGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={setUsers.index}
        defaultZoom={13}
        defaultCenter={marker_position}
        center={marker_position}
      >
        {setUsers.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <button
                  type="button"
                  onClick={() => {
                    roleNameOfOperator(value.username, "Map");
                  }}
                >
                  <div>{value.username}</div>
                </button>
              </InfoWindow>
            </Marker>
          ) : (
            ""
          );
        })}

        {/* <Polyline path={props.polyPath} defaultZoom={2} editable={false} /> */}
      </GoogleMap>
    );
  });

  const AssetsGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={roleResourceAssetsList.index}
        defaultZoom={13}
        defaultCenter={marker_position}
        center={marker_position}
      >
        {roleResourceAssetsList.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <div>{value.assetType}</div>
              </InfoWindow>
            </Marker>
          ) : (
            ""
          );
        })}

        {/* <Polyline path={props.polyPath} defaultZoom={2} editable={false} /> */}
      </GoogleMap>
    );
  });
  /*************** End Of Operators Map *************/

  /* Asset Live Monitoring */

  const assetMonitoringHandler = (siteId, assetId, lat1, lng1) => {
    setSelectedAssetId(assetId);

    getListOpe(false);
    getGridOpe(false);
    getMapOpe(false);
    setGridViewSites(false);
    setListViewSites(false);
    setMapViewSites(false);
    setGridViewAssets(false);
    setListViewAssets(false);
    setGridViewSiteIssues(false);
    setGridViewSiteReports(false);
    setGridViewAssetIssues(false);
    setMapViewAssets(false);
    setGridViewAssetreports(false);
    setGridViewAssetHealth(false);
    setAssetMonitoringView(true);

    setGridViewAssetFeedback(false);

    MainService.assetCurrentLocation(parentId, username, assetId)
      .then((res) => {
        console.log("current Location monitor:", res);
        setSelectedAssetId(res.assetId);
        setAssetMonitoringStatus(res.status);
        setMarkers([...markers, { geoLocation: res.geoLocation }]);
        setAssetMonitorTimestamp(res.ts);

        if (marker_position !== undefined) {
          const ENDPOINT = window.socketUrl;
          const socket = socketIOClient(ENDPOINT);
          socket.on(
            `tt/solution/${username}/site/${siteId}/asset/${assetId}/LOC`,
            (data) => {
              console.log(JSON.parse(data.payload).geoLocation);
              setMarkers([
                ...markers,
                { geoLocation: JSON.parse(data.payload).geoLocation },
              ]);
            }
          );
          return () => {
            socket.disconnect();
          };
        }
      })
      .catch((e) => {
        return NotificationManager.error("location not found");
      });
  };

  /* End of Asset Monitoring */

  const getLocationHandler = (props) => {
    console.log(props);
  };

  return (
    <React.Fragment>
      <div className="container" id="mainContentList">
        {(setListOpe || setGridOpe || setMapOpe) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Operator "
            className={classes.fab}
            onClick={() => {
              setOperatorsDialog(!operatorsDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        <div className="topHeadersWrapper">
          {(setListOpe || setGridOpe || setMapOpe) && (
            <div className="topHeaders">
              <Button
                startIcon={<ListIcon />}
                size="large"
                onClick={() => {
                  listOfOperators("List");
                }}
              ></Button>
              <Button
                startIcon={<GridIcon />}
                size="large"
                onClick={() => {
                  listOfOperators("Grid");
                }}
              ></Button>
              <Button
                startIcon={<MapIcon />}
                size="large"
                onClick={() => {
                  listOfOperators("Map");
                }}
              ></Button>
            </div>
          )}

          {(listViewSites || gridViewSites || mapViewSites) && (
            <div className="topHeadersWrapper">
              <div className="topHeaders">
                <Button
                  startIcon={<ListIcon />}
                  size="large"
                  onClick={sitesListViewClick}
                ></Button>
                <Button
                  startIcon={<GridIcon />}
                  size="large"
                  onClick={sitesGridViewClick}
                ></Button>
                <Button
                  startIcon={<MapIcon />}
                  size="large"
                  onClick={sitesMapViewClick}
                ></Button>
              </div>
            </div>
          )}
        </div>
        {/* Operators */}
        {setListOpe === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
            {setUsers.length > 0 ? (
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={13}>
                          List Of Operators
                        </TableCell>
                      </TableRow>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          User Id
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          User Name
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Email
                        </TableCell>
                        <TableCell align="center" colSpan={3}>
                          Option
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          Operate
                        </TableCell>
                        <TableCell align="center" colSpan={3}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {setUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((operators, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {operators.userid}
                              </TableCell>
                              <TableCell align="center">
                                {operators.username}
                              </TableCell>
                              <TableCell align="center">
                                {operators.email}
                              </TableCell>
                              
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    viewInfoClick(operators.username);
                                  }}
                                >
                                  View Info
                                </Button>
                              </TableCell>
                              {/*<TableCell align="center">
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    viewSignedAgreementClick(
                                      operators.username
                                    );
                                  }}
                                >
                                  View Signed Agreements
                                </Button>
                              </TableCell>
                               <TableCell>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    viewKYCClick(operators.username);
                                  }}
                                >
                                  View KYC
                                </Button>
                              </TableCell> */}
                              <TableCell/>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    resetPasswordDialogClick(operators.userid);
                                  }}
                                >
                                  Reset Password
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    operatorsUpdateDialogClick(operators);
                                  }}
                                >
                                  Update
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    deleteDialogClick(
                                      operators.username,
                                      operators.userid
                                    );
                                  }}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                              <TableCell/>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    feedbackDialogClick(operators.username);
                                  }}
                                >
                                  Feedback
                                </Button>
                              </TableCell>
                              {/* <TableCell align="center">
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    updateAgreementStatusClick(
                                      operators.username
                                    );
                                  }}
                                >
                                  Update Agreement Status
                                </Button>
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={setUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <h4>No Operators Found</h4>
            )}
          </Box>
        ) : null}

        {setGridOpe === true ? (
          <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
            <SearchBar
              value={farmgridsearched}
              onChange={(searchVal) => requestFarmGridSearch(searchVal)}
              onCancelSearch={() => cancelFarmGridSearch()}
            />
            {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
            <div className="cardStyleForDashboardItems">
              <h4 style={{ width: "100%", textAlign: "center" }}>
                List Of Operators
              </h4>
              {setUsers.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setUsers.map((ope, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>User Id:</b>
                          {ope.userid}
                        </p>
                        <p>
                          <b>User Name:</b>
                          {ope.username}
                        </p>
                        <p>
                          <b>Email:</b>
                          {ope.email}
                        </p>
                        <h4 className="cardactiontitle">View Sites</h4>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            startIcon={<ListIcon />}
                            size="large"
                            onClick={() => {
                              roleNameOfOperator(ope.username, "List");
                            }}
                          ></Button>
                          <Button
                            startIcon={<GridIcon />}
                            size="large"
                            onClick={() => {
                              roleNameOfOperator(ope.username, "Grid");
                            }}
                          ></Button>
                          <Button
                            startIcon={<MapIcon />}
                            size="large"
                            onClick={() => {
                              roleNameOfOperator(ope.username, "Map");
                            }}
                          ></Button>
                          <br />
                          <Button
                            style={{ marginRight: "10px" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              viewInfoClick(ope.username);
                            }}
                          >
                            View Info
                          </Button>
                          &nbsp;
                          {/* <Button
                            type="button"
                            variant="contained"
                            onClick={() => {
                              viewSignedAgreementClick(ope.username);
                            }}
                          >
                            View Signed Agreements
                          </Button> */}
                        </div>
                        &nbsp;&nbsp;
                        {/* <div>
                          <Button
                            style={{ marginRight: "10px" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              viewKYCClick(ope.username);
                            }}
                          >
                            View KYC
                          </Button>
                        </div> */}
                        <div>
                          <h4 className="cardactiontitle">Operate</h4>
                        </div>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <h4 className="cardactiontitle">Actions</h4>
                          <div>
                            <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                resetPasswordDialogClick(ope.userid);
                              }}
                            >
                              Reset Password
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                operatorsUpdateDialogClick(ope);
                              }}
                            >
                              Update
                            </Button>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                deleteDialogClick(ope.username, ope.userid);
                              }}
                            >
                              Delete
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                feedbackDialogClick(ope.username);
                              }}
                            >
                              Feedback
                            </Button>
                          </div>
                          {/* &nbsp;&nbsp;
                          <div>
                            <Button
                              type="button"
                              variant="contained"
                              onClick={() => {
                                updateAgreementStatusClick(ope.username);
                              }}
                            >
                              Update Agreement Status
                            </Button>
                          </div> */}
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Operators Found.</h4>
              )}
            </div>
                  )}
          </Box>
        ) : null}

        {setMapOpe === true ? (
          <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
            {setUsers.length > 0 ? (
              <OperatorsGoogleMap
                containerElement={
                  <div style={{ height: `500px`, width: "100%" }} />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            ) : (
              <h4>No Operator Found</h4>
            )}
          </Box>
        ) : null}

        {/* End of Operators */}

        {/* Sites */}
        {listViewSites === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              onClick={() => {
                backToHome();
              }}
            >
              Back
            </Button>
            <div className="MuiGrid-root">
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {roleResourcesList ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 350 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={10} align="center">
                                List Of Sites
                              </TableCell>
                            </TableRow>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Site Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Site Name
                              </TableCell>
                              <TableCell align="center" colSpan={5}>
                                View Assets
                              </TableCell>
                              <TableCell align="center" colSpan={4}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {roleResourcesList
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((site, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {site.resourceid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {site.resourcename}
                                    </TableCell>

                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center">
                                      <Button
                                        startIcon={<ListIcon />}
                                        size="large"
                                        onClick={() => {
                                          assetsListViewClick(site.resourceid);
                                        }}
                                      ></Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        startIcon={<GridIcon />}
                                        size="large"
                                        onClick={() => {
                                          assetsGridViewClick(site.resourceid);
                                        }}
                                      ></Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        title="Assets Map View"
                                        startIcon={<MapIcon />}
                                        size="large"
                                        onClick={() => {
                                          assetsMapViewClick(site.resourceid);
                                        }}
                                      ></Button>
                                    </TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          siteIssuesClick(site.resourceid);
                                        }}
                                      >
                                        Site Issue
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          siteReportsClick(site.resourceid);
                                        }}
                                      >
                                        Site Report
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          siteEnvClick(site.resourceid);
                                        }}
                                      >
                                        Site Env
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center"></TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={roleResourcesList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No Site List Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {gridViewSites === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <h3>List Of Sites</h3>
            <br />
            <Button
              variant="contained"
              onClick={() => {
                backToHome();
              }}
            >
              Back
            </Button>
            <div className="cardStyleForDashboardItems">
              {roleResourcesList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {roleResourcesList.map((site, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Site Id:</b>
                          {site.resourceid}
                        </p>
                        <p>
                          <b>Site Name:</b>
                          {site.resourcename}
                        </p>
                        <h4 className="cardactiontitle">View Assets</h4>
                        <div>
                          <Button
                            title="Assets List View"
                            startIcon={<ListIcon />}
                            size="large"
                            onClick={() => {
                              assetsListViewClick(site.resourceid);
                            }}
                          ></Button>

                          <Button
                            title="Assets Grid View"
                            startIcon={<GridIcon />}
                            size="large"
                            onClick={() => {
                              assetsGridViewClick(site.resourceid);
                            }}
                          ></Button>
                          <Button
                            title="Assets Map View"
                            startIcon={<MapIcon />}
                            size="large"
                            onClick={() => {
                              assetsMapViewClick(site.resourceid);
                            }}
                          ></Button>
                        </div>
                        <h4 className="cardactiontitle">Options</h4>
                        <div>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              siteIssuesClick(site.resourceid);
                            }}
                          >
                            Site Issue
                          </Button>{" "}
                          &nbsp;
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              siteReportsClick(site.resourceid);
                            }}
                          >
                            Site Report
                          </Button>
                        </div>
                        &nbsp;&nbsp;
                        <div>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              siteEnvClick(site.resourceid);
                            }}
                          >
                            Site Env
                          </Button>{" "}
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Site Found.</h4>
              )}
            </div>
          </Box>
        ) : null}

        {mapViewSites === true ? (
          <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
            <Button
              variant="contained"
              onClick={() => {
                backToHome();
              }}
            >
              Back
            </Button>
            {roleResourcesList.length > 0 ? (
              <OperatorsGoogleMap
                containerElement={
                  <div style={{ height: `500px`, width: "100%" }} />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            ) : (
              <h4>No sites Found</h4>
            )}
          </Box>
        ) : null}
        {/* End of Sites */}

        {/* Assets */}
        {listViewAssets === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToSites();
              }}
            >
              Back to Sites
            </Button>
            <br />
            <br />
            <div className="MuiGrid-root">
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {roleResourceAssetsList.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 350 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={19} align="center">
                                List Of "{resourceId}" Assets
                              </TableCell>
                            </TableRow>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Site Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Status
                              </TableCell>
                              <TableCell align="center" colSpan={6}>
                                View
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {roleResourceAssetsList
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((asset, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {asset.siteId}
                                    </TableCell>
                                    <TableCell align="center">
                                      {asset.assetType}
                                    </TableCell>
                                    <TableCell align="center">
                                      {asset.modelId}
                                    </TableCell>
                                    <TableCell align="center">
                                      {asset.assetId}
                                    </TableCell>
                                    <TableCell align="center">
                                      {asset.assetStatus}
                                    </TableCell>
                                    <TableCell />
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          assetAttenanceClick(asset.assetId);
                                        }}
                                      >
                                        Attendance
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          assetIssuesClick(asset.assetId);
                                        }}
                                      >
                                        Issues
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          assetHealthClick(
                                            asset.siteId,
                                            asset.assetId
                                          );
                                        }}
                                      >
                                        Health
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          assetReportsClick(asset.assetId);
                                        }}
                                      >
                                        Reports
                                      </Button>
                                    </TableCell>
                                    <TableCell />
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={roleResourceAssetsList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No Asset List Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {gridViewAssets === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <h3 style={{ textAlign: "center" }}>
              List Of "{resourceId}" Assets
            </h3>
            <br />
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToSites();
              }}
            >
              Back to Sites
            </Button>
            <div className="cardStyleForDashboardItems">
              {roleResourceAssetsList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {roleResourceAssetsList.map((asset, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <p>
                        <b>Site Id:</b>
                        {asset.siteId}
                      </p>
                      <p>
                        <b>Asset Type:</b>
                        {asset.assetType}
                      </p>
                      <p>
                        <b>Model Id:</b>
                        {asset.modelId}
                      </p>
                      <p>
                        <b>Asset Id:</b>
                        {asset.assetId}
                      </p>
                      <p>
                        <b>Asset Status:</b>
                        {asset.assetStatus}
                      </p>
                      <div>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetAttenanceClick(asset.assetId);
                          }}
                        >
                          Attendance
                        </Button>{" "}
                        &nbsp;
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetIssuesClick(asset.assetId);
                          }}
                        >
                          Issues
                        </Button>
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetHealthClick(asset.siteId, asset.assetId);
                          }}
                        >
                          Health
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetReportsClick(asset.assetId);
                          }}
                        >
                          Reports
                        </Button>
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetPatrolClick(asset.assetId);
                          }}
                        >
                          Patrol
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetFeedbackClick(asset.assetId);
                          }}
                        >
                          Feedback
                        </Button>
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetEnvClick(asset.assetId, asset.assetType);
                          }}
                        >
                          Asset Live Env
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetMonitoringHandler(asset.siteId, asset.assetId);
                          }}
                        >
                          Asset Live Monitoring
                        </Button>
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetMortalityClick(asset.assetId);
                          }}
                        >
                          Mortality
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            assetUpdateStatusClick(
                              asset.assetId,
                              asset.assetStatus
                            );
                          }}
                        >
                          Update Status
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <h4>No Assets Found.</h4>
                </div>
              )}
            </div>
          </Box>
        ) : null}

        {mapViewAssets === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToSites();
              }}
            >
              Back to Sites
            </Button>
            {roleResourceAssetsList.length > 0 ? (
              <AssetsGoogleMap
                containerElement={
                  <div style={{ height: `500px`, width: "100%" }} />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            ) : (
              <div>
                <h4>No Assets Found</h4>
              </div>
            )}
          </Box>
        ) : null}

        {/* Asset Monitoring */}
        {assetMonitoringView === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToAssets();
              }}
            >
              Back to Assets
            </Button>
            <br />
            <h3>Asset Live Monitoring</h3>
            <br />

            <Map
              routeLocationData={markers}
              getLocationHandler={getLocationHandler}
            />

            {/* <AssetLiveGoogleMap
              containerElement={
                <div style={{ height: "800px", width: "100%" }} />
              }
              mapElement={<div style={{ height: "100%" }} />}
            /> */}
          </Box>
        ) : null}
        {/* End of Assets */}
        {/* Site Issues */}
        {gridViewSiteIssues === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <h3>List Of Site Issues</h3>
            <br />
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToSites();
              }}
            >
              Back to Sites
            </Button>
            <div className="cardStyleForDashboardItems">
              {siteIssuesList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {siteIssuesList.map((site, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Operator Id:</b>
                          {site.operatorid}
                        </p>
                        <p>
                          <b>Solution Id:</b>
                          {site.solutionid}
                        </p>
                        <p>
                          <b>Site Id:</b>
                          {site.siteid}
                        </p>
                        <p>
                          <b>Description:</b>
                          {site.issuedescription}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {site.timestamp}
                        </p>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Site Issues Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* End of Site Issues */}
        {/* Site Reports */}
        {gridViewSiteReports === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <h3>List Of Site Reports</h3>
            <br />
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToSites();
              }}
            >
              Back to Sites
            </Button>
            <div className="cardStyleForDashboardItems">
              {siteReportsList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {siteReportsList.map((site, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Site Id:</b>
                          {site.assetid}
                        </p>
                        <p>
                          <b>Parent Id:</b>
                          {site.parentid}
                        </p>
                        <p>
                          <b>User Id:</b>
                          {site.userid}
                        </p>
                        <p>
                          <b>Attendance Recorded ?:</b>
                          {site.attendancerecorded}
                        </p>
                        <p>
                          <b>Nutrition Recorded ?:</b>
                          {site.nutritionrecorded}
                        </p>
                        <p>
                          <b>Photo Uploaded ?:</b>
                          {site.photouploaded}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {site.timestamp}
                        </p>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Site Reports Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* End of Site Reports */}
        {/* Asset Issues */}
        {gridViewAssetIssues === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <h3>List Of Asset Issues</h3>
            <br />
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToAssets();
              }}
            >
              Back to Assets
            </Button>
            <div className="cardStyleForDashboardItems">
              {assetIssuesList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {assetIssuesList.map((site, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Operator Id:</b>
                          {site.operatorid}
                        </p>
                        <p>
                          <b>Solution Id:</b>
                          {site.solutionid}
                        </p>
                        <p>
                          <b>Site Id:</b>
                          {site.siteid}
                        </p>
                        <p>
                          <b>Site Id:</b>
                          {site.assetid}
                        </p>
                        <p>
                          <b>Description:</b>
                          {site.issuedescription}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {site.timestamp}
                        </p>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Asset Issues Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* End of Asset Issues */}

        {/* Asset Health */}
        {/* {gridViewAssetHealth === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <h3>Asset Health</h3>
            <br />
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToAssets();
              }}
            >
              Back to Assets
            </Button>
            <div className="cardStyleForDashboardItems">
              {assetHealthList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {assetHealthList.map((site, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Asset Id:</b>
                          {site.assetId}
                        </p>
                        <p>
                          <b>Asset Name:</b>
                          {site.assetFriendlyName}
                        </p>
                        <p>
                          <b>Asset Type:</b>
                          {site.assetType}
                        </p>
                        <p>
                          <b>assetVertical Id:</b>
                          {site.assetVerticalId}
                        </p>
                        <p>
                          <b>Category Type:</b>
                          {site.categoryType}
                        </p>
                        <p>
                          <b>Site Id:</b>
                          {site.siteId}
                        </p>
                        <p>
                          <b>Site Name:</b>
                          {site.siteName}
                        </p>
                        <p>
                          <b>Asset Health Status:</b>
                          {site.assetHealthStatus}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {site.timestamp}
                        </p>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Asset Health Found.</h4>
              )}
            </div>
          </Box>
        ) : null} */}
        {/* End of Asset Health */}

        {/* Asset Report */}
        {gridViewAssetReports === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <h3>Asset Report</h3>
            <br />
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToAssets();
              }}
            >
              Back to Assets
            </Button>
            <div className="cardStyleForDashboardItems">
              {assetReportsList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {assetReportsList.map((site, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Asset Id:</b>
                          {site.assetid}
                        </p>
                        <p>
                          <b>User Id:</b>
                          {site.userid}
                        </p>

                        <p>
                          <b>Parent Id:</b>
                          {site.parentid}
                        </p>
                        <p>
                          <p>
                            <b>Attendance Recorded?:</b>
                            {site.attendancerecorded}
                          </p>
                          <b>Nutrition Recorded?:</b>
                          {site.nutritionrecorded}
                        </p>
                        <p>
                          <b>Photo Uploaded? :</b>
                          {site.photouploaded}
                        </p>

                        <p>
                          <b>Timestamp:</b>
                          {site.timestamp}
                        </p>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Asset Reports Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* End of Asset Report */}

        {/* Asset Feedbacks */}
        {gridViewAssetFeedback === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <h3>Asset Feedback</h3>
            <br />
            <Button
              style={{ float: "right" }}
              variant="contained"
              onClick={() => {
                backToAssets();
              }}
            >
              Back to Assets
            </Button>
            <div className="cardStyleForDashboardItems">
              {assetFeedbackList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {assetFeedbackList.map((site, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <div>
                        {Object.keys(site).map((key, i) => (
                          <p key={i}>
                            <span>
                              <b>{key}:</b>
                            </span>
                            <span>{site[key]}</span>
                          </p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Asset Feedback Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* End of Asset Feedbacks */}
        {/* Send Feedback */}
        {feedbackDialog && (
          <UserFeedbackComponent
            userFeedbackDialog={feedbackDialog}
            closeFeedbackDialog={closeFeedbackDialog}
            username={username || ""}
            userTypeValue={userTypeValue || ""}
            user={selectedOperatorsName || ""}
          />
        )}

        {/*Node Info */}
        <Dialog
          fullScreen={fullScreen}
          open={operatorsViewNodeInfoDialog}
          // onClose={handleClose}
          aria-labelledby="operatorsNodeInfo"
        >
          <DialogTitle id="operatorsNodeInfo">View User Info</DialogTitle>
          <DialogContent>
            <p>
              <b>User Name : </b>
              {nodeUserName}
            </p>
            <p>
              <b>Node ID : </b>
              {nodeUserNodeId}
            </p>
            <p>
              <b>Account Type : </b>
              {nodeUserAccountType}
            </p>
            <p>
              <b>User Type : </b>
              {nodeUserUserType}
            </p>
            <p>
              <b>Asset Type : </b>
              {nodeUserAssetType}
            </p>
            <p>
              <b>Number Of Assets : </b>
              {nodeUserNumberOfAssets}
            </p>
            <p>
              <b>Area Size : </b>
              {nodeUserAreaSize}
            </p>
            <p>
              <b>Grazing Type : </b>
              {nodeUserGrazingType}
            </p>
            <p>
              <b>Feed Type : </b>
              {nodeUserFeedType}
            </p>
            <p>
              <b>Number Of Kids : </b>
              {nodeUserNumberOfKids}
            </p>
            <p>
              <b>Male Gender Number : </b>
              {nodeUserMaleGenderNumber}
            </p>
            <p>
              <b>Female Gender Number : </b>
              {nodeUserFemaleGenderNumber}
            </p>
            <p>
              <b>Over All Age : </b>
              {nodeUserOverallAge}
            </p>
            <p>
              <b>Breed : </b>
              {nodeUserBreed}
            </p>
            <p>
              <b>Farming Experience : </b>
              {nodeUserFarmingExperience}
            </p>
            <p>
              <b>Node Status : </b>
              {nodeUserNodeStatus}
            </p>
            <p>
              <b>Content Url : </b>
              {nodeUserContentUrl}
            </p>
            <p>
              <b>Node Type : </b>
              {nodeUserNodeType}
            </p>
            <p>
              <b>Assetvertical Id : </b>
              {nodeUserAssetverticalId}
            </p>
            <p>
              <b>Assetvertical Friendly Name: </b>
              {nodeUserAssetverticalFriendlyName}
            </p>
            <p>
              <b>Firstname : </b>
              {nodeUserFirstName}
            </p>
            <p>
              <b>Last Name : </b>
              {nodeUserLastName}
            </p>
            <p>
              <b>Email : </b>
              {nodeUserEmail}
            </p>
            <p>
              <b>Phone : </b>
              {nodeUserPhone}
            </p>
            <p>
              <b>State : </b>
              {nodeUserState}
            </p>
            <p>
              <b>City : </b>
              {nodeUserCity}
            </p>
            <p>
              <b>District : </b>
              {nodeUserDistrict}
            </p>
            <p>
              <b>Pincode : </b>
              {nodeUserPincode}
            </p>
            <p>
              <b>Latitude : </b>
              {nodeUserLatitude}
            </p>
            <p>
              <b>Longitude : </b>
              {nodeUserLongitude}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOperatorsViewNodeInfoDialog(!operatorsViewNodeInfoDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/*End Of Node Info */}

        {/* create Consumer User Operator */}
        <Dialog
          fullScreen={fullScreen}
          open={operatorsDialog}
          // onClose={handleClose}
          aria-labelledby="craete operator "
        >
          <DialogTitle id="createOperators">{"Create Operator"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">Consumer User Name *:</InputLabel>
              <Input
                id="username"
                name="username"
                type="email"
                value={usernameValue}
                onChange={(e) => {
                  setUsernameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstName">First Name *:</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                value={firstNameValue}
                onChange={(e) => {
                  setFirstNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastName">Last Name *:</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                value={lastNameValue}
                onChange={(e) => {
                  setLastNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="domainName">Domain Name *:</InputLabel>
              <Input
                id="domainName"
                name="domainName"
                type="text"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="userType">User Type *</InputLabel>
              <Select
                id="userType"
                name="userType"
                value={userTypeValue}
                onChange={(e) => {
                  setUserTypeValue(e.target.value);
                }}
              >
                <MenuItem value="Operator">Operator</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id *:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number *:</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Nominee :</InputLabel>
              <Input
                id="nominee"
                name="nominee"
                type="text"
                value={nomineeValue}
                onChange={(e) => {
                  setNomineeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pancard">Pancard Number :</InputLabel>
              <Input
                id="pancard"
                name="pancard"
                type="text"
                value={pancardValue}
                onChange={(e) => {
                  setPancardValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                createoperatorssubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOperatorsDialog(!operatorsDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Create Consumer User Operator */}
        {/* Delete Operator */}
        <Dialog
          fullScreen={fullScreen}
          open={operatorsDeleteDialog}
          // onClose={handleClose}
          aria-labelledby="deleteOperators"
        >
          <DialogTitle id="deleteOperators">
            {"Delete Operator User"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to delete?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOperatorsDeleteDialog(!operatorsDeleteDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Delete Operator */}
        {/* Reset Password Of Operator */}
        <Dialog
          fullScreen={fullScreen}
          open={operatorsResetPasswordDialog}
          // onClose={handleClose}
          aria-labelledby="operatorsResetPassword"
        >
          <DialogTitle id="operatorsResetPassword">
            Operator Password Reset
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="newPassword">New Password:</InputLabel>
              <Input
                id="newPassword"
                name="newPassword"
                type="text"
                value={newPasswordValue}
                onChange={(e) => {
                  setNewPasswordValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="passwordConfirmation">
                Password Confirmation:
              </InputLabel>
              <Input
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="text"
                value={passwordConfirmationValue}
                onChange={(e) => {
                  setPasswordConfirmationValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                resetPasswordSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setoperatorsResetPasswordDialog(!operatorsResetPasswordDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Reset Password Operator */}
        {/* Update Operator*/}
        <Dialog
          fullScreen={fullScreen}
          open={operatorsUpdateDialog}
          // onClose={handleClose}
          aria-labelledby="UpdateOperators"
        >
          <DialogTitle id="UpdateOperators">{"Update Operator"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">Consumer User Name :</InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={operatorsUserName}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstName">First Name :</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                value={operatorsFirstName}
                onChange={(e) => {
                  setOperatorsFirstName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastName">Last Name :</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                value={operatorsLastName}
                onChange={(e) => {
                  setOperatorsLastName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="domainName">Domain Name :</InputLabel>
              <Input
                disabled
                id="domainName"
                name="domainName"
                type="text"
                value={operatorsUserDomainName}
                onChange={(e) => {
                  setOperatorsUserDomainName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="userType">User Type</InputLabel>
              <Select
                disabled
                id="userType"
                name="userType"
                value={operatorsUserType}
              ></Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={operatorsEmail}
                onChange={(e) => {
                  setOperatorsEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={operatorsPhone}
                onChange={(e) => {
                  setOperatorsPhone(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={operatorsAddress}
                onChange={(e) => {
                  setOperatorsAddress(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={operatorsCity}
                onChange={(e) => {
                  setOperatorsCity(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={operatorsState}
                onChange={(e) => {
                  setOperatorsState(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Nominee :</InputLabel>
              <Input
                id="nominee"
                name="nominee"
                type="text"
                value={operatorsNominee}
                onChange={(e) => {
                  setOperatorsNominee(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pancard">Pancard Number :</InputLabel>
              <Input
                id="pancard"
                name="pancard"
                type="text"
                value={operatorsPancard}
                onChange={(e) => {
                  setOperatorsPancard(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={operatorsLongitude}
                onChange={(e) => {
                  setOperatorsLongitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={operatorsLatitude}
                onChange={(e) => {
                  setOperatorsLatitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={operatorsMessage}
                onChange={(e) => {
                  setOperatorsMessage(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                operatorsUpdateSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOperatorsUpdateDialog(!operatorsUpdateDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Update Operator*/}

        {/* Asset Health Info  */}
        <Dialog
          fullScreen={fullScreen}
          open={assetHelathRecordInfoPopup}
          aria-labelledby="Asset Health Info"
        >
          <DialogTitle id="AssetHealthInfo">{"Asset Health Info"}</DialogTitle>
          <DialogContent>
            {Object.values(assetHealthRecord).length > 1 ? (
              <>
                <p>
                  <span> AssetId :{assetHealthRecord.assetid}</span>
                </p>
                <p>
                  <span>
                    {" "}
                    Asset FriendlyName :{assetHealthRecord.assetfriendlyname}
                  </span>
                </p>
                <p>
                  <span> ParentId :{assetHealthRecord.parentid}</span>
                </p>
                <p>
                  <span> UserId :{assetHealthRecord.userid}</span>
                </p>
                <p>
                  <span> MedicineDate :{assetHealthRecord.medicinedate}</span>
                </p>
                <p>
                  <span>
                    {" "}
                    VaccinationDate :{assetHealthRecord.vaccinationdate}
                  </span>
                </p>
                <p>
                  <span> Examine :{assetHealthRecord.examine}</span>
                </p>

                <p>SurgicalProcedure : {assetHealthRecord.surgicalprocedure}</p>
                <p>VaccinationType :{assetHealthRecord.vaccinationtype}</p>
                <p>MedicineType : {assetHealthRecord.medicinetype}</p>
                <p>Diseasetype : {assetHealthRecord.diseasetype}</p>
                <p>TreatmentDate : {assetHealthRecord.treatmentdate}</p>

                <p>Latitude : {assetHealthRecord.latitude}</p>
                <p>Longitude : {assetHealthRecord.longitude}</p>
                <p>
                  <span>Description :{assetHealthRecord.description}</span>
                </p>
                <p>
                  Timestamp:
                  {assetHealthRecord.timestamp}
                </p>
              </>
            ) : (
              <h4>Data Not Found</h4>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAssetHelathRecordInfoPopup(!assetHelathRecordInfoPopup);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Asset Health Info */}
        {/* Asset Attendance */}
        <Dialog
          fullScreen={fullScreen}
          open={assetAttendanceDialog}
          //onClose={handleClose}
          aria-labelledby="assetAttendance"
        >
          <DialogTitle id="assetAttendance">
            {"View Asset Attendance"}
          </DialogTitle>
          <DialogContent>
            {Object.values(assetAttendanceOnj).length > 1 ? (
              <>
                <p>
                  <span> Altitude :{assetAttendanceOnj.altitude}</span>
                </p>
                <p>Latitude : {assetAttendanceOnj.latitude}</p>
                <p>Longitude : {assetAttendanceOnj.longitude}</p>
                <p>
                  Assetfriendlyname : {assetAttendanceOnj.assetfriendlyname}
                </p>
                <p>Parentid :{assetAttendanceOnj.parentid}</p>
                <p>Userid : {assetAttendanceOnj.userid}</p>
                <p>Assetid : {assetAttendanceOnj.assetid}</p>
                <p>
                  <span>Description :{assetAttendanceOnj.description}</span>
                </p>
                <p>
                  Timestamp:
                  {assetAttendanceOnj.timestamp}
                </p>
              </>
            ) : (
              <h4>Data Not Found</h4>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAssetAttendanceDialog(!assetAttendanceDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Asset Attendance  */}
        {/* Update Asset Status */}
        <Dialog
          fullScreen={fullScreen}
          open={assetUpdateStatusPopup}
          //onClose={handleClose}
          aria-labelledby="assetReport"
        >
          <DialogTitle id="assetReport">{"Update Asset Status"}</DialogTitle>
          <DialogContent>
            <h4>Update Asset Status </h4>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="status">Existing Status:</p>
              <Input
                type="text"
                value={selectedExistingStatus}
                onChange={(e) => {
                  setSelectedExistingStatus(e.target.value);
                }}
              />
            </FormControl>

            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputLabel>Update Status</InputLabel>
              <Select
                id="selectedModel"
                value={updateAssetStatusValue}
                onChange={(event) =>
                  setUpdateAssetStatusValue(event.target.value)
                }
                label="Select Model"
              >
                <MenuItem key="in-deprecation" value="in-deprecation">
                  in-deprecation
                </MenuItem>
                <MenuItem key="lost" value="lost">
                  lost
                </MenuItem>
                <MenuItem key="damaged" value="damaged">
                  damaged
                </MenuItem>
                <MenuItem key="under-maintenance" value="under-maintenance">
                  under-maintenance
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                assetUpdateStatusSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAssetUpdateStatusPopup(!assetUpdateStatusPopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Asset Mortality  */}
        <Dialog
          fullScreen={fullScreen}
          open={assetMortalityPopup}
          onClose={() => {
            setAssetMortalityPopup(!assetMortalityPopup);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Asset Mortality Info"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <p>
              <b>Asset Id :</b>
              {assetMortalityInfo.assetid}
            </p>
            <p>
              <b>Parent Id :</b>
              {assetMortalityInfo.parentid}
            </p>
            <p>
              <b>User Id :</b>
              {assetMortalityInfo.userid}
            </p>
            <p>
              <b>Model Id :</b>
              {assetMortalityInfo.modelid}
            </p>
            <p>
              <b>Cause of Death :</b>
              {assetMortalityInfo.causeofdeath}
            </p>
            <p>
              <b>Timestamp :</b>
              {assetMortalityInfo.timestamp}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                setAssetMortalityPopup(!assetMortalityPopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Asset Env  */}
        <Dialog
          fullScreen={fullScreen}
          open={assetEnvPopup}
          onClose={() => {
            setAssetEnvPopup(!assetEnvPopup);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Asset Environment Info"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <p>
              <b>Asset Id :</b>
              {assetEnvData.assetid}
            </p>
            <p>
              <b>Device Id :</b>
              {assetEnvData.deviceid}
            </p>
            <p>
              <b>Device Type :</b>
              {assetEnvData.devicetype}
            </p>
            <p>
              <b>Network Id :</b>
              {assetEnvData.networkid}
            </p>
            <p>
              <b>Site Id :</b>
              {assetEnvData.siteid}
            </p>
            <p>
              <b>Updated Date :</b>
              {assetEnvData.updatedat}
            </p>
            <p>
              <b>Number of Properties : </b>
              {assetEnvData.NumberofProperties}
            </p>

            {assetEnvData.properties !== undefined ? (
              assetEnvData.properties.map((prop, index) => {
                return (
                  <div key={`${index} `}>
                    <p>
                      <b>Property : &nbsp;</b>
                      {prop.property} &nbsp;&nbsp;{prop.value}
                    </p>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                setAssetEnvPopup(!assetEnvPopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Site Env  */}
        <Dialog
          fullScreen={fullScreen}
          open={siteEnvPopup}
          onClose={() => {
            setSiteEnvPopup(!siteEnvPopup);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Site Environment Info"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <p>
              <b>Site Id :</b>
              {siteEnvData.siteid}
            </p>
            <p>
              <b>Device Type :</b>
              {siteEnvData.devicetype}
            </p>
            <p>
              <b>Network Id :</b>
              {siteEnvData.networkid}
            </p>

            <p>
              <b>Updated Date :</b>
              {siteEnvData.updatedat}
            </p>
            <p>
              <b>Number of Properties : </b>
              {siteEnvData.NumberofProperties}
            </p>

            {siteEnvData.properties !== undefined ? (
              siteEnvData.properties.map((prop, index) => {
                return (
                  <div key={`${index} `}>
                    <p>
                      <b>Property : &nbsp;</b>
                      {prop.property} &nbsp;&nbsp;{prop.value}
                    </p>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                setSiteEnvPopup(!siteEnvPopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Asset Patrol  */}
        <Dialog
          fullScreen={fullScreen}
          open={assetPatrolPopup}
          onClose={() => {
            setAssetPatrolPopup(!assetPatrolPopup);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Asset Patrolling Info"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <p>
              <b>Asset Id :</b>
              {assetPatrolList.assetid}
            </p>
            <p>
              <b>Asset Friendlyname :</b>
              {assetPatrolList.assetfriendlyname}
            </p>
            <p>
              <b>Parent Id :</b>
              {assetPatrolList.parentid}
            </p>
            <p>
              <b>User Id :</b>
              {assetPatrolList.userid}
            </p>
            <p>
              <b>Site Id :</b>
              {assetPatrolList.siteid}
            </p>
            <p>
              <b>Mobile Number :</b>
              {assetPatrolList.mobilenumber}
            </p>
            <p>
              <b>Latitude : </b>
              {assetPatrolList.latitude}
            </p>
            <p>
              <b>Longitude : </b>
              {assetPatrolList.longitude}
            </p>
            <p>
              <b>Altitude : </b>
              {assetPatrolList.altitude}
            </p>
            <p>
              <b>Description : </b>
              {assetPatrolList.description}
            </p>
            <p>
              <b>Timestamp : </b>
              {assetPatrolList.timestamp}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                setAssetPatrolPopup(!assetPatrolPopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* View signed Agreeement */}
        <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={viewSignedAgreementDialog}
          aria-labelledby="viewAgreements"
        >
          <DialogTitle id="viewAgreements">
            {"View Signed Agreement"}
          </DialogTitle>
          <DialogContent>
            {AgreementsDocumentLink ? (
              <>
                {isLoading ? (
                  true
                ) : (
                  <embed
                    accept="image/*,.pdf"
                    className="viewWidthHeight"
                    src={AgreementsDocumentLink}
                  ></embed>
                )}
              </>
            ) : (
              <h4>No Agreement Found</h4>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setViewSignedAgreementDialog(!viewSignedAgreementDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* update signed Agreeement status*/}
        <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={updateAgreementStatusDialog}
          aria-labelledby="viewAgreements"
        >
          <DialogTitle id="viewAgreements">
            {"View Signed Agreement"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="existingStatus">
                Existing Status :
              </InputLabel>
              <Input
                disabled
                id="existingStatus"
                name="existingStatus"
                type="text"
                value={agreementDocumentStatus}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="status">Choose Status *</InputLabel>
              <Select
                id="status"
                value={agreementStatusValue}
                label="Choose Status"
                onChange={(e) => {
                  setAgreementStatusValue(e.target.value);
                }}
              >
                <MenuItem value="Agreement-Verification-In-Progress">
                  Agreement-Verification-In-Progress
                </MenuItem>
                <MenuItem value="Agreement-Verified">
                  Agreement-Verified
                </MenuItem>
                <MenuItem value="Agreement-Rejected">
                  Agreement-Rejected
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateAgreementStatusHandler();
              }}
            >
              Update
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUpdateAgreementStatusDialog(!updateAgreementStatusDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* View KYC documents*/}
        <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={viewKYCDialog}
          aria-labelledby="viewKYC"
        >
          <DialogContent>
            <UploadKYCDocumentsComponent
              selectedUser={selectedConsumerUserName}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setViewKYCDialog(!viewKYCDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
