export function mapRequestTypeToValue(requestType) {
  switch (requestType) {
    case 'asset-buy-rfq-req':
      return 'Asset';
    case 'agri-buy-products-rfq-req':
      return 'Agri Product';
    case 'cf-agri-assets-rfq-req':
      return 'Asset Contract Farming';
    case 'cf-agri-raw-products-rfq-req':
      return 'Product Contract Farming';
    case 'cm-product-rfq-req':
      return 'Contract Manufacturing';
    case 'storage-asset-rfq-req':
      return 'Asset Storage';
    case 'storage-product-rfq-req':
      return 'Product Storage';
    case 'transport-asset-rfq-req':
      return 'Asset Transport';
    case 'transport-product-rfq-req':
      return 'Product Transport';
    default:
      return 'Unknown';
  }
}

export function mapPORequestTypeToValue(requestType) {
  switch (requestType) {
    case 'asset-buy-order-req':
      return 'Asset';
    case 'agri-buy-products-order-req':
      return 'Agri Product';
    case 'cf-agri-assets-order-req':
      return 'Asset Contract Farming';
    case 'cf-agri-raw-products-order-req':
      return 'Product Contract Farming';
    case 'cm-product-order-req':
      return 'Contract Manufacturing';
    case 'storage-asset-order-req':
      return 'Asset Storage';
    case 'storage-product-order-req':
      return 'Product Storage';
    case 'transport-asset-order-req':
      return 'Asset Transport';
    case 'transport-product-order-req':
      return 'Product Transport';
    default:
      return 'Unknown';
  }
}
