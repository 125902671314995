import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row, Container, Collapse, Fade,
  Pagination, PaginationItem, PaginationLink,
  Badge,
  Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
// import { toast } from 'react-toastify';
import '../style.css';
import './Layout.css';
import AuthAxiosInstance from '../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance } from '../../../authAxiosInstance'
import MainService from "../Common/MainService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { NotificationManager } from "react-notifications";

let prev = 0;
let next = 0;
let last = 0;
let first = 0;
class Types extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devicetypelist: [],
      disabledstate: true,
      primary: false,
      danger: false,
      editmodal: false,
      deletedata: {},
      profiledata: [],
      typeuploaddata: [],
      editdevicedetails: [],
      typelist: [],
      currentpageno: 1,
      pageno: 1,
      pagesize: 5,
      searchkey: '',
      totaltypelistcount: '',
      accordion: [true],
      currentlist: [],
      modelstatus: ''
    };

    this.togglePrimary = this.togglePrimary.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleLastClick = this.handleLastClick.bind(this);
    this.handleFirstClick = this.handleFirstClick.bind(this);
    this.onclickdelete = this.onclickdelete.bind(this);
    this.toggleAccordion = this.toggleAccordion.bind(this);
    // toast.configure({
    //   autoClose: 8000,
    //   draggable: false,
    //   //etc you get the idea
    // });
  }

  toggleAccordion(tab) {

    const prevState = this.state.accordion;
    const state = prevState.map((x, index) => tab === index ? !x : false);

    this.setState({
      accordion: state,
    });
  }



  /*=============pagination========*/
  onRangevaluechange = (_value, e) => {
    var range = '';
    if (e.target.value == "ShowAll") {
      range = this.state.totaltypelistcount;
    }
    else {
      range = e.target.value;
    }
    this.setState({ [_value]: range });
    this.setState({ currentpageno: this.state.currentpageno });
    this.setState({ pageno: 1 }, () => { this.callbackfirmware() });
  }

  onSearchkeyChange = (_value, e) => {
    this.setState({ [_value]: e.target.value });
    var queryResult = [];
    if (e.target.value !== '') {
      this.state.otypelist.forEach(function (item) {
        if (item.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
          queryResult.push(item);
      });
      this.setState({ typelist: queryResult });
    }
    else {
      this.setState({ pageno: 1 }, () => { this.callbackfirmware() });
    }
    //this.setState({pageno : 1}, () => {this.callbackfirmware() } );       
  }
  callbackfirmware = () => {
    this.getAllDevicesTypes();
  }
  handleClick(event) {
    event.preventDefault();
    this.setState({
      pageno: Number(event.target.id)
    });

    this.getAllDevicesTypes();
  }
  handleLastClick(event) {
    event.preventDefault();
    this.setState({
      pageno: last
    });
    this.getAllDevicesTypes();
  }
  handleFirstClick(event) {
    event.preventDefault();
    this.setState({
      pageno: 1
    });
    this.getAllDevicesTypes();
  }
  /*================end===============*/

  /*==============Gateway Create ===========================*/
  togglePrimary() {
    this.setState({
      primary: !this.state.primary, disabledstate: true, typeuploaddata: {}
      , disabledstate: true, errors: '', modelstatus: ''
    });
  }


  onvalueChange(name, e) {
    const formData = { ...this.state.typeuploaddata };
    if (name === 'metadata') {
      try {
        formData[e.target.name] = JSON.stringify(JSON.parse(e.target.value), undefined, 4);
      }
      catch (error) {
        formData[e.target.name] = e.target.value;

      }
    }
    else {
      //const formData = { ...this.state.typeuploaddata };
      formData[e.target.name] = e.target.value;

    }
    this.setState({ typeuploaddata: formData }, () => {
      this.handleformvalidation();
    });
  }


  handleformvalidation() {
    let fields = this.state.typeuploaddata;
    let formIsValid = true;
    var validationfield = [{ 'name': 'deviceType', 'type': 'alphanumeric', 'isrequired': true, 'minlength': 2, 'displayname': 'Name' },
    { 'name': 'description', 'type': '', 'isrequired': true, 'minlength': 10, 'displayname': 'Description' },
    { 'name': 'metadata', 'type': 'json', 'isrequired': true, 'minlength': 0, 'displayname': 'Meta Data' }];
    for (let i = 0; i < validationfield.length; i++) {
      if (validationfield[i].isrequired && !fields[validationfield[i].name]) {
        formIsValid = false;
        this.setState({ errors: 'Please fill all the fields' });
        this.setState({ disabledstate: true });
      }
      else if (validationfield[i].type === 'alphanumeric') {
        var nospecial = /[*|\":<>[\]{}`\\()';@&$!#^=+%`~,./? ]/;
        if (nospecial.test(fields[validationfield[i].name])) {
          formIsValid = false;
          this.setState({ errors: `No Special characters allowed in ${validationfield[i].displayname}` });
          this.setState({ disabledstate: true });
        }
        if (fields[validationfield[i].name].length < validationfield[i].minlength) {
          formIsValid = false;
          this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
          this.setState({ disabledstate: true });
        }
      }
      else if (validationfield[i].type === 'json') {
        try {
          JSON.stringify(JSON.parse(fields[validationfield[i].name]), undefined, 4)
        }
        catch (ccerr) {
          formIsValid = false;
          this.setState({ errors: `Please fill ${validationfield[i].displayname} in correct JSON format` });
          this.setState({ disabledstate: true });
        }

      }
      else if (validationfield[i].minlength > 0) {
        if (fields[validationfield[i].name].length < validationfield[i].minlength) {
          formIsValid = false;
          this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
          this.setState({ disabledstate: true });
        }
      }
    }
    if (formIsValid === true) {

      this.setState({ errors: '', disabledstate: false });
      //this.onclickdeviceupload();



    }

  }

  onclickdeviceupload = () => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    if (this.state.modelstatus === 'edit') {

      DeviceMetadataAxiosInstance.put(`/admin/deviceprofile/${username}/deviceTypes/${this.state.typeuploaddata.deviceType}`, this.state.typeuploaddata)
        .then((response) => {
          this.setState({
            primary: !this.state.primary, disabledstate: true, typeuploaddata: {}
          });

          // toast.success("Device type updated successfully", {
          //   position: toast.POSITION.TOP_RIGHT
          // });
          this.getAllDevicesTypes();
          return NotificationManager.success("Device type updated successfully");
        }).catch((err) => {
          // toast.error("Some error occured", {
          //   position: toast.POSITION.TOP_RIGHT
          // });
          return NotificationManager.error("Some error occured");
        });
    }
    else {
      DeviceMetadataAxiosInstance.post(`/admin/deviceprofile/${username}/deviceTypes`, this.state.typeuploaddata)
        .then((response) => {
          this.setState({
            primary: !this.state.primary, disabledstate: true, typeuploaddata: {}
          });

          // toast.success("New type created successfully", {
          //   position: toast.POSITION.TOP_RIGHT
          // });
          
          this.getAllDevicesTypes();
          return NotificationManager.success("New type created successfully");
        }).catch((err) => {
          // toast.error("Some error occured", {
          //   position: toast.POSITION.TOP_RIGHT
          // });
          return NotificationManager.error("Some error occured");

        });
    }
  }


  /*================ Gateway edit ========================*/
  toggleeditdevicemodel = (gw_id, status) => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    if (status == "edit" || status == 'view') {
      DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/${gw_id}`).then((res) => {
        this.setState({ typeuploaddata: res.data });
        this.setState({ modelstatus: status });
        if (status === 'view') {
          this.setState({ editmodal: true });
        }
        else if (status === 'edit') {
          this.setState({ primary: !this.state.primary });
          this.handleformvalidation();
        }
      })

    } else {
      this.setState({ typeuploaddata: {}, editmodal: false })
    }


  }

  /*================= Gateway delete =====================*/
  toggledeviceDelete = (data, event) => {
    this.setState({
      danger: !this.state.danger, deletedata: data
    });

  }
  onclickdelete = () => {
    console.log(this.state.deletedata);
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    DeviceMetadataAxiosInstance.delete(`/admin/deviceprofile/${username}/deviceTypes/${this.state.deletedata}`).then((resp) => {
      // toast.success("Device type successfully deleted", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      this.getAllDevicesTypes();
      this.setState({
        danger: !this.state.danger, deletedata: {}
      });
      return NotificationManager.success("Device type successfully deleted");
    }).catch((err) => {
      // toast.error("Error occured while deleting the device", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Some error occured");
    });


  }
  componentDidMount() {
    this.getAllDevicesTypes();
  }

  getAllDevicesTypes() {
    const userId = localStorage.getItem("user-id");
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/list`).then((resp) => {

      this.setState({ typelist: resp.data, otypelist: resp.data, totaltypelistcount: resp.data.length });
    }).catch((err) => {
      this.setState({ typelist: [], otypelist: [], totaltypelistcount: '' });
    })
  }


  viewProperty(devId) {
    this.props.history.push(`/layout/gateway?devId=${devId}`);
  }


  render() {

    const { devicetypelist, typelist, pageno, pagesize, totaltypelistcount } = this.state;
    const textFieldStyle = {
      marginTop: '20px', // Add your desired top margin here
    };

    if (typelist.length > 0) {
      for (var i = 0; i < typelist.length; i++) {
        for (var j = 0; j < devicetypelist.length; j++) {
          if (devicetypelist[j].dvt_id == typelist[i].dv_type) {
            typelist[i].dvt_name = devicetypelist[j].dvt_name;
          }
        }
      }
    }
    // Logic for displaying todos
    const indexOfLastTodo = pageno * pagesize;
    const indexOfFirstTodo = indexOfLastTodo - pagesize;
    const currentlist = typelist.slice(indexOfFirstTodo, indexOfLastTodo);
    prev = pageno > 0 ? (pageno - 1) : 0;
    last = Math.ceil(totaltypelistcount / pagesize);
    next = (last === pageno) ? pageno : pageno + 1;
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totaltypelistcount / pagesize); i++) {
      pageNumbers.push(i);
    }
    //Logic to get the list of pagesize
    const pagesizelist = []
    if (parseInt(totaltypelistcount) > 0) {
      for (let j = 1; j <= totaltypelistcount; j++) {
        if (j % 5 === 0) {
          pagesizelist.push(j);
        }
      }
      pagesizelist.push("ShowAll");

    }
    else {
      pagesizelist.push("ShowAll");
    }




    return (
      <div className="animated fadeIn" >
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <strong className="LabelHeader">Type List</strong>

                <div className="alignright">
                  <Button type="button" className="custom-button" onClick={this.togglePrimary} >Create New Device Type</Button>
                </div>

              </CardHeader>
                <Col xs="12" md="12">
                  <FormGroup row>
                    <Col md="8">

                      {/* {(this.state.searchkey == "") &&
                        <Input type="select" value={pagesize} className="dropdownwidth2" name="pagesize" onChange={this.onRangevaluechange.bind(this, 'pagesize')}>
                          {pagesizelist.map((number, i) =>
                            <option key={number} value={number !== 'ShowAll' ? number : totaltypelistcount} >{number !== 'ShowAll' ? number : 'ShowAll'}</option>
                          )}
                        </Input>

                      } */}
                      <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                        {this.state.searchkey === "" && (
                          <FormControl variant="outlined" className="dropdownwidth2">
                            <Select
                              value={pagesize}
                              onChange={this.onRangevaluechange.bind(this, "pagesize")}
                            >
                              {pagesizelist.map((number, i) => (
                                <MenuItem
                                  key={number}
                                  value={number !== "ShowAll" ? number : totaltypelistcount}
                                >
                                  {number !== "ShowAll" ? number : "ShowAll"}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {this.state.searchkey != "" && (
                          <FormControl variant="outlined" className="dropdownwidth2">
                           
                          </FormControl>
                        )}
                        <Grid item md={4}>
                          <TextField
                            type="text"
                            name="searchkey"
                            autoComplete="off"
                            placeholder="Enter search Key"
                            onChange={this.onSearchkeyChange.bind(this, "searchkey")}
                          />
                        </Grid>
                      </div>


                    </Col>

                  </FormGroup>
                </Col>
                {(currentlist.length > 0) &&
                  // <Table hover responsive className="table-outline mb-0  d-sm-table ">
                  //   <thead className="thead-dark">
                  //     <tr>
                  //       <th>SL No</th>

                  //       <th>TYPE NAME</th>

                  //       <th>ACTION</th>
                  //     </tr>
                  //   </thead>
                  //   {currentlist && currentlist.map((row, index) => {
                  //     return (
                  //       <tbody key={index}>
                  //         <tr className="RowStyle">
                  //           <td>{pagesize * (pageno - 1) + (index + 1)}</td>

                  //           <td>{row}</td>
                  //           <td>
                  //             <strong>
                  //               <Button color="success" title="View" className="mr-1 rounded-circle btn-circle" onClick={this.toggleeditdevicemodel.bind(this, row, 'view')} ><i className="fa fa-eye iconwidth"></i></Button>
                  //               <Button color="primary" title="Edit" className="mr-1 rounded-circle btn-circle" onClick={this.toggleeditdevicemodel.bind(this, row, 'edit')} ><i className="fa fa-edit iconwidth"></i></Button>
                  //               <Button color="danger" title="Delete" className="mr-1 rounded-circle btn-circle" onClick={this.toggledeviceDelete.bind(this, row)} ><i className="fa fa-trash iconwidth"></i></Button>
                  //             </strong>
                  //           </td>
                  //         </tr>
                  //       </tbody>
                  //     );
                  //   }, this)}
                  // </Table>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>SL No</TableCell>
                          <TableCell>TYPE NAME</TableCell>
                          <TableCell>ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentlist &&
                          currentlist.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{pagesize * (pageno - 1) + (index + 1)}</TableCell>
                              <TableCell>{row}</TableCell>
                              <TableCell>
                                <Button
                                  className="custom-button-view"
                                  variant="contained"
                                  onClick={() => this.toggleeditdevicemodel(row, "view")}
                                >
                                  <ViewIcon />
                                </Button>
                                <Button
                                  className="custom-button-edit"
                                  variant="contained"
                                  onClick={() => this.toggleeditdevicemodel(row, "edit")}
                                >
                                  <EditIcon />
                                </Button>
                                <Button
                                  className="custom-button-delete"
                                  variant="contained"
                                  onClick={() => this.toggledeviceDelete(row)}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
                <br></br>
                <div>
                  {(totaltypelistcount !== pagesize) && (currentlist.length > 0) && (this.state.searchkey == "") &&
                    <ul id="page-numbers">
                      <nav>
                        <Pagination style={{ display: "flex" }}>
                          <PaginationItem>
                            {prev === 0 ? <PaginationLink disabled>First</PaginationLink> :
                              <PaginationLink onClick={this.handleFirstClick} id={prev}>First</PaginationLink>
                            }
                          </PaginationItem>
                          <PaginationItem>
                            {prev === 0 ? <PaginationLink disabled>Prev</PaginationLink> :
                              <PaginationLink onClick={this.handleClick} id={prev} >Prev</PaginationLink>
                            }
                          </PaginationItem>
                          {
                            pageNumbers.map((number, i) =>
                              <Pagination key={i}>
                                <PaginationItem active={pageNumbers[pageno - 1] === (number) ? true : false} >
                                  <PaginationLink onClick={this.handleClick} key={number} id={number}>
                                    {number}
                                  </PaginationLink>
                                </PaginationItem>
                              </Pagination>
                            )}

                          <PaginationItem>
                            {
                              pageno === last ? <PaginationLink disabled>Next</PaginationLink> :
                                <PaginationLink onClick={this.handleClick} id={pageNumbers[pageno]} >Next</PaginationLink>
                            }
                          </PaginationItem>

                          <PaginationItem>
                            {
                              pageno === last ? <PaginationLink disabled>Last</PaginationLink> :
                                <PaginationLink onClick={this.handleLastClick} id={pageNumbers[pageno]} >Last</PaginationLink>
                            }
                          </PaginationItem>
                        </Pagination>

                      </nav>
                    </ul>
                  }
                </div>
                {(currentlist.length == 0) &&

                  <center>
                    <h3>No records found!!</h3>
                  </center>

                }

            </Card>
          </Col>
        </Row>

        {/* <Modal isOpen={this.state.primary} toggle={this.togglePrimary}
          className={'modal-lg ' + this.props.className}>
          <ModalHeader toggle={this.togglePrimary}>{this.state.modelstatus === 'edit' ? 'Edit Type' : 'Add New Type'}</ModalHeader>
          <ModalBody>
            <div id="accordion">
              <Label className="help-block" id="errorstyle">{this.state.errors}</Label>
              <Card className="mb-0">
                <CardHeader id="headingOne">
                  <Button block color="link" className="text-left m-0 p-0" onClick={() => this.toggleAccordion(0)} aria-expanded={this.state.accordion[0]} aria-controls="collapseOne">
                    <h5 className="m-0 p-0">Type Details <strong className="redfont">*</strong></h5>
                  </Button>
                </CardHeader>
                <Collapse isOpen={this.state.accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                  <CardBody>
                    <Form>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Name</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input type="text" disabled={this.state.modelstatus === 'edit' ? true : false} name="deviceType" placeholder="Enter Type Name" value={this.state.typeuploaddata.deviceType}
                            onChange={this.onvalueChange.bind(this, 'deviceType')} required maxLength='20' />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Description</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input type="text" name="description" placeholder="Description" value={this.state.typeuploaddata.description}
                            onChange={this.onvalueChange.bind(this, 'description')} required maxLength='45' />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Meta Data</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <pre>
                            <Input type="textarea" value={this.state.typeuploaddata.metadata} rows="10" name='metadata' onChange={this.onvalueChange.bind(this, 'metadata')} placeholder='Enter correct JSON format based on device type.' />
                          </pre>
                        </Col>
                      </FormGroup>





                    </Form>
                  </CardBody>
                </Collapse>
              </Card>


            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onclickdeviceupload.bind(this)} disabled={this.state.disabledstate}>{this.state.modelstatus === 'edit' ? 'Update' : 'Create'}</Button>
            <Button color="primary" onClick={this.togglePrimary}>Cancel</Button>
          </ModalFooter>
        </Modal> */}
        <Dialog open={this.state.primary} onClose={this.togglePrimary} maxWidth="lg">
          <DialogTitle>{this.state.modelstatus === "edit" ? "Edit Type" : "Add New Type"}</DialogTitle>
          <DialogContent>
            <TextField
              type="text"
              disabled={this.state.modelstatus === "edit"}
              name="deviceType"
              placeholder="Enter Type Name"
              value={this.state.typeuploaddata.deviceType}
              onChange={this.onvalueChange.bind(this, "deviceType")}
              required
              maxLength="20"
              fullWidth
            />
            <TextField
              type="text"
              name="description"
              placeholder="Description"
              value={this.state.typeuploaddata.description}
              onChange={this.onvalueChange.bind(this,"description")}
              required
              maxLength="45"
              fullWidth
            />
            <TextField
              type="text"
              name="metadata"
              placeholder="Enter correct JSON format based on device type."
              value={this.state.typeuploaddata.metadata}
              onChange={this.onvalueChange.bind(this,"metadata")}
              multiline
              rows={10}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={this.onclickdeviceupload}
              disabled={this.state.disabledstate}
              style={{backgroundColor:"green"}}
              className="custom-button"
            >
              {this.state.modelstatus === "edit" ? "Update" : "Create"}
            </Button>
            <Button className="custom-button-cancel" onClick={this.togglePrimary}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Modal isOpen={this.state.editmodal} toggle={this.toggleeditdevicemodel.bind(this, '')}
          className={'modal-lg ' + this.props.className}>
          <ModalHeader toggle={this.toggleeditdevicemodel}>View Type</ModalHeader>
          <ModalBody>
            <div id="accordion">
              <Label className="help-block" id="errorstyle">{this.state.errors}</Label>
              <Card className="mb-0">
                <CardHeader id="headingOne">
                  <Button block color="link" className="text-left m-0 p-0" onClick={() => this.toggleAccordion(0)} aria-expanded={this.state.accordion[0]} aria-controls="collapseOne">
                    <h5 className="m-0 p-0">Type Details <strong className="redfont">*</strong></h5>
                  </Button>
                </CardHeader>
                <Collapse isOpen={this.state.accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                  <CardBody>
                    <Form>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Name</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input type="text"
                            value={this.state.typeuploaddata.deviceType} disabled
                            name="deviceType" placeholder="Enter Type Name" />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Description</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input type="text"
                            value={this.state.typeuploaddata.description} disabled
                            name="description" placeholder="Description" />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Meta Data</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <pre>
                            <Input type="textarea" value={this.state.typeuploaddata.metadata} disabled
                              rows="10" name='metadata' placeholder='Enter correct JSON format based on device type.' />
                          </pre>
                        </Col>
                      </FormGroup>



                    </Form>
                  </CardBody>
                </Collapse>
              </Card>


            </div>
          </ModalBody>
        
        </Modal> */}
        <Dialog
          open={this.state.editmodal}
          onClose={this.toggleeditdevicemodel.bind(this,'')}
          maxWidth="lg"
        >
          <DialogTitle>View Type</DialogTitle>
          <DialogContent>
            <Typography className="help-block" id="errorstyle">
              {this.state.errors}
            </Typography>
            <div id="accordion">
              <Card className="mb-0">
                <CardHeader id="headingOne">
                  
                    <Typography variant="h5" className="m-0 p-0">
                      Type Details
                    </Typography>
                </CardHeader>
                <Collapse
                  isOpen={this.state.accordion[0]}
                  data-parent="#accordion"
                  id="collapseOne"
                  aria-labelledby="headingOne"
                >
                  <CardBody>
                    <TextField
                      type="text"
                      value={this.state.typeuploaddata.deviceType}
                      disabled
                      name="deviceType"
                      label="Name"
                      fullWidth
                      style={textFieldStyle} // Apply the top margin here

                    />
                    <TextField
                      type="text"
                      value={this.state.typeuploaddata.description}
                      disabled
                      name="description"
                      label="Description"
                      fullWidth
                      style={textFieldStyle} // Apply the top margin here

                    />
                    <TextField
                      type="textarea"
                      value={this.state.typeuploaddata.metadata}
                      disabled
                      name="metadata"
                      label="Meta Data"
                      rows={10}
                      multiline
                      fullWidth
                      style={textFieldStyle} // Apply the top margin here
                    />
                  </CardBody>
                </Collapse>
              </Card>
            </div>
          </DialogContent>
          <DialogActions>
           
            <Button className="custom-button-cancel" onClick={this.toggleeditdevicemodel}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>


        {/* <Modal isOpen={this.state.danger} toggle={this.toggledeviceDelete}
          className={'modal-danger ' + this.props.className}>
          <ModalHeader toggle={this.toggledeviceDelete}>Delete Device Type </ModalHeader>
          <ModalBody>
            <strong>Are you sure, You want to delete this Device Type ?</strong>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.onclickdelete.bind(this)}>Delete</Button>{' '}
            <Button color="secondary" onClick={this.toggledeviceDelete}>Cancel</Button>
          </ModalFooter>
        </Modal> */}
        <Dialog open={this.state.danger} onClose={this.toggledeviceDelete}>
          <DialogTitle>Delete Device Type</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              <strong>Are you sure you want to delete this Device Type?</strong>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onclickdelete.bind(this)} className="custom-button" style={{backgroundColor:"red"}}>
              Delete
            </Button>
            <Button onClick={this.toggledeviceDelete} className="custom-button-cancel">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>


      </div>
    );
  }

}

export default Types;
