import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import { NotificationManager } from "react-notifications";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import GrowerIcon from "../../../../resources/img/Grower.svg";

import MainService from "../../Common/MainService";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  media: {
    maxWidth: 250,
    maxHeight: 250,
    width: 100,
    height: 120,
    textAlign: "center",
  },
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    height: 120,
    width: 100,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  control: {
    padding: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  button: {
    color: theme.palette.danger.main,
    borderColor: theme.palette.danger.main,
    "&:hover": {
      backgroundColor: theme.palette.danger.shadow,
      borderColor: theme.palette.danger.main,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.danger.shadow,
      borderColor: theme.palette.danger.main,
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
}));

export default function NomineePageComponent() {
  const classes = useStyles();

  const [nomineeList, setNomineeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteNominee, setShowDeleteNominee] = useState(false);
  const [selectedNomineeIdentifier, setSelectedNomineeIdentifier] =
    useState(null);
  const [selectedNominee, setSelectedNominee] = useState(null);
  const [showManageNominee, setShowManageNominee] = useState(false);
  const [nomineePopup, setShowNomineePopup] = useState(false);
  const [nomineeName, setShowNomineeName] = useState("");
  const [nomineeMobile, setShowNomineeMobile] = useState("");
  const [nomineeEmail, setShowNomineeEmail] = useState("");
  const [nomineeDob, setShowNomineeDob] = useState("");
  const [nomineeIdentifier, setShowNomineeIdentifier] = useState("");
  const [nomineeRelationship, setShowNomineeRelationship] = useState("");
  useEffect(() => {
    getNomineeList();
  }, []);

  const getNomineeList = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;

    MainService.getNomineeList(username)
      .then((data) => {
        setIsLoading(false);
        setNomineeList(data);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const toggleAddNominee = () => {
    setShowManageNominee(!showManageNominee);
  };
  const toggleUpdateNominee = () => {
    setShowNomineePopup(!nomineePopup);
  };

  const uploadNomineeClick = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;
    let nomineeUpdateDetails = {
      nomineeName: nomineeName,
      nomineeMobile: nomineeMobile,
      nomineeEmail: nomineeEmail,
      dob: nomineeDob,
      identifier: nomineeIdentifier,
      relationship: nomineeRelationship,
    };
    const nomineeUpdateData = JSON.stringify(nomineeUpdateDetails);
    console.log("nomineeUpdateData data:", nomineeUpdateData);
    MainService.updateNominee(nomineeUpdateData, username)
      .then((data) => {
        getNomineeList();
        setIsLoading(false);
        return NotificationManager.success(
          `Nominee ${nomineeName} updated successfully.`
        );
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
        return NotificationManager.error(e);
      });
    toggleUpdateNominee();
  };
  const addNominee = (evt) => {
    evt.preventDefault();

    const nominee = document.getElementById("nomineeName").value;
    const nomineemobile = document.getElementById("nomineeMobile").value;
    const nomineeemail = document.getElementById("nomineeEmail").value;
    const dob = document.getElementById("nomineeDOB").value;
    const identifier = document.getElementById("nomineeIdentifier").value;
    const relationship = document.getElementById("nomineeRelationship").value;

    if (!nominee || nominee === "") {
      return NotificationManager.warning("Enter valid Nominee Name.");
    }
    if (!nomineemobile || nomineemobile === "") {
      return NotificationManager.warning("Enter valid Nominee Mobile Number.");
    }
    if (!nomineeemail || nomineeemail === "") {
      return NotificationManager.warning("Enter valid Nominee Email.");
    }
    if (!dob || dob === "") {
      return NotificationManager.warning("Enter valid Nominee Date Of Birth.");
    }
    if (!identifier || identifier === "") {
      return NotificationManager.warning(
        "Enter valid Nominee Identifier Number."
      );
    }
    if (!relationship || relationship === "") {
      return NotificationManager.warning("Enter valid Nominee Relationship.");
    }

    setIsLoading(true);

    const { user } = MainService.getLocalData();
    const { username } = user;

    let payload = {
      nominee: nominee,
      nomineemobile: nomineemobile,
      nomineeemail: nomineeemail,
      dob: dob,
      identifier: identifier,
      relationship: relationship,
      username: username,
    };
    const payloadData = JSON.stringify(payload);
    console.log("payloadData:", payloadData);

    MainService.addNominee(username, payloadData)
      .then((data) => {
        getNomineeList();
        setIsLoading(false);
        toggleAddNominee();
        // return NotificationManager.success('Nominee information saved successfully.');
        return NotificationManager.success(data);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
        return NotificationManager.error(e);
      });
  };

  const toggleDeleteNominee = (nomineeIdentifier, nomineeName) => {
    setSelectedNominee(nomineeName);
    setSelectedNomineeIdentifier(nomineeIdentifier);
    setShowDeleteNominee(!showDeleteNominee);
  };
  const updateNominee = (data) => {
    setShowNomineeName(data.nomineeName);
    setShowNomineeMobile(data.nomineeMobile);
    setShowNomineeEmail(data.nomineeEmail);
    setShowNomineeDob(data.dob);
    setShowNomineeIdentifier(data.identifier);
    setShowNomineeRelationship(data.relationship);
    setShowNomineePopup(!nomineePopup);
  };
  const doDeleteNominee = (evt) => {
    evt.preventDefault();
    setIsLoading(true);
    const { user } = MainService.getLocalData();
    const { username } = user;
    /* let nomineeBody = {
      deleteCountValue,
    }; */
    /*  const nomineeBodyData = JSON.stringify(nomineeBody);
    console.log("nomineeBodyData data:", nomineeBodyData); */
    MainService.deleteNominee(username, selectedNomineeIdentifier)
      .then((data) => {
        getNomineeList();
        setIsLoading(false);
        toggleDeleteNominee();
        return NotificationManager.success(
          `Nominee ${selectedNominee} deleted successfully.`
        );
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
        return NotificationManager.error(e);
      });
  };
  /* Add New Nominee */
  const manageNomineeBody = (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={5} md={4} lg={3} className="modalGridBody">
        <div className="updateProfileContainer">
          <span
            className="closeButton"
            style={{
              width: "100%",
              textAlign: "right",
              color: "red",
              fontSize: "23px",
              float: "right",
              padding: "0px 15px 0px 0px",
            }}
            onClick={toggleAddNominee}
          >
            X
          </span>
          <form id="nomineeForm" onSubmit={addNominee}>
            <h2>Add New Nominee</h2>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nomineeName">Name *</InputLabel>
              <Input
                id="nomineeName"
                type="text"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> assignment_ind </i>{" "}
                  </InputAdornment>
                }
                require="true"
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nomineeMobile">Mobile *</InputLabel>
              <Input
                id="nomineeMobile"
                type="tel"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> call </i>{" "}
                  </InputAdornment>
                }
                require="true"
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nomineeEmail">Email *</InputLabel>
              <Input
                id="nomineeEmail"
                type="email"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> alternate_email </i>{" "}
                  </InputAdornment>
                }
                require="true"
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nomineeIdentifier">ID Number *</InputLabel>
              <Input
                id="nomineeIdentifier"
                type="text"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nomineeDOB">Date Of Birth *</InputLabel>
              <Input
                id="nomineeDOB"
                type="date"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> supervisor_account </i>{" "}
                  </InputAdornment>
                }
                require="true"
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nomineeRelationship">
                Relationship *
              </InputLabel>
              <Input
                id="nomineeRelationship"
                type="text"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> supervisor_account </i>{" "}
                  </InputAdornment>
                }
                require="true"
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true}>
              {isLoading === false && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="submit"
                >
                  Save
                </Button>
              )}{" "}
              {isLoading === true && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className="loginLoader" />
                </Button>
              )}
            </FormControl>
            <FormControl
              fullWidth={true}
              className="forgotPwdLink"
              onClick={toggleAddNominee}
              style={{
                marginBottom: "15px",
              }}
            >
              Cancel
            </FormControl>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  /* Delete Nominee */
  const deleteNomineeBody = (
    <Grid container spacing={0}>
      <Grid
        item
        xs={11}
        sm={5}
        md={4}
        lg={3}
        className="modalGridBody"
        style={{ margin: "75px auto 0 !important" }}
      >
        <span
          className="closeButton"
          style={{
            width: "100%",
            textAlign: "right",
            color: "red",
            fontSize: "23px",
            float: "right",
            padding: "5px 15px 0px 0px",
          }}
          onClick={toggleDeleteNominee}
        >
          X
        </span>
        <div className="forgotPwdContainer">
          <form onSubmit={(e) => doDeleteNominee(e)}>
            <h2>Delete Nominee</h2>
            <span>
              Are you sure to Delete Nominee - <h3>{selectedNominee}</h3>
            </span>
            <br />
            <FormControl fullWidth={true}>
              {isLoading === false && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  type="submit"
                >
                  Delete
                </Button>
              )}{" "}
              {isLoading === true && (
                <Button
                  variant="contained"
                  color="secondary"
                  className="loginBtn"
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className="loginLoader" />
                </Button>
              )}
            </FormControl>

            <FormControl
              fullWidth={true}
              className="forgotPwdLink"
              onClick={() => toggleDeleteNominee()}
              style={{
                marginBottom: "15px",
              }}
            >
              Cancel
            </FormControl>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  /* Update Nominee Popup */

  const nomineeBody = (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={5} md={4} lg={3} className="modalGridBody">
        <div className="updateProfileContainer">
          <span
            className="closeButton"
            style={{
              width: "100%",
              textAlign: "right",
              color: "red",
              fontSize: "23px",
              float: "right",
              padding: "5px 15px 0px 0px",
            }}
            onClick={toggleUpdateNominee}
          >
            X
          </span>
          <form id="nomineeForm" onSubmit={uploadNomineeClick}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nomineeName">Name *</InputLabel>
              <Input
                id="nomineeName"
                type="text"
                value={nomineeName}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> assignment_ind </i>{" "}
                  </InputAdornment>
                }
                require="truse"
                onChange={(e) => {
                  setShowNomineeName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nomineeMobile">Mobile *</InputLabel>
              <Input
                id="nomineeMobile"
                type="tel"
                value={nomineeMobile}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> call </i>{" "}
                  </InputAdornment>
                }
                require="true"
                onChange={(e) => {
                  setShowNomineeMobile(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nomineeEmail">Email *</InputLabel>
              <Input
                id="nomineeEmail"
                type="email"
                value={nomineeEmail}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> alternate_email </i>{" "}
                  </InputAdornment>
                }
                require="true"
                onChange={(e) => setShowNomineeEmail(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nomineeIdentifier">ID Number *</InputLabel>
              <Input
                disabled
                id="nomineeIdentifier"
                type="text"
                value={nomineeIdentifier}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
                onChange={(e) => setShowNomineeIdentifier(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nomineeDOB">Date Of Birth *</InputLabel>
              <Input
                id="nomineeDOB"
                type="date"
                value={nomineeDob}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> supervisor_account </i>{" "}
                  </InputAdornment>
                }
                require="true"
                onChange={(e) => setShowNomineeDob(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nomineeRelationship">
                Relationship *
              </InputLabel>
              <Input
                id="nomineeRelationship"
                type="text"
                value={nomineeRelationship}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> supervisor_account </i>{" "}
                  </InputAdornment>
                }
                require="true"
                onChange={(e) => setShowNomineeRelationship(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth={true}>
              {isLoading === false && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="submit"
                >
                  Submit
                </Button>
              )}{" "}
              {isLoading === true && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className="loginLoader" />
                </Button>
              )}
            </FormControl>
            <FormControl
              fullWidth={true}
              className="forgotPwdLink"
              onClick={() => {
                setShowNomineePopup(!nomineePopup);
              }}
              style={{
                marginBottom: "20px",
                textAlign: "center",
                boxOrient: "0",
                display: "block",
                fontSize: "20",
              }}
            >
              Cancel
            </FormControl>
          </form>
        </div>
      </Grid>
    </Grid>
  );
  return (
    <>
      <Grid container spacing={2}>
        <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Add Nominee"
          style={{ color: "#fff" }}
          className={classes.fab}
          onClick={toggleAddNominee}
        >
          <AddIcon />
        </Fab>
        <Grid item md={12}>
          <h3 style={{ float: "left", marginTop: "7px", marginBottom: 0 }}>
            Nominee Details:
          </h3>
        </Grid>

        {isLoading ? (
          <div className="cardStyleForDashboardItems">
            <CircularProgress />
          </div>
        ) : (
          <div className="cardStyleForDashboardItems">
            {nomineeList.length > 0 ? (
              nomineeList.map((data, index) => {
                return (
                  <Grid item md={4} xs={12} key={index}>
                    <Card className={classes.root} variant="outlined">
                      <CardActionArea>
                        <CardMedia
                          className={classes.media}
                          image={GrowerIcon}
                          title="nominee"
                        />
                        <CardContent>
                          <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                          >
                            <b>{data.nomineeName}</b>
                          </Typography>
                          <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                          >
                            <span>
                              <b>Mobile:</b> {data.nomineeMobile}
                            </span>
                            <br />
                            <br />
                            <span>
                              <b>Email:</b> {data.nomineeEmail}
                            </span>
                            <br />
                            <br />
                            <span>
                              <b>DOB:</b> {data.dob}
                            </span>
                            <br />
                            <br />
                            <span>
                              <b>Relationship:</b> {data.relationship}
                            </span>
                            <br />
                            <br />
                            <span>
                              <b>ID:</b> {data.identifier}
                            </span>
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <span
                            style={{
                              border: "1px solid red",
                              borderRadius: 4,
                              padding: 8,
                            }}
                            type="button"
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            onClick={() =>
                              toggleDeleteNominee(
                                data.identifier,
                                data.nomineeName
                              )
                            }
                          >
                            Delete
                          </span>
                          <span
                            type="button"
                            style={{
                              border: "1px solid green",
                              borderRadius: 4,
                              padding: 8,
                            }}
                            className={classes.uploadButton}
                            onClick={() => updateNominee(data)}
                          >
                            Update
                          </span>
                        </CardActions>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <div>
                <h4>Not Found</h4>
              </div>
            )}
          </div>
        )}
      </Grid>
      <Modal
        open={nomineePopup}
        onClose={toggleUpdateNominee}
        aria-labelledby="updateNominee"
        aria-describedby="updateNominee-description"
      >
        {nomineeBody}
      </Modal>

      <Modal
        open={showDeleteNominee}
        onClose={toggleAddNominee}
        aria-labelledby="deleteNominee"
        aria-describedby="deleteNominee-description"
      >
        {deleteNomineeBody}
      </Modal>
      <Modal
        open={showManageNominee}
        onClose={toggleAddNominee}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {manageNomineeBody}
      </Modal>
    </>
  );
}
