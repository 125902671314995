import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import MainService from "../../../../Common/MainService";
import assetsOrderRequestTypesConfig from "./AssetRequestTypeConfig";
import FormForAssetBuyandWhiteLabelOrderRequest from "./FormForAssetBuyandWhiteLabelOrderRequest";
import FormForAssetOrder from "./FormForAssetOrder";
const MaintainOrderRequestForms = ({
  openModel,
  closeModel,
  verticalsIds,
  categoryTypes,
  assertTypes,
  models,
  reLoad,
  reqTypesBasedOnRoleandUser,
  props,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getReqVal, setReqVal] = useState("");
  const [getreqArr, setReqArr] = useState([]);
  const [selectedReqType, setSelectedReqType] = useState("");
  const userType = localStorage.getItem("userType");
  const userRole = localStorage.getItem("UserRole");

  useEffect(() => {
    let userConfig;

    if (
      reqTypesBasedOnRoleandUser === undefined ||
      reqTypesBasedOnRoleandUser === null ||
      typeof reqTypesBasedOnRoleandUser !== "object"
    ) {
      userConfig = assetsOrderRequestTypesConfig[userRole]?.[userType] || [];
    } else {
      userConfig = reqTypesBasedOnRoleandUser[userRole]?.[userType] || [];
    }

    setReqVal(userConfig.length > 0 ? userConfig[0]?.type : "");
    setSelectedReqType(userConfig?.length > 0 ? userConfig[0]?.type : "");
    setReqArr(userConfig);
  }, [userRole, userType, reqTypesBasedOnRoleandUser]);

  const handleButtonClick = (type) => {
    setReqVal(type);
    setSelectedReqType(type);
  };

  return (
    <>
      <Dialog
        fullScreen
        open={openModel}
        onClose={closeModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              margin: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getreqArr &&
              getreqArr?.map((reqType) => (
                <Box
                  key={reqType.type}
                  sx={{
                    width: { xs: "100%", sm: "70%", md: "auto" },
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => handleButtonClick(reqType.type)}
                    sx={{
                      backgroundColor:
                        selectedReqType === reqType.type
                          ? "#3ECF8E"
                          : "inherit",
                      color:
                        selectedReqType === reqType.type
                          ? "primary"
                          : "inherit",
                      width: "100%",
                      height: "100%",
                      minWidth: 0,
                      minHeight: 0,
                    }}
                  >
                    {reqType.type}
                  </Button>
                </Box>
              ))}
          </Box>
          {(() => {
            if (
              getReqVal === "asset-buy-order-req" ||
              getReqVal === "whitelabel-asset-request"
            ) {
              return (
                <FormForAssetBuyandWhiteLabelOrderRequest
                  reqTypes={getReqVal}
                  verticalsIds={verticalsIds}
                  categoryTypes={categoryTypes}
                  assertTypes={assertTypes}
                  models={models}
                  reLoad={reLoad}
                />
              );
            }
          })()}
          {(() => {
            if (
              getReqVal === "cf-agri-assets-order-req" ||
              getReqVal === "transport-asset-order-req" ||
              getReqVal === "storage-asset-order-req"
            ) {
              return (
                <FormForAssetOrder
                  reqTypes={getReqVal}
                  verticalsIds={verticalsIds}
                  categoryTypes={categoryTypes}
                  assertTypes={assertTypes}
                  models={models}
                  reLoad={reLoad}
                  props={props}
                />
              );
            }
          })()}
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" onClick={closeModel}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(MaintainOrderRequestForms);
