import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";

const commonProps = {
  className1: "aboutBgGrey",
};

const growerSection = (
  <HomeSubSection
    {...commonProps}
    demoTrue = "true"
    buttoncont1="Request for Demo"
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="processingSupport2"
    descriptionTitle="Create & manage own farmer community by using CBO solution"
    description="GBR empowers Agri-entrepreneurs to establish and nurture their unique farmer communities, fostering local trade and collaboration. Through GBR, agri-entrepreneurs can connect with fellow agri-producers, share valuable insights, and facilitate seamless transactions, ultimately revitalizing the local agricultural ecosystem while promoting sustainable growth."
  />
);

const ffSection = (
  <HomeSubSection
    {...commonProps}
    buttoncont1="Request for Demo"
    demoTrue = "true"

    className="rowReverseAbout--mod borderedCards borderedCards--mod"
    className3="processingSupport4"
    descriptionTitle="Contract Farming by using OFC account"
    description="GBR offers a unique service that facilitates agri-entrepreneurs in engaging in contract farming through the convenience of their OFC (Organic farming company) accounts. This streamlined platform not only simplifies the process of managing contractual agreements but also provides a centralized hub for entrepreneurs to efficiently oversee and optimize their agricultural operations."
  />
);

export default function ProcessingSubsection(props) {
  return (
    <div className="subSectionWrapper" id="FieldSupport">
      <h2 className="container-title text-center">Business Growth Service</h2>
      {props.customPropValue === "Grower" ? growerSection : null}
      {props.customPropValue === "FF" ? ffSection : null}
      {(props.customPropValue !== "Grower" && props.customPropValue !== "FF") && (
        <>
          {growerSection}
          {ffSection}
        </>
      )}
    </div>
  );
}
