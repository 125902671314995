import React from "react";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import "./NGO.css";
import NGONeeds from "./NGONeeds";
import NGOServices from "./NGOServices";

export default function NGO() {
  return (
    <>
      <UpdatesBanner className="ap-ngo" bannerText="NGOs" />
      <NGONeeds />
      <NGOServices />
    </>
  );
}
