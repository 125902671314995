import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import "./LandingPageBanner.css";
import { NavLink } from "react-router-dom";
export default function LandingPageBanner() {
  return (
    <div className="container-banner">
      {/* <div className="overlay__bg"></div> */}
      <Container>
        <Grid container spacing={2}>
          <Grid item md={8} sm={12} xs={12} className="p-0">
            <div className="abn-content">
              <h1>Empowering Next Generation Core Agri-food business</h1>
              <p>
              Introducing Agribusiness Network (ABN) as a Trusted & Traceable Supply Chain to transform Core Agri-food businesses to Digital Enterprises
                {/* Creating Digital Trust among online Agrifood MSME community
                members to transact securely */}
                {/* We help Agri MSME to transform Digitally by providing Ecosystem and ABN */}
                {/* Introducing Agribusiness Network (ABN)  and Trusted Supply Chain to transform  Agribusiness to  Digital Enterprises */}
                {/* We transform Brands to Digital Enterprise so that they can BUILD, MANAGE, OWN and OPERATE their Digital Communities to maximize profit  */}
              </p>
              {/* <p>
              OWN | OPERATE | MANAGE
              </p> */}
              <Button variant="contained" color="primary">
                <NavLink  to={{
                  pathname: "auth/3/signup",
                  state: { customData: "present" },
                }} 
                // onClick={() => {
                //   localStorage.setItem("userType", "");
                // }}
    
                >Become a Digital Enterprise </NavLink>
              </Button>
              {/* <br className="d-md-none d-block"></br>
              <Button
                variant="contained"
                color="primary"
                className="ms-md-3 ms-0 mt-md-0 mt-3"
              >
                <NavLink to="/ForSMEs">Explore Trusted Community </NavLink>
              </Button> */}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
