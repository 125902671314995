import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./Company.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import ReactPlayer from "react-player/lazy";
import SearchBar from "material-ui-search-bar";
import { Box, Button } from "@mui/material";
import { PieChart } from "../../Common/CommonContent/PieChart";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  menuPaper: {
    width: 200,
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
  menuPaperForm: {
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
  productVideo: {
    display: "inline-flex",
    maxWidth: 320,
    maxHeight: 180,
    borderRadius: 20,
  },
  productName: {
    display: "inline-flex",
    maxWidth: 300,
  },
}));

export default function Company({userType,categoryName}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getPMCUsers, setPMCUsers] = useState([]);
  const [getPMCUsersFilter, setPMCUsersFilter] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [searched, setSearched] = useState("");
  const [getMsmeCaid, setMsmeCaid] = useState("");
  const [getAdvisorSchemes, setAdvisorSchemes] = useState(false);
  const [getSchemesData, setSchemesData] = useState([]);

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = getPMCUsersFilter.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setPMCUsers(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = getPMCUsersFilter.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setPMCUsers(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    getAllAdvisorUsers();
  }, [setPMCUsers]);

  const getAllAdvisorUsers = () => {
    MainService.getGlobalUsers(userType)
      .then((data) => {
        if (data?.length > 0) {
          setPMCUsers(data);
          setPMCUsersFilter(data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error("");
      });
  };

  const getListOfCategorys = () => {
    MainService.getListOfCategorys()
      .then((data) => {
        const pmcCategoriesFilter = data.filter((val) => {
          return val.categoryname === categoryName;
        });
        setMsmeCaid(pmcCategoriesFilter[0]?.categoryid);
      })
      .catch((e) => {
        return NotificationManager.error("");
      });
  };

  useMemo(() => {
    getListOfCategorys();
  }, []);

  const msmeSchemesHandler = (userData) => {
    MainService.getModelProductsBasedOnCategoryandUser(
      parentId,
      userData.username,
      getMsmeCaid
    )
      .then((data) => {
        setSchemesData(data.products);
        setAdvisorSchemes(!getAdvisorSchemes);
      })
      .catch((e) => {
        return NotificationManager.error("");
      });
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <div className="MuiGrid-root">
          <div>
            <div style={{ float: "right" }}>
              <Button
                startIcon={<ListIcon />}
                size="large"
                onClick={listViewClick}
              ></Button>
              <Button
                startIcon={<GridIcon />}
                size="large"
                onClick={gridViewClick}
              ></Button>
            </div>
            <PieChart
              value={getPMCUsers.length}
              name="COMPANIES"
              total="TOTAL COMPANIES"
            />
          </div>
          {listView && (
            <Box>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          Company Name
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Phone
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Email
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Option
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getPMCUsers.length > 0 ? (
                        getPMCUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((users, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="center">
                                  {users.username}
                                </TableCell>
                                <TableCell align="center">
                                  {users.phone}
                                </TableCell>
                                <TableCell align="center">
                                  {users.email}
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      msmeSchemesHandler(users);
                                    }}
                                  >
                                    TOOLS
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow align="center">
                          <TableCell align="center" colSpan={12}>
                            Companie'S Not Found.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={getPMCUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          )}
          {gridView && (
            <div className="cardStyleForDashboardItems">
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <Box>
                  <SearchBar
                    value={farmgridsearched}
                    onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                    onCancelSearch={() => cancelFarmGridSearch()}
                  />
                  {getPMCUsers.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {getPMCUsers.map((users, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <p>
                            <b>Comapny Name:</b>
                            {users.username}
                          </p>
                          <p>
                            <b>Phone:</b>
                            {users.phone}
                          </p>
                          <p>
                            <b>Email:</b>
                            {users.email}
                          </p>
                          <div style={{ margin: "5px", width: "100%" }}>
                            <Button
                              style={{ margin: "5px", width: "40%" }}
                              type="button"
                              variant="contained"
                              onClick={() => {
                                msmeSchemesHandler(users);
                              }}
                            >
                              TOOLS
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>Companie'S Not Found.</h4>
                  )}
                </Box>
              )}
            </div>
          )}
        </div>

        <Dialog
          fullScreen={fullScreen}
          open={getAdvisorSchemes}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{``}</DialogTitle>
          <DialogContent>
            <div className="cardStyleForDashboardItems">
              <h2 className="cardactiontitle">** MACHINERY PRODUCTS **</h2>
              {getSchemesData.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {getSchemesData.map((product, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Business Id:</b>
                          {product.businessid}
                        </p>
                        <p>
                          <b>Product Id:</b>
                          {product.productid}
                        </p>
                        <p>
                          <b>Product Name:</b>
                          <p className={classes.productName}>
                            {product.productfriendlyname}
                          </p>
                        </p>
                        <p>
                          <b>Origin:</b>
                          {product.country}
                        </p>
                        <p>
                          <b>Asset Type:</b>
                          {product.legacyassettype}
                        </p>
                        <p>
                          <b>Breed:</b>
                          {product.modelid}
                        </p>
                        <p>
                          <b>Model Friendly Name:</b>
                          {product.modelfriendlyname}
                        </p>
                        <p>
                          <b>Product Cost:</b>
                          {product.productcost} {product.unit}
                        </p>
                        {(() => {
                          switch (product.discount) {
                            case "":
                              break;
                            case 0:
                              break;
                            default:
                              return [
                                <div>
                                  <p>
                                    <b>Discount:</b>
                                    {product.discount}
                                  </p>
                                  <p>
                                    <b>Discounted Price:</b>
                                    {product.discountedprice} {product.unit}
                                  </p>
                                </div>,
                              ];
                          }
                        })()}
                        <p>
                          <b>Item Count:</b>
                          {product.itemcount}
                        </p>
                        <div className="product-videoimage">
                          {(() => {
                            switch (product.productimageurl) {
                              case "":
                                break;
                              case "null":
                                break;
                              default:
                                return (
                                  <p>
                                    <img
                                      src={product.productimageurl}
                                      className="img-thumbnail"
                                    ></img>
                                  </p>
                                );
                            }
                          })()}
                          {(() => {
                            switch (product.productqrcodeurl) {
                              case "":
                                break;
                              case "null":
                                break;
                              default:
                                return (
                                  <p>
                                    <img
                                      src={product.productqrcodeurl}
                                      className="img-qr"
                                    ></img>
                                  </p>
                                );
                            }
                          })()}
                        </div>
                        <div className="product-videoimage">
                          {(() => {
                            switch (product.qacertificateurl) {
                              case "":
                                break;
                              case "null":
                                break;
                              default:
                                return (
                                  <p>
                                    <img
                                      src={product.qacertificateurl}
                                      className="img-thumbnail"
                                    ></img>
                                  </p>
                                );
                            }
                          })()}
                          {(() => {
                            switch (product.labcertificate) {
                              case "":
                                break;
                              case "null":
                                break;
                              default:
                                return (
                                  <p>
                                    <img
                                      src={product.labcertificate}
                                      className="img-thumbnail"
                                    ></img>
                                  </p>
                                );
                            }
                          })()}
                        </div>
                        {(() => {
                          switch (product.productvideourl) {
                            case "":
                              break;
                            case "null":
                              break;
                            default:
                              return (
                                <p>
                                  <ReactPlayer
                                    className={classes.productVideo}
                                    url={product.productvideourl}
                                    pip={true}
                                    controls={true}
                                    playing={false}
                                    muted={true}
                                  />
                                </p>
                              );
                          }
                        })()}
                        {(() => {
                          switch (product.producthistoryurl) {
                            case "":
                              break;
                            case "null":
                              break;
                            default:
                              return (
                                <p>
                                  <a
                                    href={product.producthistoryurl}
                                    target="_blank"
                                  >
                                    Product History
                                  </a>
                                </p>
                              );
                          }
                        })()}
                        <p>
                          <b>Status:</b>
                          {product.status}
                        </p>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>MACHINERY PRODUCTS NOT FOUND.</h4>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                setAdvisorSchemes(!getAdvisorSchemes);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
