import React, { useState, useEffect } from "react";
import { makeStyles,  } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function StatementSummaryyPageComponent() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [summaryDetails, setSummaryDetails] = useState({totalwhitelabelcost: 0, totalsellingcost: 0});
  const [state, setState] = useState({
    columns: [
      { title: "AAMC", field: "assetdeveloperid" },
      { title: "Breed", field: "modelid" },
      { title: "Asset Name", field: "assetname" },      
      { title: "Invested NAV(Rs)", field: "whitelabelcost" },
      { title: "Sold NAV(Rs)", field: "sellingcost" },
      
    ],
    data: [],
  });

  useEffect(() => {
    getStatementSummary();
  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  const getStatementSummary = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;
    MainService.getStatementSummary(username)
    .then((data) => {
      setIsLoading(false);
      setState({...state, data: data.statementSummary});
      setSummaryDetails({...data.totalcost});
    })
    .catch((e) => {
      setIsLoading(false);
      console.error(e);
      return NotificationManager.error(e);
    });
  };

   /*const [selectedStartDate, setSelectedStartDate] = React.useState(
    new Date(AppData.defaultStartDate)
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    new Date(AppData.defaultEndDate)
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  }; */
  const convertCurrency = (data) => {
    return new Intl.NumberFormat("en-IN", {
      maximumSignificantDigits: 10,
    }).format(data);
  };
  return (
    <Grid container spacing={1}>
      <Grid item md={12} xs={12}>
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> //To Do Later
          <Grid container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline-start"
              label="Summary Start Date"
              value={selectedStartDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />

            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline-end"
              label="Summary End Date"
              value={selectedEndDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider> */}
      </Grid>
      <Grid item md={12} xs={12}>
        <Grid container className={classes.gridRoot} spacing={2}>
          <Grid item md={4} xs={12}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Total Invested Price
                </Typography>
                <Typography variant="h5" component="h2">
                  {isLoading?(<CircularProgress/>):( convertCurrency(summaryDetails.totalwhitelabelcost))}
                 &nbsp;Rs
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={4} xs={12}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Total Selling Price
                </Typography>
                <Typography variant="h5" component="h2">
                {isLoading?(<CircularProgress/>):( convertCurrency(summaryDetails.totalsellingcost))}
                &nbsp;Rs
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={4} xs={12}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Profit
                </Typography>
                <Typography variant="h5" component="h2">
                {isLoading?(<CircularProgress/>):( convertCurrency(summaryDetails.totalsellingcost - summaryDetails.totalwhitelabelcost))}&nbsp;Rs
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} xs={12}>
        <MaterialTable
          title="Account Statement Summary"
          columns={state.columns}
          data={state.data}
          editable={{}}
        ><CircularProgress/></MaterialTable>
      </Grid>
    </Grid>
  );
}
