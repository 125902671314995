import { Button } from "@mui/material";
import React, { useState } from "react";
import BookingReqComp from "./BookingReqComp"; 

const OrderMainComp = () => {
  const [getBookingReq, setBookingReq] = useState(true);

  const bookingRequest = () => {
    setBookingReq(getBookingReq);
  };
  
  return (
    <>
    <div className="topHeadersWrapper">
      <div className="topHeaders">
        <Button
          color="success"
          size="large"
          variant="contained"
          onclick={() => {
            bookingRequest();
          }}
        >
          Booking Requests
        </Button>
      </div>
    </div>

    {getBookingReq && <BookingReqComp/>}
    </>
  );
  
};

export default OrderMainComp;
