import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory, useParams } from "react-router-dom";
import MainService from "../../Common/MainService";

const ViewRfqDoc = () => {
  const { Id, docType } = useParams();
  const history = useHistory();
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getQuoteDetails, setQuoteDetails] = useState({});

  useEffect(() => {
    if (Id === null || Id === undefined || Id === "") return;
    viewRfqDocHandler();
  }, [Id]);

  const viewRfqDocHandler = () => {
    MainService.viewRfqDoc(Id, parentId, username, docType)
      .then((res) => {
        setQuoteDetails(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const handleGoBack = () => {
    history.goBack(); // This function navigates back to the previous route
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => handleGoBack()}
        ></Button>
      </Box>
      <Box>
        {getQuoteDetails && getQuoteDetails.url && (
          <iframe
            src={getQuoteDetails.url}
            style={{ width: "100%", height: "500px", marginTop: "10px" }}
            title="RFQ Document"
          ></iframe>
        )}
        {!getQuoteDetails.url && (
          <Typography variant="h6" style={{ textAlign: "center" }}>
            No document available
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ViewRfqDoc;
