import React, { lazy, useState, useEffect, Component } from "react";
import MatTable from "../../../../../containers/table/Table";
import SearchBar from "../../../../../containers/SearchBar/SearchBar";
import MainService from "../../../Common/MainService";
import "./location.css";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Box,
  Button,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const fields = ["", "ID", "Tag", "Address", "Geo-Coordinates", "Created"];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "white",
  border: "none",
  borderRadius: "10px",
  boxShadow: 24,
  color: "black",
  p: 4,
};

const LocationManagement = ({ locHandler }) => {
  const [displayLocation, setDisplayLocation] = useState(false);
  const [locations, setLocations] = useState({ locList: [] });
  const [settings_modal, setSettings_modal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selected_items, setSelected_items] = useState("");
  const [visible, setVisible] = useState(10);
  const [viewLocation, setViewLocation] = useState(false);
  const [viewRoute, setViewRoute] = useState(false);
  const [viewTrips, setViewTrips] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();

  const classes = makeStyles();
  useEffect(() => {
    getLocationInfo();
    handlelocDetails();
    // handleSearch();
  }, []);

  const handleSearch = (searchText) => {
    setSearchQuery(searchText); // Step 2: Update search query state
  };

  const filteredLocations = searchQuery
    ? locations.locList.filter((item) =>
        item.fullAddress.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : locations.locList;

  const clearSearch = () => {
    setSearchQuery(""); // Step 3: Clear the search query
  };

  const findItem = () => {
    console.log("item");
    const items = locations?.locList;
    const filterData = items.filter((item) => item.locId !== selected_items);
    setLocations({ locList: filterData });
    setOpen(false);
  };

  const delLocation = async () => {
    let selectedItems = selected_items;
    console.log("deletereeeee", selectedItems);
    const delLoc = await MainService.delLocation(username, selectedItems);
    console.log("delete item data", delLoc);
    if (delLoc) {
      findItem();
    }
  };

  const onSelect = (item) => {
    console.log("onselect********** ", item);
    if (selected_items === item) {
      setSelected_items("");
    } else {
      setSelected_items(item);
    }
  };

  const getLocationInfo = async () => {
    const { locList, locCount } = await MainService.getLocationInfo(username);

    locList.map((item) => {
      item.geoLoc =
        "Latitude:" +
        item.geoLocation?.lat +
        " " +
        "Longitude: " +
        item.geoLocation?.lng;
      item.fullAddress = item.address?.city;
    });
    setLocations({ locCount, locList });
  };

  const loadMoreLoc = () => {
    setVisible(visible + 10);
  };

  const actionChangeHandler = (event) => {
    if (event.target.value === "delete") {
      setOpen(true);
      console.log("itemssssssssssss", locations);
    }
  };

  const closeModelHandler = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteHandler = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  //handle location details
  const handlelocDetails = async (event) => {
    if (selected_items) {
      try {
        const locDetails = await MainService.getDetailsOfLocId(
          username,
          selected_items
        );

        console.log("Location Details....//===", locDetails);

        locHandler(locDetails);
      } catch (error) {
        console.error("Error fetching location details:", error);
      }
    }
    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        {/* search bar */}
        <SearchBar
          placeholder={"Search Location"}
          onSubmit={handleSearch}
          username={username}
        />

        <Box>
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            cursor="pointer"
            sx={{ margin: "1rem" }}
            title="Add Location"
            onClick={() => locHandler()}
          >
            <AddIcon />
          </Fab>

          <Fab
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            color="secondary"
            
            disabled={selected_items === ""}
          >
            <MoreVertIcon />
          </Fab>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openAction}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handlelocDetails}>Modify Details</MenuItem>
            <MenuItem onClick={deleteHandler}>Delete</MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box sx={{ marginTop: "1rem" }}>
        <MatTable
          fields={fields}
          rows={filteredLocations.slice(0, visible)}
          keys={[
            "locId",
            "tagName",
            "fullAddress",
            "geoLoc",
            "lastModifiedDate",
          ]}
          total={locations.locCount}
          onSelect={onSelect}
          unique="locId"
          selected={selected_items}
          change_page={getLocationInfo}
        />
      </Box>
      <Box sx={{ mt: 2, width: "100%" }}>
        <Button variant="outlined" color="primary" onClick={loadMoreLoc}>
          Load More
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={closeModelHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            CONFIRM DELETE
          </Typography>
          <hr />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure?
          </Typography>
          <hr />

          <Box sx={{ marginTop: "0.5rem", display: "flex", gap: 1 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="outlined" onClick={delLocation}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LocationManagement;
