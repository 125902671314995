import React, { useState } from "react";
import { Box, styled, Container, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import investment from "../../../../resources/img/investment.svg";
import warehouse from "../../../../resources/img/warehouse.svg";
import shipped from "../../../../resources/img/shipped.svg";
import brochure from "../../../../resources/img/brochure.svg";
import Storage from "../Storage/Storage";
import Transporter from "../Transporter/Transporter";
import FinanceInvestor from "../FinanceInvestor/FinanceInvestor";
import FandBCatalog from "../FandBCatalog/FandBCatalog";

const Publish = () => {
  const [getStorageCondition, setStorageCondition] = useState(true);
  const [getTransporter, setTransporter] = useState(false);
  const [getFinanceInvestor, setFinanceInvestor] = useState(false);
  const [getCatalog, setCatalog] = useState(false);
  const [activeButton, setActiveButton] = useState("storage");
  const userType = localStorage.getItem("userType");

  const data = [
    {
      title: "Storage",
      type: "storage",
      image: `${warehouse}`,
      usertype: [],
    },
    {
      title: "Transporter",
      type: "transporter",
      image: `${shipped}`,
      usertype: [],
    },
    {
      title: "Finance-Investor",
      type: "financeinvestor",
      image: `${investment}`,
      usertype: [],
    },
    {
      title: "F&B-Catalog",
      type: "catalog",
      image: `${brochure}`,
      usertype: [],
    },
  ];

  const showHandler = (Type) => {
    setStorageCondition(false);
    setTransporter(false);
    setFinanceInvestor(false);
    setCatalog(false);
    setActiveButton(Type);

    if (Type === "storage") {
      setStorageCondition(true);
    } else if (Type === "transporter") {
      setTransporter(true);
    } else if (Type === "financeinvestor") {
      setFinanceInvestor(true);
    } else if (Type === "catalog") {
      setCatalog(true);
    }
  };

  const StyledIconButton = styled(IconButton)(({ theme, isActive }) => ({
    color: isActive ? theme.palette.primary.main : theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  }));

  return (
    <>
      <Grid container spacing={1}>
        <Container
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "row", sm: "row", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            background: "whitesmoke",
            margin: "2px 2px 12px 2px",
          }}
        >
          <Box sx={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
            {data &&
              data
                ?.filter((val) => !val?.usertype.includes(userType))
                .map((val, i) => (
                  <Tooltip key={val.title} title={val.title}>
                    <StyledIconButton
                      onClick={() => {
                        showHandler(val.type);
                      }}
                      isActive={activeButton === val.type}
                    >
                      <img
                        src={val.image}
                        style={{
                          width: "2rem",
                          height: "2rem",
                          objectFit: "cover",
                        }}
                      />
                    </StyledIconButton>
                  </Tooltip>
                ))}
          </Box>
        </Container>

        <Grid item md={12} xs={12}>
          {getStorageCondition && <Storage />}
          {getTransporter && <Transporter />}
          {getFinanceInvestor && <FinanceInvestor />}
          {getCatalog && <FandBCatalog />}
        </Grid>
      </Grid>
    </>
  );
};

export default Publish;
