import { Box, Button } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import MainService from "../../../../Common/MainService";
import ProductsInvoiceTable from "./ProductsInvoiceTable";

const ProductsInvoice = ({ reqTypes }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getReqType, setReqType] = useState("");
  const [selectedReqType, setSelectedReqType] = useState("");
  const [getCatType, setCatType] = useState("");
  const [selectedCatType, setSelectedCatType] = useState("");
  const [allReqTypes, setAllReqTypes] = useState([]);

  useEffect(() => {
    const requestTypes = [
      { val: "agri-buy-products", type: "agri-buy-products-order-req" },
      { val: "cm-product", type: "cm-product-order-req" },
      { val: "cf-agri-raw-products", type: "cf-agri-raw-products-order-req" },
      { val: "transport-product", type: "transport-product-order-req" },
      { val: "storage-product", type: "storage-product-order-req" },
    ];

    const reqConditionalTypes =
      reqTypes && Array.isArray(reqTypes) && reqTypes.length > 0
        ? reqTypes
        : requestTypes;

    setSelectedReqType(reqConditionalTypes[0]?.type);
    setReqType(reqConditionalTypes[0]?.type);
    setAllReqTypes(reqConditionalTypes);
  }, [reqTypes]);

  const categoryTypes = [
    {
      val: "Processed",
      type: "PROCESSED-PRODUCTS",
      requestTypes: [
        "agri-buy-products-order-req",
        "cm-product-order-req",
        "transport-product-order-req",
        "storage-product-order-req",
      ],
    },
    {
      val: "Raw",
      type: "RAW-PRODUCTS",
      requestTypes: [
        "agri-buy-products-order-req",
        "cf-agri-raw-products-order-req",
        "transport-product-order-req",
        "storage-product-order-req",
      ],
    },
    {
      val: "Semi Processed",
      type: "SEMI-PROCESSED",
      requestTypes: [
        "agri-buy-products-order-req",
        "cm-product-order-req",
        "transport-product-order-req",
        "storage-product-order-req",
      ],
    },
  ];

  const handleButtonClick = (type) => {
    setReqType(type);
    setSelectedReqType(type);
  };

  const categoryButtonHandler = (type) => {
    setCatType(type);
    setSelectedCatType(type);
  };

  return (
    <>
      <Box className="container" id="mainContentList">
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            margin: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {allReqTypes &&
            allReqTypes?.map((reqType) => (
              <Box
                key={reqType.type}
                sx={{
                  width: { xs: "100%", sm: "70%", md: "auto" },
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handleButtonClick(reqType.type)}
                  sx={{
                    backgroundColor:
                      selectedReqType === reqType.type ? "#3ECF8E" : "inherit",
                    color:
                      selectedReqType === reqType.type ? "primary" : "inherit",
                  }}
                >
                  {reqType.val}
                </Button>
              </Box>
            ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            margin: "15px",
            alignItems: "center",
            justifyContent: "left",
          }}
        >
          {categoryTypes &&
            categoryTypes
              ?.filter((reqType) => {
                return reqType.requestTypes.includes(getReqType);
              })
              .map((reqType) => (
                <Box
                  key={reqType.type}
                  sx={{
                    width: { xs: "100%", sm: "70%", md: "auto" },
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => categoryButtonHandler(reqType.type)}
                    sx={{
                      backgroundColor:
                        selectedCatType === reqType.type
                          ? "#3ECF8E"
                          : "inherit",
                      color:
                        selectedCatType === reqType.type
                          ? "primary"
                          : "inherit",
                    }}
                  >
                    {reqType.val}
                  </Button>
                </Box>
              ))}
          {(() => {
            if (String(getCatType) !== "") {
              return (
                <>
                  <Button
                    variant="none"
                    color="secondary"
                    onClick={() => {
                      setCatType("");
                      setSelectedCatType("");
                    }}
                  >
                    Clear Filter
                  </Button>
                </>
              );
            }
          })()}
        </Box>

        <Box className="MuiGrid-root">
          <ProductsInvoiceTable
            reqType={getReqType}
            categoryType={getCatType}
          />
        </Box>
      </Box>
    </>
  );
};

export default memo(ProductsInvoice);
