import React from 'react'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import "./AEOnboardingService.css"

import AROnboardingServiceSection from './AROnboardingServiceSection'
export default function AROnboardingService() {
  return (
    <>
     <UpdatesBanner 
      className="onBoardingBanner text-white"
      bannerText="AR Onboarding Service"/>
      <div className='subSectionWrapper'>
        <AROnboardingServiceSection/>
      </div>
    </>
  )
}
