import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./FieldSupportService.css";
import FieldSupportServiceSection1 from "./FieldSupportServiceSection1";
import FArmerCTATab from "../FarmerCTATab/FArmerCTATab";

export default function FieldSupportService() {
  return (
    <>
      <UpdatesBanner
        className="fieldSupportService"
        bannerText={["Field Support Service"]}
      />
      <div>
        <FieldSupportServiceSection1 />
        {/* <FArmerCTATab /> */}
      </div>
    </>
  );
}
