import { Box, Typography } from '@mui/material';
import React from 'react';

const LoadingPage = () => {
  return (
    <Box
      sx={{
        background: '#000000bd',
        position: 'fixed',
        top: 0,
        left: '300px',
        height: '100%',
        width: 'calc(100% - 300px)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Typography sx={{ color: '#fff', fontSize: '1.4rem' }}>
          Loading...
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingPage;
