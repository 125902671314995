import React, { useState, useEffect } from "react";
import "./ProductionNetworkComponent.css";
import { Button } from "@mui/material";
import '../MyOrders/MyOrderStyleComponent.css';
import ViewOperatorsComponent from "../ViewOperators/ViewOperatorsComponent";
import ViewDeveloperQAsComponent from "../ViewDeveloperQAs/ViewDeveloperQAsComponent";
import ViewExecutivesComponent from "../ViewExecutives/ViewExecutivesComponent";
import ViewProductionManagersComponent from "../ViewProductionManagers/ViewProductionManagersComponent";
import MainService from "../../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import FFcomponent from "../FF/FFComponent";
import AAMCAgentComponent from "../AAMCAgent/AAMCAgetComponent";
import ViewFarmersComponent from "../ViewFarmers/ViewFarmersComponent";

export default function AAMCUserManagementComponent(props) {
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [roleNameValue, setRoleNameValue] = useState("");
  const userType = localStorage.getItem("userType");
  const UserRole = localStorage.getItem("UserRole");

  useEffect(() => {
    getRoleName();
  }, []);

  const getRoleName = () => {
    MainService.getRole(parentId, username)
      .then((res) => {
        setRoleNameValue(res.role);
        console.log("role P n/w res:", res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const [activeComponent, setActiveComponent] = useState("Agent");

  const setActiveComponentByName = (componentName) => {
    setActiveComponent(componentName);
  };

  const renderComponent = (component) => {
    switch (component) {
      case "Operators":
        return <ViewOperatorsComponent />;
      case "DeveloperQAs":
        return <ViewDeveloperQAsComponent />;
      case "Executives":
        return <ViewExecutivesComponent />;
      case "ProductionManagers":
        return <ViewProductionManagersComponent />;
      case "Farmers":
        return <ViewFarmersComponent userType="Grower" />;
      case "FF":
        return <FFcomponent />;
      case "Agent":
        return <AAMCAgentComponent />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="container">
        <h2>User Management</h2>
        <div className="topHeadersWrapper">
          {(UserRole === "ADMIN" || UserRole === "PREMIUM") && (
            <Button
              size="small"
              variant="contained"
              onClick={() => setActiveComponentByName("Agent")}
              style={{ marginRight: "10px" }}
              className={activeComponent === "Agent" ? "activeButton" : ""}
            >
              Agents
            </Button>
          )}

          {(UserRole === "STANDARD" ||
            UserRole === "PREFFERED" ||
            UserRole === "ADMIN" ||
            UserRole === "PREMIUM") && (
            <Button
              size="small"
              variant="contained"
              onClick={() => setActiveComponentByName("Operators")}
              style={{ marginRight: "10px" }}
              className={activeComponent === "Operators" ? "activeButton" : ""}
            >
              Operators
            </Button>
          )}

          {(UserRole === "ADMIN" || UserRole === "PREMIUM") && (
            <Button
              size="small"
              variant="contained"
              onClick={() => setActiveComponentByName("Executives")}
              style={{ marginRight: "10px" }}
              className={activeComponent === "Executives" ? "activeButton" : ""}
            >
              Executives
            </Button>
          )}

          {(UserRole === "PREFFERED" ||
            UserRole === "PREMIUM" ||
            UserRole === "ADMIN") && (
            <Button
              size="small"
              variant="contained"
              onClick={() => setActiveComponentByName("DeveloperQAs")}
              style={{ marginRight: "10px" }}
              className={activeComponent === "DeveloperQAs" ? "activeButton" : ""}
            >
              DeveloperQA
            </Button>
          )}
        
          {(UserRole === "BASIC" ||
            UserRole === "STANDARD" ||
            UserRole === "PREFFERED" ||
            UserRole === "PREMIUM" ||
            UserRole === "ADMIN") && (
            <Button
              size="small"
              variant="contained"
              onClick={() => setActiveComponentByName("Farmers")}
              style={{ marginRight: "10px" }}
              className={activeComponent === "Farmers" ? "activeButton" : ""}
            >
              Grower
            </Button>
          )}

          {(UserRole === "BASIC" ||
            UserRole === "STANDARD" ||
            UserRole === "PREFFERED" ||
            UserRole === "PREMIUM" ||
            UserRole === "ADMIN") && (
            <Button
              size="small"
              variant="contained"
              onClick={() => setActiveComponentByName("ProductionManagers")}
              style={{ marginRight: "10px" }}
              className={activeComponent === "ProductionManagers" ? "activeButton" : ""}
            >
              Production-Managers
            </Button>
          )}

          {(UserRole === "BASIC" ||
            UserRole === "STANDARD" ||
            UserRole === "PREFFERED" ||
            UserRole === "PREMIUM" ||
            UserRole === "ADMIN") && (
            <Button
              size="small"
              variant="contained"
              onClick={() => setActiveComponentByName("FF")}
              style={{ marginRight: "10px" }}
              className={activeComponent === "FF" ? "activeButton" : ""}
            >
              FF
            </Button>
          )}
        </div>
      </div>

      <div style={{ paddingTop: "20px" }}>
        {activeComponent && renderComponent(activeComponent)}
      </div>
    </>
  );
}
