import React from "react";
import OurPartners from "../OurPartners/OurPartners";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
export default function CommunityPartners() {
  return (
    <>
      <UpdatesBanner
        className="partnerbg" bannerText={" CMN Partners who helps food processing enterprises"}
        />

      {/* <NetworkBanner /> */}
      <div className="main-wrapper2">
        <OurPartners/>
      </div>
    </>
  );
}
