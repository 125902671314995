import React from 'react';

import Icon3 from "../../../images/ServiceIcons/veterinarian.svg";
import Icon4 from "../../../images/ServiceIcons/service.svg";
import Icon5 from "../../../images/ServiceIcons/ecology.svg";
import Icon6 from "../../../images/ServiceIcons/supply-chain-management.svg";

import OurServiceCardContainer from '../../OurServices/OurServiceCardContainer';



export default function LTNServices() {
    const LTNServices = [
   
        // {
        //   image: Icon1,
        //   title: " CBN-Agri Asset ",
        //   bodycopy:
        //     "Facilitate Agri asset distribution service for producers .",
        // },
       


        // {
        //   image: Icon2,
        //   title: " CBN-AgriMachinery ",
        //   bodycopy:
        //     "Facilitate machinery asset distribution service for producers.",
        // },


      


        {
            image: Icon4,
            title: "CBN-CBO",
            bodycopy:
              "Create agri-producers club for Organic certification and local trade (buy & sell)."
          },

         
          {
            image: Icon6,
            title: "Local supply chain",
            bodycopy:
              "Most efficient value chain service to buy from agri-producers and traders to create differentiated product."
          },

          {
            image: Icon6,
            title: "FFS Service",
            bodycopy:
              "CBN offers Farmer Facilitation Service (FFS) to streamline agricultural processes and support farmers, enhancing efficiency and productivity in farming practices."
          }, 
          
          {
            image: Icon6,
            title: "Distribution Service",
            bodycopy:
              "Facilitate asset distribution services for agricultural producers, encompassing both agricultural and machinery assets."
          }, {
            image: Icon6,
            title: "Asset Market Services",
            bodycopy:
              "CBN delivers Asset Market Services, facilitating efficient transactions and market operations for various assets, optimizing investment opportunities and liquidity."
          }, 

      ];
  return (
    <div className='main-wrapper2'>
    <OurServiceCardContainer services={LTNServices}  title="LTN Services"/>
    </div>
  )
}
