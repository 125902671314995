import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';

const InvoiceItemForm = ({
  invoiceItem,
  invoiceItemDetailsChangeHandler,
  addInvoiceItemHandler,
  addInvoiceItemError,
  saveButtonStatus,
  invoiceType,
}) => {
  const [invoiceItemError, setInvoiceItemError] = useState('');

  const { description, hsn_sac, quantity, unit, price_per_unit } = invoiceItem;

  const isDescriptionItemEmpty = !description.trim();
  const ishsn_sacItemEmpty = !hsn_sac.trim();
  const isQuantityEmpty = !quantity;
  const isUnitEmpty = !unit.trim();
  const isPricePerUnitEmpty = !price_per_unit;

  useEffect(() => {
    let timer;
    const validataionItem = () => {
      if (addInvoiceItemError) {
        if (isDescriptionItemEmpty) {
          setInvoiceItemError('description');
        } else if (ishsn_sacItemEmpty) {
          setInvoiceItemError('hsn_sac');
        } else if (isQuantityEmpty) {
          setInvoiceItemError('quantity');
        } else if (isUnitEmpty) {
          setInvoiceItemError('unit');
        } else if (isPricePerUnitEmpty) {
          setInvoiceItemError('price_per_unit');
        } else {
          setInvoiceItemError('');
        }
      }
    };
    timer = setTimeout(validataionItem, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [
    isDescriptionItemEmpty,
    ishsn_sacItemEmpty,
    isQuantityEmpty,
    isUnitEmpty,
    isPricePerUnitEmpty,
    addInvoiceItemError,
  ]);

  return (
    <Box
      component='form'
      sx={{
        padding: '1rem',
        borderRadius: '1rem',
        boxShadow:
          'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        // width: '80%',
        // maxWidth: '45rem',
        mx: 'auto',
        '& > *:not(:last-child)': { paddingBottom: '0.5rem' },
        background: '#F6F4F3',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          '&>*': { width: '100%' },
        }}
      >
        <Typography sx={{ display: 'flex', flex: 1 }}>
          Name of Product / Service
        </Typography>
        <TextField
          error={invoiceItemError === 'description'}
          id='standard-error'
          label='Please enter Name of Product / Service'
          placeholder='Please enter your name'
          variant='standard'
          sx={{ flex: 2 }}
          name='description'
          onChange={invoiceItemDetailsChangeHandler}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          '&>*': { width: '100%' },
        }}
      >
        <Typography sx={{ display: 'flex', flex: 1 }}>HSN/SAC</Typography>
        <TextField
          error={invoiceItemError === 'hsn_sac'}
          id='standard-error'
          label='Please enter Name of HSN/SAC'
          variant='standard'
          name='hsn_sac'
          sx={{ flex: 2 }}
          onChange={invoiceItemDetailsChangeHandler}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          '&>*': { width: '100%' },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        }}
      >
        <Typography sx={{ display: 'flex', flex: 1 }}>QTY</Typography>
        <TextField
          error={invoiceItemError === 'quantity'}
          id='standard-error'
          label='Please enter Name of Quantity'
          variant='standard'
          sx={{ flex: 2 }}
          type='number'
          name='quantity'
          onChange={invoiceItemDetailsChangeHandler}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          '&>*': { width: '100%' },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        }}
      >
        <Typography sx={{ display: 'flex', flex: 1 }}>Unit</Typography>
        <TextField
          error={invoiceItemError === 'unit'}
          id='standard-error'
          label='Please enter Name of Unit'
          variant='standard'
          sx={{ flex: 2 }}
          name='unit'
          onChange={invoiceItemDetailsChangeHandler}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          '&>*': { width: '100%' },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        }}
      >
        <Typography sx={{ display: 'flex', flex: 1 }}>Rate</Typography>
        <TextField
          error={invoiceItemError === 'price_per_unit'}
          id='standard-error'
          label='Please enter Name of Rate'
          variant='standard'
          sx={{ flex: 2 }}
          type='number'
          name='price_per_unit'
          onChange={invoiceItemDetailsChangeHandler}
        />
      </Box>

      {invoiceType && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '1rem',
            flexWrap: 'wrap',
            '&>*': {
              alignItems: 'baseline',
              flex: { xs: '0 0 45%', md: '0 0 30%' },
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'column', md: 'row' },
              '&>*': { width: '100%' },
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                {
                  display: 'none',
                },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            }}
          >
            <Typography sx={{ display: 'flex', flex: 1 }}>CGST</Typography>
            <TextField
              id='standard-error'
              label='Please enter CGST'
              variant='standard'
              sx={{ flex: 2 }}
              type='number'
              name='totalGst.cGst'
              value={invoiceItem?.totalGst.cGst.toString()}
              onChange={invoiceItemDetailsChangeHandler}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'column', md: 'row' },
              '&>*': { width: '100%' },
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                {
                  display: 'none',
                },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            }}
          >
            <Typography sx={{ display: 'flex', flex: 1 }}>SGST</Typography>
            <TextField
              id='standard-error'
              label='Please enter SGST'
              variant='standard'
              sx={{ flex: 2 }}
              type='number'
              name='totalGst.sGst'
              value={invoiceItem?.totalGst?.sGst.toString()}
              onChange={invoiceItemDetailsChangeHandler}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'column', md: 'row' },
              '&>*': { width: '100%' },
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                {
                  display: 'none',
                },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            }}
          >
            <Typography sx={{ display: 'flex', flex: 1 }}>IGST</Typography>
            <TextField
              id='standard-error'
              label='Please enter IGST'
              variant='standard'
              sx={{ flex: 2 }}
              type='number'
              name='totalGst.iGst'
              value={invoiceItem?.totalGst?.iGst.toString()}
              onChange={invoiceItemDetailsChangeHandler}
            />
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '0.5rem',
          gap: '1rem',
        }}
      >
        <Button
          variant='outlined'
          color={saveButtonStatus ? 'error' : 'primary'}
          onClick={addInvoiceItemHandler}
        >
          Add Item
        </Button>
      </Box>
    </Box>
  );
};

export default InvoiceItemForm;
