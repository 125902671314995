import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import RoleManagementComponent from "../AssetDeveloperRole/RoleManagement/RoleManagement";
import RoleProfileManagementComponent from "../AssetDeveloperRole/RoleProfileManagement/RoleProfileManagementComponent";
import TransportAssetManagement from "../AssetDeveloperRole/TransportManagement/TransportManagementComponent";
import MainService from "../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AssetHealthStatusComponent from "../AssetDeveloperRole/AssetHealthStatus/AssetHealthStatusComponent";
import ProcessHealthStatusComponent from "../AssetDeveloperRole/ProcessHealthStatus/ProcessHealthStatusComponent";
import AAMCSiteManagementComponent from "../AssetDeveloperRole/AAMCSiteManagement/AAMCSiteManagementComponent";
import VCNFarmerComponent from "../VCNFarmer/VCNFarmerComponent";
import VCNTraderComponent from "../VCNTrader/VCNTraderComponent";
import VCNExecutiveComponent from "../VCNExecutive/VCNExecutiveComponent";
import TransportUserComponent from "../AssetDeveloperRole/TransportUser/TransportUserComponent";
import VCNStorageOperatorComponent from "../VCNStorageOperator/VCNStorageOperatorComponent";

export default function VCNComponent(props) {
  const [viewRole, setViewRole] = useState(false);
  const [viewVCNTrader, setViewVCNTrader] = useState(false);

  const [viewVCNDriver, setViewVCNDriver] = useState(false);

  const [viewVCNExecutive, setViewVCNExecutive] = useState(false);
  const [viewVCNStorageOperator, setViewVCNStorageOperator] = useState(false);
  const [viewSiteManagement, setViewSiteManagement] = useState(false);
  const [viewVCNFarmer, setViewVCNFarmer] = useState(true);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [roleNameValue, setRoleNameValue] = useState("");
  const [viewSuperRole, setViewSuperRole] = useState(false);

  useEffect(() => {
    getRoleName();
  }, []);
  const getRoleName = () => {
    MainService.getRole(parentId, username)
      .then((res) => {
        setRoleNameValue(res.role);
        console.log("role P n/w res:", res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const viewRoleClick = () => {
    setViewRole(true);
    setViewVCNTrader(false);

    setViewVCNDriver(false);

    setViewVCNExecutive(false);
    setViewVCNStorageOperator(false);
    setViewVCNFarmer(false);
    setViewSiteManagement(false);

    setViewSuperRole(false);
  };

  const viewVCNTraderClick = () => {
    setViewRole(false);
    setViewVCNTrader(true);

    setViewVCNDriver(false);

    setViewVCNExecutive(false);
    setViewVCNStorageOperator(false);

    setViewSiteManagement(false);
    setViewVCNFarmer(false);
    setViewSuperRole(false);
  };
  const viewVCNFarmerClick = () => {
    setViewRole(false);
    setViewVCNTrader(false);

    setViewVCNDriver(false);

    setViewVCNExecutive(false);
    setViewVCNStorageOperator(false);

    setViewSiteManagement(false);
    setViewVCNFarmer(true);
    setViewSuperRole(false);
  };

  const viewVCNDriverClick = () => {
    setViewRole(false);
    setViewVCNTrader(false);

    setViewVCNDriver(true);

    setViewVCNExecutive(false);
    setViewVCNStorageOperator(false);
    setViewVCNFarmer(false);
    setViewSiteManagement(false);

    setViewSuperRole(false);
  };

  const viewVCNExecutiveClick = () => {
    setViewRole(false);
    setViewVCNTrader(false);

    setViewVCNDriver(false);

    setViewVCNExecutive(true);
    setViewVCNStorageOperator(false);
    setViewVCNFarmer(false);
    setViewSiteManagement(false);

    setViewSuperRole(false);
  };
  const viewVCNStorageOperatorClick = () => {
    setViewRole(false);
    setViewVCNTrader(false);

    setViewVCNDriver(false);

    setViewVCNExecutive(false);
    setViewVCNStorageOperator(true);
    setViewVCNFarmer(false);
    setViewSiteManagement(false);

    setViewSuperRole(false);
  };

  const viewSiteManagementClick = () => {
    setViewRole(false);
    setViewVCNTrader(false);

    setViewVCNDriver(false);

    setViewVCNExecutive(false);
    setViewVCNStorageOperator(false);
    setViewVCNFarmer(false);
    setViewSiteManagement(true);

    setViewSuperRole(false);
  };

  const viewSuperRoleClick = () => {
    setViewRole(false);
    setViewVCNTrader(false);

    setViewVCNDriver(false);

    setViewVCNExecutive(false);
    setViewVCNStorageOperator(false);
    setViewVCNFarmer(false);
    setViewSiteManagement(false);

    setViewSuperRole(true);
  };

  return (
    <>
      <div className="topHeadersWrapper">
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSiteManagementClick();
            }}
          >
            Site Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSuperRoleClick();
            }}
          >
            Role Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewRoleClick();
            }}
          >
            Role Profile Management
          </Button>
        </div>
        &nbsp;
      </div>

      <div className="container" id="mainContentList">
        <h2>VCN Network</h2>
        &nbsp;&nbsp;
        
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewVCNFarmerClick();
              }}
            >
              VCN-Farmer
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewVCNTraderClick();
              }}
            >
              VCN-Trader
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewVCNDriverClick();
              }}
            >
              VCN-Driver
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewVCNExecutiveClick();
              }}
            >
              VCN-Executive
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewVCNStorageOperatorClick();
              }}
            >
              VCN-StorageOperator
            </Button>
          </div>
        </div>
        &nbsp;
        {/* {(
            roleNameValue === "PREFFERED" ||
            roleNameValue === "PREMIUM" ||
            roleNameValue === "ADMIN") && (
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      viewAssetHealthStatusClick();
                    }}
                  >
                    DeveloperQA
                  </Button>
                </div>
                &nbsp;
                <div className="topHeaders">
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      viewProcessHealthStatus();
                    }}
                  >
                    Production-Managers
                  </Button>
                </div>
                
                &nbsp;
                <div className="topHeaders">
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      viewVCNExecutiveClick();
                    }}
                  >
                    Asset Statistics
                  </Button>
                </div>
                &nbsp;
                <div className="topHeaders">
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      viewVCNStorageOperatorClick();
                    }}
                  >
                    Asset HealthStatus
                  </Button>
                </div>
                &nbsp;
                <div className="topHeaders">
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      viewSiteHealthStatusClick();
                    }}
                  >
                    Site HealthStatus
                  </Button>
                </div>
              </div>
            )} */}
      </div>

      <div style={{ paddingTop: "20px" }}>
        {viewSiteManagement && <AAMCSiteManagementComponent siteType={["TRANSPORT"]} />}
        {viewVCNFarmer && <VCNFarmerComponent />}

        {viewRole && <RoleProfileManagementComponent siteType={["TRANSPORT"]} networkType="VCN"/>}

        {viewVCNDriver && <TransportUserComponent />}
        {viewVCNTrader && <VCNTraderComponent />}

        {viewVCNStorageOperator && <VCNStorageOperatorComponent />}

        {viewVCNExecutive && <VCNExecutiveComponent />}
        {viewSuperRole && <RoleManagementComponent />}
      </div>
    </>
  );
}
