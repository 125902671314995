import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fab,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import RouteMapp from "./RouteDetailsMap";
import EditIcon from "@mui/icons-material/Edit";
import SearchBar from "../../../../../containers/SearchBar/SearchBar";
import MatTable from "../../../../../containers/table/Table";
import MainService from "../../../Common/MainService";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const fields = [
  "",
  "Route ID",
  "Name",
  "Start Location",
  "End Location",
  "Last Modified",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "white",
  border: "none",
  borderRadius: "10px",
  boxShadow: 24,
  color: "black",
  p: 4,
};

const RouteManagement = ({ rouHandlers }) => {
  const [routeData, setRouteData] = useState({ routeList: [] });
  const [selectedItem, setSelectedItem] = useState("");
  const [visible, setVisible] = useState(10);
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRouteDetails, setSelectedRouteDetails] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const { user } = MainService.getLocalData();
  const { username } = user;


  const history= useHistory();

  useEffect(() => {
    getRouteInfo();
  }, []);

  const findItem = () => {
    const items = routeData?.routeList;
    const filterdata = items.filter((item) => item.routeId !== selectedItem);
    setRouteData({ routeList: filterdata });
    setOpen(false);
  };

  const delRoute = async () => {
    let selecteditem = selectedItem;
    const deleteRoute = await MainService.delRoute(username, selecteditem);
    if (deleteRoute) {
      findItem();
    }
  };

  const getRouteInfo = async () => {
    const { routeCount, routeList } = await MainService.getRouteInfo(username);
    console.log(routeCount, "routeCount........");
    console.log(routeList, "routelist.......");
    setRouteData({ routeCount, routeList });
  };

  const deleteItems = (items) => {
    setConfirmDelete(true);
  };

  const onClose = (val) => {
    if (val) {
    } else {
      setConfirmDelete(false);
    }
  };

  const onSelect = (item) => {
    if (selectedItem === item) {
      setSelectedItem("");
    } else {
      setSelectedItem(item);
    }
  };

  const loadMoreRoute = () => {
    setVisible(visible + 10);
  };

  const actionChangeHandler = (event) => {
    if (event.target.value === "delete") {
    }
  };

  const closeModelHandler = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteHandler = () => {
    setOpen(true);
    setAnchorEl(null);
  };

        //  ..........VIEW DETAILS..........
  const handleViewDetails = () => {
    if (selectedRouteDetails) {
      history.push('./viewroute', { routeDetails: selectedRouteDetails });
    } else {
      console.log("No Item selected for viewing details");
    }
    handleClose();
  };
            //..........handle modifydetails...........
  const handleModifyDetails = async (event) => {
    if (selectedItem) {
      try {
        const routeDetails = await MainService.getDetailsofRouteId(
          username,
          selectedItem
        );


console.log(routeDetails, "routedetails............")
        rouHandlers(routeDetails);
      } catch (error) {
        console.log("Error fetching route details", error);
      }
    } else {
      
      console.log("No Item selected for modifications");
    }
    handleClose();
  };

return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <SearchBar placeholder={"Search Route"}/>
        <Box>
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            cursor="pointer"
            sx={{ margin: "1rem" }}
            title="Add Route"
            onClick={() => rouHandlers()}
          >
            <AddIcon />
          </Fab>
          <Fab
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            color="secondary"
            disabled={selectedItem === ""}
          >
            <MoreVertIcon/>
            
          </Fab>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openAction}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => history.push('./viewroute', { routeId: selectedItem })}>View Details</MenuItem>
            <MenuItem onClick={handleModifyDetails}>Modify Details</MenuItem>
            <MenuItem onClick={deleteHandler}>Delete</MenuItem>

          </Menu>
        </Box>
      </Box>
      <Box sx={{ marginTop: "1rem" }}>
        <MatTable
          fields={fields}
          rows={routeData.routeList.slice(0, visible)}
          keys={[
            "routeId",
            "routeName",
            "startLocId",
            "endLocId",
            "lastModifiedDate",
          ]}
          onSelect={onSelect}
          unique="routeId"
          selected={selectedItem}
          total={routeData.routeCount}
          change_page={getRouteInfo}
        />
      </Box>
      <Box sx={{ mt: 2, width: "100%" }}>
        {" "}
        <Button
          variant="outlined"
          color="primary"
        
          onClick={loadMoreRoute}
        >
          Load More
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={closeModelHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            CONFIRM DELETE
          </Typography>
          <hr />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure?
          </Typography>
          <hr />
          <Box sx={{ marginTop: "0.5rem", display: "flex", gap: 1 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="outlined" onClick={delRoute}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>

    
    </>
  );
};

export default RouteManagement;
