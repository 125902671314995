import React, { useState, useEffect, useMemo } from "react";
import { Button } from "@mui/material";
import "./CompactSiteManagement.css";
import ViewSiteManagementComponent from "../SiteManagement/SiteManagementComponent";
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";
import SiteTypesComponent from "./SiteTypesComponent";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { MarginRounded } from "@mui/icons-material";


const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
    backgroundColor: green,
  },
}));
export default function CompactSiteManagement(props) {
  const classes = useStyles();
  const [viewAllSites, setViewAllSites] = useState(true);
  const [viewFarmSites, setViewFarmSites] = useState(false);
  const [sitesData, setSitesData] = useState([]);
  const [mainSitesData, setMainSitesData] = useState([]);
  const [farmSitesData, setFarmSitesData] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const userType = localStorage.getItem("userType");
  const UserRole = localStorage.getItem("UserRole");
  const [showButtons, setShowButtons] = useState(true);
  const [showSiteTypes, setShowSiteTypes] = useState(false);
  const [siteType, setSiteType] = useState([]);
  const [siteManagementView, setSiteManagementView] = useState(true);
  const [siteTypesData, setSiteTypesData] = useState([]);
  const [filterBySsiteType, setFilterBySsiteType] = useState("");
  const [showFilter, setShowFilter] = useState(true);

  useEffect(() => {
    getAllSiteTypesList(username);
    if (userType === "CBO") {
      setSiteManagementView(false);
      setShowSiteTypes(true);
      setViewAllSites(false);
    }
    getAllSitesData();
  }, []);
  const getAllSiteTypesList = (user) => {
    MainService.getAllSiteTypes(user)
      .then((res) => {
        if (res.sitetypes.length > 0) {
          // Remove duplicates using Set
          const uniqueSiteTypes = Array.from(
            new Set(res.sitetypes.map((site) => site.sitetype))
          );
          console.log("uniqueSiteTypes", uniqueSiteTypes);
          setSiteTypesData(uniqueSiteTypes);
        } else {
          setSiteTypesData([]);
        }
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const getAllSitesData = () => {
    MainService.viewAllSites(parentId, username)
      .then((res) => {
        setSitesData(res.sites);
        setMainSitesData(res.sites);
        setFilterBySsiteType("");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  function deletSiteSubmitClcik(selectedSiteId, selectedSiteName, username) {
    MainService.siteDeleteHandler(selectedSiteId, selectedSiteName, username)
      .then((res) => {
        const filterData = sitesData.filter(
          (item) => item.siteid !== selectedSiteId
        );
        setSitesData(filterData);

        return NotificationManager.success("Successfully Deleted");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  }

  function viewAssetsHandler(showAssetGrid) {
    console.log("viewAllGridSites", showAssetGrid);
    if (showAssetGrid === true) {
      setShowButtons(false);
    } else {
      setShowButtons(true);
    }
  }
  function requestSearch(searchedVal) {
    if (searchedVal !== "") {
      console.log("searched val:", searchedVal);
      const filteredRows = sitesData.filter((row) => {
        return row.sitename.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setSitesData(filteredRows);
    } else {
      if (viewAllSites === true) {
        setSitesData(mainSitesData);
      } else {
        setSitesData(farmSitesData);
      }
    }
  }

  function requestFarmGridSearch(searchedVal) {
    console.log("searched val:", searchedVal);
    if (searchedVal !== "") {
      const filteredRows = sitesData.filter((row) => {
        return row.sitename.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setSitesData(filteredRows);
    } else {
      if (viewAllSites === true) {
        setSitesData(mainSitesData);
      } else {
        setSitesData(farmSitesData);
      }
    }
  }

  const viewAllSitesClick = () => {
    setViewAllSites(true);
    setSiteManagementView(true);
    setViewFarmSites(false);
    setShowSiteTypes(false);
    getAllSitesData();
    setSiteType([]);
    setShowFilter(true);
  };
  const siteFunction = (siteType) => {
    MainService.viewAllFarms(username, siteType)
      .then((res) => {
        setSitesData(res.sites);
        setFarmSitesData(res.sites);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  /* Create Site Type */
  const createdSiteTypesHandler = () => {
    setSiteManagementView(false);
    setShowSiteTypes(true);
    setViewAllSites(false);
    setViewFarmSites(false);
    setShowButtons(true);
    setShowFilter(false);
  };
  /* End of Site Type */

  return (
    <>
      <div className="container">

        {(userType === "AssetDeveloper" ||
          userType === "CBO" ||
          userType === "AHSP" ||
          userType === "LogisticsProvides" ||
          userType === "StorageCompany" ||
          UserRole !== "NULL") && (
          <>
            <div className="topHeadersWrapper">
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => {
                    createdSiteTypesHandler();
                  }}
                >
                  SiteTypes
                </Button>
            </div>
            &nbsp;
            <div style={{ display: "flex", alignItems: "center" }}>
              {showButtons && (
                <div>
                  {userType !== "CBO" && UserRole !== "NULL" && (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={viewAllSitesClick}
                      >
                        All Sites
                      </Button>
                  )}
                </div>
              )}
              {showFilter && (
                <div>
                  {userType !== "CBO" && UserRole !== "NULL" && (
                    <div style={{ marginLeft: "10px" }}>
                      {/* Added margin to create space */}
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.formControl}
                      >
                        <InputLabel>Filter By SiteType</InputLabel>
                        <Select
                          id="selectedModel"
                          value={filterBySsiteType}
                          onChange={(event) => {
                            setFilterBySsiteType(event.target.value);
                            siteFunction(event.target.value);
                            setSiteType([event.target.value]);
                          }}
                          label="Select Model"
                        >
                          {siteTypesData &&
                            siteTypesData.map((idObj, index) => (
                              <MenuItem key={`${index}`} value={idObj}>
                                {idObj}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div>
        <>
          {siteManagementView && (
            <ViewSiteManagementComponent
              sites={sitesData}
              getAllSitesData={getAllSitesData}
              deletSiteSubmitClcik={deletSiteSubmitClcik}
              requestSearch={requestSearch}
              requestFarmGridSearch={requestFarmGridSearch}
              viewAssetsHandler={viewAssetsHandler}
              siteType={siteType}
            />
          )}
        </>
        {showSiteTypes && (
          <SiteTypesComponent
            getAllSiteTypesList={getAllSiteTypesList}
          ></SiteTypesComponent>
        )}
      </div>
    </>
  );
}
