import React, { useCallback, useEffect, useState } from 'react';
import InvoiceTable from '../components/InvoiceTable';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UserForm from '../components/UserForm';
import MainService from '../../../Common/MainService';
import SupplyListHeader from '../components/SupplyListHeader';
import CommanTable from '../components/CommanTable';
import dayjs from 'dayjs';
import { uniqueNumber } from '../utils/genrateUniqueNumber';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import { formatDate } from '../../../../../utils/formatTime';
import LoadingPage from '../../loadingpage/LoadingPage';
import ConsigneeDetails from '../components/ConsigneeDetails';
import { mapRequestTypeToValue } from './utils';

const todayDate = new Date();
const formattedValue = dayjs(todayDate).format('YYYY-MM-DD');

const tempQuotationDetails = [
  { id: 'quotationID', label: 'Quotation ID' },
  { id: 'buyerName', label: 'Buyer Name' },
  { id: 'date', label: 'Date' },
  { id: 'status', label: 'Status' },
  { id: 'viewLink', label: 'View/Generate', type: 'button' },
];

const tempItemDetailsLabel = [
  { id: 'description', label: 'Description' },
  { id: 'quantity', label: 'Quantity', type: 'number' },
  { id: 'unit', label: 'Unit' },
  { id: 'reqDeliveryDate', label: 'Delivery Date', type: 'date' },
];

const tempRawItemDetailsLabel = [
  { id: 'description', label: 'Raw Material Provide by Buyer(if any)' },
  { id: 'quantity', label: 'Quantity', type: 'number' },
  { id: 'unit', label: 'Unit' },
  { id: 'reqDeliveryDate', label: 'Delivery Date', type: 'date' },
];

const tempTermConditionLabel = [{ id: 'term', label: 'Terms & Conditions:' }];

const tempBuyerData = {
  buyerName: '',
  buyer_cell: {
    buyer_cell1: '',
    buyer_cell2: '',
  },
  buyer_email: '',
  buyer_website: '',
  buyer_panNo: '',
  buyer_gstin: '',
  buyerAdressline1: '',
  buyerAdressline2: '',
  buyerAdressline3: '',
};

const tempSupplier = {
  supplierName: '',
  supplierContact: '',
  sup_email: '',
  sup_gstin: '',
  sup_panNo: '',
  supplierAdressline1: '',
  supplierAdressline2: '',
  supplierAdressline3: '',
};
const tempquotationDetails = {
  quotationID: '',
  quotationDate: formattedValue,
  customerID: '',
  validUpto: '',
};
const tempdescriptionItemDetails = [
  {
    description: '',
    quantity: '',
    unit: '',
    reqDeliveryDate: '',
  },
];

const tempTermConditionValue = [];

const BuyerQutationManagement = ({ userDetails, proxyId = '' }) => {
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [showQutationForm, setShowQutaitonForm] = useState(false);
  const [quotationContentShow, setQuotationContentShow] = useState('');
  const [quotationFileList, setQuotationFileList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [tempImage, setTempImage] = useState(null);

  const addNewQutationHandler = () => {
    setShowQutaitonForm(true);
  };

  const [quotationDetails, setQuotationDetails] = useState({
    quotationID: proxyId,
    quotationDate: formattedValue,
    customerID: '',
    validUpto: '',
  });
  const [buyerDetails, setBuyerDetails] = useState(tempBuyerData);
  const [consigneeDetails, setConsigneeDetails] = useState({});
  const [supplierDetails, setSupplierDetails] = useState(tempSupplier);
  const [descriptionItemDetails, setDescriptionItemDetails] = useState(
    tempdescriptionItemDetails
  );
  const [rawMaterialItemDetails, setRawMaterialItemDetails] = useState([]);
  const [imageSize, setImageSize] = useState(false);
  const [validImageFormat, setValidImageFormat] = useState(false);

  const [termsConditions, setTermsConditions] = useState(
    tempTermConditionValue
  );
  const [isDocumentDetailsLoaded, setIsDocumentDetailsLoaded] = useState(true);
  const [documentDetailsError, setDocumentDetailsError] = useState('');
  const [documentStatus, setDocumentStatus] = useState('');
  const [quoteType, setQuoteType] = useState('Asset');
  const [categoryType, setCategoryType] = useState('Asset');
  const [sameAddressCheck, setSameAddressCheck] = useState(false);

  const {
    user: { parentId, username, orgid, suborgid },
  } = MainService.getLocalData();

  // useEffect(() => {
  //   const quotationID = 'QT-' + uniqueNumber();
  //   setQuotationDetails({
  //     quotationID: proxyId,
  //     quotationDate: formattedValue,
  //     customerID: '',
  //     validUpto: '',
  //   });
  //   return () => {
  //     setQuotationDetails([]);
  //     uniqueNumber();
  //   };
  // }, [showQutationForm]);

  // useEffect(() => {
  //   if (proxyId) {
  //     setShowQutaitonForm(true);
  //   }
  // }, [proxyId]);

  useEffect(() => {
    setQuotationDetails((prev) => ({
      ...prev,
      quotationID: proxyId,
      quotationDate: formattedValue,
    }));
    if (proxyId) {
      setShowQutaitonForm(true);
    }
  }, [proxyId]);

  //user details
  useEffect(() => {
    if (userDetails) {
      setBuyerDetails((prevSupplierDetails) => ({
        ...prevSupplierDetails,
        ...userDetails,
      }));
    }
  }, [userDetails, showQutationForm]);

  // user form input change handler

  const formUserInputChangeHandler = (event, setFormDataInput) => {
    const { name, value } = event.target;

    setFormDataInput((formDataInput) => {
      if (name === 'buyer_cell1' || name === 'buyer_cell2') {
        return {
          ...formDataInput,
          buyer_cell: {
            ...formDataInput.buyer_cell,
            [name]: value,
          },
        };
      } else {
        return {
          ...formDataInput,
          [name]: value,
        };
      }
    });
  };

  // construct document data
  const cancelQutationHandler = () => {
    setShowQutaitonForm(false);
    setImageFile(null);
    setTempImage(null);
    setBuyerDetails(tempBuyerData);
    setSupplierDetails(tempSupplier);
    setQuotationDetails(tempquotationDetails);
    setDescriptionItemDetails(tempdescriptionItemDetails);
    setRawMaterialItemDetails([]);
    setSaveButtonStatus(false);

    setDocumentDetailsError('');
    setDocumentStatus('');
    setTermsConditions([]);
    setConsigneeDetails({});
  };

  const documentFormDataConstructed = () => {
    const isItemEmpty = (item) => {
      const excludedFields = ['buyer_cell2', 'buyer_website'];
      const hasValidSupplierContact = (supplierContact) =>
        !(supplierContact?.trim().length >= 10);
      return Object.entries(item).some(
        ([key, value]) =>
          !excludedFields.includes(key) &&
          (value === '' ||
            (typeof value === 'number' && isNaN(value)) ||
            (typeof value === 'string' && value === 'Invalid Date') ||
            (key === 'buyer_cell1' && hasValidSupplierContact(value)))
      );
    };

    const isBuyerDetailsEmpty = isItemEmpty(buyerDetails);
    const isConsigneeDetailsEmpty = isItemEmpty(consigneeDetails);
    // const isSupplierDetailsEmpty = isItemEmpty(supplierDetails);
    const isDescriptionItemDetailsEmpty =
      descriptionItemDetails.some(isItemEmpty);
    const isRawMaterialItemDetailsEmpty =
      rawMaterialItemDetails.some(isItemEmpty);
    const isQuotationDetailsEmpty = isItemEmpty(quotationDetails);

    if (
      isBuyerDetailsEmpty ||
      // isSupplierDetailsEmpty ||
      isDescriptionItemDetailsEmpty ||
      isRawMaterialItemDetailsEmpty ||
      isQuotationDetailsEmpty ||
      !(buyerDetails.buyer_cell.buyer_cell1.length === 10) ||
      !(buyerDetails.buyer_gstin.length === 15) ||
      !(buyerDetails.buyer_panNo.length === 10) ||
      isConsigneeDetailsEmpty
      // ||
      // !(supplierDetails.sup_gstin.length === 15) ||
      // !(supplierDetails.sup_panNo.length === 10)
    ) {
      // Handle empty fields error, display a message or perform appropriate action
      setSaveButtonStatus(true);
      setDocumentDetailsError('Please fill the required field.');
      return { data: {}, formData: {} };
    }

    const quotationDataDetails = {
      quotationDetails,
      buyerDetails,
      consigneeDetails: {
        consigneeContactNo: !sameAddressCheck
          ? consigneeDetails.consigneeContactNo
          : buyerDetails.buyer_cell.buyer_cell1,

        consigneeAdressline1: !sameAddressCheck
          ? consigneeDetails.consigneeAdressline1
          : buyerDetails.buyerAdressline1,

        consigneeAdressline2: !sameAddressCheck
          ? consigneeDetails.consigneeAdressline2
          : buyerDetails.buyerAdressline2,

        consigneeAdressline3: !sameAddressCheck
          ? consigneeDetails.consigneeAdressline3
          : buyerDetails.buyerAdressline3,
      },
      items: [...descriptionItemDetails],
      rawItems: [...rawMaterialItemDetails],
      termsConditions: [...termsConditions.map((obj) => obj.term)],
      // new added field
      quoteType: quoteType,
      categoryType: categoryType,
    };
    if (
      supplierDetails &&
      Object.values(supplierDetails).every(
        (value) => value !== null && value !== undefined && value != ''
      )
    ) {
      quotationDataDetails.supplierDetails = supplierDetails;
    }

    const data = {
      docId: quotationDetails.quotationID,
      networkId: window.AppConfigData.EnterpriseId,
      parentId: parentId,
      proxyId: proxyId,
      orgId: orgid,
      docCreatorId: username,
      type: 'rfq',
      docCreatorName: username,
      docCreateDate: quotationDetails.quotationDate,
      docReceiverName: buyerDetails.buyerName,
      status: 'created',
      quotationDataDetails,
    };

    const contentInfo = {
      contentname: `${quotationDetails.quotationID}`,
      contentcreator: username,
      contentownerid: username,
      contentdescription: 'contentdescription',
      expirydate: quotationDetails.validUpto,
      assetdeveloperid: window.AppConfigData.EnterpriseId,
    };

    const generatedDateInfo = {
      docGeneratedDate: formattedValue,
    };

    const formData = new FormData();
    formData.append('contentInfo', JSON.stringify(contentInfo));
    formData.append('generatedDateInfo', JSON.stringify(generatedDateInfo));
    formData.append('data', JSON.stringify(quotationDataDetails));
    formData.append('file', imageFile);
    return { data, formData };
  };

  const saveQutationHandler = async ({ data }) => {
    if (Object.keys(data).length === 0) {
      return;
    }
    setIsDocumentDetailsLoaded(false);
    try {
      const response = await MainService.saveDocumentsApi(
        parentId,
        orgid,
        suborgid,
        username,
        data
      );
      if (response?.status === 201) {
        const formattedDate = formatDate(
          new Date(response.data.docDetails.docCreateDate),
          'dd-MM-yyyy'
        );
        const tempQutationList = {
          viewLink: response.data.docDetails.LocationUrl,
          quotationID: quotationDetails.quotationID,
          buyerName: buyerDetails.buyerName,
          date: formattedDate,
          status: response.data.docDetails.status,
        };
        setQuotationFileList((prevList) => [...prevList, tempQutationList]);

        setDocumentDetailsError('Quotation submitted.');
        setTimeout(() => {
          cancelQutationHandler();
        }, 300);
        return response;
      } else {
        console.log('error in document response', response);
        // setErrorMesg(response);
        setDocumentDetailsError(response);
      }

      setIsDocumentDetailsLoaded(true);
      return response;
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(
          'Network connection issue. Please check your internet connection.'
        );
      }
      return error.message;
    } finally {
      setIsDocumentDetailsLoaded(true);
    }
  };

  const fetchGeneratedDocument = async (formData, type) => {
    const response = await MainService.generateDocumentsApi(
      parentId,
      orgid,
      suborgid,
      username,
      formData,
      type
    );
    if (response?.status === 200) {
      const tempQutationList = {
        viewLink: response.data.LocationUrl,
        status: response.data.status,
        quotationID: quotationDetails.quotationID,
        buyerName: buyerDetails.buyerName,
        date: response.data.docGeneratedDate,
      };
      setQuotationFileList((prevList) =>
        prevList.map((item) =>
          item.quotationID === tempQutationList.quotationID
            ? tempQutationList
            : item
        )
      );
      setDocumentDetailsError('Quotation generated.');
      handleDownload(response.data.LocationUrl, response.data.contentId);
      setTimeout(() => {
        cancelQutationHandler();
      }, 300);
    } else {
      setDocumentDetailsError(response);
    }
    setIsDocumentDetailsLoaded(true);
  };

  const generateDocumentHandler = async ({ data, formData }) => {
    if (Object.keys(data).length === 0) {
      return;
    }
    setIsDocumentDetailsLoaded(false);
    try {
      if (documentStatus === 'created') {
        const response = await fetchGeneratedDocument(formData, data.type);
      } else {
        // setDocumentStatus('created');
        const saveResponse = await saveQutationHandler({ data });
        if (saveResponse?.status === 201) {
          setIsDocumentDetailsLoaded(false);
          const response = await fetchGeneratedDocument(formData, data.type);
          setShowQutaitonForm(false);
        }
      }
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(
          'Network connection issue. Please check your internet connection.'
        );
        setDocumentDetailsError(
          'Network connection issue. Please check your internet connection.'
        );
      }
      setDocumentDetailsError(error.message);
    } finally {
      setIsDocumentDetailsLoaded(true);
      setShowQutaitonForm(false);
    }
  };

  const handleDownload = (url, contenId) => {
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', contenId);

      // Open the new tab
      const newTab = window.open('', '_blank');
      if (newTab) {
        newTab.document.body.appendChild(link);
        // Trigger the download
        link.click();
      } else {
        // Handle case where new tab couldn't be opened
        console.error('Failed to open new tab');
      }
    }, 100);
  };

  //image file change handler
  const imageFileChangeHandler = (event) => {
    setImageFile(event.target.files[0]);
    const file = event.target.files[0];
    setImageSize(false);
    if (file?.size > 1024 * 1024) {
      setImageSize(true);
      setTempImage(null);
      setImageFile(null);
      event.target.value = '';
      return;
    }

    // Check if file type is an image
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!validImageTypes.includes(file.type)) {
      setValidImageFormat(true);
      setTempImage(null);
      setImageFile(null);
      event.target.value = '';
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setTempImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const imageChangeHandler = () => {
    const fileInput = document.getElementById('upload-photo');
    fileInput.click();
  };

  //Quotation details change handler
  const quotationDetailsChangeHandler = (field, value) => {
    setQuotationDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  // Quote type change
  const quoteTypeChangeHandler = (event) => {
    const { value } = event.target;
    setQuoteType(value);
    if (value === 'Asset') {
      setCategoryType('Asset');
    } else if (value === 'Agri Product') {
      setCategoryType('Product');
    } else {
      setCategoryType('Services');
    }
  };

  // address check box change handler
  const addressCheckboxChageHandler = (event) => {
    setSameAddressCheck(event.target.checked);
    if (event.target.checked) {
      setConsigneeDetails({
        consigneeContactNo: buyerDetails.buyer_cell.buyer_cell1,
        consigneeAdressline1: buyerDetails.buyerAdressline1,
        consigneeAdressline2: buyerDetails.buyerAdressline2,
        consigneeAdressline3: buyerDetails.buyerAdressline3,
      });
    } else {
      setConsigneeDetails({
        consigneeContactNo: '',
        consigneeAdressline1: '',
        consigneeAdressline2: '',
        consigneeAdressline3: '',
      });
    }
  };

  //Adding new item to description
  const addItemHandler = (itemDetails, setItemDetails) => {
    const data = {
      description: '',
      quantity: '',
      unit: '',
      reqDeliveryDate: '',
    };
    setItemDetails([...itemDetails, data]);
  };

  //Deleting description item handler
  const deleteItemHandler = (
    index,
    itemDetails,
    setItemDetails,
    isDescriptionItem
  ) => {
    if (itemDetails.length === 1 && isDescriptionItem) {
      return;
    }
    setItemDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails.splice(index, 1);
      return newDetails;
    });
  };

  //Description change Handler
  const descriptionItemChangeHandler = (
    index,
    field,
    value,
    setDescription
  ) => {
    setDescription((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  // terms and condition add
  const addTermConditionHandler = () => {
    const data = {
      term: '',
    };
    setTermsConditions([...termsConditions, data]);
  };

  const termsConditionsChangeHandler = (index, field, value) => {
    setTermsConditions((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  // fetching data from server based on document id
  const fetchDocumentDetails = async (docId) => {
    console.log('is called');
    setIsDocumentDetailsLoaded(false);
    try {
      const response = await MainService.documentDetailsApi(
        parentId,
        orgid,
        suborgid,
        username,
        docId
      );
      if (!response) {
        setDocumentDetailsError(
          'Document details not found, try refreshing the page.'
        );
      }
      if (response?.status === 200) {
        const {
          buyerDetails,
          consigneeDetails,
          items,
          quotationDetails,
          rawItems,
          supplierDetails,
          termsConditions,
        } = response.data.doc.quotationDataDetails;
        const transformedArray = termsConditions.map((item) => ({
          term: item,
        }));

        setBuyerDetails(buyerDetails);
        setSupplierDetails(supplierDetails);
        setDescriptionItemDetails(items);
        setRawMaterialItemDetails(rawItems);
        setTermsConditions(transformedArray);
        setQuotationDetails(quotationDetails);
        setConsigneeDetails(consigneeDetails);
      }
    } finally {
      setIsDocumentDetailsLoaded(true);
    }
  };

  const getDocumentDetailsHander = async ({ quotationID, status }) => {
    setShowQutaitonForm(true);
    setDocumentStatus(status);
    await fetchDocumentDetails(quotationID);
  };

  // fetching qauotation list
  const { isLoading, error, data } = useQuery({
    queryKey: ['quotationData'],
    queryFn: async () => {
      try {
        const result = await MainService.documentListApi(
          parentId,
          orgid,
          suborgid,
          username,
          'rfq'
        );
        if (result) {
          return result;
        } else {
          return {
            isLoading: true,
            error: false,
            data: [],
          };
        }
      } catch (error) {
        console.error('Error fetching purchase order data:', error);
        return [];
      }
    },
  });

  useEffect(() => {
    if (!isLoading && !error && data?.data?.docs?.length !== undefined) {
      const initialData = data.data.docs.map((item) => {
        const formattedDate = format(
          new Date(item.docCreateDate),
          'dd-MM-yyyy'
        );
        return {
          quotationID: item.docId,
          buyerName: item.docReceiverName,
          date: formattedDate,
          status: item.status,
          viewLink: item.LocationUrl,
        };
      });
      setQuotationFileList(initialData);
    }
    return () => {
      setQuotationFileList([]);
    };
  }, [isLoading, error, data]);

  // get details based on rfq id
  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // fetch document based on id
  const fetchDetailsbyId = async (id) => {
    const response = await MainService.getDocumentsById(id, parentId, username);

    if (response?.status === 200) {
      console.log(quotationDetails);

      const {
        personalDetails,
        requestType,
        requirements,
        supplierDetails,
        deliveryAddress,
        pickUpAddress,
      } = response.data.requestDetails ?? {};

      const requestTypeValue = mapRequestTypeToValue(requestType);
      setQuoteType(requestTypeValue);

      setQuotationDetails((prev) => ({
        ...prev,
        quotationID: id,
        validUpto: requirements?.toDate,
      }));

      setSupplierDetails({
        supplierName: supplierDetails?.siteName ?? '',
        supplierContact: supplierDetails?.phoneNumber?.toString() ?? '',
        sup_email: supplierDetails?.emailId ?? '',
        supplierAdressline1: `${supplierDetails?.siteId ?? ''} ${
          supplierDetails?.street ?? ''
        }`,
        supplierAdressline2: `${supplierDetails?.city ?? ''} ${
          supplierDetails?.state ?? ''
        }`,
        supplierAdressline3: `${supplierDetails?.country ?? ''} ${
          supplierDetails?.pincode ?? ''
        }`,
      });

      console.log('supplier details', supplierDetails?.state);

      setConsigneeDetails({
        consigneeContactNo: personalDetails?.phoneNo ?? '',
        consigneeAdressline1: `${deliveryAddress?.siteName ?? ''} `,
        consigneeAdressline2: `${deliveryAddress?.street ?? ''} ${
          deliveryAddress?.city ?? ''
        }`,
        consigneeAdressline3: `${deliveryAddress?.state ?? ''} ${
          deliveryAddress?.country ?? ''
        } ${deliveryAddress?.pincode ?? ''}`,
      });
    }
  };

  const debouncedFetchDetailsById = useCallback(
    debounce(fetchDetailsbyId, 500),
    []
  );

  useEffect(() => {
    if (proxyId && quotationDetails.quotationID) {
      debouncedFetchDetailsById(quotationDetails.quotationID);
    }
  }, [proxyId, quotationDetails.quotationID, debouncedFetchDetailsById]);

  if (isLoading) return <LoadingPage />;
  // if (error) return 'An error has occurred: ' + error.message;

  // snackbar action handler
  const snackbarActionHandler = (
    <React.Fragment>
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={() => setDocumentDetailsError('')}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      {!showQutationForm && (
        <Box>
          <Typography
            sx={{
              fontWeight: '800',
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
              textAlign: 'center',
              mb: '0.5rem',
              color: '#1976d2',
            }}
          >
            Buyer Accounts Management
          </Typography>
          <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          <InvoiceTable
            columnData={tempQuotationDetails}
            rowDataList={quotationFileList}
            documentDetailsHander={getDocumentDetailsHander}
          />
          <Stack>
            <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={addNewQutationHandler}>Add New Quotation</Button>
            </ListItem>
          </Stack>
        </Box>
      )}
      {!isDocumentDetailsLoaded && <div>loading...</div>}
      {showQutationForm && isDocumentDetailsLoaded && (
        <Box sx={{ p: { xs: '1rem 0rem', md: '0' } }}>
          <Box>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
                textAlign: 'center',
                mb: '0.5rem',
                color: '#1976d2',
              }}
            >
              Buyer Quotation
            </Typography>
            <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          </Box>
          <Box
            sx={{
              boxShadow:
                'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              mb: '1rem',
            }}
          >
            <SupplyListHeader
              image={tempImage}
              imageChangeHandler={imageChangeHandler}
              DocumentDetails={quotationDetails}
              saveButtonStatus={saveButtonStatus}
              DocumentDetailsChangeHandler={quotationDetailsChangeHandler}
              imageFileChangeHandler={imageFileChangeHandler}
            />
          </Box>
          <Box
            sx={{
              boxShadow:
                'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              mb: '1rem',
              padding: '1rem',
              borderRadius: '0.5rem',
            }}
          >
            <Box sx={{ display: 'flex', '&>*': { flex: 1 }, gap: 3 }}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id='quoteType'>Quote Type</InputLabel>
                  <Select
                    labelId='quoteType'
                    id='quoteType'
                    value={quoteType}
                    label='quoteType'
                    onChange={quoteTypeChangeHandler}
                  >
                    <MenuItem value={'Asset'}>Asset</MenuItem>
                    <MenuItem value={'Agri Product'}>Agri Product</MenuItem>
                    <MenuItem value={'Asset Contract Farming'}>
                      Asset Contract Farming
                    </MenuItem>
                    <MenuItem value={'Product Contract Farming'}>
                      Product Contract Farming
                    </MenuItem>
                    <MenuItem value={'Contract Manufacturing'}>
                      Contract Manufacturing
                    </MenuItem>
                    <MenuItem value={'Asset Storage'}>Asset Storage</MenuItem>
                    <MenuItem value={'Product Storage'}>
                      Product Storage
                    </MenuItem>
                    <MenuItem value={'Asset Transport'}>
                      Asset Transport
                    </MenuItem>
                    <MenuItem value={'Product Transport'}>
                      Product Transport
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box>
            <UserForm
              saveButtonStatus={saveButtonStatus}
              buyerDetails={buyerDetails}
              supplierDetails={supplierDetails}
              userInputBuyerChangehandler={(event) =>
                formUserInputChangeHandler(event, setBuyerDetails)
              }
              userInputSupplierChangehandler={(event) =>
                formUserInputChangeHandler(event, setSupplierDetails)
              }
              invoice={false}
            />
            <Box
              sx={{
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                mb: '1rem',
                padding: '1rem',
                borderRadius: '0.5rem',
              }}
            >
              <FormGroup>
                <FormControlLabel
                  // required
                  control={<Checkbox />}
                  label='Is the address the same as the current delivery location?'
                  onChange={addressCheckboxChageHandler}
                />
              </FormGroup>
              <ConsigneeDetails
                saveButtonStatus={saveButtonStatus}
                consigneeDetailsData={consigneeDetails}
                userInputBuyerChangehandler={(event) =>
                  formUserInputChangeHandler(event, setConsigneeDetails)
                }
                invoice={false}
              />
            </Box>

            <CommanTable
              columnsData={tempItemDetailsLabel}
              rowsData={descriptionItemDetails}
              tableType={'editable'}
              inputFieldChangeHandler={(index, field, value) =>
                descriptionItemChangeHandler(
                  index,
                  field,
                  value,
                  setDescriptionItemDetails
                )
              }
              addItemHandler={() =>
                addItemHandler(
                  descriptionItemDetails,
                  setDescriptionItemDetails
                )
              }
              deleteItemHandler={(rowData, index) =>
                deleteItemHandler(
                  index,
                  descriptionItemDetails,
                  setDescriptionItemDetails,
                  true
                )
              }
              saveButtonStatus={saveButtonStatus}
            />
            <CommanTable
              columnsData={tempRawItemDetailsLabel}
              rowsData={rawMaterialItemDetails}
              tableType={'editable'}
              inputFieldChangeHandler={(index, field, value) =>
                descriptionItemChangeHandler(
                  index,
                  field,
                  value,
                  setRawMaterialItemDetails
                )
              }
              addItemHandler={() =>
                addItemHandler(
                  rawMaterialItemDetails,
                  setRawMaterialItemDetails
                )
              }
              deleteItemHandler={(rowData, index) =>
                deleteItemHandler(
                  index,
                  rawMaterialItemDetails,
                  setRawMaterialItemDetails,
                  false
                )
              }
              saveButtonStatus={saveButtonStatus}
            />
          </Box>
          <Box>
            <CommanTable
              columnsData={tempTermConditionLabel}
              rowsData={termsConditions}
              tableType={'editable'}
              addItemHandler={addTermConditionHandler}
              inputFieldChangeHandler={termsConditionsChangeHandler}
              deleteItemHandler={(rowData, index) =>
                deleteItemHandler(
                  index,
                  termsConditions,
                  setTermsConditions,
                  false
                )
              }
              saveButtonStatus={saveButtonStatus}
            />
          </Box>

          <Box>
            <Stack>
              <ListItem sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={cancelQutationHandler}>Cancel</Button>
                {documentStatus !== 'created' && (
                  <Button
                    onClick={() =>
                      saveQutationHandler(documentFormDataConstructed())
                    }
                  >
                    Save
                  </Button>
                )}
                <Button
                  onClick={() =>
                    generateDocumentHandler(documentFormDataConstructed())
                  }
                >
                  Generate
                </Button>
              </ListItem>
            </Stack>
          </Box>
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={documentDetailsError ? true : false}
        autoHideDuration={2000}
        onClose={() => setDocumentDetailsError('')}
        message={documentDetailsError}
        action={snackbarActionHandler}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={imageSize}
        autoHideDuration={2000}
        onClose={() => setImageSize(false)}
        message={'image size is too large, please select below 1 mb.'}
        // action={snackbarActionHandler}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={validImageFormat}
        autoHideDuration={2000}
        onClose={() => setValidImageFormat(false)}
        message={
          ' Invalid image format. Please select a JPEG, PNG, or GIF image.'
        }
      />

      {!isDocumentDetailsLoaded && <LoadingPage />}
    </>
  );
};

export default BuyerQutationManagement;
