import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import ListIcon from "@material-ui/icons/List";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import SearchBar from "material-ui-search-bar";
import React, { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "../Market/Market.css";
import Loading from "../ModelProductManagement/Loading";
import PlaceholderCardList from "../ModelProductManagement/PlaceHolderCardList";
const TraceCodeCustomCard = lazy(() => import("./TraceCodeCustomCard"));
const DeleteSubCategoryOfContent = lazy(() =>
  import("../ModelProductManagement/DeleteSubCategoryOfContent")
);
const ContentSubCategoryCreate = lazy(() =>
  import("../ModelProductManagement/ContentSubCategoryCreate")
);
const CreateProduct = lazy(() =>
  import("../ModelProductManagement/CreateProduct/CreateProduct")
);
const UpdateQR = lazy(() => import("../ModelProductManagement/UpdateQR"));
const GenerateProductQrCode = lazy(() =>
  import("../ModelProductManagement/GenerateProductQrCode")
);
const ProductCustomTable = lazy(() =>
  import("../ModelProductManagement/ProductsCustomised/ProductCustomTable")
);
const DeleteProduct = lazy(() =>
  import("../ModelProductManagement/DeleteProduct")
);
const UpdateStatus = lazy(() =>
  import("../ModelProductManagement/UpdateStatus")
);
const UpdateItemCount = lazy(() =>
  import("../ModelProductManagement/UpdateItemCount")
);

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

const textSize = {
  sx: {
    textTransform: "none",
    background: "#3ECF8E",
    margin: "2px",
    width: { xs: "100%", sm: "70%", md: "auto" },
    "&:hover": {
      background: "#3ECF8E",
    },
  },
};

const displayFields = [
  "productid",
  "productcost",
  "discount",
  "discountedprice",
  "unit",
  "country",
  "itemcount",
  "status",
];

export default function TraceCodeManagement({
  categoryType,
  compType,
  subCatType,
  modelID,
  modelFN,
  dCost,
  pUnit,
  pDiscount,
  dPrice,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const iconWidthRes = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const [isLoading, setIsLoading] = useState(true);
  const [assetPopup, setShowAssetPopup] = useState(false);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [setFilterProducts, getFilterProducts] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [getProductsData, setProductsData] = useState([]);
  const [getListView, setListView] = useState(false);
  const [getGridView, setGridView] = useState(true);
  /* Update Product QR Code */
  const [getQRUpdateModel, setQRUpdateModel] = useState(false);
  /** Get Categorys */
  const [getCategoriesList, setCategoriesList] = useState("");
  const [getCategoriesNameList, setCategoriesNameList] = useState("");
  const [getSubCategoriesList, setSubCategoriesList] = useState([]);
  const [getSubCategoryForForm, setSubCategoryForForm] = useState("");
  const [getSubCategoryApiFilter, setSubCategoryApiFilter] = useState("");
  const [getCreateSubCategoryProps, setCreateSubCategoryProps] = useState([]);
  const [getSubCategoryModel, setSubCategoryModel] = useState(false);
  const [getDeleteSubCategoryModel, setDeleteSubCategoryModel] =
    useState(false);
  /** Update Model Product Info Model */
  const [getGenerateQrModel, setGenerateQrModel] = useState(false);
  const [getGenerateQrData, setGenerateQrData] = useState({});
  /* Delete Product'S */
  const [getDeleteProduct, setDeleteProduct] = useState(false);
  const [getDeleteProductBusinessId, setDeleteProductBusinessId] = useState("");
  const [getDeleteProductId, setDeleteProductId] = useState("");
  /* Update Product Status */
  const [getStatusUpdateModel, setStatusUpdateModel] = useState(false);
  /* Update Item Count */
  const [getItemCountUpdateModel, setItemCountUpdateModel] = useState(false);
  //common props setState
  const [commonProps, setCommonProps] = useState({});

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterProducts.filter((row) => {
      return row.productfriendlyname
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    setProductsData(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(() => {
    if (!parentId || !username || !getCategoriesList) return;
    getAllModelProducts();
  }, [parentId, username, getCategoriesList]);

  useEffect(() => {
    if (!username || !getCategoriesList) return;
    subCategoryList();
  }, [username, getCategoriesList]);

  const categorysList = () => {
    MainService.getListOfCategorys()
      .then((res) => {
        const filterCategory = res.filter((data) => {
          return data.categoryname.includes(categoryType);
        });
        setCreateSubCategoryProps(Object.assign({}, filterCategory[0]));
        setCategoriesList(filterCategory[0]?.categoryid);
        setCategoriesNameList(filterCategory[0]?.categoryname);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  useMemo(() => {
    if (!username) return;
    categorysList();
  }, []);

  const subCategoryList = () => {
    MainService.getCategoryRelatedSubCategories(username, getCategoriesList)
      .then((res) => {
        if (res.length > 0) {
          setSubCategoryForForm(res[0]?.contentsubcategoryname);
          setSubCategoriesList(res);
        } else {
          setSubCategoriesList(res);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  /** Get Products */
  const getAllModelProducts = async (Type) => {
    setIsLoading(true);
    setSubCategoryApiFilter("");
    if (Type === "List") {
      setListView(true);
      setGridView(false);
    } else if (Type === "Grid") {
      setListView(false);
      setGridView(true);
    }
    await MainService.getProductsBusinessandCategory(
      parentId,
      username,
      getCategoriesList
    )
      .then((data) => {
        setProductsData(data?.products);
        getFilterProducts(data?.products);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        return NotificationManager.error(err || "something went wrong");
      });
  };

  const getAllModelProductsOfSubCaegory = async (subcatval) => {
    setIsLoading(true);
    setSubCategoryApiFilter(subcatval);
    await MainService.getProductsBusinesCategoryandSubcategory(
      parentId,
      username,
      getCategoriesList,
      subcatval
    )
      .then((data) => {
        setProductsData(data?.products);
        getFilterProducts(data?.products);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        return NotificationManager.error(err || "something went wrong");
      });
  };
  /** EOD Get Products */

  /* Create Product */
  const addProductsClickHandler = () => {
    setShowAssetPopup(!assetPopup);
  };
  /* EOD Create Product */

  /** Generate QR **/
  const generateQRHandler = (product) => {
    setGenerateQrModel(!getGenerateQrModel);
    setGenerateQrData(product);
  };
  /** EOD Generate QR **/

  /** Update QR Code */
  const updateQRCodeUrlHandler = (product) => {
    setCommonProps(product);
    setQRUpdateModel(!getQRUpdateModel);
  };
  const closeQRCodeModel = () => {
    setQRUpdateModel(!getQRUpdateModel);
  };
  /** EOD Update QR Code */

  /** Create Sub-Category */
  const createSubCategoryModel = (data) => {
    setSubCategoryModel(!getSubCategoryModel);
  };
  const closeSubCategoryModel = () => {
    setSubCategoryModel(!getSubCategoryModel);
  };
  const deleteSubCategoryModel = () => {
    setDeleteSubCategoryModel(!getDeleteSubCategoryModel);
  };
  const closeDeleteSubCategoryModel = () => {
    setDeleteSubCategoryModel(!getDeleteSubCategoryModel);
  };
  /** EOD Create Sub-Category */

  /*** Delete Product ***/
  const deleteProductData = (product) => {
    setDeleteProduct(!getDeleteProduct);
    setDeleteProductBusinessId(product.businessid);
    setDeleteProductId(product.productid);
  };
  const closeDeleteProductModel = () => {
    setDeleteProduct(!getDeleteProduct);
  };
  /*** EOD Delete Product ***/

  /** Update Status */
  const updateStatusHandler = (product) => {
    setCommonProps(product);
    setStatusUpdateModel(!getStatusUpdateModel);
  };
  const closeUpdateStatusModel = () => {
    setStatusUpdateModel(!getStatusUpdateModel);
  };
  /** EOD Update Status */

  /** Update Item Count */
  const updateItemCountHandler = (product) => {
    setCommonProps(product);
    setItemCountUpdateModel(!getItemCountUpdateModel);
  };
  const closeItemCountModel = () => {
    setItemCountUpdateModel(!getItemCountUpdateModel);
  };
  /** EOD Update Item Count */

  return (
    <>
      <Box className="container" id="mainContentList" sx={{ width: "100%" }}>
        <Fab
          color="primary"
          size={iconWidthRes ? "small" : "large"}
          aria-label="add"
          title="List"
          className={classes.fab}
          onClick={() => addProductsClickHandler()}
        >
          <AddIcon></AddIcon>
        </Fab>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: {
              xs: "100%",
              sm: "70%",
              md: "auto",
            },
            my: "1rem",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "70%",
                sm: "auto",
              },
            }}
          >
            {/* <Button
              {...textSize}
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                productCreationInstructionHandler();
              }}
            >
              Instructions
            </Button> */}
            <Button
              {...textSize}
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                deleteSubCategoryModel();
              }}
            >
              Sub Categories
            </Button>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Sub Category Filter
              </InputLabel>
              <Select
                id="Sub-Category"
                value={getSubCategoryApiFilter}
                onChange={(e) => {
                  getAllModelProductsOfSubCaegory(e.target.value);
                }}
                label="Sub-Category"
              >
                {getSubCategoriesList.length > 0 ? (
                  getSubCategoriesList &&
                  getSubCategoriesList.map((idObj, index) => (
                    <MenuItem
                      key={`${index} `}
                      value={idObj.contentsubcategoryname}
                    >
                      {idObj.contentsubcategoryname}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No sub categories</MenuItem>
                )}
              </Select>
            </FormControl>
            {getSubCategoryApiFilter !== "" ? (
              <Button
                variant="none"
                onClick={() => {
                  getAllModelProducts();
                }}
              >
                Clear Filter
              </Button>
            ) : null}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                getAllModelProducts("Grid");
              }}
            ></Button>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                getAllModelProducts("List");
              }}
            ></Button>
          </Box>
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {verticalsIdList.length > 0 && (
            <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
              <FormControl
                variant="outlined"
                size="small"
                className="postdropdown subCatbutton"
              >
                <InputLabel
                  style={{
                    color: "rgb(43, 144, 99)",
                    fontSize: "15px",
                  }}
                >
                  Sector
                </InputLabel>
                <Select
                  id="selectedVerticalsId"
                  value={selectedVerticalsId}
                  onChange={(e) => {
                    setSelectedVerticalsId(e.target.value);
                  }}
                  label="Select Verticals"
                >
                  {verticalsIdList?.length > 0 ? (
                    verticalsIdList &&
                    verticalsIdList?.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No Sector Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          )}
          {categoryTypesList.length > 0 && (
            <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
              <FormControl
                variant="outlined"
                size="small"
                className="postdropdown subCatbutton"
              >
                <InputLabel
                  style={{
                    color: "rgb(43, 144, 99)",
                    fontSize: "15px",
                  }}
                >
                  Category
                </InputLabel>
                <Select
                  id="selectedCategory"
                  value={selectedCategory}
                  onChange={(event) => {
                    setSelectedCategory(event.target.value);
                  }}
                  label="Select Category"
                >
                  {categoryTypesList?.length > 0 ? (
                    categoryTypesList &&
                    categoryTypesList?.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.categoryType}>
                        {idObj.categoryType}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No Categorys Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          )}
          {assertTypesList.length > 0 && (
            <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
              <FormControl
                variant="outlined"
                size="small"
                className="postdropdown subCatbutton"
              >
                <InputLabel
                  style={{
                    color: "rgb(43, 144, 99)",
                    fontSize: "15px",
                  }}
                >
                  Asset Type
                </InputLabel>
                <Select
                  id="selectedAssertTypes"
                  value={selectedAssertTypes}
                  onChange={(event) => {
                    setSelectedAssertTypes(event.target.value);
                  }}
                  label="Select Type"
                >
                  {assertTypesList?.length > 0 ? (
                    assertTypesList &&
                    assertTypesList?.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetType}>
                        {idObj.assetType}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No Asset Types Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          )}
          {modelList.length > 0 && (
            <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
              <FormControl
                variant="outlined"
                size="small"
                className="postdropdown subCatbutton"
              >
                <InputLabel
                  style={{
                    color: "rgb(43, 144, 99)",
                    fontSize: "15px",
                  }}
                >
                  Model
                </InputLabel>
                <Select
                  id="selectedModel"
                  value={selectedModel}
                  onChange={(event) => {
                    setSelectedModel(event.target.value);
                  }}
                  label="Select Model"
                >
                  {modelList?.length > 0 ? (
                    modelList &&
                    modelList?.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.modelid}>
                        {idObj.modelfriendlyname}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No Models Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box> */}

        <Box>
          {getListView === true ? (
            <Box>
              <Box sx={{ m: "0" }}>
                {isLoading ? (
                  <Box>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                  </Box>
                ) : (
                  <Suspense fallback={<Loading />}>
                    <ProductCustomTable
                      getTableProducts={getProductsData}
                      displayFields={displayFields}
                      productType={categoryType}
                    />
                  </Suspense>
                )}
              </Box>
            </Box>
          ) : null}

          {getGridView === true ? (
            <Box sx={{ m: "0" }}>
              <Box
                sx={{
                  m: "0",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "40%",
                    lg: "40%",
                    xl: "40%",
                  },
                }}
              >
                <SearchBar
                  value={farmgridsearched}
                  onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                  onCancelSearch={() => cancelFarmGridSearch()}
                />
              </Box>
              {isLoading ? (
                <PlaceholderCardList
                  numberOfCards={getProductsData.length || 4}
                  heightVal={500}
                />
              ) : (
                <Suspense fallback={<Loading />}>
                  <TraceCodeCustomCard
                    productsData={getProductsData}
                    generateQRCode={generateQRHandler}
                    updateQRCodeUrl={updateQRCodeUrlHandler}
                    deleteproductData={deleteProductData}
                    updateStatus={updateStatusHandler}
                    updateItemCount={updateItemCountHandler}
                    productType={categoryType}
                  />
                </Suspense>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>

      <Suspense fallback={<Loading />}>
        <ContentSubCategoryCreate
          props={getCreateSubCategoryProps}
          modelShow={getSubCategoryModel}
          closeModel={closeSubCategoryModel}
          reloadComponent={subCategoryList}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <DeleteSubCategoryOfContent
          subCategories={getSubCategoriesList}
          modelShow={getDeleteSubCategoryModel}
          closeModel={closeDeleteSubCategoryModel}
          reloadComponent={subCategoryList}
          createSubCategoryModel={createSubCategoryModel}
          subCatType={subCatType}
          modelID={modelID}
          modelFN={modelFN}
          dCost={dCost}
          pUnit={pUnit}
          pDiscount={pDiscount}
          dPrice={dPrice}
          categoryType={categoryType}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateQR
          props={commonProps}
          openModel={getQRUpdateModel}
          closeModel={closeQRCodeModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <CreateProduct
          categoryId={getCategoriesList}
          categoryName={getCategoriesNameList}
          reLoad={getAllModelProducts}
          subCatList={getSubCategoriesList}
          openModel={assetPopup}
          closeModel={addProductsClickHandler}
          defaultSubCat={getSubCategoryForForm}
          subCatType={subCatType}
          modelID={modelID}
          modelFN={modelFN}
          dCost={dCost}
          pUnit={pUnit}
          pDiscount={pDiscount}
          dPrice={dPrice}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <GenerateProductQrCode
          props={getGenerateQrData}
          openModel={getGenerateQrModel}
          closeModel={generateQRHandler}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <DeleteProduct
          deleteProductBusinessId={getDeleteProductBusinessId}
          deleteProductId={getDeleteProductId}
          openModel={getDeleteProduct}
          closeModel={closeDeleteProductModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateStatus
          props={commonProps}
          openModel={getStatusUpdateModel}
          closeModel={closeUpdateStatusModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateItemCount
          props={commonProps}
          openModel={getItemCountUpdateModel}
          closeModel={closeItemCountModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>
    </>
  );
}
