import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Types from './Types';
import TypesUI from './MetaDataConfigure';
import Model from './Model';
import ModelConfigure from './ModelConfigure';
import Device from './Device';
import KeyManagement from './KeyManagement';
import Gateway from './Gateway';
import Ota from './FirmwareUpdate/FirmwareUpdate'
import TopicManagement from "./TopicManagement/TopicManagement"

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const RoleBasedTabs = {
    NULL: [{
        label: "Types",
        component: <Types />,
        index: 0
    },
    {
        label: "Types(UI)",
        component: <TypesUI />,
        index: 1
    },
    {
        label: "Model",
        component: <Model />,
        index: 2
    },
    {
        label: "Model(UI)",
        component: <ModelConfigure />,
        index: 3
    },
    {
        label: "Device",
        component: <Device />,
        index: 4
    },
    {
        label: "Property",
        component: <Gateway />,
        index: 5
    }],
    BASIC: [{
        label: "Types",
        component: <Types />,
        index: 0
    },
    {
        label: "Types(UI)",
        component: <TypesUI />,
        index: 1
    },
    {
        label: "Model",
        component: <Model />,
        index: 2
    },
    {
        label: "Model(UI)",
        component: <ModelConfigure />,
        index: 3
    },
    {
        label: "Device",
        component: <Device />,
        index: 4
    },
    {
        label: "Property",
        component: <Gateway />,
        index: 5
    },
    {
        label: "Key Management",
        component: <KeyManagement />,
        index: 6
    }],
    STANDARD: [{
        label: "Types",
        component: <Types />,
        index: 0
    },
    {
        label: "Types(UI)",
        component: <TypesUI />,
        index: 1
    },
    {
        label: "Model",
        component: <Model />,
        index: 2
    },
    {
        label: "Model(UI)",
        component: <ModelConfigure />,
        index: 3
    },
    {
        label: "Device",
        component: <Device />,
        index: 4
    },
    {
        label: "Property",
        component: <Gateway />,
        index: 5
    },
    {
        label: "Key Management",
        component: <KeyManagement />,
        index: 6
    }],
    PREFFERED: [{
        label: "Types",
        component: <Types />,
        index: 0
    },
    {
        label: "Types(UI)",
        component: <TypesUI />,
        index: 1
    },
    {
        label: "Model",
        component: <Model />,
        index: 2
    },
    {
        label: "Model(UI)",
        component: <ModelConfigure />,
        index: 3
    },
    {
        label: "Device",
        component: <Device />,
        index: 4
    },
    {
        label: "Property",
        component: <Gateway />,
        index: 5
    },
    {
        label: "Key Management",
        component: <KeyManagement />,
        index: 6
    }],
    PREMIUM: [{
        label: "Types",
        component: <Types />,
        index: 0
    },
    {
        label: "Types(UI)",
        component: <TypesUI />,
        index: 1
    },
    {
        label: "Model",
        component: <Model />,
        index: 2
    },
    {
        label: "Model(UI)",
        component: <ModelConfigure />,
        index: 3
    },
    {
        label: "Device",
        component: <Device />,
        index: 4
    },
    {
        label: "Property",
        component: <Gateway />,
        index: 5
    },
    {
        label: "Key Management",
        component: <KeyManagement />,
        index: 6
    }],
    ADMIN: [{
        label: "Types",
        component: <Types />,
        index: 0
    },
    {
        label: "Types(UI)",
        component: <TypesUI />,
        index: 1
    },
    {
        label: "Model",
        component: <Model />,
        index: 2
    },
    {
        label: "Model(UI)",
        component: <ModelConfigure />,
        index: 3
    },
    {
        label: "Device",
        component: <Device />,
        index: 4
    },
    {
        label: "Property",
        component: <Gateway />,
        index: 5
    },
    {
        label: "Key Management",
        component: <KeyManagement />,
        index: 6
    },
    {
        label: "Topic Management",
        component: <TopicManagement />,
        index: 7
    }]
}


const DeviceManagement = () => {

    const [value, setValue] = React.useState(0);
    const Role = localStorage.getItem("UserRole");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            {Role && <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {RoleBasedTabs[Role]?.map((item) => (
                            <Tab key={item.index} label={item.label} {...a11yProps(item.index)} />
                        ))}
                    </Tabs>
                </Box>

                {RoleBasedTabs[Role].map((item) => (
                    <CustomTabPanel key={item.index} value={value} index={item.index}>
                        {item.component}
                    </CustomTabPanel>
                ))}
            </Box>}

        </>
    )
}

export default DeviceManagement