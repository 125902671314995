import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./SerializationComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import { createTheme } from "@material-ui/core/styles";
import Papa from "papaparse";
import ContractFarmingComponent from "../../AssetDistributor/ContractFarming/ContractFarmingComponent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function SerializationComponent(props) {
  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);
  const [file, setFile] = useState(false);
  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);
  const classes = useStyles();
  const theme = createTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [parentId, setParentId] = useState("");
  const [fgcolor, setFgcolor] = useState("000");
  const [bgcolor, setBgcolor] = useState("f0ffff");
  const [data, setData] = useState("No result");
  const [isLoading, setIsLoading] = useState(false);
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [assetsList, setAssetsList] = useState([]);
  const [modelList, setModelsList] = useState([]);
  const [allModelsData, setAllModelsData] = useState([]);
  const [filterModelsData, setFilterModelsData] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [qrCodeDialog, setQrCodeDialog] = useState(false);
  const [modelCreationDialog, setModelCreationDialog] = useState(false);
  const [qrSizeValue, setQrSizeValue] = useState("400");
  const [qrUrlValue, setQrUrlValue] = useState("");
  const { user } = MainService.getLocalData();
  const { username } = user;

  const [selectedVerticalName, setSelectedVerticalName] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [getListView, setListView] = useState(false);
  const [getGridView, setGridView] = useState(true);
  const [qrCodeFile, setQrCodeFile] = useState("");
  const [qrCodeFilePath, setQrCodeFilePath] = useState("");
  const [qrCodeFileDownload, setQrCodeFileDownload] = useState("");
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");

  /* create site */
  const [assetIdValue, setAssetIdValue] = useState("");
  const [legacyAssetFriendlyNameValue, setLegacyAssetFriendlyNameValue] =
    useState("");
  const [assetDomainValue, setAssetDomainValue] = useState("");
  const [siteNameValue, setSiteNameValue] = useState("");
  const [zoneValue, setZoneValue] = useState("");
  const [lotNameValue, setLotNameValue] = useState("");
  const [manufacturerIdValue, setManufacturerIdValue] = useState(username);
  const [manufacturerUrlValue, setManufacturerUrlValue] = useState("");
  const [modelFriendlyNameValue, setModelFriendlyNameValue] = useState("");
  const [modelIdValue, setModelIdValue] = useState("");
  const [modelUrlValue, setModelUrlValue] = useState("");
  const [serialNoValue, setSerialNoValue] = useState("");
  const [udnValue, setUdnValue] = useState("");
  const [upcValue, setUpcValue] = useState("");
  const [motherBreedValue, setMotherBreedValue] = useState("");
  const [fatherBreedValue, setFatherBreedValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [widthValue, setWidthValue] = useState("");
  const [heightValue, setHeightValue] = useState("");
  const [depthValue, setDepthValue] = useState("");
  const [whiteLabelCostValue, setWhiteLabelCostValue] = useState("");
  const [sellingCostValue, setSellingCostValue] = useState("");
  const [acquireCostValue, setAcquireCostValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [altitudeValue, setAltitudeValue] = useState("");
  const [selectedSite, setSelectedSite] = useState(
    "0d5c0326-a529-49bb-b233-e0a5c0114b1e"
  );
  const [assetPopup, setShowAssetPopup] = useState(false);
  const [getUserTypeValue, setUserTypeValue] = useState("");
  const [sitesList, setSitesList] = useState([]);
  const [selectedSiteName, setSelectedSiteName] = useState("StoringProducts");
  const [deleteModelDialog, setDeleteModelDialog] = useState(false);
  /* Org sub org */

  const [orgId, setOrgId] = useState("");
  const [subOrgId, setSubOrgId] = useState("");
  const [superParent, setSuperParent] = useState();

  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = filterModelsData.filter((row) => {
      return row.modelfriendlyname.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAllModelsData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = filterModelsData.filter((row) => {
      return row.modelfriendlyname.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAllModelsData(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(() => {
    getParent();
    getAssetVerticalsIdList();
  }, []);

  useEffect(() => {
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  useEffect(() => {
    getAssetTypesList();
  }, [selectedVerticalsId, selectedCategory]);

  useEffect(() => {
    getAllModels();
  }, [selectedVerticalsId, selectedCategory, selectedAssertTypes]);

  const getParent = () => {
    MainService.getParentId(username)
      .then((data) => {
        setParentId(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const getAllSites = () => {
    MainService.getAllSites(username)
      .then((data) => {
        if (data.length > 0) {
          setSelectedSite(data[0].siteid);
          setSelectedSiteName(data[0].sitename);
          setSitesList(data);
        } else {
          setSelectedSite("");
          setSelectedSiteName("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const setSelectedVerticalsIdHandler = (e) => {
    setSelectedVerticalsId(e.target.value);
    let catname = verticalsIdList.filter((data) => {
      return data.assetVerticalId === e.target.value;
    });
    setSelectedVerticalName(catname[0].friendlyName);
    const filteredRows = setFilterUsers.filter((row) => {
      return row.assetverticalId.includes(e.target.value);
    });
    setAssetsList(filteredRows);
  };
  const csvFileChangeHandler = (event) => {
    setFile(true);
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        // Parsed Data Response in array format
        setParsedData(results.data);
        console.log("csv results data:", results.data);

        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        setValues(valuesArray);
      },
    });
  };

  const getUserType = async () => {
    await MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        console.log("getUserTypeValue1", data);
        setUserTypeValue(data);
        // return data;
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const qrCodeDialogHandler = (modelId) => {
    setSelectedModel(modelId);
    setQrCodeDialog(!qrCodeDialog);
  };
  const submitQrDialogHandler = () => {
    try {
      MainService.getConsumerUserType(parentId, username)
        .then((data) => {
          console.log("getUserTypeValue1", data);
          setUserTypeValue(data);
          if (data !== undefined) {
            if (
              data === "AssetDeveloper" ||
              data === "LogisticsProvider" ||
              data === "BROKER" ||
              data === "ENERGY-PROCESSOR" ||
              data === "FOOD-PROCESSOR" ||
              data === "FMC" ||
              data === "PMC" ||
              data === "FMCDealer" ||
              data === "PMCDealer" ||
              data === "OFC" ||
              data === "StorageCompany"
            ) {
              setOrgId(username);
              console.log("org id1:", orgId);
              setSubOrgId("");
            } else if (
              data === "Production-Manager" ||
              data === "LogisticManager" ||
              data === "FF"
            ) {
              setOrgId(parentId);
              setSubOrgId("");
            }
            if (username !== "") {
              console.log("org id2:", orgId);
              let qrBody = {
                iwidth: qrSizeValue,
                fcolor: fgcolor,
                bcolor: bgcolor,
                cadminid: parentId,
                assetverticalid: selectedVerticalsId,
                avname: selectedVerticalName,
                acategory: selectedCategory,
                atype: selectedAssertTypes,
                modelid: selectedModel,
                bname: username,
                url: qrUrlValue,
              };
              const qrBodyStringify = JSON.stringify(qrBody);
              MainService.generateModelQrCode(
                qrBodyStringify,
                username,
                subOrgId,
                parentId,
                username,
                data
              )
                .then((data) => {
                  getAllModels("Grid");
                  setQrCodeDialog(!qrCodeDialog);
                  return NotificationManager.success(
                    "QR code generated successfully"
                  );
                })
                .catch((err) => {
                  console.error(err);
                  return NotificationManager.error(err);
                });
            }
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    } catch (error) {
      // Handle errors here
      console.error(error);
      NotificationManager.error(error.message || "An error occurred");
    }
  };

  const getAllModels = (Type) => {
    setIsLoading(true);
    if (Type === "List") {
      setListView(true);
      setGridView(false);
    } else if (Type === "Grid") {
      setListView(false);
      setGridView(true);
    }
    if (selectedVerticalsId && selectedCategory && selectedAssertTypes) {
      MainService.getModelsList(
        selectedVerticalsId,
        selectedCategory,
        selectedAssertTypes,
        username
      )
        .then((data) => {
          if (data.length > 0) {
            console.log("models data:", data);
            setIsLoading(false);
            setAllModelsData(data);
            setFilterModelsData(data);
          } else {
            setIsLoading(false);
            setAllModelsData([]);
            setFilterModelsData([]);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    } else {
      setIsLoading(false);
      setAllModelsData([]);
    }
  };

  const getAssetVerticalsIdList = () => {
    setIsLoading(true);
    MainService.getAssetVerticalsIdList(window.AppConfigData.EnterpriseId)
      .then((data) => {
        if (data.length > 0) {
          /* const filteredAssetVerticals = data.filter((row) => {
            return row.friendlyName === "ANIMAL" || row.friendlyName === "AGRICULTURE" ||
             row.friendlyName === "AQUACULTURE"  ;   
           });            */
          setVerticalsIdList(data);
          setSelectedVerticalsId(data[0].assetVerticalId);
          setSelectedVerticalName(data[0].friendlyName);
        } else {
          setSelectedVerticalsId("");
          setSelectedVerticalName("");
          setVerticalsIdList([]);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getCategoryTypesList = () => {
    setIsLoading(true);
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(
        selectedVerticalsId.replace(" ", ""),
        window.AppConfigData.EnterpriseId
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setSelectedCategory("");
            setCategoryTypesList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getAssetTypesList = () => {
    setIsLoading(true);
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        window.AppConfigData.EnterpriseId
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
          } else {
            setSelectedAssertTypes("");
            setAssertTypesList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const setSelectedSiteHandler = (e) => {
    setSelectedSite(e.target.value);
    let catname = sitesList.filter((data) => {
      return data.siteid === e.target.value;
    });
    setSelectedSiteName(catname[0].sitename);
  };
  const createAssetClick = () => {
    let createAssetData = {
      assetFriendlyName: legacyAssetFriendlyNameValue,
      assetVerticalId: selectedVerticalsId,
      categoryType: selectedCategory,
      legacyAssetType: selectedAssertTypes,
      assetDomain: assetDomainValue,
      siteId: selectedSite,
      siteName: selectedSiteName,
      lotId: lotNameValue,
      lotName: lotNameValue,
      manufacturerId: manufacturerIdValue,
      manufacturUrl: manufacturerUrlValue,
      modelId: selectedModel,
      modelName: selectedModel,
      modelType: selectedModel,
      modelUrl: modelUrlValue,
      serialNo: serialNoValue,
      udn: udnValue,
      upc: upcValue,
      motherBreed: motherBreedValue,
      fatherBreed: fatherBreedValue,
      description: descriptionValue,
      whitelableCost: whiteLabelCostValue,
      sellingcost: sellingCostValue,
      acquireCost: acquireCostValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      actorType: getUserTypeValue,
      consumerAdminId: parentId,
    };
    const legacyAssetData = JSON.stringify(createAssetData);
    if (legacyAssetFriendlyNameValue && assetIdValue) {
      MainService.createLegacyAsset(legacyAssetData, username, assetIdValue)
        .then((res) => {
          return NotificationManager.success(res);
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
      setShowAssetPopup(!assetPopup);
    } else {
      return NotificationManager.error("Please fill all required fields");
    }
  };
  /*Create Asset */
  const createAssetModal = (
    <span
      onClick={() => {
        setShowAssetPopup(!assetPopup);
      }}
    >
      X
    </span>
  );
  const titleCreateAsset = "Create Asset";
  const assetCreationBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetId">Legacy Asset ID* :</InputLabel>
        <Input
          id="assetId"
          name="assetId"
          type="text"
          placeholder="GBRBB00501"
          value={assetIdValue}
          onChange={(e) => {
            setAssetIdValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="legacyAssetFriendlyName">
          Legacy Asset Friendly Name *:
        </InputLabel>
        <Input
          id="legacyAssetFriendlyName"
          name="legacyAssetFriendlyName"
          placeholder="Betal Sheep"
          type="text"
          value={legacyAssetFriendlyNameValue}
          onChange={(e) => {
            setLegacyAssetFriendlyNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost"> Acquire Cost *:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="number"
          value={acquireCostValue}
          onChange={(e) => {
            setAcquireCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Name*</InputLabel>
        <Select
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedSite}
          onChange={(e) => {
            setSelectedSiteHandler(e);
          }}
        >
          {sitesList &&
            sitesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.siteid}>
                {idObj.sitename}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>AssetVerticalId:</InputLabel>
        <Input
          disabled
          id="assetVerticalID"
          name="assetVerticalID"
          type="text"
          value={selectedVerticalsId}
        ></Input>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>AssetVertical FriendlyName:</InputLabel>
        <Input
          disabled
          id="assetVerticalID"
          name="assetVerticalID"
          type="text"
          value={selectedVerticalName}
        ></Input>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Category Type*</InputLabel>
        <Input
          disabled
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedCategory}
        ></Input>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Model</InputLabel>
        <Input
          disabled
          id="selectedModel"
          value={selectedModel}
          label="Select Model"
        ></Input>
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelType"> Model Type:</InputLabel>
        <Input
          disabled
          id="modelType"
          name="modelType"
          type="text"
          value={selectedModel}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Asset Type</InputLabel>
        <Input
          disabled
          id="selectedAssertTypes"
          value={selectedAssertTypes}
          label="Select Type"
        ></Input>
      </FormControl>
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={(e) => createAssetClick()}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowAssetPopup(!assetPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  const submitCreateModelDialogHandler = () => {
    setIsLoading(true);
    let createModelData = {
      consumeradminid: username,
      assetverticalid: selectedVerticalsId,
      assetverticalfriendlyname: selectedVerticalName,
      categorytype: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: modelIdValue,
      modelfriendlyname: modelFriendlyNameValue,
      modelurl: modelUrlValue,
      description: descriptionValue,
      whitelabelcost: whiteLabelCostValue,
      sellingcost: sellingCostValue,
      acquirecost: acquireCostValue,
    };
    const modelData = JSON.stringify(createModelData);
    if (modelIdValue && modelFriendlyNameValue) {
      MainService.createModel(username, modelData)
        .then((res) => {
          getAllModels("Grid");
          return NotificationManager.success(res);
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
      setModelCreationDialog(!modelCreationDialog);
    } else {
      return NotificationManager.error("Please fill all required fields");
    }
    setAcquireCostValue("");
    setSellingCostValue("");
    setWhiteLabelCostValue("");
    setDepthValue("");
    setModelUrlValue("");
    setModelFriendlyNameValue("");
    setModelIdValue("");
  };
  const deleteModelDialogClick = (modelId) => {
    setSelectedModel(modelId);
    setDeleteModelDialog(!deleteModelDialog);
  };
  const submitDeleteModelDialog = () => {
    MainService.deleteModel(
      username,
      selectedAssertTypes,
      selectedModel,
      selectedVerticalsId,
      selectedCategory
    )
      .then((res) => {
        getAllModels("Grid");
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
      
    setDeleteModelDialog(!deleteModelDialog);
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Add Model"
          className={classes.fab}
          onClick={() => setModelCreationDialog(!modelCreationDialog)}
        >
          <AddIcon></AddIcon>
        </Fab>
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Sector
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsIdHandler(event);
                }}
                label="Select Verticals"
              >
                {verticalsIdList.length > 0 ? (
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <h4>Sector not found</h4>
                )}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Category
              </InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                  const filteredRows = setFilterUsers.filter((row) => {
                    return row.categoryType
                      .toLowerCase()
                      .includes(event.target.value.toLowerCase());
                  });
                  setAssetsList(filteredRows);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Asset Type
              </InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                  const filteredRows = setFilterUsers.filter((row) => {
                    return row.assetType
                      .toLowerCase()
                      .includes(event.target.value.toLowerCase());
                  });
                  setAssetsList(filteredRows);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                getAllModels("Grid");
              }}
            ></Button>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                getAllModels("List");
              }}
            ></Button>
          </div>
        </div>
        <div className="MuiGrid-root">
          {/* Models Grid view */}
          {getGridView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <SearchBar
                value={farmgridsearched}
                placeholder="Search by Model Name"
                onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                onCancelSearch={() => cancelFarmGridSearch()}
              />
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    List Of Models
                  </h4>
                  {allModelsData.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {allModelsData.map((models, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Developer:</b>
                              {models.assetdeveloperid}
                            </p>
                            <p>
                              <b>AssetVerticalId:</b>
                              {models.assetverticalid}
                            </p>
                            <p>
                              <b>AssetVertical friendlyName:</b>
                              {models.assetverticalfriendlyname}
                            </p>
                            <p>
                              <b>CategoryType:</b>
                              {models.categorytype}
                            </p>

                            <p>
                              <b>Asset Type:</b>
                              {models.assettype}
                            </p>

                            <p>
                              <b>ModelId:</b>
                              {models.modelid}
                            </p>
                            <p>
                              <b>Model Name:</b>
                              {models.modelfriendlyname}
                            </p>
                            <p>
                              <b>Model Url:</b>
                              <a href={models.modelurl} target="blank">
                                {models.modelurl}
                              </a>
                            </p>

                            <div>
                              {/* <img src={models.modelqrcode} alt="img"></img>
                              <br /> */}

                              {models.modelqrcode !== "" &&
                              models.modelqrcode !== "null" ? (
                                <div>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="registerBtn"
                                  >
                                    <a
                                      href={`${models.modelqrcode}`}
                                      target="_blank"
                                      download
                                    >
                                      Download QR
                                    </a>
                                  </Button>
                                </div>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="registerBtn"
                                  type="submit"
                                  onClick={(e) =>
                                    qrCodeDialogHandler(models.modelid)
                                  }
                                >
                                  Generate
                                </Button>
                              )}
                            </div>
                            <div>
                              <h3 className="cardactiontitle">Operate</h3>
                              <div style={{ width: "100%", margin: "5px" }}>
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={(e) =>
                                    deleteModelDialogClick(models.modelid)
                                  }
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>No Models Found.</h4>
                  )}
                </div>
              )}
            </Box>
          ) : null}
          {/* Models List View */}
          {getListView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <div className="cardStyleForDashboardItems">
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <SearchBar
                      value={searched}
                      placeholder="Search by Model Name"
                      onChange={(searchVal) => requestSearch(searchVal)}
                      onCancelSearch={() => cancelSearch()}
                    />
                    {allModelsData.length > 0 ? (
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={11}>
                                  List Of Models
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell align="center">
                                  Asset Developer
                                </TableCell>
                                <TableCell align="center">
                                  AssetVertical Name
                                </TableCell>
                                <TableCell align="center">
                                  Category Type
                                </TableCell>
                                <TableCell align="center">Asset Type</TableCell>
                                <TableCell align="center">ModelId</TableCell>
                                <TableCell align="center">Model Name</TableCell>
                                <TableCell align="center">Model Url</TableCell>
                                <TableCell align="center">QR Code</TableCell>
                                <TableCell align="center" colSpan={2}>
                                  Operate
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {allModelsData.length > 0 ? (
                                allModelsData
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((models, index) => {
                                    return (
                                      <TableRow hover tabIndex={-1} key={index}>
                                        <TableCell align="center">
                                          {models.assetdeveloperid}
                                        </TableCell>
                                        <TableCell align="center">
                                          {models.assetverticalfriendlyname}
                                        </TableCell>
                                        <TableCell align="center">
                                          {models.categorytype}
                                        </TableCell>
                                        <TableCell align="center">
                                          {models.assettype}
                                        </TableCell>
                                        <TableCell align="center">
                                          {models.modelid}
                                        </TableCell>
                                        <TableCell align="center">
                                          {models.modelfriendlyname}
                                        </TableCell>
                                        <TableCell align="center" style={{}}>
                                          <a
                                            href={models.modelurl}
                                            target="blank"
                                            style={{
                                              display: "block",
                                              overflowWrap: "anywhere",
                                            }}
                                          >
                                            {models.modelurl}
                                          </a>
                                        </TableCell>

                                        <TableCell align="center">
                                          {models.modelqrcode !== "" &&
                                          models.modelqrcode !== "null" ? (
                                            <div>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                className="registerBtn"
                                              >
                                                <a
                                                  className="downloadDocument"
                                                  href={models.modelqrcode}
                                                  target="_blank"
                                                  download
                                                >
                                                  Download
                                                </a>
                                              </Button>
                                            </div>
                                          ) : (
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className="registerBtn"
                                              type="submit"
                                              onClick={(e) =>
                                                qrCodeDialogHandler(
                                                  models.modelid
                                                )
                                              }
                                            >
                                              Generate
                                            </Button>
                                          )}
                                        </TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center">
                                          <Button
                                            size="small"
                                            variant="contained"
                                            onClick={(e) =>
                                              deleteModelDialogClick(
                                                models.modelid
                                              )
                                            }
                                          >
                                            Delete
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                              ) : (
                                <TableRow align="center">
                                  <TableCell align="center" colSpan={11}>
                                    Models not found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={allModelsData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <h4>Models not found.</h4>
                    )}
                  </Box>
                )}
              </div>
            </Box>
          ) : null}
        </div>
      </div>
      {/* Qr code Generate */}

      <Dialog
        fullScreen={fullScreen}
        open={qrCodeDialog}
        onClose={() => {
          setQrCodeDialog(!qrCodeDialog);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Generate QR Code"}
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="AssetVerticalId">AssetVerticalId:</InputLabel>
            <Input
              id="AssetVerticalId"
              name="AssetVerticalId"
              type="text"
              value={selectedVerticalsId}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="AssetVerticalName">
              AssetVertical Name:
            </InputLabel>
            <Input
              id="AssetVerticalName"
              name="AssetVerticalName"
              type="text"
              value={selectedVerticalName}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="category">Asset Category:</InputLabel>
            <Input
              id="category"
              name="category"
              type="text"
              value={selectedCategory}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="assetType">Asset Type:</InputLabel>
            <Input
              id="assetType"
              name="assetType"
              type="text"
              value={selectedAssertTypes}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="modelId">ModelId:</InputLabel>
            <Input
              id="modelId"
              name="modelId"
              type="text"
              value={selectedModel}
            />
          </FormControl>

          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="bname">Branch Name:</InputLabel>
            <Input id="bname" name="bname" type="text" value={username} />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="bname">Url:</InputLabel>
            <Input
              id="bname"
              name="bname"
              type="text"
              value={qrUrlValue}
              onChange={(e) => setQrUrlValue(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              submitQrDialogHandler(selectedModel);
            }}
          >
            Generate
          </Button>{" "}
          &nbsp;
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setQrCodeDialog(!qrCodeDialog);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* create Model */}
      <Dialog
        fullScreen={fullScreen}
        open={modelCreationDialog}
        onClose={() => {
          setModelCreationDialog(!modelCreationDialog);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Create Model"}</DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          {/* <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="AssetVerticalId">AssetVerticalId:</InputLabel>
            <Input
            disabled
              id="AssetVerticalId"
              name="AssetVerticalId"
              type="text"
              value={selectedVerticalsId}
            />
          </FormControl> */}
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="AssetVerticalName">
              AssetVertical Name:
            </InputLabel>
            <Input
              disabled
              id="AssetVerticalName"
              name="AssetVerticalName"
              type="text"
              value={selectedVerticalName}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="category">Asset Category:</InputLabel>
            <Input
              disabled
              id="category"
              name="category"
              type="text"
              value={selectedCategory}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="assetType">Asset Type:</InputLabel>
            <Input
              disabled
              id="assetType"
              name="assetType"
              type="text"
              value={selectedAssertTypes}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="modelId">ModelId *:</InputLabel>
            <Input
              id="modelId"
              name="modelId"
              type="text"
              value={modelIdValue}
              onChange={(e) => setModelIdValue(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="modelId">Model FriendlyName *:</InputLabel>
            <Input
              id="modelName"
              name="modelName"
              type="text"
              value={modelFriendlyNameValue}
              onChange={(e) => setModelFriendlyNameValue(e.target.value)}
            />
          </FormControl>

          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="murl">Model Url:</InputLabel>
            <Input
              id="murl"
              name="murl"
              type="text"
              value={modelUrlValue}
              onChange={(e) => setModelUrlValue(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="bname">Description:</InputLabel>
            <Input
              id="murl"
              name="murl"
              type="text"
              value={descriptionValue}
              onChange={(e) => setDescriptionValue(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="acost">Acquire Cost:</InputLabel>
            <Input
              id="acost"
              name="acost"
              type="number"
              value={acquireCostValue}
              onChange={(e) => setAcquireCostValue(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="wcost">WhiteLabel Cost:</InputLabel>
            <Input
              id="wcost"
              name="wcost"
              type="number"
              value={whiteLabelCostValue}
              onChange={(e) => setWhiteLabelCostValue(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="scost">Selling Cost:</InputLabel>
            <Input
              id="scost"
              name="scost"
              type="number"
              value={sellingCostValue}
              onChange={(e) => setSellingCostValue(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              submitCreateModelDialogHandler();
            }}
          >
            Create
          </Button>{" "}
          &nbsp;
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setModelCreationDialog(!modelCreationDialog);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Model */}
      <Dialog
        fullScreen={fullScreen}
        open={deleteModelDialog}
        // onClose={handleClose}
        aria-labelledby="deleteModel"
      >
        <DialogTitle id="deleteModel">{"Delete Model"}</DialogTitle>
        <DialogContent>
          <h4>Are you sure want to delete?</h4>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              submitDeleteModelDialog();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setDeleteModelDialog(!deleteModelDialog);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CustomPopup
        closeButton={createAssetModal}
        isOpen={assetPopup}
        closeModal={assetPopup}
        content={assetCreationBody}
        title={titleCreateAsset}
      />
    </>
  );
}
