
import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";
import { NotificationManager } from "react-notifications";
import { Button } from "@material-ui/core";
import { FiThumbsUp } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";

import FilterListIcon from "@mui/icons-material/FilterList";
class RawProducts extends Component {
  constructor(props) {
    super(props);
    console.log(props, "userTypeuserTypeuserType");

    this.state = {
      perPageprod: 6,
      offsetprod: 0,
      prodPageCount: 1,
      currentPageprod: 0,
      farmMachinerysData: [],
      filteredfarmMachinerysData: [],
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      productOwnsers: [],
      productsSubOwner: [],
      suppliesidVlaue: "",
      farmgridsearched: "",
      filterIdValue: "",
      subCategoriesList: [],
      brandRelatedSubCategory: [],
      type: props.type,
      sortOption: "name",
      sortData: [],
      filterInStock: false,
      filterOutOfStock: false,
      startPrice: "",
      endPrice: "",
      show: false,
      maxPrice: [],
      selectedBrands: [],
      testData: [],
      testData2: [],
      checkTestData: false,
      checkTestData2: false,
      allCheckboxesSelected: false,
      showMore: false,
      subcatVal: "",
      counting: "",
      userType: props.userType,
      user: props.user,
      categoryType: props.categoryType,
      services:props.services
    };
  }


  componentDidMount() {
    this.getProductCategory();
    this.getContentSubOwners();
    this.userTypesHandler();
  }

  userTypesHandler = () => {
    for (let i = 0; i < this.state.userType.length; i++) {
      this.getContentCreators(this.state.userType[i]);
    }
  };

  handleFilter = () => {
    const {
      filterInStock,
      filterOutOfStock,
      startPrice,
      endPrice,
      filteredfarmMachinerysData,
    } = this.state;

    const filteredData = filteredfarmMachinerysData.filter((item) => {
      const price = item.productcost;
      const min = parseFloat(startPrice);
      const max = parseFloat(endPrice);

      if (!isNaN(min) && !isNaN(max)) {
        if (price < min || price > max) {
          return false;
        }
      } else if (!isNaN(min)) {
        if (price < min) {
          return false;
        }
      } else if (!isNaN(max)) {
        if (price > max) {
          return false;
        }
      }

      if (filterInStock && filterOutOfStock) {
        return item;
      }
      if (filterInStock) {
        return item.itemcount !== 0;
      }

      if (filterOutOfStock) {
        return item.itemcount === 0;
      }

      return item;
    });

    this.setState({
      farmMachinerysData: filteredData,
      prodPageCount: Math.ceil(filteredData.length / this.state.perPageprod),
    });
  };

  handleSort = (sortKey) => {
    this.setState({
      offsetprod: 0,
    });
    let sortedData = [...this.state.filteredfarmMachinerysData];

    if (sortKey === "A_Z") {
      sortedData.sort((a, b) =>
        a.productfriendlyname.localeCompare(b.productfriendlyname)
      );
    } else if (sortKey === "Z_A") {
      sortedData.sort((a, b) =>
        b.productfriendlyname.localeCompare(a.productfriendlyname)
      );
    } else if (sortKey === "PRICE_LOW_TO_HIGH") {
      sortedData.sort((a, b) => a.productcost - b.productcost);
    } else if (sortKey === "PRICE_HIGH_TO_LOW") {
      sortedData.sort((a, b) => b.productcost - a.productcost);
    } else if (sortKey === "DATE_OLD_TO_NEW") {
      sortedData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    } else if (sortKey === "DATE_NEW_TO_OLD") {
      sortedData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    } else if (sortKey === "BESTSELLER") {
      sortedData = sortedData.filter((e) => e.ratings >= 4);
    }

    this.setState({
      sortOption: sortKey,
      farmMachinerysData: sortedData,
      prodPageCount: Math.ceil(sortedData.length / this.state.perPageprod),
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.suppliesidVlaue) return;
    if (prevState.suppliesidVlaue !== this.state.suppliesidVlaue) {
      this.getAllProcessedProducts(this.state.suppliesidVlaue);
    }
    if (prevState.testData !== this.state.testData) {
      this.setState({
        currentPageprod: 0,
        offsetprod: 0,

        farmMachinerysData: this.state.testData,
        filteredfarmMachinerysData: this.state.testData,
        prodPageCount: Math.ceil(
          this.state.testData.length / this.state.perPageprod
        ),
      });
      console.log("prodPageCount", this.state.prodPageCount);
      console.log("currentPageprod", this.state.currentPageprod);
    } else if (
      this.state.selectedBrands.length === 0 &&
      this.state.checkTestData === true
    ) {
      this.getAllProcessedProducts(this.state.suppliesidVlaue);
      this.setState({
        checkTestData: false,
      });
    }

    if (prevState.testData2 !== this.state.testData2) {
      this.setState({
        farmMachinerysData: this.state.testData2,
        filteredfarmMachinerysData: this.state.testData2,
        prodPageCount: Math.ceil(
          this.state.testData2.length / this.state.perPageprod
        ),
      });
    } else if (
      this.state.selectedBrands.length === 0 &&
      this.state.checkTestData2 === true
    ) {
      this.subCategoriesOnClick(this.state.subcatVal);
      this.setState({
        checkTestData2: false,
      });
    }
  }

  getAllProcessedProducts(idVal) {
    this.setState({
      subcatVal: "",
    });
    fetch(
      `${window.appConfig.getProductsBasedonCategoryId}${window.appConfig.Relam}${idVal}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          farmMachinerysData: result.products,
          filteredfarmMachinerysData: result.products,
          prodPageCount: Math.ceil(
            result.products.length / this.state.perPageprod
          ),
        });
        this.getCategorySubcategories(this.state.suppliesidVlaue);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  /** Get Brand Value or Raw Products Button*/

  // getContentCreators(catID) {
  //   this.setState({
  //     productsSubOwner: "",
  //     subCategoriesList: "",
  //     brandRelatedSubCategory: "",
  //   });
  //   fetch(
  //     `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}${catID}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + this.state.InitialAccessToken,
  //       },
  //     }
  //   ).then((res) =>{
  //     if(res !== 200){
  //       return null
  //     }
  //     return res.json();
  //   })
  //     // .then((res) => res.json())
  //     .then((result) => {
  //       console.log(result, "YYYYUIYTIYYYYUIYTIYYYYUIYTI")

  //       if (!result) return; 
  //       if(result.users){
  //         console.log(result, "YYYYUIYTI")
  //       this.setState((prevState) => ({
  //         productOwnsers: [...prevState.productOwnsers, result.users].flatMap(
  //           (val) => val
  //         ),
  //       }));
  //       this.getContentSubOwners();
  //       this.getCategorySubcategories(this.state.suppliesidVlaue);}
  //       else{
  //         console.log("No data found")
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // }

  
  getContentCreators(catID) {
    this.setState({
      productsSubOwner: "",
      subCategoriesList: "",
      brandRelatedSubCategory: "",
    });
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}${catID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    ).then((res) =>{
      if(res.status !== 200){
        return null
      }
      return res.json();
    })
      // .then((res) => res.json())
      .then((result) => {

        if (!result) return; 
        if(result.users){
        this.setState((prevState) => ({
          productOwnsers: [...prevState.productOwnsers, result.users].flatMap(
            (val) => val
          ),
        }));
        this.getContentSubOwners();
        this.getCategorySubcategories(this.state.suppliesidVlaue);}
        else{
          console.log("No data found")
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  /** EOD Get Brand Value */

  /** Get Product Category */
  getProductCategory() {
    fetch(
      // "https://proxystaging.gbrservice.com/ContentGw/getCategories/consumer2/consumer2/consumer2",
      // `${window.appConfig.getContentCategories}${window.appConfig.Relam}${window.appConfig.TokenRelam}/sriniad123`,
      `${window.appConfig.getAllCategoriesBasedonCategoryTypeID}${window.appConfig.Relam}${window.appConfig.TokenRelam}/${window.appConfig.Relam}${this.state.categoryType}`,
     
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        const inputCatID = result.categoryList.filter((data) => {
          return data.categoryname === this.state.type;
        });

        this.setState({
          suppliesidVlaue: inputCatID[0].categoryid,
        });
        this.getAllProcessedProducts(inputCatID[0].categoryid);
        this.getCategorySubcategories(inputCatID[0].categoryid);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  /** EOD Get Product Category */

  /** Get Sub Brand Values */
  getContentSubOwners() {
    const url = `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}${this.state.userType}`;
  
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.InitialAccessToken,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((result) => {
        if (result && result.users) {
          this.setState({
            productsSubOwner: result.users,
          });
        } else {
          console.log("No users found in the result");
        }
      })
      .catch((err) => {
        console.error("Error fetching content sub-owners:", err);
      });
  }
  
  /** EOD Get Sub Brand Values */

  /** Get Sub Brand Bsed FilterProducts */

  supplierUserProductsBrand = (val) => {
    console.log(val);
    this.setState({
      subCategoriesList: "",
    });
    this.getCategorySubcategories(this.state.suppliesidVlaue);

    fetch(
      `${window.appConfig.getProductsBasedonAdminUser}${window.appConfig.Relam}${val}/${this.state.suppliesidVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("result.products.length", result.products);
        this.setState((prevState) => ({
          testData: [...prevState.testData, result.products].flatMap(
            (val) => val
          ),
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  /** EOD Get Sub Brand Bsed FilterProducts */

  /** Get Sub Categories Values */
  getCategorySubcategories(catID) {
    fetch(
    
      `${window.appConfig.getSubCategoriesBasedonCategoryID}${window.appConfig.Relam}${window.appConfig.Relam}contentSubCategories/${catID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        const unique = [
          ...new Map(
            result.contentsubcategories.map((data) => [
              data.contentsubcategoryname,
              data,
            ])
          ).values(),
        ];
        this.setState({
          subCategoriesList: unique,
        });
        this.getContentSubOwners();
      })
      .catch((err) => console.log("err", err));
  }
  /** EOD Get Sub Categories Values */

  // getCategoryRelatedSubCategories

  getCategoryRelatedSubcategories(userName, catID) {
    fetch(
      `${window.appConfig.getCategoryRelatedSubCategories}${window.appConfig.Relam}${userName}/${catID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        const unique = [
          ...new Map(
            result.contentsubcategories.map((data) => [
              data.contentsubcategoryname,
              data,
            ])
          ).values(),
        ];
        this.setState({
          brandRelatedSubCategory: unique,
        });
      })
      .catch((err) => console.log("err", err));
  }

  /** Get Sub Categories Based Filtering */
  subCategoriesOnClick(subCategory) {
    this.setState({
      productsSubOwner: "",
      subcatVal: subCategory,
    });
    this.getContentSubOwners();
    fetch(
      // `https://content.gbrservice.com/AgriProductProxy/getproductlist/gbrfarming/${this.state.suppliesidVlaue}/${subCategory}`,

      `${window.appConfig.productList}${window.AppConfigData.ConsumerAdmin}/${this.state.suppliesidVlaue}/${subCategory}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          farmMachinerysData: result.products,
          filteredfarmMachinerysData: result.products,
          prodPageCount: Math.ceil(
            result.products.length / this.state.perPageprod
          ),
        });
      })
      .catch((err) => {
        console.log(err, "yyyy");
      });
  }
  /** EOD Get Sub Categories Based Filtering */

  likeCount(product, test) {
    fetch(
      `${window.appConfig.productLikeCount}${product.consumeradminid}/${product.consumeradminid}/${product.businessid}/${product.productid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          Authorization: "Bearer " + this.state.InitialAccessToken,
          networkid: window.AppConfigData.ConsumerAdmin,
          orgid: `${product.businessid}`,
          suborgid: "",
          username: `${product.businessid}`,
        },
      }
    )
      .then((res) => res.text())
      .then((result) => {
        if (this.state.subcatVal === "") {
          this.getAllProcessedProducts(this.state.suppliesidVlaue);
        } else {
          this.subCategoriesOnClick(this.state.subcatVal);
        }

        return NotificationManager.success(result);
      })
      .catch((err) => console.log("err", err));
  }

  /*Pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offsetprod = selectedPage * this.state.perPageprod;
    this.setState({
      currentPageprod: selectedPage,
      offsetprod: offsetprod,
    });
  };
  /*End of pagination */

  OffcanvasCloseHandler = () => {
    this.setState({
      show: false,
    });
  };

  OffcanvasOpenHandler = () => {
    this.setState({
      show: true,
    });
  };

  removeFilterHandler = () => {
    const { subcatVal } = this.state;

    this.setState({
      filterInStock: false,
      filterOutOfStock: false,
      startPrice: "",
      endPrice: "",
      show: false,
      checkTestData: false,
      checkTestData2: false,
      selectedBrands: [],
    });
    if (subcatVal) {
      this.subCategoriesOnClick(subcatVal);
    } else {
      this.getAllProcessedProducts(this.state.suppliesidVlaue);
    }
    this.OffcanvasCloseHandler();
  };

  productBasedonSubcategoryBrand = (brand, subcat, catId) => {
    fetch(
      `${window.appConfig.productBasedonBrand}/${brand}/${catId}/${subcat}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("7607999999999999", result);
        this.setState((prevState) => ({
          testData2: [...prevState.testData2, result.products].flatMap(
            (val) => val
          ),
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleBrandCheckboxChange = (e, brandUsername) => {
    const { subcatVal, suppliesidVlaue } = this.state;
    if (subcatVal) {
      const isChecked = e.target.checked;
      if (isChecked) {
        this.setState(
          (prevState) => ({
            selectedBrands: [...prevState.selectedBrands, brandUsername],
          }),
          () => {
            this.productBasedonSubcategoryBrand(
              brandUsername,
              subcatVal,
              suppliesidVlaue
            );
          }
        );
      } else {
        const updatedBrands = this.state.selectedBrands.filter((brand) => {
          return brand !== brandUsername;
        });

        if (updatedBrands.length === 0) {
          this.setState({
            selectedBrands: [],
            testData2: [],
            checkTestData2: true,
          });
        } else {
          this.setState((prevState) => ({
            selectedBrands: updatedBrands,
            testData2: prevState.testData2.filter(
              (brand) => brand.businessid !== brandUsername
            ),
          }));
        }
      }
    } else {
      const isChecked = e.target.checked;
      if (isChecked) {
        this.setState(
          (prevState) => ({
            selectedBrands: [...prevState.selectedBrands, brandUsername],
          }),
          () => {
            this.supplierUserProductsBrand(brandUsername);
          }
        );
      } else {
        const updatedBrands = this.state.selectedBrands.filter((brand) => {
          return (
            brand.trim().toLowerCase() !== brandUsername.trim().toLowerCase()
          );
        });

        if (updatedBrands.length === 0) {
          this.setState({
            selectedBrands: [],
            testData: [],
            checkTestData: true,
          });
        } else {
          this.setState((prevState) => ({
            selectedBrands: updatedBrands,
            testData: prevState.testData.filter(
              (brand) => brand.businessid !== brandUsername
            ),
          }));
        }
      }
    }
  };

  seeMoreHandler = () => {
    this.setState((prevState) => ({
      showMore: !prevState.showMore,
    }));
  };

  render() {
    {  console.log(this.state.userType, "yryryryrfyyyyyyyyyyyyyyyyyyyy") }

    console.log(
      this.state.currentPageprod,
      this.state.offsetprod,
      this.state.prodPageCount,
      this.state.productsSubOwner,
      this.state.productOwnsers,
      "this.state.currentPageprod offset prodpagecount"
    );
    const arrayData = this.state.farmMachinerysData;
    const AllPrice = arrayData.map((e) => {
      return e.productcost;
    });
    const highestPrice = Math.max.apply(null, AllPrice);
    const { farmMachinerysData, productOwnsers, subCategoriesList, showMore } =
      this.state;
    const visibleOwners = showMore
      ? productOwnsers
      : productOwnsers.slice(0, 5);

    const productsListOne = farmMachinerysData.slice(
      this.state.offsetprod,
      this.state.offsetprod + this.state.perPageprod
    );

    return (
      <>
        {console.log(
          this.state.productsSubOwner,
          this.state.productOwnsers,
          this.state.userType,
          this.state.offsetprod,
          this.state.prodPageCount,
          this.state.currentPageprod,
          "selectedBrandsselectedBrandsselectedBrandsselectedBrands"
        )}
        <div className="explore-products-wrapper">
          <Offcanvas
            show={this.state.show}
            onHide={this.OffcanvasCloseHandler}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <h5 className="text-center mb-0 ">
                  Filter{" "}
                  <span className="d-md-none d-inline-block"> and Sort</span>
                </h5>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="mb-4 offcanvas-body-content">
                <h3 className="mb-3">Availability</h3>
                <div className="mb-4">
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={this.state.filterInStock}
                        onChange={() =>
                          this.setState(
                            {
                              filterInStock: !this.state.filterInStock,
                            },
                            () => {
                              this.handleFilter();
                            }
                          )
                        }
                      />
                      In Stock
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={this.state.filterOutOfStock}
                        onChange={() =>
                          this.setState(
                            {
                              filterOutOfStock: !this.state.filterOutOfStock,
                            },
                            () => {
                              this.handleFilter();
                            }
                          )
                        }
                      />
                      Out of Stock
                    </label>
                  </div>
                </div>

                <h3 className="mb-2">Price</h3>
                <p className="higestPrice mb-2">
                  {highestPrice < 0
                    ? "The highest price is Rs. 0"
                    : `The highest price is Rs. ${highestPrice}`}
                </p>
                <div className="price-range-container mb-4">
                  <Row>
                    <Col md={4} xs={6}>
                      <span>₹</span>{" "}
                      <input
                        placeholder="From"
                        htmlFor="from"
                        type="number"
                        id="from"
                        value={this.state.startPrice}
                        onChange={(e) => {
                          this.setState({ startPrice: e.target.value }, () => {
                            this.handleFilter();
                          });
                        }}
                      />
                    </Col>

                    <Col md={4} xs={6}>
                      <span>₹</span>{" "}
                      <input
                        htmlFor="to"
                        type="number"
                        id="to"
                        placeholder="To"
                        value={this.state.endPrice}
                        onChange={(e) => {
                          this.setState({ endPrice: e.target.value }, () => {
                            this.handleFilter();
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <div className="mt-4 d-md-none d-block">
                    <div className="dropdown">
                      <h3 className="mb-2">Sort by </h3>
                      <div className="navbarRight m-0 w-100">
                        <select
                          id="sortDropdown"
                          defaultValue={0}
                          onChange={(e) => this.handleSort(e.target.value)}
                        >
                          <option value={0} disabled hidden>
                            Select an option
                          </option>
                          <option value="BESTSELLER">Bestseller</option>
                          <option value="A_Z">Alphabetically, A-Z</option>
                          <option value="Z_A">Alphabetically, Z-A</option>
                          <option value="PRICE_LOW_TO_HIGH">
                            Price, Low to High
                          </option>
                          <option value="PRICE_HIGH_TO_LOW">
                            Price, High to Low
                          </option>
                          <option value="DATE_OLD_TO_NEW">
                            {" "}
                            Date, Old to New
                          </option>
                          <option value="DATE_NEW_TO_OLD">
                            Date, New to Old
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className="mb-3">Brands</h3>

                {visibleOwners.map((filter, i) => (
                  <div key={i}>
                    <label>
                      <input
                        type="checkbox"
                        value={filter.username}
                        checked={this.state.selectedBrands.includes(
                          filter.username
                        )}
                        onChange={(e) =>
                          this.handleBrandCheckboxChange(e, filter.username)
                        }
                      />
                      {filter.username}
                    </label>
                  </div>
                ))}

                {productOwnsers.length > 5 && (
                  <p
                    onClick={this.seeMoreHandler}
                    className="mt-2 toggleButton"
                  >
                    {showMore ? "See Less" : "See More"}
                  </p>
                )}
              </div>
            </Offcanvas.Body>

            <div className="offcanvas-footer d-flex justify-content-between">
              <button className="btn-mod" onClick={this.removeFilterHandler}>
                Remove all
              </button>
              <button
                className="btn-success"
                onClick={() => {
                  this.OffcanvasCloseHandler();
                }}
              >
                {" "}
                Apply{" "}
              </button>
            </div>
          </Offcanvas>
        </div>

        <div className="explore-product-wrapper">
          <Button
            className="subcategoryButton me-3 mb-2"
            onClick={() =>
              this.getAllProcessedProducts(this.state.suppliesidVlaue)
            }
          >
            All Products
          </Button>
          {subCategoriesList &&
            subCategoriesList.map((filter, i) => (
              <Button
                className="subcategoryButton me-3 mb-2"
                onClick={() =>
                  this.subCategoriesOnClick(filter.contentsubcategoryname)
                }
              >
                {filter.contentsubcategoryname}
              </Button>
            ))}

          <Row className="justify-content-between mt-4 mb-4">
            <Col md={4} xs={6}>
              <span
                className="container-heading2 m-0 filter-sec d-inline-block"
                onClick={() => {
                  this.OffcanvasOpenHandler();
                }}
              >
                <FilterListIcon className="me-1" />
                Filter{" "}
                <span className="d-md-none d-inline-block"> and Sort</span>
              </span>
            </Col>
            <Col md={8} xs={6} className="d-flex justify-content-end ">
              <div className="dropdown d-md-block d-none">
                <div className="navbarRight m-0 w-auto">
                  <label className="container-heading2 mb-0 me-2">
                    Sort by :{" "}
                  </label>
                  <select
                    id="sortDropdown"
                    defaultValue={0}
                    onChange={(e) => this.handleSort(e.target.value)}
                  >
                    <option value={0} disabled hidden>
                      Select an option
                    </option>
                    <option value="BESTSELLER">Bestseller</option>
                    <option value="A_Z">Alphabetically, A-Z</option>
                    <option value="Z_A">Alphabetically, Z-A</option>
                    <option value="PRICE_LOW_TO_HIGH">
                      Price, Low to High
                    </option>
                    <option value="PRICE_HIGH_TO_LOW">
                      Price, High to Low
                    </option>
                    <option value="DATE_OLD_TO_NEW"> Date, Old to New</option>
                    <option value="DATE_NEW_TO_OLD">Date, New to Old</option>
                  </select>
                </div>
              </div>
              <p className="container-heading2 ms-3 m-0">
                {farmMachinerysData.length} Products
              </p>
            </Col>
          </Row>
          {console.log(
            productsListOne,
            "farmMachinerysDatafarmMachinerysDatafarmMachinerysData"
          )}
          <div className=" homepageSlider">
            {/* Farm Machinery's */}
            <div id="tendersContainer" className="container-fluid">
              <div className="mb-4"></div>
            </div>
            <div className="adminTendersDiv">
              {/* View All Raw Products */}
              {productsListOne?.length !== 0 ? (
                <Row>
                  {productsListOne &&
                    productsListOne.map((product, index) => (
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        className="d-flex mb-4 justify-content-center justify-content-lg-start"
                      >
                        <div className="explore-products-ncards w-100">
                          <Row className="justify-content-between">
                            <Col lg={6}>
                              <div className="explore-cards-image-container mb-lg-0 mb-3">
                                {(() => {
                                  switch (product.productimageurl) {
                                    case "":
                                      break;
                                    case "null":
                                      return (
                                        <div className="image-placeholder"></div>
                                      );
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <img
                                            src={product.productimageurl}
                                            className=""
                                          ></img>
                                        </p>
                                      );
                                  }
                                })()}
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="scrollable-div">
                                <h2 className="product-name d-block mb-2">
                                  {product.productfriendlyname}
                                </h2>
                                <h4 className=" mt-0 mb-lg-3 mb-2 ">
                                  {product.businessid}
                                </h4>

                                <p>
                                  Product Details:
                                  <span className="d-inline-block">
                                    {product.infourl === "null" ? (
                                      <a
                                        className="text-decoration-none"
                                        target="_blank"
                                        // href={product.infourl}
                                      >
                                        Link is coming soon
                                      </a>
                                    ) : (
                                      <a
                                        className="text-decoration-none"
                                        target="_blank"
                                        href={product.infourl}
                                      >
                                        Info Link
                                      </a>
                                    )}
                                    {/* <a
                                      className="text-decoration-none"
                                      target="_blank"
                                      href={product.infourl}
                                    >
                                     {product.infourl === "null" ? "soon" : "Info Link"} 
                                    </a> */}
                                  </span>
                                </p>
                                <p>
                                  Product Id:
                                  <span className="d-inline-block">
                                    {product.productid}
                                  </span>
                                </p>
                                <p>
                                  Origin:
                                  <span className="d-inline-block">
                                    {" "}
                                    {product.country}
                                  </span>
                                </p>
                                <p>
                                  Product Cost:
                                  <span className="product-original-price d-inline-block">
                                    {product.productcost} {product.unit}
                                  </span>
                                </p>

                                {(() => {
                                  switch (product.discount) {
                                    case "":
                                      break;
                                    case 0:
                                      break;
                                    default:
                                      return [
                                        <div>
                                          <p>
                                            Discounted Price:
                                            <span className="d-inline-block">
                                              {product.discountedprice}
                                              {product.unit}
                                            </span>
                                          </p>
                                        </div>,
                                      ];
                                  }
                                })()}
                                <p
                                  className="products-like mb-0 d-flex "
                                  onClick={() => {
                                    this.likeCount(product, product.productid);
                                  }}
                                >
                                  <span className="d-inline-block">
                                    <FiThumbsUp size={30} />
                                  </span>
                                  <p className="mb-0 likeCount">
                                    {product.likecount}
                                  </p>
                                </p>
                              {!this.state.services &&  <p>
                                  Item Count:
                                  <span className="d-inline-block">
                                    {product.itemcount}
                                  </span>
                                </p>}
                                <p>
                                  Status:
                                  <span className="d-inline-block">
                                    {" "}
                                    {product.status}
                                  </span>
                                </p>

                                {(() => {
                                  switch (product.productqrcodeurl) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <img
                                            src={product.productqrcodeurl}
                                            className="img-qr"
                                          ></img>
                                        </p>
                                      );
                                  }
                                })()}
                                {(() => {
                                  switch (product.qacertificateurl) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <img
                                            src={product.qacertificateurl}
                                            className="img-thumbnail"
                                          ></img>
                                        </p>
                                      );
                                  }
                                })()}

                                {(() => {
                                  switch (product.labcertificate) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <img
                                            src={product.labcertificate}
                                            className="img-thumbnail"
                                          ></img>
                                        </p>
                                      );
                                  }
                                })()}

                                {(() => {
                                  switch (product.productvideourl) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <ReactPlayer
                                            className="product-video"
                                            url={product.productvideourl}
                                            pip={true}
                                            controls={true}
                                            playing={false}
                                            muted={true}
                                          />
                                        </p>
                                      );
                                  }
                                })()}
                              </div>

                              <div className="explore-button-container pt-3  pb-lg-0 pb-3 pb-lg-0  mt-lg-0 mt-3">
                                {this.props.cbn ? (
                                  <NavLink
                                    className="text-white text-decoration-none"
                                    to="/auth/3/signin"
                                    onClick={()=>{
                                      localStorage.setItem("userType", "Buyer")
                                    }}
                                  >
                                    {" "}
                                    <button
                                      type="button"
                                      className="btn btn-success signbutton w-100  d-block mx-lg-0 mx-auto"
                                    >
                                      Buy
                                    </button>
                                  </NavLink>
                                ) : (
                                  <>
                                    <>
                                      {this.props.quote ? (
                                        this.props.quote === "quote" ? (
                                          <>
                                          <div className="d-flex justify-content-md-start justify-content-center">
                                            <NavLink
                                              className="text-white text-decoration-none me-3"
                                              to="/auth/3/signup"
                                              onClick={()=>{
                                                localStorage.setItem("userType", "Retailer")
                                              }}
                                            >
                                              {" "}
                                              <button
                                                type="button"
                                                className="btn btn-success  w-100   mx-lg-0 mx-auto"
                                              >
                                                RFQ
                                              </button>
                                            </NavLink>
                                            <NavLink
  className="text-white text-decoration-none"
  to="/auth/3/signup"
  onClick={()=>{
    localStorage.setItem("userType", "Buyer")
  }}
>
  {" "}
  <button
    type="button"
    className="btn btn-success  w-100 mx-lg-0 mx-auto"
  >
    Buy
  </button>
</NavLink>
                                            
                                          </div>


</>
                                        ):<> <div className="d-flex justify-content-md-start justify-content-center ">
                                      

                                        <NavLink
                                          className="text-white text-decoration-none"
                                          to="/auth/3/signup"
                                          onClick={()=>{
                                            localStorage.setItem("userType", "Buyer")
                                          }}
                                        >
                                          {" "}
                                          <button
                                            type="button"
                                            className="btn btn-success  w-100 mx-lg-0 mx-auto"
                                          >
                                            Buy
                                          </button>
                                        </NavLink>
                                      </div></>
                                      ) : (
                                        <NavLink
                                          className="text-white text-decoration-none"
                                          to="/auth/3/signup"
                                        >
                                          {" "}
                                          <button
                                          onClick={()=>{
                                            localStorage.setItem("userType", this.state.user)
                                          }}
                                            type="button"
                                            className="btn btn-success signbutton w-100 mx-lg-0 mx-auto"
                                          >
                                            Buy
                                          </button>
                                        </NavLink>
                                      )}
                                    </>
                                  </>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    ))}
                  <div className="paginationComponent">
                    <ReactPaginate
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.prodPageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </Row>
              ) : (
                <div className="card">
                {this.props.bodycopy ?  <p className="card-body container-heading2">
                    <b>{this.props.bodycopy}</b>
                  </p>:  <p className="card-body container-heading2">
                    <b>Products Not Found</b>
                  </p>}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
      </>
    );
  }
}

export default RawProducts;
