import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row, Container, Collapse, Fade,
    Pagination, PaginationItem, PaginationLink,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../style.css';
import './Layout.css';
import AuthAxiosInstance from '../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance } from '../../../authAxiosInstance'
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import InputLabel from '@mui/material/InputLabel';
import Badge from '@mui/material/Badge';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { NotificationManager } from "react-notifications";

let prev = 0;
let next = 0;
let last = 0;
let first = 0;
class Types extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devicetypelist: [],
            disabledstate: true,
            primary: false,
            secondary: false,
            danger: false,
            editmodal: false,
            deletedata: {},
            profiledata: [],
            typeuploaddata: [],
            editdevicedetails: [],
            typelist: [],
            currentpageno: 1,
            pageno: 1,
            pagesize: 5,
            searchkey: '',
            totaltypelistcount: '',
            accordion: [true],
            currentlist: [],
            modelstatus: '',
            properties: [],
            createData: {},
            metadata: {
                mandatory: null,
                editable: null,
                property: '',
                valueType: '',
                Desired: [],
                unit: '',
            },
            desireEnable: false,
            desired: '',
            selectedOption: null,
            editProperties: null,
            updateData: {},
            updatedData: []

        };
        this.toggleSecondary = this.toggleSecondary.bind(this);

        this.togglePrimary = this.togglePrimary.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleLastClick = this.handleLastClick.bind(this);
        this.handleFirstClick = this.handleFirstClick.bind(this);
        this.onclickdelete = this.onclickdelete.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        // toast.configure({
        //     autoClose: 8000,
        //     draggable: false,
        //     //etc you get the idea
        // });
    }

    toggleAccordion(tab) {

        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => tab === index ? !x : false);

        this.setState({
            accordion: state,
        });
    }



    /*=============pagination========*/
    onRangevaluechange = (_value, e) => {
        var range = '';
        if (e.target.value == "ShowAll") {
            range = this.state.totaltypelistcount;
        }
        else {
            range = e.target.value;
        }
        this.setState({ [_value]: range });
        this.setState({ currentpageno: this.state.currentpageno });
        this.setState({ pageno: 1 }, () => { this.callbackfirmware() });
    }

    onSearchkeyChange = (_value, e) => {
        this.setState({ [_value]: e.target.value });
        var queryResult = [];
        if (e.target.value !== '') {
            this.state.otypelist.forEach(function (item) {
                if (item.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
                    queryResult.push(item);
            });
            this.setState({ typelist: queryResult });
        }
        else {
            this.setState({ pageno: 1 }, () => { this.callbackfirmware() });
        }
        //this.setState({pageno : 1}, () => {this.callbackfirmware() } );       
    }
    callbackfirmware = () => {
        this.getAllDevicesTypes();
    }
    handleClick(event) {
        event.preventDefault();
        this.setState({
            pageno: Number(event.target.id)
        });

        this.getAllDevicesTypes();
    }
    handleLastClick(event) {
        event.preventDefault();
        this.setState({
            pageno: last
        });
        this.getAllDevicesTypes();
    }
    handleFirstClick(event) {
        event.preventDefault();
        this.setState({
            pageno: 1
        });
        this.getAllDevicesTypes();
    }
    /*================end===============*/

    /*==============Gateway Create ===========================*/
    togglePrimary() {
        this.setState({
            primary: !this.state.primary, disabledstate: true, typeuploaddata: {}
            , disabledstate: true, errors: '', modelstatus: '', properties: [], metadata: {
                mandatory: null,
                editable: null,
                property: '',
                valueType: '',
                Desired: [],
                unit: '',
            }
        });
    }

    toggleSecondary() {
        this.setState({
            secondary: !this.state.secondary, disabledstate: true, typeuploaddata: {}
            , disabledstate: true, errors: '', modelstatus: '', properties: [], updatedData: []
        });
    }


    onvalueChange(name, e) {
        const formData = { ...this.state.typeuploaddata };
        if (name === 'metadata') {
            try {
                formData[e.target.name] = JSON.stringify(JSON.parse(e.target.value), undefined, 4);
            }
            catch (error) {
                formData[e.target.name] = e.target.value;

            }
        }
        else {
            //const formData = { ...this.state.typeuploaddata };
            formData[e.target.name] = e.target.value;

        }
        this.setState({ typeuploaddata: formData }, () => {
            this.handleformvalidation();
        });
    }


    handleformvalidation() {
        let fields = this.state.typeuploaddata;
        let formIsValid = true;
        var validationfield = [{ 'name': 'deviceType', 'type': 'alphanumeric', 'isrequired': true, 'minlength': 2, 'displayname': 'Name' },
        { 'name': 'description', 'type': '', 'isrequired': true, 'minlength': 10, 'displayname': 'Description' }];
        for (let i = 0; i < validationfield.length; i++) {
            if (validationfield[i].isrequired && !fields[validationfield[i].name]) {
                formIsValid = false;
                this.setState({ errors: 'Please fill all the fields' });
                this.setState({ disabledstate: true });
            }
            else if (validationfield[i].type === 'alphanumeric') {
                var nospecial = /[*|\":<>[\]{}`\\()';@&$!#^=+%`~,./? ]/;
                if (nospecial.test(fields[validationfield[i].name])) {
                    formIsValid = false;
                    this.setState({ errors: `No Special characters allowed in ${validationfield[i].displayname}` });
                    this.setState({ disabledstate: true });
                }
                if (fields[validationfield[i].name].length < validationfield[i].minlength) {
                    formIsValid = false;
                    this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
                    this.setState({ disabledstate: true });
                }
            }
            else if (validationfield[i].type === 'json') {
                try {
                    JSON.stringify(JSON.parse(fields[validationfield[i].name]), undefined, 4)
                }
                catch (ccerr) {
                    formIsValid = false;
                    this.setState({ errors: `Please fill ${validationfield[i].displayname} in correct JSON format` });
                    this.setState({ disabledstate: true });
                }

            }
            else if (validationfield[i].minlength > 0) {
                if (fields[validationfield[i].name].length < validationfield[i].minlength) {
                    formIsValid = false;
                    this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
                    this.setState({ disabledstate: true });
                }
            }
        }
        if (formIsValid === true) {

            this.setState({ errors: '', disabledstate: false });
            //this.onclickdeviceupload();



        }

    }

    onclickdeviceupload = async () => {
        const { user } = MainService.getLocalData();
        const { username, parentId } = user;

        if (this.state.modelstatus === 'edit') {
            DeviceMetadataAxiosInstance.put(`/admin/deviceprofile/${username}/deviceTypes/${this.state.typeuploaddata.deviceType}`, this.state.typeuploaddata)
                .then((response) => {
                    this.setState({
                        secondary: !this.state.secondary, disabledstate: true, typeuploaddata: {}
                    });

                    // toast.success("Device type updated successfully", {
                    //     position: toast.POSITION.TOP_RIGHT
                    // });
                    this.getAllDevicesTypes();
                    return NotificationManager.success("Device type updated successfully");
                }).catch((err) => {
                    // toast.error("Some error occured", {
                    //     position: toast.POSITION.TOP_RIGHT
                    // });
                    return NotificationManager.error("Some error occured");

                });
        }
        else {
            const { typeuploaddata } = this.state;
            const updatedItem = {
                ...typeuploaddata,
                metadata: JSON.stringify({ properties: this.state.properties }),
            };


            // Update the state with the modified object
            this.setState({ createData: updatedItem });

            DeviceMetadataAxiosInstance.post(`/admin/deviceprofile/${username}/deviceTypes`, updatedItem)
                .then((response) => {
                    this.setState({
                        primary: !this.state.primary, disabledstate: true, typeuploaddata: {}, metadata: {
                            mandatory: null,
                            editable: null,
                            property: '',
                            valueType: '',
                            Desired: [],
                            unit: '',
                        }
                    });

                    // toast.success("New type created successfully", {
                    //     position: toast.POSITION.TOP_RIGHT
                    // });
                    this.getAllDevicesTypes();
                    return NotificationManager.success("New type created successfully");
                }).catch((err) => {
                    // toast.error("Some error occured", {
                    //     position: toast.POSITION.TOP_RIGHT
                    // });
                    return NotificationManager.error("Some error occured");
                });
        }
    }


    /*================ Gateway edit ========================*/
    toggleeditdevicemodel = (gw_id, status) => {
        const { user } = MainService.getLocalData();
        const { username, parentId } = user;
        if (status == "edit" || status == 'view') {
            DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/${gw_id}`).then((res) => {
                this.setState({ typeuploaddata: res.data });
                this.setState({ editProperties: res.data.metadata })
                this.setState({ modelstatus: status });
                this.setState({ desireEnable: false })
                if (status === 'view') {
                    this.setState({ editmodal: true });
                }
                else if (status === 'edit') {
                    this.setState({ secondary: !this.state.secondary });
                    // this.handleformvalidation();
                }
            })

        } else {
            this.setState({ typeuploaddata: {}, editmodal: false })
        }


    }

    /*================= Gateway delete =====================*/
    toggledeviceDelete = (data, event) => {
        this.setState({
            danger: !this.state.danger, deletedata: data
        });

    }
    onclickdelete = () => {
        const { user } = MainService.getLocalData();
        const { username, parentId } = user;
        console.log(this.state.deletedata);
        DeviceMetadataAxiosInstance.delete(`/admin/deviceprofile/${username}/deviceTypes/${this.state.deletedata}`).then((resp) => {
            // toast.success("Device type successfully deleted", {
            //     position: toast.POSITION.TOP_RIGHT
            // });
            this.getAllDevicesTypes();
            this.setState({
                danger: !this.state.danger, deletedata: {}
            });
            return NotificationManager.success("Device type successfully deleted");
        }).catch((err) => {
            // toast.error("Error occured while deleting the device", {
            //     position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");
        });


    }
    componentDidMount() {
        this.getAllDevicesTypes();
    }

    getAllDevicesTypes() {
        const { user } = MainService.getLocalData();
        const { username, parentId } = user;
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/list`).then((resp) => {

            this.setState({ typelist: resp.data, otypelist: resp.data, totaltypelistcount: resp.data.length });
        }).catch((err) => {
            this.setState({ typelist: [], otypelist: [], totaltypelistcount: '' });
        })
    }


    viewProperty(devId) {
        this.props.history.push(`/layout/gateway?devId=${devId}`);
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "editable") {
            if (value == 2) {
                this.setState({ desireEnable: true })
                this.setState({ primary: true })
            } else {
                this.setState({ desireEnable: false })

            }

        }
        if (name === "Desired") {
            this.setState(() => ({
                desired: value,

            }));

            this.setState((prevState) => ({
                metadata: {
                    ...prevState.metadata,
                    [name]: value.split(','),
                },
            }));



        } else {
            console.log(name,value,"name value")
            this.setState((prevState) => ({
                metadata: {
                    ...prevState.metadata,
                    [name]: value,
                },
            }));
        }

    };

    handleInputChangeEdit = (e) => {
        const { name, value } = e.target;
        if (name === "editable") {
            if (value == 2) {
                this.setState({ desireEnable: true })
            } else {
                this.setState({ desireEnable: false })

            }

        }
        if (name === "Desired") {
            this.setState(() => ({
                desired: value,

            }));

            this.setState((prevState) => ({
                metadata: {
                    ...prevState.metadata,
                    [name]: value.split(','),
                },
            }));
            console.log(this.state.metadata, "metadata")


        } else {
            this.setState((prevState) => ({
                metadata: {
                    ...prevState.metadata,
                    [name]: value,
                },
            }));
        }

    };

    handleAdd = (e) => {
        e.preventDefault();
        // Process the form data here
        this.state.properties.push(this.state.metadata)
        console.log(this.state.properties);
        const mData = {
            "properties": this.state.properties
        }
        this.setState({
            metadata: {
                mandatory: null,
                editable: null,
                property: '',
                valueType: '',
                Desired: [],
                unit: '',
            }
        })
        this.setState({ desired: '' })
        this.setState({ primary: true })

    };

    handleEditProperty = (e) => {
        e.preventDefault();
        console.log(this.state.metadata, 'metadata')
        this.setState((prevState) => ({
            updatedData: [...prevState.updatedData, this.state.metadata],
        }));

        // this.setState({...this.state.updatedData, updatedData:this.state.metadata})
        let propertiess = JSON.parse(this.state.typeuploaddata.metadata)
        propertiess.properties = propertiess.properties.map((property, i) =>
            property.property === this.state.metadata.property ? this.state.metadata : property
        )
        const updatedTypeUploadData = {
            ...this.state.typeuploaddata,
            metadata: JSON.stringify(propertiess)
        };
        this.setState({
            typeuploaddata: {
                networkId: updatedTypeUploadData.networkId,
                description: updatedTypeUploadData.description,
                deviceType: updatedTypeUploadData.deviceType,
                metadata: updatedTypeUploadData.metadata
            }
        }, () => {
            console.log(this.state.updatedData, 'updateddata');
            this.setState({ secondary: true })
        });



        // Process the form data here
        // this.state.properties.push(this.state.metadata)
        // // console.log(this.state.properties);
        // const mData = {
        //     "properties":this.state.properties
        // }
        // this.setState({metadata :{
        //     mandatory: false,
        //     editable: true,
        //     property: '',
        //     valueType: '',
        //     Desired:[],
        //     unit: '',
        // }})
        // this.setState({desired:''})
        // this.setState({primary:true})

    };

    handleOptionChange = (event) => {
        const selectedOption = event.target.value;
        this.setState({ selectedOption });
        const parseProperties = JSON.parse(this.state.editProperties)
        const selectedItem = parseProperties.properties.find(property => property.property === selectedOption);
        console.log(selectedItem);
        if (selectedItem.Desired ? selectedItem.Desired[0] : false) {
            this.setState({ desireEnable: true })
        } else {
            this.setState({ desireEnable: false })
        }
        this.setState({ metadata: selectedItem })

        this.setState({ desired: selectedItem.Desired?.join(',') })
    };

    handleDeleteClick = (propertyToDelete) => {
        console.log(`Delete property: ${propertyToDelete}`);
        console.log(this.state.properties, "ggegege")
        const newArray = this.state.properties.filter((item) => item.property !== propertyToDelete);

        // Step 2: Push the removed element back into the original array

        console.log(newArray); // The array without 'Bob'
        console.log(this.state.properties);
        this.setState({ properties: newArray })

    };

    render() {

        const { devicetypelist, typelist, pageno, pagesize, totaltypelistcount, selectedOption } = this.state;

        if (typelist.length > 0) {
            for (var i = 0; i < typelist.length; i++) {
                for (var j = 0; j < devicetypelist.length; j++) {
                    if (devicetypelist[j].dvt_id == typelist[i].dv_type) {
                        typelist[i].dvt_name = devicetypelist[j].dvt_name;
                    }
                }
            }
        }
        // Logic for displaying todos
        const indexOfLastTodo = pageno * pagesize;
        const indexOfFirstTodo = indexOfLastTodo - pagesize;
        const currentlist = typelist.slice(indexOfFirstTodo, indexOfLastTodo);
        const parseProperties = JSON.parse(this.state.editProperties)
        prev = pageno > 0 ? (pageno - 1) : 0;
        last = Math.ceil(totaltypelistcount / pagesize);
        next = (last === pageno) ? pageno : pageno + 1;
        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(totaltypelistcount / pagesize); i++) {
            pageNumbers.push(i);
        }
        //Logic to get the list of pagesize
        const pagesizelist = []
        if (parseInt(totaltypelistcount) > 0) {
            for (let j = 1; j <= totaltypelistcount; j++) {
                if (j % 5 === 0) {
                    pagesizelist.push(j);
                }
            }
            pagesizelist.push("ShowAll");

        }
        else {
            pagesizelist.push("ShowAll");
        }






        return (
            <div className="animated fadeIn" >
                <Row>
                    <Col xs="12" sm="12">
                        <Card>
                            <CardHeader>
                                <i className="fa fa-microchip"></i> <strong className="LabelHeader">Type List</strong>

                                <div className="alignright">
                                    <Button type="button" className="custom-button" onClick={this.togglePrimary} size="sm" color="primary"> Create New Device Type</Button>
                                </div>

                            </CardHeader>
                                <Col xs="12" md="12">
                                    <FormGroup row>
                                        <Col md="8">

                                            <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                                                {this.state.searchkey === "" && (
                                                    <FormControl variant="outlined" className="dropdownwidth2">
                                                        <Select
                                                            value={pagesize}
                                                            onChange={this.onRangevaluechange.bind(this, "pagesize")}
                                                        >
                                                            {pagesizelist.map((number, i) => (
                                                                <MenuItem
                                                                    key={number}
                                                                    value={number !== "ShowAll" ? number : totaltypelistcount}
                                                                >
                                                                    {number !== "ShowAll" ? number : "ShowAll"}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                                {this.state.searchkey != "" && (
                                                    <FormControl variant="outlined" className="dropdownwidth2">

                                                    </FormControl>
                                                )}
                                                <Grid item md={4}>
                                                    <TextField
                                                        type="text"
                                                        name="searchkey"
                                                        autoComplete="off"
                                                        placeholder="Enter search Key"
                                                        onChange={this.onSearchkeyChange.bind(this, "searchkey")}
                                                    />
                                                </Grid>
                                            </div></Col>
                                    </FormGroup>
                                </Col>
                                {(currentlist.length > 0) &&
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>SL No</TableCell>
                                                    <TableCell>TYPE NAME</TableCell>
                                                    <TableCell>ACTION</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {currentlist &&
                                                    currentlist.map((row, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{pagesize * (pageno - 1) + (index + 1)}</TableCell>
                                                            <TableCell>{row}</TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    className="custom-button-view"
                                                                    variant="contained"
                                                                    onClick={() => this.toggleeditdevicemodel(row, "view")}
                                                                >
                                                                    <ViewIcon />
                                                                </Button>
                                                                <Button
                                                                    className="custom-button-edit"
                                                                    variant="contained"
                                                                    onClick={() => this.toggleeditdevicemodel(row, "edit")}
                                                                >
                                                                    <EditIcon />
                                                                </Button>
                                                                <Button
                                                                    className="custom-button-delete"
                                                                    variant="contained"
                                                                    onClick={() => this.toggledeviceDelete(row)}
                                                                >
                                                                    <DeleteIcon />
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                                <br></br>
                                <div>
                                    {(totaltypelistcount !== pagesize) && (currentlist.length > 0) && (this.state.searchkey == "") &&
                                        <ul id="page-numbers">
                                            <nav>
                                                <Pagination style={{ display: "flex" }}>
                                                    <PaginationItem>
                                                        {prev === 0 ? <PaginationLink disabled>First</PaginationLink> :
                                                            <PaginationLink onClick={this.handleFirstClick} id={prev}>First</PaginationLink>
                                                        }
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        {prev === 0 ? <PaginationLink disabled>Prev</PaginationLink> :
                                                            <PaginationLink onClick={this.handleClick} id={prev} >Prev</PaginationLink>
                                                        }
                                                    </PaginationItem>
                                                    {
                                                        pageNumbers.map((number, i) =>
                                                            <Pagination key={i}>
                                                                <PaginationItem active={pageNumbers[pageno - 1] === (number) ? true : false} >
                                                                    <PaginationLink onClick={this.handleClick} key={number} id={number}>
                                                                        {number}
                                                                    </PaginationLink>
                                                                </PaginationItem>
                                                            </Pagination>
                                                        )}

                                                    <PaginationItem>
                                                        {
                                                            pageno === last ? <PaginationLink disabled>Next</PaginationLink> :
                                                                <PaginationLink onClick={this.handleClick} id={pageNumbers[pageno]} >Next</PaginationLink>
                                                        }
                                                    </PaginationItem>

                                                    <PaginationItem>
                                                        {
                                                            pageno === last ? <PaginationLink disabled>Last</PaginationLink> :
                                                                <PaginationLink onClick={this.handleLastClick} id={pageNumbers[pageno]} >Last</PaginationLink>
                                                        }
                                                    </PaginationItem>
                                                </Pagination>

                                            </nav>
                                        </ul>
                                    }
                                </div>
                                {(currentlist.length == 0) &&

                                    <center>
                                        <h3>No records found!!</h3>
                                    </center>

                                }

                        </Card>
                    </Col>
                </Row>

                {/* <Modal isOpen={this.state.primary} toggle={this.togglePrimary}

                    className={'modal-lg ' + this.props.className}>
                    <ModalHeader toggle={this.togglePrimary}>{this.state.modelstatus === 'edit' ? 'Edit Type' : 'Add New Type'}</ModalHeader>
                    <ModalBody>
                        <div id="accordion">
                            <Label className="help-block" id="errorstyle">{this.state.errors}</Label>
                            <Card className="mb-0">
                                <CardHeader id="headingOne">
                                    <Button block color="link" className="text-left m-0 p-0" onClick={() => this.toggleAccordion(0)} aria-expanded={this.state.accordion[0]} aria-controls="collapseOne">
                                        <h5 className="m-0 p-0">Type Details <strong className="redfont">*</strong></h5>
                                    </Button>
                                </CardHeader>
                                <Collapse isOpen={this.state.accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                                    <CardBody>

                                        <Form>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Name</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" disabled={this.state.modelstatus === 'edit' ? true : false} name="deviceType" placeholder="Enter Type Name" value={this.state.typeuploaddata.deviceType}
                                                        onChange={this.onvalueChange.bind(this, 'deviceType')} required maxLength='20' />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Description</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="description" placeholder="Description" value={this.state.typeuploaddata.description}
                                                        onChange={this.onvalueChange.bind(this, 'description')} required maxLength='45' />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Meta Data</Label>
                                                </Col>
                                                <Col xs="12" md="9">


                                                    <Form onSubmit={this.handleAdd}>
                                                        {this.state.properties[0] && this.state.properties[0] ? <h5>Added Properties</h5> : <h5>Add Properties </h5>}
                                                  
                                                        <div>
                                                            {this.state.properties &&
                                                                this.state.properties.map((prop) => (
                                                                    <span style={{ marginLeft: '10px' }} key={prop.property}>
                                                                        <Badge color='primary'>{prop.property}<Button color='primary' onClick={() => this.handleDeleteClick(prop.property)}>X</Button></Badge>

                                                                    </span>
                                                                ))}
                                                        </div>
                                                        <FormGroup>
                                                            <Label for="mandatory">Mandatory</Label>

                                                            <Input
                                                                required
                                                                type="select"
                                                                name="mandatory"
                                                                id="mandatory"
                                                                value={this.state.metadata.mandatory}
                                                                onChange={this.handleInputChange}
                                                            >
                                                                <option value={false}>False</option>
                                                                <option value={true}>True</option>
                                                            </Input>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="editable">Editable</Label>
                                                            <Input
                                                                required
                                                                type="select"
                                                                name="editable"
                                                                id="editable"
                                                                value={this.state.metadata.editable}
                                                                onChange={this.handleInputChange}
                                                            >
                                                                <option value={false}>False</option>
                                                                <option value={true}>True</option>
                                                                <option value={2}>AutoComplete</option>
                                                            </Input>
                                                        </FormGroup>
                                                        {this.state.desireEnable && <FormGroup>
                                                            <Label for="Desired">Desired Values</Label>
                                                            <Input
                                                                type="text"
                                                                name="Desired"
                                                                id="Desired"
                                                                placeholder="Enter Desired Values"
                                                                value={this.state.desired}
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </FormGroup>}
                                                        <FormGroup>
                                                            <Label for="property">Property</Label>
                                                            <Input
                                                                required
                                                                type="text"
                                                                name="property"
                                                                id="property"
                                                                placeholder="Enter property"
                                                                value={this.state.metadata.property}
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="valueType">Value Type</Label>
                                                            <Input
                                                                required
                                                                type="text"
                                                                name="valueType"
                                                                id="valueType"
                                                                placeholder="Enter value type"
                                                                value={this.state.metadata.valueType}
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="unit">Unit</Label>
                                                            <Input
                                                                required
                                                                type="text"
                                                                name="unit"
                                                                id="unit"
                                                                placeholder="Enter unit"
                                                                value={this.state.metadata.unit}
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </FormGroup>
                                                        <Button color="primary" type="submit" >
                                                            Add
                                                        </Button>
                                                    </Form>
                                                </Col>
                                            </FormGroup>





                                        </Form>
                                    </CardBody>
                                </Collapse>
                            </Card>


                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" disabled={this.state.disabledstate} type='submit' onClick={this.onclickdeviceupload.bind(this)} >{this.state.modelstatus === 'edit' ? 'Update' : 'Create'}</Button>
                        <Button color="primary" onClick={this.togglePrimary}>Cancel</Button>
                    </ModalFooter>
                </Modal> */}
                <Dialog open={this.state.primary} onClose={this.togglePrimary} fullWidth maxWidth="md">
                    <DialogTitle>{this.state.modelstatus === 'edit' ? 'Edit Type' : 'Add New Type'}</DialogTitle>
                    <DialogContent>
                        <div id="accordion">
                            <Typography className="help-block" id="errorstyle">
                                {this.state.errors}
                            </Typography>
                            <Collapse in={this.state.accordion[0]} data-parent="#accordion">
                                <FormGroup fullWidth >
                                <InputLabel htmlFor="deviceType">Type Name</InputLabel>
                                    <TextField
                                        type="text"
                                        disabled={this.state.modelstatus === 'edit'}
                                        name="deviceType"
                                        label="Name"
                                        placeholder="Enter Type Name"
                                        value={this.state.typeuploaddata.deviceType}
                                        onChange={this.onvalueChange.bind(this, 'deviceType')}
                                        required
                                        maxLength="20"
                                        fullWidth
                                    />
                                </FormGroup>
                                <FormGroup fullWidth >
                                <InputLabel htmlFor="description">Description</InputLabel>
                                    <TextField
                                        type="text"
                                        name="description"
                                        label="Description"
                                        placeholder="Description"
                                        value={this.state.typeuploaddata.description}
                                        onChange={this.onvalueChange.bind(this, 'description')}
                                        required
                                        maxLength="45"
                                        fullWidth
                                    />
                                </FormGroup>
                                <FormGroup fullWidth >
                                    <Form onSubmit={this.handleAdd}>
                                        {this.state.properties[0] ? (
                                            <Typography variant="h6">Added Properties</Typography>
                                        ) : (
                                            <Typography variant="h6">Add Properties</Typography>
                                        )}
                                        <div>
                                            {this.state.properties &&
                                                this.state.properties.map((prop) => (
                                                    <span style={{ marginLeft: '10px' }} key={prop.property}>
                                                        <Badge
                                                            style={{
                                                                backgroundColor: "#3ECF8E",
                                                                color: "white", // Text color
                                                                borderRadius: "20%", // To make it circular
                                                                padding: "5px", // Adjust the padding as needed
                                                            }}
                                                        >
                                                            {prop.property}
                                                            <Button color="primary" onClick={() => this.handleDeleteClick(prop.property)}>
                                                                X
                                                            </Button>
                                                        </Badge>
                                                    </span>
                                                ))}
                                        </div>
                                        <FormGroup fullWidth >
                                        <InputLabel htmlFor="property">Property Name</InputLabel>

                                            <TextField
                                                required
                                                type="text"
                                                name="property"
                                                id="property"
                                                placeholder="Enter property"
                                                value={this.state.metadata.property}
                                                onChange={this.handleInputChange}
                                                fullWidth
                                            />
                                        </FormGroup>
                                        <FormGroup fullWidth >
                                        <InputLabel htmlFor="mandatory">Mandatory</InputLabel>
                                            <Select
                                                required
                                                name="mandatory"
                                                id="mandatory"
                                                placeholder="Mandatory"
                                                value={this.state.metadata.mandatory}
                                                onChange={this.handleInputChange}
                                                fullWidth
                                            >
                                                <MenuItem value={false}>False</MenuItem>
                                                <MenuItem value={true}>True</MenuItem>
                                            </Select>
                                        </FormGroup>
                                        <FormGroup fullWidth >
                                        <InputLabel htmlFor="editable">Editable</InputLabel>
                                            <Select
                                                required
                                                name="editable"
                                                id="editable"
                                                placeholder="Editable"
                                                value={this.state.metadata.editable}
                                                onChange={this.handleInputChange}
                                                fullWidth
                                            >
                                                <MenuItem value={false}>False</MenuItem>
                                                <MenuItem value={true}>True</MenuItem>
                                                <MenuItem value={2}>AutoComplete</MenuItem>
                                            </Select>
                                        </FormGroup>
                                        {this.state.desireEnable && (
                                            <FormGroup fullWidth >
                                                <InputLabel htmlFor="Desired">Desired Values  (Give comma seperated values EX:value1,value2)</InputLabel>
                                                <TextField
                                                    type="text"
                                                    name="Desired"
                                                    id="Desired"
                                                    placeholder="Enter Desired Values"
                                                    value={this.state.desired}
                                                    onChange={this.handleInputChange}
                                                    fullWidth
                                                />
                                            </FormGroup>
                                        )}
                                        
                                        <FormGroup fullWidth >
                                        <InputLabel htmlFor="valueType">Value Type</InputLabel>
                                            <TextField
                                                required
                                                type="text"
                                                name="valueType"
                                                id="valueType"
                                                placeholder="Enter value type"
                                                value={this.state.metadata.valueType}
                                                onChange={this.handleInputChange}
                                                fullWidth
                                            />
                                        </FormGroup>
                                        <FormGroup fullWidth >
                                        <InputLabel htmlFor="unit">Unit</InputLabel>
                                            <TextField
                                                required
                                                type="text"
                                                name="unit"
                                                id="unit"
                                                placeholder="Enter unit"
                                                value={this.state.metadata.unit}
                                                onChange={this.handleInputChange}
                                                fullWidth
                                            />
                                        </FormGroup>
                                        <Button style={{ marginTop: "10px" }} className="custom-button" type="submit">
                                            Add
                                        </Button>
                                    </Form>
                                </FormGroup>
                            </Collapse>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button className="custom-button" disabled={this.state.disabledstate} type="submit" onClick={this.onclickdeviceupload.bind(this)}>
                            {this.state.modelstatus === 'edit' ? 'Update' : 'Create'}
                        </Button>
                        <Button className="custom-button-cancel" onClick={this.togglePrimary}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* <Modal isOpen={this.state.secondary} toggle={this.toggleSecondary}

                    className={'modal-lg ' + this.props.className}>
                    <ModalHeader toggle={this.toggleSecondary}>{this.state.modelstatus === 'edit' ? 'Edit Type' : 'Add New Type'}</ModalHeader>
                    <ModalBody>
                        <div id="accordion">
                            <Label className="help-block" id="errorstyle">{this.state.errors}</Label>
                            <Card className="mb-0">
                                <CardHeader id="headingOne">
                                    <Button block color="link" className="text-left m-0 p-0" onClick={() => this.toggleAccordion(0)} aria-expanded={this.state.accordion[0]} aria-controls="collapseOne">
                                        <h5 className="m-0 p-0">Type Details <strong className="redfont">*</strong></h5>
                                    </Button>
                                </CardHeader>
                                <Collapse isOpen={this.state.accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                                    <CardBody>

                                        <Form>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Name</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" required disabled={this.state.modelstatus === 'edit' ? true : false} name="deviceType" placeholder="Enter Type Name" value={this.state.typeuploaddata.deviceType}
                                                        onChange={this.onvalueChange.bind(this, 'deviceType')} maxLength='20' />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Description</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="description" placeholder="Description" value={this.state.typeuploaddata.description}
                                                        onChange={this.onvalueChange.bind(this, 'description')} required maxLength='45' />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Meta Data</Label>
                                                    <h5>Properties</h5><br />
                                                    {parseProperties && parseProperties.properties.map((item, index) => {
                                                        return (<><label>
                                                            <input
                                                                type="radio"
                                                                value={item.property}
                                                                checked={selectedOption && selectedOption === item.property}
                                                                onChange={this.handleOptionChange}
                                                            />
                                                            {item.property}
                                                        </label><br /></>)
                                                    })}
                                                </Col>
                                                <Col xs="12" md="9">
                                                    {
                                                        this.state.updatedData[0] && <h5>Updated Properties</h5>
                                                    }
                                                    {
                                                        this.state.updatedData && this.state.updatedData.map((item) => {
                                                            return <Badge style={{
                                                                marginLeft: '5px',
                                                                alignItems: 'center',      // Center vertically within the Badge
                                                                justifyContent: 'center',  // Center horizontally within the Badge
                                                                height: '20px',            // Set the desired height
                                                            }} color='primary'>{item.property}</Badge>
                                                        })
                                                    }
                                                    <Form onSubmit={this.handleEditProperty}>
                                                        <FormGroup>
                                                            <Label for="mandatory">Mandatory</Label>

                                                            <Input
                                                                type="select"
                                                                name="mandatory"
                                                                id="mandatory"
                                                                value={this.state.metadata.mandatory}
                                                                onChange={this.handleInputChangeEdit}
                                                            >
                                                                <option value={false}>False</option>
                                                                <option value={true}>True</option>
                                                            </Input>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="editable">Editable</Label>
                                                            <Input
                                                                type="select"
                                                                name="editable"
                                                                id="editable"
                                                                value={this.state.metadata.editable}
                                                                onChange={this.handleInputChangeEdit}
                                                            >
                                                                <option value={true}>True</option>
                                                                <option value={false}>False</option>
                                                                <option value={2}>AutoComplete</option>
                                                            </Input>
                                                        </FormGroup>
                                                        {this.state.desireEnable && <FormGroup>
                                                            <Label for="Desired">Desired Values</Label>
                                                            <Input
                                                                type="text"
                                                                name="Desired"
                                                                id="Desired"
                                                                placeholder="Enter Desired Values"
                                                                value={this.state.desired}
                                                                onChange={this.handleInputChangeEdit}
                                                            />
                                                        </FormGroup>}
                                                        <FormGroup>
                                                            <Label for="property">Property</Label>
                                                            <Input
                                                                required
                                                                disabled
                                                                type="text"
                                                                name="property"
                                                                id="property"
                                                                placeholder="Enter property"
                                                                value={this.state.metadata.property}
                                                                onChange={this.handleInputChangeEdit}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="valueType">Value Type</Label>
                                                            <Input
                                                                type="text"
                                                                name="valueType"
                                                                id="valueType"
                                                                placeholder="Enter value type"
                                                                value={this.state.metadata.valueType}
                                                                onChange={this.handleInputChangeEdit}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="unit">Unit</Label>
                                                            <Input
                                                                type="text"
                                                                name="unit"
                                                                id="unit"
                                                                placeholder="Enter unit"
                                                                value={this.state.metadata.unit}
                                                                onChange={this.handleInputChangeEdit}
                                                            />
                                                        </FormGroup>
                                                        <Button color="primary" type="submit" >
                                                            Update Property
                                                        </Button>
                                                    </Form>
                                                </Col>
                                            </FormGroup>





                                        </Form>
                                    </CardBody>
                                </Collapse>
                            </Card>


                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onclickdeviceupload.bind(this)} >{this.state.modelstatus === 'edit' ? 'Update' : 'Create'}</Button>
                        <Button color="primary" onClick={this.toggleSecondary}>Cancel</Button>
                    </ModalFooter>
                </Modal> */}
                <Dialog
                    open={this.state.secondary}
                    onClose={this.toggleSecondary}
                    maxWidth="600px"
                >
                    <DialogTitle>
                        {this.state.modelstatus === 'edit' ? 'Edit Type' : 'Add New Type'}
                    </DialogTitle>
                    <DialogContent>
                        <div id="accordion" style={{ width: "500px" }}>
                            <Typography className="help-block" id="errorstyle">
                                {this.state.errors}
                            </Typography>
                            <Badge color="primary">Type Details</Badge>
                            <FormGroup fullWidth>
                                <InputLabel htmlFor="deviceType">Name</InputLabel>
                                <TextField
                                    type="text"
                                    required
                                    disabled={this.state.modelstatus === 'edit'}
                                    name="deviceType"
                                    placeholder="Enter Type Name"
                                    value={this.state.typeuploaddata.deviceType}
                                    onChange={this.onvalueChange.bind(this, 'deviceType')}
                                    inputProps={{ maxLength: 20 }}
                                    fullWidth
                                />
                            </FormGroup>

                            <FormGroup>
                                <InputLabel htmlFor="description">Description</InputLabel>
                                <TextField
                                    type="text"
                                    name="description"
                                    placeholder="Description"
                                    value={this.state.typeuploaddata.description}
                                    onChange={this.onvalueChange.bind(this, 'description')}
                                    required
                                    inputProps={{ maxLength: 45 }}
                                    fullWidth
                                />
                            </FormGroup>
                            <Badge color="primary">Updated Properties</Badge>
                            {this.state.updatedData &&
                                this.state.updatedData?.map((item) => (
                                    <Badge
                                        key={item.property}
                                        style={{
                                            backgroundColor: "#3ECF8E",
                                            color: "white", // Text color
                                            borderRadius: "20%", // To make it circular
                                            padding: "5px", // Adjust the padding as needed
                                        }}
                                    >
                                        {item.property}
                                    </Badge>
                                ))}
                            <InputLabel htmlFor="metadata">Meta Data</InputLabel>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: "40%" }}>
                                    <FormGroup>
                                        <Badge color="primary">Properties</Badge>
                                        {parseProperties &&
                                            parseProperties.properties?.map((item, index) => (
                                                <div key={item.property}>
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                value={item.property}
                                                                checked={selectedOption && selectedOption === item.property}
                                                                onChange={this.handleOptionChange}
                                                            />
                                                        }
                                                        label={item.property}
                                                    />
                                                </div>
                                            ))}

                                    </FormGroup>
                                </div>
                                <div>
                                    <form onSubmit={this.handleEditProperty}>
                                    <FormGroup fullWidth>
                                            <InputLabel htmlFor="property">Property</InputLabel>
                                            <TextField
                                                required
                                                disabled
                                                type="text"
                                                name="property"
                                                id="property"
                                                placeholder="Enter property"
                                                value={this.state.metadata.property}
                                                onChange={this.handleInputChangeEdit}
                                                fullWidth
                                            />
                                        </FormGroup>
                                        <FormGroup fullWidth>
                                            <InputLabel htmlFor="mandatory">Mandatory</InputLabel>
                                            <Select
                                                name="mandatory"
                                                id="mandatory"
                                                value={this.state.metadata.mandatory}
                                                onChange={this.handleInputChangeEdit}
                                                fullWidth
                                            >
                                                <MenuItem value={false}>False</MenuItem>
                                                <MenuItem value={true}>True</MenuItem>
                                            </Select>
                                        </FormGroup>
                                        <FormGroup fullWidth>
                                            <InputLabel htmlFor="editable">Editable</InputLabel>
                                            <Select
                                                name="editable"
                                                id="editable"
                                                value={this.state.metadata.editable}
                                                onChange={this.handleInputChangeEdit}
                                                fullWidth
                                            >
                                                <MenuItem value={true}>True</MenuItem>
                                                <MenuItem value={false}>False</MenuItem>
                                                <MenuItem value={2}>AutoComplete</MenuItem>
                                            </Select>
                                        </FormGroup>
                                        {this.state.desireEnable && (
                                            <FormGroup fullWidth>
                                                <InputLabel htmlFor="Desired">Desired Values</InputLabel>
                                                <TextField
                                                    type="text"
                                                    name="Desired"
                                                    id="Desired"
                                                    placeholder="Enter Desired Values"
                                                    value={this.state.desired}
                                                    onChange={this.handleInputChangeEdit}
                                                    fullWidth
                                                />
                                            </FormGroup>
                                        )}
                                        
                                        <FormGroup fullWidth>
                                            <InputLabel htmlFor="valueType">Value Type</InputLabel>
                                            <TextField
                                                type="text"
                                                name="valueType"
                                                id="valueType"
                                                placeholder="Enter value type"
                                                value={this.state.metadata.valueType}
                                                onChange={this.handleInputChangeEdit}
                                                fullWidth
                                            />
                                        </FormGroup>
                                        <FormGroup fullWidth>
                                            <InputLabel htmlFor="unit">Unit</InputLabel>
                                            <TextField
                                                type="text"
                                                name="unit"
                                                id="unit"
                                                placeholder="Enter unit"
                                                value={this.state.metadata.unit}
                                                onChange={this.handleInputChangeEdit}
                                                fullWidth
                                            />
                                        </FormGroup>

                                        <Button className="custom-button" type="submit">
                                            Update Property
                                        </Button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button className="custom-button" onClick={this.onclickdeviceupload.bind(this)}>
                            {this.state.modelstatus === 'edit' ? 'Update' : 'Create'}
                        </Button>
                        <Button className="custom-button-cancel" onClick={this.toggleSecondary}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* <Modal isOpen={this.state.editmodal} toggle={this.toggleeditdevicemodel.bind(this, '')}
                    className={'modal-lg ' + this.props.className}>
                    <ModalHeader toggle={this.toggleeditdevicemodel}>View Type</ModalHeader>
                    <ModalBody>
                        <div id="accordion">
                            <Label className="help-block" id="errorstyle">{this.state.errors}</Label>
                            <Card className="mb-0">
                                <CardHeader id="headingOne">
                                    <Button block color="link" className="text-left m-0 p-0" onClick={() => this.toggleAccordion(0)} aria-expanded={this.state.accordion[0]} aria-controls="collapseOne">
                                        <h5 className="m-0 p-0">Type Details <strong className="redfont">*</strong></h5>
                                    </Button>
                                </CardHeader>
                                <Collapse isOpen={this.state.accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                                    <CardBody>
                                        <Form>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Name</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text"
                                                        value={this.state.typeuploaddata.deviceType} disabled
                                                        name="deviceType" placeholder="Enter Type Name" />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Description</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text"
                                                        value={this.state.typeuploaddata.description} disabled
                                                        name="description" placeholder="Description" />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Meta Data</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <pre>
                                                        <Input type="textarea" value={this.state.typeuploaddata.metadata} disabled
                                                            rows="10" name='metadata' placeholder='Enter correct JSON format based on device type.' />
                                                    </pre>
                                                </Col>
                                            </FormGroup>



                                        </Form>
                                    </CardBody>
                                </Collapse>
                            </Card>


                        </div>
                    </ModalBody>
                </Modal> */}
                <Dialog open={this.state.editmodal} onClose={this.toggleeditdevicemodel} maxWidth="lg">
                    <DialogTitle>View Type</DialogTitle>
                    <DialogContent>
                        <div id="accordion">
                            <TextField
                                label="Name"
                                value={this.state.typeuploaddata.deviceType}
                                disabled
                                fullWidth
                                style={{ marginTop: "10px" }}
                            />
                            <TextField
                                label="Description"
                                value={this.state.typeuploaddata.description}
                                disabled
                                fullWidth
                                style={{ marginTop: "10px" }}

                            />
                            <TextField
                                label="Meta Data"
                                value={this.state.typeuploaddata.metadata}
                                disabled
                                fullWidth
                                multiline
                                rows={10}
                                style={{ marginTop: "10px" }}

                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleeditdevicemodel} className="custom-button-cancel">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.danger} onClose={this.toggledeviceDelete}>
                    <DialogTitle>Delete Device Type</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            <strong>Are you sure you want to delete this Device Type?</strong>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onclickdelete.bind(this)} className="custom-button" style={{ backgroundColor: "red" }}>
                            Delete
                        </Button>
                        <Button onClick={this.toggledeviceDelete} className="custom-button-cancel">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>


            </div>
        );
    }

}

export default Types;
