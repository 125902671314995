import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./ViewAssetOEMComponent.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import VehicleStatus from "../../Broker/LiveMonitoring/VehicleStatus/VehicleStatus";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

/* end of tab panel */

export default function WhiteLabelRequestsComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const [whiteLabelRequestsList, setWhiteLabelRequestsList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [whiteLabelUpdateDialog, setWhiteLabelUpdateDialog] =
    React.useState(false);
  const [selectedAssetOEMId, steSelectedAssetOEMId] = React.useState("");
  const [selectedWhiteLabelCost, setSelectedWhiteLabelCost] =
    React.useState("");
  const [selectedWhiteLabelStatus, setSelectedWhiteLabelStatus] =
    React.useState("");
  const [selectedModelId, setSelectedModelId] = React.useState("");
  const [selectedAssetId, setSelectedAssetId] = React.useState("");
  const [selectedAssetDeveloperId, setSelectedAssetDeveloperId] =
    React.useState("");
  const [currentdWhiteLabelStatus, setCurrentWhiteLabelStatus] =
    React.useState("");

  const [filteredAssetOEMsList, setFilteredAssetOEMsList] = useState([]);
  const [filteredWhiteLabelRequests, setFilteredWhiteLabelRequests] = useState(
    []
  );
  const [searched, setSearched] = useState("");
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [showLiveTrack, setShowLiveTrack] = useState(false);

  useEffect(() => {
    getAllWhiteLabelRequests();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* view all whitelabel requests */
  const getAllWhiteLabelRequests = () => {
    MainService.getAllWhiteLabelRequests(username)
      .then((res) => {
        setWhiteLabelRequestsList(res);
        setFilteredWhiteLabelRequests(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* end of view all whitelabel requests */

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};

  const whiteLabelUpdateDialogClick = (
    assetdeveloperid,
    assetId,
    modelid,
    prevStatus,
    cost,
    assetOEMId
  ) => {
    steSelectedAssetOEMId(assetOEMId);
    setSelectedWhiteLabelCost(cost);
    setSelectedWhiteLabelStatus(prevStatus);
    setSelectedModelId(modelid);
    setSelectedAssetId(assetId);
    setSelectedAssetDeveloperId(assetdeveloperid);

    setWhiteLabelUpdateDialog(!whiteLabelUpdateDialog);
  };
  const trackAssetClick = (assetId) => {
    setSelectedAssetId(assetId);
    setShowLiveTrack(true);
    setGridView(false);
    setListView(false);
  };
  const backToRequests = () => {
    setShowLiveTrack(false);
    setGridView(true);
    setListView(false);
  };
  const whiteLabelUpdateClick = (selectedAssetOEMId) => {
    if (!currentdWhiteLabelStatus) {
      NotificationManager.warning("Please Selct Current WhiteLabel Status");
      return;
    }
    MainService.updateWhiteLabelStatus(
      selectedAssetOEMId,
      username,
      selectedAssetId,
      selectedModelId,
      currentdWhiteLabelStatus
    )
      .then((res) => {
        getAllWhiteLabelRequests();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    let statusValue;
    if (currentdWhiteLabelStatus === "Whitelabelled") {
      statusValue = "untested";
    } else {
      statusValue = currentdWhiteLabelStatus;
    }
    const formData = {
      status: statusValue,
    };
    const statusData = JSON.stringify(formData);

    MainService.assetStatusUpdate(selectedAssetId, statusData)
      .then((res) => {
        if (res) {
          MainService.updateWhitelabelId(selectedAssetOEMId, selectedAssetId)
            .then((res) => {
              return NotificationManager.success(res);
            })
            .catch((e) => {
              return NotificationManager.error(e);
            });
        }

        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });

    setWhiteLabelUpdateDialog(!whiteLabelUpdateDialog);
  };

  const requestSearchForWhiteLabelRequests = (searchedVal) => {
    const filteredRows = filteredWhiteLabelRequests.filter((row) => {
      return row.assetoemid.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setWhiteLabelRequestsList(filteredRows);
  };
  const cancelSearchForWhiteLabelRequests = () => {
    setSearched("");
    requestSearchForWhiteLabelRequests(searched);
  };
  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  return (
    <>
      <div className="container">
        <Grid>
          <div style={{ float: "right" }}>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={listViewClick}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={gridViewClick}
            ></Button>
          </div>
        </Grid>
        <br />
        <Grid container spacing={1}>
          {listView && (
            <Paper sx={{ width: "100%" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal) =>
                  requestSearchForWhiteLabelRequests(searchVal)
                }
                onCancelSearch={() => cancelSearchForWhiteLabelRequests()}
              />
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell align="center" colSpan={13}>
                        All Whitelabel Requests
                      </TableCell>
                    </TableRow>
                    <TableRow align="center">
                      <TableCell align="center" colSpan={1}>
                        AssetOEM Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Solution Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Legacy Asset Type
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Model Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Asset Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Service Package
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Whitelabel Request Status
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {whiteLabelRequestsList.length > 0 ? (
                      whiteLabelRequestsList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((whitelabelrequests, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {whitelabelrequests.assetoemid}
                              </TableCell>
                              <TableCell align="center">
                                {whitelabelrequests.assetdeveloperid}
                              </TableCell>
                              <TableCell align="center">
                                {whitelabelrequests.legacyassettype}
                              </TableCell>
                              <TableCell align="center">
                                {whitelabelrequests.modelid}
                              </TableCell>
                              <TableCell align="center">
                                {whitelabelrequests.assetid}
                              </TableCell>
                              <TableCell align="center">
                                {whitelabelrequests.servicepackage}
                              </TableCell>
                              <TableCell align="center">
                                {whitelabelrequests.status}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    whiteLabelUpdateDialogClick(
                                      whitelabelrequests.assetdeveloperid,
                                      whitelabelrequests.assetid,
                                      whitelabelrequests.modelid,
                                      whitelabelrequests.status,
                                      whitelabelrequests.whitelabelcost,
                                      whitelabelrequests.assetoemid
                                    );
                                  }}
                                >
                                  Update Request
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    trackAssetClick(whitelabelrequests.assetid);
                                  }}
                                >
                                  Track
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          Whitelabel Requests Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={whiteLabelRequestsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          {gridView && (
            <div className="cardStyleForDashboardItems">
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  {whiteLabelRequestsList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {whiteLabelRequestsList.map(
                        (whitelabelrequests, index) => (
                          <div
                            key={index}
                            className="cardStyleForDashboardItemsBody"
                          >
                            <span>
                              <p>
                                <b>AssetOEM Id:</b>
                                {whitelabelrequests.assetoemid}
                              </p>
                            </span>
                            <p>
                              <b>Solution Id:</b>
                              {whitelabelrequests.assetdeveloperid}
                            </p>
                            <p>
                              <b> Legacy Asset Type</b>
                              {whitelabelrequests.legacyassettype}
                            </p>
                            <p>
                              <b> Model Id</b>
                              {whitelabelrequests.modelid}
                            </p>
                            <p>
                              <b> Asset Id</b>
                              {whitelabelrequests.assetid}
                            </p>
                            <p>
                              <b> Service Package</b>
                              {whitelabelrequests.servicepackage}
                            </p>
                            <p>
                              <b> Whitelabel Request Status</b>
                              {whitelabelrequests.status}
                            </p>

                            <div>
                              <h4 className="cardactiontitle">Actions</h4>
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  whiteLabelUpdateDialogClick(
                                    whitelabelrequests.assetdeveloperid,
                                    whitelabelrequests.assetid,
                                    whitelabelrequests.modelid,
                                    whitelabelrequests.status,
                                    whitelabelrequests.whitelabelcost,
                                    whitelabelrequests.assetoemid
                                  );
                                }}
                              >
                                Update Request
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  trackAssetClick(whitelabelrequests.assetid);
                                }}
                              >
                                Track
                              </Button>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <h4>WhiteLabel Requests not found.</h4>
                  )}
                </div>
              )}
            </div>
          )}
          {showLiveTrack && (
            <div>
              <Button variant="contained" onClick={backToRequests}>
                Back
              </Button>
              <VehicleStatus assetId={selectedAssetId} />
            </div>
          )}
        </Grid>

        {/* Update Whitelabel Request */}
        <Dialog
          fullScreen={fullScreen}
          open={whiteLabelUpdateDialog}
          onClose={handleClose}
          aria-labelledby="whiteLabelUpdateDialog"
        >
          <DialogTitle id="whiteLabelUpdateDialog">
            {"Update Whitelabel Request"}
          </DialogTitle>
          <DialogContent>
            <p>
              <b>AssetOEM ID:</b>
              {selectedAssetOEMId}
            </p>
            <p>
              <b>Solution Id:</b>
              {selectedAssetDeveloperId}
            </p>
            <p>
              <b>Model Id:</b>
              {selectedModelId}
            </p>
            <p>
              <b>Asset Id:</b>
              {selectedAssetId}
            </p>
            <p>
              <b>Whitelabel Cost:</b>
              {selectedWhiteLabelCost}
            </p>

            <p>
              <b>Existing Whitelabel Status:</b>
              {selectedWhiteLabelStatus}
            </p>
            <FormControl fullWidth>
              <InputLabel id="currentWhitelabelStatus:">
                Current Whitelabel Status:
              </InputLabel>
              <Select
                id="currentWhitelabelStatus"
                value={currentdWhiteLabelStatus}
                label="currentWhitelabelStatus"
                onChange={(e) => {
                  setCurrentWhiteLabelStatus(e.target.value);
                }}
              >
                <MenuItem value="whitelabel-in-progress">
                  Whitelabel-in-progress
                </MenuItem>
                <MenuItem value="whitelabelled">Whitelabelled</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
                <MenuItem value="lost">Lost</MenuItem>
                <MenuItem value="damaged">Damaged</MenuItem>
                <MenuItem value="under-maintenance">Under-Maintenance</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                whiteLabelUpdateClick(selectedAssetOEMId);
              }}
            >
              Update
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setWhiteLabelUpdateDialog(!whiteLabelUpdateDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
