import React from 'react'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import DSNonBoardingSubsection from './DSNonBoardingSubsection'
export default function DSNonBoardingService() {
  return (
    <>
     <UpdatesBanner 
      className="onBoardingBanner text-white"
      bannerText="DSN Onboarding Service"/>
      <div className='subSectionWrapper'>
        <DSNonBoardingSubsection/>
      </div>
    </>  )
}
