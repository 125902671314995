import React, { useState } from "react";
import { Box, styled, Container, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AdvisoryComponent from "../Advisory/AdvisoryComponent";
import BuyInputComponent from "../BuyInput/BuyInputComponent";
import PriceDiscoveryComponent from "../PriceDiscovery/PriceDiscoveryComponent";
import SiteUpdatesComponent from "../SiteUpdates/SiteUpdatesComponent";
import PublishJob from "../JobsManagement/PublishJob";
import Advertisement from "../Advertisement/Advertisement";
import ClubUpdates from "../ClubUpdates/ClubUpdates";
import Branding from "../Branding/Branding";
import ProjectReport from "../ProjectReport/ProjectReport";
import PitchDeck from "../PitchDeck/PitchDeck";
import SiteWeatherUpdates from "../SiteWeatherUpdates/SiteWeatherUpdates";
import AAMCSiteManagementComponent from "../AAMCSiteManagement/AAMCSiteManagementComponent";
import TenderManagementComponent from "../TenderManagement/Tender";
import FarmerGovtSchemes from "../FarmerGovtSchemes/FarmerGovtSchemes";
import brandimage from "../../../../resources/img/brand-image.svg";
import consultation from "../../../../resources/img/consultation.svg";
import field from "../../../../resources/img/field.svg";
import find from "../../../../resources/img/find.svg";
import membership from "../../../../resources/img/membership.svg";
import portfolio from "../../../../resources/img/portfolio.svg";
import presentation from "../../../../resources/img/presentation.svg";
import report from "../../../../resources/img/report.svg";
import shoppingbasket from "../../../../resources/img/shopping-basket.svg";
import videoadvertising from "../../../../resources/img/video-advertising.svg";
import workflow from "../../../../resources/img/workflow.svg";
import farmer from "../../../../resources/img/farmer_scheme.svg";
import weather from "../../../../resources/img/weather.svg";
import tender from "../../../../resources/img/tender.svg";

const FarmerFacilitationServices = () => {
  const [getProfileDocCondition, setProfileDocCondition] = useState(true);
  const [getProfileUserDoc, setProfileUserDoc] = useState(false);
  const [getProfileParentDoc, setProfileParentDoc] = useState(false);
  const [getProfileKycDoc, setProfileKycDoc] = useState(false);
  const [getProfileAgreement, setProfileAgreement] = useState(false);
  const [getWalletModel, setWalletModel] = useState(false);
  const [getBankModel, setBankModel] = useState(false);
  const [getSettingsInfoModel, setSettingsInfoModel] = useState(false);
  const [getCustomerCareModel, setCustomerCareModel] = useState(false);
  const [getPitchDeckModel, setPitchDeckModel] = useState(false);
  const [getAAMCModel, setAAMCModel] = useState(false);
  const [getGovtModel, setGovtModel] = useState(false);
  const [getWeatherModel, setWeatherModel] = useState(false);
  const [getTenderModel, setTenderModel] = useState(false);
  const [activeButton, setActiveButton] = useState("advisory");
  const userType = localStorage.getItem("userType");
  const userRole = localStorage.getItem("UserRole");

  const data = [
    {
      title: "Advisory",
      type: "advisory",
      image: `${consultation}`,
      usertype: ["STANDARD"],
    },
    {
      title: "Buy-Input",
      type: "buyinput",
      image: `${shoppingbasket}`,
      usertype: [],
    },
    {
      title: "Price-Discovery",
      type: "pricediscovery",
      image: `${find}`,
      usertype: ["AssetCertifiedConsultant"],
    },
    {
      title: "Site-Updates",
      type: "siteupdates",
      image: `${field}`,
      usertype: ["AssetCertifiedConsultant","STANDARD","PREFFERED","PREMIUM"],
    },
    {
      title: "Job'S",
      type: "jobs",
      image: `${portfolio}`,
      usertype: ["AssetCertifiedConsultant","STANDARD","PREFFERED","PREMIUM"],
    },
    {
      title: "Advertisement",
      type: "advertisement",
      image: `${videoadvertising}`,
      usertype: ["STANDARD","PREFFERED","PREMIUM"],
    },
    {
      title: "Club-Updates",
      type: "clubupdates",
      image: `${membership}`,
      usertype: ["STANDARD","PREFFERED","PREMIUM"],
    },
    {
      title: "Branding",
      type: "branding",
      image: `${brandimage}`,
      usertype: ["AssetCertifiedConsultant","STANDARD","PREFFERED","PREMIUM"],
    },
    {
      title: "Project-Report",
      type: "project",
      image: `${report}`,
      usertype: ["STANDARD","PREFFERED","PREMIUM"],
    },
    {
      title: "Pitch-Deck",
      type: "pitchdeck",
      image: `${presentation}`,
      usertype: ["STANDARD","PREFFERED","PREMIUM"],
    },
    {
      title: "Farmer-Govt-Schemes",
      type: "farmer",
      image: `${farmer}`,
      usertype: ["FOOD-PROCESSOR", "AssetDeveloper","STANDARD","PREFFERED","PREMIUM"],
    },
    {
      title: "Site-Weather-Updates",
      type: "weather",
      image: `${weather}`,
      usertype: ["FOOD-PROCESSOR", "AssetDeveloper","STANDARD","PREFFERED","PREMIUM"],
    },
    {
      title: "Tenders",
      type: "tender",
      image: `${tender}`,
      usertype: ["STANDARD","PREFFERED","PREMIUM"],
    },
    {
      title: "Site-Management",
      type: "sitemanagement",
      image: `${workflow}`,
      usertype: ["AssetCertifiedConsultant"],
    },
  ];

  const showHandler = (Type) => {
    setProfileDocCondition(false);
    setProfileUserDoc(false);
    setProfileParentDoc(false);
    setProfileKycDoc(false);
    setProfileAgreement(false);
    setBankModel(false);
    setWalletModel(false);
    setCustomerCareModel(false);
    setSettingsInfoModel(false);
    setPitchDeckModel(false);
    setGovtModel(false);
    setWeatherModel(false);
    setTenderModel(false);
    setAAMCModel(false);

    setActiveButton(Type);

    if (Type === "advisory") {
      setProfileDocCondition(true);
    } else if (Type === "buyinput") {
      setProfileUserDoc(true);
    } else if (Type === "pricediscovery") {
      setProfileParentDoc(true);
    } else if (Type === "siteupdates") {
      setProfileKycDoc(true);
    } else if (Type === "jobs") {
      setProfileAgreement(true);
    } else if (Type === "advertisement") {
      setWalletModel(true);
    } else if (Type === "clubupdates") {
      setBankModel(true);
    } else if (Type === "branding") {
      setCustomerCareModel(true);
    } else if (Type === "project") {
      setSettingsInfoModel(true);
    } else if (Type === "pitchdeck") {
      setPitchDeckModel(true);
    } else if (Type === "farmer") {
      setGovtModel(true);
    } else if (Type === "weather") {
      setWeatherModel(true);
    } else if (Type === "tender") {
      setTenderModel(true);
    } else if (Type === "sitemanagement") {
      setAAMCModel(true);
    }
  };

  const StyledIconButton = styled(IconButton)(({ theme, isActive }) => ({
    color: isActive ? theme.palette.primary.main : theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  }));

  return (
    <>
      <Grid container spacing={1}>
        <Container
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "row", sm: "row", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            background: "whitesmoke",
            margin: "2px 2px 12px 2px",
          }}
        >
          <Box sx={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
            {data &&
              data
                ?.filter((val) => !val?.usertype.includes(userType) && !val?.usertype.includes(userRole))
                .map((val, i) => (
                  <Tooltip key={val.title} title={val.title}>
                    <StyledIconButton
                      onClick={() => {
                        showHandler(val.type);
                      }}
                      isActive={activeButton === val.type}
                    >
                      <img
                        src={val.image}
                        style={{
                          width: "2rem",
                          height: "2rem",
                          objectFit: "cover",
                        }}
                      />
                    </StyledIconButton>
                  </Tooltip>
                ))}
          </Box>
        </Container>

        <Grid item md={12} xs={12}>
          {getProfileDocCondition && <AdvisoryComponent />}
          {getProfileUserDoc && <BuyInputComponent />}
          {getProfileParentDoc && <PriceDiscoveryComponent />}
          {getProfileKycDoc && <SiteUpdatesComponent />}
          {getProfileAgreement && <PublishJob />}
          {getWalletModel && <Advertisement />}
          {getBankModel && <ClubUpdates />}
          {getCustomerCareModel && <Branding />}
          {getSettingsInfoModel && <ProjectReport />}
          {getPitchDeckModel && <PitchDeck />}
          {getGovtModel && <FarmerGovtSchemes />}
          {getWeatherModel && <SiteWeatherUpdates />}
          {getTenderModel && <TenderManagementComponent />}
          {getAAMCModel && (
            <AAMCSiteManagementComponent
              siteType={[
                "Physical-Market",
                "Farm",
                "District",
                "Organization",
                "Department",
                "State",
              ]}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FarmerFacilitationServices;
