import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { Suspense, lazy, useState } from "react";
import Loading from "../../AssetDeveloperRole/ModelProductManagement/Loading";
const DocsModel = lazy(() => import("../../Common/profile/DocsModel"));

const newStyles = {
  fontWeight: "600",
  padding: "8px",
  textAlign: "center",
  backgroundColor: "aliceblue",
  color: "#3c8a67",
};

const valueSize = {
  sx: {
    wordWrap: "break-word",
    fontWeight: 500,
    fontSize: "15px",
  },
};

const buttonStyel = {
  textTransform: "none",
  width: "100%", // Make the width consistent
  fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" }, // Responsive font size
};

const dynamicKey = [
  { key: "Content Owner", value: "contentowner" },
  { key: "Content Sub Category", value: "contentsubcategory" },
  { key: "Name", value: "contentname" },
  { key: "Description", value: "contentdescription" },
];

const VendorsTable = ({ data, openModel, closeModel }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const [getDocUrlModel, setDocUrlModel] = useState(false);
  const [getDocUrl, setDocUrl] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const viewDocHandler = (docUrl) => {
    setDocUrl(docUrl);
    setDocUrlModel(!getDocUrlModel);
  };

  return (
    <>
      {/* Table Model */}
      <Dialog
        fullScreen={fullScreen}
        open={openModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Paper sx={{ width: "100%", height: "100%", mt: "0.5rem" }}>
            <TableContainer style={{ width: "100%", maxHeight: "400px" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow align="center" style={newStyles}>
                    {dynamicKey.map((key, index) => (
                      <TableCell
                        align="center"
                        colSpan={1}
                        style={newStyles}
                        key={index}
                      >
                        {key.key}
                      </TableCell>
                    ))}
                    <TableCell align="center" colSpan={4} style={newStyles}>
                      Option
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(data) && data?.length > 0 ? (
                    data &&
                    data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((posts, index) => (
                        <TableRow hover tabIndex={-1} key={index}>
                          {dynamicKey.map((key, i) => (
                            <TableCell
                              align="center"
                              key={key.value + i}
                              {...valueSize}
                            >
                              {posts[key.value]}
                            </TableCell>
                          ))}
                          <TableCell align="center">
                            <Button
                              style={buttonStyel}
                              type="button"
                              variant="outlined"
                              onClick={() => {
                                viewDocHandler(posts.locationurl);
                              }}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow align="center">
                      <TableCell align="center" colSpan={12}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            color="error"
            variant="outlined"
            onClick={() => {
              closeModel();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Document or Image View */}
      <Suspense fallback={<Loading />}>
        <DocsModel
          docUrl={getDocUrl}
          closeModel={viewDocHandler}
          openModel={getDocUrlModel}
        />
      </Suspense>
    </>
  );
};

export default VendorsTable;
