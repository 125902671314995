import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row, Container, Collapse, Fade,
  Pagination, PaginationItem, PaginationLink,
  Badge,
  Modal, ModalBody, ModalFooter, ModalHeader, Spinner
} from 'reactstrap';
import * as XLSX from 'xlsx';
import saveAs from 'file-saver';
import * as ExcelJS from 'exceljs';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../style.css';
import './Layout.css';
import AuthAxiosInstance from '../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance } from '../../../authAxiosInstance'
import socketIOClients from "socket.io-client";
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputLabel from '@mui/material/InputLabel';
import { NotificationManager } from "react-notifications";
import { withRouter } from 'react-router-dom';// import path from "../../../../assets/templete.xls"
import XMLParser from 'react-xml-parser';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



let prev = 0;
let next = 0;
let last = 0;
let first = 0;

let jsonconfig = window.appConfig;

class Device extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devicetypelist: [],
      disabledstate: true,
      isLoading: false,
      primary: false,
      danger: false,
      editmodal: false,
      deletedata: {},
      profiledata: [],
      uploadedData: [],
      deviceuploaddata: [],
      editdevicedetails: [],
      devicelist: [],
      odevicelist: [],
      currentpageno: 1,
      pageno: 1,
      pagesize: 5,
      searchkey: '',
      totaldevicecount: '',
      devicelisttypeid: 'all',
      accordion: [true],
      currentlist: [],
      typelist: [],
      modellist: [],
      uploadDataModel: false,
      deviceUploadLoader: false,
      xmlData: '',
      tableData: []

    };

    this.togglePrimary = this.togglePrimary.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleLastClick = this.handleLastClick.bind(this);
    this.handleFirstClick = this.handleFirstClick.bind(this);
    this.onclickdelete = this.onclickdelete.bind(this);
    this.toggleAccordion = this.toggleAccordion.bind(this);
    this.uploadDevice = this.uploadDevice.bind(this);
    this.createUploadDevices = this.createUploadDevices.bind(this);


    // toast.configure({
    //   autoClose: 8000,
    //   draggable: false,
    //   //etc you get the idea
    // });
  }

  getAppConfig = () => {
    return window.AppConfigData;
  };

  toggleAccordion(tab) {

    const prevState = this.state.accordion;
    const state = prevState.map((x, index) => tab === index ? !x : false);

    this.setState({
      accordion: state,
    });
  }

  uploadDevice() {
    this.setState({ uploadDataModel: true })
  }

  /*=============pagination========*/
  onRangevaluechange = (_value, e) => {
    var range = '';
    if (e.target.value == "ShowAll") {
      range = this.state.totaldevicecount;
    }
    else {
      range = e.target.value;
    }
    this.setState({ [_value]: range });
    this.setState({ currentpageno: this.state.currentpageno });
    this.setState({ pageno: 1 }, () => { this.callbackfirmware() });
  }

  onSearchkeyChange = (_value, e) => {
    this.setState({ [_value]: e.target.value });
    var queryResult = [];
    if (e.target.value !== '') {
      this.state.odevicelist.forEach(function (item) {
        if (item.deviceName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
          item.deviceType.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
          item.deviceId.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
          item.model.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
          queryResult.push(item);
      });
      this.setState({ devicelist: queryResult });
    }
    else {
      this.setState({ pageno: 1 }, () => { this.callbackfirmware() });
    }
  }
  callbackfirmware = () => {
    this.getAllDevices();
  }
  handleClick(event) {
    event.preventDefault();
    this.setState({
      pageno: Number(event.target.id)
    });

    this.getAllDevices();
  }
  handleLastClick(event) {
    event.preventDefault();
    this.setState({
      pageno: last
    });
    this.getAllDevices();
  }
  handleFirstClick(event) {
    event.preventDefault();
    this.setState({
      pageno: 1
    });
    this.getAllDevices();
  }
  /*================end===============*/

  /*==============Gateway Create ===========================*/
  togglePrimary() {
    this.setState({
      primary: !this.state.primary, selectedOption: null, pointlist: [], disabledstate: true, deviceuploaddata: {}
      , errors: '', disabledstate: true
    });
  }

  onvalueChange(name, e) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    const formData = { ...this.state.deviceuploaddata };
    if (name != 'devicecloudserver') {
      formData[e.target.name] = e.target.value;
      this.setState({ deviceuploaddata: formData }, () => {
        this.handleformvalidation();
      });
      if (name === 'deviceType') {
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${e.target.value}/models`).then((res) => {
          this.setState({ modellist: res.data });
        }).catch((err) => {
          this.setState({ modellist: [] });
        });
      }
    }

    if (name === 'devicecloudserver') {
      const foundObject = this.state.tableData?.find(item => item.deviceCloudServerType == e.target.value);
      formData["cloudServer"] = e.target.value;
      formData["cloudServerName"] = foundObject.friendlyName;
      this.setState({ deviceuploaddata: formData }, () => {
        this.handleformvalidation();
      });
    }
  }



  handleformvalidation() {
    let fields = this.state.deviceuploaddata;
    let formIsValid = true;
    var validationfield = [{ 'name': 'deviceId', 'type': 'alphanumeric', 'isrequired': true, 'minlength': 3, 'displayname': 'Device Id' },
    { 'name': 'deviceName', 'type': 'alphanumericspace', 'isrequired': true, 'minlength': 5, 'displayname': 'Device Name' },
    { 'name': 'deviceType', 'type': '', 'isrequired': true, 'minlength': 0, 'displayname': 'Device Type' },
    { 'name': 'model', 'type': '', 'isrequired': false, 'minlength': 0, 'displayname': 'Model' }];
    for (let i = 0; i < validationfield.length; i++) {
      if (validationfield[i].isrequired && !fields[validationfield[i].name]) {
        formIsValid = false;
        this.setState({ errors: 'Please fill all the fields' });
        this.setState({ disabledstate: true });
      }
      else if (validationfield[i].type === 'alphanumeric') {
        let nospecial = /[*|\":<>[\]{}`\\()';@&$!#^=+%`~,./? _\-]/;
        if (nospecial.test(fields[validationfield[i].name])) {
          formIsValid = false;
          this.setState({ errors: `No Special characters allowed in ${validationfield[i].displayname}` });
          this.setState({ disabledstate: true });
        }
        if (fields[validationfield[i].name].length < validationfield[i].minlength) {
          formIsValid = false;
          this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
          this.setState({ disabledstate: true });
        }
      }
      else if (validationfield[i].type === 'alphanumericspace') {
        let nospecial1 = /[*|\":<>[\]{}`\\()';@&$!#^=+%`~,./?_\-]/;
        if (nospecial1.test(fields[validationfield[i].name])) {
          formIsValid = false;
          this.setState({ errors: `No Special characters allowed in ${validationfield[i].displayname}` });
          this.setState({ disabledstate: true });
        }
        if (fields[validationfield[i].name].length < validationfield[i].minlength) {
          formIsValid = false;
          this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
          this.setState({ disabledstate: true });
        }
      }
      else if (validationfield[i].minlength > 0) {
        if (fields[validationfield[i].name].length < validationfield[i].minlength) {
          formIsValid = false;
          this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
          this.setState({ disabledstate: true });
        }
      }
    }
    if (formIsValid === true) {

      this.setState({ errors: '', disabledstate: false });
      //this.onclickdeviceupload();



    }

  }

  onclickdeviceupload = () => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    DeviceMetadataAxiosInstance.post(`/admin/deviceprofile/${username}/register`, this.state.deviceuploaddata)
      .then((response) => {
        // toast.success("Device created successfully", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        this.setState({ primary: !this.state.primary });
        this.getAllDevices();
        return NotificationManager.success("Device created successfully");
      }).catch((err) => {
        // toast.error(err.response.data, {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");
      })
  }


  /*================ Device edit ========================*/
  toggleeditdevicemodel = (gw_id, status) => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    if (status == "edit") {
      DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${gw_id.deviceType}/models`).then((res) => {
        this.setState({ modellist: res.data });
        let gw = { ...gw_id };
        if (res.data !== 'failed' && res.data.length === 0) {
          gw.model = '';
        }
        this.setState({ deviceuploaddata: gw, editmodal: true, disabledstate: false, errors: '' })
      }).catch((err) => {
        this.setState({ modellist: [] });
        let gw = { ...gw_id };
        gw.model = '';
        this.setState({ deviceuploaddata: gw, editmodal: true, disabledstate: false, errors: '' })
      });

    } else {
      this.setState({ deviceuploaddata: {}, editmodal: false })
    }


  }


  onclickeditdeviceupload = () => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    let req = {
      deviceType: this.state.deviceuploaddata.deviceType,
      deviceName: this.state.deviceuploaddata.deviceName,
      model: this.state.deviceuploaddata.model
    }
    DeviceMetadataAxiosInstance.put(`/admin/deviceprofile/${username}/${this.state.deviceuploaddata.id}`, req)
      .then((res) => {
        // toast.success("Device Successfully updated", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        this.setState({ editmodal: false });
        this.getAllDevices();
        return NotificationManager.success("Device Successfully updated");

      }).catch((err) => {
        // toast.error("Some error occured while updating the device", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");


      });
  }
  /*================= Gateway delete =====================*/
  toggledeviceDelete = (data, event) => {
    this.setState({
      danger: !this.state.danger, deletedata: data
    });

  }
  onclickdelete = () => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    console.log(this.state.deletedata);
    DeviceMetadataAxiosInstance.delete(`/admin/deviceprofile/${username}/${this.state.deletedata.id}`).then((resp) => {
      // toast.success("Device successfully deleted", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      this.setState({
        danger: !this.state.danger
      });
      this.getAllDevices();
      return NotificationManager.success("Device successfully deleted");

    }).catch((err) => {
      // toast.error("Error occured while deleting the device", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Some error occured");

    });


  }

  getAllCloudServers() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    MainService.getDeviceCloudList(parentId, username)
      .then((res) => {

        const parsedData = new XMLParser().parseFromString(res.data);

        const tableData = parsedData?.children
          ?.filter((element) => element.name === 'devicecloudserver')
          .map((device, index) => ({
            id: device.getElementsByTagName('id')[0]?.value || '',
            deviceCloudServerType: device.getElementsByTagName('devicecloudservertype')[0]?.value || '',
            friendlyName: device.getElementsByTagName('friendlyname')[0]?.value || '',
            tenancyType: device.getElementsByTagName('tenancytype')[0]?.value || '',
            deploymentType: device.getElementsByTagName('deploymenttype')[0]?.value || '',
            baseUrl: device.getElementsByTagName('baseurl')[0]?.value || '',
            region: device.getElementsByTagName('region')[0]?.value || '',
            ipAddress: device.getElementsByTagName('ipaddress')[0]?.value || '',
            port: device.getElementsByTagName('port')[0]?.value || '',
            version: device.getElementsByTagName('version')[0]?.value || '',
            dcsImgLink: device.getElementsByTagName('dcsimglink')[0]?.value || '',
            oemIdentityAccessLink: device.getElementsByTagName('oemidentityaccesslink')[0]?.value || '',
            status: device.getElementsByTagName('status')[0]?.value || '',
          })) || [];

        this.setState({ tableData });
      })
      .catch((err) => {
        return NotificationManager.error("Something went wrong");
      });
  }
  componentDidMount() {
    this.getAllDevices();
    this.getAllDevicesTypes();
    this.getAllCloudServers()


    const socket2 = socketIOClients((this.getAppConfig().ApiUrlMapp.SocketIOURL));
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    socket2.on("presence", (pdata) => {
      const userId = localStorage.getItem("user-id");
      try {
        if (pdata.networkId.toString() === username) {
          let devicelist = [...this.state.devicelist];
          let odevicelist = [...this.state.odevicelist]
          devicelist.map((d, i) => {
            if (d.deviceId === pdata.deviceId) {
              d.deviceStatus = pdata.status;
            }
          });
          odevicelist.map((od, i) => {
            if (od.deviceId === pdata.deviceId) {
              od.deviceStatus = pdata.status;
            }
          });
          this.setState({ devicelist });
          this.setState({ odevicelist });
        }
      }
      catch (cerror) {
        // toast.error("Something went wrong.", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
      }
    })
  }

  getAllDevices() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    //const userId = localStorage.getItem("user-id");
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/list`).then((resp) => {
      if (resp.data) {


        this.setState({ devicelist: resp.data, odevicelist: resp.data, totaldevicecount: resp.data.length });

      }
      else {
        this.setState({ devicelist: [], odevicelist: [] });
      }
    }).catch((err) => {

    })
  }

  viewProperty(devId) {
    this.props.history.push(`/main/gateway?devId=${devId}`);
  }


  getAllDevicesTypes() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/list`).then((resp) => {
      if (resp.data) {
        this.setState({ typelist: resp.data },
          () => {
          }
        );
      }
      else {
        this.setState({ typelist: [] });
      }
    }).catch((err) => {

    })
  }
  registerDevice(deviceId, deviceType, deviceName, model) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    const req =
    {
      EntityID: deviceId,
      PartnerId: parentId,
      SolutionId: username,
      OrgId: username
    };

    MainService.registerDevice(JSON.stringify(req))
      .then((res) => {
        MainService.updateRegistredDeviceStatus(username, parentId, deviceId, { status: "true" })
          .then((res) => {
            this.getAllDevices();
            return NotificationManager.success("Device Registered Successfully");
          })
          .catch((err) => {
            return NotificationManager.error("Something went wrong");
          });
      })
      .catch((err) => {
        return NotificationManager.error("No Topics For this Entity");
      });

  }

  deRegisterDevice(deviceId, deviceType, deviceName, model) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    MainService.deRegisterDevice(parentId, username, deviceId)
      .then((res) => {
        MainService.updateRegistredDeviceStatus(username, parentId, deviceId, { status: "false" })
          .then((res) => {
            this.getAllDevices();
            return NotificationManager.success("Device Deregistered Successfully");
          })
          .catch((err) => {
            return NotificationManager.error("Something went wrong");
          });
      })
      .catch((err) => {
        return NotificationManager.error("Something went wrong");
      });

  }

  trend(deviceId, deviceType, deviceName, model) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    alert("to be mplemented")

  }

  toggleModalFile = () => {
    this.setState(prevState => ({
      uploadDataModel: !prevState.uploadDataModel,
    }));
  };

  // createUploadDevices(){
  //     this.state.uploadedData.forEach((device) => {
  //       console.log(device);
  //       DeviceMetadataAxiosInstance.post(`/admin/deviceprofile/${networkId.network}/register`, device)
  //     .then((response) => {
  //       this.getAllDevices();
  //       console.log("deviceCreated")
  //     }).catch((err) => {

  //     })
  //     })
  //     this.toggleModalFile();
  // }

  createUploadDevices() {
    // Start loading
    this.setState({ isLoading: true });
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    this.state.uploadedData.forEach((device) => {
      if (device.deviceId) {
        DeviceMetadataAxiosInstance.post(`/admin/deviceprofile/${username}/register`, device)
          .then((response) => {
            // Handle success
            this.getAllDevices();
            console.log("deviceCreated");
          })
          .catch((err) => {
            // toast.error(err.response.data, {
            //   position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");

          })
          .finally(() => {
            // Check if there are any more devices to upload
            if (this.state.uploadedData.indexOf(device) === this.state.uploadedData.length - 1) {
              // toast.success("Devices created successfully", {
              //   position: toast.POSITION.TOP_RIGHT
              // });
              this.setState({ isLoading: false });
              return NotificationManager.success("Devices created successfully");
            }
          });
      }

    });

    this.toggleModalFile();
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const dataArray = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const ArrayOfObjectData = [];
      const header = dataArray.shift();
      dataArray.forEach(item => {
        if (item[2]) {
          const obj = {
            deviceType: item[0],
            model: item[1],
            deviceId: item[2],
            deviceName: item[3],
            cloudServer: item[4],
            cloudServerName: item[5]
          }
          ArrayOfObjectData.push(obj)
        }
      })

      this.setState({ uploadedData: ArrayOfObjectData });
    };

    reader.readAsBinaryString(file);
  };

  downloadTemplate = async () => {
    try {
      // Create a new workbook
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet1');

      // Define your data here
      const data = [
        ['TypeName', 'ModelName', 'DeviceId', 'DeviceName', 'CloudServer', 'CloudServerFriendlyName'],
      ];

      // Add data to the worksheet
      worksheet.addRows(data);

      // Create a Blob from the workbook data
      const blob = await workbook.xlsx.writeBuffer();

      // Convert the Blob to a file
      const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a temporary URL for the file
      const url = URL.createObjectURL(file);

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'template.xlsx';

      // Trigger the download
      a.click();

      // Clean up by revoking the temporary URL
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error creating XLSX file:', error);
    }
  };



  render() {

    const appConfig = this.getAppConfig();
    const { devicetypelist, devicelist, pageno, pagesize,
      totaldevicecount, uploadedData } = this.state;


    if (devicelist.length > 0) {
      for (var i = 0; i < devicelist.length; i++) {
        for (var j = 0; j < devicetypelist.length; j++) {
          if (devicetypelist[j].dvt_id == devicelist[i].dv_type) {
            devicelist[i].dvt_name = devicetypelist[j].dvt_name;
          }
        }
      }
    }
    // Logic for displaying todos
    const indexOfLastTodo = pageno * pagesize;
    const indexOfFirstTodo = indexOfLastTodo - pagesize;
    const currentlist = devicelist.slice(indexOfFirstTodo, indexOfLastTodo);
    prev = pageno > 0 ? (pageno - 1) : 0;
    last = Math.ceil(totaldevicecount / pagesize);
    next = (last === pageno) ? pageno : pageno + 1;
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totaldevicecount / pagesize); i++) {
      pageNumbers.push(i);
    }
    //Logic to get the list of pagesize
    const pagesizelist = []
    if (parseInt(totaldevicecount) > 0) {
      for (let j = 1; j <= totaldevicecount; j++) {
        if (j % 5 === 0) {
          pagesizelist.push(j);
        }
      }
      pagesizelist.push("ShowAll");

    }
    else {
      pagesizelist.push("ShowAll");
    }




    return (

      <div className="animated fadeIn" >
        {console.log((this.getAppConfig().ApiUrlMapp.SocketIOURL), "hello")}
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <strong className="LabelHeader">Device List</strong>


                <div className="alignright" >
                  <Button style={{ marginLeft: "5px" }} type="button" className="custom-button" onClick={this.togglePrimary} size="sm" color="primary"> Create New Device</Button>
                </div>
                <div className="alignright">
                  <Button type="button" className="custom-button" onClick={this.uploadDevice} size="sm" color="primary"> Upload Device File</Button>
                </div>

              </CardHeader>
              <Col xs="12" md="12">
                <FormGroup row>
                  <Col md="8">

                    {/* {(this.state.searchkey == "") &&
                        <Input type="select" value={pagesize} className="dropdownwidth2" name="pagesize" onChange={this.onRangevaluechange.bind(this, 'pagesize')}>
                          {pagesizelist.map((number, i) =>
                            <option key={number} value={number !== 'ShowAll' ? number : totaldevicecount} >{number !== 'ShowAll' ? number : 'ShowAll'}</option>
                          )}
                        </Input>
                      }
                    </Col>
                    <Col md="4">
                      <Input type="text" name="searchkey" className="btnrightalign searchbox_color" placeholder="Enter search Key" onChange={this.onSearchkeyChange.bind(this, 'searchkey')} /> */}
                    <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                      {this.state.searchkey === "" && (
                        <FormControl variant="outlined" className="dropdownwidth2">
                          <Select
                            value={pagesize}
                            onChange={this.onRangevaluechange.bind(this, "pagesize")}
                          >
                            {pagesizelist.map((number, i) => (
                              <MenuItem
                                key={number}
                                value={number !== "ShowAll" ? number : totaldevicecount}
                              >
                                {number !== "ShowAll" ? number : "ShowAll"}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      {this.state.searchkey != "" && (
                        <FormControl variant="outlined" className="dropdownwidth2">

                        </FormControl>
                      )}
                      <Grid item md={4}>
                        <TextField
                          type="text"
                          name="searchkey"
                          autoComplete="off"
                          placeholder="Enter search Key"
                          onChange={this.onSearchkeyChange.bind(this, "searchkey")}
                        />
                      </Grid>
                    </div>
                  </Col>
                </FormGroup>
              </Col>
              {(currentlist.length > 0) &&
                // <Table hover responsive className="table-outline mb-0  d-sm-table ">
                //   <thead className="thead-dark">
                //     <tr>

                //       <th>SL No</th>
                //       <th>DEVICE ID</th>
                //       <th>DEVICE NAME</th>
                //       <th>DEVICE TYPE</th>
                //       <th>DEVICE MODEL</th>
                //       <th>DEVICE STATUS</th>
                //       <th>ACTION</th>
                //       <th></th>

                //     </tr>
                //   </thead>
                //   {currentlist && currentlist.map((row, index) => {
                //     return (
                //       <tbody key={index}>
                //         <tr className="RowStyle">
                //           <td>{pagesize * (pageno - 1) + (index + 1)}</td>
                //           <td>{row.deviceId}</td>
                //           <td>{row.deviceName}</td>
                //           <td>{row.deviceType}</td>
                //           <td>{row.model}</td>
                //           <td><i className="fa fa-circle" aria-hidden="true" style={{ color: (row.deviceStatus === "ACTIVE") ? "green" : "red" }}></i></td>
                //           <td>
                //             <strong>
                //               <Button color="success" title="View" className="mr-1 rounded-circle btn-circle" onClick={this.viewProperty.bind(this, row.deviceId)} ><i className="fa fa-eye iconwidth"></i></Button>
                //               <Button color="primary" title="Edit" className="mr-1 rounded-circle btn-circle" onClick={this.toggleeditdevicemodel.bind(this, row, 'edit')} ><i className="fa fa-edit iconwidth"></i></Button>
                //               <Button color="danger" title="Delete" className="mr-1 rounded-circle btn-circle" onClick={this.toggledeviceDelete.bind(this, row)} ><i className="fa fa-trash iconwidth"></i></Button>
                //             </strong>
                //           </td>
                //           <td>
                //             <strong>
                //               <Button color="primary" title="Register" disabled={JSON.parse(row.isRegistered) ? true : false} onClick={this.registerDevice.bind(this, row.deviceId, row.deviceType, row.deviceName, row.model,)} >{JSON.parse(row.isRegistered) ? "Registered" : "Register"}</Button>
                //             </strong>
                //             <strong>
                //               <Button color="dark" style={{marginLeft:'10px'}} title="Blacklist" disabled={JSON.parse(row.isRegistered) ? true : false} onClick={this.registerDevice.bind(this, row.deviceId, row.deviceType, row.deviceName, row.model,)} >{JSON.parse(row.isRegistered) ? "Whitelist" : "Blacklist"}</Button>
                //             </strong>
                //           </td>
                //         </tr>
                //       </tbody>
                //     );
                //   }, this)}
                // </Table>

                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>CLOUD SERVER</TableCell>
                        <TableCell>DEVICE ID</TableCell>
                        <TableCell>DEVICE NAME</TableCell>
                        <TableCell>DEVICE TYPE</TableCell>
                        <TableCell>DEVICE MODEL</TableCell>
                        <TableCell>STATUS</TableCell>
                        <TableCell>ACTION</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentlist.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.cloudServerName}</TableCell>
                          <TableCell>{row.deviceId}</TableCell>
                          <TableCell>{row.deviceName}</TableCell>
                          <TableCell>{row.deviceType}</TableCell>
                          <TableCell>{row.model}</TableCell>
                          <TableCell>
                            {row.deviceStatus === 'ACTIVE' ? (
                              <CheckCircleIcon style={{ color: 'green' }} />
                            ) : (
                              <CancelIcon style={{ color: 'red' }} />
                            )}
                          </TableCell>
                          <TableCell>
                            <Button
                              className="custom-button-view"
                              title="View"
                              onClick={this.viewProperty.bind(this, row.deviceId)}
                            >
                              <VisibilityIcon size='small' />
                            </Button>
                            <Button
                              className="custom-button-edit"
                              title="Edit"
                              onClick={this.toggleeditdevicemodel.bind(this, row, 'edit')}
                            >
                              <EditIcon size='small' />
                            </Button>
                            <Button
                              className="custom-button-delete"
                              title="Delete"
                              onClick={this.toggledeviceDelete.bind(this, row)}
                            >
                              <DeleteIcon size='small' />
                            </Button>
                          </TableCell>
                          {(row.isRegistered != 'true') ?
                            <TableCell>
                              <Button
                                className="custom-button"
                                title="Register"
                                onClick={this.registerDevice.bind(this, row.deviceId, row.deviceType, row.deviceName, row.model,)}
                              >
                                {'Register'}
                              </Button>
                            </TableCell> : <TableCell>
                              <Button
                                className="custom-button"
                                title="Deregister"
                                style={{ backgroundColor: '#d94827' }}
                                onClick={this.deRegisterDevice.bind(this, row.deviceId, row.deviceType, row.deviceName, row.model,)}
                              >
                                {'Deregister'}
                              </Button>
                            </TableCell>}
                          <TableCell>
                            <Button
                              className="custom-button"
                              title="Trend"
                              onClick={this.trend.bind(this, row.deviceId, row.deviceType, row.deviceName, row.model,)}
                            >
                              Trend
                            </Button>
                          </TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              }

              <br></br>
              <div>
                {(totaldevicecount !== pagesize) && (currentlist.length > 0) && (this.state.searchkey == "") &&
                  <ul id="page-numbers">
                    <nav>
                      <Pagination style={{ display: "flex" }}>
                        <PaginationItem>
                          {prev === 0 ? <PaginationLink disabled>First</PaginationLink> :
                            <PaginationLink onClick={this.handleFirstClick} id={prev}>First</PaginationLink>
                          }
                        </PaginationItem>
                        <PaginationItem>
                          {prev === 0 ? <PaginationLink disabled>Prev</PaginationLink> :
                            <PaginationLink onClick={this.handleClick} id={prev} >Prev</PaginationLink>
                          }
                        </PaginationItem>
                        {
                          pageNumbers.map((number, i) =>
                            <Pagination key={i}>
                              <PaginationItem active={pageNumbers[pageno - 1] === (number) ? true : false} >
                                <PaginationLink onClick={this.handleClick} key={number} id={number}>
                                  {number}
                                </PaginationLink>
                              </PaginationItem>
                            </Pagination>
                          )}

                        <PaginationItem>
                          {
                            pageno === last ? <PaginationLink disabled>Next</PaginationLink> :
                              <PaginationLink onClick={this.handleClick} id={pageNumbers[pageno]} >Next</PaginationLink>
                          }
                        </PaginationItem>

                        <PaginationItem>
                          {
                            pageno === last ? <PaginationLink disabled>Last</PaginationLink> :
                              <PaginationLink onClick={this.handleLastClick} id={pageNumbers[pageno]} >Last</PaginationLink>
                          }
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </ul>
                }
              </div>
              {(currentlist.length == 0) &&

                <center>
                  <h3>No records found!!</h3>
                </center>

              }

            </Card>
          </Col>
        </Row>
        {/* <Modal isOpen={this.state.uploadDataModel} toggle={this.toggleModalFile}>
          <ModalHeader toggle={this.toggleModal}>
            Upload Device List File
            <Button color="primary" outline onClick={this.downloadTemplate} style={{ marginLeft: '130px' }}>
              Download Template
            </Button>
          </ModalHeader>
          <ModalBody>
            <h6>Upload .xlsx file to create the devices</h6>
            <input type="file" accept=".xlsx" onChange={this.handleFileChange} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModalFile}>Cancel</Button>
            <Button color="primary" onClick={this.createUploadDevices}>Create deviceStatus</Button>
          </ModalFooter>
        </Modal> */}
        <Dialog open={this.state.uploadDataModel} onClose={this.toggleModalFile}>
          <DialogTitle>
            Upload Device List File
            <Button className="custom-button" onClick={this.downloadTemplate} style={{ marginLeft: '130px' }}>
              Download Template
            </Button>
          </DialogTitle>
          <DialogContent>
            <h6>Upload .xlsx file to create the devices</h6>
            <input type="file" accept=".xlsx" onChange={this.handleFileChange} />
          </DialogContent>
          <DialogActions>
            <Button className="custom-button-cancel" onClick={this.toggleModalFile}>
              Cancel
            </Button>
            <Button className="custom-button" onClick={this.createUploadDevices}>
              Create deviceStatus
            </Button>
          </DialogActions>
        </Dialog>


        {/* <Modal isOpen={this.state.primary} toggle={this.togglePrimary}
          className={'modal-lg ' + this.props.className}>
          <ModalHeader toggle={this.togglePrimary}>Add New Device</ModalHeader>
          <ModalBody>
            <div id="accordion">
              <Label className="help-block" id="errorstyle">{this.state.errors}</Label>
              <Card className="mb-0">
                <CardHeader id="headingOne">
                  <Button block color="link" className="text-left m-0 p-0" onClick={() => this.toggleAccordion(0)} aria-expanded={this.state.accordion[0]} aria-controls="collapseOne">
                    <h5 className="m-0 p-0">Device Details <strong className="redfont">*</strong></h5>
                  </Button>
                </CardHeader>
                <Collapse isOpen={this.state.accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                  <CardBody>
                    <Form>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Device ID</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input type="text" name="deviceId" maxLength="50" placeholder="Enter Device ID" onChange={this.onvalueChange.bind(this, 'name')} required />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Device Name</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input type="text" name="deviceName" maxLength="50" placeholder="Enter Name" onChange={this.onvalueChange.bind(this, 'name')} required />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Device Type</Label>
                        </Col>
                        <Col xs="10" md="9">
                          <Input type="select" name="deviceType" onChange={this.onvalueChange.bind(this, 'deviceType')} required>
                            <option value="">Please Select Device Type</option>
                            {this.state.typelist.map((d, i) => {
                              return <option value={d} key={i}>{d}</option>
                            })}
                          </Input>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Device Model</Label>
                        </Col>
                        <Col xs="10" md="9">

                          <Input type="select" name="model" onChange={this.onvalueChange.bind(this, 'model')} >
                            <option value="">Please Select Model</option>
                            {this.state.modellist.map((d, i) => {
                              return <option value={d} key={i}>{d}</option>
                            })}
                          </Input>
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Collapse>
              </Card>


            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onclickdeviceupload.bind(this)} disabled={this.state.disabledstate}>Create</Button>
            <Button color="primary" onClick={this.togglePrimary}>Cancel</Button>
          </ModalFooter>
        </Modal> */}
        <Dialog open={this.state.primary} onClose={this.togglePrimary} >
          <DialogTitle>Add New Device</DialogTitle>
          <DialogContent>
            <div id="accordion">
              <Typography className="help-block" id="errorstyle">
                {this.state.errors}
              </Typography>
              <Collapse in={true} data-parent="#accordion">
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <TextField
                    type="text"
                    name="deviceId"
                    label="Device ID"
                    placeholder="Enter Device ID"
                    onChange={this.onvalueChange.bind(this, 'name')}
                    required
                    fullWidth
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <TextField
                    type="text"
                    name="deviceName"
                    label="Device Name"
                    placeholder="Enter Name"
                    onChange={this.onvalueChange.bind(this, 'name')}
                    required
                    fullWidth
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel>Device Cloud Server</InputLabel>
                  <Select
                    name="devicecloudserver"
                    label="Device Cloud Server"
                    onChange={this.onvalueChange.bind(this, 'devicecloudserver')}
                    required
                  >
                    <MenuItem value="" disabled>Select Cloud Server</MenuItem>
                    {this.state.tableData && this.state.tableData?.filter((item => item.deviceCloudServerType != '')).map((d, i) => (
                      <MenuItem value={d.deviceCloudServerType} key={i}>
                        {d.deviceCloudServerType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel>Device Type</InputLabel>
                  <Select
                    name="deviceType"
                    label="Device Type"
                    onChange={this.onvalueChange.bind(this, 'deviceType')}
                    required
                  >
                    <MenuItem value="">Please Select Device Type</MenuItem>
                    {this.state.typelist.map((d, i) => (
                      <MenuItem value={d} key={i}>
                        {d}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel>Device Model</InputLabel>
                  <Select
                    name="model"
                    label="Device Model"
                    onChange={this.onvalueChange.bind(this, 'model')}
                  >
                    <MenuItem value="">Please Select Model</MenuItem>
                    {this.state.modellist.map((d, i) => (
                      <MenuItem value={d} key={i}>
                        {d}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Collapse>
            </div>
          </DialogContent>
          <DialogActions>
            <Button className="custom-button" onClick={this.onclickdeviceupload.bind(this)} disabled={this.state.disabledstate}>
              Create
            </Button>
            <Button className="custom-button-cancel" onClick={this.togglePrimary}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Modal isOpen={this.state.editmodal} toggle={this.toggleeditdevicemodel.bind(this, '')}
          className={'modal-lg ' + this.props.className}>
          <ModalHeader toggle={this.toggleeditdevicemodel.bind(this, '')}>Edit Device Details</ModalHeader>
          <ModalBody>
            <div id="accordion">
              <Label className="help-block" id="errorstyle">{this.state.errors}</Label>
              <Card className="mb-0">
                <CardHeader id="headingOne">
                  <Button block color="link" className="text-left m-0 p-0" onClick={() => this.toggleAccordion(0)} aria-expanded={this.state.accordion[0]} aria-controls="collapseOne">
                    <h5 className="m-0 p-0">Device Details <strong className="redfont">*</strong></h5>
                  </Button>
                </CardHeader>
                <Collapse isOpen={this.state.accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                  <CardBody>
                    <Form>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Device ID</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input
                            type="text"
                            maxLength="50"
                            name="deviceId"
                            placeholder="Enter Device ID"
                            onChange={this.onvalueChange.bind(this, 'name')}
                            value={this.state.deviceuploaddata.id}
                            disabled
                            required />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Device Name</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input
                            maxLength="50"
                            type="text"
                            name="deviceName"
                            placeholder="Enter Name"
                            onChange={this.onvalueChange.bind(this, 'name')} required
                            value={this.state.deviceuploaddata.deviceName} />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Device Type</Label>
                        </Col>
                        <Col xs="10" md="9">
                          <Input type="select" name="deviceType" value={this.state.deviceuploaddata.deviceType} onChange={this.onvalueChange.bind(this, 'deviceType')} required>
                            <option value="">Please Select Device Type</option>
                            {this.state.typelist.map((d, i) => {
                              return <option value={d} key={i}>{d}</option>
                            })}
                          </Input>
                        </Col>
                      </FormGroup>


                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Device Model</Label>
                        </Col>
                        <Col xs="10" md="9">

                          <Input type="select" name="model" value={this.state.deviceuploaddata.model} onChange={this.onvalueChange.bind(this, 'model')} >
                            <option value="">Please Select Model</option>
                            {this.state.modellist.map((d, i) => {
                              return <option value={d} key={i}>{d}</option>
                            })}
                          </Input>
                        </Col>
                      </FormGroup>




                    </Form>
                  </CardBody>
                </Collapse>
              </Card>


            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" id="editsave" onClick={this.onclickeditdeviceupload.bind(this)} disabled={this.state.disabledstate} >Save</Button>
            <Button color="secondary" onClick={this.toggleeditdevicemodel.bind(this, '')}>Cancel</Button>
          </ModalFooter>
        </Modal> */}
        <Dialog open={this.state.editmodal} onClose={this.toggleeditdevicemodel.bind(this, '')} fullWidth maxWidth="md">
          <DialogTitle>Edit Device Details</DialogTitle>
          <DialogContent>
            <div id="accordion">
              <Typography className="help-block" id="errorstyle">
                {this.state.errors}
              </Typography>
              <Collapse in={true} data-parent="#accordion">
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <TextField
                    type="text"
                    name="deviceId"
                    label="Device ID"
                    value={this.state.deviceuploaddata.id}
                    disabled
                    required
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <TextField
                    type="text"
                    name="deviceName"
                    label="Device Name"
                    placeholder="Enter Name"
                    onChange={this.onvalueChange.bind(this, 'name')}
                    required
                    value={this.state.deviceuploaddata.deviceName}
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel>Device Type</InputLabel>
                  <Select
                    name="deviceType"
                    label="Device Type"
                    value={this.state.deviceuploaddata.deviceType}
                    onChange={this.onvalueChange.bind(this, 'deviceType')}
                    required
                  >
                    <MenuItem value="">Please Select Device Type</MenuItem>
                    {this.state.typelist.map((d, i) => (
                      <MenuItem value={d} key={i}>
                        {d}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel>Device Model</InputLabel>
                  <Select
                    name="model"
                    label="Device Model"
                    value={this.state.deviceuploaddata.model}
                    onChange={this.onvalueChange.bind(this, 'model')}
                  >
                    <MenuItem value="">Please Select Model</MenuItem>
                    {this.state.modellist.map((d, i) => (
                      <MenuItem value={d} key={i}>
                        {d}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Collapse>
            </div>
          </DialogContent>
          <DialogActions>
            <Button className="custom-button" id="editsave" onClick={this.onclickeditdeviceupload.bind(this)} disabled={this.state.disabledstate}>
              Save
            </Button>
            <Button className="custom-button-cancel" onClick={this.toggleeditdevicemodel.bind(this, '')}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>


        {/* <Modal isOpen={this.state.danger} toggle={this.toggledeviceDelete}
          className={'modal-danger ' + this.props.className}>
          <ModalHeader toggle={this.toggledeviceDelete}>Delete Device</ModalHeader>
          <ModalBody>
            <strong>Are you sure, You want to delete this Device ?</strong>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.onclickdelete.bind(this)}>Delete</Button>{' '}
            <Button color="secondary" onClick={this.toggledeviceDelete}>Cancel</Button>
          </ModalFooter>
        </Modal> */}

        <Dialog open={this.state.danger} onClose={this.toggledeviceDelete}>
          <DialogTitle>Delete Device Type</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              <strong>Are you sure you want to delete this Device Type?</strong>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onclickdelete.bind(this)} className="custom-button" style={{ backgroundColor: "red" }}>
              Delete
            </Button>
            <Button onClick={this.toggledeviceDelete} className="custom-button-cancel">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }

}

export default withRouter(Device);
