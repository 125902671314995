import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";

export default function FFSSection() {
  return (
    <>
        <div className="subSectionWrapper">
      <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="cbo-advisory-img"
        descriptionTitle="
       Advisory "
        description="Advisory is a vital service offered by Community-Based Organizations (CBOs) aimed at providing invaluable guidance on animal, plant, and farm health to agricultural producers. Through expert advice and support, these organizations assist farmers in optimizing their agricultural practices, ensuring the well-being of their livestock, crops, and overall farm productivity. "
      />

      <HomeSubSection
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="cbo-buy-inputs-img"
        descriptionTitle="
       Buy-Input "
        description="Buy-input is a valuable service offered by Community-Based Organizations (CBOs), enabling agri-producers to access essential agricultural inputs. These inputs, ranging from seeds and fertilizers to equipment and tools, are provided by the CBOs, facilitating farmers' access to necessary resources for their farming operations. This service contributes to improving agricultural productivity and sustainability while supporting the livelihoods of farmers in rural communities.




        "
      />

      <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="cbo-price-discovery-img"
        descriptionTitle="
       Price-Discovery "
        description="Price-Discovery is a pivotal service offered by Community-Based Organizations (CBOs) to agri-producers, where they receive timely information about the best market prices for their agricultural products. By providing transparent and up-to-date market data, CBOs empower farmers to make informed decisions regarding the sale of their produce, maximizing their profitability and enhancing market access. This service plays a crucial role in fostering fair trade practices and strengthening the economic resilience of agricultural communities.   "
      />

      <HomeSubSection
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="cbo-site-updates-img"
        descriptionTitle="
       Site-Updates "
        description="Site-Updates is a valuable service offered by Community-Based Organizations (CBOs) to agri-producers, providing them with regular updates about their agricultural sites. These updates may include information on weather conditions, crop status, pest and disease outbreaks, and relevant farming practices. By staying informed through these updates, farmers can better manage their operations, mitigate risks, and optimize their yields, ultimately contributing to the sustainability and success of their agricultural ventures.   "
      />

      <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="cbo-jobs-img"
        descriptionTitle="
       Jobs "
        description="Jobs is a crucial service provided by Community-Based Organizations (CBOs), where they list job openings and employment opportunities relevant to the local agricultural community. By connecting job seekers with available positions in farming, agribusiness, and related sectors, CBOs play a vital role in facilitating employment and supporting livelihoods in rural areas. This service fosters economic growth, strengthens local agricultural networks, and helps address unemployment challenges within farming communities.  "
      />

      <HomeSubSection
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="cbo-advertisement-img"
        descriptionTitle="
       Advertisment "
        description="Advertisement is an essential service provided by Community-Based Organizations (CBOs), enabling them to promote farm machinery products to agricultural communities. Through targeted advertising campaigns, CBOs showcase the latest advancements in agricultural technology, including tractors, harvesters, and irrigation systems, among others. By facilitating access to information about these products, CBOs empower farmers to make informed decisions about adopting modern equipment, ultimately enhancing efficiency, productivity, and sustainability in farming practices.  "
      />

      <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="cbo-club-updates-img"
        descriptionTitle="
       Club-Updates "
        description="Club-Updates is a valuable service provided by Community-Based Organizations (CBOs) to agri-producers, offering regular updates and information related to agricultural clubs. These updates may include announcements about club meetings, events, workshops, training sessions, and relevant agricultural resources. By staying informed through Club-Updates, agri-producers can actively engage with their local agricultural community, network with peers, access valuable educational opportunities, and stay updated on industry trends and best practices, ultimately enhancing their farming knowledge and skills.   "
      />

      <HomeSubSection
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="cbo-branding-img"
        descriptionTitle="
       Branding "
        description="Branding is a vital service offered by Community-Based Organizations (CBOs) to agri-producers, aimed at enhancing their visibility and recognition in the market. Through strategic branding initiatives, CBOs assist agri-producers in developing unique identities, logos, packaging designs, and marketing materials that effectively represent their products and values. By establishing strong and memorable brands, agri-producers can differentiate themselves from competitors, build consumer trust, and expand their market reach, ultimately contributing to their business success and sustainability. "
      />

      <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="cbo-project-report-img"
        descriptionTitle="
       Project-Report "
        description="Project Report is a critical service provided by Community-Based Organizations (CBOs) on behalf of agri-producers, aimed at presenting comprehensive reports to banks or financial institutions. These reports outline the details of agricultural projects, including feasibility studies, financial projections, risk assessments, and potential impacts. By preparing well-structured and informative project reports, CBOs help agri-producers secure funding and loans for their agricultural ventures, facilitating access to capital and supporting the growth and development of the agricultural sector.     "
      />

      <HomeSubSection
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="cbo-pitch-deck-img"
        descriptionTitle="
       Pitch-Deck "
        description="  Pitch Deck is a service provided by Community-Based Organizations (CBOs) to raise investment pitches on behalf of agri-producers. These decks typically include key information about the agricultural project, such as its purpose, market analysis, revenue model, team members, and financial projections. By crafting compelling pitch decks, CBOs help agri-producers attract potential investors, secure funding, and garner support for their agricultural initiatives, ultimately fostering growth and innovation within the agricultural sector."
      />

      <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="cbo-tenders-img"
        descriptionTitle="
       Tenders "
        description="Tenders is a service offered by Community-Based Organizations (CBOs) where they create and publish tender opportunities relevant to agri-producers. These tenders may include contracts for supplying agricultural products, services, or equipment, as well as opportunities for agricultural projects or initiatives. By facilitating access to tender opportunities, CBOs enable agri-producers to participate in procurement processes, compete for contracts, and expand their business opportunities within the agricultural sector. This service helps to foster economic growth and development while supporting the livelihoods of farmers and agricultural businesses.    "
      />
      </div>
    </>
  );
}
