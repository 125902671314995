import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
  },
  title: {
    fontSize: 14,
  },
  link: {
    textDecoration: "none",
    padding: "1rem",
    color: "#3ECF8E", // Adjust color as needed
    fontSize: "600",
  },
}));

const ViewVendors = () => {
  const classes = useStyles();
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getPMCUsers, setPMCUsers] = useState([]);
  const [foodProcessors, setFoodProcessors] = useState([]);
  const [assetDeveloper, setAssetDeveloper] = useState([]);

  const getAllMachineProviders = () => {
    MainService.getGlobalUsers("PMC")
      .then((data) => {
        setPMCUsers(data);
      })
      .catch((e) => {
        return NotificationManager.error("something went wrong");
      });
  };

  const getAllFoodProcessors = () => {
    MainService.getGlobalUsers("FOOD-PROCESSOR")
      .then((data) => {
        console.log("'FOOD-PROCESSOR'", data);
        setFoodProcessors(data);
      })
      .catch((e) => {
        return NotificationManager.error("something went wrong");
      });
  };

  const getAllAssetDeveloper = () => {
    MainService.getGlobalUsers("AssetDeveloper")
      .then((data) => {
        setAssetDeveloper(data);
      })
      .catch((e) => {
        return NotificationManager.error("something went wrong");
      });
  };

  useEffect(() => {
    getAllMachineProviders();
    getAllFoodProcessors();
    getAllAssetDeveloper();
  }, [setPMCUsers, setFoodProcessors]);

  const renderCardContent = (title, data) => (
    <CardContent>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h5" component="h2">
        {data?.length}
      </Typography>
    </CardContent>
  );

  return (
    <>
      <Card sx={{ mb: "1rem" }}>
        <Box sx={{ p: 2, display: "flex" }}>
          <Grid container spacing={0}>
            <Grid item md={12} xs={12}>
              <Grid container className={classes.gridRoot} spacing={2}>
                <Grid item md={4} xs={12}>
                  <Card className={classes.root} variant="outlined">
                    {renderCardContent(
                      "TOTAL PMC MACHINE PROVIDERS",
                      getPMCUsers
                    )}
                    <Link to={`/main/vendorslist/PMC`} className={classes.link}>
                      View Vendors
                    </Link>
                  </Card>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Card className={classes.root} variant="outlined">
                    {renderCardContent("TOTAL FOOD PROCESSORS", foodProcessors)}
                    <Link
                      to={`/main/vendorslist/FOOD-PROCESSOR`}
                      className={classes.link}
                    >
                      View Vendors
                    </Link>
                  </Card>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Card className={classes.root} variant="outlined">
                    {renderCardContent(
                      "TOTAL ASSET DEVELOPERS",
                      assetDeveloper
                    )}
                    <Link
                      to={`/main/vendorslist/AssetDeveloper`}
                      className={classes.link}
                    >
                      View Vendors
                    </Link>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};
export default ViewVendors;
