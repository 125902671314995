import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Gateway from './Gateway';
import Overview from './Overview';
import ViewProperty from './ViewProperty';
import MultiLiveData from './ViewMultiProperty';
import CompareMultiData from './CompareMultiProperty';
import CompareProperty from './CompareProperty';
import NotificationSupport from './NotificationSupport'
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const RoleBasedTabs = [
    {
        NULL: [{
            label: "Property",
            component: <Gateway />,
            index: 0
        }
        ],
        BASIC: [{
            label: "Property",
            component: <Gateway />,
            index: 0
        }
        ],
        STANDARD:[{
            label: "Property",
            component: <Gateway />,
            index: 0
        },
        {
            label: "History",
            component: <Overview />,
            index: 1
        },
        {
            label: "View Live Data",
            component: <ViewProperty />,
            index: 2
        },
        {
            label: "Notification Support",
            component: <NotificationSupport />,
            index: 3
        }
        ],
        PREFFERED:[{
            label: "Property",
            component: <Gateway />,
            index: 0
        },
        {
            label: "History",
            component: <Overview />,
            index: 1
        },
        {
            label: "View Live Data",
            component: <ViewProperty />,
            index: 2
        },
        {
            label: "Multiple Live Data",
            component: <MultiLiveData />,
            index: 3
        },
        {
            label: "Compare Multi Data",
            component: <CompareMultiData />,
            index: 4
        },
        {
            label: "Compare Live Data",
            component: <CompareProperty />,
            index: 5
        },
        {
            label: "Notification Support",
            component: <NotificationSupport />,
            index: 6
        }
        ],
        PREMIUM: [{
            label: "Property",
            component: <Gateway />,
            index: 0
        },
        {
            label: "History",
            component: <Overview />,
            index: 1
        },
        {
            label: "View Live Data",
            component: <ViewProperty />,
            index: 2
        },
        {
            label: "Multiple Live Data",
            component: <MultiLiveData />,
            index: 3
        },
        {
            label: "Compare Multi Data",
            component: <CompareMultiData />,
            index: 4
        },
        {
            label: "Compare Live Data",
            component: <CompareProperty />,
            index: 5
        },
        {
            label: "Notification Support",
            component: <NotificationSupport />,
            index: 6
        }
        ],
        ADMIN:[{
            label: "Property",
            component: <Gateway />,
            index: 0
        },
        {
            label: "History",
            component: <Overview />,
            index: 1
        },
        {
            label: "View Live Data",
            component: <ViewProperty />,
            index: 2
        },
        {
            label: "Multiple Live Data",
            component: <MultiLiveData />,
            index: 3
        },
        {
            label: "Compare Multi Data",
            component: <CompareMultiData />,
            index: 4
        },
        {
            label: "Compare Live Data",
            component: <CompareProperty />,
            index: 5
        },
        {
            label: "Notification Support",
            component: <NotificationSupport />,
            index: 6
        }
        ]
    }
]

const defaultTabValues = [{
    label: "Property",
    component: <Gateway />,
    index: 0
},
{
    label: "History",
    component: <Overview />,
    index: 1
},
{
    label: "View Live Data",
    component: <ViewProperty />,
    index: 2
},
{
    label: "Multiple Live Data",
    component: <MultiLiveData />,
    index: 3
},
{
    label: "Compare Multi Data",
    component: <CompareMultiData />,
    index: 4
},
{
    label: "Compare Live Data",
    component: <CompareProperty />,
    index: 5
},
{
    label: "Notification Support",
    component: <NotificationSupport />,
    index: 6
}
]

const InsightManagement = () => {
    const [value, setValue] = React.useState(0);
    const Role = localStorage.getItem("UserRole");


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            {Role && <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {RoleBasedTabs[0][Role]?.map((item) => (
                            <Tab key={item.index} label={item.label} {...a11yProps(item.index)} />
                        ))}
                    </Tabs>
                </Box>

                {RoleBasedTabs[0][Role].map((item) => (
                    <CustomTabPanel key={item.index} value={value} index={item.index}>
                        {item.component}
                    </CustomTabPanel>
                ))}
            </Box>}
            {!Role && <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {defaultTabValues?.map((item) => (
                            <Tab key={item.index} label={item.label} {...a11yProps(item.index)} />
                        ))}
                    </Tabs>
                </Box>

                {defaultTabValues.map((item) => (
                    <CustomTabPanel key={item.index} value={value} index={item.index}>
                        {item.component}
                    </CustomTabPanel>
                ))}
            </Box>}
        </>
    )
}

export default InsightManagement