import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { memo } from "react";
import "../../AssetDeveloperRole/Market/Market.css";
import { Capitalize } from "./ContentedRelatedCommonFun";

const ContentDynamicCard = ({ dynamicKey, data, actions }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly", //left
          gap: "1rem",
          flexDirection: "row",
          minWidth: "100%", // Added this line to set minimum width to 100%
        }}
      >
        {Array.isArray(data) && data?.length > 0 ? (
          data?.map((item, index) => (
            <Box
              key={index}
              sx={{
                minWidth: "45%", // Adjust as needed
                maxWidth: {
                  sx: "100%",
                  sm: "100%",
                  md: "45%",
                  lg: "35%",
                  xl: "35%",
                }, // Adjust as needed
                borderRadius: "10px",
                padding: "10px",
                margin: "10px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                textAlign: "center",
              }}
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {dynamicKey?.map((key) => (
                      <TableRow key={key}>
                        <TableCell
                          style={{ fontWeight: 600, color: "#3c8a67" }}
                        >
                          {Capitalize(key)}
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: 500, fontSize: "15px" }}
                        >
                          {key === "locationurl" ? (
                            <a
                              href={item[key]}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Download
                            </a>
                          ) : (
                            item[key]
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {(() => {
                if (actions && actions?.length > 0) {
                  return (
                    <Box className="buttonBox">
                      {actions?.map((action, actionIndex) => (
                        <Button
                          variant="outlined"
                          color={
                            action.label === "Delete" ? "error" : "primary"
                          }
                          key={actionIndex}
                          className="buttonsize"
                          type="button"
                          onClick={() => action.click(item)}
                        >
                          {action.label}
                        </Button>
                      ))}
                    </Box>
                  );
                }
              })()}
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              gap: "1rem",
              flexDirection: "row",
              minWidth: "100%", // Added this line to set minimum width to 100%
            }}
          >
            <Box
              sx={{
                maxWidth: "45%", // Adjust as needed
                borderRadius: "10px",
                padding: "10px",
                margin: "10px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                textAlign: "center",
              }}
            >
              No Data Found
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default memo(ContentDynamicCard);
