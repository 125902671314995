import React from "react";

const VehicleList = ({ assetIds }) => {
  console.log("Received Asset IDs:", assetIds); 


  const openVehicleInNewTab = (assetId) => {
    const url = `/main/brokerLiveMonitoring`; 
    window.open(url, "_blank");
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "40%", 
        left: "10px", 
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "5px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
        zIndex: 1000, 
      }}
    >
      <h2>Vehicle IDs</h2>
      <ul>
        {assetIds.map((assetId) => (
          <li key={assetId} style={{cursor:'pointer'}} onClick={() => openVehicleInNewTab(assetId)}>{assetId}</li>
        ))}
      </ul>
    </div>
  );
};

export default VehicleList;
