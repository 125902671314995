import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import AgriEnrepenurJourney from "../AgriEnrepenurJourney/AgriEnrepenurJourney";
import CTA from "../CTA/CTA";
import MoneyMakingAssets from "../ForFarmers/MoneyMakingAssets";

export default function ForAgriEntreprenuer() {
  return (
    <>
      <UpdatesBanner
        className="agriEntrepreneur-bg"
        bannerText={[
          "Want to become an Production manager to do Contract Manufacturing ",
          <br className="d-lg-block d-none"></br>,
          "& to manage growers and assets ",
        ]}
        buttonText="Start Earning Now"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "Production-Manager");
        }}

        DlBtn ="true"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.aamcapp"
        DownloadText="Download App"
      />

      <Container>
        <TitleDescription
        classname="mb-0"
        classname2="mb-0"
          title="Discovering Production Manager"
          description='GBR empowers agri-producers by transforming them into production managers who take charge of both contract manufacturing and the cultivation and management of growers and farm sites. This innovative approach not only enables agri-producers to optimize their existing resources but also equips them with the skills and capabilities to oversee external manufacturing contracts. By turning agri-producers into production managers, GBR fosters self-sufficiency and expertise within the agricultural ecosystem, creating a more efficient and sustainable farming industry.' />
        <MoneyMakingAssets/>

        <AgriEnrepenurJourney />

        <div className=" subSectionWrapper">
          <CTA
            onClick={() => {
              localStorage.setItem("userType", "Production-Manager");
            }}
            href="/auth/3/signup"
            heading="Elevate Your Agricultural Expertise Join Us as a Production Manager!"
            btnText="Register Now"
            
          />
        </div>
      </Container>
    </>
  );
}
