import { Grid, Button } from "@material-ui/core";
import React, { useState } from "react";
import TechnologyModal from "../technologyModal/TechnologyModal";
import "./ServiceTypes.css";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

export default function ServiceTypes({
  id,
  src,
  alt,
  title,
  description1,
  href,
  description2,
  target,
  description3,
  description4,
  button,
  disabledbtnClass,
  buttonCnt,
  href3,button3Cnt
}) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="service-type-container">
        <TechnologyModal
          href={href}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
        <Grid container id={id} justifyContent="center" spacing={0}>
          <Grid item md={8} sm={8} xs={12} className="contentContainer">
            <img src={src} alt={alt} className="section-icon section-icon-bg" />
            <h4 className="container-heading1">{title}</h4>
            <p
              className={`${
                description2 && !description3 ? "mb-3" : "mb-0"
              } container-para`}
            >
              {description1}
            </p>

            {description3 && (
              <p className="container-para mb-3">{description3}</p>
            )}
            {description4 && (
              <p className="container-para mb-3">{description2}</p>
            )}
            {description2 && <p className="container-para">{description2}</p>}
            {href && !href3 ? (
              <>
                {" "}
                {description2 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod"
                    type="button"
                    onClick={() => setShowModal(true)}
                  >
                    View APIs
                  </Button>
                ) : (
                  <>
                    {!button && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={`${
                          disabledbtnClass && disabledbtnClass
                            ? "registerBtn--mod disabledBtn"
                            : "registerBtn--mod "
                        }   `}
                        type="button"
                      >
                        <HashLink target={target ? "_blank" : ""} to={href}>
                        {buttonCnt ? buttonCnt: "REQUEST A/C" } 
                        </HashLink>
                      </Button>
                    )}
                  </>
                )}
              </>
            ):<Button variant="contained"
            color="primary"
            className="registerBtn--mod"
            type="button">
              <NavLink to ={href3} >{button3Cnt}</NavLink></Button>}

            {href && (
              <>
                {!button && <p className="section-inner-border">_________</p>}
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
