import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@mui/material";
import sha256 from "sha256";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

export default function AgentRequestsComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [registerUserRequestsList, setRegisterUserRequestsList] = useState([]);
  const [consumerUserUserDomainName, setConsumerUserUserDomainName] =
    useState("");
  const [consumerUserUserType, setConsumerUserUserType] = useState("");
  const [consumerUserPwd, setConsumerUserPwd] = useState("unaas");
  const [consumerUserUserName, setConsumerUserUserName] = useState("");
  const [consumerUserAddress, setConsumerUserAddress] = useState("");
  const [consumerUserCity, setConsumerUserCity] = useState("");
  const [consumerUserFirstName, setConsumerUserFirstName] = useState("");
  const [consumerUserLastName, setConsumerUserLastName] = useState("");
  const [consumerUserPhone, setConsumerUserPhone] = useState("");
  const [consumerUserEmail, setConsumerUserEmail] = useState("");
  const [consumerUserState, setConsumerUserState] = useState("");
  const [consumerUserNominee, setConsumerUserNominee] = useState("");
  const [consumerUserPancard, setConsumerUserPancard] = useState("");
  const [consumerUserLongitude, setConsumerUserLongitude] = useState("");
  const [consumerUserLatitude, setConsumerUserLatitude] = useState("");
  const [consumerUserMessage, setConsumerUserMessage] = useState("");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [requestInfoDialog, setRequestInfoDialog] = useState(false);
  const [requestInfoObj, setRequestInfoObj] = useState({});

  const [selectedConsumerUserId, setSelectedConsumerUserId] = useState("");
  const [selectedRequestId, setSelectedRequestId] = useState("");
  const [selectedConsumerUserName, setSelectedConsumerUserName] = useState("");
  const [consumerUserDeleteDialog, setConsumerUserDeleteDialog] =
    useState(false);
  const [localConsumerUserCreateDialog, setLocalConsumerUserCreateDialog] =
    useState(false);

  const [globalConsumerUserCreateDialog, setGlobalConsumerUserCreateDialog] =
    useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [
    filteredRegisterUserRequestsList,
    setFilteredRegisterUserRequestsList,
  ] = useState([]);

  const [searched, setSearched] = useState("");
  const [listRequetsView, setListRequetsView] = useState(false);
  const [gridRequetsView, setGridRequetsView] = useState(true);

  useEffect(() => {
    getAllRegisterAccountsRequests();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* view All Register Account Requests */
  const getAllRegisterAccountsRequests = () => {
    setIsLoading(true);
    MainService.getAgentRequests(props.AgentName)
      .then((res) => {
        if (res?.length > 0) {
          let consumerUsers;
          if (props.networkType === "AAMC") {
            if (res.length > 0) {
              consumerUsers = res.filter((row) => {
                return (
                  (row.usertype === "Operator" ||
                  row.usertype === "DeveloperQA" ||
                  row.usertype === "Grower" ||
                  row.usertype === "Executive" ||
                  row.usertype === "Production-Manager" ||
                  row.usertype === "FF" ||
                  row.usertype === "Agent")&& (row.requesttype === "accountCreation")
                );
              });
              setRegisterUserRequestsList(consumerUsers);
              setFilteredRegisterUserRequestsList(consumerUsers);
              setIsLoading(false);
            } else {
              setRegisterUserRequestsList([]);
              setFilteredRegisterUserRequestsList([]);
              setIsLoading(false);
            }
          } else if (props.networkType === "CBN") {
            if (res?.length > 0) {
              consumerUsers = res.filter((row) => {
                return ((
                  row.usertype === "Farmer" ||
                  row.usertype === "SHG" ||
                  row.usertype === "FPO" ||
                  row.usertype === "CS" ||
                  row.usertype === "NGO" ||
                  row.usertype === "Trader" ||
                  row.usertype === "Trainer")&& (row.requesttype === "accountCreation")
                );
              });
              setRegisterUserRequestsList(consumerUsers);
              setFilteredRegisterUserRequestsList(consumerUsers);
              setIsLoading(false);
            } else {
              setRegisterUserRequestsList([]);
              setFilteredRegisterUserRequestsList([]);
              setIsLoading(false);
            }
          } else if (props.networkType === "DSN") {
            if (res?.length > 0) {
              consumerUsers = res.filter((row) => {
                return ((row.usertype === "StoreOwner")&& (row.requesttype === "accountCreation"));
              });
              setRegisterUserRequestsList(consumerUsers);
              setFilteredRegisterUserRequestsList(consumerUsers);
              setIsLoading(false);
            } else {
              setRegisterUserRequestsList([]);
              setFilteredRegisterUserRequestsList([]);
              setIsLoading(false);
            }
          } else if (props.networkType === "MN") {
            if (res?.length > 0) {
              consumerUsers = res.filter((row) => {
                return ((row.usertype === "MachimeryTechnician")&& (row.requesttype === "accountCreation"));
              });
              setRegisterUserRequestsList(consumerUsers);
              setFilteredRegisterUserRequestsList(consumerUsers);
              setIsLoading(false);
            } else {
              setRegisterUserRequestsList([]);
              setFilteredRegisterUserRequestsList([]);
              setIsLoading(false);
            }
          } else if (props.networkType === "ACN") {
            if (res?.length > 0) {
              consumerUsers = res.filter((row) => {
                return ((
                  row.usertype === "DepotOwner" ||
                  row.usertype === "Logistics-Manager" ||
                  row.usertype === "Logistics-TransportUser")&& (row.requesttype === "accountCreation")
                );
              });
              setRegisterUserRequestsList(consumerUsers);
              setFilteredRegisterUserRequestsList(consumerUsers);
              setIsLoading(false);
            } else {
              setRegisterUserRequestsList([]);
              setFilteredRegisterUserRequestsList([]);
              setIsLoading(false);
            }
          } else if (props.networkType === "SN") {
            if (res?.length > 0) {
              consumerUsers = res.filter((row) => {
                return ((row.usertype === "StorageOwner")&& (row.requesttype === "accountCreation"));
              });
              setRegisterUserRequestsList(consumerUsers);
              setFilteredRegisterUserRequestsList(consumerUsers);
              setIsLoading(false);
            } else {
              setRegisterUserRequestsList([]);
              setFilteredRegisterUserRequestsList([]);
              setIsLoading(false);
            }
          } else if (props.networkType === "PPN") {
            if (res?.length > 0) {
              consumerUsers = res.filter((row) => {
                return ((row.usertype === "PlantOwner")&& (row.requesttype === "accountCreation"));
              });
              setRegisterUserRequestsList(consumerUsers);
              setFilteredRegisterUserRequestsList(consumerUsers);
              setIsLoading(false);
            } else {
              setRegisterUserRequestsList([]);
              setFilteredRegisterUserRequestsList([]);
              setIsLoading(false);
            }
          } else if (props.networkType === "Global") {
            if (res?.length > 0) {
              consumerUsers = res.filter((row) => {
                return ((
                  row.usertype === "AssetDeveloper" ||
                  row.usertype === "CBO" ||
                  row.usertype === "FMCDealer" ||
                  row.usertype === "PMCDealer" ||
                  row.usertype === "OFC" ||
                  row.usertype === "FMC" ||
                  row.usertype === "PMC" ||
                  row.usertype === "FOOD-PROCESSOR")&& (row.requesttype === "accountCreation")
                );
              });
              setRegisterUserRequestsList(consumerUsers);
              setFilteredRegisterUserRequestsList(consumerUsers);
              setIsLoading(false);
            } else {
              setRegisterUserRequestsList([]);
              setFilteredRegisterUserRequestsList([]);
              setIsLoading(false);
            }
          }else if (props.networkType === "ConsumerIAM") {
            if (res?.length > 0) {
              consumerUsers = res.filter((row) => {
                return ( row.requesttype === "accountCreation");
              });
              setRegisterUserRequestsList(consumerUsers);
              setFilteredRegisterUserRequestsList(consumerUsers);
              setIsLoading(false);
            }else
            {
              setRegisterUserRequestsList([]);
              setFilteredRegisterUserRequestsList([]);
              setIsLoading(false);
            }
            
          }
         else {
          if (res?.length > 0) {
            consumerUsers = res.filter((row) => {
              return ( row.requesttype === "accountCreation");
            });
            setRegisterUserRequestsList(consumerUsers);
            setFilteredRegisterUserRequestsList(consumerUsers);
            setIsLoading(false);
          }else
          {
            setRegisterUserRequestsList([]);
            setFilteredRegisterUserRequestsList([]);
            setIsLoading(false);
          }
          
        }
        } else {
          setIsLoading(false);
          setRegisterUserRequestsList([]);
          setFilteredRegisterUserRequestsList([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End of All Register Account Requests */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  /* consumer IAM */

  const createLocalUserSubmit = () => {
    let consumerUserData = {
      username: consumerUserUserName,
      firstname: consumerUserFirstName,
      lastname: consumerUserLastName,
      domain: consumerUserUserDomainName,
      usertype: consumerUserUserType,
      phone: consumerUserPhone,
      address: consumerUserAddress?.replace(/\n/g, ""),
      city: consumerUserCity,
      state: consumerUserState,
      nominee: consumerUserNominee,
      pancard: consumerUserPancard,
      longitude: consumerUserLongitude,
      latitude: consumerUserLatitude,
      message: consumerUserMessage?.replace(/\n/g, ""),
      email: consumerUserEmail,
    };
    const consumerUserDataString = JSON.stringify(consumerUserData);
    setIsLoading(true);
    MainService.createConsumerUser(
      consumerUserDataString,
      consumerUserUserDomainName,
      consumerUserUserName,
      consumerUserEmail
    )
      .then((res) => {
        setIsLoading(false);
        getAllRegisterAccountsRequests();
        return NotificationManager.success("user created Successfully");
      })
      .catch((err) => {
        setIsLoading(false);
        return NotificationManager.error(err);
      });

    setLocalConsumerUserCreateDialog(!localConsumerUserCreateDialog);
  };

  const createGlobalUsersubmit = () => {
    const encPwd = sha256(consumerUserPwd);
    let consumerUserData = {
      username: consumerUserUserName,
      firstname: consumerUserFirstName,
      lastname: consumerUserLastName,
      usertype: consumerUserUserType,
      phone: consumerUserPhone,
      email: consumerUserEmail,
      password: encPwd,
    };

    setIsLoading(true);
    MainService.doSignup(consumerUserData)
      .then((res) => {
        setIsLoading(false);
        getAllRegisterAccountsRequests();
        return NotificationManager.success("user created Successfully");
      })
      .catch((err) => {
        setIsLoading(false);
        return NotificationManager.error(err);
      });

    setGlobalConsumerUserCreateDialog(!globalConsumerUserCreateDialog);
  };

  const consumerUserCreateClick = (requestId) => {
    getRequestInfo(requestId);

    if (props.networkType === "Global") {
      setConsumerUserUserDomainName(parentId);
      setGlobalConsumerUserCreateDialog(!globalConsumerUserCreateDialog);
    } else {
      setConsumerUserUserDomainName(username);
      setLocalConsumerUserCreateDialog(!localConsumerUserCreateDialog);
    }
  };

  const rejectDialogClick = (consumerUserName, consumerUserId, requestId) => {
    setSelectedConsumerUserName(consumerUserName);
    setSelectedConsumerUserId(consumerUserId);
    setSelectedRequestId(requestId);
    setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
  };
  const rejectSubmitClick = () => {
    let data = {
      requestid: selectedRequestId,
      status: "rejected",
    };
    MainService.updateRegistrationRequestStatus(data,parentId)
      .then((res) => {
        getAllRegisterAccountsRequests();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
  };

  const listUsersViewClick = () => {
    setListRequetsView(true);
    setGridRequetsView(false);
  };
  const gridRequetsViewClick = () => {
    setListRequetsView(false);
    setGridRequetsView(true);
  };

  const requestSearchForUserRequests = (searchedVal) => {
    const filteredRows = filteredRegisterUserRequestsList.filter((row) => {
      return row.usertype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRegisterUserRequestsList(filteredRows);
  };
  const cancelSearchForUserRequests = () => {
    setSearched("");
    requestSearchForUserRequests(searched);
  };

  const getRequestInfo = (requestId) => {
    MainService.requestInfoBasedonRequestid(parentId, requestId)
      .then((res) => {
        if (res) {
          setRequestInfoObj(res);
          setConsumerUserUserName(res.username);
          setConsumerUserFirstName(res.firstname);
          setConsumerUserLastName(res.lastname);
          setConsumerUserEmail(res.email);
          setConsumerUserPhone(res.phone);
          setConsumerUserUserType(res.usertype);
        } else {
          setRequestInfoObj({});
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const requestInfoClick = (requestId) => {
    getRequestInfo(requestId);

    setRequestInfoDialog(!requestInfoDialog);
  };

  const renderKeyValue = (key, value) => {
    if (typeof value === 'object' && value !== null) {
      return (
        <div key={key}>
          <strong>{key}:</strong>
          <div style={{ marginLeft: '20px' }}>
            {Object.entries(value).map(([nestedKey, nestedValue]) =>
              renderKeyValue(nestedKey, nestedValue)
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div key={key}>
          <strong>{key}:</strong> {JSON.stringify(value)}
        </div>
      );
    }
  };
  
  return (
    <div className="container" id="mainContentList">
      <Box sx={{ width: "100%", marginBottom: 30 }}>
        {(listRequetsView === true || gridRequetsView === true) && (
          <Grid>
            <div style={{ float: "right" }}>
              <Button
                startIcon={<ListIcon />}
                size="large"
                onClick={listUsersViewClick}
              ></Button>
              <Button
                startIcon={<GridIcon />}
                size="large"
                onClick={gridRequetsViewClick}
              ></Button>
            </div>
          </Grid>
        )}

        <br />
        <Grid container spacing={1}>
          {listRequetsView && (
            <Paper sx={{ width: "100%" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal) =>
                  requestSearchForUserRequests(searchVal)
                }
                onCancelSearch={() => cancelSearchForUserRequests()}
              />
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell
                        className="headerFontSize"
                        align="center"
                        colSpan={11}
                      >
                        List of Register User Requests
                      </TableCell>
                    </TableRow>
                    <TableRow align="center">
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Source
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        User Name
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Destination
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        User Type
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        RequestId
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Request Type
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Timestamp
                      </TableCell>

                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={5}
                      >
                        Operate
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registerUserRequestsList?.length > 0 ? (
                      registerUserRequestsList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((consumerUsers, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {consumerUsers.source}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.username}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.destination}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.usertype}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.requestid}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.requesttype}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.timestamp}
                              </TableCell>
                              <TableCell />
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    requestInfoClick(consumerUsers.requestid)
                                  }
                                >
                                  Request Info
                                </Button>
                              </TableCell>
                              {consumerUsers?.requesttype ===
                              "accountCreation" ? (
                                <>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        consumerUserCreateClick(
                                          consumerUsers.requestid
                                        );
                                      }}
                                    >
                                      Create
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        rejectDialogClick(
                                          consumerUsers.username,
                                          consumerUsers.userid,
                                          consumerUsers.requestid
                                        );
                                      }}
                                    >
                                      Reject
                                    </Button>
                                  </TableCell>
                                </>
                              ) : (
                                <>
                                  <TableCell align="center"></TableCell>
                                  <TableCell align="center"></TableCell>
                                </>
                              )}
                              <TableCell />
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          Register User Requests Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={registerUserRequestsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          {gridRequetsView && (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div>
                    <SearchBar
                      value={searched}
                      onChange={(searchVal) =>
                        requestSearchForUserRequests(searchVal)
                      }
                      onCancelSearch={() => cancelSearchForUserRequests()}
                    />
                    <h4 style={{ textAlign: "center" }}>
                      List of Register User Requests
                    </h4>
                  </div>
                  <br />
                  <div>
                    {registerUserRequestsList?.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {registerUserRequestsList?.map(
                          (consumerUsers, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <p>
                                <b>Source:</b>
                                {consumerUsers.source}
                              </p>
                              <p>
                                <b>User Name:</b>
                                {consumerUsers.username}
                              </p>
                              <span>
                                <p>
                                  <b>Destination:</b>
                                  {consumerUsers.destination}
                                </p>
                              </span>
                              <p>
                                <b>User Type:</b>
                                {consumerUsers.usertype}
                              </p>
                              <p>
                                <b>RequestId:</b>
                                {consumerUsers.requestid}
                              </p>
                              <p>
                                <b>Request Type:</b>
                                {consumerUsers.requesttype}
                              </p>
                              <p>
                                <b>Timestamp:</b>
                                {consumerUsers.timestamp}
                              </p>
                              &nbsp;&nbsp;
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  requestInfoClick(consumerUsers.requestid)
                                }
                              >
                                Request Info
                              </Button>
                              {consumerUsers?.requesttype ===
                                "accountCreation" && (
                                <div>
                                  <h4 className="cardactiontitle">Operate</h4>
                                  &nbsp;&nbsp;
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      consumerUserCreateClick(
                                        consumerUsers.requestid
                                      );
                                    }}
                                  >
                                    Create
                                  </Button>
                                  &nbsp;&nbsp;
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      rejectDialogClick(
                                        consumerUsers.username,
                                        consumerUsers.userid,
                                        consumerUsers.requestid
                                      );
                                    }}
                                  >
                                    Reject
                                  </Button>
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <h4 style={{ textAlign: "center" }}>
                        Register Users Requets not found.
                      </h4>
                    )}
                  </div>
                </>
              )}
            </Box>
          )}
        </Grid>
      </Box>

      {/* Create Local consumer user */}
      <Dialog
        fullScreen={fullScreen}
        open={localConsumerUserCreateDialog}
        onClose={handleClose}
        aria-labelledby="CreateConsumerUser"
      >
        <DialogTitle id="CreateConsumerUser">
          {"Create Consumer User"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="username">Consumer User Name :</InputLabel>
            <Input
              disabled
              id="username"
              name="username"
              type="text"
              value={consumerUserUserName}
            />
          </FormControl>

          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="firstName">First Name :</InputLabel>
            <Input
              disabled
              id="firstName"
              name="firstName"
              type="text"
              value={consumerUserFirstName}
              onChange={(e) => {
                setConsumerUserFirstName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="lastName">Last Name :</InputLabel>
            <Input
              disabled
              id="lastName"
              name="lastName"
              type="text"
              value={consumerUserLastName}
              onChange={(e) => {
                setConsumerUserLastName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="domainName">Domain Name :</InputLabel>
            <Input
              disabled
              id="domainName"
              name="domainName"
              type="text"
              value={consumerUserUserDomainName}
              onChange={(e) => {
                setConsumerUserUserDomainName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="userType">User Type</InputLabel>
            <Select
              disabled
              id="userType"
              name="userType"
              value={consumerUserUserType}
            >
              <MenuItem value={consumerUserUserType}>
                {consumerUserUserType}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
            <Input
              disabled
              id="email"
              name="email"
              type="email"
              value={consumerUserEmail}
              onChange={(e) => {
                setConsumerUserEmail(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
            <Input
              disabled
              id="mobileNumber"
              name="mobileNumber"
              type="text"
              value={consumerUserPhone}
              onChange={(e) => {
                setConsumerUserPhone(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <p htmlFor="address">Address:</p>
            <TextareaAutosize
              minRows={5}
              style={{ minwidth: 200 }}
              value={consumerUserAddress}
              onChange={(e) => {
                setConsumerUserAddress(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="city">City :</InputLabel>
            <Input
              id="city"
              name="city"
              type="text"
              value={consumerUserCity}
              onChange={(e) => {
                setConsumerUserCity(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="state">State :</InputLabel>
            <Input
              id="state"
              name="state"
              type="text"
              value={consumerUserState}
              onChange={(e) => {
                setConsumerUserState(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="nominee">Nominee :</InputLabel>
            <Input
              id="nominee"
              name="nominee"
              type="text"
              value={consumerUserNominee}
              onChange={(e) => {
                setConsumerUserNominee(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="pancard">Pancard Number :</InputLabel>
            <Input
              id="pancard"
              name="pancard"
              type="text"
              value={consumerUserPancard}
              onChange={(e) => {
                setConsumerUserPancard(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="longitude">Longitude :</InputLabel>
            <Input
              id="longitude"
              name="longitude"
              type="text"
              value={consumerUserLongitude}
              onChange={(e) => {
                setConsumerUserLongitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="latitude">Latitude :</InputLabel>
            <Input
              id="latitude"
              name="latitude"
              type="text"
              value={consumerUserLatitude}
              onChange={(e) => {
                setConsumerUserLatitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <p htmlFor="messageDescription">Message:</p>
            <TextareaAutosize
              minRows={5}
              style={{ minwidth: 200 }}
              value={consumerUserMessage}
              onChange={(e) => {
                setConsumerUserMessage(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              createLocalUserSubmit();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setLocalConsumerUserCreateDialog(!localConsumerUserCreateDialog);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Create Global user */}
      <Dialog
        fullScreen={fullScreen}
        open={globalConsumerUserCreateDialog}
        onClose={handleClose}
        aria-labelledby="CreateConsumerUser"
      >
        <DialogTitle id="CreateConsumerUser">
          {"Create Consumer User"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="username">User Name :</InputLabel>
            <Input
              disabled
              id="username"
              name="username"
              type="text"
              value={consumerUserUserName}
            />
          </FormControl>

          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="firstName">First Name :</InputLabel>
            <Input
              disabled
              id="firstName"
              name="firstName"
              type="text"
              value={consumerUserFirstName}
              onChange={(e) => {
                setConsumerUserFirstName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="lastName">Last Name :</InputLabel>
            <Input
              disabled
              id="lastName"
              name="lastName"
              type="text"
              value={consumerUserLastName}
              onChange={(e) => {
                setConsumerUserLastName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="domainName">Domain Name :</InputLabel>
            <Input
              disabled
              id="domainName"
              name="domainName"
              type="text"
              value={consumerUserUserDomainName}
              onChange={(e) => {
                setConsumerUserUserDomainName(e.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="userType">User Type</InputLabel>
            <Select
              disabled
              id="userType"
              name="userType"
              value={consumerUserUserType}
            >
              <MenuItem value={consumerUserUserType}>
                {consumerUserUserType}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
            <Input
              disabled
              id="email"
              name="email"
              type="email"
              value={consumerUserEmail}
              onChange={(e) => {
                setConsumerUserEmail(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
            <Input
              disabled
              id="mobileNumber"
              name="mobileNumber"
              type="text"
              value={consumerUserPhone}
              onChange={(e) => {
                setConsumerUserPhone(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              createGlobalUsersubmit();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setGlobalConsumerUserCreateDialog(
                !globalConsumerUserCreateDialog
              );
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Reject */}
      <Dialog
        fullScreen={fullScreen}
        open={consumerUserDeleteDialog}
        onClose={handleClose}
        aria-labelledby="rejectConsumerUser"
      >
        <DialogTitle id="rejectConsumerUser">
          {"Reject Consumer User"}
        </DialogTitle>
        <DialogContent>
          <h4>Are you sure want to Reject?</h4>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              rejectSubmitClick();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Request Info */}
      <Dialog
        fullScreen={fullScreen}
        open={requestInfoDialog}
        fullWidth={true}
        aria-labelledby="requestInfo"
      >
        <DialogTitle id="requestInfo">{"Request Info"}</DialogTitle>
        <DialogContent>
        {Object.keys(requestInfoObj).length > 0 ? (
            Object.entries(requestInfoObj).map(([key, value]) => renderKeyValue(key, value))
          ) : (
            <p>No Data Found</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setRequestInfoDialog(!requestInfoDialog);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
