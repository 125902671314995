import { Container } from "@material-ui/core";
import React, { useState } from "react";
import Tabs from "react-simply-tabs";
import CTA from "../CTA/CTA";
// import "./IAATab.css";

export default function FArmerCTATab() {
  const [activeTabIndex, setTabIndex] = useState(0);
  const loginHandler = (username) => {
    localStorage.setItem("userType", username);
  };
  return (
    <div className="Iaa-tabs ">
      {/* <h2 className="container-title  text-center">Become an Agri-Entrepreneur</h2> */}
    


        <div >
          {" "}
          <Container className=" partner-journey-container subSectionWrapper">
            <CTA
           data="present"
     
    href="/auth/3/signup"
              heading="Want to become Grower to create Trusted brand and earn extra income"
              btnText="Register Now"
            />
          </Container>
        </div>
     
    </div>
  );
}
