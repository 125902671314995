import React, { useState, useEffect } from "react";


export default function DashboardForEnergyProcessorComponent(props) {
 

  return (
    <>
      <h2>Coming Soon...</h2>
      
    </>
  );
}
