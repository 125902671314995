import React from "react";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import TransporterImg from "../../../images/gbrCBN/CBNImg7.webp";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import CBNBenefits from "../Benefits/CBNBenefits";
import CBN from "../../../images/LTN - GBR Network - 09-05-2024 C.gif";

// import FinancialServiceProviderImg from "../../../images/loan-business-finance-businessman-explain-business-report-from-data-analysis-bank-marketing.jpg";
import ProductionManagerImg from "../../../images/gbrPN/PNImg5.webp";
import LTNServices from "./LTNServices";
// import CBNManager from "./CBNManager";

export default function LTNNetwork() {
  return (
    <>
      {/* <NetworkBanner
        bannerTitle='CBO & Agro Dealers can BUILD, OWN, MANAGE & OPERATE Community to help farmers with everything they need from seed to sale.'
        bodycopy='Local Trade | Quality Inputs | Insurance | Advisory | Capital'
        className='cbnbg'
      /> */}

<UpdatesBanner
        className="cbnbg text-white"
        bannerTitle ="Local Trade Network"
      
        // bannerbodycopy={[
        //   "New Revenue Stream | Optimised resource management | Boosts agricultural productivity |",
        //   <br className="d-lg-block d-none"></br>,
        //   "Unlocks opportunities for investors and rural entrepreneurs",
        // ]}
        // buttonText2="Sign in"
        // href2="http://abn.gbrfarming.com/"
        // buttonText3="Sign up"
        // href3="http://abn.gbrfarming.com/auth/3/signup
      />



<div className="mt-5 ">
<h4 className="container-title text-center mx-md-4 ">
Local Trade Network  Benefits          </h4> 
        <HomeSubSection
          fontBold="true"
          className="rowReverseAbout align-items-center"
          listItems={<CBNBenefits/>}
          description="Agribusiness can Build their Own Community network (Virtual Clubs) to obtain group organic certification and find the best partners for local trade

          "
          // descriptionTitle=" Community Based Network (CBN) & Benefits"
          src={CBN}
          // href1="/CBNNetwork"
          // buttoncont1="Explore Ecosystem for Producers"
          grid="true"
          btn2= "true"


        />
      </div>



      <LTNServices/>


      <div className="subSectionWrapper ">
        <h2 className="container-title text-center mt-4">
          LTN Network Operators{" "}
        </h2>
        <HomeSubSection
        id="ABN-Investor"
        className3="cbo-img"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards--mod"
        descriptionTitle="
        Community Based Organizations"
        description="Community-based organizations (CBOs) in are grassroots groups formed by individuals within a community to collectively address agricultural challenges and enhance rural livelihoods. These organizations play a pivotal role in promoting sustainable farming practices, improving access to markets and resources, and strengthening community ties. By fostering collaboration and knowledge-sharing, CBOs contribute to the overall development of agriculture, empower small-scale farmers, and address specific issues  in farming, thereby promoting resilient and inclusive agricultural systems at the local level.
        "
        description1="FPCs | NGOs | PACS | Traders | SHG | CS"
            />
</div>
<div className="main-wrapper2">

      <div className=" subSectionWrapper">
      <h2 className='text-center container-title'>LTN Network Member</h2>

        {/* <h2 className="container-title text-center">Our Community</h2> */}

        {/* <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image16"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
          Agri-producers' Advisors"
          src={AdvisorImg}
          href2="auth/18/cbnsignup"
          buttoncont2="Register"
          href1="auth/18/cbnsignin"
          buttoncont1="Sign In"
          userType="Trainer"
        /> */}



        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image17"
          descriptionTitle="
            Traders"
          description="GBR is a technology company based in Bangalore that
          provides an alternate innovate options for agri-MSMEs
          Founders to maximize profit [reduce procurement cost &
          export] and raise easy investments through IAA.
          "
          src={TradersImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image19"
          descriptionTitle="
            Farm Machinery Providers"
          description="GBR is a technology company based in Bangalore that
    provides an alternate innovate options for agri-MSMEs
    Founders to maximize profit [reduce procurement cost &
    export] and raise easy investments through IAA.
    "
          src={MachineryImg}
          href2="auth/19/cbnsignup"
          buttoncont2="Register"
          href1="auth/19/cbnsignin"
          buttoncont1="Sign In"
          userType="Agri-producers-Machinery-Provider"
        />

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image20"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
            Nutrition Companies"
          src={NutritionCompaniesImg}
          href2="auth/20/cbnsignup"
          buttoncont2="Register"
          href1="auth/20/cbnsignin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image21"
          descriptionTitle="
            FPO,CS,NGO,SHG"
          description="GBR is a technology company based in Bangalore that
    provides an alternate innovate options for agri-MSMEs
    Founders to maximize profit [reduce procurement cost &
    export] and raise easy investments through IAA.
    "
          src={NGOImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image23"
          descriptionTitle="
            Storage Providers"
          description="GBR is a technology company based in Bangalore that
    provides an alternate innovate options for agri-MSMEs
    Founders to maximize profit [reduce procurement cost &
    export] and raise easy investments through IAA.
    "
          src={StorageProviderImg}
          href2="auth/21/cbnsignup"
          buttoncont2="Register"
          href1="auth/21/cbnsignin"
          buttoncont1="Sign In"
          userType="Storage Providers"
        />

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image24"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
            Agri Input Companies"
          src={AgriInputImg}
          href2="auth/22/cbnsignup"
          buttoncont2="Register"
          href1="auth/22/cbnsignin"
          buttoncont1="Sign In"
          userType="Agri Input Companies"
        />

        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
            Transporters"
          src={TransporterImg}
          href2="auth/23/cbnsignup"
          buttoncont2="Register"
          href1="auth/23/cbnsignin"
          buttoncont1="Sign In"
          userType="Transporters"
        /> */}
        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards "
          description="NGOs are non-profit organizations focused on supporting agricultural development and rural communities. They offer services like education, training, and technical assistance to farmers, helping them adopt sustainable practices and access markets, ultimately promoting food security and rural development.


            "
          descriptionTitle="
            Farmers"
          src={TransporterImg}
       
          userType="Transporters"
          className3="ngo-img"
        />
        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards "
          description="SHGs (Self-Help Groups) are community-based organizations formed by farmers to pool resources, share knowledge, and collectively engage in agricultural activities. These groups empower farmers, particularly women, by providing access to credit, training, and market linkages, thus enhancing their livelihoods and promoting socio-economic development in rural areas.


            "
          descriptionTitle="
            Traders"
          src={TransporterImg}
   
          className3="shg-img"
        />

















        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image25"
          descriptionTitle="
            Drivers"
          description="GBR is a technology company based in Bangalore that
    provides an alternate innovate options for agri-MSMEs
    Founders to maximize profit [reduce procurement cost &
    export] and raise easy investments through IAA.
    "
          src={DriverImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image26"
          descriptionTitle="
              Farmer Franchise"
          description="GBR is a technology company based in Bangalore that
      provides an alternate innovate options for agri-MSMEs
      Founders to maximize profit [reduce procurement cost &
      export] and raise easy investments through IAA.
      "
          src={FarmersFranchiseImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image27"
          descriptionTitle="
            Off-Takers"
          description="If you’re a modern retail company, hotel, or restaurant that wants to purchase fresh and processed food from trusted partner, we can give you what you’re looking for on best price"
          description1="Tracceable food | Disease Free | Certified"
          src={OffTakerImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}
              </div>
              </div>

{/* <div className="subSectionWrapper main-wrapper2">
        <CBNManager/>
        </div> */}
    </>
  );
}
