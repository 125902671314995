import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./PostManagementComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SearchBar from "material-ui-search-bar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import { Box, Button } from "@mui/material";
import DeleteContent from "../../Common/CommonContent/DeleteContent";
import DeleteContentSubCategory from "../../Common/CommonContent/DeleteContentSubCategory";
import CreateContentSubCategory from "../../Common/CommonContent/CreateContentSubCategory";
import { getCategoryID } from "../../Common/CommonContent/ContentedRelatedCommonFun";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  menuPaper: {
    width: 200,
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
  menuPaperForm: {
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
}));

export default function PostManagementComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [postPopup, setShowPostPopup] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("POST");
  const [subCategoryNameValue, setSubCategoryNameValue] = useState("");
  const [postList, setPostList] = useState([]);
  const [filteredPostList, setFilteredPostList] = useState([]);
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [subCategoryDialog, setSubCategoryDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [postNameValue, setPostNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [expiryDateValue, setExpiryDateValue] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [postFile, setPostFile] = useState(null);
  const [postFileDescription, setPostFileDescription] = useState(null);
  const [filteredPost, setFilteredPost] = useState([]);
  const [searched, setSearched] = useState("");
  const [deleteContentDialog, setDeleteContentDialog] = useState(false);
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteSubcategoryDialog, setDeleteSubCategoryDialog] = useState(false);
  /* delete content */
  const [selectedContentId, setSelectedContentId] = useState("");
  const [getDeleteContentCreator, setDeleteContentCreator] = useState("");
  const [getDeleteContentCategory, setDeleteContentCategory] = useState("");

  useEffect(() => {
    if (!selectedCategoryId) return;
    getSubCategories();
  }, [selectedCategoryId]);

  useMemo(async () => {
    await getCategoryID(selectedCategoryName)
      .then((data) => {
        setSelectedCategoryId(data);
      })
      .catch((e) => {
        return NotificationManager.error("error");
      });
  }, []);

  const getSubCategories = () => {
    MainService.getSubCategoriesForAD(username, selectedCategoryId)
      .then((data) => {
        setSelectedSubCategory(data[0]?.contentsubcategoryname);
        setsubCategoriesList(data);
        setIsLoading(false);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const getAllContentsList = () => {
    setIsLoading(true);
    MainService.getContentsBasedonContentCreator(
      username,
      selectedCategoryId,
      "contentowner"
    )
      .then((data) => {
        setPostList(data);
        setFilteredPostList(data);
        setIsLoading(false);
      })
      .catch((e) => {
        return NotificationManager.error("");
      });
  };

  useMemo(() => {
    if (!username) return;
    if (!selectedCategoryId) return;
    getAllContentsList();
  }, [selectedCategoryId]);

  const createSubCategoryClick = () => {
    setSubCategoryDialog(!subCategoryDialog);
  };

  /*Delete Sub-Category */
  const deleteSubCategoryClick = () => {
    setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
  };
  /* EOD Delete Sub-Category */

  const createPostClick = () => {
    setShowPostPopup(!postPopup);
  };
  const submitPostClick = () => {
    if (postFile === null) {
      return NotificationManager.error("Please Choose File");
    }
    if (postNameValue === "") {
      return NotificationManager.error("Enter Name");
    }
    if (subCategoryNameValue === "") {
      return NotificationManager.error("Select Sub Category");
    }
    const postData = new FormData();
    postData.append("file", postFile);
    postData.append("mimetype", postFile.type);
    postData.append("categoryid", selectedCategoryId);
    postData.append("categoryname", selectedCategoryName);
    postData.append("contentsubcategory", subCategoryNameValue);
    postData.append("contentname", postNameValue);
    postData.append("assetdeveloperid", parentId);
    postData.append("contentdescription", descriptionValue);
    postData.append("contentcreator", username);
    postData.append("contentformat", "DOC");
    postData.append("price", Number(0));
    postData.append("filedescription", postFileDescription);
    postData.append("contentownerid", username);
    postData.append("unit", "INR");
    postData.append("discountedprice", discountValue);
    postData.append("expirydate", expiryDateValue);
    postData.append("event", "none");

    MainService.createContent(postData, parentId)
      .then((res) => {
        getAllContentsList();
        return NotificationManager.success("Post uploaded");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setShowPostPopup(!postPopup);
  };
  /* Delete Content */
  const deleteContentDialogClick = (post) => {
    if (post.contentcreatorid === username) {
      setSelectedContentId(post.contentid);
      setDeleteContentCreator(post.contentcreatorid);
      setDeleteContentCategory(post.categoryid);
      setDeleteContentDialog(!deleteContentDialog);
    } else {
      return NotificationManager.error(
        "You can't delete other content content"
      );
    }
  };

  const closeDeleteContent = () => {
    setDeleteContentDialog(!deleteContentDialog);
  };
  /* EOD Delete Content */

  const viewAllPostsClick = () => {
    getAllContentsList();
    setSubCategoryNameValue("");
  };
  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /*Create Post */
  const closePostModal = (
    <span
      onClick={() => {
        setShowPostPopup(!postPopup);
      }}
    >
      X
    </span>
  );
  const titlePost = "Create Post";
  const postBody = (
    <div style={{ margin: "10px" }}>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryName">Category Name :</InputLabel>
        <Input
          disabled
          id="categoryName"
          name="categoryName"
          type="text"
          value={selectedCategoryName.toUpperCase()}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="subCategoryName">Sub-Category Name :</InputLabel>
        <Select
          id="subCategoryName"
          name="subCategoryName"
          type="text"
          value={subCategoryNameValue}
          onChange={(e) => {
            setSubCategoryNameValue(e.target.value);
          }}
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {subCategoriesList &&
            subCategoriesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.contentsubcategoryname}>
                {idObj.contentsubcategoryname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="postName">Post Name :</InputLabel>
        <Input
          id="postName"
          name="postName"
          type="text"
          value={postNameValue}
          onChange={(e) => {
            setPostNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth={true}>
        <InputLabel htmlFor="contentOwner">Content Owner</InputLabel>
        <Input
          disabled
          id="contentOwner"
          name="contentOwner"
          type="text"
          value={username}
        ></Input>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="contentUploader"> Content Uploader:</InputLabel>
        <Input
          disabled
          id="contentUploader"
          name="contentUploader"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="zone"> Content Approver:</InputLabel>
        <Input
          disabled
          id="contentApprover"
          name="contentApprover"
          type="text"
          value={parentId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="description"> Description:</InputLabel>
        <Input
          id="description"
          name="description"
          type="text"
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="doc">file:</InputLabel>
        <Input
          id="doc"
          name="doc"
          type="file"
          onChange={(e) => {
            setPostFile(e.target.files[0]);
          }}
        />
      </FormControl>
      <br />
      <Box>
        <Button
          sx={{ m: "5px" }}
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={() => {
            submitPostClick();
          }}
        >
          Submit
        </Button>
        <Button
          sx={{ m: "5px" }}
          variant="contained"
          color="error"
          onClick={() => {
            setShowPostPopup(!postPopup);
          }}
        >
          Close
        </Button>
      </Box>
    </div>
  );

  return (
    <>
      <div className="container" id="mainContentList">
        <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Create Post"
          className={classes.fab}
          onClick={() => {
            createPostClick();
          }}
        >
          <AddIcon></AddIcon>
        </Fab>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              viewAllPostsClick();
            }}
          >
            View All Posts
          </Button>
          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              createSubCategoryClick();
            }}
          >
            Create Sub-Category
          </Button>
          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              deleteSubCategoryClick();
            }}
          >
            Delete Sub-Category
          </Button>
          <FormControl variant="outlined" className="postdropdown" size="small">
            <InputLabel
              style={{
                color: "rgb(43, 144, 99)",
                fontSize: "15px",
              }}
            >
              Filter by Sub-Category
            </InputLabel>
            <Select
              id="selectedSubCategory"
              value={subCategoryNameValue}
              label="Select Sub-Category"
              onChange={(e) => {
                setSubCategoryNameValue(e.target.value);
                const filteredRows = filteredPostList.filter((row) => {
                  return row.contentsubcategory
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase());
                });
                setPostList(filteredRows);
              }}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
              {subCategoriesList &&
                subCategoriesList.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj.contentsubcategoryname}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        <div className="MuiGrid-root">
          <div style={{ float: "right" }}>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={listViewClick}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={gridViewClick}
            ></Button>
          </div>
          <h3>TOTAL NO OF POSTS :- {`${postList.length}`}</h3>
          {listView && (
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell align="center" colSpan={12}>
                        <h2 className="cardactiontitle">** POST DETAILS **</h2>
                      </TableCell>
                    </TableRow>
                    <TableRow align="center">
                      <TableCell align="center" colSpan={1}>
                        Assetdeveloperid
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Content Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Category
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Category Id
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Content Uploader
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Organization
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Sub Category Name
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Description
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Option
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {postList.length > 0 ? (
                      postList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((posts, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {posts.parentid}
                              </TableCell>
                              <TableCell align="center">
                                {posts.contentid}
                              </TableCell>
                              <TableCell align="center">
                                {posts.categoryname}
                              </TableCell>
                              <TableCell align="center">
                                {posts.categoryid}
                              </TableCell>
                              <TableCell align="center">
                                {posts.contentcreatorid}
                              </TableCell>
                              <TableCell align="center">
                                {posts.contentowner}
                              </TableCell>
                              <TableCell align="center">
                                {posts.contentsubcategory}
                              </TableCell>
                              <TableCell align="center">
                                {posts.contentdescription}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    deleteContentDialogClick(posts);
                                  }}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          Posts Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={postList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          {gridView && (
            <div className="cardStyleForDashboardItems">
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h2 className="cardactiontitle">** POST DETAILS **</h2>
                  {postList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {postList.map((posts, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Assetdeveloperid:</b>
                              {posts.parentid}
                            </p>
                            <p>
                              <b>Content Id:</b>
                              {posts.contentid}
                            </p>
                          </span>
                          <p>
                            <b>Category Id:</b>
                            {posts.categoryid}
                          </p>
                          <p>
                            <b>Content Uploader:</b>
                            {posts.contentcreator}
                          </p>
                          <p>
                            <b>Category:</b>
                            {posts.categoryname}
                          </p>
                          <p>
                            <b>Organization:</b>
                            {posts.contentowner}
                          </p>
                          <p>
                            <b>Sub Categoryname:</b>
                            {posts.contentsubcategory}
                          </p>
                          <p>
                            <b>Description:</b>
                            {posts.contentdescription}
                          </p>
                          <div>
                            <Button
                              type="button"
                              variant="contained"
                              onClick={() => {
                                deleteContentDialogClick(posts);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>Posts Not Found.</h4>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <CreateContentSubCategory
          reLoad={getSubCategories}
          userName={username}
          categoryId={selectedCategoryId}
          categoryName={selectedCategoryName}
          parentID={parentId}
          onOpen={subCategoryDialog}
          onClose={createSubCategoryClick}
        />

        <DeleteContentSubCategory
          reLoad={getSubCategories}
          userName={username}
          categoryId={selectedCategoryId}
          subCategoryList={subCategoriesList}
          onOpen={deleteSubcategoryDialog}
          onClose={deleteSubCategoryClick}
        />

        <DeleteContent
          reLoad={getAllContentsList}
          contentId={selectedContentId}
          contentCreator={getDeleteContentCreator}
          contentCategory={getDeleteContentCategory}
          onOpen={deleteContentDialog}
          onClose={closeDeleteContent}
        />

        <CustomPopup
          closeButton={closePostModal}
          isOpen={postPopup}
          closeModal={postPopup}
          content={postBody}
          title={titlePost}
        />
      </div>
    </>
  );
}
