// requestTypesConfig.js

const productRequestTypesConfig = {
  "NULL": {
    "FOOD-PROCESSOR": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    "BROKER": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    "BRAND": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    // Add other user types if needed...
  },
  "BASIC": {
    "FOOD-PROCESSOR": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    "BROKER": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    "BRAND": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    // Add other user types if needed...
  },
  "STANDARD": {
    "FOOD-PROCESSOR": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    "BROKER": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    "BRAND": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    // Add other user types if needed...
  },
  "PREFFERED": {
    "FOOD-PROCESSOR": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    "BROKER": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    "BRAND": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    // Add other user types if needed...
  },
  "ADMIN": {
    "FOOD-PROCESSOR": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    "BROKER": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    "BRAND": {
      "PROCESSED-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "SEMI-PROCESSED": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cm-product-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
      "RAW-PRODUCTS": [
        { type: "agri-buy-products-rfq-req" },
        { type: "cf-agri-raw-products-rfq-req" },
        { type: "transport-product-rfq-req" },
        { type: "storage-product-rfq-req" },
      ],
    },
    // Add other user types if needed...
  },
  // Add other roles if needed...
};

export default productRequestTypesConfig;
