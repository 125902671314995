import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import _ from "lodash";
import MainPage from "./MainPage/MainPageComponent";
import MainService from "./Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
/* AssetDeveloper Role */
import TrackTheOrderComponent from "./AssetDeveloperRole/TrackTheOrder/TrackTheOrderComponent";
import AdvisoryComponent from "./AssetDeveloperRole/Advisory/AdvisoryComponent";
import AgreementComponent from "./AssetDeveloperRole/Agreement/AgreementComponent";
import AssetHealthStatusComponent from "./AssetDeveloperRole/AssetHealthStatus/AssetHealthStatusComponent";
import AssetStatisticsComponent from "./AssetDeveloperRole/AssetStatistics/AssetStatisticsComponent";
import BuyInputComponent from "./AssetDeveloperRole/BuyInput/BuyInputComponent";
import ConsumerIAMComponent from "./AssetDeveloperRole/ConsumerIAM/ConsumerIAMComponent";
import CustomerCareComponent from "./AssetDeveloperRole/CustomerCare/CustomerCareComponent";
import EventsComponent from "./AssetDeveloperRole/Events/EventsComponent";
import LegacyAssetManagementComponent from "./AssetDeveloperRole/LegacyAssetManagement/LegacyAssetManagementComponent";
import OperatorLegacyAssetManagementComponent from "./AssetDeveloperRole/OperatorLegacyAssetManagement/OperatorLegacyAssetManagementComponent";
import PriceDiscoveryComponent from "./AssetDeveloperRole/PriceDiscovery/PriceDiscoveryComponent";
import ProcessHealthStatusComponent from "./AssetDeveloperRole/ProcessHealthStatus/ProcessHealthStatusComponent";
import SettingsInfoComponent from "./AssetDeveloperRole/SettingsInfo/SettingsInfoComponent";
import SiteManagementComponent from "./AssetDeveloperRole/SiteManagement/SiteManagementComponent";
//import OperatorSiteManagementComponent from "./AssetDeveloperRole/OperatorSiteManagement/OperatorSiteManagementComponent";
import UploadKYCDocumentsComponent from "./AssetDeveloperRole/UploadKYCDocuments/UploadKYCDocumentsComponent";
import ViewAssetDistributersComponent from "./AssetDeveloperRole/ViewAssetDistributers/ViewAssetDistributersComponent";
import ViewAssetOEMComponent from "./AssetDeveloperRole/ViewAssetOEM/ViewAssetOEMComponent";
import ViewBuyersComponent from "./AssetDeveloperRole/ViewBuyers/ViewBuyersComponent";
import LogisticsProviderComponent from "./AssetDeveloperRole/ViewLogisticProviders/LogisticsProviderComponent";
import ViewClinicAdminsComponent from "./AssetDeveloperRole/ViewClinicAdmins/ViewClinicAdminsComponent";
import ViewDeveloperQAsComponent from "./AssetDeveloperRole/ViewDeveloperQAs/ViewDeveloperQAsComponent";
import ViewExecutivesComponent from "./AssetDeveloperRole/ViewExecutives/ViewExecutivesComponent";
import ViewFarmersComponent from "./AssetDeveloperRole/ViewFarmers/ViewFarmersComponent";
import GrowerFarmerComponent from "./AssetDeveloperRole/GrowerFarmer/GrowerFarmerComponent";
import OperatorFarmersComponent from "./AssetDeveloperRole/OperatorFarmers/OperatorFarmersComponent";
import ViewOperatorsComponent from "./AssetDeveloperRole/ViewOperators/ViewOperatorsComponent";
import ViewProductionManagersComponent from "./AssetDeveloperRole/ViewProductionManagers/ViewProductionManagersComponent";
import ViewProductionTeamsComponent from "./AssetDeveloperRole/ViewProductionTeams/ViewProductionTeamsComponent";
import SiteUpdatesComponent from "./AssetDeveloperRole/SiteUpdates/SiteUpdatesComponent";
import TenderManagementComponent from "./AssetDeveloperRole/TenderManagement/Tender";
import TenderSiteMAnagementComponent from "./AssetDeveloperRole/TenderSiteManagement/TenderSiteManagementComponent";
import UserManagementComponent from "./AssetDeveloperRole/UserManagement/UserManagementComponent";
import PostManagementComponent from "./AssetDeveloperRole/PostManagement/PostManagementComponent";
import FarmSiteManagementComponent from "./AssetDeveloperRole/FarmSiteManagement/FarmSiteManagementComponent";
import MyTendersComponent from "./AssetDeveloperRole/MyTenders/MyTendersComponent";
import PublishJob from "./AssetDeveloperRole/JobsManagement/PublishJob";
import FarmerGovtSchemes from "./AssetDeveloperRole/FarmerGovtSchemes/FarmerGovtSchemes";
import ProjectReport from "./AssetDeveloperRole/ProjectReport/ProjectReport";
import PitchDeck from "./AssetDeveloperRole/PitchDeck/PitchDeck";
import Advertisement from "./AssetDeveloperRole/Advertisement/Advertisement";
import PropTypes from "prop-types";
import SerializationComponent from "./AssetDeveloperRole/Serialization/SerializationComponent";
import SerializationWithIdComponent from "./AssetDeveloperRole/SerializationWithId/SerializationWithIdComponent";
/* Asset OEM Role */

import AssetsCategoriesPageComponent from "./AssetOEMRole/AssetsCategories/AssetsCategoriesPageComponent";
import AssetsServicesComponent from "./AssetOEMRole/AssetsServices/AssetsServicesPageComponent";
import BankComponent from "./AssetOEMRole/Bank/BankPageComponent";
import CompliaintComponent from "./AssetOEMRole/Complaint/ComplaintPageComponent";
import DashboardComponent from "./AssetOEMRole/Dashboard/DashboardePageComponent";
import FarmUpdatesComponent from "./AssetOEMRole/FarmUpdates/FarmUpdatesComponent";
import FeedbackComponent from "./AssetOEMRole/Feedback/FeedbackPageComponent";
import GrowersComponent from "./AssetOEMRole/Growers/GrowersPageComponent";
import InvestComponent from "./AssetOEMRole/Invest/InvestPageComponent";
import KYCComponent from "./AssetOEMRole/KYC/KYC";
import MyAssetsComponent from "./AssetOEMRole/MyAssets/MyAssetsPageComponent";
import NomineeComponent from "./AssetOEMRole/Nominee/NomineePageComponent";
import OrdersComponent from "./AssetOEMRole/Orders/OrdersPageComponent";
import ProfileComponent from "./AssetOEMRole/Profile/ProfilePageComponent";
import ReferComponent from "./AssetOEMRole/ReferEarn/ReferPageComponent";
import StatementSummaryComponent from "./AssetOEMRole/StatementSummary/StatementSummaryyPageComponent";
import UploadAgreementComponet from "./AssetOEMRole/UploadAgreement/UploadAgreementPageComponent";
import WalletComponent from "./AssetOEMRole/Wallet/WalletPageComponent";
import DashboardComponentForAD from "./AssetDeveloperRole/AdDashboard/DashboardePageComponentForAD";
import BankPageComponent from "./AssetOEMRole/Bank/BankPageComponent";
/* GovtDept Role */
import ComplaintsComponent from "./GovtDeptRole/Complaints/ComplaintsComponent";
import Branding from "./AssetDeveloperRole/Branding/Branding";
import InputSupplies from "./AssetDeveloperRole/InputSupplies/InputSupplies";
import MSMEGovtSchemes from "./AssetDeveloperRole/MSMEGovtSchemes/MSMEGovtSchemes";
import Advisor from "./AssetDeveloperRole/Advisor/Advisor";
import MsmeSchemes from "./AssetDeveloperRole/Schemes/MsmeSchemes";
import MSMEDashboard from "./AssetDeveloperRole/MSMEDashboard/MSMEDashboard";
import Market from "./AssetDeveloperRole/Market/Market";
import PmcMachinery from "./AssetDeveloperRole/PMC/PmcMachinery/PmcMachinery";
import FmcCompany from "./AssetDeveloperRole/FMC/FmcCompany/FmcCompany";
import FmcMachinery from "./AssetDeveloperRole/FMC/FmcMachinery/FmcMachinery";
import Consultant from "./AssetDeveloperRole/ACC/Consultant/Consultant";
import Certification from "./AssetDeveloperRole/ACC/Certification/Certification";
import Company from "./AssetDeveloperRole/MachineryCompany/Company";

import CommonDropdownsComponent from "./AssetDeveloperRole/CommonDropdownsComponent/CommonDropdownsComponent";
import RoleProfileManagementComponent from "./AssetDeveloperRole/RoleProfileManagement/RoleProfileManagementComponent";
import CommunityComponent from "./AssetDeveloperRole/Community/CommunityComponent";
import SHGComponent from "./AssetDeveloperRole/SHG/SHGComponent";
import FPOComponent from "./AssetDeveloperRole/FPO/FPOComponent";
import CSComponent from "./AssetDeveloperRole/CS/CSComponent";
import NGOComponent from "./AssetDeveloperRole/NGO/NGOComponent";
import TraderComponent from "./AssetDeveloperRole/Trader/TraderComponent";
import FFComponent from "./AssetDeveloperRole/FF/FFComponent";
import SiloOperatorComponent from "./SiloOperator/SiloOperatorComponent";
import GBRStoreComponent from "./AssetDeveloperRole/GBRStore/GBRStoreComponent";
import TrainerComponent from "./AssetDeveloperRole/Trainer/TrainerComponent";
import SupplyShopComponent from "./AssetDeveloperRole/SupplyShop/SupplyShopComponent";
import InputRetailerComponent from "./AssetDeveloperRole/InputRetailer/InputRetailer";
import GlobalNetworkComponent from "./AssetDeveloperRole/GlobalNetwork/GlobalNetworkComponent";
import ViewAgentsComponent from "./AssetDeveloperRole/ViewAgents/ViewAgentsComponent";
import ProductionNetworkComponent from "./AssetDeveloperRole/ProductionNetwork/ProductionNetworkComponent";
import MSMEAdvisorComponent from "./AssetDeveloperRole/MSMEAdvisor/MSMEAdvisorComponent";
import FMCComponent from "./AssetDeveloperRole/FMC/FMCComonent";
import PMCComponent from "./AssetDeveloperRole/PMC/PMCComponent";
import AHSPComponent from "./AssetDeveloperRole/AHSP/AHSPComponent";
import FoodProcessorComponent from "./AssetDeveloperRole/FoodProcessor/FoodProcessorComponent";
import AssetCertifiedConsultantComponent from "./AssetDeveloperRole/AssetCertifiedConsultant/AssetCertifiedConsultantComponent";
import CompactSiteManagement from "./AssetDeveloperRole/CompactSiteManagement/CompactSiteManagement";
import ProductionFarmerComponent from "./AssetDeveloperRole/Grower/GrowerComponent";
import PMCAdvertisement from "./AssetDeveloperRole/PMC/PMCComponent";
import TransportManagementComponent from "./AssetDeveloperRole/TransportManagement/TransportManagementComponent";
import TransportUserComponent from "./AssetDeveloperRole/TransportUser/TransportUserComponent";
import TransportExecutiveComponent from "./AssetDeveloperRole/TransportExecutive/TransportExecutiveComponent";
import TransportManagerComponent from "./AssetDeveloperRole/TransportManager/TransportManagerComponent";
import RoleManagementComponent from "./AssetDeveloperRole/RoleManagement/RoleManagement";
import ClubUpdates from "./AssetDeveloperRole/ClubUpdates/ClubUpdates";
import ResourceManagement from "./AssetDeveloperRole/ResourceManagement/ResourceManagementComponent";
import FinanceInvestor from "./AssetDeveloperRole/FinanceInvestor/FinanceInvestor";
import Transporter from "./AssetDeveloperRole/Transporter/Transporter";
import ExploreProducts from "./AssetDeveloperRole/ExploreProducts/ExploreProducts";
/* website */
import SiteWeatherUpdates from "./AssetDeveloperRole/SiteWeatherUpdates/SiteWeatherUpdates";
import Storage from "./AssetDeveloperRole/Storage/Storage";

// *************BROKER and LP ROLE by BIPLOB**************
import BrokerDashCompo from "./Broker/brokerdashcompo/BrokerDashCompo";
import LpDashboard from "./Broker/logisticproviderdashboard/LpDashboard";
import VehicleManagement from "./Broker/GeoFence/VehicleManagement/VehicleManagement";
import LiveMonitoringComponent from "./Broker/LiveMonitoring/LiveMonitoringComponent";
import TripPlannerComponent from "./Broker/TripPlanner/TripPlannerComponent";
import LocationManagement from "./Broker/TripPlanner/Location/LocationManagement";
import AddLocation from "./Broker/TripPlanner/Location/AddLocation";
import RouteManagement from "./Broker/TripPlanner/Route/RouteManagement";
import AddRoute from "./Broker/TripPlanner/Route/AddRoute";
import TripManagement from "./Broker/TripPlanner/Trip/TripManagement";
import AddTrip from "./Broker/TripPlanner/Trip/AddTrip";
import GeofenceDetails from "./Broker/GeoFence/geofencedetails/GeofenceDetails";
import ViewOrders from "./Broker/BookingManagement/ViewOrders";
import AddBooking from "./Broker/BookingManagement/AddBooking";
import AddDynamic from "./Broker/TripPlanner/Trip/addDynamic/AddDynamic";
import FleetServices from "./Broker/fleetservices/FleetServices";
import TransportFacilityService from "./Broker/tfs/TransportFacilityService";
import TripMonitoring from "./Broker/TripPlanner/TripMonitoring/TripMonitoring";
import ViewRoute from "./Broker/TripPlanner/Route/ViewRoute";
import ViewDriverSchedule from "./Broker/TripPlanner/Trip/ViewDriverSchedule/ViewDriverSchedule";
import ViewVehicleSchedule from "./Broker/TripPlanner/Trip/viewVehicleSchedule/ViewVehicleSchedule";
import LpGlobalNetwork from "./Broker/lpglobalNetwork/LpGlobalNetwork";
// import OrderMangForLp from "./Broker/OrderManagementForLp/BookingReqComp";
import OrderMainComp from "./Broker/OrderManagementForLp/OrderMainComp";
import UpdatedGeofenceDetails from "./Broker/GeoFence/UpdateGeofence/UpdatedGeofenceDetails";
import UpdateGeofenceOfVehicle from "./Broker/GeoFence/UpdateGeofence/UpdateGeofenceOfVehicle";
//........... END OF BROKER AND LP..............
/*Start Invoice Components */
import BuyerAccounts from "../Main/documentComponents/Invoicecomponents/buyeraccountmanagement/BuyerAccounts";
import SellerAccounts from "../Main/documentComponents/Invoicecomponents/selleraccountmanagement/SellerAccountManagement";
import TransportDocumentManagenment from "../Main/documentComponents/transportdocuments/TransportDocumentManagenment";
import VehicleStatus from "./Broker/LiveMonitoring/VehicleStatus/VehicleStatus";
/* End Invoice components */

/* Energy-Processor */
import ACNComponent from "./ACN/ACNComponent";
import VCNComponent from "./VCN/VCNComponent";
import DashboardForEnergyProcessorComponent from "./DashboardForEnergyProcessor/DashboardForEnergyProcessorComponent";
import DSNComponent from "./DSN/DSNComponent";
import PPNComponent from "./PPN/PPNComponent";
import BrokerComponent from "./AssetDeveloperRole/ViewBroker/ViewBrokersComponent";
/* End of Energy-Processor */
import FPNComponent from "./FPN/FPNComponent";
import IANComponent from "./IAN/IANComponent";

/* Asset Distributor Role */
import ContractFarmingComponent from "./AssetDistributor/ContractFarming/ContractFarmingComponent";
import ContractManufacturingComponent from "./AssetDistributor/ContractManufacturing/ContractManufacturingComponent";
import DeliveryRequestsComponent from "./AssetDistributor/DeliveryRequests/DeliveryRequestsComponent";
import AssetDistributorBuyersRequestsComponent from "./AssetDistributor/AssetDistributorBuyerRequests/AssetDistributorBuyersRequestsComponent";
import BuyRequestsComponent from "./AssetDeveloperRole/BuyRequests/BuyRequestsComponent";
import FarmerFacilitationServices from "./AssetDeveloperRole/FarmerFacilitationServices/FarmerFacilitationServices";
import FarmerSchemes from "./AssetDeveloperRole/Schemes/FarmerSchemes";
import SNComponent from "./SNComponent/SNComponent";
import ListAllOrders from "./AssetDistributor/ordermanage/ListAllOrders";
import BuyerRequest from "./AssetDistributor/buyerRequests/BuyerRequest";
import MNComponent from "../Main/MN/MachineryNetwork/MNComponent";
import Report from "./documentComponents/features/Report";
import AssetAccesoriesMarket from "./AssetDeveloperRole/PMC/AssetAccesoriesMarket";
import ExploreAcceseriesAssets from "./AssetDeveloperRole/ExploreProducts/ExploreAcceseriesAssets";
import Publish from "./AssetDeveloperRole/Publish/Publish";
import AssetsMarketComponent from "./AssetDeveloperRole/AssetsMarket/AssetsMarketComponent";
import ExploreAssetsComponent from "./AssetDeveloperRole/ExploreAssets/ExploreAssetsComponent";
import Device from "./Layout/Device";
import DeviceMarket from "./Layout/DeviceMarket";
import ExploreDevice from "./Layout/ExploreDevice";
import NotificationSupport from "./Layout/NotificationSupport";
import Ota from "./Layout/ota";
import KeyManagement from "./Layout/KeyManagement";
import CompareProperty from "./Layout/CompareProperty";
import CompareMultiProperty from "./Layout/CompareMultiProperty";
import ViewProperty from "./Layout/ViewProperty";
import Overview from "./Layout/Overview";
import Gateway from "./Layout/Gateway";
import MetaDataConfigure from "./Layout/MetaDataConfigure";
import ModelConfigure from "./Layout/ModelConfigure";
import Types from "./Layout/Types";
import Model from "./Layout/Model";
import DashboardForFMC from "./Layout/Dashboard/Dashboard";
import TraceCode from "./AssetDeveloperRole/TraceCode/TraceCode";
import ViewMultipleProperty from "./Layout/ViewMultiProperty";
import ServerManagement from "./Layout/ServerManagement/ServerManagement";
import DeviceCloudServerManagement from "./Layout/DeviceCloudServerManagement/DeviceCloudServerManagement";
import DeviceManagement from "./Layout/DeviceManagement";
import InsightManagement from "./Layout/InsightManagement";
import FirmwareUpdate from "./Layout/FirmwareUpdate/FirmwareUpdate";
import Devicecommercialization from "./Layout/Devicecommercialization";
import OTAManagement from "./Layout/OTAManagement/OTAManagementFirmware";
import OtaManagementNew from "./Layout/OTAManagementNew/OTAManagementFirmware";
import RoleUpdationRequestComponent from "./AssetDeveloperRole/RoleUpdationRequest/RoleUpdationRequestComponent";
import ProductsRfqMarket from "./AssetDeveloperRole/RFQuoteManagement/ProductsRfq/ProductsRfqMarket";
import AssetQuoteManagement from "./AssetDeveloperRole/QuotationManagement/AssetQuoteManagement";
import CertifiedDeviceMarket from "./AssetDeveloperRole/CertifiedDeviceMarket/CertifiedDeviceMarket";
import ExploreCertifiedDevice from "./AssetDeveloperRole/CertifiedDeviceMarket/ExploreCertifiedDevice";
import AssetsRfqManagement from "./AssetDeveloperRole/RFQuoteManagement/AssetsRfq/AssetsRfqManagement";
import ProductQuoteManagement from "./AssetDeveloperRole/QuotationManagement/ProductQuoteManagement";
import FeedbacksComponent from "./AssetDeveloperRole/Feedbacks/FeedbacksComponent";
import ViewQuotes from "./AssetDeveloperRole/RFQuoteManagement/ViewQuotes";
// Brand
import BrandDashboard from "./AssetDeveloperRole/BrandDashboard/BrandDashboard";
import SocialOnMarketPlace from "./AssetDeveloperRole/SocialOnMarketPlace/SocialOnMarketPlace";
import ViewVendors from "./AssetDeveloperRole/ViewVendors/ViewVendors";
import ListOfVendors from "./AssetDeveloperRole/ViewVendors/ListOfVendors";

import OrderProductMarket from "./AssetDeveloperRole/OrderManagement/Order/ProductOrder/OrderProductMarket";
import OrderAssetManagement from "./AssetDeveloperRole/OrderManagement/Order/AssetOrder/OrderAssetManagement";
import ProductsInvoice from "./AssetDeveloperRole/OrderManagement/Invoice/ProductInvoice/ProductsInvoice";
import AssetsInvoiceManagement from "./AssetDeveloperRole/OrderManagement/Invoice/AssetInvoice/AssetsInvoiceManagement";
import JobRequestsComponent from "./AssetDeveloperRole/JobRequests/JobRequestsComponent";
import SolutionMarket from "./AssetDeveloperRole/SolutionMarket/SolutionMarket";
import ExploreSolution from "./AssetDeveloperRole/SolutionMarket/ExploreSolution";
import ViewRfqDoc from "./AssetDeveloperRole/RFQuoteManagement/ViewRfqDoc";
import MyOrdersComponent from "./AssetDeveloperRole/MyOrders/MyOrdersComponent";
import MyFarmsComponent from "./AssetDeveloperRole/MyFarms/MyFarmsComponent";
import ADGlobalNetwork from "./AssetDeveloperRole/GlobalNetwork/ADGlobalNetwork";
import CBOGlobalNetwork from "./AssetDeveloperRole/GlobalNetwork/CBOGlobalNetwork";
import FMCGlobalNetwork from "./Layout/GlobalNetwork/FMCGlobalNetwork";
import PMCGlobalNetwork from "./Layout/GlobalNetwork/PMCGlobalNetwork";
import PMCDealerGlobalNetwork from "./Layout/GlobalNetwork/PMCDealerGlobalNetwork";
import FMCDealerGlobalnetwork from "./Layout/GlobalNetwork/FMCDealerGlobalnetwork";
import OFCGlobalNetwork from "./Layout/GlobalNetwork/OFCGlobalNetwork";import DashboardPageComponentForCBO from "./CBO/CBODashboard/DashboardePageComponentForCBO";
import AgroCareComponent from "./CBO/AgroCare/AgroCareComponent";
import ViewRFQComponent from "./AssetDeveloperRole/MyOrders/ViewRFQ";
import MyOrdersComponentV1 from "./AssetDeveloperRole/MyOrders/MyOrdersv1";

export default class MainRouteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.location,
      _isMounted: true,
      userTypeValue: "",
      userTypeRole: "NULL",
    };
    this.userType = this.userType.bind(this);
  }
  componentWillUnmount() {
    this.setState({
      _isMounted: false,
    });
  }
  componentDidMount() {
    this.userType();
    this.getUserTypeRole();
    this.getUserTypeRole();

    if (this._isMounted === true) {
      const timer = setTimeout(() => {
        if (_.isEqual(this.state.location, this.props.location)) return;
        this.setState({ location: this.props.location });
        this.setState({
          _isMounted: false,
        });
      });
      return () => clearTimeout(timer);
    }
  }

  userType() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        this.setState({
          userTypeValue: data,
        });
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  }

  getUserTypeRole() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    MainService.getConsumerUserTypeRole(parentId, username)
      .then((data) => {
        if (data?.length > 0) {
          this.setState({
            userTypeRole: data[0].name,
          });
        } else {
          this.setState({
            userTypeRole: "NULL",
          });
        }
      })
      .catch((e) => {
        return NotificationManager.error("Network Error");
      });
  }

  render() {
    return (
      <MainPage location={this.props.location}>
        <Switch>
        <Route
            exact
            path={this.props.match.url + "/myOrdersV1"}
            component={(args) => <MyOrdersComponentV1 {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewRFQ"}
            component={(args) => <ViewRFQComponent {...args} />}
          />
        <Route
            exact
            path={this.props.match.url + "/dashboard"}
            component={(args) => <DashboardComponent {...args} />}
          />
        <Route
            exact
            path={this.props.match.url + "/agroCare"}
            component={(args) => <AgroCareComponent {...args} />}
          />
        <Route
            exact
            path={this.props.match.url + "/dashboardForCBO"}
            component={(args) => <DashboardPageComponentForCBO {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/cboGlobalNetwork"}
            component={(args) => <CBOGlobalNetwork {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/adGlobalNetwork"}
            component={(args) => <ADGlobalNetwork {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/myFarms"}
            component={(args) => <MyFarmsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/jobRequests"}
            component={(args) => <JobRequestsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/feedbacks"}
            component={(args) => <FeedbacksComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/myOrders"}
            component={(args) => <MyOrdersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/roleUpdationRequest"}
            component={(args) => <RoleUpdationRequestComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/assetsMarket"}
            component={(args) => <AssetsMarketComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/devicemanagement"}
            component={(args) => <DeviceManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/insightmanagement"}
            component={(args) => <InsightManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/exploreAssets"}
            component={(args) => <ExploreAssetsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/device"}
            component={(args) => <Device {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/servermanagement"}
            component={(args) => <ServerManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/firmwareupdate"}
            component={(args) => <FirmwareUpdate {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/devicecommercialization"}
            component={(args) => <Devicecommercialization {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/devicemarket"}
            component={(args) => <DeviceMarket {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/fmcglobalnetwork"}
            component={(args) => <FMCGlobalNetwork {...args} />}
          /><Route
            exact
            path={this.props.match.url + "/pmcglobalnetwork"}
            component={(args) => <PMCGlobalNetwork {...args} />}
          /><Route
            exact
            path={this.props.match.url + "/fmcdealerglobalnetwork"}
            component={(args) => <FMCDealerGlobalnetwork {...args} />}
          /><Route
            exact
            path={this.props.match.url + "/pmcdealerglobalnetwork"}
            component={(args) => <PMCDealerGlobalNetwork {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/ofcglobalnetwork"}
            component={(args) => <OFCGlobalNetwork {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/exploredevice"}
            component={(args) => <ExploreDevice {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/notificationsupport"}
            component={(args) => <NotificationSupport {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/ota"}
            component={(args) => <Ota {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/keymanagement"}
            component={(args) => <KeyManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/compareproperty"}
            component={(args) => <CompareProperty {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/comparemultiproperty"}
            component={(args) => <CompareMultiProperty {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewmultipleproperty"}
            component={(args) => <ViewMultipleProperty {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewproperty"}
            component={(args) => <ViewProperty {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/overview"}
            component={(args) => <Overview {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/gateway"}
            component={(args) => <Gateway {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/metadataconfigure"}
            component={(args) => <MetaDataConfigure {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/modelconfigure"}
            component={(args) => <ModelConfigure {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/types"}
            component={(args) => <Types {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/model"}
            component={(args) => <Model {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/dashboardforfmc"}
            component={(args) => <DashboardForFMC {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/exploreacceseriesassets"}
            component={(args) => <ExploreAcceseriesAssets {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/certifieddevicemarket"}
            component={(args) => <CertifiedDeviceMarket {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/solutionmarket"}
            component={(args) => <SolutionMarket {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/otamanagement"}
            component={(args) => <OTAManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/otamanagementnew"}
            component={(args) => <OtaManagementNew {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/explorecertifieddevice"}
            component={(args) => <ExploreCertifiedDevice {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/exploresolution"}
            component={(args) => <ExploreSolution {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/mn"}
            component={(args) => <MNComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/buyerRequests"}
            component={(args) => <BuyerRequest {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/exploreproducts"}
            component={(args) => <ExploreProducts {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/dsn"}
            component={(args) => <DSNComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/market"}
            component={(args) => <Market {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/reports"}
            component={(args) => <Report {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/ffs"}
            component={(args) => <FarmerFacilitationServices {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/sn"}
            component={(args) => <SNComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/sn"}
            component={(args) => <SNComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/transportManagement"}
            component={(args) => <TransportManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/fpn"}
            component={(args) => <FPNComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/ian"}
            component={(args) => <IANComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/bank"}
            component={(args) => <BankPageComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/buyerPurchaseOrder"}
            component={(args) => <BuyerAccounts {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/sellerInvoice"}
            component={(args) => <SellerAccounts {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/transportdocuments"}
            component={(args) => <TransportDocumentManagenment {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/resourceManagement"}
            component={(args) => <ResourceManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/roleManagement"}
            component={(args) => <RoleManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/transportManager"}
            component={(args) => <TransportManagerComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/transportExecutive"}
            component={(args) => <TransportExecutiveComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/transportUser"}
            component={(args) => <TransportUserComponent {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/productionFarmer"}
            component={(args) => <ProductionFarmerComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/assetCertifiedConsultant"}
            component={(args) => (
              <AssetCertifiedConsultantComponent {...args} />
            )}
          />
          <Route
            exact
            path={this.props.match.url + "/foodProcessor"}
            component={(args) => <FoodProcessorComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/ahsp"}
            component={(args) => <AHSPComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/pmc"}
            component={(args) => <PMCComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/fmc"}
            component={(args) => <FMCComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/msmeAdvisor"}
            component={(args) => <MSMEAdvisorComponent {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/compactSiteManagement"}
            component={(args) => <CompactSiteManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/productionNetwork"}
            component={(args) => <ProductionNetworkComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewAgents"}
            component={(args) => <ViewAgentsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/globalNetwork"}
            component={(args) => <GlobalNetworkComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/inputRetailer"}
            component={(args) => <InputRetailerComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/supplyShop"}
            component={(args) => <SupplyShopComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/trainer"}
            component={(args) => <TrainerComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/gbrStore"}
            component={(args) => <GBRStoreComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/siteOperator"}
            component={(args) => <SiloOperatorComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/ff"}
            component={(args) => <FFComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/trader"}
            component={(args) => <TraderComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/ngo"}
            component={(args) => <NGOComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/cs"}
            component={(args) => <CSComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/fpo"}
            component={(args) => <FPOComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/shg"}
            component={(args) => <SHGComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/community"}
            component={(args) => <CommunityComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/commonDropdowns"}
            component={(args) => <CommonDropdownsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/models"}
            component={(args) => <SerializationComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/serializationWithId"}
            component={(args) => <SerializationWithIdComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/trackTheOrder"}
            component={(args) => <TrackTheOrderComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/advisory"}
            component={(args) => <AdvisoryComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/agreement"}
            component={(args) => <AgreementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/assetHealthStatus"}
            component={(args) => <AssetHealthStatusComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/assetStatistics"}
            component={(args) => <AssetStatisticsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/processHealthStatus"}
            component={(args) => <ProcessHealthStatusComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/buyInput"}
            component={(args) => <BuyInputComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/consumerIAM"}
            component={(args) => <ConsumerIAMComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/roleProfileManagement"}
            component={(args) => <RoleProfileManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/customercare"}
            component={(args) => <CustomerCareComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/events"}
            component={(args) => <EventsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/legacyAssetManagement"}
            component={(args) => <LegacyAssetManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/operatorLegacyAssetManagement"}
            component={(args) => (
              <OperatorLegacyAssetManagementComponent {...args} />
            )}
          />
          <Route
            exact
            path={this.props.match.url + "/priceDiscovery"}
            component={(args) => <PriceDiscoveryComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/siteUpdates"}
            component={(args) => <SiteUpdatesComponent {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/settingsInfo"}
            component={(args) => <SettingsInfoComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/siteManagement"}
            component={(args) => <SiteManagementComponent {...args} />}
          />
          {/* <Route
            exact
            path={this.props.match.url + "/operatorSiteManagement"}
            component={(args) => <OperatorSiteManagementComponent {...args} />}
          /> */}
          <Route
            exact
            path={this.props.match.url + "/uploadKYCdocuments"}
            component={(args) => <UploadKYCDocumentsComponent {...args} />}
          />
          {/* <Route
            exact
            path={this.props.match.url + "/uploadAgreements"}
            component={(args) => <UploadAgreementComponet {...args} />}
          /> */}
          <Route
            exact
            path={this.props.match.url + "/viewAssetDistributors"}
            component={(args) => <ViewAssetDistributersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewAssetOEM"}
            component={(args) => <ViewAssetOEMComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewBuyers"}
            component={(args) => <ViewBuyersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewLogisticProviders"}
            component={(args) => <LogisticsProviderComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewClinicAdmins"}
            component={(args) => <ViewClinicAdminsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewDeveloperQAs"}
            component={(args) => <ViewDeveloperQAsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewExecutives"}
            component={(args) => <ViewExecutivesComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewFarmers"}
            component={(args) => <ViewFarmersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/operatorFarmers"}
            component={(args) => <OperatorFarmersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/growerFarmers"}
            component={(args) => <GrowerFarmerComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewOperators"}
            component={(args) => <ViewOperatorsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewProductionManagers"}
            component={(args) => <ViewProductionManagersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewProductionTeams"}
            component={(args) => <ViewProductionTeamsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/tenderManagement"}
            component={(args) => <TenderManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/mytenderManagement"}
            component={(args) => <MyTendersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/jobManagement"}
            component={(args) => <PublishJob {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/advertisement"}
            component={(args) => <Advertisement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/projectreport"}
            component={(args) => <ProjectReport {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/pitchdeck"}
            component={(args) => <PitchDeck {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/tenderSiteManagement"}
            component={(args) => <TenderSiteMAnagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/userManagement"}
            component={(args) => <UserManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/postManagement"}
            component={(args) => <PostManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/farmSiteManagement"}
            component={(args) => <FarmSiteManagementComponent {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/profile"}
            component={(args) => <ProfileComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/wallet"}
            component={(args) => <WalletComponent {...args} />}
          />

          {/* Asset Distributor Role */}
          <Route
            exact
            path={this.props.match.url + "/contractFarming"}
            component={(args) => <ContractFarmingComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/contractManufacturing"}
            component={(args) => <ContractManufacturingComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/deliveryRequests"}
            component={(args) => <DeliveryRequestsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/buyRequests"}
            component={(args) => <BuyRequestsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/listOrders"}
            component={(args) => <ListAllOrders {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/assetDistributorBuyersRequests"}
            component={(args) => (
              <AssetDistributorBuyersRequestsComponent {...args} />
            )}
          />
          {this.state.userTypeValue === "AssetDistributor" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {this.state.userTypeValue === "DeviceMaker" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/dashboardforfmc"} />
              )}
            />
          )}

          {/* AssetOEM Role */}
          

          <Route
            exact
            path={this.props.match.url + "/invest"}
            component={(args) => <InvestComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/myassets"}
            component={(args) => <MyAssetsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/KYC"}
            component={(args) => <KYCComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/uploaddocuments"}
            component={(args) => <UploadAgreementComponet {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/orders"}
            component={(args) => <OrdersComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/nominee"}
            component={(args) => <NomineeComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/statement"}
            component={(args) => <StatementSummaryComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/feedback"}
            component={(args) => <FeedbackComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/wallet"}
            component={(args) => <WalletComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/bank"}
            component={(args) => <BankComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/farmupdates"}
            component={(args) => <FarmUpdatesComponent {...args} />}
          />
          {this.state.userTypeValue === "AssetOEM" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/dashboard"} />
              )}
            />
          )}

          {/* GovtDept role */}
          <Route
            exact
            path={this.props.match.url + "/clubupdates"}
            component={(args) => <ClubUpdates {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/branding"}
            component={(args) => <Branding {...args} />}
          />
          
          <Route
            exact
            path={this.props.match.url + "/complaints"}
            component={(args) => <ComplaintsComponent {...args} />}
          />
          {this.state.userTypeValue === "GovtDept" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/dashboard"} />
              )}
            />
          )}
          {/* Farmer role */}
          
          <Route
            exact
            path={this.props.match.url + "/profile"}
            component={(args) => <ProfileComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/feedback"}
            component={(args) => <FeedbackComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/complaints"}
            component={(args) => <ComplaintsComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/growerFarmers"}
            component={(args) => <GrowerFarmerComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/uploaddocuments"}
            component={(args) => <UploadAgreementComponet {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/KYC"}
            component={(args) => <KYCComponent {...args} />}
          />
          {this.state.userTypeValue === "Farmer" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/dashboard"} />
              )}
            />
          )}
          {/* MSME Role */}
          <Route
            exact
            path={this.props.match.url + "/msmedashboard"}
            component={(args) => <MSMEDashboard {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/farmergovtschemes"}
            component={(args) => <FarmerGovtSchemes {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/msmegovtschemes"}
            component={(args) => <MSMEGovtSchemes {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/pmcadvertisement"}
            component={(args) => <PMCAdvertisement {...args} />}
          />
          {this.state.userTypeValue === "MSME-ADVISOR" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/msmedashboard"} />
              )}
            />
          )}
          {/* FOOD PROCESSOR*/}
          <Route
            exact
            path={this.props.match.url + "/advisor"}
            component={(args) => <Advisor {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/msmeschemes"}
            component={(args) => <MsmeSchemes {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/company"}
            component={(args) => <Company {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/siteweather"}
            component={(args) => <SiteWeatherUpdates {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/financeinvestor"}
            component={(args) => <FinanceInvestor {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/transporter"}
            component={(args) => <Transporter {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/publish"}
            component={(args) => <Publish {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/socialmarketplace"}
            component={(args) => <SocialOnMarketPlace {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewverdors"}
            component={(args) => <ViewVendors {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/vendorslist" + "/:Id"}
            component={(args) => <ListOfVendors {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/orderproduct"}
            component={(args) => <OrderProductMarket {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/orderasset"}
            component={(args) => <OrderAssetManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/productsinvoice"}
            component={(args) => <ProductsInvoice {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/assetsinvoice"}
            component={(args) => <AssetsInvoiceManagement {...args} />}
          />
          {/* PMC */}
          <Route
            exact
            path={this.props.match.url + "/pmcmachinery"}
            component={(args) => <PmcMachinery {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/assetacceseriesmarket"}
            component={(args) => <AssetAccesoriesMarket {...args} />}
          />

          {this.state.userTypeValue === "PMC" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {this.state.userTypeValue === "PMCDealer" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {this.state.userTypeValue === "FMCDealer" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {this.state.userTypeValue === "AgriAssetDistributor" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {this.state.userTypeValue === "MerchantExporter" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {this.state.userTypeValue === "BiogasEquipCompany" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}

          {/* FMC */}
          <Route
            exact
            path={this.props.match.url + "/fmccompany"}
            component={(args) => <FmcCompany {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/fmcmachinery"}
            component={(args) => <FmcMachinery {...args} />}
          />
          {this.state.userTypeValue === "FMC" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {/* ACC */}
          <Route
            exact
            path={this.props.match.url + "/consultant"}
            component={(args) => <Consultant {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/certification"}
            component={(args) => <Certification {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/farmerschemes"}
            component={(args) => <FarmerSchemes {...args} />}
          />
          {this.state.userTypeValue === "AssetCertifiedConsultant" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}

          {/* ***********************BROKER ROUTES******************** */}

          {/* BROKER BookingManagement ROUTES************** */}
          <Route
            exact
            path={this.props.match.url + "/bookings"}
            component={(args) => <ViewOrders {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/addBooking"}
            component={(args) => <AddBooking {...args} />}
          />

          {/* ******BROKER TRIPPLANNER ROUTES**************** */}

          <Route
            exact
            path={this.props.match.url + "/tripmonitoring"}
            component={(args) => <TripMonitoring {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/addDynamic/:tripId/:routeId"}
            component={(args) => <AddDynamic {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/brokerTripPlanner"}
            component={(args) => <TripPlannerComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/getLocation"}
            component={(args) => <LocationManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/addLocation"}
            component={(args) => <AddLocation {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/getRoutes"}
            component={(args) => <RouteManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/addRoutes"}
            component={(args) => <AddRoute {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/viewroute"}
            component={(args) => <ViewRoute {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/getTrips"}
            component={(args) => <TripManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/addTrips"}
            component={(args) => <AddTrip {...args} />}
          />

          {/* VIEW SCHEDULES */}
          <Route
            exact
            path={this.props.match.url + "/viewdriverschedule"}
            component={(args) => <ViewDriverSchedule {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/viewVehicleschedule"}
            component={(args) => <ViewVehicleSchedule {...args} />}
          />
          {/* BROKER LIVEMONITORING ROUTES ************/}

          <Route
            exact
            path={this.props.match.url + "/brokerLiveMonitoring"}
            component={(args) => <LiveMonitoringComponent {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/acn"}
            component={(args) => <ACNComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/vcn"}
            component={(args) => <VCNComponent {...args} />}
          />

          {/* LP GEOFENCING ROUTES********** */}

          <Route
            exact
            path={this.props.match.url + "/main/vehicle"}
            component={(args) => <VehicleManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/geofence/:id"}
            component={(args) => <GeofenceDetails {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/main/updateGeofence"}
            component={(args) => <UpdatedGeofenceDetails {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/updateGeofence/:id"}
            component={(args) => <UpdateGeofenceOfVehicle {...args} />}
          />

          {/* fleet services */}
          <Route
            exact
            path={this.props.match.url + "/fleetServices"}
            component={(args) => <FleetServices {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/tfs"}
            component={(args) => <TransportFacilityService {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/tracecode"}
            component={(args) => <TraceCode {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/productrfqrequest"}
            component={(args) => <ProductsRfqMarket {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/assetquotemanagement"}
            component={(args) => <AssetQuoteManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/productquotemanagement"}
            component={(args) => <ProductQuoteManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/assetsrfqrequest"}
            component={(args) => <AssetsRfqManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewquotes" + "/:Id"}
            component={(args) => <ViewQuotes {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/viewrfqdoc" + "/:Id" + "/:docType"}
            component={(args) => <ViewRfqDoc {...args} />}
          />

          {/* LP GLOBAL NETWORK */}

          <Route
            exact
            path={this.props.match.url + "/lpGlobalNetwork"}
            component={(args) => <LpGlobalNetwork {...args} />}
          />

          {/* ORDER MANAGEMENT FOR LP */}
          <Route
            exact
            path={this.props.match.url + "/myOrder"}
            component={(args) => <OrderMainComp {...args} />}
          />
          {/* DASHBOARDS OF BROKER AND LP */}

          <Route
            exact
            path={this.props.match.url + "/logisticproviderdashboard"}
            component={(args) => <LpDashboard {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/brokerdash"}
            component={(args) => <BrokerDashCompo {...args} />}
          />
          {this.state.userTypeValue === "BROKER" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {this.state.userTypeValue === "LogisticsProvider" && (
            <Route
              component={(args) => (
                <Redirect
                  to={this.props.match.url + "/logisticproviderdashboard"}
                />
              )}
            />
          )}

          {/* Energy-Processor  */}
          <Route
            exact
            path={this.props.match.url + "/energyProcessorDashboard"}
            component={(args) => (
              <DashboardForEnergyProcessorComponent {...args} />
            )}
          />

          <Route
            exact
            path={this.props.match.url + "/ppn"}
            component={(args) => <PPNComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/broker"}
            component={(args) => <BrokerComponent {...args} />}
          />
          {this.state.userTypeValue === "ENERGY-PROCESSOR" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {this.state.userTypeValue === "Buyer" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/exploreproducts"} />
              )}
            />
          )}
          <Route
            exact
            path={this.props.match.url + "/dashboardForAD"}
            component={(args) => <DashboardComponentForAD {...args} />}
          />

          {this.state.userTypeValue === "AssetDeveloper" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/dashboardForAD"} />
              )}
            />
          )}

          {/* End of Energy-Processor */}
          {/* Storage Company */}
          <Route
            exact
            path={this.props.match.url + "/storage"}
            component={(args) => <Storage {...args} />}
          />
          {this.state.userTypeValue === "StorageCompany" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {/*End of Storage Company */}
          {/* AHSP */}
          <Route
            exact
            path={this.props.match.url + "/inputsupplies"}
            component={(args) => <InputSupplies {...args} />}
          />
          {this.state.userTypeValue === "AHSP" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {(() => {
            if (this.state.userTypeValue === "FOOD-PROCESSOR") {
              switch ((this.state.userTypeValue, this.state.userTypeRole)) {
                case ("FOOD-PROCESSOR", "STANDARD"):
                  return (
                    <Route
                      component={(args) => (
                        <Redirect
                          to={this.props.match.url + "/msmedashboard"}
                        />
                      )}
                    />
                  );
                  break;
                case ("FOOD-PROCESSOR", "PREFERRED"):
                  return (
                    <Route
                      component={(args) => (
                        <Redirect
                          to={this.props.match.url + "/msmedashboard"}
                        />
                      )}
                    />
                  );
                  break;
                case ("FOOD-PROCESSOR", "PREMIUM"):
                  return (
                    <Route
                      component={(args) => (
                        <Redirect
                          to={this.props.match.url + "/msmedashboard"}
                        />
                      )}
                    />
                  );
                  break;
                case ("FOOD-PROCESSOR", "ADMIN"):
                  return (
                    <Route
                      component={(args) => (
                        <Redirect
                          to={this.props.match.url + "/msmedashboard"}
                        />
                      )}
                    />
                  );
                  break;
                case ("FOOD-PROCESSOR", "NULL"):
                  return (
                    <Route
                      component={(args) => (
                        <Redirect to={this.props.match.url + "/profile"} />
                      )}
                    />
                  );
                  break;
                case ("FOOD-PROCESSOR", "BASIC"):
                  return (
                    <Route
                      component={(args) => (
                        <Redirect to={this.props.match.url + "/profile"} />
                      )}
                    />
                  );
                  break;
              }
            }
          })()}
          {/* AIC */}
          {this.state.userTypeValue === "AIC" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {/* AssetDistributor */}
          {this.state.userTypeValue === "AssetDistributor" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {/* OFC */}
          {this.state.userTypeValue === "OFC" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {/* CBO */}
          {this.state.userTypeValue === "CBO" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/dashboardForCBO"} />
              )}
            />
          )}
          {/* BRAND */}
          <Route
            exact
            path={this.props.match.url + "/branddashboard"}
            component={(args) => <BrandDashboard {...args} />}
          />
          {this.state.userTypeValue === "BRAND" && (
            <Route
              component={(args) => (
                <Redirect
                  to={this.props.match.url + "/branddashboard"}
                />
              )}
            />
          )}
        </Switch>

      </MainPage>
    );
  }
}
MainRouteComponent.propTypes = {
  function: PropTypes.func,
};
