import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";

export default function AEOnboardingServiceSection() {
  return (
    <>
      <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="moneyMakingSupport1"
        descriptionTitle="
        Offline Registration of Agri-Entrepreneur "
        description="Offline registration of agri-entrepreneurs serves as a vital link between agri-entrepreneur and farm enterprises. Agents facilitate account creation requests on behalf of these entrepreneurs, streamlining the process and ensuring their unique needs are met. This service not only enhances efficiency but also strengthens the overall resilience and sustainability of farm enterprises by empowering agri-entrepreneurs to access the resources and support they require.        "
        // description="Agent sends account creation request to the organization on behalf of the users related to the agriculture and he manages these users."
      />
      <HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="farm-registration"
        descriptionTitle="
        Publishing Farm Details"
        description="In this service, the agent plays a crucial role in publishing comprehensive farm details on behalf of agri-entrepreneurs. Following publication, meticulous verification processes are undertaken to ensure accuracy and transparency. This facilitates trust and confidence among stakeholders, fostering sustainable partnerships in the agricultural ecosystem."
        // description="
        // After sending the account creation request, the organization will verify the user and create the account of that user. Then agent will add the node on behalf of this created user and he will manage these nodes."
      />
    </>
  );
}
