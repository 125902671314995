// requestTypesConfig.js

const assetsOrderRequestTypesConfig = {
    NULL: {
      "FOOD-PROCESSOR": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      "BROKER": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      "BRAND": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      // Add other user types if needed...
    },
    BASIC: {
      "FOOD-PROCESSOR": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      "BROKER": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      "BRAND": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      // Add other user types if needed...
    },
    STANDARD: {
      "FOOD-PROCESSOR": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      "BROKER": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      "BRAND": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      // Add other user types if needed...
    },
    PREFFERED: {
      "FOOD-PROCESSOR": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      "BROKER": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      "BRAND": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      // Add other user types if needed...
    },
    ADMIN: {
      "FOOD-PROCESSOR": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      "BROKER": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      "BRAND": [
        { type: "asset-buy-order-req" },
        { type: "cf-agri-assets-order-req" },
        { type: "transport-asset-order-req" },
        { type: "storage-asset-order-req" },
        { type: "whitelabel-asset-request" },
      ],
      // Add other user types if needed...
    },
    // Add other roles if needed...
  };
  
  export default assetsOrderRequestTypesConfig;
  