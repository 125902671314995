import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { NotificationManager } from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialTable from "material-table";
import Fab from "@material-ui/core/Fab";
import AddMoneyIcon from "@material-ui/icons/AttachMoneyTwoTone";
import WithdrawMoneyIcon from "@material-ui/icons/Money";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import MainService from "../../Common/MainService";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./WalletMapMarkerComponent.css";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 250,
    zIndex: 99,
    float: "right",
  },
  formControlLabel: {
    transform: "translate(14px, -6px) scale(0.75)",
    color: "rgb(43, 144, 99)",
    marginTop: "-10px",
    marginLeft: "-10px",
  },
  gridRoot: {
    flexGrow: 1,
  },
  addMoney: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
    borderRadius: "10px",
    minWidth: "60px",
  },
  withdrawMoney: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
    borderRadius: "10px",
    minWidth: "60px",
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));


export default function WalletPageComponent() {
  const classes = useStyles();
  const [addMoneyPopup, setShowAddMoneyPopup] = useState(false);
  const [withdrawalMoneyPopup, setShowWithdrawalMoneyPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [addAmount, setAddAmount] = useState("500");
  const [withdrawalAmount, setWithdrawalAmount] = useState("500");
  const [maskedBankAccountNumber, setMaskedBankAccountNumber] = useState("");
  const [, setBankAccountNumber] = useState("");
  const [, setBankBranchName] = useState("");
  const [, setBankList] = useState([]);
  const [data, setData] = useState({
    wallet: 0,
  });
  const [bankStatus, setBankStatus] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState({
    columns: [
      { title: "Transaction Id", field: "transactionid" },
      { title: "Transaction Type", field: "paymenttype" },
      { title: "Status", field: "status" },
      { title: "Amount", field: "amount" },
      { title: "Timestamp", field: "timestamp" },
    ],
    data: [],
  });

  useEffect(() => {
    const { user } = MainService.getLocalData();
    const { username } = user;
    getWalletBalance(username, data);
    paymentHistory();
    getProfile();
    getBankStatus();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  /* get bank status */
  const getBankStatus = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;
    MainService.bankStatus(username)
      .then((data) => {
        setBankStatus(data.status);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const getWalletBalance = (username, dataRef) => {
    let newData = { ...dataRef };
    MainService.getWalletBalance(username)
      .then((responseData) => {
        newData = {
          ...dataRef,
          wallet: new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 10,
          }).format(responseData.walletBalance),
        };
        setIsLoading(false)
        setData(newData);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const paymentHistory = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;
    MainService.paymentHistory(username)
      .then((data) => {
        setIsLoading(false);
        setState({ ...state, data: data });
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const getBankDetails = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;

    MainService.getBankDetails(username)
      .then((data) => {
        setIsLoading(false);
        if (data.length > 0) {
          setBankAccountNumber(data[0].bankAccountNumber);
          setBankBranchName(data[0].branchName);
          maskAccountId(data[0].bankAccountNumber);
        }
        setBankList(data);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error(e);
      });
  };

  const addMoneyClick = () => {
    setShowAddMoneyPopup(!addMoneyPopup);
  };
  const withdrawMoneyClick = () => {
    getBankDetails();

    setShowWithdrawalMoneyPopup(!withdrawalMoneyPopup);
  };

  /* function uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  } */

  const getProfile = () => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    MainService.getConsumerUserInfo(parentId,username)
      .then((resp) => {
        setIsLoading(false);
        setMobileNo(resp[0].phone);
        setEmail(resp[0].email);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error("No profile data found");
      });
  };
  const addMoneySubmitClick = () => {
    // var transactionId = uuid();
    const { user } = MainService.getLocalData();
    const { username } = user;
   
    /* add money  */

    let addMoneyBody = {
      username: username,
      contact: mobileNo,
      email: email,
      amount: addAmount,
    };
    const addMoneyBodyData = JSON.stringify(addMoneyBody);
    if(addAmount <= 100000)
    {
      MainService.addMoneyToWallet(addMoneyBodyData)
      .then((data) => {
        console.log("response after updation of bank details:", data);
        paymentHistory();
        setIsLoading(false);
        //window.location.href = data.short_url;
        //window.location.replace(data.short_url);
        window.open(data.short_url, "_blank");
        setIsLoading(false);
        return NotificationManager.success(
          "payment link created successfully."
        );
        
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error("Amount not added to wallet.");
      });
    getWalletBalance(username, data);
    setShowAddMoneyPopup(!addMoneyPopup);
    }else{
      return NotificationManager.error("Amount should not more than 1 Lakh.");
    }
    
  };

  const withdrawalMoneySubmitClick = () => {
    //Srini: Need to upgrade amount values as a number
    var wala = data.wallet;
    var walaint = wala.replace(/,/g, "");
    var wa = Number(withdrawalAmount);
    var w = Number(walaint);
    const { user } = MainService.getLocalData();
    const { username } = user;
    if (wa <= w) {
      if (wa > 0) {
        //var transactionId = uuid();
        //Srini: To be integrated with another API for real transactions
        let withdrawalMoneyBody = {
          username: username,
          amount: withdrawalAmount,
        };
        const withdrawalMoneyBodyData = JSON.stringify(withdrawalMoneyBody);
        console.log("Add money to wallet body:", withdrawalMoneyBodyData);
        MainService.withdrawMoneyFromWallet(withdrawalMoneyBodyData)
          .then((data) => {
            getWalletBalance(username, data);
            paymentHistory();
            setIsLoading(false);
            return NotificationManager.success(
              "Amount Withdrawal from wallet is successfull."
            );
          })
          .catch((e) => {
            setIsLoading(false);
            console.error(e);
            if (bankStatus === "verified") {
              return NotificationManager.error("Insufficient Balance");
            } else {
              return NotificationManager.error(e);
            }
          });

        setShowWithdrawalMoneyPopup(!withdrawalMoneyPopup);
      } else {
        return NotificationManager.error("Enter atleast Rs.1 ");
      }
    } else if (wa > w > 0) {
      return NotificationManager.error("Insufficient Amount in your wallet.");
    } else {
      if (w === 0) {
        return NotificationManager.error(
          "Insufficient Amount in your wallet. Maintain atleast Rs.200"
        );
      }
    }
    getWalletBalance(username, data);
  };

  const maskAccountId = (accountNumber) => {
    if (accountNumber) {
      /** Condition will only executes if accountId is *not* undefined, null, empty, false or 0 **/
      const accountNumberlength = accountNumber.length;
      const maskedLength =
        accountNumberlength - 4; /** Modify the length as per your wish */
      let newString = accountNumber;
      for (let i = 0; i < accountNumberlength; i++) {
        if (i < maskedLength) {
          newString = newString.replace(accountNumber[i], "X");
        }
      }
      return setMaskedBankAccountNumber(newString);
    } else return; /**Will handle if no string is passed */
  };

  /* Add Money */
  const closeAddMoneyModal = (
    <span onClick={() => setShowAddMoneyPopup(!addMoneyPopup)}>X</span>
  );
  const titleAddMoney = "Add Money";
  const addMoneyBody = (
    <div>
      <div>
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="latitude">Amount to Add *</InputLabel>
          <Input
            id="addMoney"
            name="addMoney"
            type="number"
            value={addAmount}
            onChange={(e) => setAddAmount(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <i className="material-icons"> &#x20B9;</i>{" "}
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <br />
      <Button
        style={{ marginRight: "10px" }}
        variant="contained"
        color="primary"
        className="registerBtn"
        type="button"
        onClick={() => addMoneySubmitClick()}
      >
        ADD &#x20B9;{addAmount}
      </Button>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => setShowAddMoneyPopup(!addMoneyPopup)}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* Withdrawal Money */
  const closeWithdrawalMoneyModal = (
    <span onClick={() => setShowWithdrawalMoneyPopup(!withdrawalMoneyPopup)}>
      X
    </span>
  );
  const titleWithdrawalMoney = "Withdrawal Money";
  const withdrawalMoneyBody = (
    <div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <div className=" shadowBox">
              HDFC A/c <br />
              {maskedBankAccountNumber}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className=" shadowBox">
              Wallet Balance &nbsp;
              <br />
              <b> {data.wallet}</b>
            </div>
          </Grid>
        </Grid>

        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="withdrawalMoney">Amount *</InputLabel>
          <Input
            id="withdrawalMoney"
            name="withdrawalMoney"
            type="number"
            value={withdrawalAmount}
            onChange={(e) => setWithdrawalAmount(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <i className="material-icons"> &#x20B9;</i>{" "}
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <br />
      <Button
        style={{ marginRight: "10px" }}
        variant="contained"
        color="primary"
        className="registerBtn"
        type="button"
        onClick={() => withdrawalMoneySubmitClick()}
      >
        Withdraw Now
      </Button>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => setShowWithdrawalMoneyPopup(!withdrawalMoneyPopup)}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  return (
    <>
      <div>
        <Grid container className={classes.gridRoot} spacing={1}>
          <Grid item md={10} xs={6}>
            <h1>Wallet</h1>
          </Grid>
          <Grid item md={1} xs={3}>
            <Fab
              color="primary"
              size={"large"}
              aria-label="add"
              title="Add Money"
              style={{ color: "#fff" }}
              onClick={() => addMoneyClick()}
              className={classes.withdrawMoney}
            >
              <AddMoneyIcon />
            </Fab>
          </Grid>
          <Grid item md={1} xs={3}>
            <Fab
              color="primary"
              size={"large"}
              aria-label="withdrawal"
              title="Withdrawal Money"
              style={{ color: "#fff" }}
              onClick={() => withdrawMoneyClick()}
              className={classes.addMoney}
            >
              <WithdrawMoneyIcon />
            </Fab>
          </Grid>
        </Grid>

        <Grid container className={classes.gridRoot} spacing={1}>
          {/* Dashboard Cards Container */}
          <Grid item md={12} xs={12}>
            <Grid container className={classes.gridRoot}>
              <Grid item md={12} xs={12}>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Balance
                      {/* My White Label Assets */}
                    </Typography>
                    <Typography variant="h5" component="h2">
                      {isLoading ? <CircularProgress /> : data.wallet}
                    </Typography>
                    {/* <Typography className={classes.pos} color="textSecondary">
                    adjective
                  </Typography>
                  <Typography variant="body2" component="p">
                    well meaning and kindly.
                    <br />
                    {'"a benevolent smile"'}
                  </Typography> */}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12} xs={12}>
            <MaterialTable
              title={`Payment History`}
              columns={state.columns}
              data={state.data}
              editable={{}}
            >
              <CircularProgress/>
            </MaterialTable>
          </Grid>
        </Grid>
      </div>
      <CustomPopup
        closeButton={closeAddMoneyModal}
        isOpen={addMoneyPopup}
        closeModal={addMoneyPopup}
        content={addMoneyBody}
        title={titleAddMoney}
      />
      <CustomPopup
        closeButton={closeWithdrawalMoneyModal}
        isOpen={withdrawalMoneyPopup}
        closeModal={withdrawalMoneyPopup}
        content={withdrawalMoneyBody}
        title={titleWithdrawalMoney}
      />
    </>
  );
}
