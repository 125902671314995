import React from "react";
import AAMCSiteManagementComponent from "../AAMCSiteManagement/AAMCSiteManagementComponent";

export default function MyFarmsComponent(props) {
  return (
    <>
      <div className="container">
        <AAMCSiteManagementComponent siteType={["Farm"]} callingFrom = "Dashboard"/>
      </div>
    </>
  );
}
