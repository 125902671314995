import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import InvestorTabs from "../WebSite/InvestorTabs/InvestorTabs";
import "./AssetForInvestment.css";
import InvestmentROIComponent from "../WebSite/InvestmentROI/InvestmentROIComponent";
export default function AssetForInvestment() {
  return (
    <>
      <UpdatesBanner
        className="InvestmentAssetBanner text-white"
        bannerText="Assets for investments"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
      />

      <div className="subSectionWrapper mb-0">
        <InvestorTabs />
      </div>
      <InvestmentROIComponent />
    </>
  );
}
