import GridIcon from "@material-ui/icons/GridOnOutlined";
import ListIcon from "@material-ui/icons/List";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import ContentDynamicCard from "../../Common/CommonContent/ContentDynamicCard";
import ContentDynamicTable from "../../Common/CommonContent/ContentDynamicTable";
import { getCategoryID } from "../../Common/CommonContent/ContentedRelatedCommonFun";
import DeleteContent from "../../Common/CommonContent/DeleteContent";
import ViewSubcategories from "../../Common/CommonContent/ViewSubcategories";
import MainService from "../../Common/MainService";
import PlaceholderCardList from "../ModelProductManagement/PlaceHolderCardList";

const textSize = {
  sx: {
    textTransform: "none",
    background: "#3ECF8E",
    margin: "2px",
    width: { xs: "100%", sm: "70%", md: "auto" },
    "&:hover": {
      background: "#3ECF8E",
    },
  },
};

const dynamicKey = [
  "parentid",
  "contentid",
  "categoryid",
  "contentcreatorid",
  "categoryname",
  "contentowner",
  "contentsubcategory",
  "contentdescription",
  "locationurl",
];

const dynamicKeyTable = [
  "parentid",
  "contentid",
  "categoryid",
  "contentcreatorid",
  "categoryname",
  "contentowner",
  "contentsubcategory",
];

const TenderManagementComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("Tenders");
  const [tendersList, setTendersList] = useState([]);
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [filteredTenders, setFilteredTenders] = useState([]);
  const [deleteContentDialog, setDeleteContentDialog] = useState(false);
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [allSites, setAllSites] = useState([]);
  const [priority, setPriority] = useState({
    Organization: 2,
    District: 1,
  });
  const [getSubCatFilter, setSubCatFilter] = useState("");
  /* delete content */
  const [selectedTenderId, setSelectedTenderId] = useState("");
  const [getDeleteContentCreator, setDeleteContentCreator] = useState("");
  const [getDeleteContentCategory, setDeleteContentCategory] = useState("");
  // sub categories model condition
  const [openSubCategoryModel, closeSubCategoryModel] = useState(false);

  useEffect(() => {
    getAllSites();
  }, []);

  useEffect(() => {
    if (!selectedCategoryId) return;
    getSubCategories();
  }, [selectedCategoryId, setTendersList]);

  useEffect(() => {
    if (!selectedCategoryId || !username) return;
    getAllContentsList();
  }, [selectedCategoryId]);

  useMemo(async () => {
    await getCategoryID(selectedCategoryName)
      .then((data) => {
        setSelectedCategoryId(data);
      })
      .catch((err) => {
        return NotificationManager.error("something went wrong");
      });
  }, [selectedCategoryId]);

  const getAllSites = () => {
    MainService.viewAllSites(parentId, username)
      .then((data) => {
        if (data["sites"].length > 0) {
          const validSiteTypes = [
            "District",
            "Organization",
            "Department",
            "State",
          ];
          const distorgdata = data?.sites?.filter((distorg) =>
            validSiteTypes.includes(distorg.sitetype)
          );
          distorgdata.sort(
            (a, b) => priority[b.sitetype] - priority[a.sitetype]
          );
          setAllSites(distorgdata);
        }
      })
      .catch((err) => {
        return NotificationManager.error("something went wrong");
      });
  };

  const getSubCategories = () => {
    MainService.getSubCategoriesForAD(username, selectedCategoryId)
      .then((data) => {
        setsubCategoriesList(data);
      })
      .catch((err) => {
        return NotificationManager.error("something went wrong");
      });
  };

  const getAllContentsList = () => {
    setIsLoading(true);
    MainService.getContentsBasedonContentCreator(
      username,
      selectedCategoryId,
      "contentcreator"
    )
      .then((data) => {
        setTendersList(data);
        setFilteredTenders(data);
        setIsLoading(false);
      })
      .catch((err) => {
        return NotificationManager.error("something went wrong");
      });
  };

  const viewAllTendersClick = () => {
    getAllContentsList();
    setSubCatFilter("");
  };

  /* Delete Content */
  const deleteContentDialogClick = (tender) => {
    if (tender?.contentcreatorid === username) {
      setSelectedTenderId(tender.contentid);
      setDeleteContentCreator(tender.contentcreatorid);
      setDeleteContentCategory(tender.categoryid);
      setDeleteContentDialog(!deleteContentDialog);
    } else {
      return NotificationManager.error(
        "You can't delete other creator content"
      );
    }
  };

  const closeDeleteContent = () => {
    setDeleteContentDialog(!deleteContentDialog);
  };
  /* EOD Delete Content */

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  const subCategoryFilterHandler = (e) => {
    setSubCatFilter(e.target.value);
    const filteredRows = filteredTenders?.filter((row) => {
      return row.contentsubcategory
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setTendersList(filteredRows);
  };

  const actions = [
    {
      label: "Delete",
      click: (item) => deleteContentDialogClick(item),
    },
  ];

  // view subcategories
  const subCategoriesHandler = () => {
    closeSubCategoryModel(!openSubCategoryModel);
  };

  return (
    <>
      <Box className="container" id="mainContentList">
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Button
            {...textSize}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              subCategoriesHandler();
            }}
          >
            Sub Categories
          </Button>
          <FormControl variant="outlined" className="postdropdown" size="small">
            <InputLabel
              htmlFor="subCategoryName"
              style={{
                color: "rgb(43, 144, 99)",
                fontSize: "15px",
              }}
            >
              Sub Category Filter
            </InputLabel>
            <Select
              id="subCategoryName"
              name="subCategoryName"
              type="text"
              value={getSubCatFilter}
              onChange={(e) => {
                subCategoryFilterHandler(e);
              }}
              label="Select Sub-Category"
            >
              {subCategoriesList &&
                subCategoriesList.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj.contentsubcategoryname}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {getSubCatFilter !== "" && (
            <Button
              color="primary"
              variant="none"
              onClick={() => {
                viewAllTendersClick();
              }}
            >
              Clear Filter
            </Button>
          )}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={listViewClick}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={gridViewClick}
            ></Button>
          </Box>
        </Box>
        <Box className="MuiGrid-root">
          {listView &&
            (isLoading ? (
              <Box>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
            ) : (
              <ContentDynamicTable
                columns={dynamicKeyTable}
                data={tendersList}
                actions={actions}
              />
            ))}
          {gridView &&
            (isLoading ? (
              <PlaceholderCardList numberOfCards={4} heightVal={500} />
            ) : (
              <ContentDynamicCard
                dynamicKey={dynamicKey}
                data={tendersList}
                actions={actions}
              />
            ))}
        </Box>

        <DeleteContent
          reLoad={getAllContentsList}
          contentId={selectedTenderId}
          contentCreator={getDeleteContentCreator}
          contentCategory={getDeleteContentCategory}
          onOpen={deleteContentDialog}
          onClose={closeDeleteContent}
        />

        <ViewSubcategories
          subCategories={subCategoriesList}
          modelShow={openSubCategoryModel}
          closeModel={subCategoriesHandler}
          reloadComponent={getSubCategories}
          userName={username}
          categoryId={selectedCategoryId}
          categoryName={selectedCategoryName}
          parentID={parentId}
          reloadContent={getAllContentsList}
          contentFormate="DOC"
          contentOwners={allSites}
        />
      </Box>
    </>
  );
};
export default TenderManagementComponent;
