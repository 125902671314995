import React, { useState, useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MainService from "../../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import RoleProfileManagementComponent from "../RoleProfileManagement/RoleProfileManagementComponent";
import AAMCSiteManagementComponent from "../AAMCSiteManagement/AAMCSiteManagementComponent";
import RoleManagementComponent from "../RoleManagement/RoleManagement";
import RegisterUsersRequestsComponent from "../RegisterUserRequests/RegisterUserRequestsComponent";
import AAMCUserManagementComponent from "./AAMCUsersManagement";
import '../MyOrders/MyOrderStyleComponent.css';

export default function ProductionNetworkComponent(props) {
  const history = useHistory();
  const [activeComponent, setActiveComponent] = useState("UserManagement");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const UserRole = localStorage.getItem("UserRole");

  useEffect(() => {
    getRoleName();
  }, []);

  const getRoleName = () => {
    MainService.getRole(parentId, username)
      .then((res) => {
        console.log("role P n/w res:", res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const setActiveComponentByName = (componentName) => {
    setActiveComponent(componentName);
  };

  const renderComponent = (component) => {
    switch (component) {
      case "UserManagement":
        return <AAMCUserManagementComponent />;
      case "SiteManagement":
        return <AAMCSiteManagementComponent siteType={["Farm", "Physical-Market"]} />;
      case "RoleProfileManagement":
        return <RoleProfileManagementComponent siteType={["Farm", "Physical-Market"]} networkType="AAMC" />;
      case "RoleManagement":
        return <RoleManagementComponent />;
      case "Requests":
        return <RegisterUsersRequestsComponent networkType="AAMC" />;
      default:
        return null;
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          style={{ marginRight: "10px" }}
          onClick={() => history.push("./dashboardForAD")}
          edge="start"
          color="inherit"
          aria-label="back"
        >
          <ArrowBackIcon />
        </IconButton>
      </div>
      <div className="topHeadersWrapper">
        {(UserRole === "ADMIN" || UserRole === "PREMIUM") && (
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => setActiveComponentByName("UserManagement")}
            style={{ marginRight: "10px" }}
            className={activeComponent === "UserManagement" ? "activeButton" : ""}
          >
            User Management
          </Button>
        )}
        {(UserRole === "ADMIN" || UserRole === "PREMIUM") && (
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => setActiveComponentByName("SiteManagement")}
            style={{ marginRight: "10px" }}
            className={activeComponent === "SiteManagement" ? "activeButton" : ""}
          >
            Site Management
          </Button>
        )}
        <Button
          color="success"
          size="large"
          variant="contained"
          onClick={() => setActiveComponentByName("RoleManagement")}
          style={{ marginRight: "10px" }}
          className={activeComponent === "RoleManagement" ? "activeButton" : ""}
        >
          Role Management
        </Button>
        {(UserRole === "ADMIN" || UserRole === "PREMIUM") && (
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => setActiveComponentByName("RoleProfileManagement")}
            style={{ marginRight: "10px" }}
            className={activeComponent === "RoleProfileManagement" ? "activeButton" : ""}
          >
            Role Profile Management
          </Button>
        )}
        
        {(UserRole === "ADMIN" || UserRole === "PREMIUM") && (
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => setActiveComponentByName("Requests")}
            style={{ marginRight: "10px" }}
            className={activeComponent === "Requests" ? "activeButton" : ""}
          >
            Requests
          </Button>
        )}
      </div>
      <div className="container" id="mainContentList">
        <div style={{ paddingTop: "20px" }}>
          {activeComponent && renderComponent(activeComponent)}
        </div>
      </div>
    </>
  );
}
