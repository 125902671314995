import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";

const textCss = {
  sx: { textTransform: "none" },
};

function UpdateRfqStatus({
  selectedStatus,
  selectedRfqId,
  openModel,
  closeModel,
  reLoad,
  reqType,
}) {
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [updateStatus, setUpdateStatus] = useState("");
  const [status, setStatus] = useState({});

  useEffect(() => {
    let statusOptions;
    if (reqType === "update-order-status") {
      statusOptions = {
        created: [
          { value: "accepted", label: "Accepted" },
          { value: "rejected", label: "Rejected" },
        ],
        accepted: [
          { value: "loaded", label: "Loaded" },
          { value: "unloaded", label: "Unloaded" },
        ],
        loaded: [{ value: "inprogress", label: "InProgress" }],
        inprogress: [{ value: "delivered", label: "Delivered" }],
      };
    } else {
      statusOptions = {
        created: [{ value: "open", label: "Open" }],
        open: [{ value: "close", label: "Close" }],
      };
    }
    setStatus(statusOptions);
  }, [reqType]);

  const updateRFQStatusHandler = () => {
    const statusKey =
      reqType === "update-order-status" ? "orderStatus" : "rfqStatus";
    const statusUpdate = { [statusKey]: updateStatus };

    MainService.updateRFQStatus(
      selectedRfqId,
      statusUpdate,
      parentId,
      username,
      reqType
    )
      .then((res) => {
        reLoad();
        closeModel();
        setUpdateStatus("");
        NotificationManager.success(res[statusKey]);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openModel}
        onClose={closeModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Update Status</DialogTitle>
        <DialogContent>
          <StatusSelect
            updateStatus={updateStatus}
            setUpdateStatus={setUpdateStatus}
            options={status[selectedStatus]}
          />
        </DialogContent>
        <DialogActions>
          <Button
            {...textCss}
            color="primary"
            variant="contained"
            onClick={updateRFQStatusHandler}
          >
            Submit
          </Button>
          <Button
            {...textCss}
            color="error"
            variant="outlined"
            onClick={closeModel}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const StatusSelect = ({ updateStatus, setUpdateStatus, options }) => (
  <FormControl fullWidth size="medium" margin="dense" variant="standard">
    <InputLabel htmlFor="status" variant="standard">
      Select Status:
    </InputLabel>
    <Select
      id="status"
      name="status"
      type="text"
      value={updateStatus}
      onChange={(e) => setUpdateStatus(e.target.value)}
    >
      {options &&
        options?.map((option, index) => (
          <MenuItem key={index} value={option?.value}>
            {option?.label}
          </MenuItem>
        ))}
    </Select>
  </FormControl>
);

export default memo(UpdateRfqStatus);
