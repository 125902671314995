import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { NotificationManager } from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import MainService from "../../Common/MainService";
import "./DashboardePageComponent.css";

/* new map1 */
 import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";



const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 250,
    zIndex: 99,
    float: "right",
  },
  formControlLabel: {
    transform: "translate(14px, -6px) scale(0.75)",
    color: "rgb(43, 144, 99)",
    marginTop: "-10px",
    marginLeft: "-10px",
  },
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));


function DashboardePageComponent() {

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    growers: 0,
    assets: 0,
    category: 0,
    myAssets: 0,
    profit: 0,
  });
  
  const [growerList, setGrowerList] = useState([]);
  const [solutionList, setSolutionList] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState(null);
  const { user } = MainService.getLocalData();
    const { username,parentId } = user;
  

  useEffect(() => {
    
    getDashboardData();
    getAllSolutions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedSolution) return;
    getAllSolutionsSite();
  }, [selectedSolution]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllSolutions = () => {
    MainService.getAllSolutions(parentId,parentId)
      .then((responseData) => {
        if (responseData && responseData.length > 0) {
          setSelectedSolution({
            ...responseData[0],
            label: responseData[0].solutionname,
            value: responseData[0].solutionid,
          });
        }
        setSolutionList(responseData);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const getAllSolutionsSite = () => {
    const { solutionid } = selectedSolution;
    MainService.getAllSolutionsSite(solutionid)
      .then((responseData) => {
        setGrowerList(responseData);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const getDashboardData = () => {
    setIsLoading(true);
    let newData = { ...data };
    MainService.getDashboardData(parentId,username)
      .then((responseData) => {
        newData = {
          ...newData,
          growers: `${responseData.AssociatedAssetDevelopersCount} / ${responseData.TotalAssetDevelopersCount}`,
        };
        setData(newData);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      })
      .finally(() => {
        getAssetsCount(username, newData);
      });
  };

  const getAssetsCount = (username, dataRef) => {
    let newData = { ...dataRef };
    MainService.getAssetsCount(parentId,username)
      .then((responseData) => {
        newData = {
          ...dataRef,
          myAssets: responseData.WhitelabelledAssetsCount,
          assets: responseData.AssetsCount,
        };
        setData(newData);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      })
      .finally(() => {
        getProfitSummary(username, newData);
      });
  };

  const getProfitSummary = (username, dataRef) => {
    let newData = { ...dataRef };
    MainService.getProfitSummary(parentId,username)
      .then((responseData) => {
        newData = {
          ...dataRef,
          profit: new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 10,
          }).format(responseData.totalprofitSummary.totalprofit),
        };

        setData(newData);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
 
 /* new map1 */
 const [selected, setSelected] = useState({});
 const options = {    
   imagePath:'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
}
 const onSelect = (item) => {
   setSelected(item);
 };
 const mapStyles = {
   height: "100vh",
   width: "100%",
 };

 const defaultCenter = {
   lat: 13.093426,
   lng: 77.655773,
 };




  return (
    <div>
      <h1>Dashboard</h1>
      
      <Grid container spacing={0}>
        {/* Dashboard Cards Container */}
        <Grid item md={12} xs={12}>
          <Grid container className={classes.gridRoot} spacing={2}>
            <Grid item md={4} xs={12}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    My AAMC
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {isLoading ? <CircularProgress /> : data.growers}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button disabled size="small">
                    <a 
                      href="/main/growers"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Show Details
                    </a>
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item md={4} xs={12}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    My Assets
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {isLoading ? <CircularProgress /> : data.myAssets}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button disabled size="small">
                    <a
                      href="/main/myassets"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Show Details
                    </a>
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item md={4} xs={12}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Profit
                    {/* My White Label Assets */}
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {isLoading ? <CircularProgress /> : data.profit}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button disabled size="small">
                    <a
                      href="/main/statement"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Show Details
                    </a>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* Map Filter Container */}
        <Grid item md={12}>
          <h3 style={{ float: "left", marginTop: "25px" }}>
            View AAMC to invest
          </h3>
          <br/>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel className={classes.formControlLabel}>
              Select AAMC
            </InputLabel>
            <Select
              placeholder="Select Grower"
              value={selectedSolution}
              onChange={(data) => {
                setSelectedSolution(data);
              }}
              options={solutionList.map((idObj) => ({
                ...idObj,
                label: idObj.solutionname,
                value: idObj.solutionid,
              }))}
            />
          </FormControl>
        </Grid>

        
        {/* New Map */}
        <Grid item md={12} sm={12} xs={12}>
          <LoadScript googleMapsApiKey="AIzaSyDzJglX1KEZkksRUr_zBWrHAG3ZYeUZt4o">
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={4}
              center={defaultCenter}
              
            >
              <MarkerClusterer
                options={options} 
                averageCenter={true}             
              >
                {(clusterer) =>
                  growerList.map((markerObj, index) => {
                    return (
                      <Marker
                        key={markerObj.siteid}
                        position={{
                          lat: parseFloat(markerObj.latitude),
                          lng: parseFloat(markerObj.longitude),
                        }}
                        
                        clusterer={clusterer}
                        onClick={() => onSelect(markerObj)}
                      />
                    );
                  })
                }
              </MarkerClusterer>

              {selected.latitude && selected.longitude && (
                <InfoWindow
                  position={{
                    lat: parseFloat(selected.latitude),
                    lng: parseFloat(selected.longitude),
                  }}
                  clickable={true}
                  onCloseClick={() => setSelected({})}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {selected.sitename}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <b>ID:</b> {selected.siteid}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <b> Name:</b>Site{selected.sitename}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <b>Site Type:</b>Site{selected.industrytype}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <b> Distance: </b>Site{selected.distance} KM
                    </Typography>
                  </CardContent>
                </InfoWindow>
              )}
            </GoogleMap>
          </LoadScript>
        </Grid> 

       
      </Grid>
    </div>
  );
}

export default DashboardePageComponent;