import React from 'react';
import { Container } from '@material-ui/core';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import "./FarmerComponent.css";
import Button from "@material-ui/core/Button";

export default class FarmerComponent extends React.Component{
    render(){
        return(
            <>
            <AppBar className="gbr_header" position="fixed">
              <Toolbar>
                <Typography variant="h6" className="whiteTextColor navTitle">
                  <Hidden only={["xs"]}>
                    <a href="/">GBR</a>
                  </Hidden>
    
                  <Hidden smUp>
                    <a href="/">GBR</a>
                  </Hidden>
                </Typography>
                <Button className="whiteTextColor">
              <a href="/ExploreTechnology">AgriStack</a>
            </Button>

            <Button className="whiteTextColor">
              <a href="/">Apps</a>
            </Button>
            <Button className="whiteTextColor">
              <a href="/Grower">Become A Grower</a>
            </Button>
            <Button className="whiteTextColor">
              <a href="/Farmer">Become A Farmer</a>
            </Button>
            <Button className="whiteTextColor">
            <a href="/businesspartner">Business Partner</a>
          </Button>
            <Button className="whiteTextColor">
              <a href="/Investments">Investments</a>
            </Button>
            <Button className="whiteTextColor">
              <a href="/auth/3/signin">Invest Now</a>
            </Button>
              </Toolbar>
            </AppBar>
            <Container maxWidth={false} className="farmerContainer">
                <div>
                </div>
                <div className="mainCnt">
            
                   <span className="descCnt">
                        <span className="iconCnt">
                            <img src="./assets/img/icons/Browser.svg" alt='' width="80" />
                        </span>
                        <h3>
                            <p>Online Application</p>
                        </h3>
                    </span>

                     <span className="descCnt">
                        <span className="iconCnt">
                            <img src="./assets/img/icons/Telephone.svg" alt='' width="80" />
                        </span>
                        <h3>
                            <p>Call Us on</p>
                            <p>1800-00-000</p>
                        </h3>
                    </span>            
                    <a href="/">
                        <span className="iconCnt closeCnt">
                            <i className="material-icons">close</i>
                        </span>
                    </a>
            
                </div>
            </Container>
            
            </>
        )
    }
}