import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import RoleManagementComponent from "../AssetDeveloperRole/RoleManagement/RoleManagement";
import RoleProfileManagementComponent from "../AssetDeveloperRole/RoleProfileManagement/RoleProfileManagementComponent";
import TransportAssetManagement from "../AssetDeveloperRole/TransportManagement/TransportManagementComponent";
import MainService from "../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import PlantOperatorComponent from "../PlantOperator/PlantOperatorComponent";
import PlantQAQCComponent from "../PlantQAQC/PlantQAQCComponent";
import PlantTechnicianComponent from "../PlantTechnician/PlantTechnicianComponent";
import AssetHealthStatusComponent from "../AssetDeveloperRole/AssetHealthStatus/AssetHealthStatusComponent";
import ProcessHealthStatusComponent from "../AssetDeveloperRole/ProcessHealthStatus/ProcessHealthStatusComponent";
import PlantExecutiveComponent from "../PlantExecutive/PlantExecutiveComponent";
import PlantManagerComponent from "../PlantManager/PlantManagerComponent";
import AAMCSiteManagementComponent from "../AssetDeveloperRole/AAMCSiteManagement/AAMCSiteManagementComponent";
import PlantOwnerComponent from "../PlantOwner/PlantOwnerComponent";
import RegisterUsersRequestsComponent from "../AssetDeveloperRole/RegisterUserRequests/RegisterUserRequestsComponent";

export default function PPNComponent(props) {
  const [viewRole, setViewRole] = useState(false);
  const [viewPlantQAQC, setViewPlantQAQC] = useState(false);

  const [viewAssetHealthStatus, setViewAssetHealthStatus] = useState(false);
  const [viewPlantOwner, setViewPlantOwner] = useState(true);
  const [viewProcessHealthStatus, setViewProcessHealthStatus] = useState(false);
  const [viewTechnician, setViewTechnician] = useState(false);
  const [viewPlantExecutive, setViewPlantExecutive] = useState(false);
  const [viewSiteManagement, setViewSiteManagement] = useState(false);
  const [viewPlantOperator, setViewPlantOperator] = useState(false);
  const [viewPlantManager, setViewPlantManager] = useState(false);
  const [viewUserRequests, setViewUserRequests] = useState(false);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [roleNameValue, setRoleNameValue] = useState("");
  const [viewSuperRole, setViewSuperRole] = useState(false);

  useEffect(() => {
    getRoleName();
  }, []);
  const getRoleName = () => {
    MainService.getRole(parentId, username)
      .then((res) => {
        setRoleNameValue(res.role);
        console.log("role P n/w res:", res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const viewRequestsClick = () => {
    setViewRole(false);
    setViewPlantQAQC(false);

    setViewPlantOwner(false);

    setViewTechnician(false);
    setViewPlantExecutive(false);
    setViewPlantOperator(false);
    setViewSiteManagement(false);
    setViewPlantManager(false);
    setViewSuperRole(false);
    setViewUserRequests(true);
  };
  const viewRoleClick = () => {
    setViewRole(true);
    setViewPlantQAQC(false);

    setViewPlantOwner(false);

    setViewTechnician(false);
    setViewPlantExecutive(false);
    setViewPlantOperator(false);
    setViewSiteManagement(false);
    setViewPlantManager(false);
    setViewSuperRole(false);
    setViewUserRequests(false);
  };

  const viewPlantQAQCClick = () => {
    setViewRole(false);
    setViewPlantQAQC(true);

    setViewPlantOwner(false);

    setViewTechnician(false);
    setViewPlantExecutive(false);

    setViewSiteManagement(false);
    setViewPlantOperator(false);
    setViewPlantManager(false);
    setViewSuperRole(false);
    setViewUserRequests(false);
  };
  const viewPlantOperatorClick = () => {
    setViewRole(false);
    setViewPlantQAQC(false);

    setViewPlantOwner(false);

    setViewTechnician(false);
    setViewPlantExecutive(false);

    setViewSiteManagement(false);
    setViewPlantOperator(true);
    setViewSuperRole(false);
    setViewPlantManager(false);
    setViewUserRequests(false);
  };
  const viewPlantManagerClick = () => {
    setViewRole(false);
    setViewPlantQAQC(false);

    setViewPlantOwner(false);

    setViewTechnician(false);
    setViewPlantExecutive(false);

    setViewSiteManagement(false);
    setViewPlantOperator(false);
    setViewSuperRole(false);
    setViewPlantManager(true);
    setViewUserRequests(false);
  };

  const viewPlantOwnerClick = () => {
    setViewRole(false);
    setViewPlantQAQC(false);

    setViewPlantOwner(true);

    setViewTechnician(false);
    setViewPlantExecutive(false);
    setViewPlantOperator(false);
    setViewSiteManagement(false);
    setViewPlantManager(false);
    setViewSuperRole(false);
    setViewUserRequests(false);
  };

  const viewTechnicianClick = () => {
    setViewRole(false);
    setViewPlantQAQC(false);

    setViewPlantOwner(false);

    setViewTechnician(true);
    setViewPlantExecutive(false);
    setViewPlantOperator(false);
    setViewSiteManagement(false);
    setViewPlantManager(false);
    setViewSuperRole(false);
    setViewUserRequests(false);
  };
  const viewPlantExecutiveClick = () => {
    setViewRole(false);
    setViewPlantQAQC(false);

    setViewPlantOwner(false);

    setViewTechnician(false);
    setViewPlantExecutive(true);
    setViewPlantOperator(false);
    setViewSiteManagement(false);
    setViewPlantManager(false);
    setViewSuperRole(false);
    setViewUserRequests(false);
  };

  const viewSiteManagementClick = () => {
    setViewRole(false);
    setViewPlantQAQC(false);

    setViewPlantOwner(false);

    setViewTechnician(false);
    setViewPlantExecutive(false);
    setViewPlantOperator(false);
    setViewSiteManagement(true);
    setViewPlantManager(false);
    setViewSuperRole(false);
    setViewUserRequests(false);
  };

  const viewSuperRoleClick = () => {
    setViewRole(false);
    setViewPlantQAQC(false);

    setViewPlantOwner(false);

    setViewTechnician(false);
    setViewPlantExecutive(false);
    setViewPlantOperator(false);
    setViewSiteManagement(false);
    setViewPlantManager(false);
    setViewSuperRole(true);
    setViewUserRequests(false);
  };

  return (
    <>
      <div className="topHeadersWrapper">
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSiteManagementClick();
            }}
          >
            Site Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSuperRoleClick();
            }}
          >
            Role Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewRoleClick();
            }}
          >
            Role Profile Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewRequestsClick();
            }}
          >
            Requests
          </Button>
        </div>
        &nbsp;
      </div>

      <div className="container" id="mainContentList">
        <h2>PPN Network</h2>
        &nbsp;&nbsp;
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewPlantOwnerClick();
              }}
            >
              Plant Owner
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewPlantOperatorClick();
              }}
            >
              PlantOperator
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewPlantManagerClick();
              }}
            >
              PlantManager
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewPlantExecutiveClick();
              }}
            >
              PlantExecutive
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewPlantQAQCClick();
              }}
            >
              PlantQA-QC
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewTechnicianClick();
              }}
            >
             Plant Technician
            </Button>
          </div>
        </div>
        &nbsp;
      </div>

      <div style={{ paddingTop: "20px" }}>
        {viewSiteManagement && (
          <AAMCSiteManagementComponent siteType={["Plant"]} />
        )}
         {viewRole && (
          <RoleProfileManagementComponent siteType={["Plant"]} networkType="PPN" />
        )}
        {viewPlantOperator && <PlantOperatorComponent />}
        {viewPlantExecutive && <PlantExecutiveComponent />}
       
        {viewPlantManager && <PlantManagerComponent />}
        {viewPlantOwner && <PlantOwnerComponent />}
        {viewPlantQAQC && <PlantQAQCComponent />}

        {viewUserRequests && <RegisterUsersRequestsComponent networkType="PPN"/>}
        {viewTechnician && <PlantTechnicianComponent />}
        {viewSuperRole && <RoleManagementComponent />}
      </div>
    </>
  );
}
