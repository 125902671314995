import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import DeleteContent from "../../Common/CommonContent/DeleteContent";
import DeleteContentSubCategory from "../../Common/CommonContent/DeleteContentSubCategory";
import CreateContentSubCategory from "../../Common/CommonContent/CreateContentSubCategory";
import { getCategoryID } from "../../Common/CommonContent/ContentedRelatedCommonFun";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

const RFQContentManagement = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [siteupdatesPopup, setShowSiteUpdatesPopup] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("RFQ");
  const [subCategoryNameValue, setSubCategoryNameValue] = useState("");
  const [siteUpdatesList, setSiteUpdatesList] = useState([]);
  const [filteredSiteUpdatesList, setFilteredSiteUpdatesList] = useState([]);
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [subcategorydialog, setSubCategoryDialog] = React.useState(false);
  const [siteupdatesNameValue, setSiteUpdatesNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [siteupdatesFile, setSiteUpdatesFile] = useState(null);
  const [siteupdatesFileDescription, setSiteUpdatesFileDescription] =
    useState(null);
  const [deleteSubcategoryDialog, setDeleteSubCategoryDialog] = useState(false);
  const [deleteContentDialog, setDeleteContentDialog] = useState(false);
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  /* delete content */
  const [selectedContentId, setSelectedContentId] = useState("");
  const [getDeleteContentCreator, setDeleteContentCreator] = useState("");
  const [getDeleteContentCategory, setDeleteContentCategory] = useState("");

  useEffect(() => {
    if (!selectedCategoryId) return;
    getSubCategories();
  }, [selectedCategoryId]);

  useMemo(async () => {
    await getCategoryID(selectedCategoryName)
      .then((data) => {
        setSelectedCategoryId(data);
      })
      .catch((e) => {
        return NotificationManager.error("error");
      });
  }, []);

  const getSubCategories = () => {
    MainService.getSubCategoriesForAD(username, selectedCategoryId)
      .then((data) => {
        setsubCategoriesList(data);
        setIsLoading(false);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const getAllContentsList = () => {
    setSubCategoryNameValue("");
    setIsLoading(true);
    MainService.getContentsBasedonContentCreator(
      username,
      selectedCategoryId,
      "contentcreator"
    )
      .then((data) => {
        setSiteUpdatesList(data);
        setFilteredSiteUpdatesList(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useMemo(() => {
    if (!parentId) return;
    if (!selectedCategoryId) return;
    getAllContentsList();
  }, [selectedCategoryId]);

  const createSubCategoryClick = () => {
    setSubCategoryDialog(!subcategorydialog);
  };

  /* Delete Sub-Category */
  const deleteSubCategoryClick = () => {
    setDeleteSubCategoryDialog(!deleteSubcategoryDialog);
  };
  /* EOD Delete Sub-Category */

  const createSiteUpdatesClick = () => {
    setShowSiteUpdatesPopup(!siteupdatesPopup);
  };
  const submitSiteUpdatesClick = () => {
    if (siteupdatesFile === null) {
      return NotificationManager.error("Please Choose File");
    }
    if (siteupdatesNameValue === "") {
      return NotificationManager.error("Enter Name");
    }
    if (subCategoryNameValue === "") {
      return NotificationManager.error("Select Sub Category");
    }
    const siteupdatesData = new FormData();
    siteupdatesData.append("file", siteupdatesFile);
    siteupdatesData.append("mimetype", siteupdatesFile.type);
    siteupdatesData.append("categoryid", selectedCategoryId);
    siteupdatesData.append("categoryname", selectedCategoryName);
    siteupdatesData.append("contentsubcategory", subCategoryNameValue);
    siteupdatesData.append("contentname", siteupdatesNameValue);
    siteupdatesData.append("assetdeveloperid", parentId);
    siteupdatesData.append("contentdescription", descriptionValue);
    siteupdatesData.append("contentcreator", username);
    siteupdatesData.append("contentformat", "DOC");
    siteupdatesData.append("price", "none");
    siteupdatesData.append("filedescription", siteupdatesFileDescription);
    siteupdatesData.append("contentownerid", username);
    siteupdatesData.append("unit", "INR");
    siteupdatesData.append("discountedprice", "none");
    siteupdatesData.append("expirydate", "none");
    siteupdatesData.append("event", "none");

    MainService.createContent(siteupdatesData, parentId)
      .then((res) => {
        getAllContentsList();
        return NotificationManager.success(res.status || "rfq uploaded");
      })
      .catch((e) => {
        return NotificationManager.error(e || "something went wrong");
      });
    setShowSiteUpdatesPopup(!siteupdatesPopup);
  };
  /* Delete Content */
  const deleteContentDialogClick = (siteupdates) => {
    if (siteupdates.contentcreatorid === username) {
      setSelectedContentId(siteupdates.contentid);
      setDeleteContentCreator(siteupdates.contentcreatorid);
      setDeleteContentCategory(siteupdates.categoryid);
      setDeleteContentDialog(!deleteContentDialog);
    } else {
      return NotificationManager.error(
        "You can't delete other creator content"
      );
    }
  };

  const closeDeleteContent = () => {
    setDeleteContentDialog(!deleteContentDialog);
  };
  /* EOD Delete Content */

  const viewAllRFQHandler = () => {
    getAllContentsList();
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* Create RFQ */
  const closeSiteUpdatesModal = (
    <span
      onClick={() => {
        setShowSiteUpdatesPopup(!siteupdatesPopup);
      }}
    >
      X
    </span>
  );
  const titleSiteUpdates = "RFQ Content";
  const siteupdatesBody = (
    <Box>
      <FormControl
        fullWidth={true}
        size="medium"
        margin="dense"
        variant="standard"
      >
        <InputLabel htmlFor="categoryName">Category Name :</InputLabel>
        <Input
          disabled
          id="categoryName"
          name="categoryName"
          type="text"
          value={selectedCategoryName.toUpperCase()}
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        size="medium"
        margin="dense"
        variant="standard"
      >
        <InputLabel htmlFor="subCategoryName">Sub-Category Name :</InputLabel>
        <Select
          id="subCategoryName"
          name="subCategoryName"
          type="text"
          value={subCategoryNameValue}
          onChange={(e) => {
            setSubCategoryNameValue(e.target.value);
          }}
        >
          {subCategoriesList &&
            subCategoriesList?.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.contentsubcategoryname}>
                {idObj.contentsubcategoryname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        fullWidth={true}
        size="medium"
        margin="dense"
        variant="standard"
      >
        <InputLabel htmlFor="siteupdatesName">SiteUpdates Name :</InputLabel>
        <Input
          id="siteupdatesName"
          name="siteupdatesName"
          type="text"
          value={siteupdatesNameValue}
          onChange={(e) => {
            setSiteUpdatesNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        size="medium"
        margin="dense"
        variant="standard"
      >
        <InputLabel htmlFor="contentowner"> Content Owner:</InputLabel>
        <Input
          disabled
          id="contentowner"
          name="contentowner"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        size="medium"
        margin="dense"
        variant="standard"
      >
        <InputLabel htmlFor="contentCreator"> Content Creator:</InputLabel>
        <Input
          disabled
          id="contentCreator"
          name="contentCreator"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        size="medium"
        margin="dense"
        variant="standard"
      >
        <InputLabel htmlFor="zone"> Content Approver:</InputLabel>
        <Input
          disabled
          id="contentApprover"
          name="contentApprover"
          type="text"
          value={parentId}
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        size="medium"
        margin="dense"
        variant="standard"
      >
        <InputLabel htmlFor="description"> Description:</InputLabel>
        <Input
          id="description"
          name="description"
          type="text"
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        size="medium"
        margin="dense"
        variant="standard"
      >
        <InputLabel htmlFor="doc">file:</InputLabel>
        <Input
          id="doc"
          name="doc"
          type="file"
          onChange={(e) => {
            setSiteUpdatesFile(e.target.files[0]);
          }}
        />
      </FormControl>
      <Box>
        <Button
          sx={{ m: "5px" }}
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => {
            submitSiteUpdatesClick();
          }}
        >
          Submit
        </Button>
        <Button
          sx={{ m: "5px" }}
          variant="outlined"
          color="error"
          type="submit"
          onClick={() => {
            setShowSiteUpdatesPopup(!siteupdatesPopup);
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Box className="container" id="mainContentList">
        <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Upload RFQ"
          className={classes.fab}
          onClick={() => {
            createSiteUpdatesClick();
          }}
        >
          <AddIcon></AddIcon>
        </Fab>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              viewAllRFQHandler();
            }}
          >
            View All RFQ
          </Button>

          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              createSubCategoryClick();
            }}
          >
            Create Sub-Category
          </Button>
          <Button
            sx={{
              background: "#3ECF8E",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
            }}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              deleteSubCategoryClick();
            }}
          >
            Delete Sub-Category
          </Button>
          <FormControl variant="outlined" size="small" className="postdropdown">
            <InputLabel
              style={{
                color: "rgb(43, 144, 99)",
                fontSize: "15px",
              }}
            >
              Filter by Sub-Category
            </InputLabel>
            <Select
              id="selectedSubCategory"
              value={subCategoryNameValue}
              onChange={(e) => {
                setSubCategoryNameValue(e.target.value);
                const filteredRows = filteredSiteUpdatesList.filter((row) => {
                  return row.contentsubcategory
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase());
                });
                setSiteUpdatesList(filteredRows);
              }}
              label="Select Sub-Category"
            >
              {subCategoriesList &&
                subCategoriesList.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj.contentsubcategoryname}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <div className="MuiGrid-root">
          <div style={{ float: "right" }}>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={listViewClick}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={gridViewClick}
            ></Button>
          </div>
          {listView && (
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell align="center" colSpan={1}>
                        Doc Url
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Option
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {siteUpdatesList?.length > 0 ? (
                      siteUpdatesList &&
                      siteUpdatesList
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((siteupdates, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {siteupdates.locationurl}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    deleteContentDialogClick(siteupdates);
                                  }}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={2}>
                          RFQ Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={siteUpdatesList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          {gridView && (
            <div className="cardStyleForDashboardItems">
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  {siteUpdatesList?.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {siteUpdatesList &&
                        siteUpdatesList?.map((siteupdates, index) => (
                          <div
                            key={index}
                            className="cardStyleForDashboardItemsBody"
                          >
                            <span>
                              <p>
                                <b>Assetdeveloperid:</b>
                                {siteupdates.parentid}
                              </p>
                            </span>
                            <p>
                              <b>Content Creator:</b>
                              {siteupdates.contentcreatorid}
                            </p>
                            <p>
                              <b>Category:</b>
                              {siteupdates.categoryname}
                            </p>
                            <p>
                              <b>Organization:</b>
                              {siteupdates.contentowner}
                            </p>
                            <p>
                              <b>Sub Categoryname:</b>
                              {siteupdates.contentsubcategory}
                            </p>
                            <p>
                              <b>Description:</b>
                              {siteupdates.contentdescription}
                            </p>
                            <p>
                              <b>Doc Url:</b>
                              {siteupdates.locationurl}
                            </p>
                            <div>
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => {
                                  deleteContentDialogClick(siteupdates);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <h4>No RFQ Found</h4>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <CreateContentSubCategory
          reLoad={getSubCategories}
          userName={username}
          categoryId={selectedCategoryId}
          categoryName={selectedCategoryName}
          parentID={parentId}
          onOpen={subcategorydialog}
          onClose={createSubCategoryClick}
        />

        <DeleteContentSubCategory
          reLoad={getSubCategories}
          userName={username}
          categoryId={selectedCategoryId}
          subCategoryList={subCategoriesList}
          onOpen={deleteSubcategoryDialog}
          onClose={deleteSubCategoryClick}
        />

        <DeleteContent
          reLoad={getAllContentsList}
          contentId={selectedContentId}
          contentCreator={getDeleteContentCreator}
          contentCategory={getDeleteContentCategory}
          onOpen={deleteContentDialog}
          onClose={closeDeleteContent}
        />

        <CustomPopup
          closeButton={closeSiteUpdatesModal}
          isOpen={siteupdatesPopup}
          closeModal={siteupdatesPopup}
          content={siteupdatesBody}
          title={titleSiteUpdates}
        />
      </Box>
    </>
  );
};

export default RFQContentManagement;
