import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, Button, Typography } from "@mui/material";
import countryList from "country-list";
import React, { Fragment, memo, useEffect, useReducer, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../../Common/MainService";
import {
  renderDropdown,
  renderMultiSelect,
  renderSingleSelect,
  renderTextField,
} from "../RFQCommon";
import {
  initialState,
  storageasset,
  transportasset,
} from "./AssetsStateMaintenance";

const commonTypography = {
  sx: {
    color: "#1976d2",
    textAlign: "left",
    fontSize: "1rem",
    fontWeight: "bold",
    margin: "5px",
  },
};

const border = {
  sx: {
    boxSizing: "border-box",
    bgcolor: " #f0f0f0",
    padding: "5px" /* Adequate padding */,
    border: "1.5px solid #3ECF8E",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
};

const filedResponsive = {
  sx: {
    display: "flex",
    boxSizing: "border-box",
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
      lg: "row",
      xl: "row",
    },
  },
};

function formReducer(state, action) {
  switch (action.type) {
    case "EMPTY_STATE":
      return {};
    case "UPDATE_STATE":
      return { ...state, ...action.payload };
    default:
      return { ...state, [action.field]: action.value };
  }
}

const AssetsRfqForm = ({
  reqTypes,
  verticalsIds,
  categoryTypes,
  assertTypes,
  models,
  reLoad,
}) => {
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getUserDetails, setUserDetails] = useState({});
  const [state, dispatch] = useReducer(formReducer, {});
  const [getCountryList, setCountryList] = useState([]);
  const transport = [{ type: "yes" }, { type: "no" }];
  const packageTypes = [
    { type: "NULL" },
    { type: "BASIC" },
    { type: "STANDARD" },
    { type: "PREFFERED" },
    { type: "PREMIUM" },
    { type: "ADMIN" },
  ];
  const grade = [
    { type: "A" },
    { type: "B" },
    { type: "C" },
    { type: "D" },
    { type: "F" },
  ];
  const getAppConfig = () => window.AppConfigData;
  const availableornot = [{ type: "true" }, { type: "false" }];

  const countries = countryList.getData();
  const countryOptions = countries?.map((country) => ({
    value: country?.code,
    label: country?.name,
  }));

  useEffect(() => {
    dispatch({ type: "EMPTY_STATE" });
    const initializeFormState = () => {
      if (reqTypes === "cf-agri-assets-rfq-req") {
        dispatch({ type: "UPDATE_STATE", payload: initialState });
      } else if (reqTypes === "transport-asset-rfq-req") {
        dispatch({
          type: "UPDATE_STATE",
          payload: transportasset,
        });
      } else if (reqTypes === "storage-asset-rfq-req") {
        dispatch({
          type: "UPDATE_STATE",
          payload: storageasset,
        });
      }
    };
    initializeFormState();
  }, [reqTypes]);

  useEffect(() => {
    MainService.getConsumerUserInfo(parentId, username)
      .then((data) => {
        setUserDetails(data[0]);
      })
      .catch((e) => {
        return NotificationManager.error("No profile data found");
      });
    dispatch({
      field: "requestType",
      value: reqTypes,
    });
  }, [reqTypes]);

  useEffect(() => {
    const defaultPersonalDetails = {
      networkId: getAppConfig().EnterpriseId,
      parentId: getUserDetails?.enterpriseid,
      username: getUserDetails?.username,
      name: getUserDetails?.username,
      phoneNo: getUserDetails?.phone,
      emailId: getUserDetails?.email,
      city: getUserDetails?.city,
      state: getUserDetails?.state,
      panNumber: getUserDetails?.pancard,
      latitude: getUserDetails?.latitude,
      longitude: getUserDetails?.longitude,
    };
    dispatch({
      field: "personalDetails",
      value: { ...state.personalDetails, ...defaultPersonalDetails },
    });
  }, [getUserDetails]);

  const handleDeleteAsset = (index) => {
    const updatedAssetDetails = [...state.assetDetails];
    updatedAssetDetails.splice(index, 1); // Remove the asset at the specified index
    dispatch({
      field: "assetDetails",
      value: updatedAssetDetails,
    });
  };

  const handleAddAsset = () => {
    const newAsset = {
      assetVerticalId: "",
      assetVerticalFriendlyName: "",
      category: "",
      legacyAssetType: "",
      modelId: "",
      cn: "",
      hsn: "",
      // labelInstructions: "",
      origin: "",
      description: "",
      quantity: "",
      unit: "",
      grade: "",
    };

    // Create a copy of the asset details array
    const updatedAssetDetails = Array.isArray(state.assetDetails)
      ? [...state.assetDetails]
      : [];
    // Push the new asset into the copied array
    updatedAssetDetails.push(newAsset);
    // Dispatch the updated array with the default asset details appended
    dispatch({
      field: "assetDetails",
      value: updatedAssetDetails.map((asset, index) => {
        // If it's the last item in the array, add default asset details
        if (index === updatedAssetDetails.length - 1) {
          return {
            ...asset,
          };
        }
        return asset;
      }),
    });
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;

    if (name.includes("personalDetails.")) {
      const [parentNames, nestedNames] = name.split(".");
      dispatch({
        field: "personalDetails",
        value: { ...state[parentNames], [nestedNames]: value },
      });
    } else if (name.includes("companyAdditionalInformation.")) {
      const [parentNamesAddInfo, nestedNamesAddInfo] = name.split(".");
      dispatch({
        field: "companyAdditionalInformation",
        value: { ...state[parentNamesAddInfo], [nestedNamesAddInfo]: value },
      });
    } else if (name.includes("deliveryAddress.")) {
      const [parentNamesDel, nestedNamesDel] = name.split(".");
      dispatch({
        field: "deliveryAddress",
        value: { ...state[parentNamesDel], [nestedNamesDel]: value },
      });
    } else if (name.includes("supplierDetails.")) {
      const [parentNamesSup, nestedNamesSup] = name.split(".");
      dispatch({
        field: "supplierDetails",
        value: { ...state[parentNamesSup], [nestedNamesSup]: value },
      });
    } else if (name.includes("growerFarmingSiteAddress.")) {
      const [parentNamesGFS, nestedNamesGFS] = name.split(".");
      dispatch({
        field: "growerFarmingSiteAddress",
        value: { ...state[parentNamesGFS], [nestedNamesGFS]: value },
      });
    } else if (name.includes(`assetDetails[${index}].`)) {
      const updatedAssetDetails = Array.isArray(state.assetDetails)
        ? [...state.assetDetails]
        : [];
      // Split the name to get the property and nested property
      const [propertyName, nestedProperty] = name.split(".");
      // Check if nested property exists
      if (nestedProperty) {
        // If nested property exists, update it in the respective object
        updatedAssetDetails[index] = {
          ...updatedAssetDetails[index],
          [nestedProperty]: value,
        };
      } else {
        // If no nested property, update the property directly
        updatedAssetDetails[index][propertyName] = value;
      }
      // Dispatch updated product details to state
      dispatch({
        field: "assetDetails",
        value: updatedAssetDetails,
      });
    } else if (name.includes("sourcingCountries.")) {
      const [parentNamesSc, nestedNamesSc] = name.split(".");
      setCountryList(value);
      let selectedLabels;
      if (
        String(nestedNamesSc) !== "preferredCountry" &&
        String(nestedNamesSc) !== "nonNegotiable"
      ) {
        selectedLabels = value
          .filter((country) => country !== undefined)
          .map((country) => country?.label);
      } else if (String(nestedNamesSc) === "preferredCountry") {
        selectedLabels = value?.label;
      } else {
        selectedLabels = value;
      }
      dispatch({
        field: "sourcingCountries",
        value: { ...state[parentNamesSc], [nestedNamesSc]: selectedLabels },
      });
    } else if (name.includes("packagingTypes.")) {
      const [parentNamesPt, nestedNamesPt] = name.split(".");
      dispatch({
        field: "packagingTypes",
        value: { ...state[parentNamesPt], [nestedNamesPt]: value },
      });
    } else if (name.includes("logisticsTerms.")) {
      const [parentNamesLt, nestedNamesLt] = name.split(".");
      dispatch({
        field: "logisticsTerms",
        value: { ...state[parentNamesLt], [nestedNamesLt]: value },
      });
    } else if (name.includes("paymentTerms.")) {
      const [parentNamesPay, nestedNamesPay] = name.split(".");
      dispatch({
        field: "paymentTerms",
        value: { ...state[parentNamesPay], [nestedNamesPay]: value },
      });
    } else if (name.includes("requirements.")) {
      const [parentNamesReq, nestedNamesReq] = name.split(".");
      dispatch({
        field: "requirements",
        value: { ...state[parentNamesReq], [nestedNamesReq]: value },
      });
    } else if (name.includes("pickUpAddress.")) {
      const [parentNamesPicup, nestedNamesPicup] = name.split(".");
      dispatch({
        field: "pickUpAddress",
        value: { ...state[parentNamesPicup], [nestedNamesPicup]: value },
      });
    } else if (name.includes("transportDetails.")) {
      const [parentNamesReq, nestedNamesReq] = name.split(".");
      dispatch({
        field: "transportDetails",
        value: { ...state[parentNamesReq], [nestedNamesReq]: value },
      });
    } else if (name.includes("storageDetails.")) {
      const [parentNamesReq, nestedNamesReq] = name.split(".");
      dispatch({
        field: "storageDetails",
        value: { ...state[parentNamesReq], [nestedNamesReq]: value },
      });
    } else {
      dispatch({
        field: name,
        value: value,
      });
    }
  };

  const handleSubmit = () => {
    MainService.orderManagement(reqTypes, state, parentId, username)
      .then((res) => {
        return NotificationManager.success(res?.status);
      })
      .catch((err) => {
        return NotificationManager.error(
          err?.message || "something went wrong"
        );
      })
      .finally(() => {
        reLoad();
      });
  };

  return (
    <Fragment>
      <Box>
        <Typography variant="h5" {...commonTypography}>
          Personal Details
        </Typography>
        <Box {...border}>
          <Box {...filedResponsive}>
            {renderTextField(
              "Network Id",
              "personalDetails.networkId",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Parent Id",
              "personalDetails.parentId",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Username",
              "personalDetails.username",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Site Name",
              "personalDetails.siteName",
              state,
              handleChange
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField("No", "personalDetails.no", state, handleChange)}
            {renderTextField(
              "Username",
              "personalDetails.name",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Phone",
              "personalDetails.phoneNo",
              state,
              handleChange,
              "number",
              "text",
              true
            )}
            {renderTextField(
              "Email",
              "personalDetails.emailId",
              state,
              handleChange,
              "email",
              "text",
              true
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField(
              "Street",
              "personalDetails.street",
              state,
              handleChange
            )}
            {renderTextField(
              "City",
              "personalDetails.city",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "State",
              "personalDetails.state",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Country",
              "personalDetails.country",
              state,
              handleChange
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField(
              "Pincode",
              "personalDetails.pincode",
              state,
              handleChange,
              "number"
            )}
            {renderTextField(
              "PAN Number",
              "personalDetails.panNumber",
              state,
              handleChange
            )}
            {renderTextField(
              "Landmark",
              "personalDetails.landmark",
              state,
              handleChange
            )}
            {renderTextField(
              "Latitude",
              "personalDetails.latitude",
              state,
              handleChange
            )}
            {renderTextField(
              "Longitude",
              "personalDetails.longitude",
              state,
              handleChange
            )}
          </Box>
        </Box>
        <Typography variant="h5" {...commonTypography}>
          Company Additional Information
        </Typography>
        <Box {...border}>
          <Box {...filedResponsive}>
            {renderTextField(
              "Company Name",
              "companyAdditionalInformation.companyName",
              state,
              handleChange
            )}
            {renderTextField(
              "Business Type",
              "companyAdditionalInformation.businessType",
              state,
              handleChange
            )}
            {renderTextField(
              "Country",
              "companyAdditionalInformation.country",
              state,
              handleChange
            )}
            {renderTextField(
              "Year Established",
              "companyAdditionalInformation.yearEstablished",
              state,
              handleChange,
              "date"
            )}
            {renderTextField(
              "Number Of Employees",
              "companyAdditionalInformation.numberOfEmployees",
              state,
              handleChange
            )}
            {renderTextField(
              "Annual Sales Revenue",
              "companyAdditionalInformation.annualSalesRevenue",
              state,
              handleChange
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField(
              "Company Webite",
              "companyAdditionalInformation.companyWebite",
              state,
              handleChange
            )}
            {renderDropdown(
              "MyCompany Has No Webite",
              "companyAdditionalInformation.myCompanyHasNoWebite",
              state,
              handleChange,
              availableornot,
              "type",
              "type"
            )}
            {renderTextField(
              "Pan Number",
              "companyAdditionalInformation.panNumber",
              state,
              handleChange
            )}
            {renderTextField(
              "GST Number",
              "companyAdditionalInformation.gstin_Number",
              state,
              handleChange
            )}
            {renderTextField(
              "Company Description",
              "companyAdditionalInformation.companyDescription",
              state,
              handleChange
            )}
          </Box>
        </Box>
        <Typography variant="h5" {...commonTypography}>
          Delivery Details
        </Typography>
        <Box {...border}>
          <Box {...filedResponsive}>
            {renderTextField(
              "Site ID",
              "deliveryAddress.siteId",
              state,
              handleChange
            )}
            {renderTextField(
              "Site Name",
              "deliveryAddress.siteName",
              state,
              handleChange
            )}
            {renderTextField(
              "Street",
              "deliveryAddress.street",
              state,
              handleChange
            )}
            {renderTextField(
              "City",
              "deliveryAddress.city",
              state,
              handleChange
            )}
            {renderTextField(
              "State",
              "deliveryAddress.state",
              state,
              handleChange
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField(
              "Pincode",
              "deliveryAddress.pincode",
              state,
              handleChange,
              "number"
            )}
            {renderTextField(
              "Country",
              "deliveryAddress.country",
              state,
              handleChange
            )}
            {renderTextField(
              "Latitude",
              "deliveryAddress.latitude",
              state,
              handleChange,
              "number"
            )}
            {renderTextField(
              "Longitude",
              "deliveryAddress.longitude",
              state,
              handleChange,
              "number"
            )}
          </Box>
        </Box>
        <Typography variant="h5" {...commonTypography}>
          Supplier Details
        </Typography>
        <Box {...border}>
          <Box {...filedResponsive}>
            {renderTextField(
              "Site Id",
              "supplierDetails.siteId",
              state,
              handleChange
            )}
            {renderTextField(
              "Site Name",
              "supplierDetails.siteName",
              state,
              handleChange
            )}
            {renderTextField(
              "Street",
              "supplierDetails.street",
              state,
              handleChange
            )}
            {renderTextField(
              "City",
              "supplierDetails.city",
              state,
              handleChange
            )}
            {renderTextField(
              "State",
              "supplierDetails.state",
              state,
              handleChange
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField(
              "Pincode",
              "supplierDetails.pincode",
              state,
              handleChange
            )}
            {renderTextField(
              "Email ID",
              "supplierDetails.emailId",
              state,
              handleChange,
              "email"
            )}
            {renderTextField(
              "Phone",
              "supplierDetails.phoneNo",
              state,
              handleChange,
              "number"
            )}
            {renderTextField(
              "Latitude",
              "supplierDetails.latitude",
              state,
              handleChange,
              "number"
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField(
              "Longitude",
              "supplierDetails.longitude",
              state,
              handleChange,
              "number"
            )}
            {renderTextField(
              "PAN",
              "supplierDetails.panNumber",
              state,
              handleChange
            )}
            {renderTextField(
              "GST Number",
              "supplierDetails.gstin_Number",
              state,
              handleChange
            )}
            {renderTextField(
              "Supplier ID",
              "supplierDetails.supplierId",
              state,
              handleChange
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          p={0.5} // Add padding as needed
        >
          <Typography variant="h5" {...commonTypography}>
            Asset Details
          </Typography>
          <Button
            onClick={handleAddAsset}
            sx={{
              color: "primary.main",
              marginLeft: "auto",
              border: "1px solid #ccc",
            }}
          >
            <AddIcon sx={{ color: "primary.main", marginRight: 1 }} /> Add More
            Assets
          </Button>
        </Box>
        {Array.isArray(state.assetDetails) &&
          state.assetDetails.map((assets, index) => (
            <Box {...border} key={index}>
              {/* Render delete icon */}
              <IconButton onClick={() => handleDeleteAsset(index)}>
                <DeleteIcon />
              </IconButton>
              {/* Rest of the fields */}
              <Box {...filedResponsive}>
                {renderDropdown(
                  "Asset Vertical Id",
                  `assetDetails[${index}].assetVerticalId`,
                  state,
                  (event) => handleChange(event, index),
                  verticalsIds,
                  "assetVerticalId",
                  "friendlyName"
                )}
                {renderDropdown(
                  "Asset Vertical Friendly Name",
                  `assetDetails[${index}].assetVerticalFriendlyName`,
                  state,
                  (event) => handleChange(event, index),
                  verticalsIds,
                  "friendlyName",
                  "friendlyName"
                )}
                {renderDropdown(
                  "Category",
                  `assetDetails[${index}].category`,
                  state,
                  (event) => handleChange(event, index),
                  categoryTypes,
                  "categoryType",
                  "categoryType"
                )}
                {renderDropdown(
                  "Legacy Asset Type",
                  `assetDetails[${index}].legacyAssetType`,
                  state,
                  (event) => handleChange(event, index),
                  assertTypes,
                  "assetType",
                  "assetType"
                )}
              </Box>
              <Box {...filedResponsive}>
                {renderDropdown(
                  "Model Id",
                  `assetDetails[${index}].modelId`,
                  state,
                  (event) => handleChange(event, index),
                  models,
                  "modelid",
                  "modelid"
                )}
                {renderTextField(
                  "CN",
                  `assetDetails[${index}].cn`,
                  state,
                  (event) => handleChange(event, index)
                )}
                {renderTextField(
                  "origin",
                  `assetDetails[${index}].origin`,
                  state,
                  (event) => handleChange(event, index)
                )}
                {renderTextField(
                  "Description",
                  `assetDetails[${index}].description`,
                  state,
                  (event) => handleChange(event, index)
                )}
              </Box>
              <Box {...filedResponsive}>
                {renderTextField(
                  "HSN",
                  `assetDetails[${index}].hsn`,
                  state,
                  (event) => handleChange(event, index)
                )}
                {renderDropdown(
                  "Grade",
                  `assetDetails[${index}].grade`,
                  state,
                  (event) => handleChange(event, index),
                  grade,
                  "type",
                  "type"
                )}
                {renderTextField(
                  "Quantity",
                  `assetDetails[${index}].quantity`,
                  state,
                  (event) => handleChange(event, index)
                )}
                {renderTextField(
                  "Unit",
                  `assetDetails[${index}].unit`,
                  state,
                  (event) => handleChange(event, index)
                )}
              </Box>
            </Box>
          ))}

        {/* cf-agri-assets-rfq-req */}
        {(() => {
          if (reqTypes === "cf-agri-assets-rfq-req") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Grower Farming Site Address
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "Site ID",
                      "growerFarmingSiteAddress.siteId",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Site Name",
                      "growerFarmingSiteAddress.siteName",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Street",
                      "growerFarmingSiteAddress.street",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "City",
                      "growerFarmingSiteAddress.city",
                      state,
                      handleChange
                    )}
                  </Box>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "Pincode",
                      "growerFarmingSiteAddress.pincode",
                      state,
                      handleChange,
                      "number"
                    )}
                    {renderTextField(
                      "State",
                      "growerFarmingSiteAddress.state",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Country",
                      "growerFarmingSiteAddress.country",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Latitude",
                      "growerFarmingSiteAddress.latitude",
                      state,
                      handleChange,
                      "number"
                    )}
                    {renderTextField(
                      "Longitude",
                      "growerFarmingSiteAddress.longitude",
                      state,
                      handleChange,
                      "number"
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}

        {(() => {
          if (reqTypes === "cf-agri-assets-rfq-req") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Sourcing Countries
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderMultiSelect(
                      "Origin Countries",
                      "sourcingCountries.originCountries",
                      getCountryList,
                      state,
                      handleChange,
                      countryOptions,
                      "label",
                      "label"
                    )}
                    {renderMultiSelect(
                      "Exclude Country",
                      "sourcingCountries.excludeCountry",
                      getCountryList,
                      state,
                      handleChange,
                      countryOptions,
                      "label",
                      "label"
                    )}
                    {renderSingleSelect(
                      "Preferred Country",
                      "sourcingCountries.preferredCountry",
                      getCountryList,
                      state,
                      handleChange,
                      countryOptions,
                      "label",
                      "label"
                    )}
                    {renderDropdown(
                      "Non-Negotiable",
                      "sourcingCountries.nonNegotiable",
                      state,
                      handleChange,
                      transport,
                      "type",
                      "type"
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}

        {(() => {
          if (reqTypes === "cf-agri-assets-rfq-req") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Logistics Terms
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "Delivery Terms",
                      "logisticsTerms.deliveryTerms",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Port Of Destination",
                      "logisticsTerms.portOfDestination",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Target ShippmentDate",
                      "logisticsTerms.targetShippmentDate",
                      state,
                      handleChange,
                      "date"
                    )}
                    {renderDropdown(
                      "Transport",
                      "logisticsTerms.transport",
                      state,
                      handleChange,
                      transport,
                      "type",
                      "type"
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}

        {(() => {
          if (reqTypes === "cf-agri-assets-rfq-req") {
            return (
              <>
                {" "}
                <Typography variant="h5" {...commonTypography}>
                  Payment Terms
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "Payment Terms",
                      "paymentTerms.paymentTerms",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Detailed Payment Terms",
                      "paymentTerms.detailedPaymentTerms",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Payment To Be MadeBy",
                      "paymentTerms.paymentToBeMadeBy",
                      state,
                      handleChange
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}

        {(() => {
          if (reqTypes === "cf-agri-assets-rfq-req") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Requirements
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderDropdown(
                      "Asset Package",
                      "requirements.assetPackage",
                      state,
                      handleChange,
                      packageTypes,
                      "type",
                      "type"
                    )}
                    {renderDropdown(
                      "Provenance",
                      "requirements.provenance",
                      state,
                      handleChange,
                      transport,
                      "type",
                      "type"
                    )}
                    {renderDropdown(
                      "Agreement",
                      "requirements.agreement",
                      state,
                      handleChange,
                      transport,
                      "type",
                      "type"
                    )}
                    {renderDropdown(
                      "Organic Certificate",
                      "requirements.organicCertificate",
                      state,
                      handleChange,
                      transport,
                      "type",
                      "type"
                    )}
                    {renderTextField(
                      "Quality Guideline Link",
                      "requirements.qualityGuidelineLink",
                      state,
                      handleChange
                    )}
                  </Box>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "Tender Link",
                      "requirements.tenderLink",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Optional DocumentLink",
                      "requirements.optionalDocumentLink",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "From Date",
                      "requirements.fromDate",
                      state,
                      handleChange,
                      "date"
                    )}
                    {renderTextField(
                      "To Date",
                      "requirements.toDate",
                      state,
                      handleChange,
                      "date"
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}
        {/* end cf-agri-assets-rfq-req */}
        {/* transport-asset-rfq-req */}
        {(() => {
          if (reqTypes !== "cf-agri-assets-rfq-req") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Pickup Address
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "Site ID",
                      "pickUpAddress.siteId",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Site Name",
                      "pickUpAddress.siteName",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Street",
                      "pickUpAddress.street",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "City",
                      "pickUpAddress.city",
                      state,
                      handleChange
                    )}
                  </Box>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "State",
                      "pickUpAddress.state",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Pincode",
                      "pickUpAddress.pincode",
                      state,
                      handleChange,
                      "number"
                    )}
                    {renderTextField(
                      "Country",
                      "pickUpAddress.country",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Latitude",
                      "pickUpAddress.latitude",
                      state,
                      handleChange,
                      "number"
                    )}
                    {renderTextField(
                      "Longitude",
                      "pickUpAddress.longitude",
                      state,
                      handleChange,
                      "number"
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}

        {(() => {
          if (reqTypes === "transport-asset-rfq-req") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Transport Details
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "TransportType",
                      "transportDetails.transportType",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Mode Of Transport",
                      "transportDetails.modeOfTransport",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Preferred Transport Company",
                      "transportDetails.preferredTransportCompany",
                      state,
                      handleChange
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}

        {(() => {
          if (reqTypes === "transport-asset-rfq-req") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Requirements
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderDropdown(
                      "Asset Package",
                      "requirements.assetPackage",
                      state,
                      handleChange,
                      packageTypes,
                      "type",
                      "type"
                    )}
                    {renderDropdown(
                      "Vehicle Insurance",
                      "requirements.vehicleInsurance",
                      state,
                      handleChange,
                      transport,
                      "type",
                      "type"
                    )}
                    {renderDropdown(
                      "Agreement",
                      "requirements.agreement",
                      state,
                      handleChange,
                      transport,
                      "type",
                      "type"
                    )}
                    {renderTextField(
                      "Transport Guide Line Link",
                      "requirements.transportGuideLineLink",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Optional DocumentLink",
                      "requirements.optionalDocumentLink",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "From Date",
                      "requirements.fromDate",
                      state,
                      handleChange,
                      "date"
                    )}
                    {renderTextField(
                      "To Date",
                      "requirements.toDate",
                      state,
                      handleChange,
                      "date"
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}
        {/* end transport-asset-rfq-req */}
        {/* storage-asset-rfq-req */}
        {(() => {
          if (reqTypes === "storage-asset-rfq-req") {
            return (
              <>
                {" "}
                <Typography variant="h5" {...commonTypography}>
                  Storage Details
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "City",
                      "storageDetails.city",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Preferred Start Date",
                      "storageDetails.preferredStartDate",
                      state,
                      handleChange,
                      "date"
                    )}
                    {renderTextField(
                      "Expected Duration Of Storage",
                      "storageDetails.expectedDurationOfStorage",
                      state,
                      handleChange,
                      "number"
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}

        {(() => {
          if (reqTypes === "storage-asset-rfq-req") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Requirements
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderDropdown(
                      "Asset Package",
                      "requirements.assetPackage",
                      state,
                      handleChange,
                      packageTypes,
                      "type",
                      "type"
                    )}
                    {renderDropdown(
                      "Transport",
                      "requirements.transport",
                      state,
                      handleChange,
                      transport,
                      "type",
                      "type"
                    )}
                    {renderDropdown(
                      "Storage Insurance",
                      "requirements.storageInsurance",
                      state,
                      handleChange,
                      transport,
                      "type",
                      "type"
                    )}
                    {renderDropdown(
                      "Agreement",
                      "requirements.agreement",
                      state,
                      handleChange,
                      transport,
                      "type",
                      "type"
                    )}
                    {renderTextField(
                      "Storage Guide Line Link",
                      "requirements.storageGuideLineLink",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Optional DocumentLink",
                      "requirements.optionalDocumentLink",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "From Date",
                      "requirements.fromDate",
                      state,
                      handleChange,
                      "date"
                    )}
                    {renderTextField(
                      "To Date",
                      "requirements.toDate",
                      state,
                      handleChange,
                      "date"
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}
        {/* end storage-asset-rfq-req */}
        <Typography variant="h5" {...commonTypography}>
          Packaging Types
        </Typography>
        <Box {...border}>
          <Box {...filedResponsive}>
            {renderTextField(
              "Packing Instructions",
              "packagingTypes.packingInstructions",
              state,
              handleChange
            )}
            {renderTextField(
              "Label Instructions",
              "packagingTypes.labelInstructions",
              state,
              handleChange
            )}
          </Box>
        </Box>
        <Typography variant="h5" {...commonTypography}>
          Additional Information
        </Typography>
        <Box {...border}>
          <Box {...filedResponsive}>
            {renderTextField(
              "Request Type",
              "requestType",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Time Stamp",
              "timeStamp",
              state,
              handleChange,
              "text",
              true
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          margin: "5px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>
      </Box>
    </Fragment>
  );
};

export default memo(AssetsRfqForm);
