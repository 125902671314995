import React from 'react'

export default function CBNBenefits() {
  return (
   <ul className='benefitList mb-0'>
    <li>
        Enable Community networks for Producers (Farmers).
    </li>
    <li>
        Enable Local Agri ecosystem partners to buy the raw material from the farmer.
    </li>
    <li>
        Enable Government official to distribute the assets within the community.
    </li>
   </ul>
  )
}
