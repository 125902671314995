import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import XMLParser from 'react-xml-parser';
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AuthAxiosInstance from '../../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance, EMSServcieAxiosInstance } from '../../../../authAxiosInstance'
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DonutChart from '../Charts/DonutChart';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
import socketIOClient from "socket.io-client";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Skeleton from '@mui/material/Skeleton';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const OTAManagement = () => {
    const ENDPOINT = `172.16.0.192:3111`;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [modelList, setModelList] = React.useState([]);
    const [deviceType, setDeviceType] = React.useState([]);
    const [openAddDailog, setOpenAddDailog] = React.useState(false);
    const [opendedicatedDailog, setopendedicatedDailog] = React.useState(false);
    const [deleteDailogOpen, setDeleteDailogOpen] = React.useState(false);
    const [updateDailogOpen, setUpdateDailogOpen] = React.useState(false);
    const [firmwareDetailsOpen, setFirmwareDetailsOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [deleteFirmware, setDeleteFirmware] = React.useState({});
    const [updateServer, setUpdateServer] = React.useState({});
    const [firmwareDetails, setFirmwareDetails] = React.useState({});
    const [type, selectedType] = React.useState("");
    const [model, selectedModel] = React.useState("");
    const [selectedRow, setSelectedRow] = React.useState({});
    const [selectedStatus, setSelectedStatus] = React.useState('published');
    const [uploadfilename, setUploadfilename] = React.useState('filename');
    const [xmlData, setXmlData] = React.useState('');
    const [appConfigXmlData, setAppConfigXmlData] = React.useState('');
    const [imageType, setselectedType] = React.useState('');
    const [firmwareStatusOpen, setFirmwareStatusOpen] = React.useState(false);
    const [addFirmwareLoading, setAddFirmwareLoading] = React.useState(false);
    const [socketIOStaus, setSocketIOStatus] = React.useState('IDLE')
    const [isLoading, setIsLoading] = React.useState(false);
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    const updatedDevices = 50;
    const notUpdatedDevices = 20;
    const totalDevices = updatedDevices + notUpdatedDevices;

    const [formMetaData, setFormMetaData] = React.useState({
        partnerId: '',
        modelId: '',
        type: '',
        dmProtocol: '',
        firmwareType: '',
        critical: '',
        deliveryMethod: [],
        version: ''
    });

    React.useEffect(() => {
        getdata();
        fetchData();
        fetchAppConfigData();
       
    }, []);

    const getTableData = () => {
        fetchData();
        fetchAppConfigData();
    }

    const getdata = () => {
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/list`).then((res) => {

            if (res.data)
                setDeviceType(res.data);
            else
                setDeviceType([]);
        }).catch((err) => {
            alert();
            setDeviceType([]);
        });

    }
    const selectDevice = (evt) => {
        //const filterModelDevice = deviceList.filter((d) => (d.deviceType === evt.target.value));
        selectedType(evt.target.value);
        //setModelList(filterModelDevice);
        setModelList([]);
        selectedModel("");
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${evt.target.value}/models`).then((res) => {
            setModelList(res.data);
        }).catch((err) => {
            setModelList([]);
        });
    }

    const selectImageType = (evt) => {
        //const filterModelDevice = deviceList.filter((d) => (d.deviceType === evt.target.value));
        setselectedType(evt.target.value);
        //setModelList(filterModelDevice);
    }
    const fetchData = async () => {
        setIsLoading(true);
        MainService.getFirmwareList(parentId, username)
            .then((res) => {
                setIsLoading(false);
                setXmlData(res.data);
            })
            .catch((err) => {
                setIsLoading(false);
                alert();

                return NotificationManager.error("Something went wrong");
            });
    };

    const fetchAppConfigData = async () => {
        setIsLoading(true);
        MainService.getAppConfigFirmwareList(parentId,username).then((res) => {
            setIsLoading(false);
            setAppConfigXmlData(res.data);
        })
        .catch((err) => {
            setIsLoading(false);
            alert();

            return NotificationManager.error("Something went wrong");
        });
    };


    const parsedData = new XMLParser().parseFromString(xmlData);

    const tableData = parsedData?.getElementsByTagName('firmware').map((firmware) => ({
        version: firmware.attributes.version || '',
        type: firmware.getElementsByTagName('type')[0]?.value || '',
        firmType: firmware.getElementsByTagName('firmType')[0]?.value || '',
        filename: firmware.getElementsByTagName('filename')[0]?.value || '',
        deliverymethod: firmware.getElementsByTagName('deliverymethod')[0]?.value || '',
        url: firmware.getElementsByTagName('url')[0]?.value || '',
        signatureCRC_DS: firmware.getElementsByTagName('signatureCRC_DS')[0]?.value || '',
        critical: firmware.getElementsByTagName('critical')[0]?.value || '',
        modelid: firmware.getElementsByTagName('modelid')[0]?.value || '',
        protocol: firmware.getElementsByTagName('protocol')[0]?.value || '',
    }));

    const parsedAppConfigData =

    console.log(tableData);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleAddfirmwareClickOpen = () => {
        setOpenAddDailog(true);
        setAnchorEl(null);
    };

    const handleAddClose = () => {
        setOpenAddDailog(false);
        setFormMetaData({
            partnerId: '',
            modelId: '',
            type: '',
            dmProtocol: '',
            firmwareType: '',
            critical: '',
            deliveryMethod: [],
            version: ''
        });
    };


    const handleAddChange = (e) => {
        const { name, value } = e.target;
        setFormMetaData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAddSubmit = async (event) => {
        event.preventDefault();
        setAddFirmwareLoading(true)
        if(imageType == 'APP' || imageType == 'CONFIGURATION' ){
            try {
                if (selectedFile) {
                    const formData = new FormData();
                    formData.append('file', selectedFile);
                    MainService.addFirmwareAppConfigFile(formData, username, model, imageType)
                        .then((res) => {
                            console.log(res,"lnk res");
                            if (res.status == 200) {
                                const location = res.data.Data[0].Location;
    
                                const xmlPayload = `<?xml version="1.0" encoding="UTF-8" ?>
                <appconfig>
                <partnerid>${username}</partnerid>
                <solutionid>${model}</solutionid>
                <protocol>${formMetaData.dmProtocol}</protocol>
                <type>${imageType}</type>
                <version>${formMetaData.version}</version>
                <firmwareType>${formMetaData.firmwareType}</firmwareType>
                <critical>${formMetaData.critical}</critical>
                <url>${location}</url>
                <filename>${uploadfilename}</filename>
                <deliverymethod>${formMetaData.deliveryMethod}</deliverymethod>
                <signatureCRC_DS>${location}</signatureCRC_DS>
                </appconfig>`
    
                                MainService.addFirmwareAppConfigMetadata(xmlPayload, username)
                                    .then((resp) => {
                                        if (resp.status == 201) {
                                            fetchData();
                                            setOpenAddDailog(false)
                                            setFormMetaData({
                                                partnerId: '',
                                                modelId: '',
                                                type: '',
                                                dmProtocol: '',
                                                firmwareType: '',
                                                critical: '',
                                                deliveryMethod: [],
                                                version: ''
                                            });
                                            setAddFirmwareLoading(false);
                                            return NotificationManager.success("Firmware Added");
                                        } else {
                                            MainService.deleteFirmwareAppConfigFile(imageType, model,uploadfilename, username)
                                                .then((res) => {
                                                    setAddFirmwareLoading(false);
                                                    return NotificationManager.success("Firmware File Deleted");
                                                })
                                                .catch((err) => {
                                                    setAddFirmwareLoading(false);
                                                    return NotificationManager.error("Something went wrong");
                                                });
                                        }
                                    })
                                    .catch((err) => {
                                        MainService.deleteFirmwareAppConfigFile(imageType, model,uploadfilename, username)
                                            .then((res) => {
                                                setAddFirmwareLoading(false);
                                                return NotificationManager.success("Firmware File Deleted");
                                            })
                                            .catch((err) => {
                                                setAddFirmwareLoading(false);
                                                return NotificationManager.error("Something went wrong");
                                            });
                                    });
                            }
    
                            // return NotificationManager.success("Device cloud server Deleted");
                        })
                        .catch((err) => {
                            setAddFirmwareLoading(false);
                            return NotificationManager.error("Something went wrong");
                        });
                }
    
            } catch (error) {
                console.error('Error uploading file:', error);
            }

        }else {
            try {
                if (selectedFile) {
                    const formData = new FormData();
                    formData.append('file', selectedFile);
                    MainService.addFirmwareFile(formData, username)
                        .then((res) => {
                            console.log(res);
                            if (res.status == 200) {
                                const location = res.data.Data[0].Location;
    
                                const xmlPayload = `<?xml version="1.0" encoding="UTF-8" ?>
                <firmware>
                <partnerid>${username}</partnerid>
                <modelid>${model}</modelid>
                <protocol>${formMetaData.dmProtocol}</protocol>
                <type>${imageType}</type>
                <version>${formMetaData.version}</version>
                <firmwareType>${formMetaData.firmwareType}</firmwareType>
                <critical>${formMetaData.critical}</critical>
                <url>${location}</url>
                <filename>${uploadfilename}</filename>
                <deliverymethod>${formMetaData.deliveryMethod}</deliverymethod>
                <signatureCRC_DS>${location}</signatureCRC_DS>
                </firmware>`
    
                                MainService.addFirmwareMetadata(xmlPayload, username)
                                    .then((resp) => {
                                        if (resp.status == 201) {
                                            fetchData();
                                            setOpenAddDailog(false)
                                            setFormMetaData({
                                                partnerId: '',
                                                modelId: '',
                                                type: '',
                                                dmProtocol: '',
                                                firmwareType: '',
                                                critical: '',
                                                deliveryMethod: [],
                                                version: ''
                                            });
                                            setAddFirmwareLoading(false);
                                            return NotificationManager.success("Firmware Added");
                                        } else {
                                            MainService.deleteFirmwareFile(uploadfilename, username)
                                                .then((res) => {
                                                    setAddFirmwareLoading(false);
                                                    return NotificationManager.success("Firmware File Deleted");
                                                })
                                                .catch((err) => {
                                                    setAddFirmwareLoading(false);
                                                    return NotificationManager.error("Something went wrong");
                                                });
                                        }
                                    })
                                    .catch((err) => {
                                        MainService.deleteFirmwareFile(uploadfilename, username)
                                            .then((res) => {
                                                setAddFirmwareLoading(false);
                                                return NotificationManager.success("Firmware File Deleted");
                                            })
                                            .catch((err) => {
                                                setAddFirmwareLoading(false);
                                                return NotificationManager.error("Something went wrong");
                                            });
                                    });
                            }
    
                            // return NotificationManager.success("Device cloud server Deleted");
                        })
                        .catch((err) => {
                            setAddFirmwareLoading(false);
                            return NotificationManager.error("Something went wrong");
                        });
                }
    
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
        


        //     MainService.publishSharedCloud(xmlPayload)
        //         .then((res) => {
        //             handleAddClose();
        //             fetchData();
        //             return NotificationManager.success("Device cloud server published");
        //         })
        //         .catch((err) => {
        //             return NotificationManager.error("Something went wrong");
        //         });

    };



    const handleSearchChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchQuery(newSearchQuery);
        setPage(0);
    };

    const searchTable = (row) => {
        const lowercasedQuery = searchQuery.toLowerCase();
        return Object.values(row).some(
            (value) =>
                typeof value === 'string' && value.toLowerCase().includes(lowercasedQuery)
        );
    };

    const filteredData = tableData?.filter(searchTable);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const visibleData = filteredData?.slice(startIndex, endIndex);

    const handleUpdate = (row) => {
        setUpdateServer(row);
        setUpdateDailogOpen(true);
    };

    const handleDetailsShow = (row) => {
        setFirmwareDetails(row);
        setFirmwareDetailsOpen(true);
    };

    const handleDeleteSubmit = async (row) => {

        MainService.deleteFirmware(deleteFirmware.version)
            .then((res) => {
                handleDeleteDailogClose();
                // Handle the response
                fetchData();
                return NotificationManager.success("firmware Deleted");
            })
            .catch((err) => {
                handleDeleteDailogClose();
                return NotificationManager.error("Something went wrong");
            });

    };
    const handleDeleteDailogClose = () => {
        setDeleteDailogOpen(false);
    }

    const handleDeviceDetailsClose = () => {
        setFirmwareDetailsOpen(false);
    }

    const handleFileChange = (event) => {
        // Get the selected file from the input
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const fileContent = e.target.result;
                console.log('File Content:', fileContent);
            };

            reader.readAsText(file);
        }
        setUploadfilename(event.target.files[0].name);
        setSelectedFile(file);
    };

    const selectModel = (evt) => {
        selectedModel(evt.target.value);
    }
    const handleRadioChange = (row) => {
        setSelectedRow(row);
    };

    const areObjectsEqual = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }

        return true;
    }
    const handleUpadtefirmware = () => {
        console.log(selectedRow,"selectedRow");
        if(selectedRow?.type == "APP" || selectedRow?.type == "CONFIGURATION"){
             if (selectedRow.modelid) {
            const rfq = {
                "parentid": parentId,
                "partnerid": username,
                "solutionid": selectedRow.modelid,
                "type": selectedRow.type,
                "protocol": selectedRow.protocol,
                "version": selectedRow.version,
                "firmwaretype": selectedRow.firmType,
                "critical": selectedRow.critical,
                "deliverymethod": selectedRow.deliverymethod,
                "url": selectedRow.url,
                "filename": selectedRow.filename
            }
            MainService.updateFirmwareAppConfig(username,rfq)
                .then((res) => {
                    // handleDeleteDailogClose();
                    // // Handle the response
                    // fetchData();
                    MainService.initStatusNotification(parentId, username).then((resp) => {
                        setFirmwareStatusOpen(true);
                        const socket = socketIOClient(ENDPOINT);
                        console.log(`url:` + ENDPOINT, `topic:` + `/livedata/${parentId}/energos`)
                        // Event listener for the "message" event
                        socket.on(
                            `/livedata/${parentId}/energos`,
                            (data) => {
                                //"tt/solution/${username}/site/${siteId}/asset${assetId}/LOC"
                                //FromAPI
                                console.log("live data11", JSON.parse(data));
                                setSocketIOStatus(JSON.parse(data))
                                if(JSON.parse(data).status === "FAILED" || JSON.parse(data).status === "RESTARTED"){
                                    MainService.destroyStatusNotification(parentId,username).then(()=>{
                                    })
                                }

                            }
                        );
                        return () => {
                            socket.disconnect();
                        };

                    })
                    return NotificationManager.success("firmware updated");
                })
                .catch((err) => {
                    alert();
                    return NotificationManager.error("Something went wrong");
                });
        }

        }else{
            if (selectedRow.modelid) {
            const rfq = {
                "parentid": parentId,
                "partnerid": username,
                "modelid": selectedRow.modelid,
                "type": selectedRow.type,
                "protocol": selectedRow.protocol,
                "version": selectedRow.version,
                "firmwaretype": selectedRow.firmType,
                "critical": selectedRow.critical,
                "deliverymethod": selectedRow.deliverymethod,
                "url": selectedRow.url,
                "filename": selectedRow.filename
            }
            MainService.updateFirmware(rfq)
                .then((res) => {
                    // handleDeleteDailogClose();
                    // // Handle the response
                    // fetchData();
                    MainService.initStatusNotification(parentId, username).then((resp) => {
                        setFirmwareStatusOpen(true);
                        const socket = socketIOClient(ENDPOINT);
                        console.log(`url:` + ENDPOINT, `topic:` + `/livedata/${parentId}/energos`)
                        // Event listener for the "message" event
                        socket.on(
                            `/livedata/${parentId}/energos`,
                            (data) => {
                                //"tt/solution/${username}/site/${siteId}/asset${assetId}/LOC"
                                //FromAPI
                                console.log("live data11", JSON.parse(data));
                                setSocketIOStatus(JSON.parse(data))
                                if(JSON.parse(data).status === "FAILED" || JSON.parse(data).status === "RESTARTED"){
                                    MainService.destroyStatusNotification(parentId,username).then(()=>{
                                    })
                                }

                            }
                        );
                        return () => {
                            socket.disconnect();
                        };

                    })
                    return NotificationManager.success("firmware updated");
                })
                .catch((err) => {
                    return NotificationManager.error("Something went wrong");
                });
        }
        }
        
    }
    const handleFirmwareClose = () => {
        setFirmwareStatusOpen(false)
    }


    // React.useEffect(() => {
    //     // Connect to the Socket.IO server
    //     const socket = socketIOClient(ENDPOINT);
    //     console.log(`url:` + ENDPOINT, `topic:` + `/livedata/${parentId}/energos`)
    //     // Event listener for the "message" event
    //     socket.on(
    //         `/livedata/${parentId}/energos`,
    //         (data) => {
    //             //"tt/solution/${username}/site/${siteId}/asset${assetId}/LOC"
    //             //FromAPI
    //             console.log("live data", JSON.parse(data));
    //             setSocketIOStatus(JSON.parse(data))

    //         }
    //     );
    //     return () => {
    //         socket.disconnect();
    //     };


    // }, []);
    // React.useEffect(() => {
    //     const socket = socketIOClient(ENDPOINT);
    //     socket.on(`/livedata/${parentId}/energos`, (data) => {
    //         console.log(JSON.parse(data));
    //         setSocketIOStatus(JSON.parse(data).status)

    //     })
    // },[])

    const skeletonRows = Array.from({ length: 5 }, (_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </TableCell>
          <TableCell colSpan={5}>
            <Box sx={{ width: '100%' }}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          </TableCell>
          <TableCell sx={{ display: 'flex' }}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={30}
              width={60}
              style={{ margin: 5 }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={30}
              width={60}
              style={{ margin: 5 }}
            />
          </TableCell>
        </TableRow>
      ));


    return (
        <div>
            <Grid container spacing={2} >
                <Grid item xs={12} md={3} sx={{ marginBottom: "1rem" }}>
                    <FormControl fullWidth>
                        <InputLabel size='small'>Partner Id </InputLabel>
                        <Select label="PartnerId" size="small" value={username} disabled >
                            <MenuItem value={username}>
                                {username}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} sx={{ marginBottom: "1rem" }}>
                    <FormControl fullWidth>
                        <InputLabel size='small'>Device Type</InputLabel>
                        <Select label="Device Type" size="small" value={type} onChange={selectDevice.bind(this)}>
                            {deviceType.map((d, i) => (
                                <MenuItem value={d} key={i + 1}>
                                    {d}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel size='small'>Device Model</InputLabel>
                        <Select label="Device Model" size="small" value={model} onChange={selectModel.bind(this)}>
                            {modelList.map((d, i) => (
                                <MenuItem value={d} key={i + 1}>
                                    {d}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} sx={{ marginBottom: "1rem" }}>
                    <FormControl fullWidth>
                        <InputLabel size='small'>Image Type</InputLabel>
                        <Select label="Image Type" size="small" value={imageType} onChange={selectImageType.bind(this)}>
                            <MenuItem disabled value="APP" >App</MenuItem>
                            <MenuItem disabled value="PATCH">Patch</MenuItem>
                            <MenuItem disabled value="CONFIGURATION" >Configuration</MenuItem>
                            <MenuItem disabled value="FIRMWARE" >Firmware</MenuItem>
                            <MenuItem disabled value="OS">OS</MenuItem>
                            <MenuItem value="CORE" >Core</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <div className='maincomponent'>
                <Grid container spacing={2} display="flex" justifyContent="space=between">
                    <Grid item xs={6} md={4}>
                        <TextField
                            label="Search"
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            fullWidth
                            size="small"
                        />
                    </Grid>

                    <Grid display="flex" justifyContent="flex-end" item xs={6} md={8}>
                        {Object.keys(selectedRow).length != 0 &&
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={() => setFirmwareStatusOpen(true)}
                                variant='outlined'
                                style={{ color: '#3ecf8e', borderColor: '#3ecf8e', height: '2.5rem', marginLeft: "5px" }}
                                size='small'
                            >
                                <CheckCircleOutlineIcon />Status
                            </Button>}
                        {Object.keys(selectedRow).length != 0 &&
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleUpadtefirmware}
                                variant='outlined'
                                style={{ color: '#3ecf8e', borderColor: '#3ecf8e', height: '2.5rem', marginLeft: "5px" }}
                                size='small'
                            >
                                <UpgradeIcon />Update Firmware
                            </Button>}
                        {type && model && imageType &&
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleAddfirmwareClickOpen}
                                variant='outlined'
                                style={{ color: '#3ecf8e', borderColor: '#3ecf8e', height: '2.5rem', marginLeft: "5px" }}
                                size='small'
                            >
                                <AddIcon />Add Firmware
                            </Button>
                        }
                    </Grid>
                </Grid>
                <div >
             {isLoading ? (
                 <TableContainer component={Paper}>
                 <Table responsive>
                     <TableHead>
                         <TableRow>
                             <TableCell></TableCell>
                             <TableCell>Type</TableCell>
                             <TableCell>Model Id</TableCell>
                             <TableCell>Firmware Type</TableCell>
                             <TableCell>Critical</TableCell>
                             <TableCell>Delivery Method</TableCell>
                             <TableCell>Option</TableCell>
                         </TableRow>
                     </TableHead>
                     <TableBody>
                         <>
                             {skeletonRows}
                         </>
                     </TableBody>
                 </Table>
             </TableContainer>

        
      ) : (
                <div>
                    <TableContainer component={Paper}>
                        <Table responsive>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Model Id</TableCell>
                                    <TableCell>Firmware Type</TableCell>
                                    <TableCell>Critical</TableCell>
                                    <TableCell>Delivery Method</TableCell>
                                    <TableCell>Option</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visibleData?.length === 0 ? <TableRow>
                                    <TableCell colSpan={7}>
                                        <h3 style={{ textAlign: 'center' }}>No Servers Found</h3>
                                    </TableCell>
                                </TableRow> :
                                    <>
                                        {visibleData?.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    <Radio
                                                        checked={areObjectsEqual(selectedRow, row)}
                                                        onChange={() => handleRadioChange(row)}
                                                    />
                                                </TableCell>
                                                <TableCell>{row.type}</TableCell>
                                                <TableCell>{row.modelid}</TableCell>
                                                <TableCell>{row.firmType}</TableCell>
                                                <TableCell>{row.critical}</TableCell>
                                                <TableCell>{row.deliverymethod}</TableCell>
                                                <TableCell style={{ display: 'flex' }}>
                                                    <Tooltip title="View" arrow placement="top">
                                                        <Button variant="outlined" color="primary" onClick={() => handleDetailsShow(row)}>
                                                            <VisibilityIcon />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" arrow placement="top">
                                                        <Button variant="outlined" color="error" style={{ marginLeft: '0.5rem' }}
                                                            onClick={() => {
                                                                setDeleteDailogOpen(true)
                                                                setDeleteFirmware(row)
                                                            }}>
                                                            <DeleteIcon />
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredData ? filteredData.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
      )}</div>
            </div>
            <Dialog open={openAddDailog} onClose={handleAddClose}>
                <DialogTitle>Add Firmware</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleAddSubmit}>
                        <TextField
                            label="Partner Id"
                            name="partnerId"
                            value={username}
                            onChange={handleAddChange}
                            fullWidth
                            margin="dense"
                            required
                            disabled
                        />
                        <FormControl fullWidth margin="dense">
                            <InputLabel size='small'>Image Type</InputLabel>
                            <Select label="Image Type" size="small" value={imageType} disabled>
                                <MenuItem disabled value="APP" >App</MenuItem>
                                <MenuItem disabled value="PATCH">Patch</MenuItem>
                                <MenuItem disabled value="CONFIGURATION" >Configuration</MenuItem>
                                <MenuItem disabled value="FIRMWARE" >Firmware</MenuItem>
                                <MenuItem disabled value="OS">OS</MenuItem>
                                <MenuItem value="CORE" >Core</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            label="Model"
                            name="model"
                            value={model}
                            onChange={handleAddChange}
                            fullWidth
                            margin="dense"
                            required
                            disabled
                        />
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Firmware Type</InputLabel>
                            <Select
                                label="Firmware Type"
                                name="firmwareType"
                                value={formMetaData.firmwareType}
                                onChange={handleAddChange}
                                required
                            >
                                <MenuItem value="New">New</MenuItem>
                                <MenuItem value="GOLDEN">GOLDEN</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Version"
                            name="version"
                            value={formMetaData.version}
                            onChange={handleAddChange}
                            fullWidth
                            margin="dense"
                            required
                        />
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Critical</InputLabel>
                            <Select
                                label="Critical"
                                name="critical"
                                value={formMetaData.critical}
                                onChange={handleAddChange}
                                required
                            >
                                <MenuItem value="YES">YES</MenuItem>
                                <MenuItem value="NO">NO</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth margin="dense">
                            <InputLabel>DM Protocol</InputLabel>
                            <Select
                                label="DM Protocol"
                                name="dmProtocol"
                                value={formMetaData.dmProtocol}
                                onChange={handleAddChange}
                                required
                            >
                                <MenuItem value="FTP">FTP</MenuItem>
                                <MenuItem value="ONEM2M">ONEM2M</MenuItem>
                                <MenuItem value="LWM2M">LWM2M</MenuItem>
                                <MenuItem value="OMADM">OMADM</MenuItem>
                                <MenuItem value="TR06">TR06</MenuItem>
                                <MenuItem value="MQTT">MQTT</MenuItem>
                            </Select>
                        </FormControl>


                        <FormControl fullWidth margin="dense">
                            <InputLabel>Delivery Method</InputLabel>
                            <Select
                                label="Delivery Method"
                                name="deliveryMethod"
                                value={formMetaData.deliveryMethod}
                                onChange={handleAddChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                                required
                                multiple
                            >
                                <MenuItem value="Push">Push</MenuItem>
                                <MenuItem value="Pull">Pull</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="dense">
                            <OutlinedInput
                                type="file"
                                onChange={handleFileChange}
                                required
                                fullWidth
                            />
                        </FormControl>

                        <DialogActions>
                            <Button type='submit' disabled={addFirmwareLoading ? true : false} variant="contained" style={{ backgroundColor: '#3ecf8e', borderColor: '#3ecf8e' }} >
                                {addFirmwareLoading ? <CircularProgress size="1.5rem" /> : 'Submit'}
                            </Button>
                            <Button onClick={handleAddClose} variant='outlined' color="error">
                                Cancel
                            </Button>

                        </DialogActions>
                    </form>
                </DialogContent>

            </Dialog>


            <Dialog open={deleteDailogOpen} onClose={handleDeleteDailogClose}>
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDailogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteSubmit} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={firmwareDetailsOpen} onClose={handleDeviceDetailsClose}>
                <DialogTitle>Firmware Details</DialogTitle>
                <DialogContent sx={{ padding: "2rem" }}>
                    {/* {Object.entries(firmwareDetails).map(([key, value]) => (
                        <Typography sx={{marginTop:'5px'}} key={key} variant="body1">
                            <strong>{key}:</strong> {value}
                        </Typography>
                    ))} */}
                    <Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Type:</strong> {firmwareDetails.type}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Version:</strong> {firmwareDetails.version}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Firm Type:</strong> {firmwareDetails.firmType}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Filename:</strong> {firmwareDetails.filename}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Delivery Method:</strong> {firmwareDetails.deliverymethod}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Url:</strong> {firmwareDetails.url}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Critical:</strong> {firmwareDetails.critical}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Model Id:</strong> {firmwareDetails.modelid}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Protocol:</strong> {firmwareDetails.protocol}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeviceDetailsClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={firmwareStatusOpen} onClose={handleFirmwareClose}>
                <DialogTitle display='flex' justifyContent='flex-end'>
                    

                    <Button variant="contained" onClick={() => { alert("Refresh Clicked") }} style={{ backgroundColor: '#3ecf8e', borderColor: '#3ecf8e' }} >
                        <RefreshIcon />Refresh
                    </Button></DialogTitle>
                <DialogContent sx={{ padding: "2rem" }}>
                    <h4>Status : {socketIOStaus?.status }</h4>   
                    <DonutChart
                        updatedDevices={updatedDevices}
                        notUpdatedDevices={notUpdatedDevices}
                        totalDevices={totalDevices} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFirmwareClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default OTAManagement