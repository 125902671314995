import React, { useState, useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import Main from "../../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import WhiteLabelRequestsComponent from "../ViewAssetOEM/WhiteLabelRequests";
import BuyRequestsComponent from "../BuyRequests/BuyRequestsComponent";
import OrderAssetManagement from "../OrderManagement/Order/AssetOrder/OrderAssetManagement";
import OrderProductManagement from "../OrderManagement/Order/ProductOrder/OrderProductManagement";
import AssetQuoteManagement from "../QuotationManagement/AssetQuoteManagement";
import ProductQuoteManagement from "../QuotationManagement/ProductQuoteManagement";
import AssetsInvoiceManagement from "../OrderManagement/Invoice/AssetInvoice/AssetsInvoiceManagement";
import ProductsInvoice from "../OrderManagement/Invoice/ProductInvoice/ProductsInvoice";
import "./MyOrderStyleComponent.css";

export default function MyOrdersComponentV0(props) {
  const history = useHistory();
  const { user } = Main.getLocalData();
  const { username, parentId } = user;
  const [userTypeValue, setUserTypeValue] = useState("");
  const [activeComponent, setActiveComponent] = useState("whitelabel");
  const userType = localStorage.getItem("userType");
  const [RequestTypes, setRequestTypes] = useState({});

  useEffect(() => {
    getUserType();
    setRequestTypes({
      AssetDeveloper: "asset-buy-order-req",
      LogisticsProvider: "transport-asset-order-req",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBackButtonClick = () => {
    // Define paths based on user types
    const paths = {
      AssetDeveloper: "./dashboardForAD",
      CBO: "./dashboardForCBO",
    };
    // Get the path based on user type
    const path = paths[userType] || "./defaultDashboard"; // Default path if user type not recognized

    // Redirect to the determined path
    history.push(path);
  };
  const getUserType = () => {
    Main.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  return (
    <>
      <div className="container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            style={{ marginRight: "10px" }}
            onClick={handleBackButtonClick}
            edge="start"
            color="inherit"
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 style={{ margin: 0 }}>My Orders</h2>
        </div>

        <div className="container">
          <div className="topHeadersWrapper">
            <div className="topHeaders">
              <Button
                size="small"
                variant="contained"
                onClick={() => handleButtonClick("whitelabel")}
                disabled={activeComponent === "whitelabel"}
                style={{ marginRight: "10px" }}
                className={
                  activeComponent === "whitelabel" ? "activeButton" : ""
                }
              >
                WhiteLabel Requests
              </Button>
            </div>
            <div className="topHeaders">
              <Button
                size="small"
                variant="contained"
                onClick={() => handleButtonClick("buy")}
                disabled={activeComponent === "buy"}
                style={{ marginRight: "10px" }}
                className={activeComponent === "buy" ? "activeButton" : ""}
              >
                Buy Requests
              </Button>
            </div>
            
          </div>
        </div>
      </div>

      <div style={{ paddingTop: "20px" }}>
        {activeComponent === "whitelabel" && <WhiteLabelRequestsComponent />}
        {activeComponent === "buy" && (
          <BuyRequestsComponent  />
        )}
        
      </div>
    </>
  );
}
