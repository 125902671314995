import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./AgribusinessMain.css";
import AgribusinessMainSection from "./AgribusinessMainSection";
import AgribussinessAdditional from "./AgribussinessAdditional";

export default function AgribusinessMain() {
  return (
    <>
      <UpdatesBanner
        bannerText="Agribusiness"
        className="agribusiness-banner-bg"
      />
      <div className="">
        <div className="subSectionWrapper">
          <h4 className="container-title text-center mx-md-4 ">
            Agribusinesses
          </h4>
          <AgribussinessAdditional />
        </div>
      </div>
    </>
  );
}
