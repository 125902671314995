import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ProcessingMachinery from "../../images/buy-assets-marketplace/processing-machinery.svg";
import FarmingMachinery from "../../images/buy-assets-marketplace/farming-machinery.svg";
import AgriAssets from "../../images/buy-assets-marketplace/agri-asset.svg";
import { Container } from "react-bootstrap";
import ExoploreProductBanner from "../ExploreProductBanner/ExoploreProductBanner";
import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import { NavHashLink } from "react-router-hash-link";

const ProcessingMachineryMarketplace = () => {
  const location = useLocation();
  const history = useHistory();
  const customPropValue = location.state?.customProp;

  let navData = [
    { to: "/asset-marketplace", link: "For Buyers" },
    { to: "/asset-marketplace-sell", link: "For Sellers" },
    // { to: "/onMarketplaceRFQ", link: "RFQ" }
  ];

  const [rawProduct, setRawProduct] = useState(true);
  const [semiProduct, setSemiProduct] = useState(false);
  const [processedProduct, setProcessedProduct] = useState(false);
  const [AnimalData, setAnimalData] = useState(false);
  const [feedProcessingMachinery, setFeedProcessingMachinery] = useState(false);

  const [userType, setUserType] = useState(["FOOD-PROCESSOR"]);

  // useEffect(() => {
  //   if (location.hash === "#processing-machinery") {
  //     setRawProduct(true);
  //     setSemiProduct(false);
  //     setProcessedProduct(false);
  //   } else if (location.hash === "#farming-machinery") {
  //     setRawProduct(false);
  //     setSemiProduct(true);
  //     setProcessedProduct(false);
  //   } else if (location.hash === "#agri-assets") {
  //     setRawProduct(false);
  //     setSemiProduct(false);
  //     setProcessedProduct(true);
  //   }
  // }, [location.hash]);

  const rawProductHandler = () => {
    setRawProduct(true);
    setSemiProduct(false);
    setProcessedProduct(false);
    setAnimalData(false);
    setFeedProcessingMachinery(false);

    // history.push({
    //   pathname: "/onMarketplace",
    //   hash: "#processing-machinery",
    //   state: { customProp: customPropValue },
    // });
  };

  const semiProductHandler = () => {
    setSemiProduct(true);
    setRawProduct(false);
    setProcessedProduct(false);
    setAnimalData(false);
    setFeedProcessingMachinery(false);

    // history.push({
    //   pathname: "/onMarketplace",
    //   hash: "#farming-machinery",
    //   state: { customProp: customPropValue },
    // });
  };

  const processedProductHandler = () => {
    setProcessedProduct(true);
    setRawProduct(false);
    setSemiProduct(false);
    setAnimalData(false);
    setFeedProcessingMachinery(false);

    // history.push({
    //   pathname: "/onMarketplace",
    //   hash: "#agri-assets",
    //   state: { customProp: customPropValue },
    // });
  };

  const AnimalHusbandryHandler = () => {
    setProcessedProduct(false);
    setRawProduct(false);
    setSemiProduct(false);
    setAnimalData(true);
    setFeedProcessingMachinery(false);
  };

  const FeedProcessingMachineryHandler = () => {
    setFeedProcessingMachinery(true);
    setProcessedProduct(false);
    setRawProduct(false);
    setSemiProduct(false);
    setAnimalData(false);
  };
  return (
    <>
      <SecondaryNavbar props={navData} />
      <div>
        <div
          className="serviceContainer buy-asset-marketplace-bg"
          id="ExploreBannerContainer"
        >
          <div className="gridSection gridSection--mod">
            <h1 className="container-title mb-2 text-white">
              Ensured Quality | Traceability
            </h1>
            {/* <p className="container-para text-white">
              Ensure food saftey across globe
            </p> */}

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              direction="row"
            >
              <Grid
                id="complementaryServices_link"
                className="hoverEffect"
                item
                md={2}
                sm={3}
                xs={6}
              >
                <NavHashLink
                  onClick={rawProductHandler}
                  to={{
                    pathname: "/asset-marketplace",
                    hash: "#processing-machinery",
                    state: { customProp: customPropValue },
                  }}
                >
                  <img src={ProcessingMachinery} alt="" />
                  <h4>
                    Processing <br className="d-none d-md-block"></br>Machinery
                  </h4>
                </NavHashLink>
              </Grid>

              <Grid
                id="complementaryServices_link"
                className="hoverEffect"
                item
                md={2}
                sm={3}
                xs={6}
              >
                <NavHashLink
                  onClick={semiProductHandler}
                  to={{
                    pathname: "/asset-marketplace",
                    hash: "#farming-machinery",
                    state: { customProp: customPropValue },
                  }}
                >
                  <img src={FarmingMachinery} alt="" />
                  <h4>
                    Farming <br className="d-none d-md-block"></br>Machinery
                  </h4>
                </NavHashLink>
              </Grid>
              {!customPropValue && (
                <Grid
                  id="coreServices_link"
                  className="hoverEffect"
                  item
                  md={2}
                  sm={3}
                  xs={6}
                >
                  <NavHashLink
                    onClick={processedProductHandler}
                    to="#agri-assets"
                  >
                    <img src={AgriAssets} alt="" />
                    <h4>
                      Agri <br className="d-none d-md-block"></br>Assets
                    </h4>
                  </NavHashLink>
                </Grid>
              )}

              <Grid
                id="complementaryServices_link"
                className="hoverEffect"
                item
                md={2}
                sm={3}
                xs={6}
              >
                <NavHashLink
                  onClick={AnimalHusbandryHandler}
                  to={{
                    pathname: "/asset-marketplace",
                    hash: "#Animal",
                    state: { customProp: customPropValue },
                  }}
                >
                  <img src={FarmingMachinery} alt="" />
                  <h4>Animal</h4>
                </NavHashLink>
              </Grid>

              <Grid
                id="complementaryServices_link"
                className="hoverEffect"
                item
                md={2}
                sm={3}
                xs={6}
              >
                <NavHashLink
                  onClick={FeedProcessingMachineryHandler}
                  to={{
                    pathname: "/asset-marketplace",
                    hash: "#FeedProcessingMachinery",
                    state: { customProp: customPropValue },
                  }}
                >
                  <img src={FarmingMachinery} alt="" />
                  <h4>
                    {" "}
                    Feed Processing <br className="d-none d-md-block"></br>
                    Machinery
                  </h4>{" "}
                </NavHashLink>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className="explore-product-wrapper">
        <Container>
          <div className=" ">
            {/* processing-machinery */}
            {rawProduct === true && (
              <div id="tendersContainer" className="container-fluid">
                <h3 id="processing-machinery" className="container-title  ">
                  Processing Machinery
                </h3>
                {/* <ProcessedProducts  quote="true"  type="RAW-PRODUCTS" userType = {userType} /> */}

                <ProcessedProducts
                  type="MACHINERY"
                  userType={["PMC"]}
                  cbn="true"
                />
              </div>
            )}
            {/* farming-machinery */}
            {semiProduct === true && (
              <div id="tendersContainer" className="container-fluid">
                <h3 id="farming-machinery" className="container-title">
                  {" "}
                  Farming Machinery
                </h3>
                <ProcessedProducts
                  type="FARM-MACHINERY"
                  userType={["FMC"]}
                  cbn="true"
                />{" "}
              </div>
            )}
            {/* agri-assets */}
            {processedProduct && (
              <div id="tendersContainer" className="container-fluid">
                <h3 id="agri-assets" className="container-title  ">
                  Agri Assets
                </h3>
                <ProcessedProducts
                  type="AGRI-ASSETS"
                  userType={["AssetDeveloper"]}
                  cbn="true"
                />{" "}
              </div>
            )}

            {AnimalData && (
              <div id="tendersContainer" className="container-fluid">
                <h3 id="Animal" className="container-title  ">
                  Animal
                </h3>
                <ProcessedProducts
                  type="ANIMAL"
                  userType={["AssetDeveloper"]}
                  cbn="true"
                />{" "}
              </div>
            )}

            {feedProcessingMachinery && (
              <div id="tendersContainer" className="container-fluid">
                <h3 id="FeedProcessingMachinery" className="container-title  ">
                  Feed Processing Machinery
                </h3>
                <ProcessedProducts
                  type="ANIMAL"
                  userType={["AssetDeveloper"]}
                  cbn="true"
                />{" "}
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default ProcessingMachineryMarketplace;
