import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";

import RoleManagementComponent from "../AssetDeveloperRole/RoleManagement/RoleManagement";
import RoleProfileManagementComponent from "../AssetDeveloperRole/RoleProfileManagement/RoleProfileManagementComponent";
import TransportAssetManagement from "../AssetDeveloperRole/TransportManagement/TransportManagementComponent";
import DriverComponent from "../AssetDeveloperRole/Driver/DriverComponent";
import AAMCSiteManagementComponent from "../../Main/AssetDeveloperRole/AAMCSiteManagement/AAMCSiteManagementComponent";
import MainService from "../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import DepotOperatorComponent from "../DepotOperator/DepotOperatorComponent";

import DepotManagerComponent from "../DepotManager/DepotManagerComponent";
import TransportUserComponent from "../AssetDeveloperRole/TransportUser/TransportUserComponent";
import TransportManagerComponent from "../AssetDeveloperRole/TransportManager/TransportManagerComponent";
import AAMCAgentComponent from "../AssetDeveloperRole/AAMCAgent/AAMCAgetComponent";
import DepotOwnerComponent from "../AssetDeveloperRole/DepotOwner/DepotOwnerComponent";
import RegisterUsersRequestsComponent from "../AssetDeveloperRole/RegisterUserRequests/RegisterUserRequestsComponent";

export default function ACNComponent(props) {
  const [viewRole, setViewRole] = useState(false);
  const [viewDrivers, setViewDrivers] = useState(false);
  const [viewTransportUser, setViewTransportUser] = useState(false);
  const [viewTransportManager, setViewTransportManager] = useState(false);

  const [viewTransportAssetManagement, setViewTransportAssetManagement] =
    useState(false);
  const [viewAgents, setViewAgents] = useState(false);
  const [viewDepoManagers, setViewDepoManagers] = useState(false);
  const [viewDepoOwners, setViewDepoOwners] = useState(true);
  const [viewSiteManagement, setViewSiteManagement] = useState(false);

  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [roleNameValue, setRoleNameValue] = useState("");
  const [viewSuperRole, setViewSuperRole] = useState(false);
  const [userTypeValue, setUserTypeValue] = useState("");
  const [viewUserRequests, setViewUserRequests] = useState(false);
  const [viewStoreOperator, setViewDepoOperator] = useState(false);

  const UserRole = localStorage.getItem("UserRole");

  useEffect(() => {
    getUserType();
  }, [userTypeValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  useEffect(() => {
    getRoleName();
  }, []);
  const getRoleName = () => {
    MainService.getRole(parentId, username)
      .then((res) => {
        setRoleNameValue(res.role);
        console.log("role P n/w res:", res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const viewRequestsClick = () => {
    setViewRole(false);
    setViewDrivers(false);

    setViewDepoManagers(false);

    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportAssetManagement(false);
    setViewTransportUser(false);
    setViewTransportManager(false);
    setViewDepoOperator(false);
    setViewAgents(false);
    setViewDepoOwners(false);
    setViewUserRequests(true);
  };
  const viewDepoOwnersClick = () => {
    setViewRole(false);
    setViewDrivers(false);

    setViewDepoManagers(false);

    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportAssetManagement(false);
    setViewTransportUser(false);
    setViewTransportManager(false);
    setViewDepoOperator(false);
    setViewAgents(false);
    setViewDepoOwners(true);
    setViewUserRequests(false);
  };
  const viewAgentsClick = () => {
    setViewRole(false);
    setViewDrivers(false);

    setViewDepoManagers(false);

    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportAssetManagement(false);
    setViewTransportUser(false);
    setViewTransportManager(false);
    setViewDepoOperator(false);
    setViewAgents(true);
    setViewDepoOwners(false);
    setViewUserRequests(false);
  };
  const viewTransportManagersClick = () => {
    setViewRole(false);
    setViewDrivers(false);

    setViewDepoManagers(false);

    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportAssetManagement(false);
    setViewTransportUser(false);
    setViewTransportManager(true);
    setViewDepoOperator(false);
    setViewAgents(false);
    setViewDepoOwners(false);
    setViewUserRequests(false);
  };
  const viewTransportUsersClick = () => {
    setViewRole(false);
    setViewDrivers(false);

    setViewDepoManagers(false);

    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportAssetManagement(false);
    setViewTransportUser(true);
    setViewTransportManager(false);
    setViewDepoOperator(false);
    setViewAgents(false);
    setViewDepoOwners(false);
    setViewUserRequests(false);
  };
  const viewDepoOperatorsClick = () => {
    setViewRole(false);
    setViewDrivers(false);

    setViewDepoManagers(false);

    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportAssetManagement(false);
    setViewTransportUser(false);
    setViewTransportManager(false);

    setViewDepoOperator(true);
    setViewAgents(false);
    setViewDepoOwners(false);
    setViewUserRequests(false);
  };
  const viewRoleClick = () => {
    setViewRole(true);
    setViewDrivers(false);

    setViewDepoManagers(false);

    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportAssetManagement(false);
    setViewTransportUser(false);
    setViewTransportManager(false);

    setViewDepoOperator(false);
    setViewAgents(false);
    setViewDepoOwners(false);
    setViewUserRequests(false);
  };

  const viewTransportAssetManagementClick = () => {
    setViewTransportAssetManagement(true);
    setViewDrivers(false);

    setViewDepoManagers(false);

    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewRole(false);

    setViewTransportUser(false);
    setViewTransportManager(false);
    setViewDepoOperator(false);
    setViewAgents(false);
    setViewDepoOwners(false);
    setViewUserRequests(false);
  };
  const viewDriversClick = () => {
    setViewRole(false);
    setViewDrivers(true);

    setViewDepoManagers(false);

    setViewTransportAssetManagement(false);
    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportUser(false);
    setViewTransportManager(false);

    setViewDepoOperator(false);
    setViewAgents(false);
    setViewDepoOwners(false);
    setViewUserRequests(false);
  };

  const viewDepoManagersClick = () => {
    setViewTransportAssetManagement(false);
    setViewRole(false);
    setViewDrivers(false);

    setViewDepoManagers(true);

    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportUser(false);
    setViewTransportManager(false);

    setViewDepoOperator(false);
    setViewAgents(false);
    setViewDepoOwners(false);
    setViewUserRequests(false);
  };

  const viewSiteManagementClick = () => {
    setViewTransportAssetManagement(false);
    setViewRole(false);
    setViewDrivers(false);

    setViewDepoManagers(false);

    setViewSiteManagement(true);

    setViewSuperRole(false);
    setViewTransportUser(false);
    setViewTransportManager(false);

    setViewDepoOperator(false);
    setViewAgents(false);
    setViewDepoOwners(false);
    setViewUserRequests(false);
  };

  const viewSuperRoleClick = () => {
    setViewTransportAssetManagement(false);
    setViewRole(false);
    setViewDrivers(false);

    setViewDepoManagers(false);

    setViewSiteManagement(false);

    setViewSuperRole(true);
    setViewTransportUser(false);
    setViewTransportManager(false);

    setViewDepoOperator(false);
    setViewAgents(false);
    setViewDepoOwners(false);
    setViewUserRequests(false);
  };

  return (
    <>
      <div className="topHeadersWrapper">
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSiteManagementClick();
            }}
          >
            Site Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSuperRoleClick();
            }}
          >
            Role Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewRoleClick();
            }}
          >
            Role Profile Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewRequestsClick();
            }}
          >
            Requests
          </Button>
        </div>
        &nbsp;
      </div>
      &nbsp;
      {userTypeValue === "LogisticProvider" && (
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              color="success"
              size="large"
              variant="contained"
              onClick={() => {
                viewTransportAssetManagementClick();
              }}
            >
              Transport Asset Management
            </Button>
          </div>
        </div>
      )} 
      <div className="container" id="mainContentList">
        <h2>ACN Network</h2>
        &nbsp;&nbsp;
        {userTypeValue === "LogisticsProvider" && (
          <div className="topHeadersWrapper">
            <div className="topHeaders">
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  viewDepoOwnersClick();
                }}
              >
                Depot Owner
              </Button>
            </div>
            &nbsp;
            <div className="topHeaders">
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  viewDepoManagersClick();
                }}
              >
                Depot Manager
              </Button>
            </div>
            &nbsp;
            <div className="topHeaders">
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  viewDepoOperatorsClick();
                }}
              >
                Depot Operator
              </Button>
            </div>
            &nbsp;
            {(UserRole === "BASIC" ||
              UserRole === "STANDARD" ||
              UserRole === "PREFERRED" ||
              UserRole === "PREMIUM" ||
              UserRole === "ADMIN") && (
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewDriversClick();
                  }}
                >
                  Driver
                </Button>
              </div>
            )}
            &nbsp;
            {(UserRole === "PREMIUM" || UserRole === "ADMIN") && (
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewTransportUsersClick();
                  }}
                >
                  Transport User
                </Button>
              </div>
            )}
            &nbsp;
            {(UserRole === "PREFERRED" ||
              UserRole === "PREMIUM" ||
              UserRole === "ADMIN") && (
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewTransportManagersClick();
                  }}
                >
                  LogisticManager
                </Button>
              </div>
            )}
            &nbsp;
            {(UserRole === "PREFERRED" ||
              UserRole === "PREMIUM" ||
              UserRole === "ADMIN") && (
              <div className="topHeaders">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    viewAgentsClick();
                  }}
                >
                  Agents
                </Button>
              </div>
            )}
          </div>
        )}
      </div>

      <div style={{ paddingTop: "20px" }}>
        {viewSiteManagement && (
          <AAMCSiteManagementComponent siteType={["Space"]} />
        )}

        {viewTransportAssetManagement && <TransportAssetManagement />}
        {viewRole && (
          <RoleProfileManagementComponent siteType={["Space"]} networkType="ACN" />
        )}
        {viewAgents && <AAMCAgentComponent />}
        {viewDepoManagers && <DepotManagerComponent />}
        {viewDrivers && <DriverComponent />}
        {viewDepoOwners && <DepotOwnerComponent />}
        {viewStoreOperator && <DepotOperatorComponent />}
        {viewTransportManager && <TransportManagerComponent />}
        {viewTransportUser && <TransportUserComponent />}
        {viewUserRequests && (
          <RegisterUsersRequestsComponent networkType="ACN" />
        )}
        {viewSuperRole && <RoleManagementComponent />}
      </div>
    </>
  );
}
