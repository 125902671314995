import React, { memo, Fragment, useState, useEffect, useMemo } from "react";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";
import {
    Box,
    FormControl,
    InputLabel,
    Input,
    Select,
    MenuItem,
    Button,
} from "@mui/material";
import Selectr from "react-select";
import countryList from "country-list";
import { subCategorieValidation } from "../../../Main/AssetDeveloperRole/ModelProductManagement/MarketValidation/MarketValidation";


function ProductCreateForm({
    additem,
    categoryId,
    categoryName,
    reLoad,
    subCatList,
    openModel,
    closeModel,
    defaultSubCat,
}) {
    console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", additem)
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    const [verticalsIdList, setVerticalsIdList] = useState([]);
    const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
    const [categoryTypesList, setCategoryTypesList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [assertTypesList, setAssertTypesList] = useState([]);
    const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
    const [modelList, setModelsList] = useState([]);
    const [selectedModel, setSelectedModel] = useState("");
    const [productIdValue, setProductIdValue] = useState("");
    const [productFriendlyNameValue, setProductFriendlyNameValue] = useState("");
    const [assetVerticalFriendlyNameValue, setAssetVerticalFriendlyNameValue] =
        useState("");
    const [getProductCostValue, setProductCostValue] = useState();
    const [getUnitValue, setUnitValue] = useState("");
    const [getDiscountValue, setDiscountValue] = useState("");
    const [getDiscountedPrice, setDiscountedPrice] = useState(0);
    const [getCategoriesList, setCategoriesList] = useState("");
    const [getCategoriesNameList, setCategoriesNameList] = useState("");
    const [getSubCategoriesList, setSubCategoriesList] = useState([]);
    const [getCreateSubCategoryProps, setCreateSubCategoryProps] = useState([]);
    const [getCountery, setCountery] = useState(null);
    const [getCounterys, setCounterys] = useState(null);
    const [getModelFriendlyName, setModelFriendlyName] = useState("");
    const [getSubCategoryForForm, setSubCategoryForForm] = useState("");
    const [assetPopup, setShowAssetPopup] = useState(false);


    useEffect(() => {
        if (!username || !getCategoriesList) return;
        subCategoryList();
    }, [username, getCategoriesList]);

    const categorysList = () => {
        MainService.getListOfCategorys()
            .then((res) => {
                const filterCategory = res.filter((data) => {
                    return data.categoryname.includes('DEVICE');
                });
                setCreateSubCategoryProps(Object.assign({}, filterCategory[0]));
                setCategoriesList(filterCategory[0]?.categoryid);
                setCategoriesNameList(filterCategory[0]?.categoryname);
            })
            .catch((err) => {
                return NotificationManager.error(err);
            });
    };

    useMemo(() => {
        if (!username) return;
        categorysList();
    }, []);

    const subCategoryList = () => {
        MainService.getCategoryRelatedSubCategories(username, getCategoriesList)
            .then((res) => {
                if (res.length > 0) {
                    setSubCategoryForForm(res[0]?.contentsubcategoryname);
                    setSubCategoriesList(res);
                } else {
                    setSubCategoriesList(res);
                }
            })
            .catch((err) => {
                return NotificationManager.error(err);
            });
    };

    /*set defaut sub categry value */
    useEffect(() => {
        setSubCategoryForForm(defaultSubCat);
    }, [defaultSubCat]);

    /* asset verticals */
    const getAssetVerticalsIdList = () => {
        MainService.getAssetVerticalsIdList(window.AppConfigData.EnterpriseId)
            .then((data) => {
                const filerFriendlyName = ["MACHINERY", "TRANSPORTATION"];
                const filterData = data.filter((val, index) => {
                    return !filerFriendlyName.includes(val.friendlyName);
                });
                if (filterData.length > 0) {
                    setSelectedVerticalsId(filterData[0].assetVerticalId);
                    setAssetVerticalFriendlyNameValue(filterData[0].friendlyName);
                    setVerticalsIdList(filterData);
                } else {
                    setSelectedVerticalsId("");
                }
            })
            .catch((e) => {
                return NotificationManager.error(e);
            });
    };

    useMemo(() => {
        if (!categoryId) return;
        getAssetVerticalsIdList();
    }, [categoryId]);

    const getCategoryTypesList = () => {
        if (selectedVerticalsId !== "") {
            MainService.getCategoryTypesList(
                selectedVerticalsId.replace(" ", ""),
                window.AppConfigData.EnterpriseId
            )
                .then((data) => {
                    if (data.length > 0) {
                        setSelectedCategory(data[0].categoryType);
                        setCategoryTypesList(data);
                    } else {
                        setSelectedCategory("");
                    }
                })
                .catch((e) => {
                    return NotificationManager.error(e);
                });
        }
    };

    useMemo(() => {
        getCategoryTypesList();
    }, [selectedVerticalsId]);

    const getAssetTypesList = () => {
        if (selectedVerticalsId !== "" && selectedCategory !== "") {
            MainService.getAssetTypesList(
                selectedVerticalsId.replace(" ", ""),
                selectedCategory.replace(" ", ""),
                window.AppConfigData.EnterpriseId
            )
                .then((data) => {
                    if (data.length > 0) {
                        setSelectedAssertTypes(data[0].assetType);
                        setAssertTypesList(data);
                    } else {
                        setSelectedAssertTypes("");
                    }
                })
                .catch((e) => {
                    return NotificationManager.error(e);
                });
        }
    };

    useMemo(() => {
        getAssetTypesList();
    }, [selectedCategory]);

    const getModelsList = () => {
        if (
            selectedVerticalsId !== "" &&
            selectedCategory !== "" &&
            selectedAssertTypes !== ""
        ) {
            MainService.getModelsList(
                selectedVerticalsId.replace(" ", ""),
                selectedCategory.replace(" ", ""),
                selectedAssertTypes.replace(" ", ""),
                username
            )
                .then((data) => {
                    if (data.length > 0) {
                        setSelectedModel(data[0].modelid);
                        setModelFriendlyName(data[0].modelfriendlyname);
                        setModelsList(data);
                    } else {
                        setSelectedModel("");
                    }
                })
                .catch((e) => {
                    return NotificationManager.error(e);
                });
        }
    };

    useMemo(() => {
        getModelsList();
    }, [selectedAssertTypes]);
    /*end asset verticals */

    /* create product */
    const createProductHandler = () => {
        if (productIdValue === "") {
            return NotificationManager.warning("Please Enter Product Model Id");
        }
        let createProductData = {
            consumeradminid: window.AppConfigData.EnterpriseId,
            businessid: username,
            assetverticalid: "null",
            assetverticalname: "null",
            category: "null",
            legacyassettype: "null",
            modelid: "null",
            modelfriendlyname: "null",
            assetid: "null",
            assetfriendlyname: "null",
            productid: productIdValue.replace(/\s/g, ""),
            productfriendlyname: productFriendlyNameValue,
            productcost: Number(getProductCostValue),
            unit: getUnitValue,
            discount: getDiscountValue,
            discountedprice: Number(getDiscountedPrice),
            country: getCountery,
            state: "null",
            district: "null",
            categoryname: "DEVICE",
        };
        const modelProductData = JSON.stringify(createProductData);
        console.log(createProductData, "creteproduct data")
        // closeModel();
        MainService.addModelProduct(
            parentId,
            username,
            getCategoriesList,
            getSubCategoryForForm,
            modelProductData
        )
            .then((res) => {
                closeModel();
                return NotificationManager.success("product info stored");
            })
            .catch((e) => {
                return NotificationManager.error(e);
            });
    };

    const setDiscountValueHandler = (e) => {
        setDiscountValue(e.target.value);
        const price = Math.ceil(
            getProductCostValue - (getProductCostValue * e.target.value) / 100
        );
        setDiscountedPrice(price);
    };

    const countries = countryList.getData();
    const countryOptions = countries?.map((country) => ({
        value: country.code,
        label: country.name,
    }));

    const handleCountryChange = (selectedOption) => {
        setCounterys(selectedOption);
        setCountery(selectedOption.label);
    };

    const closeAgreementModal = <span onClick={closeModel}>X</span>;
    const titleAgreement = "Create Product";
    const agreementBody = (
        <Box>
            <FormControl
                fullWidth={true}
                size="medium"
                margin="dense"
                variant="standard"
            >
                <InputLabel htmlFor="categoryname">Device Category:</InputLabel>
                <Input
                    id="categoryname"
                    name="categoryname"
                    type="text"
                    value={getCategoriesNameList}
                />
            </FormControl>
            <FormControl
                fullWidth={true}
                size="medium"
                margin="dense"
                variant="standard"
            >
                <InputLabel>Device Sub-Category:</InputLabel>
                <Select
                    id="SubCategory"
                    name="SubCategory"
                    type="text"
                    value={getSubCategoryForForm}
                    onChange={(e) => {
                        setSubCategoryForForm(e.target.value);
                    }}
                >
                    {getSubCategoriesList.length > 0 ? (
                        getSubCategoriesList.map((idObj, index) => (
                            <MenuItem key={`${index} `} value={idObj.contentsubcategoryname}>
                                {idObj.contentsubcategoryname}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>Sub-Category Not Found</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl
                fullWidth={true}
                size="medium"
                margin="dense"
                variant="standard"
            >
                <InputLabel htmlFor="businessid">Business ID:</InputLabel>
                <Input
                    disabled
                    id="businessid"
                    name="businessid"
                    type="text"
                    value={username}
                />
            </FormControl>
            <FormControl
                fullWidth={true}
                size="medium"
                margin="dense"
                variant="standard"
            >
                <InputLabel htmlFor="productid">Device Model ID :</InputLabel>
                <Input
                    id="productid"
                    name="productid"
                    type="text"
                    placeholder="5533222"
                    value={productIdValue}
                    onChange={(e) => {
                        setProductIdValue(e.target.value);
                    }}
                />
            </FormControl>
            <FormControl
                fullWidth={true}
                size="medium"
                margin="dense"
                variant="standard"
            >
                <InputLabel htmlFor="ProductFriendlyName">
                    Device Model Friendly Name :
                </InputLabel>
                <Input
                    id="ProductFriendlyName"
                    name="ProductFriendlyName"
                    placeholder="5533222"
                    type="text"
                    value={productFriendlyNameValue}
                    onChange={(e) => {
                        setProductFriendlyNameValue(e.target.value);
                    }}
                />
            </FormControl>
            <FormControl
                fullWidth={true}
                size="medium"
                margin="dense"
                variant="standard"
            >
                <InputLabel htmlFor="productcost">Device Cost:</InputLabel>
                <Input
                    id="productcost"
                    name="productcost"
                    type="text"
                    value={getProductCostValue}
                    onChange={(e) => {
                        setProductCostValue(e.target.value);
                    }}
                />
            </FormControl>
            <FormControl
                fullWidth={true}
                size="medium"
                margin="dense"
                variant="standard"
            >
                <InputLabel htmlFor="unit">Unit:</InputLabel>
                <Input
                    id="unit"
                    name="unit"
                    type="text"
                    value={getUnitValue}
                    onChange={(e) => {
                        setUnitValue(e.target.value);
                    }}
                />
            </FormControl>
            <FormControl
                fullWidth={true}
                size="medium"
                margin="dense"
                variant="standard"
            >
                <InputLabel htmlFor="discount">Discount:</InputLabel>
                <Input
                    id="discount"
                    name="discount"
                    type="text"
                    value={getDiscountValue}
                    onChange={(e) => {
                        setDiscountValueHandler(e);
                    }}
                />
            </FormControl>
            <FormControl
                fullWidth={true}
                size="medium"
                margin="dense"
                variant="standard"
            >
                <InputLabel htmlFor="discountprice">Discounted Price:</InputLabel>
                <Input
                    disabled
                    id="discountprice"
                    name="discountprice"
                    type="text"
                    value={getDiscountedPrice}
                //   onChange={(e) => {
                //     setDiscountedPrice(e.target.value);
                //   }}
                />
            </FormControl>
            <FormControl
                fullWidth={true}
                size="medium"
                margin="dense"
                variant="standard"
            >
                <Selectr
                    value={getCounterys}
                    onChange={handleCountryChange}
                    options={countryOptions}
                    placeholder="Select country"
                />
            </FormControl>
            <Box>
                <Button
                    sx={{ m: "5px" }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={(e) => createProductHandler()}
                >
                    Create
                </Button>
            </Box>
        </Box>
    );
    /* end create product */

    return (
        <Fragment>
            <CustomPopup
                closeButton={closeAgreementModal}
                isOpen={openModel}
                closeModal={openModel}
                content={agreementBody}
                title={titleAgreement}
            />
        </Fragment>
    );
}
export default memo(ProductCreateForm);
