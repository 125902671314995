import React from "react";
import {
    Button,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row, Container,
    Pagination, PaginationItem, PaginationLink,
    Badge,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import AuthAxiosInstance from "../../../authAxiosInstance";
import { DeviceMetadataAxiosInstance } from '../../../authAxiosInstance'
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { CheckCircleOutline, Cancel } from '@mui/icons-material';
import { NotificationManager } from "react-notifications";

// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// toast.configure({
//     autoClose: 3000,
//     draggable: false,
//     //etc you get the idea
// });

function ModelConfigure(props) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    const [deviceType, setDeviceType] = React.useState([]);
    const [deviceList, setDeviceList] = React.useState([]);
    const [deviceProperty, setDeviceProperty] = React.useState([]);
    const [modelList, setModelList] = React.useState([]);
    const [type, selectedType] = React.useState("");
    const [model, selectedModel] = React.useState("");
    const [firmwareButtonEnable, setFirmwareButtonEnable] = React.useState(false);
    let [primary, setPrimary] = React.useState(false);
    let [update, setUpdate] = React.useState(false);
    let [errors, setError] = React.useState("");
    let [accordion, setAccordion] = React.useState([true]);
    let [disabledstate, setDisabled] = React.useState(true);
    let [devicetypenew, setDevicetypenew] = React.useState("");
    let [modelinfo, setModelinfo] = React.useState("");
    let [modelinfoNew, setModelinfoNew] = React.useState("");

    let [modelname, setModelname] = React.useState("");
    let [createnewrecord, setCreatenewrecord] = React.useState(true);
    let [deleteconfirm, setDeleteconfirm] = React.useState(false);
    let [wrongformat, setWrongformat] = React.useState(false);
    let [wrongmin, setWrongmin] = React.useState(false);
    let [wrongspecial, setWrongspecial] = React.useState(false);
    let [wrongmincount, setWrongmincount] = React.useState(0);
    const [selectedProperties, setSelectedProperties] = React.useState([]);
    const [selectedModelProperty, setSelectedModelProperty] = React.useState([]);
    const [selectedUpdateProperty, setSelectedUpdateProperty] = React.useState(null);
    const [metadata, setMetadata] = React.useState({
        mandatory: false,
        editable: true,
        property: '',
        valueType: '',
        Desired: [],
        unit: '',
    });
    const [desired, setDesired] = React.useState('');
    const [desireEnable, setDesireEnable] = React.useState(false);
    const [updatePropertyList, setUpdatePropertyList] = React.useState([]);
    const [updateModelPropertyList, setUpdateModelPropertyList] = React.useState([]);

    const handleInputChangeEdit = (e) => {
        const { name, value } = e.target;
        if (name === 'editable') {
            if (value === '2') {

                setDesireEnable(true)
                setUpdate(true)
            } else {
                setDesireEnable(false)
                setUpdate(true)
            }
        }
        if (name === 'Desired') {
            setMetadata((prevMetadata) => ({
                ...prevMetadata,
                [name]: value.split(','),
            }));
            return
        }
        setMetadata((prevMetadata) => ({
            ...prevMetadata,
            [name]: value,
        }));
    };
    React.useEffect(() => {
        // This code will run whenever modelinfo changes.
        console.log(modelinfo, 'gggg');
    }, [modelinfo]);

    const handleEditModelProperty = (e) => {
        console.log(metadata, "mrtadata")
        e.preventDefault();
        setUpdateModelPropertyList([...updateModelPropertyList, metadata.property])
        const newArray = JSON.parse(modelinfo)?.properties.map((item) => {
            if (item.property === metadata.property) {
                return metadata; // Replace the matching object
            }
            return item; // Keep other objects unchanged
        });
        console.log(modelinfo, 'modelinfobegin')
        setModelinfo(JSON.stringify({ properties: newArray }), () => {
        });
    };

    const getdata = () => {
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/list`).then((res) => {

            if (res.data)
                setDeviceType(res.data);
            else
                setDeviceType([]);
        }).catch((err) => {
            setDeviceType([]);
        });

    }

    React.useEffect(() => {

        //Getting Device Type List Axios Instance


        getdata();


        validatefield();


    }, [devicetypenew, modelinfo, modelname]);

    const selectDevice = (evt) => {
        //const filterModelDevice = deviceList.filter((d) => (d.deviceType === evt.target.value));
        selectedType(evt.target.value);
        //setModelList(filterModelDevice);
        setModelList([]);
        selectedModel("");
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${evt.target.value}/models`).then((res) => {
            setModelList(res.data);
        }).catch((err) => {
            setModelList([]);
        });
        setDeviceProperty([]);
    }
    const modelName = (evt) => {
        setModelname(evt.target.value);
        if (evt.target.value.length < devicetypenew.length) {
            setWrongmincount(devicetypenew.length);
            setWrongmin(true);
        }
        else {
            setWrongmin(false);
        }
        let nospecial = /[*|\":<>[\]{}`\\()';@&$!#^=+%`~,./? _\-]/;
        if (nospecial.test(evt.target.value)) {
            setWrongspecial(true);
        }
        else {
            setWrongspecial(false);
        }
    }

    const modelInfo = (evt) => {
        try {
            setModelinfo(JSON.stringify(JSON.parse(evt.target.value), undefined, 4));
            setWrongformat(false);
        }
        catch (e) {
            setModelinfo(evt.target.value);
            setWrongformat(true);

        }
    }

    const createmodelinfo = () => {

        try {
            let req = {};
            req.deviceType = devicetypenew;
            req.model = modelname;
            req.modelInfo = {
                "properties": { "properties": selectedModelProperty }
            }
            req.modelInfo.NumberofProperties = req.modelInfo.properties.length;
            if (createnewrecord) {
                DeviceMetadataAxiosInstance.post(`/admin/deviceprofile/${username}/models/modelInfo`, req)
                    .then((response) => {
                        setPrimary(!primary);
                        setModelinfo('');
                        setModelname('');
                        selectedType('');
                        selectedModel('');
                        setDevicetypenew('');
                        setSelectedProperties('');
                        setSelectedModelProperty('')
                        // toast.success("Model info created successfully", {
                        //     position: toast.POSITION.TOP_RIGHT
                        // });
                        getdata();
                        return NotificationManager.success("Model info created successfully");
                    }).catch((err) => {
                        if (err.response && typeof (err.response.data) === 'string') {
                            // toast.error(err.response.data, {
                            //     position: toast.POSITION.TOP_RIGHT
                            // });
                            return NotificationManager.error("Some error occured");
                        }
                        else {
                            // toast.error("Some error occured", {
                            //     position: toast.POSITION.TOP_RIGHT
                            // });
                            return NotificationManager.error("Some error occured");
                        }
                    });
            }
            else {
                DeviceMetadataAxiosInstance.put(`/admin/deviceprofile/${username}/modelInfo/${type}/${model}`, req)
                    .then((response) => {
                        setUpdate(!update);
                        setModelinfo('');
                        setModelname('');
                        setDevicetypenew('');
                        // toast.success("Model info updated successfully", {
                        //     position: toast.POSITION.TOP_RIGHT
                        // });
                        return NotificationManager.success("Model info updated successfully");

                        getProperty();
                    }).catch((err) => {
                        // toast.error("Some error occured", {
                        //     position: toast.POSITION.TOP_RIGHT
                        // });
                        return NotificationManager.error("Some error occured");
                    });
            }
        }
        catch (e) {
            console.log(e.error)
            // toast.error("Some error occured", {
            //     position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");
        }
    }

    const updatemodelinfo = () => {

        try {
            let req = {};
            req.deviceType = devicetypenew;
            req.model = modelname;
            req.modelInfo = {
                "properties": JSON.parse(modelinfo)
            }
            req.modelInfo.NumberofProperties = req.modelInfo.properties.length;
            if (createnewrecord) {
                DeviceMetadataAxiosInstance.post(`/admin/deviceprofile/${username}/models/modelInfo`, req)
                    .then((response) => {
                        setPrimary(!primary);
                        setModelinfo('');
                        setModelname('');
                        setDevicetypenew('');
                        // toast.success("Model info created successfully", {
                        //     position: toast.POSITION.TOP_RIGHT
                        // });
                        getdata();
                        return NotificationManager.success("Model info created successfully");

                    }).catch((err) => {
                        if (err.response && typeof (err.response.data) === 'string') {
                            // toast.error(err.response.data, {
                            //     position: toast.POSITION.TOP_RIGHT
                            // });
                            return NotificationManager.error("Some error occured");
                        }
                        else {
                            // toast.error("Some error occured", {
                            //     position: toast.POSITION.TOP_RIGHT
                            // });
                            return NotificationManager.error("Some error occured");
                        }
                    });
            }
            else {
                DeviceMetadataAxiosInstance.put(`/admin/deviceprofile/${username}/modelInfo/${type}/${model}`, req)
                    .then((response) => {
                        setUpdate(!update);
                        setModelinfo('');
                        setModelname('');
                        setDevicetypenew('');
                        setUpdateModelPropertyList([]);
                        // toast.success("Model info updated successfully", {
                        //     position: toast.POSITION.TOP_RIGHT
                        // });
                        getProperty();
                        return NotificationManager.success("Model info updated successfully");

                    }).catch((err) => {
                        // toast.error("Some error occured", {
                        //     position: toast.POSITION.TOP_RIGHT
                        // });
                        return NotificationManager.error("Some error occured");

                    });
            }
        }
        catch (e) {
            console.log(e.error)
            // toast.error("Some error occured", {
            //     position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");

        }
    }

    function validatefield() {
        if (modelname && devicetypenew && modelinfo) {
            if (wrongformat) {
                setDisabled(true);
                setError("Please fill model info in correct JSON format");
            }
            else {
                if (wrongmin) {
                    setDisabled(true);
                    setError(`Minimum length for model name is ${wrongmincount}`);
                }
                else {
                    if (wrongspecial) {
                        setDisabled(true);
                        setError(`No Special characters allowed in model name`);
                    }
                    else {
                        setDisabled(false);
                        setError("");
                    }
                }

            }
        }
        else {
            setDisabled(true);
            setError("Please fill all the fields");
        }
    }

    const selectModel = (evt) => {
        selectedModel(evt.target.value);
        setDeviceProperty([]);
    }

    const selectDeviceNew = (evt) => {
        setDevicetypenew(evt.target.value);
        setModelname(evt.target.value);
        setWrongmin(false);
        setWrongspecial(false);
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/${evt.target.value}`)
            .then((response) => {
                if (response && response.data && response.data.metadata)
                    setModelinfo(response.data.metadata);
            }).catch((err) => {
                // toast.error("Some error occured", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                return NotificationManager.error("Some error occured");

            });
    }

    const togglePrimary = () => {
        setPrimary(!primary);
        setModelinfo('');
        setModelname('');
        setDevicetypenew('');
        setError("");
        setModelinfo("");
        setDeviceProperty([]);
        setSelectedProperties('');
        setSelectedModelProperty([])

    }

    const toggleUpdate = () => {
        setUpdate(!update);
        // setModelinfo('');
        setModelname('');
        setDevicetypenew('');
        setError("");
        setUpdateModelPropertyList([]);
    }

    const deleteProperty = () => {
        setDeleteconfirm(!deleteconfirm);
    }

    const toggledeleteconfirm = () => {
        setDeleteconfirm(!deleteconfirm);
    }

    const onclickdelete = () => {
        DeviceMetadataAxiosInstance.delete(`/admin/deviceprofile/${username}/modelInfo/${type}/${model}`)
            .then((response) => {
                setDeleteconfirm(!deleteconfirm);
                // toast.success("Model info deleted successfully", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                let e = {};
                e.target = {};
                e.target.value = type;
                selectDevice(e);
                setDeviceProperty([]);
                
                return NotificationManager.success("Model info deleted successfully");
            }).catch((err) => {
                // toast.error("Some error occured", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                return NotificationManager.error("Some error occured");

            });
    }

    const toggleAccordion = (tab) => {
        const prevState = accordion;
        const state = prevState.map((x, index) => tab === index ? !x : false);
        setAccordion(state);
    }

    const createnew = () => {
        setPrimary(true);
        setModelinfo("");
        setCreatenewrecord(true);
        setError('');
    }

    const getProperty = () => {
        if (type && model) {
            DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/modelInfo/${type}/${model}`).then((res) => {
                if (res.data) {
                    console.log(res.data, "responce data")
                    setModelinfo(JSON.stringify(res.data.properties), () => {
                        console.log(res.data.properties, "data")

                    });
                    console.log(modelinfo, "next")
                    if ((res.data.properties.properties)) {
                        setDeviceProperty(res.data.properties.properties);
                    }
                    else {
                        let a = [];
                        for (let i = 0; i < Object.keys(res.data.properties).length; i++) {
                            let o = {};
                            o.property = Object.keys(res.data.properties)[i];
                            o.valuetype = typeof res.data.properties[Object.keys(res.data.properties)[i]];
                            o.mandatory = true;
                            if (o.valuetype === 'object') {
                                if (Array.isArray(res.data.properties[Object.keys(res.data.properties)[i]])) {
                                    o.valuetype = 'array';
                                }
                                o.value = JSON.stringify(res.data.properties[Object.keys(res.data.properties)[i]], undefined, 4)
                            }
                            else {
                                o.value = res.data.properties[Object.keys(res.data.properties)[i]];
                            }
                            a.push(o);
                        }
                        //a.push(res.data.properties);
                        setDeviceProperty(a);
                    }
                }
                else {
                    setDeviceProperty([]);
                }
            }).catch((err) => {
                setDeviceProperty([]);
            });
        }
        else {
            // toast.error("Please select type and model!!", {
            //     position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");
        }
    }

    const updateProperty = () => {
        setUpdate(true);
        setDevicetypenew(type);
        setModelname(model);
        // setModelinfo(JSON.stringify(modelinfo, undefined, 4));
        setCreatenewrecord(false);

        // DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${networkId.network}/deviceTypes/${type}`)
        //     .then((response) => {
        //         if (response && response.data && response.data.metadata)
        //             setModelinfo(response.data.metadata);
        //     }).catch((err) => {
        //         toast.error("Some error occured", {
        //             position: toast.POSITION.TOP_RIGHT
        //         });
        //     });

    }

    const firmwareUpdate = () => {
        console.log(type, model);
        alert(type, model)
        // DeviceMetadataAxiosInstance.get(`/admin/apiToBeGiven`)
        // .then((response) => {

        //     toast.success("Model info deleted successfully", {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        //     let e = {};
        //     e.target = {};
        //     e.target.value = type;
        //     selectDevice(e);
        //     setDeviceProperty([]);
        // }).catch((err) => {
        //     toast.error("Some error occured", {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        // });
    }

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            const selectedElementObject = JSON.parse(modelinfo).properties.filter((element) => element.property === value);
            setSelectedModelProperty([...selectedModelProperty, selectedElementObject[0]])
            setSelectedProperties([...selectedProperties, value]);
        } else {
            const deselectedElementObject = JSON.parse(modelinfo).properties.filter((element) => element.property === value);
            const newArray = selectedModelProperty.filter((item) => item.property !== value);
            setSelectedModelProperty(newArray)
            setSelectedProperties(selectedProperties.filter((item) => item !== value));
        }
    };

    const handleRadioChange = (event) => {
        setSelectedUpdateProperty(event.target.value);


        const filteredObjects = JSON.parse(modelinfo).properties?.filter((item) => item.property === event.target.value);
        if (filteredObjects?.[0].Desired?.[0]) {
            setDesireEnable(true)
        } else {
            setDesireEnable(false)
        }
        // console.log(filteredObjects[0].Desired[0],"filterobject")
        setMetadata({
            mandatory: filteredObjects[0].mandatory,
            editable: filteredObjects[0].editable,
            property: filteredObjects[0].property,
            valueType: filteredObjects[0].valueType,
            Desired: filteredObjects[0].Desired,
            unit: filteredObjects[0].unit
        });
        setUpdatePropertyList(filteredObjects)
        setDesired(filteredObjects[0].Desired?.join(','))
    };



    return (
        <div className="animated fadeIn" >
            <i className="fa fa-crosshairs"></i> <strong className="LabelHeader">Model Details</strong>
            <div className="alignright">
                <Button type="button" className="custom-button" onClick={createnew.bind(this)} size="sm" color="primary"><i className="fa fa-plus"></i>&nbsp;&nbsp; Create New Model info</Button>
            </div>
            <Row>
                <Col xs="12" sm="12">

                    <Col xs="12" md="12">
                        <Grid container spacing={2}>
                            <Grid item md={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Device Type</InputLabel>
                                    <Select label="Device Type" value={type} onChange={selectDevice.bind(this)}>
                                        <MenuItem disabled value="">Select Device Type</MenuItem>
                                        {deviceType.map((d, i) => (
                                            <MenuItem value={d} key={i + 1}>
                                                {d}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item md={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Device Model</InputLabel>
                                    <Select label="Device Model" value={model} onChange={selectModel.bind(this)}>
                                        <MenuItem disabled value="">Select Device Model</MenuItem>
                                        {modelList.map((d, i) => (
                                            <MenuItem value={d} key={i + 1}>
                                                {d}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item md={2} style={{ marginTop: "10px" }}>
                                <Button className="custom-button" onClick={getProperty.bind(this)}>
                                    Get Model Info
                                </Button>
                            </Grid>

                            {/* <Grid item md={2} style={{ marginTop: "10px" }}>
                                <Button className="custom-button" onClick={updateProperty.bind(this)} disabled={!deviceProperty.length > 0}>
                                    Update Model Info
                                </Button>
                            </Grid> */}

                            <Grid item md={2} style={{ marginTop: "10px" }}>
                                <Button className="custom-button" onClick={deleteProperty.bind(this)} disabled={!deviceProperty.length > 0}>
                                    Delete Model Info
                                </Button>
                            </Grid>
                        </Grid>
                    </Col>
                    <TableContainer component={Paper}>
                        {deviceProperty && deviceProperty.length > 0 && (
                            <Table size="medium">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Property Name</TableCell>
                                        <TableCell>Property Type</TableCell>
                                        <TableCell>Property Value</TableCell>
                                        <TableCell>Mandatory</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {deviceProperty.map((dev, ind) => (
                                        <TableRow key={ind + 1}>
                                            <TableCell>{dev.property}</TableCell>
                                            <TableCell>{dev['value type'] ? dev['value type'] : dev['valueType']}</TableCell>
                                            <TableCell>
                                                <Typography variant="body2" noWrap>
                                                    {dev['value']}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {dev.mandatory ? (
                                                    <CheckCircleOutline color="success" fontSize="large" />
                                                ) : (
                                                    <Cancel color="error" fontSize="large" />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                </Col>
            </Row>

            {/* <Modal isOpen={primary} toggle={togglePrimary}
                className={'modal-lg ' + props.className}>
                <ModalHeader toggle={togglePrimary}>{createnewrecord ? 'Add New Model Info' : 'Update Model Info'}</ModalHeader>
                <ModalBody>
                    <div id="accordion">
                        <Label className="help-block" id="errorstyle">{errors}</Label>
                        <Card className="mb-0">
                            <CardHeader id="headingOne">
                                <Button block color="link" className="text-left m-0 p-0" onClick={() => toggleAccordion(0)} aria-expanded={accordion[0]} aria-controls="collapseOne">
                                    <h5 className="m-0 p-0">Model info Details <strong className="redfont">*</strong></h5>
                                </Button>
                            </CardHeader>
                            <Collapse isOpen={accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                                <CardBody>
                                    <Form>
                                        <FormGroup row>
                                            <Col md="6" xs="12">
                                                <Label htmlFor="devicetype">Device Type</Label>
                                                <Input disabled={!createnewrecord} type="select" name="devicetype" value={devicetypenew}
                                                    onChange={selectDeviceNew.bind(this)}>
                                                    <option value="">Select Device Type</option>
                                                    {
                                                        deviceType.map((d, i) => {
                                                            return <option value={d} key={i + 1}>{d}</option>
                                                        })
                                                    }
                                                </Input>
                                            </Col>
                                            <Col xs="12" md="6">
                                                <Label htmlFor="modelname">Model Name</Label>
                                                <Input disabled={!createnewrecord} type="text" name="modelname" maxLength={20} value={modelname} onChange={modelName} placeholder="Enter Model Name" required />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Col md="12" xs="12">
                                                <Label htmlFor="modelinfo">Model Info</Label>
                                         
                                                {modelinfo && console.log(selectedModelProperty)}
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {modelinfo &&
                                                        JSON.parse(modelinfo).properties.map((propertyObj, index) => (
                                                            <div key={propertyObj.property} style={{ width: '50%', boxSizing: 'border-box' }}>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={propertyObj.property}
                                                                        checked={selectedProperties.includes(propertyObj.property)}
                                                                        onChange={handleCheckboxChange}
                                                                    />
                                                                    {" " + propertyObj.property}
                                                                </label>
                                                            </div>
                                                        ))}
                                                </div>
                                            </Col>

                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Collapse>
                        </Card>


                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={disabledstate} onClick={createmodelinfo}>{createnewrecord ? 'Create' : 'Update'}</Button>
                    <Button color="primary" onClick={togglePrimary}>Cancel</Button>
                </ModalFooter>
            </Modal> */}
            <Dialog open={primary} onClose={togglePrimary} maxWidth="lg">
                <DialogTitle>{createnewrecord ? 'Add New Model Info' : 'Update Model Info'}</DialogTitle>
                <DialogContent>
                    <div id="accordion" style={{ width: "500px" }}>
                        <p className="help-block" id="errorstyle">{errors}</p>
                        <div className="mb-0">
                            <div id="headingOne">
                                <h5 className="m-0 p-0">Model info Details <strong className="redfont">*</strong></h5>
                            </div>
                            <div id="collapseOne" aria-labelledby="headingOne">
                                <div>
                                    <FormGroup row fullWidth>
                                        {/* <Select value={deviceType} onChange={selectDevice.bind(this)}>
                                        <MenuItem value="">Select Device Type</MenuItem>
                                        {deviceType.map((d, i) => (
                                            <MenuItem value={d} key={i + 1}>
                                                {d}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    
                                        <Select
                                            value={devicetypenew}
                                            onChange={selectDeviceNew}
                                            name="devicetype"
                                            fullWidth
                                        >
                                            <MenuItem disabled value="">Select Device Type</MenuItem>
                                            {deviceType.map((d, i) => (
                                                <MenuItem value={d} key={i + 1}>
                                                    {d}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                    <FormGroup style={{marginTop:"10px"}}>
                                        <TextField
                                            label="Model Name"
                                            type="text"
                                            name="modelname"
                                            maxLength={20}
                                            value={modelname}
                                            onChange={modelName}
                                            placeholder="Enter Model Name"
                                            required
                                            fullWidth
                                        />
                                    </FormGroup>
                                    <FormGroup row fullWidth>
                                        <Col md="12" xs="12">
                                            <Label htmlFor="modelinfo">Model Info</Label>

                                            {modelinfo && console.log(selectedModelProperty)}
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {modelinfo &&
                                                    JSON.parse(modelinfo).properties?.map((propertyObj, index) => (
                                                        <div key={propertyObj.property} style={{ width: '50%', boxSizing: 'border-box' }}>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    value={propertyObj.property}
                                                                    checked={selectedProperties.includes(propertyObj.property)}
                                                                    onChange={handleCheckboxChange}
                                                                />
                                                                {" " + propertyObj.property}
                                                            </label>
                                                        </div>
                                                    ))}
                                            </div>
                                        </Col>

                                    </FormGroup>

                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className="custom-button" disabled={disabledstate} onClick={createmodelinfo}>
                        {createnewrecord ? 'Create' : 'Update'}
                    </Button>
                    <Button className="custom-button-cancel" onClick={togglePrimary}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <Modal isOpen={update} toggle={toggleUpdate}
                className={'modal-lg ' + props.className}>
                <ModalHeader toggle={toggleUpdate}>{createnewrecord ? 'Add New Model Info' : 'Update Model Info'}</ModalHeader>
                <ModalBody>
                    <div id="accordion">
                        <Label className="help-block" id="errorstyle">{errors}</Label>
                        <Card className="mb-0">
                            <CardHeader id="headingOne">
                                <Button block color="link" className="text-left m-0 p-0" onClick={() => toggleAccordion(0)} aria-expanded={accordion[0]} aria-controls="collapseOne">
                                    <h5 className="m-0 p-0">Model info Details <strong className="redfont">*</strong></h5>
                                </Button>
                            </CardHeader>
                            <Collapse isOpen={accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                                <CardBody>
                                    <Form>
                                        <FormGroup row>
                                            <Col md="6" xs="12">
                                                <Label htmlFor="devicetype">Device Type</Label>
                                                <Input disabled={!createnewrecord} type="select" name="devicetype" value={devicetypenew}
                                                    onChange={selectDeviceNew.bind(this)}>
                                                    <option value="">Select Device Type</option>
                                                    {
                                                        deviceType.map((d, i) => {
                                                            return <option value={d} key={i + 1}>{d}</option>
                                                        })
                                                    }
                                                </Input>
                                            </Col>
                                            <Col xs="12" md="6">
                                                <Label htmlFor="modelname">Model Name</Label>
                                                <Input disabled={!createnewrecord} type="text" name="modelname" maxLength={20} value={modelname} onChange={modelName} placeholder="Enter Model Name" required />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Col md="4" xs="12">
                                                <Label htmlFor="modelinfo">Model Info</Label>
                                  
                                                {modelinfo && console.log(JSON.parse(modelinfo).properties, 'llllll')}
                                                {modelinfo && JSON.parse(modelinfo).properties.map((propertyObj) => (
                                                    <div key={propertyObj.property}>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="propertyRadio"
                                                                value={propertyObj.property}
                                                                checked={selectedUpdateProperty === propertyObj.property}
                                                                onChange={handleRadioChange}
                                                            />
                                                            {" " + propertyObj.property}
                                                        </label>
                                                    </div>
                                                ))}
                                            </Col>
                                            <Col md="8" xs="12">
                                                {updateModelPropertyList[0] ? <h5>Updated Properties</h5> : <h5>Change Properties</h5>}
                                                {updateModelPropertyList && updateModelPropertyList.map((item) => {
                                                    return <Badge color="primary" style={{ width: '60px', marginLeft: "10px" }}>{item}</Badge>
                                                })}
                                                <Form onSubmit={handleEditModelProperty}>
                                                    <FormGroup>
                                                        <Label for="mandatory">Mandatory</Label>
                                                        <Input
                                                            type="select"
                                                            name="mandatory"
                                                            id="mandatory"
                                                            value={metadata.mandatory}
                                                            onChange={handleInputChangeEdit}
                                                        >
                                                            <option value={false}>False</option>
                                                            <option value={true}>True</option>
                                                        </Input>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="editable">Editable</Label>
                                                        <Input
                                                            type="select"
                                                            name="editable"
                                                            id="editable"
                                                            value={metadata.editable}
                                                            onChange={handleInputChangeEdit}
                                                        >
                                                            <option value={true}>True</option>
                                                            <option value={false}>False</option>
                                                            <option value={2}>AutoComplete</option>
                                                        </Input>
                                                    </FormGroup>
                                                    {desireEnable && (
                                                        <FormGroup>
                                                            <Label for="Desired">Desired Values</Label>
                                                            <Input

                                                                type="text"
                                                                name="Desired"
                                                                id="Desired"
                                                                placeholder="Enter Desired Values"
                                                                value={desired}
                                                                onChange={(e) => {
                                                                    handleInputChangeEdit(e);
                                                                    setDesired(e.target.value)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    )}
                                                    <FormGroup>
                                                        <Label for="property">Property</Label>
                                                        <Input
                                                            required
                                                            disabled
                                                            type="text"
                                                            name="property"
                                                            id="property"
                                                            placeholder="Enter property"
                                                            value={metadata.property}
                                                            onChange={handleInputChangeEdit}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="valueType">Value Type</Label>
                                                        <Input
                                                            type="text"
                                                            name="valueType"
                                                            id="valueType"
                                                            placeholder="Enter value type"
                                                            value={metadata.valueType}
                                                            onChange={handleInputChangeEdit}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="unit">Unit</Label>
                                                        <Input
                                                            type="text"
                                                            name="unit"
                                                            id="unit"
                                                            placeholder="Enter unit"
                                                            value={metadata.unit}
                                                            onChange={handleInputChangeEdit}
                                                        />
                                                    </FormGroup>
                                                    <Button color="primary" type="submit">
                                                        Update Property
                                                    </Button>
                                                </Form>

                                            </Col>

                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Collapse>
                        </Card>


                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={disabledstate} onClick={updatemodelinfo}>{createnewrecord ? 'Create' : 'Update'}</Button>
                    <Button color="primary" onClick={toggleUpdate}>Cancel</Button>
                </ModalFooter>
            </Modal> */}
            <Dialog open={update} onClose={toggleUpdate} maxWidth="lg" fullWidth>
                <DialogTitle>{createnewrecord ? 'Add New Model Info' : 'Update Model Info'}</DialogTitle>
                <DialogContent>
                        <InputLabel>Device Type</InputLabel>
                        <Select
                            label="Device Type"
                            disabled={!createnewrecord}
                            value={devicetypenew}
                            onChange={selectDeviceNew}
                            name="devicetype"
                        >
                            <MenuItem disable value="">Select Device Type</MenuItem>
                            {deviceType.map((d, i) => (
                                <MenuItem value={d} key={i + 1}>
                                    {d}
                                </MenuItem>
                            ))}
                        </Select>

                    <TextField
                        fullWidth
                        label="Model Name"
                        type="text"
                        disabled={!createnewrecord}
                        name="modelname"
                        maxLength={20}
                        value={modelname}
                        onChange={modelName}
                        placeholder="Enter Model Name"
                        required
                    />

                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Model Info</FormLabel>
                        {modelinfo &&
                            JSON.parse(modelinfo).properties?.map((propertyObj) => (
                                <FormControlLabel
                                    key={propertyObj.property}
                                    control={
                                        <Radio
                                            value={propertyObj.property}
                                            checked={selectedUpdateProperty === propertyObj.property}
                                            onChange={handleRadioChange}
                                        />
                                    }
                                    label={propertyObj.property}
                                />
                            ))}
                    </FormControl>

                    <FormControl fullWidth>
                        {updateModelPropertyList[0] ? <Typography variant="h5">Updated Properties</Typography> : <Typography variant="h5">Change Properties</Typography>}
                        {updateModelPropertyList.map((item) => (
                            <Badge color="primary" sx={{ width: 60, marginLeft: 10 }}>
                                {item}
                            </Badge>
                        ))}
                        <Form>
                            <FormControl>
                                <InputLabel htmlFor="mandatory">Mandatory</InputLabel>
                                <Select
                                    name="mandatory"
                                    id="mandatory"
                                    value={metadata.mandatory}
                                    onChange={handleInputChangeEdit}
                                >
                                    <MenuItem value={false}>False</MenuItem>
                                    <MenuItem value={true}>True</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel htmlFor="editable">Editable</InputLabel>
                                <Select
                                    name="editable"
                                    id="editable"
                                    value={metadata.editable}
                                    onChange={handleInputChangeEdit}
                                >
                                    <MenuItem value={true}>True</MenuItem>
                                    <MenuItem value={false}>False</MenuItem>
                                    <MenuItem value={2}>AutoComplete</MenuItem>
                                </Select>
                            </FormControl>
                            {desireEnable && (
                                <TextField
                                    type="text"
                                    name="Desired"
                                    id="Desired"
                                    placeholder="Enter Desired Values"
                                    value={desired}
                                    onChange={(e) => {
                                        handleInputChangeEdit(e);
                                        setDesired(e.target.value);
                                    }}
                                />
                            )}
                            <TextField
                                required
                                disabled
                                type="text"
                                name="property"
                                id="property"
                                placeholder="Enter property"
                                value={metadata.property}
                                onChange={handleInputChangeEdit}
                            />
                            <TextField
                                type="text"
                                name="valueType"
                                id="valueType"
                                placeholder="Enter value type"
                                value={metadata.valueType}
                                onChange={handleInputChangeEdit}
                            />
                            <TextField
                                type="text"
                                name="unit"
                                id="unit"
                                placeholder="Enter unit"
                                value={metadata.unit}
                                onChange={handleInputChangeEdit}
                            />
                            <Button color="primary" type="submit">
                                {createnewrecord ? 'Create' : 'Update'}
                            </Button>
                        </Form>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={disabledstate} onClick={updatemodelinfo}>
                        {createnewrecord ? 'Create' : 'Update'}
                    </Button>
                    <Button color="primary" onClick={toggleUpdate}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>


            {/* <Modal isOpen={deleteconfirm} toggle={toggledeleteconfirm}
                className={'modal-danger ' + props.className}>
                <ModalHeader toggle={toggledeleteconfirm}>Delete Model info</ModalHeader>
                <ModalBody>
                    <strong>Are you sure, You want to delete this Model info ?</strong>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={onclickdelete.bind(this)}>Delete</Button>{' '}
                    <Button color="secondary" onClick={toggledeleteconfirm}>Cancel</Button>
                </ModalFooter>
            </Modal> */}

            <Dialog open={deleteconfirm} onClose={toggledeleteconfirm}>
                <DialogTitle>Delete Device Type</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        <strong>Are you sure you want to delete this Device Type?</strong>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onclickdelete.bind(this)} className="custom-button" style={{ backgroundColor: "red" }}>
                        Delete
                    </Button>
                    <Button onClick={toggledeleteconfirm} className="custom-button-cancel">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>


        </div>
    )
}



export default ModelConfigure;