import React from "react";
import '../MyOrders/MyOrderStyleComponent.css';
import GlobalNetworkComponent from "./GlobalNetworkComponent.js";

export default function CBOGlobalNetwork(props) {
    const UserRole = localStorage.getItem("UserRole");

    let allowedUserTypes = [];
    switch (UserRole) {
        case "BASIC":
            allowedUserTypes = ["Buyer", "AssetDeveloper"];
            break;
        case "STANDARD":allowedUserTypes = ["Buyer", "AssetDeveloper"];break;
        case "PREFERRED":allowedUserTypes = ["Buyer", "AssetDeveloper","AgriAssetDistributor", "GovtDept","FOOD-PROCESSOR","AHSP","AIC"];break;
        case "PREMIUM":allowedUserTypes =  ["Buyer", "AssetDeveloper","AgriAssetDistributor", "GovtDept","FOOD-PROCESSOR","AHSP","AIC"];break;
        case "ADMIN":
            allowedUserTypes = ["Buyer", "AssetDeveloper","AgriAssetDistributor", "GovtDept","FOOD-PROCESSOR","AHSP","AIC","StorageCompany", "LogisticsProvider"];
            break;
        default:
            console.error("Unexpected UserRole:", UserRole);
            // Fallback behavior: Set default userTypes or handle the error
            allowedUserTypes = ["Buyer"]; // Example default behavior
            break;
    }

    return (
        <div>
            <GlobalNetworkComponent userTypes={allowedUserTypes} />
        </div>
    );
}
