import React from "react";
import '../MyOrders/MyOrderStyleComponent.css';
import GlobalNetworkComponent from "./GlobalNetworkComponent.js";

export default function ADGlobalNetwork(props) {
    const UserRole = localStorage.getItem("UserRole");

    let allowedUserTypes = [];
    switch (UserRole) {
        case "BASIC":
            allowedUserTypes = ["Buyer"];
            break;
        case "STANDARD":allowedUserTypes = ["Buyer","GovtDept","MerchantExporter","MSME-ADVISOR","OFC","CBO","FMCDealer","PMCDealer"];break;
        case "PREFERRED":allowedUserTypes = ["Buyer","GovtDept","MerchantExporter","MSME-ADVISOR","OFC","CBO","FMCDealer","PMCDealer","LogisticsProvider","AssetOEM","AgriMiner","AssetCertifiedConsultant","FMC","PMC","AHSP","FOOD-PROCESSOR"];break;
        case "PREMIUM":allowedUserTypes =   ["Buyer","GovtDept","MerchantExporter","MSME-ADVISOR","OFC","CBO","FMCDealer","PMCDealer","LogisticsProvider","AssetOEM","AgriMiner","AssetCertifiedConsultant","FMC","PMC","AHSP","FOOD-PROCESSOR","BiogasEquipCompany","Agent","AIR","PHP","ClinicAdmin","AIC"];break;
        case "ADMIN":
            allowedUserTypes =  ["Buyer","GovtDept","MerchantExporter","MSME-ADVISOR","OFC","CBO","FMCDealer","PMCDealer","LogisticsProvider","AssetOEM","AgriMiner","AssetCertifiedConsultant","FMC","PMC","AHSP","FOOD-PROCESSOR","BiogasEquipCompany","Agent","AIR","PHP","ClinicAdmin","AIC","DeviceMaker"];break;
            break;
        default:
            console.error("Unexpected UserRole:", UserRole);
            // Fallback behavior: Set default userTypes or handle the error
            allowedUserTypes = ["Buyer"]; // Example default behavior
            break;
    }

    return (
        <div>
            <GlobalNetworkComponent userTypes={allowedUserTypes} />
        </div>
    );
}
