import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, Button, Typography } from "@mui/material";
import countryList from "country-list";
import React, { Fragment, memo, useEffect, useReducer, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../../../Common/MainService";
import {
  renderDropdown,
  renderSingleSelect,
  renderTextField,
} from "../../OrderCommon";
import {
  assetbuyrfqreq,
  whitelabelrequest,
} from "./StateMaintananceOfAssetOrder";

const commonTypography = {
  sx: {
    color: "#1976d2",
    textAlign: "left",
    fontSize: "1rem",
    fontWeight: "bold",
    margin: "5px",
  },
};

const border = {
  sx: {
    boxSizing: "border-box",
    bgcolor: " #f0f0f0",
    padding: "5px" /* Adequate padding */,
    border: "1.5px solid #3ECF8E",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
};

const filedResponsive = {
  sx: {
    display: "flex",
    boxSizing: "border-box",
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
      lg: "row",
      xl: "row",
    },
  },
};

// function formReducer(state, action) {
//   return { ...state, [action.field]: action.value };
// }

function formReducer(state, action) {
  switch (action.type) {
    case "EMPTY_STATE":
      return {};
    case "UPDATE_STATE":
      return { ...state, ...action.payload };
    default:
      return { ...state, [action.field]: action.value };
  }
}

const FormForAssetBuyandWhiteLabelOrderRequest = ({
  reqTypes,
  verticalsIds,
  categoryTypes,
  assertTypes,
  models,
  reLoad,
}) => {
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getUserDetails, setUserDetails] = useState({});
  const [state, dispatch] = useReducer(formReducer, {});
  const [getCountryList, setCountryList] = useState([]);
  const gender = [{ type: "Male" }, { type: "Female" }];
  const grade = [
    { type: "A" },
    { type: "B" },
    { type: "C" },
    { type: "D" },
    { type: "F" },
  ];
  const modeOfPayment = [{ type: "online" }, { type: "offline" }];
  const packageTypes = [
    { type: "NULL" },
    { type: "BASIC" },
    { type: "STANDARD" },
    { type: "PREFFERED" },
    { type: "PREMIUM" },
    { type: "ADMIN" },
  ];
  const availableornot = [{ type: "true" }, { type: "false" }];
  const getAppConfig = () => window.AppConfigData;

  const countries = countryList.getData();
  const countryOptions = countries?.map((country) => ({
    value: country?.code,
    label: country?.name,
  }));

  useEffect(() => {
    dispatch({ type: "EMPTY_STATE" });
    const initializeFormState = () => {
      if (reqTypes === "asset-buy-order-req") {
        dispatch({ type: "UPDATE_STATE", payload: assetbuyrfqreq });
      } else if (reqTypes === "whitelabel-asset-request") {
        dispatch({
          type: "UPDATE_STATE",
          payload: whitelabelrequest,
        });
      }
    };
    initializeFormState();
  }, [reqTypes]);

  useEffect(() => {
    MainService.getConsumerUserInfo(parentId, username)
      .then((data) => {
        setUserDetails(data[0]);
      })
      .catch((e) => {
        return NotificationManager.error("No profile data found");
      });
    dispatch({
      field: "requestType",
      value: reqTypes,
    });
  }, [reqTypes]);

  useEffect(() => {
    const defaultPersonalDetails = {
      networkId: getAppConfig().EnterpriseId,
      parentId: getUserDetails?.enterpriseid,
      username: getUserDetails?.username,
      name: getUserDetails?.username,
      phoneNo: getUserDetails?.phone,
      emailId: getUserDetails?.email,
      city: getUserDetails?.city,
      state: getUserDetails?.state,
      panNumber: getUserDetails?.pancard,
      latitude: getUserDetails?.latitude,
      longitude: getUserDetails?.longitude,
    };
    dispatch({
      field: "personalDetails",
      value: { ...state.personalDetails, ...defaultPersonalDetails },
    });
  }, [getUserDetails]);

  const handleDeleteAsset = (index) => {
    const updatedAssetDetails = [...state.assetDetails];
    updatedAssetDetails.splice(index, 1); // Remove the asset at the specified index
    dispatch({
      field: "assetDetails",
      value: updatedAssetDetails,
    });
  };

  const handleAddAsset = () => {
    const newAsset = {
      assetVerticalId: "",
      assetVerticalFriendlyName: "",
      category: "",
      legacyAssetType: "",
      modelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      description: "",
      grade: "",
      origin: "",
      quantity: "",
      unit: "",
      price: "",
      discount: "",
      totalDiscountablePrice: Number(),
    };

    // Create a copy of the asset details array
    const updatedAssetDetails = Array.isArray(state.assetDetails)
      ? [...state.assetDetails]
      : [];
    // Push the new asset into the copied array
    updatedAssetDetails.push(newAsset);
    // Dispatch the updated array with the default asset details appended
    dispatch({
      field: "assetDetails",
      value: updatedAssetDetails.map((asset, index) => {
        // If it's the last item in the array, add default asset details
        if (index === updatedAssetDetails.length - 1) {
          return {
            ...asset,
          };
        }
        return asset;
      }),
    });
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    if (name.includes("personalDetails.")) {
      const [parentNames, nestedNames] = name.split(".");
      dispatch({
        field: "personalDetails",
        value: { ...state[parentNames], [nestedNames]: value },
      });
    } else if (name.includes("companyAdditionalInformation.")) {
      const [parentNamesAddInfo, nestedNamesAddInfo] = name.split(".");
      dispatch({
        field: "companyAdditionalInformation",
        value: { ...state[parentNamesAddInfo], [nestedNamesAddInfo]: value },
      });
    } else if (name.includes("deliveryAddress.")) {
      const [parentNamesDel, nestedNamesDel] = name.split(".");
      setCountryList(value);
      let selectedLabels;
      if (String(nestedNamesDel) === "country") {
        selectedLabels = value?.label;
      } else {
        selectedLabels = value;
      }
      dispatch({
        field: "deliveryAddress",
        value: { ...state[parentNamesDel], [nestedNamesDel]: selectedLabels },
      });
    } else if (name.includes("supplierDetails.")) {
      const [parentNamesSup, nestedNamesSup] = name.split(".");
      dispatch({
        field: "supplierDetails",
        value: { ...state[parentNamesSup], [nestedNamesSup]: value },
      });
    } else if (name.includes("pickUpAddress.")) {
      const [parentNamesPicup, nestedNamesPicup] = name.split(".");
      setCountryList(value);
      let selectedLabels;
      if (String(nestedNamesPicup) === "country") {
        selectedLabels = value?.label;
      } else {
        selectedLabels = value;
      }
      dispatch({
        field: "pickUpAddress",
        value: {
          ...state[parentNamesPicup],
          [nestedNamesPicup]: selectedLabels,
        },
      });
    } else if (name.includes("siteAddress.")) {
      const [parentNamesPicup, nestedNamesPicup] = name.split(".");
      setCountryList(value);
      let selectedLabels;
      if (String(nestedNamesPicup) === "country") {
        selectedLabels = value?.label;
      } else {
        selectedLabels = value;
      }
      dispatch({
        field: "siteAddress",
        value: {
          ...state[parentNamesPicup],
          [nestedNamesPicup]: selectedLabels,
        },
      });
    } else if (name.includes(`assetDetails[${index}].`)) {
      const updatedAssetDetails = Array.isArray(state.assetDetails)
        ? [...state.assetDetails]
        : [];
      // Split the name to get the property and nested property
      const [propertyName, nestedProperty] = name.split(".");
      setCountryList(value);
      let selectedLabels;
      if (String(nestedProperty) === "origin") {
        selectedLabels = value?.label;
      } else {
        selectedLabels = value;
      }
      // Check if nested property exists
      if (nestedProperty) {
        // If nested property exists, update it in the respective object
        updatedAssetDetails[index] = {
          ...updatedAssetDetails[index],
          [nestedProperty]: selectedLabels,
        };
      } else {
        // If no nested property, update the property directly
        updatedAssetDetails[index][propertyName] = selectedLabels;
      }
      // Dispatch updated product details to state
      dispatch({
        field: "assetDetails",
        value: updatedAssetDetails,
      });
    } else if (name.includes("requirements.")) {
      const [parentNamesReq, nestedNamesReq] = name.split(".");
      dispatch({
        field: "requirements",
        value: { ...state[parentNamesReq], [nestedNamesReq]: value },
      });
    } else {
      dispatch({
        field: name,
        value: value,
      });
    }
  };

  const handleSubmit = () => {
    MainService.orderManagement(reqTypes, state, parentId, username)
      .then((res) => {
        return NotificationManager.success(res?.status);
      })
      .catch((err) => {
        return NotificationManager.error(
          err?.message || "something went wrong"
        );
      })
      .finally(() => {
        reLoad();
      });
  };

  return (
    <Fragment>
      <Box>
        {(() => {
          if (reqTypes !== "whitelabel-asset-request") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Generated Id's
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderTextField("Rfq Id", "rfqId", state, handleChange)}
                    {renderTextField(
                      "Proforma Invoice Id",
                      "proformaInvoiceId",
                      state,
                      handleChange
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}
        <Typography variant="h5" {...commonTypography}>
          Personal Details
        </Typography>
        <Box {...border}>
          <Box {...filedResponsive}>
            {renderTextField(
              "Network Id",
              "personalDetails.networkId",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Parent Id",
              "personalDetails.parentId",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Username",
              "personalDetails.username",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Site Name",
              "personalDetails.siteName",
              state,
              handleChange
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField("No", "personalDetails.no", state, handleChange)}
            {renderTextField(
              "Username",
              "personalDetails.name",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Phone",
              "personalDetails.phoneNo",
              state,
              handleChange,
              "number",
              "text",
              true
            )}
            {renderTextField(
              "Email",
              "personalDetails.emailId",
              state,
              handleChange,
              "email",
              "text",
              true
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField(
              "Street",
              "personalDetails.street",
              state,
              handleChange
            )}
            {renderTextField(
              "City",
              "personalDetails.city",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "State",
              "personalDetails.state",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Country",
              "personalDetails.country",
              state,
              handleChange
            )}
            {renderTextField(
              "Pincode",
              "personalDetails.pincode",
              state,
              handleChange,
              "number"
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField(
              "Landmark",
              "personalDetails.landmark",
              state,
              handleChange
            )}
            {renderTextField(
              "PAN Number",
              "personalDetails.panNumber",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Latitude",
              "personalDetails.latitude",
              state,
              handleChange
            )}
            {renderTextField(
              "Longitude",
              "personalDetails.longitude",
              state,
              handleChange
            )}
          </Box>
        </Box>
        <Typography variant="h5" {...commonTypography}>
          Company Additional Information
        </Typography>
        <Box {...border}>
          <Box {...filedResponsive}>
            {renderTextField(
              "Company Name",
              "companyAdditionalInformation.companyName",
              state,
              handleChange
            )}
            {renderTextField(
              "Business Type",
              "companyAdditionalInformation.businessType",
              state,
              handleChange
            )}
            {renderTextField(
              "Country",
              "companyAdditionalInformation.country",
              state,
              handleChange
            )}
            {renderTextField(
              "Year Established",
              "companyAdditionalInformation.yearEstablished",
              state,
              handleChange,
              "date"
            )}
            {renderTextField(
              "Number Of Employees",
              "companyAdditionalInformation.numberOfEmployees",
              state,
              handleChange
            )}
            {renderTextField(
              "Annual Sales Revenue",
              "companyAdditionalInformation.annualSalesRevenue",
              state,
              handleChange
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField(
              "Company Webite",
              "companyAdditionalInformation.companyWebite",
              state,
              handleChange
            )}
            {renderDropdown(
              "MyCompany Has No Webite",
              "companyAdditionalInformation.myCompanyHasNoWebite",
              state,
              handleChange,
              availableornot,
              "type",
              "type"
            )}
            {renderTextField(
              "Pan Number",
              "companyAdditionalInformation.panNumber",
              state,
              handleChange
            )}
            {renderTextField(
              "GST Number",
              "companyAdditionalInformation.gstin_Number",
              state,
              handleChange
            )}
            {renderTextField(
              "Company Description",
              "companyAdditionalInformation.companyDescription",
              state,
              handleChange
            )}
          </Box>
        </Box>

        {(() => {
          if (reqTypes !== "whitelabel-asset-request") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Delivery Details
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "Site ID",
                      "deliveryAddress.siteId",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Site Name",
                      "deliveryAddress.siteName",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Street",
                      "deliveryAddress.street",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "City",
                      "deliveryAddress.city",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "State",
                      "deliveryAddress.state",
                      state,
                      handleChange
                    )}
                  </Box>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "Pincode",
                      "deliveryAddress.pincode",
                      state,
                      handleChange,
                      "number"
                    )}
                    {renderSingleSelect(
                      "Country",
                      "deliveryAddress.country",
                      getCountryList,
                      state,
                      handleChange,
                      countryOptions,
                      "label",
                      "label"
                    )}
                    {renderTextField(
                      "Latitude",
                      "deliveryAddress.latitude",
                      state,
                      handleChange,
                      "number"
                    )}
                    {renderTextField(
                      "Longitude",
                      "deliveryAddress.longitude",
                      state,
                      handleChange,
                      "number"
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}
        {(() => {
          if (reqTypes !== "whitelabel-asset-request") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Pickup Address
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "Site ID",
                      "pickUpAddress.siteId",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Site Name",
                      "pickUpAddress.siteName",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Street",
                      "pickUpAddress.street",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "City",
                      "pickUpAddress.city",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "State",
                      "pickUpAddress.state",
                      state,
                      handleChange
                    )}
                  </Box>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "Pincode",
                      "pickUpAddress.pincode",
                      state,
                      handleChange,
                      "number"
                    )}
                    {renderSingleSelect(
                      "Country",
                      "pickUpAddress.country",
                      getCountryList,
                      state,
                      handleChange,
                      countryOptions,
                      "label",
                      "label"
                    )}
                    {renderTextField(
                      "Latitude",
                      "pickUpAddress.latitude",
                      state,
                      handleChange,
                      "number"
                    )}
                    {renderTextField(
                      "Longitude",
                      "pickUpAddress.longitude",
                      state,
                      handleChange,
                      "number"
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}

        {(() => {
          if (reqTypes === "whitelabel-asset-request") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Site Address
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "Site ID",
                      "siteAddress.siteId",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Site Name",
                      "siteAddress.siteName",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Street",
                      "siteAddress.street",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "City",
                      "siteAddress.city",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "State",
                      "siteAddress.state",
                      state,
                      handleChange
                    )}
                  </Box>
                  <Box {...filedResponsive}>
                    {renderTextField(
                      "Pincode",
                      "siteAddress.pincode",
                      state,
                      handleChange,
                      "number"
                    )}
                    {renderSingleSelect(
                      "Country",
                      "siteAddress.country",
                      getCountryList,
                      state,
                      handleChange,
                      countryOptions,
                      "label",
                      "label"
                    )}
                    {renderTextField(
                      "Latitude",
                      "siteAddress.latitude",
                      state,
                      handleChange,
                      "number"
                    )}
                    {renderTextField(
                      "Longitude",
                      "siteAddress.longitude",
                      state,
                      handleChange,
                      "number"
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}

        <Typography variant="h5" {...commonTypography}>
          Supplier Details
        </Typography>
        <Box {...border}>
          <Box {...filedResponsive}>
            {renderTextField(
              "Supplier ID",
              "supplierDetails.supplierId",
              state,
              handleChange
            )}
            {renderTextField(
              "Site Id",
              "supplierDetails.siteId",
              state,
              handleChange
            )}
            {renderTextField(
              "Site Name",
              "supplierDetails.siteName",
              state,
              handleChange
            )}
            {renderTextField(
              "Street",
              "supplierDetails.street",
              state,
              handleChange
            )}
            {renderTextField(
              "City",
              "supplierDetails.city",
              state,
              handleChange
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField(
              "State",
              "supplierDetails.state",
              state,
              handleChange
            )}
            {renderTextField(
              "Pincode",
              "supplierDetails.pincode",
              state,
              handleChange
            )}
            {renderTextField(
              "Email ID",
              "supplierDetails.emailId",
              state,
              handleChange,
              "email"
            )}
            {renderTextField(
              "Phone",
              "supplierDetails.phoneNo",
              state,
              handleChange,
              "number"
            )}
            {renderTextField(
              "Country",
              "supplierDetails.country",
              state,
              handleChange,
              "text"
            )}
          </Box>
          <Box {...filedResponsive}>
            {renderTextField(
              "Latitude",
              "supplierDetails.latitude",
              state,
              handleChange,
              "number"
            )}
            {renderTextField(
              "Longitude",
              "supplierDetails.longitude",
              state,
              handleChange,
              "number"
            )}
            {renderTextField(
              "PAN",
              "supplierDetails.panNumber",
              state,
              handleChange
            )}
            {renderTextField(
              "GST Number",
              "supplierDetails.gstin_Number",
              state,
              handleChange
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          p={0.5} // Add padding as needed
        >
          <Typography variant="h5" {...commonTypography}>
            Asset Details
          </Typography>
          <Button
            onClick={handleAddAsset}
            sx={{
              color: "primary.main",
              marginLeft: "auto",
              border: "1px solid #ccc",
            }}
          >
            <AddIcon sx={{ color: "primary.main", marginRight: 1 }} /> Add More
            Assets
          </Button>
        </Box>
        {Array.isArray(state.assetDetails) &&
          state.assetDetails.map((asset, index) => (
            <Box {...border} key={index}>
              {/* Render delete icon */}
              <IconButton onClick={() => handleDeleteAsset(index)}>
                <DeleteIcon />
              </IconButton>
              {/* Rest of the fields */}
              <Box {...filedResponsive}>
                {renderDropdown(
                  "Asset Vertical Id",
                  `assetDetails[${index}].assetVerticalId`,
                  state,
                  (event) => handleChange(event, index),
                  verticalsIds,
                  "assetVerticalId",
                  "friendlyName"
                )}
                {renderDropdown(
                  "Asset Vertical Friendly Name",
                  `assetDetails[${index}].assetVerticalFriendlyName`,
                  state,
                  (event) => handleChange(event, index),
                  verticalsIds,
                  "friendlyName",
                  "friendlyName"
                )}
                {renderDropdown(
                  "Category",
                  `assetDetails[${index}].category`,
                  state,
                  (event) => handleChange(event, index),
                  categoryTypes,
                  "categoryType",
                  "categoryType"
                )}
                {renderDropdown(
                  "Legacy Asset Type",
                  `assetDetails[${index}].legacyAssetType`,
                  state,
                  (event) => handleChange(event, index),
                  assertTypes,
                  "assetType",
                  "assetType"
                )}
              </Box>
              <Box {...filedResponsive}>
                {renderDropdown(
                  "Model Id",
                  `assetDetails[${index}].modelId`,
                  state,
                  (event) => handleChange(event, index),
                  models,
                  "modelid",
                  "modelid"
                )}
                {renderTextField(
                  "CN",
                  `assetDetails[${index}].cn`,
                  state,
                  (event) => handleChange(event, index)
                )}
                {renderTextField(
                  "Label Instructions",
                  `assetDetails[${index}].labelInstructions`,
                  state,
                  (event) => handleChange(event, index)
                )}
                {renderSingleSelect(
                  "origin",
                  `assetDetails[${index}].origin`,
                  getCountryList,
                  state,
                  (event) => handleChange(event, index),
                  countryOptions,
                  "label",
                  "label"
                )}
              </Box>
              <Box {...filedResponsive}>
                {renderTextField(
                  "Description",
                  `assetDetails[${index}].description`,
                  state,
                  (event) => handleChange(event, index)
                )}
                {renderTextField(
                  "HSN",
                  `assetDetails[${index}].hsn`,
                  state,
                  (event) => handleChange(event, index)
                )}
                {renderDropdown(
                  "Grade",
                  `assetDetails[${index}].grade`,
                  state,
                  (event) => handleChange(event, index),
                  grade,
                  "type",
                  "type"
                )}
                {renderTextField(
                  "Quantity",
                  `assetDetails[${index}].quantity`,
                  state,
                  (event) => handleChange(event, index),
                  "number"
                )}
              </Box>
              <Box {...filedResponsive}>
                {renderTextField(
                  "Unit",
                  `assetDetails[${index}].unit`,
                  state,
                  (event) => handleChange(event, index)
                )}
                {renderTextField(
                  "Price",
                  `assetDetails[${index}].price`,
                  state,
                  (event) => handleChange(event, index),
                  "number"
                )}
                {renderTextField(
                  "Discount",
                  `assetDetails[${index}].discount`,
                  state,
                  (event) => handleChange(event, index)
                )}
                {renderTextField(
                  "Total Discountable Price",
                  `assetDetails[${index}].totalDiscountablePrice`,
                  state,
                  (event) => handleChange(event, index),
                  "number"
                )}
              </Box>
            </Box>
          ))}

        {(() => {
          if (reqTypes !== "whitelabel-asset-request") {
            return (
              <>
                <Typography variant="h5" {...commonTypography}>
                  Requirements
                </Typography>
                <Box {...border}>
                  <Box {...filedResponsive}>
                    {renderDropdown(
                      "Asset Package",
                      "requirements.assetPackage",
                      state,
                      handleChange,
                      packageTypes,
                      "type",
                      "type"
                    )}
                    {renderTextField(
                      "Optional Document Link",
                      "requirements.optionalDocumentLink",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Weight",
                      "requirements.weight",
                      state,
                      handleChange
                    )}
                    {renderTextField(
                      "Age",
                      "requirements.age",
                      state,
                      handleChange
                    )}
                    {renderDropdown(
                      "Gender",
                      "requirements.gender",
                      state,
                      handleChange,
                      gender,
                      "type",
                      "type"
                    )}
                    {renderTextField(
                      "Order Type",
                      "requirements.orderType",
                      state,
                      handleChange
                    )}
                  </Box>
                </Box>
              </>
            );
          }
        })()}

        <Typography variant="h5" {...commonTypography}>
          Additional Information
        </Typography>
        <Box {...border}>
          <Box {...filedResponsive}>
            {(() => {
              if (reqTypes === "whitelabel-asset-request") {
                return (
                  <>
                    {renderDropdown(
                      "Asset Package",
                      "assetPackage",
                      state,
                      handleChange,
                      packageTypes,
                      "type",
                      "type"
                    )}
                  </>
                );
              }
            })()}
            {renderTextField(
              "Total Amount",
              "totalAmount",
              state,
              handleChange,
              "number"
            )}
            {renderDropdown(
              "Payment Mode",
              "paymentMode",
              state,
              handleChange,
              modeOfPayment,
              "type",
              "type"
            )}
            {renderTextField(
              "Request Type",
              "requestType",
              state,
              handleChange,
              "text",
              true
            )}
            {renderTextField(
              "Time Stamp",
              "timeStamp",
              state,
              handleChange,
              "text",
              true
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          margin: "5px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>
      </Box>
    </Fragment>
  );
};

export default memo(FormForAssetBuyandWhiteLabelOrderRequest);
