// requestTypesConfig.js

const assetsRequestTypesConfig = {
  NULL: {
    "FOOD-PROCESSOR": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    "BROKER": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    "BRAND": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    // Add other user types if needed...
  },
  BASIC: {
    "FOOD-PROCESSOR": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    "BROKER": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    "BRAND": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    // Add other user types if needed...
  },
  STANDARD: {
    "FOOD-PROCESSOR": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    "BROKER": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    "BRAND": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    // Add other user types if needed...
  },
  PREFFERED: {
    "FOOD-PROCESSOR": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    "BROKER": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    "BRAND": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    // Add other user types if needed...
  },
  ADMIN: {
    "FOOD-PROCESSOR": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    "BROKER": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    "BRAND": [
      { type: "asset-buy-rfq-req" },
      { type: "cf-agri-assets-rfq-req" },
      { type: "transport-asset-rfq-req" },
      { type: "storage-asset-rfq-req" },
    ],
    // Add other user types if needed...
  },
  // Add other roles if needed...
};

export default assetsRequestTypesConfig;
