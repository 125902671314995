import Typography from "@material-ui/core/Typography";
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import AssetQuoteManagement from "../QuotationManagement/AssetQuoteManagement";
import ProductQuoteManagement from "../QuotationManagement/ProductQuoteManagement";
import AssetsRfqManagement from "../RFQuoteManagement/AssetsRfq/AssetsRfqManagement";
import ProductsRfqMarket from "../RFQuoteManagement/ProductsRfq/ProductsRfqMarket";

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

const SocialOnMarketPlace = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const orientation = isSmallScreen ? "vertical" : "horizontal";
  const userType = localStorage.getItem("userType");
  const userRole = localStorage.getItem("UserRole");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    switch (userType) {
      case "BRAND":
        setValue(3);
        break;
      default:
        setValue(0);
        break;
    }
  }, [userType]);
  
  return (
    <>
      <div className="container" id="mainContentList">
        <Box
          sx={{
            width: "100%",
            mb: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "center" },
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "100%",
            }}
          >
            <Tabs
              orientation={orientation}
              value={value}
              onChange={handleChange}
              aria-label="Market"
            >
              {userType === "FOOD-PROCESSOR" && (
                <Tab
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  label="ASSET-QUOTE"
                  {...a11yProps(0)}
                  value={0}
                />
              )}
              {userType === "FOOD-PROCESSOR" && (
                <Tab
                  sx={{ alignItems: "flex-start" }}
                  label="PRODUCT-QUOTE"
                  {...a11yProps(1)}
                  value={1}
                />
              )}
              {(userType === "FOOD-PROCESSOR" || userType === "BRAND") && (
                <Tab
                  sx={{ alignItems: "flex-start" }}
                  label="ASSET-RFQ"
                  {...a11yProps(2)}
                  value={2}
                />
              )}
              {(userType === "FOOD-PROCESSOR" || userType === "BRAND") && (
                <Tab
                  sx={{ alignItems: "flex-start" }}
                  label="PRODUCT-RFQ"
                  {...a11yProps(3)}
                  value={3}
                />
              )}
            </Tabs>
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            {value === 0 && userType === "FOOD-PROCESSOR" && (
              <TabPanel value={value} index={0} style={{ width: "100%" }}>
                <AssetQuoteManagement />
              </TabPanel>
            )}
            {value === 1 && userType === "FOOD-PROCESSOR" && (
              <TabPanel value={value} index={1} style={{ width: "100%" }}>
                <ProductQuoteManagement />
              </TabPanel>
            )}
            {((value === 2 && userType === "FOOD-PROCESSOR") ||
              (value === 2 && userType === "BRAND")) && (
              <TabPanel value={value} index={2} style={{ width: "100%" }}>
                <AssetsRfqManagement />
              </TabPanel>
            )}
            {((value === 3 && userType === "FOOD-PROCESSOR") ||
              (value === 3 && userType === "BRAND")) && (
              <TabPanel value={value} index={3} style={{ width: "100%" }}>
                <ProductsRfqMarket />
              </TabPanel>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};
export default SocialOnMarketPlace;
