// // GoogleMap.js
// import React, { useEffect, useRef } from "react";
// import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";

// const containerStyle = {
//   width: "100%",
//   height: "60vh",
// };

// const center = {
//   lat: 12.971599,
//   lng: 77.594566,
// };

// const RouteDetailsMap = ({ routeGeopath }) => {
//   console.log(routeGeopath, "GEOOOOOO::::");

//   const mapRef = useRef(null);

//   const mapLoad = (ref) => {
//     mapRef.current = ref;
//   };

//   // Calculate bounds to fit the polyline
//   useEffect(() => {
//     if (mapRef.current && routeGeopath.length > 0) {
//       const bounds = new window.google.maps.LatLngBounds();
//       routeGeopath.forEach(point => bounds.extend(point));

//       mapRef.current.fitBounds(bounds);
//     }
//   }, [routeGeopath]);


//   const startMarker = (
//     <Marker
//       position={{ lat: routeGeopath[0].lat, lng: routeGeopath[0].lng }}
//       label="A"
//     />
//   );

//   const endMarker = (
//     <Marker
//       position={{
//         lat: routeGeopath[routeGeopath.length - 1].lat,
//         lng: routeGeopath[routeGeopath.length - 1].lng,
//       }}
//       label="B"
//     />
//   );
//   const path = routeGeopath.map((point) => ({
//     lat: point.lat,
//     lng: point.lng,
//   }));
//   return (
//     <GoogleMap 
//     mapContainerStyle={containerStyle}  
//     center={{ lat: routeGeopath[0].lat, lng: routeGeopath[0].lng }} 
//     zoom={5}    
//     onLoad={mapLoad}
// >
//       {/* Polyline to show the route */}
//       <Polyline
//         path={path}
//         options={{
//           strokeColor: "rgb(0, 123, 255)",
//           strokeOpacity: 1.0,
//           strokeWeight: 6,
//         }}
//       />

//         {/* Markers for start and end locations */}
//         {startMarker}
//       {endMarker}
//     </GoogleMap>
//   );
// };

// export default RouteDetailsMap;

// GoogleMap.js
import React, { useEffect, useRef } from "react";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "60vh",
};

const center = {
  lat: 12.971599,
  lng: 77.594566,
};

const RouteDetailsMap = ({ routeGeopath }) => {
  console.log(routeGeopath, "GEOOOOOO::::");

  const mapRef = useRef(null);

  const mapLoad = (ref) => {
    mapRef.current = ref;
  };

  useEffect(() => {
    if (mapRef.current && routeGeopath.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      routeGeopath.forEach((point) => bounds.extend(point));

      mapRef.current.fitBounds(bounds);
    }
  }, [routeGeopath]);

  // Check if routeGeopath is empty before accessing its elements
  if (!routeGeopath || routeGeopath.length === 0) {
    return null; 
  }

  const startMarker = (
    <Marker position={{ lat: routeGeopath[0].lat, lng: routeGeopath[0].lng }} label="A" />
  );

  const endMarker = (
    <Marker
      position={{
        lat: routeGeopath[routeGeopath.length - 1].lat,
        lng: routeGeopath[routeGeopath.length - 1].lng,
      }}
      label="B"
    />
  );

  const path = routeGeopath.map((point) => ({
    lat: point.lat,
    lng: point.lng,
  }));

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: routeGeopath[0].lat, lng: routeGeopath[0].lng }}
      zoom={5}
      onLoad={mapLoad}
    >
      {/* Polyline to show the route */}
      <Polyline
        path={path}
        options={{
          strokeColor: "rgb(0, 123, 255)",
          strokeOpacity: 1.0,
          strokeWeight: 6,
        }}
      />

      {/* Markers for start and end locations */}
      {startMarker}
      {endMarker}
    </GoogleMap>
  );
};

export default RouteDetailsMap;

