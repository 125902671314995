import React, { Component } from 'react';
import GaugeChart from 'react-gauge-chart';
// import {MultiSelect} from "react-multi-select-component";  
import { Select, MenuItem, FormControl, Grid } from '@mui/material';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row, Container,
} from 'reactstrap';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { masterService } from '../../Common/services/index';
import '../style.css';
import './Layout.css';
import AuthAxiosInstance from '../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance, EMSServcieAxiosInstance } from '../../../authAxiosInstance'
import Category from './containers/DeviceCategory/DeviceCategory';
import socketIOClient from "socket.io-client";
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import { NotificationManager } from "react-notifications";


const chartStyle = {
  height: 150,
}

var jsonconfig = window.appConfig;

class ViewMultiProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showspinner: false,
      data: {},
      deviceList: [],
      selectDevId: 0,
      listProperty: [],
      selectedProperty: [],
      date: {},
      validDate: {},
      selectedDevices: [],
      multivalueselected: [],
      historyloopcount: 0,
      finalhistorycall: []
    };

    // toast.configure({
    //   autoClose: 8000,
    //   draggable: false,
    //   //etc you get the idea
    // });
  }

  getAppConfig = () => {
    return window.AppConfigData;
  };
  componentDidMount() {
    //this.getlayoutlist();
    const socket = socketIOClient((this.getAppConfig().ApiUrlMapp.SocketIOURL));


    socket.on("livedata", (data) => {
      try {
        console.log("data is", data);
        //const alldevicelists = this.state.selectedDevices;
        const filterData = this.state.deviceList.filter((d) => (d.deviceId == this.state.selectedDevices[this.state.selectedDevices.length - 1]));
        const userId = localStorage.getItem("user-id");
        const { user } = MainService.getLocalData();
        const { username, parentId } = user;

        if (filterData.length > 0) {
          if (username === data.networkid.toString() && data.deviceid.toString() === filterData[0].deviceId) {
            //if (filterData[0].deviceType === data.devicetype) {
            let selectedProperty = [...this.state.selectedProperty];
            let propnamearray = [];
            switch (data.devicetype) {
              case 'AHUG':
                propnamearray = data.AHUGproperty;
                break;
              case 'CCN':
                propnamearray = data.CHILLER[0].CHILLERproperty;
                break;
              case 'LG':
                propnamearray = data.THS[0].THSproperty;
                break;
              case 'TCN':
                propnamearray = data.Tstat[0].Tstatproperty;
                break;
              case 'VCN':
                propnamearray = data.VFD[0].VFDproperty;
                break;
              case 'EMG':
                propnamearray = data.EMG[0].EMproperty;
                break;
              case 'EMMW':
                propnamearray = data.EMMW;
                break;
              default:
                propnamearray = data.properties;
                break;
              // code block
            }

            if (selectedProperty.length > 0) {
              selectedProperty.map((d, i) => {
                propnamearray.map((da) => {
                  if (d.property === da.property) {
                    if (data.deviceid.toString() === selectedProperty.deviceid) {
                      d.value = da.value;
                    }
                  }
                })
              })
              this.setState({ selectedProperty });
            }
            else {
              var t = this.state.selectedProperty;

            }

            //}
          }
        }
      }
      catch (cerror) {
        // toast.error("Something went wrong.", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");

      }
    });

    this.loadDeviceList();
  }


  loadDeviceList() {
    const userId = localStorage.getItem("user-id");
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/list`).then((resp) => {
      if (resp.data)
        this.setState({ deviceList: resp.data });
      else
        this.setState({ deviceList: [] });
    }).catch((err) => {

    })
  }

  selectDevice(evt, e) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    try {
      let dev_id = evt.target.value;
      this.setState({ selectDevId: evt.target.value, selectedPropertyID: '' });
      // var myCheckbox = document.getElementsByName("devicecheckbox");
      // Array.prototype.forEach.call(myCheckbox,function(el){
      //   el.checked = false;
      // });
      // e.currentTarget.checked = true;
      //const {selectedDevices} = this.state;
      // if (selectedDevices.includes(dev_id)) {
      //   //Perform Remove operation
      //   const ind = selectedDevices.indexOf(dev_id);
      //   selectedDevices.splice(ind, 1);
      // } else {
      //   //perform insert operation
      //   selectedDevices.push(dev_id);
      // }

      this.setState({ listProperty: [] });
      this.setState({ multivalueselected: [] });
      let selectedDevices = [];
      selectedDevices.push(dev_id);
      this.setState({ selectedDevices: selectedDevices });

      if (selectedDevices.length > 0) {
        const filterDevice = this.state.deviceList.filter((d) => (d.deviceId == selectedDevices[selectedDevices.length - 1]));

        this.setState({ showspinner: true });
        //AuthAxiosInstance.get(`/ems/livedata/${networkId.network}/${selectedDevices[selectedDevices.length - 1]}/properties?deviceType=${filterDevice[0].deviceType}`).then((res) => {
          DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${filterDevice[0].deviceId}/metadata?deviceType=${filterDevice[0].deviceType}&model=${filterDevice[0].model}`)
          .then((res) => {
            console.log("Response Data", res.data);
            this.setState({ showspinner: false });
            if (res.data && res.data !== 'failed' && res.data !== 'error') {
              console.log("filterDevice[0]", filterDevice[0]);
              if (filterDevice[0].deviceType == "AHUG") {
                this.setState({ listProperty: res.data.AHUGproperty ? res.data.AHUGproperty : [] }, () => {
                  console.log(this.state.listProperty);
                });

              }
              else if (filterDevice[0].deviceType == "EMMW") {
                this.setState({ listProperty: res.data.EMMW ? res.data.EMMW : [] }, () => {
                  console.log(this.state.listProperty);
                });
              }
              else if (filterDevice[0].deviceType == "CCN") {
                console.log("Here")
                this.setState({ listProperty: res.data.CHILLER[0].CHILLERproperty ? res.data.CHILLER[0].CHILLERproperty : [] }, () => {
                  console.log(this.state.listProperty);
                });
              }
              else if (filterDevice[0].deviceType == "LG") {
                console.log("Here")
                this.setState({ listProperty: res.data.THS[0].THSproperty ? res.data.THS[0].THSproperty : [] }, () => {
                  console.log(this.state.listProperty);
                });
              }
              else if (filterDevice[0].deviceType == "TCN") {
                this.setState({ listProperty: res.data.Tstat[0].Tstatproperty ? res.data.Tstat[0].Tstatproperty : [] }, () => {
                  console.log(this.state.listProperty);
                });
              }
              else if (filterDevice[0].deviceType == "VCN") {
                this.setState({ listProperty: res.data.VFD[0].VFDproperty ? res.data.VFD[0].VFDproperty : [] }, () => {
                  console.log(this.state.listProperty);
                });
              }
              else if (filterDevice[0].deviceType == "EMG") {
                if (res.data.EMG[0].EMproperty.length > 0) {
                  this.setState({ listProperty: res.data.EMG[0].EMproperty ? res.data.EMG[0].EMproperty : [] }, () => {
                    console.log(this.state.listProperty);
                  });
                }
                else {
                  this.setState({ listProperty: [] });
                  // toast.error("Data not found.", {
                  //   position: toast.POSITION.TOP_RIGHT
                  // });
                  return NotificationManager.error("Data not found.");

                }
              }
              else {
                this.setState({ listProperty: res.data.properties ? res.data.properties : [] }, () => {
                  console.log(this.state.listProperty);
                });
              }

            }
            else {
              this.setState({ listProperty: [] });
              // toast.error("Data not found.", {
              //   position: toast.POSITION.TOP_RIGHT
              // });
              return NotificationManager.error("Data not found.");

            }

          }).catch((err) => {
            this.setState({ showspinner: false });
            this.setState({ listProperty: [] });
            // toast.error("Some error occurred.", {
            //   position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");

          });
      } else {
        this.setState({ listProperty: [] });
      }
    }
    catch (cerror) {
      this.setState({ showspinner: false });
      this.setState({ listProperty: [] });
      // toast.error("Something went wrong.", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Some error occured");

    }

  }

  selectProperty(evt) {
    if (evt.target.value == '') {
      return;
    }
    const selectedProperty = this.state.selectedProperty.filter((d) => (d.property == evt.target.value));
    this.setState({ selectedPropertyID: evt.target.value });
    if (selectedProperty.length == 0) {
      this.getHistoryData(evt.target.value);
      //const resultArray = [...this.state.selectedProperty];
      //this.setState({selectedProperty: resultArray});
    } else {
      // const ind = this.state.selectedProperty.indexOf(selectedProperty[0]);
      // const property = [...this.state.selectedProperty];
      // property.splice(ind, 1);
      // this.setState({selectedProperty: property});
      const filterDevice = this.state.deviceList.filter((d) => (d.deviceId == this.state.selectedDevices[this.state.selectedDevices.length - 1]));
      if (filterDevice[0].deviceName === selectedProperty[0].deviceName) {
        // toast.info("Already added in the list!!", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
      }
      else {
        this.getHistoryData(evt.target.value);
      }

    }


    //this.setState({ selectedProperty: evt.target.value });

  }


  getHistoryData(property) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    try {
      this.setState({ showspinner: true });
      let currentdate = new Date();
      let todate = currentdate.toISOString().split('T')[0] + 'T23:59:59';;
      currentdate.setDate(currentdate.getDate() - 2);
      let fromdate = currentdate.toISOString();
      const filterproperty = this.state.listProperty.filter((d) => (d.property == property));
      const filterDevice = this.state.deviceList.filter((d) => (d.deviceId == this.state.selectedDevices[this.state.selectedDevices.length - 1]));
      EMSServcieAxiosInstance.get(`/ems/history/${username}/${filterDevice[0].deviceId}?deviceType=${filterDevice[0].deviceType}&property=${property}&fromDate=${fromdate}&toDate=${todate}`)
        .then((res) => {
          this.setState({ showspinner: false });
          let hcount = this.state.historyloopcount;
          hcount++;
          this.setState({ historyloopcount: hcount });
          if (res.data.length > 0) {
            res.data.sort(function (a, b) {
              var c = new Date(a.updatedat);
              var d = new Date(b.updatedat);
              return d - c;
            });
            if (filterDevice[0].deviceType === "AHUG") {
              const index = res.data[0].AHUGproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].AHUGproperty[index];
              results.deviceName = filterDevice[0].deviceName;
              results.deviceid = filterDevice[0].deviceId;
              results.unit = filterproperty[0].unit;
              const resultArray = [...this.state.selectedProperty];
              //results.value = "70";
              resultArray.push(results);
              this.setState({ selectedProperty: resultArray });
            }
            else if (filterDevice[0].deviceType === "EMMW") {
              const index = res.data[0].EMMW.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].EMMW[index];
              results.deviceName = filterDevice[0].deviceName;
              results.deviceid = filterDevice[0].deviceId;
              results.unit = filterproperty[0].unit;
              const resultArray = [...this.state.selectedProperty];
              //results.value = "70";
              resultArray.push(results);
              this.setState({ selectedProperty: resultArray });
            }
            else if (filterDevice[0].deviceType === "CCN") {
              const index = res.data[0].CHILLER[0].CHILLERproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].CHILLER[0].CHILLERproperty[index];
              results.deviceName = filterDevice[0].deviceName;
              results.deviceid = filterDevice[0].deviceId;
              results.unit = filterproperty[0].unit;
              const resultArray = [...this.state.selectedProperty];
              resultArray.push(results);
              this.setState({ selectedProperty: resultArray });
            }
            else if (filterDevice[0].deviceType === "LG") {
              const index = res.data[0].THS[0].THSproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].THS[0].THSproperty[index];
              results.deviceName = filterDevice[0].deviceName;
              results.deviceid = filterDevice[0].deviceId;
              results.unit = filterproperty[0].unit;
              const resultArray = [...this.state.selectedProperty];
              resultArray.push(results);
              this.setState({ selectedProperty: resultArray });
            }
            else if (filterDevice[0].deviceType === "TCN") {
              const index = res.data[0].Tstat[0].Tstatproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].Tstat[0].Tstatproperty[index];
              results.deviceName = filterDevice[0].deviceName;
              results.deviceid = filterDevice[0].deviceId;
              results.unit = filterproperty[0].unit;
              const resultArray = [...this.state.selectedProperty];
              resultArray.push(results);
              this.setState({ selectedProperty: resultArray });
            }

            else if (filterDevice[0].deviceType === "VCN") {
              const index = res.data[0].VFD[0].VFDproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].VFD[0].VFDproperty[index];
              results.deviceName = filterDevice[0].deviceName;
              results.deviceid = filterDevice[0].deviceId;
              results.unit = filterproperty[0].unit;
              const resultArray = [...this.state.selectedProperty];
              //results.value = "70";
              resultArray.push(results);
              this.setState({ selectedProperty: resultArray });
            }
            else if (filterDevice[0].deviceType === "EMG") {
              const index = res.data[0].EMG[0].EMproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].EMG[0].EMproperty[index];
              results.deviceName = filterDevice[0].deviceName;
              results.deviceid = filterDevice[0].deviceId;
              results.unit = filterproperty[0].unit;
              const resultArray = [...this.state.selectedProperty];
              //results.value = "70";
              resultArray.push(results);
              this.setState({ selectedProperty: resultArray });
            }
            else {
              const index = res.data[0].properties.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].properties[index];
              results.deviceName = filterDevice[0].deviceName;
              results.deviceid = filterDevice[0].deviceId;
              results.unit = filterproperty[0].unit;
              const resultArray = [...this.state.selectedProperty];
              //results.value = "70";
              resultArray.push(results);
              this.setState({ selectedProperty: resultArray });
            }
          }
          else {
            let dumobj = { property: property, value: 'NA', deviceName: filterDevice[0].deviceName, unit: filterproperty[0].unit, deviceid: filterDevice[0].deviceId };
            const resultArray = [...this.state.selectedProperty];
            resultArray.push(dumobj);
            this.setState({ selectedProperty: resultArray });
            // toast.info(`No old data found for ${filterDevice[0].deviceName}->${property}. If live data available, it will start displaying.`, {
            //   position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error(`No old data found for ${filterDevice[0].deviceName}->${property}. If live data available, it will start displaying.`);

          }

          if (this.state.historyloopcount === this.state.multivalueselected.length) {
            this.setState({ historyloopcount: 0, multivalueselected: [] })
          }

        }).catch((err) => {
          this.setState({ showspinner: false });
          // toast.error("Some error occurred", {
          //   position: toast.POSITION.TOP_RIGHT
          // });
          return NotificationManager.error("Some error occured");

        })
    }
    catch (cerror) {
      // toast.error("Something went wrong.", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Some error occured");

    }
  }

  preselectProperty(e) {
    const selectedValues = e.target.value;
    console.log(selectedValues);
    this.setState({ multivalueselected: selectedValues }, () => console.log(this.state.multivalueselected))

  }

  getloopdata() {
    let addedprop = this.state.selectedProperty;
    let multivalueselected = [...this.state.multivalueselected];
    let finalmultivalueselected = [...this.state.multivalueselected];
    if (multivalueselected.length > 0) {
      //const selectedProperty = this.state.selectedProperty.filter((d) => (d.property == evt.target.value));
      const filterDevice = this.state.deviceList.filter((d) => (d.deviceId == this.state.selectedDevices[this.state.selectedDevices.length - 1]));
      for (let i = 0; i < addedprop.length; i++) {
        for (let j = 0; j < multivalueselected.length; j++) {
          if (addedprop[i].property === multivalueselected[j] && filterDevice[0].deviceName === addedprop[i].deviceName) {
            //finalmultivalueselected
            finalmultivalueselected = multivalueselected.filter((item) => item !== addedprop[i].property);
            // toast.info(`${filterDevice[0].deviceName}->${addedprop[i].property} is already added in the list!!`, {
            //   position: toast.POSITION.TOP_RIGHT
            // });
          }
        }
      }

      this.setState({ multivalueselected: finalmultivalueselected },
        () => {
          for (let i = 0; i < finalmultivalueselected.length; i++) {
            let property = finalmultivalueselected[i];
            this.getHistoryData(property);
          }
        }
      );

    }
    else {
      // toast.error("Please select Device and Property.", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Please select Device and Property");

    }
  }



  render() {
    const { listProperty } = this.state;
    var listPropertynew = [];
    for (let i = 0; i < listProperty.length; i++) {
      let o = {};
      o.label = listProperty[i].property;
      o.value = listProperty[i].property;
      listPropertynew.push(o);
    }
    return (
      <div className="animated fadeIn" >
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <i className="fa fa-list-alt"></i>
                <strong className="LabelHeader">Multiple Live Data</strong>
                <div className="card-header-actions">
                  {/* <Button className="float-right badge badge-warning badge-pill" >Edit &nbsp;<i className="fa fa-edit float-right"></i></Button> */}
                </div>
              </CardHeader>
              <CardBody>

                <Grid container spacing={2} className='m-r-15'>
                  {/* <Col md="3">
                        <Category 
                        devices={this.state.deviceList} 
                        properties={this.state.listProperty} 
                        selectDevice={this.selectDevice.bind(this)}
                        selectProperty={this.selectProperty.bind(this)}
                        devicetype='radio'
                        propertytype='checkbox'/>
                      </Col> */}

                  <Grid item xs={6} md={5.5}>
                    <label className="m-r--15" style={{marginLeft:"15px"}}>Select Device</label>
                    {/* <Input disabled={this.state.showspinner} type="select" name="device" value={this.state.selectDevId} placeholder="Select Device" className="dropdownwidth2" onChange={this.selectDevice.bind(this)} style={{ width: "100%" }}>
                      <option value="">Select Device</option>
                      {this.state.deviceList.map((d, ind) => {
                        return <option key={ind + 1} value={d.deviceId}>{d.deviceName}</option>
                      })}

                    </Input> */}
                    <Select
                      disabled={this.state.showspinner}
                      name="device"
                      value={this.state.selectDevId}
                      onChange={this.selectDevice.bind(this)}
                      fullWidth
                    >
                      <MenuItem disabled value="">Select Device</MenuItem>
                      {this.state.deviceList.map((d, ind) => (
                        <MenuItem key={ind + 1} value={d.deviceId}>
                          {d.deviceName}
                        </MenuItem>
                      ))}
                    </Select>

                  </Grid>

                  <Grid item xs={6} md={5.5}>
                    <label className="">Select Property</label>
                    {/* <Input disabled={this.state.showspinner} type="select" 
                      value={this.state.selectedPropertyID} className="dropdownwidth2" 
                      name="pagesize" onChange={this.selectProperty.bind(this)} style={{ width: "100%" }}>
                        <option value="">Select Property</option>
                        {this.state.listProperty.map((d, ind) => {
                          return <option key={ind + 1} value={d.property}>{d.property}</option>
                        })}
                      </Input> */}
                    {listPropertynew.length > 0 &&
                      // <MultiSelect
                      //         disabled={this.state.showspinner}

                      //         options={listPropertynew}
                      //         value={this.state.multivalueselected}
                      //         onChange={this.preselectProperty.bind(this)}
                      //         labelledBy="Select Property"
                      //         style={{ width: "100%" }}
                      //         hasSelectAll={false}
                      //       />
                      <Select
                        multiple
                        labelId="select-multiple-label"
                        id="select-multiple"
                        value={this.state.multivalueselected}
                        onChange={this.preselectProperty.bind(this)}
                        inputProps={{ name: 'selectedOptions' }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        }}
                        fullWidth
                      >
                        {listPropertynew.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    {listPropertynew.length == 0 &&
                      <Select
                        disabled
                        multiple
                        labelId="select-multiple-label"
                        id="select-multiple"
                        value={this.state.multivalueselected}
                        onChange={this.preselectProperty.bind(this)}
                        inputProps={{ name: 'selectedOptions' }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        }}
                        fullWidth
                      >
                        {listPropertynew.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  </Grid>


                  <Grid item xs={6} md={1}>
                    <label className="">Get Data</label>
                    <div style={{marginTop:"10px"}}>
                      <Button disabled={this.state.showspinner} className='custom-button' onClick={this.getloopdata.bind(this)}>Get Data</Button>
                    </div>
                  </Grid>

                </Grid>

                <Row>
                  <Col xs="12" sm="12" lg="12" md="12">
                    <Grid container spacing={2} className='m-r-15'>
                      {
                        this.state.selectedProperty && this.state.selectedProperty.map((d, ind) => {
                          return (
                            <Grid item xs={6} md={5}>
                              {/* <div className="hide-button">X</div> */}
                              {/*<Meter {...d}/>*/}
                              <h6 className="text-center" style={{ minHeight: '32px' }}>{d.deviceName}&rarr;{d.property} {d.unit ? `(${d.unit})` : ''}</h6>
                              {isNaN(d.value / 100) &&
                                <GaugeChart
                                  id="gaugechart"
                                  style={chartStyle}
                                  needleColor={'#fff'}
                                  needleBaseColor={'#fff'}
                                  //colors={d.value.toLowerCase() === 'on' ? [ "#00FF00","#f86c6b" ] : 
                                  //(d.value.toLowerCase() === 'off' ? [ "#00ff0091","#FF0000" ] : [ "#00FF00","#FF0000" ])}
                                  //colors={d.value.toLowerCase() === 'on' ? [ "#00FF00","#00FF00" ] : 
                                  //(d.value.toLowerCase() === 'off' ? [ "#FF0000","#FF0000" ] : [ "#00FF00","#FF0000" ])}
                                  //nrOfLevels={2}
                                  nrOfLevels={20}
                                  animDelay={0}
                                  textColor={'#000'}
                                  formatTextValue={value => d.value}
                                />
                              }

                              {!isNaN(d.value / 100) &&
                                <GaugeChart
                                  id="gaugechart"
                                  style={chartStyle}
                                  percent={d.value > 100 ? (d.value > 1000 ? 1 : d.value / 1000) : d.value / 100}
                                  nrOfLevels={20}
                                  animDelay={0}
                                  textColor={'#000'}
                                  formatTextValue={value => d.value}
                                />
                              }

                            </Grid>
                          )
                        })
                      }

                    </Grid>

                    <Row>
                      {this.state.showspinner &&
                        <div className="sk-cube-grid">
                          <div className="sk-cube sk-cube1"></div>
                          <div className="sk-cube sk-cube2"></div>
                          <div className="sk-cube sk-cube3"></div>
                          <div className="sk-cube sk-cube4"></div>
                          <div className="sk-cube sk-cube5"></div>
                          <div className="sk-cube sk-cube6"></div>
                          <div className="sk-cube sk-cube7"></div>
                          <div className="sk-cube sk-cube8"></div>
                          <div className="sk-cube sk-cube9"></div>
                        </div>
                      }
                    </Row>

                  </Col>


                </Row>

              </CardBody>
            </Card>

          </Col>
        </Row>


      </div>
    );
  }
}

export default ViewMultiProperty;
