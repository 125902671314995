import React from 'react';
import { withGoogleMap, GoogleMap, Marker, Circle, Polyline, Polygon, InfoWindow } from "react-google-maps"

const google = window.google;



const LocationMap = withGoogleMap((props) => {

 
  console.log("props are", props);
  const handleEvent = (e) => {
    //props.setPath(e.latLng.lat(), e.latLng.lng());
  }




  return (
    <GoogleMap
    defaultZoom={16}
    defaultCenter={props.marker_position}
    center={props.marker_position}
 

  >

    {/* {props.places  && props.places.map((e) => <Marker position={e} />)} */}

    {Object.entries(props.vehicleList).map(([key, value]) => {
      
      return (
       (value) ? <Marker position={{lat: value.lat, lng: value.lng}}>
       <InfoWindow options={{disableAutoPan: true}}>
         <div>{key}</div>
       </InfoWindow>
     </Marker> : ''
      )
    })}
    
    <Polyline path={props.polyPath} defaultZoom={2} editable={false} />
  </GoogleMap>
  )
}
  
)

export default LocationMap;