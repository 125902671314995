import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import ListIcon from "@material-ui/icons/List";
import { Box, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import React, { useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import { PieChart } from "../../Common/CommonContent/PieChart";
import MainService from "../../Common/MainService";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  menuPaper: {
    width: 200,
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
  menuPaperForm: {
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
}));

export default function Advisor() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = MainService.getLocalData();
  const { username } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getMSMEUsers, setMSMEUsers] = useState([]);
  const [getMSMEUsersFilter, setMSMEUsersFilter] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [searched, setSearched] = useState("");
  const [getMsmeCaid, setMsmeCaid] = useState("");
  const [getFarmerCaid, setFarmerCaid] = useState("");
  const [getAdvisorSchemes, setAdvisorSchemes] = useState(false);
  const [getSchemesData, setSchemesData] = useState([]);
  const [getTitleName, setTitleName] = useState("");

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = getMSMEUsersFilter.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setMSMEUsers(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = getMSMEUsersFilter.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setMSMEUsers(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    getAllAdvisorUsers();
  }, [setMSMEUsers]);

  const getAllAdvisorUsers = () => {
    setIsLoading(true);
    MainService.getAllMSMEConsumerUsers()
      .then((data) => {
        setMSMEUsers(data);
        setMSMEUsersFilter(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error("");
      });
  };

  const getListOfCategorys = () => {
    MainService.getListOfCategorys()
      .then((data) => {
        const maseCategories = ["MSME_GOVT_SCHEMES", "FARMER_GOVT_SCHEMES"];
        const maseCategoriesFilter = data.filter((val) => {
          return maseCategories.includes(val.categoryname);
        });
        setMsmeCaid(maseCategoriesFilter[0].categoryid);
        setFarmerCaid(maseCategoriesFilter[1].categoryid);
      })
      .catch((e) => {
        return NotificationManager.error("");
      });
  };

  useMemo(() => {
    getListOfCategorys();
  }, []);

  const msmeSchemesHandler = (userData) => {
    setTitleName("MSME");
    MainService.getContentsBasedonContentCreator(
      userData.username,
      getMsmeCaid,
      "contentowner"
    )
      .then((data) => {
        setSchemesData(data);
        setAdvisorSchemes(!getAdvisorSchemes);
      })
      .catch((e) => {
        return NotificationManager.error("");
      });
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <div className="MuiGrid-root">
          <div>
            <div style={{ float: "right" }}>
              <Button
                startIcon={<ListIcon />}
                size="large"
                onClick={listViewClick}
              ></Button>
              <Button
                startIcon={<GridIcon />}
                size="large"
                onClick={gridViewClick}
              ></Button>
            </div>
            <PieChart
              value={getMSMEUsers.length}
              name="ADVISORS"
              total="TOTAL ADVISOR'S"
            />
          </div>
          {listView && (
            <Box>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      {/* <TableRow align="center">
                        <TableCell align="center" colSpan={5}>
                          <h2 className="cardactiontitle">
                            ** ADVISOR's DETAILS **
                          </h2>
                        </TableCell>
                      </TableRow> */}
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          Advisor Name
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Phone
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Email
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          Option
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getMSMEUsers.length > 0 ? (
                        getMSMEUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((users, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="center">
                                  {users.username}
                                </TableCell>
                                <TableCell align="center">
                                  {users.phone}
                                </TableCell>
                                <TableCell align="center">
                                  {users.email}
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    style={{ margin: "5px", width: "40%" }}
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      msmeSchemesHandler(users);
                                    }}
                                  >
                                    MSME
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow align="center">
                          <TableCell align="center" colSpan={12}>
                            Advisor'S Not Found.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={getMSMEUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          )}
          {gridView && (
            <div className="cardStyleForDashboardItems">
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                </div>
              ) : (
                <Box>
                  {/* <h2 className="cardactiontitle">** ADVISOR's DETAILS **</h2> */}
                  <SearchBar
                    value={farmgridsearched}
                    onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                    onCancelSearch={() => cancelFarmGridSearch()}
                  />
                  {getMSMEUsers.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {getMSMEUsers.map((users, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <p>
                            <b>Advisor Name:</b>
                            {users.username}
                          </p>
                          <p>
                            <b>Phone:</b>
                            {users.phone}
                          </p>
                          <p>
                            <b>Email:</b>
                            {users.email}
                          </p>
                          <div style={{ margin: "5px", width: "100%" }}>
                            <Button
                              style={{ margin: "5px", width: "40%" }}
                              type="button"
                              variant="contained"
                              onClick={() => {
                                msmeSchemesHandler(users);
                              }}
                            >
                              MSME
                            </Button>
                            {/* <Button
                                      style={{margin:"5px",width:"40%"}}
                                      type="button"
                                      variant="contained"
                                      onClick={() => {
                                        farmerSchemesHandler(users);
                                      }}
                                  >
                                      FARM
                                  </Button> */}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>Advisor'S Not Found.</h4>
                  )}
                </Box>
              )}
            </div>
          )}
        </div>

        <Dialog
          fullScreen={fullScreen}
          open={getAdvisorSchemes}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {`${getTitleName} GOVT SCHEMES DETAILS`}
          </DialogTitle>
          <DialogContent>
            <div className="cardStyleForDashboardItems">
              {getSchemesData.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {getSchemesData.map((schemes, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Assetdeveloperid:</b>
                          {schemes.parentid}
                        </p>
                        <p>
                          <b>Content Id:</b>
                          {schemes.contentid}
                        </p>
                      </span>
                      <p>
                        <b>Category Id:</b>
                        {schemes.categoryid}
                      </p>
                      <p>
                        <b>Content Uploader:</b>
                        {schemes.contentcreator}
                      </p>
                      <p>
                        <b>Category:</b>
                        {schemes.categoryname}
                      </p>
                      <p>
                        <b>Organization:</b>
                        {schemes.contentowner}
                      </p>
                      <p>
                        <b>Sub Categoryname:</b>
                        {schemes.contentsubcategory}
                      </p>
                      <p
                        style={{
                          padding: 10,
                          width: 300,
                        }}
                      >
                        <b>Description:</b>
                        {schemes.contentdescription}
                      </p>
                      <p>
                        <b>Expiry Date:</b>
                        {schemes.contentexpirydate}
                      </p>
                      <a
                        className="downloadDocument"
                        target="_blank"
                        href={schemes.locationurl}
                        download
                      >
                        Download Document
                      </a>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>Msme Govt Schemes Not Found.</h4>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                setAdvisorSchemes(!getAdvisorSchemes);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
