import { Box, Button, Divider, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import ChildSignedAgreements from "./ChildSignedAgreements";
import ChildUnsignedAgreements from "./ChildUnsignedAgreements";

const ChildDocs = () => {
  const [unSignedTradeDos, setUnsignedTradeDos] = useState(true);
  const [signedTradeDos, setSignedTradeDos] = useState(false);
  const [selectedReqType, setSelectedReqType] = useState("unsigned");

  const componentMapping = [
    {
      condition: signedTradeDos,
      component: <ChildSignedAgreements />,
      key: "signed",
    },
    {
      condition: unSignedTradeDos,
      component: <ChildUnsignedAgreements />,
      key: "unsigned",
    },
  ];

  const data = [
    { title: "Unsigned Child Agreements", type: "unsigned" },
    { title: "Signed Child Agreements", type: "signed" },
  ];

  const showHandler = (Type) => {
    setSelectedReqType(Type);
    const typeMappings = {
      unsigned: setUnsignedTradeDos,
      signed: setSignedTradeDos,
    };

    Object.keys(typeMappings).forEach((key) => {
      const setterFunction = typeMappings[key];
      if (Type === key) {
        setterFunction(true);
      } else {
        setterFunction(false);
      }
    });
  };

  const renderComponent = (condition, component) => {
    return condition ? component : null;
  };
  return (
    <>
      <Box className="container" id="mainContentList">
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          {data?.map((val, i) => (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                showHandler(val.type);
              }}
              sx={{
                textTransform: "none",
                backgroundColor:
                  selectedReqType === val.type ? "#3ECF8E" : "inherit",
                color: selectedReqType === val.type ? "primary" : "inherit",
              }}
            >
              {val.title}
            </Button>
          ))}
        </Box>
        <Divider sx={{ width: "100%" }}>
          <Typography sx={{ color: "#3ECF8E", padding: "5px 10px" }}>
            Document Types
          </Typography>
        </Divider>
        <Box className="MuiGrid-root">
          {componentMapping.map(({ condition, component, key }) => (
            <Fragment key={key}>
              {renderComponent(condition, component)}
            </Fragment>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ChildDocs;
