import React, {
  useState,
  useEffect,
  useMemo,
  memo,
  Fragment,
  useContext,
} from 'react';
import {
  Box,
  Button,
  Divider,
  ListItem,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { componentDetailsContext } from '../selleraccountmanagement/SellerAccountManagement';

const InvoiceTable = ({
  columnData,
  rowDataList,
  documentDetailsHander,
  invoice,
}) => {
  const { setInvoiceTypeDetails, buttonStatusHandler } = useContext(
    componentDetailsContext
  );

  const supplyList = useMemo(() => {
    return rowDataList || [];
  }, [rowDataList]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, column) => {
    setInvoiceTypeDetails(column);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    const tempInvoiceType = event.target.getAttribute('value');
    if (tempInvoiceType) {
      buttonStatusHandler(tempInvoiceType);
    }
    setAnchorEl(null);
  };

  const renderTableRow = (rowData, index) => {
    const openPdfHandler = (url) => {
      setTimeout(() => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Bill_PO.pdf');
        // Open the new tab
        const newTab = window.open('', '_blank');
        if (newTab) {
          newTab.document.body.appendChild(link);
          // Trigger the download
          link.click();
        } else {
          // Handle case where new tab couldn't be opened
          console.error('Failed to open new tab');
        }
      }, 100);
    };

    return (
      <ListItem
        key={index}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: '0.7rem',
          mb: '0.7rem',
          borderBottom: '1px solid gray',
        }}
      >
        {/* Render item details */}
        {columnData.map((column, columnIndex) => {
          if (column.type === 'button') {
            return (
              <Fragment key={columnIndex}>
                <Typography
                  sx={{
                    flex: '1',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {rowData.viewLink ? (
                    <Button onClick={() => openPdfHandler(rowData[column.id])}>
                      View
                    </Button>
                  ) : (
                    <Button
                      onClick={() => documentDetailsHander(supplyList[index])}
                    >
                      Generate
                    </Button>
                  )}
                </Typography>
                {invoice && (
                  <Box
                    sx={{
                      color: '#1976d2',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(event) => handleClick(event, supplyList[index])}
                  >
                    <MoreVertIcon />
                  </Box>
                )}
              </Fragment>
            );
          } else {
            return (
              <Typography key={columnIndex} sx={{ flex: '1', display: 'flex' }}>
                {rowData[column.id]}
              </Typography>
            );
          }
        })}
      </ListItem>
    );
  };

  return (
    <Box>
      <Stack spacing={2}>
        <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {columnData.map((li, index) => (
            <Typography
              key={index}
              sx={{
                flex: '1',
                display: 'flex',
                justifyContent:
                  index === columnData.length - 1 ? 'flex-end' : 'flex-start',
                fontWeight: '700',
              }}
            >
              {li.label}
            </Typography>
          ))}
        </ListItem>

        <Divider />
      </Stack>

      <Stack>{supplyList.map(renderTableRow)}</Stack>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose} value='challan'>
          Delivery Challan
        </MenuItem>
        <MenuItem onClick={handleClose} value='receipt'>
          Delivery Receipt
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default InvoiceTable;
