import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row, Container, Collapse, Fade,
    Pagination, PaginationItem, PaginationLink,
    Modal, ModalBody, ModalFooter, ModalHeader, Spinner
} from 'reactstrap';
import * as XLSX from 'xlsx';
import Badge from '@mui/material/Badge';
import saveAs from 'file-saver';
import * as ExcelJS from 'exceljs';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../style.css';
import './Layout.css';
import Chip from '@mui/material/Chip';
import AuthAxiosInstance from '../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance, EMSServcieAxiosInstance } from '../../../authAxiosInstance'
import socketIOClients from "socket.io-client";
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputLabel from '@mui/material/InputLabel';
import { NotificationManager } from "react-notifications";

// import path from "../../../../assets/templete.xls"

let prev = 0;
let next = 0;
let last = 0;
let first = 0;

var jsonconfig = window.appConfig;

class DeviceMarket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devicetypelist: [],
            disabledstate: true,
            isLoading: false,
            primary: false,
            danger: false,
            editmodal: false,
            deletedata: {},
            profiledata: [],
            uploadedData: [],
            deviceuploaddata: [],
            editdevicedetails: [],
            devicelist: [],
            odevicelist: [],
            currentpageno: 1,
            pageno: 1,
            pagesize: 5,
            searchkey: '',
            totaldevicecount: '',
            devicelisttypeid: 'all',
            accordion: [true],
            currentlist: [],
            typelist: [],
            modellist: [],
            uploadDataModel: false,
            deviceUploadLoader: false,
            marketstatus: ''
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleLastClick = this.handleLastClick.bind(this);
        this.handleFirstClick = this.handleFirstClick.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);


        // toast.configure({
        //   autoClose: 8000,
        //   draggable: false,
        //   //etc you get the idea
        // });
    }

    getAppConfig = () => {
        return window.AppConfigData;
      };

      
    toggleAccordion(tab) {

        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => tab === index ? !x : false);

        this.setState({
            accordion: state,
        });
    }

    handleClick(event) {
        event.preventDefault();
        this.setState({
            pageno: Number(event.target.id)
        });

        this.getAllDevices();
    }

    handleLastClick(event) {
        event.preventDefault();
        this.setState({
            pageno: last
        });
        this.getAllDevices();
    }
    handleFirstClick(event) {
        event.preventDefault();
        this.setState({
            pageno: 1
        });
        this.getAllDevices();
    }
    /*=============pagination========*/
    onRangevaluechange = (_value, e) => {
        var range = '';
        if (e.target.value == "ShowAll") {
            range = this.state.totaldevicecount;
        }
        else {
            range = e.target.value;
        }
        this.setState({ [_value]: range });
        this.setState({ currentpageno: this.state.currentpageno });
        this.setState({ pageno: 1 }, () => { this.callbackfirmware() });
    }

    onSearchkeyChange = (_value, e) => {
        this.setState({ [_value]: e.target.value });
        var queryResult = [];
        if (e.target.value !== '') {
            this.state.odevicelist.forEach(function (item) {
                if (item.deviceName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
                    item.deviceType.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
                    item.deviceId.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
                    item.model.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
                    queryResult.push(item);
            });
            this.setState({ devicelist: queryResult });
        }
        else {
            this.setState({ pageno: 1 }, () => { this.callbackfirmware() });
        }
    }
    callbackfirmware = () => {
        this.getAllDevices();
    }


    componentDidMount() {
        this.getAllDevices();
        this.getAllDevicesTypes();


        const socket2 = socketIOClients((this.getAppConfig().ApiUrlMapp.SocketIOURL));
        const { user } = MainService.getLocalData();
        const { username, parentId } = user;
        socket2.on("presence", (pdata) => {
            const userId = localStorage.getItem("user-id");
            try {
                if (pdata.networkId.toString() === username) {
                    let devicelist = [...this.state.devicelist];
                    let odevicelist = [...this.state.odevicelist]
                    devicelist.map((d, i) => {
                        if (d.deviceId === pdata.deviceId) {
                            d.deviceStatus = pdata.status;
                        }
                    });
                    odevicelist.map((od, i) => {
                        if (od.deviceId === pdata.deviceId) {
                            od.deviceStatus = pdata.status;
                        }
                    });
                    this.setState({ devicelist });
                    this.setState({ odevicelist });
                }
            }
            catch (cerror) {
                // toast.error("Something went wrong.", {
                //   position: toast.POSITION.TOP_RIGHT
                // });
            }
        })
    }

    getAllDevices() {
        const { user } = MainService.getLocalData();
        const { username, parentId } = user;
        //const userId = localStorage.getItem("user-id");
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/list`).then((resp) => {
            if (resp.data) {
                this.setState({ devicelist: resp.data, odevicelist: resp.data, totaldevicecount: resp.data.length });
            }
            else {
                this.setState({ devicelist: [], odevicelist: [] });
            }
        }).catch((err) => {

        })
    }


    getAllDevicesTypes() {
        const { user } = MainService.getLocalData();
        const { username, parentId } = user;
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/list`).then((resp) => {
            if (resp.data) {
                this.setState({ typelist: resp.data },
                    () => {
                    }
                );
            }
            else {
                this.setState({ typelist: [] });
            }
        }).catch((err) => {

        })
    }

    handleDropdownStatusChange = (event, row) => {

        const { user } = MainService.getLocalData();
        const { username, parentId } = user;
        let reqbody = {
            status: event.target.value
        }
        DeviceMetadataAxiosInstance.post(`/admin/deviceprofile/${username}/${row.deviceId}/certified/status`, reqbody).then((resp) => {
            if (resp.data) {
                this.getAllDevices();
                return NotificationManager.success("Status Updated successfully");
            }
            else {
                return NotificationManager.error("Not Updated");
            }
        }).catch((err) => {
            return NotificationManager.error("Not Updated");
        })
    };

    render() {

        const { devicetypelist, devicelist, pageno, pagesize,
            totaldevicecount, uploadedData } = this.state;


        if (devicelist.length > 0) {
            for (var i = 0; i < devicelist.length; i++) {
                for (var j = 0; j < devicetypelist.length; j++) {
                    if (devicetypelist[j].dvt_id == devicelist[i].dv_type) {
                        devicelist[i].dvt_name = devicetypelist[j].dvt_name;
                    }
                }
            }
        }
        // Logic for displaying todos
        const indexOfLastTodo = pageno * pagesize;
        const indexOfFirstTodo = indexOfLastTodo - pagesize;
        const currentlist = devicelist.slice(indexOfFirstTodo, indexOfLastTodo);
        prev = pageno > 0 ? (pageno - 1) : 0;
        last = Math.ceil(totaldevicecount / pagesize);
        next = (last === pageno) ? pageno : pageno + 1;
        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(totaldevicecount / pagesize); i++) {
            pageNumbers.push(i);
        }
        //Logic to get the list of pagesize
        const pagesizelist = []
        if (parseInt(totaldevicecount) > 0) {
            for (let j = 1; j <= totaldevicecount; j++) {
                if (j % 5 === 0) {
                    pagesizelist.push(j);
                }
            }
            pagesizelist.push("ShowAll");

        }
        else {
            pagesizelist.push("ShowAll");
        }




        return (
            <div className="animated fadeIn" >
                <Row>
                    <Col xs="12" sm="12">
                        <Card>
                            <CardHeader>
                            </CardHeader>
                            <Col xs="12" md="12">
                                <FormGroup row>
                                    <Col md="8">
                                        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                                            {this.state.searchkey === "" && (
                                                <FormControl variant="outlined" className="dropdownwidth2">
                                                    <Select
                                                        value={pagesize}
                                                        onChange={this.onRangevaluechange.bind(this, "pagesize")}
                                                    >
                                                        {pagesizelist.map((number, i) => (
                                                            <MenuItem
                                                                key={number}
                                                                value={number !== "ShowAll" ? number : totaldevicecount}
                                                            >
                                                                {number !== "ShowAll" ? number : "ShowAll"}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}
                                            {this.state.searchkey != "" && (
                                                <FormControl variant="outlined" className="dropdownwidth2">

                                                </FormControl>
                                            )}
                                            <Grid item md={4}>
                                                <TextField
                                                    type="text"
                                                    name="searchkey"
                                                    autoComplete="off"
                                                    placeholder="Enter search Key"
                                                    onChange={this.onSearchkeyChange.bind(this, "searchkey")}
                                                />
                                            </Grid>
                                        </div>
                                    </Col>
                                </FormGroup>
                            </Col>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>DEVICE ID</TableCell>
                                            <TableCell>DEVICE NAME</TableCell>
                                            <TableCell>DEVICE TYPE</TableCell>
                                            <TableCell>DEVICE MODEL</TableCell>
                                            <TableCell>DEVICE TESTING STATUS</TableCell>
                                            <TableCell>CHANGE TESTING STATUS</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {currentlist.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{row.deviceId}</TableCell>
                                                <TableCell>{row.deviceName}</TableCell>
                                                <TableCell>{row.deviceType}</TableCell>
                                                <TableCell>{row.model}</TableCell>
                                                <TableCell align="center">
                                                    {/* <Badge
                                                        sx={{ fontSize: "1rem", backgroundColor: "#3ecf8e", borderRadius: "15px", color: "white", padding: "5px" }}>
                                                        Untested
                                                    </Badge> */}
                                                    {row.certified == 'Verified' ? (<Chip
                                                        label={row.certified}
                                                        variant='outlined'
                                                        color='success'
                                                    />) : (<Chip
                                                        label={row.certified}
                                                        variant='outlined'
                                                        color='error'
                                                    />) }
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        value={this.state.marketstatus}
                                                        onChange={(e) => this.handleDropdownStatusChange(e, row)}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        fullWidth
                                                        size='small'
                                                    >
                                                        <MenuItem value='' disabled>
                                                            Change Device Status
                                                        </MenuItem>
                                                        <MenuItem value="untested">Untested</MenuItem>
                                                        <MenuItem value="In QA">In-QA</MenuItem>
                                                        <MenuItem value="In Progress">In-QA-Progress</MenuItem>
                                                        <MenuItem value="In Success">In-QA-Success</MenuItem>
                                                        <MenuItem value="Verified">Verified</MenuItem>
                                                    </Select>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br></br>
                            <div>
                                {(totaldevicecount !== pagesize) && (currentlist.length > 0) && (this.state.searchkey == "") &&
                                    <ul id="page-numbers">
                                        <nav>
                                            <Pagination style={{ display: "flex" }}>
                                                <PaginationItem>
                                                    {prev === 0 ? <PaginationLink disabled>First</PaginationLink> :
                                                        <PaginationLink onClick={this.handleFirstClick} id={prev}>First</PaginationLink>
                                                    }
                                                </PaginationItem>
                                                <PaginationItem>
                                                    {prev === 0 ? <PaginationLink disabled>Prev</PaginationLink> :
                                                        <PaginationLink onClick={this.handleClick} id={prev} >Prev</PaginationLink>
                                                    }
                                                </PaginationItem>
                                                {
                                                    pageNumbers.map((number, i) =>
                                                        <Pagination key={i}>
                                                            <PaginationItem disabled={pageNumbers[pageno - 1] === (number) ? true : false} >
                                                                <PaginationLink onClick={this.handleClick} key={number} id={number}>
                                                                    {number}
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                        </Pagination>
                                                    )}

                                                <PaginationItem>
                                                    {
                                                        pageno === last ? <PaginationLink disabled>Next</PaginationLink> :
                                                            <PaginationLink onClick={this.handleClick} id={pageNumbers[pageno]} >Next</PaginationLink>
                                                    }
                                                </PaginationItem>

                                                <PaginationItem>
                                                    {
                                                        pageno === last ? <PaginationLink disabled>Last</PaginationLink> :
                                                            <PaginationLink onClick={this.handleLastClick} id={pageNumbers[pageno]} >Last</PaginationLink>
                                                    }
                                                </PaginationItem>
                                            </Pagination>
                                        </nav>
                                    </ul>
                                }
                            </div>
                            {(currentlist.length == 0) &&

                                <center>
                                    <h3>No records found!!</h3>
                                </center>

                            }

                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }

}

export default DeviceMarket;
