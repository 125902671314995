import React from "react";
import FarmerImg from "../../images/gbrPN/PNImg1.webp";
import FarmersFranchiseImg from "../../images/gbrPN/PNImg5.webp";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import NetworkBanner from "../WebSite/NetworkBanner/NetworkBanner";
import ExporterImg from "../../images/gbrHome/exporter.webp";

export default function DSNService() {
  return (
    <>
      <NetworkBanner
        bannerTitle=" DSN Service"
        // bodycopy="Job creation | Digital transformation | Brand differentiation | Investable asset management | Organic certification"
        className="pnbg"
      />

      {/* <AAMCNetworkServices title="Agri Asset Management Cloud Services"/> */}
      {/* <MoneyMakingAssets /> */}

      {/* <IAABenefit />
      <IAAManagement /> */}

      <div className="main-wrapper2">
        <div className="subSectionWrapper ">
          <h2 className="container-title text-center mt-4">
            Dark Store Network Operator{" "}
          </h2>
          <HomeSubSection
            id="ABN-Tenders"
            className3="image4"
            className1="aboutBgGrey"
            className="rowReverseAbout borderedCards"
            descriptionTitle="
        F&B Buyers"
            description="F&B Buyers are integral partners for CMN network, forming a crucial link in the agri-food supply chain. These partners play a vital role in the success of CMN network by purchasing processed, semi-processed and raw products. The collaboration with buyers ensures a market for the  agri-products, contributing to financial sustainability and fostering a mutually beneficial relationship. This partnership highlights the interconnected nature of the agricultural ecosystem, where agri-producer, processor and buyers work together within CMN network guidelines to meet end users market demands and achieve overall success."
            // description="Through CMN distributor marketplace portal allows the corporate buyers to act as local distributor and exporters . Through this portal, corporate buyers keep track of transactions, make online orders and track the orders . The benefits for corporate buyers is to get quantity, quality and availability market fit assets for processing that have a direct impact on operations of downstream food supplier companies."
            // description1="Forecasting | Quality Certified| Reliable Supply | Fair Price | Improved Operational Efficency"
            src={ExporterImg}
            // href2="auth/3/signup"
            // buttoncont2="Register"
            // href1="auth/21/signin"
            // buttoncont1={`${exploreButton ? "Explore Exporters" : "Sign In"} `}
            // exploreButton={exploreButton}
            // href3="/f&b-buyers"
            // buttoncont3="Explore More"
            // onClick={() => {
            //   localStorage.setItem("userType", "AssetDistributor");
            // }}
            // description1="Corporate Buyers | Merchant Exporters | Distributors | Wholesalers | Brands | Retailers "
          />
        </div>
      </div>
      <div className="network-wrapper">
        <h2 className="container-title text-center mt-4">
          Dark Store Network Members{" "}
        </h2>
        {/* <h4 className="container-title text-center mx-4">
        Production Network (PN) - Investable Asset Management network for MSMEs
      </h4>
      <p className="container-para text-center mx-md-0 mx-4 mb-4 ">MSME can Build, Own, Manage & Operate Production Network</p> */}

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="pn-executive"
          description="A store owner is responsible for managing a retail business where products are stored and sold to customers. Key duties include inventory management to ensure products are adequately stocked and reordered as needed, providing excellent customer service to facilitate sales. "
          descriptionTitle="
          Store Owner"
          src={FarmerImg}

          // href2="auth/3/signin"
          // buttoncont2="Sign in"
          // buttoncont7="Sign up"
          // onClick={() => {
          //   localStorage.setItem("userType", "Grower");
          // }}
          // href7="/auth/3/signup"
          // descriptionTitle2= "What our plant "
        />

        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          descriptionTitle="
        Store Operator"
          description="
          A store operator is an individual or entity responsible for the day-to-day management and functioning of a store. This role includes tasks such as overseeing inventory, ensuring that shelves are stocked and products are available, managing staff, providing customer service, handling transactions, and maintaining the overall organization and cleanliness of the store. The store operator ensures that the store runs smoothly and efficiently, meeting the needs of both the business and its customers. "
          src={FarmersFranchiseImg}
          className3="pn-manager"
        />

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="pn-operator"
          description="
          A store manager is a professional responsible for overseeing the daily operations of a retail store. Their key duties include managing staff, ensuring excellent customer service, maintaining inventory levels, organizing the store layout, and handling financial aspects such as budgeting, sales tracking, and cash management. The store manager also implements company policies, ensures compliance with safety and health regulations, and works to achieve sales targets and business goals. Their role is crucial in ensuring the store operates efficiently and profitably."
          descriptionTitle="
          Store Manager"
          src={FarmerImg}
        />

        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="pn-qa"
          description="A store executive is responsible for monitoring the overall performance and operations of the retail store. This includes tracking sales and financial performance, ensuring compliance with company policies and standards, overseeing inventory levels, and maintaining the quality of customer service. They also supervise store managers and staff, ensuring that operational goals and targets are met. By analyzing data and reports, the store executive identifies areas for improvement and implements strategies to enhance efficiency and profitability. Their role is crucial in maintaining the store’s success and ensuring a positive shopping experience for customers."
          descriptionTitle="
          Store Executive"
          src={FarmerImg}
        />
        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="pn-technician "
          description="
          A Store QA (Quality Assurance) professional is responsible for ensuring that all aspects of a retail store meet specified quality standards and operational guidelines. Their duties include inspecting products for quality and consistency, verifying compliance with health and safety regulations, evaluating store cleanliness and organization, and monitoring customer service practices. They also conduct audits, train staff on quality protocols, and address any issues related to product defects or operational inefficiencies. The goal of a Store QA professional is to maintain high standards, improve customer satisfaction, and ensure a smooth and reliable shopping experience."
          descriptionTitle="
          Store QA "
          src={FarmerImg}

          // description1="The CMN Plant operator communicates project status updates to both the Plant Manager and the Plant Executive, facilitating effective coordination and decision-making within the plant's management hierarchy."
        />
        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="pn-qa"
          description="An agriminer serve as intermediaries to oversee the entire process, ensuring a seamless integration of store owners into the network. Additionally, agents are responsible for publishing detailed information regarding store. This includes conducting rigorous verification procedures to ensure the accuracy and reliability of the published information, thereby maintaining high standards and trust within the network.



          "
          descriptionTitle="
          AgriMiner"
          src={FarmerImg}
        />
        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="pn-technician "
          description="
          An agents serve as intermediaries to oversee the entire process, ensuring a seamless integration of store owners into the network. Additionally, agents are responsible for publishing detailed information regarding store. This includes conducting rigorous verification procedures to ensure the accuracy and reliability of the published information, thereby maintaining high standards and trust within the network.





          "
          descriptionTitle="
          Agent "
          src={FarmerImg}

          // description1="The CMN Plant operator communicates project status updates to both the Plant Manager and the Plant Executive, facilitating effective coordination and decision-making within the plant's management hierarchy."
        />
      </div>
    </>
  );
}
