import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import { Chip, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import XMLParser from 'react-xml-parser';
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';


const DeviceCloudServerManagement = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [opensharedDailog, setOpenSharedDailog] = React.useState(false);
    const [opendedicatedDailog, setopendedicatedDailog] = React.useState(false);
    const [deleteDailogOpen, setDeleteDailogOpen] = React.useState(false);
    const [updateDailogOpen, setUpdateDailogOpen] = React.useState(false);
    const [deviceDetailsOpen, setDeviceDetailsOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [deleteServer, setDeleteServer] = React.useState({});
    const [updateServer, setUpdateServer] = React.useState({});
    const [deviceDetails, setDeviceDetails] = React.useState({});
    const [selectedStatus, setSelectedStatus] = React.useState('published');
    const [isLoading, setIsLoading] = React.useState(false);
    const [xmlData, setXmlData] = React.useState('');
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    const [SharedformData, setSharedformData] = React.useState({
        deviceCloudServerType: '',
        friendlyName: '',
        tenancyType: '',
        deploymentType: '',
        ipAddress: '',
        port: '',
        baseUrl: '',
        region: '',
        version: '',
        oemIdentityAccessLink: '',
    });
    const [DedicatedformData, setDedicatedformData] = React.useState({
        deviceCloudServerType: '',
        friendlyName: '',
        tenancyType: '',
        deploymentType: '',
        dcsImageDownloadLink: '',
        version: '',
        oemIdentityAccessLink: '',
    });

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    React.useEffect(() => {

        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        MainService.getDeviceCloudList(parentId, username)
            .then((res) => {
                console.log(res)
                setXmlData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);

                return NotificationManager.error("Something went wrong");
            });
    };

    const parsedData = new XMLParser().parseFromString(xmlData);

    const tableData = parsedData?.children?.filter((element) => element.name === 'devicecloudserver')
        .map((device, index) => ({
            id: device.getElementsByTagName('id')[0]?.value || '',
            deviceCloudServerType: device.getElementsByTagName('devicecloudservertype')[0]?.value || '',
            friendlyName: device.getElementsByTagName('friendlyname')[0]?.value || '',
            tenancyType: device.getElementsByTagName('tenancytype')[0]?.value || '',
            deploymentType: device.getElementsByTagName('deploymenttype')[0]?.value || '',
            baseUrl: device.getElementsByTagName('baseurl')[0]?.value || '',
            region: device.getElementsByTagName('region')[0]?.value || '',
            ipAddress: device.getElementsByTagName('ipaddress')[0]?.value || '',
            port: device.getElementsByTagName('port')[0]?.value || '',
            version: device.getElementsByTagName('version')[0]?.value || '',
            dcsImgLink: device.getElementsByTagName('dcsimglink')[0]?.value || '',
            oemIdentityAccessLink: device.getElementsByTagName('oemidentityaccesslink')[0]?.value || '',
            status: device.getElementsByTagName('status')[0]?.value || '',
        }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSharedClickOpen = () => {
        setOpenSharedDailog(true);
        setAnchorEl(null);
    };

    const handleDedicatedClickOpen = () => {
        setopendedicatedDailog(true);
        setAnchorEl(null);
    };

    const handleSaredClose = () => {
        setOpenSharedDailog(false);
        setSharedformData({
            deviceCloudServerType: '',
            friendlyName: '',
            tenancyType: '',
            deploymentType: '',
            ipAddress: '',
            port: '',
            baseUrl: '',
            region: '',
            version: '',
            oemIdentityAccessLink: '',
        });
    };

    const handleDedicatedClose = () => {
        setopendedicatedDailog(false);
        setDedicatedformData({
            deviceCloudServerType: '',
            friendlyName: '',
            tenancyType: 'Not Applicable',
            deploymentType: 'Not Applicable',
            dcsImageDownloadLink: '',
            version: '',
            oemIdentityAccessLink: '',
        });
    };

    const handlesharedFormChange = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = name === 'deviceCloudServerType' ? value.replace(/\s/g, '') : value;
        setSharedformData((prevData) => ({
            ...prevData,
            [name]: sanitizedValue,
        }));
    };


    const handlededicatedFormChange = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = name === 'deviceCloudServerType' ? value.replace(/\s/g, '') : value;
        setDedicatedformData((prevData) => ({
            ...prevData,
            [name]: sanitizedValue,
        }));
    };

    const handleSharedSubmit = async (event) => {
        event.preventDefault();

        const xmlPayload = `<?xml version="1.0" encoding="UTF-8" ?>
    <dcs-info>
            <id>${username}</id>
            <devicecloudservertype>${SharedformData.deviceCloudServerType}</devicecloudservertype>
            <friendlyname>${SharedformData.friendlyName}</friendlyname>
            <tenancytype>${SharedformData.tenancyType}</tenancytype>
            <deploymenttype>${SharedformData.deploymentType}</deploymenttype>
            <ipaddress>${SharedformData.ipAddress}</ipaddress>
            <port>${SharedformData.port}</port>
            <baseurl>${SharedformData.baseUrl}</baseurl>
            <region>${SharedformData.region}</region>
            <version>${SharedformData.version}</version>
            <accesslink>${SharedformData.oemIdentityAccessLink}</accesslink>
        </dcs-info>`

        MainService.publishSharedCloud(xmlPayload)
            .then((res) => {
                handleSaredClose();
                fetchData();
                return NotificationManager.success("Device cloud server published");
            })
            .catch((err) => {
                return NotificationManager.error("Something went wrong");
            });

    };


    const handleDedicatedFormSubmit = async (event) => {
        event.preventDefault();

        const xmlPayload = `<?xml version="1.0" encoding="UTF-8" ?>
        <dcs-info>
                <id>${username}</id>
                <devicecloudservertype>${DedicatedformData.deviceCloudServerType}</devicecloudservertype>
                <friendlyname>${DedicatedformData.friendlyName}</friendlyname>
                <tenancytype>notApplicable</tenancytype>
                <deploymenttype>notApplicable</deploymenttype>
                <imagelink>${DedicatedformData.imagelink}</imagelink>
                <version>${DedicatedformData.version}</version>
                <accesslink>${DedicatedformData.oemIdentityAccessLink}</accesslink>
            </dcs-info>`

        MainService.publishDedicatedCloud(xmlPayload)
            .then((res) => {
                handleDedicatedClose();
                fetchData();
                return NotificationManager.success("Device cloud server published");
            })
            .catch((err) => {
                return NotificationManager.error("Something went wrong");
            });



    };
    const handleSearchChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchQuery(newSearchQuery);
        setPage(0);
    };

    const searchTable = (row) => {
        const lowercasedQuery = searchQuery.toLowerCase();
        return Object.values(row).some(
            (value) =>
                typeof value === 'string' && value.toLowerCase().includes(lowercasedQuery)
        );
    };

    const filteredData = tableData?.filter(searchTable);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const visibleData = filteredData?.slice(startIndex, endIndex);

    const handleUpdate = (row) => {
        setUpdateServer(row);
        setUpdateDailogOpen(true);
    };

    const handleDetailsShow = (row) => {
        setDeviceDetails(row);
        setDeviceDetailsOpen(true);
    };

    const handleUpdateSubmit = async () => {

        MainService.updateDeviceCloud(username, updateServer.deviceCloudServerType, updateServer.tenancyType, selectedStatus)
            .then((res) => {
                setUpdateDailogOpen(false);
                // Handle the response
                fetchData();
                return NotificationManager.success("Device cloud server Updated");
            })
            .catch((err) => {
                return NotificationManager.error("Something went wrong");
            });
    }

    const handleDeleteSubmit = async (row) => {

        MainService.deleteDeviceCloud(username, deleteServer.deviceCloudServerType, deleteServer.tenancyType, deleteServer.version)
            .then((res) => {
                handleDeleteDailogClose();
                // Handle the response
                fetchData();
                return NotificationManager.success("Device cloud server Deleted");
            })
            .catch((err) => {
                handleDeleteDailogClose();
                return NotificationManager.error("Something went wrong");
            });

    };
    const handleDeleteDailogClose = () => {
        setDeleteDailogOpen(false);
    }

    const handleUpdateDailogClose = () => {
        setUpdateDailogOpen(false);
    }

    const handleDeviceDetailsClose = () => {
        setDeviceDetailsOpen(false);
    }

    const skeletonRows = Array.from({ length: 5 }, (_, index) => (
        <TableRow key={index}>
            <TableCell colSpan={7}>
                <Box sx={{ width: '100%' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </Box>
            </TableCell>
            <TableCell><Chip
                label={
                    <Skeleton
                        animation="wave"
                        height={10}
                        width={30}
                        style={{ marginBottom: 6 }}
                    />
                }
            /></TableCell>
            <TableCell sx={{ display: 'flex' }}>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={30}
                    width={60}
                    style={{ margin: 5 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={30}
                    width={60}
                    style={{ margin: 5 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={30}
                    width={60}
                    style={{ margin: 5 }}
                />
            </TableCell>
        </TableRow>
    ));



    return (
        <div>
            {console.log(parsedData)}
            <span><h3>Device Cloud Server Management</h3></span>

            <Grid container spacing={2} display="flex" justifyContent="space=between">
                <Grid item xs={6} md={4}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid display="flex" justifyContent="flex-end" item xs={6} md={8}>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        variant='outlined'
                        style={{ color: '#3ecf8e', borderColor: '#3ecf8e', height: '2.5rem' }}
                        size='small'
                    >
                        <AddIcon />Create Cloud Server
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleSharedClickOpen}>SHARED</MenuItem>
                        <MenuItem onClick={handleDedicatedClickOpen}>DEDICATED</MenuItem>
                    </Menu>
                </Grid>

            </Grid>
            {isLoading ? <div>
                <TableContainer component={Paper}>
                    <Table responsive>
                        <TableHead>
                            <TableRow>
                                <TableCell>Oem Id</TableCell>
                                <TableCell>Device Cloud Server Type</TableCell>
                                <TableCell>Friendly Name</TableCell>
                                <TableCell>Tenancy Type</TableCell>
                                <TableCell>Deployment Type</TableCell>
                                <TableCell>IP Address</TableCell>
                                <TableCell>Port</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {skeletonRows}
                            </>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData ? filteredData.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div> : <div>
                <TableContainer component={Paper}>
                    <Table responsive>
                        <TableHead>
                            <TableRow>
                                <TableCell>Oem Id</TableCell>
                                <TableCell>Device Cloud Server Type</TableCell>
                                <TableCell>Friendly Name</TableCell>
                                <TableCell>Tenancy Type</TableCell>
                                <TableCell>Deployment Type</TableCell>
                                <TableCell>IP Address</TableCell>
                                <TableCell>Port</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(visibleData?.length === 0 || visibleData === undefined) ? <TableRow>
                                <TableCell colSpan={9}>
                                    <h3 style={{ textAlign: 'center' }}>No Servers Found</h3>
                                </TableCell>
                            </TableRow> :
                                <>
                                    {visibleData?.map((row,index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell>{row.deviceCloudServerType}</TableCell>
                                            <TableCell>{row.friendlyName}</TableCell>
                                            <TableCell>{row.tenancyType}</TableCell>
                                            <TableCell>{row.deploymentType}</TableCell>
                                            <TableCell>{row.ipAddress}</TableCell>
                                            <TableCell>{row.port}</TableCell>
                                            <TableCell><Chip label={row.status} color={row.status === 'verified' ? "info" : "success"} variant='outlined'></Chip></TableCell>
                                            <TableCell style={{ display: 'flex' }}>
                                                <Tooltip title="View" arrow placement="top">
                                                    <Button variant="outlined" color="primary" onClick={() => handleDetailsShow(row)}>
                                                        <VisibilityIcon />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Update" arrow placement="top">
                                                    <Button variant="outlined" color="secondary" style={{ marginLeft: '0.5rem' }} onClick={() => handleUpdate(row)}>
                                                        <DriveFileRenameOutlineIcon />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Delete" arrow placement="top">
                                                    <Button variant="outlined" color="error" style={{ marginLeft: '0.5rem' }}
                                                        onClick={() => {
                                                            setDeleteDailogOpen(true)
                                                            setDeleteServer(row)
                                                        }}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData ? filteredData.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>}
            <Dialog open={opensharedDailog} onClose={handleSaredClose}>
                <DialogTitle>Create Shared Cloud Server</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSharedSubmit}>
                        <TextField
                            label="Device Cloud Server Type"
                            name="deviceCloudServerType"
                            value={SharedformData.deviceCloudServerType}
                            onChange={handlesharedFormChange}
                            fullWidth
                            margin="normal"
                            inputProps={{ pattern: '^\\S*$' }}
                            required
                        />
                        <TextField
                            label="Friendly Name"
                            name="friendlyName"
                            value={SharedformData.friendlyName}
                            onChange={handlesharedFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Tenancy Type</InputLabel>
                            <Select
                                label="Tenancy Type"
                                name="tenancyType"
                                value={SharedformData.tenancyType}
                                onChange={handlesharedFormChange}
                                required
                            >
                                <MenuItem value="Shared">Shared</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Deployment Type</InputLabel>
                            <Select
                                label="Deployment Type"
                                name="deploymentType"
                                value={SharedformData.deploymentType}
                                onChange={handlesharedFormChange}
                                required
                            >
                                <MenuItem value="Standalone">Standalone</MenuItem>
                                <MenuItem value="Cluster">Cluster</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="IP Address"
                            name="ipAddress"
                            value={SharedformData.ipAddress}
                            onChange={handlesharedFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Port"
                            name="port"
                            value={SharedformData.port}
                            onChange={handlesharedFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Base Url"
                            name="baseUrl"
                            value={SharedformData.baseUrl}
                            onChange={handlesharedFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Region"
                            name="region"
                            value={SharedformData.region}
                            onChange={handlesharedFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Version"
                            name="version"
                            value={SharedformData.version}
                            onChange={handlesharedFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="OEM Identity Access Link"
                            name="oemIdentityAccessLink"
                            value={SharedformData.oemIdentityAccessLink}
                            onChange={handlesharedFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <DialogActions>
                            <Button type='submit' variant="contained" style={{ backgroundColor: '#3ecf8e', borderColor: '#3ecf8e' }} >
                                Submit
                            </Button>
                            <Button onClick={handleSaredClose} variant='outlined' color="error">
                                Cancel
                            </Button>

                        </DialogActions>
                    </form>
                </DialogContent>

            </Dialog>

            <Dialog open={opendedicatedDailog} onClose={handleDedicatedClose}>
                <DialogTitle>Create Dedicated Cloud Server</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleDedicatedFormSubmit}>
                        <TextField
                            label="Device Cloud Server Type"
                            name="deviceCloudServerType"
                            value={DedicatedformData.deviceCloudServerType}
                            onChange={handlededicatedFormChange}
                            fullWidth
                            margin="normal"
                            inputProps={{ pattern: '^\\S*$' }}
                            required
                        />
                        <TextField
                            label="Friendly Name"
                            name="friendlyName"
                            value={DedicatedformData.friendlyName}
                            onChange={handlededicatedFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />

                        <TextField
                            label="Tenancy Type"
                            name="tenancyType"
                            value='Not Applicable'
                            onChange={handlededicatedFormChange}
                            disabled
                            fullWidth
                            placeholder='Not Applicable'
                            margin="normal"
                            required

                        />

                        <TextField
                            label="Deployment Type"
                            name="deploymentType"
                            value='Not Applicable'
                            onChange={handlededicatedFormChange}
                            disabled
                            fullWidth
                            placeholder='Not Applicable'
                            margin="normal"
                            required

                        />
                        <TextField
                            label="DCS Image Download Link"
                            name="dcsImageDownloadLink"
                            value={DedicatedformData.dcsImageDownloadLink}
                            onChange={handlededicatedFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Version"
                            name="version"
                            value={DedicatedformData.version}
                            onChange={handlededicatedFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="OEM Identity Access Link"
                            name="oemIdentityAccessLink"
                            value={DedicatedformData.oemIdentityAccessLink}
                            onChange={handlededicatedFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <DialogActions>
                            <Button type='submit' variant="contained" style={{ backgroundColor: '#3ecf8e', borderColor: '#3ecf8e' }} color="primary">
                                Submit
                            </Button>
                            <Button onClick={handleDedicatedClose} variant="outlined" color="error">
                                Cancel
                            </Button>

                        </DialogActions>
                    </form>
                </DialogContent>

            </Dialog>

            <Dialog open={deleteDailogOpen} onClose={handleDeleteDailogClose}>
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDailogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteSubmit} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={updateDailogOpen} onClose={handleUpdateDailogClose}>
                <DialogTitle>Update Cloud Server</DialogTitle>
                <DialogContent>
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={selectedStatus}
                        onChange={handleStatusChange}
                        fullWidth
                        defaultValue="published"
                    >
                        <MenuItem selected value="published">Published</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateDailogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateSubmit} color="secondary" autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deviceDetailsOpen} onClose={handleDeviceDetailsClose}>
                <DialogTitle>Device Cloud Server Details</DialogTitle>
                <DialogContent sx={{ padding: "2rem" }}>
                    {/* {Object.entries(deviceDetails).map(([key, value]) => (
                        <Typography sx={{marginTop:'5px'}} key={key} variant="body1">
                            <strong>{key}:</strong> {value}
                        </Typography>
                    ))} */}
                    <Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Oem Id:</strong> {deviceDetails.id}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Device Cloud Server Type:</strong> {deviceDetails.deviceCloudServerType}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Friendly Name:</strong> {deviceDetails.friendlyName}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Tenancy Type:</strong> {deviceDetails.tenancyType}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Deployment Type:</strong> {deviceDetails.deploymentType}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Base Url:</strong> {deviceDetails.baseUrl}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Region:</strong> {deviceDetails.region}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>IP Address:</strong> {deviceDetails.ipAddress}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Port:</strong> {deviceDetails.port}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Version:</strong> {deviceDetails.version}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>DCS Image Link:</strong> {deviceDetails.dcsImgLink}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>OEM Identity Access Link:</strong> {deviceDetails.oemIdentityAccessLink}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Status:</strong> {deviceDetails.status}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeviceDetailsClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DeviceCloudServerManagement