import React,{ createContext, useState, useEffect } from "react";


const CartItemContext = createContext()

const CartItemProvider = ({children}) => {
        const [cartState, setCartState] = useState([]);
        
    const cartStateChangeHandler =(item) => {
        console.log('item in cart ,....', item)
        setCartState([...cartState, item])
    }

    return <CartItemContext.Provider value={{cartState,cartStateChangeHandler}}>{children}</CartItemContext.Provider>
}

export {CartItemContext, CartItemProvider}