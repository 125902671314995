import React from 'react';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';

export default function AgribusinessMainSection({msme}) {
  return (
    <>
          <HomeSubSection
        id="ABN-Investor"
        className3="agribusiness-img"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards--mod"
        descriptionTitle="
                Farm Enterprise"
        description="A farm enterprise, also known as a farming enterprise, is an agribusiness that is primarily engaged in agricultural activities. This includes the cultivation of crops, the raising of livestock, and other related farming practices. We help farm enterprises to become Agri Asset Management Company so that they can assist clients, which can include investors, agri-producers, or agricultural businesses, in effectively managing and generating returns from their agricultural assets.

        "
        description1="Organic farming companies | Agri-asset management companies"
        href3="/ForAAMC"
        buttoncont3="For Farm Enterprise"

        href2="/auth/3/signup"
        buttoncont2="Register"
                   onClick={() => {
              localStorage.setItem("userType", "AssetDeveloper");
            }}

      />
<HomeSubSection
        id="ABN-Investor"
        className3="agri-foodmanufacturer-img"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards--mod"
        descriptionTitle="
        Food Processing Enterprises"
        description="Food Processing Enterprises are companies involved in processing agricultural products into consumable goods, playing a crucial role in the production and transformation of raw materials such as crops and livestock into various food products. Examples include those producing packaged foods, beverages, meat, dairy, and processed agricultural goods.
        "
        description1="Food processor "
        href3="/food-processing-enterprises"
        buttoncont3="For Food Processing Enterprises        "
        href2="/auth/3/signup"
        buttoncont2="Register"
                   onClick={() => {
              localStorage.setItem("userType", "FOOD-PROCESSOR");
            }}
      />



<HomeSubSection
        id="ABN-Investor"
        className3="cbo-img"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards--mod"
        descriptionTitle="
        Community Based Organizations"
        description="Community-based organizations (CBOs) in are grassroots groups formed by individuals within a community to collectively address agricultural challenges and enhance rural livelihoods. These organizations play a pivotal role in promoting sustainable farming practices, improving access to markets and resources, and strengthening community ties. By fostering collaboration and knowledge-sharing, CBOs contribute to the overall development of agriculture, empower small-scale farmers, and address specific issues  in farming, thereby promoting resilient and inclusive agricultural systems at the local level.
        "
        description1="FPCs | NGOs | PACS | Traders | SHG | CS"
        href3="/CBO"
        buttoncont3="For Community Based Organizations"
        href2="/auth/3/signup"
        buttoncont2="Register"
                   onClick={() => {
              localStorage.setItem("userType", "CBO");
            }}      />

{/* {msme === "true"  && <HomeSubSection
        id="ABN-Investor"
        className3="msme-img"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards--mod"
        descriptionTitle="
        Agri-MSMEs"
        description="Agri-MSMEs are small and medium-sized enterprises operating within the agro sector, encompassing farmers, agri-processing units, input suppliers, storage providers and service providers. They play a vital role in the agro value chain, contributing to economic development, employment, and sustainable agriculture. Support for Agri-MSMEs is crucial for fostering inclusive growth and enhancing the resilience of the agro economy.
"
description1="Agri-Entrepreneurs | Agri-Storage Entrepreneurs |
Agri-Contract Manufacturer | Agri-Transporter
"
href4="http://gbrfarming.com/"
        buttoncont1="For Agri-MSMEs"
       
      />} */}
    </>
  )
}
