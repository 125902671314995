import React from "react";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";

const LayOut = ({ children }) => {
  const { pathname } = useLocation(); 

  if (pathname.startsWith("/main") || pathname.startsWith("/auth")) {
    return <main>{children}</main>;
  } else {
    return (
      <>
        <HeaderComponent />
        <main>{children}</main>
        <FooterComponent />
      </>
    );
  }
};

export default LayOut;
