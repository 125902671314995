import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import { Line } from "react-chartjs-2";
import FormHelperText from "@material-ui/core/FormHelperText";

import "./InvestmentROIComponent.css";

export default class InvestmentROIComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: "",
      product_error: false,
      qty: "",
      qty_error: false,
      years: "",
      years_error: false,
      amount: "",
      open: false,
      graphData: {
        labels: ["8M-15%", "12M-18%", "15M-22%"],
        datasets: [
          {
            label: "Returns",
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "rgba(75,192,192,1)",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(75,192,192,1)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [2500000, 5500000, 8500000],
          },
        ],
      },
    };
  }


 handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value },
      this.calculateAmount
    );
    console.log(event.target.name,event.target.value)
  };

  calculateAmount = () => {
    if (this.state.product === "") {
      this.setState({ product_error: true });
      return;
    }
    this.setState({ product_error: false });

    if (this.state.qty < 1) {
      this.setState({ qty_error: true });
      return;
    }
    this.setState({ qty_error: false });

    if (this.state.years === "") {
      this.setState({ years_error: true });
      return;
    }
    this.setState({ years_error: false });

    let _graphData = this.state.graphData;
    let _currentValue = 5000;
    let _amount = this.state.qty * _currentValue;

    _graphData.datasets[0].data = [];

    let _cumAmout = 0;
    for (let i = 1; i <= 15; i++) {
      _cumAmout = _cumAmout + _amount;

      let _intrestRate = 0.15;

      if (i > 8) _intrestRate = 0.18;
      if (i > 12) _intrestRate = 0.22;

      _cumAmout += this.calculateTotalCompoundInterest(
        _cumAmout,
        _intrestRate,
        1,
        1
      );

      if (i === 8) _graphData.datasets[0].data.push(_cumAmout);
      if (i === 12) _graphData.datasets[0].data.push(_cumAmout);
      if (i === 15) _graphData.datasets[0].data.push(_cumAmout);
    }

    this.setState({
      amount: this.state.qty * _currentValue,
      graphData: _graphData,
    });
  };

  calculateTotalCompoundInterest(principal, annual_rate, n_times, t_years) {
    return Math.ceil(
      principal * (Math.pow(1 + annual_rate / n_times, n_times * t_years) - 1)
    );
  }

  submit = (event) => {
    event.preventDefault();
    this.calculateAmount();
    this.toggleModalClose();
  };

  toggleModalClose = () => {
    this.setState({ open: !this.state.open });
  };
  render() {
    console.log(this.state.graphData.datasets[0].data, "jjjj")

    return (
      <Container className="InvestmentROIContainer SignUpasContainerWhite mt-3">
        <h3 className="text-center container-title mb-3">
           ROI Calculator
        </h3>

        <Grid container spacing={2}>
          <form className="investorForm" onSubmit={this.submit}>
            <FormControl className="formControl">
              <InputLabel htmlFor="outlined-age-simple">
                Select Product
              </InputLabel>
              <Select
                required
                name="product"
                value={this.state.product}
                onChange={this.handleChange}
                input={<OutlinedInput name="Select Product" />}
              >
                <MenuItem value={""}>
                  Select Product
                </MenuItem>
                <MenuItem value={"Beehive"}>Beehive</MenuItem>

                {/* <MenuItem value={"Sheep"}>Sheep</MenuItem>
                <MenuItem value={"Goat"}>Goat</MenuItem> */}
              </Select>
              {this.state.product_error && (
                <FormHelperText className="colorRed">
                  Please select product.
                </FormHelperText>
              )}
            </FormControl>

            <FormControl className="formControl">
              <TextField
                required
                type="number"
                name="qty"
                value={this.state.qty}
                label="Enter quantity"
                placeholder="Enter quantity in no"
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
              />
              {this.state.qty_error && (
                <FormHelperText className="colorRed margin0">
                  Please enter quantity.
                </FormHelperText>
              )}
            </FormControl>

            <FormControl className="formControl">
              <InputLabel htmlFor="outlined-age-simple">
                Investment Period
              </InputLabel>
              <Select
                required
                name="years"
                value={this.state.years}
                onChange={this.handleChange}
                input={<OutlinedInput name="Select Years" />}
              >
                <MenuItem value={""}>
                  Select Years
                </MenuItem>
                <MenuItem value={8}>8 Month</MenuItem>
                <MenuItem value={12}>12 Month</MenuItem>
                <MenuItem value={15}>15 Month</MenuItem>
              </Select>
              {this.state.years_error && (
                <FormHelperText className="colorRed">
                  Investment Period.
                </FormHelperText>
              )}
            </FormControl>

            <FormControl className="formControl">
              <TextField
                required
                name="amount"
                value={formatMoney(this.state.amount)}
                disabled={true}
                label="Investment Amount Per Month"
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
              />
            </FormControl>

            <Button
              type="submit"
              className="contactUsBtn"
              variant="contained"
              color="primary"
            >
              Calculate <i className="material-icons">assessment</i>
            </Button>
          </form>
        </Grid>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.toggleModalClose}
        >
          <div className="modalCnt">
            <i
              className="closeBtn material-icons"
              onClick={this.toggleModalClose}
            >
              close
            </i>
            <h2>Your Forecasting Return</h2>
            <p className="returnsDetails">
              8Month -{" "}
              <b>{formatMoney(this.state.graphData.datasets[0].data[0])}</b>{" "}
              @15%
            </p>
            <p className="returnsDetails">
              12Month -{" "}
              <b>{formatMoney(this.state.graphData.datasets[0].data[1])}</b>{" "}
              @18%
            </p>
            <p className="returnsDetails">
              15Month -{" "}
              <b>{formatMoney(this.state.graphData.datasets[0].data[2])}</b>{" "}
              @22%
            </p>

            {this.state.open && <Line data={this.state.graphData} />}
          </div>
        </Modal>
      </Container>
    );
  }
}

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  console.log(amount,"jvgg")
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}