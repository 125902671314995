import React from 'react'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import FFSSection from './FFSSection'
import './FFS.css'

export default function FFS() {
  return (
    <>
    <UpdatesBanner     className="FFS text-white"
         bannerText="Farmer Facilitation Service"/>
         <FFSSection/>
    </>
  )
}
