import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function OrdersPageComponent() {
  const [, setIsLoading] = useState(true);
  const [state, setState] = useState({
    columns: [
      { title: "OrderId", field: "orderid" },
      { title: "TransactionId", field: "transactionid" },
      { title: "OrderDate", field: "timestamp" },
      { title: "Quantity", field: "quantity" },
      { title: "Total Amount", field: "totalamount" },
      { title: "Timestamp", field: "timestamp" },
      { title: "Paymentmode", field: "paymentmode" },
      { title: "Invest Date", field: "whitelabeldate" },
      { title: "Investment Cost", field: "whitelabelcost" },
      { title: "Order Status", field: "status" },
      { title: "Investor", field: "assetoemid" },
      { title: "AAMC", field: "solutionid" },
      { title: "Farm", field: "siteid" },
      { title: "Animal Type", field: "legacyassettype" },
      { title: "Breed", field: "modelid" },
      { title: "Animal Id", field: "assetid" },
      { title: "ServicePackage", field: "servicepackage" },  
       
    ],
    data: [],
  });

  //const chartData = AppData.orders;

  /* const [selectedStartDate, setSelectedStartDate] = useState(
    new Date(AppData.defaultStartDate)
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    new Date(AppData.defaultEndDate)
  ); */

  useEffect(() => {
    getOrderList()
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  /* const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  }; */

  const getOrderList = () => {
    const { user } = MainService.getLocalData();
    const { username } = user;
    MainService.getOrderList(username)
    .then((data) => {
      setIsLoading(false);
      setState({...state, data: data});
      // setSummaryDetails({...data.totalcost});
    })
    .catch((e) => {
      setIsLoading(false);
      console.error(e);
      return NotificationManager.error(e);
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={12} sm={12} xs={12}>
        <MaterialTable
          title="Orders List"
          columns={state.columns}
          data={state.data}
          editable={{}}
        ><CircularProgress className="loginLoader" /></MaterialTable>
      </Grid>
    </Grid>
  );
}
