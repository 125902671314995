import React, { useState, useEffect, useMemo } from "react";
import { Button } from "@mui/material";
//import ViewFarmSiteManagementComponent from "../FarmSiteManagement/FarmSiteManagementComponent";
import ViewSiteManagementComponent from "../SiteManagement/SiteManagementComponent";
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";

export default function AAMCSiteManagementComponent(props) {
  const [viewAllSites, setViewAllSites] = useState(true);
  const [viewFarmSites, setViewFarmSites] = useState(false);
  const [sitesData, setSitesData] = useState([]);
  const [sitesData1, setSitesData1] = useState([]);
  const [sitesData2, setSitesData2] = useState([]);
  const [mainSitesData,setMainSitesData] = useState([]);
  const [farmSitesData, setFarmSitesData] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [showButtons, setShowButtons] = useState(true);
  const [siteTypeList, setSiteTypeList] = useState([props.siteType]);
 
  useEffect(() => {
    getAllSitesData();
  }, []);
  const getAllSitesData =()=>{
   

    const res = props?.siteType.map(async (item) => 
     {const data =await MainService.viewAllFarms(username, item);
      const {sites} = data
    
      return sites
     }
      
    )
    Promise.all(res).then((responses) => {
      
      const mergeData = responses.reduce((acc, curr) => acc.concat(curr), []);
      setSitesData(mergeData);
      setMainSitesData(mergeData);

      console.log('mergeData', mergeData)
  });
    
  }
  
function deletSiteSubmitClcik (selectedSiteId, selectedSiteName, username){
 // console.log("childData:",childData);
  MainService.siteDeleteHandler(selectedSiteId, selectedSiteName, username)
  .then((res) => {
    //getAllSitesData();
    const filterData = sitesData.filter((item) => item.siteid !== selectedSiteId )
    setSitesData(filterData)
    
    return NotificationManager.success("Successfully Deleted");
    
  })
  .catch((err) => {
    return NotificationManager.error(err);
  });
}
  
function viewAssetsHandler(showAssetGrid)
{
  console.log("viewAllGridSites",showAssetGrid);
  if(showAssetGrid === true)
  {
    setShowButtons(false);
  }else{
    setShowButtons(true);
  }
}
function requestSearch(searchedVal){
  console.log("searched val:",searchedVal)
  const filteredRows = sitesData.filter((row) => {
    return row.sitename.toLowerCase().includes(searchedVal.toLowerCase());
  });
  setSitesData(filteredRows);
}

function requestFarmGridSearch(searchedVal){
  console.log("searched val:",searchedVal)
  if(searchedVal !== "")
  {
    const filteredRows = sitesData.filter((row) => {
      return row.sitename.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setSitesData(filteredRows);
  }else{
    if(viewAllSites === true)
    {
      setSitesData(mainSitesData);
    }else{
      setSitesData(farmSitesData);
    }
    
  }
}


  

  return (
    <>
      <div>
        {<ViewSiteManagementComponent
         sites={sitesData} 
         getAllSitesData={getAllSitesData}
         deletSiteSubmitClcik={deletSiteSubmitClcik}
         requestSearch={requestSearch}
         requestFarmGridSearch={requestFarmGridSearch}
         viewAssetsHandler={viewAssetsHandler}
         siteType={props.siteType}
         callingFrom = {props.callingFrom}
        
         />}
      </div>
    </>
  );
}
