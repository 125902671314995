import React, { useEffect, useState } from "react";
import Map from "../../googlemap/Map";
import TripMonitorMap from "./TripMonitorMap";
import { Box, Button, Typography } from "@mui/material";
import {
  useHistory,
  useNavigate,
} from "react-router-dom/cjs/react-router-dom.min";
import MainService from "../../../Common/MainService";
import { io } from "socket.io-client";
import viewlefticon from "../Route/viewrouteimages/left-arrow.png";

const commonTypographyStyle = {
  fontSize: "15px",
  fontWeight: "bold",
  padding: "10px",
  borderRadius: "8px",
  boxShadow:
    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
  margin: "8px",
};

const TripMonitoring = (props) => {
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [routeDetails, setRouteDetails] = useState("");
  const [geopath, setGeopath] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState({});

  // const navigate = useNavigate();
  //.................SOCKET-IO..................
  useEffect(() => {
    const newSocket = io(
      "http://ec2-34-217-29-64.us-west-2.compute.amazonaws.com:9001"
    );
    newSocket.on("connect", () => {
      console.log(
        "Connected to server!************",
        `tt/solution/${username}/site/TestSite1/asset/${selectedTrip.vehicleId}/LOC`
      );
    });

    newSocket.on(
      `tt/solution/consumer2/site/TestSite1/asset/${selectedTrip.vehicleId}/LOC`,
      (data) => {
        console.log(".....DAAATAAA........", data);

        console.log(
          JSON.parse(data.payload).geoLocation,
          "..........GEOLOCATION DATA........."
        );
        setMarkers([{ geoLocation: JSON.parse(data.payload).geoLocation }]);

        console.log(markers, "..........TRIP--markersss..........");
      }
    );

    return () => {
      newSocket.disconnect();
    };
  }, [vehicleDetails.assetId, username]);

  const history = useHistory();
  const selectedTrip = props.location.state
    ? props.location.state.tripData
    : null;

  console.log(selectedTrip, "selected trippp:::");
  console.log(selectedTrip.tripId, "TRIP ID:::");
  console.log(selectedTrip.routeId, "ROUTE ID:::");
  console.log(selectedTrip.vehicleId, "VEHICLE ID:::::::");
  console.log(selectedTrip.driverId, "DRIVER ID:::");

  const callApiWithRouteId = async (routeId) => {
    try {
      const routeId = selectedTrip.routeId;
      const response = await MainService.getDetailsofRouteId(username, routeId);
      console.log("ROUTE GEOPATH RESPONSE::::", response.geoPath);
      setRouteDetails(response);
      setGeopath(response.geoPath);

      console.log(routeDetails, "ROUTE DETAILS.........");
    } catch (error) {
      console.error("Error calling API:", error);
    }
  };

  useEffect(() => {
    callApiWithRouteId();
  }, []);

  return (
    <div>
      <Button onClick={() => history.goBack()}>
        <img
          src={viewlefticon}
          alt="back icon"
          style={{ width: "2.5rem", height: "2.5rem" }}
        />
      </Button>
      <Box>
        <div
          style={{
            borderBottom: "2px solid #007BFF",
            padding: "10px",
            textAlign: "center",
          }}
        >
          <Typography variant="h4" component="h1" style={{ color: "#007BFF" }}>
            Trip Monitoring
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Typography sx={commonTypographyStyle}>
            TRIP-ID: {selectedTrip.tripId}
          </Typography>

          <Typography sx={commonTypographyStyle}>
            VEHICLE-ID: {selectedTrip.vehicleId}
          </Typography>

          <Typography sx={commonTypographyStyle}>
            DRIVER-ID: {selectedTrip.driverId}
          </Typography>
        </div>
      </Box>

      <Box>
        <TripMonitorMap
          geopath={geopath}
          routeLocationdata={markers}
          driverId={selectedTrip.driverId}
        />
      </Box>
    </div>
  );
};

export default TripMonitoring;
