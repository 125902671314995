import React from "react";
import "./Category.css";

function Category(props) {
    try{
    return (
        <div className="side-nav-categories">
            <ul id="category-tabs">
                <li><h6> Devices</h6>
                    <ul className="sub-category-tabs">
                        {props.devices.map((d, ind) => {
                            return <li key={ind+1}>
                                <input type={props.devicetype} name="devicecheckbox" onClick={props.selectDevice.bind(this, d.deviceId)}/>
                                <span href="javascript:void"> {d.deviceName}</span></li>
                        })}
                        
                    </ul>
                </li>
            </ul>
            <ul id="category-tabs">
                <li><h6> Properties</h6>
                    {(props.properties.length == 0) && <p>Click devices to view properties</p> }
                    <ul className="sub-category-tabs">
                    
                        {props.properties.map((d, ind) => {return <li key={ind+1}>
                            <input type={props.propertytype} name={props.propertytype} onClick={props.selectProperty.bind(this, d.property)}/>
                            {d.property}
                            </li>})}

                    </ul>
                </li>
            </ul>
        </div>
    )
                    }
                    catch(cerror){
                        return;
                        // toast.error("Something went wrong.", {
                        //   position: toast.POSITION.TOP_RIGHT
                        // });
                      }
}

export default Category;
