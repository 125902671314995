import React, { useEffect } from "react";
import { Button, styled } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import ViewOrders from "../BookingManagement/ViewOrders";
import TransportDocumentManagenment from "../../documentComponents/transportdocuments/TransportDocumentManagenment";
import SellerAccountManagement from "../../documentComponents/Invoicecomponents/selleraccountmanagement/SellerAccountManagement";
import BuyerAccounts from "../../../Main/documentComponents/Invoicecomponents/buyeraccountmanagement/BuyerAccounts";
import MainService from "../../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";

const StyledButton = styled(Button)`
  background-color: #2e7d32;
  color: white;
  margin-right:5px;
`;

const TransportFacilityService = () => {
  const [showBookings, setShowBookings] = useState(true);
  const [showTransportDocs, setShowTransportDocs] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [userTypeValue, setUserTypeValue] = useState("");

  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  const UserRole = localStorage.getItem("UserRole");

  const bookingHandler = () => {
    setShowBookings(true);

    setShowTransportDocs(false);
    setShowInvoice(false);
    setShowPayment(false);
  };

  const transportDocHandler = () => {
    setShowTransportDocs(true);
    setShowBookings(false);
    setShowInvoice(false);
    setShowPayment(false);
  };

  const invoiceHandler = () => {
    setShowPayment(false);
    setShowInvoice(true);
    setShowBookings(false);
    setShowTransportDocs(false);
  };

  const paymentHandler = () => {
    setShowPayment(true);
    setShowBookings(false);
    setShowInvoice(false);
    setShowTransportDocs(false);
  };

  useEffect(() => {
    getUserType();
  }, [userTypeValue]);

  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);

        console.log("USERTYPE", userTypeValue);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  return (
    <>
      {userTypeValue === "LogisticsProvider" && (
        <div className="topHeadersWrapper" style={{ margin: "2rem" }}>
          {(UserRole === "BASIC" ||
            UserRole === "STANDARD" ||
            UserRole === "PREFERRED" ||
            UserRole === "PREMIUM" ||
            UserRole === "ADMIN") && (
            <div className="topHeaders">
              <StyledButton
                color="success"
                size="large"
                variant="contained"
                onClick={bookingHandler}
              >
                BOOKING MANAGEMENT
              </StyledButton>
            </div>
          )}

          {UserRole === "ADMIN" && (
            <div className="topHeaders">
              <StyledButton
                color="success"
                size="large"
                variant="contained"
                onClick={transportDocHandler}
              >
                TRANSPORT DOCUMENTS
              </StyledButton>
            </div>
          )}

          {UserRole === "ADMIN" && (
            <div className="topHeaders">
              <StyledButton
                color="success"
                size="large"
                variant="contained"
                onClick={paymentHandler}
              >
                PAYMENT
              </StyledButton>
            </div>
          )}

          {UserRole === "ADMIN" && (
            <div className="topHeaders">
              <StyledButton
                color="success"
                size="large"
                variant="contained"
                onClick={invoiceHandler}
              >
                INVOICE
              </StyledButton>
            </div>
          )}
        </div>
      )}

      {showBookings && <ViewOrders />}
      {showTransportDocs && <TransportDocumentManagenment />}
      {showInvoice && <SellerAccountManagement />}
      {showPayment && <BuyerAccounts />}
    </>
  );
};

export default TransportFacilityService;
