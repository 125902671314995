import React from 'react';
import "./StepCards.css";
import step1 from "../../images/step1.png";


export default function StepCards({src,stepTitle,stepContent,stepContent2}) {
  return (
    <div className="section3Body">
         <img src={src} className="mb-2"/>
    <h4 className="container-heading2">
     
      <b> {stepTitle}</b>
    </h4>
    <p className="container-para">
        {stepContent}
    </p>
   {stepContent2 &&  <p className="container-para">
        {stepContent2}
    </p>}
  </div>
  )
}
