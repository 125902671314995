// import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { NotificationManager } from "react-notifications";
// import MainService from "../../../Common/MainService";
// import Fab from "@material-ui/core/Fab";
// import AddIcon from "@material-ui/icons/Add";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import { Link } from "react-router-dom";
// import {
//   Box,
//   Button,
//   InputLabel,
//   MenuItem,
//   FormControl,
//   Select,
//   useMediaQuery,
//   useTheme,
//   Divider,
//   Typography,
// } from "@mui/material";
// import ProductsRfqForm from "./ProductsRfqForm";
// import "../../Market/Market.css";
// import UpdateRfqStatus from "../UpdateRfqStatus";
// import Loading from "../../ModelProductManagement/Loading";
// const ContentSubCategoryCreate = lazy(() =>
//   import("../../ModelProductManagement/ContentSubCategoryCreate")
// );
// const DeleteSubCategoryOfContent = lazy(() =>
//   import("../../ModelProductManagement/DeleteSubCategoryOfContent")
// );
// const GenerateRFQ = lazy(() => import("../GenerateRFQ"));

// const useStyles = makeStyles((theme) => ({
//   fab: {
//     position: "fixed",
//     bottom: theme.spacing(5),
//     right: theme.spacing(5),
//     zIndex: 10,
//     color: "#fff",
//   },
// }));

// export default function ProductsRfqManagement({ categoryType }) {
//   const classes = useStyles();
//   const theme = useTheme();
//   const iconWidthRes = useMediaQuery(theme.breakpoints.between("xs", "sm"));
//   const [isLoading, setIsLoading] = useState(true);
//   const [verticalsIdList, setVerticalsIdList] = useState([]);
//   const [categoryTypesList, setCategoryTypesList] = useState([]);
//   const [assertTypesList, setAssertTypesList] = useState([]);
//   const [modelList, setModelsList] = useState([]);
//   const { user } = MainService.getLocalData();
//   const { username, parentId } = user;
//   /** Get Categorys */
//   const [getCategoriesList, setCategoriesList] = useState("");
//   const [getCategoriesNameList, setCategoriesNameList] = useState("");
//   const [getSubCategoriesList, setSubCategoriesList] = useState([]);
//   const [getSubCategoryForForm, setSubCategoryForForm] = useState("");
//   const [getCreateSubCategoryProps, setCreateSubCategoryProps] = useState([]);
//   const [getSubCategoryModel, setSubCategoryModel] = useState(false);
//   const [getDeleteSubCategoryModel, setDeleteSubCategoryModel] =
//     useState(false);
//   const [getContractManufacturing, setContractManufacturing] = useState(false);
//   const [getRfqData, setRfqData] = useState([]);
//   const [getReqVal, setReqVal] = useState("");
//   const [getreqArr, setReqArr] = useState([]);
//   const [selectedReqType, setSelectedReqType] = useState("");
//   const [rfqIdModel, setRfqIdModel] = useState(false);
//   const [selectedRfqId, setSelectedRfqId] = useState("");
//   const [selectedStatus, setSelectedStatus] = useState("");
//   const [generateRFQ, setGenerateRFQ] = useState({
//     model: false,
//     value: "",
//   });

//   useEffect(() => {
//     let reqStaticTypes = [
//       { val: "agri-buy-products", type: "agri-buy-products-rfq-req" },
//       { val: "cm-product", type: "cm-product-rfq-req" },
//       { val: "cf-agri-raw-products", type: "cf-agri-raw-products-rfq-req" },
//       { val: "transport-product", type: "transport-product-rfq-req" },
//       { val: "storage-product", type: "storage-product-rfq-req" },
//     ];
//     setReqVal(reqStaticTypes[0].type);
//     setSelectedReqType(reqStaticTypes[0].type);
//     setReqArr(reqStaticTypes);
//   }, []);

//   useEffect(() => {
//     if (!username || !getCategoriesList) return;
//     subCategoryList();
//   }, [username, getCategoriesList]);

//   const categorysList = () => {
//     MainService.getListOfCategorys()
//       .then((res) => {
//         const filterCategory = res.filter((data) => {
//           return data.categoryname.includes(categoryType);
//         });
//         setCreateSubCategoryProps(Object.assign({}, filterCategory[0]));
//         setCategoriesList(filterCategory[0]?.categoryid);
//         setCategoriesNameList(filterCategory[0]?.categoryname);
//       })
//       .catch((err) => {
//         return NotificationManager.error(err);
//       });
//   };

//   useMemo(() => {
//     if (!username) return;
//     categorysList();
//   }, []);

//   const subCategoryList = () => {
//     MainService.getCategoryRelatedSubCategories(username, getCategoriesList)
//       .then((res) => {
//         setSubCategoriesList(res);
//       })
//       .catch((err) => {
//         return NotificationManager.error(err || "something went wrong");
//       });
//   };

//   /** Create Sub-Category */
//   const createSubCategoryModel = () => {
//     setSubCategoryModel(!getSubCategoryModel);
//   };
//   const closeSubCategoryModel = () => {
//     setSubCategoryModel(!getSubCategoryModel);
//   };
//   const deleteSubCategoryModel = () => {
//     setDeleteSubCategoryModel(!getDeleteSubCategoryModel);
//   };
//   const closeDeleteSubCategoryModel = () => {
//     setDeleteSubCategoryModel(!getDeleteSubCategoryModel);
//   };
//   /** EOD Create Sub-Category */

//   /** Contract Manufacturing Model */
//   const contractManufacturingHandler = () => {
//     setContractManufacturing(!getContractManufacturing);
//   };
//   /** EOD Contract Manufacturing Model */

//   /* get all quote requests */
//   useEffect(() => {
//     if (getReqVal) {
//       quoteRequestHandler();
//     }
//   }, [getReqVal, categoryType, getSubCategoryForForm]);

//   const quoteRequestHandler = () => {
//     MainService.getQuotesBasedOnCategory(
//       getReqVal,
//       categoryType,
//       getSubCategoryForForm,
//       parentId,
//       username
//     )
//       .then((res) => {
//         setRfqData(res.data);
//       })
//       .catch((err) => {
//         return NotificationManager.error(
//           err?.message || "something went wrong"
//         );
//       })
//       .finally(() => {
//         setIsLoading(false);
//       });
//   };

//   const handleButtonClick = (type) => {
//     setReqVal(type);
//     setSelectedReqType(type);
//   };

//   const statusChangeHandler = () => {
//     setRfqIdModel(!rfqIdModel);
//   };

//   // Generate RFQ Model
//   const generateRFQModelHandler = (rfqId) => {
//     setGenerateRFQ({
//       value: rfqId,
//       model: !generateRFQ?.model,
//     });
//   };

//   return (
//     <>
//       <Box className="container" id="mainContentList" sx={{ width: "100%" }}>
//         <Fab
//           color="primary"
//           size={iconWidthRes ? "small" : "large"}
//           aria-label="add"
//           title="List"
//           className={classes.fab}
//           onClick={() => contractManufacturingHandler()}
//         >
//           <AddIcon></AddIcon>
//         </Fab>
//         <Box
//           sx={{
//             display: "flex",
//             flexWrap: "wrap",
//             gap: "10px",
//             margin: "5px",
//             alignItems: "left",
//             justifyContent: "left",
//           }}
//         >
//           <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
//             <Button
//               sx={{
//                 background: "#3ECF8E",
//                 margin: "2px",
//                 width: { xs: "100%", sm: "70%", md: "auto" },
//               }}
//               color="primary"
//               className="registerBtn"
//               variant="contained"
//               onClick={() => {
//                 deleteSubCategoryModel();
//               }}
//             >
//               Sub-Categories
//             </Button>
//           </Box>
//           <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
//             <FormControl
//               variant="outlined"
//               size="small"
//               className="postdropdown subCatbutton"
//             >
//               <InputLabel
//                 style={{
//                   color: "rgb(43, 144, 99)",
//                   fontSize: "15px",
//                 }}
//               >
//                 Sub-Category
//               </InputLabel>
//               <Select
//                 id="Sub-Category"
//                 value={getSubCategoryForForm}
//                 onChange={(event) => {
//                   setSubCategoryForForm(event.target.value);
//                 }}
//                 label="Sub-Category"
//               >
//                 {Array.isArray(getSubCategoriesList) &&
//                 getSubCategoriesList?.length > 0 ? (
//                   getSubCategoriesList &&
//                   getSubCategoriesList?.map((idObj, index) => (
//                     <MenuItem
//                       key={`${index} `}
//                       value={idObj.contentsubcategoryname}
//                     >
//                       {idObj.contentsubcategoryname}
//                     </MenuItem>
//                   ))
//                 ) : (
//                   <MenuItem disabled>No Sub Categories</MenuItem>
//                 )}
//               </Select>
//             </FormControl>
//             {(() => {
//               if (getSubCategoryForForm !== "") {
//                 return (
//                   <Button
//                     variant="none"
//                     onClick={() => {
//                       setSubCategoryForForm("");
//                     }}
//                   >
//                     Clear
//                   </Button>
//                 );
//               }
//             })()}
//           </Box>
//         </Box>
//         <Box
//           sx={{
//             display: "flex",
//             flexWrap: "wrap",
//             gap: "10px",
//             margin: "5px",
//             alignItems: "left",
//             justifyContent: "left",
//           }}
//         >
//           {getreqArr &&
//             getreqArr?.map((reqType) => (
//               <Box
//                 key={reqType.type}
//                 sx={{
//                   width: { xs: "100%", sm: "70%", md: "auto" },
//                 }}
//               >
//                 <Button
//                   variant="outlined"
//                   onClick={() => handleButtonClick(reqType.type)}
//                   sx={{
//                     backgroundColor:
//                       selectedReqType === reqType.type ? "#3ECF8E" : "inherit",
//                     color:
//                       selectedReqType === reqType.type ? "primary" : "inherit",
//                   }}
//                 >
//                   {reqType.val}
//                 </Button>
//               </Box>
//             ))}
//         </Box>
//         {isLoading ? (
//           <Box className="cardStyleForDashboardItems">
//             <CircularProgress />
//           </Box>
//         ) : (
//           <Box>
//             <Divider sx={{ width: "100%" }}>
//               <Typography sx={{ color: "#3ECF8E", padding: "5px 10px" }}>
//                 LIST OF RFQ'S
//               </Typography>
//             </Divider>
//             {Array.isArray(getRfqData) && getRfqData?.length > 0 ? (
//               <Box className="cardStyleForDashboardItems">
//                 {getRfqData &&
//                   getRfqData?.map((data, index) => (
//                     <Box key={index} className="cardStyleForDashboardItemsBody">
//                       <Box component="span">
//                         <p>
//                           <b>RFQ ID:</b>
//                           {data.rfqId}
//                         </p>
//                         <p>
//                           <b>Status:</b>
//                           {data.rfqStatus}
//                         </p>
//                         <p>
//                           <b>TimeStamp:</b>
//                           {data.timeStamp}
//                         </p>
//                       </Box>
//                       <Box>
//                         <Button
//                           sx={{ textTransform: "none", margin: "5px" }}
//                           variant="outlined"
//                         >
//                           <Link
//                             to={`/main/viewquotes/${data.rfqId}`}
//                             style={{ textDecoration: "none" }}
//                           >
//                             View Quotes
//                           </Link>
//                         </Button>
//                         {data.rfqStatus !== "close" && (
//                           <Button
//                             sx={{ textTransform: "none", margin: "5px" }}
//                             variant="outlined"
//                             onClick={() => {
//                               setSelectedStatus(data.rfqStatus);
//                               setSelectedRfqId(data.rfqId);
//                               statusChangeHandler();
//                             }}
//                           >
//                             Update Status
//                           </Button>
//                         )}
//                       </Box>
//                       <Box>
//                         {data.rfqStatus !== "close" && (
//                           <Button
//                             sx={{ textTransform: "none", margin: "5px" }}
//                             variant="outlined"
//                             onClick={() => {
//                               generateRFQModelHandler(data.rfqId);
//                             }}
//                           >
//                             Generate RFQ Doc
//                           </Button>
//                         )}
//                         <Button
//                           sx={{ textTransform: "none", margin: "5px" }}
//                           variant="outlined"
//                         >
//                           <Link
//                             to={`/main/viewrfqdoc/${data.rfqId}/rfq`}
//                             style={{ textDecoration: "none" }}
//                           >
//                             View RFQ Doc
//                           </Link>
//                         </Button>
//                       </Box>
//                     </Box>
//                   ))}
//               </Box>
//             ) : (
//               <h4 className="cardStyleForDashboardItems">
//                 No Request For Quote.
//               </h4>
//             )}
//           </Box>
//         )}
//         <ProductsRfqForm
//           openModel={getContractManufacturing}
//           closeModel={contractManufacturingHandler}
//           verticalsIds={verticalsIdList}
//           categoryTypes={categoryTypesList}
//           assertTypes={assertTypesList}
//           models={modelList}
//           categoryName={categoryType}
//           getSubCategories={getSubCategoriesList}
//           reLoad={quoteRequestHandler}
//         />

//         <UpdateRfqStatus
//           selectedStatus={selectedStatus}
//           selectedRfqId={selectedRfqId}
//           openModel={rfqIdModel}
//           closeModel={statusChangeHandler}
//           reLoad={quoteRequestHandler}
//           reqType="update-rfq-status"
//         />

//         <Suspense fallback={<Loading />}>
//           <ContentSubCategoryCreate
//             props={getCreateSubCategoryProps}
//             modelShow={getSubCategoryModel}
//             closeModel={closeSubCategoryModel}
//             reloadComponent={subCategoryList}
//           />
//         </Suspense>

//         <Suspense fallback={<Loading />}>
//           <DeleteSubCategoryOfContent
//             subCategories={getSubCategoriesList}
//             modelShow={getDeleteSubCategoryModel}
//             closeModel={closeDeleteSubCategoryModel}
//             reloadComponent={subCategoryList}
//             createSubCategoryModel={createSubCategoryModel}
//           />
//         </Suspense>

//         <Suspense fallback={<Loading />}>
//           <GenerateRFQ
//             openModel={generateRFQ?.model}
//             closeModel={generateRFQModelHandler}
//             rfqId={generateRFQ?.value}
//             componentName="rfq"
//           />
//         </Suspense>
//       </Box>
//     </>
//   );
// }
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, {
  Suspense,
  lazy,
  useEffect,
  useMemo,
  useState,
  memo,
} from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import MainService from "../../../Common/MainService";
import "../../Market/Market.css";
import Loading from "../../ModelProductManagement/Loading";
import UpdateRfqStatus from "../UpdateRfqStatus";
import ProductsRfqForm from "./ProductsRfqForm";
const ContentSubCategoryCreate = lazy(() =>
  import("../../ModelProductManagement/ContentSubCategoryCreate")
);
const DeleteSubCategoryOfContent = lazy(() =>
  import("../../ModelProductManagement/DeleteSubCategoryOfContent")
);
const GenerateRFQ = lazy(() => import("../GenerateRFQ"));

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

const ProductsRfqManagement = ({
  categoryType,
  requestTypes,
  reqTypesBasedOnRoleandUserType,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const iconWidthRes = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const [isLoading, setIsLoading] = useState(true);
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [modelList, setModelsList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  /** Get Categorys */
  const [getCategoriesList, setCategoriesList] = useState("");
  const [getCategoriesNameList, setCategoriesNameList] = useState("");
  const [getSubCategoriesList, setSubCategoriesList] = useState([]);
  const [getSubCategoryForForm, setSubCategoryForForm] = useState("");
  const [getCreateSubCategoryProps, setCreateSubCategoryProps] = useState([]);
  const [getSubCategoryModel, setSubCategoryModel] = useState(false);
  const [getDeleteSubCategoryModel, setDeleteSubCategoryModel] =
    useState(false);
  const [getContractManufacturing, setContractManufacturing] = useState(false);
  const [getRfqData, setRfqData] = useState([]);
  const [getReqVal, setReqVal] = useState("");
  const [getreqArr, setReqArr] = useState([]);
  const [selectedReqType, setSelectedReqType] = useState("");
  const [rfqIdModel, setRfqIdModel] = useState(false);
  const [selectedRfqId, setSelectedRfqId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [generateRFQ, setGenerateRFQ] = useState({
    model: false,
    value: "",
  });

  useEffect(() => {
    if (
      requestTypes === undefined ||
      requestTypes === null ||
      !Array.isArray(requestTypes) ||
      requestTypes.length === 0
    ) {
      let reqStaticTypes = [
        { val: "agri-buy-products", type: "agri-buy-products-rfq-req" },
        { val: "cm-product", type: "cm-product-rfq-req" },
        { val: "cf-agri-raw-products", type: "cf-agri-raw-products-rfq-req" },
        { val: "transport-product", type: "transport-product-rfq-req" },
        { val: "storage-product", type: "storage-product-rfq-req" },
      ];
      setReqVal(reqStaticTypes[0]?.type);
      setSelectedReqType(reqStaticTypes[0]?.type);
      setReqArr(reqStaticTypes);
    } else {
      setReqVal(requestTypes[0]?.type);
      setSelectedReqType(requestTypes[0]?.type);
      setReqArr(requestTypes);
    }
  }, [requestTypes]);

  useEffect(() => {
    if (!username || !getCategoriesList) return;
    subCategoryList();
  }, [username, getCategoriesList]);

  const categorysList = () => {
    MainService.getListOfCategorys()
      .then((res) => {
        const filterCategory = res.filter((data) => {
          return data.categoryname.includes(categoryType);
        });
        setCreateSubCategoryProps(Object.assign({}, filterCategory[0]));
        setCategoriesList(filterCategory[0]?.categoryid);
        setCategoriesNameList(filterCategory[0]?.categoryname);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  useMemo(() => {
    if (!username) return;
    categorysList();
  }, []);

  const subCategoryList = () => {
    MainService.getCategoryRelatedSubCategories(username, getCategoriesList)
      .then((res) => {
        setSubCategoriesList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
  };

  /** Create Sub-Category */
  const createSubCategoryModel = () => {
    setSubCategoryModel(!getSubCategoryModel);
  };
  const closeSubCategoryModel = () => {
    setSubCategoryModel(!getSubCategoryModel);
  };
  const deleteSubCategoryModel = () => {
    setDeleteSubCategoryModel(!getDeleteSubCategoryModel);
  };
  const closeDeleteSubCategoryModel = () => {
    setDeleteSubCategoryModel(!getDeleteSubCategoryModel);
  };
  /** EOD Create Sub-Category */

  /** Contract Manufacturing Model */
  const contractManufacturingHandler = () => {
    setContractManufacturing(!getContractManufacturing);
  };
  /** EOD Contract Manufacturing Model */

  /* get all quote requests */
  useEffect(() => {
    if (getReqVal) {
      quoteRequestHandler();
    }
  }, [getReqVal, categoryType, getSubCategoryForForm]);

  const quoteRequestHandler = () => {
    MainService.getQuotesBasedOnCategory(
      getReqVal,
      categoryType,
      getSubCategoryForForm,
      parentId,
      username
    )
      .then((res) => {
        setRfqData(res.data);
      })
      .catch((err) => {
        return NotificationManager.error(
          err?.message || "something went wrong"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleButtonClick = (type) => {
    setReqVal(type);
    setSelectedReqType(type);
  };

  const statusChangeHandler = () => {
    setRfqIdModel(!rfqIdModel);
  };

  // Generate RFQ Model
  const generateRFQModelHandler = (rfqId) => {
    setGenerateRFQ({
      value: rfqId,
      model: !generateRFQ?.model,
    });
  };

  return (
    <>
      <Box className="container" id="mainContentList" sx={{ width: "100%" }}>
        <Fab
          color="primary"
          size={iconWidthRes ? "small" : "large"}
          aria-label="add"
          title="List"
          className={classes.fab}
          onClick={() => contractManufacturingHandler()}
        >
          <AddIcon></AddIcon>
        </Fab>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            margin: "5px",
            alignItems: "left",
            justifyContent: "left",
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <Button
              sx={{
                background: "#3ECF8E",
                margin: "2px",
                width: { xs: "100%", sm: "70%", md: "auto" },
              }}
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                deleteSubCategoryModel();
              }}
            >
              Sub-Categories
            </Button>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Sub-Category
              </InputLabel>
              <Select
                id="Sub-Category"
                value={getSubCategoryForForm}
                onChange={(event) => {
                  setSubCategoryForForm(event.target.value);
                }}
                label="Sub-Category"
              >
                {Array.isArray(getSubCategoriesList) &&
                getSubCategoriesList?.length > 0 ? (
                  getSubCategoriesList &&
                  getSubCategoriesList?.map((idObj, index) => (
                    <MenuItem
                      key={`${index} `}
                      value={idObj.contentsubcategoryname}
                    >
                      {idObj.contentsubcategoryname}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Sub Categories</MenuItem>
                )}
              </Select>
            </FormControl>
            {(() => {
              if (getSubCategoryForForm !== "") {
                return (
                  <Button
                    variant="none"
                    onClick={() => {
                      setSubCategoryForForm("");
                    }}
                  >
                    Clear
                  </Button>
                );
              }
            })()}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            margin: "5px",
            alignItems: "left",
            justifyContent: "left",
          }}
        >
          {getreqArr &&
            getreqArr?.map((reqType) => (
              <Box
                key={reqType.type}
                sx={{
                  width: { xs: "100%", sm: "70%", md: "auto" },
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handleButtonClick(reqType.type)}
                  sx={{
                    backgroundColor:
                      selectedReqType === reqType.type ? "#3ECF8E" : "inherit",
                    color:
                      selectedReqType === reqType.type ? "primary" : "inherit",
                  }}
                >
                  {reqType.val}
                </Button>
              </Box>
            ))}
        </Box>
        {isLoading ? (
          <Box className="cardStyleForDashboardItems">
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {Array.isArray(getRfqData) && getRfqData?.length > 0 ? (
              <Box className="cardStyleForDashboardItems">
                {getRfqData &&
                  getRfqData?.map((data, index) => (
                    <Box key={index} className="cardStyleForDashboardItemsBody">
                      <Box component="span">
                        <p>
                          <b>RFQ ID:</b>
                          {data.rfqId}
                        </p>
                        <p>
                          <b>Status:</b>
                          {data.rfqStatus}
                        </p>
                        <p>
                          <b>TimeStamp:</b>
                          {data.timeStamp}
                        </p>
                      </Box>
                      <Box>
                        <Button
                          sx={{ textTransform: "none", margin: "5px" }}
                          variant="outlined"
                        >
                          <Link
                            to={`/main/viewquotes/${data.rfqId}`}
                            style={{ textDecoration: "none" }}
                          >
                            View Quotes
                          </Link>
                        </Button>
                        {data.rfqStatus !== "close" && (
                          <Button
                            sx={{ textTransform: "none", margin: "5px" }}
                            variant="outlined"
                            onClick={() => {
                              setSelectedStatus(data.rfqStatus);
                              setSelectedRfqId(data.rfqId);
                              statusChangeHandler();
                            }}
                          >
                            Update Status
                          </Button>
                        )}
                      </Box>
                      <Box>
                        {data.rfqStatus !== "close" && (
                          <Button
                            sx={{ textTransform: "none", margin: "5px" }}
                            variant="outlined"
                            onClick={() => {
                              generateRFQModelHandler(data.rfqId);
                            }}
                          >
                            Generate RFQ Doc
                          </Button>
                        )}
                        <Button
                          sx={{ textTransform: "none", margin: "5px" }}
                          variant="outlined"
                        >
                          <Link
                            to={`/main/viewrfqdoc/${data.rfqId}/rfq`}
                            style={{ textDecoration: "none" }}
                          >
                            View RFQ Doc
                          </Link>
                        </Button>
                      </Box>
                    </Box>
                  ))}
              </Box>
            ) : (
              <h4 className="cardStyleForDashboardItems">
                No Request For Quote.
              </h4>
            )}
          </Box>
        )}
        <ProductsRfqForm
          openModel={getContractManufacturing}
          closeModel={contractManufacturingHandler}
          verticalsIds={verticalsIdList}
          categoryTypes={categoryTypesList}
          assertTypes={assertTypesList}
          models={modelList}
          categoryName={categoryType}
          getSubCategories={getSubCategoriesList}
          reLoad={quoteRequestHandler}
          reqTypesBasedOnRoleandUser={reqTypesBasedOnRoleandUserType}
        />

        <UpdateRfqStatus
          selectedStatus={selectedStatus}
          selectedRfqId={selectedRfqId}
          openModel={rfqIdModel}
          closeModel={statusChangeHandler}
          reLoad={quoteRequestHandler}
          reqType="update-rfq-status"
        />

        <Suspense fallback={<Loading />}>
          <ContentSubCategoryCreate
            props={getCreateSubCategoryProps}
            modelShow={getSubCategoryModel}
            closeModel={closeSubCategoryModel}
            reloadComponent={subCategoryList}
          />
        </Suspense>

        <Suspense fallback={<Loading />}>
          <DeleteSubCategoryOfContent
            subCategories={getSubCategoriesList}
            modelShow={getDeleteSubCategoryModel}
            closeModel={closeDeleteSubCategoryModel}
            reloadComponent={subCategoryList}
            createSubCategoryModel={createSubCategoryModel}
          />
        </Suspense>

        <Suspense fallback={<Loading />}>
          <GenerateRFQ
            openModel={generateRFQ?.model}
            closeModel={generateRFQModelHandler}
            rfqId={generateRFQ?.value}
            componentName="rfq"
          />
        </Suspense>
      </Box>
    </>
  );
};
export default memo(ProductsRfqManagement);
