import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainService from "../../Common/MainService";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import "./GrowerFarmerComponent.css";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SearchBar from "material-ui-search-bar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@mui/material/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import sha256 from "sha256";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function GrowerFarmerComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [setListOpe, getListOpe] = useState(false);
  const [setGridOpe, getGridOpe] = useState(true);
  const [viewNode, setNode] = useState(false);
  const [viewGridNode, setGridNode] = useState(false);
  const [viewApproval, setApproval] = useState(false);
  const [viewGridApproval, setGridApproval] = useState(false);
  const [viewGrow, setGrow] = useState(false);
  const [viewGridGrow, setGridGrow] = useState(false);
  const [viewSell, setSell] = useState(false);
  const [viewGridSell, setGridSell] = useState(false);
  const [viewLoan, setLoan] = useState(false);
  const [viewGridLoan, setGridLoan] = useState(false);
  const [viewVetVisit, setVetVisit] = useState(false);
  const [viewGidVetVisit, setGridVetVisit] = useState(false);
  const [viewBuyInput, setBuyInput] = useState(false);
  const [viewGridBuyInput, setGridBuyInput] = useState(false);
  const [viewStore, setStore] = useState(false);
  const [viewGridStore, setGridStore] = useState(false);
  const [viewAdvisory, setAdvisory] = useState(false);
  const [viewGridAdvisory, setGridAdvisory] = useState(false);
  const [setUsers, getUsers] = useState([]);
  const [setApprovalReq, getApprovalReq] = useState([]);
  const [setGrowReq, getGrowReq] = useState([]);
  const [setSellReq, getSellReq] = useState([]);
  const [setLoanReq, getLoanReq] = useState([]);
  const [setVetVisitReq, getVetVisitReq] = useState([]);
  const [setBuyInputReq, getBuyInputReq] = useState([]);
  const [setStoreReq, getStoreReq] = useState([]);
  const [setAdvisoryReq, getAdvisoryReq] = useState([]);
  const [setNodeReq, getNodeReq] = useState([]);
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [viewNodeUpdate, setNodeUpdate] = useState(false);
  const [viewNodeInfo, setNodeInfo] = useState(false);
  const [setNodeName, getNodeName] = useState("");
  const [setNodeStatus, getNodeStatus] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [setDefaultNodeName, getDefaultNodeName] = useState("");
  /* Node Info Data */
  const [setInfoEnterpriceId, getInfoEnterpriceId] = useState("");
  const [setInfoUserName, getInfoUserName] = useState("");
  const [setInfoFname, getInfoFname] = useState("");
  const [setInfoLname, getInfoLname] = useState("");
  const [setInfoUserType, getInfoUserType] = useState("");
  const [setInfoEmail, getInfoEmail] = useState("");
  const [setInfoPhone, getInfoPhone] = useState("");
  const [setInfoAddress, getInfoAddress] = useState("");
  const [setInfoCity, getInfoCity] = useState("");
  const [setInfoState, getInfoState] = useState("");
  const [setInfoPan, getInfoPan] = useState("");
  const [setInfoDomain, getInfoDomain] = useState("");
  const [setInfoMessage, getInfoMessage] = useState("");
  const [setInfoNominee, getInfoNominee] = useState("");
  const [setInfoLatitude, getInfoLatitude] = useState("");
  const [setInfoLongitude, getInfoLongitude] = useState("");
  const [setInfoStatus, getInfoStatus] = useState("");
  /* End Of Node Info Data */
  /* Update Approval Status */
  const [setUpdateRequest, getUpdateRequest] = useState(false);
  const [setApprovalId, getApprovalId] = useState("");
  const [setApprovalPreStatus, getApprovalPreStatus] = useState("");
  const [setApprovalPostStatus, getApprovalPostStatus] = useState("");
  /* Update Grow Status */
  const [setGrowUpdateRequest, getGrowUpdateRequest] = useState(false);
  const [setGrowId, getGrowId] = useState("");
  const [setGrowPreStatus, getGrowPreStatus] = useState("");
  const [setGrowPostStatus, getGrowPostStatus] = useState("");
  /* Update Sell Status */
  const [setSellUpdateRequest, getSellUpdateRequest] = useState(false);
  const [setSellId, getSellId] = useState("");
  const [setSellPreStatus, getSellPreStatus] = useState("");
  const [setSellPostStatus, getSellPostStatus] = useState("");
  /* Update Loan Status */
  const [setLoanUpdateRequest, getLoanUpdateRequest] = useState(false);
  const [setLoanId, getLoanId] = useState("");
  const [setLoanPreStatus, getLoanPreStatus] = useState("");
  const [setLoanPostStatus, getLoanPostStatus] = useState("");
  /* Update Vet Visit Status */
  const [setVetUpdateRequest, getVetUpdateRequest] = useState(false);
  const [setVetId, getVetId] = useState("");
  const [setVetPreStatus, getVetPreStatus] = useState("");
  const [setVetPostStatus, getVetPostStatus] = useState("");
  /* Update Buy Input Status */
  const [setBuyUpdateRequest, getBuyUpdateRequest] = useState(false);
  const [setBuyId, getBuyId] = useState("");
  const [setBuyPreStatus, getBuyPreStatus] = useState("");
  const [setBuyPostStatus, getBuyPostStatus] = useState("");
  /* Update Store Status */
  const [setStoreUpdateRequest, getStoreUpdateRequest] = useState(false);
  const [setStoreId, getStoreId] = useState("");
  const [setStorePreStatus, getStorePreStatus] = useState("");
  const [setStorePostStatus, getStorePostStatus] = useState("");
  /* End Of Update Store Status */
  /*Creator Farmers */
  const [addDialog, setAddDialog] = useState(false);
  const [usernameValue, setUsernameValue] = useState("");
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [domainNameValue, setDomainNameValue] = useState("");
  const [userTypeValue, setUserTypeValue] = useState("farmer");
  const [email, setEmail] = useState("");
  const [phoneNumerValue, setPhoneNumerValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [cityNameValue, setCityNameValue] = useState("");
  const [stateNameValue, setStateNameValue] = useState("");
  const [nomineeValue, setNomineeValue] = useState("");
  const [pancardValue, setPancardValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  /*End Creator Farmers */
  /*Delete Farmers */
  const [consumerUserDeleteDialog, setConsumerUserDeleteDialog] =
    useState(false);
  const [selectedConsumerUserName, setSelectedConsumerUserName] = useState("");
  const [selectedConsumerUserId, setSelectedConsumerUserId] = useState("");
  /*End Of Delete Farmers */
  /*Farmers Password Reset*/
  const [consumerUserResetPasswordDialog, setconsumerUserResetPasswordDialog] =
    useState(false);
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [passwordConfirmationValue, setPasswordConfirmationValue] =
    useState("");
  /*End Of Farmers Password Reset*/
  /*Farmers Update*/
  const [consumerUserUpdateDialog, setConsumerUserUpdateDialog] =
    useState(false);
  const [consumerUserUserId, setConsumerUserUserId] = useState("");
  const [consumerUserUserName, setConsumerUserUserName] = useState("");
  const [consumerUserFirstName, setConsumerUserFirstName] = useState("");
  const [consumerUserLastName, setConsumerUserLastName] = useState("");
  const [consumerUserEmail, setConsumerUserEmail] = useState("");
  const [consumerUserPhone, setConsumerUserPhone] = useState("");
  const [consumerUserAddress, setConsumerUserAddress] = useState("");
  const [consumerUserCity, setConsumerUserCity] = useState("");
  const [consumerUserState, setConsumerUserState] = useState("");
  const [consumerUserNominee, setConsumerUserNominee] = useState("");
  const [consumerUserPancard, setConsumerUserPancard] = useState("");
  const [consumerUserLongitude, setConsumerUserLongitude] = useState("");
  const [consumerUserLatitude, setConsumerUserLatitude] = useState("");
  const [consumerUserMessage, setConsumerUserMessage] = useState("");
  const [consumerUserUserType, setConsumerUserUserType] = useState("");
  const [consumerUserUserDomainName, setConsumerUserUserDomainName] =
    useState("");
  /*End Of Farmers Update*/
  /*Update Node Status Of Consumer Farmers*/
  const [viewNodeStatusDialog, setViewNodeStatusDialog] = useState(false);
  const [selectedNodeNameValue, setSelectedNodeNameValue] = useState("");
  const [nodeStatusValue, setNodeStatusValue] = useState("");
  /*End Of Update Node Status Of Consumer Farmers*/
  /*Node Info Of Consumer Farmers*/
  const [consumerUserViewNodeInfoDialog, setConsumerUserViewNodeInfoDialog] =
    useState(false);
  const [nodeUserName, setNodeUserName] = useState("");
  const [nodeUserNodeId, setNodeUserNodeId] = useState("");
  const [nodeUserAccountType, setNodeUserAccountType] = useState("");
  const [nodeUserUserType, setNodeUserUserType] = useState("");
  const [nodeUserAssetType, setNodeUserAssetType] = useState("");
  const [nodeUserNumberOfAssets, setNodeUserNumberOfAssets] = useState("");
  const [nodeUserAreaSize, setNodeUserAreaSize] = useState("");
  const [nodeUserGrazingType, setNodeUserGrazingType] = useState("");
  const [nodeUserFeedType, setNodeUserFeedType] = useState("");
  const [nodeUserNumberOfKids, setNodeUserNumberOfKids] = useState("");
  const [nodeUserMaleGenderNumber, setNodeUserMaleGenderNumber] = useState("");
  const [nodeUserFemaleGenderNumber, setNodeUserFemaleGenderNumber] =
    useState("");
  const [nodeUserOverallAge, setNodeUserOverallAge] = useState("");
  const [nodeUserBreed, setNodeUserBreed] = useState("");
  const [nodeUserFarmingExperience, setNodeUserFarmingExperience] =
    useState("");
  const [nodeUserNodeStatus, setNodeUserNodeStatus] = useState("");
  const [nodeUserContentUrl, setNodeUserContentUrl] = useState("");
  const [nodeUserNodeType, setNodeUserNodeType] = useState("");
  const [nodeUserAssetverticalId, setNodeUserAssetverticalId] = useState("");
  const [
    nodeUserAssetverticalFriendlyName,
    setNodeUserAssetverticalFriendlyName,
  ] = useState("");
  const [nodeUserFirstName, setNodeUserFirstName] = useState("");
  const [nodeUserLastName, setNodeUserLastName] = useState("");
  const [nodeUserEmail, setNodeUserEmail] = useState("");
  const [nodeUserPhone, setNodeUserPhone] = useState("");
  const [nodeUserAddress, setNodeUserAddress] = useState("");
  const [nodeUserCity, setNodeUserCity] = useState("");
  const [nodeUserState, setNodeUserState] = useState("");
  const [nodeUserDistrict, setNodeUserDistrict] = useState("");
  const [nodeUserPincode, setNodeUserPincode] = useState("");
  const [nodeUserLatitude, setNodeUserLatitude] = useState("");
  const [nodeUserLongitude, setNodeUserLongitude] = useState("");
  const [nodeUserTimestamp, setNodeUserTimestamp] = useState("");
  /*End Of Node Info Of Consumer Farmers*/
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [getType, setType] = useState("");
  const [nodeId, setNodeId] = useState("");
  /* Add node */
  const [pincodeValue, setPincodeValue] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [breedValue, setBreedValue] = useState("");
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [selectedVerticalsName, setSelectedVerticalsName] = useState("");
  const [accountTypeValue, setAccountTypeValue] = useState("");
  const [grazingTypeValue, setGrazingTypeValue] = useState("");
  const [assetTypeValue, setAssetTypeValue] = useState("");
  const [feedTypeValue, setFeedTypeValue] = useState("");
  const [farmingExperienceValue, setFarmingExperienceValue] = useState("");
  const [overallAgeValue, setOverallAgeValue] = useState("");
  const [maleGenderNumberValue, setMaleGenderNumberValue] = useState("");
  const [femaleGenderNumberValue, setFemaleGenderNumberValue] = useState("");
  const [noOfKidsValue, setNoOfKidsValue] = useState("");
  const [areaSizeValue, setAreaSizeValue] = useState("");
  const [numOfAssetsValue, setNumOfAssetsValue] = useState("");
  const [nodeTypeValue, setNodeTypeValue] = useState("farm");
  /* Grow Request */
  const [addGrowRequestDialog, setAddGrowRequestDialog] = useState(false);
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [assetsList, setAssetsList] = useState([]);
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedGrowerDestination, setSelectedGrowerDestination] =
    useState("");
  const [selectedGrowerSource, setSelectedGrowerSource] = useState("");
  const [selectedGrowerReqId, setSelectedGrowerReqId] = useState("");
  const [selectedGrowerStatus, setSelectedGrowerStatus] = useState("");
  const [cancelGrowRequestDialog, setCancelGrowRequestDialog] = useState(false);
  const [addSellRequestDialog, setAddSellRequestDialog] = useState(false);
  const [cancelSellRequestDialog, setCancelSellRequestDialog] = useState(false);
  const [selectedSellSource, setSelectedSellSource] = useState("");
  const [selectedSellDestination, setSelectedSellDestination] = useState("");
  const [selectedSellStatus, setSelectedSellStatus] = useState("");
  const [selectedSellReqId, setSelectedSellReqId] = useState("");
  const [selectedLoanSource, setSelectedLoanSource] = useState("");
  const [selectedLoanDestination, setSelectedLoanDestination] = useState("");
  const [selectedLoanStatus, setSelectedLoanStatus] = useState("");
  const [selectedLoanReqId, setSelectedLoanReqId] = useState("");
  const [addLoanRequestDialog, setAddLoanRequestDialog] = useState(false);
  const [cancelLoanRequestDialog, setCancelLoanRequestDialog] = useState(false);

  const [selectedVetSource, setSelectedVetSource] = useState("");
  const [selectedVetDestination, setSelectedVetDestination] = useState("");
  const [selectedVetStatus, setSelectedVetStatus] = useState("");
  const [selectedVetReqId, setSelectedVetReqId] = useState("");
  const [addVetRequestDialog, setAddVetRequestDialog] = useState(false);
  const [cancelVetRequestDialog, setCancelVetRequestDialog] = useState(false);

  const [selectedBuyInputSource, setSelectedBuyInputSource] = useState("");
  const [selectedBuyInputDestination, setSelectedBuyInputDestination] =
    useState("");
  const [selectedBuyInputStatus, setSelectedBuyInputStatus] = useState("");
  const [selectedBuyInputReqId, setSelectedBuyInputReqId] = useState("");
  const [addBuyInputDialog, setAddBuyInputDialog] =
    useState(false);
  const [cancelBuyInputDialog, setCancelBuyInputDialog] =
    useState(false);

  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    getUsers(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    getUsers(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(() => {
    listOfFarmerNodes();
  }, [getUsers]);

  useEffect(() => {
    getAssetVerticalsIdList();
  });
  useEffect(() => {
    if (!selectedVerticalsId) return;
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  useEffect(() => {
    if (!selectedCategory) return;
    getAssetTypesList();
  }, [selectedVerticalsId,selectedCategory,]);

  useEffect(() => {
    if (!selectedAssertTypes) return;
    getModelsList();
  }, [selectedVerticalsId,selectedCategory,selectedAssertTypes]);

  /* Pagination */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* End Pagination */
  /* API Section */

  const listOfFarmerNodes = (Type) => {
    setType(Type);
    setIsLoading(true);
    if (Type === "List") {
      getListOpe(true);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(true);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }
    MainService.getFarmerNodes(username)
      .then((res) => {
        getUsers(res);
        getFilterUsers(res);
        setIsLoading(false);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const nodeHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(true);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(true);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }

    MainService.getNodeInfo(farmername)
      .then((res) => {
        getNodeReq(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const approvalRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(true);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(true);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }

    MainService.allApprovalRequests(farmername)
      .then((res) => {
        getApprovalReq(res.approvalrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const growRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(true);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(true);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allGrowRequests(farmername)
      .then((res) => {
        getGrowReq(res.growrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const sellRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(true);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(true);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allSellRequests(farmername)
      .then((res) => {
        getSellReq(res.sellrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const LoanRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(true);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(true);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allLoanRequests(farmername)
      .then((res) => {
        getLoanReq(res.loanrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const vetVisitRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(true);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(true);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allVetVisitRequests(farmername)
      .then((res) => {
        getVetVisitReq(res.vetvisitrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const buyInputRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(true);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(true);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allBuyInputRequests(farmername)
      .then((res) => {
        getBuyInputReq(res.buyinputrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const storeRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(true);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(true);
      setAdvisory(false);
      setGridAdvisory(false);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allStoreRequests(farmername)
      .then((res) => {
        getStoreReq(res.storerequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const advisoryRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(true);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(true);
    }
    setSelectedNodeNameValue(farmername);
    MainService.allAdvisoryRequests(farmername)
      .then((res) => {
        getAdvisoryReq(res.advisoryrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const nodeStatusHandler = (nodename) => {
    getNodeName(nodename);
    // setNode(false);
    setNodeUpdate(true);
  };

  const handleUpdateNodeClose = () => {
    // setNode(true);
    setNodeUpdate(false);
  };

  const updateNodeStatusHandler = () => {
    MainService.setNodeStatus(setNodeName, setNodeStatus)
      .then((res) => {
        setNodeUpdate(false);
        nodeHandler(setDefaultNodeName);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const nodeInfoHandler = (nodeuser) => {
    setNodeInfo(true);
    MainService.setNodeInfo(nodeuser)
      .then((res) => {
        getInfoEnterpriceId(res.requestInfo[0].enterpriseid);
        getInfoUserName(res.requestInfo[0].username);
        getInfoFname(res.requestInfo[0].firstname);
        getInfoLname(res.requestInfo[0].lastname);
        getInfoUserType(res.requestInfo[0].usertype);
        getInfoEmail(res.requestInfo[0].email);
        getInfoPhone(res.requestInfo[0].phone);
        getInfoAddress(res.requestInfo[0].address);
        getInfoCity(res.requestInfo[0].city);
        getInfoState(res.requestInfo[0].state);
        getInfoPan(res.requestInfo[0].pancard);
        getInfoDomain(res.requestInfo[0].domain);
        getInfoMessage(res.requestInfo[0].message);
        getInfoNominee(res.requestInfo[0].nominee);
        getInfoLatitude(res.requestInfo[0].latitude);
        getInfoLongitude(res.requestInfo[0].longitude);
        getInfoStatus(res.requestInfo[0].status);
      })
      .catch((err) => {
        return NotificationManager.error("err");
      });
  };

  const nodeInfoCloseHandler = () => {
    setNodeInfo(false);
  };

  const updateRequestHandler = () => {
    getUpdateRequest(false);
  };

  const updateApprovalRequest = (requestid, status) => {
    getApprovalId(requestid);
    getApprovalPreStatus(status);
    getUpdateRequest(true);
  };

  const approvalReqStatusHandler = () => {
    const approvalBody = {
      requestid: setApprovalId,
      existingApprovalRequestStatus: setApprovalPreStatus,
      updatedApprovalRequestStatus: setApprovalPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.approvalReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateGrowRequest = (requestid, status) => {
    getGrowId(requestid);
    getGrowPreStatus(status);
    getGrowUpdateRequest(true);
  };

  const updateGrowRequestHandler = () => {
    getGrowUpdateRequest(false);
  };

  const growReqStatusHandler = () => {
    const approvalBody = {
      requestid: setGrowId,
      existingGrowRequestStatus: setGrowPreStatus,
      updatedGrowRequestStatus: setGrowPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.growReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateSellRequest = (requestid, status) => {
    getSellId(requestid);
    getSellPreStatus(status);
    getSellUpdateRequest(true);
  };

  const updateSellRequestHandler = () => {
    getSellUpdateRequest(false);
  };

  const sellReqStatusHandler = () => {
    const approvalBody = {
      requestid: setSellId,
      existingSellRequestStatus: setSellPreStatus,
      updatedSellRequestStatus: setSellPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.sellReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateLoanRequest = (requestid, status) => {
    getLoanId(requestid);
    getLoanPreStatus(status);
    getLoanUpdateRequest(true);
  };

  const updateLoanRequestHandler = () => {
    getLoanUpdateRequest(false);
  };

  const loanReqStatusHandler = () => {
    const approvalBody = {
      requestid: setLoanId,
      existingLoanRequestStatus: setLoanPreStatus,
      updatedLoanRequestStatus: setLoanPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.LoanReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateVetRequest = (requestid, status) => {
    getVetId(requestid);
    getVetPreStatus(status);
    getVetUpdateRequest(true);
  };

  const updateVetRequestHandler = () => {
    getVetUpdateRequest(false);
  };

  const vetReqStatusHandler = () => {
    const approvalBody = {
      requestid: setVetId,
      existingLoanRequestStatus: setVetPreStatus,
      updatedLoanRequestStatus: setVetPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.VetReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateBuyRequest = (requestid, status) => {
    getBuyId(requestid);
    getBuyPreStatus(status);
    getBuyUpdateRequest(true);
  };

  const updateBuyRequestHandler = () => {
    getBuyUpdateRequest(false);
  };

  const buyReqStatusHandler = () => {
    const approvalBody = {
      requestid: setBuyId,
      existingBuyInputRequestStatus: setBuyPreStatus,
      updatedBuyInputRequestStatus: setBuyPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.BuyReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateStoreRequest = (requestid, status) => {
    getStoreId(requestid);
    getStorePreStatus(status);
    getStoreUpdateRequest(true);
  };

  const updateStoreRequestHandler = () => {
    getStoreUpdateRequest(false);
  };

  const storeReqStatusHandler = () => {
    const approvalBody = {
      requestid: setStoreId,
      existingStoreRequestStatus: setStorePreStatus,
      updatedStoreRequestStatus: setStorePostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.StoreReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(selectedVerticalsId.replace(" ", ""),window.AppConfigData.EnterpriseId)
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setSelectedCategory("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        window.AppConfigData.EnterpriseId
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
          } else {
            setSelectedAssertTypes("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  const getModelsList = () => {
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", ""),
        username
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedModel(data[0].modelid);
            setModelsList(data);
          } else {
            setSelectedModel("");

            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  /*************** Create, Update, Delete, NodeStatus, Node Info Farmers ***************/

  const deleteSubmitClick = () => {
    MainService.deleteConsumerUserIndividual(
      username,
      selectedConsumerUserName,
      selectedConsumerUserId
    )
      .then((res) => {
        listOfFarmerNodes(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
  };

  const deleteDialogClick = (consumerUserName, consumerUserId) => {
    setSelectedConsumerUserName(consumerUserName);
    setSelectedConsumerUserId(consumerUserId);

    setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
  };

  const resetPasswordDialogClick = (consumerUserId) => {
    setSelectedConsumerUserId(consumerUserId);
    setconsumerUserResetPasswordDialog(!consumerUserResetPasswordDialog);
  };

  const resetPasswordSubmit = () => {
    if (newPasswordValue === "") {
      return NotificationManager.error("please provide password");
    }
    if (newPasswordValue === passwordConfirmationValue) {
      let resetPwdData = {
        type: "password",
        value:sha256(newPasswordValue),
        temporary: false,
      };
      MainService.resetConsumerUserPasswordIndividual(
        resetPwdData,
        username,
        selectedConsumerUserId
      )
        .then((res) => {
          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
      setconsumerUserResetPasswordDialog(!consumerUserResetPasswordDialog);
    } else {
      return NotificationManager.error(
        " Confirmation Password is not match with New password"
      );
    }
  };

  const consumerUserUpdateDialogClick = (consumerUser) => {
    setSelectedConsumerUserName(consumerUser.username);
    setSelectedConsumerUserId(consumerUser.userid);

    setConsumerUserUserId(consumerUser.userid);
    setConsumerUserUserName(consumerUser.username);
    setConsumerUserFirstName(consumerUser.firstname);
    setConsumerUserLastName(consumerUser.lastname);
    setConsumerUserEmail(consumerUser.email);
    setConsumerUserPhone(consumerUser.phone);
    setConsumerUserAddress(consumerUser.address);
    setConsumerUserCity(consumerUser.city);
    setConsumerUserState(consumerUser.state);
    setConsumerUserNominee(consumerUser.nominee);
    setConsumerUserPancard(consumerUser.pancard);
    setConsumerUserLongitude(consumerUser.longitude);
    setConsumerUserLatitude(consumerUser.latitude);
    setConsumerUserMessage(consumerUser.message);
    setConsumerUserUserType(consumerUser.usertype);
    setConsumerUserUserDomainName(consumerUser.domain);

    setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
  };

  const consumerUserUpdateSubmit = () => {
    let consumerUserUpdateData = {
      firstname: consumerUserFirstName,
      lastname: consumerUserLastName,
      phone: consumerUserPhone,
      address: consumerUserAddress?.replace(/\n/g, ''),
      city: consumerUserCity,
      state: consumerUserState,
      nominee: consumerUserNominee,
      pancard: consumerUserPancard,
      longitude: consumerUserLongitude,
      latitude: consumerUserLatitude,
      message: consumerUserMessage?.replace(/\n/g, ''),
      email: consumerUserEmail,
    };
    const consumerUserUpdateDataString = JSON.stringify(consumerUserUpdateData);
    MainService.updateConsumerUserInfoIndividual(
      username,
      consumerUserUpdateDataString,
      selectedConsumerUserId,
      selectedConsumerUserName
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
  };

  const viewNodeStatusClick = (selectedNodeId) => {
    MainService.viewNodeStatus(selectedNodeId)
      .then((res) => {
        getNodeName(res.nodename);
        setNodeId(res.nodeid);
        getNodeStatus(res.nodestatus);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setViewNodeStatusDialog(!viewNodeStatusDialog);
  };

  const viewNodeInfoClick = (consumerUserName) => {
    MainService.getConsumerNodeInfo(consumerUserName)
      .then((res) => {
        setNodeUserName(res[0].username);
        setNodeUserNodeId(res[0].nodeid);
        setNodeUserAccountType(res[0].accounttype);
        setNodeUserUserType(res[0].usertype);
        setNodeUserAssetType(res[0].assettype);
        setNodeUserNumberOfAssets(res[0].numberofassets);
        setNodeUserAreaSize(res[0].areasize);
        setNodeUserGrazingType(res[0].grazingtype);
        setNodeUserFeedType(res[0].feedtype);
        setNodeUserNumberOfKids(res[0].numberofkids);
        setNodeUserMaleGenderNumber(res[0].malegendernumber);
        setNodeUserFemaleGenderNumber(res[0].femalegendernumber);
        setNodeUserOverallAge(res[0].overallage);
        setNodeUserBreed(res[0].breed);
        setNodeUserFarmingExperience(res[0].farmingexperience);
        setNodeUserNodeStatus(res[0].nodestatus);
        setNodeUserContentUrl(res[0].contenturl);
        setNodeUserNodeType(res[0].nodetype);
        setNodeUserAssetverticalId(res[0].assetverticalid);
        setNodeUserAssetverticalFriendlyName(res[0].assetverticalfriendlyname);
        setNodeUserFirstName(res[0].firstname);
        setNodeUserLastName(res[0].lastname);
        setNodeUserEmail(res[0].email);
        setNodeUserPhone(res[0].phone);
        setNodeUserAddress(res[0].address);
        setNodeUserCity(res[0].city);
        setNodeUserState(res[0].state);
        setNodeUserDistrict(res[0].district);
        setNodeUserPincode(res[0].pincode);
        setNodeUserLatitude(res[0].latitude);
        setNodeUserLongitude(res[0].longitude);
        setNodeUserTimestamp(res[0].timestamp);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserViewNodeInfoDialog(!consumerUserViewNodeInfoDialog);
  };
  /*************** End Of Create, Update, Delete, NodeStatus, NodeInfo Farmers ***************/
  /* End Of API Section */

  const gotoFarmerNodesClick = (viewType) => {
    if (viewType === "Grid") {
      setGridNode(false);
      setGridApproval(false);
      setGridAdvisory(false);
      setGridBuyInput(false);
      setGridGrow(false);
      setGridLoan(false);
      setGridSell(false);
      setGridStore(false);
      setGridVetVisit(false);
      if (
        viewGridNode === false ||
        viewGridAdvisory === false ||
        viewApproval === false ||
        viewGidVetVisit === false ||
        viewGridBuyInput === false ||
        viewGridGrow === false ||
        viewGridLoan === false ||
        viewGridSell === false ||
        viewGridStore === false
      ) {
        getGridOpe(true);
      }
    } else if (viewType === "List") {
      setNode(false);
      setApproval(false);
      setAdvisory(false);
      setBuyInput(false);
      setGrow(false);
      setLoan(false);
      setSell(false);
      setStore(false);
      setVetVisit(false);
      if (
        viewNode === false ||
        viewAdvisory === false ||
        viewApproval === false ||
        viewBuyInput === false ||
        viewGrow === false ||
        viewLoan === false ||
        viewSell === false ||
        viewStore === false ||
        viewVetVisit === false
      ) {
        getListOpe(true);
      }
    }
  };
  /* Add Node */
  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList()
      .then((data) => {
        if (data.length > 0) {
          setVerticalsIdList(data);
          setSelectedVerticalsId(data[0].assetVerticalId);
        } else {
          setIsLoading(false);
          setSelectedVerticalsId("");
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const selectedVeticalIdHandler = (event) => {
    const filteredVerticalName = verticalsIdList.filter(function (item) {
      return item.assetVerticalId === event.target.value;
    });
    setSelectedVerticalsName(filteredVerticalName[0].friendlyName);
    setSelectedVerticalsId(filteredVerticalName[0].assetVerticalId);
  };
  const addGrowRequestSubmit = () => {
    let addGrowRequestData = {
      source: selectedNodeNameValue,
      destination: username,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      phone: phoneNumerValue,
      email: selectedNodeNameValue,
      city: cityNameValue,
      state: stateNameValue,
      address: addressValue?.replace(/\n/g, ''),
      lat: latitudeValue,
      longi: longitudeValue,
      quantity: numOfAssetsValue,
    };
    const addGrowRequestDataString = JSON.stringify(addGrowRequestData);
    MainService.addGrowerRequest(addGrowRequestDataString)
      .then((res) => {
        listOfFarmerNodes(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddGrowRequestDialog(!addGrowRequestDialog);
  };
  const addSellRequestSubmit = () => {
    let addSellRequestData = {
      source: selectedNodeNameValue,
      destination: username,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      phone: phoneNumerValue,
      email: selectedNodeNameValue,
      city: cityNameValue,
      state: stateNameValue,
      address: addressValue?.replace(/\n/g, ''),
      lat: latitudeValue,
      longi: longitudeValue,
      quantity: numOfAssetsValue,
    };
    const addSellRequestDataString = JSON.stringify(addSellRequestData);
    MainService.addSellRequest(addSellRequestDataString)
      .then((res) => {
        listOfFarmerNodes(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddSellRequestDialog(!addSellRequestDialog);
  };
  const addLoanRequestSubmit = () => {
    let addLoanRequestData = {
      source: selectedNodeNameValue,
      destination: username,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      phone: phoneNumerValue,
      email: selectedNodeNameValue,
      city: cityNameValue,
      state: stateNameValue,
      address: addressValue?.replace(/\n/g, ''),
      lat: latitudeValue,
      longi: longitudeValue,
      quantity: numOfAssetsValue,
    };
    const addLoanRequestDataString = JSON.stringify(addLoanRequestData);
    MainService.addSellRequest(addLoanRequestDataString)
      .then((res) => {
        listOfFarmerNodes(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddLoanRequestDialog(!addLoanRequestDialog);
  };
  const addVetRequestSubmit = () => {
    let addVetRequestData = {
      source: selectedNodeNameValue,
      destination: username,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      phone: phoneNumerValue,
      email: selectedNodeNameValue,
      city: cityNameValue,
      state: stateNameValue,
      address: addressValue?.replace(/\n/g, ''),
      lat: latitudeValue,
      longi: longitudeValue,
      quantity: numOfAssetsValue,
    };
    const addVetRequestDataString = JSON.stringify(addVetRequestData);
    MainService.addVetRequest(addVetRequestDataString)
      .then((res) => {
        listOfFarmerNodes(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddVetRequestDialog(!addVetRequestDialog);
  };
  const addBuyInputSubmit = () => {
    let addBuyInputData = {
      source: selectedNodeNameValue,
      destination: username,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      phone: phoneNumerValue,
      email: selectedNodeNameValue,
      city: cityNameValue,
      state: stateNameValue,
      address: addressValue?.replace(/\n/g, ''),
      lat: latitudeValue,
      longi: longitudeValue,
      quantity: numOfAssetsValue,
    };
    const addBuyInputDataString = JSON.stringify(addBuyInputData);
    MainService.addVetRequest(addBuyInputDataString)
      .then((res) => {
        listOfFarmerNodes(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddBuyInputDialog(!addBuyInputDialog);
  };
  const cancelGrowRequestSubmit = () => {
    let cancelRequestData = {
      requestid: selectedGrowerReqId,
      existingRequestStatus: selectedGrowerStatus,
    };
    const cancelRequestDataString = JSON.stringify(cancelRequestData);
    MainService.cancelGrowRequest(
      selectedGrowerDestination,
      selectedGrowerSource,
      cancelRequestDataString
    )
      .then((res) => {
        listOfFarmerNodes(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const cancelSellRequestSubmit = () => {
    let cancelRequestData = {
      requestid: selectedSellReqId,
      existingRequestStatus: selectedSellStatus,
    };
    const cancelRequestDataString = JSON.stringify(cancelRequestData);
    MainService.cancelSellRequest(
      selectedSellDestination,
      selectedSellSource,
      cancelRequestDataString
    )
      .then((res) => {
        listOfFarmerNodes(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const cancelLoanRequestSubmit = () => {
    let cancelRequestData = {
      requestid: selectedLoanReqId,
      existingRequestStatus: selectedLoanStatus,
    };
    const cancelRequestDataString = JSON.stringify(cancelRequestData);
    MainService.cancelLoanRequest(
      selectedLoanDestination,
      selectedLoanSource,
      cancelRequestDataString
    )
      .then((res) => {
        listOfFarmerNodes(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const cancelVetRequestSubmit = () => {
    let cancelRequestData = {
      requestid: selectedVetReqId,
      existingRequestStatus: selectedVetStatus,
    };
    const cancelRequestDataString = JSON.stringify(cancelRequestData);
    MainService.cancelLoanRequest(
      selectedVetDestination,
      selectedVetSource,
      cancelRequestDataString
    )
      .then((res) => {
        listOfFarmerNodes(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const cancelBuyInputSubmit = () => {
    let cancelRequestData = {
      requestid: selectedBuyInputReqId,
      existingRequestStatus: selectedBuyInputStatus,
    };
    const cancelRequestDataString = JSON.stringify(cancelRequestData);
    MainService.cancelLoanRequest(
      selectedBuyInputDestination,
      selectedBuyInputSource,
      cancelRequestDataString
    )
      .then((res) => {
        listOfFarmerNodes(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const cancelGrowRequestDialogClick = (grower) => {
    setSelectedGrowerSource(grower.source);
    setSelectedGrowerDestination(grower.destination);
    setSelectedGrowerStatus(grower.status);
    setSelectedGrowerReqId(grower.requestid);

    setCancelGrowRequestDialog(!cancelGrowRequestDialog);
  };
  const cancelSellRequestDialogClick = (sell) => {
    setSelectedSellSource(sell.source);
    setSelectedSellDestination(sell.destination);
    setSelectedSellStatus(sell.status);
    setSelectedSellReqId(sell.requestid);

    setCancelGrowRequestDialog(!cancelGrowRequestDialog);
  };
  const cancelLoanRequestDialogClick = (sell) => {
    setSelectedLoanSource(sell.source);
    setSelectedLoanDestination(sell.destination);
    setSelectedLoanStatus(sell.status);
    setSelectedLoanReqId(sell.requestid);

    setCancelLoanRequestDialog(!cancelLoanRequestDialog);
  };
  const cancelVetRequestDialogClick = (sell) => {
    setSelectedVetSource(sell.source);
    setSelectedVetDestination(sell.destination);
    setSelectedVetStatus(sell.status);
    setSelectedVetReqId(sell.requestid);

    setCancelVetRequestDialog(!cancelVetRequestDialog);
  };
  const cancelBuyInputDialogClick = (sell) => {
    setSelectedBuyInputSource(sell.source);
    setSelectedBuyInputDestination(sell.destination);
    setSelectedBuyInputStatus(sell.status);
    setSelectedBuyInputReqId(sell.requestid);

    setCancelBuyInputDialog(!cancelBuyInputDialog);
  };
  const addNodeSubmit = () => {
    let addNodeData = {
      id: username,
      username: usernameValue,
      email: email,
      firstname: firstNameValue,
      lastname: lastNameValue,
      phone: phoneNumerValue,
      district: districtValue,
      pincode: pincodeValue,
      state: stateNameValue,
      city: cityNameValue,
      address: addressValue?.replace(/\n/g, ''),
      longitude: longitudeValue,
      latitude: latitudeValue,
      accounttype: accountTypeValue,
      usertype: userTypeValue,
      assettype: assetTypeValue,
      numberofassets: numOfAssetsValue,
      areasize: areaSizeValue,
      grazingtype: grazingTypeValue,
      feedtype: feedTypeValue,
      numberofkids: noOfKidsValue,
      malegendernumber: maleGenderNumberValue,
      femalegendernumber: femaleGenderNumberValue,
      overallage: overallAgeValue,
      farmingexperience: farmingExperienceValue,
      nodetype: nodeTypeValue,
      assetverticalid: selectedVerticalsId,
      assetverticalfriendlyname: selectedVerticalsName,
      breed: breedValue,
    };
    const addNodeDataString = JSON.stringify(addNodeData);
    MainService.addNodeToFarmer(addNodeDataString)
      .then((res) => {
        listOfFarmerNodes(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAddDialog(!addDialog);
  };

  /* End of add node */
  return (
    <React.Fragment>
      <div className="container" id="mainContentList">
        {(viewBuyInput === true || viewGridBuyInput === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Buy-Input Request"
            className={classes.fab}
            onClick={() => {
              setAddBuyInputDialog(!addBuyInputDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        {(viewVetVisit === true || viewGidVetVisit === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Vet Visit Request"
            className={classes.fab}
            onClick={() => {
              setAddVetRequestDialog(!addVetRequestDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        {(viewGridSell === true || viewSell === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Sell Request"
            className={classes.fab}
            onClick={() => {
              setAddSellRequestDialog(!addSellRequestDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        {(viewGridLoan === true || viewLoan === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Loan Request"
            className={classes.fab}
            onClick={() => {
              setAddLoanRequestDialog(!addLoanRequestDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        {(viewGrow === true || viewGridGrow === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Grow Request"
            className={classes.fab}
            onClick={() => {
              setAddGrowRequestDialog(!addGrowRequestDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}
        {(setListOpe === true || setGridOpe === true) && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Node"
            className={classes.fab}
            onClick={() => {
              setAddDialog(!addDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}

        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                listOfFarmerNodes("Grid");
              }}
            ></Button>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                listOfFarmerNodes("List");
              }}
            ></Button>
          </div>
        </div>
        {/* LIST OF Nodes */}
        {setListOpe === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
            {setUsers.length > 0 ? (
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 450 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={22}>
                          LIST OF FARMER NODES
                        </TableCell>
                      </TableRow>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          Node Id
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Node Name
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Email
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          Farm Details
                        </TableCell>
                        <TableCell align="center" colSpan={3}>
                          Farmer Operations
                        </TableCell>
                        <TableCell align="center" colSpan={10}>
                          Farmer Requests
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {setUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((user, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {user.nodeid}
                              </TableCell>
                              <TableCell align="center">
                                {user.username}
                              </TableCell>
                              <TableCell align="center">{user.email}</TableCell>
                              <TableCell />
                              {/* <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    nodeHandler(user.username, "List");
                                  }}
                                >
                                  View Node
                                </Button>
                              </TableCell> */}
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    viewNodeInfoClick(user.username);
                                  }}
                                >
                                  Node Info
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    viewNodeStatusClick(user.nodeid);
                                  }}
                                >
                                  Node Status
                                </Button>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    resetPasswordDialogClick(user.userid);
                                  }}
                                >
                                  Reset Password
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    consumerUserUpdateDialogClick(user);
                                  }}
                                >
                                  Update
                                </Button>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                              {/* <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    approvalRequestHandler(
                                      user.username,
                                      "List"
                                    );
                                  }}
                                >
                                  Approval Requests
                                </Button>
                              </TableCell> */}
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    growRequestHandler(user.username, "List");
                                  }}
                                >
                                  Grow Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    sellRequestHandler(user.username, "List");
                                  }}
                                >
                                  Sell Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    LoanRequestHandler(user.username, "List");
                                  }}
                                >
                                  Loan Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    vetVisitRequestHandler(
                                      user.username,
                                      "List"
                                    );
                                  }}
                                >
                                  VET Visit Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    buyInputRequestHandler(
                                      user.username,
                                      "List"
                                    );
                                  }}
                                >
                                  Buy Input Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    storeRequestHandler(user.username, "List");
                                  }}
                                >
                                  Store Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    advisoryRequestHandler(
                                      user.username,
                                      "List"
                                    );
                                  }}
                                >
                                  Advisory Requests
                                </Button>
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={setUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <h4>No Nodes Found</h4>
            )}
          </Box>
        ) : null}

        {isLoading ? (
          <div className="cardStyleForDashboardItems">
            <CircularProgress />
          </div>
        ) : (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            {setGridOpe === true ? (
              <Box sx={{ width: "100%", marginBottom: 30 }}>
                <SearchBar
                  value={farmgridsearched}
                  onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                  onCancelSearch={() => cancelFarmGridSearch()}
                />
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    LIST OF FARMER NODES
                  </h4>
                  {setUsers.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {setUsers.map((ope, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Node Id:</b>
                              {ope.nodeid}
                            </p>
                            <p>
                              <b>Node Name:</b>
                              {ope.username}
                            </p>
                            <p>
                              <b>Email:</b>
                              {ope.email}
                            </p>
                            <h4 className="cardactiontitle">Farm Details</h4>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  viewNodeInfoClick(ope.username);
                                }}
                              >
                                Node Info
                              </Button>
                              {/*  <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  nodeHandler(ope.username, "Grid");
                                }}
                              >
                                View Node
                              </Button> */}
                              &nbsp;&nbsp;
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  viewNodeStatusClick(ope.nodeid);
                                }}
                              >
                                Node Status
                              </Button>
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <h4 className="cardactiontitle">
                                Farmer Operations
                              </h4>
                              {/* <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  resetPasswordDialogClick(ope.userid);
                                }}
                              >
                                Reset Password
                              </Button> */}
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  consumerUserUpdateDialogClick(ope);
                                }}
                              >
                                Update
                              </Button>
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <h4 className="cardactiontitle">
                                Farmer Requests
                              </h4>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  growRequestHandler(ope.username, "Grid");
                                }}
                              >
                                Grow Requests
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  sellRequestHandler(ope.username, "Grid");
                                }}
                              >
                                Sell Requests
                              </Button>
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  LoanRequestHandler(ope.username, "Grid");
                                }}
                              >
                                Loan Requests
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  vetVisitRequestHandler(ope.username, "Grid");
                                }}
                              >
                                VET Visit Requests
                              </Button>
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  buyInputRequestHandler(ope.username, "Grid");
                                }}
                              >
                                Buy Input Requests
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  storeRequestHandler(ope.username, "Grid");
                                }}
                              >
                                Store Requests
                              </Button>
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  advisoryRequestHandler(ope.username, "Grid");
                                }}
                              >
                                Advisory Requests
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  advisoryRequestHandler(ope.username, "Grid");
                                }}
                              >
                                Market Price
                              </Button>
                            </div>

                            <div></div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>No Farmers Found.</h4>
                  )}
                </div>
              </Box>
            ) : null}
          </Box>
        )}
        {/* VIEW NODE LIST */}
        {viewNode === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Nodes
            </Button>
            <div className="MuiGrid-root">
              <h2>VIEW NODE LIST</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setNodeReq ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                USER NAME
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                TYPE OF ASSET
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                NUMBER OF ASSETS
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                AREA SIZE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                GRAZING TYPE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                FEED TYPE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                NUMBER OF KIDS
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                MALE GENDER NUMBER
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                FEMALE GENDER NUMBER
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                OVERALL AGE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                BREED
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                FARMING EXPERIENCE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                LATITUDE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                LONGITUDE
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                STATUS
                              </TableCell>
                              <TableCell align="center" colSpan={3}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setNodeReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((node, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {node.username}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.assettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.numberofassets}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.areasize}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.grazingtype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.feedtype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.numberofkids}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.malegendernumber}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.femalegendernumber}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.overallage}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.breed}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.farmingexperience}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.latitude}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.longitude}
                                    </TableCell>
                                    <TableCell align="center">
                                      {node.nodestatus}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          nodeStatusHandler(node.username);
                                        }}
                                      >
                                        Node Status
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          nodeInfoHandler(node.username);
                                        }}
                                      >
                                        Node Info
                                      </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        // onClick={() => {
                                        //   roleNameOfOperator(user.username)
                                        // }}
                                      >
                                        View Operators
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setNodeReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No Nodes Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridNode === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>VIEW NODE LIST</h4>
              </span>
              {setNodeReq ? (
                <div className="cardStyleForDashboardItems">
                  {setNodeReq.map((nodelist, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>USER NAME:</b>
                          {nodelist.username}
                        </p>
                        <p>
                          <b>TYPE OF ASSET:</b>
                          {nodelist.assettype}
                        </p>
                        <p>
                          <b>NUMBER OF ASSETS:</b>
                          {nodelist.numberofassets}
                        </p>
                        <p>
                          <b>AREA SIZE:</b>
                          {nodelist.areasize}
                        </p>
                        <p>
                          <b>GRAZING TYPE:</b>
                          {nodelist.grazingtype}
                        </p>
                        <p>
                          <b>FEED TYPE:</b>
                          {nodelist.feedtype}
                        </p>
                        <p>
                          <b>NUMBER OF KIDS:</b>
                          {nodelist.numberofkids}
                        </p>
                        <p>
                          <b>MALE GENDER NUMBER:</b>
                          {nodelist.malegendernumber}
                        </p>
                        <p>
                          <b>FEMALE GENDER NUMBER:</b>
                          {nodelist.femalegendernumber}
                        </p>
                        <p>
                          <b>OVERALL AGE:</b>
                          {nodelist.overallage}
                        </p>
                        <p>
                          <b>BREED:</b>
                          {nodelist.breed}
                        </p>
                        <p>
                          <b>FARMING EXPERIENCE:</b>
                          {nodelist.farmingexperience}
                        </p>
                        <p>
                          <b>LATITUDE:</b>
                          {nodelist.latitude}
                        </p>
                        <p>
                          <b>LONGITUDE:</b>
                          {nodelist.longitude}
                        </p>
                        <p>
                          <b>STATUS:</b>
                          {nodelist.nodestatus}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "90%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              nodeStatusHandler(nodelist.username);
                            }}
                          >
                            Node Status
                          </Button>
                        </div>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "90%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              nodeInfoHandler(nodelist.username);
                            }}
                          >
                            Node Info
                          </Button>
                        </div>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "90%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              // storeRequestHandler(nodelist.username);
                            }}
                          >
                            View Operators
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Node List Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL APPROVAL REQUESTS */}
        {viewApproval === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL APPROVAL REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setApprovalReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setApprovalReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((approval, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {approval.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {approval.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {approval.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {approval.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {approval.timestamp}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          updateApprovalRequest(
                                            approval.requestid,
                                            approval.status
                                          );
                                        }}
                                      >
                                        Update Approval Request Status
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setApprovalReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No APPROVAL REQUESTS Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridApproval === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL APPROVAL REQUESTS</h4>
              </p>
              {setApprovalReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setApprovalReq.map((approval, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {approval.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {approval.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {approval.requestid}
                        </p>
                        <p>
                          <b>Status:</b>
                          {approval.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {approval.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "50%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              updateApprovalRequest(
                                approval.requestid,
                                approval.status
                              );
                            }}
                          >
                            Update Approval Request Status
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Approval Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL GROW REQUESTS */}
        {viewGrow === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL GROW REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setGrowReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Quantity
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setGrowReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((grow, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {grow.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.quantity}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.timestamp}
                                    </TableCell>
                                    {grow.status === "new-grow-request" && (
                                      <TableCell align="center">
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelGrowRequestDialogClick(grow);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setGrowReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No GROW REQUESTS Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridGrow === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL GROW REQUESTS</h4>
              </p>
              {setGrowReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setGrowReq.map((grow, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {grow.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {grow.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {grow.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {grow.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {grow.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {grow.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {grow.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {grow.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {grow.email}
                        </p>
                        <p>
                          <b>Quantity:</b>
                          {grow.quantity}
                        </p>
                        <p>
                          <b>Status:</b>
                          {grow.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {grow.timestamp}
                        </p>
                        {grow.status === "new-grow-request" && (
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ margin: "5px", width: "50%" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                cancelGrowRequestDialogClick(grow);
                              }}
                            >
                              cancel
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Grow Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL SELL REQUESTS */}
        {viewSell === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL SELL REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setSellReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Quantity
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Total Selling Amount
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setSellReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((sell, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {sell.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.quantity}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.totalsellingamount}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.timestamp}
                                    </TableCell>
                                    {sell.status === "new-sell-request" && (
                                      <TableCell align="center">
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelSellRequestDialogClick(sell);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setSellReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No SELL REQUESTS Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridSell === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL SELL REQUESTS</h4>
              </p>
              {setSellReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setSellReq.map((sell, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {sell.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {sell.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {sell.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {sell.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {sell.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {sell.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {sell.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {sell.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {sell.email}
                        </p>
                        <p>
                          <b>Quantity:</b>
                          {sell.quantity}
                        </p>
                        <p>
                          <b>Total Selling Amount:</b>
                          {sell.totalsellingamount}
                        </p>
                        <p>
                          <b>Status:</b>
                          {sell.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {sell.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          {sell.status === "new-sell-request" && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                cancelSellRequestDialogClick(sell);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Sell Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL LOAN REQUESTS */}
        {viewLoan === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL LOAN REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setLoanReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setLoanReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((loan, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {loan.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.timestamp}
                                    </TableCell>
                                    {loan.status === "new-loan-request" && (
                                      <TableCell align="center">
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelLoanRequestDialogClick(loan);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setLoanReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No LOAN REQUESTS Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridLoan === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL LOAN REQUESTS</h4>
              </p>
              {setLoanReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setLoanReq.map((loan, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {loan.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {loan.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {loan.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {loan.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {loan.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {loan.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {loan.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {loan.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {loan.email}
                        </p>
                        <p>
                          <b>Status:</b>
                          {loan.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {loan.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          {loan.status === "new-loan-request" && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                cancelLoanRequestDialogClick(loan);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Loan Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL VET VISIT REQUESTS */}
        {viewVetVisit === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL VET VISIT REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setVetVisitReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setVetVisitReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((vet, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {vet.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.timestamp}
                                    </TableCell>
                                    {vet.status === "new-vetvisit-request" && (
                                      <TableCell align="center">
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelVetRequestDialogClick(vet);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setVetVisitReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No VET VISIT Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGidVetVisit === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL VET VISIT REQUESTS</h4>
              </p>
              {setVetVisitReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setVetVisitReq.map((vetvisit, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {vetvisit.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {vetvisit.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {vetvisit.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {vetvisit.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {vetvisit.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {vetvisit.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {vetvisit.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {vetvisit.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {vetvisit.email}
                        </p>
                        <p>
                          <b>Status:</b>
                          {vetvisit.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {vetvisit.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          {vetvisit.status === "new-vetvisit-request" && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                cancelVetRequestDialogClick(vetvisit);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Vet Visit Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL BUY INPUT REQUESTS */}
        {viewBuyInput === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL BUY INPUT REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setBuyInputReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Growering Input
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setBuyInputReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((buyinput, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {buyinput.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.groweringinput}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.timestamp}
                                    </TableCell>
                                    {buyinput.status ===
                                      "new-buyinput-request" && (
                                      <TableCell align="center">
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelBuyInputDialogClick(
                                              buyinput
                                            );
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setBuyInputReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No BUY INPUT Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridBuyInput === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL BUY INPUT REQUESTS</h4>
              </span>
              {setBuyInputReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setBuyInputReq.map((buyinput, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {buyinput.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {buyinput.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {buyinput.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {buyinput.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {buyinput.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {buyinput.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {buyinput.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {buyinput.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {buyinput.email}
                        </p>
                        <p>
                          <b>Growering Input:</b>
                          {buyinput.groweringinput}
                        </p>
                        <p>
                          <b>Status:</b>
                          {buyinput.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {buyinput.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          {buyinput.status === "new-buyinput-request" && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                cancelBuyInputDialogClick(buyinput);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Buy Input Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL STORE REQUESTS */}
        {viewStore === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL STORE REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setStoreReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Quantity
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setStoreReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((store, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {store.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.quantity}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.timestamp}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          updateStoreRequest(
                                            store.requestid,
                                            store.status
                                          );
                                        }}
                                      >
                                        Update Store Request Status
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setStoreReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No Store Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridStore === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL STORE REQUESTS</h4>
              </span>
              {setStoreReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setStoreReq.map((store, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {store.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {store.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {store.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {store.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {store.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {store.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {store.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {store.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {store.email}
                        </p>
                        <p>
                          <b>Quantity:</b>
                          {store.quantity}
                        </p>
                        <p>
                          <b>Status:</b>
                          {store.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {store.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "50%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              updateStoreRequest(store.requestid, store.status);
                            }}
                          >
                            Update Store Request Status
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Store Request Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL ADVISORY REQUESTS */}
        {viewAdvisory === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL ADVISORY REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setAdvisoryReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Advisory Input
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setAdvisoryReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((advisory, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {advisory.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.advisoryinputs}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.timestamp}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setAdvisoryReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No ADVISORY Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridAdvisory === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmerNodesClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL ADVISORY REQUESTS</h4>
              </span>
              {setAdvisoryReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setAdvisoryReq.map((advisory, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {advisory.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {advisory.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {advisory.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {advisory.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {advisory.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {advisory.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {advisory.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {advisory.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {advisory.email}
                        </p>
                        <p>
                          <b>Advisory Input:</b>
                          {advisory.advisoryinputs}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {advisory.timestamp}
                        </p>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Advisory Request Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* NODE STATUS */}
        <Dialog
          fullScreen={fullScreen}
          open={viewNodeUpdate}
          onClose={handleUpdateNodeClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Node Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nodename">Node Name:</InputLabel>
              <Input
                id="nodename"
                name="nodename"
                placeholder="Node Name"
                type="text"
                value={setNodeName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Node Status</InputLabel>
              <Select
                id="status"
                value={setNodeStatus}
                onChange={(e) => {
                  getNodeStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem value="" selected disabled>
                  {" "}
                  Select Any Node Status
                </MenuItem>
                <MenuItem
                  key="authenticationpending"
                  value="authentication-pending"
                >
                  {" "}
                  authentication-pending{" "}
                </MenuItem>
                <MenuItem key="authenticated" value="authenticated">
                  {" "}
                  authenticated{" "}
                </MenuItem>
                <MenuItem key="approved" value="approved">
                  {" "}
                  approved{" "}
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={updateNodeStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={handleUpdateNodeClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* NODE INFO */}
        <Dialog
          fullScreen={fullScreen}
          open={viewNodeInfo}
          onClose={nodeInfoCloseHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Node Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pid">Enterprice Id:</InputLabel>
              <Input
                id="pid"
                name="pid"
                placeholder="Enterprice Id"
                type="text"
                value={setInfoEnterpriceId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="uname">User Name:</InputLabel>
              <Input
                id="uname"
                name="uname"
                placeholder="User Name"
                type="text"
                value={setInfoUserName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="fname">First Name:</InputLabel>
              <Input
                id="fname"
                name="fname"
                placeholder="First Name"
                type="text"
                value={setInfoFname}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lname">Last Name:</InputLabel>
              <Input
                id="lname"
                name="lname"
                placeholder="Last Name"
                type="text"
                value={setInfoLname}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="usertype">User Type:</InputLabel>
              <Input
                id="usertype"
                name="usertype"
                placeholder="User Type"
                type="text"
                value={setInfoUserType}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="eid">Email Id:</InputLabel>
              <Input
                id="eid"
                name="eid"
                placeholder="Email Id"
                type="text"
                value={setInfoEmail}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="phone">Phone:</InputLabel>
              <Input
                id="phone"
                name="phone"
                placeholder="Phone"
                type="text"
                value={setInfoPhone}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="address">Address:</InputLabel>
              <Input
                id="address"
                name="address"
                placeholder="Address"
                type="text"
                value={setInfoAddress}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City:</InputLabel>
              <Input
                id="city"
                name="city"
                placeholder="City"
                type="text"
                value={setInfoCity}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State:</InputLabel>
              <Input
                id="state"
                name="state"
                placeholder="State"
                type="text"
                value={setInfoState}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pan">Pan Card Number:</InputLabel>
              <Input
                id="pan"
                name="pan"
                placeholder="Pan Card Number"
                type="text"
                value={setInfoPan}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="domain">Domain:</InputLabel>
              <Input
                id="domain"
                name="domain"
                placeholder="Domain"
                type="text"
                value={setInfoDomain}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Nominee:</InputLabel>
              <Input
                id="nominee"
                name="nominee"
                placeholder="Nominee"
                type="text"
                value={setInfoNominee}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="message">Message:</InputLabel>
              <Input
                id="message"
                name="message"
                placeholder="Message"
                type="text"
                value={setInfoMessage}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude:</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                placeholder="Longitude"
                type="text"
                value={setInfoLongitude}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude:</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                placeholder="Latitude"
                type="text"
                value={setInfoLatitude}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Node Status:</InputLabel>
              <Input
                id="status"
                name="status"
                placeholder="Node Status"
                type="text"
                value={setInfoStatus}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={nodeInfoCloseHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Approval Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setUpdateRequest}
          onClose={updateRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Approval Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setApprovalId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setApprovalPostStatus}
                onChange={(e) => {
                  getApprovalPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem>
                  <MenuItem key="in-progress" value="in-progress"> in-progress </MenuItem> */}
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                {/* <MenuItem key="accepted" value="accepted"> accepted </MenuItem>
                  <MenuItem key="rejected" value="rejected"> rejected </MenuItem>
                  <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={approvalReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Grow Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setGrowUpdateRequest}
          onClose={updateGrowRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Grow Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setGrowId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setGrowPostStatus}
                onChange={(e) => {
                  getGrowPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={growReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateGrowRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Sell Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setSellUpdateRequest}
          onClose={updateSellRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Sell Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setSellId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setSellPostStatus}
                onChange={(e) => {
                  getSellPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={sellReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateSellRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Loan Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setLoanUpdateRequest}
          onClose={updateLoanRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Loan Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setLoanId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setLoanPostStatus}
                onChange={(e) => {
                  getLoanPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={loanReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateLoanRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Vet Visit Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setVetUpdateRequest}
          onClose={updateVetRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Vet Visit Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setVetId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setVetPostStatus}
                onChange={(e) => {
                  getVetPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={vetReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateVetRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Buy Input Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setBuyUpdateRequest}
          onClose={updateBuyRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Buy Input Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setBuyId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setBuyPostStatus}
                onChange={(e) => {
                  getBuyPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={buyReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateBuyRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Store Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setStoreUpdateRequest}
          onClose={updateStoreRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Store Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setStoreId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setStorePostStatus}
                onChange={(e) => {
                  getStorePostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={storeReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateStoreRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add Node */}
        <Dialog
          fullScreen={fullScreen}
          open={addDialog}
          // onClose={handleClose}
          aria-labelledby="addNode"
        >
          <DialogTitle id="addNode">{"Create Node"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">Id :</InputLabel>
              <Input
                id="username"
                name="username"
                type="email"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">User Name :</InputLabel>
              <Input
                id="username"
                name="username"
                type="email"
                value={usernameValue}
                onChange={(e) => {
                  setUsernameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstName">First Name :</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                value={firstNameValue}
                onChange={(e) => {
                  setFirstNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastName">Last Name :</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                value={lastNameValue}
                onChange={(e) => {
                  setLastNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="numOfAssets">Number of Assets:</InputLabel>
              <Input
                id="numOfAssets"
                name="numOfAssets"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="areaSize">Area Size(in sqmt):</InputLabel>
              <Input
                id="areaSize"
                name="areaSize"
                type="text"
                value={areaSizeValue}
                onChange={(e) => {
                  setAreaSizeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="noOfKids">Number of Kids:</InputLabel>
              <Input
                id="noOfKids"
                name="noOfKids"
                type="text"
                value={noOfKidsValue}
                onChange={(e) => {
                  setNoOfKidsValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="maleNumber">Male Gender Number:</InputLabel>
              <Input
                id="maleNumber"
                name="maleNumber"
                type="text"
                value={maleGenderNumberValue}
                onChange={(e) => {
                  setMaleGenderNumberValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="femaleNumber">
                Female Gender Number:
              </InputLabel>
              <Input
                id="femaleNumber"
                name="femaleNumber"
                type="text"
                value={femaleGenderNumberValue}
                onChange={(e) => {
                  setFemaleGenderNumberValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="overallAge">
                Overall Age(in months):
              </InputLabel>
              <Input
                id="overallAge"
                name="overallAge"
                type="text"
                value={overallAgeValue}
                onChange={(e) => {
                  setOverallAgeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="farmingexperience">
                Farming Experience:
              </InputLabel>
              <Input
                id="farmingexperience"
                name="farmingexperience"
                type="text"
                value={farmingExperienceValue}
                onChange={(e) => {
                  setFarmingExperienceValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="userType">User Type</InputLabel>
              <Select id="userType" name="userType" value={userTypeValue}>
                <MenuItem value={userTypeValue}>Farmer</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="nodeType">Node Type</InputLabel>
              <Select
                id="nodeType"
                name="nodeType"
                value={nodeTypeValue}
                /* onChange={(e) => {
                  setUserTypeValue(e.target.value);
                }} */
              >
                <MenuItem value={nodeTypeValue}>Farm</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="feedType">Feed Type</InputLabel>
              <Select
                id="feedType"
                name="feedType"
                value={feedTypeValue}
                onChange={(e) => {
                  setFeedTypeValue(e.target.value);
                }}
              >
                <MenuItem value="Pasture">Pasture</MenuItem>
                <MenuItem value="Hay">Hay</MenuItem>
                <MenuItem value="Silage">Silage</MenuItem>
                <MenuItem value="RootCrops">RootCrops</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="assetType">Asset Type</InputLabel>
              <Select
                id="assetType"
                name="assetType"
                value={assetTypeValue}
                onChange={(e) => {
                  setAssetTypeValue(e.target.value);
                }}
              >
                <MenuItem value="sheep">Sheep</MenuItem>
                <MenuItem value="goat">Goat</MenuItem>
                <MenuItem value="cow">Cow</MenuItem>
                <MenuItem value="buffalo">Buffalo</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="grazingType">Grazing Type</InputLabel>
              <Select
                id="grazingType"
                name="grazingType"
                value={grazingTypeValue}
                onChange={(e) => {
                  setGrazingTypeValue(e.target.value);
                }}
              >
                <MenuItem value="opengrazing">Open-Grazing</MenuItem>
                <MenuItem value="closedgrazing">Closed-Grazing</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="userType">Account Type</InputLabel>
              <Select
                id="accountType"
                name="accountType"
                value={accountTypeValue}
                onChange={(e) => {
                  setAccountTypeValue(e.target.value);
                }}
              >
                <MenuItem value="Individual">Individual</MenuItem>
                <MenuItem value="Family">Family</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  selectedVeticalIdHandler(event);
                }}
                label="Select Verticals"
              >
                <MenuItem>Choose Asset Vertical Id</MenuItem>
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="breed">Breed :</InputLabel>
              <Input
                id="breed"
                name="breed"
                type="text"
                value={breedValue}
                onChange={(e) => {
                  setBreedValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">District :</InputLabel>
              <Input
                id="district"
                name="district"
                type="text"
                value={districtValue}
                onChange={(e) => {
                  setDistrictValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Pincode :</InputLabel>
              <Input
                id="pincode"
                name="pincode"
                type="text"
                value={pincodeValue}
                onChange={(e) => {
                  setPincodeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pancard">Pancard Number :</InputLabel>
              <Input
                id="pancard"
                name="pancard"
                type="text"
                value={pancardValue}
                onChange={(e) => {
                  setPancardValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addNodeSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddDialog(!addDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add Grow Request */}
        <Dialog
          fullScreen={fullScreen}
          open={addGrowRequestDialog}
          aria-labelledby="addGrowRequst"
        >
          <DialogTitle id="addGrowRequest">{"Add Grow Request"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="source">Source(Node Name) :</InputLabel>
              <Input
                disabled
                id="source"
                name="source"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">
                {" "}
                Destination (User Name) :
              </InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsId(event.target.value);
                }}
                label="Select Verticals"
              >
                <MenuItem>Choose Asset Vertical</MenuItem>
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="quantity">Quantity :</InputLabel>
              <Input
                id="quantity"
                name="quantity"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addGrowRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddGrowRequestDialog(!addGrowRequestDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancel Grower Request  */}
        <Dialog
          fullScreen={fullScreen}
          open={cancelGrowRequestDialog}
          aria-labelledby="cancelGrowerRequest"
        >
          <DialogTitle id="cancelGrowerRequest">
            {"Cancel Grower Request"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to Cancel it?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                cancelGrowRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCancelGrowRequestDialog(!cancelGrowRequestDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add Sell Request */}
        <Dialog
          fullScreen={fullScreen}
          open={addSellRequestDialog}
          aria-labelledby="addSellRequst"
        >
          <DialogTitle id="addSellRequst">{"Add Sell Request"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="source">Source(Node Name) :</InputLabel>
              <Input
                disabled
                id="source"
                name="source"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">
                {" "}
                Destination (User Name) :
              </InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsId(event.target.value);
                }}
                label="Select Verticals"
              >
                <MenuItem>Choose Asset Vertical</MenuItem>
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="quantity">Quantity :</InputLabel>
              <Input
                id="quantity"
                name="quantity"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addSellRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddSellRequestDialog(!addSellRequestDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancel Sell Request  */}
        <Dialog
          fullScreen={fullScreen}
          open={cancelSellRequestDialog}
          aria-labelledby="cancelSellRequest"
        >
          <DialogTitle id="cancelSellRequest">
            {"Cancel Sell Request"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to Cancel it?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                cancelSellRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCancelSellRequestDialog(!cancelSellRequestDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add Loan Request */}
        <Dialog
          fullScreen={fullScreen}
          open={addLoanRequestDialog}
          aria-labelledby="addLoanRequst"
        >
          <DialogTitle id="addLoanRequest">{"Add Loan Request"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="source">Source(Node Name) :</InputLabel>
              <Input
                disabled
                id="source"
                name="source"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">
                {" "}
                Destination (User Name) :
              </InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsId(event.target.value);
                }}
                label="Select Verticals"
              >
                <MenuItem>Choose Asset Vertical</MenuItem>
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="quantity">Quantity :</InputLabel>
              <Input
                id="quantity"
                name="quantity"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addLoanRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddLoanRequestDialog(!addLoanRequestDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancel Loan Request  */}
        <Dialog
          fullScreen={fullScreen}
          open={cancelLoanRequestDialog}
          aria-labelledby="cancelLoanRequest"
        >
          <DialogTitle id="cancelLoanRequest">
            {"Cancel Loan Request"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to Cancel it?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                cancelLoanRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCancelLoanRequestDialog(!cancelLoanRequestDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add BuyInput */}
        <Dialog
          fullScreen={fullScreen}
          open={addBuyInputDialog}
          aria-labelledby="addBuyInput"
        >
          <DialogTitle id="addBuyInput">{"Add BuyInput"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="source">Source(Node Name) :</InputLabel>
              <Input
                disabled
                id="source"
                name="source"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">
                {" "}
                Destination (User Name) :
              </InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsId(event.target.value);
                }}
                label="Select Verticals"
              >
                <MenuItem>Choose Asset Vertical</MenuItem>
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="quantity">Quantity :</InputLabel>
              <Input
                id="quantity"
                name="quantity"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addBuyInputSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddBuyInputDialog(!addBuyInputDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancel BuyInput */}
        <Dialog
          fullScreen={fullScreen}
          open={cancelBuyInputDialog}
          aria-labelledby="cancelBuyInput"
        >
          <DialogTitle id="cancelBuyInput">
            {"Cancel BuyInput Request"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to Cancel it?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                cancelBuyInputSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCancelBuyInputDialog(!cancelBuyInputDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add VET Request */}
        <Dialog
          fullScreen={fullScreen}
          open={addVetRequestDialog}
          aria-labelledby="addVetRequst"
        >
          <DialogTitle id="addVetRequest">
            {"Add VET Visit Request"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="source">Source(Node Name) :</InputLabel>
              <Input
                disabled
                id="source"
                name="source"
                type="text"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">
                {" "}
                Destination (User Name) :
              </InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="email"
                value={selectedNodeNameValue}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Asset Vertical</InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsId(event.target.value);
                }}
                label="Select Verticals"
              >
                <MenuItem>Choose Asset Vertical</MenuItem>
                {verticalsIdList &&
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="quantity">Quantity :</InputLabel>
              <Input
                id="quantity"
                name="quantity"
                type="text"
                value={numOfAssetsValue}
                onChange={(e) => {
                  setNumOfAssetsValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addVetRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAddVetRequestDialog(!addVetRequestDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Cancel VET Request  */}
        <Dialog
          fullScreen={fullScreen}
          open={cancelVetRequestDialog}
          aria-labelledby="cancelVetRequest"
        >
          <DialogTitle id="cancelVetRequest">
            {"Cancel VET Request"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to Cancel it?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                cancelVetRequestSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCancelVetRequestDialog(!cancelVetRequestDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete Farmer */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserDeleteDialog}
          // onClose={handleClose}
          aria-labelledby="deleteConsumerUser"
        >
          <DialogTitle id="deleteConsumerUser">
            {"Delete Farmer User"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to delete?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Delete Farmer */}
        {/* Reset Password Of Farmer */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserResetPasswordDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserResetPassword"
        >
          <DialogTitle id="consumerUserResetPassword">
            Farmer Password Reset
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="newPassword">New Password:</InputLabel>
              <Input
                id="newPassword"
                name="newPassword"
                type="text"
                value={newPasswordValue}
                onChange={(e) => {
                  setNewPasswordValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="passwordConfirmation">
                Password Confirmation:
              </InputLabel>
              <Input
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="text"
                value={passwordConfirmationValue}
                onChange={(e) => {
                  setPasswordConfirmationValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                resetPasswordSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setconsumerUserResetPasswordDialog(
                  !consumerUserResetPasswordDialog
                );
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Reset Password Farmer */}
        {/* Update Farmer*/}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserUpdateDialog}
          // onClose={handleClose}
          aria-labelledby="UpdateConsumerUser"
        >
          <DialogTitle id="UpdateConsumerUser">{"Update Farmer"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">Consumer User Name :</InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={consumerUserUserName}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstName">First Name :</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                value={consumerUserFirstName}
                onChange={(e) => {
                  setConsumerUserFirstName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastName">Last Name :</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                value={consumerUserLastName}
                onChange={(e) => {
                  setConsumerUserLastName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="domainName">Domain Name :</InputLabel>
              <Input
                disabled
                id="domainName"
                name="domainName"
                type="text"
                value={consumerUserUserDomainName}
                onChange={(e) => {
                  setConsumerUserUserDomainName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="userType">User Type</InputLabel>
              <Select
                disabled
                id="userType"
                name="userType"
                value={consumerUserUserType}
              ></Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={consumerUserEmail}
                onChange={(e) => {
                  setConsumerUserEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number :</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={consumerUserPhone}
                onChange={(e) => {
                  setConsumerUserPhone(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={consumerUserAddress}
                onChange={(e) => {
                  setConsumerUserAddress(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={consumerUserCity}
                onChange={(e) => {
                  setConsumerUserCity(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={consumerUserState}
                onChange={(e) => {
                  setConsumerUserState(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Nominee :</InputLabel>
              <Input
                id="nominee"
                name="nominee"
                type="text"
                value={consumerUserNominee}
                onChange={(e) => {
                  setConsumerUserNominee(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pancard">Pancard Number :</InputLabel>
              <Input
                id="pancard"
                name="pancard"
                type="text"
                value={consumerUserPancard}
                onChange={(e) => {
                  setConsumerUserPancard(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={consumerUserLongitude}
                onChange={(e) => {
                  setConsumerUserLongitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={consumerUserLatitude}
                onChange={(e) => {
                  setConsumerUserLatitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={consumerUserMessage}
                onChange={(e) => {
                  setConsumerUserMessage(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                consumerUserUpdateSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Update Farmer*/}
        {/* Node Status */}
        <Dialog
          fullScreen={fullScreen}
          open={viewNodeStatusDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserNodeUpdate"
        >
          <DialogTitle id="consumerUserNodeUpdate">
            View Node Status
          </DialogTitle>
          <DialogContent>
            <p>
              <b>Node Name:</b>
              {setNodeName}
            </p>
            <p>
              <b>Node ID:</b>
              {nodeId}
            </p>
            <p>
              <b>Node Status:</b>
              {setNodeStatus}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setViewNodeStatusDialog(!viewNodeStatusDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Node Status */}
        {/*Node Info */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserViewNodeInfoDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserNodeInfo"
        >
          <DialogTitle id="consumerUserNodeInfo">View Node Info</DialogTitle>
          <DialogContent>
            <p>
              <b>User Name : </b>
              {nodeUserName}
            </p>
            <p>
              <b>Node ID : </b>
              {nodeUserNodeId}
            </p>
            <p>
              <b>Account Type : </b>
              {nodeUserAccountType}
            </p>
            <p>
              <b>User Type : </b>
              {nodeUserUserType}
            </p>
            <p>
              <b>Asset Type : </b>
              {nodeUserAssetType}
            </p>
            <p>
              <b>Number Of Assets : </b>
              {nodeUserNumberOfAssets}
            </p>
            <p>
              <b>Area Size : </b>
              {nodeUserAreaSize}
            </p>
            <p>
              <b>Grazing Type : </b>
              {nodeUserGrazingType}
            </p>
            <p>
              <b>Feed Type : </b>
              {nodeUserFeedType}
            </p>
            <p>
              <b>Number Of Kids : </b>
              {nodeUserNumberOfKids}
            </p>
            <p>
              <b>Male Gender Number : </b>
              {nodeUserMaleGenderNumber}
            </p>
            <p>
              <b>Female Gender Number : </b>
              {nodeUserFemaleGenderNumber}
            </p>
            <p>
              <b>Over All Age : </b>
              {nodeUserOverallAge}
            </p>
            <p>
              <b>Breed : </b>
              {nodeUserBreed}
            </p>
            <p>
              <b>Farming Experience : </b>
              {nodeUserFarmingExperience}
            </p>
            <p>
              <b>Node Status : </b>
              {nodeUserNodeStatus}
            </p>
            <p>
              <b>Content Url : </b>
              {nodeUserContentUrl}
            </p>
            <p>
              <b>Node Type : </b>
              {nodeUserNodeType}
            </p>
            <p>
              <b>Assetvertical Id : </b>
              {nodeUserAssetverticalId}
            </p>
            <p>
              <b>Assetvertical Friendly Name: </b>
              {nodeUserAssetverticalFriendlyName}
            </p>
            <p>
              <b>Firstname : </b>
              {nodeUserFirstName}
            </p>
            <p>
              <b>Last Name : </b>
              {nodeUserLastName}
            </p>
            <p>
              <b>Email : </b>
              {nodeUserEmail}
            </p>
            <p>
              <b>Phone : </b>
              {nodeUserPhone}
            </p>
            <p>
              <b>State : </b>
              {nodeUserState}
            </p>
            <p>
              <b>City : </b>
              {nodeUserCity}
            </p>
            <p>
              <b>District : </b>
              {nodeUserDistrict}
            </p>
            <p>
              <b>Pincode : </b>
              {nodeUserPincode}
            </p>
            <p>
              <b>Latitude : </b>
              {nodeUserLatitude}
            </p>
            <p>
              <b>Longitude : </b>
              {nodeUserLongitude}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserViewNodeInfoDialog(
                  !consumerUserViewNodeInfoDialog
                );
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/*End Of Node Info */}
      </div>
    </React.Fragment>
  );
}
