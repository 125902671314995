import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@material-ui/core/Button";
import TablePagination from "@mui/material/TablePagination";
import "./TenderSiteManagementComponent.css";
import { get } from "lodash";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SearchBar from "material-ui-search-bar";
import Box from "@material-ui/core/Box";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  menuPaperForm: {
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
}));

export default function TenderSiteManagementComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const [isLoading, setIsLoading] = useState(false);
  const [viewAllSites, setViewallSites] = useState(false);
  const [viewAllGridSites, setViewallGridSites] = useState(true);
  const [viewAllMapSites, setViewallMapSites] = useState(false);
  const [showMappedAssets, hideMappedAssets] = useState(false);
  const [showMappedGridAssets, hideMappedGridAssets] = useState(false);
  const [showMappedMapAssets, hideMappedMapAssets] = useState(false);
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [getAllSites, setAllSites] = useState([]);
  const getDistrict = "District";
  const [getMapwithSite, setMapwithSite] = useState([]);
  const [getSiteId, setSiteId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  /* Create Form Site */
  const [getSitePopUp, setSiteCreatePopUp] = useState(false);
  const [getSiteName, setSiteName] = useState("");
  const [getMailId, setMailId] = useState("");
  const [getPhone, setPhone] = useState("");
  const [getSiteType, setSiteType] = useState("");
  const [getSiteData, setSiteData] = useState([]);
  const assetVerticalIdValue = "6a112364-7adc-47c7-8436-47479d11fcf3";
  const assetVerticalFriendlyNameValue = "Animal";
  const [deleteSiteDialog, setDeleteSiteDialog] = useState(false);
  const [selectedSiteName, setSelectedSiteName] = useState("");
  const [selectedSiteId, setSelectedSiteId] = useState("");
  /* End Create Form Site */
  const [showMapPopup, hideMapPopup] = useState(false);
  const [setAssetVal, getAssetVal] = useState("");
  const [setAssetCatVal, getAssetCatVal] = useState("");
  const [getMappedAssets, setMappedAssets] = useState([]);
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [setmarker_position, getmarker_position] = useState({ lat: 0, lng: 0 });

  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.sitetype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAllSites(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.sitetype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAllSites(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(() => {
    getAllSitesData();
  }, [setAllSites]);

  useEffect(() => {
    getAllSitesList();
  }, [setSiteData]);

  /*  useEffect(() => {
    return navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        getmarker_position(pos);
      }
    );
  }, []);
 */
  /* Pagination */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* End Pagination */

  /* View All Sites */

  const getAllSitesData = (Type) => {
    if (Type === "List") {
      setViewallSites(true);
      setViewallGridSites(false);
      setViewallMapSites(false);

      hideMappedAssets(false);
      hideMappedGridAssets(false);
      hideMappedMapAssets(false);
    } else if (Type === "Grid") {
      setViewallSites(false);
      setViewallGridSites(true);
      setViewallMapSites(false);

      hideMappedAssets(false);
      hideMappedGridAssets(false);
      hideMappedMapAssets(false);
    } else if (Type === "Map") {
      setViewallSites(false);
      setViewallGridSites(false);
      setViewallMapSites(true);

      hideMappedAssets(false);
      hideMappedGridAssets(false);
      hideMappedMapAssets(false);
    }

    MainService.viewAllTenderSites(username, getDistrict)
      .then((res) => {
          setAllSites(res.sites);
          getFilterUsers(res.sites);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  /* End View All Sites */

  const viewMappedAssets = (siteid, Type) => {
    if (Type === "List") {
      setViewallSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);

      hideMappedAssets(true);
      hideMappedGridAssets(false);
      hideMappedMapAssets(false);
    } else if (Type === "Grid") {
      setViewallSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);

      hideMappedAssets(false);
      hideMappedGridAssets(true);
      hideMappedMapAssets(false);
    } else if (Type === "Map") {
      setViewallSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);

      hideMappedAssets(false);
      hideMappedGridAssets(false);
      hideMappedMapAssets(true);
    }

    setSiteId(siteid);
    setPage(0);

    MainService.mappedTenderLegacyAssets(siteid, username)
      .then((res) => {
        setMappedAssets(res.assets);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const backToSitesClick = () => {
    if (showMappedAssets === true) {
      setViewallSites(true);
    } else if (showMappedGridAssets === true) {
      setViewallGridSites(true);
    } else if (showMappedMapAssets === true) {
      setViewallMapSites(true);
    }
  };
  const addUnmappedToMap = () => {
    hideMapPopup(true);
    setViewallSites(false);

    MainService.getAllTenderAssetsData(username)
      .then((res) => {
        setMapwithSite(res.assets);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const getAssetValHandler = (e) => {
    getAssetVal(e.target.value);
    categoryAssetHandler(e.target.value);
  };

  const categoryAssetHandler = (assetval) => {
    const data = getMapwithSite.filter((val) => {
      return val.assetId === assetval;
    });
    getAssetCatVal(data[0].category);
  };

  const closeMapPopup = () => {
    hideMapPopup(false);
  };

  const mappedAssetValues = () => {
    const formData = {
      siteid: getSiteId,
    };
    const dataSiteId = JSON.stringify(formData);
    MainService.assetMapingtoTenderSite(
      username,
      setAssetVal,
      setAssetCatVal,
      dataSiteId
    )
      .then((res) => {
        hideMapPopup(false);
        viewMappedAssets(getSiteId);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const handleClose = () => {};
  /* Delete site */
  const delteSiteDialogClick = (siteid, sitename) => {
    setSelectedSiteId(siteid);
    setSelectedSiteName(sitename);
    setDeleteSiteDialog(!deleteSiteDialog);
  };
  const deleteSubmitClick = () => {
    MainService.deleteTenderSite(username, selectedSiteName, selectedSiteId)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setDeleteSiteDialog(!deleteSiteDialog);
  };
  /* Create Site */

  const getAllSitesList = () => {
    MainService.getAllTenderSiteTypes()
      .then((res) => {
        console.log("res for site data:",res)
        if(res)
        {
          console.log("res for site data:",res)
          setSiteData(res.sitetypes);
        }
        
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const siteCreateHandler = () => {
    const createSiteFormData = new FormData();
    createSiteFormData.append("parentid", username);
    createSiteFormData.append("sitename", getSiteName);
    createSiteFormData.append("mailid", getMailId);
    createSiteFormData.append("phone", getPhone);
    createSiteFormData.append("sitetype", getSiteType);
    createSiteFormData.append("assetverticalid", assetVerticalIdValue);
    createSiteFormData.append(
      "assetverticalfriendlyname",
      assetVerticalFriendlyNameValue
    );

    MainService.createTenderSite(createSiteFormData)
      .then((res) => {
        setViewallSites(true);
        hideMappedAssets(false);
        setSiteCreatePopUp(!getSitePopUp);
        return NotificationManager.success("Site Created");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    getAllSitesData();
  };
  const createSiteHandler = () => {
    setSiteCreatePopUp(!getSitePopUp);
  };

  const closeSiteModal = (
    <span
      onClick={() => {
        setSiteCreatePopUp(!getSitePopUp);
      }}
    >
      X
    </span>
  );
  const siteAgreement = "Create A New Site";
  const siteModelBody = (
    <div style={{margin:"10px"}}>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentId"> Admin ID:</InputLabel>
        <Input
          disabled
          id="parentId"
          name="parentId"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
        <Input
          id="sitename"
          name="sitename"
          type="text"
          value={getSiteName}
          onChange={(e) => setSiteName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="mailid"> Mail ID:</InputLabel>
        <Input
          id="mailid"
          name="mailid"
          type="text"
          value={getMailId}
          onChange={(e) => setMailId(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="phone">Phone Number</InputLabel>
        <Input
          id="phone"
          name="phone"
          type="text"
          value={getPhone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Type</InputLabel>
        <Select
          id="selectedModel"
          value={getSiteType}
          onChange={(event) => setSiteType(event.target.value)}
          label="Select Model"
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          {getSiteData &&
            getSiteData.map((idObj, index) => (
              console.log("get site data:",getSiteData),
              <MenuItem key={`${index}`} value={idObj.sitetype}>
                {idObj.sitetype}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <br />
      <div style={{marginLeft : "400px"}}>
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={siteCreateHandler}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setSiteCreatePopUp(!getSitePopUp);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
      </div>
    </div>
  );

  /* End Of Create Site */

  /*************** Tender Site Map *************/
  /* const TenderSiteGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={getAllSites.index}
        defaultZoom={13}
        defaultCenter={setmarker_position}
        center={setmarker_position}
      >
        {getAllSites.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <button
                  type="button"
                  onClick={() => {
                    viewMappedAssets(value.siteid, "Map");
                  }}
                >
                  <div>{value.sitename}</div>
                </button>
              </InfoWindow>
            </Marker>
          ) : (
            ""
          );
        })}
      </GoogleMap>
    );
  }); */

  /* new map1 */
  const [selected, setSelected] = useState({});
  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };
  const onSelect = (item) => {
    setSelected(item);
  };
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: 13.093426,
    lng: 77.655773,
  };

  const defaultAssetCenter = {
    lat: 18.093426,
    lng: 129.655773,
  };

  const TenderSiteGoogleMap = () => {
    return (
      <LoadScript googleMapsApiKey="AIzaSyDzJglX1KEZkksRUr_zBWrHAG3ZYeUZt4o">
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={4}
          center={defaultCenter}
        >
          <MarkerClusterer options={options} averageCenter={true}>
            {(clusterer) =>
              getAllSites.map((markerObj, index) => {
                return (
                  <Marker
                    key={index}
                    position={{
                      lat: parseFloat(markerObj.latitude),
                      lng: parseFloat(markerObj.longitude),
                    }}
                    clusterer={clusterer}
                    onClick={() => onSelect(markerObj)}
                  />
                );
              })
            }
          </MarkerClusterer>

          {selected.latitude && selected.longitude && (
            <InfoWindow
              position={{
                lat: parseFloat(selected.latitude),
                lng: parseFloat(selected.longitude),
              }}
              clickable={true}
              onCloseClick={() => setSelected({})}
            >
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  <b>ID:</b> {selected.siteid}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <b> Name:</b>Site{selected.sitename}
                </Typography>
              </CardContent>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    );
  };

  const AssetsGoogleMap = () => {
    return (
      <div>
        <LoadScript googleMapsApiKey="AIzaSyDzJglX1KEZkksRUr_zBWrHAG3ZYeUZt4o">
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={4}
            center={defaultAssetCenter}
          >
            <MarkerClusterer options={options} averageCenter={true}>
              {(clusterer) =>
                getMappedAssets.map((markerObj, index) => {
                  return (
                    <Marker
                      key={index}
                      position={{
                        lat: parseFloat(markerObj.latitude),
                        lng: parseFloat(markerObj.longitude),
                      }}
                      clusterer={clusterer}
                      onClick={() => onSelect(markerObj)}
                    />
                  );
                })
              }
            </MarkerClusterer>

            {selected.latitude && selected.longitude && (
              <InfoWindow
                position={{
                  lat: parseFloat(selected.latitude),
                  lng: parseFloat(selected.longitude),
                }}
                clickable={true}
                onCloseClick={() => setSelected({})}
              >
                <CardContent>
                  <Typography variant="body2" color="textSecondary">
                    <b>Asset ID:</b> {selected.assetId}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <b>Asset Status:</b>
                    {selected.assetStatus}
                  </Typography>
                </CardContent>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    );
  };
  /*  const AssetsGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={getMappedAssets.index}
        defaultZoom={13}
        defaultCenter={setmarker_position}
        center={setmarker_position}
      >
        {getMappedAssets.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <div>{value.assetType}</div>
              </InfoWindow>
            </Marker>
          ) : (
            ""
          );
        })}

        
      </GoogleMap>
    );
  }); */
  /*************** End Of Tender Site Map *************/

  return (
    <React.Fragment>
      <div className="container" id="mainContentList">
        <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Add Asset"
          className={classes.fab}
        >
          <AddIcon onClick={() => createSiteHandler()}></AddIcon>
        </Fab>
        <div className="topHeadersWrapper">
          <div className="topHeaders">
          <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => getAllSitesData("List")}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => getAllSitesData("Grid")}
            ></Button>
            <Button
              startIcon={<MapIcon />}
              size="large"
              onClick={() => getAllSitesData("Map")}
            ></Button>            
          </div>
        </div>
        <div className="MuiGrid-root">
          {viewAllSites === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <h2>View Site List</h2>
              <SearchBar
                placeholder="Search by Site Type"
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              {getAllSites.length > 0 ? (
                <TableContainer component={Paper} sx={{ maxWidth: "100%" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell colSpan={1}>Parent ID</StyledTableCell>
                        <StyledTableCell align="center" colSpan={1}>
                          Site ID
                        </StyledTableCell>
                        {/* <StyledTableCell align="center" colSpan={1}>Public ID</StyledTableCell> */}
                        <StyledTableCell align="center" colSpan={1}>
                          Site Name
                        </StyledTableCell>
                        <StyledTableCell align="center" colSpan={1}>
                          Site Type
                        </StyledTableCell>
                        <StyledTableCell align="center" colSpan={1}>
                          View Assets
                        </StyledTableCell>
                        <StyledTableCell align="center" colSpan={1}>
                          Option
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ maxWidth: 1100 }}>
                      {getAllSites
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <StyledTableRow hover tabIndex={-1} key={index}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              sx={{ maxWidth: 220 }}
                            >
                              {row.parentid}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.siteid}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {row.sitename}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.sitetype}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Stack spacing={2}>
                                <Button
                                  variant="contained"
                                  style={{ margin: "2px" }}
                                  sx={{ bgcolor: "#21756e" }}
                                  onClick={() =>
                                    viewMappedAssets(row.siteid, "List")
                                  }
                                >
                                  List
                                </Button>
                              </Stack>
                              <Stack spacing={2}>
                                <Button
                                  variant="contained"
                                  style={{ margin: "2px" }}
                                  sx={{ bgcolor: "#21756e" }}
                                  onClick={() =>
                                    viewMappedAssets(row.siteid, "Grid")
                                  }
                                >
                                  Grid
                                </Button>
                              </Stack>
                              <Stack spacing={2}>
                                <Button
                                  variant="contained"
                                  style={{ margin: "2px" }}
                                  sx={{ bgcolor: "#21756e" }}
                                  onClick={() =>
                                    viewMappedAssets(row.siteid, "Map")
                                  }
                                >
                                  Map
                                </Button>
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Stack spacing={2}>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    delteSiteDialogClick(
                                      row.siteid,
                                      row.sitename
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={getAllSites.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              ) : (
                <h4>Tender Sites Not Found</h4>
              )}
            </Box>
          ) : null}

          {viewAllGridSites === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <SearchBar
                placeholder="Search by Site Type"
                value={farmgridsearched}
                onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                onCancelSearch={() => cancelFarmGridSearch()}
              />
              <div className="cardStyleForDashboardItems">
                <span style={{ width: "100%", textAlign: "center" }}>
                  <h2>VIEW SITE LIST</h2>
                </span>
                {getAllSites.length > 0 ? (
                  <div className="cardStyleForDashboardItems">
                    {getAllSites.map((tendersite, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Parent Id:</b>
                            {tendersite.parentid}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {tendersite.siteid}
                          </p>
                          <p>
                            <b>Site Name:</b>
                            {tendersite.sitename}
                          </p>
                          <p>
                            <b>Site Type:</b>
                            {tendersite.sitetype}
                          </p>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                viewMappedAssets(tendersite.siteid, "Grid")
                              }
                            >
                              Grid
                            </Button>
                            <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                viewMappedAssets(tendersite.siteid, "Map")
                              }
                            >
                              Map
                            </Button>
                            <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                viewMappedAssets(tendersite.siteid, "List")
                              }
                            >
                              List
                            </Button>
                          </div>
                          <div>
                            <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                delteSiteDialogClick(
                                  tendersite.siteid,
                                  tendersite.sitename
                                )
                              }
                            >
                              Delete
                            </Button>
                          </div>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>Tender Sites Not Found.</h4>
                )}
              </div>
            </Box>
          ) : null}

          {viewAllMapSites === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <h2>View All Sites</h2>
              {getAllSites.length > 0 ? (
                <TenderSiteGoogleMap
                  containerElement={
                    <div style={{ height: `500px`, width: "100%" }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              ) : (
                <h4>No Tender Site Found</h4>
              )}
            </Box>
          ) : null}

          {showMappedAssets === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <h2>Mapped Assets List</h2>
              <span>
                <Button
                  variant="contained"
                  style={{ margin: "2px" }}
                  sx={{ bgcolor: "#21756e" }}
                  onClick={backToSitesClick}
                >
                  Back to Sites
                </Button>
                <Button
                  variant="contained"
                  style={{ margin: "2px" }}
                  sx={{ bgcolor: "#21756e" }}
                  onClick={addUnmappedToMap}
                >
                  Add
                </Button>
              </span>
              {getMappedAssets.length > 0 ? (
                <TableContainer component={Paper} sx={{ maxWidth: "100%" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell colSpan={1}>
                          Solution Id
                        </StyledTableCell>
                        <StyledTableCell colSpan={1}>Asset Id</StyledTableCell>
                        <StyledTableCell colSpan={1}>
                          Asset Status
                        </StyledTableCell>
                        <StyledTableCell colSpan={1}>Category</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ maxWidth: 1100 }}>
                      {getMappedAssets
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <StyledTableRow hover tabIndex={-1} key={index}>
                            <StyledTableCell align="left">
                              {row.solutionId}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.assetId}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.assetStatus}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.category}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={getMappedAssets.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              ) : (
                <h4>No Assets Found</h4>
              )}
            </Box>
          ) : null}

          {showMappedGridAssets === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <div className="cardStyleForDashboardItems">
                <span style={{ width: "100%", textAlign: "center" }}>
                  <h2>MAPPED ASSETS LIST</h2>
                </span>
                <span style={{ width: "100%", textAlign: "center" }}>
                  <Button
                    variant="contained"
                    style={{ margin: "2px" }}
                    sx={{ bgcolor: "#21756e" }}
                    onClick={backToSitesClick}
                  >
                    Back to Sites
                  </Button>
                  <Button
                    variant="contained"
                    style={{ margin: "2px" }}
                    sx={{ bgcolor: "#21756e" }}
                    onClick={addUnmappedToMap}
                  >
                    Add
                  </Button>
                </span>
                {getMappedAssets.length > 0 ? (
                  <div className="cardStyleForDashboardItems">
                    {getMappedAssets.map((tenderasset, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Solution Id:</b>
                            {tenderasset.solutionId}
                          </p>
                          <p>
                            <b>Asset Id:</b>
                            {tenderasset.assetId}
                          </p>
                          <p>
                            <b>Asset Status:</b>
                            {tenderasset.assetStatus}
                          </p>
                          <p>
                            <b>Category:</b>
                            {tenderasset.category}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Assets Found.</h4>
                )}
              </div>
            </Box>
          ) : null}

          {showMappedMapAssets === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {getMappedAssets.length > 0 ? (
                <div>
                  <Button
                    variant="contained"
                    style={{ margin: "2px" }}
                    sx={{ bgcolor: "#21756e" }}
                    onClick={backToSitesClick}
                  >
                    Back to Sites
                  </Button>
                  <br />
                  <AssetsGoogleMap
                    containerElement={
                      <div style={{ height: `500px`, width: "100%" }} />
                    }
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                </div>
              ) : (
                <h4>No Assets Found</h4>
              )}
            </Box>
          ) : null}
        </div>

        {/* Asset Map With Site */}
        <Dialog
          fullScreen={fullScreen}
          open={showMapPopup}
          onClose={closeMapPopup}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Asset Map With Site"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nodename">Site Id:</InputLabel>
              <Input
                id="nodename"
                name="nodename"
                placeholder="Node Name"
                type="text"
                value={getSiteId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Select Asset</InputLabel>
              <Select
                id="status"
                value={setAssetVal}
                onChange={(e) => {
                  getAssetValHandler(e);
                }}
                label="Select"
              >
                {getMapwithSite &&
                  getMapwithSite.map((asset, index) => (
                    <MenuItem key={`${index}`} value={asset.assetId}>
                      {asset.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nodename">Category:</InputLabel>
              <Input
                id="nodename"
                name="nodename"
                placeholder="Node Name"
                type="text"
                value={setAssetCatVal}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              className="registerBtn"
              variant="contained"
              onClick={mappedAssetValues}
            >
              Submit
            </Button>
            <Button variant="contained" onClick={closeMapPopup}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete */}
        <Dialog
          fullScreen={fullScreen}
          open={deleteSiteDialog}
          onClose={handleClose}
          aria-labelledby="deleteSite"
        >
          <DialogTitle id="deleteSite">{"Delete Site"}</DialogTitle>
          <DialogContent>
            <h4>Are you sure want to delete?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setDeleteSiteDialog(!deleteSiteDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <CustomPopup
        closeButton={closeSiteModal}
        isOpen={getSitePopUp}
        closeModal={getSitePopUp}
        content={siteModelBody}
        title={siteAgreement}
      />
    </React.Fragment>
  );
}
