import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import Loading from "../../AssetDeveloperRole/ModelProductManagement/Loading";
import ViewSubcategories from "../../Common/CommonContent/ViewSubcategories";
import { getCategoryID } from "../CommonContent/ContentedRelatedCommonFun";
import MainService from "../MainService";
const ProfileTable = lazy(() => import("./ProfileTable"));

const textSize = {
  sx: {
    textTransform: "none",
    background: "#3ECF8E",
    margin: "2px",
    width: { xs: "100%", sm: "70%", md: "auto" },
    "&:hover": {
      background: "#3ECF8E",
    },
  },
};

const ParentSignedAgreements = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] =
    useState("SIGNED-AGREEMENT");
  const [postList, setPostList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  const [getSubCatVal, setSubCatVal] = useState("");
  // sub categories model condition
  const [openSubCategoryModel, closeSubCategoryModel] = useState(false);

  useMemo(async () => {
    await getCategoryID(selectedCategoryName)
      .then((data) => {
        setSelectedCategoryId(data);
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
  }, []);

  useEffect(() => {
    if (!selectedCategoryId) return;
    getSubCategories();
  }, [selectedCategoryId]);

  const getSubCategories = () => {
    MainService.getSubCategoriesForAD(parentId, selectedCategoryId)
      .then((data) => {
        setsubCategoriesList(data);
      })
      .catch((e) => {
        return NotificationManager.error(e || "something went wrong");
      });
  };

  const getAllContentsList = () => {
    setSubCatVal("");
    setIsLoading(true);
    MainService.getContentsBasedonOwnerCreatorApprover(
      parentId,
      parentId,
      username,
      selectedCategoryId
    )
      .then((data) => {
        setPostList(data);
        setIsLoading(false);
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
  };

  useMemo(() => {
    if (!selectedCategoryId) return;
    if (!username) return;
    getAllContentsList();
  }, [selectedCategoryId]);

  const getSubCategoryBasedContentHandler = (subcat) => {
    setIsLoading(true);
    MainService.getContentsBasedonCategoryandSubcat(
      username,
      selectedCategoryId,
      subcat,
      "contentcreator"
    )
      .then((data) => {
        setPostList(data);
        setIsLoading(false);
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
  };

  // view subcategories
  const subCategoriesHandler = () => {
    closeSubCategoryModel(!openSubCategoryModel);
  };

  return (
    <>
      <Box className="container" id="mainContentList">
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Button
            {...textSize}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              subCategoriesHandler();
            }}
          >
            Sub Categories
          </Button>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Sub Category Filter
              </InputLabel>
              <Select
                id="subcategory"
                value={getSubCatVal}
                onChange={(e) => {
                  setSubCatVal(e.target.value);
                  getSubCategoryBasedContentHandler(e.target.value);
                }}
                label="SubCategory Filter"
              >
                {subCategoriesList?.length > 0 ? (
                  subCategoriesList &&
                  subCategoriesList?.map((idObj, index) => (
                    <MenuItem
                      key={`${index} `}
                      value={idObj?.contentsubcategoryname}
                    >
                      {idObj?.contentsubcategoryname}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Data Found</MenuItem>
                )}
              </Select>
            </FormControl>
            {getSubCatVal !== "" && (
              <Button
                variant="none"
                onClick={() => {
                  getAllContentsList();
                }}
              >
                Clear Filter
              </Button>
            )}
          </Box>
        </Box>

        <Box className="MuiGrid-root">
          <Suspense fallback={<Loading />}>
            <ProfileTable
              data={postList}
              isLoading={isLoading}
              reLoad={getAllContentsList}
              requeiredDocumentStatusUpdateButton={false}
              requeiredDeleteContentButton={false}
              requeiredUpdateKycButton={false}
            />
          </Suspense>
        </Box>

        <ViewSubcategories
          subCategories={subCategoriesList}
          modelShow={openSubCategoryModel}
          closeModel={subCategoriesHandler}
          reloadComponent={getSubCategories}
          userName={username}
          categoryId={selectedCategoryId}
          categoryName={selectedCategoryName}
          parentID={parentId}
          reloadContent={getAllContentsList}
          contentFormate="DOC"
          contentOwners={[{ sitename: parentId }]}
        />
      </Box>
    </>
  );
};

export default ParentSignedAgreements;
