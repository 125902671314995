import { Grid } from '@material-ui/core'
import React from 'react';
import LocalTradeImg from "../../images/agreement.png";
import GlobalTradeImg from "../../images/statistics.png";
import InvestementImg from "../../images/investment.png";

export default function IAABanner() {
  return (
    <div className=''>
     <div maxWidth="lg" className="serviceContainer IAABanner">
          <div maxWidth="sm" className="gridSection">
            
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              direction="row"
            >
       
              <Grid
                className="hoverEffect"
                item
                md={4}
                sm={6}
                xs={12}
              >
                <a href="#GlobalTrade">
                  <img
                    className="banner-icon mb-3"
                    src={GlobalTradeImg}
                    alt="Icon"
                  />
                  <h4>Explore Global Trade</h4>
                </a>
              </Grid>

              <Grid
                className="hoverEffect"
                item
                md={4}
                sm={6}
                xs={12}
              >
                <a href="#ExploreInvestment">
                  <img
                    src={InvestementImg}
                    alt="Icon"
                    className="banner-icon mb-3"
                  />
                  <h4>Explore Investment</h4>
                </a>
              </Grid>

              <Grid
                className="hoverEffect"
                item
                md={4}
                sm={6}
                xs={12}
              >
                <a href="#LocalTrade">
                  <img
                    src={LocalTradeImg}
                    alt="Icon "
                    className="banner-icon mb-3"
                  />
                  <h4>
                    Explore Local Trade
                  </h4>
                </a>
              </Grid>
            </Grid>
          </div>
        </div>
    </div>
  )
}
