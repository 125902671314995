import React from "react";
import Iaa1 from "../../images/cow (2).png";
import Iaa2 from "../../images/mushrooms.png";
import Iaa3 from "../../images/IAA3.png";
import Iaa4 from "../../images/tree-silhouette.png";
import Iaa5 from "../../images/extractor.png";

import { Row, Col } from "react-bootstrap";
import "./IAABlock.css";
import {Button} from "@material-ui/core";
import { NavHashLink } from "react-router-hash-link";

export default function IAABlock({ producers }) {
  const Image = [
    { img: Iaa3, href: "#Bee" },
    { img: Iaa1 },
    { img: Iaa2 },
    { img: Iaa4 },
    { img: Iaa5  }  ,
  ];
  return (
    
    <>
      {!producers ? (
        <div className="Iaa-container Iaa-container--mod-1 mb-5">
          <h2 className="text-center mb-0"></h2>

          <Row className="justify-content-center">
          {Image.map((e, index) => (
            <Col md={2} className="md-md-0 mb-3" key={index}>
              {e.href ? (
                <NavHashLink to={e.href}>
                  <div className="imageHover-block Iaa-Cards d-flex justify-content-center align-items-center">
                    <img className="imgCursor" src={e.img} alt="icon" />
                  </div>
                </NavHashLink>
              ) : (
                <div className="Iaa-Cards d-flex justify-content-center align-items-center card-deactivated">
                  <img src={e.img} alt="icon" />
                </div>
              )}
            </Col>
          ))}
        </Row>
        </div>
      ) : (
        <div className="Iaa-container Iaa-container--mod mb-5">
          <h2 className="text-center mb-0"> </h2>
          <Row className="justify-content-center">
            {Image.map((e) => {
              return (
                <Col md={3} className="md-md-0 mb-3">
                  
                    <div
                      // className={`${
                      //   e.href
                      //     ? "imageHover Iaa-Cards d-flex justify-content-center align-items-center"
                      //     : "Iaa-Cards d-flex justify-content-center align-items-center card-deactivated"
                      // }`}

                      className=" Iaa-Cards d-flex justify-content-center align-items-center"
                    >
                      {" "}
                      <img
                        // className={`${e.href && "imgCursor"}`}
                        src={e.img}
                        alt="icon"
                      />
                    </div>
                  
                </Col>
              );
            })}
          </Row>

          <Button variant="contained" color="primary">
          <a href="/auth/3/cbnsignup">Join </a>
        </Button>
        </div>
      )}
    </>
  );
}
