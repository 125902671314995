import React from "react";
import PmcFmcMachinery from "../../PMC/PmcFmcMachinery/PmcFmcMachinery";

export default function FmcMachinery() {
  return (
    <>
      <PmcFmcMachinery categoryType="FARM-MACHINERY" compType="FMC" />
    </>
  );
}
