import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import IaaMarketNavbar from "../IaaMarketNavbar/IaaMarketNavbar";

export default function ForProcessingMachineryProvider() {
  return (
    <>
      <IaaMarketNavbar />
      <UpdatesBanner
        className="onMarketplaceBanner text-white"
        bannerText="Start selling processing machinery with CMN"
        buttonText="Sign Up"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "PMC");
        }}
      />
    </>
  );
}
