import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { io } from "socket.io-client";
import Map from "../../googlemap/Map";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import MainService from "../../../Common/MainService";
import {
  Box,
  Button,
  Card,
  CardHeader,
  FormControl,
  Modal,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const VehicleStatus = () => {

  
  const [markers, setMarkers] = useState([]);
  const [vehicle, setVehicle] = useState({});
  const [tripDetails, setTripDetails] = useState(null);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [selectedVehicleId, setSelectedVehicleId] = useState("");
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getVehicleStatus, setVehicleStatus] = useState("");
  const [vehicleGeolocation, setVehicleGeolocation] = useState(null);

  const mapRef = useRef(null);

  // Function to set the map center and zoom
  const setMapCenterAndZoom = (latLng) => {
    if (mapRef.current && latLng) {
      const googleMap = mapRef.current.getMap();
      if (googleMap) {
        googleMap.setCenter(latLng);
        googleMap.setZoom(15);
      }
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const newSocket = io(
      "http://ec2-34-217-29-64.us-west-2.compute.amazonaws.com:9001"
    );
    newSocket.on("connect", () => {
      console.log(
        "Connected to server!************",
        `tt/solution/${username}/site/${vehicleDetails.siteId}/asset/${vehicleDetails.assetId}/LOC`
      );
      
   });


    newSocket.on(
      `tt/solution/consumer2/site/TestSite1/asset/${vehicleDetails.assetId}/LOC`,
      (data) => {
        console.log("DAAATAAA........", data);

        console.log(JSON.parse(data.payload).geoLocation, "dataaaaa//////////////////");
        setMarkers([{ geoLocation: JSON.parse(data.payload).geoLocation }]);

        console.log(markers, "markerssss//////////////");
      }
    );

    return () => {
      newSocket.disconnect();
    };
  }, [vehicleDetails.assetId, username]);

  // last known location details:
  useEffect(() => {
    if (selectedVehicleId && username) {
      MainService.lastLocationDetails(username, selectedVehicleId)
        .then((locationDetails) => {
          // Handle the locationDetails data as needed
          console.log("Last Location Details:::", locationDetails);

          // Assuming locationDetails contains a "geoLocation" property
          if (locationDetails && locationDetails.geoLocation) {
            setVehicleGeolocation(locationDetails.geoLocation);
          }
        })
        .catch((error) => {
          console.error("Error fetching location details:", error);
        });
    }
  }, [selectedVehicleId, username]);

  useEffect(() => {
    if (selectedVehicleId && vehicleGeolocation) {
      setMapCenterAndZoom(vehicleGeolocation);
    }
  }, [selectedVehicleId, vehicleGeolocation]);

  useEffect(() => {
    const getVehicleList = async () => {
      const data = await MainService.getVehicleList(username);
      setVehicle({ vehicle: data.assets });
      console.log(data, "get vehiclelist:");
    };
    getVehicleList();
  }, [username]);

  const getVehicleTripDetails = async (userName, vehicleId) => {
    try {
      const tripData = await MainService.getVehicleTripDetails(
        userName,
        vehicleId
      );
      setTripDetails(tripData);
    } catch (error) {
      console.error("Error fetching trip details:", error);
    }
  };

  //vehicle status
  const vehicleStatus = async (vehicleId) => {
    try {
      const updatedStatus = await MainService.vehicleStatus(
        username,
        vehicleId
      );
      setVehicleStatus(updatedStatus.status);

      console.log(updatedStatus.status, "vehiclestatus.........");
    } catch (error) {
      console.log("Error fetching route details:", error);
    }
  };

  //last location details of a vehicle:

  useEffect(() => {
    if (selectedVehicleId) {
      vehicleStatus(selectedVehicleId);
    }
  }, [selectedVehicleId]);

  useEffect(() => {
    getVehicleTripDetails();
  }, []);

  const mapListChangeHandler = useCallback(
    (name, value) => {
      const filterData = vehicle.vehicle.filter(
        (item) => item.assetId === value
      );

      if (filterData.length > 0) {
        setVehicleDetails({
          assetId: filterData[0].assetId,
          siteId: filterData[0].siteId,
        });
        setSelectedVehicleId(filterData[0].assetId);
      } else {
        // Handle the case where no matching assetId was found.
        setVehicleDetails({
          assetId: "",
          siteId: "",
        });
        setSelectedVehicleId("");
      }
    },
    [vehicle.vehicle]
  );

  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid #ddd",
            marginBottom: theme.spacing(2),
            paddingBottom: theme.spacing(1),
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            color="#1976d2"
            sx={{
              textTransform: "uppercase",
              letterSpacing: "2px",
            }}
          >
            Vehicle Status
          </Typography>
        </Box>

        <Box
          sx={{
            padding: 2,
            border: "1px solid #e0e0e0",
            borderRadius: 4,
            mb: 2,
            background: "aliceBlue",
          }}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={4}>
              <Typography variant="h6">
                Selected Vehicle:{" "}
                <span style={{ color: "#3ECF8E" }}>{selectedVehicleId}</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              Status:{" "}
              <span style={{ color: "#3ECF8E" }}>{getVehicleStatus}</span>
            </Grid>
            <Grid item xs={4}>
              <Button
                color="primary"
                onClick={() => {
                  getVehicleTripDetails(username, selectedVehicleId);
                  openModal();
                }}
              >
                Get On Going Trip Details
              </Button>
            </Grid>
            <Grid item xs={2}>
              <InputLabel id="triplist">Select Vehicle</InputLabel>
              <Select
                variant="outlined"
                id="triplist"
                labelId="demo-simple-select-helper-label"
                value={vehicleDetails.assetId || ""}
                onChange={(event) =>
                  mapListChangeHandler("driverlist", event.target.value)
                }
                endAdornment={
                  selectedVehicleId && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          mapListChangeHandler("driverlist", "");
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
                sx={{ width: 180, mr: 2 }}
              >
                {vehicle.vehicle?.map((li) => (
                  <MenuItem key={li.assetId} value={li.assetId}>
                    {li.assetId}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>

        <Map
          showSearchBox={false}
          routeLocationData={markers}
          vehicleGeolocation={vehicleGeolocation}
        />
      </Box>

      {/* modal for trip details */}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="trip-details-modal"
      >
        <Box
          sx={{
            position: "absolute",
            width: "80%",
            maxWidth: 600,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
            boxShadow: 24,
            borderRadius: 8,
          }}
        >
          {tripDetails && (
            <div>
              <Typography
                variant="h5"
                sx={{
                  marginBottom: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Trip Details
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Vehicle ID: {tripDetails.vehicleId}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Trip ID: {tripDetails.tripId}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Driver ID: {tripDetails.driverId}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Status: {tripDetails.status}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Start Time: {tripDetails.startTime}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Created Date: {tripDetails.createdDate}
              </Typography>

              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Last Modified Date: {tripDetails.lastModifiedDate}
              </Typography>
              {/* Add more trip details here */}
            </div>
          )}
          {!tripDetails && (
            <Typography variant="body1">No trip details available.</Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={closeModal}
            sx={{ marginTop: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default VehicleStatus;
