import React, { memo, Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
} from "@mui/material";

const ContactBuyAgriAssets = ({ openModel, closeModel, props }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={openModel}
        onClose={closeModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Contact Details"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold", marginRight: 1 }}>
                Email:
              </Typography>
              <Typography>{props.email}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold", marginRight: 1 }}>
                Phone:
              </Typography>
              <Typography><a href={`tel:${props.phone}`}>{props.phone}</a></Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" onClick={closeModel}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default memo(ContactBuyAgriAssets);
