import Typography from "@material-ui/core/Typography";
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import AAMCSiteManagementComponent from "../AAMCSiteManagement/AAMCSiteManagementComponent";
import "../Market/Market.css";
import MarketProducts from "../Market/MarketProducts";
import PmcFmcMachinery from "../PMC/PmcFmcMachinery/PmcFmcMachinery";

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

export default function AssetAccesoriesMarket() {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const orientation = isSmallScreen ? "vertical" : "horizontal";
  const userType = localStorage.getItem("userType");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (userType === "FMC" || userType === "FMCDealer") {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [userType]);

  return (
    <>
      <div className="container" id="mainContentList">
        <Box
          sx={{
            width: "100%",
            marginBottom: 30,
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "center" },
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "100%",
            }}
          >
            <Tabs
              orientation={orientation}
              value={value}
              onChange={handleChange}
              aria-label="Market"
            >
              {(userType === "PMC" || userType === "PMCDealer") && (
                <Tab
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  label="PMC-MACHINERY"
                  {...a11yProps(0)}
                  value={0}
                />
              )}
              {(userType === "FMC" || userType === "FMCDealer") && (
                <Tab
                  sx={{ alignItems: "flex-start" }}
                  label="FMC-MACHINERY"
                  {...a11yProps(1)}
                  value={1}
                />
              )}
              <Tab
                sx={{ alignItems: "flex-start" }}
                label="AGRI-ASSETS"
                {...a11yProps(2)}
                value={2}
              />
              <Tab
                sx={{ alignItems: "flex-start" }}
                label="SITE-MANAGEMENT"
                {...a11yProps(3)}
                value={3}
              />
            </Tabs>
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            {((value === 0 && userType === "PMC") ||
              userType === "PMCDealer") && (
              <TabPanel value={value} index={0} style={{ width: "100%" }}>
                <PmcFmcMachinery
                  categoryType="PROCESSING-MACHINERY"
                  compType="PMC"
                />
              </TabPanel>
            )}
            {((value === 1 && userType === "FMC") ||
              userType === "FMCDealer") && (
              <TabPanel value={value} index={1} style={{ width: "100%" }}>
                <PmcFmcMachinery categoryType="FARM-MACHINERY" compType="FMC" />
              </TabPanel>
            )}
            {value === 2 && (
              <TabPanel value={value} index={2} style={{ width: "100%" }}>
                <MarketProducts
                  categoryType="AGRI-ASSETS"
                  compType="Store"
                  subCatType="Sub Category"
                  modelID="Asset Model ID"
                  modelFN="Asset Model Friendly Name"
                  dCost="Asset Cost"
                  pUnit="Unit"
                  pDiscount="Discount"
                  dPrice="Discounted Price"
                />
              </TabPanel>
            )}
            {value === 3 && (
              <TabPanel value={value} index={3} style={{ width: "100%" }}>
                <AAMCSiteManagementComponent siteType={["Store"]} />
              </TabPanel>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
}
