import React from 'react';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
import "./BusinessDiversificationService.css";




export default function BusinessDiversificationService(props) {
  const customPropValue = props.location.state.customProp ;

  const commonProps = {
    className1: "aboutBgGrey",
  };
  
  const farmEnterpriseSection = (

    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="business-diversification-service"
//     descriptionTitle="
//   Loan"
  description="   Business growth service is offered by the Contract Manufacturing Network (CMN) to food processing enterprises, enabling them to either become a network operator to manage their own CMN or become a Community-Based Organization (CBO) to oversee their own farmer network for sourcing materials. This service supports the expansion and optimization of their operational capabilities.
  "
  // href2="auth/3/signup"
  // buttoncont2="Register"
  // onClick={() => {
  //   localStorage.setItem("userType", "FOOD-PROCESSOR");
  // }}
  />





  );
  
  const FoodProcessingEnterpriseSection = (
   
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="business-diversification-service"
    
  description="  Contract manufacturing network offers a business diversification service that helps food processing enterprises transition into farm enterprises. This service allows food processors to integrate farming operations, enabling them to grow their own raw materials. By doing so, they can enhance supply chain control, reduce costs, and increase product quality. Contract manufacturing network provides the necessary support and expertise to ensure a smooth and successful transition."
  href2="auth/3/signup"
  buttoncont2="Register"
  onClick={() => {
    localStorage.setItem("userType", "AssetDeveloper");
  }}
  />
  );
  const CBOSection = (
   
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
   
  description="  Agri-business Network offers a business diversification service designed for Community-Based Organizations (CBOs), enabling them to become Agro Dealers. This service helps CBOs expand their roles by supplying agricultural products and services to local farmers. By transforming into Agro Dealers, CBOs can support their communities more effectively, enhancing agricultural productivity and fostering economic growth. Agri-business Network provides the expertise and resources needed to facilitate this transition smoothly and successfully."
  href2="auth/3/signup"
  buttoncont2="Register"
  onClick={() => {
    localStorage.setItem("userType", "AgroDealer");
  }}
  />
  );
  const growerSection = (
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="Agri-business Network offers a business diversification service that enables Agro Dealers to transition into Community-Based Organizations (CBOs). This service helps Agro Dealers expand their operations to better support local communities, providing a wider range of services and fostering community development. By becoming CBOs, Agro Dealers can enhance their impact on agricultural productivity and local economies. Agri-business Network provides the necessary expertise and resources to ensure a smooth and successful transition."
  href2="auth/3/signup"
  buttoncont2="Register"
  onClick={() => {
    localStorage.setItem("userType", "CBO");
  }}
  />
  );
  
  const PMSection = (
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="GBR Farming offers dedicated loan services to agri-contract manufacturers, providing crucial financial support for machinery acquisition and operational optimization in the agricultural industry.
  "
  
  />
  );
  







  
  const LPSection = (
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="  GBR Farming enables agri-transporters to access tailored loan services, providing essential financial support for optimizing their transportation operations within the agricultural sector.

  "
  
  />
  );
  return (
    
    <>

    <UpdatesBanner
        className="business-growth-bg"
        bannerText={["Business Diversification Service"]}
      />
      <div className="subSectionWrapper" >
      {/* <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService5"
        descriptionTitle="
      Loan"
      description="GBR Farming facilitates a comprehensive range of financial services, including asset loans for agricultural fields and processing assets. Our loan services are designed to provide essential financial support to Agri-Entrepreneur, enabling them to acquire the necessary assets for their operations. Whether you're looking to expand your agricultural holdings or invest in processing equipment, GBR Farming is here to help you secure the capital you need for a prosperous and sustainable future in agriculture."

      /> */}

{farmEnterpriseSection }
   
      
      </div>
    </>
  )
}
