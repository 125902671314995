import React, { Component } from 'react';
import { Line } from "react-chartjs-2";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row, Container,
} from 'reactstrap';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../style.css';
import './Layout.css';
import AuthAxiosInstance from '../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance, EMSServcieAxiosInstance } from '../../../authAxiosInstance'
import Category from './containers/DeviceCategory/DeviceCategory';
import socketIOClient from "socket.io-client";
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import { NotificationManager } from "react-notifications";

class CompareProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showspinner: false,
      data: {},
      deviceList: [],
      selectDevId: 0,
      listProperty: [],
      selectedProperty: [],
      date: {},
      validDate: {},
      selectedDevices: [],
      
    };
  }

  getAppConfig = () => {
    return window.AppConfigData;
  };

  componentDidMount() {
    //this.getlayoutlist();
    const socket = socketIOClient((this.getAppConfig().ApiUrlMapp.SocketIOURL));

    socket.on("livedata", (livedata) => {
      try{
      const filterData = this.state.deviceList.filter((d) => (d.deviceId ==  this.state.selectedDevices[this.state.selectedDevices.length - 1]));
      const userId = localStorage.getItem("user-id");
      const { user } = MainService.getLocalData();
      const { username, parentId } = user;
      
      if (filterData.length > 0) {
        if(username === livedata.networkid.toString()){
        //if (filterData[0].deviceType === livedata.devicetype) {
          let selectedProperty = [ ...this.state.selectedProperty ];
          let currentlist = [...this.state.selectedDevices]
          let data = {...this.state.data} ;
          if(selectedProperty.length > 0){
          let propnamearray = [];
          switch(livedata.devicetype) {
            case 'AHUG':
              propnamearray = livedata.AHUGproperty;
              break;
            case 'EMMW':
              propnamearray = livedata.EMMW;
              break;
            case 'CCN':
              propnamearray = livedata.CHILLER[0].CHILLERproperty;
              break;
            case 'LG':
              propnamearray = livedata.THS[0].THSproperty;
              break;
            case 'TCN':
              propnamearray = livedata.Tstat[0].Tstatproperty;
              break;
            case 'VCN':
              propnamearray = livedata.VFD[0].VFDproperty;
              break;
            case 'EMG':
              propnamearray = livedata.EMG[0].EMproperty;
              break;
            default:
              propnamearray = livedata.properties;
              break;
              // code block
          }
          selectedProperty.map((d, i) => {
            propnamearray.map((da) => {
             
              if (d === da.property) {
                if(Object.keys(data).length > 0 && data.datasets.length > 0){
                  const index = data.datasets.findIndex((element, index) => {
                    let a = element.label.split(' in (');
                    if (a[0].split('->')[0] === livedata.deviceid.toString()) {
                      return true
                    }
                  });

                  data.datasets[index].data = [...data.datasets[index].data];

                  if(data.datasets[index].data.length === 0){
                    for(let i=0; i<data.labels.length; i++){
                      data.datasets[index].data.push('-');
                    }
                  }
                 
                  data.datasets[index].data.push(da.value);
                  data.labels = [...data.labels];
                 
                  var current = new Date();
                  let time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
                  if((data.datasets[index].data.length-1) % currentlist.length === 0 ){
                  data.labels.push(time);
                  }
                }
              }
            })
          })

          this.setState({ data }? { data } : { });

          
            
            }
          //}
        }
      }
      }
      catch(cerror){
        console.error('An error occurred:', cerror.message);

        // toast.error("Something went wrong.", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");
      }
    });

    this.loadDeviceList();
  }


  loadDeviceList() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    const userId = localStorage.getItem("user-id");
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/list`).then((resp) => {
      if(resp.data)
          this.setState({ deviceList: resp.data });
      else
          this.setState({ deviceList: [] });
    }).catch((err) => {

    })
  }

  selectDevice(dev_id) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    try{
    this.setState({ selectedProperty: [] });
    if(document.getElementsByName('radio').length > 0){
      for(let i=0; i<document.getElementsByName('radio').length; i++){
        document.getElementsByName('radio')[i].checked = false;
      }
    }
    const {selectedDevices} = this.state;
    if (selectedDevices.includes(dev_id)) {
      //Perform Remove operation
      const ind = selectedDevices.indexOf(dev_id);
      selectedDevices.splice(ind, 1);
    } else {
      //perform insert operation
      selectedDevices.push(dev_id);
    }
    this.setState({selectedDevices});

    if (this.state.selectedDevices.length > 0 && this.state.selectedDevices.length === 1) {
      this.setState({showspinner: true});
      const filterDevice = this.state.deviceList.filter((d) => (d.deviceId ==  selectedDevices[selectedDevices.length - 1]));
      //AuthAxiosInstance.get(`/ems/livedata/${networkId.network}/${selectedDevices[selectedDevices.length - 1]}/properties?deviceType=${filterDevice[0].deviceType}`)
      DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${filterDevice[0].deviceId}/metadata?deviceType=${filterDevice[0].deviceType}&model=${filterDevice[0].model}`)
        .then((res) => {
        
        this.setState({showspinner: false});
        if (res.data && res.data !== 'failed' && res.data !== 'error') {
          if (filterDevice[0].deviceType == "AHUG") {
            this.setState({ listProperty: res.data.AHUGproperty ? res.data.AHUGproperty : [] }, () => {
              console.log(this.state.listProperty);
            });
          }
          else if (filterDevice[0].deviceType == "EMMW") {
            this.setState({ listProperty: res.data.EMMW ? res.data.EMMW : [] }, () => {
              console.log(this.state.listProperty);
            });
          }
          else if (filterDevice[0].deviceType == "CCN") {
            this.setState({ listProperty: res.data.CHILLER[0].CHILLERproperty ? res.data.CHILLER[0].CHILLERproperty : [] }, () => {
              console.log(this.state.listProperty);
            });
          }
          else if (filterDevice[0].deviceType == "LG") {
            this.setState({ listProperty: res.data.THS[0].THSproperty ? res.data.THS[0].THSproperty : [] }, () => {
              console.log(this.state.listProperty);
            });
          }


          else if (filterDevice[0].deviceType == "TCN") {
            this.setState({ listProperty: res.data.Tstat[0].Tstatproperty ?res.data.Tstat[0].Tstatproperty : []}, () => {
              console.log(this.state.listProperty);
            });
          }
          else if (filterDevice[0].deviceType == "VCN") {
            this.setState({ listProperty: res.data.VFD[0].VFDproperty ? res.data.VFD[0].VFDproperty : [] }, () => {
              console.log(this.state.listProperty);
            });
          }
          else if (filterDevice[0].deviceType == "EMG") {
            this.setState({ listProperty: res.data.EMG[0].EMproperty ? res.data.EMG[0].EMproperty : [] }, () => {
              console.log(this.state.listProperty);
            });
          }
          else  {
            this.setState({ listProperty: res.data.properties ? res.data.properties : [] }, () => {
              console.log(this.state.listProperty);
            });
          }
         
        }
        else{
          this.setState({listProperty: []});
          // toast.error("Data not found.", {
          //   position: toast.POSITION.TOP_RIGHT
          // });
          return NotificationManager.error("Data not found.");
        }

      }).catch((err) => {
        this.setState({showspinner: false});
        this.setState({listProperty: []});
        // toast.error("Some error occurred.", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");
      });
    }
    else if(this.state.selectedDevices.length > 1){
      let arrToCmp = [];
      let devicelength = this.state.selectedDevices.length;
      let count = 0;
      const { user } = MainService.getLocalData();
      const { username, parentId } = user;
      
      for(let i=0; i< this.state.selectedDevices.length; i++){
        const filterDevicecmp = this.state.deviceList.filter((d) => (d.deviceId ==  selectedDevices[i]));
        //AuthAxiosInstance.get(`/ems/livedata/${networkId.network}/${selectedDevices[i]}/properties?deviceType=${filterDevicecmp[0].deviceType}`).then((res) => {
          DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${filterDevicecmp[0].deviceId}/metadata?deviceType=${filterDevicecmp[0].deviceType}&model=${filterDevicecmp[0].model}`)
          .then((res) => {  
          
          if (res.data) {
            if (filterDevicecmp[0].deviceType == "AHUG") {
              //this.setState({ listProperty: res.data.AHUGproperty });
              if(arrToCmp.length == 0){
                arrToCmp = res.data.AHUGproperty;
              }
              else{
                arrToCmp = arrToCmp.concat(res.data.AHUGproperty);
              }
              count++;
            }
            else if (filterDevicecmp[0].deviceType == "EMMW") {
              //this.setState({ listProperty: res.data.AHUGproperty });
              if(arrToCmp.length == 0){
                arrToCmp = res.data.EMMW;
              }
              else{
                arrToCmp = arrToCmp.concat(res.data.EMMW);
              }
              count++;
            }
            
            else if (filterDevicecmp[0].deviceType == "CCN") {
              if(arrToCmp.length == 0){
                arrToCmp = res.data.CHILLER[0].CHILLERproperty;
              }
              else{
                arrToCmp = arrToCmp.concat(res.data.CHILLER[0].CHILLERproperty);
              }
              count++;
            }

            else if (filterDevicecmp[0].deviceType == "LG") {
              if(arrToCmp.length == 0){
                arrToCmp = res.data.THS[0].THSproperty;
              }
              else{
                arrToCmp = arrToCmp.concat(res.data.THS[0].THSproperty);
              }
              count++;
            }
  
            else if (filterDevicecmp[0].deviceType == "TCN") {
              if(arrToCmp.length == 0){
                arrToCmp = res.data.Tstat[0].Tstatproperty;
              }
              else{
                arrToCmp = arrToCmp.concat(res.data.Tstat[0].Tstatproperty);
              }
              count++;
            }
            else if (filterDevicecmp[0].deviceType == "VCN") {
              if(arrToCmp.length == 0){
                arrToCmp = res.data.VFD[0].VFDproperty;
              }
              else{
                arrToCmp = arrToCmp.concat(res.data.VFD[0].VFDproperty);
              }
              count++;
            }
            else if (filterDevicecmp[0].deviceType == "EMG") {
              if(arrToCmp.length == 0){
                arrToCmp = res.data.EMG[0].EMproperty;
              }
              else{
                arrToCmp = arrToCmp.concat(res.data.EMG[0].EMproperty);
              }
              count++;
            }
            else{
              if(arrToCmp.length == 0){
                arrToCmp = res.data.properties;
              }
              else{
                arrToCmp = arrToCmp.concat(res.data.properties);
              }
              count++;
            }
           
          }
          else{
            count++;
          }

          if(count == devicelength){
            const values = arrToCmp;
          //   const lookup = values.reduce((a, e) => {
          //    a[e.property] = ++a[e.property] || 0;
          //    return a;
          //  }, {});
           
          //  let commonprop = values.filter(e => lookup[e.property]);
          //  let unique = [...new Set(commonprop.map(a => a.property))];

          let dupList = [];
          let uniqueList = [];
          Array.prototype.contains = function(item){
            let filtered_item = this.filter((i) => {
              return i.property === item.property
            });
            return !!filtered_item.length;
          }
          
          function contains(list, item){
            let filtered_item = list.filter((i) => {
              return i.property === item.property
            });
            return !!filtered_item.length;
          }

          function pushToUniqueList(item){
            if(!uniqueList.contains(item)) uniqueList.push(item);
          }
          
          function pushToDuplicateList(item){
            if(!dupList.contains(item)) dupList.push(item);
          }
          
          for(let i = 0; i < values.length; i++){
            if(uniqueList.contains(values[i])){
              pushToDuplicateList(values[i]);
            } else {
              pushToUniqueList(values[i]);
            }
          }
           this.setState({ listProperty: dupList});
          }
  
        }).catch((err) => {
  
        });
      }

    }
    
    else {
      this.setState({listProperty: []});
    }
  }
  catch(cerror){
    // toast.error("Something went wrong.", {
    //   position: toast.POSITION.TOP_RIGHT
    // });
    return NotificationManager.error("Some error occured");
  }

  }

  selectProperty(evt) {
   
    this.getHistoryData(evt);
    this.setState({ selectedProperty: [evt] });
    
  }


  getHistoryData(property) {
    try{
    this.setState({showspinner: true, data: {}});
    let devlength = this.state.selectedDevices.length;
    let graphdata = [];
    let currentdate = new Date();
    let todate = currentdate.toISOString().split('T')[0]+'T23:59:59';;
    currentdate.setDate(currentdate.getDate() - 2);
    let fromdate = currentdate.toISOString();
    let count = 0;
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    
    const filterproperty = this.state.listProperty.filter((d) => (d.property == property));
    for(let i=0; i< devlength; i++){
    let labels = [];
    const filterDevice = this.state.deviceList.filter((d) => (d.deviceId ==  this.state.selectedDevices[i]));
    EMSServcieAxiosInstance.get(`/ems/history/${username}/${filterDevice[0].deviceId}?deviceType=${filterDevice[0].deviceType}&property=${property}&fromDate=${fromdate}&toDate=${todate}`)
      .then((res) => {
            this.setState({showspinner: false});
            count++;
            const dataset = [];
            

            if(res.data && res.data.length > 0){
            res.data.sort(function(a, b) {
              var c = new Date(a.updatedat);
              var d = new Date(b.updatedat);
              return d-c;
            });
            if (filterDevice[0].deviceType === "AHUG") {
              const index = res.data[0].AHUGproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              if(isNaN(res.data[0].AHUGproperty[0].value)){
                // toast.info("The value is not in correct format. So, cannot plot the graph.", {
                //   position: toast.POSITION.TOP_RIGHT
                // });
                return NotificationManager.error("The value is not in correct format. So, cannot plot the graph.");
              }
              //var labels = [];
              res.data.slice(0, 100).map((d) => {
                let date = new Date(d.updatedat);
                let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                labels.push(decideDate);
                dataset.push(d.AHUGproperty[index].value);
              });
            } 
            else if (filterDevice[0].deviceType === "EMMW") {
              const index = res.data[0].EMMW.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              if(isNaN(res.data[0].EMMW[0].value)){
                // toast.info("The value is not in correct format. So, cannot plot the graph.", {
                //   position: toast.POSITION.TOP_RIGHT
                // });
                return NotificationManager.error("The value is not in correct format. So, cannot plot the graph");
              }
              //var labels = [];
              res.data.slice(0, 100).map((d) => {
                let date = new Date(d.updatedat);
                let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                labels.push(decideDate);
                dataset.push(d.EMMW[index].value === "-" ? 0 : d.EMMW[index].value);
              });
            } 
            else if(filterDevice[0].deviceType === "CCN") {
              //var labels = [];
              const index = res.data[0].CHILLER[0].CHILLERproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              if(isNaN(res.data[0].CHILLER[0].CHILLERproperty[0].value)){
                // toast.info("The value is not in correct format. So, cannot plot the graph.", {
                //   position: toast.POSITION.TOP_RIGHT
                // });
              }
              res.data.slice(0, 100).map((d) => {
                let date = new Date(d.updatedat);
                let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                labels.push(decideDate);
                dataset.push(d.CHILLER[0].CHILLERproperty[index].value);
              });
            }
            else if(filterDevice[0].deviceType === "LG") {
              //var labels = [];
              const index = res.data[0].THS[0].THSproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              if(isNaN(res.data[0].THS[0].THSproperty[0].value)){
                // toast.info("The value is not in correct format. So, cannot plot the graph.", {
                //   position: toast.POSITION.TOP_RIGHT
                // });
              }
              res.data.slice(0, 100).map((d) => {
                let date = new Date(d.updatedat);
                let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                labels.push(decideDate);
                dataset.push(d.THS[0].THSproperty[index].value);
              });
            }
            else if(filterDevice[0].deviceType === "TCN"){
              //var labels = [];
              const index = res.data[0].Tstat[0].Tstatproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              if(isNaN(res.data[0].Tstat[0].Tstatproperty[0].value)){
                // toast.info("The value is not in correct format. So, cannot plot the graph.", {
                //   position: toast.POSITION.TOP_RIGHT
                // });
              }
              res.data.slice(0, 100).map((d) => {
                let date = new Date(d.updatedat);
                let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                labels.push(decideDate);
                dataset.push(d.Tstat[0].Tstatproperty[index].value);
              });
            }
            else if(filterDevice[0].deviceType === "VCN"){
              //var labels = [];
              const index = res.data[0].VFD[0].VFDproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              if(isNaN(res.data[0].VFD[0].VFDproperty[0].value)){
                // toast.info("The value is not in correct format. So, cannot plot the graph.", {
                //   position: toast.POSITION.TOP_RIGHT
                // });
              }
              res.data.slice(0, 100).map((d) => {
                let date = new Date(d.updatedat);
                let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                labels.push(decideDate);
                dataset.push(d.VFD[0].VFDproperty[index].value);
              });
            }
            else if(filterDevice[0].deviceType === "EMG"){
              //var labels = [];
              const index = res.data[0].EMG[0].EMproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              if(isNaN(res.data[0].EMG[0].EMproperty[0].value)){
                // toast.info("The value is not in correct format. So, cannot plot the graph.", {
                //   position: toast.POSITION.TOP_RIGHT
                // });
              }
              res.data.slice(0, 100).map((d) => {
                let date = new Date(d.updatedat);
                let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                labels.push(decideDate);
                dataset.push(d.EMG[0].EMproperty[index].value);
              });
            }
            else {
              //var labels = [];
              const index = res.data[0].properties.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              if(isNaN(res.data[0].properties[0].value)){
                // toast.info("The value is not in correct format. So, cannot plot the graph.", {
                //   position: toast.POSITION.TOP_RIGHT
                // });
              }
              res.data.slice(0, 100).map((d) => {
                let date = new Date(d.updatedat);
                let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                labels.push(decideDate);
                dataset.push(d.properties[index].value);
                
              });
            }
          }
          else{
            // toast.info(`Data not found for ${filterDevice[0].deviceName}. If live data available, it will start displaying.`, {
            //   position: toast.POSITION.TOP_RIGHT
            // });
          }

            let obj = {};
            obj.label = filterDevice[0].deviceId + '->' + filterDevice[0].deviceName + (filterproperty[0].unit ? ` in (${filterproperty[0].unit})` : '');
            obj.fill = false;
            obj.lineTension = 0.5;
            obj.borderColor = `rgba(${Math.floor(Math.random()*256)},${Math.floor(Math.random()*256)},${Math.floor(Math.random()*256)}, 1)`;
            obj.backgroundColor = obj.borderColor;
            obj.borderWidth = 2;
            obj.data = dataset;
            obj.pointRadius = 0.2;
            graphdata.push(obj);
            if(count == devlength){
              const data = {
                labels: labels,
                datasets: graphdata
              }
              this.setState({ data });
            }
            
            
      }).catch((err) => {
        this.setState({showspinner: false});
        // toast.error('Something went wrong.', {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");
      });
    }

  }
  catch(cerror){
    // toast.error("Something went wrong.", {
    //   position: toast.POSITION.TOP_RIGHT
    // });
    return NotificationManager.error("Some error occured");
  }
  }




  render() {
   
    return (
      <div className="animated fadeIn" >
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <i className="fa fa-list-alt"></i>
                <strong className="LabelHeader">Compare Live Data</strong>
                <div className="card-header-actions">
                  {/* <Button className="float-right badge badge-warning badge-pill" >Edit &nbsp;<i className="fa fa-edit float-right"></i></Button> */}
                </div>
              </CardHeader>
              <CardBody>
                   <Row>
                      <Col md="3">
                        <Category 
                        devices={this.state.deviceList} 
                        properties={this.state.listProperty} 
                        selectDevice={this.selectDevice.bind(this)}
                        selectProperty={this.selectProperty.bind(this)}
                        devicetype='checkbox'
                        propertytype='radio'/>
                      </Col>
                      {!this.state.showspinner &&
                        <Col md="9">
                            
                          {this.state.selectedProperty.length > 0 && this.state.listProperty.length > 0 &&
                          <Line
                          data={this.state.data}
                          options={{
                            title: {
                              display: true,
                              fontSize: 20
                    
                            },
                            legend: {
                              display: true,
                              position: 'right'
                            },
                            scales: {
                              yAxes: [{
                                scaleLabel: {
                                  display: true,
                                  labelString: '',
                                  fontColor: 'black' // Set the font color to black
                                },
                                ticks: {
                                  fontColor: 'black' // Set the tick font color to black
                              }
                              }],
                              xAxes: [{
                                scaleLabel: {
                                  display: true,
                                  labelString: 'Date/time',
                                  fontColor: 'black' // Set the font color to black
                                },
                                ticks: {
                                  fontColor: 'black' // Set the tick font color to black
                              }
                              }],
                            } 
                          }}
                        />
                          } 
                        
                          
                        </Col>
                      }
                    
                      {this.state.showspinner &&
                        <div className="sk-cube-grid">
                          <div className="sk-cube sk-cube1"></div>
                          <div className="sk-cube sk-cube2"></div>
                          <div className="sk-cube sk-cube3"></div>
                          <div className="sk-cube sk-cube4"></div>
                          <div className="sk-cube sk-cube5"></div>
                          <div className="sk-cube sk-cube6"></div>
                          <div className="sk-cube sk-cube7"></div>
                          <div className="sk-cube sk-cube8"></div>
                          <div className="sk-cube sk-cube9"></div>
                        </div>
                      }
                    
                     
                   </Row>
                
                
              </CardBody>
            </Card>

          </Col>
        </Row>

        
      </div>
    );
  }
}

export default CompareProperty;
