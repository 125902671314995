import React from 'react'
import StepCards from '../stepCards/StepCards'
// import step1 from "../../images/ae-journey/ae1.png";
import step2 from "../../images/ae-journey/ae2.png";

import step3 from "../../images/step2.png";
// import step4 from "../../images/ae-journey/ae3.png";
// import step5 from "../../images/ae-journey/ae4.png";
// import img5 from "../../images/ae-journey/ae5.png";

import step1 from "../../images/FarmersJourney/phone-call (1).svg";
// import step2 from "../../images/FarmersJourney/download.png";
// import step3 from "../../images/FarmersJourney/account.png";
import step4 from "../../images/FarmersJourney/buy.svg";
import step5 from "../../images/FarmersJourney/sales.svg";
import img5 from "../../images/FarmersJourney/quality.svg";
// import img1 from "../../images/optionalChoice.png";
import { Row, Col } from "react-bootstrap";
import { Container } from '@material-ui/core';

export default function FarmerFranchiseJourney() {
  const stepCardContent = [
    {
      title: "Step 1",
      bodycopy: " Contact a GBR agent to initiate an account or request the creation of an account.",
      img: step1,
    },
    {
      title: "Step 2",
      bodycopy: "Provide the required identification and address proof documents to finalize your account creation.",
      img: step2,
    },
    {
      title: "Step 3",
      bodycopy: "Download the application and log in to your account.",
      img: step3,
    },
    {
      title: "Step 4",
      bodycopy:
        "Start interacting with GBR Asset management company for their needs listed in the app & start earning.",
      img: step5,
    },
    // {
    //   title: "Step 5",
    //   bodycopy:
    //   "Become a approved Grower",
    //   img: img5,
    // },
    // {
    //     title: "Step 6",
    //     bodycopy:
    //       "Raise request for Profit-generating assets and start earning",
    //     img: step5,
    //   },
  ];
  return (
    <Container>
    <h2 className="text-center container-title mb-4">
    Grower Journey
  </h2>
    <div className='section3BodyContainer justify-content-md-center p-0'>
         
    {stepCardContent.map((e) =>{
               return (
                <StepCards
                  src={e.img}
                  stepTitle={e.title}
                  stepContent={e.bodycopy}
                />
              );
        })}    </div>
        </Container>
  )
}
