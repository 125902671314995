// DonutChart.js
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import Highcharts from 'highcharts';

const DonutChart = ({ updatedDevices, notUpdatedDevices, totalDevices }) => {
  const config = {
    chart: {
      type: 'pie',
      height: 400, // Set the height, default to 300
      width: 500,   // Set the width, default to 400
    },
    title: {
      text: 'Firmware Status',
    },
    plotOptions: {
      pie: {
        animation: false, // Disable animation
      },
    },
    series: [
      {
        name: 'Devices',
        data: [
          { name: 'Updated Devices', y: updatedDevices },
          { name: 'Not Updated Devices', y: notUpdatedDevices },
        ],
      },
    ],
  };

  return <ReactHighcharts config={config} highcharts={Highcharts} />;
};

export default DonutChart;
