import { Container } from "@material-ui/core";
import React, { useState } from "react";
import Tabs from "react-simply-tabs";
import AnimalCare from "../ForManufactures/AnimalCare";
import PlantCare from "./PlantCare";

export default function PlantAnimalCareProducts() {
  const [activeTabIndex, setTabIndex] = useState(0);

  return (
    <div className="Iaa-tabs Iaa-manufacturer-tabs">
      <h2 className="container-title text-center">Contract Manufacturing Services</h2>
      <p className="container-para mx-4 text-center mb-5">
       
      </p>

      <Tabs
        id="test"
        activeTabIndex={activeTabIndex}
        activeControlProps={{
          className: "active",
        }}
        onRequestChange={setTabIndex}
        controls={[
          <button type="button" title="" className="Iaa-tab-buttons">
Animal Care
          </button>,
          <button type="button" title="" className="Iaa-tab-buttons">
            Plant Care           
          </button>,
        ]}
      >
        <div className="mt-5">
        <div id="ExploreIAA" className="  mb-0">

            <AnimalCare/>
          </div>
        </div>

        <div className="mt-5">
          {" "}
          <div id="ExploreIAA" className="  mb-0">
          <PlantCare/>
          </div>
        </div>
      </Tabs>
    </div>
  );
}
