import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";
import MainService from "../MainService";
import { NotificationManager } from "react-notifications";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 1,
  p: 4,
};

const UserFeedbackComponent = (props) => {
  //const [open, setOpen] = React.useState(props.userFeedbackDialog);
  const [usernameFromParent, setUSernameForParent] = useState(props.username);
  const [userTypeFromParent, setUserTypeFromParent] = useState(
    props.userTypeValue
  );
  const [userFeedbackDialog, setUserFeedbackDialog] = useState(
    props.userFeedbackDialog
  );
  const [userFromParent, setUserFromParent] = useState(props.user);
  const handleOpen = () => setUserFeedbackDialog(true);
  // const closeFeedbackDialog = () => setUserFeedbackDialog(false);

  const [customerRate, setCustomerRate] = React.useState(0);
  const [customerRateHover, setCustomerRateHover] = React.useState(-1);
  const [customerExp, setcustomerExp] = useState("");
  const [politenessRate, setPolitenessRate] = React.useState(0);
  const [politenessRateHover, setPolitenessRateHover] = React.useState(-1);
  const [politenessExp, setPolitenessExp] = useState("");
  const [punctualityRate, setpunctualityRate] = React.useState(0);
  const [punctualityRateHover, setpunctualityRateHover] = React.useState(-1);
  const [punctualityExp, setPunctualityExp] = useState("");
  const [remarks, setRemarks] = useState("");

  const labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  function getLabelTextUser(customerRate) {
    return `${customerRate} Star${customerRate !== 1 ? "s" : ""}, ${
      labels[customerRate]
    }`;
  }
  function getLabelTextPoliteness(punctualityRate) {
    return `${punctualityRate} Star${punctualityRate !== 1 ? "s" : ""}, ${
      labels[punctualityRate]
    }`;
  }
  function getLabelTextPoliteness(value) {
    return `${politenessRate} Star${politenessRate !== 1 ? "s" : ""}, ${
      labels[politenessRate]
    }`;
  }

  const userFeedbackSubmit = () => {
    let addFeedback = {
      executiveid: props.user,
      "how_would_you_rate_your_overall_customer_experience?": customerExp,
      "how_satisfied_were_you_with_the_operator_punctuality_in_the_field?":
        punctualityExp,
      "how_satisfied_were_you_with_politeness_with_people?": politenessExp,
      remark: remarks,
    };
    console.log("feedbacl post data:", addFeedback);
    const addFeedbackData = JSON.stringify(addFeedback);
    MainService.userFeedback(
      addFeedbackData,
      props.username,
      props.userTypeValue
    )
      .then((res) => {
        props.closeFeedbackDialog();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setUserFeedbackDialog(!userFeedbackDialog);
  };

  const textChangeHandler = (event) => {
    setRemarks(event.target.value);
  };
  return (
  
      <Dialog
        open={handleOpen}
        onClose={props.closeFeedbackDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>
        <h4>Send Feedback</h4>
        </DialogTitle>
        <DialogContent>
        <Typography color="textSecondary" gutterBottom>
          {props.user} Feedback
        </Typography>
        <br/>
        <Typography variant="body2" component="span">
          how would you rate your overall experience for the{" "}
          {props.userTypeValue}?: <br />
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Rating
              name="customer-feedback"
              value={customerRate}
              defaultValue={0}
              precision={0.5}
              getLabelText={getLabelTextUser}
              onChange={(event, customerRate) => {
                setCustomerRate(customerRate);
                setcustomerExp(labels[customerRate]);
              }}
              onChangeActive={(event, customerRateHover) => {
                setCustomerRateHover(customerRateHover);
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            {customerRate !== null && (
              <Box sx={{ ml: 2 }}>
                {
                  labels[
                    customerRateHover !== -1 ? customerRateHover : customerRate
                  ]
                }
              </Box>
            )}
          </Box>
        </Typography>
        <Typography variant="body2" component="span">
          how would you rate {props.userTypeValue} punctuality at the field?:{" "}
          <br />
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Rating
              name="Punctuality-feedback"
              value={punctualityRate}
              defaultValue={0}
              precision={0.5}
              getLabelText={getLabelTextPoliteness}
              onChange={(event, punctualityRate) => {
                setpunctualityRate(punctualityRate);
                setPunctualityExp(labels[punctualityRate]);
              }}
              onChangeActive={(event, punctualityRateHover) => {
                setpunctualityRateHover(punctualityRateHover);
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            {punctualityRate !== null && (
              <Box sx={{ ml: 2 }}>
                {
                  labels[
                    punctualityRateHover !== -1
                      ? punctualityRateHover
                      : punctualityRate
                  ]
                }
              </Box>
            )}
          </Box>
        </Typography>
        <Typography variant="body2" component="span">
          how would you rate {props.userTypeValue} politeness with people?:{" "}
          <br />
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Rating
              name="politeness-feedback"
              value={politenessRate}
              defaultValue={0}
              precision={0.5}
              getLabelText={getLabelTextPoliteness}
              onChange={(event, politenessRate) => {
                setPolitenessRate(politenessRate);
                setPolitenessExp(labels[politenessRate]);
              }}
              onChangeActive={(event, politenessRateHover) => {
                setPolitenessRateHover(politenessRateHover);
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            {politenessRate !== null && (
              <Box sx={{ ml: 2 }}>
                {
                  labels[
                    politenessRateHover !== -1
                      ? politenessRateHover
                      : politenessRate
                  ]
                }
              </Box>
            )}
          </Box>
        </Typography>
        <br />
        
        {/* <Typography variant="body2" component="span">
          Remarks: <br />
          <TextareaAutosize
            minRows={5}
            aria-label="minimum height"
            placeholder="Any remarks"
            style={{ minWidth: "260px" }}
            value={remarks}
            onChange={(e)=>{textChangeHandler(e)}}
          />
        </Typography> */}
        </DialogContent>
        <br />
        <DialogActions>
        <Button
          style={{ marginRight: "10px" }}
          variant="contained"
          color="primary"
          className="registerBtn"
          type="button"
          onClick={() => userFeedbackSubmit()}
        >
          Submit
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={props.closeFeedbackDialog}
        >
          Close
        </Button>
        </DialogActions>
      </Dialog>
    
  );
};

export default UserFeedbackComponent;
