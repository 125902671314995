import React from "react";
import { getFormattedTimestamp } from "../RFQCommon";

const initialState = {
  personalDetails: {
    networkId: "",
    parentId: "",
    username: "",
    no: "",
    siteName: "",
    name: "",
    phoneNo: Number(),
    emailId: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: Number(),
    panNumber: "",
    landmark: "",
    latitude: Number(),
    longitude: Number(),
  },
  companyAdditionalInformation: {
    companyName: "",
    businessType: "",
    country: "",
    yearEstablished: "",
    numberOfEmployees: "",
    annualSalesRevenue: "",
    companyWebite: "",
    myCompanyHasNoWebite: "",
    panNumber: "",
    gstin_Number: "",
    companyDescription: "",
  },
  deliveryAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    pincode: "",
    state: "",
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  supplierDetails: {
    supplierId: "",
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    pincode: "",
    state: "",
    emailId: "",
    phoneNo: "",
    latitude: Number(),
    longitude: Number(),
    panNumber: Number(),
    gstin_Number: "",
  },
  growerFarmingSiteAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    pincode: Number(),
    state: "",
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  assetDetails: [
    {
      assetVerticalId: "",
      assetVerticalFriendlyName: "",
      category: "",
      legacyAssetType: "",
      modelId: "",
      cn: "",
      hsn: "",
      origin: "",
      description: "",
      quantity: "",
      unit: "",
      grade: "",
    },
  ],
  sourcingCountries: {
    originCountries: [],
    excludeCountry: [],
    preferredCountry: "",
    nonNegotiable: "",
  },
  packagingTypes: {
    packingInstructions: "",
    labelInstructions: "",
  },
  logisticsTerms: {
    deliveryTerms: "",
    portOfDestination: "",
    targetShippmentDate: "",
    transport: "",
  },
  paymentTerms: {
    paymentTerms: "",
    detailedPaymentTerms: "",
    paymentToBeMadeBy: "",
  },
  requirements: {
    assetPackage: "",
    provenance: "",
    agreement: "",
    organicCertificate: "",
    qualityGuidelineLink: "",
    tenderLink: "",
    optionalDocumentLink: "",
    fromDate: "",
    toDate: "",
  },
  requestType: "",
  timeStamp: getFormattedTimestamp(),
};

const transportasset = {
  personalDetails: {
    networkId: "",
    parentId: "",
    username: "",
    no: "",
    siteName: "",
    name: "",
    phoneNo: Number(),
    emailId: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: Number(),
    panNumber: "",
    landmark: "",
    latitude: Number(),
    longitude: Number(),
  },
  companyAdditionalInformation: {
    companyName: "",
    businessType: "",
    country: "",
    yearEstablished: "",
    numberOfEmployees: "",
    annualSalesRevenue: "",
    companyWebite: "",
    myCompanyHasNoWebite: Boolean(),
    panNumber: "",
    gstin_Number: "",
    companyDescription: "",
  },
  deliveryAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    pincode: Number(),
    state: "",
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  supplierDetails: {
    supplierId: "",
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    pincode: "",
    emailId: "",
    phoneNo: "",
    latitude: Number(),
    longitude: Number(),
    panNumber: "",
    gstin_Number: "",
  },
  pickUpAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: Number(),
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  assetDetails: [
    {
      assetVerticalId: "",
      assetVerticalFriendlyName: "",
      category: "",
      legacyAssetType: "",
      modelId: "",
      cn: "",
      hsn: "",
      description: "",
      grade: "",
      origin: "",
      quantity: "",
      unit: "",
    },
  ],
  transportDetails: {
    transportType: "",
    modeOfTransport: "",
    preferredTransportCompany: "",
  },
  packagingTypes: {
    packingInstructions: "",
    labelInstructions: "",
  },
  requirements: {
    assetPackage: "",
    vehicleInsurance: "",
    agreement: "",
    transportGuideLineLink: "",
    optionalDocumentLink: "",
    fromDate: "",
    toDate: "",
  },
  requestType: "",
  timeStamp: getFormattedTimestamp(),
};

const storageasset = {
  personalDetails: {
    networkId: "",
    parentId: "",
    username: "",
    no: "",
    siteName: "",
    name: "",
    phoneNo: Number(),
    emailId: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: Number(),
    panNumber: "",
    landmark: "",
    latitude: Number(),
    longitude: Number(),
  },
  companyAdditionalInformation: {
    companyName: "",
    businessType: "",
    country: "",
    yearEstablished: "",
    numberOfEmployees: "",
    annualSalesRevenue: "",
    companyWebite: "",
    myCompanyHasNoWebite: Boolean(),
    panNumber: "",
    gstin_Number: "",
    companyDescription: "",
  },
  deliveryAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    latitude: Number(),
    longitude: Number(),
  },
  supplierDetails: {
    supplierId: "",
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    pincode: "",
    emailId: "",
    phoneNo: "",
    latitude: Number(),
    longitude: Number(),
    panNumber: "",
    gstin_Number: "",
  },
  pickUpAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    country: "",
    state: "",
    pincode: Number(),
    latitude: Number(),
    longitude: Number(),
  },
  assetDetails: [
    {
      assetVerticalId: "",
      assetVerticalFriendlyName: "",
      category: "",
      legacyAssetType: "",
      modelId: "",
      cn: "",
      hsn: "",
      description: "",
      grade: "",
      origin: "",
      quantity: "",
      unit: "",
    },
  ],
  storageDetails: {
    city: "",
    preferredStartDate: "",
    expectedDurationOfStorage: "",
  },
  packagingTypes: {
    packingInstructions: "",
    labelInstructions: "",
  },
  requirements: {
    assetPackage: "",
    transport: "",
    storageInsurance: "",
    agreement: "",
    storageGuideLineLink: "",
    optionalDocumentLink: "",
    fromDate: "",
    toDate: "",
  },
  requestType: "",
  timeStamp: getFormattedTimestamp(),
};

const assetbuyrfqreq = {
  personalDetails: {
    networkId: "",
    parentId: "",
    username: "",
    no: "",
    siteName: "",
    name: "",
    phoneNo: Number(),
    emailId: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: Number(),
    panNumber: "",
    landmark: "",
    latitude: Number(),
    longitude: Number(),
  },
  companyAdditionalInformation: {
    companyName: "",
    businessType: "",
    country: "",
    yearEstablished: "",
    numberOfEmployees: "",
    annualSalesRevenue: "",
    companyWebite: "",
    myCompanyHasNoWebite: Boolean(),
    panNumber: "",
    gstin_Number: "",
    companyDescription: "",
  },
  deliveryAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    pincode: "",
    state: "",
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  pickUpAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    pincode: Number(),
    state: "",
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  supplierDetails: {
    supplierId: "",
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    emailId: "",
    phoneNo: "",
    latitude: Number(),
    longitude: Number(),
    panNumber: "",
    gstin_Number: "",
  },
  assetDetails: [
    {
      assetVerticalId: "",
      assetVerticalFriendlyName: "",
      category: "",
      legacyAssetType: "",
      modelId: "",
      cn: "",
      hsn: "",
      description: "",
      grade: "",
      origin: "",
      quantity: "",
      unit: "",
    },
  ],
  requirements: {
    assetPackage: "",
    optionalDocumentLink: "",
    weight: "",
    age: "",
    gender: "",
    orderType: "",
  },
  requestType: "",
  timeStamp: getFormattedTimestamp(),
};

export { initialState, transportasset, storageasset, assetbuyrfqreq };
