import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import Box from "@material-ui/core/Box";
import { Grid } from "@mui/material";
import { Card } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import sha256 from "sha256";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function PMCComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [logisticProvidersList, setLogisticProvidersList] = useState([]);
  const [logisticProviderUserId, setLogisticProviderUserId] = useState("");
  const [logisticProviderUserName, setLogisticProviderUserName] = useState("");
  const [logisticProviderAddress, setLogisticProviderAddress] = useState("");
  const [logisticProviderCity, setLogisticProviderCity] = useState("");
  const [logisticProviderFirstName, setLogisticProviderFirstName] =
    useState("");
  const [logisticProviderLastName, setLogisticProviderLastName] = useState("");
  const [logisticProviderPhone, setLogisticProviderPhone] = useState("");
  const [logisticProviderEnterpriceId, setLogisticProviderEnterpriceId] =
    useState("");
  const [logisticProviderUserType, setLogisticProviderUserType] = useState("");
  const [logisticProviderEmail, setLogisticProviderEmail] = useState("");
  const [logisticProviderDomain, setLogisticProviderDomain] = useState("");
  const [logisticProviderState, setLogisticProviderState] = useState("");
  const [logisticProviderNominee, setLogisticProviderNominee] = useState("");
  const [logisticProviderPancard, setLogisticProviderPancard] = useState("");
  const [logisticProviderLongitude, setLogisticProviderLongitude] =
    useState("");
  const [logisticProviderLatitude, setLogisticProviderLatitude] = useState("");
  const [logisticProviderMessage, setLogisticProviderMessage] = useState("");
  const [logisticProviderStatus, setLogisticProviderStatus] = useState("");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [emailValue, setEmailValue] = React.useState("");
  const [pwdValue, setPwdValue] = React.useState("unaas");
  const [messageValue, setMessageValue] = React.useState("");
  const [logisticProviderEmailDialog, setLogisticProviderEmailDialog] =
    React.useState(false);
  const [subjectValue, setSubjectValue] = React.useState("");
  const [logisticProviderSMSDialog, setLogisticProviderSMSDialog] =
    React.useState(false);
  const [phoneNumerValue, setPhoneNumerValue] = React.useState("");
  const [
    selectedLogisticProviderUsername,
    setSelectedLogisticProviderUserName,
  ] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredLogisticProviders, setFilteredLogisticProviders] = useState(
    []
  );
  const [searched, setSearched] = useState("");
  const [logisticProviderInfoDialog, setLogisticProviderInfoDialog] =
    useState(false);
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [mapView, setMapView] = useState(false);
  const [viewAllClick, setViewAllClick] = useState(true);
  const [viewAssociatedClick, setViewAssociatedClick] = useState(false);
/* Feedback  start*/
const [overallRate, setOverallRate] = React.useState(0);
const [overallRateHover, setOverallRateHover] = React.useState(-1);
const [deliveryRate, setDeliveryRate] = React.useState(0);
const [deliveryRateHover, setDeliveryRateHover] = React.useState(-1);
const [customerRate, setCustomerRate] = React.useState(0);
const [customerRateHover, setCustomerRateHover] = React.useState(-1);
const [productRate, setProductRate] = React.useState(0);
const [productRateHover, setProductRateHover] = React.useState(-1);
const [supportRate, setSupportRate] = React.useState(0);
const [supportRateHover, setSupportRateHover] = React.useState(-1);
const [othersRate, setOthersRate] = React.useState(0);
const [othersRateHover, setOthersRateHover] = React.useState(-1);
const [remarks, setRemarks] = useState("");
const [value, setValue] = React.useState(1);
const [hover, setHover] = React.useState(-1);
const [overallExp, setOverallExp] = useState("");
const [customerExp, setCustomerExp] = useState("");
const [deliveryExp, setDeliveryExp] = useState("");
const [productExp, setProductExp] = useState("");
const [supportExp, setSupportExp] = useState("");
const [othersExp, setOthersExp] = useState("");
const [executiveFeedbackDialog, setExecutiveFeedbackDialog] = useState(false);
const [selectedExecutiveUserId, setSelectedExecutiveUserId] = useState("");
const [selectedExecutiveName,setSelectedExecutiveName] = useState("");

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};
function getLabelText(overallRate) {
  return `${overallRate} Star${overallRate !== 1 ? "s" : ""}, ${labels[overallRate]}`;
}
function getLabelTextCustomer(customerRate) {
  return `${customerRate} Star${customerRate !== 1 ? "s" : ""}, ${
    labels[customerRate]
  }`;
}
function getLabelTextProduct(productRate) {
  return `${productRate} Star${productRate !== 1 ? "s" : ""}, ${
    labels[productRate]
  }`;
}
function getLabelTextSupport(supportRate) {
  return `${supportRate} Star${supportRate !== 1 ? "s" : ""}, ${
    labels[supportRate]
  }`;
}
function getLabelTextOthers(othersRate) {
  return `${othersRate} Star${othersRate !== 1 ? "s" : ""}, ${
    labels[othersRate]
  }`;
}

/* Feedback end */
/* Create Global User */

const [consumerUserNameValue, setConsumerUserNameValue] = useState("");
const [firstNameValue, setFirstNameValue] = React.useState("");
const [lastNameValue, setLastNameValue] = React.useState("");
const [userTypeValue, setUserTypeValue] = React.useState("PMC");
const [addressValue, setAddressValue] = useState("");
const [cityNameValue, setCityNameValue] = useState("");
const [stateNameValue, setStateNameValue] = useState("");
const [nomineeValue, setNomineeValue] = useState("");
const [pancardValue, setPancardValue] = useState("");
const [longitudeValue, setLongitudeValue] = useState("");
const [latitudeValue, setLatitudeValue] = useState("");
const [email, setEmail] = React.useState("");
const [consumerUserDialog, setConsumerUserDialog] = React.useState(false);
const createconsumerUsersubmit = () => {
  let encPwd = sha256(pwdValue);
  let consumerUserData = {
    username: consumerUserNameValue,
    firstname: firstNameValue,
    lastname: lastNameValue,
    usertype: userTypeValue,
    phone: phoneNumerValue,
    email: email,
    password:encPwd
  };
  const consumerUserDataString = JSON.stringify(consumerUserData);

  if (
    consumerUserNameValue === email &&
    firstNameValue &&
    lastNameValue &&
    userTypeValue &&
    phoneNumerValue
  ) {
    MainService.doSignup(
      consumerUserData
    )
      .then((res) => {
        getAllLogisticProviders();
        return NotificationManager.success("Signup successfully,to continue verify your e-mail");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  } else {
    return NotificationManager.error("Username and Email should be same");
  }

  setConsumerUserDialog(!consumerUserDialog);
};
/* End of Creatie global user */

  useEffect(() => {
    getAllLogisticProviders();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* View All PMC */
  const getAllLogisticProviders = () => {
    MainService.getGlobalUsers("PMC")
      .then((res) => {
        setLogisticProvidersList(res);
        setFilteredLogisticProviders(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  /* Logistic Provider info Dialog */
  const logisticProviderViewInfoDialogClick = (logicProvider) => {
    MainService.getLogisticProviderInfo(logicProvider)
      .then((res) => {
        setLogisticProviderUserId(res[0].userid);
        setLogisticProviderUserName(res[0].username);
        setLogisticProviderFirstName(res[0].firstname);
        setLogisticProviderLastName(res[0].lastname);
        setLogisticProviderEnterpriceId(res[0].enterpriseid);
        setLogisticProviderUserType(res[0].usertype);
        setLogisticProviderEmail(res[0].email);
        setLogisticProviderDomain(res[0].domain);
        setLogisticProviderPhone(res[0].phone);
        setLogisticProviderAddress(res[0].address);
        setLogisticProviderCity(res[0].city);
        setLogisticProviderState(res[0].state);
        setLogisticProviderNominee(res[0].nominee);
        setLogisticProviderPancard(res[0].pancard);
        setLogisticProviderLongitude(res[0].longitude);
        setLogisticProviderLatitude(res[0].latitude);
        setLogisticProviderMessage(res[0].message);
        setLogisticProviderStatus(res[0].status);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setLogisticProviderInfoDialog(!logisticProviderInfoDialog);
  };

  const logisticProviderSendEmailClick = (selectedUser) => {
    setSelectedLogisticProviderUserName(selectedUser);
    setLogisticProviderEmailDialog(!logisticProviderEmailDialog);
  };
  const sendEmailClick = () => {
    let emailData = {
      subject: subjectValue,
      content: messageValue,
    };
    const emailDataString = JSON.stringify(emailData);
    MainService.sendEmail(emailValue, emailDataString)
      .then((res) => {
        return NotificationManager.success("email Sent Successfully");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setLogisticProviderEmailDialog(!logisticProviderEmailDialog);
  };

  const sendSMSClick = () => {
    let smsData = {
      sms: messageValue,
    };
    const smsDataString = JSON.stringify(smsData);
    MainService.sendSMS(phoneNumerValue, smsDataString)
      .then((res) => {
        return NotificationManager.success("Message Sent Successfully");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setLogisticProviderSMSDialog(!logisticProviderSMSDialog);
  };

  const logisticProviderSendMessageClick = (selectedUser) => {
    setSelectedLogisticProviderUserName(selectedUser);
    setLogisticProviderSMSDialog(!logisticProviderSMSDialog);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = filteredLogisticProviders.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setLogisticProvidersList(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
    setMapView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
    setMapView(false);
  };
  const mapViewClick = () => {
    setListView(false);
    setGridView(false);
    setMapView(true);
  };
  
  /*Start of Feedback */

const feedbackDialogClick = (selectedUserId, selectedUserName) => {
  setSelectedExecutiveUserId(selectedUserId);
  setSelectedExecutiveName(selectedUserName);
  setExecutiveFeedbackDialog(!executiveFeedbackDialog);
};
const executiveFeedbackClick = () => {
  let addFeedback = {
    executiveid: selectedExecutiveName,
    //  recorder: username,
    " how_would_you_rate_your_overall_customer_experience?": customerExp,
    " how_satisfied_were_you_with_the_timeliness_of_delivery?": deliveryExp,
    "how_satisfied_were_you_with_the_product?": productExp,
    "how_satisfied_were_you_with_the_customer_support?": supportExp,
    "would_you_recommend_our_product_or_service_to_others?": othersExp,
    remark: remarks,
  };
  const addFeedbackData = JSON.stringify(addFeedback);
  MainService.userFeedback(addFeedbackData, username)
    .then((res) => {
      return NotificationManager.success(res);
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
    setExecutiveFeedbackDialog(!executiveFeedbackDialog);
};

/* end of Feedback */

  /* new map1 */
  const [selected, setSelected] = useState({});
  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };
  const onSelect = (item) => {
    setSelected(item);
  };
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: 13.093426,
    lng: 77.655773,
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <div style={{ float: "right" }}>
          <Button
            startIcon={<ListIcon />}
            size="large"
            onClick={listViewClick}
          ></Button>
          <Button
            startIcon={<GridIcon />}
            size="large"
            onClick={gridViewClick}
          ></Button>
          <Button
            startIcon={<MapIcon />}
            size="large"
            onClick={mapViewClick}
          ></Button>
          &nbsp;
          {(listView === true || mapView === true || gridView === true) && (
            <Fab
              color="primary"
              size={"large"}
              aria-label="add"
              title="Create User"
              className={classes.fab}
              onClick={() => {
                setIsLoading(false);
                setConsumerUserDialog(!consumerUserDialog);
              }}
            >
              <AddIcon></AddIcon>
            </Fab>
          )}
        </div>
          <div>
            <h2>PMC</h2>

            {listView && (
              <Paper sx={{ width: "100%" }}>
                <SearchBar
                  style={{ width: "100%" }}
                  value={searched}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          List of PMC
                        </TableCell>
                      </TableRow>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          User Id
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          User Name
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          View
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Operate
                        </TableCell>
                        <TableCell align="center" colSpan={3}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logisticProvidersList.length > 0 ? (
                        logisticProvidersList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((logisticProviders, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="center">
                                  {logisticProviders.userid}
                                </TableCell>
                                <TableCell align="center">
                                  {logisticProviders.username}
                                </TableCell>

                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      logisticProviderViewInfoDialogClick(
                                        logisticProviders.username
                                      );
                                    }}
                                  >
                                    View Info
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      logisticProviderSendEmailClick(
                                        logisticProviders.username
                                      );
                                    }}
                                  >
                                    Send Email
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      logisticProviderSendMessageClick(
                                        logisticProviders.username
                                      );
                                    }}
                                  >
                                    Send Message
                                  </Button>
                                </TableCell>
                                
                                {/* <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      feedbackDialogClick(
                                        logisticProviders.userid,
                                        logisticProviders.username
                                      );
                                    }}
                                  >
                                    Feedback
                                  </Button>
                                </TableCell> */}
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow align="center">
                          <TableCell align="center" colSpan={12}>
                            LogisticsProviders Not Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={logisticProvidersList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
            {gridView && (
              <div className="cardStyleForDashboardItems">
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="cardStyleForDashboardItems">
                    {logisticProvidersList.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {logisticProvidersList.map(
                          (logisticProviders, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>User Id:</b>
                                  {logisticProviders.userid}
                                </p>
                              </span>
                              <p>
                                <b>User Name:</b>
                                {logisticProviders.username}
                              </p>
                              <h4 className="cardactiontitle">View</h4>
                              <div>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    logisticProviderViewInfoDialogClick(
                                      logisticProviders.username
                                    );
                                  }}
                                >
                                  View Info
                                </Button>                                
                              </div>
                              <div>
                                <h4 className="cardactiontitle">Operate</h4>
                              </div>
                              <div>                               
                                <h4 className="cardactiontitle">Actions</h4>
                                <div>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    logisticProviderSendEmailClick(
                                      logisticProviders.username
                                    );
                                  }}
                                >
                                  Send Email
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    logisticProviderSendMessageClick(
                                      logisticProviders.username
                                    );
                                  }}
                                >
                                  Send Msssage
                                </Button>
                                </div>
                                &nbsp;
                                {/* <div>
                                
                                <Button type="button" variant="contained"
                                onClick={() => {
                                  feedbackDialogClick(
                                    logisticProviders.userid,
                                    logisticProviders.username
                                  );
                                }}>
                                  Send Feedback
                                </Button>
                                </div> */}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <h4>PMC not found.</h4>
                    )}
                  </div>
                )}
              </div>
            )}
            {mapView && (
              <div>
                <LoadScript googleMapsApiKey="AIzaSyDzJglX1KEZkksRUr_zBWrHAG3ZYeUZt4o">
                  <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={4}
                    center={defaultCenter}
                  >
                    <MarkerClusterer options={options} averageCenter={true}>
                      {(clusterer) =>
                        logisticProvidersList.map((markerObj, index) => {
                          return (
                            <Marker
                              key={index}
                              position={{
                                lat: parseFloat(markerObj.latitude),
                                lng: parseFloat(markerObj.longitude),
                              }}
                              clusterer={clusterer}
                              onClick={() => onSelect(markerObj)}
                            />
                          );
                        })
                      }
                    </MarkerClusterer>

                    {selected.latitude && selected.longitude && (
                      <InfoWindow
                        position={{
                          lat: parseFloat(selected.latitude),
                          lng: parseFloat(selected.longitude),
                        }}
                        clickable={true}
                        onCloseClick={() => setSelected({})}
                      >
                        <CardContent>
                          <Typography variant="body2" color="textSecondary">
                            <b>ID:</b> {selected.siteid}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            <b> Name:</b>Site{selected.sitename}
                          </Typography>
                        </CardContent>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
            )}
          </div>
        


        {/* Send SMS */}
        <Dialog
          fullScreen={fullScreen}
          open={logisticProviderSMSDialog}
          onClose={handleClose}
          aria-labelledby="sendSMS"
        >
          <DialogTitle id="sendSMS">Send SMS</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="logisticProviderUsername">
                User Name:
              </InputLabel>
              <Input
                disabled
                id="logisticProviderUsername"
                name="logisticProviderUsername"
                type="text"
                value={selectedLogisticProviderUsername}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="phone">Mobile Number:</InputLabel>
              <Input
                id="phone"
                name="phone"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                sendSMSClick();
              }}
            >
              Send
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setLogisticProviderSMSDialog(!logisticProviderSMSDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Send Email */}
        <Dialog
          fullScreen={fullScreen}
          open={logisticProviderEmailDialog}
          onClose={handleClose}
          aria-labelledby="sendEmail"
        >
          <DialogTitle id="sendEmail">{"Send Email"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="logisticProviderUsername">
                User Name:
              </InputLabel>
              <Input
                disabled
                id="logisticProviderUsername"
                name="logisticProviderUsername"
                type="text"
                value={selectedLogisticProviderUsername}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={emailValue}
                onChange={(e) => {
                  setEmailValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="subject">Subject:</InputLabel>
              <Input
                id="subject"
                name="subject"
                type="text"
                value={subjectValue}
                onChange={(e) => {
                  setSubjectValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                sendEmailClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setLogisticProviderEmailDialog(!logisticProviderEmailDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* View Info */}
        <Dialog
          fullScreen={fullScreen}
          open={logisticProviderInfoDialog}
          onClose={handleClose}
          aria-labelledby="viewInfoDialog"
        >
          <DialogTitle id="viewInfoDialog">
            {"View Info"}
          </DialogTitle>
          <DialogContent>
            <p>
              <b>User Id:</b>
              {logisticProviderUserId}
            </p>
            <p>
              <b>User Name:</b>
              {logisticProviderUserName}
            </p>
            <p>
              <b>First Name:</b>
              {logisticProviderFirstName}
            </p>
            <p>
              <b>Last Name:</b>
              {logisticProviderLastName}
            </p>
            <p>
              <b>Enterprise Id:</b>
              {logisticProviderEnterpriceId}
            </p>
            <p>
              <b>UserType:</b>
              {logisticProviderUserType}
            </p>
            <p>
              <b>Email:</b>
              {logisticProviderEmail}
            </p>
            <p>
              <b>Domain:</b>
              {logisticProviderDomain}
            </p>
            <p>
              <b>Phone:</b>
              {logisticProviderPhone}
            </p>
            <p>
              <b>Address:</b>
              {logisticProviderAddress}
            </p>
            <p>
              <b>City:</b>
              {logisticProviderCity}
            </p>
            <p>
              <b>State:</b>
              {logisticProviderState}
            </p>
            <p>
              <b>Nominee:</b>
              {logisticProviderNominee}
            </p>
            <p>
              <b>Pancard:</b>
              {logisticProviderPancard}
            </p>
            <p>
              <b>Longitude:</b>
              {logisticProviderLongitude}
            </p>
            <p>
              <b>Latitude:</b>
              {logisticProviderLatitude}
            </p>
            <p>
              <b>Message:</b>
              {logisticProviderMessage}
            </p>
            <p>
              <b>Status:</b>
              {logisticProviderStatus}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setLogisticProviderInfoDialog(!logisticProviderInfoDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Send Feedback */}
        <Dialog
          fullScreen={fullScreen}
          open={executiveFeedbackDialog}
          onClose={handleClose}
          aria-labelledby="sendFeedback"
        >
          <DialogTitle id="sendFeedback">Send Feedback</DialogTitle>
          <DialogContent>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  {selectedExecutiveName} Feedback
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your overall experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="customer-feedback"
                      value={overallRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextCustomer}
                      onChange={(event, overallRate) => {
                        setOverallRate(overallRate);
                        setOverallExp(labels[overallRate]);
                      }}
                      onChangeActive={(event, overallRateHover) => {
                        setOverallRateHover(overallRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {overallRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            overallRateHover !== -1
                              ? overallRateHover
                              : overallRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your product experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="product-feedback"
                      value={productRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextProduct}
                      onChange={(event, productRate) => {
                        setProductRate(productRate);
                        setProductExp(labels[productRate]);
                      }}
                      onChangeActive={(event, productRateHover) => {
                        setProductRateHover(productRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {productRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            productRateHover !== -1
                              ? productRateHover
                              : productRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your delivery experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="delivery-feedback"
                      value={deliveryRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelText}
                      onChange={(event, deliveryRate) => {
                        setDeliveryRate(deliveryRate);
                        setDeliveryExp(labels[deliveryRate]);
                      }}
                      onChangeActive={(event, deliveryRateHover) => {
                        setDeliveryRateHover(deliveryRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {deliveryRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {labels[deliveryRateHover !== -1 ? hover : deliveryRate]}
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your customer Support: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="support-feedback"
                      value={supportRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextSupport}
                      onChange={(event, supportRate) => {
                        setSupportRate(supportRate);
                        setSupportExp(labels[supportRate]);
                      }}
                      onChangeActive={(event, supportRateHover) => {
                        setSupportRateHover(supportRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {supportRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            supportRateHover !== -1
                              ? supportRateHover
                              : supportRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography variant="body2" component="span">
                  how would you rate your other experience: <br />
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="others-feedback"
                      value={othersRate}
                      defaultValue={0}
                      precision={0.5}
                      getLabelText={getLabelTextOthers}
                      onChange={(event, othersRate) => {
                        setOthersRate(othersRate);
                        setOthersExp(labels[othersRate]);
                      }}
                      onChangeActive={(event, othersRateHover) => {
                        setOthersRateHover(othersRateHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {othersRate !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            othersRateHover !== -1
                              ? othersRateHover
                              : othersRate
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <br />
                <Typography variant="body2" component="span">
                  Remarks: <br />
                  <TextareaAutosize
                    rowsMin={5}
                    aria-label="minimum height"
                    placeholder="Any remarks"
                    style={{ minWidth: "260px" }}
                    value={remarks}
                    onChange={(event) => {
                      setRemarks(event.target.value);
                    }}
                  />
                </Typography>
                <br />
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
              className="registerBtn"
              type="button"
              onClick={() => executiveFeedbackClick()}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setExecutiveFeedbackDialog(!executiveFeedbackDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* create Global User */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserDialog}
          onClose={handleClose}
          aria-labelledby="craeteConsumerUser"
        >
          <DialogTitle id="createConsumerUser">{"Create  User"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">User Name * :</InputLabel>
              <Input
                id="username"
                name="username"
                type="email"
                value={consumerUserNameValue}
                onChange={(e) => {
                  setConsumerUserNameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstName">First Name *:</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                value={firstNameValue}
                onChange={(e) => {
                  setFirstNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastName">Last Name *:</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                value={lastNameValue}
                onChange={(e) => {
                  setLastNameValue(e.target.value);
                }}
              />
            </FormControl>
           
            <FormControl fullWidth>
              <InputLabel id="userType">User Type *</InputLabel>
              <Select
                id="userType"
                name="userType"
                value={userTypeValue}
                
              >
                <MenuItem value="PMC">PMC</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id:*</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number *:</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
                        
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                createconsumerUsersubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setIsLoading(false);
                setConsumerUserDialog(!consumerUserDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
