import React from "react";
import MarketProducts from "../Market/MarketProducts";

const CertifiedDeviceMarket = () => {
  return (
    <MarketProducts
      categoryType="DEVICE"
      compType="Store"
      subCatType="Sub Category"
      modelID="Device Model ID"
      modelFN="Device Model Friendly Name"
      dCost="Device Cost"
      pUnit="Unit"
      pDiscount="Discount"
      dPrice="Discounted Price"
    />
  );
};

export default CertifiedDeviceMarket;
