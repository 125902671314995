import React from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Marker, Circle, Polyline, Polygon, DirectionsRenderer } from "react-google-maps"

const google = window.google;



const DynamicTripMap = withGoogleMap((props) => {
  console.log("props recieved", props);
  const places = props.markers;
  const [directions, setDirections] = React.useState(null);
  const {markers}=props
  const { selectedGeopath } = props;
  const { from, to } = markers;
  const handleEvent = (e) => {
    //props.setPath(e.latLng.lat(), e.latLng.lng());
  }


  // Set initial zoom and center for the map
  const mapZoom = 5; 
  const mapCenter = {  lat: 12.9716,
    lng: 77.5946 }; 

  if (places.length >= 2) {
    const waypoints = places.map(p =>({
      location: {lat: p.latitude, lng:p.longitude},
      stopover: true
    }))

    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;
    
    const directionsService = new  google.maps.DirectionsService();

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: waypoints
      },
      (result, status) => {
        if(result) {
          let latLng = [];
          result.routes[0].overview_path.map((p) => {
            latLng.push({lat: p.lat(), lng: p.lng()});
          });
          if (props.getSteps) {
            props.getSteps(latLng);
          }
          
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result)
          }
        }
        
      }
    );
  }
  
  React.useEffect(() => {
    if (from && to) {
      const directionsService = new google.maps.DirectionsService();

      const origin = new google.maps.LatLng(from.latitude, from.longitude);
      const destination = new google.maps.LatLng(to.latitude, to.longitude);

      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
          }
        }
      );
    }
  }, [from, to]);

    // Render the selected geopath as a Polyline if it exists
    const renderSelectedGeopath = () => {
      if (selectedGeopath) {
        return (
          <Polyline
            path={selectedGeopath} 
            options={{
              strokeColor: "#FF0000", 
              strokeOpacity: 1.0, 
              strokeWeight: 4, 
            }}
          />
        );
      }
      return null;
    };
  return (

    <GoogleMap
    defaultZoom={mapZoom}
    defaultCenter={mapCenter}
  >
    <DirectionsRenderer directions={directions} />
    {renderSelectedGeopath()} {/* Render the selected geopath */}
  </GoogleMap>
  
)
  })

export default DynamicTripMap;