import React from "react";
import { NavHashLink } from "react-router-hash-link";
// import "./BenefitCard.css";

export default function OurServiceCard({ src, title, bodycopy, href }) {
  return (
    <>
      {href ? (
        <>
          <NavHashLink to={href} style={{ textDecoration: 'none' }}>
            <div className="benefitsCardContainer mb-lg-0 mb-5 ">
              <img src={src} alt="icon" className="mb-4" />

              <h3>{title}</h3>
              <p className="mb-0"> {bodycopy} </p>
            </div>
          </NavHashLink>
        </>
      ) : (
        <div className="benefitsCardContainer mb-lg-0 mb-5">
          <img src={src} alt="icon" className="mb-4" />

          <h3>{title}</h3>
          <p className="mb-0"> {bodycopy} </p>
        </div>
      )}
    </>
  );
}
