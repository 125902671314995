import React, { useState, useEffect } from "react";
import { makeStyles,  } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MaterialTable from "material-table";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 250,
  },
  gridRoot: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function AssetsServicesPageComponent() {
  const classes = useStyles();
  const [state, setState] = useState({
    columns: [
      { title: "ID", field: "assetId" },
      { title: "Asset Name", field: "friendlyName" },
      { title: "Model", field: "modelId" },
      { title: "Cost", field: "whitelabelCost" },
      { title: "Blocking Status", field: "blockingStatus" },
      { title: "Status", field: "assetStatus" },
    ],
    data: [],
  });

  const [growerIdList, setGrowerIdList] = useState([]);
  const [selectedGrowserId, setSelectedGrowserId] = useState('');
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState('');
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("{}");
 

  useEffect(() => {
  
    getGrowerIdList();
  }, []);

  useEffect(() => {
    if (!selectedGrowserId) return;
    getAssetVerticalsIdList();
  }, [selectedGrowserId]);//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedVerticalsId) return;
    getCategoryTypesList();
  }, [selectedVerticalsId]);//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedCategory) return;
    getAssetTypesList();
  }, [selectedCategory]);//eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (!selectedAssertTypes || selectedAssertTypes === "{}") return;
    console.log(selectedAssertTypes);
    getSolutionAssets();
  }, [selectedAssertTypes]);//eslint-disable-line react-hooks/exhaustive-deps

  const getGrowerIdList = () => {
    MainService.getAllSolutions()
      .then((data) => {
        if (data.length > 0) {
          setSelectedGrowserId(data[0].solutionid);
        }
        setGrowerIdList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList(window.AppConfigData.EnterpriseId)
      .then((data) => {
        if (data.length > 0) {
          setSelectedVerticalsId(data[0].assetVerticalId);
        }
        setVerticalsIdList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getCategoryTypesList = () => {
    MainService.getCategoryTypesList(selectedGrowserId, selectedVerticalsId,window.AppConfigData.EnterpriseId)
      .then((data) => {
        if (data.length > 0) {
          setSelectedCategory(data[0].categoryType);
        }
        setCategoryTypesList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getAssetTypesList = () => {
    MainService.getAssetTypesList(
      selectedGrowserId,
      selectedVerticalsId,
      selectedCategory,
      window.AppConfigData.EnterpriseId
    )
      .then((data) => {
        if (data.length > 0) {
          setSelectedAssertTypes(JSON.stringify(data[0]));
        }
        setAssertTypesList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getSolutionAssets = () => {
    const selectedAssertTypesObj = JSON.parse(selectedAssertTypes);
   
    const requestPayload = {
      growerId: selectedGrowserId,
      assetverticalId: selectedVerticalsId,
      assetType: selectedAssertTypesObj.assetType, 
      categoryType: selectedCategory, 
      //modelId: selectedAssertTypesObj.modelId,
    }
    MainService.getSolutionAssets(requestPayload)
      .then((data) => {
        setState({ ...state, data });
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <span style={{ display: "inlineBlock", float: "right" }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              style={{
                transform: "translate(14px, -6px) scale(0.75)",
                color: "rgb(43, 144, 99)",
              }}
            >
              Select Type
            </InputLabel>
            <Select
              id="selectedAssertTypes"
              value={selectedAssertTypes}
              onChange={(event) => setSelectedAssertTypes(event.target.value)}
              label="Select Type"
            >
              {assertTypesList.map((idObj) => (
                <MenuItem key={idObj.modelId} value={JSON.stringify(idObj)}>
                  {idObj.assetType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
        <span style={{ display: "inlineBlock", float: "right" }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              style={{
                transform: "translate(14px, -6px) scale(0.75)",
                color: "rgb(43, 144, 99)",
              }}
            >
              Select Category
            </InputLabel>
            <Select
              id="selectedCategory"
              value={selectedCategory}
              onChange={(event) => setSelectedCategory(event.target.value)}
              label="Select Category"
            >
              {categoryTypesList.map((idObj, index) => (
                <MenuItem
                  key={`${index} - ${idObj.categoryType}`}
                  value={idObj.categoryType}
                >
                  {idObj.categoryType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
        <span style={{ display: "inlineBlock", float: "right" }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              style={{
                transform: "translate(14px, -6px) scale(0.75)",
                color: "rgb(43, 144, 99)",
              }}
            >
              Select Asset Verticals
            </InputLabel>
            <Select
              id="selectedVerticalsId"
              value={selectedVerticalsId}
              onChange={(event) => setSelectedVerticalsId(event.target.value)}
              label="Select Verticals"
            >
              {verticalsIdList.map((idObj, index) => (
                <MenuItem
                  key={`${index} - ${idObj.assetVerticalId}`}
                  value={idObj.assetVerticalId}
                >
                  {idObj.assetVerticalFriendlyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
        <span style={{ display: "inlineBlock", float: "right" }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              style={{
                transform: "translate(14px, -6px) scale(0.75)",
                color: "rgb(43, 144, 99)",
              }}
            >
              Select Grower
            </InputLabel>
            <Select
              id="selectedGrowserId"
              value={selectedGrowserId}
              onChange={(event) => setSelectedGrowserId(event.target.value)}
              label="Select Grower"
            >
              {growerIdList.map((idObj) => (
                <MenuItem key={idObj.solutionid} value={idObj.solutionid}>
                  {idObj.solutionname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
      </Grid>
      <Grid item md={12}>
        <MaterialTable
          title="Asset Services"
          columns={state.columns}
          data={state.data}
          editable={{}}
        />
      </Grid>
    </Grid>
  );
}
