import React, { useState, useEffect } from "react";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "react-google-maps";
import socketIOClient from "socket.io-client";
import LocationMap from "../../Common/LiveMonitorMap";
import Map from "../../Common/googleMap/Map";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function ExploreAssetsComponent(props) {
  const classes = useStyles();
  const theme = createTheme();
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [isLoading, setIsLoading] = useState(false);
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [assetsList, setAssetsList] = useState([]);
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [assetsListView, setAssetsListView] = useState(false);
  const [assetsGridView, setAssetsGridView] = useState(true);
  const [assetsMapView, setAssetsMapView] = useState(false);
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");


  /* Asset Live Monoitoring */
 
  const [marker_position, setmarker_position] = useState({
    lat: 0,
    lng: 0,
  });


  const [selectedUser,setSelectedUser] = useState("");
  const [usersList,setUsersList] = useState([]);


  useEffect(() => {  
    getUserType();
    getUsersBasedOnUserTypes();
  }, []);

  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        console.log("getUserTypeValue1:", data);
        getAssetVerticalsIdList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getUsersBasedOnUserTypes = () => {
    MainService.getUsersBasedOnUserType(parentId, parentId, "FMC")
      .then((data) => {
        if (data && Array.isArray(data) && data.length > 0) {
          setUsersList(data);
          setSelectedUser(data[0].username);
        } else {
          
          setUsersList([]);
          setSelectedUser("");
        }
      })
      .catch((e) => {
        console.error(e);
        NotificationManager.error(e);
      });
  };
  
  const getAssetVerticalsIdList = (getUserTypeValue) => {
    setIsLoading(true);
    if (getUserTypeValue) {
      MainService.getAssetVerticalsIdList(window.AppConfigData.EnterpriseId)
        .then((data) => {
          if (data.length > 0) {
            var filteredAssetVerticals;
            if (
              getUserTypeValue === "AssetDeveloper" ||
              getUserTypeValue === "FOOD-PROCESSOR"
            ) {
              filteredAssetVerticals = data.filter((row) => {
                return (
                  row.friendlyName === "ANIMAL" ||
                  row.friendlyName === "TRANSPORTATION"
                );
              });
            } else if (getUserTypeValue === "ENERGY-PROCESSOR") {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "AGRICULTURE";
              });
            } else if (getUserTypeValue === "BROKER") {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "TRANSPORTATION";
              });
            } else if (getUserTypeValue === "AgriAssetDistributor") {
              filteredAssetVerticals = data.filter((row) => {
                return (
                  row.friendlyName === "ANIMAL" ||
                  row.friendlyName === "AGRI-CULTURE" ||
                  row.friendlyName === "AQUA-CULTURE"
                );
              });
            } else if (getUserTypeValue === "FMC") {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "AGRI-MACHINERY";
              });
            } else if (getUserTypeValue === "PMC") {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "PROCESSING-MACHINERY";
              });
            } else if (getUserTypeValue === "FMCDealer") {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "AGRI-MACHINERY";
              });
            } else if (getUserTypeValue === "PMCDealer") {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "PROCESSING-MACHINERY";
              });
            } else if (getUserTypeValue === "OFC") {
              filteredAssetVerticals = data.filter((row) => {
                return (
                  row.friendlyName === "AGRICULTURE" ||
                  row.friendlyName === "AGRI-MACHINERY"
                );
              });
            } else {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "ANIMAL";
              });
            }
            console.log("filteredAssetVerticals", filteredAssetVerticals);
            setVerticalsIdList(filteredAssetVerticals);
            setSelectedVerticalsId(filteredAssetVerticals[0].assetVerticalId);
          } else {
            setSelectedVerticalsId("");
            setVerticalsIdList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  useEffect(() => {
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  const getCategoryTypesList = () => {
    setIsLoading(true);
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(
        selectedVerticalsId.replace(" ", ""),
        window.AppConfigData.EnterpriseId
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setSelectedCategory("");
            setCategoryTypesList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    } else {
      setSelectedCategory("");
      setSelectedAssertTypes("");
      setSelectedModel("");
    }
  };

  useEffect(() => {
    getAssetTypesList();
  }, [selectedVerticalsId, selectedCategory]);

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        window.AppConfigData.EnterpriseId
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
          } else {
            setSelectedAssertTypes("");
            setAssertTypesList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    } else {
      setSelectedAssertTypes("");
      setSelectedModel("");
    }
  };

  useEffect(() => {
    getModelsList();
  }, [selectedVerticalsId, selectedCategory, selectedAssertTypes]);

  const getModelsList = () => {
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", ""),
        username
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedModel(data[0].modelid);
            setModelsList(data);
          } else {
            setSelectedModel("");
            setModelsList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  useEffect(() => {
    getAllAssetsBasedonModels();
  }, [selectedUser,selectedVerticalsId, selectedCategory, selectedAssertTypes,selectedModel]);
 

  const getAllAssetsBasedonModels = (Type) => {
    if (
      selectedUser &&
      selectedVerticalsId &&
      selectedCategory &&
      selectedAssertTypes &&
      selectedModel
    ) {
      MainService.getAllAssetsBasedonModel(
        selectedUser,
        selectedAssertTypes.replace(" ", ""),
        selectedModel.replace(" ", ""),
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", "")
      )
        .then((data) => {
          setIsLoading(true);
          if (data && data.length > 0) {
            const filteredAssets = data.filter((row) => {
              return row.assetStatus === "verified";
            });
            setAssetsList(filteredAssets);
            getFilterUsers(filteredAssets);
          } else {
            setAssetsList([]);
            getFilterUsers([]);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e);
          NotificationManager.error(e);
          setIsLoading(false);
        });
    } else {
      
      setIsLoading(false);
      setAssetsList([]);
      getFilterUsers([]);
    }
  
    if (Type === "List") {
      setAssetsListView(true);
      setAssetsGridView(false);
      setAssetsMapView(false);
     
    } else if (Type === "Grid") {
      setAssetsListView(false);
      setAssetsGridView(true);
      setAssetsMapView(false);

    } else if (Type === "Map") {
      setAssetsListView(false);
      setAssetsGridView(false);
      setAssetsMapView(true);
     
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const setSelectedVerticalsIdHandler = (e) => {
    setSelectedVerticalsId(e.target.value);
    let catname = verticalsIdList.filter((data) => {
      return data.assetVerticalId === e.target.value;
    });
    
  };

  const setSelectedCategoryHandler = (event) => {
    setSelectedCategory(event.target.value);
  };

  const setSelectedAssertTypeHandler = (event) => {
    setSelectedAssertTypes(event.target.value);
  };

  const setSelectedModelHandler = (event) => {
    setSelectedModel(event.target.value);
  };
  
  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.friendlyName.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetsList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.friendlyName.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetsList(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  const AssetManagementGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={assetsList.index}
        defaultZoom={2}
        defaultCenter={marker_position}
        center={marker_position}
      >
        {assetsList.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <div>{value.assetId}</div>
              </InfoWindow>
            </Marker>
          ) : (
            ""
          );
        })}
      </GoogleMap>
    );
  });

 
  return (
    <>
      <div className="container" id="mainContentList">
        <div className="topHeadersWrapper">
        <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                User
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedUser}
                onChange={(event) => {
                  setSelectedUser(event.target.value);
                }}
                label="Select User"
              >
                {usersList.length > 0 ? (
                  usersList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.username}>
                      {idObj.username}
                    </MenuItem>
                  ))
                ) : (
                  <h4>Not found</h4>
                )}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Sector
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsIdHandler(event);
                }}
                label="Select Verticals"
              >
                {verticalsIdList.length > 0 ? (
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <h4></h4>
                )}
              </Select>
            </FormControl>
          </div>

          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Category
              </InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategoryHandler(event);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Asset Type
              </InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypeHandler(event);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Model
              </InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModelHandler(event);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          {(assetsListView || assetsGridView || assetsMapView) && (
            <div className="topHeaders">
              <Button
                startIcon={<GridIcon />}
                size="large"
                title="Assets Grid View"
                onClick={() => {
                  getAllAssetsBasedonModels("Grid");
                }}
              ></Button>
              <Button
                startIcon={<ListIcon />}
                size="large"
                title="Assets List View"
                onClick={() => {
                  getAllAssetsBasedonModels( "List");
                }}
              ></Button>
              <Button
                startIcon={<MapIcon />}
                size="large"
                title="Assets Map View"
                onClick={() => {
                  getAllAssetsBasedonModels("Map");
                }}
              ></Button>
            </div>
          )}
        </div>
       
        <div className="MuiGrid-root">
         

          {/* Asset List */}
          
          {assetsListView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <div className="cardStyleForDashboardItems">
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <SearchBar
                      placeholder="Search by Asset Name"
                      value={searched}
                      onChange={(searchVal) => requestSearch(searchVal)}
                      onCancelSearch={() => cancelSearch()}
                    />

                    {assetsList.length > 0 ? (
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={20}>
                                  Assets
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={1}>
                                  Asset Id
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Asset Status
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  AssetType
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Asset Friendly Name
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  AssetverticalId
                                </TableCell>                                
                                <TableCell align="center" colSpan={1}>
                                  Category Type
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Gender
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  ModelId
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Model Type
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Model Name
                                </TableCell>                                

                                <TableCell align="center" colSpan={1}>
                                  SiteId
                                </TableCell>                                
                                <TableCell align="center" colSpan={1}>
                                  Site Name
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Weight
                                </TableCell>       
                                <TableCell align="center" colSpan={1}>
                                  Age
                                </TableCell>                               
                                <TableCell align="center" colSpan={1}>
                                  Selling Cost
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Whitelabel Cost
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Acquire Cost
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Latitude
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Longitude
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {assetsList?.length > 0 ? (
                                assetsList
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((assets, index) => {
                                    return (
                                      <TableRow hover tabIndex={-1} key={index}>
                                        <TableCell align="center">
                                          {assets.assetId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.assetStatus}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.assetType}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.friendlyName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.assetverticalId}
                                        </TableCell>
                                        
                                        <TableCell align="center">
                                          {assets.categoryType}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.gender}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelType}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.siteId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.siteName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.weight}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.age}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.sellingCost}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.whitelabelCost}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.acquireCost}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.latitude}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.longitude}
                                        </TableCell>
                                        <TableCell align="center" />
                                      </TableRow>
                                    );
                                  })
                              ) : (
                                <TableRow align="center">
                                  <TableCell align="center" colSpan={12}>
                                    Assets not found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={assetsList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <h4>Assets not found.</h4>
                    )}
                  </Box>
                )}
              </div>
            </Box>
          ) : null}

          {assetsGridView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <SearchBar
                placeholder="Search by Asset Name"
                value={farmgridsearched}
                onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                onCancelSearch={() => cancelFarmGridSearch()}
              />

              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    List Of Assets
                  </h4>
                  {assetsList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {assetsList.map((assets, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                          <p>
                              <b>Asset Friendly Name:</b>
                              {assets.friendlyName}
                            </p>
                            <p>
                              <b>Asset Id:</b>
                              {assets.assetId}
                            </p>
                            <p>
                              <b>Asset Status:</b>
                              {assets.assetStatus}
                            </p>
                            <p>
                              <b>AssetType:</b>
                              {assets.assetType}
                            </p>
                            
                            <p>
                              <b>AssetverticalId:</b>
                              {assets.assetverticalId}
                            </p>
                            
                            <p>
                              <b>Category Type:</b>
                              {assets.categoryType}
                            </p>
                            
                            <p>
                              <b>ModelId:</b>
                              {assets.modelId}
                            </p>

                            <p>
                              <b>Model Name:</b>
                              {assets.modelName}
                            </p>
                            <p>
                              <b>Model Type:</b>
                              {assets.modelType}
                            </p>
                            <p>
                              <b>Model Url:</b>
                              {assets.modelUrl}
                            </p>
                            <p>
                              <b>SiteId:</b>
                              {assets.siteid}
                            </p>
                            <p>
                              <b>AssetDeveloper Id:</b>
                              {assets.assetdeveloperid}
                            </p>
                           
                            <p>
                              <b>Gender:</b>
                              {assets.gender}
                            </p>
                           
                            <p>
                              <b>Weight:</b>
                              {assets.weight}
                            </p>
                            <p>
                              <b>Age:</b>
                              {assets.age}
                            </p>
                            <p>
                              <b>Acquire Cost:</b>
                              {assets.acquireCost}
                            </p>
                            <p>
                              <b>Whitelabel Cost:</b>
                              {assets.whitelabelCost}
                            </p>
                            <p>
                              <b>Selling Cost:</b>
                              {assets.sellingCost}
                            </p>
                            <p>
                              <b>Latitude:</b>
                              {assets.latitude}
                            </p>
                            <p>
                              <b>Longitude:</b>
                              {assets.longitude}
                            </p>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <h4 style={{ width: "100%", textAlign: "center" }}>No Assets Found.</h4>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Box>
          ) : null}

          {assetsMapView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {assetsList.length > 0 ? (
                <AssetManagementGoogleMap
                  containerElement={
                    <div style={{ height: `500px`, width: "100%" }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              ) : (
                <h4>Assets Not Found</h4>
              )}
            </Box>
          ) : null}
        </div>
      </div>

     
    </>
  );
}
