import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Marker,
  Circle,
  Polyline,
  DrawingManager,
} from "@react-google-maps/api";

const containerStyle = {
  width: "80vw",
  height: "90vh",
};

const center = {
  lat: 20.5937,
  lng: 78.9629,
};

const Map = ({
  getLocationHandler,
  routeLocationData,
  defaultRadius,
  getNewRadius,
  drawing_type,
  onShapeAdd,
  clearShapes,
  polyShapes,
  handlePolyShapeAdd,
  isPolygonCreated,
}) => {
  const mapRef = useRef();
  const [searchBox, setSearchBox] = useState(null);
  const [position, setPosition] = useState({ lat: "", lng: "" });
  const [locationData, setLocationData] = useState([]);
  const [dposition, setdPosition] = useState({ lat: "", lng: "" });
  const [liveLocation, setLiveLocation] = useState(routeLocationData);
  const drawingManagerRef = useRef();
  const onLoad = useCallback(
    (ref) => {
      setSearchBox(ref);
    },
    [locationData]
  );

  const [radius, setRadius] = useState(defaultRadius);

  const mapLoad = (ref) => {
    mapRef.current = ref;
  };

  const onPlacesChanged = useCallback(() => {
    setPosition({
      lat: searchBox.getPlaces()[0].geometry.location.lat(),
      lng: searchBox.getPlaces()[0].geometry.location.lng(),
    });
    setLiveLocation([
      ...liveLocation,
      {
        geoLocation: {
          lat: searchBox.getPlaces()[0].geometry.location.lat(),
          lng: searchBox.getPlaces()[0].geometry.location.lng(),
        },
      },
    ]);

    if (getLocationHandler) {
      getLocationHandler(searchBox.getPlaces());
    }
  }, [searchBox]);

  const getBounds = (props) => {
    if (
      !props?.map ||
      !props?.geoLocation ||
      !props?.geoLocation?.lat ||
      !props?.geoLocation?.lng
    ) {
      return;
    }

    const mapRef = props.map;
    const position = new window.google.maps.LatLng(
      props?.geoLocation?.lat,
      props?.geoLocation?.lng
    );

    const bounds = mapRef.current.getBounds();
    if (!bounds || !bounds.contains(position)) {
      mapRef.current.panTo(position);
    }
  };

  useEffect(() => {
    setLiveLocation(routeLocationData);
    getBounds(routeLocationData[0]);
  }, [routeLocationData]);

  const circleRef = useRef(null);
  const circleListenerRef = useRef([]);

  const onCircleEdit = useCallback(() => {
    if (circleRef.current) {
      const circle = circleRef.current;
      const newRadius = circle.getRadius();
      setRadius(newRadius);
      getNewRadius(newRadius);
    }
  }, [setRadius]);

  const onCircleLoad = useCallback(
    (circle) => {
      circleRef.current = circle;
      const center = circle.getCenter();
      const radius = circle.getRadius();
      circleListenerRef.current.push(
        circle.addListener("radius_changed", onCircleEdit),
        circle.addListener("center_changed", onCircleEdit)
      );
    },
    [onCircleEdit]
  );

  useEffect(() => {
    console.log("liveLocationliveLocation", liveLocation);
    setPosition({
      lat: parseFloat(liveLocation[0]?.geoLocation?.lat),
      lng: parseFloat(liveLocation[0]?.geoLocation?.lng),
    });
  }, [defaultRadius]);

  const onCircleUnmount = useCallback(() => {
    circleListenerRef.current.forEach((listener) => listener.remove());
    circleRef.current = null;
  }, []);

  useEffect(() => {
    setRadius(parseFloat(defaultRadius));
  }, [defaultRadius]);

  // Define refs for Polygon instance and listeners
  const [path, setPath] = useState([
    { lat: 52.52549080781086, lng: 13.398118538856465 },
    { lat: 52.48578559055679, lng: 13.36653284549709 },
    { lat: 52.48871246221608, lng: 13.44618372440334 },
  ]);

  const polygonRef = useRef(null);
  const listenersRef = useRef([]); // Call setPath with new edited path

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      handlePolyShapeAdd(nextPath);
    }
  }, [setPath]); // Bind refs to current Polygon and listeners

  const onPolyLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },

    [onEdit]
  ); // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  useEffect(() => {
    setPath(polyShapes);
  }, [polyShapes]);

  console.log("The path state is", path, polyShapes);

  const clearShapess = (event) => {
    event.preventDefault();
    console.log("props", polyShapes);

    polyShapes.forEach((polyline) => polyline.setMap(null));
  };

  const [polylines, setPolylines] = useState([]);

  const demoClear = (event) => {
    event.preventDefault();
    console.log("props", polylines);
    const shapes = this.state.shapes;

    // Remove all shapes from the map
    shapes.markers.forEach((marker) => marker.setMap(null));
    shapes.polylines.forEach((polyline) => polyline.setMap(null));
    shapes.circles.forEach((circle) => circle.setMap(null));
    shapes.rectangles.forEach((rectangle) => rectangle.setMap(null));
    shapes.polygons.forEach((polygon) => polygon.setMap(null));
  };

  const polygonOptions = {
    fillOpacity: 0.3,
    fillColor: "#ff0000",
    strokeColor: "#ff0000",
    strokeWeight: 2,
    draggable: true,
    editable: true,
  };

  // option
  const drawingManagerOptions = {
    drawingMode: window.google?.maps?.drawing?.OverlayType?.POLYLINE,
    polygonOptions: polygonOptions,
    drawingControl: false,
    drawingControlOptions: {
      drawingModes: [window.google?.maps?.drawing?.OverlayType?.POLYLINE],
    },
  };

  // drawing manager ref
  const onLoadDrawingManager = (drawingManager) => {
    drawingManagerRef.current = drawingManager;
  };

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position.lat ? position : center}
        zoom={12}
        onLoad={mapLoad}
      >
        <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
          <input
            className="mapInput"
            type="text"
            placeholder="Enter a Location"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </StandaloneSearchBox>

        {liveLocation?.map((li) => {
          const parsedLat = parseFloat(li?.geoLocation?.lat);
          const parsedLng = parseFloat(li?.geoLocation?.lng);
          if (isNaN(parsedLat) || isNaN(parsedLng)) {
            return null;
          }
          return (
            <Marker
              key={li.geoLocation?.lat}
              position={{ lat: parsedLat, lng: parsedLng }}
            />
          );
        })}

        {drawing_type === "polygon" && !isPolygonCreated && (
          <DrawingManager
            onLoad={onLoadDrawingManager}
            options={drawingManagerOptions}
            onPolylineComplete={(polyline) => onShapeAdd(polyline, "polylines")}
          />
        )}

        {(drawing_type === "polygon" || isPolygonCreated) && (
          <Polyline
            editable
            draggable
            path={path}
            onMouseUp={onEdit}
            onDragEnd={onEdit}
            onLoad={onPolyLoad}
            onUnmount={onUnmount}
          />
        )}

        {drawing_type === "circle" && (
          <Circle
            editable
            center={position}
            radius={radius}
            onEdit={onCircleEdit}
            onLoad={onCircleLoad}
            onUnmount={onCircleUnmount}
          />
        )}
      </GoogleMap>
    </>
  );
};

export default React.memo(Map);
