import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';

class PieChart extends Component {
  constructor(props) {
    super(props);


    this.colors = {
      'Active Devices': '#3f6e8e',
      'Inactive Devices': 'grey',
      // Add more color entries for additional data names...
    };

    
  }

  renderColorEntries() {
    return Object.entries(this.colors).map(([dataName, color]) => (
      <div
        key={dataName}
        style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}
      >
        <div
          style={{
            width: '15px',
            height: '15px',
            marginRight: '5px',
            backgroundColor: color,
          }}
        ></div>
        <div>{dataName}</div>
      </div>
    ));
  }

  render() {
    const { activeDevice, inactiveDevices } = this.props; // Destructuring props

    const chartStyle = {
      width: '400px', // Set your desired width here
      height: '300px', // Set your desired height here
    };
    const chartConfig = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: '<div><b>Device Information For Active/Inactive Devices</b></div>',
        align: 'left', // Align title to the left
        x: 10, // Set the x position of the title
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
        },
      },
      series: [
        {
          name: 'Devices',
          colorByPoint: true,
          data: [
            {
              name: 'Active Devices',
              y: activeDevice,
              color: this.colors['Active Devices'],
            },
            {
              name: 'Inactive Devices',
              y: inactiveDevices,
              color: this.colors['Inactive Devices'],
            },
          ],
        },
      ],
    };

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '60vw' }}>
          <ReactHighcharts config={chartConfig} style={chartStyle} />
        </div>
        <div
          style={{
            paddingTop: '25vh',
            width: '40vw',
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          {this.renderColorEntries()}
        </div>
      </div>
    );
  }
}

export default PieChart;
