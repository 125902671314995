let networkId = {
    network: localStorage.getItem("user-id") // Default to 0 if "user-id" is not set or not a valid integer
};

// Export the networkId object
export default networkId;

// Other constants
export const consumerAdmin = 'consumer2';
export const profileAPI = 'http://44.197.120.123:8080';


