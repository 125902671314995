import React from 'react'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'

export default function ExploreAnimalCareBanner() {
  return (
    <UpdatesBanner
    className="explore-animal-care-bg"
    bannerText={[
      "Animal Health Care",
    ]}
  />
  )
}
