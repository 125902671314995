import React, { useState, useEffect } from "react";
import Geocode from "react-geocode";
import DynamicTripMap from "../../../../../../containers/Map/DynamicTrip";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import MainService from "../../../../Common/MainService";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

const google = window.google;

function AddDynamic(props) {
  const [places, setPlaces] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [all_steps, setAllSteps] = useState([]);
  const [route_id, setRouteId] = useState("");
  const [trip_id, setTripId] = useState("");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [orderLocation, setOrderLocation] = useState({});
  const [fromLocation, setFromLocation] = useState({});
  const [toLocation, setToLocation] = useState({});
  const history = useHistory();
  const [routeDetails, setRouteDetails] = useState(null);
  const [selectedGeopath, setSelectedGeopath] = useState({geopath:[]});


  // Access the tripId parameter from the URL:
  const { tripId,routeId } = useParams();

console.log(tripId,routeId,"routeid in add dynamic//////////")
  console.log("Received tripId:", tripId);
 

  //get ordersof a trip API:
  const getOrdersOfTrip = async () => {
    try {
      const viewOrders = await MainService.getOrdersOfTrip(username, tripId);
      console.log(viewOrders, "get all orders");
      setOrders(viewOrders); // Set the retrieved orders in the state as an array
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  //get order location:
  const fetchOrderLocation = async () => {
    if (!selectedOrder) {
      console.error("No order selected");
      return;
    }

    try {
      const orderLocation = await MainService.getOrderLocation(
        username,
        selectedOrder
      );
      // Extract "from" and "to" locations here
      const { fromLocationlat, fromLocationlng, lat, lng } = orderLocation;
      const fromLocation = {
        latitude: fromLocationlat,
        longitude: fromLocationlng,
      };
      const toLocation = { latitude: lat, longitude: lng };

      console.log("Order Location:", orderLocation);
      setOrderLocation(orderLocation);
      setFromLocation(fromLocation);
      setToLocation(toLocation);
    } catch (error) {
      console.error("Error fetching order location:", error);
    }
  };

// Fetch route details function
const fetchRouteDetails = async () => {
  try {
    // Call the API to fetch route details
    const routeDetailsData = await MainService.getDetailsofRouteId(username, routeId);
    console.log("Route Details:", routeDetailsData);
    if (routeDetailsData && routeDetailsData.geoPath) {
      // Set the route details in your state
      setRouteDetails(routeDetailsData);

      // Extract and set the geopath data
      setSelectedGeopath(routeDetailsData.geoPath);
      console.log(routeDetailsData.geoPath, "//////geopathhhh/////////");
    } else {
      console.error("GeoPath not found in routeDetailsData.");
    }
  } catch (error) {
    console.error("Error fetching route details:", error);
  }
};
  useEffect(() => {
    getOrdersOfTrip();
    fetchRouteDetails();
  }, []);

  const cancelHandler = () => {
    history.goBack();
  };

  const confirmOrderHandler = () => {
    fetchOrderLocation();
    
  };
  return (
    <>

    
      <Card sx={{ m: 2 }}>
        <CardContent>
          <FormControl fullWidth>
            <InputLabel>Select Order</InputLabel>
            <Select
              value={selectedOrder}
              onChange={(e) => setSelectedOrder(e.target.value)}
            >
              <MenuItem value="">
                <em>Select an Order</em>
              </MenuItem>
              {console.log("helooooooooooo", orders.orders)}
              {orders.orders?.map((order) => (
                <MenuItem key={order.orderid} value={order.orderid}>
                  {order.orderid}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ display: "flex", justifyContent:'flex-end', m: 2 }}>
            <Button onClick={cancelHandler} color="error">
              Cancel
            </Button>
            <Button variant="contained" onClick={confirmOrderHandler}>
              Confirm Order
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Grid item xs={12}>
        <DynamicTripMap
          containerElement={<div style={{ height: "500px", width: "100%" }} />}
          markers={{ from: fromLocation, to: toLocation }}
          mapElement={<div style={{ height: "100%" }} />}
          isMarkerShown
          page="location"
          defaultZoom={5}
          selectedGeopath={selectedGeopath}
        />
      </Grid>
    </>
  );
}
export default AddDynamic;
