import React, { Component } from "react";
import "./Club.css";
import ReactPaginate from "react-paginate";
import $, { event, merge } from "jquery";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import { Container, ThemeProvider } from "@material-ui/core";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import index from "reactjs-media";
import { Row, Col } from "react-bootstrap";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import ClubDetails from "./clubDetailsModal/ClubDetails";
import SearchBar from "material-ui-search-bar";

class AssetDeveloperClub extends Component {
  constructor() {
    super();
    this.state = {
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      joinwithus: [],
      filterJoinwithus: [],
      offset: 0,
      clubsPageCount: 1,
      perPage: 6,
      currentPage: 0,
      userTypes: ["Production-Manager"],
      getShow: false,
      showModal: false,
      clubname: "",
      userInfoData: [],
      farmgridsearched: "",
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers() {
    for (let i = 0; i < this.state.userTypes.length; i++) {
      this.joinClubValues(this.state.userTypes[i]);
    };
  };

  joinClubValues(userTypes) {
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}NewAssetDeveloper2@gmail.com/${userTypes}`,
      // `${window.appConfig.allConsumerUsreswithAssetType}${window.appConfig.Relam}${window.appConfig.Relam}${userTypes}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res,"ugggggggggggggggggggggggggggggggggggggggg")
        this.setState((prevState) => ({
          joinwithus: [...prevState.joinwithus, res.users].flatMap(
            (num) => num
          ),
          filterJoinwithus: [...prevState.filterJoinwithus, res.users].flatMap(
            (num) => num
          ),
          clubsPageCount: Math.ceil(
            [...prevState.joinwithus, res.users].flatMap((num) => num).length /
              this.state.perPage
          ),
        }));
      })
      .catch((err) => console.log("err", err));
  };

    /* Clubs Search */
    requestFarmGridSearch(searchedVal) {
      const {filterJoinwithus} = this.state;
      const filterData = filterJoinwithus.filter((data,index) => {
        return data.username.toLowerCase().includes(searchedVal.toLowerCase());
      });
      this.setState({
        joinwithus : filterData,
        clubsPageCount: Math.ceil(
          filterData.length / this.state.perPage
        ),
      });
  };

  cancelFarmGridSearch = () => {
    this.setState({
      farmgridsearched: "",
    });
    this.requestFarmGridSearch(
      this.state.farmgridsearched,
    );
  };
  /* EOD  Clubs Search */

  loginHandler(username) {
    localStorage.setItem("clubusername", username);
  };

  /************* Pagination *****************/
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({
      currentPage: selectedPage,
      offset: offset,
    });
  };
  /************* EOD Pagination *****************/
  /************* View Details modal *****************/
  // modalHandler = () =>{
  //   this.setState({showModal:true});
  //   console.log("jvvvvvvvvvvvvvvv")
  // }

  openModal = (adminName) => {
    this.userInfo(adminName);
  };

  userInfo(adminName){
    fetch(
      `${window.appConfig.userInfo}${window.appConfig.Relam}${adminName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          userInfoData : res.requestInfo,
          showModal: true,
        });
      })
      .catch((err) => {
        console.log("err", err)
      });
  };

  closeModal = () => this.setState({ showModal: false });

  render() {
    const { 
      joinwithus, 
      farmgridsearched,
      filterJoinwithus,
    } = this.state;
    const clubsList = joinwithus.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    return (
      <>
        <ClubDetails 
          show={this.state.showModal} 
          onHide={this.closeModal}
          userInfo={this.state.userInfoData}
        />
    
        <div className="club-container investment-card-wrapper">
          <div id="tendersContainer" className="container-fluid">
          <h3 class="container-title">Production Manager for GBR</h3>
            {/* <div className="mb-3">
              <div className="dropdown">
                <div className="navbarRight navbarRight-mod">
                  <SearchBar
                    placeholder="Search Club Here..."
                    value={farmgridsearched}
                    onChange={(searchedVal) =>
                      this.requestFarmGridSearch(
                        searchedVal
                      )
                    }
                    onCancelSearch={() => this.cancelFarmGridSearch()}
                  />
                </div>
              </div>
            </div> */}
            {clubsList.length > 0 ? (
              <Row className="justify-content-center">
                {clubsList.map((joindata, index) => {
                  return (
                    <Col lg={4} md={6} sm={12} className="d-flex mb-3">
                      <div className="w-100 d-flex">
                        <div
                          className="card bg-light mb-3"
                          style={{ width: "100%" }}
                        >
                          <div className="text-left card-header container-heading1 mt-0 mb-0 d-flex justify-content-between ">
                            <div className="club-heading">
                              {joindata.username} AAMC
                            </div>{" "}
                            <div>
                              {" "}
                              <a
                                className="text-white me-2"
                                href={`tel:${joindata.phone}`}
                              >
                                <BsTelephoneFill />
                              </a>
                              <a
                                className="text-white"
                                href={`mailto:${joindata.email}`}
                              >
                                <MdEmail />
                              </a>
                            </div>
                          </div>
                          <div className="card-body">
                            <h6
                              className="card-title container-heading2 mb-0"
                              onClick={()=>this.openModal(joindata.username)}
                            >
                              Click here to view more details
                            </h6>

                            {/* {
                            joindata.assettypes.map((assettypes, index)=>{
                              return(
                                <h6 className=" card-content mb-2"><b>Asset Type: </b> {assettypes.assettype}</h6>
                              )
                            })
                          } */}
                            {/* <div className="signdiv d-flex justify-content-between">
                              <button
                                type="button"
                                className="btn btn-primary signbutton mt-2"
                                onClick={() =>
                                  this.loginHandler(joindata.username)
                                }
                              >
                                <a
                                  className="text-white text-decoration-none"
                                  href="/auth/3/cbnsignup"
                                >
                                  Join
                                </a>
                              </button>
                              <button
                                type="button"
                                className="btn btn-success signbutton mt-2"
                                onClick={() =>
                                  this.loginHandler(joindata.username)
                                }
                              >
                                <a
                                  className="text-white text-decoration-none"
                                  href="/auth/3/cbnsignin"
                                >
                                  SignIn
                                </a>
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
                <div className="paginationComponent">
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.clubsPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </Row>
            ) : (
              <div className="card">
                <p className="card-body container-heading2 mb-0">
                  <b> Club's Not Found </b>
                </p>
              </div>
            )}
          </div>
        </div>
       
      </>
    );
  }
}

export default AssetDeveloperClub;
