import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Axios from "axios";
import MainService from "../../../../Common/MainService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import backicon from "../../Route/viewrouteimages/left-arrow.png";

const ViewVehicleSchedule = ({ location }) => {
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [scheduleData, setScheduleData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const [buttonClicked, setButtonClicked] = useState(false);

  const [endDate, setEndDate] = useState(() => {
    const sevenDaysAfterStartDate = new Date(startDate);
    sevenDaysAfterStartDate.setDate(startDate.getDate() + 7);
    return sevenDaysAfterStartDate;
  });

  const history = useHistory();
  const { selectedDriver } = location.state;
  console.log(selectedDriver, "SELECTED DRIVER:::::");
  const { selectedVehicle } = location.state;
  console.log(selectedVehicle, "SELECTED vehicle::::///");

  const fetchVehicleSchedule = async () => {
    try {
      

      const formattedStartDate = format(startDate, "dd-MM-yyyy");
      const formattedEndDate = format(endDate, "dd-MM-yyyy");

      const apiUrl = `${selectedVehicle}?fromDate=${formattedStartDate}&toDate=${formattedEndDate}&start=0&limit=100`;

      const response = await MainService.getVehicleSchedule(username,apiUrl);
      
      setScheduleData(response);
    } catch (error) {
      console.error("Error fetching driver schedule:", error);
    } finally {
      setButtonClicked(true);
    }
  };

  useEffect(() => {
    fetchVehicleSchedule();
  }, [startDate, endDate]);

  return (
    <>
      <div>
        <Button
          onClick={() =>
            history.push("/main/brokerTripPlanner", {
              routePath: "tripplanner",
            })
          }
        >
          <img
            src={backicon}
            alt="back icon"
            style={{ width: "2.5rem", height: "2.5rem" }}
          />
        </Button>
      </div>
      <div
        style={{
          padding: "20px",
          textAlign: "center",
          marginBottom: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          background: "linear-gradient(to bottom, #FFFFFF, #F4F4F4)",
        }}
      >
        <Typography
          variant="h5"
          component="h1"
          style={{ color: "#007BFF", marginBottom: "15px" }}
        >
          UPCOMING SCHEDULE: {selectedVehicle}
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            padding: "40px",
            display: "inline-block",

            borderRadius: "9px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <Box mb={2}>
            <label style={{ marginRight: "8px" }}>
              <b>Start Date:</b>
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                const sevenDaysAfterStartDate = new Date(date);
                sevenDaysAfterStartDate.setDate(date.getDate() + 7);
                setEndDate(sevenDaysAfterStartDate);
              }}
            />
          </Box>
          <Box mb={2}>
            <label style={{ marginRight: "12px" }}>
              <b>End Date:</b>
            </label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Box>
        </Box>
      </div>

      <Grid container spacing={2}>
        {buttonClicked &&
          (Array.isArray(scheduleData.tripList) && scheduleData.tripList.length > 0 ? (
            scheduleData.tripList.map((scheduleItem) => (
              <Grid item key={scheduleItem.id} xs={12} sm={4} md={4}>
                <Box
                  sx={{
                    borderRadius: 8,
                    padding: 3,
                    marginTop: "20px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    transition: "transform 0.2s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  {/* Render content for each schedule item */}
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan={2}>
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{ color: "#2e86de", fontWeight: "bold" }}
                            >
                              {scheduleItem.tripId}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <strong>Driver Id:</strong>
                          </TableCell>
                          <TableCell>{scheduleItem.driverId}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Vehicle Id:</strong>
                          </TableCell>
                          <TableCell>{scheduleItem.vehicleId}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Start Time:</strong>
                          </TableCell>
                          <TableCell>{scheduleItem.startTime}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Created Date:</strong>
                          </TableCell>
                          <TableCell>{scheduleItem.createdDate}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Status:</strong>
                          </TableCell>
                          <TableCell>{scheduleItem.status}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            ))
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: "200px" }}
            >
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h6" color="textSecondary">
                  Oops! No schedule data available.
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginTop: "8px" }}
                >
                  It seems there are no upcoming schedule for the selected
                  vehicle.
                </Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default ViewVehicleSchedule;
