import React, {useState, useEffect, useMemo} from "react";
import { Table, TableCell, TableRow, TableBody, TableHead, TableContainer, Typography , Button, Box, Fab,TablePagination, Paper, styled} from '@mui/material';
import MainService from "../../Common/MainService";

import { useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import AddIcon from '@mui/icons-material/Add';
import AddOrder from "../ordermanage/AddOrder";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[3],
  marginTop: theme.spacing(2),
}));
const columns = [
  { id: 'orderId', label: 'Order Id', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'timeStamp', label: 'Time Stamp', align: 'center' },
  { id: 'actions', label: 'Action', align: 'center' },
];

const DeliveryRequestsComponent = ({fromDate}) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const[test, setTest] = useState(false);
  const {user} = MainService.getLocalData();
  const {username, parentId} = user;
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPage = Math.min(page, Math.floor(data.length / newRowsPerPage));
  
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
  };
 
  const getAllOrderByRequestTypes = () =>{
    const requestType = 'deliveryRequest';
    MainService.getAllOrderIds1(requestType)
     .then((response) => {
     setData(response);
     console.log('delveryyyyyyyyyyyy',response);
     })
     .catch((error)=> {
       return NotificationManager.error(error);
     })
     }
  
  useMemo(() => {
    getAllOrderByRequestTypes();
  }, []);

  return (
    <div>
     <Box>
    <Fab
      color="primary"
      size={"large"}
      aria-label="add"
      title="create Order"
      
      // className={classes.fab}

      sx={{ position: "fixed"  , bottom: "50px", right: "50px", }}
      onClick={() => {
        setTest(true)
      }}
    >
      <AddIcon></AddIcon>

    </Fab>

  </Box>
  {test === true ? <AddOrder optType="delivery" listing="deli"/> :  <StyledTableContainer >
      
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={10}>
                <Typography variant="h6" align="center" fontWeight="bold">
                  All Delivery's
                </Typography>
              </TableCell>
            </TableRow>
      <TableRow align="center">
        {columns.map((column) => (
          <TableCell key={column.id} align={column.align}>
            {column.label}
          </TableCell>
        ))}
      </TableRow>
          </TableHead>
            <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
            <TableRow key = {item.orderId}>

                <TableCell align="center">{item.orderId}</TableCell>
                <TableCell align="center"> {item.status === null ? "null" : item.status} </TableCell>
                <TableCell align="center">{item.timeStamp} </TableCell>
                <TableCell align='center'>
                <div style={{ display: 'flex', gap: '10px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                  
                    onClick={() => {
                      
                    }}
                  >
                    Request
                  </Button>
                  <Button
                    variant="contained"
                   
                    color="primary"
                    size="small"
                    onClick={() => {
                      
                    }}
                  >
                    Cancel
                  </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
         
        </Table>
        <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      </StyledTableContainer> }

    </div>
  );
}

export default DeliveryRequestsComponent;