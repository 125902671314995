import React from "react";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import FarmersNeed from "./FarmersNeeds";
import FarmersServices from "./FarmersServices";
import "./Farmers.css";

export default function Farmers() {
  return (
    <>
      <UpdatesBanner className="ap-farmers" bannerText="Farmers" />
      <FarmersNeed />
      <FarmersServices />
    </>
  );
}
