import GridIcon from "@material-ui/icons/GridOnOutlined";
import ListIcon from "@material-ui/icons/List";
import { Box, Button } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import ContentDynamicCard from "../../Common/CommonContent/ContentDynamicCard";
import ContentDynamicTable from "../../Common/CommonContent/ContentDynamicTable";
import MainService from "../../Common/MainService";
import PlaceholderCardList from "../ModelProductManagement/PlaceHolderCardList";

const dynamicKey = [
  "aboutusLink",
  "CustomerCare_number",
  "CustomerCare_email",
  "CustomerCare_fax",
  "Address",
];

const dynamicKeyTable = [
  "aboutusLink",
  "CustomerCare_number",
  "CustomerCare_email",
  "CustomerCare_fax",
  "Address",
];

const CustomerCareComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [customerSupportList, setCustomerSupportList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);

  useEffect(() => {
    getCustomerSupportList();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* View All Infos */
  const getCustomerSupportList = () => {
    setIsLoading(true);
    MainService.customerSupport(parentId)
      .then((res) => {
        setCustomerSupportList(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        return NotificationManager.error(err);
      });
  };
  /* End All Infos */

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };

  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  return (
    <>
      <Box className="container" id="mainContentList">
        <Box style={{ float: "right" }}>
          <Button
            startIcon={<ListIcon />}
            size="large"
            onClick={listViewClick}
          ></Button>
          <Button
            startIcon={<GridIcon />}
            size="large"
            onClick={gridViewClick}
          ></Button>
        </Box>

        {listView &&
          (isLoading ? (
            <Box>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          ) : (
            <ContentDynamicTable
              columns={dynamicKeyTable}
              data={customerSupportList}
              actions={[]}
            />
          ))}
        {gridView &&
          (isLoading ? (
            <PlaceholderCardList numberOfCards={1} heightVal={300} />
          ) : (
            <ContentDynamicCard
              dynamicKey={dynamicKey}
              data={customerSupportList}
              actions={[]}
            />
          ))}
      </Box>
    </>
  );
};
export default CustomerCareComponent;
