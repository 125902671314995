import React from "react";
import {
    Button,
    Col,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row, Container,
    Pagination, PaginationItem, PaginationLink,
    Badge,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import AuthAxiosInstance from "../../../authAxiosInstance";
import { DeviceMetadataAxiosInstance } from '../../../authAxiosInstance'
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { CheckCircleOutline, Cancel } from '@mui/icons-material';
import { NotificationManager } from "react-notifications";

// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// toast.configure({
//     autoClose: 3000,
//     draggable: false,
//     //etc you get the idea
// });

function Model(props) {
    const [deviceType, setDeviceType] = React.useState([]);
    const [deviceList, setDeviceList] = React.useState([]);
    const [deviceProperty, setDeviceProperty] = React.useState([]);
    const [modelList, setModelList] = React.useState([]);
    const [type, selectedType] = React.useState("");
    const [model, selectedModel] = React.useState("");
    const [firmwareButtonEnable, setFirmwareButtonEnable] = React.useState(false);
    let [primary, setPrimary] = React.useState(false);
    let [errors, setError] = React.useState("");
    let [accordion, setAccordion] = React.useState([true]);
    let [disabledstate, setDisabled] = React.useState(true);
    let [devicetypenew, setDevicetypenew] = React.useState("");
    let [modelinfo, setModelinfo] = React.useState("");
    let [modelname, setModelname] = React.useState("");
    let [createnewrecord, setCreatenewrecord] = React.useState(true);
    let [deleteconfirm, setDeleteconfirm] = React.useState(false);
    let [wrongformat, setWrongformat] = React.useState(false);
    let [wrongmin, setWrongmin] = React.useState(false);
    let [wrongspecial, setWrongspecial] = React.useState(false);
    let [wrongmincount, setWrongmincount] = React.useState(0);
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;



    const getdata = () => {
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/list`).then((res) => {

            if (res.data)
                setDeviceType(res.data);
            else
                setDeviceType([]);
        }).catch((err) => {
            setDeviceType([]);
        });

    }

    React.useEffect(() => {

        //Getting Device Type List Axios Instance


        getdata();


        validatefield();


    }, [devicetypenew, modelinfo, modelname]);

    const selectDevice = (evt) => {
        //const filterModelDevice = deviceList.filter((d) => (d.deviceType === evt.target.value));
        selectedType(evt.target.value);
        //setModelList(filterModelDevice);
        setModelList([]);
        selectedModel("");
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${evt.target.value}/models`).then((res) => {
            setModelList(res.data);
        }).catch((err) => {
            setModelList([]);
        });
        setDeviceProperty([]);
    }
    const modelName = (evt) => {
        setModelname(evt.target.value);
        if (evt.target.value.length < devicetypenew.length) {
            setWrongmincount(devicetypenew.length);
            setWrongmin(true);
        }
        else {
            setWrongmin(false);
        }
        let nospecial = /[*|\":<>[\]{}`\\()';@&$!#^=+%`~,./? _\-]/;
        if (nospecial.test(evt.target.value)) {
            setWrongspecial(true);
        }
        else {
            setWrongspecial(false);
        }
    }

    const modelInfo = (evt) => {
        try {
            setModelinfo(JSON.stringify(JSON.parse(evt.target.value), undefined, 4));
            setWrongformat(false);
        }
        catch (e) {
            setModelinfo(evt.target.value);
            setWrongformat(true);

        }
    }

    const createmodelinfo = () => {
        try {
            let req = {};
            req.deviceType = devicetypenew;
            req.model = modelname;
            req.modelInfo = {
                "properties": JSON.parse(modelinfo)
            }
            req.modelInfo.NumberofProperties = req.modelInfo.properties.length;
            if (createnewrecord) {
                DeviceMetadataAxiosInstance.post(`/admin/deviceprofile/${username}/models/modelInfo`, req)
                    .then((response) => {
                        setPrimary(!primary);
                        setModelinfo('');
                        setModelname('');
                        setDevicetypenew('');
                        // toast.success("Model info created successfully", {
                        //     position: toast.POSITION.TOP_RIGHT
                        // });
                        getdata();
                        return NotificationManager.success("Model info created successfully");
                    }).catch((err) => {
                        if (err.response && typeof (err.response.data) === 'string') {
                            // toast.error(err.response.data, {
                            //     position: toast.POSITION.TOP_RIGHT
                            // });
                            return NotificationManager.error(err.response.data);
                        }
                        else {
                            // toast.error("Some error occured", {
                            //     position: toast.POSITION.TOP_RIGHT
                            // });
                            return NotificationManager.error("Some error occured");
                        }
                    });
            }
            else {
                DeviceMetadataAxiosInstance.put(`/admin/deviceprofile/${username}/modelInfo/${type}/${model}`, req)
                    .then((response) => {
                        setPrimary(!primary);
                        setModelinfo('');
                        setModelname('');
                        setDevicetypenew('');
                        // toast.success("Model info updated successfully", {
                        //     position: toast.POSITION.TOP_RIGHT
                        // });
                        getProperty();
                        return NotificationManager.success("Model info updated successfully");

                    }).catch((err) => {
                        // toast.error("Some error occured", {
                        //     position: toast.POSITION.TOP_RIGHT
                        // });
                        return NotificationManager.error("Some error occured");

                    });
            }
        }
        catch (e) {
            // toast.error("Some error occured", {
            //     position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");
        }
    }

    function validatefield() {
        if (modelname && devicetypenew && modelinfo) {
            if (wrongformat) {
                setDisabled(true);
                setError("Please fill model info in correct JSON format");
            }
            else {
                if (wrongmin) {
                    setDisabled(true);
                    setError(`Minimum length for model name is ${wrongmincount}`);
                }
                else {
                    if (wrongspecial) {
                        setDisabled(true);
                        setError(`No Special characters allowed in model name`);
                    }
                    else {
                        setDisabled(false);
                        setError("");
                    }
                }

            }
        }
        else {
            setDisabled(true);
            setError("Please fill all the fields");
        }
    }

    const selectModel = (evt) => {
        selectedModel(evt.target.value);
        setDeviceProperty([]);
    }

    const selectDeviceNew = (evt) => {
        setDevicetypenew(evt.target.value);
        setModelname(evt.target.value);
        setWrongmin(false);
        setWrongspecial(false);
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/${evt.target.value}`)
            .then((response) => {
                if (response && response.data && response.data.metadata)
                    setModelinfo(response.data.metadata);
            }).catch((err) => {
                // toast.error("Some error occured", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                return NotificationManager.error("Some error occured");
            });
    }

    const togglePrimary = () => {
        setPrimary(!primary);
        setModelinfo('');
        setModelname('');
        setDevicetypenew('');
        setError("");
    }

    const deleteProperty = () => {
        setDeleteconfirm(!deleteconfirm);
    }

    const toggledeleteconfirm = () => {
        setDeleteconfirm(!deleteconfirm);
    }

    const onclickdelete = () => {
        DeviceMetadataAxiosInstance.delete(`/admin/deviceprofile/${username}/modelInfo/${type}/${model}`)
            .then((response) => {
                setDeleteconfirm(!deleteconfirm);
                // toast.success("Model info deleted successfully", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                let e = {};
                e.target = {};
                e.target.value = type;
                selectDevice(e);
                setDeviceProperty([]);
                return NotificationManager.success("Model info deleted successfully");
            }).catch((err) => {
                // toast.error("Some error occured", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                return NotificationManager.error("Some error occured");
            });
    }

    const toggleAccordion = (tab) => {
        const prevState = accordion;
        const state = prevState.map((x, index) => tab === index ? !x : false);
        setAccordion(state);
    }

    const createnew = () => {
        setPrimary(true);
        setCreatenewrecord(true);
        setError('');
    }

    const getProperty = () => {
        if (type && model) {
            DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/modelInfo/${type}/${model}`).then((res) => {
                if (res.data) {
                    setModelinfo(res.data.properties);
                    if (Array.isArray(res.data.properties)) {
                        setDeviceProperty(res.data.properties);
                    }
                    else {
                        let a = [];
                        for (let i = 0; i < Object.keys(res.data.properties).length; i++) {
                            let o = {};
                            o.property = Object.keys(res.data.properties)[i];
                            o.valuetype = typeof res.data.properties[Object.keys(res.data.properties)[i]];
                            o.mandatory = true;
                            if (o.valuetype === 'object') {
                                if (Array.isArray(res.data.properties[Object.keys(res.data.properties)[i]])) {
                                    o.valuetype = 'array';
                                }
                                o.value = JSON.stringify(res.data.properties[Object.keys(res.data.properties)[i]], undefined, 4)
                            }
                            else {
                                o.value = res.data.properties[Object.keys(res.data.properties)[i]];
                            }
                            a.push(o);
                        }
                        //a.push(res.data.properties);
                        setDeviceProperty(a);
                    }
                }
                else {
                    setDeviceProperty([]);
                }
            }).catch((err) => {
                setDeviceProperty([]);
            });
        }
        else {
            // toast.error("Please select type and model!!", {
            //     position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");
        }
    }

    const updateProperty = () => {
        setPrimary(true);
        setDevicetypenew(type);
        setModelname(model);
        setModelinfo(JSON.stringify(modelinfo, undefined, 4));
        setCreatenewrecord(false);

        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/${type}`)
            .then((response) => {
                if (response && response.data && response.data.metadata)
                    setModelinfo(response.data.metadata);
            }).catch((err) => {
                // toast.error("Some error occured", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                return NotificationManager.error("Some error occured");
            });

    }

    const firmwareUpdate = () => {
        console.log(type, model);
        alert(type, model)
        // AuthAxiosInstance.get(`/admin/apiToBeGiven`)
        // .then((response) => {

        //     toast.success("Model info deleted successfully", {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        //     let e = {};
        //     e.target = {};
        //     e.target.value = type;
        //     selectDevice(e);
        //     setDeviceProperty([]);
        // }).catch((err) => {
        //     toast.error("Some error occured", {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        // });
    }



    return (
        <div className="animated fadeIn" >
            <strong className="LabelHeader">Model Details</strong>
            <div className="alignright">
                <Button type="button" onClick={createnew.bind(this)} className="custom-button" color="primary" > Create New Model info</Button>
            </div>
            <Row>
                <Col xs="12" sm="12">

                    <Col xs="12" md="12">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Device Type</InputLabel>
                                    <Select value={type} label='Device Type' onChange={selectDevice.bind(this)}>
                                        <MenuItem value="">Select Device Type</MenuItem>
                                        {deviceType.map((d, i) => (
                                            <MenuItem value={d} key={i + 1}>
                                                {d}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Device Model</InputLabel>
                                    <Select label='Device Model' value={model} onChange={selectModel.bind(this)}>
                                        <MenuItem value="">Select Device Model</MenuItem>
                                        {modelList.map((d, i) => (
                                            <MenuItem value={d} key={i + 1}>
                                                {d}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item md={2} style={{ marginTop: "10px" }}>
                                <Button className="custom-button" onClick={getProperty.bind(this)}>
                                    Get Model Info
                                </Button>
                            </Grid>

                            {/* <Grid item md={2} style={{ marginTop: "10px" }}>
                                <Button className="custom-button" onClick={updateProperty.bind(this)} disabled={!deviceProperty.length > 0}>
                                    Update Model Info
                                </Button>
                            </Grid> */}

                            <Grid item md={2} style={{ marginTop: "10px" }}>
                                <Button className="custom-button" onClick={deleteProperty.bind(this)} disabled={!deviceProperty.length > 0}>
                                    Delete Model Info
                                </Button>
                            </Grid>
                        </Grid>
                    </Col>
                    <TableContainer component={Paper}>
                        {deviceProperty && deviceProperty.length > 0 && (
                            <Table size="medium">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Property Name</TableCell>
                                        <TableCell>Property Type</TableCell>
                                        <TableCell>Property Value</TableCell>
                                        <TableCell>Mandatory</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {deviceProperty.map((dev, ind) => (
                                        <TableRow key={ind + 1}>
                                            <TableCell>{dev.property}</TableCell>
                                            <TableCell>{dev['value type'] ? dev['value type'] : dev['valuetype']}</TableCell>
                                            <TableCell>
                                                <Typography variant="body2" noWrap>
                                                    {dev['value']}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {dev.mandatory ? (
                                                    <CheckCircleOutline color="success" fontSize="large" />
                                                ) : (
                                                    <Cancel color="error" fontSize="large" />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                </Col>
            </Row>

            {/* <Modal isOpen={primary} toggle={togglePrimary}
                className={'modal-lg ' + props.className}>
                <ModalHeader toggle={togglePrimary}>{createnewrecord ? 'Add New Model Info' : 'Update Model Info'}</ModalHeader>
                <ModalBody>
                    <div id="accordion">
                        <Label className="help-block" id="errorstyle">{errors}</Label>
                        <Card className="mb-0">
                            <CardHeader id="headingOne">
                                <Button block color="link" className="text-left m-0 p-0" onClick={() => toggleAccordion(0)} aria-expanded={accordion[0]} aria-controls="collapseOne">
                                    <h5 className="m-0 p-0">Model info Details <strong className="redfont">*</strong></h5>
                                </Button>
                            </CardHeader>
                            <Collapse isOpen={accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                                <CardBody>
                                    <Form>
                                        <FormGroup row>
                                            <Col md="6" xs="12">
                                                <Label htmlFor="devicetype">Device Type</Label>
                                                <Input disabled={!createnewrecord} type="select" name="devicetype" value={devicetypenew}
                                                    onChange={selectDeviceNew.bind(this)}>
                                                    <option value="">Select Device Type</option>
                                                    {
                                                        deviceType.map((d, i) => {
                                                            return <option value={d} key={i + 1}>{d}</option>
                                                        })
                                                    }
                                                </Input>
                                            </Col>
                                            <Col xs="12" md="6">
                                                <Label htmlFor="modelname">Model Name</Label>
                                                <Input disabled={!createnewrecord} type="text" name="modelname" maxLength={20} value={modelname} onChange={modelName} placeholder="Enter Model Name" required />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Col md="12" xs="12">
                                                <Label htmlFor="modelinfo">Model Info</Label>
                                                <pre>
                                                    <Input type="textarea" rows="10" value={modelinfo} onChange={modelInfo} name="modelname" placeholder='Enter correct JSON format based on the device type.' required />
                                                </pre>
                                            </Col>

                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Collapse>
                        </Card>


                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={disabledstate} onClick={createmodelinfo}>{createnewrecord ? 'Create' : 'Update'}</Button>
                    <Button color="primary" onClick={togglePrimary}>Cancel</Button>
                </ModalFooter>
            </Modal> */}
            <Dialog open={primary} onClose={togglePrimary} >
                <DialogTitle>
                    {createnewrecord ? 'Add New Model Info' : 'Update Model Info'}
                </DialogTitle>
                <DialogContent>
                    <Typography className="help-block" id="errorstyle">
                        {errors}
                    </Typography>
                    <div id="accordion">
                        <CardHeader>
                            <Button
                                fullWidth
                                color="link"
                                onClick={() => toggleAccordion(0)}
                            >
                                <Typography variant="h5">
                                    Model Info Details{' '}
                                    <strong style={{ color: 'red' }}>*</strong>
                                </Typography>
                            </Button>
                        </CardHeader>
                        <Collapse
                            in={accordion[0]}
                            data-parent="#accordion"
                            aria-labelledby="headingOne"
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Device Type</InputLabel>
                                        <Select
                                            value={devicetypenew}
                                            onChange={selectDeviceNew}
                                            disabled={!createnewrecord}
                                            fullWidth
                                        >
                                            <MenuItem value="">Select Device Type</MenuItem>
                                            {deviceType.map((d, i) => (
                                                <MenuItem value={d} key={i + 1}>
                                                    {d}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        type="text"
                                        name="modelname"
                                        maxLength={20}
                                        value={modelname}
                                        onChange={modelName}
                                        label="Model Name"
                                        fullWidth
                                        placeholder="Enter Model Name"
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                type="textarea"
                                name="modelinfo"
                                rows={10}
                                sx={{ width: '500px' }}
                                value={modelinfo}
                                onChange={modelInfo}
                                label="Model Info"
                                multiline
                                fullWidth
                                placeholder="Enter correct JSON format based on the device type."
                                required
                            />
                        </Collapse>
                    </div>
                </DialogContent>
                <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px" }}>
                    <Button className="custom-button" style={{ marginRight: "5px" }} disabled={disabledstate} onClick={createmodelinfo}>{createnewrecord ? 'Create' : 'Update'}</Button>
                    <Button className="custom-button-cancel" onClick={togglePrimary}>Cancel</Button>
                </div>
            </Dialog>

            {/* <Modal isOpen={deleteconfirm} toggle={toggledeleteconfirm}
                className={'modal-danger ' + props.className}>
                <ModalHeader toggle={toggledeleteconfirm}>Delete Model info</ModalHeader>
                <ModalBody>
                    <strong>Are you sure, You want to delete this Model info ?</strong>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={onclickdelete.bind(this)}>Delete</Button>{' '}
                    <Button color="secondary" onClick={toggledeleteconfirm}>Cancel</Button>
                </ModalFooter>
            </Modal> */}

            <Dialog open={deleteconfirm} onClose={toggledeleteconfirm}>
                <DialogTitle>Delete Device Type</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        <strong>Are you sure you want to delete this Device Type?</strong>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onclickdelete.bind(this)} className="custom-button" style={{ backgroundColor: "red" }}>
                        Delete
                    </Button>
                    <Button onClick={toggledeleteconfirm} className="custom-button-cancel">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>


        </div>
    )
}



export default Model;