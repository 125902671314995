import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InfoIcon from "@material-ui/icons/Info";
import FenceIcon from "@mui/icons-material/Fence";
import CategoryIcon from "@material-ui/icons/Category";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StorageIcon from "@material-ui/icons/Storage";
import MoneyIcon from "@material-ui/icons/Money";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AppsIcon from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TenderManagementIcon from "@material-ui/icons/AutorenewRounded";
import TenderSiteManagementIcon from "@material-ui/icons/EditLocationRounded";
import PostManagementIcon from "@material-ui/icons/InsertCommentRounded";
import FarmSiteManagementIcon from "@material-ui/icons/PersonPinCircle";
import UserManagementIcon from "@material-ui/icons/PeopleAlt";
import SiteManagementIcon from "@material-ui/icons/PlaceRounded";
import LegacyAssetMappingIcon from "@material-ui/icons/PetsRounded";
import AssetOEMIcon from "@material-ui/icons/Person";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UploadDocumentsIcon from "@material-ui/icons/CloudUpload";
import StatisticsIcon from "@material-ui/icons/Assessment";
import StatusIcon from "@material-ui/icons/QueryBuilder";
import SettingsIcon from "@material-ui/icons/Settings";
import SupportICon from "@material-ui/icons/ContactSupport";
import UserIcon from "@material-ui/icons/Person";
import ProductionManagerIcon from "@material-ui/icons/AccountBox";
import PeopleGroupIcon from "@material-ui/icons/Group";
import WorkerIcon from "@material-ui/icons/AccessibilityNew";
import BrokerIcon from "@material-ui/icons/ContactPhone";
import EventsIcon from "@material-ui/icons/Event";
import CommuteIcon from "@mui/icons-material/Commute";
import UpdateIcon from "@material-ui/icons/Update";
import PriceIcon from "@material-ui/icons/MoneyOutlined";
import BuyInputIcon from "@material-ui/icons/Storefront";
import AdviceIcon from "@material-ui/icons/EmojiObjects";
import DoctorIcon from "@material-ui/icons/AssignmentInd";
import FeaturedVideoIcon from "@material-ui/icons/FeaturedVideo";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import WorkIcon from "@material-ui/icons/Work";
import ForumIcon from "@mui/icons-material/Forum";
import ViewListIcon from "@mui/icons-material/ViewList";
import PresentToAllTwoToneIcon from "@material-ui/icons/PresentToAllTwoTone";
import CachedIcon from "@material-ui/icons/Cached";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import MonitorIcon from "@mui/icons-material/Monitor";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import sha256 from "sha256";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { useMediaPredicate } from "react-media-hook";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Main from "../Common/MainService";
import "react-notifications/lib/notifications.css";
import "./MainPageComponent.css";
import AppData from "../../../data.json";
import AddToCart from "../AssetOEMRole/AddToCart/AddToCartComponent";
import MainService from "../Common/MainService";
import { CartItemContext } from "../../../context/CartItemContext";
import Tooltip from "@mui/material/Tooltip";
import { Padding } from "@mui/icons-material";
import MainDrawer from "./MainDrawer";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import QrCodeIcon from "@mui/icons-material/QrCode";
import BuildIcon from "@mui/icons-material/Build";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleIcon from "@mui/icons-material/People";
import GroupIcon from "@mui/icons-material/Group";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PaymentIcon from "@mui/icons-material/Payment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import StarIcon from '@material-ui/icons/Star';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

export default function MainPageComponent(props) {
  var i;
  var postItems = [];
  const { user } = Main.getLocalData();
  const { username, parentId } = user;
  const [userTypeValue, setUserTypeValue] = useState("");
  const [userTypeRole, setUserTypeRole] = useState("");
  const [userTypeRoleProfile, setUserTypeRoleProfile] = useState("");
  // const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [redirecTo, setRedirecTo] = useState("");
  const [showChangePwd, setShowChangePwd] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(
    props.location.pathname.replace("/main", "")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [cartPopup, setCartPopup] = useState(false);

  const { cartStateChangeHandler } = useContext(CartItemContext);

  const iconComponents = {
    FormatQuoteIcon:FormatQuoteIcon,
    ShoppingCartIcon:ShoppingCartIcon,
    StarIcon:StarIcon,
    AttachFileIcon: AttachFileIcon,
    ReceiptIcon: ReceiptIcon,
    PaymentIcon: PaymentIcon,
    VerifiedUserIcon: VerifiedUserIcon,
    SupervisorAccountIcon: SupervisorAccountIcon,
    GroupIcon: GroupIcon,
    PeopleIcon: PeopleIcon,
    GroupAddIcon: GroupAddIcon,
    PersonAddIcon: PersonAddIcon,
    BuildIcon: BuildIcon,
    QrCodeIcon: QrCodeIcon,
    ArrowUpwardIcon: ArrowUpwardIcon,
    FeaturedVideoIcon: FeaturedVideoIcon,
    AccountTreeIcon: AccountTreeIcon,
    WorkIcon: WorkIcon,
    PresentToAllTwoToneIcon: PresentToAllTwoToneIcon,
    DashboardIcon: DashboardIcon,
    CloudUploadIcon: CloudUploadIcon,
    CategoryIcon: CategoryIcon,
    AssignmentIndIcon: AssignmentIndIcon,
    AssignmentTurnedInIcon: AssignmentTurnedInIcon,
    AccessibilityNewIcon: AccessibilityNewIcon,
    AssignmentIcon: AssignmentIcon,
    StorageIcon: StorageIcon,
    MoneyIcon: MoneyIcon,
    CloudQueueIcon: CloudQueueIcon,
    ManageAccountsIcon: ManageAccountsIcon,
    DriveEtaIcon: DriveEtaIcon,
    AssessmentIcon: AssessmentIcon,
    InfoIcon: InfoIcon,
    TenderManagementIcon: TenderManagementIcon,
    TenderSiteManagementIcon: TenderSiteManagementIcon,
    PostManagementIcon: PostManagementIcon,
    FarmSiteManagementIcon: FarmSiteManagementIcon,
    UserManagementIcon: UserManagementIcon,
    SiteManagementIcon: SiteManagementIcon,
    LegacyAssetMappingIcon: LegacyAssetMappingIcon,
    AssetOEMIcon: AssetOEMIcon,
    UploadDocumentsIcon: UploadDocumentsIcon,
    StatisticsIcon: StatisticsIcon,
    StatusIcon: StatusIcon,
    SettingsIcon: SettingsIcon,
    SupportICon: SupportICon,
    UserIcon: UserIcon,
    ProductionManagerIcon: ProductionManagerIcon,
    PeopleGroupIcon: PeopleGroupIcon,
    WorkerIcon: WorkerIcon,
    BrokerIcon: BrokerIcon,
    ForumIcon: ForumIcon,
    EventsIcon: EventsIcon,
    UpdateIcon: UpdateIcon,
    PriceIcon: PriceIcon,
    BuyInputIcon: BuyInputIcon,
    AdviceIcon: AdviceIcon,
    DoctorIcon: DoctorIcon,
    CommuteIcon: CommuteIcon,
    SpaceDashboardIcon: SpaceDashboardIcon,
    AccountCircleIcon: AccountCircleIcon,
    MonitorIcon: MonitorIcon,
    FenceIcon: FenceIcon,
    ViewListIcon: ViewListIcon,
  };

  useEffect(() => {
    getUserType();
    getUserTypeRoleProfile();
    getCartDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentMenu(props.location.pathname.replace("/main", ""));
  }, [props.location]);

  const getCartDetails = () => {
    MainService.findCartItems(username)
      .then((data) => {
        console.log(
          "datttttttttttt caaaaaaaaaaaaaaaaiiiiiiiiiitttttttemmmmmmmm",
          data
        );
        cartStateChangeHandler(...data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error("Network Error");
      });
  };
  const getUserType = () => {
    Main.getConsumerUserType(parentId, username)
      .then((data) => {
        localStorage.setItem("userType", data);
        setUserTypeValue(data);
        getUserTypeRole(data);
      })
      .catch((e) => {
        return NotificationManager.error("Network Error");
      });
  };

  const defaultNavItems = [
    {
      NULL: [
        {
          acc_menu: "PROFILE",
          acc_menuUrl: "/profile",
          acc_icon: "AccountCircleIcon",
          acc_desc: "This is the Profile page.",
        },
        {
          acc_menu: "ROLE UPGRADATION REQUEST",
          acc_menuUrl: "/roleUpdationRequest",
          acc_icon: "ArrowUpwardIcon",
          acc_desc: "This is the Role Updation page.",
        }
      ],
      BASIC: [
        {
          acc_menu: "PROFILE",
          acc_menuUrl: "/profile",
          acc_icon: "AccountCircleIcon",
          acc_desc: "This is the Profile page.",
        },
        {
          acc_menu: "ROLE UPGRADATION REQUEST",
          acc_menuUrl: "/roleUpdationRequest",
          acc_icon: "ArrowUpwardIcon",
          acc_desc: "This is the Role Updation page.",
        },
      ],
      STANDARD: [
        {
          acc_menu: "PROFILE",
          acc_menuUrl: "/profile",
          acc_icon: "AccountCircleIcon",
          acc_desc: "This is the Profile page.",
        },
        {
          acc_menu: "ROLE UPGRADATION REQUEST",
          acc_menuUrl: "/roleUpdationRequest",
          acc_icon: "ArrowUpwardIcon",
          acc_desc: "This is the Role Updation page.",
        },
      ],
      PREFFERED: [
        {
          acc_menu: "PROFILE",
          acc_menuUrl: "/profile",
          acc_icon: "AccountCircleIcon",
          acc_desc: "This is the Profile page.",
        },
        {
          acc_menu: "ROLE UPGRADATION REQUEST",
          acc_menuUrl: "/roleUpdationRequest",
          acc_icon: "ArrowUpwardIcon",
          acc_desc: "This is the Role Updation page.",
        },
      ],
      PREMIUM: [
        {
          acc_menu: "PROFILE",
          acc_menuUrl: "/profile",
          acc_icon: "AccountCircleIcon",
          acc_desc: "This is the Profile page.",
        },
        {
          acc_menu: "ROLE UPGRADATION REQUEST",
          acc_menuUrl: "/roleUpdationRequest",
          acc_icon: "ArrowUpwardIcon",
          acc_desc: "This is the Role Updation page.",
        },
      ],
      ADMIN: [
        {
          acc_menu: "PROFILE",
          acc_menuUrl: "/profile",
          acc_icon: "AccountCircleIcon",
          acc_desc: "This is the Profile page.",
        },
        {
          acc_menu: "ROLE UPGRADATION REQUEST",
          acc_menuUrl: "/roleUpdationRequest",
          acc_icon: "ArrowUpwardIcon",
          acc_desc: "This is the Role Updation page.",
        },
      ],
    },
  ];
  const getUserTypeRole = (userType) => {
    Main.getConsumerUserTypeRole(parentId, username)
      .then((data) => {
        console.log("getUserTypeRole",data);
        if (data?.length == 0) {
          setUserTypeRole("NULL");
          getRoleAccessList(userType, "NULL");
          localStorage.setItem("UserRole", "NULL");
        } else {
          setUserTypeRole(data[0]?.name);
          console.log("getUserTypeRole else",data);
          getRoleAccessList(userType, data[0]?.name);
          localStorage.setItem("UserRole", data[0]?.name);
        }
      })
      .catch((error) => {
        console.error(error);
        return NotificationManager.error("Network Error");
      });
  };
  const getUserTypeForDataJson = (userType) => {
    const object = {
      "AssetOEM": "AssetOEM",
      FMCDealer: "FMCDealer",
      PMCDealer: "PMCDealer",
      PMC: "PMC",
      FMC: "FMC",
      OFC: "OFC",
      CBO: "CBO",
      AgriAssetDistributor: "AgriAssetDistributor",
      MerchantExporter: "MerchantExporter",
      "ENERGY-PROCESSOR": "EnergyProcessor",
      MainAdmin: "MainAdmin",
      LogisticsTransportUser: "LogisticsTransportUser",
      BROKER: "Broker",
      LogisticsProvider: "LogisticProvider",
      GovtDept: "GovtDept",
      AssetDeveloper: "AssetDeveloper",
      Agent: "Agent",
      AgriMiner: "AgriMiner",
      pico: "pico",
      "MSME-ADVISOR": "MSMEADVISOR",
      Buyer: "Buyer",
      "FOOD-PROCESSOR": "FOODPROCESSOR",
      AHSP: "AHSP",
      AIC: "AIC",
      FMC: "FMC",
      AssetCertifiedConsultant: "ACC",
      BiogasEquipCompany: "BiogasEquip",
      StorageCompany: "StorageCompany",
      AIC: "AIC",
      DeviceMaker: "DeviceMaker",
      BRAND: "BRAND",
      VerticalUsecaseDeveloper:'VerticalUsecaseDeveloper',

    };
    if (object[userType]) {
      return object[userType];
    } else {
      return null;
    }
  };
  const getUserTypeRoleProfile = () => {
    Main.getConsumerUserTypeRoleProfile(parentId, username)
      .then((data) => {
        console.log("getConsumerUserTypeRole profile in mainpage:", data);
        setUserTypeRoleProfile(data);
      })
      .catch((e) => {});
  };
  const getRoleAccessList = (userTypeValue, packageType) => {
    const userType = getUserTypeForDataJson(userTypeValue);
    const navitems = AppData[userType] && AppData[userType][0][packageType];
    console.log("packageType",navitems,userTypeValue,packageType)
    if (navitems === undefined || !userTypeValue || !packageType) {
      setMenuList(defaultNavItems[0]["NULL"]);
    } else {
      console.log("navitems:",navitems )     
        setMenuList(navitems);            
    }
  };

  const logout = () => {
    Main.doLogout();
    localStorage.setItem("Login" , true)
  };

  if (Main.isLoggedIn() !== true) {
    logout();
    return <>Unauthorised.</>;
  }
  const toggleChangePassword = () => {
    setShowChangePwd(!showChangePwd);
  };

  const cartHandler = () => {
    setCartPopup(true);
  };

  const reLoad = () => {
    window.location.reload();
  };

  const closeCartPopup = () => {
    setCartPopup(false);
  };
  const closeCartPopupFn = () => {
    setCartPopup(false);
  };

  const mainDashboardItem = () => {
    setIsLoading(true);
    if (userTypeRole) {
      return <>{props.children}</>;
    } else {
      return (
        <div className="main-dashboard-item-container">
          {isLoading && (
            <div className="loader-container">
              <div className="loader"></div>
              <h3>Loading...</h3>
            </div>
          )}
        </div>
      );
    }
  };

  const getMenuList = () => {
    return (
      <List>
        {menuList?.map((menu, index) => {
          const IconTagName = iconComponents[menu.acc_icon || "InfoIcon"];
          return (
            <ListItem
              style={{ height: "50px" }}
              selected={currentMenu === menu.acc_menuUrl}
              button
              key={index}
              component={Link}
              to={`/main${menu.acc_menuUrl}`}
            >
              <Tooltip
                style={{ Padding: "200px" }}
                title={menu.acc_menu}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <IconTagName />
                </ListItemIcon>
              </Tooltip>

              <ListItemText primary={menu.acc_menu} />
            </ListItem>
          );
        })}
      </List>
    );
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    return mobileOpen;
  };

  const doChangePwd = (evt) => {
    evt.preventDefault();
    const oldPwd = document.getElementById("oldPwd").value;
    const newPwd = document.getElementById("newPwd").value;
    const email = document.getElementById("mail").value;
    if (!oldPwd || !newPwd) {
      NotificationManager.error("Please enter valid input.");
      return;
    }
    const oldpassword = sha256(oldPwd);
    const password = oldpassword;
    const newpassword = sha256(newPwd);
    const pwd = newpassword;
    setIsLoading(true);
    Main.doChangePwd({
      username,
      email,
      oldpassword: password,
      newpassword: pwd,
    })
      .then((res) => {
        setIsLoading(false);
        NotificationManager.success("Password changed successfully.");

        toggleChangePassword();
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error(e);
      });
  };
  const changePwdBody = (
    <Grid container spacing={0}>
      <Grid item xs={11} sm={5} md={4} lg={3} className="loginOuterContainer">
        <div className="forgotPwdContainer">
          <form onSubmit={doChangePwd}>
            <h2>Change Password</h2>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mail">Email *</InputLabel>
              <Input
                id="mail"
                type="mail"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> vpn_key </i>{" "}
                  </InputAdornment>
                }
                require="true"
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">Current Password *</InputLabel>
              <Input
                id="oldPwd"
                type="password"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> vpn_key </i>{" "}
                  </InputAdornment>
                }
                require="true"
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">New Password *</InputLabel>
              <Input
                id="newPwd"
                type="password"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> vpn_key </i>{" "}
                  </InputAdornment>
                }
                require="true"
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true}>
              {isLoading === false && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="submit"
                >
                  Reset
                </Button>
              )}{" "}
              {isLoading === true && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className="loginLoader" />
                </Button>
              )}
            </FormControl>
            <FormControl
              fullWidth={true}
              className="forgotPwdLink"
              onClick={toggleChangePassword}
              style={{
                marginBottom: "15px",
              }}
            >
              Cancel
            </FormControl>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <CssBaseline />
      <div>
        {redirecTo === "" ? "" : <Redirect to={redirecTo} />}
        
          <MainDrawer            
            mainDashboardItemHandler={mainDashboardItem}
            reLoadHandler={reLoad}
            toggleChangePasswordHandler={toggleChangePassword}
            logoutHandler={logout}
            handleDrawerToggle={handleDrawerToggle}
            cartHandler={cartHandler}
            getMenuListHandler={getMenuList}
          />
        

        <Modal
          open={showChangePwd}
          onClose={toggleChangePassword}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {changePwdBody}
        </Modal>
        <Modal
          open={cartPopup}
          onClose={closeCartPopup}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {
            <AddToCart
              cartPopup={cartPopup}
              closeCartPopupFn={closeCartPopupFn}
            />
          }
        </Modal>
        <NotificationContainer />
      </div>
    </div>
  );
}
