import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "./AboutusComponent.css";

export default function AboutBanner() {
  return (
    <div className="about-us-banner">
      <Container>
        <Grid container spacing={2}>
          <Grid item md={9} sm={12} xs={12} className="p-0">
            <h2>
            We own, operate, and manage a hybrid CMN network that enables intelligent edge computing and establishes digital trust within the food processing enterprise ecosystem, ensuring secure transactions.            {/* ABN.Network is a technology company based in Bangalore that creates online DIGITAL TRUST in agri-food business ecosystem to transact securely */}
</h2>
            {/* GBR, an AAMC and tech company in Bangalore, facilitates Agri-entrepreneurs in bringing commercial farming to life</h2> */}
            {/* GBR is a technology  based  company in Bangalore that enables Agri-business to bring COMMERCIAL FARMING for everyone            </h2> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
