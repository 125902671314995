import MainService from "../MainService";
const getAppConfig = () => window.AppConfigData;

export const getCategoryID = (categoryName) => {
  return new Promise((resolve, reject) => {
    MainService.getCategory(getAppConfig().EnterpriseId, categoryName)
      .then((data) => {
        return resolve(data);
      })
      .catch((e) => {
        return reject(e);
      });
  });
};

export const toCamelCase = (str) => {
  return str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
};

export const Capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
