import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { CChart } from "@coreui/react-chartjs";
import MainService from "../../Common/MainService";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import drivericon from "./dashboardimages/driver.png";
import vehicleicon from "./dashboardimages/delivery-truck.gif";
import deviceicon from "./dashboardimages/smart-tv.png";
import transportmanager from "./dashboardimages/business.png";
import locationicon from "./dashboardimages/pin.gif";
import routeicon from "./dashboardimages/route.gif";
import tripicon from "./dashboardimages/worldwide.gif";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#3498db",
    },
    secondary: {
      main: "#2ecc71",
    },
  },
});

const imgContainer = {
  width: "3rem",
  height: "3rem",
};

const LpDashboard = () => {
  const [assetCount, setAssetCount] = useState({});
  const [deviceCount, setDeviceCount] = useState({});
  const [driversInfo, setDriversInfo] = useState("");
  const [tripInfo, setTripInfo] = useState({});
  const [tripPlannerInfo, setTripPlannerInfo] = useState({});
  const [logisticManagerCount, setLogisticManagerCount] = useState({});
  const [transportUserCount, setTransportUserCount] = useState("");
  const [depotOwnerCount, setDepotOwnerCount] = useState("");
  const [depotManagerCount, setDepotManagerCount] = useState("");
  const [depotOperatorCount, setDepotOperatorCount] = useState("");
  const [agentCount, setAgentCount] = useState("");
  // const [depotOwnerCount, setDepotOwnerCount]= useState("");

  // const [mainserviceInfo] = useState(MainService.getLocalData());
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  const history = useHistory();

  const handleMyOrders = () => {
    history.push("./myOrders");
  };

  useEffect(() => {
    getAssetsCountInfo();
    getDeviceCountInfo();
    getDriversInfo();
    getTripPlannerInfo();
    getMonthlyTrips();
    getLogisticManagerCount();
    getTransportUserInfo();
    getDepotOwnerCount();
    getDepotManagerCount();
    getDepotOperatorCount();
    getAgentCount();
  }, []);

  const getAgentCount = async () => {
    const {
      userCount: { totalCount },
    } = await MainService.getAgentCount(username);
    setAgentCount(totalCount);
  };
  const getDepotOperatorCount = async () => {
    const {
      userCount: { totalCount },
    } = await MainService.getDepotOperatorCount(username);
    setDepotOperatorCount(totalCount);
  };

  const getDepotManagerCount = async () => {
    const {
      userCount: { totalCount },
    } = await MainService.getDepotManagerCount(username);
    setDepotManagerCount(totalCount);
  };

  const getDepotOwnerCount = async () => {
    const {
      userCount: { totalCount },
    } = await MainService.getDepotOwnerCount(username);
    setDepotOwnerCount(totalCount);
  };

  const getTransportUserInfo = async () => {
    const {
      userCount: { totalCount },
    } = await MainService.getTransportUserCount(username);
    setTransportUserCount(totalCount);
    console.log(transportUserCount, "transport user///////");
  };

  const getAssetsCountInfo = async () => {
    const { assetCount } = await MainService.getAssetsCountInfo(username);
    setAssetCount(assetCount);
    console.log(assetCount, ".....assetCount...........");
  };

  const getDeviceCountInfo = async () => {
    const { deviceCount } = await MainService.getDeviceCountInfo(username);
    setDeviceCount(deviceCount);
    console.log(deviceCount, "getdevicescount...........");
  };

  const getDriversInfo = async () => {
    const {
      userCount: { totalCount },
    } = await MainService.getDriversInfo(username);
    setDriversInfo(totalCount);
    console.log(driversInfo, "driver1///////");
  };

  const getTripPlannerInfo = async () => {
    const { locationCount, routeCount, tripCount } =
      await MainService.getTripPlannerInfo(username);
    console.log(locationCount, "locationCount........");
    setTripPlannerInfo({ locationCount, routeCount, tripCount });
  };

  const getMonthlyTrips = async () => {
    const data = await MainService.getMonthlyTrips(username);
    setTripInfo(data);
    console.log(tripInfo, "summaryy////////////////");
  };

  const getLogisticManagerCount = async () => {
    const { userCount } = await MainService.getLogisticManagerCount(username);
    setLogisticManagerCount(userCount);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          flexGrow: 1,
          padding: "24px",
          background: "linear-gradient(180deg, #F4F6F8 0%, #E1E8ED 100%)",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Item>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", marginBottom: 2, color: "#3498db" }}
              >
                Vehicle Status
              </Typography>
              <CChart
                style={{ height: "250px", marginTop: "16px" }}
                type="polarArea"
                data={{
                  labels: ["Active", "Inactive"],
                  datasets: [
                    {
                      data: [assetCount.activeCount, assetCount.inactiveCount],
                      backgroundColor: ["#4BC0C0", "#FFCE56"],
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  cutoutPercentage: 80,
                }}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item sx={{ backgroundColor: "#fff", padding: 3 }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", marginBottom: 2, color: "#3498db" }}
              >
                Fleet Summary
              </Typography>
              <CChart
                style={{ height: "250px" }}
                type="bar"
                data={{
                  datasets: [
                    {
                      label: "Monthly Trips",
                      backgroundColor: theme.palette.primary.main,
                      data: tripInfo,
                    },
                  ],
                }}
                labels="months"
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  cutoutPercentage: 80,
                }}
              />
            </Item>
          </Grid>

          {/* FLEET INFO */}

          <Grid item xs={12}>
            <Item sx={{ backgroundColor: "#fff", padding: 3 }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", marginBottom: 2, color: "#3498db" }}
              >
                Fleet Info
              </Typography>
              <Grid container spacing={3} justifyContent="space-around">
                <Grid item>
                  <Typography variant="subtitle1">Vehicles</Typography>
                  <img
                    src={vehicleicon}
                    alt="Vehicle Icon"
                    style={{ width: "3rem", height: "3rem" }}
                  />

                  <Typography variant="h6" color="secondary">
                    {assetCount.totalCount}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Devices</Typography>

                  <img
                    src={deviceicon}
                    alt="Device Icon"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                  <Typography variant="h6" color="secondary">
                    {deviceCount.totalCount}
                  </Typography>
                </Grid>
              </Grid>
            </Item>
          </Grid>

          {/* USERS INFO */}
          <Grid item xs={12}>
            <Item sx={{ backgroundColor: "#fff", padding: 3 }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", marginBottom: 2, color: "#3498db" }}
              >
                Users Info
              </Typography>

              <Grid container spacing={3} justifyContent="space-around">
                <Grid item>
                  <Typography variant="subtitle1">Drivers</Typography>

                  <img
                    src={drivericon}
                    alt="Driver Icon"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                  <Typography variant="h6" color="secondary">
                    {driversInfo}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Transport Manager</Typography>

                  <img
                    src={transportmanager}
                    alt="Transport Manager"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                  <Typography variant="h6" color="secondary">
                    {logisticManagerCount.totalCount}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="subtitle1">Transport User</Typography>

                  <img
                    src={drivericon}
                    alt="Driver Icon"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                  <Typography variant="h6" color="secondary">
                    {transportUserCount}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="subtitle1">Depot Owner</Typography>

                  <img
                    src={transportmanager}
                    alt="Transport Manager"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                  <Typography variant="h6" color="secondary">
                    {depotOwnerCount}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="subtitle1">Depot Manager</Typography>

                  <img
                    src={transportmanager}
                    alt="Transport Manager"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                  <Typography variant="h6" color="secondary">
                    {depotManagerCount}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="subtitle1">Depot Operator</Typography>

                  <img
                    src={transportmanager}
                    alt="Transport Manager"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                  <Typography variant="h6" color="secondary">
                    {depotOperatorCount}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="subtitle1">Agents</Typography>

                  <img
                    src={transportmanager}
                    alt="Transport Manager"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                  <Typography variant="h6" color="secondary">
                    {agentCount}
                  </Typography>
                </Grid>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", marginBottom: 2, color: "#3498db" }}
              >
                Trip Info
              </Typography>
              <Grid container spacing={3} justifyContent="space-around">
                <Grid item>
                  <Typography variant="subtitle1">Locations</Typography>
                  <img
                    src={locationicon}
                    alt="Location Icon"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                  <Typography variant="h6" color="secondary">
                    {tripPlannerInfo.locationCount}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Routes</Typography>
                  <img
                    src={routeicon}
                    alt="Route Icon"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                  <Typography variant="h6" color="secondary">
                    {tripPlannerInfo.routeCount}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Trips</Typography>
                  <img
                    src={tripicon}
                    alt="Trip Icon"
                    style={{ height: "3rem", width: "3rem" }}
                  />
                  <Typography variant="h6" color="secondary">
                    {tripPlannerInfo.tripCount}
                  </Typography>
                </Grid>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", marginBottom: 2, color: "#3498db" }}
              >
                ORDERS INFO
              </Typography>
              <Grid container spacing={3} justifyContent="center">
                <Grid item>
                  <Button variant="contained" onClick={handleMyOrders}>
                    MY ORDERS
                  </Button>
                  {/* <Typography variant="subtitle1">Trips</Typography>
                  <img
                    src={tripicon}
                    alt="Trip Icon"
                    style={{ height: "3rem", width: "3rem" }}
                  />
                  <Typography variant="h6" color="secondary">
                    {tripPlannerInfo.tripCount}
                  </Typography> */}
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default LpDashboard;
