import { Container } from "@material-ui/core";
import React, { useState } from "react";
import Tabs from "react-simply-tabs";
import InvestorTabBlocks from "../../InvestorTabBlocks/InvestorTabBlocks";
import InvestableAssets from "../InvestableAssets/InvestableAssets";
import InvestableMachinery from "../InvestableMachinery/InvestableMachinery";
import "./InvestorTabs.css";
import MeatAsset from "../InvestableAssets/MeatAsset";
import AromaAssets from "../InvestableAssets/AromaAssets";

export default function InvestorTabs() {
  const [activeTabIndex, setTabIndex] = useState(0);

  return (
    <div className="Iaa-tabs ">
      <h2 className="container-title text-center">
      Investments
      </h2>
      <p className="container-para mx-4 text-center mb-5">
      Investable Asset (IA) is a new "Asset Class" for Agrifood industry. <br></br> Assets of this "Asset Class" are managed by MSME and Owned by Agri-Investors.
      </p>

      <Tabs
        id="test"
        activeTabIndex={activeTabIndex}
        activeControlProps={{
          className: "active",
        }}
        onRequestChange={setTabIndex}
        controls={[
          <button type="button" title="" className="Iaa-tab-buttons">
            Honey
          </button>,
          <button type="button" title="" className="Iaa-tab-buttons">
            Meat
          </button>,
           <button type="button" title="" className="Iaa-tab-buttons">
           Diary
         </button>,
           <button type="button" title="" className="Iaa-tab-buttons">
           Essential Oils
         </button>,
        ]}
      >
        <div className="">
         
            <InvestorTabBlocks
              IAA="true"
                // title="Investable Agri Asset (IAA) for MSMEs"
              //   description=" IAA is new category managed by MSMEs to raise investment from Agri-Investors"
              // href="/auth/3/signup"
              // buttonText="Join Now"
            />
            <InvestableAssets />
        </div>

        <div className="">
          {" "}
          <div id="ExploreIAA" className=" main-wrapper2--mod">
            <InvestorTabBlocks
              IAM="true"
                // title="Investable Machinery Asset (IAA) for MSMEs"
              //   description=" IAA is new category managed by MSMEs to raise investment from Agri-Investors"
              // href="/auth/3/signup"
              // buttonText="Join Now"
            />
            <MeatAsset />
          </div>
        </div>
        <div className="">
         
         <InvestorTabBlocks
           DB="true"
             // title="Investable Agri Asset (IAA) for MSMEs"
           //   description=" IAA is new category managed by MSMEs to raise investment from Agri-Investors"
           // href="/auth/3/signup"
           // buttonText="Join Now"
         />
            <InvestableMachinery />
     </div>

     <div className="">
         
         <InvestorTabBlocks
           EssentailOils="true"
             // title="Investable Agri Asset (IAA) for MSMEs"
           //   description=" IAA is new category managed by MSMEs to raise investment from Agri-Investors"
           // href="/auth/3/signup"
           // buttonText="Join Now"
         />
            <AromaAssets />
     </div>
      </Tabs>
    </div>
  );
}
