import React, { Component } from 'react';
import { Line } from "react-chartjs-2";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row, Container,
} from 'reactstrap';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../style.css';
import './Layout.css';
import AuthAxiosInstance from '../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance, EMSServcieAxiosInstance } from '../../../authAxiosInstance'
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { NotificationManager } from "react-notifications";


class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showspinner: false,
      data: {},
      deviceList: [],
      selectDevId: 0,
      listProperty: [],
      selectedProperty: "",
      date: {},
      validDate: {},
      options: {
        title: {
          display: true,
          fontSize: 20

        },
        legend: {
          display: true,
          position: 'right'
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'value',
              fontColor: 'black'
            },
            ticks: {
              fontColor: 'black' // Set the tick font color to black
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Date',
              fontColor: 'black'
            },
            ticks: {
              fontColor: 'black' // Set the tick font color to black
            }
          }],
        }
      }
    };

    // toast.configure({
    //   autoClose: 8000,
    //   draggable: false,
    //   //etc you get the idea
    // });
  }

  componentDidMount() {
    this.loadDeviceList();
  }




  loadDeviceList() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    const userId = localStorage.getItem("user-id");
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/list`).then((resp) => {
      this.setState({ deviceList: resp.data ? resp.data : [] });
    }).catch((err) => {

    })
  }

  selectDate(type, evt) {
    let date = { ...this.state.date };
    let validDate = { ...this.state.validDate };
    date[type] = evt.target.value + (type === "end" ? "T23:59:59" : "T00:00:00");
    validDate[type] = evt.target.value;
    this.setState({ date, validDate });
  }

  selectDevice(evt) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    try {
      const filterDevice = this.state.deviceList.filter((d) => (d.deviceId == evt.target.value));

      this.setState({ selectDevId: evt.target.value });
      //AuthAxiosInstance.get(`/ems/livedata/${networkId.network}/${evt.target.value}/properties?deviceType=${filterDevice[0].deviceType}`).then((res) => {
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${filterDevice[0].deviceId}/metadata?deviceType=${filterDevice[0].deviceType}&model=${filterDevice[0].model}`)
        .then((res) => {
          console.log("Response Data", res.data);
          if (res.data && res.data !== 'failed' && res.data !== 'error') {
            if (filterDevice[0].deviceType == "AHUG") {
              this.setState({ listProperty: res.data.AHUGproperty ? res.data.AHUGproperty : [] }, () => {
                console.log(this.state.listProperty);
              });
            }
            else if (filterDevice[0].deviceType == "EMMW") {
              this.setState({ listProperty: res.data.EMMW ? res.data.EMMW : [] }, () => {
                console.log(this.state.listProperty);
              });
            }
            else if (filterDevice[0].deviceType == "CCN") {
              console.log("Here")
              this.setState({ listProperty: res.data.CHILLER[0].CHILLERproperty ? res.data.CHILLER[0].CHILLERproperty : [] }, () => {
                console.log(this.state.listProperty);
              });
            }
            else if (filterDevice[0].deviceType == "LG") {
              console.log("Here")
              this.setState({ listProperty: res.data.THS[0].THSproperty ? res.data.THS[0].THSproperty : [] }, () => {
                console.log(this.state.listProperty);
              });
            }
            else if (filterDevice[0].deviceType == "TCN") {
              this.setState({ listProperty: res.data.Tstat[0].Tstatproperty ? res.data.Tstat[0].Tstatproperty : [] }, () => {
                console.log(this.state.listProperty);
              });
            }
            else if (filterDevice[0].deviceType == "VCN") {
              this.setState({ listProperty: res.data.VFD[0].VFDproperty ? res.data.VFD[0].VFDproperty : [] }, () => {
                console.log(this.state.listProperty);
              });
            }
            else if (filterDevice[0].deviceType == "EMG") {
              this.setState({ listProperty: res.data.EMG[0].EMproperty ? res.data.EMG[0].EMproperty : [] }, () => {
                console.log(this.state.listProperty);
              });
            }
            else {
              this.setState({ listProperty: res.data.properties ? res.data.properties : [] }, () => {
                console.log(this.state.listProperty);
              });
            }
          }
          else {
            this.setState({ listProperty: [] });
          }

        }).catch((err) => {
          this.setState({ listProperty: [] });
        });
    }
    catch (cerror) {
      this.setState({ listProperty: [] });
      // toast.error("Something went wrong.", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
    }
  }

  selectProperty(evt) {
    this.setState({ selectedProperty: evt.target.value });

  }


  getHistoryData() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    try {
      if (this.state.selectDevId && this.state.selectedProperty && this.state.date.start && this.state.date.end) {
        this.setState({ showspinner: true });
        const filterproperty = this.state.listProperty.filter((d) => (d.property == this.state.selectedProperty));
        const filterDevice = this.state.deviceList.filter((d) => (d.deviceId == this.state.selectDevId));
        EMSServcieAxiosInstance.get(`/ems/history/${username}/${this.state.selectDevId}?deviceType=${filterDevice[0].deviceType}&property=${this.state.selectedProperty}&fromDate=${this.state.date.start}&toDate=${this.state.date.end}`)
          .then((res) => {
            //const data = {...this.state.data};
            this.setState({ showspinner: false });
            if (res.data.length > 0) {
              const dataset = [];
              const labels = [];
              res.data.map((d) => {
                let date = new Date(d.updatedat);
                let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                labels.push(decideDate);
                // if (labels.indexOf(decideDate) == -1) {
                //   labels.push(date.getDate()+' - '+date.getMonth()+1+'-'+date.getFullYear());
                // }
                if (filterDevice[0].deviceType === "AHUG") {
                  const index = d.AHUGproperty.findIndex((element, index) => {
                    if (element.property === this.state.selectedProperty) {
                      return true
                    }
                  });
                  dataset.push(parseInt(d.AHUGproperty[index].value));
                }
                else if (filterDevice[0].deviceType === "EMMW") {
                  const index = d.EMMW.findIndex((element, index) => {
                    if (element.property === this.state.selectedProperty) {
                      return true
                    }
                  });
                  dataset.push(parseInt(d.EMMW[index].value));
                }
                else if (filterDevice[0].deviceType === "CCN") {
                  const index = d.CHILLER[0].CHILLERproperty.findIndex((element, index) => {
                    if (element.property === this.state.selectedProperty) {
                      return true
                    }
                  });
                  dataset.push(parseInt(d.CHILLER[0].CHILLERproperty[index].value));
                }
                else if (filterDevice[0].deviceType === "LG") {
                  const index = d.THS[0].THSproperty.findIndex((element, index) => {
                    if (element.property === this.state.selectedProperty) {
                      return true
                    }
                  });
                  dataset.push(parseInt(d.THS[0].THSproperty[index].value));
                }
                else if (filterDevice[0].deviceType === "TCN") {
                  const index = d.Tstat[0].Tstatproperty.findIndex((element, index) => {
                    if (element.property === this.state.selectedProperty) {
                      return true
                    }
                  });
                  dataset.push(parseInt(d.Tstat[0].Tstatproperty[index].value));
                }
                else if (filterDevice[0].deviceType === "VCN") {
                  const index = d.VFD[0].VFDproperty.findIndex((element, index) => {
                    if (element.property === this.state.selectedProperty) {
                      return true
                    }
                  });
                  dataset.push(parseInt(d.VFD[0].VFDproperty[index].value));
                }
                else if (filterDevice[0].deviceType === "EMG") {
                  const index = d.EMG[0].EMproperty.findIndex((element, index) => {
                    if (element.property === this.state.selectedProperty) {
                      return true
                    }
                  });
                  dataset.push(parseInt(d.EMG[0].EMproperty[index].value));
                }
                else {
                  const index = d.properties.findIndex((element, index) => {
                    if (element.property === this.state.selectedProperty) {
                      return true
                    }
                  });
                  dataset.push(parseInt(d.properties[index].value));
                }
                //dataset.push(Math.floor(Math.random() * 30) + 1)

              })

              const data = {
                labels: labels,
                datasets: [
                  {
                    label: this.state.selectedProperty,
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: 'rgba(75,192,192,1)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderWidth: 2,
                    pointRadius: 0.2,
                    data: dataset
                  }
                ]
              }

              let options = { ...this.state.options }
              options.scales.yAxes[0].scaleLabel.labelString = filterproperty[0].unit ? filterproperty[0].unit : '';
              this.setState({ options });
              this.setState({ data });
            }
            else {
              const data = {
                labels: [],
                datasets: [

                ]
              }

              this.setState({ data });
              // toast.error("Data not found for selected values. Please select another value.", {
              //   position: toast.POSITION.TOP_RIGHT
              // });
              return NotificationManager.error("Data not found for selected values. Please select another value.");
            }
          }).catch((err) => {
            this.setState({ showspinner: false });
            // toast.error("Some error occurred", {
            //   position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");
          })
      }
      else {
        // toast.error("Please fill all fields", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Please fill all fields");
      }
    }
    catch (cerror) {
      // toast.error("Something went wrong.", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Some error occured");
    }
  }

  formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  setDayDate(DateNumber) {
    let date = { ...this.state.date };
    let validDate = { ...this.state.validDate };
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - DateNumber);
    validDate['start'] = this.formatDate(yesterday);
    validDate['end'] = this.formatDate(today)
    date['end'] = this.formatDate(today) + "T23:59:59";
    date['start'] = this.formatDate(yesterday) + "T00:00:00";

    this.setState({ validDate, date }, () => {
      this.getHistoryData();
    });


  }

  setOneMonthDate() {
    let date = { ...this.state.date };
    let validDate = { ...this.state.validDate };
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 30);
    validDate['start'] = this.formatDate(yesterday);
    validDate['end'] = this.formatDate(today)
    date['end'] = this.formatDate(today) + "T23:59:59";
    date['start'] = this.formatDate(yesterday) + "T00:00:00";

    this.setState({ validDate, date }, () => {
      this.getHistoryData();
    });


  }



  render() {
    return (
      <div className="animated fadeIn" >
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <i className="fa fa-list-alt"></i>
                <strong className="LabelHeader">Device History</strong>
                <div className="card-header-actions">
                  {/* <Button className="float-right badge badge-warning badge-pill" >Edit &nbsp;<i className="fa fa-edit float-right"></i></Button> */}
                </div>
              </CardHeader>
              <CardBody>
                <div >
                  <Grid container spacing={2}>

                    <Grid item xs={12} md={3}>
                      <InputLabel >Select Device</InputLabel>
                      <Select
                        value={this.state.selectDevId}
                        onChange={this.selectDevice.bind(this)}
                        fullWidth

                      >
                        {this.state.deviceList.map((device, index) => (
                          <MenuItem key={index} value={device.deviceId}>
                            {device.deviceName}
                          </MenuItem>
                        ))}
                      </Select>

                    </Grid>

                    <Grid item xs={12} md={3}>
                      <InputLabel >Select Property</InputLabel>
                      <Select
                        value={this.state.selectedProperty}
                        onChange={this.selectProperty.bind(this)}
                        fullWidth

                      >

                        {this.state.listProperty.map((property, index) => (
                          <MenuItem key={index} value={property.property}>
                            {property.property}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={2.5}>
                      <InputLabel>From Date</InputLabel>
                      <OutlinedInput
                        type="date"
                        value={this.state.validDate.start}
                        name="fromdate"
                        onChange={this.selectDate.bind(this, 'start')}
                        style={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2.5}>
                      <InputLabel>To Date</InputLabel>
                      <OutlinedInput
                        type="date"
                        value={this.state.validDate.end}
                        name="todate"
                        onChange={this.selectDate.bind(this, 'end')}
                        style={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={1} >
                      <Button
                        style={{ marginTop: '2rem' }}
                        className="custom-button"
                        onClick={this.getHistoryData.bind(this)}>
                        Get History
                      </Button>
                    </Grid>
                  </Grid>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Col xs="6" md="2" style={{ marginTop: '4vh' }}>
                      <Button
                        className="custom-button"
                        outline
                        disabled={(this.state.selectDevId != 0 && this.state.selectedProperty != "") ? false : true}
                        onClick={this.setDayDate.bind(this, 1)}
                      >Last One Day Data
                      </Button>
                    </Col>
                    <Col xs="6" md="2" style={{ marginTop: '4vh' }}>
                      <Button
                        className="custom-button"
                        outline
                        disabled={(this.state.selectDevId != 0 && this.state.selectedProperty != "") ? false : true}
                        onClick={this.setDayDate.bind(this, 7)}>
                        Last One Week Data
                      </Button>
                    </Col>
                    <Col xs="6" md="2" style={{ marginTop: '4vh' }}>
                      <Button
                        className="custom-button"
                        outline
                        disabled={(this.state.selectDevId != 0 && this.state.selectedProperty != "") ? false : true}
                        onClick={this.setDayDate.bind(this, 15)}>
                        Last 15 Day Data
                      </Button>
                    </Col>
                    <Col xs="6" md="2" style={{ marginTop: '4vh' }}>
                      <Button
                        className="custom-button"
                        outline
                        disabled={(this.state.selectDevId != 0 && this.state.selectedProperty != "") ? false : true}
                        onClick={this.setDayDate.bind(this, 30)}>
                        Last One Month Data
                      </Button>
                    </Col>
                  </div>


                  <Row>
                    <Col xs="12" sm="12" lg="12" md="12">

                      <div style={{ height: 1000 }}>


                        {!this.state.showspinner &&

                          <Line
                            data={this.state.data}
                            options={this.state.options}
                          />
                        }

                        {this.state.showspinner &&
                          <div className="sk-cube-grid">
                            <div className="sk-cube sk-cube1"></div>
                            <div className="sk-cube sk-cube2"></div>
                            <div className="sk-cube sk-cube3"></div>
                            <div className="sk-cube sk-cube4"></div>
                            <div className="sk-cube sk-cube5"></div>
                            <div className="sk-cube sk-cube6"></div>
                            <div className="sk-cube sk-cube7"></div>
                            <div className="sk-cube sk-cube8"></div>
                            <div className="sk-cube sk-cube9"></div>
                          </div>
                        }
                      </div>







                    </Col>
                  </Row>
                </div>

              </CardBody>
            </Card>

          </Col>
        </Row>


      </div>
    );
  }
}

export default Overview;
