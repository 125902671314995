import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import { useHistory } from "react-router-dom";
import { Menu } from "@material-ui/core";
import SellerAccountManagement from "../../documentComponents/Invoicecomponents/selleraccountmanagement/SellerAccountManagement";
import BookingRequestForm from "../MyOrders/BookingRequestForm";
import AddBooking from "../../Broker/BookingManagement/AddBooking";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

export default function BuyRequestsComponent(props) {
  let history = useHistory();
  const theme = useTheme();
  const userType = localStorage.getItem("userType");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [searched, setSearched] = useState("");

  const [buyerRequestsList, setBuyerRequestsList] = useState([]);
  const [filteredBuyerRequestsList, setFilteredBuyerRequestsList] = useState(
    []
  );
  const [selectedId, setSelectedId] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [UpdateRequestDialog, setUpdateRequestDialog] = useState(false);
  const [newStatusValue, setNewStatusValue] = useState("");
  const [existingStatus, setSelectedExistingStatus] = useState("");
  const [showSellerInvoice, setShowSellerInvoice] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [showOrders, setShowOrders] = useState(true);
  const [showTracking, setShowTracking] = useState(false);
  const [showBookingRequestForm, setShowBookingRequestForm] = useState(false);
  const [RequestTitle, setRequestTitle] = useState({});
  const [showCreateBooking,setShowCreateBooking] = useState(false);

  useEffect(() => {
    getAllAssetRequests();
    setRequestTitle({
      AssetDeveloper: "Orders",
      LogisticsProvider: "Booking Requests",
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const getAllAssetRequests = () => {
    MainService.getAllAssetRequests(username)
      .then((res) => {
        // Filter orders based on orderId and orderStatus
        const filteredOrders = res.filter(
          (order) => order.orderid !== "" && order.status !== ""
        );

        // Update state with filtered orders
        setBuyerRequestsList(filteredOrders);
        setFilteredBuyerRequestsList(filteredOrders);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = filteredBuyerRequestsList.filter((row) => {
      return row.status.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setBuyerRequestsList(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  const UpdateRequestDialogClick = (id,existingStatus) => {
    setSelectedId(id);
    setSelectedExistingStatus(existingStatus);
    setUpdateRequestDialog(!UpdateRequestDialog);
  };
  const updateRequestStatusSubmit = () => {
    let updateStatusData = {
      orderStatus: newStatusValue,
    };
    const updateStatusDataString = JSON.stringify(updateStatusData);
    MainService.updateOrderStatus(
      selectedId,
      updateStatusDataString,
      parentId,
      username
    )
      .then((res) => {
        getAllAssetRequests();
        return NotificationManager.success("Status Updated Successfully");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setUpdateRequestDialog(!UpdateRequestDialog);
  };

  /* new map1 */
  const [selected, setSelected] = useState({});
  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };
  const onSelect = (item) => {
    setSelected(item);
  };
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: 13.093426,
    lng: 77.655773,
  };

  // order menu function
  const [orderMenuAnchorEl, setOrderMenuAnchorEl] = useState(null);
  const orderMenuOpen = Boolean(orderMenuAnchorEl);

  const orderMenuOpenHandler = (event, request) => {
    setOrderMenuAnchorEl(event.currentTarget);
    console.log("prrops", event.currentTarget, request);
  };

  const orderMenuCloseHandler = () => {
    setOrderMenuAnchorEl(null);
  };

  // invoice handler
  const createInvoiceHandler = (props) => {
    console.log("props", props);
    orderMenuCloseHandler();
  };

  const backToOrders = () => {
    setShowTracking(false);
    setShowOrders(true);
    setShowSellerInvoice(false);
    setShowBookingRequestForm(false);
    setShowCreateBooking(false);
  };
  const sendBookingRequestHandler = (orderId) => {
    setSelectedOrderId(orderId);
    setShowTracking(false);
    setShowOrders(false);
    setShowSellerInvoice(false);
    setShowBookingRequestForm(true);
    setShowCreateBooking(false);
  };
  const createInvoiceHandlerClick = (orderId) => {
    setSelectedOrderId(orderId);
    setShowSellerInvoice(true);
    setShowTracking(false);
    setShowOrders(false);
    setShowBookingRequestForm(false);
    setShowCreateBooking(false);
  };
  const createBookingHandlerClick = (orderId) =>{
    setSelectedOrderId(orderId);
    setShowSellerInvoice(false);
    setShowTracking(false);
    setShowOrders(false);
    setShowBookingRequestForm(false);
    setShowCreateBooking(true);
  }
  return (
    <>
      <div className="container" id="mainContentList">
        <Box sx={{ width: "100%", marginBottom: 30 }}>
          {showOrders && (
            <Paper sx={{ width: "100%" }}>
              <SearchBar
                value={searched}
                placeholder="Serach by order status"
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell
                        className="headerFontSize"
                        align="center"
                        colSpan={8}
                      >
                        All Orders
                      </TableCell>
                    </TableRow>
                    <TableRow align="center">
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Order Id
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Order Status
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        TimeStamp
                      </TableCell>

                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={5}
                      >
                        Option
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {buyerRequestsList.length > 0 ? (
                      buyerRequestsList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((requests, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {requests.orderid}
                              </TableCell>
                              <TableCell align="center">
                                {requests.status}
                              </TableCell>
                              <TableCell align="center">
                                {requests.timestamp}
                              </TableCell>

                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    UpdateRequestDialogClick(
                                      requests.orderid,
                                      requests.status
                                    );
                                  }}
                                >
                                  Update Request
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    createInvoiceHandlerClick(requests.orderid);
                                  }}
                                >
                                  Create Invoice
                                </Button>
                              </TableCell>
                              {userType === "AssetDeveloper" && (
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      sendBookingRequestHandler(
                                        requests.orderid
                                      );
                                    }}
                                  >
                                    Send Booking Request
                                  </Button>
                                </TableCell>
                              )}

                              <TableCell align="center">
                                <Button size="small" variant="contained"
                                onClick={() => {
                                  createBookingHandlerClick(
                                    requests.orderid
                                  );
                                }}>
                                  Create Booking
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button size="small" variant="contained">
                                  Track
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          Asset Requests Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={buyerRequestsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}

          {showSellerInvoice && (
            <div>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  backToOrders();
                }}
              >
                Back
              </Button>
              <SellerAccountManagement
                proxyId={`${selectedOrderId}`}
                componentName="invoice"
              />
            </div>
          )}
          {showBookingRequestForm && (
            <div>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  backToOrders();
                }}
              >
                Back
              </Button>
              <BookingRequestForm orderId={selectedOrderId} />
            </div>
          )}
          {showCreateBooking && (
            <div>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  backToOrders();
                }}
              >
                Back
              </Button>
              <AddBooking orderId={selectedOrderId} />
            </div>
          )}
          {/* Update asset request status */}
          <Dialog
            fullScreen={fullScreen}
            open={UpdateRequestDialog}
            onClose={handleClose}
            aria-labelledby="updateOrderStatus"
          >
            <DialogTitle id="updateOrderStatus">
              Update Order Status
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="id">Order ID :</InputLabel>
                <Input
                  disabled
                  id="id"
                  name="id"
                  type="text"
                  value={selectedId}
                />
              </FormControl>
              
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="existingStatus">
                  Existing Order Status :
                </InputLabel>
                <Input
                  disabled
                  id="existingStatus"
                  name="existingStatus"
                  type="text"
                  value={existingStatus}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="assetId">
                  Update Asset Request Status
                </InputLabel>
                <Select
                  id="assetId"
                  name="assetId"
                  value={newStatusValue}
                  onChange={(e) => {
                    setNewStatusValue(e.target.value);
                  }}
                >
                  <MenuItem value="created">created</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                  <MenuItem value="Loaded">Loaded</MenuItem>
                  <MenuItem value="Unloaded">Unloaded</MenuItem>
                  <MenuItem value="InProgress">InProgress</MenuItem>
                  <MenuItem value="Delivered">Delivered</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateRequestStatusSubmit();
                }}
              >
                Update
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setUpdateRequestDialog(!UpdateRequestDialog);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        
      </div>
    </>
  );
}
