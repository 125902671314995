import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import { NotificationManager } from "react-notifications";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import UpgradeIcon from "@material-ui/icons/Update";
import MainService from "../../Common/MainService";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  media: {
    width: 250,
    height: 250,
  },
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  fab1: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(15),
    zIndex: 10,
    color: "#fff",
  },
  control: {
    padding: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  button: {
    color: theme.palette.danger.main,
    borderColor: theme.palette.danger.main,
    "&:hover": {
      backgroundColor: theme.palette.danger.shadow,
      borderColor: theme.palette.danger.main,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.danger.shadow,
      borderColor: theme.palette.danger.main,
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
}));

export default function BankPageComponent() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [bankList, setBankList] = useState([]);
  const [bankUserId, setBankUserId] = useState("");
  const [bankStatus, setBankStatus] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankIfscCode, setBankIfscCode] = useState("");
  const [bankBranchName, setBankBranchName] = useState("");
  const [bankAccountHolderName, setBankAccountHolderName] = useState("");
  const [showManageBankDetails, setShowManageBankDetails] = useState(false);
  const [showAddBankDetails, setShowAddBankDetails] = useState(false);
  const { user } = MainService.getLocalData();
  const { username,parentId } = user;
  const [getImageLink, setgetImageLink] = useState("");
  
  useEffect(() => {
    getBankStatus();
    getBankDetails();
    getUserImage();
  }, []);
  /* get bank status */
  const getBankStatus = () => {
    
    MainService.bankStatus(username)
      .then((data) => {
        setBankStatus(data.status);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  /*get user image*/
  const getUserImage = () => {
    
    MainService.getUserImag(parentId,username)
      .then((data) => {
        var size = Object.keys(data).length;
        if (data.locationurl === "null" || size === 0) {
          setgetImageLink("https://www.jquery-az.com/html/images/banana.jpg");
        } else setgetImageLink(data.locationurl);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  /* Get bank details */
  const getBankDetails = () => {
    MainService.getBankDetails(username)
      .then((data) => {
        setIsLoading(false);
        if (data.length > 0) {
          setBankUserId(data[0].userId);
          setBankAccountHolderName(data[0].accountHolderName);
          setBankAccountNumber(data[0].bankAccountNumber);
          setBankIfscCode(data[0].ifscCode);
          setBankBranchName(data[0].branchName);
        }
        setBankList(data);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error(e);
      });
  };

  const toggleUpdateBankDetails = () => {
    setShowManageBankDetails(!showManageBankDetails);
  };

  const toggleAddBankDetails = () => {
    setShowAddBankDetails(!showAddBankDetails);
  };
  /* Update bank details */
  const submitUpdateBankDetails = () => {
    setIsLoading(false);

    let updateBankDetailsBody = {
      userId: bankUserId,
      bankAccountNumber: bankAccountNumber,
      ifscCode: bankIfscCode,
      branchName: bankBranchName,
      accountHolderName: bankAccountHolderName,
    };
    const updateBankDetailsData = JSON.stringify(updateBankDetailsBody);

    MainService.updateBankDetails(updateBankDetailsData)
      .then((data) => {
        console.log("response after updation of bank details:", data);
        getBankDetails();
        setIsLoading(false);
        toggleUpdateBankDetails();
        return NotificationManager.success("bank details updated.");
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error("bank details not updated.");
      });
  };
  /* Add bank details */
  const addBankDetailsClick = (evt) => {
    evt.preventDefault();
    const bankAccountNumber =
      document.getElementById("bankAccountNumber").value;
    const ifscCode = document.getElementById("ifscCode").value;
    const branchName = document.getElementById("branchName").value;
    const accountHolderName =
      document.getElementById("accountHolderName").value;

    if (!bankAccountNumber || bankAccountNumber === "") {
      return NotificationManager.warning("Enter valid Bank Account Number.");
    }
    if (!ifscCode || ifscCode === "") {
      return NotificationManager.warning("Enter valid IFSC Code.");
    }
    if (!branchName || branchName === "") {
      return NotificationManager.warning("Enter valid Branch Name.");
    }
    if (!accountHolderName || accountHolderName === "") {
      return NotificationManager.warning("Enter valid Account Holder Name.");
    }

    setIsLoading(true);

    const { user } = MainService.getLocalData();
    const { username } = user;

    const payload = {
      userId: username,
      bankAccountNumber: bankAccountNumber,
      ifscCode: ifscCode,
      branchName: branchName,
      accountHolderName: accountHolderName,
    };

    const addBankDetailsBody = JSON.stringify(payload);

    console.log("Add bank details body:", addBankDetailsBody);
    MainService.addBankDetails(addBankDetailsBody)
      .then((data) => {
        console.log("Add new bank response data:", data);
        getBankDetails();
        setIsLoading(false);
        toggleAddBankDetails();
        if (data.length > 0) {
          return NotificationManager.success("bank details already added.");
        } else {
          return NotificationManager.success("bank details  added.");
        }
      })
      .catch((e) => {
        console.error(e);
        getBankDetails();
        setIsLoading(false);
        toggleAddBankDetails();
        // return NotificationManager.error(e);
        // return NotificationManager.error("bank details already added.");
        /* if (e.response && e.response.data && e.response.data.notification)
        { */
        // return reject(e.response.data.notification);
        return NotificationManager.error(e);
        // }
      });
  };
  /* Update Bank Details */
  const updateBankDetails = (
    <Grid container spacing={0}>
      <Grid
        item
        xs={11}
        sm={5}
        md={4}
        lg={3}
        className="modalGridBody"
        style={{ margin: "0 auto 0 !important" }}
      >
        <span
          className="closeButton"
          
          onClick={toggleUpdateBankDetails}
        >
          X
        </span>
        <br />
        <br />
        <div className="forgotPwdContainer">
          <form id="updateBankDetailsForm" onSubmit={submitUpdateBankDetails}>
            <h2>Update Bank Details</h2>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="userId">User ID *</InputLabel>
              <Input
                name="userId"
                type="text"
                value={bankUserId}
                onChange={(e) => setBankUserId(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="bankAccountNumber">
                Bank Account Number *
              </InputLabel>
              <Input
                name="bankAccountNumber"
                type="text"
                value={bankAccountNumber}
                onChange={(e) => setBankAccountNumber(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="ifscCode">IFSC Code *</InputLabel>
              <Input
                name="ifscCode"
                type="text"
                value={bankIfscCode}
                onChange={(e) => setBankIfscCode(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="branchName">Branch Name *</InputLabel>
              <Input
                name="branchName"
                type="text"
                value={bankBranchName}
                onChange={(e) => setBankBranchName(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="accountHolderName">
                Account Holder Name *
              </InputLabel>
              <Input
                name="accountHolderName"
                type="text"
                value={bankAccountHolderName}
                onChange={(e) => setBankAccountHolderName(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true}>
              {isLoading === false && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="submit"
                >
                  Save
                </Button>
              )}{" "}
              {isLoading === true && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className="loginLoader" />
                </Button>
              )}
            </FormControl>
            <FormControl
              fullWidth={true}
              className="forgotPwdLink"
              onClick={toggleUpdateBankDetails}
              style={{
                marginBottom: "15px",
              }}
            >
              Cancel
            </FormControl>
          </form>
        </div>
      </Grid>
    </Grid>
  );
  /* Add Bank Details */
  const AddBankDetails = (
    <Grid container spacing={0}>
      <Grid
        item
        xs={11}
        sm={5}
        md={4}
        lg={3}
        className="modalGridBody"
        style={{ margin: "0 auto 0 !important" }}
      >
        <span
          className="closeButton"
          style={{
            width: "100%",
            textAlign: "right",
            color: "red",
            fontSize: "23px",
            float: "right",
            padding: "5px 15px 0px 0px",
          }}
          onClick={toggleAddBankDetails}
        >
          X
        </span>
        <br />
        <br />
        <div className="forgotPwdContainer">
          <form id="addBankDetailsForm" onSubmit={addBankDetailsClick}>
            <h2>Add Bank Details</h2>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="userId1">User ID *</InputLabel>
              <Input
                disabled
                name="userId1"
                type="text"
                value={username}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="bankAccountNumber">
                Bank Account Number *
              </InputLabel>
              <Input
                id="bankAccountNumber"
                type="text"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="ifscCode">IFSC Code *</InputLabel>
              <Input
                id="ifscCode"
                type="text"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="branchName">Branch Name *</InputLabel>
              <Input
                id="branchName"
                type="text"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="accountHolderName">
                Account Holder Name *
              </InputLabel>
              <Input
                id="accountHolderName"
                type="text"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true}>
              {isLoading === false && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="submit"
                >
                  Save
                </Button>
              )}{" "}
              {isLoading === true && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className="loginLoader" />
                </Button>
              )}
            </FormControl>
            <FormControl
              fullWidth={true}
              className="forgotPwdLink"
              onClick={toggleAddBankDetails}
              style={{
                marginBottom: "15px",
              }}
            >
              Cancel
            </FormControl>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={2}>
        <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Add Bank Details"
          style={{ color: "#fff" }}
          onClick={toggleAddBankDetails}
          className={classes.fab}
        >
          <AddIcon />
        </Fab>

        <Fab
          color="primary"
          size={"large"}
          aria-label="update"
          title="Update Bank Details"
          style={{ color: "#fff" }}
          onClick={toggleUpdateBankDetails}
          className={classes.fab1}
        >
          <UpgradeIcon />
        </Fab>
        <Grid item md={12} xs={12}>
          <h3 style={{ float: "left", marginTop: "7px", marginBottom: "20px" }}>
            Bank Details
          </h3>
        </Grid>
        <br />
        {isLoading ? (
          <div className="cardStyleForDashboardItems">
            <CircularProgress />
          </div>
        ) : (
          <div className="cardStyleForDashboardItems">
            {bankList.length > 0 ? (
              bankList.map((data, index) => {
                return (
                  <Grid item md={4} xs={12} key={index}>
                    <Card className={classes.root} variant="outlined">
                      <CardActionArea>
                        <CardMedia
                          className={classes.media}
                          image={getImageLink}
                          title="bank"
                        />
                        <CardContent>
                          <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                          >
                            <b>{data.nomineeName}</b>
                          </Typography>
                          <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                          >
                            <span>
                              <b>User Name:</b> {data.userId}
                            </span>
                            <br />
                            <br />
                            <span>
                              <b>Bank Account Number:</b>{" "}
                              {data.bankAccountNumber}
                            </span>
                            <br />
                            <br />
                            <span>
                              <b>Ifsc Code:</b> {data.ifscCode}
                            </span>
                            <br />
                            <br />
                            <span>
                              <b>Branch Name:</b> {data.branchName}
                            </span>
                            <br />
                            <br />
                            <span>
                              <b>Account Holder Name:</b>{" "}
                              {data.accountHolderName}
                            </span>
                            <br />
                            <br />
                            <span>
                              <b>Bank Status:</b> {bankStatus}
                            </span>
                            <br />
                            <br />
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <div>
                <h4>Not Found</h4>
              </div>
            )}
          </div>
        )}
      </Grid>
      <Modal
        open={showManageBankDetails}
        onClose={toggleUpdateBankDetails}
        aria-labelledby="updateBAnkDetails"
        aria-describedby="updateBAnkDetails-description"
      >
        {updateBankDetails}
      </Modal>
      <Modal
        open={showAddBankDetails}
        onClose={toggleAddBankDetails}
        aria-labelledby="addBAnkDetails"
        aria-describedby="addBAnkDetails-description"
      >
        {AddBankDetails}
      </Modal>
    </>
  );
}
