import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import { Chip, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import XMLParser from 'react-xml-parser';
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import UpdateIcon from '@mui/icons-material/Update';

const TopicManagement = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openCreateDailog, setOpenCreateDailog] = React.useState(false);
    const [opendedicatedDailog, setopendedicatedDailog] = React.useState(false);
    const [deleteDailogOpen, setDeleteDailogOpen] = React.useState(false);
    const [updateDailogOpen, setUpdateDailogOpen] = React.useState(false);
    const [verticalDetailsOpen, setVerticalDetailsOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [deleteTopic, setDeleteTopic] = React.useState({});
    const [updateServer, setUpdateServer] = React.useState({});
    const [verticalDetails, setVerticalDetails] = React.useState({});
    const [selectedStatus, setSelectedStatus] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [updateVersionDailogOpen, setUpdateVersionDailogOpen] = React.useState(false);
    const [updateVersion, setUpdateVersion] = React.useState('');
    const [updateVersionServer, setUpdateVersionServer] = React.useState({});
    const [xmlData, setXmlData] = React.useState('');
    const [xmlVerticalData, setXmlVerticalData] = React.useState('');
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    const [FormData, setFormData] = React.useState({
        EntityID: '',
        EntityType: '',
        PartnerId: parentId,
        SolutionId: username,
        OrgId: username,
        TopicType: '',
        Topic: '',
        TopicAction: '',
        TopicStatus: '',
    });
    const [UpdateFormData, setUpdateFormData] = React.useState({
        TopicId: '',
        EntityID: '',
        EntityType: '',
        PartnerId: parentId,
        SolutionId: username,
        OrgId: username,
        TopicType: '',
        Topic: '',
        TopicAction: '',
        TopicStatus: '',
    });
    const [allTopics, setAllTopics] = React.useState('');
    const [updateRuleId, setUpdateRuleId] = React.useState('');
    const [deviceList, setDeviceList] = React.useState('')


    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    React.useEffect(() => {
        fetchData();
        getdevices();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        MainService.getAllTopics(parentId, username)
            .then((res) => {
                console.log(res)
                setAllTopics(res);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);

                return NotificationManager.error(err.error);
            });
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleCreateClick = (event) => {

        setOpenCreateDailog(true);
    };


    const handleCreateClose = () => {
        setOpenCreateDailog(false);
        setFormData({
            EntityID: '',
            EntityType: '',
            PartnerId: parentId,
            SolutionId: username,
            OrgId: username,
            TopicType: '',
            Topic: '',
            TopicAction: '',
            TopicStatus: '',
        });
    };


    const handleCreateFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleUpdateFormChange = (e) => {
        const { name, value } = e.target;
        setUpdateFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCreateSubmit = async (event) => {
        event.preventDefault();

        console.log(FormData);

        MainService.addTopics(JSON.stringify(FormData))
            .then((res) => {
                fetchData();
                handleCreateClose();
                return NotificationManager.success("Topic Created Sucessfully");
            })
            .catch((err) => {
                if (err == 'Error: Request failed with status code 409') {
                    handleCreateClose();
                    return NotificationManager.error("Topic Already Created on this Entity and Topic Type");
                }
                handleCreateClose();
                return NotificationManager.error("Somthing went wrong");
            });

    };

    const getdevices = () => {
        MainService.getDeviceList(username, parentId)
            .then((response) => {
                setDeviceList(response);
            }).catch((err) => {
                return NotificationManager.error("Something went wrong");
            });
    }


    const handleSearchChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchQuery(newSearchQuery);
        setPage(0);
    };

    const searchTable = (row) => {
        const lowercasedQuery = searchQuery.toLowerCase();
        return Object.values(row).some(
            (value) =>
                typeof value === 'string' && value.toLowerCase().includes(lowercasedQuery)
        );
    };

    const filteredData = [...allTopics]?.filter(searchTable);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const visibleData = filteredData?.slice(startIndex, endIndex);

    const handleUpdate = (row) => {
        setUpdateFormData({
            TopicId: row.TopicId,
            EntityID: row.EntityID,
            EntityType: row.EntityType,
            PartnerId: parentId,
            SolutionId: username,
            OrgId: username,
            TopicType: row.TopicType,
            Topic: row.Topic,
            TopicAction: row.TopicAction,
            TopicStatus: row.TopicStatus
        });
        setUpdateDailogOpen(true);
    };

    const handleUpdateVersion = (row) => {
        setUpdateVersionServer(row);
        setUpdateVersionDailogOpen(true);
    };

    const handleDetailsShow = (row) => {
        setVerticalDetails(row);
        setVerticalDetailsOpen(true);
    };

    const handleUpdateSubmit = async (event) => {
        event.preventDefault();

        MainService.updateTopic(UpdateFormData)
            .then((res) => {
                setUpdateDailogOpen(false);
                // Handle the response
                fetchData();
                return NotificationManager.success("Topic Updated");
            })
            .catch((err) => {
                return NotificationManager.error("Something went wrong");
            });
    }

    const handleDeleteSubmit = async (row) => {

        MainService.deleteTopic(parentId, username, deleteTopic.TopicId, deleteTopic.EntityID)
            .then((res) => {
                handleDeleteDailogClose();
                // Handle the response
                fetchData();
                return NotificationManager.success("Topic Deleted");
            })
            .catch((err) => {
                handleDeleteDailogClose();
                return NotificationManager.error("Something went wrong");
            });

    };
    const handleDeleteDailogClose = () => {
        setDeleteDailogOpen(false);
    }

    const handleUpdateDailogClose = () => {
        setUpdateDailogOpen(false);
        setUpdateFormData({
            TopicId: '',
            EntityID: '',
            EntityType: '',
            PartnerId: parentId,
            SolutionId: username,
            OrgId: username,
            TopicType: '',
            Topic: '',
            TopicAction: '',
            TopicStatus: '',
        })
    }

    const handleDetailsClose = () => {
        setVerticalDetailsOpen(false);
    }

    const skeletonRows = Array.from({ length: 5 }, (_, index) => (
        <TableRow key={index}>
            <TableCell colSpan={6}>
                <Box sx={{ width: '100%' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </Box>
            </TableCell>
            <TableCell><Chip
                label={
                    <Skeleton
                        animation="wave"
                        height={10}
                        width={30}
                        style={{ marginBottom: 6 }}
                    />
                }
            /></TableCell>
            <TableCell sx={{ display: 'flex' }}>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={30}
                    width={60}
                    style={{ margin: 5 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={30}
                    width={60}
                    style={{ margin: 5 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={30}
                    width={60}
                    style={{ margin: 5 }}
                />
            </TableCell>
        </TableRow>
    ));

    const handleUpdateVersionDailogClose = () => {
        setUpdateVersionDailogOpen(false);
    }

    const handleUpdateVersionSubmit = () => {

        console.log(updateVersionServer, "version");
        console.log(updateVersion)
        alert("need to implement API")
        // MainService.updateVersionVertical(parentId, username)
        // .then((res) => {
        //   console.log(res)
        //   return NotificationManager.success("Version Updated Sucessfully");
        // })
        // .catch((err) => {
        //   setIsLoading(false);

        //   return NotificationManager.error("Something went wrong");
        // });

    }

    return (
        <div>
            <span><h3>Topic Management</h3></span>
            {console.log(deviceList, "device")}
            <Grid container spacing={2} display="flex" justifyContent="space=between">
                <Grid item xs={6} md={4}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid display="flex" justifyContent="flex-end" item xs={6} md={8}>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleCreateClick}
                        variant='outlined'
                        style={{ color: '#3ecf8e', borderColor: '#3ecf8e', height: '2.5rem' }}
                        size='small'
                    >
                        <AddIcon />Create Topic
                    </Button>
                </Grid>

            </Grid>
            {isLoading ? <div>
                <TableContainer component={Paper}>
                    <Table responsive>
                        <TableHead>
                            <TableRow>
                                <TableCell>Topic ID</TableCell>
                                <TableCell>Entity ID</TableCell>
                                <TableCell>Entity Type</TableCell>
                                <TableCell>Topic Type</TableCell>
                                <TableCell>Topic</TableCell>
                                <TableCell>TopicAction</TableCell>
                                <TableCell>TopicStatus</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {skeletonRows}
                            </>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={allTopics ? allTopics.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div> : <div>
                <TableContainer component={Paper}>
                    <Table responsive>
                        <TableHead>
                            <TableRow>
                                <TableCell>Topic ID</TableCell>
                                <TableCell>Entity ID</TableCell>
                                <TableCell>Entity Type</TableCell>
                                <TableCell>Topic Type</TableCell>
                                <TableCell>Topic</TableCell>
                                <TableCell>TopicAction</TableCell>
                                <TableCell>TopicStatus</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(visibleData?.length === 0 || visibleData === undefined) ? <TableRow>
                                <TableCell colSpan={9}>
                                    <h3 style={{ textAlign: 'center' }}>No Topics Found</h3>
                                </TableCell>
                            </TableRow> :
                                <>
                                    {visibleData?.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.TopicId}</TableCell>
                                            <TableCell>{row.EntityID}</TableCell>
                                            <TableCell>{row.EntityType}</TableCell>
                                            <TableCell>{row.TopicType}</TableCell>
                                            <TableCell>{row.Topic}</TableCell>
                                            <TableCell>{row.TopicAction}</TableCell>
                                            {/* <TableCell>{row.status}</TableCell> */}
                                            <TableCell><Chip label={row.TopicStatus} color={row.TopicStatus === 'DENY' ? "info" : "success"} variant='outlined'></Chip></TableCell>
                                            <TableCell style={{ display: 'flex' }}>
                                                <Tooltip title="View" arrow placement="top">
                                                    <Button variant="outlined" color="primary" onClick={() => handleDetailsShow(row)}>
                                                        <VisibilityIcon />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Update" arrow placement="top">
                                                    <Button variant="outlined" color="secondary" style={{ marginLeft: '0.5rem' }} onClick={() => {
                                                        setUpdateRuleId(row.RuleID);
                                                        handleUpdate(row)
                                                    }}>
                                                        <DriveFileRenameOutlineIcon />
                                                    </Button>
                                                </Tooltip>
                                                {/* <Tooltip title="Update Version" arrow placement="top">
                          <Button variant="outlined" color="info" style={{ marginLeft: '0.5rem' }} onClick={() => handleUpdateVersion(row)}>
                            <UpdateIcon />
                          </Button>
                        </Tooltip> */}
                                                <Tooltip title="Delete" arrow placement="top">
                                                    <Button variant="outlined" color="error" style={{ marginLeft: '0.5rem' }}
                                                        onClick={() => {
                                                            setDeleteDailogOpen(true)
                                                            setDeleteTopic(row)
                                                        }}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={allTopics ? allTopics.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>}
            <Dialog open={openCreateDailog} onClose={handleCreateClose}
                PaperProps={{
                    sx: {
                        width: "50vw",
                        maxHeight: "50vw",
                        height : "80vh" ,
                        maxHeight: '80vh'
                    }
                }}>
                <DialogTitle>Create Topic</DialogTitle>
                <DialogContent >
                    <form onSubmit={handleCreateSubmit}>
                        {/* <TextField
                            label="Entity Id"
                            name="EntityID"
                            value={FormData.EntityID}
                            onChange={handleCreateFormChange}
                            fullWidth
                            margin="normal"
                            required
                        /> */}
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Entity Id</InputLabel>
                            <Select
                                label="Entity Id"
                                name="EntityID"
                                value={FormData.EntityID}
                                onChange={handleCreateFormChange}
                                required
                                fullWidth
                            >
                                {deviceList?.length > 0 && deviceList?.map((device, index) => {
                                    return <MenuItem key={index} value={device.deviceId}>{device.deviceId}</MenuItem>
                                })}

                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Entity Type</InputLabel>
                            <Select
                                label="Entity Type"
                                name="EntityType"
                                value={FormData.EntityType}
                                onChange={handleCreateFormChange}
                                required
                                fullWidth
                            >
                                <MenuItem value="Device">Device</MenuItem>
                                <MenuItem disabled value="Asset">Asset</MenuItem>
                                <MenuItem disabled value="Site">Site</MenuItem>
                                <MenuItem disabled value="Solution">Solution</MenuItem>
                                <MenuItem disabled value="Organization">Organization</MenuItem>
                                <MenuItem disabled value="Enterprise">Enterprise</MenuItem>
                                <MenuItem disabled value="Partner">Partner</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Topic Type</InputLabel>
                            <Select
                                label="Topic Type"
                                name="TopicType"
                                value={FormData.TopicType}
                                onChange={handleCreateFormChange}
                                required
                                fullWidth
                            >
                                <MenuItem value="Data">Data</MenuItem>
                                <MenuItem value="OTA">OTA</MenuItem>
                                <MenuItem value="Command">Command</MenuItem>
                                <MenuItem value="Core">Core</MenuItem>
                                <MenuItem value="Configuration">Configuration</MenuItem>
                                <MenuItem value="App">App</MenuItem>
                                <MenuItem value="ConnectionStatus">Connection Status</MenuItem>
                                <MenuItem value="BatteryStatus">Battery Status</MenuItem>
                                <MenuItem value="Acknowledgement">Acknowledgement</MenuItem>
                                <MenuItem value="ProcessedPayload">Processed Payload</MenuItem>
                                <MenuItem value="Location">Location</MenuItem>
                                <MenuItem value="Altitude">Altitude</MenuItem>
                                <MenuItem value="OTAStatus">OTA Status</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Topic"
                            name="Topic"
                            value={FormData.Topic}
                            onChange={handleCreateFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Topic Action</InputLabel>
                            <Select
                                label="Topic Action"
                                name="TopicAction"
                                value={FormData.TopicAction}
                                onChange={handleCreateFormChange}
                                required
                                fullWidth
                            >
                                <MenuItem value="PUBLISH">PUBLISH</MenuItem>
                                <MenuItem value="SUBSCRIBE">SUBSCRIBE</MenuItem>
                                <MenuItem value="ALL">ALL</MenuItem>

                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Topic Status</InputLabel>
                            <Select
                                label="Topic Status"
                                name="TopicStatus"
                                value={FormData.TopicStatus}
                                onChange={handleCreateFormChange}
                                required
                                fullWidth
                            >
                                <MenuItem value="ALLOW">ALLOW</MenuItem>
                                <MenuItem value="DENY">DENY</MenuItem>
                            </Select>
                        </FormControl>
                        <DialogActions>
                            <Button type='submit' variant="contained" style={{ backgroundColor: '#3ecf8e', borderColor: '#3ecf8e' }} >
                                Submit
                            </Button>
                            <Button onClick={handleCreateClose} variant='outlined' color="error">
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog open={deleteDailogOpen} onClose={handleDeleteDailogClose}>
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDailogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteSubmit} color="error" variant="outlined" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={updateDailogOpen} onClose={handleUpdateDailogClose}>
                <DialogTitle>Update Topic Details</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleUpdateSubmit}>
                        <TextField
                            label="Entity Id"
                            name="EntityID"
                            value={UpdateFormData.EntityID}
                            onChange={handleUpdateFormChange}
                            fullWidth
                            margin="normal"
                            required
                            disabled
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Entity Type</InputLabel>
                            <Select
                                label="Entity Type"
                                name="EntityType"
                                value={UpdateFormData.EntityType}
                                onChange={handleUpdateFormChange}
                                required
                                disabled
                            >
                                <MenuItem value="Device">Device</MenuItem>
                                <MenuItem value="Asset">Asset</MenuItem>
                                <MenuItem value="Site">Site</MenuItem>
                                <MenuItem value="Solution">Solution</MenuItem>
                                <MenuItem value="Organization">Organization</MenuItem>
                                <MenuItem value="Enterprise">Enterprise</MenuItem>
                                <MenuItem value="Partner">Partner</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Topic Type</InputLabel>
                            <Select
                                label="Topic Type"
                                name="TopicType"
                                value={UpdateFormData.TopicType}
                                onChange={handleUpdateFormChange}
                                required
                                disabled
                            >
                                <MenuItem value="Data">Data</MenuItem>
                                <MenuItem value="OTA">OTA</MenuItem>
                                <MenuItem value="Command">Command</MenuItem>

                            </Select>
                        </FormControl>
                        <TextField
                            label="Topic"
                            name="Topic"
                            value={UpdateFormData.Topic}
                            onChange={handleUpdateFormChange}
                            fullWidth
                            margin="normal"
                            required
                            disabled
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Topic Action</InputLabel>
                            <Select
                                label="Topic Action"
                                name="TopicAction"
                                value={UpdateFormData.TopicAction}
                                onChange={handleUpdateFormChange}
                                required
                            >
                                <MenuItem value="PUBLISH">PUBLISH</MenuItem>
                                <MenuItem value="SUBSCRIBE">SUBSCRIBE</MenuItem>
                                <MenuItem value="ALL">ALL</MenuItem>

                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Topic Status</InputLabel>
                            <Select
                                label="Topic Status"
                                name="TopicStatus"
                                value={UpdateFormData.TopicStatus}
                                onChange={handleUpdateFormChange}
                                required
                            >
                                <MenuItem value="ALLOW">ALLOW</MenuItem>
                                <MenuItem value="DENY">DENY</MenuItem>
                            </Select>
                        </FormControl>

                        <DialogActions>
                            <Button type='submit' color="secondary" autoFocus variant="contained" style={{ backgroundColor: '#3ecf8e', borderColor: '#3ecf8e' }} >
                                Update
                            </Button>
                            <Button onClick={handleUpdateDailogClose} color="error" variant='outlined'>
                                Cancel
                            </Button>

                        </DialogActions>
                    </form>
                </DialogContent>

            </Dialog>

            <Dialog open={verticalDetailsOpen} onClose={handleDetailsClose}>
                <DialogTitle>Topic Details</DialogTitle>
                <DialogContent sx={{ padding: "2rem" }}>
                    <Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Topic ID:</strong> {verticalDetails.TopicId}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Entity ID:</strong> {verticalDetails.EntityID}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Entity Type:</strong> {verticalDetails.EntityType}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Topic Type:</strong> {verticalDetails.TopicType}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Topic:</strong> {verticalDetails.Topic}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Topic Action:</strong> {verticalDetails.TopicAction}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Topic Status:</strong> {verticalDetails.TopicStatus}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDetailsClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default TopicManagement
