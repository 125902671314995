// import {
//   Box,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   useMediaQuery,
//   useTheme
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import MainService from "../../../Common/MainService";
// import AssetsRfqBuyForm from "./AssetsRfqBuyForm";
// import AssetsRfqForm from "./AssetsRfqForm";
// import assetsRequestTypesConfig from "./assetsRequestTypesConfig";

// const AssetsRFQuoteRequests = ({
//   openModel,
//   closeModel,
//   verticalsIds,
//   categoryTypes,
//   assertTypes,
//   models,
//   reLoad,
// }) => {
//   const theme = useTheme();
//   const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
//   const { user } = MainService.getLocalData();
//   const { username, parentId } = user;
//   const [getReqVal, setReqVal] = useState("");
//   const [getreqArr, setReqArr] = useState([]);
//   const [selectedReqType, setSelectedReqType] = useState("");
//   const userType = localStorage.getItem("userType");
//   const userRole = localStorage.getItem("UserRole");

//   useEffect(() => {
//     const userConfig = assetsRequestTypesConfig[userRole]?.[userType] || [];
//     setReqVal(userConfig.length > 0 ? userConfig[0].type : "");
//     setSelectedReqType(userConfig.length > 0 ? userConfig[0].type : "");
//     setReqArr(userConfig);
//   }, [userRole, userType]);

//   const handleButtonClick = (type) => {
//     setReqVal(type);
//     setSelectedReqType(type);
//   };

//   return (
//     <>
//       <Dialog
//         fullScreen
//         open={openModel}
//         onClose={closeModel}
//         aria-labelledby="responsive-dialog-title"
//       >
//         <DialogContent>
//           <Box
//             sx={{
//               display: "flex",
//               flexWrap: "wrap",
//               gap: "10px",
//               margin: "5px",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             {getreqArr &&
//               getreqArr?.map((reqType) => (
//                 <Box
//                   key={reqType.type}
//                   sx={{
//                     width: { xs: "100%", sm: "70%", md: "auto" },
//                   }}
//                 >
//                   <Button
//                     variant="outlined"
//                     onClick={() => handleButtonClick(reqType.type)}
//                     sx={{
//                       backgroundColor:
//                         selectedReqType === reqType.type
//                           ? "#3ECF8E"
//                           : "inherit",
//                       color:
//                         selectedReqType === reqType.type
//                           ? "primary"
//                           : "inherit",
//                       width: "100%",
//                       height: "100%",
//                       minWidth: 0,
//                       minHeight: 0,
//                     }}
//                   >
//                     {reqType.type}
//                   </Button>
//                 </Box>
//               ))}
//           </Box>
//           {(() => {
//             if (getReqVal === "asset-buy-rfq-req") {
//               return (
//                 <AssetsRfqBuyForm
//                   reqTypes={getReqVal}
//                   verticalsIds={verticalsIds}
//                   categoryTypes={categoryTypes}
//                   assertTypes={assertTypes}
//                   models={models}
//                   reLoad={reLoad}
//                 />
//               );
//             }
//           })()}
//           {(() => {
//             if (
//               getReqVal === "cf-agri-assets-rfq-req" ||
//               getReqVal === "transport-asset-rfq-req" ||
//               getReqVal === "storage-asset-rfq-req"
//             ) {
//               return (
//                 <AssetsRfqForm
//                   reqTypes={getReqVal}
//                   verticalsIds={verticalsIds}
//                   categoryTypes={categoryTypes}
//                   assertTypes={assertTypes}
//                   models={models}
//                   reLoad={reLoad}
//                 />
//               );
//             }
//           })()}
//         </DialogContent>
//         <DialogActions>
//           <Button color="error" variant="outlined" onClick={closeModel}>
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default AssetsRFQuoteRequests;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState, memo } from "react";
import MainService from "../../../Common/MainService";
import AssetsRfqBuyForm from "./AssetsRfqBuyForm";
import AssetsRfqForm from "./AssetsRfqForm";
import assetsRequestTypesConfig from "./assetsRequestTypesConfig";

const AssetsRFQuoteRequests = ({
  openModel,
  closeModel,
  verticalsIds,
  categoryTypes,
  assertTypes,
  models,
  reLoad,
  reqTypesBasedOnRoleandUser,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getReqVal, setReqVal] = useState("");
  const [getreqArr, setReqArr] = useState([]);
  const [selectedReqType, setSelectedReqType] = useState("");
  const userType = localStorage.getItem("userType");
  const userRole = localStorage.getItem("UserRole");

  useEffect(() => {
    let userConfig;

    if (
      reqTypesBasedOnRoleandUser === undefined ||
      reqTypesBasedOnRoleandUser === null ||
      typeof reqTypesBasedOnRoleandUser !== "object"
    ) {
      userConfig = assetsRequestTypesConfig[userRole]?.[userType] || [];
    } else {
      userConfig = reqTypesBasedOnRoleandUser[userRole]?.[userType] || [];
    }

    setReqVal(userConfig.length > 0 ? userConfig[0]?.type : "");
    setSelectedReqType(userConfig?.length > 0 ? userConfig[0]?.type : "");
    setReqArr(userConfig);
  }, [userRole, userType, reqTypesBasedOnRoleandUser]);

  const handleButtonClick = (type) => {
    setReqVal(type);
    setSelectedReqType(type);
  };

  return (
    <>
      <Dialog
        fullScreen
        open={openModel}
        onClose={closeModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              margin: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getreqArr &&
              getreqArr?.map((reqType) => (
                <Box
                  key={reqType.type}
                  sx={{
                    width: { xs: "100%", sm: "70%", md: "auto" },
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => handleButtonClick(reqType.type)}
                    sx={{
                      backgroundColor:
                        selectedReqType === reqType.type
                          ? "#3ECF8E"
                          : "inherit",
                      color:
                        selectedReqType === reqType.type
                          ? "primary"
                          : "inherit",
                      width: "100%",
                      height: "100%",
                      minWidth: 0,
                      minHeight: 0,
                    }}
                  >
                    {reqType.type}
                  </Button>
                </Box>
              ))}
          </Box>
          {(() => {
            if (getReqVal === "asset-buy-rfq-req") {
              return (
                <AssetsRfqBuyForm
                  reqTypes={getReqVal}
                  verticalsIds={verticalsIds}
                  categoryTypes={categoryTypes}
                  assertTypes={assertTypes}
                  models={models}
                  reLoad={reLoad}
                />
              );
            }
          })()}
          {(() => {
            if (
              getReqVal === "cf-agri-assets-rfq-req" ||
              getReqVal === "transport-asset-rfq-req" ||
              getReqVal === "storage-asset-rfq-req"
            ) {
              return (
                <AssetsRfqForm
                  reqTypes={getReqVal}
                  verticalsIds={verticalsIds}
                  categoryTypes={categoryTypes}
                  assertTypes={assertTypes}
                  models={models}
                  reLoad={reLoad}
                />
              );
            }
          })()}
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" onClick={closeModel}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(AssetsRFQuoteRequests);
