import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { Fragment, memo } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";

function DeleteContent({
  reLoad,
  contentId,
  contentCreator,
  contentCategory,
  onOpen,
  onClose,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  const submitDeleteContentClick = () => {
    MainService.deleteContent(contentCreator, contentCategory, contentId)
      .then((res) => {
        reLoad();
        return NotificationManager.success(contentId + "Content deleted");
      })
      .catch((e) => {
        reLoad();
        return NotificationManager.error(contentId + "Content Not deleted");
      });
    onClose();
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={onOpen}
        onClose={onClose}
        aria-labelledby="deleteContentDialog"
      >
        <DialogTitle id="deleteContentDialog">{"Delete Content"}</DialogTitle>
        <DialogContent>Are you sure want to delete?</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={(e) => {
              submitDeleteContentClick();
            }}
          >
            Delete
          </Button>
          <Button color="error" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default memo(DeleteContent);
