import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import RoleManagementComponent from "../AssetDeveloperRole/RoleManagement/RoleManagement";
import RoleProfileManagementComponent from "../AssetDeveloperRole/RoleProfileManagement/RoleProfileManagementComponent";

import MainService from "../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";

import AAMCSiteManagementComponent from "../AssetDeveloperRole/AAMCSiteManagement/AAMCSiteManagementComponent";
import StorageQAComponent from "../StorageQA/StorageQAComponent";
import StorageManagerComponent from "../StorageManager/StorageManagerComponent";
import StorageOperatorComponent from "../StorageOperator/StorageOperatorComponent";
import AAMCAgentComponent from "../AssetDeveloperRole/AAMCAgent/AAMCAgetComponent";
import StorageOwnerComponent from "../AssetDeveloperRole/StorageOwner/StorageOwnerComponent";
import RegisterUsersRequestsComponent from "../AssetDeveloperRole/RegisterUserRequests/RegisterUserRequestsComponent";

export default function SNComponent(props) {
  const [viewRole, setViewRole] = useState(false);
  const [viewStorageManager, setViewStorageManager] = useState(false);
  const [viewStorageOwner, setViewStorageOwner] = useState(false);
  const [viewStorageOperator, setViewStorageOperator] = useState(false);
  const [viewStorageQA, setViewStorageQA] = useState(false);
  const [viewSiteManagement, setViewSiteManagement] = useState(false);
  const [viewAgents, setViewAgents] = useState(false);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [roleNameValue, setRoleNameValue] = useState("");
  const [viewSuperRole, setViewSuperRole] = useState(false);
  const [viewUserRequests, setViewUserRequests] = useState(true);

  useEffect(() => {
    getRoleName();
  }, []);
  const getRoleName = () => {
    MainService.getRole(parentId, username)
      .then((res) => {
        setRoleNameValue(res.role);
        console.log("role P n/w res:", res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const viewRequestsClick = () => {
    setViewRole(false);
    setViewStorageManager(false);
    setViewStorageOperator(false);
    setViewStorageQA(false);
    setViewSiteManagement(false);
    setViewSuperRole(false);
    setViewAgents(false);
    setViewStorageOwner(false);
    setViewUserRequests(true);
  };
  const viewStorageOwnerClick = () => {
    setViewRole(false);
    setViewStorageManager(false);
    setViewStorageOperator(false);
    setViewStorageQA(false);
    setViewSiteManagement(false);
    setViewSuperRole(false);
    setViewAgents(false);
    setViewStorageOwner(true);
    setViewUserRequests(false);
  };
  
  const viewAgentsClick = () => {
    setViewRole(false);
    setViewStorageManager(false);
    setViewStorageOperator(false);
    setViewStorageQA(false);
    setViewSiteManagement(false);
    setViewSuperRole(false);
    setViewAgents(true);
    setViewStorageOwner(false);
    setViewUserRequests(false);
  };
  const viewRoleClick = () => {
    setViewRole(true);
    setViewStorageManager(false);
    setViewStorageOperator(false);
    setViewStorageQA(false);
    setViewSiteManagement(false);
    setViewSuperRole(false);
    setViewAgents(false);
    setViewStorageOwner(false);
    setViewUserRequests(false);
  };

  const viewStorageManagerClick = () => {
    setViewRole(false);
    setViewStorageManager(true);
    setViewStorageOperator(false);
    setViewStorageQA(false);
    setViewSiteManagement(false);
    setViewSuperRole(false);
    setViewAgents(false);
    setViewStorageOwner(false);
    setViewUserRequests(false);
  };
  
  const viewStorageOperatorClick = () => {
    setViewRole(false);
    setViewStorageManager(false);
    setViewStorageOperator(true);
    setViewStorageQA(false);
    setViewSiteManagement(false);
    setViewSuperRole(false);
    setViewAgents(false);
    setViewStorageOwner(false);
    setViewUserRequests(false);
  };
  
  const viewStorageQAClick = () => {
    setViewRole(false);
    setViewStorageManager(false);
    setViewStorageOperator(false);
    setViewStorageQA(true);
    setViewSiteManagement(false);
    setViewSuperRole(false);
    setViewAgents(false);
    setViewStorageOwner(false);
    setViewUserRequests(false);
  };
  

  const viewSiteManagementClick = () => {
    setViewRole(false);
    setViewStorageManager(false);
    setViewStorageOperator(false);
    setViewStorageQA(false);
    setViewSiteManagement(true);
    setViewSuperRole(false);
    setViewAgents(false);
    setViewStorageOwner(false);
    setViewUserRequests(false);
  };

  const viewSuperRoleClick = () => {
    setViewRole(false);
    setViewStorageManager(false);
    setViewStorageOperator(false);
    setViewStorageQA(false);
    setViewSiteManagement(false);
    setViewSuperRole(true);
    setViewAgents(false);
    setViewStorageOwner(false);
    setViewUserRequests(false);
  };

  return (
    <>
      <div className="topHeadersWrapper">
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSiteManagementClick();
            }}
          >
            Site Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSuperRoleClick();
            }}
          >
            Role Management
          </Button>
        </div>
        
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewRoleClick();
            }}
          >
            Role Profile Management
          </Button>
        </div>
        &nbsp;
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewRequestsClick();
            }}
          >
            Requests
          </Button>
        </div>
        &nbsp;
        
      </div>
      &nbsp;
      <div className="topHeadersWrapper">
          
        </div>
      <div className="container" id="mainContentList">
        <h2>SN Network</h2>
        &nbsp;&nbsp;
        
        <div className="topHeadersWrapper">
        <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewStorageOwnerClick();
              }}
            >
              StorageOwner
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewStorageManagerClick();
              }}
            >
              StorageManager
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewStorageOperatorClick();
              }}
            >
              StorageOperator
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewStorageQAClick();
              }}
            >
              Storage-QA
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewAgentsClick();
              }}
            >
              Agents
            </Button>
          </div>
        </div>
     
      </div>

      <div style={{ paddingTop: "20px" }}>
      {viewSiteManagement && <AAMCSiteManagementComponent siteType={["Storage","Warehouse","ColdStorage","RootCellars","DryingFacilities","GrainBins","HayBarns"]}/>}
       
        {viewRole && <RoleProfileManagementComponent siteType={["Storage","Warehouse","ColdStorage","RootCellars","DryingFacilities","GrainBins","HayBarns"]} networkType="SN"/>}

        {viewStorageOperator && <StorageOperatorComponent />}
        {viewStorageManager && <StorageManagerComponent />}
        {viewStorageOwner && <StorageOwnerComponent/>}
       {viewAgents && <AAMCAgentComponent/>}
        {viewStorageQA && <StorageQAComponent />}
        {viewSuperRole && <RoleManagementComponent />}
        {viewUserRequests && <RegisterUsersRequestsComponent networkType="SN"/>}
      </div>
    </>
  );
}
