import React, { useState, useEffect, memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  Menu,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Tooltip,
  Box,
} from "@mui/material";
import { NotificationManager } from "react-notifications";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MainService from "../../Common/MainService";
import { Capitalize } from "../../Common/CommonContent/ContentedRelatedCommonFun";
import QuoteRequestModel from "./QuoteRequestModel";

const newStyles = {
  fontWeight: "bold",
  padding: "8px",
  textAlign: "center",
  backgroundColor: "aliceblue",
  position: "sticky",
};

const ProductQuoteManagementTable = ({ reqType, categoryType }) => {
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [5, 10, 25];
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rfqStatusFilter, setRfqStatusFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [sortBy, setSortBy] = useState("timeStamp");
  const [sortOrder, setSortOrder] = useState("asc");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getQuoteDetails, setQuoteDetails] = useState([]);
  const [getQuoteRequestModel, setQuoteRequestModel] = useState(false);
  const [reqId, setRfqId] = useState("");

  useEffect(() => {
    if (reqType) {
      getQuoteDataLoopforProductsHandler();
    }
  }, [
    reqType,
    categoryType,
    rfqStatusFilter,
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
  ]);

  const getQuoteDataLoopforProductsHandler = () => {
    MainService.getQuoteDataLoopforProducts(
      reqType,
      categoryType,
      rfqStatusFilter,
      page,
      rowsPerPage,
      sortBy,
      sortOrder,
      parentId,
      username
    )
      .then((res) => {
        let newArray;
        if (Array.isArray(res) && res.length > 0) {
          newArray = res?.map((item) => ({
            rfqId: item?.requestDetails.rfqId,
            name: item?.requestDetails?.personalDetails?.name || "",
            phoneNo: item?.requestDetails?.personalDetails?.phoneNo || "",
            // emailId: item?.requestDetails?.personalDetails?.emailId || "",
            origin: item?.requestDetails?.productDetails?.origin || "",
            grade: item?.requestDetails?.productDetails?.grade || "",
            quantity: item?.requestDetails?.productDetails?.quantity || "",
            rfqStatus: item?.requestDetails?.rfqStatus || "",
            timeStamp: item?.requestDetails?.timeStamp || "",
          }));
        } else {
          newArray = [];
        }
        setQuoteDetails(newArray);
        setFilteredData(newArray);
      })
      .catch((err) => {
        if (err && err.message) {
          NotificationManager.error(err.message); // Assuming 'message' is the property you want to display
        } else {
          NotificationManager.error("An error occurred");
        }
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = () => {
    setRfqStatusFilter("");
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  //   quote request
  const riseQuoteRequestHandler = () => {
    setQuoteRequestModel(!getQuoteRequestModel);
  };

  return (
    <>
      <Box>
        <IconButton onClick={handleClick}>
          <FilterListIcon />
          <Box component="span" sx={{ fontSize: "15px" }}>
            Filter
          </Box>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            <FormControl
              size="small"
              variant="outlined"
              sx={{
                width: "100%",
              }}
            >
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={rfqStatusFilter}
                onChange={(e) => setRfqStatusFilter(e.target.value)}
              >
                <MenuItem value="created">Created</MenuItem>
                <MenuItem value="open">Open</MenuItem>
                <MenuItem value="close">Close</MenuItem>
              </Select>
            </FormControl>
          </MenuItem>
          <MenuItem>
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
                handleFilter();
              }}
            >
              Clear
            </Button>
          </MenuItem>
        </Menu>
        <TableContainer
          component={Paper}
          style={{ height: "370px", marginTop: "15px" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(getQuoteDetails[0] || {}).map((header) => (
                  <TableCell key={header} style={newStyles}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>{Capitalize(header)}</span>
                      {header === "timeStamp" && (
                        <Tooltip
                          title={`Sort by ${header} ${
                            sortOrder === "asc" ? "descending" : "ascending"
                          }`}
                        >
                          <IconButton onClick={() => handleSort(header)}>
                            {sortOrder === "asc" ? (
                              <ArrowUpwardIcon />
                            ) : (
                              <ArrowDownwardIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                ))}
                <TableCell style={newStyles}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(getQuoteDetails) && getQuoteDetails.length > 0 ? (
                getQuoteDetails.map((row, index) => (
                  <TableRow key={index}>
                    {Object.values(row).map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                    <TableCell>
                      <Button
                        sx={{
                          textTransform: "none",
                          fontSize: "10px",
                          border: "1px solid gray",
                        }}
                        onClick={() => {
                          riseQuoteRequestHandler();
                          setRfqId(row.rfqId);
                        }}
                        disabled={row.rfqStatus === "close"}
                      >
                        Quote Request
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={1}>
                    <Typography variant="h6" style={{ textAlign: "center" }}>
                      No data available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={getQuoteDetails.length < 5 ? getQuoteDetails.length : -1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <QuoteRequestModel
        openModel={getQuoteRequestModel}
        closeModel={riseQuoteRequestHandler}
        rfqId={reqId}
        componentName="proforma"
      />
    </>
  );
};

export default memo(ProductQuoteManagementTable);
