import React, { useState, useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import Main from "../../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import WhiteLabelRequestsComponent from "../ViewAssetOEM/WhiteLabelRequests";
import BuyRequestsComponent from "../BuyRequests/BuyRequestsComponent";
import OrderAssetManagement from "../OrderManagement/Order/AssetOrder/OrderAssetManagement";
import OrderProductManagement from "../OrderManagement/Order/ProductOrder/OrderProductManagement";
import AssetQuoteManagement from "../QuotationManagement/AssetQuoteManagement";
import ProductQuoteManagement from "../QuotationManagement/ProductQuoteManagement";
import AssetsInvoiceManagement from "../OrderManagement/Invoice/AssetInvoice/AssetsInvoiceManagement";
import ProductsInvoice from "../OrderManagement/Invoice/ProductInvoice/ProductsInvoice";
import "./MyOrderStyleComponent.css";

export default function ViewRFQComponent(props) {
  const history = useHistory();
  const { user } = Main.getLocalData();
  const { username, parentId } = user;
  const [userTypeValue, setUserTypeValue] = useState("");
  const [activeComponent, setActiveComponent] = useState("assetsRFQ");
  const userType = localStorage.getItem("userType");
  const [RequestTypes, setRequestTypes] = useState({});

  useEffect(() => {
    getUserType();
   
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

 
  const getUserType = () => {
    Main.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  return (
    <>
      <div className="container">
        

        <div className="container">
          <div className="topHeadersWrapper">
            
            <div className="topHeaders">
              <Button
                size="small"
                variant="contained"
                onClick={() => handleButtonClick("assetsRFQ")}
                disabled={activeComponent === "assetsRFQ"}
                style={{ marginRight: "10px" }}
                className={
                  activeComponent === "assetsRFQ" ? "activeButton" : ""
                }
              >
                Assets RFQ
              </Button>
            </div>
            <div className="topHeaders">
              <Button
                size="small"
                variant="contained"
                onClick={() => handleButtonClick("productsRFQ")}
                disabled={activeComponent === "productsRFQ"}
                style={{ marginRight: "10px" }}
                className={
                  activeComponent === "productsRFQ" ? "activeButton" : ""
                }
              >
                Products RFQ
              </Button>
            </div>
            
          </div>
        </div>
      </div>

      <div style={{ paddingTop: "20px" }}>
       
        {activeComponent === "assetsRFQ" && (
          <AssetQuoteManagement
          reqTypes={[
            { val: "asset-buy", type: "asset-buy-rfq-req" },
            { val: "cf-agri-assets", type: "cf-agri-assets-rfq-req" },
          ]}
        />
        
        )}
        {activeComponent === "productsRFQ" && (
          <ProductQuoteManagement
          reqTypes={[
            { val: "agri-buy-products", type: "agri-buy-products-rfq-req" },
            {
              val: "cf-agri-raw-products",
              type: "cf-agri-raw-products-rfq-req",
            },
          ]}
          />
        )}
        
      </div>
    </>
  );
}
