import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import "./SiteManagementComponent.css";
import Typography from "@material-ui/core/Typography";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from "react-google-maps";
import socketIOClient from "socket.io-client";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import { Grid } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";
import { RowingSharp } from "@material-ui/icons";
import Map from "../../Common/googleMap/Map";
import FarmUpdatesBySiteIdComponent from "../../AssetDeveloperRole/FarmUpdatesBySiteId/FarmUpdatesBySiteIdComponent";
import { Menu, ListSubheader } from "@mui/material";
import {
  Info as InfoIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  GetApp as GetAppIcon,
  Feedback as FeedbackIcon,
  Report as ReportIcon,
  Alarm as AlarmIcon,
  Agriculture as AgricultureIcon,
  FileCopy as FileCopyIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // set last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2.5),
    right: theme.spacing(2.5),
    zIndex: 10,
    color: "#fff",
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "black",
    borderStyle: "solid",
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: "100vw",
  },
  tableHeader: {
    fontWeight: "bold",
    textAlign: "center",
  },
  table: {
    maxWidth: "100%",
    overflowX: "scroll",
  },
}));

export default function SiteManagementComponent(props) {
  const _ = require("lodash");
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  /* Sites */
  const [newBirthAssetid, setNewBirthAssetid] = useState("");
  const [newParturitionAssetid, setNewParturitionAssetid] = useState("");
  const [newMortalityAssetid, setNewMortalityAssetid] = useState("");
  const [newBirthSiteid, setNewBirthSiteid] = useState("");
  const [ageVal, setAgeVal] = useState("");
  const [weightVal, setWeightVal] = useState("");
  const [genderVal, setGenderVal] = useState("");
  const [getMappedAssetsType, setMappedAssetsType] = useState("");
  const [getMappedAssetsSiteid, setMappedAssetsSiteid] = useState("");
  const [getMappedAssetsSiteName, setMappedAssetsSiteName] = useState("");
  const [getBackToSites, setBackToSites] = useState("");
  const [getAssetBackButtton, setAssetBackButtton] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [viewAllListSites, setViewallListSites] = useState(false);
  const [viewAllGridSites, setViewallGridSites] = useState(true);
  const [viewAllMapSites, setViewallMapSites] = useState(false);
  const [showAssetList, setAssetList] = useState(false);
  const [showAssetGrid, setAssetGrid] = useState(false);
  const [showAssetMap, setAssetMap] = useState(false);
  const [showDistricts, setShowDistricts] = useState(false);
  const [mappedAssetShow, setMappedAssetShow] = useState(false);
  const [mapWithSite, setMapWithSites] = useState(false);
  const [showSiteRating, setShowSiteRating] = useState(false);
  const [getAllSites, setAllSites] = useState([]);
  const [getMappedAssets, setMappedAssets] = useState([]);
  const [getMapwithSite, setMapwithSite] = useState([]);
  const [getSiteId, setSiteId] = useState("");
  const [redirectSiteId, setRedirectSiteId] = useState("");
  const [getSiteRatings, setSiteRatings] = useState([]);
  const [getSiteRateSiteId, setSiteRateSiteId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  /* QR Code */
  const [assetVerticalIdForQr, setAssetVerticalIdForQr] = useState("");
  const [fgcolor, setFgcolor] = useState("000");
  const [bgcolor, setBgcolor] = useState("f0ffff");
  const [qrSizeValue, setQrSizeValue] = useState("400");
  const [qrCodeFile, setQrCodeFile] = useState("");
  const [qrCodeFileDownload, setQrCodeFileDownload] = useState("");
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [selectedSiteName, setSelectedSiteName] = useState("");
  const [siteDeleteDialog, setSiteDeleteDialog] = useState(false);
  const [generateQrClick, setGenerateQrClick] = useState(false);
  const [qrCodeDialog, setQrCodeDialog] = useState(false);
  /* Create Frm Site */
  const [getSitePopUp, setSiteCreatePopUp] = useState(false);
  const [showCreateSitePopup, setShowCreateSitePopup] = useState(false);
  const [showCreateSiteDialog, setShowCreateSiteDialog] = useState(false);
  const [getSiteName, setSiteName] = useState("");
  const [getMailId, setMailId] = useState("");
  const [getPhone, setPhone] = useState("");
  const [getFarmerUserName, setFarmerUserName] = useState("");
  const [getSiteType, setSiteType] = useState(props?.siteType[0] || "");
  const [getSiteData, setSiteData] = useState([]);
  const [nodeEmail, setNodeEmail] = useState("");
  const [getNodeId, setNodeId] = useState("");
  const [nodeMobileNumber, setNodePhoneNumber] = useState("");
  const [nodeUserName, setNodeUserName] = useState("");
  const [nodeType, setNodeType] = useState("");
  const [nodeVerticalName, setNodeVerticalName] = useState("");
  const [nodeVerticalId, setNodeVerticalId] = useState("");
  /* End Create Farm Site */
  /* Site Rating */
  const [getSiteRatePopup, setSiteRatePopUp] = useState(false);
  const [defaultSiteId, setDefaultSiteId] = useState("");
  const [setupdateSiteRating, getUpDateSiteRating] = useState("");
  /* End Of Site Rating */
  /* Site Info */
  const [siteInfoObj, setSiteInfoObj] = useState({});
  const [nodeInfoObj, setNodeInfoObj] = useState({});
  const [isObjectEmpty, setIsObjectEmpty] = useState(false);
  const [infoSiteId, getInfoSiteId] = useState("");
  const [setSiteInfoPopup, getSiteInfoPopUp] = useState(false);
  const [getNodeInfoPopup, setNodeInfoPopUp] = useState(false);
  /* Site update */
  const [roleName, setRoleName] = useState("");
  const [getSiteInfoName, setSiteInfoName] = useState("");
  const [getPhoneNumber, setPhoneNumber] = useState("");
  const [getEmail, setEmail] = useState("");
  const [getSiteInfoExistingSiteType, setSiteInfoExistingSiteType] =
    useState("");
  const [getUpdateSiteType, setUpdateSiteType] = useState("");
  const [getWebsiteUrl, setWebsiteUrl] = useState("");
  const [getLocAddress, setLocAddress] = useState("");
  const [getSiteInfoAssetVerticalId, setSiteInfoAssetVerticalId] = useState("");
  const [getIndustryType, setIndustryType] = useState("");
  const [getStreetName, setStreetName] = useState("");
  const [getStreetNumber, setStreetNumber] = useState("");
  const [getLocality, setLocality] = useState("");
  const [getArea, setArea] = useState("");
  const [getRegion, setRegion] = useState("");
  const [getCountry, setCountry] = useState("");
  const [getPostalCode, setPostalCode] = useState("");
  const [getLatitude, setLatitude] = useState("");
  const [getLongitude, setLongitude] = useState("");
  const [getAltitude, setAltitude] = useState("");
  const [getTimeZone, setTimeZone] = useState("");
  const [getCityCode, setCityCode] = useState("");
  const [getCityName, setCityName] = useState("");
  const [getCountryCode, setCountryCode] = useState("");
  const [getStdCode, setStdCode] = useState("");
  const [getSiteUpdatePopup, setSiteUpdatePopup] = useState(false);

  /* End Of Site Update */
  const [searched, setSearched] = useState("");
  const [farmgridsearched, setFarmGridSearched] = useState("");

  /********** Birth ****************/
  const [assetBirthInfoPopup, setAssetBirthInfoPopup] = useState(false);

  const [getBirthInfos, setBirthInfos] = useState([]);

  const [getNewBirthSiteName, setNewBirthSiteName] = useState("");

  /********** End Of Birth ****************/
  /********** Back Button ****************/
  const [getBackType, setBackType] = useState("");
  /********** End Of Back Button ****************/
  /********** Attendance Location ****************/
  const [assetAttendanceDialog, setAssetAttendanceDialog] = useState(false);
  const [assetAttendanceOnj, setAssetAttendanceOnj] = useState({});

  const [showAttendanceLocation, setAttendanceLocation] = useState(false);
  const [getAttendanceLocationHistory, setAttendanceLocationHistory] = useState(
    []
  );

  const [showNewAttendanceLocationPopup, setNewAttendanceLocationPopup] =
    useState(false);
  const [getNewAttendanceLocationAssetid, setNewAttendanceLocationAssetid] =
    useState("");
  const [
    getNewAttendanceLocationAssetfriendlyName,
    setNewAttendanceLocationAssetfriendlyName,
  ] = useState("");
  const [getNewAttendanceLocationUserid, setNewAttendanceLocationUserid] =
    useState(username);
  const [getNewAttendanceLocationLatitude, setNewAttendanceLocationLatitude] =
    useState("");
  const [getNewAttendanceLocationLongitude, setNewAttendanceLocationLongitude] =
    useState("");
  const [getNewAttendanceLocationAltitude, setNewAttendanceLocationAltitude] =
    useState("");
  const [
    getNewAttendanceLocationDescription,
    setNewAttendanceLocationDescription,
  ] = useState("");

  const [getDeletedAttendanceAssetid, setDeletedAttendanceAssetid] =
    useState("");
  const [getDeletedAttendancePopup, setDeletedAttendancePopup] =
    useState(false);

  const [
    getUpdateAttendanceLocationInfoAssetid,
    setUpdateAttendanceLocationInfoAssetid,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoAssetfriendlyName,
    setUpdateAttendanceLocationInfoAssetfriendlyName,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoUserid,
    setUpdateAttendanceLocationInfoUserid,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoLatitude,
    setUpdateAttendanceLocationInfoLatitude,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoLongitude,
    setUpdateAttendanceLocationInfoLongitude,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoAltitude,
    setUpdateAttendanceLocationInfoAltitude,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoDescription,
    setUpdateAttendanceLocationInfoDescription,
  ] = useState("");
  const [showUpdateAttendaneLocationPopup, setUpdateAttendaneLocationPopup] =
    useState(false);
  /********** End Of Attendance Location ****************/
  /********** Health Record ****************/
  const [assetHelathRecordInfoPopup, setAssetHelathRecordInfoPopup] =
    useState(false);
  const [showHealthRecords, setHealthRecords] = useState(false);

  const [assetHealthRecord, setAssetHealthRecord] = useState({});

  /********** End Of Health Record ****************/
  /********** Nutrition ****************/

  const [getNutritions, setNutritions] = useState({});
  const [assetNutritionInfoPopup, setAssetNutritionInfoPopup] = useState(false);

  /********** End Of Nutrition ****************/
  /********** Parturition ****************/
  const [assetParturitionInfoPopup, setAssetParturitionInfoPopup] =
    useState(false);

  const [getParturitionDetail, setParturitionDetail] = useState([]);

  /********** End Of Parturition ****************/
  /********** Mortality ****************/
  const [assetMortalityInfoPopup, setAssetMortalityInfoPopup] = useState(false);
  const [getMortalityDetails, setMortalityDetails] = useState([]);

  /********** End Of Mortality ****************/
  /**********  Asset Map ,UnMap, Delete, Update, Create  ****************/
  const [mapPopUp, setMapPopUp] = useState(false);
  const [mapSiteId, setMapSiteId] = useState("");
  const [mapAssetId, setMapAssetId] = useState("");
  const [mapCategoryType, setMapCategoryType] = useState("");

  const [getShowDeletePopup, setShowDeletePopup] = useState(false);
  const [getMappedAssetDeleteAssetid, setMappedAssetDeleteAssetid] =
    useState("");
  const [getMappedAssetDeleteAssetType, setMappedAssetDeleteAssetType] =
    useState("");
  const [getMappedAssetDeleteAssetModelid, setMappedAssetDeleteAssetModelid] =
    useState("");
  const [
    getMappedAssetDeleteAssetVerticalid,
    setMappedAssetDeleteAssetVerticalid,
  ] = useState("");
  const [getMappedAssetDeleteAssetCategory, setMappedAssetDeleteAssetCategory] =
    useState("");

  //const [getlegacyUpdatePopup, setLegacyUpdatePopup] = useState(false);
  const [assetInfoUpdatePopup, setAssetInfoUpdatePopup] = useState(false);
  const [legacyUpdate, setLegacyUpdate] = useState(false);
  const [assetVeriticalId, setAssetVerticalId] = useState("");
  const [assetTypeVal, setAssetTypeVal] = useState("");
  const [assetIdVal, setAssetIdVal] = useState("");
  const [assetFriName, setAssetFriName] = useState("");
  const [modelIdVal, setModelIdVal] = useState("");
  const [categoryVal, setCatgeryVal] = useState("");
  const [udnVal, setUdnVal] = useState("");
  const [whiteLabelVal, setWhiteLabelVal] = useState("");
  const [sellingCostVal, setSellingCostVal] = useState("");
  const [exAssetStatus, setExAssetStatus] = useState("");
  const [updateAssetStatus, setUpdateAssetStatus] = useState("");
  /********** End Of Asset Map UnMap Delete Update Create ****************/
  /**************************** Create Asset ********************************/
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [modelList, setModelsList] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedSite, setSelectedSite] = useState({});
  const [qrUrlValue, setQrUrlValue] = useState("");
  const [assetIdValue, setAssetIdValue] = useState("");
  const [legacyAssetFriendlyNameValue, setLegacyAssetFriendlyNameValue] =
    useState("");
  const [assetVerticalFriendlyNameValue, setAssetVerticalFriendlyNameValue] =
    useState("");
  const [assetDomainValue, setAssetDomainValue] = useState("");
  const [lotNameValue, setLotNameValue] = useState("");
  const [lotIdValue, setLotIdValue] = useState("");
  const [manufacturerIdValue, setManufacturerIdValue] = useState(username);
  const [manufacturerUrlValue, setManufacturerUrlValue] = useState("");
  const [modelUrlValue, setModelUrlValue] = useState("");
  const [serialNoValue, setSerialNoValue] = useState("");
  const [udnValue, setUdnValue] = useState("");
  const [upcValue, setUpcValue] = useState("");
  const [motherBreedValue, setMotherBreedValue] = useState("");
  const [fatherBreedValue, setFatherBreedValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [whiteLabelCostValue, setWhiteLabelCostValue] = useState("");
  const [sellingCostValue, setSellingCostValue] = useState("");
  const [acquireCostValue, setAcquireCostValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [altitudeValue, setAltitudeValue] = useState("");
  const [getCreateAssetPopup, setCreateAssetPopup] = useState(false);
  const [assetSiteIdValue, setAssetSiteIdValue] = useState("");
  const [assetSiteNameValue, setAssetSiteNameValue] = useState("");
  const [modelNameVal, setModelNameVal] = useState("");
  const [modelTypeVal, setModelTypeVal] = useState("");
  const [modelUrlVal, setModelUrlVal] = useState("");

  /********************************** End Of Create Asset***********************/
  /********** Site Feedback ****************/
  const [showSiteFeedbackModel, setShowSiteFeedbackModel] = useState(false);
  const [getSiteFeedbackSiteid, setSiteFeedbackSiteid] = useState("");

  const [getSiteCustomerExperience, setSiteCustomerExperience] = useState(0);
  const [getSiteCustomerExperienceHover, setSiteCustomerExperienceHover] =
    useState(-1);
  const [getSiteFeedbackCustomerExperience, setSiteFeedbackCustomerExperience] =
    useState("");

  const [getSiteProduct, setSiteProduct] = useState(0);
  const [getSiteProductHover, setSiteProductHover] = useState(-1);
  const [getSiteFeedbackProduct, setSiteFeedbackProduct] = useState("");

  const [getSiteCustomerSupport, setSiteCustomerSupport] = useState(0);
  const [getSiteCustomerSupportHover, setSiteCustomerSupportHover] =
    useState(-1);
  const [getSiteFeedbackCustomerSupport, setSiteFeedbackCustomerSupport] =
    useState("");

  const [getSiteDelivery, setSiteDelivery] = useState(0);
  const [getSiteDeliveryHover, setSiteDeliveryHover] = useState(-1);
  const [getSiteFeedbackDelivery, setSiteFeedbackDelivery] = useState("");

  const [getSiteServiceOthers, setSiteServiceOthers] = useState(0);
  const [getSiteServiceOthersHover, setSiteServiceOthersHover] = useState(-1);
  const [getSiteFeedbackServiceOthers, setSiteFeedbackServiceOthers] =
    useState("");

  const [getSiteFeedbackRemark, setSiteFeedbackRemark] = useState("");
  /********** End Of Site Feedback ****************/
  /********** Asset Feedback ****************/
  const [showAssetFeedbackModel, setShowAssetFeedbackModel] = useState(false);
  const [getAssetFeedbackAssetDeveloperid, setAssetFeedbackAssetDeveloperid] =
    useState(username);
  const [getAssetFeedbackAssetid, setAssetFeedbackAssetid] = useState("");

  const [getAssetCustomerExperience, setAssetCustomerExperience] = useState(0);
  const [getAssetCustomerExperienceHover, setAssetCustomerExperienceHover] =
    useState(-1);
  const [
    getAssetFeedbackCustomerExperience,
    setAssetFeedbackCustomerExperience,
  ] = useState("");

  const [getAssetProduct, setAssetProduct] = useState(0);
  const [getAssetProductHover, setAssetProductHover] = useState(-1);
  const [getAssetFeedbackProduct, setAssetFeedbackProduct] = useState("");

  const [getAssetCustomerSupport, setAssetCustomerSupport] = useState(0);
  const [getAssetCustomerSupportHover, setAssetCustomerSupportHover] =
    useState(-1);
  const [getAssetFeedbackCustomerSupport, setAssetFeedbackCustomerSupport] =
    useState("");

  const [getAssetDelivery, setAssetDelivery] = useState(0);
  const [getAssetDeliveryHover, setAssetDeliveryHover] = useState(-1);
  const [getAssetFeedbackDelivery, setAssetFeedbackDelivery] = useState("");

  const [getAssetServiceOthers, setAssetServiceOthers] = useState(0);
  const [getAssetServiceOthersHover, setAssetServiceOthersHover] = useState(-1);
  const [getAssetFeedbackServiceOthers, setAssetFeedbackServiceOthers] =
    useState("");

  const [getAssetFeedbackRemark, setAssetFeedbackRemark] = useState("");
  /********** End Of Asset Feedback ****************/
  /********** Site Issue ****************/
  const [showSiteIssue, getShowSiteIssue] = useState(false);
  const [getSiteIssueSolutionId, setSiteIssueSolutionId] = useState(username);
  const [getSiteIssueSiteId, setSiteIssueSiteId] = useState("");
  const [getSiteIssueDescription, setSiteIssueDescription] = useState("");

  const [showSiteIssueDetailsModel, getShowSiteIssueDetailsModel] =
    useState(false);
  const [getSiteIssueDetails, setSiteIssueDetails] = useState([]);
  /********** End Of Site Issue ****************/
  /********** Asset Issue ****************/
  const [getAssetIssueSiteId, setAssetIssueSiteId] = useState("");
  const [showAssetIssueDetailsModel, getShowAssetIssueDetailsModel] =
    useState(false);
  const [getAssetIssueDetails, setAssetIssueDetails] = useState([]);
  /********** End Of Asset Issue ****************/
  /********** Daily Report ****************/
  const [showDailyReports, setShowDailyReports] = useState(false);
  const [getDailyReports, setDailyReports] = useState([]);

  const [showAddDailyReportModel, getShowAddDailyReportModel] = useState(false);
  const [getAddDailyReportSiteId, setAddDailyReportSiteId] = useState("");
  const [getAddDailyReportNutrition, setAddDailyReportNutrition] = useState("");
  const [getAddDailyReportAttendance, setAddDailyReportAttendance] =
    useState("");
  const [getAddDailyReportPhoto, setAddDailyReportPhoto] = useState("");
  /********** End Of Daily Report ****************/

  /************    Site Alarms       **********/
  const [gridViewSiteAlarms, setGridViewSiteAlarms] = useState(false);
  const [siteAlarmPopup, setSiteAlarmPopup] = useState(false);
  const [sitealarmsList, setSiteAlarmsList] = useState([]);
  /******* End of Site Alarms ******** */
  /* Satrt of Create Site Alarm */
  const [selectedVerticalName, setSelectedVerticalName] = useState("");
  const [alarmCategoryValue, setAlarmCategoryValue] = useState("");
  const [alarmNameValue, setAlarmNameValue] = useState("");
  const [resourceId, setResourceId] = useState("");
  /* End of Site Alarm */
  /********** Site Group Nutrition ****************/
  const [showGropuNutritionView, setShowGropuNutritionView] = useState(false);
  const [getGroupNutritionDetails, setGroupNutritionDetails] = useState([]);

  const [showAddGroupNutritionModel, setShowAddGroupNutritionModel] =
    useState(false);
  const [getAddGroupNutritionSiteid, setAddGroupNutritionSiteid] = useState("");
  const [getAddGroupNutritionFriendlyname, setAddGroupNutritionFriendlyname] =
    useState("");
  const [getAddGroupNutritionUserid, setAddGroupNutritionUserid] =
    useState(username);
  const [getAddGroupNutritionFeedType, setAddGroupNutritionFeedType] =
    useState("");
  const [getAddGroupNutritionSchedule, setAddGroupNutritionSchedule] =
    useState("");
  const [getAddGroupNutritionAltitude, setAddGroupNutritionAltitude] =
    useState("");
  const [getAddGroupNutritionDescription, setAddGroupNutritionDescription] =
    useState("");

  const [showDeleteGroupNutritionModel, setShowDeleteGroupNutritionModel] =
    useState(false);
  const [getDeleteGroupNutritionSiteid, setDeleteGroupNutritionSiteid] =
    useState("");

  const [showUpdateGroupNutritionModel, setShowUpdateGroupNutritionModel] =
    useState(false);
  const [getUpdateGroupNutritionSiteid, setUpdateGroupNutritionSiteid] =
    useState("");
  const [
    getUpdateGroupNutritionFriendlyname,
    setUpdateGroupNutritionFriendlyname,
  ] = useState("");
  const [getUpdateGroupNutritionUserid, setUpdateGroupNutritionUserid] =
    useState("");
  const [getUpdateGroupNutritionFeedType, setUpdateGroupNutritionFeedType] =
    useState("");
  const [getUpdateGroupNutritionSchedule, setUpdateGroupNutritionSchedule] =
    useState("");
  const [getUpdateGroupNutritionLatitude, setUpdateGroupNutritionLatitude] =
    useState("");
  const [getUpdateGroupNutritionLongitude, setUpdateGroupNutritionLongitude] =
    useState("");
  const [getUpdateGroupNutritionAltitude, setUpdateGroupNutritionAltitude] =
    useState("");
  const [
    getUpdateGroupNutritionDescription,
    setUpdateGroupNutritionDescription,
  ] = useState("");

  const [
    prevgetUpdateGroupNutritionFeedType,
    prevsetUpdateGroupNutritionFeedType,
  ] = useState("");
  const [
    prevgetUpdateGroupNutritionSchedule,
    prevsetUpdateGroupNutritionSchedule,
  ] = useState("");
  /********** End Of Site Group Nutrition ****************/
  /**********Site Attendance Location ****************/
  const [showSiteAttendanceLocation, setSiteAttendanceLocation] =
    useState(false);
  const [getSiteAttendanceLocationHistory, setSiteAttendanceLocationHistory] =
    useState([]);

  const [
    showSiteNewAttendanceLocationPopup,
    setSiteNewAttendanceLocationPopup,
  ] = useState(false);
  const [
    getSiteNewAttendanceLocationAssetid,
    setSiteNewAttendanceLocationAssetid,
  ] = useState("");
  const [
    getSiteNewAttendanceLocationAssetfriendlyName,
    setSiteNewAttendanceLocationAssetfriendlyName,
  ] = useState("");
  const [
    getSiteNewAttendanceLocationUserid,
    setSiteNewAttendanceLocationUserid,
  ] = useState(username);
  const [
    getSiteNewAttendanceLocationAltitude,
    setSiteNewAttendanceLocationAltitude,
  ] = useState("");
  const [
    getSiteNewAttendanceLocationDescription,
    setSiteNewAttendanceLocationDescription,
  ] = useState("");

  const [getSiteDeletedAttendanceAssetid, setSiteDeletedAttendanceAssetid] =
    useState("");
  const [getSiteDeletedAttendancePopup, setSiteDeletedAttendancePopup] =
    useState(false);

  const [
    getSiteUpdateAttendanceLocationInfoAssetid,
    setSiteUpdateAttendanceLocationInfoAssetid,
  ] = useState("");
  const [
    getSiteUpdateAttendanceLocationInfoAssetfriendlyName,
    setSiteUpdateAttendanceLocationInfoAssetfriendlyName,
  ] = useState("");
  const [
    getSiteUpdateAttendanceLocationInfoUserid,
    setSiteUpdateAttendanceLocationInfoUserid,
  ] = useState("");
  const [
    getSiteUpdateAttendanceLocationInfoLatitude,
    setSiteUpdateAttendanceLocationInfoLatitude,
  ] = useState("");
  const [
    getSiteUpdateAttendanceLocationInfoLongitude,
    setSiteUpdateAttendanceLocationInfoLongitude,
  ] = useState("");
  const [
    getSiteUpdateAttendanceLocationInfoAltitude,
    setSiteUpdateAttendanceLocationInfoAltitude,
  ] = useState("");
  const [
    getSiteUpdateAttendanceLocationInfoDescription,
    setSiteUpdateAttendanceLocationInfoDescription,
  ] = useState("");
  const [
    showSiteUpdateAttendaneLocationPopup,
    setSiteUpdateAttendaneLocationPopup,
  ] = useState(false);
  /********** End Of Site Attendance Location ****************/
  /********** Grow,Sell,Loan,Vet Visit Requests ****************/
  const [getGrowSiteID, setGrowSiteID] = useState("");

  const [viewFarmUpdates, setVewFarmUpdates] = useState(false);

  const [viewSell, setViewSell] = useState(false);

  const [viewLoan, setViewLoan] = useState(false);
  const [viewVetVisit, setViewVetVisit] = useState(false);

  /********** End Of Grow,Sell,Loan,Vet Visit Requests ****************/
  /* Asset Live Monoitoring */
  const [markers, setMarkers] = useState([]);
  const [ssetMonitoringStatus, setAssetMonitoringStatus] = useState("");
  const [marker_position, setmarker_position] = useState({
    lat: 0,
    lng: 0,
  });
  const [ssetMonitorTimestamp, setAssetMonitorTimestamp] = useState("");
  const [assetMonitoringView, setAssetMonitoringView] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState("");
  /* Photo Upload */
  const [selectedCategoryName, setSelectedCategoryName] = useState("image");
  const [uploadPhotoPopup, setShowUploadPhotoPopup] = useState(false);
  const [uploadPhotoFile, setUploadPhotoFile] = useState(null);
  const [uploadPhotoFileDescription, setUploadPhotoFileDescription] =
    useState(null);
  const [uploadPhotoNameValue, setUploadPhotoNameValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [expiryDateValue, setExpiryDateValue] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  /* End of Photo Upload */
  /* Site and Asset Env data */
  const [assetEnvPopup, setAssetEnvPopup] = useState(false);
  const [assetEnvData, setAssetEnvData] = useState({});
  const [siteEnvPopup, setSiteEnvPopup] = useState(false);
  const [siteEnvData, setSiteEnvData] = useState({});
  const [selectedSiteType, setSelectedSiteType] = useState("");
  /* End of Site and Asset Env data */
  const [userTypeValue, setUserTypeValue] = useState("");
  const [orgId, setOrgId] = useState("");
  const [subOrgId, setSubOrgId] = useState("");
  const [superParent, setSuperParent] = useState();

  /* White space validation */
  const [assetNameError, setAssetNameError] = useState(false);
  const [assetIdError, setAssetIdError] = useState(false);
  const [siteNameError, setSiteNameError] = useState(false);
  const [moreAnchorElForSite, setmoreAnchorElForSiteForSite] = useState(null);
  const [moreAnchorElForAsset, setMoreAnchorElForAsset] = useState(null);

  const handleMoreClickForAsset = (event) => {
    setMoreAnchorElForAsset(event.currentTarget);
  };

  const handleMoreCloseForAsset = () => {
    setMoreAnchorElForAsset(null);
  };

  const handleMoreClickForSite = (event) => {
    setmoreAnchorElForSiteForSite(event.currentTarget);
  };

  const handleMoreCloseForSite = () => {
    setmoreAnchorElForSiteForSite(null);
  };
  /* View All Sites */
  useEffect(() => {
    setAllSites(props.sites);
    if ("geolocation" in navigator) {
      console.log("Available");
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitudeValue(position.coords.latitude.toString());
        setLongitudeValue(position.coords.longitude.toString());
      });
    } else {
      console.log("Not Available");
      setLatitudeValue("");
      setLongitudeValue("");
    }
    //getAllSitesData("Grid");
    console.log("getAllSitesData1");
  }, []);
  const backToSites = () => {
    setViewallListSites(false);
    setViewallGridSites(true);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setVewFarmUpdates(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setAttendanceLocation(false);
    setHealthRecords(false);

    setGridViewSiteAlarms(false);
    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
  };
  const getAllSitesData = (Type) => {
    setPage(0);
    setIsLoading(true);
    if (Type === "List") {
      setViewallListSites(true);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setShowSiteRating(false);
      setAssetList(false);
      setAssetGrid(false);
      setAssetMap(false);
      setVewFarmUpdates(false);
      setAttendanceLocation(false);
      setHealthRecords(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
    } else if (Type === "Grid") {
      setViewallListSites(false);
      setViewallGridSites(true);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setVewFarmUpdates(false);
      setShowSiteRating(false);
      setAssetList(false);
      setAssetGrid(false);
      setAssetMap(false);

      setAttendanceLocation(false);
      setHealthRecords(false);

      setGridViewSiteAlarms(false);
      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
    } else if (Type === "Map") {
      setViewallListSites(false);
      setViewallGridSites(false);
      setViewallMapSites(true);
      setAssetMonitoringView(false);
      setShowSiteRating(false);
      setAssetList(false);
      setAssetGrid(false);
      setAssetMap(false);

      setAttendanceLocation(false);
      setHealthRecords(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
    }
    props.viewAssetsHandler(false);
    /* MainService.viewAllSites(parentId, username)
      .then((res) => {
        setIsLoading(false);
        setAllSites(res.sites);
        getFilterUsers(res.sites);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      }); */
    console.log("sites data from props1:", props.sites);
    setAllSites(props.sites);
    getFilterUsers(props.sites);
    setIsLoading(false);
  };

  /* End View All Sites */

  /*List Filter */
  const requestSearch = (searchedVal) => {
    console.log("search value:", searchedVal);
    props.requestSearch(searchedVal);
  };

  const cancelSearch = () => {
    setSearched("");
    props.requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    console.log("search value:", searchedVal);
    props.requestFarmGridSearch(searchedVal);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    props.requestFarmGridSearch(farmgridsearched);
  };
  useEffect(() => {
    getUserType();
    getRoleNameofOperator();
    getCategory();
    getAssetVerticalsIdList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getAllSitesList();
  }, [setSiteData]);

  useEffect(() => {
    getCategoryTypes();
  }, [selectedVerticalsId]);
  useEffect(() => {
    getAssetTypes();
  }, [selectedVerticalsId, selectedCategory]);
  useEffect(() => {
    getModels();
  }, [selectedVerticalsId, selectedCategory, selectedAssertTypes]);
  useEffect(() => {
    getAllAssetsBasedonModel();
  }, [selectedVerticalsId, selectedCategory, selectedAssertTypes,selectedModel]);

  
  const getRoleNameofOperator = () => {
    MainService.getRoleNameofOperator(parentId, username)
      .then((res) => {
        console.log("role pm", res);
        setRoleName(res);
        getAllSitesData("Grid", res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        console.log("userTypeValue:", data);
        setUserTypeValue(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const getCategory = () => {
    if (parentId !== "" && selectedCategoryName !== "") {
      MainService.getCategory(parentId, selectedCategoryName)
        .then((data) => {
          if (data) {
            setSelectedCategoryId(data);
          } else {
            setSelectedCategoryId("");
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  /* Pagination */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* End Pagination */

  /* Birth Api's*/

  const birthInfoButtonClickHandler = (assetid, viewtype) => {
    setMoreAnchorElForAsset(null);
    setNewBirthAssetid(assetid);
    setAssetBackButtton(viewtype);
    getBirthDetailsHandler(assetid);
    setAssetBirthInfoPopup(!assetBirthInfoPopup);
  };

  const getBirthDetailsHandler = (assetid) => {
    setIsLoading(true);
    MainService.getBirthInfo(assetid)
      .then((res) => {
        setIsLoading(false);
        setBirthInfos(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAssetBirthInfoPopup(!assetBirthInfoPopup);
  };

  /* End Of Birth Api's*/

  /* Attendance Location Api's*/
  const assetAttenanceClick = (assetId) => {
    setMoreAnchorElForAsset(null);
    MainService.getAttendanceLocationInfo(assetId)
      .then((res) => {
        console.log("attendance  innfo:", res);
        setAssetAttendanceOnj(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAssetAttendanceDialog(!assetAttendanceDialog);
  };

  const addAttendanceLocationHandler = () => {
    const attendanceLocation = {
      assetid: getNewAttendanceLocationAssetid,
      assetfriendlyname: getNewAttendanceLocationAssetfriendlyName,
      parentid: parentId,
      userid: getNewAttendanceLocationUserid,
      latitude: getNewAttendanceLocationLatitude,
      longitude: getNewAttendanceLocationLongitude,
      altitude: getNewAttendanceLocationAltitude,
      description: getNewAttendanceLocationDescription,
    };
    const newattendanceLocation = JSON.stringify(attendanceLocation);

    MainService.NewAttendanceLocation(newattendanceLocation)
      .then((res) => {
        setNewAttendanceLocationPopup(false);

        NotificationManager.success("attendance location added");
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const deleteAttendanceLocationsAssetId = (assetid) => {
    setDeletedAttendanceAssetid(assetid);
    setDeletedAttendancePopup(!getDeletedAttendancePopup);
  };

  const deleteAttendanceAsset = () => {
    MainService.DeleteNewAttendanceLocation(getDeletedAttendanceAssetid)
      .then((res) => {
        setDeletedAttendancePopup(false);

        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateAttendanceLocationsAssetInfo = (assetinfo) => {
    setUpdateAttendanceLocationInfoAssetid(assetinfo.assetid);
    setUpdateAttendanceLocationInfoAssetfriendlyName(
      assetinfo.assetfriendlyname
    );
    setUpdateAttendanceLocationInfoUserid(assetinfo.userid);
    setUpdateAttendanceLocationInfoLatitude(assetinfo.latitude);
    setUpdateAttendanceLocationInfoLongitude(assetinfo.longitude);
    setUpdateAttendanceLocationInfoAltitude(assetinfo.altitude);
    setUpdateAttendanceLocationInfoDescription(assetinfo.description);
    setUpdateAttendaneLocationPopup(!showUpdateAttendaneLocationPopup);
  };

  const updateAttendanceLocationsHandler = () => {
    const updateAttendanceAsset = {
      assetid: getUpdateAttendanceLocationInfoAssetid,
      assetfriendlyname: getUpdateAttendanceLocationInfoAssetfriendlyName,
      latitude: getUpdateAttendanceLocationInfoLatitude,
      longitude: getUpdateAttendanceLocationInfoLongitude,
      altitude: getUpdateAttendanceLocationInfoAltitude,
      description: getUpdateAttendanceLocationInfoDescription,
    };
    const updateAttendance = JSON.stringify(updateAttendanceAsset);

    MainService.UpdateAttendanceLocation(updateAttendance)
      .then((res) => {
        setUpdateAttendaneLocationPopup(false);

        return NotificationManager.success("attendance location updated");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End Of Attendance Location Api's*/

  /* Health Records Api's */

  const healthRecordButtonClickHandler = (assetid, assetType) => {
    setMoreAnchorElForAsset(null);
    healthRecordsHandler(assetid);
    setAssetHelathRecordInfoPopup(!assetHelathRecordInfoPopup);
  };

  const healthRecordsHandler = (assetid) => {
    setIsLoading(true);
    MainService.getAssetHealthInfo(assetid)
      .then((res) => {
        console.log("asset health info:", res);
        setIsLoading(false);
        setAssetHealthRecord(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  /* End Of Health Records Api's */

  /* Nutrition Api's */

  const nutritionButtonClickHandler = (assetid, assetType) => {
    setMoreAnchorElForAsset(null);
    getNutritionHandler(assetid);

    setAssetNutritionInfoPopup(!assetNutritionInfoPopup);
  };

  const getNutritionHandler = (assetid) => {
    setIsLoading(true);
    MainService.getNutritionInfo(assetid)
      .then((res) => {
        setIsLoading(false);
        console.log("get asset nutri:", res);
        setNutritions(res);
      })
      .catch((err) => {
        return NotificationManager.err(err);
      });
    setAssetNutritionInfoPopup(!assetNutritionInfoPopup);
  };

  /* End Of Nutrition Api's */

  /* Parturition Api's */

  const parturitionButtonClickHandler = (assetid, assetType) => {
    setMoreAnchorElForAsset(null);
    getParturitionDetailsHandler(assetid);
    setNewParturitionAssetid(assetid);
    setAssetBackButtton(assetType);

    setAssetParturitionInfoPopup(!assetParturitionInfoPopup);
  };

  const getParturitionDetailsHandler = (assetid) => {
    setIsLoading(true);
    MainService.getParturationInfo(username, assetid)
      .then((res) => {
        setIsLoading(false);
        setParturitionDetail(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAssetParturitionInfoPopup(!assetParturitionInfoPopup);
  };

  /* End Of Parturition Api's */

  /*  Mortality Api's */

  const mortalityButtonClickHandler = (assetid, assetType) => {
    setMoreAnchorElForAsset(null);
    getMortalityDetailsHandler(assetid);
    setNewMortalityAssetid(assetid);
    setAssetBackButtton(assetType);

    setAssetMortalityInfoPopup(!assetMortalityInfoPopup);
  };

  const getMortalityDetailsHandler = (assetid) => {
    setIsLoading(true);
    MainService.getMortalityInfo(assetid)
      .then((res) => {
        setIsLoading(false);
        setMortalityDetails(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
    setAssetMortalityInfoPopup(!assetMortalityInfoPopup);
  };

  /* End Of Mortality Api's */

  const viewMappedAssets = (siteid, Type, sitename) => {
    setVewFarmUpdates(false);
    setMappedAssetsType(Type);
    setMappedAssetsSiteid(siteid);
    setMappedAssetsSiteName(sitename);
    setSelectedSiteId(siteid);
    setSelectedSiteName(sitename);
    setPage(0);
    setRedirectSiteId(siteid);
    setNewBirthSiteName(sitename);
    setNewBirthSiteid(siteid);
    setAssetIssueSiteId(siteid);
    if (Type === "List") {
      setViewallListSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setShowSiteRating(false);
      setMappedAssetShow(false);
      setAssetList(true);
      setAssetGrid(false);
      setAssetMap(false);

      setAttendanceLocation(false);
      setHealthRecords(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
    } else if (Type === "Grid") {
      setViewallListSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setShowSiteRating(false);
      setMappedAssetShow(false);
      setAssetList(false);
      setAssetGrid(true);
      setAssetMap(false);

      setAttendanceLocation(false);
      setHealthRecords(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
    } else if (Type === "Map") {
      setViewallListSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setShowSiteRating(false);
      setMappedAssetShow(false);
      setAssetList(false);
      setAssetGrid(false);
      setAssetMap(true);

      setAttendanceLocation(false);
      setHealthRecords(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
    }

    props.viewAssetsHandler(true);

    MainService.mappedLegacyAssets(siteid, username)
      .then((res) => {
        setMappedAssets(res.assets);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setMarkers([]);
  };

  /* Site Delete Functionality */

  const deleteSiteHandler = (siteid, sitename) => {
    handleMoreCloseForSite();
    setSelectedSiteId(siteid);
    setSelectedSiteName(sitename);
    MainService.mappedLegacyAssets(siteid, username)
      .then((res) => {
        if (res.assets.length > 0) {
          return NotificationManager.info(
            "Unmap all assets from the site, then only you can able to delete site."
          );
        } else {
          setSiteDeleteDialog(!siteDeleteDialog);
        }
      })
      .catch((err) => {});
  };
  const deletSiteSubmitClcik = () => {
    props.deletSiteSubmitClcik(selectedSiteId, selectedSiteName, username);
    setSiteDeleteDialog(!siteDeleteDialog);
  };
  /* End Of Site Delete Functionality */

  /*Site Ratings */

  const siteRatingsHandler = (siteId) => {
    handleMoreCloseForSite();
    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(true);
    setSiteRateSiteId(siteId);
    setVewFarmUpdates(false);
    MainService.siteRating(siteId)
      .then((res) => {
        setSiteRatings(res.ratings);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const addedSiteRatingHandler = (siteratesiteid) => {
    setDefaultSiteId(siteratesiteid);
    setSiteRatePopUp(!getSiteRatePopup);
  };

  const addorupdateSiteRating = () => {
    const siteRateData = {
      siteid: defaultSiteId,
      ratings: setupdateSiteRating,
    };
    const formData = JSON.stringify(siteRateData);
    MainService.addSiteRating(formData)
      .then((res) => {
        setSiteRatePopUp(!getSiteRatePopup);
        siteRatingsHandler(defaultSiteId);
        return NotificationManager.success("ratings added");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const closeSiteRatingModal = (
    <span
      onClick={() => {
        setSiteRatePopUp(!getSiteRatePopup);
      }}
    >
      X
    </span>
  );

  const siteRateAgreement = "RATINGS";
  const siteRateModel = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="siteId"> Id:</InputLabel>
        <Input
          disabled
          id="siteId"
          name="siteId"
          type="text"
          value={defaultSiteId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="siterating"> Ratings:</InputLabel>
        <Input
          id="siterating"
          name="siterating"
          type="text"
          value={setupdateSiteRating}
          onChange={(e) => getUpDateSiteRating(e.target.value)}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={addorupdateSiteRating}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setSiteRatePopUp(!getSiteRatePopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /*End Of Site Ratings */

  /* Create Node & Site */

  const getAllSitesList = () => {
    MainService.getAllSiteTypes(username)
      .then((res) => {
        if (res.sitetypes.length > 0) {
          setSiteData(res.sitetypes);
          setSiteType(res.sitetypes[0].sitetype);
        } else {
          setSiteData([]);
          setSiteType("");
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const handleSiteNameValueChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, ""); // Regex to remove whitespace characters
    setSiteName(sanitizedValue);
    setSiteNameError(value !== sanitizedValue ? true : false);
  };
  const siteCreateHandler = () => {
    if (
      getSiteType !== "" &&
      getSiteName !== "" &&
      getFarmerUserName !== "" &&
      assetVerticalFriendlyNameValue !== "" &&
      getPhone !== ""
    ) {
      var siteDetails = {
        farmerusername: getFarmerUserName,
        parentid: username,
        sitename: getSiteName,
        mailid: getFarmerUserName,
        phone: getPhone,
        //sitetype: props.siteType,
        sitetype: getSiteType,
        assetverticalid: selectedVerticalsId,
        assetverticalfriendlyname: assetVerticalFriendlyNameValue,
      };
      var siteBody = [];
      for (var property in siteDetails) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(siteDetails[property]);
        siteBody.push(encodedKey + "=" + encodedValue);
      }
      siteBody = siteBody.join("&");

      MainService.createSite(siteBody)
        .then((res) => {
          setViewallListSites(false);
          setVewFarmUpdates(false);
          setViewallGridSites(true);
          setViewallMapSites(false);
          setAssetMonitoringView(false);
          setShowSiteRating(false);
          setAssetList(false);
          setAssetGrid(false);
          setAssetMap(false);
          setAttendanceLocation(false);
          setHealthRecords(false);
          setShowDailyReports(false);
          setShowGropuNutritionView(false);
          setSiteAttendanceLocation(false);

          props.getAllSitesData();
          //updateRoleSubmit(res.siteid);
          return NotificationManager.success("Site Created");
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
    } else {
      return NotificationManager.error("Please fill all fields");
    }

    setSiteCreatePopUp(!getSitePopUp);
  };

  const closeSiteModal = (
    <span
      onClick={() => {
        setSiteCreatePopUp(!getSitePopUp);
      }}
    >
      X
    </span>
  );

  const siteCreationTitle = "Create New Site";
  const siteModelBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="farmerName">User Name:</InputLabel>
        <Input
          id="farmerName"
          name="farmerName"
          type="text"
          value={getFarmerUserName}
          onChange={(e) => {
            setFarmerUserName(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="mailid"> Mail ID:</InputLabel>
        <Input
          disabled
          id="mailid"
          name="mailid"
          type="text"
          value={getFarmerUserName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="phone">Phone Number</InputLabel>
        <Input
          id="phone"
          name="phone"
          type="text"
          value={getPhone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
        <Input
          id="sitename"
          name="sitename"
          type="text"
          value={getSiteName}
          onChange={(e) => handleSiteNameValueChange(e)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentid"> Parent Id:</InputLabel>
        <Input
          disabled
          id="parentid"
          name="parentid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>AssetVerticalId:</InputLabel>
        <Select
          id="assetVerticalID"
          name="assetVerticalID"
          type="text"
          value={selectedVerticalsId}
          onChange={(e) => {
            setSelectedVerticalsIdHandler(e);
          }}
        >
          {verticalsIdList.length > 0 ? (
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))
          ) : (
            <h4>Asset Vertical not found</h4>
          )}
        </Select>
      </FormControl>

      {props.siteType.length > 0 ? (
        <FormControl
          variant="standard"
          fullWidth={true}
          className={classes.formControl}
        >
          <InputLabel>Site Type</InputLabel>

          <Select
            id="SiteType"
            value={getSiteType}
            onChange={(event) => {
              setSiteType(event.target.value);
            }}
            label="Select SiteType"
          >
            {props?.siteType &&
              props?.siteType?.map((idObj, index) => (
                <MenuItem key={`${index}`} value={idObj}>
                  {idObj}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : (
        <FormControl
          variant="standard"
          fullWidth={true}
          className={classes.formControl}
        >
          <InputLabel>Site Type</InputLabel>

          <Select
            id="selecttype"
            value={getSiteType}
            onChange={(event) => setSiteType(event.target.value)}
            label="Selecttype"
          >
            {getSiteData &&
              getSiteData.map((idObj, index) => (
                <MenuItem key={`${index}`} value={idObj.sitetype}>
                  {idObj.sitetype}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={siteCreateHandler}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setSiteCreatePopUp(!getSitePopUp);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End Of Create Node & Site */

  /* Start of Site Creation with Node Id */

  const createSiteWithNodeId = () => {
    setShowCreateSitePopup(!showCreateSitePopup);
  };
  const siteAndNodeCreateHandler = () => {
    const verticalName = nodeVerticalName || assetVerticalFriendlyNameValue;
    const verticalId = nodeVerticalId || selectedVerticalsId;

    const details = {
      nodeid: getNodeId,
      parentid: username,
      sitename: getSiteName,
      mailid: nodeEmail,
      phone: nodeMobileNumber,
      sitetype: nodeType,
      assetverticalid: verticalId,
      assetverticalfriendlyname: verticalName,
    };

    const formBody = Object.entries(details)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    if (getSiteName) {
      MainService.createSiteWithNodeId(formBody)
        .then(() => {
          setVewFarmUpdates(false);
          setViewallListSites(false);
          setViewallGridSites(true);
          setViewallMapSites(false);
          setAssetMonitoringView(false);
          setShowSiteRating(false);
          setAssetList(false);
          setAssetGrid(false);
          setAssetMap(false);
          setAttendanceLocation(false);
          setHealthRecords(false);
          setShowDailyReports(false);
          setShowGropuNutritionView(false);
          setSiteAttendanceLocation(false);

          props.getAllSitesData();
          setShowCreateSiteDialog(!showCreateSiteDialog);
          NotificationManager.success("Site Created");
        })
        .catch((err) => {
          NotificationManager.error(err);
        });
    } else {
      NotificationManager.error("Please enter sitename");
    }
    props.getAllSitesData();
  };

  const createSiteHandler = () => {
    setSiteCreatePopUp(!getSitePopUp);
  };

  const submitForNode = () => {
    MainService.getNodeInfoBasedOnNodeId(getNodeId)
      .then((data) => {
        if (data && Object.keys(data).length > 0) {
          console.log("node info based on node id:", data);
          setNodeUserName(data.username);
          setNodePhoneNumber(data.phone);
          setNodeEmail(data.email);
          setNodeType(data.nodetype);
          setNodeVerticalName(data.assetverticalfriendlyname);
          setNodeVerticalId(data.assetverticalid);
        } else {
          return NotificationManager.error("invalid node id");
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });

    setShowCreateSitePopup(!showCreateSitePopup);
    setShowCreateSiteDialog(!showCreateSiteDialog);
    getAllSitesData("Grid");
  };
  const closeSiteWithNodeModal = (
    <span
      onClick={() => {
        setShowCreateSitePopup(!showCreateSitePopup);
      }}
    >
      X
    </span>
  );

  const closeSiteCreationWithNodeModal = (
    <span
      onClick={() => {
        setShowCreateSiteDialog(!showCreateSiteDialog);
      }}
    >
      X
    </span>
  );
  const siteAndNodeCreationTitle = "Create New Node & Site";

  const siteCreationWithNodeModelBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentId"> Node ID:</InputLabel>
        <Input
          id="nodeid"
          name="nodeid"
          type="text"
          value={getNodeId}
          onChange={(e) => setNodeId(e.target.value)}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={submitForNode}
        >
          Submit
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowCreateSitePopup(!showCreateSitePopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  const siteCreationModelBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentId"> Node ID:</InputLabel>
        <Input
          disabled
          id="parentId"
          name="parentId"
          type="text"
          value={getNodeId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="farmerName">User Name:</InputLabel>
        <Input
          disabled
          id="farmerName"
          name="farmerName"
          type="text"
          value={nodeUserName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="mailid"> Mail ID:</InputLabel>
        <Input
          disabled
          id="mailid"
          name="mailid"
          type="text"
          value={nodeEmail}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="phone">Phone Number</InputLabel>
        <Input
          disabled
          id="phone"
          name="phone"
          type="text"
          value={nodeMobileNumber}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
        <Input
          id="sitename"
          name="sitename"
          type="text"
          value={getSiteName}
          onChange={(e) => handleSiteNameValueChange(e)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Parent Id:</InputLabel>
        <Input
          disabled
          id="sitename"
          name="sitename"
          type="text"
          value={username}
        />
      </FormControl>
      {nodeVerticalName !== "" &&
      nodeVerticalName !== null &&
      nodeVerticalId !== "" &&
      nodeVerticalId !== null ? (
        <FormControl
          variant="standard"
          fullWidth={true}
          className={classes.formControl}
        >
          <InputLabel>AssetVerticalId:</InputLabel>
          <Select
            id="assetVerticalID"
            name="assetVerticalID"
            type="text"
            value={nodeVerticalName}
          >
            <MenuItem value={nodeVerticalName}>{nodeVerticalName}</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <FormControl
          variant="standard"
          fullWidth={true}
          className={classes.formControl}
        >
          <InputLabel>AssetVerticalId:</InputLabel>
          <Select
            id="assetVerticalID"
            name="assetVerticalID"
            type="text"
            value={selectedVerticalsId}
            onChange={(e) => {
              setSelectedVerticalsIdHandler(e);
            }}
          >
            {verticalsIdList.length > 0 ? (
              verticalsIdList.map((idObj, index) => (
                <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                  {idObj.friendlyName}
                </MenuItem>
              ))
            ) : (
              <h4>Asset Vertical not found</h4>
            )}
          </Select>
        </FormControl>
      )}

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="siteType">Site Type</InputLabel>
        <Input
          disabled
          id="siteType"
          name="siteType"
          type="text"
          value={nodeType}
        />
      </FormControl>

      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={siteAndNodeCreateHandler}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowCreateSiteDialog(!showCreateSiteDialog);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End of Site Creation with Node Id */

  /* Site Info */
  const siteInfoPopUpHandler = (infositeid) => {
    handleMoreCloseForSite();
    MainService.siteInfo(infositeid)
      .then((res) => {
        const isObjectEmpty = (res) => {
          return _.isEmpty(res);
        };
        setIsObjectEmpty(isObjectEmpty(res));
        if (isObjectEmpty(res) === false) {
          setIsLoading(false);
          setSiteInfoObj(res);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    getInfoSiteId(infositeid);
    if (generateQrClick === false) {
      getSiteInfoPopUp(true);
    }
  };

  const closeSiteInfoModal = (
    <span
      onClick={() => {
        getSiteInfoPopUp(!setSiteInfoPopup);
      }}
    >
      X
    </span>
  );

  const siteInfoHeader = "SITE DETAILS";
  const SiteInfoBody = isObjectEmpty ? (
    isLoading ? (
      <div className="cardStyleForDashboardItems">
        <CircularProgress />
      </div>
    ) : (
      <h4>Data Not Available</h4>
    )
  ) : isLoading ? (
    <div className="cardStyleForDashboardItems">
      <CircularProgress />
    </div>
  ) : (
    <div>
      <p>
        <b>Site Name:</b>
        {siteInfoObj.sitename}
      </p>
      <p>
        <b>Site Status:</b>
        {siteInfoObj.sitestatus}
      </p>
      <p>
        <b>AssetverticalId:</b>
        {siteInfoObj.assetverticalid}
      </p>
      <p>
        <b>Displayname:</b>
        {siteInfoObj.displayname}
      </p>
      <p>
        <b>Designation:</b>
        {siteInfoObj.designation}
      </p>
      <p>
        <b>Industry Type:</b>
        {siteInfoObj.industrytype}
      </p>
      <p>
        <b>Contact Addr:</b>
        {siteInfoObj.contactaddr}
      </p>
      <p>
        <b>City Name:</b>
        {siteInfoObj.cityname}
      </p>
      <p>
        <b>City Code:</b>
        {siteInfoObj.citycode}
      </p>
      <p>
        <b>Country:</b>
        {siteInfoObj.country}
      </p>
      <p>
        <b>Countrycode:</b>
        {siteInfoObj.countrycode}
      </p>
      <p>
        <b>Street Name:</b>
        {siteInfoObj.streetname}
      </p>
      <p>
        <b>Street Number:</b>
        {siteInfoObj.streetnumber}
      </p>
      <p>
        <b>Area:</b>
        {siteInfoObj.area}
      </p>
      <p>
        <b>Region:</b>
        {siteInfoObj.region}
      </p>
      <p>
        <b>Postal Code:</b>
        {siteInfoObj.postalcode}
      </p>
      <p>
        <b>Subdivision:</b>
        {siteInfoObj.subdivision}
      </p>
      <p>
        <b>Locality:</b>
        {siteInfoObj.locality}
      </p>
      <p>
        <b>Latitude:</b>
        {siteInfoObj.latitude}
      </p>
      <p>
        <b>Longitude:</b>
        {siteInfoObj.longitude}
      </p>
      <p>
        <b>Altitude:</b>
        {siteInfoObj.altitude}
      </p>
      <p>
        <b>Timezone:</b>
        {siteInfoObj.timezone}
      </p>
      <br />
      <Button
        variant="outlined"
        style={{ left: "45%" }}
        onClick={() => {
          getSiteInfoPopUp(!setSiteInfoPopup);
        }}
      >
        Cancel
      </Button>
    </div>
  );
  /* End of Site Info */

  /* Node Info */

  const nodeInfoPopUpHandler = (infositeid) => {
    handleMoreCloseForSite();
    MainService.getNodeInfoBasedOnNodeId(infositeid)
      .then((res) => {
        const isObjectEmpty = (res) => {
          return _.isEmpty(res);
        };
        setIsObjectEmpty(isObjectEmpty(res));
        if (isObjectEmpty(res) === false) {
          setIsLoading(false);
          console.log("node info:", res);
          setNodeInfoObj(res);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    getInfoSiteId(infositeid);
    if (generateQrClick === false) {
      setNodeInfoPopUp(true);
    }
  };

  const closeNodeInfoModal = (
    <span
      onClick={() => {
        setNodeInfoPopUp(!getNodeInfoPopup);
      }}
    >
      X
    </span>
  );

  const nodeInfoHeader = "Node Details";
  const NodeInfoBody = isObjectEmpty ? (
    isLoading ? (
      <div className="cardStyleForDashboardItems">
        <CircularProgress />
      </div>
    ) : (
      <h4>Data Not Available</h4>
    )
  ) : isLoading ? (
    <div className="cardStyleForDashboardItems">
      <CircularProgress />
    </div>
  ) : (
    <div>
      <p>
        <b>User Name:</b>
        {nodeInfoObj.username}
      </p>
      <p>
        <b>Node Id:</b>
        {nodeInfoObj.nodeid}
      </p>
      <p>
        <b>AssetverticalId:</b>
        {nodeInfoObj.assetverticalid}
      </p>
      <p>
        <b>Assetvertical Friendly Name:</b>
        {nodeInfoObj.assetverticalfriendlyname}
      </p>
      <p>
        <b>Account Type:</b>
        {nodeInfoObj.accounttype}
      </p>
      <p>
        <b>Industry Type:</b>
        {nodeInfoObj.industrytype}
      </p>
      <p>
        <b>User Type:</b>
        {nodeInfoObj.usertype}
      </p>
      <p>
        <b>Asset Type:</b>
        {nodeInfoObj.assettype}
      </p>
      <p>
        <b>Grazing Type:</b>
        {nodeInfoObj.grazingtype}
      </p>
      <p>
        <b>Feed Type:</b>
        {nodeInfoObj.feedtype}
      </p>
      <p>
        <b>Number of Assets:</b>
        {nodeInfoObj.numberofassets}
      </p>
      <p>
        <b>Number of Kids:</b>
        {nodeInfoObj.numberofkids}
      </p>
      <p>
        <b>Male Gender Number:</b>
        {nodeInfoObj.malegendernumber}
      </p>
      <p>
        <b>Female Gender Number:</b>
        {nodeInfoObj.femalegendernumber}
      </p>
      <p>
        <b>Overall Age:</b>
        {nodeInfoObj.overallage}
      </p>

      <p>
        <b>Farming Experience:</b>
        {nodeInfoObj.farmingexperience}
      </p>
      <p>
        <b>Node Status:</b>
        {nodeInfoObj.nodestatus}
      </p>
      <p>
        <b>Content Url:</b>
        {nodeInfoObj.contenturl}
      </p>
      <p>
        <b>Breed:</b>
        {nodeInfoObj.breed}
      </p>
      <p>
        <b>Address:</b>
        {nodeInfoObj.address}
      </p>
      <p>
        <b>First Name:</b>
        {nodeInfoObj.firstname}
      </p>
      <p>
        <b>Last Name:</b>
        {nodeInfoObj.lastname}
      </p>
      <p>
        <b>Email:</b>
        {nodeInfoObj.email}
      </p>
      <p>
        <b>Phone:</b>
        {nodeInfoObj.phone}
      </p>
      <p>
        <b>First Name:</b>
        {nodeInfoObj.firstname}
      </p>
      <p>
        <b>City Name:</b>
        {nodeInfoObj.city}
      </p>
      <p>
        <b>State:</b>
        {nodeInfoObj.state}
      </p>
      <p>
        <b>District:</b>
        {nodeInfoObj.district}
      </p>
      <p>
        <b>Pincode:</b>
        {nodeInfoObj.pincode}
      </p>
      <p>
        <b>Latitude:</b>
        {nodeInfoObj.latitude}
      </p>
      <p>
        <b>Longitude:</b>
        {nodeInfoObj.longitude}
      </p>
      <p>
        <b>Timestamp:</b>
        {nodeInfoObj.timestamp}
      </p>
      <br />
      <Button
        variant="outlined"
        style={{ left: "45%" }}
        onClick={() => {
          setNodeInfoPopUp(!getNodeInfoPopup);
        }}
      >
        Cancel
      </Button>
    </div>
  );
  /* End of Node Info */

  /* Start of Site Update */
  const updateSiteUpdateClick = (infositeid) => {
    handleMoreCloseForSite();
    getInfoSiteId(infositeid);
    MainService.siteInfo(infositeid)
      .then((res) => {
        console.log("site info beforeupdate:", res);
        setSiteInfoName(res.sitename);
        setUpdateSiteType(res.subdivision);
        setSiteInfoExistingSiteType(res.subdivision);
        setWebsiteUrl(res.designation);
        setLocAddress(res.contactaddr);
        setSiteInfoAssetVerticalId(res.assetverticalid);
        setIndustryType(res.industrytype);
        setStreetName(res.streetname);
        setStreetNumber(res.streetnumber);
        setLocality(res.locality);
        setArea(res.area);
        setRegion(res.region);
        setCountry(res.country);
        setPostalCode(res.postalcode);
        setLatitude(res.latitude);
        setLongitude(res.longitude);
        setAltitude(res.altitude);
        setTimeZone(res.timezone);
        setCityCode(res.citycode);
        setCityName(res.cityname);
        setCountryCode(res.countrycode);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSiteUpdatePopup(!getSiteUpdatePopup);
  };

  const updateSiteUpdateHandler = () => {
    const siteUpdateInfo = {
      phone: getPhoneNumber,
      email: getEmail,
      sitetype: getUpdateSiteType,
      websiteurl: getWebsiteUrl,
      locationaddress: getLocAddress?.replace(/\n/g, ""),
      streetname: getStreetName,
      streetnumber: getStreetNumber,
      locality: getLocality,
      area: getArea,
      region: getRegion,
      country: getCountry,
      postalcode: getPostalCode,
      latitude: getLatitude,
      longitude: getLongitude,
      altitude: getAltitude,
      timezone: getTimeZone,
      citycode: getCityCode,
      cityname: getCityName,
      countrycode: getCountryCode,
      stdcode: getStdCode,
    };

    const formData = JSON.stringify(siteUpdateInfo);

    MainService.updateSiteInfo(infoSiteId, formData)
      .then((res) => {
        props.getAllSitesData();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setSiteUpdatePopup(!getSiteUpdatePopup);
  };

  const closeSiteUpdateModal = (
    <span
      onClick={() => {
        setSiteUpdatePopup(!getSiteUpdatePopup);
      }}
    >
      X
    </span>
  );

  const siteUpdateHeader = "SITE UPDATE DETAILS";
  const SiteUpdateBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentId"> Admin ID:</InputLabel>
        <Input
          disabled
          id="parentId"
          name="parentId"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="siteid"> Site Id:</InputLabel>
        <Input
          disabled
          id="siteid"
          name="siteid"
          type="text"
          value={infoSiteId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
        <Input
          disabled
          id="sitename"
          name="sitename"
          type="text"
          value={getSiteInfoName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="phone"> Phone Number:</InputLabel>
        <Input
          id="phone"
          name="phone"
          type="number"
          value={getPhoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="email"> Email:</InputLabel>
        <Input
          id="email"
          name="email"
          type="email"
          value={getEmail}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitetype"> Existing Site Type:</InputLabel>
        <Input
          disabled
          id="sitetype"
          name="sitetype"
          type="text"
          value={getSiteInfoExistingSiteType}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Update Site Type</InputLabel>
        <Select
          id="selectedModel"
          value={getUpdateSiteType}
          onChange={(event) => setUpdateSiteType(event.target.value)}
          label="Select Model"
        >
          {getSiteData &&
            getSiteData.map((idObj, index) => (
              <MenuItem key={`${index}`} value={idObj.sitetype}>
                {idObj.sitetype}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="weburl"> Website URL:</InputLabel>
        <Input
          id="weburl"
          name="weburl"
          type="text"
          value={getWebsiteUrl}
          onChange={(e) => setWebsiteUrl(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="locaddress"> Location Address:</InputLabel>
        <Input
          id="locaddress"
          name="locaddress"
          type="text"
          value={getLocAddress}
          onChange={(e) => setLocAddress(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetvi"> Asset Vertical Id:</InputLabel>
        <Input
          disabled
          id="assetvi"
          name="assetvi"
          type="text"
          value={getSiteInfoAssetVerticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="industrytype">
          {" "}
          Industry Type (Asset Vertical Friendly Name):
        </InputLabel>
        <Input
          id="industrytype"
          name="industrytype"
          type="text"
          value={getIndustryType}
          onChange={(e) => setIndustryType(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="streetname"> Street Name:</InputLabel>
        <Input
          id="streetname"
          name="streetname"
          type="text"
          value={getStreetName}
          onChange={(e) => setStreetName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="streetnumber"> Street Number:</InputLabel>
        <Input
          id="streetnumber"
          name="streetnumber"
          type="text"
          value={getStreetNumber}
          onChange={(e) => setStreetNumber(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="locality"> Locality:</InputLabel>
        <Input
          id="locality"
          name="locality"
          type="text"
          value={getLocality}
          onChange={(e) => setLocality(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="area"> Area (State):</InputLabel>
        <Input
          id="area"
          name="area"
          type="text"
          value={getArea}
          onChange={(e) => setArea(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="region"> Region:</InputLabel>
        <Input
          id="region"
          name="region"
          type="text"
          value={getRegion}
          onChange={(e) => setRegion(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="country"> Country:</InputLabel>
        <Input
          id="country"
          name="country"
          type="text"
          value={getCountry}
          onChange={(e) => setCountry(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="pstcode"> Postal Code(Zip):</InputLabel>
        <Input
          id="pstcode"
          name="pstcode"
          type="text"
          value={getPostalCode}
          onChange={(e) => setPostalCode(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="latitude"> Latitude:</InputLabel>
        <Input
          id="latitude"
          name="latitude"
          type="text"
          value={getLatitude}
          onChange={(e) => setLatitude(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="longitude"> Longitude:</InputLabel>
        <Input
          id="longitude"
          name="longitude"
          type="text"
          value={getLongitude}
          onChange={(e) => setLongitude(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="altitude"> Altitude:</InputLabel>
        <Input
          id="altitude"
          name="altitude"
          type="text"
          value={getAltitude}
          onChange={(e) => setAltitude(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="timezone"> Time Zone:</InputLabel>
        <Input
          id="timezone"
          name="timezone"
          type="text"
          value={getTimeZone}
          onChange={(e) => setTimeZone(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="citycode"> City Code:</InputLabel>
        <Input
          id="citycode"
          name="citycode"
          type="text"
          value={getCityCode}
          onChange={(e) => setCityCode(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="cityname"> City Name:</InputLabel>
        <Input
          id="cityname"
          name="cityname"
          type="text"
          value={getCityName}
          onChange={(e) => setCityName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="countrycode"> Country Code:</InputLabel>
        <Input
          id="countrycode"
          name="countrycode"
          type="text"
          value={getCountryCode}
          onChange={(e) => setCountryCode(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="stdcode"> Std Code:</InputLabel>
        <Input
          id="stdcode"
          name="stdcode"
          type="text"
          value={getStdCode}
          onChange={(e) => setStdCode(e.target.value)}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateSiteUpdateHandler}
        >
          Update
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setSiteUpdatePopup(!getSiteUpdatePopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* End Of Site update Info */
  /*************** Site Management Map *************/
  const SiteManagementGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={props.sites.index}
        defaultZoom={4}
        defaultCenter={marker_position}
        center={marker_position}
      >
        {props.sites.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <button
                  type="button"
                  onClick={() => {
                    viewMappedAssets(value.siteid, "Map", value.sitename);
                  }}
                >
                  <div>{value.sitename}</div>
                </button>
              </InfoWindow>
            </Marker>
          ) : (
            ""
          );
        })}
      </GoogleMap>
    );
  });

  const AssetsGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={getMappedAssets.index}
        defaultZoom={4}
        defaultCenter={marker_position}
        center={marker_position}
      >
        {getMappedAssets.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <div>{value.assetType}</div>
              </InfoWindow>
            </Marker>
          ) : (
            ""
          );
        })}
      </GoogleMap>
    );
  });
  /*************** End Of Site Management Map *************/

  /****************** Map, UnMap, Update, Delete Asset Api's **********************/

  const addAssetToSiteMappingHandler = () => {
    const siteIdData = {
      siteid: mapSiteId,
    };
    const siteid = JSON.stringify(siteIdData);
    MainService.createMapping(username, mapAssetId, mapCategoryType, siteid)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setMapPopUp(!mapPopUp);
    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setVewFarmUpdates(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setAttendanceLocation(false);
    setHealthRecords(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
  };

  const unMapAssetHandler = (assetInfo) => {
    setMoreAnchorElForAsset(null);
    const siteIdData = {
      siteid: assetInfo.siteId,
    };
    const siteidstr = JSON.stringify(siteIdData);
    MainService.createUnMapping(
      assetInfo.assetId,
      assetInfo.category,
      siteidstr,
      username
    )
      .then((res) => {
        viewMappedAssets(assetInfo.siteId, "Grid", getMappedAssetsSiteName);
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const addedAssetDeleteHandler = (assetid) => {
    setMoreAnchorElForAsset(null);
    MainService.getLegacyAssetInfo(username, assetid)
      .then((res) => {
        console.log("Asset delete info", res);
        setMappedAssetDeleteAssetid(res.assetId);
        setMappedAssetDeleteAssetType(res.assetType);
        setMappedAssetDeleteAssetModelid(res.modelId);
        setMappedAssetDeleteAssetVerticalid(res.assetverticalId);
        setMappedAssetDeleteAssetCategory(res.category);
        setSelectedSiteId(res.siteid);
        setSelectedSiteName(res.sitename);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setShowDeletePopup(!getShowDeletePopup);
  };

  const assetDeleteHandler = () => {
    if (
      getMappedAssetDeleteAssetid !== "" &&
      getMappedAssetDeleteAssetType !== "" &&
      getMappedAssetDeleteAssetModelid !== "" &&
      getMappedAssetDeleteAssetVerticalid !== "" &&
      getMappedAssetDeleteAssetCategory !== "" &&
      username
    ) {
      MainService.assetDelete(
        getMappedAssetDeleteAssetid,
        getMappedAssetDeleteAssetType,
        getMappedAssetDeleteAssetModelid,
        getMappedAssetDeleteAssetVerticalid,
        getMappedAssetDeleteAssetCategory,
        username
      )
        .then((res) => {
          setShowDeletePopup(false);
          viewMappedAssets(selectedSiteId, "Grid", selectedSiteName);
          return NotificationManager.success("successfully deleted");
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
    }

    setShowDeletePopup(false);
  };

  /* start of Asset status update */
  const updateAssetStatusHandler = () => {
    if (updateAssetStatus === "") {
      return NotificationManager.error("please change the asset status");
    } else {
      if (
        updateAssetStatus === "null" ||
        updateAssetStatus === "readyForOnboard"
      ) {
        if (whiteLabelVal !== "") {
          updateWhitelabelCostHandler();
        } else {
          return NotificationManager.error(
            "Please provide the Whitelabel cost"
          );
        }
      } else if (
        updateAssetStatus === "leased" ||
        updateAssetStatus === "prematureSale" ||
        updateAssetStatus === "verified"
      ) {
        if (sellingCostVal !== "") {
          updateSellingCostHandler();
        } else {
          return NotificationManager.error("Please provide the Selling cost");
        }
      } else {
        updateWhitelabelCostHandler();
      }

      setUpdateAssetStatus("");
    }
  };
  const closeUpdateAssetStatusModel = (
    <span
      onClick={() => {
        setLegacyUpdate(!legacyUpdate);
      }}
    >
      X
    </span>
  );
  const updateLegacyAsset = "Update Asset Status";
  const updateLegacyAssetData = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="solutionid">SOLUTION ID:</InputLabel>
        <Input
          disabled
          id="solutionid"
          name="solutionid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetverticalid">ASSET VERTICAL ID:</InputLabel>
        <Input
          disabled
          id="assetverticalid"
          name="assetverticalid"
          type="text"
          value={assetVeriticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assettype">ASSET TYPE:</InputLabel>
        <Input
          disabled
          id="assettype"
          name="assettype"
          type="text"
          value={assetTypeVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetid">ASSET ID:</InputLabel>
        <Input
          disabled
          id="assetid"
          name="assetid"
          type="text"
          value={assetIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetfname">ASSET FRIENDLY NAME:</InputLabel>
        <Input
          disabled
          id="assetfname"
          name="assetfname"
          type="text"
          value={assetFriName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelid">MODEL ID:</InputLabel>
        <Input
          disabled
          id="modelid"
          name="modelid"
          type="text"
          value={modelIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="category">CATEGORY:</InputLabel>
        <Input
          disabled
          id="category"
          name="category"
          type="text"
          value={categoryVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="manufacturerid">MANUFACTURER ID:</InputLabel>
        <Input
          disabled
          id="manufacturerid"
          name="manufacturerid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">UDN:</InputLabel>
        <Input disabled id="udn" name="udn" type="text" value={udnVal} />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">ACQUIRE COST:</InputLabel>
        <Input
          disabled
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={acquireCostValue}
        />
      </FormControl>
      {updateAssetStatus === "readyForOnboard" ||
      updateAssetStatus === "null" ? (
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
          <Input
            id="whitecost"
            name="whitecost"
            type="text"
            value={whiteLabelVal}
            onChange={(e) => {
              setWhiteLabelVal(e.target.value);
            }}
          />
        </FormControl>
      ) : (
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
          <Input
            disabled
            id="whitecost"
            name="whitecost"
            type="text"
            value={whiteLabelVal}
          />
        </FormControl>
      )}
      {updateAssetStatus === "prematureSale" ||
      updateAssetStatus === "leased" ||
      updateAssetStatus === "verified" ? (
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
          <Input
            id="sellingcost"
            name="sellingcost"
            type="text"
            value={sellingCostVal}
            onChange={(e) => {
              setSellingCostVal(e.target.value);
            }}
          />
        </FormControl>
      ) : (
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
          <Input
            disabled
            id="sellingcost"
            name="sellingcost"
            type="text"
            value={sellingCostVal}
          />
        </FormControl>
      )}

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetstatus">EXISTING ASSET STATUS:</InputLabel>
        <Input
          disabled
          id="assetstatus"
          name="assetstatus"
          type="text"
          value={exAssetStatus}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>UPDATE ASSET STATUS</InputLabel>
        <Select
          id="selectedModel"
          value={updateAssetStatus}
          onChange={(event) => setUpdateAssetStatus(event.target.value)}
          label="Select Model"
        >
          <MenuItem key="readyForOnboard" value="readyForOnboard">
            ReadyForOnboard --- initial state(aquired)
          </MenuItem>
          <MenuItem key="null" value="null">
            Null --- Ready for whitelabel(onboarded)
          </MenuItem>
          <MenuItem key="whitelabelled" value="whitelabelled">
            Whitelabelled
          </MenuItem>
          <MenuItem key="prematureSale" value="prematureSale">
            Premature Sale
          </MenuItem>
          <MenuItem key="verified" value="verified">
            Verified
          </MenuItem>
          <MenuItem key="sold" value="sold">
            Sold
          </MenuItem>
          <MenuItem key="leased" value="leased">
            Leased
          </MenuItem>
          <MenuItem key="lost" value="lost">
            Lost
          </MenuItem>
          <MenuItem key="damaged" value="damaged">
            Damaged
          </MenuItem>
          <MenuItem key="under-maintenance" value="under-maintenance">
            Under maintenance
          </MenuItem>
          <MenuItem key="deboard" value="deboard">
            Deboard
          </MenuItem>
        </Select>
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateAssetStatusHandler}
        >
          Update Status
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setLegacyUpdate(!legacyUpdate);
          setUpdateAssetStatus("");
        }}
        style={{
          marginBottom: "50px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  const updateLegacyAssetHandler = (assetid) => {
    setMoreAnchorElForAsset(null);
    MainService.getLegacyAssetInfo(username, assetid)
      .then((res) => {
        setAssetVerticalId(res.assetverticalId);
        setAssetTypeVal(res.assetType);
        setAssetIdVal(res.assetId);
        setAssetFriName(res.friendlyName);
        setModelIdVal(res.modelId);
        setCatgeryVal(res.category);
        setUdnVal(res.udn);
        setWhiteLabelVal(res.whitelabelcost);
        setSellingCostVal(res.sellingcost);
        setExAssetStatus(res.assetstatus);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setLegacyUpdate(!legacyUpdate);
  };

  const updateSellingCostHandler = () => {
    const updateAssetSellingCostData = {
      assetid: assetIdVal,
      assetfriendlyname: assetFriName,
      parentid: parentId,
      userid: username,
      sellingcost: sellingCostVal,
    };
    const data = JSON.stringify(updateAssetSellingCostData);

    MainService.updateSellingCost(updateAssetSellingCostData)
      .then((res) => {
        setLegacyUpdate(!legacyUpdate);
        viewMappedAssets(
          getMappedAssetsSiteid,
          "Grid",
          getMappedAssetsSiteName
        );
        return NotificationManager.success(res);
      })
      .catch((e) => {
        setUpdateAssetStatus(null);
        return NotificationManager.error(e);
      });
    const statusData = {
      status: updateAssetStatus,
    };
    const statusDataStr = JSON.stringify(statusData);
    MainService.assetStatusUpdate(assetIdVal, statusDataStr)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const updateWhitelabelCostHandler = () => {
    const updateAssetSellignCostData = {
      assetid: assetIdVal,
      whitelabelcost: whiteLabelVal,
      assetstatus: updateAssetStatus,
    };
    const data = JSON.stringify(updateAssetSellignCostData);

    MainService.updateWhitelabelCost(updateAssetSellignCostData)
      .then((res) => {
        setLegacyUpdate(!legacyUpdate);
        viewMappedAssets(
          getMappedAssetsSiteid,
          "Grid",
          getMappedAssetsSiteName
        );
        return NotificationManager.success(res);
      })
      .catch((e) => {
        setUpdateAssetStatus();
        return NotificationManager.error(e);
      });
  };
  /* End of Asset status Update */
  /* Start of Asset info update */

  /* Asset Info */
  const getAssetInfo = (assetid) => {
    MainService.getAssetInfo(username, assetid)
      .then((res) => {
        setAssetSiteIdValue(res.siteid);
        setAssetSiteNameValue(res.sitename);
        setAssetVerticalId(res.assetverticalId);
        setAssetTypeVal(res.assetType);
        setAssetIdVal(res.assetId);
        setAssetFriName(res.friendlyName);
        setModelIdVal(res.modelId);
        setModelNameVal(res.modelName);
        setModelTypeVal(res.modelType);
        setModelUrlVal(res.modelUrl);
        setCatgeryVal(res.category);
        setUdnVal(res.udn);
        setUpcValue(res.upc);
        setManufacturerUrlValue(res.manufacturerURL);
        setSerialNoValue(res.serialNumber);
        setLatitudeValue(res.latitude);
        setLongitudeValue(res.longitude);
        setAltitudeValue(res.altitude);
        setAcquireCostValue(res.acquirecost);
        setWhiteLabelVal(res.whitelabelcost);
        setSellingCostVal(res.sellingcost);
        setLotIdValue(res.lotid);
        setLotNameValue(res.lotname);
        setFatherBreedValue(res.fatherbreed);
        setMotherBreedValue(res.motherbreed);
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };
  const udateAssetInfoClick = (assetid) => {
    setMoreAnchorElForAsset(null);
    getAssetInfo(assetid);
    setAssetInfoUpdatePopup(!assetInfoUpdatePopup);
  };
  const updateAssetInfoSubmitClick = () => {
    const updateAssetInfoData = {
      friendlyname: assetFriName,
      udn: udnVal,
      upc: upcValue,
      whitelabelcost: whiteLabelVal,
      sellingcost: sellingCostVal,
      acquirecost: acquireCostValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      manufacturerurl: manufacturerUrlValue,
      serialno: serialNoValue,
      motherbreed: motherBreedValue,
      fatherbreed: fatherBreedValue,
      description: descriptionValue,
      lotid: lotIdValue,
      lotname: lotNameValue,
      siteid: assetSiteIdValue,
      sitename: assetSiteNameValue,
      motherbreed: motherBreedValue,
      fatherbreed: fatherBreedValue,
      category: categoryVal,
      assettype: assetTypeVal,
      assetverticalid: assetVeriticalId,
      assetdomain: assetDomainValue,
      modelid: modelIdVal,
      modelname: modelNameVal,
      modeltype: modelTypeVal,
      modelurl: modelUrlVal,
    };
    const data = JSON.stringify(updateAssetInfoData);

    MainService.updateAssetInfo(data, assetIdVal)
      .then((res) => {
        viewMappedAssets(
          getMappedAssetsSiteid,
          "Grid",
          getMappedAssetsSiteName
        );
        return NotificationManager.success(res);
      })
      .catch((e) => {
        setUpdateAssetStatus(null);
        return NotificationManager.error(e);
      });

    setAssetVerticalId("");
    setAssetTypeVal("");
    setAssetIdVal("");
    setAssetFriName("");
    setModelIdVal("");
    setCatgeryVal("");
    setUdnVal("");
    setUpcValue("");
    setManufacturerUrlValue("");
    setSerialNoValue("");
    setLatitudeValue("");
    setLongitudeValue("");
    setAltitudeValue("");
    setAcquireCostValue("");
    setWhiteLabelVal("");
    setSellingCostVal("");
    setAgeVal("");
    setWeightVal("");
    setGenderVal("");
    setLotIdValue("");
    setLotNameValue("");
    setFatherBreedValue("");
    setMotherBreedValue("");
    setModelNameVal("");
    setModelTypeVal("");
    setModelUrlVal("");
    setAssetSiteIdValue("");
    setAssetSiteNameValue("");

    setAssetInfoUpdatePopup(!assetInfoUpdatePopup);
  };
  const closeUpdateAssetInfoModel = (
    <span
      onClick={() => {
        setAssetInfoUpdatePopup(!assetInfoUpdatePopup);
      }}
    >
      X
    </span>
  );
  const updateLegacyAssetInfo = "Update Asset Information";
  const updateLegacyAssetInfoData = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="solutionid">SOLUTION ID:</InputLabel>
        <Input
          disabled
          id="solutionid"
          name="solutionid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetverticalid">ASSET VERTICAL ID:</InputLabel>
        <Input
          disabled
          id="assetverticalid"
          name="assetverticalid"
          type="text"
          value={assetVeriticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="category">CATEGORY:</InputLabel>
        <Input
          disabled
          id="category"
          name="category"
          type="text"
          value={categoryVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assettype">ASSET TYPE:</InputLabel>
        <Input
          disabled
          id="assettype"
          name="assettype"
          type="text"
          value={assetTypeVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelid">MODEL ID:</InputLabel>
        <Input
          disabled
          id="modelid"
          name="modelid"
          type="text"
          value={modelIdVal}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetid">ASSET ID:</InputLabel>
        <Input
          disabled
          id="assetid"
          name="assetid"
          type="text"
          value={assetIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetfname">ASSET FRIENDLY NAME:</InputLabel>
        <Input
          id="assetfname"
          name="assetfname"
          type="text"
          value={assetFriName}
          onChange={(e) => {
            setAssetFriName(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">UDN:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={udnVal}
          onChange={(e) => {
            setUdnVal(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">UPC:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={upcValue}
          onChange={(e) => {
            setUpcValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">LOT ID:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={lotIdValue}
          onChange={(e) => {
            setLotIdValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">LOT NAME:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={lotNameValue}
          onChange={(e) => {
            setLotNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">MANUFACTURER URL:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={manufacturerUrlValue}
          onChange={(e) => {
            setManufacturerUrlValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">SERIAL NO:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={serialNoValue}
          onChange={(e) => {
            setSerialNoValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost1">ACQUIRE COST:</InputLabel>
        <Input
          id="acquireCost1"
          name="acquireCost1"
          type="number"
          value={acquireCostValue}
          onChange={(e) => {
            setAcquireCostValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
        <Input
          id="whitecost"
          name="whitecost"
          type="text"
          value={whiteLabelVal}
          onChange={(e) => {
            setWhiteLabelVal(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
        <Input
          id="sellingcost"
          name="sellingcost"
          type="text"
          value={sellingCostVal}
          onChange={(e) => {
            setSellingCostVal(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">LATITUDE:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={latitudeValue}
          onChange={(e) => {
            setLatitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">LONGITUDE:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={longitudeValue}
          onChange={(e) => {
            setLongitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">ALTITUDE:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={altitudeValue}
          onChange={(e) => {
            setAltitudeValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">MOTHER BREED:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={motherBreedValue}
          onChange={(e) => {
            setMotherBreedValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">FATHER BREED:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={fatherBreedValue}
          onChange={(e) => {
            setFatherBreedValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">DESCRIPTION:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateAssetInfoSubmitClick}
        >
          Update Asset
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setAssetInfoUpdatePopup(!assetInfoUpdatePopup);
        }}
        style={{
          marginBottom: "50px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End of Asset info upadte */

  /****************** End Of Map, UnMap, Update, Delete Asset Api's **********************/

  /*Create Asset */
  const cretaeAssetModel = () => {
    setCreateAssetPopup(!getCreateAssetPopup);
    getAllAssetsBasedonModel();
  };

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList(window.AppConfigData.EnterpriseId)
      .then((data) => {
        if (data.length > 0) {
          var filteredAssetVerticals = data.filter((row) => {
            return row.friendlyName === "ANIMAL";
          });
          setVerticalsIdList(filteredAssetVerticals);
          setSelectedVerticalsId(filteredAssetVerticals[0].assetVerticalId);
          setAssetVerticalFriendlyNameValue(
            filteredAssetVerticals[0].friendlyName
          );
        } else {
          setVerticalsIdList([]);
          setSelectedVerticalsId("");
          setAssetVerticalFriendlyNameValue("");
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const setSelectedVerticalsIdHandler = (e) => {
    setSelectedVerticalsId(e.target.value);
    let catname = verticalsIdList.filter((data) => {
      return data.assetVerticalId === e.target.value;
    });
    setAssetVerticalFriendlyNameValue(catname[0].friendlyName);
  };

  const getCategoryTypes = () => {
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(
        selectedVerticalsId,
        window.AppConfigData.EnterpriseId
      )
        .then((data) => {
          if (data.length > 0) {
            var filteredCategories;
            if (selectedVerticalsId) {
              if (
                userTypeValue === "AssetDeveloper" ||
                userTypeValue === "CBO"
              ) {
                filteredCategories = data.filter((row) => {
                  return row.categoryType === "INSECTS";
                });
                setSelectedCategory(filteredCategories[0].categoryType);
                setCategoryTypesList(filteredCategories);
              } else {
                setSelectedCategory(data[0].categoryType);
                setCategoryTypesList(data);
              }
            }
          } else {
            setCategoryTypesList([]);
            setSelectedCategory("");
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  const setSelectedCategoryHandler = (e) => {
    setSelectedCategory(e.target.value);
  };

  const getAssetTypes = () => {
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId,
        selectedCategory,
        window.AppConfigData.EnterpriseId
      )
        .then((data) => {
          if (data.length > 0) {
            var filteredAssetTypes;
            if (selectedVerticalsId && selectedCategory) {
              if (
                userTypeValue === "AssetDeveloper" ||
                userTypeValue === "CBO"
              ) {
                filteredAssetTypes = data.filter((row) => {
                  return row.assetType === "BEE";
                });
                setSelectedAssertTypes(filteredAssetTypes[0].assetType);
                setAssertTypesList(filteredAssetTypes);
              } else {
                setSelectedAssertTypes(data[0].assetType);
                setAssertTypesList(data);
              }
            }
          } else {
            setAssertTypesList([]);
            setSelectedAssertTypes("");
          }
        })
        .catch((e) => {
          if (e) {
            return NotificationManager.error(e.message || "An error occurred");
          }
        });
    }
  };

  const setSelectedAssertTypesHandler = (e) => {
    setSelectedAssertTypes(e.target.value);
  };

  const getModels = () => {
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId,
        selectedCategory,
        selectedAssertTypes,
        username
      )
        .then((data) => {
          if (data.length > 0) {
            setModelsList(data);
            setSelectedModel(data[0].modelfriendlyname);
            
          } else {
            setModelsList([]);
            setSelectedModel("");
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getAllAssetsBasedonModel = () => {
    if (
      selectedVerticalsId &&
      selectedCategory &&
      selectedAssertTypes &&
      selectedModel
    ) {
      MainService.getAllAssetsBasedonModel(
        username,
        selectedAssertTypes.replace(" ", ""),
        selectedModel.replace(" ", ""),
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", "")
      )
        .then((data) => {
          setIsLoading(true);
          if (data.length > 0) {
            const filteredAssets = data.filter(
              (asset) =>
                (asset.siteId === "null" || asset.siteId === "NULL") &&
                (asset.siteName === "null" || asset.siteName === "NULL")
            );
            console.log("filtered assets:", filteredAssets);
            setAssetsList(filteredAssets);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setAssetsList([]);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    } else {
      setIsLoading(false);
      setAssetsList([]);
    }
  };
  const setSelectedModelHandler = (e) => {
    setSelectedModel(e.target.value);
  };

  const createMapping = () => {
    if (assetIdValue !== "NULL") {
      const siteIdData = {
        siteid: selectedSiteId,
        sitename: selectedSiteName,
      };
      const data = JSON.stringify(siteIdData);

      MainService.createMapping(username, assetIdValue, selectedCategory, data)
        .then((res) => {
          viewMappedAssets(
            getMappedAssetsSiteid,
            "Grid",
            getMappedAssetsSiteName
          );
          return NotificationManager.success(res);
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    } else {
      return NotificationManager.error("please choose Asset");
    }
    setCreateAssetPopup(!getCreateAssetPopup);
  };
  /*End Of Create Asset */

  /* Site Rating */
  /* Site Feedback */
  const labels = {
    1: "Very Poor",
    2: "Poor",
    3: "Fair",
    4: "Good",
    5: "Very Good",
  };

  const getLabelCustomerExperience = (getSiteCustomerExperience) => {
    return `${getSiteCustomerExperience} Star${
      getSiteCustomerExperience !== 1 ? "s" : ""
    }, 
      ${labels[getSiteCustomerExperience]}`;
  };

  const getLabelProduct = (getSiteProduct) => {
    return `${getSiteProduct} Star${getSiteProduct !== 1 ? "s" : ""}, 
      ${labels[getSiteProduct]}`;
  };

  const getLabelCustomerSupport = (getSiteCustomerSupport) => {
    return `${getSiteCustomerSupport} Star${
      getSiteCustomerSupport !== 1 ? "s" : ""
    }, 
      ${labels[getSiteCustomerSupport]}`;
  };

  const getLabelCustomerDelivery = (getSiteDelivery) => {
    return `${getSiteDelivery} Star${getSiteDelivery !== 1 ? "s" : ""}, 
      ${labels[getSiteDelivery]}`;
  };

  const getLabelServiceOthers = (getSiteServiceOthers) => {
    return `${getSiteServiceOthers} Star${
      getSiteServiceOthers !== 1 ? "s" : ""
    }, 
      ${labels[getSiteServiceOthers]}`;
  };

  const siteFeedback = (siteid) => {
    handleMoreCloseForSite();
    setSiteFeedbackSiteid(siteid);
    setShowSiteFeedbackModel(!showSiteFeedbackModel);
  };

  const siteFeedbackSubmit = () => {
    const sitefeedback = {
      siteid: getSiteFeedbackSiteid,
      "how_would_you_rate_your_overall_customer_experience?":
        getSiteFeedbackCustomerExperience,
      "how_satisfied_were_you_with_the_product?": getSiteFeedbackProduct,
      "how_satisfied_were_you_with_the_customer_support?":
        getSiteFeedbackCustomerSupport,
      "how_satisfied_were_you_with_the_timeliness_of_delivery?":
        getSiteFeedbackDelivery,
      "would_you_recommend_our_product_or_service_to_others?":
        getSiteFeedbackServiceOthers,
      remark: getSiteFeedbackRemark,
    };
    const feeback = JSON.stringify(sitefeedback);
    MainService.siteFeedbackDetails(username, feeback)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSiteCustomerExperience("");
    setSiteFeedbackCustomerExperience("");
    setSiteProduct("");
    setSiteFeedbackProduct("");
    setSiteCustomerSupport("");
    setSiteFeedbackCustomerSupport("");
    setSiteDelivery("");
    setSiteFeedbackDelivery("");
    setSiteServiceOthers("");
    setSiteFeedbackServiceOthers("");
    setSiteFeedbackRemark("");
    setSiteServiceOthersHover(-1);
    setSiteCustomerExperienceHover(-1);
    setSiteProductHover(-1);
    setSiteCustomerSupportHover(-1);
    setSiteDeliveryHover(-1);
    setShowSiteFeedbackModel(!showSiteFeedbackModel);
  };
  /* End Of Site Feedback */

  /* Asset Feedback */
  const labelsAsset = {
    1: "Very Poor",
    2: "Poor",
    3: "Fair",
    4: "Good",
    5: "Very Good",
  };

  const getLabelAssetCustomerExperience = (getAssetCustomerExperience) => {
    return `${getAssetCustomerExperience} Star${
      getAssetCustomerExperience !== 1 ? "s" : ""
    }, 
      ${labels[getAssetCustomerExperience]}`;
  };

  const getLabelAssetProduct = (getAssetProduct) => {
    return `${getAssetProduct} Star${getAssetProduct !== 1 ? "s" : ""}, 
      ${labels[getAssetProduct]}`;
  };

  const getLabelAssetCustomerSupport = (getAssetCustomerSupport) => {
    return `${getAssetCustomerSupport} Star${
      getAssetCustomerSupport !== 1 ? "s" : ""
    }, 
      ${labels[getAssetCustomerSupport]}`;
  };

  const getLabelAssetCustomerDelivery = (getAssetDelivery) => {
    return `${getAssetDelivery} Star${getAssetDelivery !== 1 ? "s" : ""}, 
      ${labels[getAssetDelivery]}`;
  };

  const getLabelAssetServiceOthers = (getAssetServiceOthers) => {
    return `${getAssetServiceOthers} Star${
      getAssetServiceOthers !== 1 ? "s" : ""
    }, 
      ${labels[getAssetServiceOthers]}`;
  };

  const assetFeedback = (assetid) => {
    setMoreAnchorElForAsset(null);
    setAssetFeedbackAssetid(assetid);
    setShowAssetFeedbackModel(!showAssetFeedbackModel);
  };

  const assetFeedbackSubmit = () => {
    const assetfeedback = {
      assetdeveloperid: getAssetFeedbackAssetDeveloperid,
      assetid: getAssetFeedbackAssetid,
      "how_would_you_rate_your_overall_customer_experience?":
        getAssetFeedbackCustomerExperience,
      "how_satisfied_were_you_with_the_product?": getAssetFeedbackProduct,
      "how_satisfied_were_you_with_the_customer_support?":
        getAssetFeedbackCustomerSupport,
      "how_satisfied_were_you_with_the_timeliness_of_delivery?":
        getAssetFeedbackDelivery,
      "would_you_recommend_our_product_or_service_to_others?":
        getAssetFeedbackServiceOthers,
      remark: getSiteFeedbackRemark,
    };
    const data = JSON.stringify(assetfeedback);
    MainService.assetFeedbackDetails(username, data)
      .then((res) => {
        setShowAssetFeedbackModel(!showAssetFeedbackModel);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End Of Site Feedback */

  /* Site Issue */
  const createSiteIssue = (siteid) => {
    handleMoreCloseForSite();
    getShowSiteIssue(!showSiteIssue);
    setSiteIssueSiteId(siteid);
  };

  const siteIssueHandler = () => {
    if (getSiteIssueDescription !== "") {
      const siteissue = {
        operatorid: window.AppConfigData.EnterpriseId,
        solutionid: getSiteIssueSolutionId,
        siteid: getSiteIssueSiteId,
        issuedescription: getSiteIssueDescription,
      };
      const data = JSON.stringify(siteissue);

      MainService.siteIssueCreate(username, data)
        .then((res) => {
          getShowSiteIssue(!showSiteIssue);
          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
    } else {
      return NotificationManager.error("Describe the issue");
    }
    setSiteIssueDescription("");
  };

  const siteIssueDetails = (siteid) => {
    handleMoreCloseForSite();
    MainService.getSiteIssueData(username, siteid)
      .then((res) => {
        console.log("isssues site:", res);
        if (res.length > 0) {
          setSiteIssueDetails(res);
        } else {
          setSiteIssueDetails([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    getShowSiteIssueDetailsModel(!showSiteIssueDetailsModel);
  };
  /* End Of Site Issue */

  const assetIssueDetailsModel = (assetid) => {
    setMoreAnchorElForAsset(null);
    MainService.getAssetIssueData(username, getAssetIssueSiteId, assetid)
      .then((res) => {
        setAssetIssueDetails(res.issues);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    getShowAssetIssueDetailsModel(!showAssetIssueDetailsModel);
  };
  /* End Of Asset Issue */

  /*Photo Upload */
  const uploadPhotoClick = () => {
    setMoreAnchorElForAsset(null);
    setShowUploadPhotoPopup(!uploadPhotoPopup);
  };
  const submitUploadPhotoClick = () => {
    if (uploadPhotoFile) {
      const uploadPhotoData = new FormData();
      uploadPhotoData.append("file", uploadPhotoFile);
      uploadPhotoData.append("mimetype", uploadPhotoFile.type);
      uploadPhotoData.append("categoryid", selectedCategoryId);
      uploadPhotoData.append("categoryname", selectedCategoryName);
      //uploadPhotoData.append("contentsubcategory", subCategoryNameValue);
      uploadPhotoData.append("contentname", uploadPhotoNameValue);
      uploadPhotoData.append("assetdeveloperid", parentId);
      uploadPhotoData.append("contentdescription", descriptionValue);
      uploadPhotoData.append("contentcreator", username);
      uploadPhotoData.append("contentformat", "DOC");
      uploadPhotoData.append("price", priceValue);
      uploadPhotoData.append("filedescription", uploadPhotoFileDescription);
      uploadPhotoData.append("contentownerid", username);
      uploadPhotoData.append("unit", "INR");
      uploadPhotoData.append("discountedprice", discountValue);
      uploadPhotoData.append("expirydate", expiryDateValue);
      uploadPhotoData.append("event", "none");
      console.log("create uploadPhoto data:", uploadPhotoData);
      MainService.createContent(uploadPhotoData, parentId)
        .then((res) => {
          return NotificationManager.success("UploadPhoto uploaded");
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    } else {
      return NotificationManager.error("please choose file");
    }

    setShowUploadPhotoPopup(!uploadPhotoPopup);
  };

  const closeUploadPhotoModal = (
    <span
      onClick={() => {
        setShowUploadPhotoPopup(!uploadPhotoPopup);
      }}
    >
      X
    </span>
  );
  const titleUploadPhoto = "Upload Photo";
  const uploadPhotoBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryName">Category Name :</InputLabel>
        <Input
          disabled
          id="categoryName"
          name="categoryName"
          type="text"
          value={selectedCategoryName.toUpperCase()}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="uploadPhotoName">UploadPhoto Name :</InputLabel>
        <Input
          id="uploadPhotoName"
          name="uploadPhotoName"
          type="text"
          value={uploadPhotoNameValue}
          onChange={(e) => {
            setUploadPhotoNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel htmlFor="contentOwner">Content Owner</InputLabel>
        <Input
          disabled
          id="contentOwner"
          name="contentOwner"
          type="text"
          value={username}
        ></Input>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="contentCreator"> Content Creator:</InputLabel>
        <Input
          disabled
          id="contentCreator"
          name="contentCreator"
          type="text"
          value={username}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="zone"> Content Approver:</InputLabel>
        <Input
          disabled
          id="contentApprover"
          name="contentApprover"
          type="text"
          value={parentId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="doc">file:</InputLabel>
        <Input
          id="doc"
          name="doc"
          type="file"
          onChange={(e) => {
            setUploadPhotoFile(e.target.files[0]);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="description"> Description:</InputLabel>
        <Input
          id="description"
          name="description"
          type="text"
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>

      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={() => {
            submitUploadPhotoClick();
          }}
        >
          Submit
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowUploadPhotoPopup(!uploadPhotoPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* end of photo upload */

  /* Daily Reports Api's */
  const dailyReportsHandler = (siteid, Type) => {
    handleMoreCloseForSite();
    setAddDailyReportSiteId(siteid);
    setBackToSites(Type);
    siteAssetsDailyReports(siteid);

    setAttendanceLocation(false);
    setHealthRecords(false);
    setVewFarmUpdates(false);
    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(true);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
  };

  const siteAssetsDailyReports = (id) => {
    setIsLoading(true);
    MainService.dailyReportDetails(username, id)
      .then((res) => {
        if (res.length > 0) {
          setIsLoading(false);
          setDailyReports(res);
        } else {
          setIsLoading(false);
          setDailyReports([]);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const createDailyReportHandler = () => {
    const report = {
      parentid: window.AppConfigData.EnterpriseId,
      userid: username,
      type: userTypeValue,
      assetid: getAddDailyReportSiteId,
      nutritionRecorded: getAddDailyReportNutrition,
      attendanceRecorded: getAddDailyReportAttendance,
      photoUploaded: getAddDailyReportPhoto,
    };
    const data = JSON.stringify(report);
    if (
      getAddDailyReportNutrition !== "" &&
      getAddDailyReportAttendance !== "" &&
      getAddDailyReportPhoto !== ""
    ) {
      MainService.createDailyReport(data)
        .then((res) => {
          siteAssetsDailyReports(getAddDailyReportSiteId);
          getShowAddDailyReportModel(!showAddDailyReportModel);
          return NotificationManager.success("Daily report submitted");
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
    } else {
      return NotificationManager.error("Plese fill all fields");
    }

    setAddDailyReportNutrition("");
    setAddDailyReportAttendance("");
    setAddDailyReportPhoto("");
  };
  /* End Of Daily Reports Api's */

  /* Site Alarms Api's */
  const siteAlarmsClick = (siteid, Type) => {
    handleMoreCloseForSite();
    setResourceId(siteid);
    siteAlarms(siteid);
    setBackToSites(Type);
    //siteAssetsDailyReports(siteid);

    setAttendanceLocation(false);
    setHealthRecords(false);
    setVewFarmUpdates(false);
    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);

    setGridViewSiteAlarms(true);
  };

  const siteAlarms = (siteid) => {
    setIsLoading(true);
    MainService.getSiteAlarms(parentId, siteid)
      .then((res) => {
        setSiteAlarmsList(res);
        setIsLoading(false);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const submitSitealarmClick = () => {
    if (alarmNameValue !== "" && alarmCategoryValue !== "") {
      let alarmData = {
        source: parentId,
        assetverticalid: selectedVerticalsId,
        assetverticalname: selectedVerticalName,
        category: selectedCategory,
        legacyassettype: selectedAssertTypes,
        modelid: selectedModel,
        siteid: resourceId,
        alarmcategory: alarmCategoryValue,
        alarmname: alarmNameValue,
      };

      const alarmDataStringify = JSON.stringify(alarmData);
      MainService.addSiteAlarm(parentId, alarmDataStringify)
        .then((res) => {
          NotificationManager.success(res);
        })
        .catch((err) => {
          NotificationManager.error(err);
        });
    } else {
      return NotificationManager.error("please fill all fields");
    }
    siteAlarms(resourceId);
    setAlarmCategoryValue("");
    setAlarmNameValue("");
    setSiteAlarmPopup(!siteAlarmPopup);
  };
  /* End Of Site Alarms Api's */

  /* Site Group Nutrition  Api's */
  const groupNutritionHandler = (siteid, Type, sitename) => {
    handleMoreCloseForSite();
    setAddGroupNutritionSiteid(siteid);
    setAddGroupNutritionFriendlyname(sitename);
    groupNutritionDetails();
    setBackToSites(Type);

    setAttendanceLocation(false);
    setHealthRecords(false);
    setVewFarmUpdates(false);
    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(true);
    setSiteAttendanceLocation(false);
  };

  const groupNutritionDetails = () => {
    MainService.getGroupNutritionData(username)
      .then((res) => {
        setGroupNutritionDetails(res.nutritions);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const createGroupNutritionHandler = () => {
    if (
      getAddGroupNutritionFeedType !== "" &&
      getAddGroupNutritionSchedule !== "" &&
      getAddGroupNutritionAltitude !== "" &&
      getAddGroupNutritionDescription !== ""
    ) {
      const groupnutrition = {
        assetid: getAddGroupNutritionSiteid,
        assetfriendlyname: getAddGroupNutritionFriendlyname,
        parentid: window.AppConfigData.EnterpriseId,
        userid: getAddGroupNutritionUserid,
        feedtype: getAddGroupNutritionFeedType,
        schedule: getAddGroupNutritionSchedule,
        latitude: latitudeValue,
        longitude: longitudeValue,
        altitude: getAddGroupNutritionAltitude,
        description: getAddGroupNutritionDescription,
        assettype: selectedAssertTypes,
      };
      const data = JSON.stringify(groupnutrition);
      MainService.addNutritionData(data)
        .then((res) => {
          groupNutritionDetails();
          return NotificationManager.success("nutrition added");
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
    } else {
      return NotificationManager.error("please fill all fields");
    }

    setAddGroupNutritionDescription("");
    setAddGroupNutritionSchedule("");
    setAddGroupNutritionFeedType("");
    setAddGroupNutritionAltitude("");
    setShowAddGroupNutritionModel(!showAddGroupNutritionModel);
  };

  const deleteAddedGroupNutrition = (assetid) => {
    setShowDeleteGroupNutritionModel(!showDeleteGroupNutritionModel);
    setDeleteGroupNutritionSiteid(assetid);
  };

  const groupNutritionDeleteHandler = () => {
    MainService.deleteGroupNutrition(getDeleteGroupNutritionSiteid)
      .then((res) => {
        setShowDeleteGroupNutritionModel(!showDeleteGroupNutritionModel);
        groupNutritionDetails();
        return NotificationManager.success("");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateAddedGroupNutrition = (nutrition) => {
    setShowUpdateGroupNutritionModel(!showUpdateGroupNutritionModel);
    setUpdateGroupNutritionSiteid(nutrition.assetid);
    setUpdateGroupNutritionFriendlyname(nutrition.assetfriendlyname);
    setUpdateGroupNutritionUserid(nutrition.userid);
    prevsetUpdateGroupNutritionFeedType(nutrition.feedtype);
    prevsetUpdateGroupNutritionSchedule(nutrition.schedule);
    setUpdateGroupNutritionLatitude(nutrition.latitude);
    setUpdateGroupNutritionLongitude(nutrition.longitude);
    setUpdateGroupNutritionAltitude(nutrition.altitude);
    setUpdateGroupNutritionDescription(nutrition.description);
  };

  const updateGroupNutritionHandler = () => {
    const updategroupnutrition = {
      assetid: getUpdateGroupNutritionSiteid,
      assetfriendlyname: getUpdateGroupNutritionFriendlyname,
      feedtype: getUpdateGroupNutritionFeedType,
      schedule: getUpdateGroupNutritionSchedule,
      latitude: getUpdateGroupNutritionLatitude,
      longitude: getUpdateGroupNutritionLongitude,
      altitude: getUpdateGroupNutritionAltitude,
      description: getUpdateGroupNutritionDescription,
    };
    const data = JSON.stringify(updategroupnutrition);
    MainService.updateGroupNutrition(data)
      .then((res) => {
        setShowUpdateGroupNutritionModel(!showUpdateGroupNutritionModel);
        groupNutritionDetails();
        return NotificationManager.success("nutrition info updated");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End Of Site Group Nutrition Api's */
  const farmUpdatesHandler = (siteid, Type, sitename) => {
    setSelectedSiteId(siteid);
    setSelectedSiteName(sitename);
    setBackToSites(Type);

    setAttendanceLocation(false);
    setHealthRecords(false);
    setVewFarmUpdates(false);
    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setVewFarmUpdates(true);
  };
  /* Site Attendance Location Api's*/

  const siteattendanceLocationButtonClickHandler = (siteid, Type, sitename) => {
    handleMoreCloseForSite();
    setSiteNewAttendanceLocationAssetid(siteid);
    setSiteNewAttendanceLocationAssetfriendlyName(sitename);
    siteattendanceLocationHistoryHandler();
    setBackToSites(Type);

    setAttendanceLocation(false);
    setHealthRecords(false);
    setVewFarmUpdates(false);
    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(true);
  };

  const siteattendanceLocationHistoryHandler = () => {
    setIsLoading(true);
    MainService.siteAttendanceLocationHistory(username)
      .then((res) => {
        setIsLoading(false);
        setSiteAttendanceLocationHistory(res.attendanceLocations);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const addSiteAttendanceLocationHandler = () => {
    if (
      latitudeValue !== "" &&
      longitudeValue !== "" &&
      getSiteNewAttendanceLocationAltitude !== "" &&
      getSiteNewAttendanceLocationDescription !== ""
    ) {
      const attendanceLocation = {
        assetid: getSiteNewAttendanceLocationAssetid,
        assetfriendlyname: getSiteNewAttendanceLocationAssetfriendlyName,
        parentid: window.AppConfigData.EnterpriseId,
        userid: getSiteNewAttendanceLocationUserid,
        latitude: latitudeValue,
        longitude: longitudeValue,
        altitude: getSiteNewAttendanceLocationAltitude,
        description: getSiteNewAttendanceLocationDescription,
      };
      const newattendanceLocation = JSON.stringify(attendanceLocation);

      MainService.siteNewAttendanceLocation(newattendanceLocation)
        .then((res) => {
          siteattendanceLocationHistoryHandler();
          NotificationManager.success("attendance location added");
        })
        .catch((err) => {
          NotificationManager.error(err);
        });
    } else {
      return NotificationManager.error("Please fill all fields");
    }
    setSiteNewAttendanceLocationDescription("");
    setSiteNewAttendanceLocationDescription("");
    setSiteNewAttendanceLocationPopup(false);
  };

  const deleteSiteAttendanceLocationsAssetId = (siteid) => {
    setSiteDeletedAttendanceAssetid(siteid);
    setSiteDeletedAttendancePopup(!getSiteDeletedAttendancePopup);
  };

  const deleteSiteAttendanceAsset = () => {
    MainService.siteDeleteNewAttendanceLocation(getSiteDeletedAttendanceAssetid)
      .then((res) => {
        setSiteDeletedAttendancePopup(false);
        siteattendanceLocationHistoryHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateSiteAttendanceLocationsAssetInfo = (siteinfo) => {
    setSiteUpdateAttendanceLocationInfoAssetid(siteinfo.assetid);
    setSiteUpdateAttendanceLocationInfoAssetfriendlyName(
      siteinfo.assetfriendlyname
    );
    setSiteUpdateAttendanceLocationInfoUserid(siteinfo.userid);
    setSiteUpdateAttendanceLocationInfoLatitude(siteinfo.latitude);
    setSiteUpdateAttendanceLocationInfoLongitude(siteinfo.longitude);
    setSiteUpdateAttendanceLocationInfoAltitude(siteinfo.altitude);
    setSiteUpdateAttendanceLocationInfoDescription(siteinfo.description);
    setSiteUpdateAttendaneLocationPopup(!showSiteUpdateAttendaneLocationPopup);
  };

  const updateSiteAttendanceLocationsHandler = () => {
    const updateAttendanceAsset = {
      assetid: getSiteUpdateAttendanceLocationInfoAssetid,
      assetfriendlyname: getSiteUpdateAttendanceLocationInfoAssetfriendlyName,
      latitude: getSiteUpdateAttendanceLocationInfoLatitude,
      longitude: getSiteUpdateAttendanceLocationInfoLongitude,
      altitude: getSiteUpdateAttendanceLocationInfoAltitude,
      description: getSiteUpdateAttendanceLocationInfoDescription,
    };
    const updateAttendance = JSON.stringify(updateAttendanceAsset);

    MainService.siteUpdateAttendanceLocation(updateAttendance)
      .then((res) => {
        setSiteUpdateAttendaneLocationPopup(false);
        siteattendanceLocationHistoryHandler();
        return NotificationManager.success("attendance location updated");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  /* End Of Site Attendance Location Api's*/
  /* QR Code */
  const qrCodeDialogHandler = (site) => {
    setSelectedSite(site);
    setQrCodeDialog(!qrCodeDialog);
  };
  const submitQrDialogHandler = (site) => {
    console.log("submit generate qr");
    if (userTypeValue !== undefined) {
      if (
        userTypeValue === "AssetDeveloper" ||
        userTypeValue === "LogisticsProvider" ||
        userTypeValue === "BROKER" ||
        userTypeValue === "ENERGY-PROCESSOR" ||
        userTypeValue === "FOOD-PROCESSOR" ||
        userTypeValue === "FMC" ||
        userTypeValue === "PMC" ||
        userTypeValue === "FMCDealer" ||
        userTypeValue === "PMCDealer" ||
        userTypeValue === "OFC" ||
        userTypeValue === "StorageCompany" ||
        userTypeValue === "CBO"
      ) {
        setOrgId(username);
        setSubOrgId("");
        qrGeneratorSubmitHandler(username, site);
      } else if (
        userTypeValue === "Production-Manager" ||
        userTypeValue === "LogisticManager" ||
        userTypeValue === "FF"
      ) {
        setOrgId(parentId);
        setSubOrgId("");
        qrGeneratorSubmitHandler(parentId, site);
      }
    }
  };
  const qrGeneratorSubmitHandler = (orgId, site) => {
    if (orgId !== "") {
      let qrBody = {
        iwidth: qrSizeValue,
        fcolor: fgcolor,
        bcolor: bgcolor,
        cadminid: parentId,
        sitename: site.sitename,
        sitetype: site.sitetype,
        bname: username,
        siteid: site.siteid,
        url: qrUrlValue,
      };

      const qrBodyStringify = JSON.stringify(qrBody);
      MainService.generateSiteQrCode(
        qrBodyStringify,
        orgId,
        subOrgId,
        parentId,
        username,
        userTypeValue
      )
        .then((data) => {
          setIsLoading(false);
          setQrCodeDialog(!qrCodeDialog);
          props.getAllSitesData();
          return NotificationManager.success("QR Code generated successfully");
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
          return NotificationManager.error(err);
        });
    }
  };

  /* Asset Live Monitoring */

  const assetMonitoringHandler = (siteId, assetId, siteName) => {
    setMoreAnchorElForAsset(null);
    setSelectedAssetId(assetId);
    setSelectedSiteName(siteName);
    setSelectedSiteId(siteId);

    setAssetMonitoringView(true);
    setVewFarmUpdates(false);
    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowSiteRating(false);
    setMappedAssetShow(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setAttendanceLocation(false);
    setHealthRecords(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);

    MainService.assetCurrentLocation(parentId, username, assetId)
      .then((res) => {
        console.log("current Location monitor:", res);
        setSelectedAssetId(res.assetId);
        setAssetMonitoringStatus(res.status);
        setMarkers([...markers, { geoLocation: res.geoLocation }]);
        setAssetMonitorTimestamp(res.ts);

        if (marker_position !== undefined) {
          const ENDPOINT = window.socketUrl;
          const socket = socketIOClient(ENDPOINT);
          socket.on(
            `tt/solution/${username}/site/${siteId}/asset/${assetId}/LOC`,
            (data) => {
              console.log(JSON.parse(data.payload).geoLocation);
              setMarkers([
                ...markers,
                { geoLocation: JSON.parse(data.payload).geoLocation },
              ]);
            }
          );
          return () => {
            socket.disconnect();
          };
        }
      })
      .catch((e) => {
        return NotificationManager.error("location not found");
      });
  };

  const getLocationHandler = (props) => {
    console.log(props);
  };

  /* End of Asset Monitoring */

  /* Site and Asset Env */
  const siteEnvClick = (siteid) => {
    handleMoreCloseForSite();
    MainService.siteInfo(siteid)
      .then((res) => {
        setSelectedSiteType(res.subdivision);
      })
      .catch((e) => {
        NotificationManager.error(e);
      });

    if (selectedSiteType !== undefined) {
      console.log("selectedSiteType", selectedSiteType);
      MainService.getSiteEnvInfo(parentId, username, siteid, selectedSiteType)
        .then((res) => {
          console.log("live Site Env monitor:", res);
          setSiteEnvData(res);
        })
        .catch((e) => {
          return NotificationManager.error("Env not found");
        });
    }

    console.log("socket url:", window.socketUrl);
    const ENDPOINT = window.liveEnvUrl;
    const socket = socketIOClient(ENDPOINT);
    console.log("socket :", socket);
    socket.on("livedata", (data) => {
      console.log("from api data:", data);
      setSiteEnvData(data);
    });
    setSiteEnvPopup(!siteEnvPopup);
  };

  /* End of Site and Asset Env */

  /* End of QR Code */
  return (
    <>
      <div className="container">
        {(viewAllListSites || viewAllGridSites || viewAllMapSites) &&
          (props.callingFrom === "Dashboard") && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                style={{ marginRight: "10px" }}
                onClick={() => history.push("./dashboardForAD")}
                edge="start"
                color="inherit"
                aria-label="back"
              >
                <ArrowBackIcon />
              </IconButton>
              <h2 style={{ margin: 0 }}>Site Management</h2>
            </div>
          )}
        {(viewAllListSites || viewAllGridSites || viewAllMapSites) && (
          <div className="topHeadersWrapper">
            <div className="topHeaders">
              <Button
                startIcon={<GridIcon />}
                size="large"
                onClick={() => {
                  getAllSitesData("Grid");
                }}
              ></Button>
              <Button
                startIcon={<MapIcon />}
                size="large"
                onClick={() => {
                  getAllSitesData("Map");
                }}
              ></Button>
              <Button
                startIcon={<ListIcon />}
                size="large"
                onClick={() => {
                  getAllSitesData("List");
                }}
              ></Button>
            </div>
          </div>
        )}
        {(showAssetList || showAssetGrid || showAssetMap) && (
          <div style={{ display: "flow-root", alignItems: "center" }}>
            <Button
              style={{ float: "left" }}
              variant="contained"
              onClick={() => {
                getAllSitesData("Grid");
              }}
            >
              Back to Sites
            </Button>

            <div style={{ float: "right" }}>
              <Button
                style={{
                  marginRight: "10px",
                }}
                size="small"
                variant="contained"
                onClick={() =>
                  viewMappedAssets(selectedSiteId, "Map", selectedSiteName)
                }
              >
                <MapIcon titleAccess="Assets Map View" />
              </Button>
              <Button
                style={{
                  marginRight: "10px",
                }}
                size="small"
                variant="contained"
                onClick={() =>
                  viewMappedAssets(selectedSiteId, "Grid", selectedSiteName)
                }
              >
                <GridIcon titleAccess="Assets Grid View" />
              </Button>
              <Button
                style={{
                  marginRight: "10px",
                }}
                size="small"
                variant="contained"
                onClick={() =>
                  viewMappedAssets(selectedSiteId, "List", selectedSiteName)
                }
              >
                <ListIcon titleAccess="Assets List View" />
              </Button>
            </div>
          </div>
        )}

        <div>
          {viewAllListSites === true ? (
            <div>
              {props.loadingSites ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div>
                  <Fab
                    color="primary"
                    size={"large"}
                    aria-label="add"
                    title="Add Site"
                    className={classes.fab}
                    onClick={() => createSiteHandler()}
                  >
                    <AddIcon></AddIcon>
                  </Fab>
                  <SearchBar
                    value={searched}
                    placeholder="Search by Site Name"
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                  {props.sites.length > 0 ? (
                    <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                      <Paper sx={{ width: "100%" }}>
                      <TableContainer>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={6}>
                                VIEW SITE LIST
                              </TableCell>
                            </TableRow>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Site ID
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Site Name
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Site Type
                              </TableCell>

                              <TableCell align="center" colSpan={1}>
                                QR Code
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                View
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                More
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {props.sites
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((site, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {site.siteid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {site.sitename}
                                    </TableCell>
                                    <TableCell align="center">
                                      {site.sitetype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {site.siteqrcodelink !== "" ||
                                      site.siteqrcodelink !== "null" ? (
                                        <div>
                                          <img
                                            src={site.siteqrcodelink}
                                            alt="img"
                                          ></img>
                                          <br />
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            className="registerBtn"
                                          >
                                            <a
                                              href={`${site.siteqrcodelink}`}
                                              target="_blank"
                                              download
                                            >
                                              Download QR
                                            </a>
                                          </Button>
                                        </div>
                                      ) : (
                                        <>
                                          {isLoading ? (
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className="registerBtn"
                                            >
                                              <CircularProgress />
                                            </Button>
                                          ) : (
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className="registerBtn"
                                              type="submit"
                                              onClick={(e) =>
                                                qrCodeDialogHandler(site)
                                              }
                                            >
                                              Generate
                                            </Button>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() =>
                                          viewMappedAssets(
                                            site.siteid,
                                            "List",
                                            site.sitename
                                          )
                                        }
                                      >
                                        Assets
                                      </Button>
                                    </TableCell>
                                    {/* More button */}
                                    <TableCell align="center">
                                      <Button
                                        aria-controls={`more-menu-${index}`}
                                        aria-haspopup="true"
                                        onClick={handleMoreClickForSite}
                                      >
                                        <MoreVertIcon />
                                      </Button>
                                      <Menu
                                        id={`more-menu-${index}`}
                                        anchorEl={moreAnchorElForSite}
                                        keepMounted
                                        open={Boolean(moreAnchorElForSite)}
                                        onClose={handleMoreCloseForSite}
                                      >
                                        <ListSubheader>View</ListSubheader>
                                        <MenuItem
                                          onClick={() =>
                                            siteInfoPopUpHandler(site.siteid)
                                          }
                                        >
                                          <InfoIcon /> Site Info
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            nodeInfoPopUpHandler(site.siteid)
                                          }
                                        >
                                          <EditIcon /> Node Info
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            siteEnvClick(site.siteid)
                                          }
                                        >
                                          <FeedbackIcon /> Site Env Monitoring
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            siteRatingsHandler(site.siteid)
                                          }
                                        >
                                          <ReportIcon /> Site Rating
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            siteIssueDetails(site.siteid)
                                          }
                                        >
                                          <FeedbackIcon /> Site Issue Details
                                        </MenuItem>
                                        <ListSubheader>Operate</ListSubheader>
                                        <MenuItem
                                          onClick={() =>
                                            updateSiteUpdateClick(site.siteid)
                                          }
                                        >
                                          <EditIcon /> Site Update
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            deleteSiteHandler(
                                              site.siteid,
                                              site.sitename
                                            )
                                          }
                                        >
                                          <DeleteIcon /> Delete
                                        </MenuItem>

                                        <MenuItem
                                          onClick={() =>
                                            siteFeedback(site.siteid)
                                          }
                                        >
                                          <AddIcon /> Site Feedback
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            createSiteIssue(site.siteid)
                                          }
                                        >
                                          <AddIcon /> Site Issue
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            dailyReportsHandler(
                                              site.siteid,
                                              "Grid"
                                            )
                                          }
                                        >
                                          <ReportIcon /> Daily Report
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            siteAlarmsClick(
                                              site.siteid,
                                              "List"
                                            );
                                          }}
                                        >
                                          <AlarmIcon /> Site Alarms
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            groupNutritionHandler(
                                              site.siteid,
                                              "Grid",
                                              site.sitename
                                            )
                                          }
                                        >
                                          <AgricultureIcon /> Group Nutrition
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            siteattendanceLocationButtonClickHandler(
                                              site.siteid,
                                              "Grid",
                                              site.sitename
                                            )
                                          }
                                        >
                                          <FileCopyIcon /> Site Attendance
                                        </MenuItem>
                                        <ListSubheader>Action</ListSubheader>
                                        <MenuItem
                                          onClick={() =>
                                            farmUpdatesHandler(
                                              site.siteid,
                                              "Grid",
                                              site.sitename
                                            )
                                          }
                                        >
                                          <FileCopyIcon /> Farm Updates
                                        </MenuItem>
                                      </Menu>
                                    </TableCell>

                                    {/* Continue with other TableCell components */}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={props.sites.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                      </Paper>
                    </Box>
                  ) : (
                    <h4>No Sites found </h4>
                  )}
                </div>
              )}
            </div>
          ) : null}

          {viewAllGridSites === true ? (
            <>
              {props.loadingSites ? (
                <div className="cardStyleForDashboardItems">
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                </div>
              ) : (
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <Box>
                      <Fab
                        color="primary"
                        size={"large"}
                        aria-label="add"
                        title="Node&Site Creation"
                        className={classes.fab}
                        onClick={() => createSiteHandler()}
                      >
                        <AddIcon></AddIcon>
                      </Fab>
                      <Fab
                        style={{ marginBottom: "60px" }}
                        color="primary"
                        size={"large"}
                        aria-label="add"
                        title="Site Creation With Node"
                        className={classes.fab}
                        onClick={() => createSiteWithNodeId()}
                      >
                        <AddIcon></AddIcon>
                      </Fab>
                      <SearchBar
                        placeholder="Search by Site Name"
                        value={farmgridsearched}
                        onChange={(searchVal) =>
                          requestFarmGridSearch(searchVal)
                        }
                        onCancelSearch={() => cancelFarmGridSearch()}
                      />
                      <div className="cardStyleForDashboardItems">
                        <h4 style={{ width: "100%", textAlign: "center" }}>
                          View Site List
                        </h4>

                        {props.sites.length > 0 ? (
                          <div className="cardStyleForDashboardItems">
                            {props.sites.map((sites, index) => {
                              return (
                                <div
                                  key={index}
                                  className="cardStyleForDashboardItemsBody"
                                >
                                  <span>
                                    <p>
                                      <b>Site ID:</b>
                                      {sites.siteid}
                                    </p>
                                    <p>
                                      <b>Site Name:</b>
                                      {sites.sitename}
                                    </p>
                                    <p>
                                      <b>Site Type:</b>
                                      {sites.sitetype}
                                    </p>
                                    {sites.siteqrcodelink &&
                                    sites.siteqrcodelink !== "" &&
                                    sites.siteqrcodelink !== "null" ? (
                                      <div>
                                        <img
                                          src={sites.siteqrcodelink}
                                          alt="img"
                                        ></img>
                                        <br />
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          className="registerBtn"
                                        >
                                          <a
                                            href={`${sites.siteqrcodelink}`}
                                            target="_blank"
                                            download
                                          >
                                            Download QR
                                          </a>
                                        </Button>
                                      </div>
                                    ) : (
                                      <>
                                        {isLoading ? (
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            className="registerBtn"
                                          >
                                            <CircularProgress />
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            className="registerBtn"
                                            type="submit"
                                            onClick={(e) =>
                                              qrCodeDialogHandler(sites)
                                            }
                                          >
                                            Generate
                                          </Button>
                                        )}
                                      </>
                                    )}
                                    <h3 className="cardactiontitle">View</h3>
                                    <div
                                      style={{ width: "100%", margin: "5px" }}
                                    >
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "30%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() =>
                                          viewMappedAssets(
                                            sites.siteid,
                                            "Grid",
                                            sites.sitename
                                          )
                                        }
                                      >
                                        Assets
                                      </Button>
                                    </div>
                                    <div
                                      style={{ width: "100%", margin: "5px" }}
                                    >
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() =>
                                          siteInfoPopUpHandler(sites.siteid)
                                        }
                                      >
                                        Site Info
                                      </Button>
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() =>
                                          nodeInfoPopUpHandler(sites.siteid)
                                        }
                                      >
                                        Node Info
                                      </Button>
                                    </div>
                                    <div
                                      style={{ width: "100%", margin: "5px" }}
                                    >
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() =>
                                          siteRatingsHandler(sites.siteid)
                                        }
                                      >
                                        Site Rating
                                      </Button>
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          siteEnvClick(sites.siteid);
                                        }}
                                      >
                                        Site Env Monitoring
                                      </Button>
                                    </div>

                                    <div
                                      style={{ width: "100%", margin: "5px" }}
                                    >
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          siteIssueDetails(sites.siteid);
                                        }}
                                      >
                                        Site Issue Details
                                      </Button>
                                    </div>
                                    <h3 className="cardactiontitle">Operate</h3>
                                    <div
                                      style={{ width: "100%", margin: "5px" }}
                                    >
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() =>
                                          updateSiteUpdateClick(sites.siteid)
                                        }
                                      >
                                        Site Update
                                      </Button>
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() =>
                                          deleteSiteHandler(
                                            sites.siteid,
                                            sites.sitename
                                          )
                                        }
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                    <div
                                      style={{ width: "100%", margin: "5px" }}
                                    >
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          siteFeedback(sites.siteid);
                                        }}
                                      >
                                        Site Feedback
                                      </Button>
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          createSiteIssue(sites.siteid);
                                        }}
                                      >
                                        Site Issue
                                      </Button>
                                    </div>
                                    <div
                                      style={{ width: "100%", margin: "5px" }}
                                    >
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          dailyReportsHandler(
                                            sites.siteid,
                                            "Grid"
                                          );
                                        }}
                                      >
                                        Daily Report
                                      </Button>
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          siteAlarmsClick(sites.siteid, "Grid");
                                        }}
                                      >
                                        Site Alarms
                                      </Button>
                                    </div>
                                    <div
                                      style={{ width: "100%", margin: "5px" }}
                                    >
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          groupNutritionHandler(
                                            sites.siteid,
                                            "Grid",
                                            sites.sitename
                                          );
                                        }}
                                      >
                                        Group Nutrition
                                      </Button>
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() =>
                                          siteattendanceLocationButtonClickHandler(
                                            sites.siteid,
                                            "Grid",
                                            sites.sitename
                                          )
                                        }
                                      >
                                        Site Attendance
                                      </Button>
                                    </div>
                                    <h3 className="cardactiontitle">Actions</h3>

                                    <div
                                      style={{ width: "100%", margin: "5px" }}
                                    >
                                      <Button
                                        style={{
                                          marginRight: "10px",
                                          width: "45%",
                                        }}
                                        size="small"
                                        variant="contained"
                                        onClick={() =>
                                          farmUpdatesHandler(
                                            sites.siteid,
                                            "Grid",
                                            sites.sitename
                                          )
                                        }
                                      >
                                        Farm Updates
                                      </Button>
                                    </div>
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <h4>No Sites found.</h4>
                        )}
                      </div>
                    </Box>
                  )}
                </Box>
              )}
            </>
          ) : null}

          {viewAllMapSites === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Site"
                className={classes.fab}
                onClick={() => createSiteHandler()}
              >
                <AddIcon></AddIcon>
              </Fab>
              {props.sites.length > 0 ? (
                <SiteManagementGoogleMap
                  containerElement={
                    <div style={{ height: `500px`, width: "100%" }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              ) : (
                <h4>Sites List Not Found</h4>
              )}
            </Box>
          ) : null}

          {showAssetList === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {getMappedAssets.length > 0 ? (
                <Paper sx={{ width: "100%" }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow align="center">
                          <TableCell align="center" colSpan={8}>
                            MAPPED LEGACY Assets
                          </TableCell>
                        </TableRow>
                        <TableRow align="center">
                          <TableCell align="center" colSpan={1}>
                            Solution ID
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Site ID
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Site Name
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Asset ID
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Asset Type
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Category
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Asset Status
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            More
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getMappedAssets
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((assets, index) => (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {assets.solutionId}
                              </TableCell>
                              <TableCell align="center">
                                {assets.siteId}
                              </TableCell>
                              <TableCell align="center">
                                {getMappedAssetsSiteName}
                              </TableCell>
                              <TableCell align="center">
                                {assets.assetId}
                              </TableCell>
                              <TableCell align="center">
                                {assets.assetType}
                              </TableCell>
                              <TableCell align="center">
                                {assets.category}
                              </TableCell>
                              <TableCell align="center">
                                {assets.assetStatus}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  aria-controls={`more-menu-${index}`}
                                  aria-haspopup="true"
                                  onClick={handleMoreClickForAsset}
                                >
                                  <MoreVertIcon />
                                </Button>
                                <Menu
                                  id={`more-menu-${index}`}
                                  anchorEl={moreAnchorElForAsset}
                                  keepMounted
                                  open={Boolean(moreAnchorElForAsset)}
                                  onClose={handleMoreCloseForAsset}
                                >
                                  <ListSubheader>View</ListSubheader>
                                  <MenuItem
                                    onClick={() =>
                                      assetMonitoringHandler(
                                        assets.siteId,
                                        assets.assetId,
                                        getMappedAssetsSiteName
                                      )
                                    }
                                  >
                                    Asset Monitoring
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      assetAttenanceClick(
                                        assets.assetId,
                                        "List"
                                      )
                                    }
                                  >
                                    Asset Attendance
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      birthInfoButtonClickHandler(
                                        assets.assetId,
                                        "List"
                                      )
                                    }
                                  >
                                    Birth
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      nutritionButtonClickHandler(
                                        assets.assetId,
                                        "List"
                                      )
                                    }
                                  >
                                    Nutrition
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      mortalityButtonClickHandler(
                                        assets.assetId,
                                        "List"
                                      )
                                    }
                                  >
                                    Mortality
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      parturitionButtonClickHandler(
                                        assets.assetId,
                                        "List"
                                      )
                                    }
                                  >
                                    Parturition
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      healthRecordButtonClickHandler(
                                        assets.assetId,
                                        "List"
                                      )
                                    }
                                  >
                                    Health Record
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      assetIssueDetailsModel(assets.assetId)
                                    }
                                  >
                                    Asset Issues
                                  </MenuItem>
                                  <ListSubheader>Operate</ListSubheader>
                                  <MenuItem
                                    onClick={() => {
                                      unMapAssetHandler(assets);
                                    }}
                                  >
                                    UnMap
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      udateAssetInfoClick(assets.assetId)
                                    }
                                  >
                                    Update Info
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      updateLegacyAssetHandler(assets.assetId)
                                    }
                                  >
                                    Update Status
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      addedAssetDeleteHandler(assets.assetId)
                                    }
                                  >
                                    Delete
                                  </MenuItem>
                                  <MenuItem onClick={() => uploadPhotoClick()}>
                                    Photo Upload
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      assetFeedback(assets.assetId)
                                    }
                                  >
                                    Asset Feedback
                                  </MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={getMappedAssets.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              ) : (
                <h4 style={{textAlign:"center"}}>No Mapped Legacy Assets</h4>
              )}
            </Box>
          ) : null}

          {showAssetGrid === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Asset"
                className={classes.fab}
                onClick={() => cretaeAssetModel()}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="cardStyleForDashboardItems">
                <h4 style={{ width: "100%", textAlign: "center" }}>
                  Mapped Legacy Assets
                </h4>

                {getMappedAssets.length > 0 ? (
                  <div className="cardStyleForDashboardItems">
                    {getMappedAssets.map((assets, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Solution ID:</b>
                            {assets.solutionId}
                          </p>
                          <p>
                            <b>Site Name :</b>
                            {getMappedAssetsSiteName}
                          </p>
                          <p>
                            <b>Site ID:</b>
                            {assets.siteId}
                          </p>
                          <p>
                            <b>Asset ID:</b>
                            {assets.assetId}
                          </p>
                          <p>
                            <b>Asset Type:</b>
                            {assets.assetType}
                          </p>
                          <p>
                            <b>Model ID:</b>
                            {assets.modelId}
                          </p>
                          <p>
                            <b>Category:</b>
                            {assets.category}
                          </p>
                          <p>
                            <b>Asset Status:</b>
                            {assets.assetStatus}
                          </p>
                          <h3 className="cardactiontitle">View</h3>
                          <div style={{ width: "100%", margin: "5px" }}
                                    >
                            <Button
                              style={{ marginRight: "10px", width: "45%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                assetMonitoringHandler(
                                  assets.siteId,
                                  assets.assetId,
                                  getMappedAssetsSiteName
                                )
                              }
                            >
                              Asset Monitoring
                            </Button>
                            <Button
                              style={{ marginRight: "10px",width: "45%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                birthInfoButtonClickHandler(
                                  assets.assetId,
                                  "Grid"
                                )
                              }
                            >
                              Birth
                            </Button>
                          </div>

                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px", width: "45%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                assetAttenanceClick(assets.assetId, "Grid")
                              }
                            >
                              Asset Attendance
                            </Button>
                            <Button
                              style={{ marginRight: "10px", width: "45%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                nutritionButtonClickHandler(
                                  assets.assetId,
                                  "Grid"
                                )
                              }
                            >
                              Nutrition
                            </Button>
                          </div>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px", width: "45%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                parturitionButtonClickHandler(
                                  assets.assetId,
                                  "Grid"
                                )
                              }
                            >
                              Parturition
                            </Button>
                            <Button
                              style={{ marginRight: "10px", width: "45%"}}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                healthRecordButtonClickHandler(
                                  assets.assetId,
                                  "Grid"
                                )
                              }
                            >
                              Health Record
                            </Button>
                          </div>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px", width: "45%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                assetIssueDetailsModel(assets.assetId)
                              }
                            >
                              Asset Issues
                            </Button>
                            <Button
                              style={{ marginRight: "10px", width: "45%"}}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                mortalityButtonClickHandler(
                                  assets.assetId,
                                  "Grid"
                                )
                              }
                            >
                              Mortality
                            </Button>
                          </div>

                          <h3 className="cardactiontitle">Operate</h3>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px", width: "45%" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                unMapAssetHandler(assets);
                              }}
                            >
                              UnMap
                            </Button>
                            <Button
                              style={{ marginRight: "10px", width: "45%" }}
                              size="small"
                              variant="contained"
                              onClick={() => uploadPhotoClick()}
                            >
                              Photo Upload
                            </Button>
                          </div>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px", width: "45%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                updateLegacyAssetHandler(assets.assetId)
                              }
                            >
                              Update Status
                            </Button>
                        
                            <Button
                              style={{ marginRight: "10px",width: "45%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                udateAssetInfoClick(assets.assetId)
                              }
                            >
                              Update Info
                            </Button>
                          </div>
                          <div>
                            <Button
                              style={{ marginRight: "10px", width: "45%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                addedAssetDeleteHandler(assets.assetId)
                              }
                            >
                              Delete
                            </Button>
                       
                            <Button
                              style={{ marginRight: "10px", width: "45%" }}
                              size="small"
                              variant="contained"
                              onClick={() => assetFeedback(assets.assetId)}
                            >
                              Asset Feedback
                            </Button>
                          </div>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="cardStyleForDashboardItems">
                  <h4 style={{textAlign:"center"}}>No Mapped Legacy Assets.</h4>
                  </div>
                )}
              </div>
            </Box>
          ) : null}

          {showAssetMap === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {getMappedAssets.length > 0 ? (
                <AssetsGoogleMap
                  containerElement={
                    <div style={{ height: `500px`, width: "100%" }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              ) : (
                <h4 style={{textAlign:"center"}}>No Mapped Legacy Assets.</h4>
              )}
            </Box>
          ) : null}

          {showSiteRating === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Site Rating"
                className={classes.fab}
                onClick={() => addedSiteRatingHandler(getSiteRateSiteId)}
              >
                <AddIcon></AddIcon>
              </Fab>

              <Button
                variant="contained"
                onClick={() => {
                  backToSites();
                }}
              >
                Back to Sites
              </Button>
              <br />
              {getSiteRatings.length > 0 ? (
                <TableContainer component={Paper}>
                  <h2>RATINGS</h2>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">Id</StyledTableCell>
                        <StyledTableCell align="center">Rating</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getSiteRatings.map((row) => (
                        <StyledTableRow key={row.siteid}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {row.siteid}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.ratings}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <h4>Ratings Not Found</h4>
              )}
            </Box>
          ) : null}
          {/* Asset Monitoring */}
          {assetMonitoringView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <h3>Asset Live Monitoring</h3>
              <br />
              <Button
                style={{ margin: "10px" }}
                variant="contained"
                onClick={() => {
                  viewMappedAssets(selectedSiteId, "Grid", selectedSiteName);
                }}
              >
                Back to Assets
              </Button>
             
              <Map
                routeLocationData={markers}
                getLocationHandler={getLocationHandler}
              />
            </Box>
          ) : null}
          {/* Farm Updates */}
          {viewFarmUpdates === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    variant="contained"
                    onClick={() => {
                      backToSites();
                    }}
                  >
                    Back to Sites
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <FarmUpdatesBySiteIdComponent
                  siteid={selectedSiteId}
                  sitename={selectedSiteName}
                />
              </div>
            </Box>
          ) : null}
          {/************************  Attendance Location ****************************/}
          {showAttendanceLocation === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Attendance Location"
                className={classes.fab}
                onClick={() => {
                  setNewAttendanceLocationPopup(
                    !showNewAttendanceLocationPopup
                  );
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    variant="contained"
                    onClick={() => {
                      viewMappedAssets(
                        redirectSiteId,
                        "Grid",
                        getNewBirthSiteName
                      );
                    }}
                  >
                    Back to Assets
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <div className="cardStyleForDashboardItems">
                        <CircularProgress />
                      </div>
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW ATTENDANCE LOCATIONS
                      </h4>
                      {getAttendanceLocationHistory.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getAttendanceLocationHistory.map((assets, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Asset Id:</b>
                                  {assets.assetid}
                                </p>
                                <p>
                                  <b>Latitude:</b>
                                  {assets.latitude}
                                </p>
                                <p>
                                  <b>Longitude:</b>
                                  {assets.longitude}
                                </p>
                                <p>
                                  <b>Altitude:</b>
                                  {assets.altitude}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {assets.timestamp}
                                </p>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      updateAttendanceLocationsAssetInfo(assets)
                                    }
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      deleteAttendanceLocationsAssetId(
                                        assets.assetid
                                      )
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>VIEW ATTENDANCE LOCATIONS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Attendance Location ****************************/}

          {/************************ Daily Reports ****************************/}
          {showDailyReports === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Daily Report"
                className={classes.fab}
                onClick={() => {
                  getShowAddDailyReportModel(!showAddDailyReportModel);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    variant="contained"
                    onClick={() => {
                      backToSites();
                    }}
                  >
                    Back to Sites
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <div className="cardStyleForDashboardItems">
                        <CircularProgress />
                      </div>
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW DAILY REPORT DETAILS
                      </h4>
                      {getDailyReports.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getDailyReports.map((dailyreports, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Site Id:</b>
                                  {dailyreports.assetid}
                                </p>
                                <p>
                                  <b>Nutrition Recorded:</b>
                                  {dailyreports.nutritionrecorded}
                                </p>
                                <p>
                                  <b>Attendance Recorded:</b>
                                  {dailyreports.attendancerecorded}
                                </p>
                                <p>
                                  <b>Photo Uploaded :</b>
                                  {dailyreports.photouploaded}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {dailyreports.timestamp}
                                </p>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>DAILY REPORT DETAILS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}

          {/* Site Alarms */}
          {gridViewSiteAlarms === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Create Site Alarm"
                className={classes.fab}
                onClick={() => {
                  setSiteAlarmPopup(!siteAlarmPopup);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>

              <h3>List Of Site Alarms</h3>
              <br />
              <Button
                variant="contained"
                onClick={() => {
                  backToSites();
                }}
              >
                Back to Sites
              </Button>
              <div className="cardStyleForDashboardItems">
                {sitealarmsList.length > 0 ? (
                  <div className="cardStyleForDashboardItems">
                    {sitealarmsList.map((site, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Alarm Id:</b>
                            {site.alarmid}
                          </p>
                          <p>
                            <b>Source Id:</b>
                            {site.source}
                          </p>
                          <p>
                            <b>Assetvertical Id:</b>
                            {site.assetverticalid}
                          </p>
                          <p>
                            <b>Assetvertical Name:</b>
                            {site.assetverticalname}
                          </p>
                          <p>
                            <b>Category:</b>
                            {site.category}
                          </p>
                          <p>
                            <b>Legacyasset Type:</b>
                            {site.legacyassettype}
                          </p>
                          <p>
                            <b>Model Id:</b>
                            {site.modelid}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Alarm Type:</b>
                            {site.alarmtype}
                          </p>
                          <p>
                            <b>Alarm Category:</b>
                            {site.alarmcategory}
                          </p>
                          <p>
                            <b>Alarm Name:</b>
                            {site.alarmname}
                          </p>
                          <p>
                            <b>Alarm Status:</b>
                            {site.status}
                          </p>
                          <p>
                            <b>Timestamp:</b>
                            {site.timestamp}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Site Alarms Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Site Alarms */}
          {/************************ End Of Daily Reports ****************************/}

          {/************************ Site Group Nutrition Info ****************************/}
          {showGropuNutritionView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Group Nutrition"
                className={classes.fab}
                onClick={() => {
                  setShowAddGroupNutritionModel(!showAddGroupNutritionModel);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    variant="contained"
                    onClick={() => {
                      backToSites();
                    }}
                  >
                    Back to Sites
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <div className="cardStyleForDashboardItems">
                        <CircularProgress />
                      </div>
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW NUTRITION DETAILS
                      </h4>
                      {getGroupNutritionDetails.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getGroupNutritionDetails.map(
                            (groupnutrition, index) => (
                              <div
                                key={index}
                                className="cardStyleForDashboardItemsBody"
                              >
                                <span>
                                  <p>
                                    <b>Site Id:</b>
                                    {groupnutrition.assetid}
                                  </p>
                                  <p>
                                    <b>Site Friendly Name:</b>
                                    {groupnutrition.assetfriendlyname}
                                  </p>
                                  <p>
                                    <b>Feed Type:</b>
                                    {groupnutrition.feedtype}
                                  </p>
                                  <p>
                                    <b>Schedule :</b>
                                    {groupnutrition.schedule}
                                  </p>
                                  <p>
                                    <b>Timestamp:</b>
                                    {groupnutrition.timestamp}
                                  </p>
                                  <div style={{ width: "100%", margin: "5px" }}>
                                    <Button
                                      style={{ marginRight: "10px" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() =>
                                        updateAddedGroupNutrition(
                                          groupnutrition
                                        )
                                      }
                                    >
                                      Update
                                    </Button>
                                    <Button
                                      style={{ marginRight: "10px" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() =>
                                        deleteAddedGroupNutrition(
                                          groupnutrition.assetid
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <h4>VIEW NUTRITION DETAILS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Site Group Nutrition Info ****************************/}

          {/************************  Site Group Attendance Location ****************************/}
          {showSiteAttendanceLocation === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Site Attendance"
                className={classes.fab}
                onClick={() => {
                  setSiteNewAttendanceLocationPopup(
                    !showSiteNewAttendanceLocationPopup
                  );
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    variant="contained"
                    onClick={() => {
                      backToSites();
                    }}
                  >
                    Back to Sites
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <div className="cardStyleForDashboardItems">
                        <CircularProgress />
                      </div>
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW SITE ATTENDANCE LOCATIONS
                      </h4>
                      {getSiteAttendanceLocationHistory.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getSiteAttendanceLocationHistory.map(
                            (sites, index) => (
                              <div
                                key={index}
                                className="cardStyleForDashboardItemsBody"
                              >
                                <span>
                                  <p>
                                    <b>Site Id:</b>
                                    {sites.assetid}
                                  </p>
                                  <p>
                                    <b>Latitude:</b>
                                    {sites.latitude}
                                  </p>
                                  <p>
                                    <b>Longitude:</b>
                                    {sites.longitude}
                                  </p>
                                  <p>
                                    <b>Altitude:</b>
                                    {sites.altitude}
                                  </p>
                                  <p>
                                    <b>Timestamp:</b>
                                    {sites.timestamp}
                                  </p>
                                  <div style={{ width: "100%", margin: "5px" }}>
                                    <Button
                                      style={{ marginRight: "10px" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() =>
                                        updateSiteAttendanceLocationsAssetInfo(
                                          sites
                                        )
                                      }
                                    >
                                      Update
                                    </Button>
                                    <Button
                                      style={{ marginRight: "10px" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() =>
                                        deleteSiteAttendanceLocationsAssetId(
                                          sites.assetid
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <h4>VIEW SITE ATTENDANCE LOCATIONS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Site Group Attendance Location ****************************/}
          {/* Asset Nutrition Info  */}
          <Dialog
            fullScreen={fullScreen}
            open={assetNutritionInfoPopup}
            aria-labelledby="Asset Nutrition Info"
          >
            <DialogTitle id="AssetNutritionInfo">
              {"Asset Nutrition Info"}
            </DialogTitle>
            <DialogContent>
              {Object.values(getNutritions).length > 1 ? (
                <>
                  <p>
                    <span> AssetId :{getNutritions.assetid}</span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      Asset FriendlyName :{getNutritions.assetfriendlyname}
                    </span>
                  </p>
                  <p>
                    <span> ParentId :{getNutritions.parentid}</span>
                  </p>
                  <p>
                    <span> UserId :{getNutritions.userid}</span>
                  </p>
                  <p>
                    <span> Feed Type :{getNutritions.feedtype}</span>
                  </p>
                  <p>
                    <span> Schedule :{getNutritions.schedule}</span>
                  </p>

                  <p>Latitude : {getNutritions.latitude}</p>
                  <p>Longitude : {getNutritions.longitude}</p>
                  <p>
                    <span>Description :{getNutritions.description}</span>
                  </p>
                  <p>
                    Timestamp:
                    {getNutritions.timestamp}
                  </p>
                </>
              ) : (
                <h4>Data Not Found</h4>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setAssetNutritionInfoPopup(!assetNutritionInfoPopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Asset Nutrition Info */}
          {/* Asset Parturition Info  */}
          <Dialog
            fullScreen={fullScreen}
            open={assetParturitionInfoPopup}
            aria-labelledby="Asset Birth Info"
          >
            <DialogTitle id="AssetParturitionInfo">
              {"Asset Parturition Info"}
            </DialogTitle>
            <DialogContent>
              {Object.values(getParturitionDetail).length > 1 ? (
                <>
                  <p>
                    <span> AssetId :{getParturitionDetail.assetid}</span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      Asset FriendlyName :
                      {getParturitionDetail.assetfriendlyname}
                    </span>
                  </p>
                  <p>
                    <span> ParentId :{getParturitionDetail.parentid}</span>
                  </p>
                  <p>
                    <span> UserId :{getParturitionDetail.userid}</span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      Weightatfirstmating :
                      {getParturitionDetail.weightatfirstmating}
                    </span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      Ageatfirstparturition :
                      {getParturitionDetail.ageatfirstparturition}
                    </span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      Bodyconditionsindex :
                      {getParturitionDetail.bodyconditionsindex}
                    </span>
                  </p>

                  <p>
                    Numberofmatings : {getParturitionDetail.numberofmatings}
                  </p>
                  <p>Supermogram :{getParturitionDetail.supermogram}</p>
                  <p>Breed : {getParturitionDetail.breed}</p>
                  <p>
                    Breedpercentage : {getParturitionDetail.breedpercentage}
                  </p>
                  <p>BreederId : {getParturitionDetail.breederid}</p>
                  <p>Supermogram :{getParturitionDetail.supermogram}</p>
                  <p>BreedingDate : {getParturitionDetail.breedingdate}</p>
                  <p>Nextbreeding : {getParturitionDetail.nextbreeding}</p>
                  <p>
                    Expectedkiddingdate :{" "}
                    {getParturitionDetail.expectedkiddingdate}
                  </p>
                  <p>
                    Totalkidsdelivered :
                    {getParturitionDetail.totalkidsdelivered}
                  </p>
                  <p>Disposal : {getParturitionDetail.disposal}</p>
                  <p>
                    Breedpercentage : {getParturitionDetail.breedpercentage}
                  </p>
                  <p>Latitude : {getParturitionDetail.latitude}</p>
                  <p>Longitude : {getParturitionDetail.longitude}</p>
                  <p>
                    <span>Description :{getParturitionDetail.description}</span>
                  </p>
                  <p>
                    Timestamp:
                    {getParturitionDetail.timestamp}
                  </p>
                </>
              ) : (
                <h4>Data Not Found</h4>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setAssetParturitionInfoPopup(!assetParturitionInfoPopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Asset Parturition Info */}
          {/* Asset Mortality Info  */}
          <Dialog
            fullScreen={fullScreen}
            open={assetMortalityInfoPopup}
            aria-labelledby="Asset Mortality Info"
          >
            <DialogTitle id="AssetMortalityInfo">
              {"Asset Mortality Info"}
            </DialogTitle>
            <DialogContent>
              {Object.values(getMortalityDetails).length > 1 ? (
                <>
                  <p>
                    <span> AssetId :{getMortalityDetails.assetid}</span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      Asset FriendlyName :
                      {getMortalityDetails.assetfriendlyname}
                    </span>
                  </p>
                  <p>
                    <span> ParentId :{getMortalityDetails.parentid}</span>
                  </p>
                  <p>
                    <span> UserId :{getMortalityDetails.userid}</span>
                  </p>
                  <p>
                    <span> Modelid :{getMortalityDetails.modelid}</span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      Breed Percentage :{getMortalityDetails.breedpercentage}
                    </span>
                  </p>
                  <p>
                    <span> Gender :{getMortalityDetails.gender}</span>
                  </p>
                  <p>
                    <span> Birth Date :{getMortalityDetails.birthdate}</span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      Expired Date :{getMortalityDetails.expireddate}
                    </span>
                  </p>
                  <p>
                    <span> TotalAge :{getMortalityDetails.totalage}</span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      Current Value :{getMortalityDetails.currentvalue}
                    </span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      Causeof Death :{getMortalityDetails.causeofdeath}
                    </span>
                  </p>

                  <p>Latitude : {getMortalityDetails.latitude}</p>
                  <p>Longitude : {getMortalityDetails.longitude}</p>
                  <p>Altitude : {getMortalityDetails.altitude}</p>
                  <p>
                    <span>Description :{getMortalityDetails.description}</span>
                  </p>
                  <p>
                    Timestamp:
                    {getMortalityDetails.timestamp}
                  </p>
                </>
              ) : (
                <h4>Data Not Found</h4>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setAssetMortalityInfoPopup(!assetMortalityInfoPopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Asset Mortality Info */}
          {/* Asset Health Info  */}
          <Dialog
            fullScreen={fullScreen}
            open={assetHelathRecordInfoPopup}
            aria-labelledby="Asset Health Info"
          >
            <DialogTitle id="AssetHealthInfo">
              {"Asset Health Info"}
            </DialogTitle>
            <DialogContent>
              {Object.values(assetHealthRecord).length > 1 ? (
                <>
                  <p>
                    <span> AssetId :{assetHealthRecord.assetid}</span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      Asset FriendlyName :{assetHealthRecord.assetfriendlyname}
                    </span>
                  </p>
                  <p>
                    <span> ParentId :{assetHealthRecord.parentid}</span>
                  </p>
                  <p>
                    <span> UserId :{assetHealthRecord.userid}</span>
                  </p>
                  <p>
                    <span> MedicineDate :{assetHealthRecord.medicinedate}</span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      VaccinationDate :{assetHealthRecord.vaccinationdate}
                    </span>
                  </p>
                  <p>
                    <span> Examine :{assetHealthRecord.examine}</span>
                  </p>

                  <p>
                    SurgicalProcedure : {assetHealthRecord.surgicalprocedure}
                  </p>
                  <p>VaccinationType :{assetHealthRecord.vaccinationtype}</p>
                  <p>MedicineType : {assetHealthRecord.medicinetype}</p>
                  <p>Diseasetype : {assetHealthRecord.diseasetype}</p>
                  <p>TreatmentDate : {assetHealthRecord.treatmentdate}</p>

                  <p>Latitude : {assetHealthRecord.latitude}</p>
                  <p>Longitude : {assetHealthRecord.longitude}</p>
                  <p>
                    <span>Description :{assetHealthRecord.description}</span>
                  </p>
                  <p>
                    Timestamp:
                    {assetHealthRecord.timestamp}
                  </p>
                </>
              ) : (
                <h4>Data Not Found</h4>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setAssetHelathRecordInfoPopup(!assetHelathRecordInfoPopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Asset Health Info */}
          {/* Asset Birth Info  */}
          <Dialog
            fullScreen={fullScreen}
            open={assetBirthInfoPopup}
            aria-labelledby="Asset Birth Info"
          >
            <DialogTitle id="AssetBirthInfo">{"Asset Birth Info"}</DialogTitle>
            <DialogContent>
              {Object.values(getBirthInfos).length > 1 ? (
                <>
                  <p>
                    <span> AssetId :{getBirthInfos.assetid}</span>
                  </p>
                  <p>
                    <span>
                      {" "}
                      Asset FriendlyName :{getBirthInfos.assetfriendlyname}
                    </span>
                  </p>
                  <p>
                    <span> ParentId :{getBirthInfos.parentid}</span>
                  </p>
                  <p>
                    <span> UserId :{getBirthInfos.userid}</span>
                  </p>
                  <p>
                    <span> UserName :{getBirthInfos.username}</span>
                  </p>
                  <p>
                    <span> SiteId :{getBirthInfos.siteid}</span>
                  </p>
                  <p>
                    <span> SiteName :{getBirthInfos.sitename}</span>
                  </p>
                  <p>
                    <span> Lotid :{getBirthInfos.lotid}</span>
                  </p>

                  <p>LotName : {getBirthInfos.lotname}</p>
                  <p>Legacyassettype :{getBirthInfos.legacyassettype}</p>
                  <p>Modelid : {getBirthInfos.modelid}</p>
                  <p>Modelfriendlyname : {getBirthInfos.modelfriendlyname}</p>
                  <p>Modeltype : {getBirthInfos.modeltype}</p>
                  <p>Modelurl : {getBirthInfos.modelurl}</p>
                  <p>DOB :{getBirthInfos.dob}</p>
                  <p>Gender : {getBirthInfos.gender}</p>
                  <p>Maleparent : {getBirthInfos.maleparent}</p>
                  <p>Femaleparent : {getBirthInfos.femaleparent}</p>
                  <p>Parentmodelid : {getBirthInfos.parentmodelid}</p>
                  <p>Latitude : {getBirthInfos.latitude}</p>
                  <p>Longitude : {getBirthInfos.longitude}</p>
                  <p>
                    <span>Description :{getBirthInfos.description}</span>
                  </p>
                  <p>
                    Timestamp:
                    {getBirthInfos.timestamp}
                  </p>
                </>
              ) : (
                <h4>Data Not Found</h4>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setAssetBirthInfoPopup(!assetBirthInfoPopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Asset Birth Info */}

          {/* Delete Site  */}
          <Dialog
            fullScreen={fullScreen}
            open={siteDeleteDialog}
            aria-labelledby="delete site"
          >
            <DialogTitle id="deleteSite">{"Delete Site"}</DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  deletSiteSubmitClcik();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setSiteDeleteDialog(!siteDeleteDialog);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete site */}

          {/* New Attendance Location */}
          <Dialog
            fullScreen={fullScreen}
            open={showNewAttendanceLocationPopup}
            // onClose={handleClose}
            aria-labelledby="craete Consumer User"
          >
            <DialogTitle id="createConsumerUser">
              {"New Attendance Location"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetid">Asset id :</InputLabel>
                <Input
                  id="assetid"
                  name="assetid"
                  type="text"
                  value={getNewAttendanceLocationAssetid}
                  // onChange={(e) => {
                  //   setNewAttendanceLocationAssetid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Asset friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getNewAttendanceLocationAssetfriendlyName}
                  onChange={(e) => {
                    setNewAttendanceLocationAssetfriendlyName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User id :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getNewAttendanceLocationUserid}
                  // onChange={(e) => {
                  //   setNewAttendanceLocationUserid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Latitude">Latitude :</InputLabel>
                <Input
                  id="Latitude"
                  name="Latitude"
                  type="text"
                  value={getNewAttendanceLocationLatitude}
                  onChange={(e) => {
                    setNewAttendanceLocationLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Longitude">Longitude:</InputLabel>
                <Input
                  id="Longitude"
                  name="Longitude"
                  type="text"
                  value={getNewAttendanceLocationLongitude}
                  onChange={(e) => {
                    setNewAttendanceLocationLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Altitude">Altitude :</InputLabel>
                <Input
                  id="Altitude"
                  name="Altitude"
                  type="text"
                  value={getNewAttendanceLocationAltitude}
                  onChange={(e) => {
                    setNewAttendanceLocationAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getNewAttendanceLocationDescription}
                  onChange={(e) => {
                    setNewAttendanceLocationDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  addAttendanceLocationHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setNewAttendanceLocationPopup(
                    !showNewAttendanceLocationPopup
                  );
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of New Attendance Location */}

          {/* Delete Attendance Location */}
          <Dialog
            fullScreen={fullScreen}
            open={getDeletedAttendancePopup}
            // onClose={handleClose}
            aria-labelledby="craete Consumer User"
          >
            <DialogTitle id="createConsumerUser">
              {"Delete Attendance Location"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  deleteAttendanceAsset();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setDeletedAttendancePopup(!getDeletedAttendancePopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Attendance Location */}

          {/* Update Attendance Location Info */}
          <Dialog
            fullScreen={fullScreen}
            open={showUpdateAttendaneLocationPopup}
            // onClose={handleClose}
            aria-labelledby="craete Consumer User"
          >
            <DialogTitle id="createConsumerUser">
              {"Update Attendance Location Info"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetid">Asset id :</InputLabel>
                <Input
                  disabled
                  id="assetid"
                  name="assetid"
                  type="text"
                  value={getUpdateAttendanceLocationInfoAssetid}
                  // onChange={(e) => {
                  //   setUpdateAttendanceLocationInfoAssetid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Asset friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getUpdateAttendanceLocationInfoAssetfriendlyName}
                  onChange={(e) => {
                    setUpdateAttendanceLocationInfoAssetfriendlyName(
                      e.target.value
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User id :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getUpdateAttendanceLocationInfoUserid}
                  // onChange={(e) => {
                  //   setNewAttendanceLocationUserid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Latitude">Latitude :</InputLabel>
                <Input
                  id="Latitude"
                  name="Latitude"
                  type="text"
                  value={getUpdateAttendanceLocationInfoLatitude}
                  onChange={(e) => {
                    setUpdateAttendanceLocationInfoLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Longitude">Longitude:</InputLabel>
                <Input
                  id="Longitude"
                  name="Longitude"
                  type="text"
                  value={getUpdateAttendanceLocationInfoLongitude}
                  onChange={(e) => {
                    setUpdateAttendanceLocationInfoLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Altitude">Altitude :</InputLabel>
                <Input
                  id="Altitude"
                  name="Altitude"
                  type="text"
                  value={getUpdateAttendanceLocationInfoAltitude}
                  onChange={(e) => {
                    setUpdateAttendanceLocationInfoAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getUpdateAttendanceLocationInfoDescription}
                  onChange={(e) => {
                    setUpdateAttendanceLocationInfoDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateAttendanceLocationsHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setUpdateAttendaneLocationPopup(
                    !showUpdateAttendaneLocationPopup
                  );
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Attendance Location Info */}

          {/* Mapped Asset Popup */}
          <Dialog
            fullScreen={fullScreen}
            open={mapPopUp}
            aria-labelledby="mapasset"
          >
            <DialogTitle id="mapasset">MAP Asset With Site</DialogTitle>
            <DialogContent>
              <div>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Site Id</InputLabel>
                  <Select
                    id="selectedModel"
                    value={mapSiteId}
                    onChange={(event) => setMapSiteId(event.target.value)}
                    label="Select Model"
                  >
                    {props.sites &&
                      props.sites.map((idObj, index) => (
                        <MenuItem key={`${index} `} value={idObj.siteid}>
                          {idObj.sitename}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId"> Asset Id:</InputLabel>
                  <Input
                    disabled
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={mapAssetId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="categoryType">
                    {" "}
                    Category Type:
                  </InputLabel>
                  <Input
                    disabled
                    id="categoryType"
                    name="categoryType"
                    type="text"
                    value={mapCategoryType}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  addAssetToSiteMappingHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setMapPopUp(!mapPopUp);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Mapped Asset Popup */}

          {/* Delete Asset */}
          <Dialog
            fullScreen={fullScreen}
            open={getShowDeletePopup}
            aria-labelledby="DeleteAsset"
          >
            <DialogTitle id="DeleteAsset">{"DeleteAsset"}</DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  assetDeleteHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowDeletePopup(!getShowDeletePopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Asset */}

          {/* Create Asset */}
          <Dialog
            fullScreen={fullScreen}
            open={getCreateAssetPopup}
            aria-labelledby="createasset"
          >
            <DialogTitle id="createasset">Map Asset</DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetDeveloperId">
                    AssetDeveloper ID:
                  </InputLabel>
                  <Input
                    disabled
                    id="assetDeveloperId"
                    name="assetDeveloperId"
                    type="text"
                    value={username}
                  />
                </FormControl>

                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>AssetVerticalId *:</InputLabel>
                  <Select
                    id="assetVerticalID"
                    name="assetVerticalID"
                    type="text"
                    value={selectedVerticalsId}
                    onChange={(e) => {
                      setSelectedVerticalsIdHandler(e);
                    }}
                  >
                    {verticalsIdList.length > 0 ? (
                      verticalsIdList.map((idObj, index) => (
                        <MenuItem
                          key={`${index} `}
                          value={idObj.assetVerticalId}
                        >
                          {idObj.friendlyName}
                        </MenuItem>
                      ))
                    ) : (
                      <h4>Asset Vertical not found</h4>
                    )}
                  </Select>
                </FormControl>

                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Category Type*</InputLabel>
                  <Select
                    id="categoryType"
                    name="categoryType"
                    type="text"
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedCategoryHandler(e);
                    }}
                  >
                    {categoryTypesList &&
                      categoryTypesList.map((idObj, index) => (
                        <MenuItem key={`${index} `} value={idObj.categoryType}>
                          {idObj.categoryType}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Asset Type *</InputLabel>
                  <Select
                    id="selectedAssertTypes"
                    value={selectedAssertTypes}
                    onChange={(e) => setSelectedAssertTypesHandler(e)}
                    label="Select Type"
                  >
                    {assertTypesList &&
                      assertTypesList.map((idObj, index) => (
                        <MenuItem key={`${index} `} value={idObj.assetType}>
                          {idObj.assetType}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Model </InputLabel>
                  <Select
                    id="selectedModel"
                    value={selectedModel}
                    onChange={(event) => setSelectedModelHandler(event)}
                    label="Select Model"
                  >
                    {modelList &&
                      modelList.map((idObj, index) => (
                        <MenuItem key={`${index} `} value={idObj.modelid}>
                          {idObj.modelfriendlyname}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Choose Asset *</InputLabel>
                  <Select
                    id="asset"
                    value={assetIdValue}
                    onChange={(e) => setAssetIdValue(e.target.value)}
                    label="asset"
                  >
                    {assetsList &&
                      assetsList.map((idObj, index) => (
                        <MenuItem key={`${index} `} value={idObj.assetId}>
                          {idObj.friendlyName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Site Name*</InputLabel>
                  <Input
                    disabled
                    id="siteName"
                    name="siteName"
                    type="text"
                    value={getMappedAssetsSiteName}
                  ></Input>
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  createMapping();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setCreateAssetPopup(!getCreateAssetPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Create Asset */}

          {/* Site Feedback */}
          <Dialog
            fullScreen={fullScreen}
            open={showSiteFeedbackModel}
            aria-labelledby="sitefeedback"
          >
            <DialogTitle id="sitefeedback">
              {"Record Site Feedback"}
            </DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="siteid">Site id:</InputLabel>
                  <Input
                    id="siteid"
                    name="siteid"
                    type="text"
                    value={getSiteFeedbackSiteid}
                  />
                </FormControl>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    How would you rate your overall customer experience?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="customer-experience"
                      value={getSiteCustomerExperience}
                      precision={1}
                      getLabelText={getLabelCustomerExperience}
                      onChange={(event, value) => {
                        setSiteCustomerExperience(value);
                        setSiteFeedbackCustomerExperience(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteCustomerExperienceHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getSiteCustomerExperience !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            getSiteCustomerExperienceHover !== -1
                              ? getSiteCustomerExperienceHover
                              : getSiteCustomerExperience
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    how_satisfied_were_you_with_the_product?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="product"
                      value={getSiteProduct}
                      precision={1}
                      getLabelText={getLabelProduct}
                      onChange={(event, value) => {
                        setSiteProduct(value);
                        setSiteFeedbackProduct(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteProductHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getSiteProduct !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            getSiteProductHover !== -1
                              ? getSiteProductHover
                              : getSiteProduct
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    how_satisfied_were_you_with_the_customer_support?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="customer_support"
                      value={getSiteCustomerSupport}
                      precision={1}
                      getLabelText={getLabelCustomerSupport}
                      onChange={(event, value) => {
                        setSiteCustomerSupport(value);
                        setSiteFeedbackCustomerSupport(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteCustomerSupportHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getSiteCustomerSupport !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            getSiteCustomerSupportHover !== -1
                              ? getSiteCustomerSupportHover
                              : getSiteCustomerSupport
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    how_satisfied_were_you_with_the_timeliness_of_delivery?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="delivery"
                      value={getSiteDelivery}
                      precision={1}
                      getLabelText={getLabelCustomerDelivery}
                      onChange={(event, value) => {
                        setSiteDelivery(value);
                        setSiteFeedbackDelivery(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteDeliveryHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getSiteDelivery !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            getSiteDeliveryHover !== -1
                              ? getSiteDeliveryHover
                              : getSiteDelivery
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    would_you_recommend_our_product_or_service_to_others?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="serviceothers"
                      value={getSiteServiceOthers}
                      precision={1}
                      getLabelText={getLabelServiceOthers}
                      onChange={(event, value) => {
                        setSiteServiceOthers(value);
                        setSiteFeedbackServiceOthers(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteServiceOthersHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getSiteServiceOthers !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            getSiteServiceOthersHover !== -1
                              ? getSiteServiceOthersHover
                              : getSiteServiceOthers
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="remark">Remark:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getSiteFeedbackRemark}
                    onChange={(e) => {
                      setSiteFeedbackRemark(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  siteFeedbackSubmit();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowSiteFeedbackModel(!showSiteFeedbackModel);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Site Feedback */}

          {/* Asset Feedback */}
          <Dialog
            fullScreen={fullScreen}
            open={showAssetFeedbackModel}
            aria-labelledby="assetfeedback"
          >
            <DialogTitle id="assetfeedback">
              {"Record Asset Feedback"}
            </DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetdevid">Asset id:</InputLabel>
                  <Input
                    id="assetdevid"
                    name="assetdevid"
                    type="text"
                    value={getAssetFeedbackAssetDeveloperid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetid">Asset id:</InputLabel>
                  <Input
                    id="assetid"
                    name="assetid"
                    type="text"
                    value={getAssetFeedbackAssetid}
                  />
                </FormControl>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    How would you rate your overall customer experience?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="customerexperience"
                      value={getAssetCustomerExperience}
                      precision={1}
                      getLabelText={getLabelAssetCustomerExperience}
                      onChange={(event, value) => {
                        setAssetCustomerExperience(value);
                        setAssetFeedbackCustomerExperience(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetCustomerExperienceHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getAssetCustomerExperience !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labelsAsset[
                            getAssetCustomerExperienceHover !== -1
                              ? getAssetCustomerExperienceHover
                              : getAssetCustomerExperience
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    how_satisfied_were_you_with_the_product?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="product"
                      value={getAssetProduct}
                      precision={1}
                      getLabelText={getLabelAssetProduct}
                      onChange={(event, value) => {
                        setAssetProduct(value);
                        setAssetFeedbackProduct(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetProductHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getAssetProduct !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labelsAsset[
                            getAssetProductHover !== -1
                              ? getAssetProductHover
                              : getAssetProduct
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    how_satisfied_were_you_with_the_customer_support?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="customersupport"
                      value={getAssetCustomerSupport}
                      precision={1}
                      getLabelText={getLabelAssetCustomerSupport}
                      onChange={(event, value) => {
                        setAssetCustomerSupport(value);
                        setAssetFeedbackCustomerSupport(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetCustomerSupportHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getAssetCustomerSupport !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labelsAsset[
                            getAssetCustomerSupportHover !== -1
                              ? getAssetCustomerSupportHover
                              : getAssetCustomerSupport
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    how_satisfied_were_you_with_the_timeliness_of_delivery?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="delivery"
                      value={getAssetDelivery}
                      precision={1}
                      getLabelText={getLabelAssetCustomerDelivery}
                      onChange={(event, value) => {
                        setAssetDelivery(value);
                        setAssetFeedbackDelivery(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetDeliveryHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getAssetDelivery !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labelsAsset[
                            getAssetDeliveryHover !== -1
                              ? getAssetDeliveryHover
                              : getAssetDelivery
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    would_you_recommend_our_product_or_service_to_others?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="service-others"
                      value={getAssetServiceOthers}
                      precision={1}
                      getLabelText={getLabelAssetServiceOthers}
                      onChange={(event, value) => {
                        setAssetServiceOthers(value);
                        setAssetFeedbackServiceOthers(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetServiceOthersHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getAssetServiceOthers !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labelsAsset[
                            getAssetServiceOthersHover !== -1
                              ? getAssetServiceOthersHover
                              : getAssetServiceOthers
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="remark">Remark:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getAssetFeedbackRemark}
                    onChange={(e) => {
                      setAssetFeedbackRemark(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  assetFeedbackSubmit();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowAssetFeedbackModel(!showAssetFeedbackModel);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Asset Feedback */}

          {/* Create Site Issue */}
          <Dialog
            fullScreen={fullScreen}
            open={showSiteIssue}
            aria-labelledby="siteissue"
          >
            <DialogTitle id="siteissue">Raise Site Issue</DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="username">User Name:</InputLabel>
                  <Input
                    id="username"
                    name="username"
                    type="text"
                    value={getSiteIssueSolutionId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="siteid">Site Id:</InputLabel>
                  <Input
                    id="siteid"
                    name="siteid"
                    type="text"
                    value={getSiteIssueSiteId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="address">Issue Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getSiteIssueDescription}
                    onChange={(e) => {
                      setSiteIssueDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  siteIssueHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  getShowSiteIssue(!showSiteIssue);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Create Site Issue */}

          {/* Site Issue Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showSiteIssueDetailsModel}
            aria-labelledby="siteissuedetails"
          >
            <DialogTitle id="siteissuedetails">
              {"Site Issue Details"}
            </DialogTitle>
            <DialogContent>
              {getSiteIssueDetails.length > 0 ? (
                <div>
                  {getSiteIssueDetails &&
                    getSiteIssueDetails.map((site, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Operator ID:</b>
                            {site.operatorid}
                          </p>
                          <p>
                            <b>User Name:</b>
                            {site.solutionid}
                          </p>
                          <p>
                            <b>Site ID:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Issue Description:</b>
                            {site.issuedescription}
                          </p>
                        </span>
                      </div>
                    ))}
                </div>
              ) : (
                <h4 className="cardStyleForDashboardItemsBody">
                  Issues Not Found
                </h4>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  getShowSiteIssueDetailsModel(!showSiteIssueDetailsModel);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Site Issue Details */}

          {/* Asset Issue Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showAssetIssueDetailsModel}
            aria-labelledby="assetissuedetails"
          >
            <DialogTitle id="assetissuedetails">
              {"Asset Issue Details"}
            </DialogTitle>
            <DialogContent>
              {getAssetIssueDetails.length > 0 ? (
                <div>
                  {getAssetIssueDetails &&
                    getAssetIssueDetails.map((asset, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Operator ID:</b>
                            {asset.operatorid}
                          </p>
                          <p>
                            <b>User Name:</b>
                            {asset.solutionid}
                          </p>
                          <p>
                            <b>Site ID:</b>
                            {asset.siteid}
                          </p>
                          <p>
                            <b>Site ID:</b>
                            {asset.siteid}
                          </p>
                          <p>
                            <b>Issue Description:</b>
                            {asset.issuedescription}
                          </p>
                        </span>
                      </div>
                    ))}
                </div>
              ) : (
                <h4 className="cardStyleForDashboardItemsBody">
                  No Data Found
                </h4>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  getShowAssetIssueDetailsModel(!showAssetIssueDetailsModel);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Asset Issue Details */}

          {/* Add Daily Report */}
          <Dialog
            fullScreen={fullScreen}
            open={showAddDailyReportModel}
            aria-labelledby="dailyreport"
          >
            <DialogTitle id="dailyreport">{"Daily Report"}</DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetid">Site ID:</InputLabel>
                <Input
                  id="assetid"
                  name="assetid"
                  type="text"
                  value={getAddDailyReportSiteId}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Have you recorded the Nutrition today?</InputLabel>
                <Select
                  id="Nutrition"
                  value={getAddDailyReportNutrition}
                  onChange={(e) => setAddDailyReportNutrition(e.target.value)}
                  label="Nutrition"
                >
                  <MenuItem key="yes" value="yes">
                    Yes
                  </MenuItem>
                  <MenuItem key="no" value="no">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Have you recorded the Attendance today?</InputLabel>
                <Select
                  id="Attendance"
                  value={getAddDailyReportAttendance}
                  onChange={(e) => setAddDailyReportAttendance(e.target.value)}
                  label="Attendance"
                >
                  <MenuItem key="yes" value="yes">
                    Yes
                  </MenuItem>
                  <MenuItem key="no" value="no">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Have you uploaded the Photo today?</InputLabel>
                <Select
                  id="Photo"
                  value={getAddDailyReportPhoto}
                  onChange={(e) => setAddDailyReportPhoto(e.target.value)}
                  label="Photo"
                >
                  <MenuItem key="yes" value="yes">
                    Yes
                  </MenuItem>
                  <MenuItem key="no" value="no">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  createDailyReportHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  getShowAddDailyReportModel(!showAddDailyReportModel);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Add Daily Report */}

          {/* Group Nutrition Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showAddGroupNutritionModel}
            aria-labelledby="groupnutritiondetails"
          >
            <DialogTitle id="groupnutritiondetails">
              {"Group Nutrition details"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteid">Site ID :</InputLabel>
                <Input
                  id="siteid"
                  name="assetid"
                  type="text"
                  value={getAddGroupNutritionSiteid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Asset Friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getAddGroupNutritionFriendlyname}
                  onChange={(e) => {
                    setAddGroupNutritionFriendlyname(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User ID :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getAddGroupNutritionUserid}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Vertical ID :</InputLabel>
                <Select
                  id="assetverticalid"
                  name="assetverticalid"
                  type="text"
                  value={selectedVerticalsId}
                  onChange={(e) => {
                    setSelectedVerticalsIdHandler(e);
                  }}
                >
                  {verticalsIdList &&
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Category Type</InputLabel>
                <Select
                  id="categoryType"
                  name="categoryType"
                  type="text"
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategoryHandler(e);
                  }}
                >
                  {categoryTypesList &&
                    categoryTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.categoryType}>
                        {idObj.categoryType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Type</InputLabel>
                <Select
                  id="selectedAssertTypes"
                  value={selectedAssertTypes}
                  onChange={(e) => setSelectedAssertTypesHandler(e)}
                  label="Select Type"
                >
                  {assertTypesList &&
                    assertTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetType}>
                        {idObj.assetType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Feed Type :</InputLabel>
                <Select
                  id="assetid"
                  value={getAddGroupNutritionFeedType}
                  onChange={(e) => setAddGroupNutritionFeedType(e.target.value)}
                  label="assetid"
                >
                  <MenuItem key="carbohy- drates " value="carbohy- drates ">
                  carbohy- drates 
                  </MenuItem>
                  <MenuItem key="Nectar" value="Nectar">
                  Nectar
                  </MenuItem>
                  <MenuItem key="DrySugar" value="DrySugar">
                  DrySugar
                  </MenuItem>
                 
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Schedule :</InputLabel>
                <Select
                  id="Schedule"
                  value={getAddGroupNutritionSchedule}
                  onChange={(e) => setAddGroupNutritionSchedule(e.target.value)}
                  label="Schedule"
                >
                  <MenuItem key="Morning" value="Morning">
                    Morning
                  </MenuItem>
                  <MenuItem key="Afternoon" value="Afternoon">
                    Afternoon
                  </MenuItem>
                  <MenuItem key="Evening" value="Evening">
                    Evening
                  </MenuItem>
                  <MenuItem key="Night" value="Night">
                    Night
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={latitudeValue}
                  onChange={(e) => {
                    setLatitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={longitudeValue}
                  onChange={(e) => {
                    setLongitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                <Input
                  id="altitude"
                  name="altitude"
                  type="text"
                  value={getAddGroupNutritionAltitude}
                  onChange={(e) => {
                    setAddGroupNutritionAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getAddGroupNutritionDescription}
                  onChange={(e) => {
                    setAddGroupNutritionDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  createGroupNutritionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowAddGroupNutritionModel(!showAddGroupNutritionModel);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Group Nutrition Details */}

          {/* Delete Group Nutrition Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showDeleteGroupNutritionModel}
            aria-labelledby="deletenutrition"
          >
            <DialogTitle id="deletenutrition">
              {"Delete Group Nutrition"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  groupNutritionDeleteHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowDeleteGroupNutritionModel(
                    !showDeleteGroupNutritionModel
                  );
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Group Nutrition Details */}

          {/* Update Group Nutrition Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showUpdateGroupNutritionModel}
            aria-labelledby="groupnutritiondetails"
          >
            <DialogTitle id="groupnutritiondetails">
              {"Update Group Nutrition details"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteid">Site ID :</InputLabel>
                <Input
                  id="siteid"
                  name="assetid"
                  type="text"
                  value={getUpdateGroupNutritionSiteid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Asset Friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getUpdateGroupNutritionFriendlyname}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User ID :</InputLabel>
                <Input
                  id="userid"
                  name="userid"
                  type="text"
                  value={getUpdateGroupNutritionUserid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="prevfeedtype">Prev Feed Type:</InputLabel>
                <Input
                  id="prevfeedtype"
                  name="prevfeedtype"
                  type="text"
                  value={prevgetUpdateGroupNutritionFeedType}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Feed Type :</InputLabel>
                <Select
                  id="assetid"
                  value={getUpdateGroupNutritionFeedType}
                  onChange={(e) =>
                    setUpdateGroupNutritionFeedType(e.target.value)
                  }
                  label="assetid"
                >
                  <MenuItem key="Milk" value="Milk">
                    Milk
                  </MenuItem>
                  <MenuItem key="Colstrum" value="Colstrum">
                    Colstrum
                  </MenuItem>
                  <MenuItem key="Rough-Age" value="Rough-Age">
                    Rough-Age
                  </MenuItem>
                  <MenuItem key="Concentrate" value="Concentrate">
                    Concentrate
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="prevshedule">Prev Schedule:</InputLabel>
                <Input
                  id="prevshedule"
                  name="prevshedule"
                  type="text"
                  value={prevgetUpdateGroupNutritionSchedule}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Schedule :</InputLabel>
                <Select
                  id="Schedule"
                  value={getUpdateGroupNutritionSchedule}
                  onChange={(e) =>
                    setUpdateGroupNutritionSchedule(e.target.value)
                  }
                  label="Schedule"
                >
                  <MenuItem key="Morning" value="Morning">
                    Morning
                  </MenuItem>
                  <MenuItem key="Afternoon" value="Afternoon">
                    Afternoon
                  </MenuItem>
                  <MenuItem key="Evening" value="Evening">
                    Evening
                  </MenuItem>
                  <MenuItem key="Night" value="Night">
                    Night
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={getUpdateGroupNutritionLatitude}
                  onChange={(e) => {
                    setUpdateGroupNutritionLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={getUpdateGroupNutritionLongitude}
                  onChange={(e) => {
                    setUpdateGroupNutritionLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                <Input
                  id="altitude"
                  name="altitude"
                  type="text"
                  value={getUpdateGroupNutritionAltitude}
                  onChange={(e) => {
                    setUpdateGroupNutritionAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getUpdateGroupNutritionDescription}
                  onChange={(e) => {
                    setUpdateGroupNutritionDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateGroupNutritionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowUpdateGroupNutritionModel(
                    !showUpdateGroupNutritionModel
                  );
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Group Nutrition Details */}

          {/* New Site Group Attendance Location */}
          <Dialog
            fullScreen={fullScreen}
            open={showSiteNewAttendanceLocationPopup}
            aria-labelledby="siteattendance"
          >
            <DialogTitle id="siteattendance">
              {"New Site Attendance Location"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetid">Site id :</InputLabel>
                <Input
                  id="assetid"
                  name="assetid"
                  type="text"
                  value={getSiteNewAttendanceLocationAssetid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Site friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getSiteNewAttendanceLocationAssetfriendlyName}
                  onChange={(e) => {
                    setSiteNewAttendanceLocationAssetfriendlyName(
                      e.target.value
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User id :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getSiteNewAttendanceLocationUserid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Latitude">Latitude :</InputLabel>
                <Input
                  id="Latitude"
                  name="Latitude"
                  type="text"
                  value={latitudeValue}
                  onChange={(e) => {
                    setLatitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Longitude">Longitude:</InputLabel>
                <Input
                  id="Longitude"
                  name="Longitude"
                  type="text"
                  value={longitudeValue}
                  onChange={(e) => {
                    setLongitudeValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Altitude">Altitude :</InputLabel>
                <Input
                  id="Altitude"
                  name="Altitude"
                  type="text"
                  value={getSiteNewAttendanceLocationAltitude}
                  onChange={(e) => {
                    setSiteNewAttendanceLocationAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getSiteNewAttendanceLocationDescription}
                  onChange={(e) => {
                    setSiteNewAttendanceLocationDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  addSiteAttendanceLocationHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setSiteNewAttendanceLocationPopup(
                    !showSiteNewAttendanceLocationPopup
                  );
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of New Site Group Attendance Location */}

          {/* Delete Site Group Attendance Location */}
          <Dialog
            fullScreen={fullScreen}
            open={getSiteDeletedAttendancePopup}
            aria-labelledby="sitedelete"
          >
            <DialogTitle id="sitedelete">
              {"Delete Site Attendance Location"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  deleteSiteAttendanceAsset();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setSiteDeletedAttendancePopup(!getSiteDeletedAttendancePopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Site Group Attendance Location */}

          {/* Update Site Group Attendance Location Info */}
          <Dialog
            fullScreen={fullScreen}
            open={showSiteUpdateAttendaneLocationPopup}
            aria-labelledby="siteattandanceupdate"
          >
            <DialogTitle id="siteattandanceupdate">
              {"Update Site Attendance Location Info"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteid">Site id :</InputLabel>
                <Input
                  disabled
                  id="siteid"
                  name="siteid"
                  type="text"
                  value={getSiteUpdateAttendanceLocationInfoAssetid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">Site Name :</InputLabel>
                <Input
                  disabled
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getSiteUpdateAttendanceLocationInfoAssetfriendlyName}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User id :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getSiteUpdateAttendanceLocationInfoUserid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Latitude">Latitude :</InputLabel>
                <Input
                  id="Latitude"
                  name="Latitude"
                  type="text"
                  value={getSiteUpdateAttendanceLocationInfoLatitude}
                  onChange={(e) => {
                    setSiteUpdateAttendanceLocationInfoLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Longitude">Longitude:</InputLabel>
                <Input
                  id="Longitude"
                  name="Longitude"
                  type="text"
                  value={getSiteUpdateAttendanceLocationInfoLongitude}
                  onChange={(e) => {
                    setSiteUpdateAttendanceLocationInfoLongitude(
                      e.target.value
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Altitude">Altitude :</InputLabel>
                <Input
                  id="Altitude"
                  name="Altitude"
                  type="text"
                  value={getSiteUpdateAttendanceLocationInfoAltitude}
                  onChange={(e) => {
                    setSiteUpdateAttendanceLocationInfoAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getSiteUpdateAttendanceLocationInfoDescription}
                  onChange={(e) => {
                    setSiteUpdateAttendanceLocationInfoDescription(
                      e.target.value
                    );
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateSiteAttendanceLocationsHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setSiteUpdateAttendaneLocationPopup(
                    !showSiteUpdateAttendaneLocationPopup
                  );
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Site Group Attendance Location Info */}
          {/* Create Site Alarm */}
          <Dialog
            fullScreen={fullScreen}
            open={siteAlarmPopup}
            onClose={() => {
              setSiteAlarmPopup(!siteAlarmPopup);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Create Site Alarm"}
            </DialogTitle>
            <DialogContent style={{ minWidth: "600px" }}>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="solutionId">Source:</InputLabel>
                <Input
                  disabled
                  id="solutionId"
                  name="solutionId"
                  type="text"
                  value={parentId}
                />
              </FormControl>

              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>AssetVertical Id:</InputLabel>
                <Select
                  id="assetVerticalID"
                  name="assetVerticalID"
                  type="text"
                  value={selectedVerticalsId}
                  onChange={(event) => {
                    setSelectedVerticalsIdHandler(event);
                  }}
                >
                  {verticalsIdList &&
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Category</InputLabel>
                <Select
                  id="selectedCategory"
                  value={selectedCategory}
                  onChange={(event) => {
                    setSelectedCategoryHandler(event);
                  }}
                  label="Select Category"
                >
                  {categoryTypesList &&
                    categoryTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.categoryType}>
                        {idObj.categoryType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Type</InputLabel>
                <Select
                  id="selectedAssertTypes"
                  value={selectedAssertTypes}
                  onChange={(event) => {
                    setSelectedAssertTypesHandler(event);
                  }}
                  label="Select Type"
                >
                  {assertTypesList &&
                    assertTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetType}>
                        {idObj.assetType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Model</InputLabel>
                <Select
                  id="selectedModel"
                  value={selectedModel}
                  onChange={(event) => {
                    setSelectedModel(event.target.value);
                  }}
                  label="Select Model"
                >
                  {modelList &&
                    modelList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.modelid}>
                        {idObj.modelfriendlyname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteId">Site Id:</InputLabel>
                <Input
                  disabled
                  id="siteId"
                  name="siteId"
                  type="text"
                  value={resourceId}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteId">Alarm Category:</InputLabel>
                <Input
                  id="siteId"
                  name="siteId"
                  type="text"
                  value={alarmCategoryValue}
                  onChange={(e) => {
                    setAlarmCategoryValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteId">Alarm Name:</InputLabel>
                <Input
                  id="siteId"
                  name="siteId"
                  type="text"
                  value={alarmNameValue}
                  onChange={(e) => {
                    setAlarmNameValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  submitSitealarmClick();
                }}
              >
                Submit
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  setSiteAlarmPopup(!siteAlarmPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Asset Attendance */}
          <Dialog
            fullScreen={fullScreen}
            open={assetAttendanceDialog}
            //onClose={setAssetAttendanceDialog(assetAttendanceDialog)}
            aria-labelledby="assetAttendance"
          >
            <DialogTitle id="assetAttendance">
              {"View Asset Attendance"}
            </DialogTitle>
            <DialogContent>
              {Object.values(assetAttendanceOnj).length > 1 ? (
                <>
                  <p>
                    <span> Altitude :{assetAttendanceOnj.altitude}</span>
                  </p>
                  <p>Latitude : {assetAttendanceOnj.latitude}</p>
                  <p>Longitude : {assetAttendanceOnj.longitude}</p>
                  <p>
                    Assetfriendlyname : {assetAttendanceOnj.assetfriendlyname}
                  </p>
                  <p>Parentid :{assetAttendanceOnj.parentid}</p>
                  <p>Userid : {assetAttendanceOnj.userid}</p>
                  <p>Assetid : {assetAttendanceOnj.assetid}</p>
                  <p>
                    <span>Description :{assetAttendanceOnj.description}</span>
                  </p>
                  <p>
                    Timestamp:
                    {assetAttendanceOnj.timestamp}
                  </p>
                </>
              ) : (
                <h4>Data Not Found</h4>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setAssetAttendanceDialog(!assetAttendanceDialog);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Asset Attendance  */}
          {/* Site Env  */}
          <Dialog
            fullScreen={fullScreen}
            open={siteEnvPopup}
            onClose={() => {
              setSiteEnvPopup(!siteEnvPopup);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Site Environment Info"}
            </DialogTitle>
            <DialogContent style={{ minWidth: "600px" }}>
              <p>
                <b>Site Id :</b>
                {siteEnvData.siteid}
              </p>
              <p>
                <b>Device Type :</b>
                {siteEnvData.devicetype}
              </p>
              <p>
                <b>Network Id :</b>
                {siteEnvData.networkid}
              </p>

              <p>
                <b>Updated Date :</b>
                {siteEnvData.updatedat}
              </p>
              <p>
                <b>Number of Properties : </b>
                {siteEnvData.NumberofProperties}
              </p>

              {siteEnvData.properties !== undefined ? (
                siteEnvData.properties.map((prop, index) => {
                  return (
                    <div key={`${index} `}>
                      <p>
                        <b>Property : &nbsp;</b>
                        {prop.property} &nbsp;&nbsp;{prop.value}
                      </p>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  setSiteEnvData({});
                  setSiteEnvPopup(!siteEnvPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* Qr code Generate */}

        <Dialog
          fullScreen={fullScreen}
          open={qrCodeDialog}
          onClose={() => {
            setQrCodeDialog(!qrCodeDialog);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Generate QR Code"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="AssetVerticalId">Site Name:</InputLabel>
              <Input
                id="AssetVerticalId"
                name="AssetVerticalId"
                type="text"
                value={selectedSite.sitename}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="AssetVerticalName">Site Type:</InputLabel>
              <Input
                id="AssetVerticalName"
                name="AssetVerticalName"
                type="text"
                value={selectedSite.sitetype}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="category">Site Id:</InputLabel>
              <Input
                id="category"
                name="category"
                type="text"
                value={selectedSite.siteid}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="bname">Branch Name:</InputLabel>
              <Input id="bname" name="bname" type="text" value={username} />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="bname">Url:</InputLabel>
              <Input
                id="bname"
                name="bname"
                type="text"
                value={qrUrlValue}
                onChange={(e) => setQrUrlValue(e.target.value)}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                submitQrDialogHandler(selectedSite);
              }}
            >
              Generate
            </Button>{" "}
            &nbsp;
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                setQrCodeDialog(!qrCodeDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <CustomPopup
          closeButton={closeSiteWithNodeModal}
          isOpen={showCreateSitePopup}
          closeModal={showCreateSitePopup}
          content={siteCreationWithNodeModelBody}
          title={siteAndNodeCreationTitle}
        />
        <CustomPopup
          closeButton={closeSiteCreationWithNodeModal}
          isOpen={showCreateSiteDialog}
          closeModal={showCreateSiteDialog}
          content={siteCreationModelBody}
          title={siteAndNodeCreationTitle}
        />
        <CustomPopup
          closeButton={closeSiteModal}
          isOpen={getSitePopUp}
          closeModal={getSitePopUp}
          content={siteModelBody}
          title={siteCreationTitle}
        />
        <CustomPopup
          closeButton={closeSiteRatingModal}
          isOpen={getSiteRatePopup}
          closeModal={getSiteRatePopup}
          content={siteRateModel}
          title={siteRateAgreement}
        />
        <CustomPopup
          closeButton={closeSiteInfoModal}
          isOpen={setSiteInfoPopup}
          closeModal={setSiteInfoPopup}
          content={SiteInfoBody}
          title={siteInfoHeader}
        />
        <CustomPopup
          closeButton={closeSiteUpdateModal}
          isOpen={getSiteUpdatePopup}
          closeModal={getSiteUpdatePopup}
          content={SiteUpdateBody}
          title={siteUpdateHeader}
        />
        <CustomPopup
          closeButton={closeNodeInfoModal}
          isOpen={getNodeInfoPopup}
          closeModal={getNodeInfoPopup}
          content={NodeInfoBody}
          title={nodeInfoHeader}
        />

        <CustomPopup
          closeButton={closeUploadPhotoModal}
          isOpen={uploadPhotoPopup}
          closeModal={uploadPhotoPopup}
          content={uploadPhotoBody}
          title={titleUploadPhoto}
        />
        <CustomPopup
          closeButton={closeUpdateAssetStatusModel}
          isOpen={legacyUpdate}
          closeModal={legacyUpdate}
          content={updateLegacyAssetData}
          title={updateLegacyAsset}
        />
        <CustomPopup
          closeButton={closeUpdateAssetInfoModel}
          isOpen={assetInfoUpdatePopup}
          closeModal={assetInfoUpdatePopup}
          content={updateLegacyAssetInfoData}
          title={updateLegacyAssetInfo}
        />
      </div>
    </>
  );
}
