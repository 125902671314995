import GridIcon from "@material-ui/icons/GridOnOutlined";
import ListIcon from "@material-ui/icons/List";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import SearchBar from "material-ui-search-bar";
import React, { useEffect, useMemo, useState } from "react";
import { FiThumbsUp } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import ReactPlayer from "react-player/lazy";
import MainService from "../../Common/MainService";
import PlaceholderCardList from "../ModelProductManagement/PlaceHolderCardList";
import ProductCustomTable from "../ModelProductManagement/ProductsCustomised/ProductCustomTable";
import "./BuyAgriProducts.css";
import ContactBuyAgriAssets from "./ContactBuyAgriAssets";
import ProductsContractManufacturing from "./ProductsContractManufacturing";
import RawProductsContractManufacturing from "./RawProductsContractFarming";

const displayFields = [
  "productid",
  "productcost",
  "discount",
  "discountedprice",
  "unit",
  "country",
  "itemcount",
  "status",
];

const buttonSize = {
  typeOne: {
    width: "45%",
    mx: "auto",
    my: "0.1rem",
    textTransform: "none",
    display: "block",
    border: "1px solid #3ECF8E",
    color: "#3ECF8E",
    ":hover": {
      border: "1px solid #3ECF8E",
      color: "#3ECF8E",
    },
  },
  typeTwo: {
    width: "70%",
    mx: "auto",
    my: "0.1rem",
    textTransform: "none",
    display: "block",
    border: "1px solid #3ECF8E",
    color: "#3ECF8E",
    ":hover": {
      border: "1px solid #3ECF8E",
      color: "#3ECF8E",
    },
  },
};

const BuyAgriProducts = ({ categoryType, type, reqType }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { user } = MainService.getLocalData();
  const { parentId } = user;
  const [setFilterProducts, getFilterProducts] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [getProductsData, setProductsData] = useState([]);
  const [getListView, setListView] = useState(false);
  const [getGridView, setGridView] = useState(true);
  const [getCategoriesList, setCategoriesList] = useState("");
  const [getBrands, setBrands] = useState([]);
  const [getBrandName, setBrandName] = useState("");
  const [getBrandSubCategories, setBrandSubCategories] = useState([]);
  const [getBrandSubCategoryValue, setBrandSubCategoryValue] = useState("");
  const [showContactModel, hideContactModel] = useState(false);
  const [getConsumerInfo, setConsumerInfo] = useState({});
  const [getContractManufacturing, setContractManufacturing] = useState(false);
  const [getProductDetails, setProductDetails] = useState({});
  const [getCFRawProducts, setCFRawProducts] = useState(false);

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterProducts?.filter((row) => {
      return row.legacyassettype
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    setProductsData(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(() => {
    if (!getCategoriesList) return;
    getAllModelProducts();
  }, [getCategoriesList]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    for (let i = 0; i < type?.length; i++) {
      allConsumerUsersLoop(parentId, type[i]);
    }
  };

  const categorysList = async () => {
    await MainService.getListOfCategorys()
      .then((res) => {
        const filterCategory = res?.filter((data) => {
          return data.categoryname.includes(categoryType);
        });
        setCategoriesList(filterCategory[0]?.categoryid);
        getSubCategoriesBasedonCategoryIDandParentId(
          parentId,
          filterCategory[0]?.categoryid
        );
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  useMemo(() => {
    categorysList();
  }, []);

  /** Grid and List */
  const viewsOfListandGrid = (Type) => {
    if (Type === "List") {
      setListView(true);
      setGridView(false);
    } else if (Type === "Grid") {
      setListView(false);
      setGridView(true);
    }
  };
  /**EOD Grid and List */

  /** Get Products */
  const getAllModelProducts = async () => {
    setIsLoading(true);
    setBrandName("");
    setBrandSubCategoryValue("");
    await MainService.getProductsBasedonCategoryID(getCategoriesList)
      .then((data) => {
        setProductsData(data?.products);
        getFilterProducts(data?.products);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error(e || "something went wrong");
      });
  };
  /** EOD Get Products */

  /** Brand */
  const allConsumerUsersLoop = async (parentVal, Type) => {
    await MainService.allConsumerUsersLoop(parentVal, Type)
      .then((data) => {
        setBrands((prevState) =>
          [...prevState, data.users]?.flatMap((val) => val)
        );
      })
      .catch((e) => {
        return NotificationManager.error(e || "something went wrong");
      });
  };

  const brandSelectionHandler = async (value) => {
    setIsLoading(true);
    if (value === undefined || value === null) return;
    setBrandSubCategoryValue("");
    setBrandName(value);
    await MainService.getModelProductsBasedOnCategoryandUser(
      parentId,
      value,
      getCategoriesList
    )
      .then((data) => {
        setProductsData(data?.products);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error(e || "something went wrong");
      });
  };
  /**EOD Brand */

  /** Get Sub-Categories */
  const getSubCategoriesBasedonCategoryIDandParentId = async (pid, catid) => {
    await MainService.getSubCategoriesBasedonCategoryIDandParentId(pid, catid)
      .then((data) => {
        setBrandSubCategories(data?.contentsubcategories);
      })
      .catch((e) => {
        return NotificationManager.error(e || "something went wrong");
      });
  };

  const selectedSubCategoryHandler = (value) => {
    setBrandName("");
    setBrandSubCategoryValue(value);
    const filterData = setFilterProducts?.filter((data) => {
      return data.contentsubcategory
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    setProductsData(filterData);
  };
  /** EOD Get Sub-Categories */

  /** Like Count */
  const likeCount = async (product) => {
    await MainService.agriProductLikeCount(
      parentId,
      product.businessid,
      product.productid
    )
      .then((data) => {
        getAllModelProducts();
        return NotificationManager.success(data);
      })
      .catch((e) => {
        return NotificationManager.error(e || "something went wrong");
      });
  };

  /** Contact Model */
  const contactModelHandler = (product) => {
    MainService.getConsumerUserInfo(parentId, product.businessid)
      .then((data) => {
        setConsumerInfo(Object.assign({}, data[0]));
      })
      .catch(() => {
        return NotificationManager.error("No data found");
      });
    hideContactModel(!showContactModel);
  };
  /** EOD Contact Model */

  /** Contract Manufacturing Model */
  const contractManufacturingHandler = (product) => {
    setContractManufacturing(!getContractManufacturing);
    setProductDetails(product);
  };
  /** EOD Contract Manufacturing Model */

  /** Contract Farming Raw Products */
  const contractRawProductsFarmingHandler = (product) => {
    setCFRawProducts(!getCFRawProducts);
    setProductDetails(product);
  };
  /** EOD Contract Farming Raw Products */

  return (
    <>
      <Box className="container" id="mainContentList" sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Brand
              </InputLabel>
              <Select
                id="brand"
                value={getBrandName}
                onChange={(e) => brandSelectionHandler(e.target.value)}
                label="brand"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: "200px", // Set the maximum height of the dropdown
                      width: "200px", // Set the width of the dropdown
                    },
                  },
                }}
              >
                {getBrands?.length > 0 ? (
                  getBrands &&
                  getBrands.map((idObj, index) => (
                    <MenuItem
                      key={index + idObj.username}
                      value={idObj.username}
                    >
                      {idObj.username}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No data found</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Sub Categories
              </InputLabel>
              <Select
                id="selectedCategory"
                value={getBrandSubCategoryValue}
                onChange={(event) => {
                  selectedSubCategoryHandler(event.target.value);
                }}
                label="Select Category"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: "200px", // Set the maximum height of the dropdown
                      width: "200px", // Set the width of the dropdown
                    },
                  },
                }}
              >
                {getBrandSubCategories &&
                  getBrandSubCategories.map((idObj, index) => (
                    <MenuItem
                      key={`${index} `}
                      value={idObj.contentsubcategoryname}
                    >
                      {idObj.contentsubcategoryname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          {getBrandName !== "" || getBrandSubCategoryValue !== "" ? (
            <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
              <Button
                variant="none"
                onClick={() => {
                  getAllModelProducts();
                }}
              >
                Clear Filter
              </Button>
            </Box>
          ) : null}
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "70%",
                md: "auto",
              },
            }}
          >
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                viewsOfListandGrid("Grid");
              }}
            ></Button>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                viewsOfListandGrid("List");
              }}
            ></Button>
          </Box>
        </Box>

        <Box>
          {getListView === true ? (
            <Box>
              <Box sx={{ m: "0" }}>
                {isLoading ? (
                  <Box>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                  </Box>
                ) : (
                  <ProductCustomTable
                    getTableProducts={getProductsData}
                    displayFields={displayFields}
                    productType={categoryType}
                  />
                )}
              </Box>
            </Box>
          ) : null}

          {getGridView === true ? (
            <Box sx={{ m: 0, width: "100%" }}>
              <Box sx={{ m: 0, pb: 1, width: "30%" }}>
                <SearchBar
                  value={farmgridsearched}
                  onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                  onCancelSearch={() => cancelFarmGridSearch()}
                />
              </Box>
              {isLoading ? (
                <PlaceholderCardList
                  numberOfCards={getProductsData.length || 4}
                  heightVal={500}
                />
              ) : (
                <>
                  {Array.isArray(getProductsData) &&
                  getProductsData?.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-evenly", //space-evenly
                        gap: "1rem",
                        flexDirection: "row",
                        minWidth: "100%", // Added this line to set minimum width to 100%
                      }}
                    >
                      {getProductsData.map((product, index) => (
                        <Box
                          key={index}
                          sx={{
                            minWidth: "45%", // Adjust as needed
                            maxWidth: {
                              sx: "100%",
                              sm: "100%",
                              md: "45%",
                              lg: "45%",
                              xl: "45%",
                            }, // Adjust as needed
                            borderRadius: "10px",
                            padding: "0.5rem",
                            boxShadow:
                              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              height: "200px",
                              ":hover": {
                                cursor: "initial",
                              },
                            }}
                          >
                            {(() => {
                              switch (product.productimageurl) {
                                case "":
                                  break;
                                case "null":
                                  return (
                                    <Box
                                      sx={{
                                        height: "100%",
                                        width: "100%",
                                        borderTopLeftRadius: "8px",
                                        borderTopLeftRadius: "8px",
                                        backgroundColor: "#e6e6e6",
                                      }}
                                    ></Box>
                                  );
                                  break;
                                default:
                                  return (
                                    <img
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        borderTopLeftRadius: "8px",
                                        borderTopLeftRadius: "8px",
                                      }}
                                      src={product.productimageurl}
                                    ></img>
                                  );
                              }
                            })()}
                          </Box>
                          <div className="mt-4 mb-4">
                            <div className="explore-prooduct-card-body">
                              <div className="explore-prooduct-card-body-header d-flex justify-content-between align-items-center mb-2">
                                <span className="product-name d-block mb-0">
                                  {product.productfriendlyname}
                                </span>
                                <p className="products-like mb-0 d-flex align-items-end">
                                  <span
                                    className="d-inline-block"
                                    onClick={() => {
                                      likeCount(product);
                                    }}
                                  >
                                    <FiThumbsUp size={30} />
                                  </span>
                                  <small>{product.likecount}</small>
                                </p>
                              </div>
                              <h2 className=" mt-0 mb-3">
                                {product.businessid}
                              </h2>
                              <span>
                                <p>
                                  <b>Product Id: </b>
                                  {product.productid}
                                </p>
                                <p>
                                  <b>Origin: </b>
                                  {product.country}
                                </p>
                                <p>
                                  <b>Product Cost: </b>
                                  <span className="product-original-price">
                                    {product.productcost} {product.unit}
                                  </span>
                                </p>
                                {(() => {
                                  switch (product.discount) {
                                    case "":
                                      break;
                                    case 0:
                                      break;
                                    default:
                                      return [
                                        <div>
                                          <p>
                                            <b>Discounted Price: </b>
                                            {product.discountedprice}{" "}
                                            {product.unit}
                                          </p>
                                        </div>,
                                      ];
                                  }
                                })()}
                                <p>
                                  <b>Item Count: </b>
                                  {product.itemcount}
                                </p>
                                {(() => {
                                  switch (product.productqrcodeurl) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <img
                                            src={product.productqrcodeurl}
                                            className="img-qr"
                                          ></img>
                                        </p>
                                      );
                                  }
                                })()}
                                {(() => {
                                  switch (product.qacertificateurl) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <img
                                            src={product.qacertificateurl}
                                            className="img-thumbnail"
                                          ></img>
                                        </p>
                                      );
                                  }
                                })()}
                                {(() => {
                                  switch (product.labcertificate) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <img
                                            src={product.labcertificate}
                                            className="img-thumbnail"
                                          ></img>
                                        </p>
                                      );
                                  }
                                })()}
                                {(() => {
                                  switch (product.productvideourl) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <ReactPlayer
                                            className="product-video"
                                            url={product.productvideourl}
                                            pip={true}
                                            controls={true}
                                            playing={false}
                                            muted={true}
                                          />
                                        </p>
                                      );
                                  }
                                })()}
                                {(() => {
                                  switch (product.producthistoryurl) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <a
                                            href={product.producthistoryurl}
                                            target="_blank"
                                          >
                                            Product History
                                          </a>
                                        </p>
                                      );
                                  }
                                })()}
                                <p>
                                  <b>Status: </b>
                                  {product.status}
                                </p>
                              </span>
                            </div>
                          </div>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              gap: "0.1rem",
                            }}
                          >
                            <Button
                              type="button"
                              variant="outlined"
                              sx={buttonSize.typeOne}
                              onClick={() => contactModelHandler(product)}
                            >
                              Contact
                            </Button>
                            <Button
                              type="button"
                              variant="outlined"
                              sx={buttonSize.typeOne}
                            >
                              Buy
                            </Button>
                            {(() => {
                              if (
                                categoryType === "RAW-PRODUCTS" ||
                                categoryType === "AGRI-ASSETS"
                              ) {
                                return (
                                  <Button
                                    type="button"
                                    variant="outlined"
                                    sx={buttonSize.typeTwo}
                                    onClick={() => {
                                      contractRawProductsFarmingHandler(
                                        product
                                      );
                                    }}
                                  >
                                    Contract Farming
                                  </Button>
                                );
                              }
                            })()}
                            {(() => {
                              if (
                                categoryType === "PROCESSED-PRODUCTS" ||
                                categoryType === "SEMI-PROCESSED"
                              ) {
                                return (
                                  <Button
                                    type="button"
                                    variant="outlined"
                                    sx={buttonSize.typeTwo}
                                    onClick={() => {
                                      contractManufacturingHandler(product);
                                    }}
                                  >
                                    Contract Manufactur
                                  </Button>
                                );
                              }
                            })()}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-evenly",
                        gap: "1rem",
                        flexDirection: "row",
                        minWidth: "100%", // Added this line to set minimum width to 100%
                      }}
                    >
                      <Box
                        sx={{
                          maxWidth: "45%", // Adjust as needed
                          borderRadius: "10px",
                          padding: "10px",
                          margin: "10px",
                          boxShadow:
                            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                          textAlign: "center",
                        }}
                      >
                        {categoryType} NOT FOUND
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
      <ContactBuyAgriAssets
        openModel={showContactModel}
        closeModel={contactModelHandler}
        props={getConsumerInfo}
      />

      <ProductsContractManufacturing
        openModel={getContractManufacturing}
        closeModel={contractManufacturingHandler}
        productInfo={getProductDetails}
        reqTypes={reqType}
      />

      <RawProductsContractManufacturing
        openModel={getCFRawProducts}
        closeModel={contractRawProductsFarmingHandler}
        productInfo={getProductDetails}
      />
    </>
  );
};
export default BuyAgriProducts;
