import React, { useState } from "react";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Badge,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import AuthAxiosInstance from "../../../authAxiosInstance";
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { CheckCircleOutline, Cancel } from '@mui/icons-material';
import { NotificationManager } from "react-notifications";
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// toast.configure({
//     autoClose: 3000,
//     draggable: false,
//     //etc you get the idea
// });

const Ota = () => {
    const [deviceType, setDeviceType] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [deviceProperty, setDeviceProperty] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [type, selectedType] = useState("");
    const [model, selectedModel] = useState("");
    const [firmwareButtonEnable, setFirmwareButtonEnable] = useState(false);
    let [primary, setPrimary] = useState(false);
    let [errors, setError] = useState("");
    let [accordion, setAccordion] = useState([true]);
    let [disabledstate, setDisabled] = useState(true);
    let [devicetypenew, setDevicetypenew] = useState("");
    let [modelinfo, setModelinfo] = useState("");
    let [modelname, setModelname] = useState("");
    let [createnewrecord, setCreatenewrecord] = useState(true);
    let [deleteconfirm, setDeleteconfirm] = useState(false);
    let [wrongformat, setWrongformat] = useState(false);
    let [wrongmin, setWrongmin] = useState(false);
    let [wrongspecial, setWrongspecial] = useState(false);
    let [wrongmincount, setWrongmincount] = useState(0);
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;



    const getdata = () => {
        AuthAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/list`).then((res) => {

            if (res.data)
                setDeviceType(res.data);
            else
                setDeviceType([]);
        }).catch((err) => {
            setDeviceType([]);
        });

    }

    React.useEffect(() => {

        //Getting Device Type List Axios Instance


        getdata();


        validatefield();


    }, [devicetypenew, modelinfo, modelname]);

    const selectDevice = (evt) => {
        //const filterModelDevice = deviceList.filter((d) => (d.deviceType === evt.target.value));
        selectedType(evt.target.value);
        //setModelList(filterModelDevice);
        setModelList([]);
        selectedModel("");
        AuthAxiosInstance.get(`/admin/deviceprofile/${username}/${evt.target.value}/models`).then((res) => {
            setModelList(res.data);
        }).catch((err) => {
            setModelList([]);
        });
        setDeviceProperty([]);
    }
    const modelName = (evt) => {
        setModelname(evt.target.value);
        if (evt.target.value.length < devicetypenew.length) {
            setWrongmincount(devicetypenew.length);
            setWrongmin(true);
        }
        else {
            setWrongmin(false);
        }
        let nospecial = /[*|\":<>[\]{}`\\()';@&$!#^=+%`~,./? _\-]/;
        if (nospecial.test(evt.target.value)) {
            setWrongspecial(true);
        }
        else {
            setWrongspecial(false);
        }
    }

    const modelInfo = (evt) => {
        try {
            setModelinfo(JSON.stringify(JSON.parse(evt.target.value), undefined, 4));
            setWrongformat(false);
        }
        catch (e) {
            setModelinfo(evt.target.value);
            setWrongformat(true);

        }
    }

    function validatefield() {
        if (modelname && devicetypenew && modelinfo) {
            if (wrongformat) {
                setDisabled(true);
                setError("Please fill model info in correct JSON format");
            }
            else {
                if (wrongmin) {
                    setDisabled(true);
                    setError(`Minimum length for model name is ${wrongmincount}`);
                }
                else {
                    if (wrongspecial) {
                        setDisabled(true);
                        setError(`No Special characters allowed in model name`);
                    }
                    else {
                        setDisabled(false);
                        setError("");
                    }
                }

            }
        }
        else {
            setDisabled(true);
            setError("Please fill all the fields");
        }
    }

    const selectModel = (evt) => {
        selectedModel(evt.target.value);
        setDeviceProperty([]);
    }

    const selectDeviceNew = (evt) => {
        setDevicetypenew(evt.target.value);
        setModelname(evt.target.value);
        setWrongmin(false);
        setWrongspecial(false);
        AuthAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/${evt.target.value}`)
            .then((response) => {
                if (response && response.data && response.data.metadata)
                    setModelinfo(response.data.metadata);
            }).catch((err) => {
                // toast.error("Some error occured", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            });
    }

    const togglePrimary = () => {
        setPrimary(!primary);
        setModelinfo('');
        setModelname('');
        setDevicetypenew('');
        setError("");
    }

    const deleteProperty = () => {
        setDeleteconfirm(!deleteconfirm);
    }

    const toggledeleteconfirm = () => {
        setDeleteconfirm(!deleteconfirm);
    }

    const onclickdelete = () => {
        AuthAxiosInstance.delete(`/admin/deviceprofile/${username}/modelInfo/${type}/${model}`)
            .then((response) => {
                setDeleteconfirm(!deleteconfirm);
                // toast.success("Model info deleted successfully", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                let e = {};
                e.target = {};
                e.target.value = type;
                selectDevice(e);
                setDeviceProperty([]);
            }).catch((err) => {
                // toast.error("Some error occured", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            });
    }

    const toggleAccordion = (tab) => {
        const prevState = accordion;
        const state = prevState.map((x, index) => tab === index ? !x : false);
        setAccordion(state);
    }

    const createnew = () => {
        setPrimary(true);
        setCreatenewrecord(true);
        setError('');
    }

    const ConfigUpdate = () => {
        alert(type, model)
    }

    const applicationUpdate = () => {
        alert(model)
        // setPrimary(true);
        // setDevicetypenew(type);
        // setModelname(model);
        // setModelinfo(JSON.stringify(modelinfo, undefined, 4));
        // setCreatenewrecord(false);

        // AuthAxiosInstance.get(`/admin/deviceprofile/${networkId.network}/deviceTypes/${type}`)
        //             .then((response) => {
        //                 if(response && response.data && response.data.metadata)
        //                     setModelinfo(response.data.metadata); 
        //             }).catch((err) => {
        //                 toast.error("Some error occured", {
        //                     position: toast.POSITION.TOP_RIGHT
        //                 });
        //             });

    }

    const firmwareUpdate = () => {
        console.log(type, model);
        alert(type, model)

    }
    const osUpdate = () => {
        console.log(type, model);
        alert(type, model)

    }



    return (
        <div className="animated fadeIn" >
            <i className="fa fa-arrow-circle-up"></i> <strong className="LabelHeader">OTA</strong>
            <Col xs="12" md="12">
                <Grid container spacing={2} style={{marginTop:"10px"}}>
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-outlined-label-type">Device Type</InputLabel>
                            <Select labelId="demo-simple-select-outlined-label-type" id="demo-simple-select-outlined"  label="Package Type" value={type} onChange={selectDevice.bind(this)}>
                                <MenuItem value="">Select Device Type</MenuItem>
                                {deviceType.map((d, i) => (
                                    <MenuItem value={d} key={i + 1}>
                                        {d}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-outlined-label-type1">Device Model</InputLabel>
                            <Select labelId="demo-simple-select-outlined-label-type1" id="demo-simple-select-outlined1"  label="Package Type" value={model} onChange={selectModel.bind(this)}>
                                <MenuItem value="">Select Device Model</MenuItem>
                                {modelList.map((d, i) => (
                                    <MenuItem value={d} key={i + 1}>
                                        {d}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={1.5} >
                        <Button className="custom-button" disabled={type ? (model ? false : true) : true} onClick={ConfigUpdate.bind(this)}> Configuration Update</Button>
                    </Grid>
                    { }
                    <Grid item md={1.5} >
                        <Button className="custom-button" disabled={type ? (model ? false : true) : true} onClick={applicationUpdate.bind(this)}> Application Update</Button>
                    </Grid>
                    <Grid item md={1.5} >
                        <Button className="custom-button" disabled={type ? (model ? false : true) : true} onClick={firmwareUpdate.bind(this)}> Firmware Update</Button>
                    </Grid>
                    <Grid item md={1.5} >
                        <Button className="custom-button" disabled={type ? (model ? false : true) : true} onClick={osUpdate.bind(this)}> Operating System Update</Button>
                    </Grid>
                    {/* <Col>
                                        <Button color="primary" onClick={() =>firmwareUpdate(this)} disabled={type ? (model ? false : true) : true}>Firmware Update</Button>
                                    </Col> */}
                </Grid>
            </Col>
        </div>
    )

}

export default Ota