import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import SearchBar from "material-ui-search-bar";
import React, { useState } from "react";
import { Capitalize } from "../../../Common/CommonContent/ContentedRelatedCommonFun";

const newStyles = {
  fontWeight: "bold",
  padding: "8px",
  textAlign: "center",
  backgroundColor: "aliceblue",
  position: "sticky",
};

export default function ProductCustomTable({
  getTableProducts,
  displayFields,
  productType,
}) {
  const [setFilterProducts, getFilterProducts] = useState(
    getTableProducts || []
  );
  const [getProductsData, setProductsData] = useState(getTableProducts || []);
  const [page, setPage] = React.useState(0);
  const [searched, setSearched] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  /*Filter*/
  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterProducts.filter((row) => {
      return row.productfriendlyname
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    setProductsData(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Pagination*/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = getProductsData.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "50%",
            lg: "40%",
            xl: "40%",
          },
          mb: 1,
        }}
      >
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
      </Box>
      {Array.isArray(slicedData) && slicedData?.length > 0 ? (
        <Paper sx={{ width: "100%" }}>
          <TableContainer style={{ maxHeight: "300px" }}>
            <Table stickyHeader area-aria-label="sticky table">
              <TableHead style={newStyles}>
                <TableRow>
                  {displayFields?.map((header) => (
                    <TableCell
                      key={header}
                      style={{
                        color: "#3c8a67",
                      }}
                    >
                      {Capitalize(header)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {slicedData?.map((row, index) => (
                  <TableRow key={index}>
                    {displayFields?.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{row[cell]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={getProductsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>No Data Found</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
