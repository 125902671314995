import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";


const markerLabelStyle = {
  
  color: "black", 
  fontWeight:'bold',
  
 
 
};
const LiveMonitorMap = ({ geoLocations,assetIds }) => {
  const theme = createTheme();

  useEffect(() => {
    // Your logic to fetch markers goes here
  }, []);

  const GoogleMapComponent = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={4}
        defaultCenter={geoLocations[0]}
      >
        {geoLocations.map((location, index) => (
          <Marker
            key={index}
            position={{ lat: location.lat, lng: location.lng }}
            label={{
              text: assetIds[index], 
              fontWeight:'bold',
             
              ...markerLabelStyle, 
            }}
            labelAnchor={new window.google.maps.Point(0, -20)}
            labelOrigin={{ x: 0, y: -20 }} 
          />
        ))}
      </GoogleMap>
    ))
  );

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <GoogleMapComponent
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDzJglX1KEZkksRUr_zBWrHAG3ZYeUZt4o`}
          loadingElement={<div style={{ height: "100%" }} />}
          containerElement={<div style={{ height: "600px" }} />}
          mapElement={<div style={{ height: "100%" }} />}
        />
      </Box>
    </ThemeProvider>
  );
};

export default LiveMonitorMap;
