import React from "react";
import { Button, styled } from "@mui/material";
import TransportManagementComponent from "../../AssetDeveloperRole/TransportManagement/TransportManagementComponent";
import { useState } from "react";
import VehicleManagement from "../GeoFence/VehicleManagement/VehicleManagement";
import TripPlannerComponent from "../TripPlanner/TripPlannerComponent";
import LiveMonitoringComponent from "../LiveMonitoring/LiveMonitoringComponent";
import GeofenceDetails from "../GeoFence/geofencedetails/GeofenceDetails";
import MainService from "../../Common/MainService";
import { useEffect } from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";

const StyledButton = styled(Button)`
  background-color: #2e7d32;
  color: white;
  margin-right: 5px;
`;

const FleetServices = () => {
  const [viewAsset, setViewAsset] = useState(false);
  const [viewGeofence, setViewGeofence] = useState(false);
  const [showTripPlannerButtons, setShowTripPlannerButtons] = useState(true);
  const [viewLiveMonitor, setViewLiveMonitor] = useState(false);
  const [showGeofenceDetials, setShowGeofenceDetials] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [userTypeValue, setUserTypeValue] = useState("");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  const UserRole = localStorage.getItem("UserRole");

  const geofenceHandler = () => {
    setViewGeofence(true);
    setViewAsset(false);
    setViewLiveMonitor(false);
    setShowTripPlannerButtons(false);
  };

  const showTripPlanner = () => {
    setShowTripPlannerButtons(true);
    setViewAsset(false);
    setViewGeofence(false);
    setViewLiveMonitor(false);
  };

  const liveMonitorHandler = () => {
    setViewLiveMonitor(true);
    setViewAsset(false);
    setViewGeofence(false);
    setShowTripPlannerButtons(false);
  };

  const transportHandler = () => {
    setViewAsset(!viewAsset);
    setViewGeofence(false);
    setShowTripPlannerButtons(false);
    setViewLiveMonitor(false);
  };

  const showGeofenceDetailsHandler = () => {
    setViewGeofence(false);
    setShowGeofenceDetials(true);
  };
  const cancelHandler = () => {
    setViewGeofence(true);
    setShowGeofenceDetials(false);
  };

  useEffect(() => {
    getUserType();
  }, [userTypeValue]);

  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);

        console.log("USERTYPE", userTypeValue);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  return (
    <>
      {userTypeValue === "LogisticsProvider" && (
        <div className="topHeadersWrapper" style={{ margin: "2rem" }}>
          {(UserRole === "PREFERRED" || UserRole === "PREMIUM" || UserRole === "ADMIN") && (
            <div className="topHeaders">
              <StyledButton
                color="success"
                size="large"
                variant="contained"
                onClick={showTripPlanner}
              >
                TRIPPLANNER
              </StyledButton>
            </div>
          )} 
          
          {(UserRole === "PREFERRED" ||
            UserRole === "PREMIUM" ||
            UserRole === "ADMIN") && (
            <div className="topHeaders">
              <StyledButton
                color="success"
                size="large"
                variant="contained"
                onClick={liveMonitorHandler}
              >
                LIVEMONITORING
              </StyledButton>
            </div>
          )}
          
          {UserRole === "ADMIN" && (
            <div className="topHeaders">
              <StyledButton
                color="success"
                size="large"
                variant="contained"
                onClick={geofenceHandler}
              >
                GEOFENCING
              </StyledButton>
            </div>
          )}
          
          {(UserRole === "BASIC" ||
            UserRole === "STANDARD" ||
            UserRole === "PREFERRED" ||
            UserRole === "PREMIUM" ||
            UserRole === "ADMIN") && (
            <div className="topHeaders">
              <StyledButton
                color="success"
                size="large"
                variant="contained"
                onClick={transportHandler}
              >
                TRANSPORT ASSET MANAGEMENT
              </StyledButton>
            </div>
          )}
        </div>
      )}

      {viewGeofence && (
        <VehicleManagement
          isFleet={true}
          showGeofenceDetailsHandler={showGeofenceDetailsHandler}
          setSelectedItem={setSelectedItem}
        />
      )}
      {showTripPlannerButtons && <TripPlannerComponent />}

      {viewLiveMonitor && <LiveMonitoringComponent />}

      {viewAsset && <TransportManagementComponent />}

      {showGeofenceDetials && (
        <GeofenceDetails
          selectedItem={selectedItem}
          cancelHandler={cancelHandler}
        />
      )}
    </>
  );
};

export default FleetServices;
