import React from "react";
import HoneyAmla from "../../images/collection/amla-candy.jpg";
import StawberryJam from "../../images/collection/strawberry-jam.jpg";
import OrangeJam from "../../images/collection/orange-jam.jpg";
import HoneySoap from "../../images/collection/soap-honey-comb-arrangement.jpg";
import HoneyShampoo from "../../images/collection/6613153.jpg";
import HoneyDryfruits from "../../images/collection/honey-dryfruit.jpg";
import HoneyCinamon from "../../images/collection/cinamon-honey.jpg";
import NaturalHoney from "../../images/collection/natural-honey.jpg";
import { Row, Col } from "react-bootstrap";
import "./CollectionProduct.css";


const CollectionProduct = ({ title }) => {
  const collections = [
    {
      img: NaturalHoney,
      collectionName: "Natural Honey ",
    },
    {
      img: HoneyAmla,
      collectionName: "Honey Amla Candy",
    },
    {
      img: StawberryJam,
      collectionName: "Honey Strawberry Jam",
    },
    {
      img: OrangeJam,
      collectionName: "Honey Orange Jam",
    },
    {
      img: HoneySoap,
      collectionName: "Honey Soap",
    },
    {
      img: HoneyShampoo,
      collectionName: "Honey Shampoo",
    },
    {
      img: HoneyDryfruits,
      collectionName: "Honey Dryfruits",
    },
    {
      img: HoneyCinamon,
      collectionName: "Cinamon Honey ",
    },
  ];
  return (
    <div className="container for-farmer-section2 subSectionWrapper px-0">
      {title && (
        <h2 className="text-center title mb-4 mb-lg-5 container-title">
          {title}
        </h2>
      )}
      <Row className="justify-content-center">
        {collections.map((e) => (
          <Col md={2} key={e.title}>
            <div className="image-container image-container-collections mb-4">
              <img className="" src={e.img} alt="Investment asset" />
            </div>
            <h3 className="text-center image-caption mt-0  mb-4 text-decoration-none">
              {e.collectionName}
            </h3>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CollectionProduct;
