import React from "react";
import { Button, Container } from "@material-ui/core";
import "./ABNCBN.css";
import IAABlock from "../IaaBlock/IAABlock";
import IAMBlock from "../IaaBlock/IAMBlock";
export default function ABNCBN({title,description, buttonText, href,src,src2,IAA,IAM,network,producers}) {
  return (
    <Container className="custom-container abn-container">
      <h4 className="container-title mx-4"> {title}</h4>
      <p className="container-para mb-3">
        {description}
      </p>
      {IAA && <IAABlock producers={producers}/>}
      {IAM && <IAMBlock producers={producers}/> }

      {src && <img className={`${network ? "networkImg d-block mx-auto" :"mob-vis "} `}src={src} alt="ABN-Image" />}
      {src2 && <img className="mob-hide"  src={src2} alt="ABN-Image" />}

      {href  && <Button variant="contained" color="primary" >
        <a href={href}>{buttonText} </a>
      </Button>}
    </Container>
  );
}
