import React from "react";
import ExploreTechnologyBanner from "./ExploreTechnologyBanner";
import ExploreTechnologySection2 from "./ExploreTechnologySection2";
import "./ExploreTechnology.css";

export default function ExploreTechnology() {
  return (
    <>
      <ExploreTechnologyBanner />
      <div className="subSectionWrapper">
        {/* <ExploreTechnologySection1 /> */}
        <ExploreTechnologySection2 />
      </div>
    </>
  );
}
