// import Axios from "axios";
// import { consumerAdmin } from "../src/components/Main/Layout/networkId";
// var jsonconfig = window.appConfig;

// // baseURL: "http://54.185.221.117:8900",

// const AuthAxiosInstance = Axios.create({
//     baseURL: "http://54.185.221.117:8900",
//     headers: {'Authorization':  localStorage.getItem('GBR'),
//     'parent':consumerAdmin
// }
// });


// export default AuthAxiosInstance;

import Axios from "axios";
import { consumerAdmin } from "./networkId";

// Get the configuration from window object
const jsonConfig = window.appConfig;

// Common headers for both Axios instances
const commonHeaders = {
  'Authorization': localStorage.getItem('GBR'),
  'parent': consumerAdmin
};

// Function to create Axios instances with specified baseURL
function createAxiosInstance(baseURL) {
  return Axios.create({
    baseURL: baseURL,
    headers: commonHeaders
  });
}

// Create the instances with their specific base URLs
// const AuthAxiosInstance = createAxiosInstance("http://172.16.1.157:8900");
// const DeviceMetadataAxiosInstance = createAxiosInstance("http://172.16.1.157:8901");
// const SecurityAxiosInstance = createAxiosInstance("http://172.16.1.157:8903");
// const EMSServcieAxiosInstance = createAxiosInstance("http://172.16.1.157:8905");

const AuthAxiosInstance = createAxiosInstance("https://devicestaging.gbrservice.com");
const DeviceMetadataAxiosInstance = createAxiosInstance("https://devicestaging.gbrservice.com");
const SecurityAxiosInstance = createAxiosInstance("https://devicestaging.gbrservice.com");
const EMSServcieAxiosInstance = createAxiosInstance("https://devicestaging.gbrservice.com");

// Export an object with both instances, with AuthAxiosInstance as the default export
export default AuthAxiosInstance;
export { DeviceMetadataAxiosInstance, SecurityAxiosInstance, EMSServcieAxiosInstance };
