import React from 'react';
import MarketProducts from "../Market/MarketProducts";

const SolutionMarket = () => {
    return(
        <MarketProducts
      categoryType="SOLUTIONS"
      compType="Store"
      subCatType="Sub Category"
      modelID="Solution Model ID"
      modelFN="Solution Model Friendly Name"
      dCost="Solution Cost"
      pUnit="Unit"
      pDiscount="Discount"
      dPrice="Discounted Price"
    />
    )
}
export default SolutionMarket; 
