import dayjs from 'dayjs';

export const uniqueNumber = () => {
  const date = new Date();
  const timestamp = date.getTime(); // Get the current timestamp in milliseconds
  const uniqueNumber = timestamp % 100000000; // Use modulo operator to limit the number within 8 digits

  return uniqueNumber;
};

export const formattedValue = () => {
  const todayDate = new Date();
  const formattedDate = dayjs(todayDate).format('YYYY-MM-DD');
  return formattedDate;
};

export const capitalizeFirstLetter = (string) => {
  return `is${string.charAt(0).toUpperCase() + string.slice(1)}`;
};

// export const isValidUserFormData = (obj) => {
//   return Object.values(obj).some((value) => !value);
// };

export const isValidUserFormData = (obj, setStateFunction) => {
  const emptyField = Object.keys(obj).find((key) => !obj[key]);
  if (emptyField) {
    setStateFunction((prev) => {
      return emptyField;
    });
    return false;
  }
  return true;
};
