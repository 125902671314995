import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ItemDataTable = ({
  invoiceItemDataList,
  deleteItemHandler,
  discount,
  setDiscount,
}) => {
  // const [discountPrice, setDiscountPrice] = useState(0);
  let c = 0;
  let s = 0;
  let i = 0;
  let ta = 0;

  const priceRow = (qty, unit) => {
    return qty * unit;
  };

  const ccyFormat = (num) => {
    if (num === 0) {
      return '0';
    }

    if (!num) {
      return;
    }
    return `${parseFloat(num).toFixed(2)}`;
  };

  const tax = (qty, rate, tax, type) => {
    if (type === 'cgst') {
      c = (qty * rate * tax) / 100;
    } else if (type === 'sgst') {
      s = (qty * rate * tax) / 100;
    } else if (type === 'igst') {
      i = qty * rate * tax;
    }
    const t = (qty * rate * tax) / 100;
    return t;
  };

  const totalRowAmount = (rate, qty, sgst, cgst, igst) => {
    if (igst) {
      const p = rate * qty;
      const t = (p * igst) / 100;
      ta = p + t;
      return ta;
    } else {
      const p = rate * qty;
      const t = (p * (sgst + cgst)) / 100;
      ta = p + t;
      return ta;
    }
  };

  const totalAmount = invoiceItemDataList.reduce((acc, row) => {
    const { quantity, price_per_unit, totalGst } = row;
    const rowAmount = totalRowAmount(
      price_per_unit,
      quantity,
      totalGst.sGst,
      totalGst.cGst,
      totalGst.iGst
    );

    return acc + rowAmount;
  }, 0);

  const totalDiscount = (total, dis) => {
    const td = (total * dis) / 100;
    return td;
  };

  return (
    <TableContainer sx={{ my: '1rem', borderRadius: '0.5rem' }}>
      <Table sx={{ minWidth: 700 }} aria-label='spanning table'>
        <TableHead sx={{ background: '#1976d236' }}>
          <TableRow>
            <TableCell>SR.NO.</TableCell>
            <TableCell align='right'>PRODUCT</TableCell>
            <TableCell align='right'>HSN CODE</TableCell>
            <TableCell align='right'>QTY</TableCell>
            <TableCell align='right'>UNIT</TableCell>
            <TableCell align='right'>RATE</TableCell>
            <TableCell align='right'>TAXABLE VALUE</TableCell>
            <TableCell align='right'>CGST</TableCell>
            <TableCell align='right'>SGST</TableCell>
            <TableCell align='right'>IGST</TableCell>
            <TableCell align='right'>TOTAL</TableCell>
            <TableCell align='right'>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceItemDataList?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell align='left'>{row.description}</TableCell>
              <TableCell align='right'>{row.hsn_sac}</TableCell>
              <TableCell align='right'>{ccyFormat(row.quantity)}</TableCell>
              <TableCell align='right'>{row.unit}</TableCell>

              <TableCell align='right'>
                {ccyFormat(row.price_per_unit)}
              </TableCell>
              <TableCell align='right'>
                {ccyFormat(priceRow(row.quantity, row.price_per_unit))}
              </TableCell>

              <TableCell align='right'>
                {ccyFormat(
                  tax(
                    row.quantity,
                    row.price_per_unit,
                    row.totalGst.sGst,
                    'cgst'
                  )
                )}
                <br />
                {ccyFormat(row.totalGst.sGst)}%
              </TableCell>

              <TableCell align='right'>
                {ccyFormat(
                  tax(
                    row.quantity,
                    row.price_per_unit,
                    row.totalGst.cGst,
                    'sgst'
                  )
                )}
                <br />
                {ccyFormat(row.totalGst.cGst)}%
              </TableCell>

              <TableCell align='right'>
                {ccyFormat(
                  tax(
                    row.quantity,
                    row.price_per_unit,
                    row.totalGst.iGst,
                    'igst'
                  )
                )}
                <br />
                {ccyFormat(row.totalGst.iGst)}%
              </TableCell>

              <TableCell align='right'>
                {ccyFormat(
                  totalRowAmount(
                    row.price_per_unit,
                    row.quantity,
                    row.totalGst.sGst,
                    row.totalGst.cGst,
                    row.totalGst.iGst,
                    'igst'
                  )
                )}
                <br />
              </TableCell>
              <TableCell>
                <DeleteIcon
                  sx={{
                    cursor: 'pointer',
                    color: '#e35a5a',
                  }}
                  onClick={() => deleteItemHandler(row, index)}
                />
              </TableCell>
            </TableRow>
          ))}

          {/* {invoiceItemDataList.length > 0 && ( */}
          <>
            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell colSpan={3}>
                <strong>Amount</strong>
              </TableCell>
              <TableCell align='right' colSpan={3}>
                {ccyFormat(totalAmount) || 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell colSpan={3}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: '0.5rem',
                  }}
                >
                  <strong>Discount</strong>
                  <TextField
                    id='standard-error'
                    label='Discount'
                    variant='standard'
                    sx={{
                      width: '5rem',
                    }}
                    type='number'
                    name='discPercent'
                    value={discount.toString()}
                    onChange={(event) =>
                      setDiscount(parseFloat(event.target.value) || 0)
                    }
                  />
                  <strong>%</strong>
                </Box>
              </TableCell>
              <TableCell align='right' colSpan={3}>
                {ccyFormat(totalDiscount(totalAmount, discount)) || 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell colSpan={3}>
                <strong>TOTAL</strong>
              </TableCell>
              <TableCell align='right' colSpan={3} sx={{ fontWeight: 700 }}>
                {ccyFormat(
                  totalAmount - totalDiscount(totalAmount, discount)
                ) || 0}
              </TableCell>
            </TableRow>
          </>
          {/* )} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ItemDataTable;
