import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../../images/Agro-Dealer_Needs_08-02-2024_C-removebg-preview.png";
// import production from "../../../images/challangesImg/production (2).svg";
import Ecosystem from "../../../images/loan.svg";
import Brand from "../../../images/marketing (2).svg";
import Marketing from "../../../images/retail (2).svg";
import Finance from "../../../images/technology.svg";


export default function NGONeeds() {
  const challanges =[{
    title:"Loan",
    description:"Access to loans is crucial for agro-dealers, enabling them to navigate seasonal cash flow fluctuations, invest in inventory, and facilitate business expansion.",
    image:Finance
  },
  {
    title:"Sourcing ",
    description:"Sourcing is a critical need for agro-dealers as it involves securing a reliable and cost-effective supply chain for agricultural inputs, ensuring product availability for customers, and maintaining a competitive edge in the market.",
    image:Marketing
  },
  {
    title:"Technology",
    description:"Technology is crucial for agro-dealers to streamline inventory management, enhance market access, and optimize agricultural supply chains, ultimately improving efficiency and profitability.",
    image:Ecosystem
  },

  {
    title:"Market Expansion ",
    description:"Market Expansion is essential for agro-dealers to create awareness, reach farmers, and establish a strong market presence, ensuring effective distribution of agricultural inputs and products. ",
    image:Brand
  }

]
  return (
    <div className="main-wrapper2">
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        {" "}
        NGO Needs
      </h2>

      <Row className="align-items-center">
        <Col md={4}>
          <img src={challangeImg} className="mb-md-0 mb-4" />
        </Col>

        <Col md={8}>
         

          <Row className="challangesRow justify-content-between ">
            {challanges.map((e)=>{
              return <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 mb-3 ">
                <img src={e.image} className="mb-3" />
                <h6 className="mb-2">{e.title}</h6>
                <p>
                 {e.description}
                </p>
              </div>
            </Col>
           
            })}
          
          </Row>
   
        </Col>
      </Row>
    </Container>
    </div>
  );
}
