import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import MainService from "../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";

import  FieldInspectorComponent from "../AssetDeveloperRole/FieldInspector/FieldInspectorComponent";
import ViewExecutivesComponent from "../AssetDeveloperRole/ICSExecutive/ICSExecutiveComponent";
import FieldOfficerComponent from "../FieldOfficer/FieldOfficerComponent";
import  IcsManagerComponent from "../AssetDeveloperRole/ICSManager/ICSManagerComponent";

export default function FPNUsersComponent(props) {
  const [viewSiteManager, setViewSiteManager] = useState(false);
  const [viewSiteQA, setViewSiteQA] = useState(false);
  const [viewSiteExecutive, setViewSiteExecutive] = useState(false);
  const [viewSiteOperator, setViewSiteOperator] = useState(true);
  const { user } = MainService.getLocalData();
  const [roleNameValue, setRoleNameValue] = useState("");
  const { username, parentId } = user;


  useEffect(() => {
    getRoleName();
  }, []);
  const getRoleName = () => {
    MainService.getRole(parentId, username)
      .then((res) => {
        setRoleNameValue(res.role);
        console.log("role P n/w res:", res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };


  const viewSiteManagerClick = () => {
    setViewSiteManager(true);
    setViewSiteQA(false);
    setViewSiteExecutive(false);
    setViewSiteOperator(false);    
  };
  const viewSiteOperatorClick = () => {    
    setViewSiteManager(false);
    setViewSiteQA(false);
    setViewSiteExecutive(false);      
    setViewSiteOperator(true);    
  };

  const viewSiteQAClick = () => {
    setViewSiteManager(false);
    setViewSiteQA(true);
    setViewSiteExecutive(false);
    setViewSiteOperator(false);      
  };

  const viewSiteExecutiveClick = () => {
    setViewSiteManager(false);
    setViewSiteQA(false);
    setViewSiteExecutive(true);
    setViewSiteOperator(false);
  };
  

  return (
    <>
      <div className="container" id="mainContentList">
        <h2>User Management</h2>   
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewSiteOperatorClick();
              }}
            >
              FieldOfficer
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewSiteManagerClick();
              }}
            >
              ICSManager
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewSiteQAClick();
              }}
            >
              FieldInspector
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewSiteExecutiveClick();
              }}
            >
              ICSExecutive
            </Button>
          </div>
         
        </div>
        &nbsp;
      </div>

      <div style={{ paddingTop: "20px" }}>
     
        {viewSiteOperator && <FieldOfficerComponent />}      
        {viewSiteQA && < FieldInspectorComponent />}
        {viewSiteManager && < IcsManagerComponent />}
        {viewSiteExecutive && <ViewExecutivesComponent />}
   
      </div>
    </>
  );
}