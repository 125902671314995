import React from "react";
import Icon4 from "../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../images/gbrFarmerBenefits/save-money.svg";
import Icon6 from "../../images/gbrFarmerBenefits/breakdown.svg";
// import "./ForFarmerSection3.css"
 
import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
 
export default function AgroDealerBenefits() {
  const benefits = [
   
    {
      image: Icon4,
      title: "Access to Agri-inputs",
      bodycopy:
        "Access to agri-inputs empowers agro-dealers by providing essential resources for machinery, animal health care, and plant health care, fostering an enriched product portfolio and enhanced service capabilities.",
    },
    {
      image: Icon5,
      title: "Access to Finance ",
      bodycopy:
        "Securing financial resources empowers agro-dealers to invest in machinery, animal health care, and plant health care, fostering improved product offerings and services within the agricultural sector.",
    },
    {
      image: Icon6,
      title: "Build & manage own community ",
      bodycopy:
        "Agro-dealers can create and manage farmer and agri-retailer communities, facilitating collaboration and efficient management for collective growth in agriculture."
    },
  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Agro-Dealer Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
   
    </div>
    </Container>
 
  );
}