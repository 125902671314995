import React from "react";
import Schemes from "./Schemes";

const FarmerSchemes = () => {
  return (
    <>
      <Schemes categoryname="FARMER_GOVT_SCHEMES" />
    </>
  );
};

export default FarmerSchemes;
