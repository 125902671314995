import React from 'react'

const BrokerDashCompo = () => {
  return (
    <div>
      Coming soon.........
    </div>
  )
}

export default BrokerDashCompo;
