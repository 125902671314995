
import React from "react";
import HoneyAsset from "../../../images/1 (1).png";
import Community from "../../../images/forMSMEBanner.webp";
import FieldSupport from "../../../images/combine-harvester-garden-concept-harvesting-making-money-agriculture.jpg";
import ProcessingSupport from "../../../images/bee-nests-flower-garden.jpg"
import { NavLink } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

export default function InvestmentServices() {
  const services = [
   

      // {
      //   title: "Investable Assets",
      //   image: FieldSupport,
      //   href: "/InvestableAssets",
      // },

      {
        title: "Social iaaMarketplace",
        image: ProcessingSupport,
        href: "/social-iaaMarketplace-for-institutional-lenders",
      },
  ];

  return (
    <div className="container for-farmer-section2 subSectionWrapper pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
          return (
            <Col md={6}>
              <NavLink to={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
