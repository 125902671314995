import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainService from "../../Common/MainService";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SearchBar from "material-ui-search-bar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@mui/material/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import UploadKYCDocumentsComponent from "../UploadKYCDocuments/UploadKYCDocumentsComponent";
import sha256 from "sha256";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function GrowerComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [setListOpe, getListOpe] = useState(false);
  const [setGridOpe, getGridOpe] = useState(true);
  const [viewNode, setNode] = useState(false);
  const [viewGridNode, setGridNode] = useState(false);
  const [viewApproval, setApproval] = useState(false);
  const [viewGridApproval, setGridApproval] = useState(false);
  const [viewGrow, setGrow] = useState(false);
  const [viewGridGrow, setGridGrow] = useState(false);
  const [viewSell, setSell] = useState(false);
  const [viewGridSell, setGridSell] = useState(false);
  const [viewLoan, setLoan] = useState(false);
  const [viewGridLoan, setGridLoan] = useState(false);
  const [viewVetVisit, setVetVisit] = useState(false);
  const [viewGidVetVisit, setGridVetVisit] = useState(false);
  const [viewBuyInput, setBuyInput] = useState(false);
  const [viewGridBuyInput, setGridBuyInput] = useState(false);
  const [viewStore, setStore] = useState(false);
  const [viewGridStore, setGridStore] = useState(false);
  const [viewAdvisory, setAdvisory] = useState(false);
  const [viewGridAdvisory, setGridAdvisory] = useState(false);
  const [farmersList, setGrowersList] = useState([]);
  const [setApprovalReq, getApprovalReq] = useState([]);
  const [setGrowReq, getGrowReq] = useState([]);
  const [setSellReq, getSellReq] = useState([]);
  const [setLoanReq, getLoanReq] = useState([]);
  const [setVetVisitReq, getVetVisitReq] = useState([]);
  const [setBuyInputReq, getBuyInputReq] = useState([]);
  const [setStoreReq, getStoreReq] = useState([]);
  const [setAdvisoryReq, getAdvisoryReq] = useState([]);
  const [farmerNodesList, setGrowerNodesList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username,parentId } = user;
  const [viewNodeUpdate, setNodeUpdate] = useState(false);
  const [viewGrowerInfoDialog, setViewGrowerInfoDialog] = useState(false);
  const [setNodeName, getNodeName] = useState("");
  const [setNodeStatus, getNodeStatus] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [setDefaultNodeName, getDefaultNodeName] = useState("");
  /* Node Info Data */
  const [setInfoEnterpriceId, getInfoEnterpriceId] = useState("");
  const [setInfoUserName, getInfoUserName] = useState("");
  const [setInfoFname, getInfoFname] = useState("");
  const [setInfoLname, getInfoLname] = useState("");
  const [setInfoUserType, getInfoUserType] = useState("");
  const [setInfoEmail, getInfoEmail] = useState("");
  const [setInfoPhone, getInfoPhone] = useState("");
  const [setInfoAddress, getInfoAddress] = useState("");
  const [setInfoCity, getInfoCity] = useState("");
  const [setInfoState, getInfoState] = useState("");
  const [setInfoPan, getInfoPan] = useState("");
  const [setInfoDomain, getInfoDomain] = useState("");
  const [setInfoMessage, getInfoMessage] = useState("");
  const [setInfoNominee, getInfoNominee] = useState("");
  const [setInfoLatitude, getInfoLatitude] = useState("");
  const [setInfoLongitude, getInfoLongitude] = useState("");
  const [setInfoStatus, getInfoStatus] = useState("");
  /* End Of Node Info Data */
  /* Update Approval Status */
  const [setUpdateRequest, getUpdateRequest] = useState(false);
  const [setApprovalId, getApprovalId] = useState("");
  const [setApprovalPreStatus, getApprovalPreStatus] = useState("");
  const [setApprovalPostStatus, getApprovalPostStatus] = useState("");
  /* Update Grow Status */
  const [setGrowUpdateRequest, getGrowUpdateRequest] = useState(false);
  const [setGrowId, getGrowId] = useState("");
  const [setGrowPreStatus, getGrowPreStatus] = useState("");
  const [setGrowPostStatus, getGrowPostStatus] = useState("");
  /* Update Sell Status */
  const [setSellUpdateRequest, getSellUpdateRequest] = useState(false);
  const [setSellId, getSellId] = useState("");
  const [setSellPreStatus, getSellPreStatus] = useState("");
  const [setSellPostStatus, getSellPostStatus] = useState("");
  /* Update Loan Status */
  const [setLoanUpdateRequest, getLoanUpdateRequest] = useState(false);
  const [setLoanId, getLoanId] = useState("");
  const [setLoanPreStatus, getLoanPreStatus] = useState("");
  const [setLoanPostStatus, getLoanPostStatus] = useState("");
  /* Update Vet Visit Status */
  const [setVetUpdateRequest, getVetUpdateRequest] = useState(false);
  const [setVetId, getVetId] = useState("");
  const [setVetPreStatus, getVetPreStatus] = useState("");
  const [setVetPostStatus, getVetPostStatus] = useState("");
  /* Update Buy Input Status */
  const [setBuyUpdateRequest, getBuyUpdateRequest] = useState(false);
  const [setBuyId, getBuyId] = useState("");
  const [setBuyPreStatus, getBuyPreStatus] = useState("");
  const [setBuyPostStatus, getBuyPostStatus] = useState("");
  /* Update Store Status */
  const [setStoreUpdateRequest, getStoreUpdateRequest] = useState(false);
  const [setStoreId, getStoreId] = useState("");
  const [setStorePreStatus, getStorePreStatus] = useState("");
  const [setStorePostStatus, getStorePostStatus] = useState("");
  /* End Of Update Store Status */
  /*Creator Growers */
  const [consumerUserDialog, setConsumerUserDialog] = useState(false);
  const [usernameValue, setUsernameValue] = useState("");
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [domainNameValue, setDomainNameValue] = useState("");
  const [userTypeValue, setUserTypeValue] = useState("Grower");
  const [email, setEmail] = useState("");
  const [phoneNumerValue, setPhoneNumerValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [cityNameValue, setCityNameValue] = useState("");
  const [stateNameValue, setStateNameValue] = useState("");
  const [nomineeValue, setNomineeValue] = useState("");
  const [pancardValue, setPancardValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  /*End Creator Growers */
  /*Delete Growers */
  const [consumerUserDeleteDialog, setConsumerUserDeleteDialog] =
    useState(false);

  const [selectedConsumerUserId, setSelectedConsumerUserId] = useState("");
  /*End Of Delete Growers */
  /*Growers Password Reset*/
  const [consumerUserResetPasswordDialog, setconsumerUserResetPasswordDialog] =
    useState(false);
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [passwordConfirmationValue, setPasswordConfirmationValue] =
    useState("");
  /*End Of Growers Password Reset*/
  /*Growers Update*/
  const [consumerUserUpdateDialog, setConsumerUserUpdateDialog] =
    useState(false);
  const [consumerUserUserId, setConsumerUserUserId] = useState("");
  const [consumerUserUserName, setConsumerUserUserName] = useState("");
  const [consumerUserFirstName, setConsumerUserFirstName] = useState("");
  const [consumerUserLastName, setConsumerUserLastName] = useState("");
  const [consumerUserEmail, setConsumerUserEmail] = useState("");
  const [consumerUserPhone, setConsumerUserPhone] = useState("");
  const [consumerUserAddress, setConsumerUserAddress] = useState("");
  const [consumerUserCity, setConsumerUserCity] = useState("");
  const [consumerUserState, setConsumerUserState] = useState("");
  const [consumerUserNominee, setConsumerUserNominee] = useState("");
  const [consumerUserPancard, setConsumerUserPancard] = useState("");
  const [consumerUserLongitude, setConsumerUserLongitude] = useState("");
  const [consumerUserLatitude, setConsumerUserLatitude] = useState("");
  const [consumerUserMessage, setConsumerUserMessage] = useState("");
  const [consumerUserUserType, setConsumerUserUserType] = useState("");
  const [consumerUserUserDomainName, setConsumerUserUserDomainName] =
    useState("");
  /*End Of Growers Update*/
  /*Update Node Status Of Consumer Growers*/
  const [
    consumerUserUpdateNodeStatusDialog,
    setConsumerUserUpdateNodeStatusDialog,
  ] = useState(false);
  const [selectedNodeNameValue, setSelectedNodeNameValue] = useState("");
  const [selectedNodeId,setSelectedNodeId] = useState("");
  const [nodeStatusValue, setNodeStatusValue] = useState("");
  /*End Of Update Node Status Of Consumer Growers*/
  /*Node Info Of Consumer Growers*/
  const [farmerNodeInfoDialog, setGrowerNodeInfoDialog] =
    useState(false);
  const [nodeUserName, setNodeUserName] = useState("");
  const [nodeUserNodeId, setNodeUserNodeId] = useState("");
  const [nodeUserAccountType, setNodeUserAccountType] = useState("");
  const [nodeUserUserType, setNodeUserUserType] = useState("");
  const [nodeUserAssetType, setNodeUserAssetType] = useState("");
  const [nodeUserNumberOfAssets, setNodeUserNumberOfAssets] = useState("");
  const [nodeUserAreaSize, setNodeUserAreaSize] = useState("");
  const [nodeUserGrazingType, setNodeUserGrazingType] = useState("");
  const [nodeUserFeedType, setNodeUserFeedType] = useState("");
  const [nodeUserNumberOfKids, setNodeUserNumberOfKids] = useState("");
  const [nodeUserMaleGenderNumber, setNodeUserMaleGenderNumber] = useState("");
  const [nodeUserFemaleGenderNumber, setNodeUserFemaleGenderNumber] =
    useState("");
  const [nodeUserOverallAge, setNodeUserOverallAge] = useState("");
  const [nodeUserBreed, setNodeUserBreed] = useState("");
  const [nodeUserFarmingExperience, setNodeUserFarmingExperience] =
    useState("");
  const [nodeUserNodeStatus, setNodeUserNodeStatus] = useState("");
  const [nodeUserContentUrl, setNodeUserContentUrl] = useState("");
  const [nodeUserNodeType, setNodeUserNodeType] = useState("");
  const [nodeUserAssetverticalId, setNodeUserAssetverticalId] = useState("");
  const [
    nodeUserAssetverticalFriendlyName,
    setNodeUserAssetverticalFriendlyName,
  ] = useState("");
  const [nodeUserFirstName, setNodeUserFirstName] = useState("");
  const [nodeUserLastName, setNodeUserLastName] = useState("");
  const [nodeUserEmail, setNodeUserEmail] = useState("");
  const [nodeUserPhone, setNodeUserPhone] = useState("");
  const [nodeUserAddress, setNodeUserAddress] = useState("");
  const [nodeUserCity, setNodeUserCity] = useState("");
  const [nodeUserState, setNodeUserState] = useState("");
  const [nodeUserDistrict, setNodeUserDistrict] = useState("");
  const [nodeUserPincode, setNodeUserPincode] = useState("");
  const [nodeUserLatitude, setNodeUserLatitude] = useState("");
  const [nodeUserLongitude, setNodeUserLongitude] = useState("");
  const [nodeUserTimestamp, setNodeUserTimestamp] = useState("");
  /*End Of Node Info Of Consumer Growers*/
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [getType, setType] = useState("");
  const [nodeId, setNodeId] = useState("");
  const [viewNodeStatusDialog, setViewNodeStatusDialog] = useState(false);
  const [selectedGrower, setSelectedGrower] = useState("");

  /* Agreent view and update status */
  const [isLoading,setIsLoading] = useState(false);
  const [viewSignedAgreementDialog, setViewSignedAgreementDialog] =
  useState(false);
const [viewSignedDocumentList, setViewSignedDocumentList] = useState([]);
const [AgreementsDocumentLink, setAgreementsDocumentLink] = useState(null);
const [agreementDocumentStatus, setAgreementDocumentStatus] = useState("");
const [updateAgreementStatusDialog, setUpdateAgreementStatusDialog] =
  useState(false);
const [agreementStatusValue, setAgreementStatusValue] = useState("");
const [selectedConsumerUserName, setSelectedConsumerUserName] = useState("");
const [viewKYCDialog, setViewKYCDialog] =  useState(false);
/*End of  Agreent view and update status */

  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setGrowersList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setGrowersList(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(() => {
    listOfGrowers();
  }, [setGrowersList]);

  /* Pagination */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* End Pagination */
  /* API Section */

  const listOfGrowers = (Type) => {
    setType(Type);
    setIsLoading(true);
    if (Type === "List") {
      getListOpe(true);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(true);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }

    MainService.viewListOfFarmers(username,"Grower")
      .then((res) => {
        setGrowersList(res.users);
        getFilterUsers(res.users);
        setIsLoading(false);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const viewNodesClick = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(true);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(true);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }
    
    MainService.getFarmerNodes(username,farmername)
      .then((res) => {
        setGrowerNodesList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const approvalRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(true);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(true);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }

    MainService.allApprovalRequests(farmername)
      .then((res) => {
        getApprovalReq(res.approvalrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const growRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(true);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(true);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }

    MainService.allGrowRequests(farmername)
      .then((res) => {
        getGrowReq(res.growrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const sellRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(true);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(true);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }

    MainService.allSellRequests(farmername)
      .then((res) => {
        getSellReq(res.sellrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const LoanRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(true);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(true);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }

    MainService.allLoanRequests(farmername)
      .then((res) => {
        getLoanReq(res.loanrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const vetVisitRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(true);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(true);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }

    MainService.allVetVisitRequests(farmername)
      .then((res) => {
        getVetVisitReq(res.vetvisitrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const buyInputRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(true);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(true);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }

    MainService.allBuyInputRequests(farmername)
      .then((res) => {
        getBuyInputReq(res.buyinputrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const storeRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(true);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(true);
      setAdvisory(false);
      setGridAdvisory(false);
    }

    MainService.allStoreRequests(farmername)
      .then((res) => {
        getStoreReq(res.storerequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const advisoryRequestHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(true);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(true);
    }

    MainService.allAdvisoryRequests(farmername)
      .then((res) => {
        getAdvisoryReq(res.advisoryrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const nodeStatusHandler = (nodename) => {
    getNodeName(nodename);
    // setNode(false);
    setNodeUpdate(true);
  };

  const handleUpdateNodeClose = () => {
    // setNode(true);
    setNodeUpdate(false);
  };

  const updateNodeStatusHandler = () => {
    MainService.setNodeStatus(setNodeName, setNodeStatus)
      .then((res) => {
        setNodeUpdate(false);
        viewNodesClick(setDefaultNodeName);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

 

  const nodeInfoCloseHandler = () => {
    setViewGrowerInfoDialog(false);
  };

  const updateRequestHandler = () => {
    getUpdateRequest(false);
  };

  const updateApprovalRequest = (requestid, status) => {
    getApprovalId(requestid);
    getApprovalPreStatus(status);
    getUpdateRequest(true);
  };

  const approvalReqStatusHandler = () => {
    const approvalBody = {
      requestid: setApprovalId,
      existingApprovalRequestStatus: setApprovalPreStatus,
      updatedApprovalRequestStatus: setApprovalPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.approvalReqStatus(data)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
  };

  const updateGrowRequest = (requestid, status) => {
    getGrowId(requestid);
    getGrowPreStatus(status);
    getGrowUpdateRequest(true);
  };

  const updateGrowRequestHandler = () => {
    getGrowUpdateRequest(false);
  };

  const growReqStatusHandler = () => {
    const approvalBody = {
      requestid: setGrowId,
      existingGrowRequestStatus: setGrowPreStatus,
      updatedGrowRequestStatus: setGrowPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.growReqStatus(data)
      .then((res) => {
        listOfGrowers("Grid");
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
      getGrowUpdateRequest(false);
  };

  const updateSellRequest = (requestid, status) => {
    getSellId(requestid);
    getSellPreStatus(status);
    getSellUpdateRequest(true);
  };

  const updateSellRequestHandler = () => {
    getSellUpdateRequest(false);
  };

  const sellReqStatusHandler = () => {
    const approvalBody = {
      requestid: setSellId,
      existingSellRequestStatus: setSellPreStatus,
      updatedSellRequestStatus: setSellPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.sellReqStatus(data)
      .then((res) => {
        listOfGrowers("Grid");
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
      getSellUpdateRequest(false);
  };

  const updateLoanRequest = (requestid, status) => {
    getLoanId(requestid);
    getLoanPreStatus(status);
    getLoanUpdateRequest(true);
  };

  const updateLoanRequestHandler = () => {
    getLoanUpdateRequest(false);
  };

  const loanReqStatusHandler = () => {
    const approvalBody = {
      requestid: setLoanId,
      existingLoanRequestStatus: setLoanPreStatus,
      updatedLoanRequestStatus: setLoanPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.LoanReqStatus(data)
      .then((res) => {
        listOfGrowers("Grid");
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
      getLoanUpdateRequest(false);
  };

  const updateVetRequest = (requestid, status) => {
    getVetId(requestid);
    getVetPreStatus(status);
    getVetUpdateRequest(true);
  };

  const updateVetRequestHandler = () => {
    getVetUpdateRequest(false);
  };

  const vetReqStatusHandler = () => {
    const approvalBody = {
      requestid: setVetId,
      existingVetVisitRequestStatus: setVetPreStatus,
      updatedVetVisitRequestStatus: setVetPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.VetReqStatus(data)
      .then((res) => {
        listOfGrowers("Grid");
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
      getVetUpdateRequest(false);
  };

  const updateBuyRequest = (requestid, status) => {
    getBuyId(requestid);
    getBuyPreStatus(status);
    getBuyUpdateRequest(true);
  };

  const updateBuyRequestHandler = () => {
    getBuyUpdateRequest(false);
  };

  const buyReqStatusHandler = () => {
    const approvalBody = {
      requestid: setBuyId,
      existingBuyInputRequestStatus: setBuyPreStatus,
      updatedBuyInputRequestStatus: setBuyPostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.BuyReqStatus(data)
      .then((res) => {
        listOfGrowers("Grid");
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
      getBuyUpdateRequest(false);
  };

  const updateStoreRequest = (requestid, status) => {
    getStoreId(requestid);
    getStorePreStatus(status);
    getStoreUpdateRequest(true);
  };

  const updateStoreRequestHandler = () => {
    getStoreUpdateRequest(false);
  };

  const storeReqStatusHandler = () => {
    const approvalBody = {
      requestid: setStoreId,
      existingStoreRequestStatus: setStorePreStatus,
      updatedStoreRequestStatus: setStorePostStatus,
    };
    const data = JSON.stringify(approvalBody);

    MainService.StoreReqStatus(data)
      .then((res) => {
        listOfGrowers("Grid");
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error("status not supported");
      });
      getStoreUpdateRequest(false);
  };

  /*************** Create, Update, Delete, NodeStatus, Node Info Growers ***************/

  const createconsumerUsersubmit = () => {
    setIsLoading(true);
    let consumerUserData = {
      username: usernameValue,
      firstname: firstNameValue,
      lastname: lastNameValue,
      domain: username,
      usertype: userTypeValue,
      phone: phoneNumerValue,
      address: addressValue?.replace(/\n/g, ''),
      city: cityNameValue,
      state: stateNameValue,
      nominee: nomineeValue,
      pancard: pancardValue,
      longitude: longitudeValue,
      latitude: latitudeValue,
      message: messageValue?.replace(/\n/g, ''),
      email: email,
    };
    const consumerUserDataString = JSON.stringify(consumerUserData);
    if(usernameValue && firstNameValue && lastNameValue && phoneNumerValue)
    {
      if(usernameValue === email){
        MainService.createConsumerUserOfIndividual(
          consumerUserDataString,
          username,
          usernameValue,
          email
        )
          .then((res) => {
            listOfGrowers(getType);
            setIsLoading(false);
            return NotificationManager.success(res);
          })
          .catch((err) => {
            setIsLoading(false);
            return NotificationManager.error(err);
          });
      }else
      {
        setIsLoading(false);
        return NotificationManager.error("username and email should be same");
      }
      
       
    }else
    {
      setIsLoading(false);
      return NotificationManager.error("Please fill all required fields");
    }
    
    setConsumerUserDialog(!consumerUserDialog);
  };

  const deleteSubmitClick = () => {
    MainService.deleteConsumerUserIndividual(
      username,
      selectedConsumerUserName,
      selectedConsumerUserId
    )
      .then((res) => {
        listOfGrowers(getType);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
  };

  const deleteDialogClick = (consumerUserName, consumerUserId) => {
    setSelectedConsumerUserName(consumerUserName);
    setSelectedConsumerUserId(consumerUserId);

    setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
  };
  const consumerUserUpdateDialogClick = (consumerUser) => {
    setSelectedConsumerUserName(consumerUser.username);
    setSelectedConsumerUserId(consumerUser.userid);

    setConsumerUserUserId(consumerUser.userid);
    setConsumerUserUserName(consumerUser.username);
    setConsumerUserFirstName(consumerUser.firstname);
    setConsumerUserLastName(consumerUser.lastname);
    setConsumerUserEmail(consumerUser.email);
    setConsumerUserPhone(consumerUser.phone);
    setConsumerUserAddress(consumerUser.address);
    setConsumerUserCity(consumerUser.city);
    setConsumerUserState(consumerUser.state);
    setConsumerUserNominee(consumerUser.nominee);
    setConsumerUserPancard(consumerUser.pancard);
    setConsumerUserLongitude(consumerUser.longitude);
    setConsumerUserLatitude(consumerUser.latitude);
    setConsumerUserMessage(consumerUser.message);
    setConsumerUserUserType(consumerUser.usertype);
    setConsumerUserUserDomainName(consumerUser.domain);

    setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
  };

  const consumerUserUpdateSubmit = () => {
    let consumerUserUpdateData = {
      firstname: consumerUserFirstName,
      lastname: consumerUserLastName,
      phone: consumerUserPhone,
      address: consumerUserAddress?.replace(/\n/g, ''),
      city: consumerUserCity,
      state: consumerUserState,
      nominee: consumerUserNominee,
      pancard: consumerUserPancard,
      longitude: consumerUserLongitude,
      latitude: consumerUserLatitude,
      message: consumerUserMessage?.replace(/\n/g, ''),
      email: consumerUserEmail,
    };
    const consumerUserUpdateDataString = JSON.stringify(consumerUserUpdateData);
    MainService.updateConsumerUserInfoIndividual(
      username,
      consumerUserUpdateDataString,
      selectedConsumerUserId,
      selectedConsumerUserName
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
  };
  const resetPasswordDialogClick = (consumerUserId) => {
    setSelectedConsumerUserId(consumerUserId);
    setconsumerUserResetPasswordDialog(!consumerUserResetPasswordDialog);
  };

  const resetPasswordSubmit = () => {
    if (newPasswordValue === "") {
      return NotificationManager.error("please provide password");
    }
    if (newPasswordValue === passwordConfirmationValue) {
      let resetPwdData = {
        type: "password",
        value:sha256(newPasswordValue),
        temporary: false,
      };
      MainService.resetConsumerUserPasswordIndividual(
        resetPwdData,
        username,
        selectedConsumerUserId
      )
        .then((res) => {
          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
      setconsumerUserResetPasswordDialog(!consumerUserResetPasswordDialog);
    } else {
      return NotificationManager.error(
        " Confirmation Password is not match with New password"
      );
    }
  };

 

  const updateNodeStatusClick = (nodename,nodeId) => {    
    setSelectedNodeNameValue(nodename);
    setSelectedNodeId(nodeId);
    MainService.viewNodeStatus(nodeId)
      .then((res) => {
        getNodeName(res.nodename);
        setNodeId(res.nodeid);
        getNodeStatus(res.nodestatus);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserUpdateNodeStatusDialog(!consumerUserUpdateNodeStatusDialog);
    
  };
  
  const viewNodeStatusClick = (selectedNodeId) => {
   
    MainService.viewNodeStatus(selectedNodeId)
      .then((res) => {
        getNodeName(res.nodename);
        setNodeId(res.nodeid);
        getNodeStatus(res.nodestatus);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setViewNodeStatusDialog(!viewNodeStatusDialog);
  };
  const updateNodeStatusSubmit = () => {
    if (nodeStatusValue === "") {
      return NotificationManager.error("please select status");
    }

    MainService.updateConsumerNodeStatus(selectedNodeId, nodeStatusValue)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setConsumerUserUpdateNodeStatusDialog(!consumerUserUpdateNodeStatusDialog);
  };
const viewGrowerInfoClick = (farmername)=>{
  MainService.communityUsersInfo(username,farmername)
  .then((res) => {
    getInfoEnterpriceId(res[0].enterpriseid);
    getInfoUserName(res[0].username);
    getInfoFname(res[0].firstname);
    getInfoLname(res[0].lastname);
    getInfoUserType(res[0].usertype);
    getInfoEmail(res[0].email);
    getInfoPhone(res[0].phone);
    getInfoAddress(res[0].address);
    getInfoCity(res[0].city);
    getInfoState(res[0].state);
    getInfoPan(res[0].pancard);
    getInfoDomain(res[0].domain);
    getInfoMessage(res[0].message);
    getInfoNominee(res[0].nominee);
    getInfoLatitude(res[0].latitude);
    getInfoLongitude(res[0].longitude);
    getInfoStatus(res[0].status);
  })
  .catch((err) => {
    return NotificationManager.error("err");
  });

  setViewGrowerInfoDialog(!viewGrowerInfoDialog);
}
  const viewGrowerNodeInfoClick = (consumerUserName) => {
    MainService.getConsumerNodeInfo(consumerUserName)
      .then((res) => {
        setNodeUserName(res[0].username);
        setNodeUserNodeId(res[0].nodeid);
        setNodeUserAccountType(res[0].accounttype);
        setNodeUserUserType(res[0].usertype);
        setNodeUserAssetType(res[0].assettype);
        setNodeUserNumberOfAssets(res[0].numberofassets);
        setNodeUserAreaSize(res[0].areasize);
        setNodeUserGrazingType(res[0].grazingtype);
        setNodeUserFeedType(res[0].feedtype);
        setNodeUserNumberOfKids(res[0].numberofkids);
        setNodeUserMaleGenderNumber(res[0].malegendernumber);
        setNodeUserFemaleGenderNumber(res[0].femalegendernumber);
        setNodeUserOverallAge(res[0].overallage);
        setNodeUserBreed(res[0].breed);
        setNodeUserFarmingExperience(res[0].farmingexperience);
        setNodeUserNodeStatus(res[0].nodestatus);
        setNodeUserContentUrl(res[0].contenturl);
        setNodeUserNodeType(res[0].nodetype);
        setNodeUserAssetverticalId(res[0].assetverticalid);
        setNodeUserAssetverticalFriendlyName(res[0].assetverticalfriendlyname);
        setNodeUserFirstName(res[0].firstname);
        setNodeUserLastName(res[0].lastname);
        setNodeUserEmail(res[0].email);
        setNodeUserPhone(res[0].phone);
        setNodeUserAddress(res[0].address);
        setNodeUserCity(res[0].city);
        setNodeUserState(res[0].state);
        setNodeUserDistrict(res[0].district);
        setNodeUserPincode(res[0].pincode);
        setNodeUserLatitude(res[0].latitude);
        setNodeUserLongitude(res[0].longitude);
        setNodeUserTimestamp(res[0].timestamp);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setGrowerNodeInfoDialog(!farmerNodeInfoDialog);
  };
  const nodeHandler = (farmername, Type) => {
    setPage(0);
    if (Type === "List") {
      getListOpe(false);
      getGridOpe(false);
      setNode(true);
      setGridNode(false);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    } else if (Type === "Grid") {
      getListOpe(false);
      getGridOpe(false);
      setNode(false);
      setGridNode(true);
      setApproval(false);
      setGridApproval(false);
      setGrow(false);
      setGridGrow(false);
      setSell(false);
      setGridSell(false);
      setLoan(false);
      setGridLoan(false);
      setVetVisit(false);
      setGridVetVisit(false);
      setBuyInput(false);
      setGridBuyInput(false);
      setStore(false);
      setGridStore(false);
      setAdvisory(false);
      setGridAdvisory(false);
    }

    MainService.getNodeInfo(farmername)
      .then((res) => {
        setGrowerNodesList(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /*************** End Of Create, Update, Delete, NodeStatus, NodeInfo Growers ***************/
  /* End Of API Section */

  const gotoFarmsClick = (viewType) => {
    if (viewType === "Grid") {
      setGridNode(false);
      setGridAdvisory(false);
      setGridBuyInput(false);
      setGridGrow(false);
      setGridLoan(false);
      setGridSell(false);
      setGridStore(false);
      setGridVetVisit(false);
      if (
        viewGridNode === false ||
        viewGridAdvisory === false ||
        viewApproval === false ||
        viewGidVetVisit === false ||
        viewGridBuyInput === false ||
        viewGridGrow === false ||
        viewGridLoan === false ||
        viewGridSell === false ||
        viewGridStore === false
      ) {
        getGridOpe(true);
      }
    } else if (viewType === "List") {
      setNode(false);
      setAdvisory(false);
      setBuyInput(false);
      setGrow(false);
      setLoan(false);
      setSell(false);
      setStore(false);
      setVetVisit(false);
      if (
        viewNode === false ||
        viewAdvisory === false ||
        viewApproval === false ||
        viewBuyInput === false ||
        viewGrow === false ||
        viewLoan === false ||
        viewSell === false ||
        viewStore === false ||
        viewVetVisit === false
      ) {
        getListOpe(true);
      }
    }
  };

  /* Sigend Agreemnts view and update status */
  const viewKYCClick = (user)=>{
    setSelectedConsumerUserName(user);
    setViewKYCDialog(!viewKYCDialog);
  }
const viewSignedAgreementClick = (user,from) => {
  MainService.getContentsBasedonCreator( user, "Ep2AUBOYK0")
  .then((res) => {
    if (res.length > 0) {
      setAgreementsDocumentLink(res[0].texturl);
      //setAgreementDocumentStatus(res[0].documentstatus);
      setIsLoading(false);
    } else {
      console.log("setViewSignedDocumentList", res);
      setAgreementsDocumentLink(null);
      //setAgreementDocumentStatus("");
      setIsLoading(false);
    }
  })
    .catch((err) => {
      return NotificationManager.error(err);
    });
  setIsLoading(false);
    if(from !== "fromupdatebtn")
    {
      setViewSignedAgreementDialog(!viewSignedAgreementDialog);
    }
  
};
const updateAgreementStatusClick = (user) => {
  viewSignedAgreementClick(user,"fromupdatebtn")
  setSelectedConsumerUserName(user);
  setUpdateAgreementStatusDialog(!updateAgreementStatusDialog);
};
const updateAgreementStatusHandler = () => {
  MainService.updateDocumentStatus(
    agreementStatusValue,
    username,
    selectedConsumerUserName,
    "GrowerAgreementDocument"
  )
    .then((res) => {
      return NotificationManager.success(res);
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
  setUpdateAgreementStatusDialog(!updateAgreementStatusDialog);
};
/* End of Agreemnet view and update status */
  return (
    <React.Fragment>
      <div className="container" id="mainContentList">
        {(setListOpe === true || setGridOpe === true) &&
        <Fab
        color="primary"
        size={"large"}
        aria-label="add"
        title="Add Grower"
        className={classes.fab}
        onClick={() => {
          setConsumerUserDialog(!consumerUserDialog);
        }}
      >
        <AddIcon></AddIcon>
      </Fab>
        }
      
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                listOfGrowers("Grid");
              }}
            ></Button>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                listOfGrowers("List");
              }}
            ></Button>
            &nbsp;
            {/* <Button
              color="primary"
              size="large"
              className="registerBtn"
              variant="contained"
            >View Parent Growers</Button> */}
          </div>
        </div>
        {/* LIST OF GROWERS */}
        
        
        
        {setListOpe === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
            {farmersList.length > 0 ? (
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 450 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={24}>
                          LIST OF GROWERS
                        </TableCell>
                      </TableRow>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          Grower Id
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Grower Name
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Email
                        </TableCell>
                        <TableCell align="center" colSpan={5}>
                          Farm Details
                        </TableCell>
                        <TableCell align="center" colSpan={6}>
                          Grower Operations
                        </TableCell>
                        <TableCell align="center" colSpan={10}>
                          Grower Requests
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {farmersList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((user, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {user.userid}
                              </TableCell>
                              <TableCell align="center">
                                {user.username}
                              </TableCell>
                              <TableCell align="center">{user.email}</TableCell>
                              <TableCell />

                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    viewNodesClick(user.username, "List");
                                  }}
                                >
                                  View Nodes
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    viewSignedAgreementClick(
                                      user.username
                                    );
                                  }}
                                >
                                  View Signed Agreements
                                </Button>
                              </TableCell>
                              <TableCell>
                              <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                viewKYCClick(user.username);
                              }}
                            >
                              View KYC
                            </Button>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    viewGrowerInfoClick(user.username);
                                  }}
                                >
                                  View Grower Info
                                </Button>
                              </TableCell>
                              {/* <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    resetPasswordDialogClick(user.userid);
                                  }}
                                >
                                  Reset Password
                                </Button>
                              </TableCell> */}
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    consumerUserUpdateDialogClick(user);
                                  }}
                                >
                                  Update
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    deleteDialogClick(
                                      user.username,
                                      user.userid
                                    );
                                  }}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                              {/* <TableCell align="center">
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    updateAgreementStatusClick(
                                      user.username
                                    );
                                  }}
                                >
                                  Update Agreement Status
                                </Button>
                              </TableCell> */}
                              <TableCell />
                              <TableCell align="center" />

                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    growRequestHandler(user.username, "List");
                                  }}
                                >
                                  View Grow Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    sellRequestHandler(user.username, "List");
                                  }}
                                >
                                  View Sell Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    LoanRequestHandler(user.username, "List");
                                  }}
                                >
                                  View Loan Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    vetVisitRequestHandler(
                                      user.username,
                                      "List"
                                    );
                                  }}
                                >
                                  View VET Visit Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    buyInputRequestHandler(
                                      user.username,
                                      "List"
                                    );
                                  }}
                                >
                                  View Buy Input Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    storeRequestHandler(user.username, "List");
                                  }}
                                >
                                  View Store Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    advisoryRequestHandler(
                                      user.username,
                                      "List"
                                    );
                                  }}
                                >
                                  View Advisory Requests
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={farmersList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <h4>No Growers Found</h4>
            )}
          </Box>
        ) : null}

        {isLoading ? (
          <div className="cardStyleForDashboardItems">
            <CircularProgress />
          </div>
        ) : (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            {setGridOpe === true ? (
              <Box sx={{ width: "100%", marginBottom: 30 }}>
                <SearchBar
                  value={farmgridsearched}
                  onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                  onCancelSearch={() => cancelFarmGridSearch()}
                />
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    LIST OF GROWERS
                  </h4>
                  {farmersList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {farmersList.map((ope, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Grower Id:</b>
                              {ope.userid}
                            </p>
                            <p>
                              <b>Grower Name:</b>
                              {ope.username}
                            </p>
                            <p>
                              <b>Email:</b>
                              {ope.email}
                            </p>
                            <h4 className="cardactiontitle">Farm Details</h4>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  viewNodesClick(ope.username, "Grid");
                                }}
                              >
                                View Nodes
                              </Button>
                              &nbsp;
                          <Button
                            type="button"
                            variant="contained"
                            onClick={() => {
                              viewSignedAgreementClick(ope.username);
                            }}
                          >
                            View Signed Agreements
                          </Button>
                            </div>
                            &nbsp;&nbsp;
                            <div>
                            <Button
                              style={{ marginRight: "10px" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                viewKYCClick(ope.username);
                              }}
                            >
                              View KYC
                            </Button>
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <h4 className="cardactiontitle">
                                Grower Operations
                              </h4>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  viewGrowerInfoClick(ope.username);
                                }}
                              >
                                View Grower Info
                              </Button>
                              
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  consumerUserUpdateDialogClick(ope);
                                }}
                              >
                                Update
                              </Button>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  deleteDialogClick(ope.username, ope.userid);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                            &nbsp;&nbsp;
                          {/* <div>
                            <Button
                              type="button"
                              variant="contained"
                              onClick={() => {
                                updateAgreementStatusClick(ope.username);
                              }}
                            >
                              Update Agreement Status
                            </Button>
                          </div> */}
                            <div style={{ width: "100%", margin: "5px" }}>
                              <h4 className="cardactiontitle">
                                Grower Requests
                              </h4>

                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  growRequestHandler(ope.username, "Grid");
                                }}
                              >
                                View Grow Requests
                              </Button>
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  sellRequestHandler(ope.username, "Grid");
                                }}
                              >
                                View Sell Requests
                              </Button>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  LoanRequestHandler(ope.username, "Grid");
                                }}
                              >
                                View Loan Requests
                              </Button>
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  vetVisitRequestHandler(ope.username, "Grid");
                                }}
                              >
                                View VET Visit Requests
                              </Button>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  buyInputRequestHandler(ope.username, "Grid");
                                }}
                              >
                                View Buy Input Requests
                              </Button>
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  storeRequestHandler(ope.username, "Grid");
                                }}
                              >
                                View Store Requests
                              </Button>
                              <Button
                                style={{ margin: "5px", width: "40%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  advisoryRequestHandler(ope.username, "Grid");
                                }}
                              >
                                View Advisory Requests
                              </Button>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>No Growers Found.</h4>
                  )}
                </div>
              </Box>
            ) : null}
          </Box>
        )}
        
        {/* VIEW NODE LIST */}
        {viewNode === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("List")}
            >
              Back to Growers
            </Button>

            <h3>View List of Grower Nodes</h3>

            {farmerNodesList ? (
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 450 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={22}>
                          LIST OF GROWER NODES
                        </TableCell>
                      </TableRow>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={1}>
                          Node Id
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Node Name
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          Email
                        </TableCell>
                        <TableCell align="center" colSpan={5}>
                          Farm Details
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          Grower Operations
                        </TableCell>
                        <TableCell align="center" colSpan={10}>
                          Grower Requests
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {farmerNodesList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((user, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {user.nodeid}
                              </TableCell>
                              <TableCell align="center">
                                {user.username}
                              </TableCell>
                              <TableCell align="center">{user.email}</TableCell>
                              <TableCell />
                              
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    viewGrowerNodeInfoClick(user.username);
                                  }}
                                >
                                  Node Info
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    viewNodeStatusClick(user.nodeid);
                                  }}
                                >
                                  View Node Status
                                </Button>
                              </TableCell>
                              <TableCell >
                              <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              updateNodeStatusClick(user.username,user.nodeid);
                            }}
                          >
                           Update Node Status
                          </Button>
                              </TableCell>
                              <TableCell />
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    resetPasswordDialogClick(user.userid);
                                  }}
                                >
                                  Reset Password
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    consumerUserUpdateDialogClick(user);
                                  }}
                                >
                                  Update
                                </Button>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    approvalRequestHandler(
                                      user.username,
                                      "List"
                                    );
                                  }}
                                >
                                  Approval Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    growRequestHandler(user.username, "List");
                                  }}
                                >
                                  Grow Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    sellRequestHandler(user.username, "List");
                                  }}
                                >
                                  Sell Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    LoanRequestHandler(user.username, "List");
                                  }}
                                >
                                  Loan Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    vetVisitRequestHandler(
                                      user.username,
                                      "List"
                                    );
                                  }}
                                >
                                  VET Visit Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    buyInputRequestHandler(
                                      user.username,
                                      "List"
                                    );
                                  }}
                                >
                                  Buy Input Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    storeRequestHandler(user.username, "List");
                                  }}
                                >
                                  Store Requests
                                </Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    advisoryRequestHandler(
                                      user.username,
                                      "List"
                                    );
                                  }}
                                >
                                  Advisory Requests
                                </Button>
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={farmerNodesList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <h4>No Nodes Found</h4>
            )}
          </Box>
        ) : null}

        {viewGridNode === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <SearchBar
              value={farmgridsearched}
              onChange={(searchVal) => requestFarmGridSearch(searchVal)}
              onCancelSearch={() => cancelFarmGridSearch()}
            />
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("Grid")}
            >
              Back to Growers
            </Button>
            <div className="cardStyleForDashboardItems">
              <h4 style={{ width: "100%", textAlign: "center" }}>
                LIST OF GROWER NODES
              </h4>
              {farmerNodesList.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {farmerNodesList.map((ope, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Node Id:</b>
                          {ope.nodeid}
                        </p>
                        <p>
                          <b>Node Name:</b>
                          {ope.username}
                        </p>
                        <p>
                          <b>Email:</b>
                          {ope.email}
                        </p>
                        <h4 className="cardactiontitle">Farm Details</h4>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              viewGrowerNodeInfoClick(ope.username);
                            }}
                          >
                            Node Info
                          </Button>
                          
                          &nbsp;&nbsp;
                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              viewNodeStatusClick(ope.nodeid);
                            }}
                          >
                           View Node Status
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              updateNodeStatusClick(ope.username,ope.nodeid);
                            }}
                          >
                           Update Node Status
                          </Button>
                        </div>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <h4 className="cardactiontitle">Grower Operations</h4>
                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              resetPasswordDialogClick(ope.userid);
                            }}
                          >
                            Reset Password
                          </Button>
                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              consumerUserUpdateDialogClick(ope);
                            }}
                          >
                            Update
                          </Button>
                        </div>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <h4 className="cardactiontitle">Grower Requests</h4>

                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              growRequestHandler(ope.username, "Grid");
                            }}
                          >
                            Grow Requests
                          </Button>
                        </div>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              sellRequestHandler(ope.username, "Grid");
                            }}
                          >
                            Sell Requests
                          </Button>
                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              LoanRequestHandler(ope.username, "Grid");
                            }}
                          >
                            Loan Requests
                          </Button>
                        </div>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              vetVisitRequestHandler(ope.username, "Grid");
                            }}
                          >
                            VET Visit Requests
                          </Button>
                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              buyInputRequestHandler(ope.username, "Grid");
                            }}
                          >
                            Buy Input Requests
                          </Button>
                        </div>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              storeRequestHandler(ope.username, "Grid");
                            }}
                          >
                            Store Requests
                          </Button>
                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              advisoryRequestHandler(ope.username, "Grid");
                            }}
                          >
                            Advisory Requests
                          </Button>
                        </div>
                        &nbsp;&nbsp;
                        <div>
                          <Button
                            style={{ margin: "5px", width: "40%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              advisoryRequestHandler(ope.username, "Grid");
                            }}
                          >
                            Market Price
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Nodes Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL APPROVAL REQUESTS */}
        {viewApproval === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL APPROVAL REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setApprovalReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setApprovalReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((approval, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {approval.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {approval.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {approval.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {approval.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {approval.timestamp}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          updateApprovalRequest(
                                            approval.requestid,
                                            approval.status
                                          );
                                        }}
                                      >
                                        Update Approval Request Status
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setApprovalReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No APPROVAL REQUESTS Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridApproval === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL APPROVAL REQUESTS</h4>
              </p>
              {setApprovalReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setApprovalReq.map((approval, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {approval.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {approval.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {approval.requestid}
                        </p>
                        <p>
                          <b>Status:</b>
                          {approval.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {approval.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "50%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              updateApprovalRequest(
                                approval.requestid,
                                approval.status
                              );
                            }}
                          >
                            Update Approval Request Status
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Approval Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL GROW REQUESTS */}
        {viewGrow === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL GROW REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setGrowReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Quantity
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setGrowReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((grow, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {grow.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.quantity}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {grow.timestamp}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          updateGrowRequest(
                                            grow.requestid,
                                            grow.status
                                          );
                                        }}
                                      >
                                        Update Grow Request Status
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setGrowReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No GROW REQUESTS Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridGrow === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL GROW REQUESTS</h4>
              </p>
              {setGrowReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setGrowReq.map((grow, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {grow.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {grow.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {grow.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {grow.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {grow.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {grow.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {grow.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {grow.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {grow.email}
                        </p>
                        <p>
                          <b>Quantity:</b>
                          {grow.quantity}
                        </p>
                        <p>
                          <b>Status:</b>
                          {grow.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {grow.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "50%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              updateGrowRequest(grow.requestid, grow.status);
                            }}
                          >
                            Update Grow Request Status
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Grow Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL SELL REQUESTS */}
        {viewSell === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL SELL REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setSellReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Quantity
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Total Selling Amount
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setSellReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((sell, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {sell.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.quantity}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.totalsellingamount}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {sell.timestamp}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          updateSellRequest(
                                            sell.requestid,
                                            sell.status
                                          );
                                        }}
                                      >
                                        Update Sell Request Status
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setSellReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No SELL REQUESTS Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridSell === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL SELL REQUESTS</h4>
              </p>
              {setSellReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setSellReq.map((sell, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {sell.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {sell.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {sell.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {sell.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {sell.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {sell.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {sell.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {sell.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {sell.email}
                        </p>
                        <p>
                          <b>Quantity:</b>
                          {sell.quantity}
                        </p>
                        <p>
                          <b>Total Selling Amount:</b>
                          {sell.totalsellingamount}
                        </p>
                        <p>
                          <b>Status:</b>
                          {sell.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {sell.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "50%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              updateSellRequest(sell.requestid, sell.status);
                            }}
                          >
                            Update Sell Request Status
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Sell Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL LOAN REQUESTS */}
        {viewLoan === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL LOAN REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setLoanReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setLoanReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((loan, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {loan.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {loan.timestamp}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          updateLoanRequest(
                                            loan.requestid,
                                            loan.status
                                          );
                                        }}
                                      >
                                        Update Loan Request Status
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setLoanReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No LOAN REQUESTS Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridLoan === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL LOAN REQUESTS</h4>
              </p>
              {setLoanReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setLoanReq.map((loan, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {loan.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {loan.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {loan.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {loan.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {loan.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {loan.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {loan.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {loan.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {loan.email}
                        </p>
                        <p>
                          <b>Status:</b>
                          {loan.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {loan.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "50%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              updateLoanRequest(loan.requestid, loan.status);
                            }}
                          >
                            Update Loan Request Status
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Loan Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL VET VISIT REQUESTS */}
        {viewVetVisit === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL VET VISIT REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setVetVisitReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setVetVisitReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((vet, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {vet.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {vet.timestamp}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          updateVetRequest(
                                            vet.requestid,
                                            vet.status
                                          );
                                        }}
                                      >
                                        Update Vet Visit Request Status
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setVetVisitReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No VET VISIT Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGidVetVisit === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <p style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL VET VISIT REQUESTS</h4>
              </p>
              {setVetVisitReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setVetVisitReq.map((vetvisit, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {vetvisit.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {vetvisit.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {vetvisit.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {vetvisit.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {vetvisit.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {vetvisit.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {vetvisit.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {vetvisit.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {vetvisit.email}
                        </p>
                        <p>
                          <b>Status:</b>
                          {vetvisit.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {vetvisit.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "50%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              updateVetRequest(
                                vetvisit.requestid,
                                vetvisit.status
                              );
                            }}
                          >
                            Update Vet Visit Request Status
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Vet Visit Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL BUY INPUT REQUESTS */}
        {viewBuyInput === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL BUY INPUT REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setBuyInputReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Growering Input
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setBuyInputReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((buyinput, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {buyinput.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.groweringinput}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {buyinput.timestamp}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          updateBuyRequest(
                                            buyinput.requestid,
                                            buyinput.status
                                          );
                                        }}
                                      >
                                        Update Buy Input Request Status
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setBuyInputReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No BUY INPUT Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridBuyInput === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL BUY INPUT REQUESTS</h4>
              </span>
              {setBuyInputReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setBuyInputReq.map((buyinput, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {buyinput.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {buyinput.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {buyinput.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {buyinput.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {buyinput.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {buyinput.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {buyinput.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {buyinput.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {buyinput.email}
                        </p>
                        <p>
                          <b>Growering Input:</b>
                          {buyinput.groweringinput}
                        </p>
                        <p>
                          <b>Status:</b>
                          {buyinput.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {buyinput.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "50%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              updateBuyRequest(
                                buyinput.requestid,
                                buyinput.status
                              );
                            }}
                          >
                            Update Buy Input Request Status
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Buy Input Requests Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL STORE REQUESTS */}
        {viewStore === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL STORE REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setStoreReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Quantity
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Status
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Option
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setStoreReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((store, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {store.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.quantity}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      {store.timestamp}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          updateStoreRequest(
                                            store.requestid,
                                            store.status
                                          );
                                        }}
                                      >
                                        Update Store Request Status
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setStoreReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No Store Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridStore === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL STORE REQUESTS</h4>
              </span>
              {setStoreReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setStoreReq.map((store, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {store.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {store.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {store.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {store.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {store.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {store.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {store.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {store.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {store.email}
                        </p>
                        <p>
                          <b>Quantity:</b>
                          {store.quantity}
                        </p>
                        <p>
                          <b>Status:</b>
                          {store.status}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {store.timestamp}
                        </p>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{ margin: "5px", width: "50%" }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              updateStoreRequest(store.requestid, store.status);
                            }}
                          >
                            Update Store Request Status
                          </Button>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Store Request Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* ALL ADVISORY REQUESTS */}
        {viewAdvisory === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("List")}
            >
              Back to Farms
            </Button>
            <div className="MuiGrid-root">
              <h2>ALL ADVISORY REQUESTS</h2>
              <div className="cardStyleForDashboardItems">
                <div className="cardStyleForDashboardItems">
                  {setAdvisoryReq.length > 0 ? (
                    <Paper sx={{ width: 1100 }}>
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Source
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Destination
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Request Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Asset Vertical Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Category
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Legacy Asset Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Model Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Phone
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Email
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Advisory Input
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {setAdvisoryReq
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((advisory, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                      {advisory.source}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.destination}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.requestid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.assetverticalid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.category}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.legacyassettype}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.modelid}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.phone}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.advisoryinputs}
                                    </TableCell>
                                    <TableCell align="center">
                                      {advisory.timestamp}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={setAdvisoryReq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>No ADVISORY Found</h4>
                  )}
                </div>
              </div>
            </div>
          </Box>
        ) : null}

        {viewGridAdvisory === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <Button
              variant="contained"
              style={{ margin: "2px" }}
              sx={{ bgcolor: "#21756e" }}
              onClick={() => gotoFarmsClick("Grid")}
            >
              Back to Farms
            </Button>
            <div className="cardStyleForDashboardItems">
              <span style={{ width: "100%", textAlign: "center" }}>
                <h4>ALL ADVISORY REQUESTS</h4>
              </span>
              {setAdvisoryReq.length > 0 ? (
                <div className="cardStyleForDashboardItems">
                  {setAdvisoryReq.map((advisory, index) => (
                    <div key={index} className="cardStyleForDashboardItemsBody">
                      <span>
                        <p>
                          <b>Source:</b>
                          {advisory.source}
                        </p>
                        <p>
                          <b>Destination:</b>
                          {advisory.destination}
                        </p>
                        <p>
                          <b>Request Id:</b>
                          {advisory.requestid}
                        </p>
                        <p>
                          <b>Asset Vertical Id:</b>
                          {advisory.assetverticalid}
                        </p>
                        <p>
                          <b>Category:</b>
                          {advisory.category}
                        </p>
                        <p>
                          <b>Legacy Asset Type:</b>
                          {advisory.legacyassettype}
                        </p>
                        <p>
                          <b>Model Id:</b>
                          {advisory.modelid}
                        </p>
                        <p>
                          <b>Phone:</b>
                          {advisory.phone}
                        </p>
                        <p>
                          <b>Email:</b>
                          {advisory.email}
                        </p>
                        <p>
                          <b>Advisory Input:</b>
                          {advisory.advisoryinputs}
                        </p>
                        <p>
                          <b>Timestamp:</b>
                          {advisory.timestamp}
                        </p>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <h4>No Advisory Request Found.</h4>
              )}
            </div>
          </Box>
        ) : null}
        {/* NODE STATUS */}
        <Dialog
          fullScreen={fullScreen}
          open={viewNodeUpdate}
          onClose={handleUpdateNodeClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Node Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nodename">Node Name:</InputLabel>
              <Input
                id="nodename"
                name="nodename"
                placeholder="Node Name"
                type="text"
                value={setNodeName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nodename">Existing Node Status:</InputLabel>
              <Input
                id="nodename"
                name="nodename"
                placeholder="Node Name"
                type="text"
                value={setNodeName}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Node Status</InputLabel>
              <Select
                id="status"
                value={setNodeStatus}
                onChange={(e) => {
                  getNodeStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem value="" selected disabled>
                  {" "}
                  Select Any Node Status
                </MenuItem>
                <MenuItem
                  key="authenticationpending"
                  value="authentication-pending"
                >
                  {" "}
                  authentication-pending{" "}
                </MenuItem>
                <MenuItem key="authenticated" value="authenticated">
                  {" "}
                  authenticated{" "}
                </MenuItem>
                <MenuItem key="approved" value="approved">
                  {" "}
                  approved{" "}
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={updateNodeStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={handleUpdateNodeClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Grower INFO */}
        <Dialog
          fullScreen={fullScreen}
          open={viewGrowerInfoDialog}
          onClose={nodeInfoCloseHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Grower Info"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <p><b>Enterprice Id:</b>{setInfoEnterpriceId}</p>
            <p><b>User Name:</b>{setInfoUserName}</p>
            <p><b>First Name:</b>{setInfoFname}</p>
            <p><b>Last Name:</b>{setInfoLname}</p>
            <p><b>User Type:</b>{setInfoUserType}</p>
            <p><b>Email Id:</b>{setInfoEmail}</p>
            <p><b>Phone:</b>{setInfoPhone}</p>
            <p><b>Address:</b>{setInfoAddress}</p>
            <p><b>City:</b>{setInfoCity}</p>
            <p><b>State:</b>{setInfoState}</p>
            <p><b>Pancard Number:</b>{setInfoPan}</p>
            <p><b>Domain:</b>{setInfoDomain}</p>
            <p><b>Nominee:</b>{setInfoNominee}</p>
            <p><b>Message:</b>{setInfoMessage}</p>
            <p><b>Longitude:</b>{setInfoLongitude}</p>
            <p><b>Latitude:</b>{setInfoLatitude}</p>
            <p><b>Node Status:</b>{setInfoStatus}</p>
           
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={nodeInfoCloseHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Approval Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setUpdateRequest}
          onClose={updateRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Approval Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setApprovalId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setApprovalPostStatus}
                onChange={(e) => {
                  getApprovalPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem>
                  <MenuItem key="in-progress" value="in-progress"> in-progress </MenuItem> */}
                <MenuItem key="request-approved" value="request-approved">
                  {" "}
                  request-approved{" "}
                </MenuItem>
                {/* <MenuItem key="accepted" value="accepted"> accepted </MenuItem>
                  <MenuItem key="rejected" value="rejected"> rejected </MenuItem>
                  <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={approvalReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Grow Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setGrowUpdateRequest}
          onClose={updateGrowRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Grow Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setGrowId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setGrowPostStatus}
                onChange={(e) => {
                  getGrowPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="approved" value="approved">
                  {" "}
                  approved{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={growReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateGrowRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Sell Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setSellUpdateRequest}
          onClose={updateSellRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Sell Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setSellId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setSellPostStatus}
                onChange={(e) => {
                  getSellPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="approved" value="approved">
                  {" "}
                  approved{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={sellReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateSellRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Loan Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setLoanUpdateRequest}
          onClose={updateLoanRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Loan Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setLoanId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setLoanPostStatus}
                onChange={(e) => {
                  getLoanPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>                
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="approved" value="approved">
                  {" "}
                  approved{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={loanReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateLoanRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Vet Visit Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setVetUpdateRequest}
          onClose={updateVetRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Vet Visit Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setVetId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setVetPostStatus}
                onChange={(e) => {
                  getVetPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="approved" value="approved">
                  {" "}
                  approved{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={vetReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateVetRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Buy Input Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setBuyUpdateRequest}
          onClose={updateBuyRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Buy Input Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setBuyId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setBuyPostStatus}
                onChange={(e) => {
                  getBuyPostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>                
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="approved" value="approved">
                  {" "}
                  approved{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={buyReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateBuyRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Store Request Update */}
        <Dialog
          fullScreen={fullScreen}
          open={setStoreUpdateRequest}
          onClose={updateStoreRequestHandler}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Update Store Request Status"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
              <Input
                id="approvalid"
                name="approvalid"
                placeholder="Request Id"
                type="text"
                value={setStoreId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="status">Update Status</InputLabel>
              <Select
                id="status"
                value={setStorePostStatus}
                onChange={(e) => {
                  getStorePostStatus(e.target.value);
                }}
                label="Select"
              >
                <MenuItem selected disabled>
                  {" "}
                  Select Any Status
                </MenuItem>
                {/* <MenuItem key="new-grow-request" value="new-approval-request"> new-approval-request </MenuItem> */}
                <MenuItem key="in-progress" value="in-progress">
                  {" "}
                  in-progress{" "}
                </MenuItem>                
                <MenuItem key="accepted" value="accepted">
                  {" "}
                  accepted{" "}
                </MenuItem>
                <MenuItem key="approved" value="approved">
                  {" "}
                  approved{" "}
                </MenuItem>
                <MenuItem key="rejected" value="rejected">
                  {" "}
                  rejected{" "}
                </MenuItem>
                {/* <MenuItem key="fulfilled" value="fulfilled"> fulfilled </MenuItem> */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={storeReqStatusHandler}
            >
              Submit
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={updateStoreRequestHandler}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* create Consumer User Grower */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserDialog}
          aria-labelledby="craete Grower "
        >
          <DialogTitle id="createConsumerUser">{"Create Grower"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">Consumer User Name * :</InputLabel>
              <Input
                id="username"
                name="username"
                type="email"
                value={usernameValue}
                onChange={(e) => {
                  setUsernameValue(e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstName">First Name *:</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                value={firstNameValue}
                onChange={(e) => {
                  setFirstNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastName">Last Name *:</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                value={lastNameValue}
                onChange={(e) => {
                  setLastNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="domainName">Domain Name *:</InputLabel>
              <Input
                id="domainName"
                name="domainName"
                type="text"
                value={username}
                
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="userType">User Type *</InputLabel>
              <Select
                id="userType"
                name="userType"
                value={userTypeValue}
                onChange={(e) => {
                  setUserTypeValue(e.target.value);
                }}
              >
                <MenuItem value="Grower">Grower</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id *:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number *:</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={phoneNumerValue}
                onChange={(e) => {
                  setPhoneNumerValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={addressValue}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={cityNameValue}
                onChange={(e) => {
                  setCityNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={stateNameValue}
                onChange={(e) => {
                  setStateNameValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Nominee :</InputLabel>
              <Input
                id="nominee"
                name="nominee"
                type="text"
                value={nomineeValue}
                onChange={(e) => {
                  setNomineeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pancard">Pancard Number :</InputLabel>
              <Input
                id="pancard"
                name="pancard"
                type="text"
                value={pancardValue}
                onChange={(e) => {
                  setPancardValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={longitudeValue}
                onChange={(e) => {
                  setLongitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={latitudeValue}
                onChange={(e) => {
                  setLatitudeValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={messageValue}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                createconsumerUsersubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setIsLoading(false);
                setConsumerUserDialog(!consumerUserDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Create Consumer User Grower */}
        {/* Delete Grower */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserDeleteDialog}
          // onClose={handleClose}
          aria-labelledby="deleteConsumerUser"
        >
          <DialogTitle id="deleteConsumerUser">
            {"Delete Grower User"}
          </DialogTitle>
          <DialogContent>
            <h4>Are you sure want to delete?</h4>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteSubmitClick();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Delete Grower */}
        
        {/* Update Grower*/}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserUpdateDialog}
          // onClose={handleClose}
          aria-labelledby="UpdateConsumerUser"
        >
          <DialogTitle id="UpdateConsumerUser">{"Update Grower"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">Consumer UserName *:</InputLabel>
              <Input
                disabled
                id="username"
                name="username"
                type="email"
                value={consumerUserUserName}
              />
            </FormControl>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstName">First Name *:</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                value={consumerUserFirstName}
                onChange={(e) => {
                  setConsumerUserFirstName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastName">Last Name *:</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                value={consumerUserLastName}
                onChange={(e) => {
                  setConsumerUserLastName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="domainName">Domain Name *:</InputLabel>
              <Input
                disabled
                id="domainName"
                name="domainName"
                type="text"
                value={consumerUserUserDomainName}
                onChange={(e) => {
                  setConsumerUserUserDomainName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="userType">User Type *:</InputLabel>
              <Select
                disabled
                id="userType"
                name="userType"
                value={consumerUserUserType}
              ></Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">E-Mail Id *:</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={consumerUserEmail}
                onChange={(e) => {
                  setConsumerUserEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mobileNumber">Mobile Number *:</InputLabel>
              <Input
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                value={consumerUserPhone}
                onChange={(e) => {
                  setConsumerUserPhone(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Address:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={consumerUserAddress}
                onChange={(e) => {
                  setConsumerUserAddress(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City :</InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={consumerUserCity}
                onChange={(e) => {
                  setConsumerUserCity(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State :</InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={consumerUserState}
                onChange={(e) => {
                  setConsumerUserState(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Nominee :</InputLabel>
              <Input
                id="nominee"
                name="nominee"
                type="text"
                value={consumerUserNominee}
                onChange={(e) => {
                  setConsumerUserNominee(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pancard">Pancard Number :</InputLabel>
              <Input
                id="pancard"
                name="pancard"
                type="text"
                value={consumerUserPancard}
                onChange={(e) => {
                  setConsumerUserPancard(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={consumerUserLongitude}
                onChange={(e) => {
                  setConsumerUserLongitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={consumerUserLatitude}
                onChange={(e) => {
                  setConsumerUserLatitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="messageDescription">Message:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={consumerUserMessage}
                onChange={(e) => {
                  setConsumerUserMessage(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                consumerUserUpdateSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserUpdateDialog(!consumerUserUpdateDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Update Grower*/}
        {/* Reset Password Of Grower */}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserResetPasswordDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserResetPassword"
        >
          <DialogTitle id="consumerUserResetPassword">
            Grower Password Reset
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="newPassword">New Password:</InputLabel>
              <Input
                id="newPassword"
                name="newPassword"
                type="text"
                value={newPasswordValue}
                onChange={(e) => {
                  setNewPasswordValue(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="passwordConfirmation">
                Password Confirmation:
              </InputLabel>
              <Input
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="text"
                value={passwordConfirmationValue}
                onChange={(e) => {
                  setPasswordConfirmationValue(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                resetPasswordSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setconsumerUserResetPasswordDialog(
                  !consumerUserResetPasswordDialog
                );
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Reset Password Grower */}
        {/* Node Status Update*/}
        <Dialog
          fullScreen={fullScreen}
          open={consumerUserUpdateNodeStatusDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserNodeUpdate"
        >
          <DialogTitle id="consumerUserNodeUpdate">
            Update Node Status
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nodeName">Node Name :</InputLabel>
              <Input
                disabled
                id="nodeName"
                name="nodeName"
                type="text"
                value={setNodeName}
              />
            </FormControl>
            <InputLabel htmlFor="existingNodeStatus">Existing Node Status :</InputLabel>
              <Input
                disabled
                id="existingNodeStatus"
                name="existingNodeStatus"
                type="text"
                value={setNodeStatus}
              />
           
            <FormControl fullWidth>
              <InputLabel id="nodeStatus">Update Node Status</InputLabel>
              <Select
                id="nodeStatus"
                name="nodeStatus"
                value={nodeStatusValue}
                onChange={(e) => {
                  setNodeStatusValue(e.target.value);
                }}
              >
                <MenuItem value="authentication-pending">Authentication Pending</MenuItem>
                <MenuItem value="authenticated">Authenticated</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateNodeStatusSubmit();
              }}
            >
              Update
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setConsumerUserUpdateNodeStatusDialog(
                  !consumerUserUpdateNodeStatusDialog
                );
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Node Status Update*/}
        {/*Node Info */}
        <Dialog
          fullScreen={fullScreen}
          open={farmerNodeInfoDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserNodeInfo"
        >
          <DialogTitle id="consumerUserNodeInfo">View Node Info</DialogTitle>
          <DialogContent>
            <p>
              <b>User Name : </b>
              {nodeUserName}
            </p>
            <p>
              <b>Node ID : </b>
              {nodeUserNodeId}
            </p>
            <p>
              <b>Account Type : </b>
              {nodeUserAccountType}
            </p>
            <p>
              <b>User Type : </b>
              {nodeUserUserType}
            </p>
            <p>
              <b>Asset Type : </b>
              {nodeUserAssetType}
            </p>
            <p>
              <b>Number Of Assets : </b>
              {nodeUserNumberOfAssets}
            </p>
            <p>
              <b>Area Size : </b>
              {nodeUserAreaSize}
            </p>
            <p>
              <b>Grazing Type : </b>
              {nodeUserGrazingType}
            </p>
            <p>
              <b>Feed Type : </b>
              {nodeUserFeedType}
            </p>
            <p>
              <b>Number Of Kids : </b>
              {nodeUserNumberOfKids}
            </p>
            <p>
              <b>Male Gender Number : </b>
              {nodeUserMaleGenderNumber}
            </p>
            <p>
              <b>Female Gender Number : </b>
              {nodeUserFemaleGenderNumber}
            </p>
            <p>
              <b>Over All Age : </b>
              {nodeUserOverallAge}
            </p>
            <p>
              <b>Breed : </b>
              {nodeUserBreed}
            </p>
            <p>
              <b>Farming Experience : </b>
              {nodeUserFarmingExperience}
            </p>
            <p>
              <b>Node Status : </b>
              {nodeUserNodeStatus}
            </p>
            <p>
              <b>Content Url : </b>
              {nodeUserContentUrl}
            </p>
            <p>
              <b>Node Type : </b>
              {nodeUserNodeType}
            </p>
            <p>
              <b>Assetvertical Id : </b>
              {nodeUserAssetverticalId}
            </p>
            <p>
              <b>Assetvertical Friendly Name: </b>
              {nodeUserAssetverticalFriendlyName}
            </p>
            <p>
              <b>Firstname : </b>
              {nodeUserFirstName}
            </p>
            <p>
              <b>Last Name : </b>
              {nodeUserLastName}
            </p>
            <p>
              <b>Email : </b>
              {nodeUserEmail}
            </p>
            <p>
              <b>Phone : </b>
              {nodeUserPhone}
            </p>
            <p>
              <b>State : </b>
              {nodeUserState}
            </p>
            <p>
              <b>City : </b>
              {nodeUserCity}
            </p>
            <p>
              <b>District : </b>
              {nodeUserDistrict}
            </p>
            <p>
              <b>Pincode : </b>
              {nodeUserPincode}
            </p>
            <p>
              <b>Latitude : </b>
              {nodeUserLatitude}
            </p>
            <p>
              <b>Longitude : </b>
              {nodeUserLongitude}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setGrowerNodeInfoDialog(
                  !farmerNodeInfoDialog
                );
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/*End Of Node Info */}
        {/* Node Status */}
        <Dialog
          fullScreen={fullScreen}
          open={viewNodeStatusDialog}
          // onClose={handleClose}
          aria-labelledby="consumerUserNodeUpdate"
        >
          <DialogTitle id="consumerUserNodeUpdate">
            View Node Status
          </DialogTitle>
          <DialogContent>
            <p>
              <b>Node Name:</b>
              {setNodeName}
            </p>
            <p>
              <b>Node ID:</b>
              {nodeId}
            </p>
            <p>
              <b>Node Status:</b>
              {setNodeStatus}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setViewNodeStatusDialog(!viewNodeStatusDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Node Status */}
        {/* View signed Agreeement */}
        <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={viewSignedAgreementDialog}
          aria-labelledby="viewAgreements"
        >
          <DialogTitle id="viewAgreements">
            {"View Signed Agreement"}
          </DialogTitle>
          <DialogContent>
            {AgreementsDocumentLink ? (
              <>
                {isLoading ? (
                  true
                ) : (
                  <embed
                    accept="image/*,.pdf"
                    className="viewWidthHeight"
                    src={AgreementsDocumentLink}
                  ></embed>
                )}
              </>
            ) : (
              <h4>No Agreement Found</h4>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setViewSignedAgreementDialog(!viewSignedAgreementDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* update signed Agreeement status*/}
        <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={updateAgreementStatusDialog}
          aria-labelledby="viewAgreements"
        >
          <DialogTitle id="viewAgreements">
            {"View Signed Agreement"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="existingStatus">
                Existing Status :
              </InputLabel>
              <Input
                disabled
                id="existingStatus"
                name="existingStatus"
                type="text"
                value={agreementDocumentStatus}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="status">Choose Status *</InputLabel>
              <Select
                id="status"
                value={agreementStatusValue}
                label="Choose Status"
                onChange={(e) => {
                  setAgreementStatusValue(e.target.value);
                }}
              >
                <MenuItem value="Agreement-Verification-In-Progress">
                  Agreement-Verification-In-Progress
                </MenuItem>
                <MenuItem value="Agreement-Verified">
                  Agreement-Verified
                </MenuItem>
                <MenuItem value="Agreement-Rejected">
                  Agreement-Rejected
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateAgreementStatusHandler();
              }}
            >
              Update
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUpdateAgreementStatusDialog(!updateAgreementStatusDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* View KYC documents*/}
        <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={viewKYCDialog}
          aria-labelledby="viewKYC"
        >
          <DialogContent>
            <UploadKYCDocumentsComponent selectedUser={selectedConsumerUserName}/>
          </DialogContent>
          <DialogActions>
            
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setViewKYCDialog(!viewKYCDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
