import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";
import { NotificationManager } from "react-notifications";
import { Button } from "@material-ui/core";
import { FiThumbsUp } from "react-icons/fi";
import SearchBar from "material-ui-search-bar";
import { NavLink } from "react-router-dom";
import { Container } from "@mui/material";

class RawProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perPageprod: 2,
      offsetprod: 0,
      prodPageCount: 1,
      currentPageprod: 0,
      farmMachinerysData: [],
      filteredfarmMachinerysData: [],
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      productOwnsers: [],
      productsSubOwner:[],
      suppliesidVlaue: "",
      farmgridsearched: "",
      filterIdValue: "",
      subCategoriesList: [],
      brandRelatedSubCategory:[],
    };
  }

  componentDidMount() {
    // this.getContentCreators();
    this.getProductCategory();
    this.getContentSubOwners();
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (!this.state.suppliesidVlaue) return;
  //   if (prevState.suppliesidVlaue !== this.state.suppliesidVlaue) {
  //     this.getAllProcessedProducts();
  //   };
  // };


  
  // getAllProcessedProducts() {
  //   this.setState({
  //     productOwnsers : "",
  //     subCategoriesList : "",
  //   });
  //   fetch(
  //     `${window.appConfig.getProductsBasedonCategoryId}${window.appConfig.Relam}${this.state.suppliesidVlaue}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + this.state.InitialAccessToken,
  //       },
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((result) => {
  //       this.setState({
  //         farmMachinerysData: result.products,
  //         filteredfarmMachinerysData: result.products,
  //         prodPageCount: Math.ceil(
  //           result.products.length / this.state.perPageprod
  //         ),
  //       });
  //       this.getContentCreators();
  //       this.getCategorySubcategories(this.state.suppliesidVlaue);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };
  /** Get Brand Value or Raw Products Button*/
  getContentCreators(catID) {
    this.setState({
      productsSubOwner:"",
      subCategoriesList:"",
      brandRelatedSubCategory:""
    })
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}FOOD-PROCESSOR`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "RESULTTTTTTTTTTTTTTTTTTTTTTTTTT")

        const assetDeveloperUser = result.users.filter((value, index)=>{
          return value.username === "Sapna.R@Altiux.com"
        })
        this.supplierUserProducts(assetDeveloperUser[0].username);
        this.setState({
          productOwnsers: assetDeveloperUser,
        });
        this.getCategoryRelatedSubcategories(assetDeveloperUser[0].username, catID);
        this.getContentSubOwners();
        this.getCategorySubcategories(this.state.suppliesidVlaue);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  /** EOD Get Brand Value */

  /** Get Product Category */
  getProductCategory() {
    fetch(
      `${window.appConfig.getContentCategories}${window.appConfig.Relam}${window.appConfig.Relam}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {

        const inputCatID = result.categoryList.filter((data) => {
          return data.categoryname === "AGRI-ASSETS";
        });
        this.setState({
          suppliesidVlaue: inputCatID[0].categoryid,
        });
        this.getCategorySubcategories(inputCatID[0].categoryid);
        this.getContentCreators(inputCatID[0].categoryid);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
   /** EOD Get Product Category */

  /** Get Sub Brand Values */
  getContentSubOwners() {
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}NewAssetDeveloper2@gmail.com/Production-Manager`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          productsSubOwner: result.users
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  /** EOD Get Sub Brand Values */
  /**  Get Brand Values */
  supplierUserProducts(Val) {
    this.setState({
      subCategoriesList: "",
      contentsubcategoryname:""
    });
    fetch(
      `${window.appConfig.getProductsBasedonAdminUser}${window.appConfig.Relam}${Val}/${this.state.suppliesidVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result,"RESULT")
        this.getCategorySubcategories(this.state.suppliesidVlaue);
        this.getContentSubOwners();
        this.setState({
          farmMachinerysData: result.products,
          filteredfarmMachinerysData: result.products,
          prodPageCount: Math.ceil(
            result.products.length / this.state.perPageprod
          ),
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  /** EOD Get Brand Values */

  /** Get Sub Brand Bsed FilterProducts */
  supplierUserProductsBrand(Val) {
    this.setState({
      subCategoriesList: "",
    });
    this.getCategorySubcategories(this.state.suppliesidVlaue);
    fetch(
      `${window.appConfig.getProductsBasedonAdminUser}${window.appConfig.Relam}${Val}/${this.state.suppliesidVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          farmMachinerysData: result.products,
          prodPageCount: Math.ceil(
            result.products.length / this.state.perPageprod
          ),
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  /** EOD Get Sub Brand Bsed FilterProducts */


  /* PRODUCTS FILTER BASED ON THE Legacy Asset Type */
  requestFarmGridSearch(searchedVal) {
    const filteredRows = this.state.filteredfarmMachinerysData.filter((row) => {
      return row.legacyassettype
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    this.setState({
      farmMachinerysData: filteredRows,
      prodPageCount: Math.ceil(filteredRows.length / this.state.perPageprod),
    });
  }

  cancelFarmGridSearch = () => {
    this.setState({
      farmgridsearched: "",
    });
    this.requestFarmGridSearch(
      this.state.farmgridsearched,
      this.state.filterIdValue
    );
  };
  /* EOD PRODUCTS FILTER BASED ON THE Legacy Asset Type */

  /** Get Sub Categories Values */
  getCategorySubcategories(catID) {
    fetch(
      `${window.appConfig.getSubCategoriesBasedonCategoryID}${window.appConfig.Relam}contentSubCategories/${catID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        const unique = [
          ...new Map(
            result.contentsubcategories.map((data) => [
              data.contentsubcategoryname,
              data,
            ])
          ).values(),
        ];
        this.setState({
          subCategoriesList: unique,
        });
        // productsSubOwner: result.users
        this.getContentSubOwners()



      })
      .catch((err) => console.log("err", err));
  };
  /** EOD Get Sub Categories Values */




  // getCategoryRelatedSubCategories

  getCategoryRelatedSubcategories(userName,catID) {
    fetch(
      `${window.appConfig.getCategoryRelatedSubCategories}${window.appConfig.Relam}${userName}/${catID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "OOUUIFGUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU")
        const unique = [
          ...new Map(
            result.contentsubcategories.map((data) => [
              data.contentsubcategoryname,
              data,
            ])
          ).values(),
        ];
        this.setState({
          brandRelatedSubCategory: unique,
        });



      })
      .catch((err) => console.log("err", err));
  };

  /** Get Sub Categories Based Filtering */
  subCategoriesOnClick(subCategory) {
    this.setState({
      productsSubOwner:"",
   
    });
    this.getContentSubOwners();
    // this.getContentCreators()

    const filterData = this.state.filteredfarmMachinerysData.filter(
      (data, index) => {
        return data.contentsubcategory
          .toLowerCase()
          .includes(subCategory.toLowerCase());
      }
    );
    this.setState({
      farmMachinerysData: filterData,
      prodPageCount: Math.ceil(filterData.length / this.state.perPageprod),
    });
  };
  /** EOD Get Sub Categories Based Filtering */

  likeCount(product) {
    fetch(
      `${window.appConfig.productLikeCount}${product.consumeradminid}/${product.businessid}/${product.productid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.text())
      .then((result) => {
        // this.getAllRawProducts();
        return NotificationManager.success(result);
      })
      .catch((err) => console.log("err", err));
  }

  /*Pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offsetprod = selectedPage * this.state.perPageprod;
    this.setState({
      currentPageprod: selectedPage,
      offsetprod: offsetprod,
    });
  };
  /*End of pagination */

  render() {
    const {
      farmMachinerysData,
      productOwnsers,
      farmgridsearched,
      subCategoriesList,
      productsSubOwner,
      brandRelatedSubCategory
    } = this.state;

    const productsListOne = farmMachinerysData.slice(
      this.state.offsetprod,
      this.state.offsetprod + this.state.perPageprod
    );

    return (
      <>
        <Container className="explore-product-wrapper">
          <div className=" homepageSlider">
            {/* Farm Machinery's */}
            <div id="tendersContainer" className="container-fluid">
              <div className="mb-4">
                <div className="dropdown">
                  <div className="navbarRight">
                    <button
                      class="btn btn-success homepageBtn"
                      type="button"
                      onClick={() => this.getContentCreators(this.state.suppliesidVlaue)}
                    >
                      Agri Assets
                    </button>
                  </div>
                </div>
                {/* <div className="dropdown">
                  <div className="navbarRight">
                    <Form.Select
                      onChange={(e) =>
                        this.supplierUserProducts(e.target.value)
                      }
                    >
                      <option>Brand</option>
                      {productOwnsers &&
                        productOwnsers.map((filter, i) => (
                          <option value={filter.username}>
                            {filter.username}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div> */}

                <div className="dropdown">
                  <div className="navbarRight">
                    <Form.Select
                      onChange={(e) =>
                        this.supplierUserProductsBrand(e.target.value)
                      }
                    >
                      <option>Sub Brand</option>
                      {productsSubOwner &&
                        productsSubOwner.map((filter, i) => (
                          <option value={filter.username}>
                            {filter.username}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div>


                
                <div className="dropdown">
                  <div className="navbarRight">
                    <Form.Select
                      onChange={(e) =>
                        this.subCategoriesOnClick(e.target.value)
                      }
                    >
                      <option>Sub Categories</option>
                      {subCategoriesList &&
                        subCategoriesList.map((filter, i) => (
                          <option value={filter.contentsubcategoryname}>
                            {filter.contentsubcategoryname}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div>

                <div className="dropdown">
                  <div className="navbarRight">
                    <Form.Select
                      onChange={(e) =>
                        this.subCategoriesOnClick(e.target.value)
                      }
                    >
                      <option>Brand Sub-Categories</option>
                      {brandRelatedSubCategory &&
                        brandRelatedSubCategory.map((filter, i) => (
                          <option value={filter.contentsubcategoryname}>
                            {filter.contentsubcategoryname}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="dropdown">
                  <div className="navbarRight">
                    <SearchBar
                      placeholder="Search"
                      value={farmgridsearched}
                      onChange={(searchedVal) =>
                        this.requestFarmGridSearch(searchedVal)
                      }
                      onCancelSearch={() => this.cancelFarmGridSearch()}
                    />
                  </div>
                </div>
              </div>
              <div className="adminTendersDiv">
                {/* View All Raw Products */}
                {productsListOne.length !== 0 ? (
                  <Row>
                    {productsListOne.map((product, index) => (
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        className="d-flex mb-4 justify-content-center justify-content-lg-start"
                      >
                        <div className="explore-products-ncards w-100">
                          <Row className="justify-content-between">
                            <Col lg={6}>
                              <div className="explore-cards-image-container mb-lg-0 mb-3">
                                {(() => {
                                  switch (product.productimageurl) {
                                    case "":
                                      break;
                                    case "null":
                                      return (
                                        <div className="image-placeholder"></div>
                                      );
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <img
                                            src={product.productimageurl}
                                            className=""
                                          ></img>
                                        </p>
                                      );
                                  }
                                })()}
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="scrollable-div">
                                <h2 className="product-name d-block mb-2">
                                  {product.productfriendlyname}
                                </h2>
                                <h4 className=" mt-0 mb-lg-3 mb-2">
                                  {product.businessid}
                                </h4>
 <p>
                                Product Details:
                                  <span className="d-inline-block">
                                  <a className="text-decoration-none" target="_blank" href={product.infourl}>
                                    Info Link
                                  </a>
                                  </span>
                                </p>
                                <p>
                                  Product Id:
                                  <span className="d-inline-block">{product.productid}</span>
                                </p>
                                <p>
                                  Origin:
                                  <span className="d-inline-block"> {product.country}</span>
                                </p>
                                <p>
                                  Product Cost:
                                  <span className="product-original-price d-inline-block">
                                    {product.productcost} {product.unit}
                                  </span>
                                </p>

                                {(() => {
                                  switch (product.discount) {
                                    case "":
                                      break;
                                    case 0:
                                      break;
                                    default:
                                      return [
                                        <div>
                                          <p>
                                            Discounted Price:
                                            <span className="d-inline-block">
                                              {product.discountedprice}
                                              {product.unit}
                                            </span>
                                          </p>
                                        </div>,
                                      ];
                                  }
                                })()}
                                <p className="products-like mb-0 d-flex " onClick={() => {
                                      this.likeCount(product);
                                    }}>
                                  <span
                                    className="d-inline-block"
                                    
                                  >
                                    <FiThumbsUp size={30} />
                                  </span>
                                  <p className="mb-0 likeCount">{product.likecount}</p>
                                </p>
                                <p>
                                  Item Count:
                                  <span className="d-inline-block"> {product.itemcount}</span>
                                </p>
                                <p>
                                  Status:
                                  <span className="d-inline-block"> {product.status}</span>
                                </p>

                                {(() => {
                                  switch (product.productqrcodeurl) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <img
                                            src={product.productqrcodeurl}
                                            className="img-qr"
                                          ></img>
                                        </p>
                                      );
                                  }
                                })()}
                                {(() => {
                                  switch (product.qacertificateurl) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <img
                                            src={product.qacertificateurl}
                                            className="img-thumbnail"
                                          ></img>
                                        </p>
                                      );
                                  }
                                })()}

                                {(() => {
                                  switch (product.labcertificate) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <img
                                            src={product.labcertificate}
                                            className="img-thumbnail"
                                          ></img>
                                        </p>
                                      );
                                  }
                                })()}

                                {(() => {
                                  switch (product.productvideourl) {
                                    case "":
                                      break;
                                    case "null":
                                      break;
                                    default:
                                      return (
                                        <p>
                                          <ReactPlayer
                                            className="product-video"
                                            url={product.productvideourl}
                                            pip={true}
                                            controls={true}
                                            playing={false}
                                            muted={true}
                                          />
                                        </p>
                                      );
                                  }
                                })()}
                              </div>
                              <div className="explore-button-container pt-3  pb-lg-0 pb-3 pb-lg-0  mt-lg-0 mt-3">
                                <button
                                  type="button"
                                  className="btn btn-success signbutton w-100  d-block mx-lg-0 mx-auto"
                                >
                                  <NavLink
                                    className="text-white text-decoration-none"
                                    to="/auth/3/signin"
                                  >
                                    Buy
                                  </NavLink>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* <div className="explore-cards w-100">
                            <div className="explore-cards-image-container">
                              {(() => {
                                switch (product.productimageurl) {
                                  case "":
                                    break;
                                  case "null":
                                    return (
                                      <div className="image-placeholder"></div>
                                    );
                                    break;
                                  default:
                                    return (
                                      <p>
                                        <img
                                          src={product.productimageurl}
                                          className=""
                                        ></img>
                                      </p>
                                    );
                                }
                              })()}
                            </div>
                            <div className="mt-4 mb-4">
                              <div className="explore-prooduct-card-body">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <span className="product-name d-block mb-0">
                                    {product.productfriendlyname}
                                  </span>
                                  <p className="products-like mb-0 d-flex align-items-end">
                                    <span
                                      className="d-inline-block"
                                      onClick={() => {
                                        this.likeCount(product);
                                      }}
                                    >
                                      <FiThumbsUp size={30} />
                                    </span>
                                    <small>{product.likecount}</small>
                                  </p>
                                </div>
                                <h2 className=" mt-0 mb-3">
                                  {product.businessid}
                                </h2>
                                <span>
                                  <p>
                                    <b>Product Id: </b>
                                    {product.productid}
                                  </p>
                                  <p>
                                    <b>Origin: </b>
                                    {product.country}
                                  </p>
                                  <p>
                                    <b>Product Cost: </b>
                                    <span className="product-original-price">
                                      {product.productcost} {product.unit}
                                    </span>
                                  </p>
                                  {(() => {
                                    switch (product.discount) {
                                      case "":
                                        break;
                                      case 0:
                                        break;
                                      default:
                                        return [
                                          <div>
                                            <p>
                                              <b>Discounted Price: </b>
                                              {product.discountedprice}{" "}
                                              {product.unit}
                                            </p>
                                          </div>,
                                        ];
                                    }
                                  })()}
                                  <p>
                                    <b>Item Count: </b>
                                    {product.itemcount}
                                  </p>
                                  {(() => {
                                    switch (product.productqrcodeurl) {
                                      case "":
                                        break;
                                      case "null":
                                        break;
                                      default:
                                        return (
                                          <p>
                                            <img
                                              src={product.productqrcodeurl}
                                              className="img-qr"
                                            ></img>
                                          </p>
                                        );
                                    }
                                  })()}
                                  {(() => {
                                    switch (product.qacertificateurl) {
                                      case "":
                                        break;
                                      case "null":
                                        break;
                                      default:
                                        return (
                                          <p>
                                            <img
                                              src={product.qacertificateurl}
                                              className="img-thumbnail"
                                            ></img>
                                          </p>
                                        );
                                    }
                                  })()}
                                  {(() => {
                                    switch (product.labcertificate) {
                                      case "":
                                        break;
                                      case "null":
                                        break;
                                      default:
                                        return (
                                          <p>
                                            <img
                                              src={product.labcertificate}
                                              className="img-thumbnail"
                                            ></img>
                                          </p>
                                        );
                                    }
                                  })()}
                                  {(() => {
                                    switch (product.productvideourl) {
                                      case "":
                                        break;
                                      case "null":
                                        break;
                                      default:
                                        return (
                                          <p>
                                            <ReactPlayer
                                              className="product-video"
                                              url={product.productvideourl}
                                              pip={true}
                                              controls={true}
                                              playing={false}
                                              muted={true}
                                            />
                                          </p>
                                        );
                                    }
                                  })()}
                                  {(() => {
                                    switch (product.producthistoryurl) {
                                      case "":
                                        break;
                                      case "null":
                                        break;
                                      default:
                                        return (
                                          <p>
                                            <a
                                              href={product.producthistoryurl}
                                              target="_blank"
                                            >
                                              Product History
                                            </a>
                                          </p>
                                        );
                                    }
                                  })()}
                                  <p>
                                    <b>Status: </b>
                                    {product.status}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className="explore-button-container p-3 px-0">
                              <button
                                type="button"
                                className="btn btn-success signbutton w-100 mx-auto d-block"
                              >
                                <NavLink
                                  className="text-white text-decoration-none"
                                  to="/auth/3/signin"
                                >
                                  Buy
                                </NavLink>
                              </button>
                            </div>
                          </div> */}
                      </Col>
                    ))}
                    <div className="paginationComponent">
                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.prodPageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </Row>
                ) : (
                  <div className="card">
                    <p className="card-body container-heading2">
                      <b>Agri assets Not Found</b>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default RawProducts;
