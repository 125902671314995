import React,{useState} from 'react';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import ProcessedProducts from '../ExploreProducts/ProcessedProducts';
import { Container } from '@material-ui/core';
import SolarMachineryMarketplaceNavbar from './SolarMachineryMarketplaceNavbar';

export default function SolarMachineryMarketplace() {
    const [userType, setUserType] = useState(["FOOD-PROCESSOR"])

  return (
    <>
    <SolarMachineryMarketplaceNavbar/>

    <UpdatesBanner 
         className="socialMarketplace text-white"
         bannerText={["SolarMachinery Marketplace"]}
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
   <div className="explore-product-wrapper">
        <Container>
          <div className=" ">
            {/* Raw-Products */}
       <div id="tendersContainer" className="container-fluid">
              <h3 id="Raw-Products" className="container-title  ">
                Solar Machinery
              </h3>
              <ProcessedProducts  quote="true"  type="SOLAR-MACHINERY" userType = {userType} />
            </div>
            {/* Semi-Processed-Products */}
           
          </div>
        </Container>
      </div>
    </>
  )
}
