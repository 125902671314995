// import React from "react";
// import "./UpdatesBanner.css";
// import { Container, Button } from "@material-ui/core";
// import { NavLink } from "react-router-dom";
// import { NavHashLink } from "react-router-hash-link";
// import playstore from "../../images/playstore.svg";

// export default function UpdatesBanner({
//   className,
//   bannerText,
//   href,
//   buttonText,
//   bannerbodycopy,
//   href1,
//   href2,
//   href3,
//   buttonText1,
//   buttonText2,
//   buttonText3,
//   buttonText4,
//   buttonText5,
//   buttonText6,
//   href6,
//   DlBtn,
//   onClick,
//   DownloadText,
//   clicked,
//   dlLink,
//   data,
//   roleType,
//   bannerTitle,
//   bannerbodycopy1,
//   marginfalse
// }) {
//   return (
//     <div className={`${className} update-banner-container`}>
//       <Container>
//       {bannerTitle && <h1 className = "BannerHeading">{bannerTitle}</h1>}
// {bannerbodycopy1 && <p className="bodycopy mb-4">{bannerbodycopy1}</p>}

// {bannerText &&<h1>{bannerText}</h1>} 
//  {bannerbodycopy && <p className="mb-4">{bannerbodycopy}</p>}

//         {buttonText && (
//                       <Button variant="contained" color="primary">

//           <NavHashLink
//             onClick={clicked}
//             className="text-white"
//             to={{ pathname: href, state: { customProp: data } }}
//           >
//               {buttonText}{" "}
//           </NavHashLink>
//           </Button>

//         )}
//           <br className="d-md-none d-block"></br>

//         {buttonText5 && (
//           <>
//           <Button
//             variant="contained"
//             color="primary"
//             className="me-md-3 me-0 mt-md-0 mt-3 "
//           >
//             <NavHashLink onClick={clicked} className="text-white" to={href}>
//               {buttonText5}
//             </NavHashLink>
//           </Button>
//           <br className="d-md-none d-block"></br>

//           </>
//         )}
//         {DlBtn && (
//           <>
//    <Button
//               className={`${marginfalse ? "" : "ms-md-3 ms-0"} mt-3 mt-md-0`}
//               variant="contained"
//               color="primary"
//               onClick={onClick}
//             >
//           <a href={dlLink} rel = "noopener noreferrer" target="_blank">
         
//               <img
//                 className="buttonIcon"
//                 src={playstore}
//                 alt="Playstore Icon"
//               />{" "}
//               {DownloadText}
//           </a>
//           </Button>

//           </>
//         )}
//         {buttonText1 && (
//            <Button
//            variant="contained"
//            color="primary"
//            className="ms-md-3 ms-0 mt-md-0 mt-3 "
//          >
//           <NavLink to={href1} className="text-white">
           
//               {buttonText1}
//           </NavLink>
//           </Button>

//         )}
//         <br className="d-md-none d-block"></br>

//         {buttonText2 && (
//           <Button variant="contained" color="primary">
//             <a
//               target="_blank"
//               rel = "noopener noreferrer"
//               className="text-white text-decoration-none"
//               href={href2}
//             >
//               {buttonText2}{" "}
//             </a>
//           </Button>
//         )}

//         {buttonText3 && (
//           <Button variant="contained" color="primary" className="ms-3 ">
//             <a
//               target="_blank"
//               rel = "noopener noreferrer"
//               className="text-white text-decoration-none"
//               href={href3}
//             >
//               {buttonText3}{" "}
//             </a>
//           </Button>
//         )}
//         {buttonText4 && (
//           <Button variant="contained" color="primary" className=" ">
//             <a
//               target="_blank"
//               rel = "noopener noreferrer"
//               className="text-white text-decoration-none"
//               href={href3}
//             >
//               {buttonText4}{" "}
//             </a>
//           </Button>
//         )}
//                 <br className="d-md-none d-block"></br>
//                 {buttonText6 && (
//                      <Button
//                      variant="contained"
//                      color="primary"
//                      className="ms-md-3 ms-0 mt-md-0 mt-3 "
//                    >
//           <NavLink to={href6} className="text-white">
       
//             {buttonText6}
//         </NavLink>
//         </Button>

//         )}
//       </Container>
//     </div>
//   );
// }


// import React, { useState } from "react";
// import "./UpdatesBanner.css";
// import { Container, Button } from "@material-ui/core";
// import { NavLink } from "react-router-dom";
// import { NavHashLink } from "react-router-hash-link";
// import playstore from "../../images/playstore.svg";
// import DemoModal from "../DemoModal/DemoModal";

// export default function UpdatesBanner({
//   className,
//   bannerText,
//   href,
//   buttonText,
//   bannerbodycopy,
//   bannerbodycopy1,

//   href1,
//   href2,
//   href3,
//   buttonText1,
//   buttonText2,
//   buttonText3,
//   buttonText4,
//   buttonText5,
//   DlBtn,
//   onClick,
//   DownloadText,
//   clicked,
//   dlLink,
//   data,
//   id1,
//   id2,
//   demo
// }) {
//   const [demoModal, setDemoModal] = useState(false);
//   const [id11, setId1] = useState(id1);
//   const [id22, setId2] = useState(id2);

//   const handleClick = (event) => {
//     const button = event.target.id;
//     setDemoModal(true);
//     console.log(` ${button} was clicked!`);
//   };

//   const hideModal = () => {
//     setDemoModal(false);
//     setId2("");
//     setId1("");
//   };
//   return (
//     <>
//       <DemoModal show={demoModal} onHide={hideModal} id1={id11} id2={id22} />
//       <div className={`${className} update-banner-container`}>
//         <Container>
//           <h1>{bannerText}</h1>
//           {bannerbodycopy && <p className="mb-2">{bannerbodycopy}</p>}
//           {bannerbodycopy1  && <p className="mb-4">{bannerbodycopy1}</p>}

//           {demo && (
//             <Button
//               variant="contained"
//               color="primary"
//               className=""
//               id={id2}
//               onClick={handleClick}
//             >
//               Book a demo
//             </Button>
//           )}
//           {buttonText && (
//             <NavHashLink
//               onClick={clicked}
//               className="text-white"
//               to={{ pathname: href, state: { customData: data } }}
//             >
//               <Button variant="contained" color="primary">
//                 {buttonText}{" "}
//               </Button>
//             </NavHashLink>
//           )}

//           {buttonText5 && (
//               <Button
//                 variant="contained"
//                 color="primary"
//                 className="me-md-3 me-0 mt-md-0 mt-3 "
//               >
//                             <NavHashLink onClick={clicked} className="text-white" to={href}>

//                 {buttonText5}
//                 </NavHashLink>

//               </Button>
//           )}
//           <br className="d-md-none d-block"></br>
//           {DlBtn && (
//               <Button
//                 className="ms-md-3 ms-0 mt-md-0 mt-3 "
//                 variant="contained"
//                 color="primary"
//                 onClick={onClick}
//               >
//                             <a href={dlLink} target="_blank">

//                 <img
//                   className="me-1 buttonIcon"
//                   src={playstore}
//                   alt="Playstore Icon"
//                 />{" "}
//                 {DownloadText}
//                 </a>
//               </Button>

//           )}
//           {buttonText1 && (
//             <NavLink to={href1} className="text-white">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 className="ms-md-3 ms-0 mt-md-0 mt-3 "
//               >
//                 {buttonText1}
//               </Button>
//             </NavLink>
//           )}
//           <br className="d-md-none d-block"></br>

//           {buttonText2 && (
//             <Button variant="contained" color="primary">
//               <a
//                 target="_blank"
//                 className="text-white text-decoration-none"
//                 href={href2}
//               >
//                 {buttonText2}{" "}
//               </a>
//             </Button>
//           )}

//           {buttonText3 && (
//             <Button variant="contained" color="primary" className="ms-3 ">
//               <a
//                 target="_blank"
//                 className="text-white text-decoration-none"
//                 href={href3}
//               >
//                 {buttonText3}{" "}
//               </a>
//             </Button>
//           )}
//           {buttonText4 && (
//             <Button variant="contained" color="primary" className=" ">
//               <a
//                 target="_blank"
//                 className="text-white text-decoration-none"
//                 href={href3}
//               >
//                 {buttonText4}{" "}
//               </a>
//             </Button>
//           )}

//         </Container>
//       </div>
//     </>
//   );
// }
import React from "react";
import "./UpdatesBanner.css";
import { Container, Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import playstore from "../../images/playstore.svg";

export default function UpdatesBanner({
  className,
  bannerText,
  href,
  buttonText,
  bannerbodycopy,
  bannerbodycopy1,
  href1,
  href2,
  href3,
  buttonText1,
  buttonText2,
  buttonText3,
  buttonText4,
  buttonText5,
  buttonText6,
  href6,
  DlBtn,
  onClick,
  DownloadText,
  clicked,
  dlLink,
  data,
  roleType,
  bannerTitle,
  id1,
  id2,
  demo,
  marginfalse
}) {
  return (
    <div className={`${className} update-banner-container`}>
      <Container>
        {bannerTitle && <h1 className="BannerHeading">{bannerTitle}</h1>}
        {bannerbodycopy1 && <p className="bodycopy mb-4">{bannerbodycopy1}</p>}

        {bannerText && <h1>{bannerText}</h1>}
        {bannerbodycopy && <p className="mb-4">{bannerbodycopy}</p>}

        {buttonText && (
          <Button variant="contained" color="primary">
            <NavHashLink
              onClick={clicked}
              className="text-white"
              to={{ pathname: href, state: { customProp: data } }}
            >
              {buttonText}{" "}
            </NavHashLink>
          </Button>
        )}
        <br className="d-md-none d-block"></br>

        {/* {buttonText5 && (
          <>
            <Button
              variant="contained"
              color="primary"
              className="me-md-3 me-0 mt-md-0 mt-3 "
            >
              <NavHashLink onClick={clicked} className="text-white" to={href}>
                {buttonText5}
              </NavHashLink>
            </Button>
            <br className="d-md-none d-block"></br>
          </>
        )} */}
        {DlBtn && (
          <>
            {/* <Button
              className="ms-md-3 ms-0 mt-md-0 mt-3"
              variant="contained"
              color="primary"
              onClick={onClick}
            >
              <a href={dlLink} rel="noopener noreferrer" target="_blank">
                <img
                  className="buttonIcon"
                  src={playstore}
                  alt="Playstore Icon"
                />{" "}
                {DownloadText}
              </a>
            </Button> */}

<Button
              className={`${marginfalse ? "" : "ms-md-3 ms-0"} mt-3 mt-md-0`}
              variant="contained"
              color="primary"
              onClick={onClick}
            >
          <a href={dlLink} rel = "noopener noreferrer" target="_blank">
         
              <img
                className="buttonIcon"
                src={playstore}
                alt="Playstore Icon"
              />{" "}
              {DownloadText}
          </a>
          </Button>
            
          </>
        )}
        {/* {buttonText1 && (
          <Button
            variant="contained"
            color="primary"
            className="ms-md-3 ms-0 mt-md-0 mt-3 "
          >
            <NavLink to={href1} className="text-white">
              {buttonText1}
            </NavLink>
          </Button>
        )}
        <br className="d-md-none d-block"></br>

        {buttonText2 && (
          <Button variant="contained" color="primary">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-decoration-none"
              href={href2}
            >
              {buttonText2}{" "}
            </a>
          </Button>
        )}

        {buttonText3 && (
          <Button variant="contained" color="primary" className="ms-3 ">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-decoration-none"
              href={href3}
            >
              {buttonText3}{" "}
            </a>
          </Button>
        )}
        {buttonText4 && (
          <Button variant="contained" color="primary" className=" ">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-decoration-none"
              href={href3}
            >
              {buttonText4}{" "}
            </a>
          </Button>
        )}
        <br className="d-md-none d-block"></br>
        {buttonText6 && (
          <Button
            variant="contained"
            color="primary"
            className="ms-md-3 ms-0 mt-md-0 mt-3 "
          >
            <NavLink to={href6} className="text-white">
              {buttonText6}
            </NavLink>
          </Button>
        )} */}
      </Container>
    </div>
  );
}
