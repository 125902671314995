import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Box, Button, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AAMCSiteManagementComponent from "../AAMCSiteManagement/AAMCSiteManagementComponent";
import "./Market.css";
import MarketProducts from "./MarketProducts";
import { IconButton } from "@material-ui/core";

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

export default function Market() {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const orientation = isSmallScreen ? "vertical" : "horizontal";
  const userType = localStorage.getItem("userType");
  const userRole = localStorage.getItem("UserRole");
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    switch (userType) {
      case "AHSP":
        setValue(3);
        break;
      case "AIC":
        setValue(4);
        break;
      case "FOOD-PROCESSOR":
      case "BRAND":
        setValue(0);
        break;
      case "AssetDeveloper":
        setValue(1);
        break;
      default:
        setValue(6);
        break;
    }
  }, [userType]);
  
  const handleBackButtonClick = () => {
    // Define paths based on user types
    const paths = {
      "AssetDeveloper": "./dashboardForAD",
      "CBO": "./dashboardForCBO"
    };
    // Get the path based on user type
    const path = paths[userType] || "./defaultDashboard"; // Default path if user type not recognized

    // Redirect to the determined path
    history.push(path);
  };
  return (
    <>
      <div className="container" id="mainContentList">
      <IconButton
            style={{ float: 'left' }}
            onClick={handleBackButtonClick}
              edge="start"
              color="inherit"
              aria-label="back"
            >
              <ArrowBackIcon />
            </IconButton>
        <Box
          sx={{
            width: "100%",
            mb: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "center" },
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "100%",
            }}
          >
            <Tabs
              orientation={orientation}
              value={value}
              onChange={handleChange}
              aria-label="Market"
            >
              {((userType === "FOOD-PROCESSOR" && userRole !== "BASIC") ||
                (userType === "BRAND" && userRole !== "BASIC")) && (
                <Tab
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  label="PROCESSED-PRODUCTS"
                  {...a11yProps(0)}
                  value={0}
                />
              )}
              {userType !== "AHSP" &&
                userType !== "AIC" &&
                userType !== "BRAND" &&
                userRole === "ADMIN" && (
                  <Tab
                    sx={{ alignItems: "flex-start" }}
                    label="RAW-PRODUCTS"
                    {...a11yProps(1)}
                    value={1}
                  />
                )}
              {(userType === "FOOD-PROCESSOR" || userType === "CBO") && (
                <Tab
                  sx={{ alignItems: "flex-start" }}
                  label="SEMI-PROCESSED"
                  {...a11yProps(2)}
                  value={2}
                />
              )}
              {userType === "AHSP" && (
                <Tab
                  sx={{ alignItems: "flex-start" }}
                  label="AHP"
                  {...a11yProps(3)}
                  value={3}
                />
              )}
              {userType === "AIC" && (
                <Tab
                  sx={{ alignItems: "flex-start" }}
                  label="PHP"
                  {...a11yProps(4)}
                  value={4}
                />
              )}
              {userType === "AIC" && (
                <Tab
                  sx={{ alignItems: "flex-start" }}
                  label="SEEDS"
                  {...a11yProps(5)}
                  value={5}
                />
              )}
              <Tab
                sx={{ alignItems: "flex-start" }}
                label="SITE-MANAGEMENT"
                {...a11yProps(6)}
                value={6}
              />
            </Tabs>
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            {((value === 0 && userType === "FOOD-PROCESSOR") ||
              (value === 0 && userType === "BRAND")) && (
              <TabPanel value={value} index={0} style={{ width: "100%" }}>
                <MarketProducts
                  categoryType="PROCESSED-PRODUCTS"
                  compType="Store"
                  subCatType="Sub Category"
                  modelID="Product Model ID"
                  modelFN="Product Model Friendly Name"
                  dCost="Product Cost"
                  pUnit="Unit"
                  pDiscount="Discount"
                  dPrice="Discounted Price"
                />
              </TabPanel>
            )}
            {((value === 1 && userType !== "AHSP") ||
              (value === 1 && userType !== "AIC") ||
              (value === 1 && userType !== "BRAND") ||
              (value === 1 && userRole === "ADMIN")) && (
              <TabPanel value={value} index={1} style={{ width: "100%" }}>
                <MarketProducts
                  categoryType="RAW-PRODUCTS"
                  compType="Store"
                  subCatType="Sub Category"
                  modelID="Product Model ID"
                  modelFN="Product Model Friendly Name"
                  dCost="Product Cost"
                  pUnit="Unit"
                  pDiscount="Discount"
                  dPrice="Discounted Price"
                />
              </TabPanel>
            )}
            {((value === 2 && userType === "FOOD-PROCESSOR") ||
              (value === 2 && userType === "CBO")) && (
              <TabPanel value={value} index={2} style={{ width: "100%" }}>
                <MarketProducts
                  categoryType="SEMI-PROCESSED"
                  compType="Store"
                  subCatType="Sub Category"
                  modelID="Product Model ID"
                  modelFN="Product Model Friendly Name"
                  dCost="Product Cost"
                  pUnit="Unit"
                  pDiscount="Discount"
                  dPrice="Discounted Price"
                />
              </TabPanel>
            )}
            {value === 3 && userType === "AHSP" && (
              <TabPanel value={value} index={3} style={{ width: "100%" }}>
                <MarketProducts
                  categoryType="AHP"
                  compType="Store"
                  subCatType="Sub Category"
                  modelID="Product Model ID"
                  modelFN="Product Model Friendly Name"
                  dCost="Product Cost"
                  pUnit="Unit"
                  pDiscount="Discount"
                  dPrice="Discounted Price"
                />
              </TabPanel>
            )}
            {value === 4 && userType === "AIC" && (
              <TabPanel value={value} index={4} style={{ width: "100%" }}>
                <MarketProducts
                  categoryType="PHP"
                  compType="Store"
                  subCatType="Sub Category"
                  modelID="Product Model ID"
                  modelFN="Product Model Friendly Name"
                  dCost="Product Cost"
                  pUnit="Unit"
                  pDiscount="Discount"
                  dPrice="Discounted Price"
                />
              </TabPanel>
            )}
            {value === 5 && userType === "AIC" && (
              <TabPanel value={value} index={5} style={{ width: "100%" }}>
                <MarketProducts
                  categoryType="SEEDS"
                  compType="Store"
                  subCatType="Sub Category"
                  modelID="Seed Model ID"
                  modelFN="Seed Model Friendly Name"
                  dCost="Seed Cost"
                  pUnit="Unit"
                  pDiscount="Discount"
                  dPrice="Discounted Price"
                />
              </TabPanel>
            )}
            {value === 6 && (
              <TabPanel value={value} index={6} style={{ width: "100%" }}>
                <AAMCSiteManagementComponent siteType={["Store"]} />
              </TabPanel>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
}
