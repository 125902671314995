import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import "./InvestPageComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddToCart from "../AddToCart/AddToCartComponent";
import { CartItemContext } from "../../../../context/CartItemContext";



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function InvestPageComponent(props) {
  const classes = useStyles();
  var i;
  const [postItems, setPostItems] = useState([]);
  const [bookNowResponceID, setBoknowResponseID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [siteDetailsPopup, setShowSiteDetailsPopup] = useState(false);
  const [siteInvestPopup, setShowSiteInvestPopup] = useState(false);
  const [aamcInvestPopup, setShowAAMCInvestPopup] = useState(false);
  const [modelInvestPopup, setShowModelInvestPopup] = useState(false);
  const [growerDetailsPopup, setShowGrowerDetailsPopup] = useState(false);
  const [modelDetailsPopup, setShowModelDetailsPopup] = useState(false);
  const [assetDetailsPopup, setShowAssetDetailsPopup] = useState(false);
  const [booknowResponsePopup, setBooknowResponsePopup] = useState(false);
  const [cartPopup, setShowCartPopup] = useState(false);
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [sitesInvestList, setSitesInvestList] = useState([]);
  const [aamcInvestList, setAAMCInvestList] = useState([]);
  const [modelsInvestList, setModelsInvestList] = useState([]);
  const [, setGrowersInfoList] = useState([]);
  const [, setGrowersRatingsList] = useState([]);
  const [data, setData] = useState({ wallet: "0" });
  const [siteLatitude, setSiteLatitude] = useState("");
  const [siteLongitude, setSiteLongitude] = useState("");
  const [siteID, setSiteID] = useState("");
  const [siteName, setSiteName] = useState("");
  const [siteRating, setSiteRating] = useState("");
  const [cartItems, setCartItems] = useState([]);
  let [price, setPrice] = useState(0);
  const [siteLocationAddress, setSiteLocationAddress] = useState("");
  const [selectedAssetDeveloperId, setSelectedAssetDeveloperId] = useState("");
  const [selectedSiteId,setSelectedSiteId] = useState(""); 
  /* model values set state */
  const [modelAssetDeveloperID, setModelAssetDeveloperID] = useState("");
  const [modelAssetVerticalID, setModelAssetVerticalID] = useState("");
  const [modelAssetVerticalFriendlyName, setModelAssetVerticalFriendlyName] =
    useState("");
  const [modelCategoryType, setModelCategoryType] = useState("");
  const [modelAssetType, setModelAssetType] = useState("");
  const [modelId, setModelId] = useState("");
  const [modelFriendlyname, setModelFriendlyname] = useState("");
  const [modelUrl, setModelUrl] = useState("");
  const [modelDescription, setModelDescription] = useState("");
  const [modelImagedata, setModelImagedata] = useState("");
  /* Assset values set state */
  const [AssetDeveloperID, setAssetDeveloperID] = useState("");
  const [AssetVerticalID, setAssetVerticalID] = useState("");
  const [AssetCategory, setAssetCategory] = useState("");
  const [AssetType, setAssetType] = useState("");
  const [AssetModelId, setAssetModelId] = useState("");
  const [AssetId, setAssetId] = useState("");
  const [AssetStatus, setAssetStatus] = useState("");
  const [AssetFriendlyName, setAssetFriendlyName] = useState("");
  const [AssetLatutude, setAssetLatitude] = useState("");
  const [AssetLongitude, setAssetLongitude] = useState("");
  const [AssetWhitelabelCost, setAssetWhitelabelCost] = useState();
  const [AssetSellingCost, setAssetSellingCost] = useState("");
  const [AssetImage, setAssetImage] = useState("");
  /* Grower Info set state */
  const [growerObj, setGrowerObj] = useState({growerUserID:"",growerUsername:"",growerFirstname:"",growerLastname:"",
  growerUserType:"",growerEmail:"",growerParentId:"",growerPhone:"",growerAddress:"",growerCity:"",growerState:"",
  growerPancard:"",growerLongitude:"",growerLatitude:""
  })
  /* const [growerUserID, setGrowerUserID] = useState("");
  const [growerUsername, setGrowerUsername] = useState("");
  const [growerFirstname, setGrowerFirstname] = useState("");
  const [growerLastname, setGrowerLastname] = useState("");
  const [growerUserType, setGrowerUserType] = useState("");
  const [growerEmail, setGrowerEmail] = useState("");
  const [growerParentId, setGrowerParentId] = useState("");
  const [, setGrowerDomain] = useState("");
  const [growerPhone, setGrowerPhone] = useState("");
  const [growerAddress, setGrowerAddress] = useState("");
  const [growerCity, setGrowerCity] = useState("");
  const [growerState, setGrowerState] = useState("");
  const [growerPancard, setGrowerPancard] = useState("");
  const [growerLongitude, setGrowerLongitude] = useState("");
  const [growerLatitude, setGrowerLatitude] = useState(""); */
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  /* Grower Rating set state */
  const [GrowerAssetDeveloperID, setGrowerAssetDeveloperID] = useState("");
  const [GrowerRating, setGrowerRating] = useState("");

 const {cartStateChangeHandler} = useContext(CartItemContext)

  useEffect(() => {
    getAssetVerticalsIdList();
    
    getCartDetails();
   // findCartItemsFunction();
    getWalletBalance(username, data);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* useEffect(()=>{
    
  },[cartItems]); */

  useEffect(() => {
    if (!selectedVerticalsId) return ;
    getCategoryTypesList();
  }, [selectedVerticalsId]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedCategory) return;
    getAssetTypesList();
  }, [selectedCategory]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!selectedVerticalsId) return;
      if (!selectedCategory) return;
      if (!selectedAssertTypes) return;
      getWhiteLabelAssets();
    }, 2000);
    return () => clearTimeout(timer);
  }, [selectedVerticalsId, selectedCategory, selectedAssertTypes]); //eslint-disable-line react-hooks/exhaustive-deps

  const getWalletBalance = (username, dataRef) => {
    let newData = { ...dataRef };
    MainService.getWalletBalance(username)
      .then((responseData) => {
        newData = {
          ...dataRef,
          wallet: new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 3,
          }).format(responseData.walletBalance),
        };

        setData(newData);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getAssetVerticalsIdList = () => {
    setIsLoading(true);
    MainService.getAssetVerticalsIdList(window.AppConfigData.EnterpriseId)
      .then((data) => {
        if (data.length > 0) {
          setSelectedVerticalsId(data[0].assetVerticalId);
          setVerticalsIdList(data); 
        } else {
          setVerticalsIdList(data); 
          setSelectedVerticalsId("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getCategoryTypesList = () => {
    setIsLoading(true);
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(selectedVerticalsId.replace(" ", ""),window.AppConfigData.EnterpriseId)
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setCategoryTypesList(data);
            setSelectedCategory("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
    
  };

  const getAssetTypesList = () => {
    setIsLoading(true);
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        window.AppConfigData.EnterpriseId
      )
        .then((data) => {
          if (data.length>0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
          } else {            
            setAssertTypesList(data);
            setIsLoading(true);
            setSiteList([]);
            setSelectedAssertTypes("");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
    
  };

  const getWhiteLabelAssets = () => {
    setIsLoading(true);
    
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getWhiteLabelAssets(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", "")
      )
        .then((data) => {
          if (data) {
            setSiteList(data);
            setIsLoading(false);
          } else {
            setSiteList(data);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
        setIsLoading(false);
    }
    
  };
  const assetAddToCartClick = (sieid) => {
   
    let addToCartData = {
      userid: username,
      assetdeveloperid: AssetDeveloperID,
      siteid: sieid,
      assetverticalid: AssetVerticalID,
      categorytype: AssetCategory,
      legacyassettype: selectedAssertTypes,
      modelid: AssetModelId,
      assetid: AssetId,
      whitelabelcost: parseInt(AssetWhitelabelCost),
      quantity: 1,
    };

    const cartData = JSON.stringify(addToCartData);
    MainService.addToCart(cartData)
      .then((res) => {
        
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
      findCartItemsFunction();

    setShowCartPopup(!cartPopup);
  };

  const assetBookNowClick = (siteid) => {
    var transactionId = uuid();
   

    var wala = data.wallet;
    var walaint = wala.replace(/,/g, "");
    var w = Number(walaint);

    if (w >= AssetWhitelabelCost) {
      let paymentData = {
        transactionid: transactionId,
        consumeradminid: window.AppConfigData.EnterpriseId,
        assetoemid: username,
        categorytype: AssetCategory,
        legacyassettype: selectedAssertTypes,
        assetid: AssetId,
        modelid: AssetModelId,
        solutionid: AssetDeveloperID,
        siteid: siteid,
        paymentmode: "Online",
        whitelabelcost:AssetWhitelabelCost,
        totalamount: AssetWhitelabelCost,
        quantity: "1",
        assetpackage: "Standard",
      };

      const paymentDataBody = JSON.stringify(paymentData);
      MainService.bookNowPayment(paymentDataBody)
        .then((res) => {
          setBoknowResponseID(res);
          setBooknowResponsePopup(!booknowResponsePopup);
          //window.location.reload();
          return NotificationManager.success("Asset Booked Successfully");
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    } else {
      return NotificationManager.error("Insufficient balance in your wallet");
    }
  };
  function uuid() {
    // return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    return "xxxyxxxxyxyxyyxy".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  const bookNowClick = (sites) => {
    var transactionId = uuid();
    

    var wala = data.wallet;
    var walaint = wala.replace(/,/g, "");
    var w = Number(walaint);

    if (w >= sites.whitelabelcost) {
      let paymentData = {
        transactionid: transactionId,
        consumeradminid: parentId,
        assetoemid: username,
        categorytype: sites.category,
        legacyassettype: selectedAssertTypes,
        assetid: sites.assetid,
        modelid: sites.modelid,
        solutionid: sites.assetdeveloperid,
        siteid: sites.siteid,
        paymentmode: "Online",
        whitelabelcost:sites.whitelabelcost,
        totalamount: sites.whitelabelcost,
        quantity: "1",
        assetpackage: "Standard",
      };
      const paymentDataBody = JSON.stringify(paymentData);
      MainService.bookNowPayment(paymentDataBody)
        .then((res) => {
          setBoknowResponseID(res);
          setBooknowResponsePopup(!booknowResponsePopup);
          getWhiteLabelAssets();
          //window.location.reload();
          setBooknowResponsePopup(!booknowResponsePopup);
          return NotificationManager.success("Asset Booked Successfully");
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    } else {
      return NotificationManager.error("Insufficient balance in your wallet");
    }
  };
  const siteAddToCartClick = (sites) => {
    //assetDetailsClick(sites); //to get image from assets

    let addToCartData = {
      userid: username,
      assetdeveloperid: sites.assetdeveloperid,
      siteid: sites.siteid,
      assetverticalid: sites.assetverticalid,
      categorytype: sites.category,
      legacyassettype: selectedAssertTypes,
      modelid: sites.modelid,
      assetid: sites.assetid,
      whitelabelcost: parseInt(sites.whitelabelcost),
      quantity: 1,
    };
    
    const cartData = JSON.stringify(addToCartData);
    MainService.addToCart(cartData)
      .then((res) => {
        findCartItemsFunction();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
      
    //setShowCartPopup(!cartPopup);
  };

  const getCartDetails = () => {
    MainService.findCartItems(username)
      .then((data) => {
        setCartItems(data);
        postItemsFunction(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const postItemsFunction = (data) => {
    const updatedPostItems = data.map((item) => ({
      consumeradminid: parentId,
      assetoemid: username,
      categorytype: item.categorytype,
      legacyassettype: item.legacyassettype,
      modelid: item.modelid,
      assetid: item.assetid,
      solutionid: item.assetdeveloperid,
      siteid: item.siteid,
      quantity:"1",
      paymentmode: 'Online',
      assetpackage: 'Standard',
    }));

    setPostItems(updatedPostItems);
  };

  
  const assetDetailsClick = (sites) => {
    setSelectedSiteId(sites.siteid);
    setShowAssetDetailsPopup(!assetDetailsPopup);
    MainService.getAssetDetails(
      sites.assetdeveloperid,
      selectedVerticalsId,
      selectedCategory,
      selectedAssertTypes,
      sites.modelid,
      sites.assetid
    )
      .then((data) => {
        setAssetDeveloperID(data.assetdeveloperid);
        setAssetVerticalID(data.assetverticalId);
        setAssetCategory(data.category);
        setAssetType(data.assetType);
        setAssetModelId(data.modelid);
        setAssetId(data.assetid);
        setAssetStatus(data.assetstatus);
        setAssetFriendlyName(data.friendlyname);
        setAssetLatitude(data.latitude);
        setAssetLongitude(data.longitude);
        setAssetWhitelabelCost(data.whitelabelcost);
        setAssetSellingCost(data.sellingcost);
        if (data.image === "null") {
          setAssetImage("");
        } else {
          setAssetImage(data.image);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const sitesInvestClick = (siteID) => {
    setShowSiteInvestPopup(!siteInvestPopup);
    MainService.whiteLabelAssetsBasedonSite(
      siteID,
      selectedVerticalsId,
      selectedCategory,
      selectedAssertTypes
    )
      .then((data) => {
        setSitesInvestList(data || []);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const aamcInvestClick = (assetdeveloperId) => {
    setShowAAMCInvestPopup(!aamcInvestPopup);
    MainService.whiteLabelAssetsBasedonAssetDeveloper(
      assetdeveloperId,
      selectedVerticalsId,
      selectedCategory,
      selectedAssertTypes
    )
      .then((data) => {
        setAAMCInvestList(data || []);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const modelInvestClick = (modelID) => {
    setShowModelInvestPopup(!modelInvestPopup);
    MainService.whiteLabelAssetsBasedonModel(
      modelID,
      selectedVerticalsId,
      selectedCategory,
      selectedAssertTypes
    )
      .then((data) => {
        setModelsInvestList(data || []);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const sitesInvestClick1 = (siteID) => {
    setShowSiteInvestPopup(!siteInvestPopup);
    MainService.whiteLabelAssetsBasedonSite(
      siteID,
      selectedVerticalsId,
      selectedCategory,
      selectedAssertTypes
    )
      .then((data) => {
        setSitesInvestList(data || []);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const siteDetailsPopupClick = (siteid) => {
    setSelectedSiteId(siteid);
    MainService.getSitesDetails(siteid)
      .then((data) => {
        if(data.siteassettypes.length>0)
        {          
          setSiteID(data.siteassettypes[0].siteId);
        }
        if(data.siteratings.length>0)
        {
          setSiteRating(data.siteratings[0].ratings);
          setSiteID(data.siteratings[0].siteid);
        }
        
        setSiteName(data.sitename);
        setSiteLatitude(data.latitude);
        setSiteLongitude(data.longitude);
        setSiteLocationAddress(data.location_address);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
    setShowSiteDetailsPopup(!siteDetailsPopup);
  };

  const growerDetailsPopupClick = (selectedAssetdeveloperId) => {
    setSelectedAssetDeveloperId(selectedAssetdeveloperId);
    
    MainService.getGrowerDetails(selectedAssetdeveloperId)
      .then((data) => {

        const responseData = [ /* Your dynamic response data */ ];

// Initialize empty arrays for each key
let info = [];
let ratings = [];
let assetVerticals = [];
let categoryTypes = [];
let assetTypes = [];
let models = [];

// Iterate over the response data and filter keys
responseData.forEach(item => {
  if (item.hasOwnProperty('info')) {
    info = item.info;
  }
  if (item.hasOwnProperty('ratings')) {
    ratings = item.ratings;
  }
  if (item.hasOwnProperty('assetVerticals')) {
    assetVerticals = item.assetVerticals;
  }
  if (item.hasOwnProperty('categoryTypes')) {
    categoryTypes = item.categoryTypes;
  }
  if (item.hasOwnProperty('assetTypes')) {
    assetTypes = item.assetTypes;
  }
  if (item.hasOwnProperty('models')) {
    models = item.models;
  }
});

// Output the filtered data
console.log('Ratings:', ratings);
console.log('Asset Verticals:', assetVerticals);
console.log('Category Types:', categoryTypes);
console.log('Asset Types:', assetTypes);
console.log('Models:', models);




        // Check if data is valid
        //if (data && data.length === 2 && data[0].info && data[1].ratings) {
          // setGrowersInfoList(data[0].info);
          // setGrowersRatingsList(data[1].ratings);

          //setGrowersInfoList(info);
          // setGrowersRatingsList(ratings);
          
          // Set grower details
          if (info?.info.length > 0) {
            const growerInfo = info;
            setGrowerObj({growerUserID:growerInfo.userid,growerUsername:growerInfo.username,growerFirstname:growerInfo.firstname,growerLastname:growerInfo.lastname,
            growerUserType:growerInfo.usertype,growerEmail:growerInfo.email,growerParentId:growerInfo.parentid,growerPhone:growerInfo.phone,growerAddress:growerInfo.address,growerCity:growerInfo.city,growerState:growerInfo.state,
            growerPancard:growerInfo.pancard,growerLongitude:growerInfo.longitude,growerLatitude:growerInfo.latitude});
/* 
            setGrowerObj(growerInfo.userid);
            setGrowerUsername(growerInfo.username);
            setGrowerFirstname(growerInfo.firstname);
            setGrowerLastname(growerInfo.lastname);
            setGrowerPancard(growerInfo.pancard);
            setGrowerParentId(growerInfo.parentid);
            setGrowerUserType(growerInfo.usertype);
            setGrowerEmail(growerInfo.email);
            setGrowerDomain(growerInfo.domain);
            setGrowerPhone(growerInfo.phone);
            setGrowerState(growerInfo.state);
            setGrowerAddress(growerInfo.address);
            setGrowerCity(growerInfo.city);
            setGrowerLatitude(growerInfo.latitude);
            setGrowerLongitude(growerInfo.longitude); */
          }
  
          // Set grower ratings
          if (ratings?.ratings.length > 0) {
            //setGrowerAssetDeveloperID(ratings[0]?.assetdeveloperid);
            setGrowerRating(ratings[0]?.ratings);
          }
        // } else {
        //   // Handle case where data is invalid
        //   NotificationManager.warning("Invalid grower details data format");
        // }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  
    setShowGrowerDetailsPopup(!growerDetailsPopup);
  };
  

  const modalDetailsPopupClick = async (assetdeveloperid, modelid) => {
    try {
      const data = await MainService.getModelDetails(assetdeveloperid, modelid);
  
      // Check if data is available
      if (data && data.length > 0) {
        setModelAssetDeveloperID(data[0].assetdeveloperid);
        setModelAssetVerticalID(data[0].assetverticalid);
        setModelAssetVerticalFriendlyName(data[0].assetverticalfriendlyname);
        setModelCategoryType(data[0].categorytype);
        setModelAssetType(data[0].assetType);
        setModelId(data[0].modelid);
        setModelFriendlyname(data[0].modelfriendlyname);
        setModelUrl(data[0].modelurl);
        setModelDescription(data[0].description);
  
        if (data[0].imagedata === "null") {
          setModelImagedata("");
        } else {
          setModelImagedata(data[0].imagedata);
        }
  
        setShowModelDetailsPopup(!modelDetailsPopup);
      } else {
        // Handle case where data is empty or undefined
        NotificationManager.warning("Model details not found");
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error(error.message);
    }
  };
  
  /* Cart functions */

  const handlePrice = (cartItems) => {
    let ans = 0;
    
    /* cartItems.map((item) => (ans += item.count * item.whitelabelcost)); */
   cartItems.map((item) =>
   {
    ans += 1 * item.whitelabelcost
   });    
    setPrice(ans);

  };
  

  const handleAdd = () => {
    findCartItemsFunction();
    setShowCartPopup(false);
    setShowAssetDetailsPopup(false);
    setShowSiteInvestPopup(true);
  };

  const findCartItemsFunction = () => {
    
    MainService.findCartItems(username)
      .then((data) => {
        if (data.length > 0) {
          setCartItems(data);
          handlePrice(data);
        
        }
        else{
          setCartItems([]);
          setPrice(0);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const handleRemove = (modelId, assetid) => {
    const { user } = MainService.getLocalData();
    const { username } = user;
    MainService.deleteAssetFromCart(username, modelId, assetid)
      .then((data) => {     
        findCartItemsFunction();      
        return NotificationManager.success(assetid + "   deleted successfully");
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  

  const makePaymentClick = () => {
    const referenceId = uuid();
    const paymentBody = {
      username,
      referenceid: referenceId,
      schemename: 'scheme1',
      schemeid: 'scheme999',
      superad: 'superad',
      optionalid: '6758595',
      unit: 'INR',
      totalamount: price,
      items: postItems,
    };
    const paymentBodyData = JSON.stringify(paymentBody);
    var wala = data.wallet;
    var walaint = wala.replace(/,/g, "");
    var w = Number(walaint);
    if(w >= price)
    {
      MainService.makePayment(paymentBodyData, username)
      .then((data) => {
        setIsLoading(false);  // Assuming setIsLoading is a state setter
        setShowCartPopup(!cartPopup);
        return NotificationManager.success(data);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error('white label request is unsuccessful');
      });
    }else{
      return NotificationManager.error('insufficient balance in your wallet');
    }
    
  };
  /* Booknow Response Popup */
  const closeBooknowResponsePopup = (
    <span
      onClick={() => {
        setBooknowResponsePopup(!booknowResponsePopup);
      }}
    >
      X
    </span>
  );
  const titleBooknowResponse = "Invoice";
  const booknowResponsePopupBody = (
    <div style={{ marginTop: "20px !important" }}>
      <h4>
        Your Asset transfered on your name will be completed with in a day.
      </h4>
      <p>
        <b>Booking ID:</b>
        {bookNowResponceID}
      </p>

      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setBooknowResponsePopup(!booknowResponsePopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
      <br />
    </div>
  );
  /* Cart Popup */
  const closeCartPopup = (
    <span
      onClick={() => {
        setShowCartPopup(!cartPopup);
      }}
    >
      X
    </span>
  );
  const titleCart = "Items in Cart";
  const CartPopupBody = (
    <div style={{ marginTop: "20px !important" }}>
      {(!cartItems || cartItems.length === 0) && (
        <h4 style={{ float: "left", marginTop: "7px", marginBottom: "10px" }}>
          Cart items not found.
        </h4>
      )}
      <article id="cartDrawer">
        {cartItems.map((item, index) => (
          <div className="cart_box" key={index}>
            <div className="cart_img">
              <img
                alt=" "
                src={`data:image/jpeg;base64,${item.image}`}
                width="200"
                height="200"
              />
              <p>{item.modelid}</p>
              <p>{item.assetid}</p>
              <p>{item.legacyassettype}</p>
            </div>

            <div className="cartButtons">
              <span>{item.whitelabelcost}</span>&nbsp;
              <button onClick={() => handleRemove(item.modelid, item.assetid)}>
                Remove
              </button>
            </div>
          </div>
        ))}
        <div className="total">
          <span>Total Price of your Cart</span>
          <span>Rs : {price}</span>
        </div>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="button"
          onClick={() => makePaymentClick()}
        >
          Make Payment
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="button"
          onClick={() => handleAdd()}
        >
          Add
        </Button>
      </article>
      <br />
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowCartPopup(!cartPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
      <br />
    </div>
  );
  /* Site Invest */
  const closeSiteInvestModal = (
    <span
      onClick={() => {
        setShowSiteInvestPopup(!siteInvestPopup);
      }}
    >
      X
    </span>
  );
  const titleInvest = "Invest Page";
  const siteInvestBody = (
    <div style={{ marginTop: "20px !important" }}>
      <div className="cardStyleForDashboardItems">
        {sitesInvestList.map((invests, index) => (
          <div key={index} className="cardStyleForDashboardItemsBody">
            <p>
              <b>assetdeveloperid:</b>
              {invests.assetdeveloperid}
            </p>
            <p>
              <b>siteid:</b>
              {invests.siteid}
            </p>
            <p>
              <b>assetverticalid:</b>
              {invests.assetverticalid}
            </p>
            <p>
              <b>category:</b>
              {invests.category}
            </p>
            <p>
              <b>modelid:</b>
              {invests.modelid}
            </p>
            <p>
              <b>modelfriendlyname:</b>
              {invests.modelfriendlyname}
            </p>
            <p>
              <b>assetid:</b>
              {invests.assetid}
            </p>
            <p>
              <b>assetfriendlyname:</b>
              {invests.assetfriendlyname}
            </p>
            <p>
              <b>assetstatus:</b>
              {invests.assetstatus}
            </p>
            <p>
              <b>whitelabelcost:</b>
              {invests.whitelabelcost}
            </p>
            <Button
              variant="contained"
              color="primary"
              className="registerBtn"
              type="button"
              onClick={() => siteAddToCartClick(invests)}
            >
              Add to Cart
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color="primary"
              className="registerBtn"
              type="button"
              onClick={() => bookNowClick(invests)}
            >
              Book Now
            </Button>
            <br/><br/>
            <Button
              variant="contained"
              color="primary"
              className="registerBtn"
              type="button"
              onClick={() => assetDetailsClick(invests)}
            >
              Asset Details
            </Button>
          </div>
        ))}
      </div>
      <br />
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowSiteInvestPopup(!siteInvestPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
      <br />
    </div>
  );
  /* AAMC Invest */
  const closeAAMCInvestModal = (
    <span
      onClick={() => {
        setShowAAMCInvestPopup(!aamcInvestPopup);
      }}
    >
      X
    </span>
  );
  const titleAAMCInvest = "AAMC Invest Page";
  const aamcInvestBody = (
    <div style={{ marginTop: "20px !important" }}>
      <div className="cardStyleForDashboardItems">
        {aamcInvestList.map((invests, index) => (
          <div key={index} className="cardStyleForDashboardItemsBody">
            <p>
              <b>assetdeveloperid:</b>
              {invests.assetdeveloperid}
            </p>
            <p>
              <b>siteid:</b>
              {invests.siteid}
            </p>
            <p>
              <b>assetverticalid:</b>
              {invests.assetverticalid}
            </p>
            <p>
              <b>category:</b>
              {invests.category}
            </p>
            <p>
              <b>modelid:</b>
              {invests.modelid}
            </p>
            <p>
              <b>modelfriendlyname:</b>
              {invests.modelfriendlyname}
            </p>
            <p>
              <b>assetid:</b>
              {invests.assetid}
            </p>
            <p>
              <b>assetfriendlyname:</b>
              {invests.assetfriendlyname}
            </p>
            <p>
              <b>assetstatus:</b>
              {invests.assetstatus}
            </p>
            <p>
              <b>whitelabelcost:</b>
              {invests.whitelabelcost}
            </p>
            <Button
              variant="contained"
              color="primary"
              className="registerBtn"
              type="button"
              onClick={() => siteAddToCartClick(invests)}
            >
              Add to Cart
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color="primary"
              className="registerBtn"
              type="button"
              onClick={() => bookNowClick(invests)}
            >
              Book Now
            </Button>
            <br/><br/>
            <Button
              variant="contained"
              color="primary"
              className="registerBtn"
              type="button"
              onClick={() => assetDetailsClick(invests)}
            >
              Asset Details
            </Button>
          </div>
        ))}
      </div>
      <br />
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowAAMCInvestPopup(!aamcInvestPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
      <br />
    </div>
  );
  /* Model Invest */
  const closeModelInvestModal = (
    <span
      onClick={() => {
        setShowModelInvestPopup(!modelInvestPopup);
      }}
    >
      X
    </span>
  );
  const titleModelInvest = "Model Invest Page";
  const modelInvestBody = (
    <div style={{ marginTop: "20px !important" }}>
      <div className="cardStyleForDashboardItems">
        {modelsInvestList.map((invests, index) => (
          <div key={index} className="cardStyleForDashboardItemsBody">
            <p>
              <b>assetdeveloperid:</b>
              {invests.assetdeveloperid}
            </p>
            <p>
              <b>siteid:</b>
              {invests.siteid}
            </p>
            <p>
              <b>assetverticalid:</b>
              {invests.assetverticalid}
            </p>
            <p>
              <b>category:</b>
              {invests.category}
            </p>
            <p>
              <b>modelid:</b>
              {invests.modelid}
            </p>
            <p>
              <b>modelfriendlyname:</b>
              {invests.modelfriendlyname}
            </p>
            <p>
              <b>assetid:</b>
              {invests.assetid}
            </p>
            <p>
              <b>assetfriendlyname:</b>
              {invests.assetfriendlyname}
            </p>
            <p>
              <b>assetstatus:</b>
              {invests.assetstatus}
            </p>
            <p>
              <b>whitelabelcost:</b>
              {invests.whitelabelcost}
            </p>
            <Button
              variant="contained"
              color="primary"
              className="registerBtn"
              type="button"
              onClick={() => siteAddToCartClick(invests)}
            >
              Add to Cart
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color="primary"
              className="registerBtn"
              type="button"
              onClick={() => bookNowClick(invests)}
            >
              Book Now
            </Button>
            <br/><br/>
            <Button
              variant="contained"
              color="primary"
              className="registerBtn"
              type="button"
              onClick={() => assetDetailsClick(invests)}
            >
              Asset Details
            </Button>
          </div>
        ))}
      </div>
      <br />
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowModelInvestPopup(!modelInvestPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
      <br />
    </div>
  );
  /* Site Details */
  const closeSiteModal = (
    <span
      onClick={() => {
        setShowSiteDetailsPopup(!siteDetailsPopup);
      }}
    >
      X
    </span>
  );
  const title = "More Site Details";
  const siteDetailsBody = (
    <div style={{ marginTop: "20px !important" }}>
      <div className="poupupContent">
        <p>Site Rating:{siteRating}</p>
        <p>Site ID:{selectedSiteId}</p>
        <p>Site Name:{siteName}</p>
        <div className="section3BodyWithoutPAdding ">
          <p>location Address:{siteLocationAddress}</p>
          <p>Latitude:{siteLatitude}</p>
          <p>Longitude:{siteLongitude}</p>
        </div>

        <Button
          style={{ alignContent: "center" }}
          variant="contained"
          color="primary"
          className="registerBtn"
          type="button"
          onClick={() => sitesInvestClick(selectedSiteId)}
        >
          Invest
        </Button>
      </div>

      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowSiteDetailsPopup(!siteDetailsPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* Asset Details */
  const closeAssetModal = (
    <span
      onClick={() => {
        setShowAssetDetailsPopup(!assetDetailsPopup);
      }}
    >
      X
    </span>
  );
  const titleAsset = "More Asset Details";
  const assetDetailsBody = (
    <div style={{ marginTop: "20px !important" }}>
      <div className="poupupContent">
        <img
          alt=""
          src={`data:image/jpeg;base64,${AssetImage}`}
          width="200"
          height="200"
        />
        <p>
          <b>Assetdeveloper ID:</b>
          {AssetDeveloperID}
        </p>
        <p>
          <b>AssetverticalId</b>
          {AssetVerticalID}
        </p>
        <p>
          <b>Category:</b>
          {AssetCategory}
        </p>
        <p>
          <b>AssetType:</b>
          {AssetType}
        </p>
        <p>
          <b>Model ID:</b>
          {AssetModelId}
        </p>
        <p>
          <b>Assetid:</b>
          {AssetId}
        </p>
        <p>
          <b>Assetstatus:</b>
          {AssetStatus}
        </p>
        <p>
          <b>Friendlyname:</b>
          {AssetFriendlyName}
        </p>
        <p>
          <b>Whitelabelcost:</b>
          {AssetWhitelabelCost}
        </p>
        <p>
          <b>Sellingcost:</b>
          {AssetSellingCost}
        </p>
        <div className="section3BodyWithoutPAdding ">
          <p>
            <b>Latitude:</b>
            {AssetLatutude}
          </p>
          <p>
            <b>Longitude:</b>
            {AssetLongitude}
          </p>
        </div>
        <br />
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="button"
          onClick={() => assetAddToCartClick(selectedSiteId)}
        >
          Add to Cart
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="button"
          onClick={() => assetBookNowClick(selectedSiteId)}
        >
          Book Now
        </Button>
      </div>

      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowAssetDetailsPopup(!assetDetailsPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* Grower Details */
  const closeGrowerModal = (
    <span
      onClick={() => {
        setShowGrowerDetailsPopup(!growerDetailsPopup);
      }}
    >
      X
    </span>
  );
  const titleGrower = "More AAMC Details";
  const growerDetailsBody = (
    <div style={{ marginTop: "20px !important" }}>
      <div className="poupupContent">
        <p>Rating:{GrowerRating}</p>
        <p>userid:{growerObj?.growerUserID}</p>
        <p>username:{growerObj?.growerUsername}</p>
        <p>firstname:{growerObj?.growerFirstname}</p>
        <p>lastname:{growerObj?.growerLastname}</p>
        <p>parentid:{growerObj?.growerParentId}</p>
        <p>usertype:{growerObj?.growerUserType}</p>
        <p>email:{growerObj?.growerEmail}</p>
        <p>phone:{growerObj?.growerPhone}</p>
        <p>city:{growerObj?.growerCity}</p>
        <p>state:{growerObj?.growerState}</p>
        <p>pancard:{growerObj?.growerPancard}</p>
        <p>assetdeveloperid:{selectedAssetDeveloperId}</p>
        <div className="section3BodyWithoutPAdding ">
          <p>address:{growerObj?.growerAddress}</p>
          <p>Latitude:{growerObj?.growerLatitude}</p>
          <p>Longitude:{growerObj?.growerLongitude}</p>
        </div>
        <Button
          style={{ alignContent: "center" }}
          variant="contained"
          color="primary"
          className="registerBtn"
          type="button"
          onClick={() => aamcInvestClick(selectedAssetDeveloperId)}
        >
          Invest
        </Button>
      </div>

      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowGrowerDetailsPopup(!growerDetailsPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* Model Details */
  const closeModelModal = (
    <span
      onClick={() => {
        setShowModelDetailsPopup(!modelDetailsPopup);
      }}
    >
      X
    </span>
  );
  const titleModel = "More Model Details";
  const modelDetailsBody = (
    <div>
      <div className="poupupContent">
        <img
          alt=""
          src={`data:image/jpeg;base64,${modelImagedata}`}
          width="200"
          height="200"
        />
        <p>AssetdeveloperId:{modelAssetDeveloperID}</p>
        <p>AssetverticalId:{modelAssetVerticalID}</p>
        <p>AssetverticalfriendlyName:{modelAssetVerticalFriendlyName}</p>
        <p>CategoryType:{modelCategoryType}</p>
        <p>AssetType:{modelAssetType}</p>
        <p>ModelId:{modelId}</p>
        <p>ModelFriendlyName:{modelFriendlyname}</p>
        <p>Modelurl:{modelUrl}</p>
        <p>Description:{modelDescription}</p>

        <br />
        <Button
          style={{ alignContent: "center" }}
          variant="contained"
          color="primary"
          className="registerBtn"
          type="button"
          onClick={() => modelInvestClick(modelId)}
        >
          Invest
        </Button>
      </div>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowModelDetailsPopup(!modelDetailsPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  return (
    <>
      <div className="container" id="mainContentList">
        <CustomPopup
          closeButton={closeSiteModal}
          isOpen={siteDetailsPopup}
          closeModal={siteDetailsPopup}
          content={siteDetailsBody}
          title={title}
        />

        <CustomPopup
          closeButton={closeSiteInvestModal}
          isOpen={siteInvestPopup}
          closeModal={siteInvestPopup}
          content={siteInvestBody}
          title={titleInvest}
        />

        <CustomPopup
          closeButton={closeGrowerModal}
          isOpen={growerDetailsPopup}
          closeModal={growerDetailsPopup}
          content={growerDetailsBody}
          title={titleGrower}
        />
        <CustomPopup
          closeButton={closeAAMCInvestModal}
          isOpen={aamcInvestPopup}
          closeModal={aamcInvestPopup}
          content={aamcInvestBody}
          title={titleAAMCInvest}
        />
        <CustomPopup
          closeButton={closeModelModal}
          isOpen={modelDetailsPopup}
          closeModal={modelDetailsPopup}
          content={modelDetailsBody}
          title={titleModel}
        />
        <CustomPopup
          closeButton={closeModelInvestModal}
          isOpen={modelInvestPopup}
          closeModal={modelInvestPopup}
          content={modelInvestBody}
          title={titleModelInvest}
        />
        <CustomPopup
          closeButton={closeAssetModal}
          isOpen={assetDetailsPopup}
          closeModal={assetDetailsPopup}
          content={assetDetailsBody}
          title={titleAsset}
        />
        <CustomPopup
          closeButton={closeCartPopup}
          isOpen={cartPopup}
          closeModal={cartPopup}
          content={CartPopupBody}
          title={titleCart}
        />
        <CustomPopup
          closeButton={closeBooknowResponsePopup}
          isOpen={booknowResponsePopup}
          closeModal={booknowResponsePopup}
          content={booknowResponsePopupBody}
          title={titleBooknowResponse}
        />
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Sector
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => setSelectedVerticalsId(event.target.value)}
                label="Select Verticals"
              >
                {verticalsIdList.length > 0 ? (
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <h4>Sector not found</h4>
                )}
              </Select>
            </FormControl>
          </div>

          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Category
              </InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => setSelectedCategory(event.target.value)}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Asset Type
              </InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => setSelectedAssertTypes(event.target.value)}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="MuiGrid-root">
          <h2>Farms</h2>

          <div className="cardStyleForDashboardItems">
            {isLoading ? (
              <div className="cardStyleForDashboardItems">
                <CircularProgress />
              </div>
            ) : (
              <div className="cardStyleForDashboardItems">
                {siteList.length>0 ? (
                  <div className="cardStyleForDashboardItems">
                    {siteList.map((sites, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        
                          <p>
                            <b>Rating:</b>
                            {sites.siteratings}
                          </p>
                          <p>
                            <b>Site Name:</b>
                            {sites.sitename}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {sites.siteid}
                          </p>
                        <p>
                          <b>Assetdeveloperid:</b>
                          {sites.assetdeveloperid}
                        </p>
                        <p>
                          <b>ModelId:</b>
                          {sites.modelid}
                        </p>
                        <p>
                          <b>Model Asset Count:</b>
                          {sites.modelassetcount}
                        </p>
                        <div className="buttonWrapper">
                          <Button
                            variant="contained"
                            color="primary"
                            className="registerBtn"
                            type="button"
                            onClick={() => siteDetailsPopupClick(sites.siteid)}
                          >
                            Site Details
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className="registerBtn"
                            type="button"
                            onClick={() => growerDetailsPopupClick(sites.assetdeveloperid)}
                          >
                            AAMC Details
                          </Button>
                        </div>
                        <div className="buttonWrapper">
                          <Button
                            variant="contained"
                            color="primary"
                            className="registerBtn"
                            type="button"
                            onClick={() => modalDetailsPopupClick(sites.assetdeveloperid, sites.modelid)}
                          >
                            Model Details
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className="registerBtn"
                            type="button"
                            onClick={() => sitesInvestClick1(sites.siteid)}
                          >
                            Invest
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>Sites not found.</h4>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
