import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import MainService from "../../../../Common/MainService";
import GenerateRFQ from "../../../RFQuoteManagement/GenerateRFQ";
import UpdateRfqStatus from "../../../RFQuoteManagement/UpdateRfqStatus";
import MaintainOrderRequestForms from "./MaintainOrderRequestForms";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

const OrderAssetManagement = ({ reqTypes, reqTypesBasedOnRoleandUserType }) => {
  const classes = useStyles();
  const theme = useTheme();
  const iconWidthRes = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const [isLoading, setIsLoading] = useState(true);
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [assetVerticalFriendlyNameValue, setAssetVerticalFriendlyNameValue] =
    useState("");
  const [getModelFriendlyName, setModelFriendlyName] = useState("");
  const [getContractManufacturing, setContractManufacturing] = useState(false);
  const [getRfqData, setRfqData] = useState([]);
  const [getReqVal, setReqVal] = useState("");
  const [getreqArr, setReqArr] = useState([]);
  const [selectedReqType, setSelectedReqType] = useState("");
  const [rfqIdModel, setRfqIdModel] = useState(false);
  const [selectedRfqId, setSelectedRfqId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [generateRFQ, setGenerateRFQ] = useState({
    model: false,
    value: "",
  });
  const getAppConfig = () => window.AppConfigData;

  useEffect(() => {
    const defaultReqTypes = [
      { type: "asset-buy-order-req" },
      { type: "cf-agri-assets-order-req" },
      { type: "transport-asset-order-req" },
      { type: "storage-asset-order-req" },
      { type: "whitelabel-asset-request" },
    ];

    const selectedReqTypes =
      reqTypes && Array.isArray(reqTypes) && reqTypes.length > 0
        ? reqTypes
        : defaultReqTypes;

    const [selectedReqType] = selectedReqTypes;

    setReqVal(selectedReqType?.type);
    setSelectedReqType(selectedReqType?.type);
    setReqArr(selectedReqTypes);
  }, [reqTypes]);

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList(getAppConfig().EnterpriseId)
      .then((data) => {
        const filerFriendlyName = ["MACHINERY", "TRANSPORTATION"];
        const filterData = data.filter((val, index) => {
          return !filerFriendlyName.includes(val.friendlyName);
        });
        if (filterData.length > 0) {
          setSelectedVerticalsId(filterData[0]?.assetVerticalId);
          setAssetVerticalFriendlyNameValue(filterData[0]?.friendlyName);
          setVerticalsIdList(filterData);
        } else {
          setSelectedVerticalsId("");
          setVerticalsIdList([]);
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  useMemo(() => {
    getAssetVerticalsIdList();
  }, []);

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(
        selectedVerticalsId?.replace(" ", ""),
        getAppConfig().EnterpriseId
      )
        .then((data) => {
          if (data?.length > 0) {
            setSelectedCategory(data[0]?.categoryType);
            setCategoryTypesList(data);
          } else {
            setSelectedCategory("");
            setCategoryTypesList([]);
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId?.replace(" ", ""),
        selectedCategory?.replace(" ", ""),
        getAppConfig().EnterpriseId
      )
        .then((data) => {
          if (data?.length > 0) {
            setSelectedAssertTypes(data[0]?.assetType);
            setAssertTypesList(data);
          } else {
            setSelectedAssertTypes("");
            setAssertTypesList([]);
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    getAssetTypesList();
  }, [selectedCategory]);

  const getModelsList = () => {
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId?.replace(" ", ""),
        selectedCategory?.replace(" ", ""),
        selectedAssertTypes?.replace(" ", ""),
        username
      )
        .then((data) => {
          if (data?.length > 0) {
            setSelectedModel(data[0]?.modelid);
            setModelFriendlyName(data[0]?.modelfriendlyname);
            setModelsList(data);
          } else {
            setSelectedModel("");
            setModelsList([]);
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    getModelsList();
  }, [selectedAssertTypes]);

  /** Contract Manufacturing Model */
  const contractManufacturingHandler = () => {
    setContractManufacturing(!getContractManufacturing);
  };
  /** EOD Contract Manufacturing Model */

  /* get all quote requests */
  useEffect(() => {
    if (getReqVal) {
      quoteRequestHandler();
    }
  }, [
    getReqVal,
    assetVerticalFriendlyNameValue,
    selectedCategory,
    selectedAssertTypes,
    selectedModel,
  ]);

  const quoteRequestHandler = () => {
    MainService.getQuotesBasedOnAssetVerticals(
      getReqVal.replace(/ /g, ""),
      assetVerticalFriendlyNameValue.replace(/ /g, ""),
      selectedCategory.replace(/ /g, ""),
      selectedAssertTypes.replace(/ /g, ""),
      selectedModel.replace(/ /g, ""),
      parentId,
      username
    )
      .then((res) => {
        setRfqData(res.data);
      })
      .catch((err) => {
        return NotificationManager.error(
          err?.message || "something went wrong"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const assetVerticalHandler = (value) => {
    setAssetVerticalFriendlyNameValue(value);
    const data = verticalsIdList.filter((val, i) => {
      return val.friendlyName === value;
    });
    setSelectedVerticalsId(data[0]?.assetVerticalId);
  };

  const handleButtonClick = (type) => {
    setReqVal(type);
    setSelectedReqType(type);
  };

  const statusChangeHandler = () => {
    setRfqIdModel(!rfqIdModel);
  };

  // Generate RFQ Model
  const generateRFQModelHandler = (rfqId) => {
    setGenerateRFQ({
      value: rfqId,
      model: !generateRFQ?.model,
    });
  };

  return (
    <>
      <Box className="container" id="mainContentList" sx={{ width: "100%" }}>
        <Fab
          color="primary"
          size={iconWidthRes ? "small" : "large"}
          aria-label="add"
          title="List"
          className={classes.fab}
          onClick={() => contractManufacturingHandler()}
        >
          <AddIcon></AddIcon>
        </Fab>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            margin: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {getreqArr &&
            getreqArr?.map((reqType) => (
              <Box
                key={reqType.type}
                sx={{
                  width: { xs: "100%", sm: "70%", md: "auto" },
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handleButtonClick(reqType.type)}
                  sx={{
                    backgroundColor:
                      selectedReqType === reqType.type ? "#3ECF8E" : "inherit",
                    color:
                      selectedReqType === reqType.type ? "primary" : "inherit",
                  }}
                >
                  {reqType.type}
                </Button>
              </Box>
            ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            margin: "10px",
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Sector
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={assetVerticalFriendlyNameValue}
                onChange={(e) => {
                  assetVerticalHandler(e.target.value);
                }}
                label="Select Verticals"
              >
                {verticalsIdList?.length > 0 ? (
                  verticalsIdList &&
                  verticalsIdList?.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.friendlyName}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Sector Found</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Category
              </InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList?.length > 0 ? (
                  categoryTypesList &&
                  categoryTypesList?.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Categorys Found</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Asset Type
              </InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList?.length > 0 ? (
                  assertTypesList &&
                  assertTypesList?.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Asset Types Found</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Model
              </InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                }}
                label="Select Model"
              >
                {modelList?.length > 0 ? (
                  modelList &&
                  modelList?.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Models Found</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {isLoading ? (
          <Box className="cardStyleForDashboardItems">
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {getRfqData?.length > 0 ? (
              <Box className="cardStyleForDashboardItems">
                {getRfqData &&
                  getRfqData?.map((data, index) => (
                    <Box key={index} className="cardStyleForDashboardItemsBody">
                      <Box component="span">
                        <p>
                          <b>Order ID:</b>
                          {data.orderId}
                        </p>
                        <p>
                          <b>Status:</b>
                          {data.orderStatus}
                        </p>
                        <p>
                          <b>TimeStamp:</b>
                          {data.timeStamp}
                        </p>
                      </Box>
                      <Box>
                        <Button
                          sx={{ textTransform: "none", margin: "5px" }}
                          variant="outlined"
                        >
                          <Link
                            to={`/main/viewrfqdoc/${data.orderId}/invoice`}
                            style={{ textDecoration: "none" }}
                          >
                            View Invoice
                          </Link>
                        </Button>
                        {data.rfqStatus !== "close" && (
                          <Button
                            sx={{ textTransform: "none", margin: "5px" }}
                            variant="outlined"
                            onClick={() => {
                              setSelectedStatus(data.orderStatus);
                              setSelectedRfqId(data.orderId);
                              statusChangeHandler();
                            }}
                          >
                            Update Status
                          </Button>
                        )}
                      </Box>
                      <Box>
                        {data.rfqStatus !== "close" && (
                          <Button
                            sx={{ textTransform: "none", margin: "5px" }}
                            variant="outlined"
                            onClick={() => {
                              generateRFQModelHandler(data.orderId);
                            }}
                          >
                            Generate PO Doc
                          </Button>
                        )}
                        <Button
                          sx={{ textTransform: "none", margin: "5px" }}
                          variant="outlined"
                        >
                          <Link
                            to={`/main/viewrfqdoc/${data.orderId}/po`}
                            style={{ textDecoration: "none" }}
                          >
                            View PO Doc
                          </Link>
                        </Button>
                      </Box>
                    </Box>
                  ))}
              </Box>
            ) : (
              <h4 className="cardStyleForDashboardItems">
                Order Data Not Found.
              </h4>
            )}
          </Box>
        )}
        {/* geneare order related forms */}
        <MaintainOrderRequestForms
          openModel={getContractManufacturing}
          closeModel={contractManufacturingHandler}
          verticalsIds={verticalsIdList}
          categoryTypes={categoryTypesList}
          assertTypes={assertTypesList}
          models={modelList}
          reLoad={quoteRequestHandler}
          reqTypesBasedOnRoleandUser={reqTypesBasedOnRoleandUserType}
        />
        {/* rfq status update */}
        <UpdateRfqStatus
          selectedStatus={selectedStatus}
          selectedRfqId={selectedRfqId}
          openModel={rfqIdModel}
          closeModel={statusChangeHandler}
          reLoad={quoteRequestHandler}
          reqType="update-order-status"
        />
        {/* PO request */}
        <GenerateRFQ
          openModel={generateRFQ?.model}
          closeModel={generateRFQModelHandler}
          rfqId={generateRFQ?.value}
          componentName="po"
        />
      </Box>
    </>
  );
};
export default OrderAssetManagement;
