import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import { Container,Form, Button } from "react-bootstrap";

export default function BlogDetails() {
  const { id } = useParams();
  const [blogData, setBlogData] = useState({ attributes: {} });
  const [newComment, setNewComment] = useState("");

  useEffect(() => {
    blogDetails();
  }, [id]);
  const handleCommentSubmit = () => {
    // Make a POST request to your API to add a new comment
    fetch(`http://172.16.1.63:1337/api/comments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
       data:{ blog: id,
        commentText: newComment,
       }
      }),
    })
      .then((res) => res.json())
      .then((response) => {
       
        setBlogData((prevData) => ({
          ...prevData,
          attributes: {
            ...prevData.attributes,
            comments: {
              data: [...prevData.attributes.comments.data, response.data],
            },
          },
        }));
        // Clear the input field after submitting the comment
        setNewComment("");
      })
      .catch((error) => {
        console.error("Error posting comment:", error);
      });
  };
  const blogDetails = () => {
    fetch(
      `http://172.16.1.63:1337/api/blogs/${id}?populate=blogImage&populate=comments`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setBlogData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog details:", error);
      });
  };


  const commentSubmitHandler = () =>{

  }
  const baseUrl = "http://172.16.1.63:1337";
  const imageUrl =
    baseUrl + (blogData?.attributes?.blogImage?.data?.attributes?.url || "");

  const comments = blogData?.attributes?.comments?.data || [];
  const commentElements = comments.map((comment) => (
    <div key={comment.id}>
      <p>{comment.attributes.commentText}</p>
      {/* Render other comment details as needed */}
    </div>
  ));
  return (
    <>
      <HeaderComponent />
      <Container className="subSectionWrapper mt-5">
        <h3>{blogData?.attributes?.blogTitle}</h3>
        <p>{blogData?.attributes?.blogShortDescription}</p>
        <p>{blogData?.attributes?.blogDate}</p>
        {imageUrl && (
          <img src={imageUrl} alt={blogData?.attributes?.blogTitle} />
        )}

        <p>{blogData?.attributes?.comments?.data?.attributes?.commentText}</p>

        Comments: {commentElements}
        <Form.Group controlId="commentInput">
          <Form.Label>Add a Comment:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
          />
        </Form.Group>
        
        {/* Submit button for posting a comment */}
        <Button variant="primary" onClick={handleCommentSubmit}>
          Post Comment
        </Button>

      </Container>
      <FooterComponent />
    </>
  );
}
