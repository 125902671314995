import React from 'react';
import step1 from "../../../images/step1.png";
import step2 from "../../../images/step2.png";
import step3 from "../../../images/growth.png";
import step4 from "../../../images/step4.png";
import step5 from "../../../images/step5.png";
import StepCards from '../../stepCards/StepCards';
import img5 from "../../../images/return-of-investment.png";
import img1 from "../../../images/optionalChoice.png";


export default function InvestmentSection4() {

    const stepCardContent = [
        {
          title: "Step 1",
          bodycopy: "Investor login & choose the Farm.",
          img: step1,
        },
        {
          title: "Step 2",
          bodycopy: "Choose the Asset Type and invest.",
          img: img1,
        },
        {
          title: "Step 3",
          bodycopy: "Grown by GBR Farming approved grower on behalf of investor.",
          img: step3,
        },
        {
          title: "Step 4",
          bodycopy:
            "Managed by GBR expert partner to provide the best return.",
          img: img5,
        },
        {
          title: "Step 5",
          bodycopy:
            "Wallet to check the returns. ",
          img: step4,
        },
        {
            title: "Step 6",
            bodycopy:
              "Investor can withdraw money anytime from anywhere.",
            img: step5,
          },
      ];
  return (
    
<div className="section3BodyContainer p-0 investment-card-wrapper justify-content-md-left justify-content-center section3BodyContainer--mod">
    {stepCardContent.map((e) =>{
               return (
                <StepCards
                  src={e.img}
                  stepTitle={e.title}
                  stepContent={e.bodycopy}
                />
              );
        })}
    </div>
      
      
      )
}
