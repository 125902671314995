import React, { useState, useEffect, useMemo } from "react";
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles,createTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function SiteTypesComponent(props) {
  const timeStamp = new Date();
  const classes = useStyles();
  const theme = createTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [siteTypesData, setSiteTypesData] = useState([]);
  const [allSiteTypes,setAllSiteTypes] = useState([]);
  const [filteredSiteTypesData, setFilteredSiteTypesData] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [isLoading, setIsLoading] = useState(false);
  const [showListSiteTypes, setShowListSiteTypes] = useState(false);
  const [showGridSiteTypes, setGridListSiteTypes] = useState(true);
  const [siteType, setSiteType] = useState([]);
  const [searched, setSearched] = useState("");
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedSiteType, setSelectedSiteType] = useState("");
  const [createSiteTypePopup,setCreateSiteTypePopup] = useState(false);
  const [siteTypeDeletePopup,setSiteTypeDeletePopup] = useState(false);
  const [urlValue,setUrlValue]= useState("");

  useEffect(() => {
    getAllSiteTypesList(username);
  }, []);


  const getAllSiteTypesList = (user) => {
    MainService.getAllSiteTypes(user)
      .then((res) => {
        if (res.sitetypes.length > 0) {
          // Remove duplicate objects based on a property (e.g., 'sitetype')
          const uniqueSiteTypes = removeDuplicates(res.sitetypes, 'sitetype');
  
          setSiteTypesData(uniqueSiteTypes);
          setFilteredSiteTypesData(uniqueSiteTypes);
          setSiteType(uniqueSiteTypes[0]);
        } else {
          setSiteTypesData([]);
          setFilteredSiteTypesData([]);
          setSiteType("");
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  
  // Function to remove duplicate objects from an array based on a property
  const removeDuplicates = (arr, prop) => {
    const seen = new Set();
    return arr.filter((item) => {
      const key = prop ? item[prop] : JSON.stringify(item);
      if (!seen.has(key)) {
        seen.add(key);
        return true;
      }
      return false;
    });
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /*List Filter */
  const requestSiteTypesSearch = (searchedVal) => {
    const filteredRows = filteredSiteTypesData.filter((row) => {
      return row.sitetype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setSiteTypesData(filteredRows);
  };

  const cancelSiteTypesSearch = () => {
    setSearched("");
    requestSiteTypesSearch(searched);
  };

  /*Grid Filter */
  const requestSiteTypesGridSearch = (searchedVal) => {
    const filteredRows = filteredSiteTypesData.filter((row) => {
      return row.sitetype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setSiteTypesData(filteredRows);
  };

  const cancelSiteTypesGridSearch = () => {
    setFarmGridSearched("");
    requestSiteTypesGridSearch(farmgridsearched);
  };

  
  const createSiteTypeHandler = ()=>{
    MainService.getAllSiteTypes(parentId)
      .then((res) => {
        if (res.sitetypes.length > 0) {
          setAllSiteTypes(res.sitetypes);
          setFilteredSiteTypesData(res.sitetypes);
          setSiteType(res.sitetypes[0].sitetype);
        } else {
          setAllSiteTypes([]);
          setFilteredSiteTypesData([]);
          setSiteType("");
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setCreateSiteTypePopup(!createSiteTypePopup)
    
  }
  const createSiteTypeSubmitHandler = ()=>{
    let pointData = {
        id: username,
        sitetype: siteType,
        url: urlValue,
        timestamp: timeStamp,
      };
      const pointDataString = JSON.stringify(pointData);
      MainService.createSiteType( parentId,username,pointDataString)
        .then((res) => {
            getAllSiteTypesList(username);
          return NotificationManager.success(res);
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
        props.getAllSiteTypesList(username);
      setCreateSiteTypePopup(!createSiteTypePopup);
  }
const deleteHandler = (sitetype)=>{
    setSelectedSiteType(sitetype);
    setSiteTypeDeletePopup(!siteTypeDeletePopup);
  }
  const deleteSiteTypeSubmitHandler = ()=>{
    MainService.deleteSiteType( parentId,username,selectedSiteType)
    .then((res) => {
        getAllSiteTypesList(username);
      return NotificationManager.success(res);
    })
    .catch((e) => {
      return NotificationManager.error(e);
    });
    props.getAllSiteTypesList(username);
  setSiteTypeDeletePopup(!siteTypeDeletePopup);
}
  return (
    <>
      <div className="container" id="mainContentList">
      <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Create SiteType"
            className={classes.fab}
            onClick={() => createSiteTypeHandler()}
          >
            <AddIcon></AddIcon>
          </Fab>
        {showListSiteTypes === true ? (
          <Box sx={{ width: "100%", marginBottom: 30 }}>
            <div className="cardStyleForDashboardItems">
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <Box sx={{ width: "100%" }}>
                  <SearchBar
                    value={searched}
                    placeholder="Search by Site Type"
                    onChange={(searchVal) => requestSiteTypesSearch(searchVal)}
                    onCancelSearch={() => cancelSiteTypesSearch()}
                  />
                  <br />

                  {siteTypesData.length > 0 ? (
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ maxHeight: 540 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={5}>
                                Site types
                              </TableCell>
                            </TableRow>
                            <TableRow align="center">
                              <TableCell align="center" colSpan={1}>
                                Id
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Site Type
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                url
                              </TableCell>
                              <TableCell align="center" colSpan={1}>
                                Timestamp
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {siteTypesData.length > 0 ? (
                              siteTypesData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((assets, index) => {
                                  return (
                                    <TableRow hover tabIndex={-1} key={index}>
                                      <TableCell align="center">
                                        {assets.id}
                                      </TableCell>
                                      <TableCell align="center">
                                        {assets.sitetype}
                                      </TableCell>
                                      <TableCell align="center">
                                        {assets.url}
                                      </TableCell>
                                      <TableCell align="center">
                                        {assets.timestamp}
                                      </TableCell>

                                      <TableCell align="center">
                                        <Button
                                          style={{
                                            margin: "5px",
                                            width: "50%",
                                          }}
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            deleteHandler(assets.sitetype);
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                            ) : (
                              <TableRow align="center">
                                <TableCell align="center" colSpan={4}>
                                  SiteTypes not found.
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={siteTypesData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <h4>SiteTypes not found.</h4>
                  )}
                </Box>
              )}
            </div>
          </Box>
        ) : null}

        {showGridSiteTypes === true ? (
          <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
            <SearchBar
              placeholder="Search by Site Type"
              value={farmgridsearched}
              onChange={(searchVal) => requestSiteTypesGridSearch(searchVal)}
              onCancelSearch={() => cancelSiteTypesGridSearch()}
            />
            <br />

            {isLoading ? (
              <div className="cardStyleForDashboardItems">
                <CircularProgress />
              </div>
            ) : (
              <div className="cardStyleForDashboardItems">
                <h4 style={{ width: "100%", textAlign: "center" }}>
                  List Of SiteTypes
                </h4>

                {siteTypesData.length > 0 ? (
                  <div className="cardStyleForDashboardItems">
                    {siteTypesData.map((assets, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Id:</b>
                            {assets.id}
                          </p>
                          <p>
                            <b>SiteType:</b>
                            {assets.sitetype}
                          </p>
                          <p>
                            <b>Url:</b>
                            {assets.url}
                          </p>
                          <p>
                            <b>Timestamp:</b>
                            {assets.timestamp}
                          </p>
                        </span>
                        <div style={{ width: "100%", margin: "5px" }}>
                          <Button
                            style={{
                              margin: "5px",
                              width: "50%",
                            }}
                            size="small"
                            variant="contained"
                            onClick={() => {
                              deleteHandler(assets.sitetype);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="cardStyleForDashboardItems">
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <h4>No SiteTypes Found.</h4>
                    )}
                  </div>
                )}
              </div>
            )}
            
          </Box>
        ) : null}
        {/* create SiteType  */}
      <Dialog
        fullScreen={fullScreen}
        open={createSiteTypePopup}
        onClose={() => {
            setCreateSiteTypePopup(!createSiteTypePopup);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Create SiteType"}</DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="creator">Creator:</InputLabel>
            <Input
              disabled
              id="creator"
              name="creator"
              type="text"
              value={username}
            />
          </FormControl>
          

          <FormControl fullWidth>
              <InputLabel id="nodeType">Site Type</InputLabel>
              <Select
                id="nodeType"
                name="nodeType"
                value={siteType}
                onChange={(e) => {
                  setSiteType(e.target.value);
                }}
              >
                {allSiteTypes &&
                  allSiteTypes.map((idObj, index) => (
                    <MenuItem key={`${index}`} value={idObj.sitetype}>
                      {idObj.sitetype}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="url">Url:</InputLabel>
            <Input
              style={{ minwidth: 200 }}
              value={urlValue}
              onChange={(e) => {
                setUrlValue(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              createSiteTypeSubmitHandler();
            }}
          >
            Create
          </Button>{" "}
          &nbsp;
          <Button
            color="default"
            variant="contained"
            onClick={() => {
                getAllSiteTypesList(username);
                setCreateSiteTypePopup(!createSiteTypePopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/*  Delete SiteType */}
      <Dialog
        fullScreen={fullScreen}
        open={siteTypeDeletePopup}
        onClose={() => {
            setSiteTypeDeletePopup(!siteTypeDeletePopup);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Asset Delete"}</DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <h4>Are you sure want to delete?</h4>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              deleteSiteTypeSubmitHandler();
            }}
          >
            Delete
          </Button>{" "}
          &nbsp;
          <Button
            color="default"
            variant="contained"
            onClick={() => {
                getAllSiteTypesList(username);
                setSiteTypeDeletePopup(!siteTypeDeletePopup);

            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    </>
  );
}
