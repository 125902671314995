import React, { } from "react";
import Grid from "@material-ui/core/Grid";
import "./UserManagementComponent.css";

export default function UserManagementComponent() {
   

  return (
    <Grid container spacing={1}>
      <Grid item md={12} sm={12} xs={12}>
        <h1>UserManagementComponent component</h1>
      </Grid>
    </Grid>
  );
}
