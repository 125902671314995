import GridIcon from "@material-ui/icons/GridOnOutlined";
import ListIcon from "@material-ui/icons/List";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import ContentDynamicCard from "../../Common/CommonContent/ContentDynamicCard";
import ContentDynamicTable from "../../Common/CommonContent/ContentDynamicTable";
import MainService from "../../Common/MainService";
import PlaceholderCardList from "../ModelProductManagement/PlaceHolderCardList";
import { getCategoryID } from "../../Common/CommonContent/ContentedRelatedCommonFun";

const dynamicKey = [
  "parentid",
  "contentid",
  "categoryid",
  "contentcreatorid",
  "categoryname",
  "contentowner",
  "contentsubcategory",
  "contentdescription",
  "locationurl",
];

const dynamicKeyTable = [
  "parentid",
  "contentid",
  "categoryid",
  "contentcreatorid",
  "categoryname",
  "contentowner",
  "contentsubcategory",
];

const Schemes = ({ categoryname }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [getMSMEUsers, setMSMEUsers] = useState([]);
  const [getMsmeCaid, setMsmeCaid] = useState("");
  const [getSchemesData, setSchemesData] = useState([]);
  const [getFilterSchemesData, setFilterSchemesData] = useState([]);
  const [getAdvisorName, setAdvisorName] = useState("");
  const [getAdvisorSubCats, setAdvisorSubCats] = useState([]);
  const [getSchemeName, setSchemeName] = useState("");

  useMemo(async () => {
    await getCategoryID(categoryname)
      .then((data) => {
        setMsmeCaid(data);
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
  }, []);

  useEffect(() => {
    if (!getMsmeCaid) return;
    msmeSchemesHandler();
  }, [getMsmeCaid]);

  useEffect(() => {
    getAllAdvisorUsers();
  }, [setMSMEUsers]);

  const getAllAdvisorUsers = () => {
    MainService.getAllMSMEConsumerUsers()
      .then((data) => {
        setMSMEUsers(data);
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
  };

  const msmeSchemesHandler = () => {
    setIsLoading(true);
    setAdvisorName("");
    setSchemeName("");
    MainService.getCategoryContentBaedonCategoryid(getMsmeCaid)
      .then((data) => {
        setSchemesData(data);
        setFilterSchemesData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        return NotificationManager.error(err || "something went wrong");
      });
  };

  const advisorUserHandler = (e) => {
    setAdvisorName(e.target.value);
    MainService.getContentsBasedonContentCreator(
      e.target.value,
      getMsmeCaid,
      "contentowner"
    )
      .then((data) => {
        setSchemesData(data);
        setFilterSchemesData(data);
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
    getSubCategories(e.target.value, getMsmeCaid);
  };

  const getSubCategories = (adviosrUser, msmeId) => {
    MainService.getSubCategoriesForAD(adviosrUser, msmeId)
      .then((data) => {
        setAdvisorSubCats(data);
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
  };

  const schemesSubCatageryFilterData = (e) => {
    setSchemeName(e.target.value);
    const msmeFilterData = getFilterSchemesData.filter((data, index) => {
      return data.contentsubcategory.includes(e.target.value);
    });
    setSchemesData(msmeFilterData);
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  return (
    <>
      <Box className="container" id="mainContentList">
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <FormControl variant="outlined" className="postdropdown" size="small">
            <InputLabel
              style={{
                color: "rgb(43, 144, 99)",
                fontSize: "15px",
              }}
            >
              Advisor
            </InputLabel>
            <Select
              id="selectedSubCategory"
              value={getAdvisorName}
              label="Select Sub-Category"
              onChange={(e) => {
                advisorUserHandler(e);
              }}
            >
              {getMSMEUsers &&
                getMSMEUsers.map((idObj, index) => (
                  <MenuItem key={`${index} `} value={idObj.username}>
                    {idObj.username}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {getAdvisorName !== "" ? (
            <FormControl
              variant="outlined"
              className="postdropdown"
              size="small"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Choose Scheme
              </InputLabel>
              <Select
                id="selectedSubCategory"
                value={getSchemeName}
                label="Select Sub-Category"
                onChange={(e) => {
                  schemesSubCatageryFilterData(e);
                }}
              >
                {getAdvisorSubCats &&
                  getAdvisorSubCats.map((idObj, index) => (
                    <MenuItem
                      key={`${index} `}
                      value={idObj.contentsubcategoryname}
                    >
                      {idObj.contentsubcategoryname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : null}
          {(getAdvisorName !== "" || getSchemeName !== "") && (
            <Button
              color="primary"
              variant="none"
              onClick={() => {
                msmeSchemesHandler();
              }}
            >
              Clear Filter
            </Button>
          )}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={listViewClick}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={gridViewClick}
            ></Button>
          </Box>
        </Box>

        <Box className="MuiGrid-root">
          {listView &&
            (isLoading ? (
              <Box>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
            ) : (
              <ContentDynamicTable
                columns={dynamicKeyTable}
                data={getSchemesData}
                actions={[]}
              />
            ))}
          {gridView &&
            (isLoading ? (
              <PlaceholderCardList numberOfCards={4} heightVal={500} />
            ) : (
              <ContentDynamicCard
                dynamicKey={dynamicKey}
                data={getSchemesData}
                actions={[]}
              />
            ))}
        </Box>
      </Box>
    </>
  );
};
export default Schemes;
