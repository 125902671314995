export const transpoterReducer = (state, action) => {
  switch (action.type) {
    case 'address': {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case 'bookingDetails': {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case 'deliveryDetails': {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case 'transportType': {
      return {
        ...state,
        transportType: action.payload,
      };
    }
    case 'copyType': {
      return {
        ...state,
        copyType: action.payload,
      };
    }
    case 'goodsItemsDetails': {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case 'deliveryChargeDetails': {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case 'termsDetails': {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    default:
      return state;
  }
};
