import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import MainService from "../../Common/MainService";
import {
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import "./KYC.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    width: 200,
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
  menuPaperForm: {
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
}));


export default function KYC(props) {
  const { user } = MainService.getLocalData();
  const { username } = user;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [kycPopup, setShowKycPopup] = useState(false);
  const [kycType, setKycType] = useState("");
  const [kycFile, setKycFile] = useState(null);
  const [kycAgreementDocumentLink, setKycAgreementDocumentLink] = useState("");
  const [viewKycDocumentPopup, setViewKycDocumentPopup] = useState(false);

  const [state, setState] = useState({
    columns: [
      { title: "Document type", field: "documenttype" }, 
      { title: "Document Status", field: "documentstatus" },
      {
        title: "Document Link",
        field: "documentlink",
        render: () => (
          <Button variant="contained" onClick={() => viewKycDocumentsClick()}>
            View
          </Button>
        ),
      },
    ],
    data: [],
  });

  useEffect(() => {
    getKycDocument();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getKycDocument = () => {
   
    MainService.getKycDocument(username)
      .then((data) => {
        setIsLoading(false);
        if (data.length > 0) {
          setState({ ...state, data: data });
          setKycAgreementDocumentLink(data[0].documentlink);
          // setKycAgreementDocumentLink(data[0].locationurl);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const viewKycDocumentsClick = () => {
    setViewKycDocumentPopup(!viewKycDocumentPopup);
  };
  const uploadKycClick = () => {
    setShowKycPopup(!kycPopup);
  };

  const uploadKycDataClick = () => {
    
    if(kycFile)
    {
      var bodyFormData = new FormData();
      bodyFormData.append("file", kycFile);
      bodyFormData.append("mimetype", kycFile.type);
      MainService.uploadKyc(bodyFormData, username, kycType)
        .then((data) => {
          getKycDocument();
          
          return NotificationManager.success("Kyc Uploaded");
        })
        .catch((e) => {
          setIsLoading(false);
          console.error(e);
          return NotificationManager.error("kyc not uploaded");
        });
    }else{
      return NotificationManager.error("please choose file");
    }
    setShowKycPopup(false);
  };

  /* kyc Details */
  const closeKycModal = (
    <span
      onClick={() => {
        setShowKycPopup(!kycPopup);
      }}
    >
      X
    </span>
  );
  const titleKyc = "Add KYC";
  const kycBody = (
    <div style={{margin:"10px"}}>
      <div style={{ display: "inline !important", marginBottom:"10px"}}>
        <p>Upload KYC file:</p>
        <Button variant="contained" component="label">
          Upload File
          <input
            type="file"
            onChange={(e) => {
              setKycFile(e.target.files[0]);
            }}
            hidden
          />
        </Button>
      </div>
      <FormControl fullWidth>
        <InputLabel id="kycLabel">KYC Type</InputLabel>
        <Select
          id="kycLabel"
          value={kycType}
          label="KYC"
          onChange={(e) => {
            setKycType(e.target.value);
          }}
          MenuProps={{ classes: { paper: classes.menuPaperForm } }}
        >
          <MenuItem value="Pan">PAN</MenuItem>
          <MenuItem value="Aadhar">Aadhar</MenuItem>
          <MenuItem value="Passport">Passport</MenuItem>
        </Select>
      </FormControl>
      <br/>
      <div style={{marginLeft : "400px", marginTop : "20px"}}>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="button"
          onClick={() => uploadKycDataClick()}
        >
          Submit
        </Button>
        <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowKycPopup(!kycPopup);
        }}
        style={{
          margin: "20px",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
      </div>
    </div>
  );

  /* View KYC Documents */
  const closeViewKycDocumentPopup = (
    <span
      onClick={() => {
        setViewKycDocumentPopup(!viewKycDocumentPopup);
      }}
    >
      X
    </span>
  );
  const titlecViewKycDocument = "View KYC Document";
  const viewKycDocumentBody = (
    <div style={{ marginTop: "20px !important" }}>
      <div style={{ display: "inline !important" }}>
        <embed
          className="viewWidthHeight"
          type="application/pdf"
          src={kycAgreementDocumentLink}
        ></embed>
      </div>

      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setViewKycDocumentPopup(!viewKycDocumentPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  return (
    <Grid container spacing={1}>
      <Grid item md={12} sm={12} xs={12} className="buttonsContainer">
        <Button
          style={{ float: "right" }}
          variant="contained"
          color="primary"
          className="registerBtn"
          type="button"
          onClick={() => uploadKycClick()}
        >
          Upload KYC
        </Button>
      </Grid>

      <Grid item md={12} sm={12} xs={12}>
        {isLoading ? (
         <div className="cardStyleForDashboardItems"> <CircularProgress /></div>
        ) : (
          <div>
            {state.data.length > 0 ? (
              <div>
                <h3>KYC Status : Verified</h3>
                <MaterialTable
                  fullWidth={12}
                  title={`Investor KYC Documents`}
                  columns={state.columns}
                  data={state.data}
                  editable={{}}
                  
                >
                  <CircularProgress className="loginLoader" />
                  </MaterialTable>
              </div>
            ) : (
              <div>
                <h3>KYC Status :Not Verified</h3>
                <h3>Do your KYC...</h3>
              </div>
            )}
          </div>
        )}
      </Grid>

      <CustomPopup
        closeButton={closeKycModal}
        isOpen={kycPopup}
        closeModal={kycPopup}
        content={kycBody}
        title={titleKyc}
      />

      <CustomPopup
        closeButton={closeViewKycDocumentPopup}
        isOpen={viewKycDocumentPopup}
        closeModal={viewKycDocumentPopup}
        content={viewKycDocumentBody}
        title={titlecViewKycDocument}
      />
    </Grid>
  );
}
