import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
}));

export default function AssetsCategoriesPageComponent() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    columns: [
      { title: "ID", field: "assetVerticalId" },
      { title: "Category Name", field: "categoryType" },
    ],
    data: [],
  });

  const [growerIdList, setGrowerIdList] = useState([]);
  const [selectedGrowserId, setSelectedGrowserId] = useState('');
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState('');

  useEffect(() => {
    getGrowerIdList();
  }, []);

  useEffect(() => {
    if (!selectedGrowserId) return;
    getAssetVerticalsIdList();
  }, [selectedGrowserId]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedVerticalsId) return;
    getCategoryTypesList();
  }, [selectedVerticalsId]);// eslint-disable-line react-hooks/exhaustive-deps

  const getGrowerIdList = () => {
    MainService.getAllSolutions()
      .then((data) => {
        if (data.length > 0) {
          setSelectedGrowserId(data[0].solutionid);
        }
        setGrowerIdList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList(window.AppConfigData.EnterpriseId)
      .then((data) => {
        if (data.length > 0) {
          setSelectedVerticalsId(data[0].assetVerticalId);
        }
        setVerticalsIdList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getCategoryTypesList = () => {
    MainService.getCategoryTypesList(selectedGrowserId, selectedVerticalsId,window.AppConfigData.EnterpriseId)
      .then((data) => {
        setState({ ...state, data });
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <span style={{ display: "inlineBlock", float: "right" }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Select Asset Verticals
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => setSelectedVerticalsId(event.target.value)}
                label="Select Verticals"
              >
                {verticalsIdList.map((idObj, index) => (
                  <MenuItem
                    key={`${index} - ${idObj.assetVerticalId}`}
                    value={idObj.assetVerticalId}
                  >
                    {idObj.assetVerticalFriendlyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
          <span style={{ display: "inlineBlock", float: "right" }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Select Grower
              </InputLabel>
              <Select
                id="selectedGrowserId"
                value={selectedGrowserId}
                onChange={(event) => setSelectedGrowserId(event.target.value)}
                label="Select Grower"
              >
                {growerIdList.map((idObj) => (
                  <MenuItem key={idObj.solutionid} value={idObj.solutionid}>
                    {idObj.solutionname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
        </Grid>

        <Grid item md={12}>
          <MaterialTable
            title="View Asset Categories"
            columns={state.columns}
            data={state.data}
            editable={{}}
          />
        </Grid>
      </Grid>
    </>
  );
}
