// import React, { useState, useEffect } from "react";
// import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
// import Typography from "@material-ui/core/Typography";
// import PropTypes from "prop-types";
// import ProductsRfqManagement from "./ProductsRfqManagement";
// import RFQContentManagement from "../../ContentRfq/RFQContent";
// import "../../Market/Market.css";

// /* Tab panel  */
// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography component={"div"}>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// /* end of tab panel */

// export default function ProductsRfqMarket() {
//   const [value, setValue] = useState(0);
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
//   const orientation = isSmallScreen ? "vertical" : "horizontal";
//   const userType = localStorage.getItem("userType");
//   const userRole = localStorage.getItem("UserRole");

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <>
//       <div className="container" id="mainContentList">
//         <Box
//           sx={{
//             width: "100%",
//             marginBottom: 30,
//             display: "flex",
//             flexDirection: "column",
//             alignItems: { xs: "center", sm: "center" },
//           }}
//         >
//           <Box
//             sx={{
//               borderBottom: 1,
//               borderColor: "divider",
//               width: "100%",
//             }}
//           >
//             <Tabs
//               orientation={orientation}
//               value={value}
//               onChange={handleChange}
//               aria-label="Market"
//             >
//               <Tab
//                 sx={{
//                   display: "flex",
//                   alignItems: "flex-start",
//                 }}
//                 label="PROCESSED-PRODUCTS"
//                 {...a11yProps(0)}
//                 value={0}
//               />
//               <Tab
//                 sx={{ alignItems: "flex-start" }}
//                 label="RAW-PRODUCTS"
//                 {...a11yProps(1)}
//                 value={1}
//               />
//               <Tab
//                 sx={{ alignItems: "flex-start" }}
//                 label="SEMI-PROCESSED"
//                 {...a11yProps(2)}
//                 value={2}
//               />
//               <Tab
//                 sx={{ alignItems: "flex-start" }}
//                 label="RFQ CONTENT"
//                 {...a11yProps(3)}
//                 value={3}
//               />
//             </Tabs>
//           </Box>
//           <Box sx={{ display: "flex", width: "100%" }}>
//             {value === 0 && (
//               <TabPanel value={value} index={0} style={{ width: "100%" }}>
//                 <ProductsRfqManagement
//                   categoryType="PROCESSED-PRODUCTS"
//                 />
//               </TabPanel>
//             )}
//             {value === 1 && (
//               <TabPanel value={value} index={1} style={{ width: "100%" }}>
//                 <ProductsRfqManagement
//                   categoryType="RAW-PRODUCTS"
//                 />
//               </TabPanel>
//             )}
//             {value === 2 && (
//               <TabPanel value={value} index={2} style={{ width: "100%" }}>
//                 <ProductsRfqManagement
//                   categoryType="SEMI-PROCESSED"
//                 />
//               </TabPanel>
//             )}
//             {value === 3 && (
//               <TabPanel value={value} index={3} style={{ width: "100%" }}>
//                 <RFQContentManagement />
//               </TabPanel>
//             )}
//           </Box>
//         </Box>
//       </div>
//     </>
//   );
// }

import Typography from "@material-ui/core/Typography";
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import RFQContentManagement from "../../ContentRfq/RFQContent";
import "../../Market/Market.css";
import ProductsRfqManagement from "./ProductsRfqManagement";

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

const ProductsRfqMarket = ({
  categoryTypes,
  reqTypesBasedOnRoleandUserType,
}) => {
  console.log("categoryTypes", categoryTypes);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const orientation = isSmallScreen ? "vertical" : "horizontal";
  const userType = localStorage.getItem("userType");
  const userRole = localStorage.getItem("UserRole");
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (
      categoryTypes === undefined ||
      categoryTypes === null ||
      !Array.isArray(categoryTypes) ||
      categoryTypes.length === 0
    ) {
      setTabs([
        { label: "PROCESSED-PRODUCTS", categoryType: "PROCESSED-PRODUCTS" },
        { label: "RAW-PRODUCTS", categoryType: "RAW-PRODUCTS" },
        { label: "SEMI-PROCESSED", categoryType: "SEMI-PROCESSED" },
      ]);
    } else {
      setTabs(categoryTypes);
    }
  }, [categoryTypes]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <Box
          sx={{
            width: "100%",
            marginBottom: 30,
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "center" },
          }}
        >
          {Array.isArray(tabs) && (
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
              }}
            >
              <Tabs
                orientation={orientation}
                value={value}
                onChange={handleChange}
                aria-label="Market"
              >
                {tabs &&
                  tabs?.map((tab, index) => (
                    <Tab
                      key={index}
                      sx={{ alignItems: "flex-start" }}
                      label={tab.label}
                      {...a11yProps(index)}
                      value={index}
                    />
                  ))}
                <Tab
                  sx={{ alignItems: "flex-start" }}
                  label="RFQ CONTENT"
                  {...a11yProps(tabs.length)}
                  value={tabs.length}
                />
              </Tabs>
            </Box>
          )}
          {Array.isArray(tabs) && (
            <Box sx={{ display: "flex", width: "100%" }}>
              {tabs &&
                tabs?.map((tab, index) => (
                  <TabPanel
                    key={index}
                    value={value}
                    index={index}
                    style={{ width: "100%" }}
                  >
                    <ProductsRfqManagement
                      categoryType={tab.categoryType}
                      requestTypes={tab.reqTypes}
                      reqTypesBasedOnRoleandUserType={
                        reqTypesBasedOnRoleandUserType
                      }
                    />
                  </TabPanel>
                ))}
              {value === tabs.length && (
                <TabPanel
                  value={value}
                  index={tabs.length}
                  style={{ width: "100%" }}
                >
                  <RFQContentManagement />
                </TabPanel>
              )}
            </Box>
          )}
        </Box>
      </div>
    </>
  );
};
export default memo(ProductsRfqMarket);
