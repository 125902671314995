import React from 'react'
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "./NetworkBanner.css"
export default function NetworkBanner({className, bannerTitle}) {
  return (
    <div className={`network-banner ${className}`}>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={8} sm={12} xs={12}>
            <h1>
            {bannerTitle}
            </h1>
          </Grid>
        </Grid>
      </Container>
    </div>  )
}
