import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { Fragment, memo, useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import { subCategorieValidation } from "./MarketValidation/MarketValidation";

function ContentSubCategoryCreate({
  props,
  modelShow,
  closeModel,
  reloadComponent,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [getSubcategoryName, setSubcategoryName] = useState("");
  const [errors, setErrors] = useState({});
  const [getErrorBool, setErrorBool] = useState(false);

  useEffect(() => {
    if (getErrorBool) {
      validateForm();
    }
    setErrorBool(true);
  }, [getSubcategoryName]);

  const validateForm = useCallback(() => {
    const nameError = subCategorieValidation(getSubcategoryName, "subcategory");
    setErrors({ getSubcategoryName: nameError });
    return !nameError;
  }, [getSubcategoryName]);

  // Clear the form errors when Cancel is clicked
  const handleCancel = () => {
    setErrors({});
    closeModel();
  };

  /* Create Sub-Category */
  const createSubCategoryHandler = () => {
    if (validateForm()) {
      let subCategoryData = {
        categoryid: props.categoryid,
        categoryname: props.categoryname,
        contentsubcategoryname: getSubcategoryName.replace(/\s/g, "_"),
        parentid: props.assetdeveloperid,
        contentsubcategorycreator: username,
      };
      const subCategoryDataString = JSON.stringify(subCategoryData);
      MainService.createContentSubCategory(subCategoryDataString)
        .then((res) => {
          closeModel();
          reloadComponent();
          setSubcategoryName("");
          return NotificationManager.success("Created Sub-Category");
        })
        .catch((err) => {
          return NotificationManager.error(err || "something went wrong");
        });
    }
  };
  /* EOD Create Sub-Category */

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={modelShow}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Create Sub Category"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Category"
              name="Category"
              type="text"
              value={props.categoryname}
              disabled
            />
          </FormControl>
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Category Creator"
              name="Creator"
              type="text"
              value={username}
              disabled
            />
          </FormControl>
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Sub Category Name"
              required
              name="subcatname"
              type="text"
              value={getSubcategoryName}
              onChange={(e) => {
                setSubcategoryName(e.target.value);
              }}
            />
            {errors.getSubcategoryName && (
              <Typography variant="caption" color="error">
                {errors.getSubcategoryName}
              </Typography>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            color="primary"
            variant="contained"
            onClick={() => {
              createSubCategoryHandler();
            }}
          >
            Submit
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            color="error"
            variant="outlined"
            onClick={() => {
              handleCancel();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default memo(ContentSubCategoryCreate);
