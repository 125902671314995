import React from "react";
import Toast from "light-toast";
import { Container } from "@material-ui/core";
import "react-awesome-slider/dist/styles.css";
import "../App.css";
import SolutionEcosystem from "../SolutionEcosystem/SolutionEcosystem";

import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import CTA from "../../CTA/CTA";

import ForFarmerBenefits from "../ForFarmerBenefits/ForFarmerBenefits";
import ForFarmerChallenges from "../ForFarmerChallenges/ForFarmerChallenges";
import ForInputProviderChallenges from "../ForInputProviderChallenges/ForInputProviderChallenges";
import InputProviderBenefits from "../InputProviderBenefits/InputProviderBenefits";
import GBRFarmerService from "../../AgriProducers/Farmer/GBRFarmerService";
import ProcessingMachineryProviderPricing from "../../pricingTabs/ProcessingMachineryProviderPricing";
export default function ForInputProviders() {
  return (
    <>
      <UpdatesBanner
        className="processing-machinery-provider-bg"
        bannerText="Processing Machinery Providers"
        //       bannerbodycopy1={[
        // "We help processing machinery providers to expand their markets"          ]}
        // bannerbodycopy="Brand Recognization | Increased Income | Job Creations | Market Access | Value Addition"
        // buttonText2="Sign in"
        // href2="http://abn.gbrfarming.com/"
        // buttonText="Sign up"
        // href="auth/3/signup"
        // clicked={() => {
        //   localStorage.setItem("userType", "PMC");
        // }}
      />
      {/* <div className="main-wrapper2">
      <ForInputProviderChallenges/>
    </div> */}

      <SolutionEcosystem
      bold="true"
        type="IP"
        bodycopy="Provides financed food processing machinery ecosystem for processing machinery providers, institutional lenders and food processing enterprises"
      />
      <div className="main-wrapper2">
        <Container>
          <GBRFarmerService />
        </Container>
      </div>

      <div className="">
        <InputProviderBenefits />
      </div>

      {/* <div className="subSectionWrapper main-wrapper2">
        <Container>
          <h2 className="container-title text-center"> Pricing</h2>

          <ProcessingMachineryProviderPricing/>
        </Container>
        </div> */}

      {/* <Container>
      {" "}
      <div className="subSectionWrapper">
      <CTA
      
      data="present"
                onClick={()=>{
                  localStorage.setItem("userType", "PMC")
                              }}
                  href="auth/3/signup"
                heading="Expand Your Reach! Join the CMN Network Today and Connect with New Markets!"
                btnText="Register now for free"
      />
    </div>
    </Container> */}

    </>
  );
}
