import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Icon1 from "../../images/app2.png";
import Icon2 from "../../images/app6.png";
import Icon3 from "../../images/app4.png";
import Icon4 from "../../images/market-positioning (1).png";
import Icon5 from "../../images/socialmarketW.svg";
import Icon6 from "../../images/retail (1).svg";
import Icon7 from "../../images/farming.svg";
import Icon8 from "../../images/talking.svg";


import { NavHashLink } from "react-router-hash-link";

export default function ExploreTechnologyBanner() {
  const apps = [
   
    {
      href: "#CMNNetwork",
      image: Icon1,
      title: "CMN Network",
    },
    {
      href: "#PPNApp",
      image: Icon8,
      title: "PPN App",
    },
    {
      href: "#AgriMinerApp",
      image: Icon3,
      title: "AgriMiner App",
    },
   

    {
      href: "#Marketplaces",
      image: Icon5,
      title: "Marketplaces",
    },
    // {
    //   href: "#SocialiaaMarketplace",
    //   image: Icon6,
    //   title: "Social iaaMarketplace",
    // },
    // {
    //   href: "#ProcessingMachineryMarketplace",
    //   image: Icon7,
    //   title: "ProcessingMachinery Marketplace      ",
    // },
    // {
    //   href: "#B2BonMarketplace",
    //   image: Icon7,
    //   title: "B2BonMarketplace",
    // },
    // {
    //   href: "#D2ConMarketplace",
    //   image: Icon4,
    //   title: "D2ConMarketplace",
    // },
  ];

  return (
    <div maxwidth={12} className="businessPartnerContainer agristackContainer">
      <Container maxWidth="sm" className="gridSection gridSection--mod">
        <Grid container={true} alignItems="center" spacing={1} direction="row">
          {apps.map((e) => {
            return (
              <Grid
                id="ecoSystemView_link"
                className="hoverEffect mb-3"
                item
                md={3}
                sm={4}
                xs={6}
              >
                <NavHashLink to={e.href}>
                  <img src={e.image} alt="ecoSystem & Mobile Apps View " />
                  <h4>{e.title}</h4>
                </NavHashLink>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}
