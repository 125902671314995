import React from "react";
import { Chart } from "react-google-charts";

export function PieChart({value, name, total}) {
    const data = [
        ["Task", "Hours per Day"],
        [`${total}`, value],
      ];

      const options = {
        title: name,
        is3D: true,
      };

  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"70%"}
      height={"150px"}
    />
  );
}
