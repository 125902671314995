import React from "react";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import FarmersServices from "../Farmer/FarmersServices";
import "./FPO.css";
import FPONeeds from "./FPONeeds";

export default function Farmers() {
  return (
    <>
      <UpdatesBanner className="ap-fpo" bannerText="FPOs" />
      <FPONeeds />
      <FarmersServices />
    </>
  );
}
