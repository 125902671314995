import React from 'react'
import '../../AssetDeveloperRole/MyOrders/MyOrderStyleComponent.css';
import GlobalNetworkComponent from '../../AssetDeveloperRole/GlobalNetwork/GlobalNetworkComponent';

const PMCGlobalNetwork = () => {
    const UserRole = localStorage.getItem("UserRole");

    let allowedUserTypes = [];
    switch (UserRole) {
        case "BASIC":
            allowedUserTypes = ["CBO","PMCDealer","LogisticsProvider","FMC","FOOD-PROCESSOR","DeviceMaker"];
            break;
        case "STANDARD":allowedUserTypes = ["CBO","PMCDealer","LogisticsProvider","FMC","FOOD-PROCESSOR","DeviceMaker"];break;
        case "PREFERRED":allowedUserTypes = ["CBO","PMCDealer","LogisticsProvider","FMC","FOOD-PROCESSOR","DeviceMaker"];break;
        case "PREMIUM":allowedUserTypes =   ["CBO","PMCDealer","LogisticsProvider","FMC","FOOD-PROCESSOR","DeviceMaker"];break;
        case "ADMIN":
            allowedUserTypes =  ["CBO","PMCDealer","LogisticsProvider","FMC","FOOD-PROCESSOR","DeviceMaker"];break;
            break;
        default:
            console.error("Unexpected UserRole:", UserRole);
            // Fallback behavior: Set default userTypes or handle the error
            allowedUserTypes = ["CBO","PMCDealer","LogisticsProvider","FMC","FOOD-PROCESSOR","DeviceMaker"]; // Example default behavior
            break;
    }
  return (
    <div>
    <GlobalNetworkComponent userTypes={allowedUserTypes} />
</div>
  )
}

export default PMCGlobalNetwork