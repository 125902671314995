import React from "react";
import BuyAgriProducts from "../ExploreProducts/BuyAgriProducts";

const ExploreCertifiedDevice = () => {
  return (
    <>
      <BuyAgriProducts categoryType="DEVICE" type={["DeviceMaker"]} />
    </>
  );
};

export default ExploreCertifiedDevice;
