
import React from 'react'
import GlobalNetworkComponent from '../../AssetDeveloperRole/GlobalNetwork/GlobalNetworkComponent'

const LpGlobalNetwork = () => {

  const UserRole = localStorage.getItem("UserRole");
  let allowedUserTypes = [];
      switch (UserRole) {
          case "BASIC":
              allowedUserTypes = ["Buyer", "AssetDeveloper"];
              break;
          case "STANDARD":allowedUserTypes = ["Buyer", "AssetDeveloper"];break;
          case "PREFERRED":allowedUserTypes = ["Buyer", "AssetDeveloper","AgriAssetDistributor","FOOD-PROCESSOR"];break;
          case "PREMIUM":allowedUserTypes =  ["Buyer", "AssetDeveloper","AgriAssetDistributor","FOOD-PROCESSOR"];break;
          case "ADMIN":
              allowedUserTypes = ["Buyer", "AssetDeveloper","AgriAssetDistributor","FOOD-PROCESSOR","StorageCompany", "LogisticsProvider"];
              break;
          default:
              console.error("Unexpected UserRole:", UserRole);
              // Fallback behavior: Set default userTypes or handle the error
              allowedUserTypes = ["Buyer"]; // Example default behavior
              break;
      }
  return (
    <div>
        <div>
<GlobalNetworkComponent userTypes={allowedUserTypes} />
         </div>
    </div>
  )
}

export default LpGlobalNetwork
