import React, { useEffect, useState } from "react";
import { Box, Button, Fab, Paper, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchBar from "../../../../../containers/SearchBar/SearchBar";
import MatTable from "../../../../../containers/table/Table";
import MainService from "../../../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const geofenceFields = [
  "",
  "Realm",
  "Tenant ID",
  "Vehicle Id", 
  "Geofence Type",
  
];

const UpdatedGeofenceDetails = (props) => {
  const [vehicleDetails, setVehicleDetails] = useState({ assets: [] });
  const [selectedItem, setSelectedItem] = useState("");
  const [assetCount, setAssetCount] = useState({});
  const [searchText, setSearchText] = useState("");
  const [userTypeValue, setUserTypeValue] = useState("");
  const [userTypeRole, setUserTypeRole] = useState("");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [geofenceDetails, setGeofenceDetails] = useState([]);

  const UserRole = localStorage.getItem("UserRole");

// const history= useHistory();

  useEffect(() => {
    getVehicleList();
    getAssetsCountInfo();
    getGeofenceDetailOfAllVehicle();
    deleteGeofence();
  }, []);

  useEffect(() => {
    getUserType();
  }, [userTypeValue]);

  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);

        console.log("USERTYPE", userTypeValue);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  //DELETE GEOFENCE:
  const deleteGeofence = async () => {
    if (!selectedItem) {
      return;
    }

    try {
      const response = await MainService.delGeofence(username, selectedItem);
      console.log(response, "DELETE GEOFENCE:::::::::::::")
      NotificationManager.success("Geofence deleted successfully");
      // Refresh the list to reflect deletion
      getGeofenceDetailOfAllVehicle();
    } catch (e) {
      NotificationManager.error(e.message || "Error deleting geofence");
    }
  };


  const handleDelete = () => {
    
      deleteGeofence();
  };


  //get geofence details of all the vehicle:
  const getGeofenceDetailOfAllVehicle = async () => {
    try {
      const response = await MainService.geofenceDetailOfAllVehicle(username);
      console.log(response, "ALL GEOFENCE DETAILS::::::");
      setGeofenceDetails(response);
    } catch (e) {
      NotificationManager.error(e.message || "Error fetching geofence details");
    }
  };

  //get vehicle list:
  const getVehicleList = async () => {
    const { assets } = await MainService.getVehicleList(username);

    const assetsWithMappedDevice = assets.map((asset) => {
      const mappedDevice =
        asset.points.length > 0 ? asset.points[0].endnode : "N/A";
      return {
        ...asset,
        coordinates: `Lat: ${asset.latitude}, Lng: ${asset.longitude}`,
        mappedDevice: mappedDevice,
      };
    });

    const filteredAssets = searchText
      ? assetsWithMappedDevice.filter((asset) =>
          asset.assetId.includes(searchText)
        )
      : assetsWithMappedDevice;

    setVehicleDetails({ assets: filteredAssets });
  };

  const onSelect = (item) => {
    setSelectedItem((prevSelectedItem) =>
      prevSelectedItem === item ? "" : item
    );
  };

  // Filter geofence data based on search text
  const filteredGeofenceDetails = geofenceDetails.filter((detail) =>
    detail.vehicleId.includes(searchText)
  );

  const handleClick = () => {
    // if (props.isFleet) {
    //   console.log("clickeeeeeeeeingggggggg", selectedItem);
    //   props.showGeofenceDetailsHandler(true);
    //   props.setSelectedItem(selectedItem);
    // } else {
      props.history.push(`/main/updateGeofence/${selectedItem}`);
    // }
  };

  const getAssetsCountInfo = async () => {
    const { assetCount } = await MainService.getAssetsCountInfo(username);
    setAssetCount(assetCount);
    console.log(assetCount, ".....assetCount...........");
  };

  return (
    <>
      {/* {userTypeValue === "LogisticsProvider" && ( */}
      <div className="container" id="mainContentList">
      <Paper 
      elevation={3} 
      sx={{
        padding: '20px', 
        backgroundColor: '#f5f5f5', 
        borderRadius: '8px', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography 
        variant="h4" 
        sx={{
          color: '#333', 
          fontWeight: 'bold', 
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', 
        }}
      >
        Geofenced Vehicles Overview
      </Typography>
    </Paper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <SearchBar
          placeholder={"Search Vehicle Id"}
          onSearch={(text) => setSearchText(text)}
        />

        <Box
          sx={{
            display: "flex",
            marginLeft: "auto",
            gap: "1rem", 
          }}
        >
          <Button
            variant="contained"
            onClick={handleClick}
            disabled={!selectedItem}
          >
            UPDATE
          </Button>
          

          <Button
            variant="contained"
      onClick={handleDelete}
            
            disabled={!selectedItem}
          >
            DELETE
          </Button>
        </Box>
      </Box>


{/* .....................MATTABLE..................... */}
        <Box sx={{ marginTop: "1rem" }}>
          <MatTable
            fields={geofenceFields}
            rows={filteredGeofenceDetails}
            keys={["realm", "tenantId", "vehicleId", "fencingType"]}
            // total={assetCount.totalCount}
            unique="vehicleId"
            onSelect={onSelect}
            change_page={getVehicleList}
            selected={selectedItem}
          />
        </Box>
      </div>
    </>
  );
};

export default UpdatedGeofenceDetails;
