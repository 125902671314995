import React from "react";
import HoneyAsset from "../../images/installation-bee-hives-new-location.jpg";
import Community from "../../images/test3.jpg";
import FieldSupport from "../../images/young-agronomist-analyzing-field-with-farmer-indian-farming.jpg";
import ProcessingSupport from "../../images/processingService/banner.jpg"
import { NavLink } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

export default function FarmerServices() {
  const services = [
    // {
    //   title: "Community",
    //   image: Community,
    //   href: "/Community",
    // },

    {
      title: "Field Support",
      image: FieldSupport,
      href: "/Field-Support",
    },
    {
      title: "Business Growth Service",
      image: ProcessingSupport,
      href: "/GrowthService",
      customPropValue : "Grower"
    },
   
  ];

  return (
    <div className="container for-farmer-section2 subSectionWrapper">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
           const navLinkProps = {
            to: { pathname: e.href },
          };
  
          // Add customPropValue to NavLink props if it exists
          if (e.customPropValue) {
            navLinkProps.to.state = { customProp: e.customPropValue };
          }
          return (
            <Col md={5}>
              <NavLink {...navLinkProps}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-0 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

