import React from "react";
import AFE from "../../../images/young-smiling-manager.jpg";
import FE from "../../../images/AAMC.jpg"
import { NavLink } from "react-router-dom";
import FinancialSupport from "../../../images/IMA.jpg";
import { Container } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import AD from "../../../images/businessman-shaking-hands-each.jpg";
import CBO from "../../../images/top-view-diverse-people-hands-holding-together-circle-hands-stack-min.jpg"

import TitleDescription from "../../TitleDescription/TitleDescription";
import AgribusinessMainSection from "../../AgribusinessMain/AgribusinessMainSection";
// import storage from "../../../images/metal-elevator-grain-silo-agriculture-zone-grain-warehouse-depository-is-important-part-harvesting-sorn-wheat-other-crops-are-stored-it.jpg"
export default function MSMEServices() {
  const services = [
    // {
    //   title: "Community",
    //   image: Community,
    //   href: "/Community",
    // },

   
    {
      title: "Farm Enterprise",
      image: FE,
      href: "/ForAAMC",
      // customPropValue : "Grower",
      data: "A farm enterprise, also known as a farming enterprise, is an agribusiness that is primarily engaged in agricultural activities. This includes the cultivation of crops, the raising of livestock, and other related farming practices. We help farm enterprises to become Agri Asset Management Company so that they can assist clients, which can include investors, agri-producers, or agricultural businesses, in effectively managing and generating returns from their agricultural assets."
    },
    {
      title: " Food Processing Enterprises      ",
      image: AFE,
      href: "/food-processing-enterprises",
      data:"Food Processing Enterprises are companies involved in processing agricultural products into consumable goods, playing a crucial role in the production and transformation of raw materials such as crops and livestock into various food products. Examples include those producing packaged foods, beverages, meat, dairy, and processed agricultural goods.      "
    },
    {
      title: "Agro-Dealers      ",
      image: AD,
      href: "/agro-dealers",
      // customPropValue: "farming",
      data:"Agro-dealers serve as vital intermediaries, connecting agri-retailers and farmers with crucial agricultural inputs and services at the local level. They offer a range of resources, including seeds, fertilizers, and pesticides, while also providing technical support, credit facilities, and market access. Through these services, agro-dealers contribute significantly to improving agricultural productivity, empowering farmers with the necessary tools and knowledge for sustainable and profitable farming practices.      "

    },


    {
      title: "CBO",
      image: CBO,
      href: "/CBO",
      data:"Community-based organizations (CBOs) in are grassroots groups formed by individuals within a community to collectively address agricultural challenges and enhance rural livelihoods. These organizations play a pivotal role in promoting sustainable farming practices, improving access to markets and resources, and strengthening community ties. By fostering collaboration and knowledge-sharing, CBOs contribute to the overall development of agriculture, empower small-scale farmers, and address specific issues in farming, thereby promoting resilient and inclusive agricultural systems at the local level.      "

    },
  ];

  return (
    <>
    <Container className="container for-farmer-section2 px-0  subSectionWrapper">
 


      <TitleDescription
        classname="mb-0 mt-0 pb-0"
        classname2=""
          title="Who are a Core Agri-food businesses"
          description="Core Agri-food business refers to the fundamental elements within the agricultural industry, including entities engaged in farming, food processing, distribution, and related activities. This term encompasses leading corporations, organizations advocating for agricultural interests, and academic disciplines focused on agricultural economics and management. The concept of Core Agri-food business underscores the essential components driving innovation, sustainability, and productivity within the agricultural sector, vital for meeting global food demand and shaping agricultural policy and research agendas."
        />
            </Container>

<div className="mb-5 ">
<AgribusinessMainSection/>
</div>
      {/* <Row className="justify-content-center ">
        {services.map((e) => {
           const navLinkProps = {
            to: { pathname: e.href },
          };
  
          // Add customPropValue to NavLink props if it exists
          if (e.customPropValue) {
            navLinkProps.to.state = { customProp: e.customPropValue };
          }
          return (
            
            <Col md={5} className="mb-4">
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-decoration-none  container-heading1 mt-0 mb-4 text-decoration-none">
                  {e.title}
                </h3>

                <p className="mb-0 container-para
"> {e.data} </p>
                  <NavLink to={e.href}  className="text-white">
                    <Button
                      variant="contained"
                      color="primary"
                      className="mt-3  mb-4"
                    >
                     Explore
                    </Button>
                  </NavLink>
            </Col>
          );
        })}
      </Row> */}
      </>
  );
}

