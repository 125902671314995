import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React, { Fragment, memo, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";

function CreateContentSubCategory({
  reLoad,
  userName,
  categoryId,
  categoryName,
  parentID,
  onOpen,
  onClose,
}) {
  const [subCategoryName, setSubCategoryName] = useState("");

  const submitSubCategoryClick = () => {
    if (!subCategoryName) {
      NotificationManager.warning("Please enter Sub-Category Name");
      return;
    }
    let subCategoryData = {
      categoryid: categoryId,
      categoryname: categoryName,
      contentsubcategoryname: subCategoryName,
      parentid: parentID,
      contentsubcategorycreator: userName,
    };

    const subCategoryDataString = JSON.stringify(subCategoryData);
    MainService.createContentSubCategory(subCategoryDataString)
      .then((res) => {
        reLoad();
        setSubCategoryName("");
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err || "something went wrong");
      });
    onClose();
  };

  return (
    <Fragment>
      <Dialog
        open={onOpen}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Create Sub Category"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Asset Developer Id"
              id="assetdeveloperid"
              name="assetdeveloperid"
              type="text"
              value={userName}
              disabled
            />
          </FormControl>
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Category"
              id="category"
              name="category"
              type="text"
              value={categoryId}
              disabled
            />
          </FormControl>
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Enter Sub Category"
              id="subcat"
              name="subcat"
              type="text"
              value={subCategoryName}
              onChange={(e) => {
                setSubCategoryName(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={submitSubCategoryClick}
          >
            Create
          </Button>
          <Button color="error" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default memo(CreateContentSubCategory);
