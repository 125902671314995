import React from 'react'
import AboutList from './AboutList'

export default function ProductionService() {
    const data=[
  "D2ConMarketplace:  D2ConMarketplace is a marketplace provided by CMN Network for buying and selling processed agricultural products to a wider consumer."  ,
"Social CMonMarketplace:   Social CMonMarketplace is a platform provided by CMN Network that integrates social features for food processing enterprises and brands, facilitating interaction and commerce within the agricultural community.",
// "AgroCare Marketplace:  Agrocare Marketplace, provided by CMN Network, offers a range of animal and plant care products, catering to the needs of agricultural businesses and enthusiasts.",
"Social iaaMarketplace: Social iaaMarketplace, provided by CMN Network, is a platform designed for food processing enterprises, institutional lenders and retail investors to interact, collaborate, and conduct transactions within the agricultural sector.",
      "B2BonMarketplace: B2BonMarketplace is a B2B platform provided by the CMN network, enabling b2b buyers  and food processing enterprise to trade processed products efficiently.",
      "ProcessingMachinery Marketplace: ProcessingMachinery Marketplace is a platform offered by the CMN network, connecting food processing enterprises with processing machinery providers for loanable machinery",

    ]
  return (
   <AboutList title="Marketplace" contentlist={data}/>
  )
}