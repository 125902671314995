import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@mui/material";
import sha256 from "sha256";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

export default function UserTypesComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const [registerUserRequestsList, setRegisterUserRequestsList] = useState([]);

  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [userTypeValue, setUserTypeValue] = React.useState("");

  const [consumerUserDialog, setConsumerUserDialog] = React.useState(false);

  const [selectedUserType, setSelectedUserType] = useState("");
  const [consumerUserDeleteDialog, setConsumerUserDeleteDialog] =
    useState(false);
  const [createUserTypeDialog, setCreateUserTypeDialog] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [
    filteredRegisterUserRequestsList,
    setFilteredRegisterUserRequestsList,
  ] = useState([]);
  const [loginUserUserTypeValue, setLoginUserUserTypeValue] = useState("");
  const [searched, setSearched] = useState("");
  const [listRequetsView, setListRequetsView] = useState(false);
  const [gridRequetsView, setGridRequetsView] = useState(true);
  const [userTypeError, setUserTypeError] = useState(false);

  useEffect(() => {
    getAllUserTypesOfUser();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* view All Register Account Requests */
  const getAllUserTypesOfUser = () => {
    setIsLoading(true);
    MainService.getAllUserTypes(parentId, username)
      .then((res) => {
        if (res.length > 0) {
          setRegisterUserRequestsList(res);
          setFilteredRegisterUserRequestsList(res);
          setIsLoading(false);
        } else {
          setRegisterUserRequestsList([]);
          setFilteredRegisterUserRequestsList([]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End of All Register Account Requests */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  /* consumer IAM */
  const handleUserTypeValueChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, ''); // Regex to remove whitespace characters
    setUserTypeValue(sanitizedValue);
    setUserTypeError(value !== sanitizedValue ? true : false);
   };
  const createconsumerUsersubmit = () => {
    if(userTypeValue)
    {
      let consumerUserData = {
        id: username,
        usertype: userTypeValue,
      };
      const consumerUserDataString = JSON.stringify(consumerUserData);
      setIsLoading(true);
      MainService.createUserType(consumerUserDataString, username)
        .then((res) => {
          setIsLoading(false);
          getAllUserTypesOfUser();
          return NotificationManager.success("usertype created Successfully");
        })
        .catch((err) => {
          setIsLoading(false);
          return NotificationManager.error(err);
        });
  
    }else{
      return NotificationManager.error("please provide valid usertype");
    }
    
    setCreateUserTypeDialog(!createUserTypeDialog);
  };

  const deleteDialogClick = (usertype) => {
    setSelectedUserType(usertype);

    setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
  };
  const deleteSubmitClick = () => {
    let data = {usertype:selectedUserType}
    const stringifyedData = JSON.stringify(data);
    MainService.deleteUserType(username, selectedUserType,stringifyedData )
      .then((res) => {
        getAllUserTypesOfUser();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
  };

  const listUsersViewClick = () => {
    setListRequetsView(true);
    setGridRequetsView(false);
  };
  const gridRequetsViewClick = () => {
    setListRequetsView(false);
    setGridRequetsView(true);
  };

  const requestSearchForUserRequests = (searchedVal) => {
    const filteredRows = filteredRegisterUserRequestsList.filter((row) => {
      return row.usertype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRegisterUserRequestsList(filteredRows);
  };
  const cancelSearchForUserRequests = () => {
    setSearched("");
    requestSearchForUserRequests(searched);
  };

  return (
    <div className="container" id="mainContentList">
      <Box sx={{ width: "100%", marginBottom: 30 }}>
        <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Create UserType"
          className={classes.fab}
          onClick={() => {
            setCreateUserTypeDialog(!createUserTypeDialog);
          }}
        >
          <AddIcon></AddIcon>
        </Fab>
        {(listRequetsView === true || gridRequetsView === true) && (
          <Grid>
            <div style={{ float: "right" }}>
              <Button
                startIcon={<ListIcon />}
                size="large"
                onClick={listUsersViewClick}
              ></Button>
              <Button
                startIcon={<GridIcon />}
                size="large"
                onClick={gridRequetsViewClick}
              ></Button>
            </div>
          </Grid>
        )}

        <br />
        <Grid container spacing={1}>
          {listRequetsView && (
            <Paper sx={{ width: "100%" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal) =>
                  requestSearchForUserRequests(searchVal)
                }
                onCancelSearch={() => cancelSearchForUserRequests()}
              />
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell
                        className="headerFontSize"
                        align="center"
                        colSpan={3}
                      >
                        List of UserTypes
                      </TableCell>
                    </TableRow>
                    <TableRow align="center">
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Parent Id
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        User Type
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registerUserRequestsList.length > 0 ? (
                      registerUserRequestsList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((consumerUsers, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {consumerUsers.id}
                              </TableCell>
                              <TableCell align="center">
                                {consumerUsers.usertype}
                              </TableCell>

                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    deleteDialogClick(consumerUsers.usertype);
                                  }}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={3}>
                          UserTypes Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={registerUserRequestsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          {gridRequetsView && (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div>
                    <SearchBar
                      value={searched}
                      onChange={(searchVal) =>
                        requestSearchForUserRequests(searchVal)
                      }
                      onCancelSearch={() => cancelSearchForUserRequests()}
                    />
                    <h4 style={{ textAlign: "center" }}>List of UserTypes</h4>
                  </div>
                  <br />
                  <div>
                    {registerUserRequestsList.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {registerUserRequestsList.map(
                          (consumerUsers, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Parent Id:</b>
                                  {consumerUsers.id}
                                </p>
                              </span>
                              <p>
                                <b>User Type:</b>
                                {consumerUsers.usertype}
                              </p>
                              &nbsp;&nbsp;
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  deleteDialogClick(consumerUsers.usertype);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <h4 style={{ textAlign: "center" }}>
                        UserTypes not found.
                      </h4>
                    )}
                  </div>
                </>
              )}
            </Box>
          )}
        </Grid>
      </Box>

      {/* create createUserTypeDialog */}
      <Dialog
        fullScreen={fullScreen}
        open={createUserTypeDialog}
        onClose={handleClose}
        aria-labelledby="craeteUserType"
      >
        <DialogTitle id="craeteUserType">{"Create UserType"}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="username">Creator Name * :</InputLabel>
            <Input
              disabled
              id="username"
              name="username"
              type="email"
              value={username}
            />
          </FormControl>

          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="email">UserType:*</InputLabel>
            <Input
              id="usertype"
              name="usertype"
              type="text"
              value={userTypeValue}
              onChange={(e) => handleUserTypeValueChange(e)}
               
            />
            {userTypeError && <span style={{ color: 'red' }}>Space not allowed.</span>}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              createconsumerUsersubmit();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setCreateUserTypeDialog(!createUserTypeDialog);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete */}
      <Dialog
        fullScreen={fullScreen}
        open={consumerUserDeleteDialog}
        onClose={handleClose}
        aria-labelledby="deleteusertype"
      >
        <DialogTitle id="deleteusertype">{"Delete  Usertype"}</DialogTitle>
        <DialogContent>
          <h4>Are you sure want to delete?</h4>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              deleteSubmitClick();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setConsumerUserDeleteDialog(!consumerUserDeleteDialog);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
