import React from 'react';
import Icon1 from "../../images/ServiceIcons/bee-box.svg";
import Icon2 from "../../images/ServiceIcons/tractor (1).svg";
import Icon3 from "../../images/ServiceIcons/veterinarian.svg";
import Icon4 from "../../images/ServiceIcons/service.svg";
import Icon5 from "../../images/ServiceIcons/ecology.svg";
import Icon6 from "../../images/ServiceIcons/supply-chain-management.svg";

import OurServiceCardContainer from './OurServiceCardContainer';



export default function CBNServices() {
    const CBNServices = [
   
        // {
        //   image: Icon1,
        //   title: " CBN-Agri Asset ",
        //   bodycopy:
        //     "Facilitate Agri asset distribution service for producers .",
        // },
       


        // {
        //   image: Icon2,
        //   title: " CBN-AgriMachinery ",
        //   bodycopy:
        //     "Facilitate machinery asset distribution service for producers.",
        // },


        {
          image: Icon3,
          title: "CBN-AH/Agri-Clinic ",
          bodycopy:
            " Facilitate animal and plant care through farmer advisors and nutrition distributors. "
        },
     


        {
            image: Icon4,
            title: "CBN-CBO",
            bodycopy:
              "Create agri-producers club for Organic certification and local trade (buy & sell)."
          },

          {
            image: Icon5,
            title: "CBN-Organic input",
            bodycopy:
              "Easy access for agri-producers to buy organic feed from the approved organic feed providers."
          },
          {
            image: Icon6,
            title: "CBN-VCN (Local supply chain)",
            bodycopy:
              "Most efficient value chain service to buy from agri-producers and traders to create differentiated product."
          },

          {
            image: Icon6,
            title: "FFS Service",
            bodycopy:
              "CBN offers Farmer Facilitation Service (FFS) to streamline agricultural processes and support farmers, enhancing efficiency and productivity in farming practices."
          }, {
            image: Icon6,
            title: "Care Services (animal and plant care )",
            bodycopy:
              "CBN offers comprehensive Care Services for animals and plants, ensuring their well-being and optimal growth through specialized care and attention."
          }, {
            image: Icon6,
            title: "Distribution Service",
            bodycopy:
              "Facilitate asset distribution services for agricultural producers, encompassing both agricultural and machinery assets."
          }, {
            image: Icon6,
            title: "Asset Market Services",
            bodycopy:
              "CBN delivers Asset Market Services, facilitating efficient transactions and market operations for various assets, optimizing investment opportunities and liquidity."
          }, 

      ];
  return (
    <div className='main-wrapper2'>
    <OurServiceCardContainer services={CBNServices}  title="CBN Services for Agri-producers"/>
    </div>
  )
}
