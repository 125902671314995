export const subCategorieValidation = (name, value) => {
  if (value === "subcategory") {
    if (!name) {
      return "Required";
    } else if (name.length < 3) {
      return "Min length required(3)";
    }
    return null;
  }
};
