import React, { Component } from "react";
import "./PriceDiscovery.css";
import ReactPaginate from "react-paginate";
import $, { event } from "jquery";
import OutsideClickHandler from "react-outside-click-handler";
import { Row, Col } from "react-bootstrap";
import { FiThumbsUp } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { Container } from "@mui/material";

class PriceDiscovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenders: [],
      tendersFilter: [],
      offset: 0,
      tensdersPageCount: 1,
      perPage: 3,
      currentPage: 0,
      checked: false,
      postCategoryIdVlaue: "",
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      subCategoriesList: [],
      copyOfTenders: [],
      contentOwners: [],

    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.postCategoryIdValue();
  }

  postCategoryIdValue() {
    fetch(
      `${window.appConfig.getContentCategories}${window.appConfig.Relam}${window.appConfig.Relam}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        const postid = result.categoryList.filter((data) => {
          return data.categoryname === "PRICE-DISCOVERY";
        });
        this.viewAllPriceDiscovery(postid[0].categoryid);
        this.setState({
          postCategoryIdVlaue: postid[0].categoryid,
        });
        this.adminsDropdownClick(postid[0].categoryid);
        this.getCategorySubcategories(postid[0].categoryid);
        this.ownerDropdownClick(postid[0].categoryid);

      })
      .catch((err) => console.log("err", err));
  }

  viewAllPriceDiscovery(postid) {
    fetch(
      `${window.appConfig.getContentList}${window.appConfig.Relam}category/${postid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          tenders: result.contentList,
          copyOfTenders: result.contentList,
          tensdersPageCount: Math.ceil(
            result.contentList.length / this.state.perPage
          ),
        });
      });
  }

  adminsDropdownClick(catID) {
    fetch(
      // `${window.appConfig.getAllContentOwnersBasedOnCategory}${catID}`,
      `${window.appConfig.getContentCreator}${window.appConfig.Relam}contentcreators/${catID}`,
       {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.InitialAccessToken,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const unique = [
          ...new Map(
            result.contentcreatorsList.map((data) => [data.contentcreatorid, data])
          ).values(),
        ];
        this.setState({
          tendersFilter: unique,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  organizationsDropdownClick() {
    $("#dropdownPrice").toggle();
  }

  organizationsOnClick(Creator) {
    fetch(
      `${window.appConfig.getContentonContentCreator}${window.appConfig.Relam}contentcreator/${Creator}/${this.state.postCategoryIdVlaue}`,

      // `${window.appConfig.getContentList}${window.appConfig.Relam}contentowner/${Owner}/${this.state.postCategoryIdVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          tenders: result.contentList,
          tensdersPageCount: Math.ceil(
            result.contentList.length / this.state.perPage
          ),
        });
      })
      .catch((err) => console.log("err", err));
    $("#dropdownPrice").hide();
  }

  getCategorySubcategories(catID) {
    fetch(
      `${window.appConfig.getSubCategoriesBasedonCategoryID}${window.appConfig.Relam}contentSubCategories/${catID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          subCategoriesList: result.contentsubcategories,
        });
      })
      .catch((err) => console.log("err", err));
  }

  subCategoriesDropdownClick() {
    $("#subCategoriesOfPrice").toggle();
  }

  subCategoriesOnClick(subCategory) {
    const subCategoriesOfAdsFilter = this.state.copyOfTenders.filter(
      (data, index) => {
        return data.contentsubcategory
          .toLowerCase()
          .includes(subCategory.toLowerCase());
      }
    );
    this.setState({
      tenders: subCategoriesOfAdsFilter,
      tensdersPageCount: Math.ceil(
        subCategoriesOfAdsFilter.length / this.state.perPage
      ),
    });
    $("#subCategoriesOfPrice").hide();
  }


  // Content Owner
  ownerDropdownClick(catID) {
    fetch(
      `${window.appConfig.getAllContentOwnersBasedOnCategory}${catID}`, 
      // `${window.appConfig.getContentCreator}${window.appConfig.Relam}contentcreators/${catID}`,
      {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.InitialAccessToken,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        
        console.log(result, "qwertyuio1598598489489498494888o")
        const unique = [
          ...new Map(
            result.contentownerList.map((data) => [data.contentowner, data])
          ).values(),
        ];
        this.setState({
          contentOwners: unique,
        });
      })
      .catch((err) => console.log("err", err));
  };

  contentOwnerDropdownClick(){
    $("#ContentOwnerOfPrice").toggle();
  };

  getContentOwnerContent(Owner) {
    fetch(
      // `${window.appConfig.getContentonContentCreator}${window.appConfig.Relam}contentcreator/${Creator}/${this.state.postCategoryIdVlaue}`,
      `${window.appConfig.getContentList}${window.appConfig.Relam}contentowner/${Owner}/${this.state.postCategoryIdVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "setstarrrrrrrrrrrrrrrrrr")
        this.setState({
          tenders: result.contentList,
          tensdersPageCount: Math.ceil(
            result.contentList.length / this.state.perPage
          ),
        });
      })
      .catch((err) => console.log("err", err));
    $("#dropdownOrganizations").hide();
  };

  // Content Owner End
  likeCount(contentid) {
    fetch(
      `${window.appConfig.contentLikeCount}${window.appConfig.Relam}${contentid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.text())
      .then((result) => {
        this.postCategoryIdValue();
        return NotificationManager.success(result);
      })
      .catch((err) => console.log("err", err));
  }
  /*Pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {}
    );
  };
  /*End of pagination */

  render() {
    const { tenders, subCategoriesList, contentOwners } = this.state;
    const priceDiscoveryList = tenders.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    return (
      <Container>
      <div
        id="tendersContainer"
        className="mb-lg-5 mb-4 mt-4 gbr-engage-container container-fluid"
      >
        <h3 class="container-title">Market Prices </h3>

        <div className="mb-4">
          <div className="dropdown">
            <div className="navbarRight">
              <button
                class="btn btn-success homepageBtn"
                type="button"
                onClick={() =>
                  this.viewAllPriceDiscovery(this.state.postCategoryIdVlaue)
                }
              >
                All Price Discovery's
              </button>
            </div>
          </div>
          <div className="dropdown">
            <div class="dropdown homepage-nav-item">
              <div className="navbarRight">
                <button
                  class="btn btn-success homepageBtn"
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.organizationsDropdownClick()}
                >
                  Price Publisher
                </button>
              </div>
              <div
                class="dropdown-menu"
                id="dropdownPrice"
                aria-labelledby="dropdownPrice"
              >
                {this.state.tendersFilter &&
                  this.state.tendersFilter.map((filter, i) => (
                    <li>
                      <a
                        className="dropdown-item"
                        key={i}
                        role="button"
                        onClick={() =>
                          this.organizationsOnClick(filter.contentcreatorid)
                        }
                      >
                        {filter.contentcreatorid}
                      </a>
                    </li>
                  ))}
              </div>
            </div>
          </div>
          <div className="dropdown">
            <div class="dropdown homepage-nav-item">
              <div className="navbarRight">
                <button
                  class="btn btn-success homepageBtn"
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.subCategoriesDropdownClick()}
                >
                  Sub Categories
                </button>
              </div>
              <div
                class="dropdown-menu"
                id="subCategoriesOfPrice"
                aria-labelledby="subCategoriesOfPrice"
              >
                {subCategoriesList &&
                  subCategoriesList.map((filter, i) => (
                    <li>
                      <a
                        className="dropdown-item"
                        key={i}
                        role="button"
                        onClick={() =>
                          this.subCategoriesOnClick(
                            filter.contentsubcategoryname
                          )
                        }
                      >
                        {filter.contentsubcategoryname}
                      </a>
                    </li>
                  ))}
              </div>
            </div>
          </div>
          <div className="dropdown">
            <div class="dropdown homepage-nav-item">
              <div className="navbarRight">
                <button
                  class="btn btn-success homepageBtn"
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.contentOwnerDropdownClick()}
                >
                  Markets
                </button>
              </div>
              <div
                class="dropdown-menu"
                id="ContentOwnerOfPrice"
                aria-labelledby="ContentOwnerOfPrice"
              >
                {contentOwners &&
                  contentOwners.map((filter, i) => (
                    <li>
                      <a
                        className="dropdown-item"
                        key={i}
                        role="button"
                        onClick={() =>
                          this.getContentOwnerContent(
                            filter.contentowner
                          )
                        }
                      >
                        {filter.contentowner}
                      </a>
                    </li>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="adminTendersDiv">
          {/* View All priceDiscoverys */}
          {priceDiscoveryList.length !== 0 ? (
            <Row className="justify-content-center">
              {/* {priceDiscoveryList.map((tend, index) => (
                <div className="videosWrapper">
                  <div class="card cardContent w-100 m-0">
                    <div class="card-body card-body-wrapper ">
                      <p>
                        <b>Assetdeveloperid: </b> {tend.parentid}
                      </p>
                      <p><b>Content Id: </b>{tend.contentid}</p>
                      <p><b>Category Id: </b>{tend.categoryid}</p>
                      <p><b>Content Uploader: </b>{tend.contentcreator}</p>
                      <p><b>Category: </b>{tend.categoryname}</p>
                      <p><b>Organization: </b>{tend.contentowner}</p>
                      <p><b>Sub Categoryname: </b>{tend.contentsubcategory}</p>
                      <h6>{tend.contentdescription}</h6>
                      
                      <a
                        className="downloadDocument"
                        href={tend.locationurl}
                        download
                      >
                        Download Document
                      </a>
                    </div>
                  </div>
                </div>
              ))} */}

              {priceDiscoveryList.map((tend, index) => (
                <Col md={6} lg={4} sm={12} xs={12} className="mb-4">
                  <div class="community-cards w-100">
                    <div className="bordered-div"></div>
                    <div className="pb-2">
                      <div className="scrollable-container">
                        <div class="community-card-body">
                          {/* <div className="mt-2 mb-2">
                            <span className="d-block">
                              {tend.contentsubcategory}
                            </span>
                            <span>{tend.timestamp}</span>
                          </div>
                        <h2 className="mb-2">Posted by {tend.contentowner}</h2>
                        <h6 className="mb-3">{tend.contentdescription}</h6> */}

                          <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
                            <span className=" d-block ">
                              {tend.contentsubcategory}
                            </span>
                            <p className="products-like mb-0 d-flex align-items-end">
                              <span
                                className="d-inline-block"
                                onClick={() => {
                                  this.likeCount(tend.contentid);
                                }}
                              >
                                <FiThumbsUp size={30} />
                              </span>
                              <small>{tend.likecount}</small>
                            </p>
                          </div>

                          <h2 className="mb-0">{tend.contentname}</h2>

                          <h5 className="mb-2">
                            Posted by <span>{tend.contentcreatorid}</span>,{" "}
                            {tend.timestamp}
                          </h5>
                          <a className="text-decoration-none" href={tend.texturl} target="_blank"><h6 className="mb-3">{tend.contentdescription}</h6>
                          </a>  
                          {/* <p>
                          <b>Assetdeveloperid:</b> {tend.parentid}
                        </p>
                        <p>
                          <b>Content Id:</b> {tend.contentid}
                        </p>
                        <p>
                          <b>Category Id:</b> {tend.categoryid}
                        </p> */}
                          {/* <p>
                            <b>Content Uploader:</b> {tend.contentowner}
                          </p> */}
                          {/* <p>
                        <b>Category:</b> {tend.categoryname}
                      </p> */}
                          {/* <p>
                        <b>Organization:</b> {tend.contentowner}
                      </p> */}
                          {/* <p>
                          <b>Sub Categoryname:</b> {tend.contentsubcategory}
                        </p> */}
                          {/* <h6>{tend.contentdescription}</h6> */}

                          {/* <a
                        className="downloadDocument"
                        href={tend.locationurl}
                        download
                      >
                        Download Document
                      </a> */}
                        </div>
                      </div>
                    </div>
                    <div className="community-button-container p-3 px-0 text-center mt-2">
                      <a
                        className={`${
                          tend.locationurl
                            ? "downloadDocument mt-0 "
                            : "downloadDocument mt-0 disabledBtn"
                        }   `}
                        href={tend.locationurl}
                        download
                      >
                        Download Document
                      </a>
                    </div>
                  </div>
                </Col>
              ))}
              <div className="paginationComponent">
                <ReactPaginate
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.tensdersPageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </Row>
          ) : (
            <div className="card">
              <p className="card-body container-heading2">
                <b>Price Discovery's Not Found</b>
              </p>
            </div>
          )}
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            $("#dropdownPrice").hide();
            $("#subCategoriesOfPrice").hide();
            $("#ContentOwnerOfPrice").hide();
          }}
        ></OutsideClickHandler>
      </div>
      </Container>
    );
  }
}

export default PriceDiscovery;
