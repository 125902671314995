import React from "react";
import PmcFmcMachinery from "../PmcFmcMachinery/PmcFmcMachinery";

export default function FmcMachinery() {
  return (
    <>
      <PmcFmcMachinery categoryType="PROCESSING-MACHINERY" compType="PMC" />
    </>
  );
}
