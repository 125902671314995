import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MaterialTable from "material-table";

import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 250,
  },
  gridRoot: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function ComplaintPageComponent() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    columns: [
      { title: "Id", field: "operatorid" },
      { title: "Complaint", field: "complaintdescription" },
    ],
    data: [],
  });

  const [growerIdList, setGrowerIdList] = useState([]);
  const [selectedGrowserId, setSelectedGrowserId] = useState('');
  const [siteList, setSiteList] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');

  useEffect(() => {
    getGrowerIdList();
  }, []);

  useEffect(() => {
    if(!selectedGrowserId) return;
    const getSitesList = () => {
      MainService.getSitesList(selectedGrowserId)
        .then((data) => {
          if (data.length > 0) {
            setSelectedSite(data[0].siteid);
          }
          setSiteList(data);
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    };
    getSitesList();
  }, [selectedGrowserId]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedSite) return;
    const getSiteComplaints = () => {
      MainService.getSiteComplaints(selectedGrowserId, selectedSite)
        .then((data) => {
          setState({ ...state, data });
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    };
    getSiteComplaints();
  }, [selectedSite]);// eslint-disable-line react-hooks/exhaustive-deps

  const getGrowerIdList = () => {
    MainService.getAllSolutions()
      .then((data) => {
        if (data.length > 0) {
          setSelectedGrowserId(data[0].solutionid);
        }
        setGrowerIdList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  

  

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <span style={{ display: "inlineBlock", float: "right" }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              style={{
                transform: "translate(14px, -6px) scale(0.75)",
                color: "rgb(43, 144, 99)",
              }}
            >
              Select Site
            </InputLabel>
            <Select
              id="selectedSite"
              value={selectedSite}
              onChange={(event) => setSelectedSite(event.target.value)}
              label="Select Grower"
            >
              {siteList.map((idObj) => (
                <MenuItem key={idObj.siteid} value={idObj.siteid}>
                  {idObj.sitename}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
        <span style={{ display: "inlineBlock", float: "right" }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              style={{
                transform: "translate(14px, -6px) scale(0.75)",
                color: "rgb(43, 144, 99)",
              }}
            >
              Select Grower
            </InputLabel>
            <Select
              id="selectedGrowserId"
              value={selectedGrowserId}
              onChange={(event) => setSelectedGrowserId(event.target.value)}
              label="Select Grower"
            >
              {growerIdList.map((idObj) => (
                <MenuItem key={idObj.solutionid} value={idObj.solutionid}>
                  {idObj.solutionname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
      </Grid>
      <Grid item md={12}>
        <MaterialTable
          title="Complaint"
          columns={state.columns}
          data={state.data}
          editable={{
            // onRowAdd: (newData) =>
            //   new Promise((resolve) => {
            //     setTimeout(() => {
            //       resolve();
            //       setState((prevState) => {
            //         const data = [...prevState.data];
            //         data.push(newData);
            //         return { ...prevState, data };
            //       });
            //     }, 600);
            //   }),
            // onRowUpdate: (newData, oldData) =>
            //   new Promise((resolve) => {
            //     setTimeout(() => {
            //       resolve();
            //       if (oldData) {
            //         setState((prevState) => {
            //           const data = [...prevState.data];
            //           data[data.indexOf(oldData)] = newData;
            //           return { ...prevState, data };
            //         });
            //       }
            //     }, 600);
            //   }),
            // onRowDelete: (oldData) =>
            //   new Promise((resolve) => {
            //     setTimeout(() => {
            //       resolve();
            //       setState((prevState) => {
            //         const data = [...prevState.data];
            //         data.splice(data.indexOf(oldData), 1);
            //         return { ...prevState, data };
            //       });
            //     }, 600);
            //   }),
          }}
        />
      </Grid>
    </Grid>
  );
}
