import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row, Container, Collapse, Fade,
  Pagination, PaginationItem, PaginationLink,
  Badge,
  Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../style.css';
import './Layout.css';
import AuthAxiosInstance from '../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance, SecurityAxiosInstance } from '../../../authAxiosInstance'

import socketIOClients from "socket.io-client";
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { NotificationManager } from "react-notifications";

let prev = 0;
let next = 0;
let last = 0;
let first = 0;

var jsonconfig = window.appConfig;

class KeyManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devicetypelist: [],
      disabledstate: true,
      primary: false,
      danger: false,
      viewKeyModell: false,
      deletedata: {},
      profiledata: [],
      deviceuploaddata: [],
      editdevicedetails: [],
      devicelist: [],
      odevicelist: [],
      currentpageno: 1,
      pageno: 1,
      pagesize: 5,
      searchkey: '',
      totaldevicecount: '',
      devicelisttypeid: 'all',
      accordion: [true],
      currentlist: [],
      typelist: [],
      modellist: [],
      viewData: ''
    };

    this.togglePrimary = this.togglePrimary.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleLastClick = this.handleLastClick.bind(this);
    this.handleFirstClick = this.handleFirstClick.bind(this);
    this.onclickdelete = this.onclickdelete.bind(this);
    this.toggleAccordion = this.toggleAccordion.bind(this);
    // toast.configure({
    //   autoClose: 8000,
    //   draggable: false,
    //   //etc you get the idea
    // });
  }

  getAppConfig = () => {
    return window.AppConfigData;
  };
  
  toggleAccordion(tab) {

    const prevState = this.state.accordion;
    const state = prevState.map((x, index) => tab === index ? !x : false);

    this.setState({
      accordion: state,
    });
  }



  /*=============pagination========*/
  onRangevaluechange = (_value, e) => {
    var range = '';
    if (e.target.value == "ShowAll") {
      range = this.state.totaldevicecount;
    }
    else {
      range = e.target.value;
    }
    this.setState({ [_value]: range });
    this.setState({ currentpageno: this.state.currentpageno });
    this.setState({ pageno: 1 }, () => { this.callbackfirmware() });
  }

  onSearchkeyChange = (_value, e) => {
    this.setState({ [_value]: e.target.value });
    var queryResult = [];
    if (e.target.value !== '') {
      this.state.odevicelist.forEach(function (item) {
        if (item.deviceName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
          item.deviceType.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
          item.deviceId.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
          item.model.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
          queryResult.push(item);
      });
      this.setState({ devicelist: queryResult });
    }
    else {
      this.setState({ pageno: 1 }, () => { this.callbackfirmware() });
    }
  }
  callbackfirmware = () => {
    this.getAllDevices();
  }
  handleClick(event) {
    event.preventDefault();
    this.setState({
      pageno: Number(event.target.id)
    });

    this.getAllDevices();
  }
  handleLastClick(event) {
    event.preventDefault();
    this.setState({
      pageno: last
    });
    this.getAllDevices();
  }
  handleFirstClick(event) {
    event.preventDefault();
    this.setState({
      pageno: 1
    });
    this.getAllDevices();
  }
  /*================end===============*/

  /*==============Gateway Create ===========================*/
  togglePrimary() {
    this.setState({
      primary: !this.state.primary, selectedOption: null, pointlist: [], disabledstate: true, deviceuploaddata: {}
      , errors: '', disabledstate: true
    });
  }

  onvalueChange(name, e) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    const formData = { ...this.state.deviceuploaddata };
    formData[e.target.name] = e.target.value;
    this.setState({ deviceuploaddata: formData }, () => {
      this.handleformvalidation();
    });
    if (name === 'deviceType') {
      DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${e.target.value}/models`).then((res) => {
        this.setState({ modellist: res.data });
      }).catch((err) => {
        this.setState({ modellist: [] });
      });
    }
  }



  handleformvalidation() {
    let fields = this.state.deviceuploaddata;
    let formIsValid = true;
    var validationfield = [{ 'name': 'deviceId', 'type': 'alphanumeric', 'isrequired': true, 'minlength': 3, 'displayname': 'Device Id' },
    { 'name': 'deviceName', 'type': 'alphanumericspace', 'isrequired': true, 'minlength': 5, 'displayname': 'Device Name' },
    { 'name': 'deviceType', 'type': '', 'isrequired': true, 'minlength': 0, 'displayname': 'Device Type' },
    { 'name': 'model', 'type': '', 'isrequired': false, 'minlength': 0, 'displayname': 'Model' }];
    for (let i = 0; i < validationfield.length; i++) {
      if (validationfield[i].isrequired && !fields[validationfield[i].name]) {
        formIsValid = false;
        this.setState({ errors: 'Please fill all the fields' });
        this.setState({ disabledstate: true });
      }
      else if (validationfield[i].type === 'alphanumeric') {
        let nospecial = /[*|\":<>[\]{}`\\()';@&$!#^=+%`~,./? _\-]/;
        if (nospecial.test(fields[validationfield[i].name])) {
          formIsValid = false;
          this.setState({ errors: `No Special characters allowed in ${validationfield[i].displayname}` });
          this.setState({ disabledstate: true });
        }
        if (fields[validationfield[i].name].length < validationfield[i].minlength) {
          formIsValid = false;
          this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
          this.setState({ disabledstate: true });
        }
      }
      else if (validationfield[i].type === 'alphanumericspace') {
        let nospecial1 = /[*|\":<>[\]{}`\\()';@&$!#^=+%`~,./?_\-]/;
        if (nospecial1.test(fields[validationfield[i].name])) {
          formIsValid = false;
          this.setState({ errors: `No Special characters allowed in ${validationfield[i].displayname}` });
          this.setState({ disabledstate: true });
        }
        if (fields[validationfield[i].name].length < validationfield[i].minlength) {
          formIsValid = false;
          this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
          this.setState({ disabledstate: true });
        }
      }
      else if (validationfield[i].minlength > 0) {
        if (fields[validationfield[i].name].length < validationfield[i].minlength) {
          formIsValid = false;
          this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
          this.setState({ disabledstate: true });
        }
      }
    }
    if (formIsValid === true) {

      this.setState({ errors: '', disabledstate: false });
      //this.onclickdeviceupload();



    }

  }

  onclickdeviceupload = () => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    DeviceMetadataAxiosInstance.post(`/admin/deviceprofile/${username}/register`, this.state.deviceuploaddata)
      .then((response) => {
        // toast.success("Device created successfully", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        this.setState({ primary: !this.state.primary });
        this.getAllDevices();
        return NotificationManager.success("Device created successfully");
      }).catch((err) => {
        // toast.error(err.response.data, {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");
      })
  }


  /*================ Device edit ========================*/
  toggleeditdevicemodel = (gw_id, status) => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    if (status == "edit") {
      DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${gw_id.deviceType}/models`).then((res) => {
        this.setState({ modellist: res.data });
        let gw = { ...gw_id };
        if (res.data !== 'failed' && res.data.length === 0) {
          gw.model = '';
        }
        this.setState({ deviceuploaddata: gw, viewKeyModell: true, disabledstate: false, errors: '' })
      }).catch((err) => {
        this.setState({ modellist: [] });
        let gw = { ...gw_id };
        gw.model = '';
        this.setState({ deviceuploaddata: gw, viewKeyModell: true, disabledstate: false, errors: '' })
      });

    } else {
      this.setState({ deviceuploaddata: {}, viewKeyModell: false })
    }


  }


  onclickeditdeviceupload = () => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    let req = {
      deviceType: this.state.deviceuploaddata.deviceType,
      deviceName: this.state.deviceuploaddata.deviceName,
      model: this.state.deviceuploaddata.model
    }
    DeviceMetadataAxiosInstance.put(`/admin/deviceprofile/${username}/${this.state.deviceuploaddata.id}`, req)
      .then((res) => {
        // toast.success("Device Successfully updated", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        this.setState({ viewKeyModell: false });
        this.getAllDevices();
        return NotificationManager.success("Device Successfully updated");

      }).catch((err) => {
        // toast.error("Some error occured while updating the device", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");

      });
  }
  /*================= Gateway delete =====================*/
  deleteKeyToggle = (data, event) => {
    this.setState({
      danger: !this.state.danger, deletedata: data
    });

  }
  // delete function after model pop up
  onclickdelete = () => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    SecurityAxiosInstance.delete(`/security/${username}/${this.state.deletedata.id}`).then((resp) => {
      // toast.success("Device successfully deleted", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      this.setState({
        danger: !this.state.danger
      });
      this.getAllDevices();
      return NotificationManager.success("Device successfully deleted");

    }).catch((err) => {
      // toast.error("Error occured while deleting the device", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Some error occured");

    });


  }
  componentDidMount() {
    this.getAllDevices();
    this.getAllDevicesTypes();

    const socket2 = socketIOClients((this.getAppConfig().ApiUrlMapp.SocketIOURL));
    socket2.on("presence", (pdata) => {
      const userId = localStorage.getItem("user-id");
      try {
        if (pdata.networkId.toString() === userId) {
          let devicelist = [...this.state.devicelist];
          let odevicelist = [...this.state.odevicelist]
          devicelist.map((d, i) => {
            if (d.deviceId === pdata.deviceId) {
              d.deviceStatus = pdata.status;
            }
          });
          odevicelist.map((od, i) => {
            if (od.deviceId === pdata.deviceId) {
              od.deviceStatus = pdata.status;
            }
          });
          this.setState({ devicelist });
          this.setState({ odevicelist });
        }
      }
      catch (cerror) {
        // toast.error("Something went wrong.", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");

      }
    })
  }

  getAllDevices() {
    //const userId = localStorage.getItem("user-id");
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/list`).then((resp) => {
      if (resp.data) {


        this.setState({ devicelist: resp.data, odevicelist: resp.data, totaldevicecount: resp.data.length });

      }
      else {
        this.setState({ devicelist: [], odevicelist: [] });
      }
    }).catch((err) => {

    })
  }

  //view device key api and enable model
  viewDeviceKey(devId) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    SecurityAxiosInstance.get(`/security/${username}/${devId}/keyfile`)
      .then((resp) => {

        SecurityAxiosInstance.get(`/security/${username}/${devId}/certfile`)
          .then((response) => {

            // const concatenatedData = resp.data + "<br>" + response.data;
            //         console.log('hello',concatenatedData)
            // this.setState({ viewKeyModell: true, viewData: concatenatedData });
            const updatedViewDataArray = [
              resp.data,      // Add the data from the first response
              response.data   // Add the data from the second response
            ];
            this.setState({ viewKeyModell: true, viewData: updatedViewDataArray });

          }).catch((err) => {
            // toast.error(err.response.data, {
            //   position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");

          })

      }).catch((err) => {
        // toast.error(err.response.data, {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");

      })

  }

  //renew the device key
  renewDeviceKey(devId) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    SecurityAxiosInstance.put(`/security/${username}/${devId}`)
      .then((response) => {
        // toast.success("Device Key renewed successfully", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        // this.setState({ viewKeyModell: true,  errors: '', viewData: response})
        // console.log(response);
        this.getAllDevices();
        return NotificationManager.success("Device Key renewed successfully");
      }).catch((err) => {
        // toast.error(err.response.data, {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");

      })
  }

  //create a key for device
  createDeviceKey(devId) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    SecurityAxiosInstance.post(`/security/${username}/${devId}`).then((resp) => {
      this.getAllDevices();
      // toast.success("Device Key created successfully", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.success("Device Key created successfully");
    }).catch((err) => {
      // toast.error(err.response.data, {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Some error occured");

    })
  }


  getAllDevicesTypes() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/list`).then((resp) => {
      if (resp.data) {
        this.setState({ typelist: resp.data },
          () => {
          }
        );
      }
      else {
        this.setState({ typelist: [] });
      }
    }).catch((err) => {

    })
  }

  //download device key for a device as a file
  downloadKey = (devId, key) => {
    // const keyData = 'your_key_data_here'; // Replace with your actual key data

    const blob = new Blob([key], { type: 'text/plain' });
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = `${devId}key.txt`; // Set the desired file name
    a.style.display = 'none';

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  };


  //download a key for a device
  downloadDeviceKey(devId) {
    // AuthAxiosInstance.get(`/security/${networkId.network}/${devId}/certfile`)
    //   .then((response) => {
    //     console.log(response.data);
    //     toast.success("Device Downloaded successfully", {
    //       position: toast.POSITION.TOP_RIGHT
    //     });
    //     this.downloadKey(devId,response.data);
    //     // this.setState({ viewKeyModell: true,  errors: '', viewData: response})
    //     // console.log(response);
    //     // this.getAllDevices();
    //   }).catch((err) => {
    //     toast.error(err.response.data, {
    //       position: toast.POSITION.TOP_RIGHT
    //     });
    //   })
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    SecurityAxiosInstance.get(`/security/${username}/${devId}/keyfile`)
      .then((resp) => {

        SecurityAxiosInstance.get(`/security/${username}/${devId}/certfile`)
          .then((response) => {

            const concatenatedData = resp.data + response.data;
            this.downloadKey(devId, concatenatedData);

            // this.setState({ viewKeyModell: true, viewData: updatedViewDataArray });

          }).catch((err) => {
            // toast.error(err.response.data, {
            //   position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");

          })

      }).catch((err) => {
        // toast.error(err.response.data, {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");

      })

  }

  renewExpiryCheck(date) {
    const today = new Date();
    const givenDate = new Date(date);

    if (givenDate > today) {
      return true;
    } else {
      return false;
    }
  }

  formatDate(date) {
    const dateTimeString = date;
    const dateObject = new Date(dateTimeString);

    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(dateObject.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return (formattedDate); // Output: "2024-08-29"
  }


  render() {

    const { devicetypelist, devicelist, pageno, pagesize,
      totaldevicecount } = this.state;


    if (devicelist.length > 0) {
      for (var i = 0; i < devicelist.length; i++) {
        for (var j = 0; j < devicetypelist.length; j++) {
          if (devicetypelist[j].dvt_id == devicelist[i].dv_type) {
            devicelist[i].dvt_name = devicetypelist[j].dvt_name;
          }
        }
      }
    }
    // Logic for displaying todos
    const indexOfLastTodo = pageno * pagesize;
    const indexOfFirstTodo = indexOfLastTodo - pagesize;
    const currentlist = devicelist.slice(indexOfFirstTodo, indexOfLastTodo);
    prev = pageno > 0 ? (pageno - 1) : 0;
    last = Math.ceil(totaldevicecount / pagesize);
    next = (last === pageno) ? pageno : pageno + 1;
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totaldevicecount / pagesize); i++) {
      pageNumbers.push(i);
    }
    //Logic to get the list of pagesize
    const pagesizelist = []
    if (parseInt(totaldevicecount) > 0) {
      for (let j = 1; j <= totaldevicecount; j++) {
        if (j % 5 === 0) {
          pagesizelist.push(j);
        }
      }
      pagesizelist.push("ShowAll");

    }
    else {
      pagesizelist.push("ShowAll");
    }
    const viewStyle = {
      backgroundColor: "#463223 !important"
    };




    return (
      <div className="animated fadeIn" >
        
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <i className="fa fa-key"></i> <strong className="LabelHeader">Key Management System</strong>
              </CardHeader>
                <Col xs="12" md="12">
                  <FormGroup row>
                    <Col md="8">

                      {/* {(this.state.searchkey == "") &&
                        <Input type="select" value={pagesize} className="dropdownwidth2" name="pagesize" onChange={this.onRangevaluechange.bind(this, 'pagesize')}>
                          {pagesizelist.map((number, i) =>
                            <option key={number} value={number !== 'ShowAll' ? number : totaldevicecount} >{number !== 'ShowAll' ? number : 'ShowAll'}</option>
                          )}
                        </Input>
                      } */}
                      <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                        {this.state.searchkey === "" && (
                          <FormControl variant="outlined" className="dropdownwidth2">
                            <Select
                              value={pagesize}
                              onChange={this.onRangevaluechange.bind(this, "pagesize")}
                            >
                              {pagesizelist.map((number, i) => (
                                <MenuItem
                                  key={number}
                                  value={number !== "ShowAll" ? number : totaldevicecount}
                                >
                                  {number !== "ShowAll" ? number : "ShowAll"}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {this.state.searchkey != "" && (
                          <FormControl variant="outlined" className="dropdownwidth2">

                          </FormControl>
                        )}
                        {/* </Col>
                    <Col md="4">
                      <Input type="text" name="searchkey" autoComplete="off" className="btnrightalign searchbox_color" placeholder="Enter search Key" onChange={this.onSearchkeyChange.bind(this, 'searchkey')} /> */}
                        <Grid item md={4}>
                          <TextField
                            type="text"
                            name="searchkey"
                            autoComplete="off"
                            placeholder="Enter search Key"
                            onChange={this.onSearchkeyChange.bind(this, "searchkey")}
                          />
                        </Grid>
                      </div>
                    </Col>
                  </FormGroup>
                </Col>
                {(currentlist.length > 0) &&
                  // <Table hover responsive className="table-outline mb-0  d-sm-table ">
                  //   <thead className="thead-dark">
                  //     <tr>

                  //       <th>SL No</th>
                  //       <th>DEVICE ID</th>
                  //       <th>DEVICE NAME</th>
                  //       <th>DEVICE TYPE</th>
                  //       <th>DEVICE MODEL</th>
                  //       <th>EXPIRY DATE</th>
                  //       <th>ACTIONS</th>
                  //       <th></th>
                  //       <th></th>

                  //     </tr>
                  //   </thead>
                  //   {currentlist && currentlist.map((row, index) => {
                  //     return (
                  //       <tbody key={index}>
                  //         <tr className="RowStyle">
                  //           <td>{pagesize * (pageno - 1) + (index + 1)}</td>
                  //           <td>{row.deviceId}</td>
                  //           <td>{row.deviceName}</td>
                  //           <td>{row.deviceType}</td>
                  //           <td>{row.model}</td>
                  //           <td>{row.expiryDate === "NA" ? "-" : this.formatDate(row.expiryDate)}</td>
                  //           {/* <td><i className="fa fa-circle" aria-hidden="true" style={{ color: (row.deviceStatus === "ACTIVE") ? "green" : "red" }}></i></td> */}

                  //           <td>
                  //             <strong>
                  //               <Button color="primary" disabled={JSON.parse(row.hasCertificates) ? true : false} title="Create Key" className="mr-1 rounded-circle btn-circle" onClick={this.createDeviceKey.bind(this, row.deviceId)} ><i className="fa fa-plus-circle iconwidth"></i></Button>
                  //               <Button color="success" disabled={JSON.parse(row.hasCertificates) ? false : true} title="View Key" className="mr-1 rounded-circle btn-circle" onClick={this.viewDeviceKey.bind(this, row.deviceId)} ><i className="fa fa-eye iconwidth"></i></Button>
                  //               <Button color="danger" disabled={JSON.parse(row.hasCertificates) ? false : true} title="Revoke Key" className="mr-1 rounded-circle btn-circle" onClick={this.deleteKeyToggle.bind(this, row)} ><i className="fa fa-trash iconwidth"></i></Button>
                  //             </strong>
                  //           </td>
                  //           <td>
                  //             <strong>
                  //               <strong>
                  //                 <Button color="secondary" title="Renew Key" disabled={!JSON.parse(row.hasCertificates) || !this.renewExpiryCheck(this.formatDate(row.expiryDate)) ? true : false} className="btn-primary" onClick={this.renewDeviceKey.bind(this, row.deviceId)} >Renew</Button>
                  //               </strong>
                  //             </strong>
                  //           </td>
                  //           <td>
                  //             <strong>
                  //               <Button color="primary" title="Download Key File" disabled={JSON.parse(row.hasCertificates) ? false : true} className="btn-primary" onClick={this.downloadDeviceKey.bind(this, row.deviceId)} >Download</Button>
                  //             </strong>
                  //           </td>
                  //         </tr>
                  //       </tbody>
                  //     );
                  //   }, this)}
                  // </Table>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>SL No</TableCell>
                          <TableCell>DEVICE ID</TableCell>
                          <TableCell>DEVICE NAME</TableCell>
                          <TableCell>DEVICE TYPE</TableCell>
                          <TableCell>DEVICE MODEL</TableCell>
                          <TableCell>EXPIRY DATE</TableCell>
                          <TableCell>ACTIONS</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentlist.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{row.deviceId}</TableCell>
                            <TableCell>{row.deviceName}</TableCell>
                            <TableCell>{row.deviceType}</TableCell>
                            <TableCell>{row.model}</TableCell>
                            <TableCell>{row.expiryDate === 'NA' ? '-' : this.formatDate(row.expiryDate)}</TableCell>
                            <TableCell>
                              <Button
                                className="custom-button"
                                disabled={JSON.parse(row.hasCertificates) ? true : false}
                                title="Create Key"
                                onClick={this.createDeviceKey.bind(this, row.deviceId)}
                              >
                                <AddIcon />
                              </Button>
                              <Button
                                className="custom-button-view"
                                style={viewStyle}
                                disabled={JSON.parse(row.hasCertificates) ? false : true}
                                title="View Key"
                                onClick={this.viewDeviceKey.bind(this, row.deviceId)}
                              >
                                <ViewIcon />
                              </Button>
                              <Button
                                className="custom-button-delete"
                                disabled={JSON.parse(row.hasCertificates) ? false : true}
                                title="Revoke Key"
                                onClick={this.deleteKeyToggle.bind(this, row)}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                className="custom-button"
                                title="Renew Key"
                                disabled={
                                  !JSON.parse(row.hasCertificates) ||
                                    !this.renewExpiryCheck(this.formatDate(row.expiryDate))
                                    ? true
                                    : false
                                }
                                onClick={this.renewDeviceKey.bind(this, row.deviceId)}
                              >
                                Renew Key
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                className="custom-button"
                                title="Download Key File"
                                disabled={JSON.parse(row.hasCertificates) ? false : true}
                                onClick={this.downloadDeviceKey.bind(this, row.deviceId)}
                              >
                                Download Key File
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                }

                <br></br>
                <div>
                  {(totaldevicecount !== pagesize) && (currentlist.length > 0) && (this.state.searchkey == "") &&
                    <ul id="page-numbers">
                      <nav>
                        <Pagination style={{ display: "flex" }}>
                          <PaginationItem>
                            {prev === 0 ? <PaginationLink disabled>First</PaginationLink> :
                              <PaginationLink onClick={this.handleFirstClick} id={prev}>First</PaginationLink>
                            }
                          </PaginationItem>
                          <PaginationItem>
                            {prev === 0 ? <PaginationLink disabled>Prev</PaginationLink> :
                              <PaginationLink onClick={this.handleClick} id={prev} >Prev</PaginationLink>
                            }
                          </PaginationItem>
                          {
                            pageNumbers.map((number, i) =>
                              <Pagination key={i}>
                                <PaginationItem active={pageNumbers[pageno - 1] === (number) ? true : false} >
                                  <PaginationLink onClick={this.handleClick} key={number} id={number}>
                                    {number}
                                  </PaginationLink>
                                </PaginationItem>
                              </Pagination>
                            )}

                          <PaginationItem>
                            {
                              pageno === last ? <PaginationLink disabled>Next</PaginationLink> :
                                <PaginationLink onClick={this.handleClick} id={pageNumbers[pageno]} >Next</PaginationLink>
                            }
                          </PaginationItem>

                          <PaginationItem>
                            {
                              pageno === last ? <PaginationLink disabled>Last</PaginationLink> :
                                <PaginationLink onClick={this.handleLastClick} id={pageNumbers[pageno]} >Last</PaginationLink>
                            }
                          </PaginationItem>
                        </Pagination>
                      </nav>
                    </ul>
                  }
                </div>
                {(currentlist.length == 0) &&

                  <center>
                    <h3>No records found!!</h3>
                  </center>

                }

            </Card>
          </Col>
        </Row>

        <Modal isOpen={this.state.primary} toggle={this.togglePrimary}
          className={'modal-lg ' + this.props.className}>
          <ModalHeader toggle={this.togglePrimary}>Add New Device</ModalHeader>
          <ModalBody>
            <div id="accordion">
              <Label className="help-block" id="errorstyle">{this.state.errors}</Label>
              <Card className="mb-0">
                <CardHeader id="headingOne">
                  <Button block color="link" className="text-left m-0 p-0" onClick={() => this.toggleAccordion(0)} aria-expanded={this.state.accordion[0]} aria-controls="collapseOne">
                    <h5 className="m-0 p-0">Device Details <strong className="redfont">*</strong></h5>
                  </Button>
                </CardHeader>
                <Collapse isOpen={this.state.accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                  <CardBody>
                    <Form>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Device ID</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input type="text" name="deviceId" maxLength="50" placeholder="Enter Device ID" onChange={this.onvalueChange.bind(this, 'name')} required />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Device Name</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input type="text" name="deviceName" maxLength="50" placeholder="Enter Name" onChange={this.onvalueChange.bind(this, 'name')} required />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Device Type</Label>
                        </Col>
                        <Col xs="10" md="9">
                          <Input type="select" name="deviceType" onChange={this.onvalueChange.bind(this, 'deviceType')} required>
                            <option value="">Please Select Device Type</option>
                            {this.state.typelist.map((d, i) => {
                              return <option value={d} key={i}>{d}</option>
                            })}
                          </Input>
                        </Col>
                      </FormGroup>
                      {/* ----------------------- DEVICE MODEL  --------------------- */}
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="email-input">Device Model</Label>
                        </Col>
                        <Col xs="10" md="9">
                          {/* <Input type="text" name="model" placeholder="Enter Device Model" onChange={this.onvalueChange.bind(this, 'model')} >
                             
                            </Input> */}

                          <Input type="select" name="model" onChange={this.onvalueChange.bind(this, 'model')} >
                            <option value="">Please Select Model</option>
                            {this.state.modellist.map((d, i) => {
                              return <option value={d} key={i}>{d}</option>
                            })}
                          </Input>
                        </Col>
                      </FormGroup>

                      {/*  ------------------------------------- */}
                    </Form>
                  </CardBody>
                </Collapse>
              </Card>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onclickdeviceupload.bind(this)} disabled={this.state.disabledstate}>Create</Button>
            <Button color="primary" onClick={this.togglePrimary}>Cancel</Button>
          </ModalFooter>
        </Modal>

        {/* <Modal isOpen={this.state.viewKeyModell} toggle={this.toggleeditdevicemodel.bind(this, '')}
          className={'modal-lg ' + this.props.className}>
          <ModalHeader toggle={this.toggleeditdevicemodel.bind(this, '')}>Device Key</ModalHeader>
          <ModalBody>
            <center><h4>Private Key</h4></center>
            <center>{this.state.viewData[0]}</center>
            <br />
            <center><h4>Public Key</h4></center>
            <center>{this.state.viewData[1]}</center>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleeditdevicemodel.bind(this, '')}>Close</Button>
          </ModalFooter>
        </Modal> */}
        <Dialog open={this.state.viewKeyModell} onClose={this.toggleeditdevicemodel.bind(this, '')}>
          <DialogTitle>Device Key</DialogTitle>
          <DialogContent>
            <Typography variant="h6">Private Key</Typography>
            <Typography>{this.state.viewData[0]}</Typography>
            <br />
            <Typography variant="h6">Public Key</Typography>
            <Typography>{this.state.viewData[1]}</Typography>
          </DialogContent>
          <DialogActions>
            <Button className="custom-button-cancel" onClick={this.toggleeditdevicemodel.bind(this, '')} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>


        {/* <Modal isOpen={this.state.danger} toggle={this.deleteKeyToggle}
          className={'modal-danger ' + this.props.className}>
          <ModalHeader toggle={this.deleteKeyToggle}>Delete Device Key</ModalHeader>
          <ModalBody>
            <strong>Are you sure, You want to delete this Device Key ?</strong>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.onclickdelete.bind(this)}>Delete</Button>{' '}
            <Button color="secondary" onClick={this.deleteKeyToggle}>Cancel</Button>
          </ModalFooter>
        </Modal> */}

        <Dialog open={this.state.danger} onClose={this.deleteKeyToggle}>
          <DialogTitle>Delete Device Type</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              <strong>Are you sure you want to delete this Device Type?</strong>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onclickdelete.bind(this)} className="custom-button" style={{ backgroundColor: "red" }}>
              Delete
            </Button>
            <Button onClick={this.deleteKeyToggle} className="custom-button-cancel">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }

}

export default KeyManagement;
