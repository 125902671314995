import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import AddBooking from "../../Broker/BookingManagement/AddBooking";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

export default function GetBookingRequestsComponent(props) {
  let history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [searched, setSearched] = useState("");

  const [bookingRequestsList, setBookingRequestsList] = useState([]);
  const [filteredBookingRequestsList, setfilteredBookingRequestsList] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [UpdateRequestDialog, setUpdateRequestDialog] = useState(false);
  const [newStatusValue, setNewStatusValue] = useState("");
  const [existingStatus, setSelectedExistingStatus] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [bookingRequestData,setBookingRequestData] = useState({});
  const [bookingPopUp, setBookingPopUp] = useState(false);
  useEffect(() => {
    getAllBookingRequests();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

 
  const getAllBookingRequests = () => {
    MainService.getBookingRequests(props.user)
      .then((res) => {
        if(res.length>0)
        {
          setBookingRequestsList(res);
          setfilteredBookingRequestsList(res);
        }else{
          setBookingRequestsList([]);
          setfilteredBookingRequestsList([]);
        }
       
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
 
  const requestSearch = (searchedVal) => {
    const filteredRows = filteredBookingRequestsList.filter((row) => {
      return row.orderid.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setBookingRequestsList(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  const UpdateRequestDialogClick = (id, assetId, existingStatus) => {
    setSelectedId(id);
    setSelectedAssetId(assetId);
    setSelectedExistingStatus(existingStatus);
    setUpdateRequestDialog(!UpdateRequestDialog);
  };
  const updateRequestStatusSubmit = () => {
    let updateStatusData = {
      status: newStatusValue,
    };
    const updateStatusDataString = JSON.stringify(updateStatusData);
    MainService.updateAssetRequestStatusForAd(
      updateStatusDataString,
      selectedId,
      selectedAssetId
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setUpdateRequestDialog(!UpdateRequestDialog);
  };

  

  /* new map1 */
  const [selected, setSelected] = useState({});
  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };
  const onSelect = (item) => {
    setSelected(item);
  };
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: 13.093426,
    lng: 77.655773,
  };

  return (
    <>
        <Box sx={{ width: "100%"}}>         
            
          
            <Paper sx={{ width: "100%" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell
                        className="headerFontSize"
                        align="center"
                        colSpan={13}
                      >
                        All Booking Requests
                      </TableCell>
                    </TableRow>
                    <TableRow align="center">
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Order Id
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Source Id
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Destination Id
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Trip Id
                      </TableCell>

                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        FreightCost
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        ShippingCharge
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        PaymentMode
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        PaymentTerms
                      </TableCell>

                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        DeliveryMode
                      </TableCell>
                      
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Asset Request Status
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={1}
                      >
                        Timestamp
                      </TableCell>
                      <TableCell
                        className="subHeaderFontSize"
                        align="center"
                        colSpan={2}
                      >
                        Option
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookingRequestsList.length > 0 ? (
                      bookingRequestsList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((requests, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {requests.orderid}
                              </TableCell>
                              <TableCell align="center">
                                {requests.source}
                              </TableCell>
                              <TableCell align="center">
                                {requests.destination}
                              </TableCell>
                              <TableCell align="center">
                                {requests.tripid}
                              </TableCell>
                              <TableCell align="center">
                                {requests.freightcost}
                              </TableCell>
                              <TableCell align="center">
                                {requests.shippingcharge}
                              </TableCell>
                              <TableCell align="center">
                                {requests.paymentmode}
                              </TableCell>
                              <TableCell align="center">
                                {requests.paymentterms}
                              </TableCell>
                              <TableCell align="center">
                                {requests.deliverymode}
                              </TableCell>
                              <TableCell align="center">
                                {requests.status}
                              </TableCell>
                              <TableCell align="center">
                                {requests.timestamp}
                              </TableCell>
                              
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={()=> history.push({ pathname: "./addBooking", state: requests })}
                                >
                                  Create Booking
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          Booking Requests Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={bookingRequestsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>

            <Dialog
              fullScreen={fullScreen}
              open={UpdateRequestDialog}
              onClose={handleClose}
              aria-labelledby="updateRequest"
            >
              <DialogTitle id="updateRequest">
                Update Asset Request Status
              </DialogTitle>
              <DialogContent>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="id">ID :</InputLabel>
                  <Input
                    disabled
                    id="id"
                    name="id"
                    type="text"
                    value={selectedId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId">Asset ID :</InputLabel>
                  <Input
                    disabled
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={selectedId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="existingStatus">
                    Existing Asset Request Status :
                  </InputLabel>
                  <Input
                    disabled
                    id="existingStatus"
                    name="existingStatus"
                    type="text"
                    value={existingStatus}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="assetId">
                    Update Asset Request Status
                  </InputLabel>
                  <Select
                    id="assetId"
                    name="assetId"
                    value={newStatusValue}
                    onChange={(e) => {
                      setNewStatusValue(e.target.value);
                    }}
                  >
                    <MenuItem value="in-progress">In-Progress</MenuItem>
                    <MenuItem value="in-shipment">In-Shipment</MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateRequestStatusSubmit();
                  }}
                >
                  Update
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setUpdateRequestDialog(!UpdateRequestDialog);
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          
         
        </Box>
      
    </>
  );
}
