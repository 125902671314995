// AddToCart.js
import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import MainService from '../../Common/MainService';
import { NotificationManager } from 'react-notifications';
import { CartItemContext } from '../../../../context/CartItemContext';
import './AddToCart.css';
import { FormControl } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const AddToCart = (props) => {
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [isLoading, setIsLoading] = useState(false);
  const [cartPopup, setCartPopup] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [price, setPrice] = useState(0);
  const [postItems, setPostItems] = useState([]);
  const [data, setData] = useState({ wallet: "0" });

  const { cartState, cartStateChangeHandler } = useContext(CartItemContext);
  

  useEffect(() => {
   getCartDetails();
   getWalletBalance(username, data);
  }, []);

  const closeCartPopupFn = () => {
 
    props.closeCartPopupFn(!props.cartPopup);
  };

  const getWalletBalance = (username, dataRef) => {
    let newData = { ...dataRef };
    MainService.getWalletBalance(username)
      .then((responseData) => {
        newData = {
          ...dataRef,
          wallet: new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 3,
          }).format(responseData.walletBalance),
        };

        setData(newData);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  function uuid() {
    // return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    return "xxxyxxxxyxyxyyxy".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  const getCartDetails = () => {
    MainService.findCartItems(username)
      .then((data) => {
        setCartItems(data);
        handlePrice(data);
        cartStateChangeHandler(...data)
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const handlePrice = (cartItems) => {
    let ans = 0;
    cartItems.forEach((item) => {
      ans += 1 * item.whitelabelcost;
    });
    setPrice(ans);
  };

  const handleRemove = (modelId, assetid) => {
    MainService.deleteAssetFromCart(username, modelId, assetid)
      .then(() => {
        findCartItemsFunction();
        return NotificationManager.success(`${assetid} deleted successfully`);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const findCartItemsFunction = () => {
    MainService.findCartItems(username)
      .then((data) => {
        setCartItems(data);
        handlePrice(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const makePaymentClick = () => {
    const referenceId = uuid();
    const paymentBody = {
      username,
      referenceid: referenceId,
      schemename: 'scheme1', // need to change after new api implementation
      schemeid: 'scheme999',// need to change after new api implementation
      superad: parentId,// need to change after new api implementation
      optionalid: '6758595',// need to change after new api implementation
      unit: 'INR',
      totalamount: price,
      items: postItems,
    };
    const paymentBodyData = JSON.stringify(paymentBody);
    var wala = data.wallet;
    var walaint = wala.replace(/,/g, "");
    var w = Number(walaint);
    if(w >= price)
    {
      MainService.makePayment(paymentBodyData, username)
      .then((data) => {
        setIsLoading(false); 
        return NotificationManager.success(data);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error('white label request is unsuccessful');
      });
    }else{
      return NotificationManager.error('insufficient balance in your wallet');
    }
    
  };

  return (
    <div className="add-to-cart-container">
      <div className="close-button-container">
      <IconButton className="close-cart-icon" onClick={closeCartPopupFn}>
          <CloseIcon />
        </IconButton>
      </div>
      <h3 style={{ width:"100%", textAlign:"center"}}>Your Cart</h3>

      <div className='cart-items-container'>
        {!cartItems || cartItems.length === 0 ? (
          <h4 className="no-items-message">No items in your cart.</h4>
        ) : (
          <article id="cartDrawer">
            {cartItems.map((item, index) => (
              <div className="cart-box" key={index}>
                <div className="cart-img">
                  <img
                    alt="Product"
                    src={`data:image/jpeg;base64,${item.image}`}
                    width="120"
                    height="120"
                  />
                </div>
                <div className="cart-details">
                  <span className="model-id"><b>Mode lId :</b>{item.modelid}</span>&nbsp;&nbsp;
                  <span className="asset-id"><b>Asset Id :</b>{item.assetid}</span>&nbsp;&nbsp;
                  <span className="legacy-asset-type"><b>Asset Type :</b>{item.legacyassettype}</span>&nbsp;&nbsp;
                  <span className="whitelabel-cost"><b>Cost: Rs {item.whitelabelcost}</b></span>
                </div>
                <div className="cart-buttons">
                  <button onClick={() => handleRemove(item.modelid, item.assetid)}>
                    Remove
                  </button>
                </div>
              </div>
            ))}
            <div className="total">
              <span>Total Price:</span>
              <span>Rs {price}</span>
            </div>
            <div className="payment-buttons">
              <Button
                variant="contained"
                color="primary"
                className="make-payment-btn"
                onClick={makePaymentClick}
              >
                Make Payment
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="add-btn"
                onClick={findCartItemsFunction}
              >
                Refresh Cart
              </Button>
            </div>
          </article>
        )}
      </div>
    </div>
  );
};

export default AddToCart;
