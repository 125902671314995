import React from "react";
import "./BenefitsCard.css";

export default function BenefitsCard({ src, title, bodycopy }) {
  return (
    <div className="benefitsCardContainer mb-lg-0 mb-3">
      <img src={src} alt="icon" className="mb-4 cursor-initial"/>

      <h3>{title}</h3>
      <p className="mb-0"> {bodycopy} </p>
    </div>
  );
}
