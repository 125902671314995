import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { Fragment, memo, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";

function DeleteContentSubCategory({
  reLoad,
  userName,
  categoryId,
  subCategoryList,
  onOpen,
  onClose,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [subCategoryNameValue, setSubCategoryNameValue] = useState("");

  const submitSubCategoryDeleteClick = () => {
    MainService.deleteContentSubCategory(
      userName,
      subCategoryNameValue.replace(" ", "")
    )
      .then((res) => {
        reLoad();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    onClose();
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={onOpen}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Content Sub-Category"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Asset Developer Id"
              id="assetdeveloperid"
              name="assetdeveloperid"
              type="text"
              value={userName}
              disabled
            />
          </FormControl>
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Category"
              id="category"
              name="category"
              type="text"
              value={categoryId}
              disabled
            />
          </FormControl>
          <FormControl fullWidth size="small" margin="dense">
            <TextField
              size="small"
              label="Choose Sub Category"
              select
              id="selectedSubCategory"
              value={subCategoryNameValue}
              onChange={(e) => {
                setSubCategoryNameValue(e.target.value);
              }}
            >
              {subCategoryList &&
                subCategoryList?.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj.contentsubcategoryid}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={submitSubCategoryDeleteClick}
          >
            Delete
          </Button>
          <Button color="error" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default memo(DeleteContentSubCategory);
