import React from 'react';
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import ClubMainPage from '../Club/ClubMainPage';
import "./Brands.css"
import SocialMarketNavbar from '../SocialMarketNavbar/SocialMarketNavbar';

export default function Brands() {
  const userTypes= ["FOOD-PROCESSOR"]

  
 
  return (
    <>
    <SocialMarketNavbar/>
    <UpdatesBanner 
         className="socialMarketplace text-white"
         bannerText="Social onMarketplace Brands"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 

<div className='subSectionWrapper'>
    <ClubMainPage type={userTypes}/>
    </div>
    </>
  )
}
