import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import MainService from "../../Common/MainService";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";

UploadAgreementPageComponent.propTypes = {
  function: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 250,
  },
  root: {
    margin: 5,
  },
  contentContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: 5,
    justifyContent: "space-between",
  },
  media: {
    width: 250,
    height: 250,
  },
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  control: {
    padding: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  button: {
    color: theme.palette.danger.main,
    borderColor: theme.palette.danger.main,
    "&:hover": {
      backgroundColor: theme.palette.danger.shadow,
      borderColor: theme.palette.danger.main,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.danger.shadow,
      borderColor: theme.palette.danger.main,
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
  menuPaper: {
    width: 200,
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
  menuPaperForm: {
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
}));

export default function UploadAgreementPageComponent() {
  const [, setIsVisible] = useState(true);
  const classes = useStyles();
  const [, setIsLoading] = useState(true);
  const [isAccepted, setIsAccepted] = useState(false);
  const [signedAgreementDocumentLink] = useState("");
  const [unsignedAgreementDocumentLink, setUnsignedAgreementDocumentLink] =
    useState("");
  const [viewUnsignedDocumentPopup, setViewUnsignedDocumentPopup] =
    useState(false);
  const [agreementFile, setAgreementFile] = useState(null);
  const [agreementPopup, setShowAgreementPopup] = useState(false);
  const [growerIdList, setGrowerIdList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [selectedRowCategoryID, setSelectedRowCategoryID] = React.useState("");
  const [selectedRowAssetDeveloperID, setSelectedRowAssetDeveloperID] =
    React.useState("");
  const [selectedRowCategoryName, setSelectedRowCategoryName] =
    React.useState("");
  const [, setSelectedRowContentcreator] = React.useState("");
  const [selectedRowContentDescription, setSelectedRowContentDescription] =
    React.useState("");
  const [selectedRowContentexpirydate, setSelectedRowContentexpirydate] =
    React.useState("");
  const [selectedRowContentFormat, setSelectedRowContentFormat] =
    React.useState("");
  const [, setSelectedRowContentID] = React.useState("");
  const [selectedRowContentName, setSelectedRowContentName] =
    React.useState("");
  const [selectedRowContentOwner, setSelectedRowContentOwner] =
    React.useState("");
  const [selectedRowSubCategoryName, setSelectedRowSubCategoryName] =
    React.useState("");
  const [selectedRowDiscountedprice, setSelectedRowDiscountedprice] =
    React.useState("");
  const [, setSelectedRowLocationUrl] = React.useState("");
  const [, setSelectedRowMimeType] = React.useState("");
  const [, setSelectedRowTextUrl] = React.useState("");
  const [, setSelectedRowTimestamp] = React.useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedGrowerId, setSelectedGrowerId] = useState("");
  const [viewsignedDocumentPopup, setViewsignedDocumentPopup] = useState(false);
  const [unsignedAgreementList, setUnsignedAgreementList] = useState({
    data: [],
  });

  useEffect(() => {
    getAllSolutions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllSolutions = ()=>{
    MainService.getAllSolutions(parentId,parentId)
    .then((data) => {
      if (data.length>0) {
        console.log("get all solutions data:",data)
        setSelectedGrowerId(data[0].solutionid);
        setGrowerIdList(data);
      } else {
        setGrowerIdList(data);
        setSelectedGrowerId("null");
      }
    })
    .catch((e) => {
      console.error(e);
      return NotificationManager.error(e);
    });
  }

  useEffect(() => {
    if (!selectedGrowerId) return;
    getCategoriesBasedOnUsername();
  }, [selectedGrowerId]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedCategory) return;
    getSubCategories();
  }, [selectedCategory]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedGrowerId) return;
    if (!selectedCategory) return;
    if (!selectedSubCategory) return;
    getUnsignedAgreements();
  }, [selectedGrowerId, selectedCategory, selectedSubCategory]); //eslint-disable-line react-hooks/exhaustive-deps

  const getCategoriesBasedOnUsername = () => {
    if (selectedGrowerId !== null) {
      MainService.getCategoriesBasedOnOEM(selectedGrowerId.replace(" ", ""))
        .then((data) => {
          if (data.length>0) {
            setSelectedCategory(data[0].categoryid);
            setCategoryTypesList(data);
          } else {
            setCategoryTypesList(data);
            setSelectedCategory("null");
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  const getSubCategories = () => {
    if (selectedGrowerId !== null && selectedCategory !== null) {
      MainService.getSubCategoriesForOEM(
        selectedGrowerId.replace(" ", ""),
        selectedCategory.replace(" ", "")
      )
        .then((data) => {
          if (data.length > 0) {
            console.log("sub catg:", data);
            setSelectedSubCategory(data[0].contentsubcategoryname);
            setSubCategoriesList(data);
          } else {
            setSubCategoriesList(data);
            setSelectedSubCategory("null");
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  const getUnsignedAgreements = () => {
    MainService.getUnsignedAgreements(
      selectedGrowerId.replace(" ", ""),
      selectedCategory.replace(" ", ""),
      selectedSubCategory.replace(" ", "")
    )
      .then((data) => {
        setIsLoading(false);
        if (data.length > 0) {
          setUnsignedAgreementList({ ...unsignedAgreementList, data: data });
          setUnsignedAgreementDocumentLink(data[0].locationurl);
        } else {
          setUnsignedAgreementList({ ...unsignedAgreementList, data: data });
          setUnsignedAgreementDocumentLink("null");
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const uploadAgreementDataClick = (evt) => {
    evt.preventDefault();
    if(agreementFile)
    {
      const agreementData = new FormData();
      agreementData.append("file", agreementFile);
      agreementData.append("mimetype", agreementFile.type);
      agreementData.append("categoryid", selectedRowCategoryID);
      agreementData.append("categoryname", selectedRowCategoryName);
      agreementData.append("contentsubcategory", selectedRowSubCategoryName);
      agreementData.append("contentname", selectedRowContentName);
      agreementData.append("assetdeveloperid", selectedRowAssetDeveloperID);
      agreementData.append("contentdescription", selectedRowContentDescription);
      agreementData.append("contentcreator", username);
      // agreementData.append("contentcreator", selectedRowContentcreator);
      //agreementData.append("mimetype", selectedRowMimeType);
      agreementData.append("contentformat", selectedRowContentFormat);
      //agreementData.append("price",);
      agreementData.append("filedescription", selectedRowContentDescription);
      agreementData.append("contentownerid", selectedRowContentOwner);
      agreementData.append("unit", "INR");
      agreementData.append("discountedprice", selectedRowDiscountedprice);
      agreementData.append("expirydate", selectedRowContentexpirydate);
      //agreementData.append("event", );
  
      console.log("agreementData:", agreementData);
      MainService.uploadAgreement(agreementData)
        .then((data) => {
          
          return NotificationManager.success("Agreement Uploaded");
        })
        .catch((e) => {
          setIsLoading(false);
          console.error(e);
          return NotificationManager.error("Agreement not Uploaded");
        });
    }else{
      return NotificationManager.error("please choose file");
    }
    setShowAgreementPopup(false);
  };
  const viewUnsignedDocumentClick = () => {
    setViewUnsignedDocumentPopup(!viewUnsignedDocumentPopup);
  };

  const uploadSignedDocumentClick = () => {
    setShowAgreementPopup(!agreementPopup);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};

  const agreemetStatusClick = (event,data) => {
    console.log("selected cell:",data)
    
  };
  /*Upload Agreement */
  const closeAgreementModal = (
    <span
      onClick={() => {
        setShowAgreementPopup(!agreementPopup);
      }}
    >
      X
    </span>
  );
  const titleAgreement = "Add Agreement";
  const agreementBody = (
    <form onSubmit={uploadAgreementDataClick}>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="doc">Upload Agreement file:</InputLabel>
        <Input
          id="doc"
          name="doc"
          type="file"
          onChange={(e) => {
            setAgreementFile(e.target.files[0]);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryid">Category ID :</InputLabel>
        <Input
          disabled
          id="categoryid"
          name="categoryid"
          type="text"
          value={selectedRowCategoryID}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryname">Category Name :</InputLabel>
        <Input
          disabled
          id="categoryname"
          name="categoryname"
          type="text"
          value={selectedRowCategoryName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="contentname">Content Name :</InputLabel>
        <Input
          disabled
          id="contentname"
          name="contentname"
          type="text"
          value={selectedRowContentName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetdeveloperid">AssetDeveloper ID :</InputLabel>
        <Input
          disabled
          id="assetdeveloperid"
          name="assetdeveloperid"
          type="text"
          value={selectedRowAssetDeveloperID}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="contentcreator">Content Creator :</InputLabel>
        <Input
          disabled
          id="contentcreator"
          name="contentcreator"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="contentownerid">ContentOwner ID :</InputLabel>
        <Input
          disabled
          id="contentownerid"
          name="contentownerid"
          type="text"
          value={selectedRowContentOwner}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
        >
          Submit
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowAgreementPopup(!agreementPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </form>
  );
  /* View Unsigned Document */
  const closeViewUnsignedDocumentPopup = (
    <span
      onClick={() => {
        setViewUnsignedDocumentPopup(!viewUnsignedDocumentPopup);
      }}
    >
      X
    </span>
  );
  const titlecViewUnsignedDocument = "View Unsigned Document";
  const viewUnsignedDocumentBody = (
    <div style={{ marginTop: "20px !important" }}>
      <div style={{ display: "inline !important" }}>
        <iframe
          title="uploadUnsignedDocument"
          className="viewWidthHeight"
          type="application/pdf"
          src={unsignedAgreementDocumentLink}
        ></iframe>
      </div>

      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setViewUnsignedDocumentPopup(!viewUnsignedDocumentPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* View Signed Document */
  const closeViewsignedDocumentPopup = (
    <span
      onClick={() => {
        setViewsignedDocumentPopup(!viewsignedDocumentPopup);
      }}
    >
      X
    </span>
  );
  const titlecViewsignedDocument = "View signed Document";
  const viewsignedDocumentBody = (
    <div>
      <div>
        <iframe
          title="signedAgreement"
          className="viewWidthHeight"
          type="application/pdf"
          src={signedAgreementDocumentLink}
        ></iframe>
      </div>

      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setViewsignedDocumentPopup(!viewsignedDocumentPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  return (
    <>
      <Grid item md={12} sm={12} xs={12}>
        <FormControl variant="outlined" size="small" className="postdropdown">
          <InputLabel
            style={{
              color: "rgb(43, 144, 99)",
              fontSize:"15px",
            }}
          >
            AssetDeveloper
          </InputLabel>
          <Select
            style={{ minWidth: "120px" }}
            id="selectedGrowerId"
            value={selectedGrowerId}
            onChange={(event) => {
              setSelectedGrowerId(event.target.value);
            }}
            label="Select Grower"
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            {growerIdList &&
              growerIdList.map((idObj) => (
                <MenuItem key={idObj.solutionid} value={idObj.solutionid}>
                  {idObj.solutionname}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl  variant="outlined" size="small" className="postdropdown">
          <InputLabel
            style={{
              color: "rgb(43, 144, 99)",
              fontSize:"15px",
            }}
          >
            Category
          </InputLabel>
          <Select
            id="selectedCategory"
            value={selectedCategory}
            onChange={(event) => setSelectedCategory(event.target.value)}
            label="Select Category"
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            {categoryTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.categoryid}>
                {idObj.categoryname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl  variant="outlined" size="small" className="postdropdown">
          <InputLabel
            style={{
              color: "rgb(43, 144, 99)",
              fontSize:"15px",
            }}
          >
            Sub Category
          </InputLabel>
          <Select
            id="selectedSubCategory"
            value={selectedSubCategory}
            onChange={(event) => setSelectedSubCategory(event.target.value)}
            label="Sub Category"
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            {subCategoriesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.contentsubcategoryname}>
                {idObj.contentsubcategoryname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <br />
      <br />
      
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow align="center">
                <TableCell align="center" colSpan={12}>
                  List of Agreements
                </TableCell>
              </TableRow>
              <TableRow align="center">
                <TableCell align="center" colSpan={1}>
                  S.No
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Content Creator
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Content Name
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Category Name
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Content SubCategory
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Document Link
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Accept Agreement
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Upload Signed Document
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  View Signed Document
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unsignedAgreementList.data.length > 0 ? (
                unsignedAgreementList.data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((AgreementsDocs, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {AgreementsDocs.contentcreator}
                        </TableCell>
                        <TableCell align="center">
                          {AgreementsDocs.contentname}
                        </TableCell>
                        <TableCell align="center">
                          {AgreementsDocs.categoryname}
                        </TableCell>
                        <TableCell align="center">
                          {AgreementsDocs.contentsubcategory}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              viewUnsignedDocumentClick(
                                AgreementsDocs.locationurl
                              );
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            color="success"
                            onChange={(e)=>{
                              agreemetStatusClick(e,AgreementsDocs)
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                                    uploadSignedDocumentClick();
                                  }}
                          >
                            Upload
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                                    setViewsignedDocumentPopup(!viewsignedDocumentPopup
                                      
                                    );
                                  }}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow align="center">
                  <TableCell align="center" colSpan={12}>
                    UnSigned Agreements Not Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={unsignedAgreementList.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <CustomPopup
        closeButton={closeAgreementModal}
        isOpen={agreementPopup}
        closeModal={agreementPopup}
        content={agreementBody}
        title={titleAgreement}
      />
      <CustomPopup
        closeButton={closeViewUnsignedDocumentPopup}
        isOpen={viewUnsignedDocumentPopup}
        closeModal={viewUnsignedDocumentPopup}
        content={viewUnsignedDocumentBody}
        title={titlecViewUnsignedDocument}
      />
      <CustomPopup
        closeButton={closeViewsignedDocumentPopup}
        isOpen={viewsignedDocumentPopup}
        closeModal={viewsignedDocumentPopup}
        content={viewsignedDocumentBody}
        title={titlecViewsignedDocument}
      />
    </>
  );
}
