import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row, Container, Collapse, Fade,
    Pagination, PaginationItem, PaginationLink, Badge,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../style.css';
import './Layout.css';
import AuthAxiosInstance from '../../../authAxiosInstance';
import socketIOClients from "socket.io-client";
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputLabel from '@mui/material/InputLabel';
import { NotificationManager } from "react-notifications";

let prev = 0;
let next = 0;
let last = 0;
let first = 0;

var jsonconfig = window.appConfig;
const data = [{ "id": "not1", "deviceId": "GBRBBDIV", "deviceType": "GBRBB0001", "NotificaionName": "Notification1", "NotificationType": "Type1" },
{ "id": "not2", "deviceId": "GBRBBDIV2", "deviceType": "GBRBB0002", "NotificaionName": "Notification2", "NotificationType": "Type2" },
{ "id": "not1", "deviceId": "GBRBBDIV3", "deviceType": "GBRBB0003", "NotificaionName": "Notification3", "NotificationType": "Type3" },
{ "id": "not1", "deviceId": "GBRBBDIV4", "deviceType": "GBRBB0004", "NotificaionName": "Notification4", "NotificationType": "Type4" },
{ "id": "not1", "deviceId": "GBRBBDIV5", "deviceType": "GBRBB0005", "NotificaionName": "Notification5", "NotificationType": "Type5" },
{ "id": "not1", "deviceId": "GBRBBDIV6", "deviceType": "GBRBB0006", "NotificaionName": "Notification6", "NotificationType": "Type6" },
{ "id": "not1", "deviceId": "GBRBBDIV7", "deviceType": "GBRBB0007", "NotificaionName": "Notification7", "NotificationType": "Type7" },
{ "id": "not1", "deviceId": "GBRBBDIV8", "deviceType": "GBRBB0008", "NotificaionName": "Notification8", "NotificationType": "Type8" },];

class NotificationSupport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devicetypelist: [],
            disabledstate: true,
            primary: false,
            danger: false,
            viewKeyModell: false,
            deletedata: {},
            profiledata: [],
            deviceuploaddata: [],
            editdevicedetails: [],
            devicelist: [],
            odevicelist: [],
            currentpageno: 1,
            pageno: 1,
            pagesize: 5,
            searchkey: '',
            totaldevicecount: '',
            devicelisttypeid: 'all',
            accordion: [true],
            currentlist: [],
            typelist: [],
            modellist: [],
            viewData: '',
            startDate: '',
            endDate: '',
            currentDate: ''
        };

        this.togglePrimary = this.togglePrimary.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleLastClick = this.handleLastClick.bind(this);
        this.handleFirstClick = this.handleFirstClick.bind(this);
        this.onclickdelete = this.onclickdelete.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.submitStartEndDate = this.submitStartEndDate.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);

        // toast.configure({
        //     autoClose: 8000,
        //     draggable: false,
        //     //etc you get the idea
        // });
    }


    getAppConfig = () => {
        return window.AppConfigData;
      };

      
      
    formatDate(date) {
        const d = new Date(date);
        const day = d.getDate();
        const month = d.getMonth() + 1;
        const year = d.getFullYear();
        return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
    }

    submitStartEndDate() {

        // alert(this.formatDate(this.state.startDate));
        // alert(this.formatDate(this.state.endDate));
    }

    toggleAccordion(tab) {

        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => tab === index ? !x : false);

        this.setState({
            accordion: state,
        });
    }



    /*=============pagination========*/
    onRangevaluechange = (_value, e) => {
        var range = '';
        if (e.target.value == "ShowAll") {
            range = this.state.totaldevicecount;
        }
        else {
            range = e.target.value;
        }
        this.setState({ [_value]: range });
        this.setState({ currentpageno: this.state.currentpageno });
        this.setState({ pageno: 1 }, () => { this.callbackfirmware() });
    }

    onSearchkeyChange = (_value, e) => {
        this.setState({ [_value]: e.target.value });
        var queryResult = [];
        if (e.target.value !== '') {
            this.state.odevicelist.forEach(function (item) {
                if (item.deviceId.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
                    item.deviceType.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
                    item.NotificaionName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
                    item.NotificationType.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
                    queryResult.push(item);
            });
            this.setState({ devicelist: queryResult });
        }
        else {
            this.setState({ pageno: 1 }, () => { this.callbackfirmware() });
        }
    }
    callbackfirmware = () => {
        this.getAllDevices();
    }
    handleClick(event) {
        event.preventDefault();
        this.setState({
            pageno: Number(event.target.id)
        });

        this.getAllDevices();
    }
    handleLastClick(event) {
        event.preventDefault();
        this.setState({
            pageno: last
        });
        this.getAllDevices();
    }
    handleFirstClick(event) {
        event.preventDefault();
        this.setState({
            pageno: 1
        });
        this.getAllDevices();
    }
    /*================end===============*/

    /*==============Gateway Create ===========================*/
    togglePrimary() {
        this.setState({
            primary: !this.state.primary, selectedOption: null, pointlist: [], disabledstate: true, deviceuploaddata: {}
            , errors: '', disabledstate: true
        });
    }

    onvalueChange(name, e) {
        const formData = { ...this.state.deviceuploaddata };
        formData[e.target.name] = e.target.value;
        this.setState({ deviceuploaddata: formData }, () => {
            this.handleformvalidation();
        });
        if (name === 'deviceType') {
            AuthAxiosInstance.get(`/admin/deviceprofile/${networkId.network}/${e.target.value}/models`).then((res) => {
                this.setState({ modellist: res.data });
            }).catch((err) => {
                this.setState({ modellist: [] });
            });
        }
    }



    handleformvalidation() {
        let fields = this.state.deviceuploaddata;
        let formIsValid = true;
        var validationfield = [{ 'name': 'deviceId', 'type': 'alphanumeric', 'isrequired': true, 'minlength': 3, 'displayname': 'Device Id' },
        { 'name': 'deviceName', 'type': 'alphanumericspace', 'isrequired': true, 'minlength': 5, 'displayname': 'Device Name' },
        { 'name': 'deviceType', 'type': '', 'isrequired': true, 'minlength': 0, 'displayname': 'Device Type' },
        { 'name': 'model', 'type': '', 'isrequired': false, 'minlength': 0, 'displayname': 'Model' }];
        for (let i = 0; i < validationfield.length; i++) {
            if (validationfield[i].isrequired && !fields[validationfield[i].name]) {
                formIsValid = false;
                this.setState({ errors: 'Please fill all the fields' });
                this.setState({ disabledstate: true });
            }
            else if (validationfield[i].type === 'alphanumeric') {
                let nospecial = /[*|\":<>[\]{}`\\()';@&$!#^=+%`~,./? _\-]/;
                if (nospecial.test(fields[validationfield[i].name])) {
                    formIsValid = false;
                    this.setState({ errors: `No Special characters allowed in ${validationfield[i].displayname}` });
                    this.setState({ disabledstate: true });
                }
                if (fields[validationfield[i].name].length < validationfield[i].minlength) {
                    formIsValid = false;
                    this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
                    this.setState({ disabledstate: true });
                }
            }
            else if (validationfield[i].type === 'alphanumericspace') {
                let nospecial1 = /[*|\":<>[\]{}`\\()';@&$!#^=+%`~,./?_\-]/;
                if (nospecial1.test(fields[validationfield[i].name])) {
                    formIsValid = false;
                    this.setState({ errors: `No Special characters allowed in ${validationfield[i].displayname}` });
                    this.setState({ disabledstate: true });
                }
                if (fields[validationfield[i].name].length < validationfield[i].minlength) {
                    formIsValid = false;
                    this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
                    this.setState({ disabledstate: true });
                }
            }
            else if (validationfield[i].minlength > 0) {
                if (fields[validationfield[i].name].length < validationfield[i].minlength) {
                    formIsValid = false;
                    this.setState({ errors: `Minimum length for ${validationfield[i].displayname} is ${validationfield[i].minlength}` });
                    this.setState({ disabledstate: true });
                }
            }
        }
        if (formIsValid === true) {

            this.setState({ errors: '', disabledstate: false });
            //this.onclickdeviceupload();



        }

    }

    onclickdeviceupload = () => {
        AuthAxiosInstance.post(`/admin/deviceprofile/${networkId.network}/register`, this.state.deviceuploaddata)
            .then((response) => {
                // toast.success("Device created successfully", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                this.setState({ primary: !this.state.primary });
                this.getAllDevices();
            }).catch((err) => {
                // toast.error(err.response.data, {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            })
    }


    /*================ Device edit ========================*/
    toggleeditdevicemodel = (gw_id, status) => {
        if (status == "edit") {
            AuthAxiosInstance.get(`/admin/deviceprofile/${networkId.network}/${gw_id.deviceType}/models`).then((res) => {
                this.setState({ modellist: res.data });
                let gw = { ...gw_id };
                if (res.data !== 'failed' && res.data.length === 0) {
                    gw.model = '';
                }
                this.setState({ deviceuploaddata: gw, viewKeyModell: true, disabledstate: false, errors: '' })
            }).catch((err) => {
                this.setState({ modellist: [] });
                let gw = { ...gw_id };
                gw.model = '';
                this.setState({ deviceuploaddata: gw, viewKeyModell: true, disabledstate: false, errors: '' })
            });

        } else {
            this.setState({ deviceuploaddata: {}, viewKeyModell: false })
        }


    }


    onclickeditdeviceupload = () => {

        let req = {
            deviceType: this.state.deviceuploaddata.deviceType,
            deviceName: this.state.deviceuploaddata.deviceName,
            model: this.state.deviceuploaddata.model
        }
        AuthAxiosInstance.put(`/admin/deviceprofile/${networkId.network}/${this.state.deviceuploaddata.id}`, req)
            .then((res) => {
                // toast.success("Device Successfully updated", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                this.setState({ viewKeyModell: false });
                this.getAllDevices();
            }).catch((err) => {
                // toast.error("Some error occured while updating the device", {
                //     position: toast.POSITION.TOP_RIGHT
                // });

            });
    }
    /*================= Gateway delete =====================*/
    deleteKeyToggle = (data, event) => {
        this.setState({
            danger: !this.state.danger, deletedata: data
        });

    }
    // delete function after model pop up
    onclickdelete = () => {

        AuthAxiosInstance.delete(`/security/${networkId.network}/${this.state.deletedata.id}`).then((resp) => {
            // toast.success("Device successfully deleted", {
            //     position: toast.POSITION.TOP_RIGHT
            // });
            this.setState({
                danger: !this.state.danger
            });
            this.getAllDevices();
        }).catch((err) => {
            // toast.error("Error occured while deleting the device", {
            //     position: toast.POSITION.TOP_RIGHT
            // });
        });


    }
    componentDidMount() {
        this.getAllDevices();
        this.getAllDevicesTypes();
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        // this.setState({ endDate: formattedDate });
        const oneDayBefore = `${day - 1}-${month}-${year}`;
        // this.setState({ startDate: oneDayBefore, currentDate: currentDate })

        const socket2 = socketIOClients((this.getAppConfig().ApiUrlMapp.SocketIOURL));
        socket2.on("presence", (pdata) => {
            const userId = localStorage.getItem("user-id");
            try {
                if (pdata.networkId.toString() === userId) {
                    let devicelist = [...this.state.devicelist];
                    let odevicelist = [...this.state.odevicelist]
                    devicelist.map((d, i) => {
                        if (d.deviceId === pdata.deviceId) {
                            d.deviceStatus = pdata.status;
                        }
                    });
                    odevicelist.map((od, i) => {
                        if (od.deviceId === pdata.deviceId) {
                            od.deviceStatus = pdata.status;
                        }
                    });
                    this.setState({ devicelist });
                    this.setState({ odevicelist });
                }
            }
            catch (cerror) {
                // toast.error("Something went wrong.", {
                //   position: toast.POSITION.TOP_RIGHT
                // });
            }
        })
    }

    getAllDevices() {
        //const userId = localStorage.getItem("user-id");
        this.setState({ devicelist: data, odevicelist: data, totaldevicecount: data.length });
        // AuthAxiosInstance.get(`/admin/deviceprofile/${networkId.network}/list`).then((resp) => {
        //     if (resp.data) {


        //         this.setState({ devicelist: data, odevicelist: data, totaldevicecount: data.length });

        //     }
        //     else {
        //         this.setState({ devicelist: [], odevicelist: [] });
        //     }
        // }).catch((err) => {

        // })
    }

    //view device key api and enable model
    viewDeviceKey(devId) {

        AuthAxiosInstance.get(`/security/${networkId.network}/${devId}/keyfile`)
            .then((resp) => {

                AuthAxiosInstance.get(`/security/${networkId.network}/${devId}/certfile`)
                    .then((response) => {

                        // const concatenatedData = resp.data + "<br>" + response.data;
                        //         console.log('hello',concatenatedData)
                        // this.setState({ viewKeyModell: true, viewData: concatenatedData });
                        const updatedViewDataArray = [
                            resp.data,      // Add the data from the first response
                            response.data   // Add the data from the second response
                        ];
                        this.setState({ viewKeyModell: true, viewData: updatedViewDataArray });

                    }).catch((err) => {
                        // toast.error(err.response.data, {
                        //     position: toast.POSITION.TOP_RIGHT
                        // });
                    })

            }).catch((err) => {
                // toast.error(err.response.data, {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            })

    }

    //renew the device key
    renewDeviceKey(devId) {
        AuthAxiosInstance.put(`/security/${networkId.network}/${devId}`)
            .then((response) => {
                // toast.success("Device Key renewed successfully", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                // this.setState({ viewKeyModell: true,  errors: '', viewData: response})
                // console.log(response);
                this.getAllDevices();
            }).catch((err) => {
                // toast.error(err.response.data, {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            })
    }

    //create a key for device
    createDeviceKey(devId) {
        AuthAxiosInstance.post(`/security/${networkId.network}/${devId}`).then((resp) => {
            // console.log(resp);
            // toast.success("Device Key created successfully", {
            //     position: toast.POSITION.TOP_RIGHT
            // });
        }).catch((err) => {
            // toast.error(err.response.data, {
            //     position: toast.POSITION.TOP_RIGHT
            // });
        })
    }


    getAllDevicesTypes() {
        AuthAxiosInstance.get(`/admin/deviceprofile/${networkId.network}/deviceTypes/list`).then((resp) => {
            if (resp.data) {
                this.setState({ typelist: resp.data },
                    () => {
                    }
                );
            }
            else {
                this.setState({ typelist: [] });
            }
        }).catch((err) => {

        })
    }

    //download device key for a device as a file
    downloadKey = (devId, key) => {
        // const keyData = 'your_key_data_here'; // Replace with your actual key data

        const blob = new Blob([key], { type: 'text/plain' });
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = `${devId}key.txt`; // Set the desired file name
        a.style.display = 'none';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
    };


    //download a key for a device
    downloadDeviceKey(devId) {
        // AuthAxiosInstance.get(`/security/${networkId.network}/${devId}/certfile`)
        //   .then((response) => {
        //     console.log(response.data);
        //     toast.success("Device Downloaded successfully", {
        //       position: toast.POSITION.TOP_RIGHT
        //     });
        //     this.downloadKey(devId,response.data);
        //     // this.setState({ viewKeyModell: true,  errors: '', viewData: response})
        //     // console.log(response);
        //     // this.getAllDevices();
        //   }).catch((err) => {
        //     toast.error(err.response.data, {
        //       position: toast.POSITION.TOP_RIGHT
        //     });
        //   })

        AuthAxiosInstance.get(`/security/${networkId.network}/${devId}/keyfile`)
            .then((resp) => {

                AuthAxiosInstance.get(`/security/${networkId.network}/${devId}/certfile`)
                    .then((response) => {

                        const concatenatedData = resp.data + response.data;
                        this.downloadKey(devId, concatenatedData);

                        // this.setState({ viewKeyModell: true, viewData: updatedViewDataArray });

                    }).catch((err) => {
                        // toast.error(err.response.data, {
                        //     position: toast.POSITION.TOP_RIGHT
                        // });
                    })

            }).catch((err) => {
                // toast.error(err.response.data, {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            })

    }
    // handleStartDateChange = (event) => {
    //     const inputDate = event.target.value;
    //     const [year, month, day] = inputDate.split('-');
    //     const formattedDate = `${day}-${month}-${year}`
    //     this.setState({ startDate: formattedDate });
    // };

    // handleEndDateChange = (event) => {
    //     const inputDate = event.target.value;
    //     const [year, month, day] = inputDate.split('-');
    //     const formattedDate = `${day}-${month}-${year}`
    //     this.setState({ endDate: formattedDate });
    // };


    handleStartDateChange(event) {
        this.setState({ startDate: event.target.value });
    }

    handleEndDateChange(event) {
        this.setState({ endDate: event.target.value });
    }

    render() {

        const { devicetypelist, devicelist, pageno, pagesize,
            totaldevicecount } = this.state;


        if (devicelist.length > 0) {
            for (var i = 0; i < devicelist.length; i++) {
                for (var j = 0; j < devicetypelist.length; j++) {
                    if (devicetypelist[j].dvt_id == devicelist[i].dv_type) {
                        devicelist[i].dvt_name = devicetypelist[j].dvt_name;
                    }
                }
            }
        }
        // Logic for displaying todos
        const indexOfLastTodo = pageno * pagesize;
        const indexOfFirstTodo = indexOfLastTodo - pagesize;
        const currentlist = devicelist.slice(indexOfFirstTodo, indexOfLastTodo);
        prev = pageno > 0 ? (pageno - 1) : 0;
        last = Math.ceil(totaldevicecount / pagesize);
        next = (last === pageno) ? pageno : pageno + 1;
        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(totaldevicecount / pagesize); i++) {
            pageNumbers.push(i);
        }
        //Logic to get the list of pagesize
        const pagesizelist = []
        if (parseInt(totaldevicecount) > 0) {
            for (let j = 1; j <= totaldevicecount; j++) {
                if (j % 5 === 0) {
                    pagesizelist.push(j);
                }
            }
            pagesizelist.push("ShowAll");

        }
        else {
            pagesizelist.push("ShowAll");
        }




        return (
            <div className="animated fadeIn" >
                <Row>
                    <Col xs="12" sm="12">
                        <Card>
                            <CardHeader>
                                <strong className="LabelHeader">Notification Support</strong>
                            </CardHeader>
                                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                    <Grid item md={3}>
                                        <TextField
                                            type="text"
                                            name="searchkey"
                                            autoComplete="off"
                                            placeholder="Enter search Key"
                                            onChange={this.onSearchkeyChange.bind(this, "searchkey")}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={2}></Grid>

                                    <Grid item md={3}>
                                        <TextField
                                            id="startDatePicker"
                                            label="Start Date"
                                            type="date"
                                            value={this.state.startDate}
                                            onChange={this.handleStartDateChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={3}>
                                        <TextField
                                            id="endDatePicker"
                                            label="End Date"
                                            type="date"
                                            value={this.state.endDate}
                                            onChange={this.handleEndDateChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    {/* <Grid md="8"> */}

                                    {/* {(this.state.searchkey == "") &&
                        <Input type="select" value={pagesize} className="dropdownwidth2" name="pagesize" onChange={this.onRangevaluechange.bind(this, 'pagesize')}>
                          {pagesizelist.map((number, i) =>
                            <option key={number} value={number !== 'ShowAll' ? number : totaldevicecount} >{number !== 'ShowAll' ? number : 'ShowAll'}</option>
                          )}
                        </Input>
                      } */}
                                    {/* </Grid> */}
                                    <Grid item md={1}>

                                        <strong>
                                            <Button className="custom-button" style={{marginTop:"10px"}} title="Submit Date"  onClick={this.submitStartEndDate.bind()} >Submit</Button>
                                        </strong>

                                    </Grid>

                                </Grid>
                                {(currentlist.length > 0) &&
                                    <TableContainer>
                                    <Table hover responsive>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>SL No</TableCell>
                                          <TableCell>DEVICE ID</TableCell>
                                          {/* <TableCell>DEVICE NAME</TableCell> */}
                                          <TableCell>DEVICE TYPE</TableCell>
                                          <TableCell>NOTIFICATION NAME</TableCell>
                                          <TableCell>NOTIFICATION TYPE</TableCell>
                                          {/* <TableCell>DEVICE MODEL</TableCell> */}
                                          {/* <TableCell>DEVICE STATUS</TableCell> */}
                                          {/* <TableCell>ACTIONS</TableCell>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell> */}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {currentlist && currentlist.map((row, index) => (
                                          <TableRow key={index} className="RowStyle">
                                            <TableCell>{pagesize * (pageno - 1) + (index + 1)}</TableCell>
                                            <TableCell>{row.deviceId}</TableCell>
                                            {/* <TableCell>{row.deviceName}</TableCell> */}
                                            <TableCell>{row.deviceType}</TableCell>
                                            <TableCell>{row.NotificaionName}</TableCell>
                                            <TableCell>{row.NotificationType}</TableCell>
                                            {/* <TableCell>{row.model}</TableCell> */}
                                            {/* <TableCell>
                                              <strong>
                                                <Button color="primary" title="Create Key" className="mr-1 rounded-circle btn-circle" onClick={this.createDeviceKey.bind(this, row.deviceId)}><i className="fa fa-plus-circle iconwidth"></i></Button>
                                                <Button color="success" title="View Key" className="mr-1 rounded-circle btn-circle" onClick={this.viewDeviceKey.bind(this, row.deviceId)}><i className="fa fa-eye iconwidth"></i></Button>
                                                <Button color="danger" title="Delete Key" className="mr-1 rounded-circle btn-circle" onClick={this.deleteKeyToggle.bind(this, row)}><i className="fa fa-trash iconwidth"></i></Button>
                                              </strong>
                                            </TableCell>
                                            <TableCell>
                                              <strong>
                                                <strong>
                                                  <Button color="secondary" title="Renew Key" className="btn-primary" onClick={this.renewDeviceKey.bind(this, row.deviceId)}>Renew</Button>
                                                </strong>
                                              </strong>
                                            </TableCell>
                                            <TableCell>
                                              <strong>
                                                <Button color="primary" title="Download Key File" className="btn-primary" onClick={this.downloadDeviceKey.bind(this, row.deviceId)}>Download</Button>
                                              </strong>
                                            </TableCell> */}
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                }

                                <br></br>
                                <div>
                                    {(totaldevicecount !== pagesize) && (currentlist.length > 0) && (this.state.searchkey == "") &&
                                        <ul id="page-numbers">
                                            <nav>
                                                <Pagination style={{ display: "flex" }}>
                                                    <PaginationItem>
                                                        {prev === 0 ? <PaginationLink disabled>First</PaginationLink> :
                                                            <PaginationLink onClick={this.handleFirstClick} id={prev}>First</PaginationLink>
                                                        }
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        {prev === 0 ? <PaginationLink disabled>Prev</PaginationLink> :
                                                            <PaginationLink onClick={this.handleClick} id={prev} >Prev</PaginationLink>
                                                        }
                                                    </PaginationItem>
                                                    {
                                                        pageNumbers.map((number, i) =>
                                                            <Pagination key={i}>
                                                                <PaginationItem active={pageNumbers[pageno - 1] === (number) ? true : false} >
                                                                    <PaginationLink onClick={this.handleClick} key={number} id={number}>
                                                                        {number}
                                                                    </PaginationLink>
                                                                </PaginationItem>
                                                            </Pagination>
                                                        )}

                                                    <PaginationItem>
                                                        {
                                                            pageno === last ? <PaginationLink disabled>Next</PaginationLink> :
                                                                <PaginationLink onClick={this.handleClick} id={pageNumbers[pageno]} >Next</PaginationLink>
                                                        }
                                                    </PaginationItem>

                                                    <PaginationItem>
                                                        {
                                                            pageno === last ? <PaginationLink disabled>Last</PaginationLink> :
                                                                <PaginationLink onClick={this.handleLastClick} id={pageNumbers[pageno]} >Last</PaginationLink>
                                                        }
                                                    </PaginationItem>
                                                </Pagination>
                                            </nav>
                                        </ul>
                                    }
                                </div>
                                {(currentlist.length == 0) &&

                                    <center>
                                        <h3>No records found!!</h3>
                                    </center>

                                }

                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={this.state.primary} toggle={this.togglePrimary}
                    className={'modal-lg ' + this.props.className}>
                    <ModalHeader toggle={this.togglePrimary}>Add New Device</ModalHeader>
                    <ModalBody>
                        <div id="accordion">
                            <Label className="help-block" id="errorstyle">{this.state.errors}</Label>
                            <Card className="mb-0">
                                <CardHeader id="headingOne">
                                    <Button block color="link" className="text-left m-0 p-0" onClick={() => this.toggleAccordion(0)} aria-expanded={this.state.accordion[0]} aria-controls="collapseOne">
                                        <h5 className="m-0 p-0">Device Details <strong className="redfont">*</strong></h5>
                                    </Button>
                                </CardHeader>
                                <Collapse isOpen={this.state.accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                                    <CardBody>
                                        <Form>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Device ID</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="deviceId" maxLength="50" placeholder="Enter Device ID" onChange={this.onvalueChange.bind(this, 'name')} required />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Device Name</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="deviceName" maxLength="50" placeholder="Enter Name" onChange={this.onvalueChange.bind(this, 'name')} required />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Device Type</Label>
                                                </Col>
                                                <Col xs="10" md="9">
                                                    <Input type="select" name="deviceType" onChange={this.onvalueChange.bind(this, 'deviceType')} required>
                                                        <option value="">Please Select Device Type</option>
                                                        {this.state.typelist.map((d, i) => {
                                                            return <option value={d} key={i}>{d}</option>
                                                        })}
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            {/* ----------------------- DEVICE MODEL  --------------------- */}
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">Device Model</Label>
                                                </Col>
                                                <Col xs="10" md="9">
                                                    {/* <Input type="text" name="model" placeholder="Enter Device Model" onChange={this.onvalueChange.bind(this, 'model')} >

                                                    </Input> */}

                                                    <Input type="select" name="model" onChange={this.onvalueChange.bind(this, 'model')} >
                                                        <option value="">Please Select Model</option>
                                                        {this.state.modellist.map((d, i) => {
                                                            return <option value={d} key={i}>{d}</option>
                                                        })}
                                                    </Input>
                                                </Col>
                                            </FormGroup>

                                            {/*  ------------------------------------- */}
                                        </Form>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onclickdeviceupload.bind(this)} disabled={this.state.disabledstate}>Create</Button>
                        <Button color="primary" onClick={this.togglePrimary}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.viewKeyModell} toggle={this.toggleeditdevicemodel.bind(this, '')}
                    className={'modal-lg ' + this.props.className}>
                    <ModalHeader toggle={this.toggleeditdevicemodel.bind(this, '')}>Device Key</ModalHeader>
                    <ModalBody>
                        <center><h4>Private Key</h4></center>
                        <center>{this.state.viewData[0]}</center>
                        <br />
                        <center><h4>Public Key</h4></center>
                        <center>{this.state.viewData[1]}</center>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button color="primary" id="editsave" onClick={this.onclickeditdeviceupload.bind(this)} disabled={this.state.disabledstate} >Save</Button> */}
                        <Button color="secondary" onClick={this.toggleeditdevicemodel.bind(this, '')}>Close</Button>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={this.state.danger} toggle={this.deleteKeyToggle}
                    className={'modal-danger ' + this.props.className}>
                    <ModalHeader toggle={this.deleteKeyToggle}>Delete Device Key</ModalHeader>
                    <ModalBody>
                        <strong>Are you sure, You want to delete this Device Key ?</strong>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.onclickdelete.bind(this)}>Delete</Button>{' '}
                        <Button color="secondary" onClick={this.deleteKeyToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }

}

export default NotificationSupport;
