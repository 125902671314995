import React from 'react'

export default function DTNBenefits() {
  return (
   <ul className='benefitList mb-0'>
    <li>
    Agrodealers gain access to comprehensive logistics and marketing support, enhancing their ability to efficiently distribute agricultural products to markets and farmers.
    </li>
    <li>
    ARN offers marketing support to agrodealers, helping them promote their products effectively to target markets and farmers.     </li>
    
   </ul>
  )
}
