import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import ClubMainPage from "../Club/ClubMainPage";
import "./AgriBusiness.css";
import SocialMarketNavbar from "../SocialMarketNavbar/SocialMarketNavbar";
import CMJourney from "./CMJourney";
import { Container } from "@material-ui/core";
import SocialOnMarketplaceService from "./SocialOnMarketplaceService";
import MarketPlaceQuotePP from "../MarketPlaceQuote/MarketPlaceQuotePP";

export default function AgriBusiness() {
  const userTypes = ["BRAND"];

  return (
    <>
      <SocialMarketNavbar />
      <UpdatesBanner
        className="socialMarketplace text-white"
        bannerText={[
          "Start your journey  and introduce yourself & your services to Food Brands  ",
          <br className="d-md-block d-none"></br>,
          "by submitting your contract manufacturing services brochure (Catalogue)  ",
        ]}
      />
      <div className="subSectionWrapper pb-0">
        <Container>
          <CMJourney />
        </Container>
      </div>
      <div className="subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">Brands</h2>

        <ClubMainPage
          type={userTypes}
          registerUser="FOOD-PROCESSOR"
          catalog="true"
          bodycopy="Brands Not Found"
        />
      </div>


<MarketPlaceQuotePP  requestType = "cm-product-rfq-req" category="CM-PROCESSED-PRODUCTS" heading="Explore MOD RFQ"/>

    </>
  );
}
