import React, { useState, useEffect } from "react";
import "./ComplaintsComponent.css";

export default function ComplaintsComponent() {
   return (
    <>
      <h2>Complaints component</h2>
    </>
  );
}
