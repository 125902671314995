import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./Privacy.css";
import "react-awesome-slider/dist/styles.css";
import SiteWeatherManagement from "../SiteWeatherManagement/SiteWeatherManagement";

export default class PrivacyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>

        <Container className="SignUpasContainerLightGray privacy-policy-container ">
          <Grid justifyContent="center">
            <Grid md={10}>
              <h2 className="container-title">Privacy Policy</h2>
              <div className="terms-subcontainer">
                <p>
                  CMN Network Pvt ltd. and/or its affiliates (referred as "CMN Network,"
                  the "Company," "we," "us," and "our,") respect your privacy
                  and is committed to protecting it through its compliance with
                  its privacy policies.
                </p>
                <p>This policy describes:</p>
                <ol type="a">
                  <li>
                    The types of information that CMN Network may collect from you when
                    you access or use its websites, applications and other
                    online services (collectively, referred as "Services"); and
                  </li>
                  <li>
                    Its practices for collecting, using, maintaining, protecting
                    and disclosing that information.
                  </li>
                </ol>

                <p>
                  This policy applies only to the information CMN Network collects
                  through its Services, in email, text and other electronic
                  communications sent through or in connection with its
                  Services.
                </p>
                <p>
                  This policy DOES NOT apply to information that you provide to,
                  or that is collected by, any third-party, such as databases
                  available through vendors etc. or social networks that you use
                  in connection with its Services. CMN Network encourages you to consult
                  directly with such third-parties about their privacy
                  practices.
                </p>
                <p>
                  Please read this policy carefully to understand CMN Network's policies
                  and practices regarding your information and how CMN Network will
                  treat it.
                </p>
                <p>
                  By accessing or using its Services and/or registering for an
                  account with CMN Network, you agree to this privacy policy and you are
                  consenting to CMN Network's collection, use, disclosure, retention,
                  and protection of your personal information as described here.
                  If you do not provide the information CMN Network requires, CMN Network may
                  not be able to provide all of its Services to you.
                </p>
                <p>
                CMN Network will control your data so collected or through data
                  controllers appointed by CMN Network who is responsible for the
                  collection, use, disclosure, retention, and protection of your
                  personal information in accordance with its privacy standards
                  as well as any applicable national laws. Your data controller
                  may transfer data to members of CMN Network as described in this
                  Privacy Policy.
                </p>
                <p>
                CMN Network may process and retain your personal information on its
                  servers where its data centers are located, and/or on the
                  servers of its third parties (in or outside India), having
                  contractual relationships with CMN Network.
                </p>
                <p>
                  This policy may change from time to time, your continued use
                  of CMN Network's Services after it makes any change is deemed to be
                  acceptance of those changes, so please check the policy
                  periodically for updates.
                </p>
              </div>

              <div className="terms-subcontainer">
                <h3>Information we collect and how we use it:</h3>
                <p>
                  The information we collect on or through our Services may
                  include:
                </p>
                <p>
                  Your account information: Your full name, email address,
                  postal code, password and other information you may provide
                  with your account, such as your gender, mobile phone number
                  and website. Your profile picture that will be publicly
                  displayed as part of your account profile. You may optionally
                  provide us with this information through third-party sign-in
                  services such as Facebook, LinkedIn or Google. In such cases,
                  we fetch and store whatever information is made available to
                  us by you through these sign-in services.
                </p>
                <p>
                  Your preferences: Your preferences and settings such as time
                  zone and language.
                </p>
                <p>
                  Your content: Information you provide through our Services,
                  including your reviews, photographs, comments, lists,
                  followers, the users you follow, contact information of people
                  you add to, or notify of, your navigation through our
                  Services, names, and other information you provide on our
                  Services, and other information in your account profile.
                </p>
                <p>
                  <b>Your searches and other activities:</b> The search terms
                  you have looked up and results you selected.
                </p>
                <p>
                  <b>Your browsing information:</b> How long you used our
                  Services and which features you used; the ads you clicked on.
                </p>
                <p>
                  <b>Your communications:</b> Communications between you and
                  other users or agri-producers / officers through our Services; your
                  participation in a survey, poll, sweepstakes, contest or
                  promotion scheme; your request for certain features (e.g.,
                  newsletters, updates or other products); your communication
                  with us about various new offers / opportunities posted to the
                  services.
                </p>
                <p>
                  <b>Your transactional information:</b> Your investments,
                  trends through our Services, we may collect and store
                  information about you to process your requests and
                  automatically complete forms for future transactions,
                  including (but not limited to) your phone number, address,
                  email, billing information and credit or payment card
                  information. This information may be shared with third-parties
                  which assist in processing and fulfilling your requests,
                  including PCI Payment Card Information compliant payment
                  gateway processors. When you submit credit or payment card
                  information, we encrypt the information using industry
                  standard technologies, as further described below under
                  "Payment Card Information." If you write reviews about
                  businesses with which you conduct transactions through our
                  Services, we may publicly display information that you
                  transacted with those businesses.
                </p>
                <p>
                  <b>Your Public Posts:</b> You also may provide information
                  (such as ratings, reviews, tips, photos, comments, likes,
                  bookmarks, friends, lists, etc.) to be published or displayed
                  (hereinafter, "posted") on publicly accessible areas of our
                  Services, or transmitted to other users of our Services or
                  third-parties (collectively, "User Contributions"). Your User
                  Contributions are posted on and transmitted to others at your
                  own risk. Although we limit access to certain pages, you may
                  set certain privacy settings for such information by logging
                  into your account profile. Please be aware that no security
                  measures are perfect or impenetrable (see "Security" section
                  below). Additionally, we cannot control the actions of other
                  users of our Services with whom you may choose to share your
                  User Contributions. Therefore, we cannot and do not guarantee
                  that your User Contributions will not be viewed by
                  unauthorized persons. We may display this information on the
                  Services, share it with businesses, and further distribute it
                  to a wider audience through third party sites and services.
                  You should be careful about revealing any sensitive details
                  about yourself in such postings.
                </p>
                <p>
                  We use the information you provide to us to enhance the
                  functionality and improve the quality of our Services, and to
                  personalize your experience while using our Services. We also
                  use this information to display relevant advertising, provide
                  support to you, communicate with you, and comply with our
                  legal obligations.
                </p>
              </div>
              <div className="terms-subcontainer">
                <h3>Information about Your Friends</h3>
                <p>
                  You have the option to request your friends to join the
                  Services by providing their contact information. If you
                  request a friend to join and connect with you on the Services,
                  we will only use your friend's contact information to process
                  your request.
                </p>
                <h3>Information about Your Messages</h3>
                <p>
                  If you exchange messages with others through the Services, we
                  may store them in order to process and deliver them, allow you
                  to manage them, and investigate possible violations of our
                  Terms of Service and wrongdoing in connection with the
                  Services. If you send information from the Services to your
                  mobile device via SMS text message, we may log your phone
                  number, phone carrier, and the date and time that the message
                  was processed. Carriers may charge recipients for texts that
                  they receive.
                </p>
                <h3>
                  Information We Collect Through Automatic Data Collection
                  Technologies
                </h3>
                <p>
                  We may automatically collect certain information about the
                  computer or devices (including mobile devices) you use to
                  access the Services, and about your use of the Services, even
                  if you use the Services without registering or logging in.
                </p>
                <p>
                  Usage information: Details of your use of our Services,
                  including traffic data, location data, logs and other
                  communication data and the resources that you access and use
                  on or through our Services.
                </p>
                <p>
                  Computer and device information: Information about your
                  computer, Internet connection and mobile device, including
                  your IP address, operating systems, platforms, browser type,
                  other browsing information (connection, speed, connection type
                  etc.), device type, device's unique device identifier, mobile
                  network information and the device's telephone number.
                </p>
                <p>
                  Stored information and files: Our applications also may access
                  metadata and other information associated with other files
                  stored on your mobile device. This may include, for example,
                  photographs, audio and video clips, personal contacts and
                  address book information.
                </p>
                <p>
                  <b>Location information:</b> Our applications collect
                  real-time information about the location of your device, as
                  permitted by you.
                </p>
                <p>
                  <b>Last URL visited:</b> The URL of the last web page you
                  visited before visiting our websites.
                </p>
                <p>
                  <b>Mobile device IDs:</b> Unique mobile device identifier
                  (e.g. IDFA or other device IDs on Apple devices like the
                  iPhone and iPad), if you're using our Services on a mobile
                  device, we may use mobile device IDs (the unique identifier
                  assigned to a device by the manufacturer), instead of cookies,
                  to recognize you. We may do this to store your preferences and
                  track your use of our applications. Unlike cookies, mobile
                  device IDs cannot be deleted. Advertising companies may use
                  device IDs to track your use of our applications, track the
                  number of advertisements displayed, measure advertising
                  performance and display advertisements that are more relevant
                  to you. Analytics companies may use mobile device IDs to track
                  your usage of our applications.
                </p>
                <p>
                  <b>Your preferences:</b> Your preferences and settings such as
                  time zone and language.
                </p>
                <p>
                  <b>Your activity on the Services:</b> Information about your
                  activity on the Services, such as your search queries,
                  comments, domain names, search results selected, number of
                  clicks, pages viewed and the order of those pages, how long
                  you visited our Services, the date and time you used the
                  Services, error logs, and other similar information.
                </p>
                <p>
                  <b>Mobile status:</b> For mobile application users, the online
                  or offline status of your application.
                </p>
                <p>
                  <b>Applications:</b> If you use the CMN Network application, CMN Network may
                  collect information about the presence and/ or absence and/ or
                  details pertaining to other applications on your mobile phone.
                  The applications we gather information for, may vary across
                  categories including, without limitation, shopping, travel
                  etc. This will help us understand you and your preferences
                  better and enable CMN Network to provide you with a personalized
                  experience.
                </p>
              </div>
              <h3>Information from Third Parties</h3>
              <p>
                We may collect, process and store your user ID associated with
                any social media account (such as your Facebook and Google
                account) that you use to sign into the Services or connect with
                or use with the Services. When you sign in to your account with
                your social media account information, or otherwise connect to
                your social media account with the Services, you consent to our
                collection, storage, and use, in accordance with this Privacy
                Policy, of the information that you make available to us through
                the social media interface. This could include, without
                limitation, any information that you have made public through
                your social media account, information that the social media
                service shares with us, or information that is disclosed during
                the sign-in process. Please see your social media provider's
                privacy policy and help center for more information about how
                they share information when you choose to connect your account.
              </p>
              <p>
                We may also obtain information about you from third parties such
                as partners, marketers, third-party websites, and researchers,
                and combine that information with information which we collect
                from or about you.
              </p>
              <h3>Anonymous or De-Identified Data</h3>
              <p>
                We may anonymize and/or de-identify information collected from
                you through the Services or via other means, including via the
                use of third-party web analytic tools as described below. As a
                result, our use and disclosure of aggregated and/or
                de-identified information is not restricted by this Privacy
                Policy, and it may be used and disclosed to others without
                limitation.
              </p>
              <h3>Use of information we collect</h3>
              <p>
                We use the information we collect from and about you for a
                variety of purposes, including to:
              </p>
              <ol type="a">
                <li>Process and respond to your queries</li>
                <li>
                  Understand our users (what they do on our Services, what
                  features they like, how they use them, etc.), improve the
                  content and features of our Services (such as by personalizing
                  content to your interests), process and complete your
                  transactions, and make special offers.
                </li>
                <li>
                  Administer our Services and diagnose technical problems.
                </li>
                <li>
                  Send you communications that you have requested or that may be
                  of interest to you by way of emails, or courier, or registered
                  post, or telephone calls, or any other mode of communication.
                  We may also share your preferences or the Services availed by
                  you with your network followers on CMN Network for marketing and other
                  promotional activities of our Services.
                </li>
                <li>
                  Send you questions from other users that you may be able to
                  answer if you have registered with CMN Network.
                </li>
                <li>Enable us to show you ads that are relevant to you.</li>
                <li>
                  {" "}
                  Generate and review reports and data about, and to conduct
                  research on, our user base and Service usage patterns.
                </li>
                <li> Administer contests and sweepstakes.</li>
                <li> Provide you with customer support.</li>
                <li> Provide you with policies about your account.</li>
                <li>
                  {" "}
                  Carry out our obligations and enforce our rights arising from
                  any contracts entered into between you and us, including for
                  billing and collection.
                </li>
                <li> Notify you about changes to our Services.</li>
                <li>
                  Allow you to participate in interactive features offered
                  through our Services.
                </li>
                <li>
                  In any other way we may describe when you provide the
                  information.
                </li>
                <li> For any other purpose with your consent.</li>
              </ol>

              <p>
                We may also use your information to contact you about our own
                and third-party goods and services that may be of interest to
                you. If you do not want us to use your information in this way,
                please check the relevant box located on the form on which we
                collect your data and/or adjust your user preferences in your
                account profile. We may use the information we have collected
                from you to enable us to display advertisements to our
                advertisers'/service providers' target audiences. Even though we
                do not disclose your personal information for these purposes
                without your consent, if you click on or otherwise interact with
                an advertisement, the advertiser may assume that you meet its
                target criteria.
              </p>
              <h3>How we share the information we collect</h3>
              <p>
                We may disclose personal information that we collect or you
                provide, as described in this privacy policy, in the following
                ways:
              </p>
              <h3>General Information Disclosures</h3>
              <ol type="a">
                <li>
                  {" "}
                  To our subsidiaries and affiliates, which are entities under
                  common ownership or control of our ultimate parent company CMN Network
                  Limited.
                </li>
                <li>
                  {" "}
                  To contractors, agri-producers/service providers and other
                  third-parties whom we use to support our business and who are
                  bound by contractual obligations to keep personal information
                  confidential and use it only for the purposes for which we
                  disclose it to them.
                </li>
                <li>
                  {" "}
                  To a buyer or other successor in the event of a merger,
                  divestiture, restructuring, reorganization, dissolution or
                  other sale or transfer of some or all of CMN Network's assets, whether
                  as a going concern or as part of bankruptcy, liquidation or
                  similar proceeding, in which personal information held by CMN Network
                  about the users of our Services are among the assets
                  transferred.
                </li>
                <li>
                  {" "}
                  Service Providers. We may share your information with outside
                  vendors that we use for a variety of purposes, such as to send
                  you communications via emails, messages or tele-call to inform
                  you about our products that may be of interest to you, push
                  notifications to your mobile device on our behalf, provide
                  voice recognition services to process your spoken queries and
                  questions, help us analyze use of our Services, and process
                  and collect payments. Some of our products, services and
                  databases are hosted by third party hosting services
                  providers. We also may use vendors for other projects, such as
                  conducting surveys or organizing sweepstakes for us. We may
                  share information about you with these vendors only to enable
                  them to perform their services.
                </li>
                <li>
                  {" "}
                  Legal Purposes. We may share your information when we believe
                  in good faith that such sharing is reasonably necessary in
                  order to investigate, prevent, or take action regarding
                  possible illegal activities or to comply with legal process.
                  We may also share your information to investigate and address
                  threats or potential threats to the physical safety of any
                  person, to investigate and address violations of this Privacy
                  Policy or the Terms of Service, or to investigate and address
                  violations of the rights of third parties and/or to protect
                  the rights, property and safety of CMN Network, our employees, users,
                  or the public. This may involve the sharing of your
                  information with law enforcement, government agencies, courts,
                  and/or other organizations on account of legal request such as
                  subpoena, court order or government demand to comply with the
                  law.
                </li>
                <li>
                  {" "}
                  Social Networks. If you interact with social media features on
                  our Services, such as the Facebook Like button, or use your
                  social media credentials to log-in or post content, these
                  features may collect information about your use of the
                  Services, as well as post information about your activities on
                  the social media service. Your interactions with social media
                  companies are governed by their privacy policies.
                </li>
                <li>
                  {" "}
                  To enforce or apply our Terms of Use and other agreements,
                  including for billing and collection purposes.
                </li>
                <li>
                  {" "}
                  If we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of CMN Network, our customers
                  or others. This includes exchanging information with other
                  companies and organizations for the purposes of fraud
                  protection and credit risk reduction.
                </li>
                <li>
                  {" "}
                  Consent. We may share your information in any other
                  circumstances where we have your consent.
                </li>
              </ol>
              <h3>Payment Card Information</h3>
              <p>
                To use certain of our Services, we may require credit or debit
                card account information. By submitting your credit or debit
                card account information through our Services, you expressly
                consent to the sharing of your information with CMN Network, third-party
                payment processors, and other third-party service providers
                (including but not limited to vendors who provide fraud
                detection services to us and other third parties), and you
                further agree to the following terms:
              </p>
              <ol type="a">
                <li>
                  When you use a credit or debit card to secure a reservation
                  through our Sites, we provide your credit or debit card
                  account information (including card number and expiration
                  date) to our third-party payment service providers and the
                  applicable restaurant.
                </li>
                <li>
                  When you initially provide your credit or debit card account
                  information through our Services in order to use our services,
                  make investment for purchase we provide your credit or debit
                  card account information to our third-party payment service
                  providers. As explained in our Terms of Use, these third
                  parties may store your credit or debit card account
                  information so you can use our restaurant payment services
                  through our Services in the future.
                </li>
                <li>
                  For information about the security of your credit or debit
                  card account information, see the "Security" section below.
                </li>
              </ol>
              <h3>Analytics and tailored advertising</h3>
              <p>
                To help us better understand your use of the Services, we may
                use third-party web analytics on our Services, such as Google
                Analytics. The information collected by this technology will be
                disclosed to or collected directly by these service providers,
                who use the information to evaluate our users' use of the
                Services. We also use Google Analytics as described in the
                following section. To prevent Google Analytics from collecting
                or using your information, you may install the Google Analytics
                Opt-Out Browser add-on.
              </p>
              <h3>Tailored Advertising</h3>
              <p>
                Third parties whose products or services are accessible or
                advertised via the Services may also use cookies or similar
                technologies to collect information about your use of the
                Services. This is done in order to help them-
              </p>
              <ol type="a">
                <li>
                  Inform, optimize, and serve ads based on past visits to our
                  website and other sites and
                </li>
                <li>
                  Report how our ad impressions, other uses of ad services, and
                  interactions with these ad impressions and ad services are
                  related to visits to our website. We also allow other third
                  parties software to serve tailored ads to you on the Services,
                  and to access their own cookies or similar technologies on
                  your computer, mobile phone, or other device you use to access
                  the Services. We neither have access to, nor does this Privacy
                  Policy govern, the use of cookies or other tracking
                  technologies that may be placed by such third parties. These
                  parties may permit you to opt out of ad targeting. If you are
                  interested in more information about tailored browser
                  advertising and how you can generally control cookies from
                  being put on your computer to deliver tailored advertising,
                  you may opt-out of receiving tailored advertising from
                  companies that participate in those programs. To opt out of
                  Google Analytics for Display Advertising or customize Google
                  Display Network ads, you can visit the Google Ads Settings
                  page. Please note that to the extent advertising technology is
                  integrated into the Services, you may still receive ads even
                  if you opt-out of tailored advertising. In that case, the ads
                  will just not be tailored to your interests. Also, we do not
                  control any of the above opt-out links and are not responsible
                  for any choices you make using these mechanisms or the
                  continued availability or accuracy of these mechanisms.
                </li>
              </ol>

              <p>
                When accessing the Services from a mobile application you may
                also receive tailored in-application advertisements. Each
                operating system: iOS, Android and Windows Phone provides its
                own instructions on how to prevent the delivery of tailored
                in-application advertisements. You may review the support
                materials and/or the privacy settings for the respective
                operating systems in order to opt-out of tailored in-application
                advertisements. For any other devices and/or operating systems,
                please visit the privacy settings for the applicable device or
                operating system or contact the applicable platform operator.
              </p>
              <h3>Choices about how we use and disclose your information</h3>
              <p>
                We strive to provide you with choices regarding the personal
                information you provide to us. You can set your browser or
                mobile device to refuse all or some browser cookies, or to alert
                you when cookies are being sent. To learn how you can manage
                your Flash cookie settings, visit the Flash player settings page
                on Adobe's website. If you disable or refuse cookies, please
                note that some parts of our Services may then be inaccessible or
                not function properly. We do not share your personal information
                with any advertising agency.
              </p>
              <h3>Communication choices</h3>
              <p>
                When you sign up for an account, you are opting in to receive
                emails from other CMN Network users, businesses, and CMN Network itself. You can
                log in to manage your email preferences and follow the
                "unsubscribe" instructions in commercial email messages, but
                note that you cannot opt out of receiving certain administrative
                policy, service policy, or legal policy from CMN Network.
              </p>
              <h3>Reviewing, changing or deleting information</h3>
              <p>
                If you would like to review, change or delete personal
                information we have collected from you, or permanently delete
                your account, please use the "Contact Us" link on the website.
              </p>
              <h3>Accessing & correcting your personal information</h3>
              <p>
                We will take reasonable steps to accurately record the personal
                information that you provide to us and any subsequent updates.
                We encourage you to review, update, and correct the personal
                information that we maintain about you, and you may request that
                we delete personal information about you that is inaccurate,
                incomplete, or irrelevant for legitimate purposes, or are being
                processed in a way which infringes any applicable legal
                requirement.
              </p>
              <p>
                Your right to review, update, correct, and delete your personal
                information may be limited, subject to the law of your
                jurisdiction:
              </p>
              <ol type="a">
                <li>If your requests are abusive or unreasonably excessive,</li>
                <li>
                  Where the rights or safety of another person or persons would
                  be encroached upon, or
                </li>
                <li>
                  If the information or material you request relates to existing
                  or anticipated legal proceedings between you and us, or
                  providing access to you would prejudice negotiations between
                  us or an investigation of possible unlawful activity. Your
                  right to review, update, correct, and delete your information
                  is subject to our records retention policies and applicable
                  law, including any statutory retention requirements.
                </li>
              </ol>
              <h3>Security: How we protect your information</h3>
              <p>
                We have implemented appropriate physical, electronic, and
                managerial procedures to safeguard and help prevent unauthorized
                access to your information and to maintain data security. These
                safeguards take into account the sensitivity of the information
                that we collect, process and store and the current state of
                technology. We follow generally accepted industry standards to
                protect the personal information submitted to us, both during
                transmission and once we receive it. The third party service
                providers with respect to payment gateway and payment processing
                are all validated as compliant with the payment card industry
                standard (generally referred to as PCI compliant service
                providers).
              </p>
              <p>
                We assume no liability or responsibility for disclosure of your
                information due to errors in transmission, unauthorized
                third-party access, or other causes beyond our control. You play
                an important role in keeping your personal information secure.
                You should not share your user name, password, or other security
                information for your CMN Network account with anyone. If we receive
                instructions using your user name and password, we will consider
                that you have authorized the instructions.
              </p>
              <h3>Permissible Age</h3>
              <p>
                The Services are not intended for users under the age of 18,
                unless permitted under applicable local laws (Permissible Age).
                We do not knowingly collect any personal information from users
                or market to or solicit information from anyone under the
                Permissible Age. If we become aware that a person submitting
                personal information is under the Permissible Age, we will
                delete the account and any related information as soon as
                possible. If you believe we might have any information from or
                about a user under the Permissible Age, please contact us.
              </p>
              <h3>Third party links and services</h3>
              <p>
                The Services may contain links to third-party websites. Your use
                of these features may result in the collection, processing or
                sharing of information about you, depending on the feature.
                Please be aware that we are not responsible for the content or
                privacy practices of other websites or services which may be
                linked on our services. We do not endorse or make any
                representations about third-party websites or services. Our
                Privacy Policy does not cover the information you choose to
                provide to or that is collected by these third parties. We
                strongly encourage you to read such third parties' privacy
                policies.
              </p>
              <h3>Data retention and account termination</h3>
              <p>
                You can close your account by visiting your profile settings
                page on our website. We will remove your public posts from view
                and/or dissociate them from your account profile, but we may
                retain information about you for the purposes authorized under
                this Privacy Policy unless prohibited by law. Thereafter, we
                will either delete your personal information or de-identify it
                so that it is anonymous and not attributed to your identity. For
                example, we may retain information to prevent, investigate, or
                identify possible wrongdoing in connection with the Service or
                to comply with legal obligations.
              </p>
              <h3>Job applicants</h3>
              <p>
                If your information is submitted to us through our Service when
                applying for a position with our company, the information will
                be used to consider your application. We may retain your
                information for any period of time. This information may be
                shared with other companies for the purpose of evaluating your
                qualifications for the particular position or other available
                positions, as well as with third-party service providers
                retained by us to collect, maintain and analyze candidate
                submissions for job postings. For more details please refer
                Privacy Policy at our careers page.
              </p>
              <h3>Changes to this privacy policy</h3>
              <p>
                We reserve the right to amend this Privacy Policy from time to
                time to reflect changes in the law, our data collection and use
                practices, the features of our services, or advances in
                technology. Please check this page periodically for changes. Use
                of information we collect is subject to the Privacy Policy in
                effect at the time such information is used. If we make any
                material changes to this Privacy Policy, we will post the
                changes here. Please review the changes carefully. Your
                continued use of the Services following the posting of changes
                to this Privacy Policy will constitute your consent and
                acceptance of those changes.
              </p>
              <h3>Contact Us</h3>
              <p>
                If you have any queries relating to the processing/ usage of
                information provided by you or CMN Network's Privacy Policy, you may
                Contact Us using details provided on our website or write to us,
                our address:
              </p>
              <p>Our India/Global Address:</p>
              <p>
                Salarpuria Touchstone, No. 133/1-3, First Floor, A Block,
                Kadubeesanahalli, Outer Ring Road, Bangalore - 560103, India
                {/* <span>Salarpuria Touchstone,</span>
                <span>No. 133/1-3, First Floor, A Block,</span>
                <span>Kadubeesanahalli,</span>
                <span>Outer Ring Road,</span>
                <span>Bangalore - 560103, India.</span> */}
              </p>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
