import React from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { height } from "@material-ui/system";
import _ from "lodash";

const commonTypographyProps = {
  sx: {
    margin: "5px",
  },
};

export const renderTextField = (
  label,
  name,
  state,
  handleChange,
  type = "text",
  isDisabled = false
) => {
  const value = _.get(state, name, "");
  return (
    <TextField
      key={name}
      label={type === "date" ? null : label}
      name={name}
      type={type}
      value={value}
      onChange={handleChange}
      fullWidth
      size="small"
      disabled={isDisabled}
      {...commonTypographyProps}
    />
  );
};

export const renderDropdown = (
  label,
  name,
  state,
  handleChange,
  options,
  valueKey,
  labelKey,
  isDisabled = false
) => {
  const value = _.get(state, name, "");
  return (
    <FormControl fullWidth size="small" disabled={isDisabled}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        {...commonTypographyProps}
      >
        {options?.map((option) => (
          <MenuItem key={option[valueKey]} value={option[valueKey]}>
            {option[labelKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const renderMultiSelect = (
  label,
  name,
  countryList,
  state,
  handleChange,
  options,
  valueKey,
  labelKey,
  isDisabled = false
) => {
  const selectedValues = _.get(countryList, name);
  return (
    <Autocomplete
      sx={{ width: "300px", margin: "3px" }}
      multiple
      id={name}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option[labelKey]}
      getOptionValue={(option) => option[valueKey]}
      value={selectedValues}
      onChange={(event, newValue) => {
        handleChange({ target: { name, value: newValue } });
      }}
      isDisabled={isDisabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          {...commonTypographyProps}
          sx={{ width: "300px" }}
        />
      )}
    />
  );
};

export const renderSingleSelect = (
  label,
  name,
  countryList,
  state,
  handleChange,
  options,
  valueKey,
  labelKey,
  isDisabled = false
) => {
  const selectedValues = _.get(countryList, name);
  return (
    <Autocomplete
      sx={{ width: "300px", margin: "3px" }}
      id={name}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option[labelKey]}
      getOptionValue={(option) => option[valueKey]}
      value={selectedValues}
      onChange={(event, newValue) => {
        handleChange({ target: { name, value: newValue } });
      }}
      isDisabled={isDisabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          {...commonTypographyProps}
          sx={{ width: "300px" }}
        />
      )}
    />
  );
};

export const getFormattedTimestamp = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

// export const renderTextField = (
//   label,
//   name,
//   state,
//   handleChange,
//   type = "text",
//   isDisabled = false
// ) => {
//   const value = _.get(state, name, "");
//   if (type === "date") {
//     return (
//       <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
//         <DatePicker
//           value={value ? dayjs(value) : null}
//           onChange={(date) => {
//             const formattedDate = date ? date.format("YYYY-MM-DD") : "";
//             handleChange({ target: { name, value: formattedDate } });
//           }}
//           renderInput={(startProps) => (
//             <TextField
//               {...startProps}
//               label={label}
//               fullWidth
//               size="small"
//               disabled={isDisabled}
//             />
//           )}
//         />
//       </LocalizationProvider>
//     );
//   }
//   return (
//     <TextField
//       key={name}
//       label={label}
//       name={name}
//       type={type}
//       value={value}
//       onChange={handleChange}
//       fullWidth
//       size="small"
//       disabled={isDisabled}
//       {...commonTypographyProps}
//     />
//   );
// };

// export const renderTextFieldForAddress = (
//   label,
//   name,
//   state,
//   handleChange,
//   type = "text",
//   isDisabled = false
// ) => {
//   return (
//     <TextField
//       key={name}
//       label={label}
//       name={name}
//       type={type}
//       value={state[name]}
//       onChange={handleChange}
//       fullWidth
//       size="small"
//       disabled={isDisabled}
//       {...commonTypographyProps}
//     />
//   );
// };
