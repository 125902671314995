import Typography from "@material-ui/core/Typography";
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import BuyAgriProducts from "./BuyAgriProducts";

/* Tab panel  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* end of tab panel */

export default function ExploreAcceseriesAssets() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const orientation = isSmallScreen ? "vertical" : "horizontal";
  const [value, setValue] = useState(0);
  const userRole = localStorage.getItem("UserRole");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <Box
          sx={{
            width: "100%",
            marginBottom: 30,
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "center" },
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "100%",
            }}
          >
            <Tabs
              orientation={orientation}
              value={value}
              onChange={handleChange}
              aria-label="ExploreProducts"
            >
              <Tab
                sx={{ alignItems: "flex-start" }}
                label="AGRI-ASSETS"
                {...a11yProps(0)}
                value={0}
              />
              {userRole === "ADMIN" && (
                <Tab
                  sx={{ alignItems: "flex-start" }}
                  label="AGRI-MACHINERY"
                  {...a11yProps(1)}
                  value={1}
                />
              )}
              <Tab
                sx={{ alignItems: "flex-start" }}
                label="PROCESSING-MACHINERY"
                {...a11yProps(2)}
                value={2}
              />
            </Tabs>
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            {value === 0 && (
              <TabPanel value={value} index={0} style={{ width: "100%" }}>
                <BuyAgriProducts
                  categoryType="AGRI-ASSETS"
                  type={["FOOD-PROCESSOR", "AssetDeveloper", "Broker"]}
                />
              </TabPanel>
            )}
            {value === 1 && userRole === "ADMIN" && (
              <TabPanel value={value} index={1} style={{ width: "100%" }}>
                <BuyAgriProducts categoryType="FARM-MACHINERY" type={["FMC"]} />
              </TabPanel>
            )}
            {value === 2 && (
              <TabPanel value={value} index={2} style={{ width: "100%" }}>
                <BuyAgriProducts
                  categoryType="PROCESSING-MACHINERY"
                  type={["PMC"]}
                />
              </TabPanel>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
}
