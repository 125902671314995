import React from 'react'
import BuyAgriProducts from "../ExploreProducts/BuyAgriProducts";


const ExploreSolution = () => {
  return (
    <BuyAgriProducts categoryType="SOLUTIONS" type={["VerticalUsecaseDeveloper"]} />
  )
}

export default ExploreSolution