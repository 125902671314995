import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./SiteWeatherUpdates.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  menuPaper: {
    width: 200,
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
  menuPaperForm: {
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
}));

export default function SiteUpdatesComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getFarmSites, setFarmSites] = useState([]);
  const [getWeatherReport, setWeatherReport] = useState({});
  const [weatherPopup, setWeatherPopup] = useState(false);

  useEffect(() => {
    if (!parentId) return;
    if (!username) return;
    getAllSites();
  }, [parentId, username]);

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* get Sites Related to Farm */
  const getAllSites = () => {
    setIsLoading(true);
    MainService.viewAllSites(parentId, username)
      .then((data) => {
        if (data["sites"]?.length > 0) {
          const distorgdata = data?.sites?.filter((distorg) => {
            return distorg.sitetype === "Farm";
          });
          setFarmSites(distorgdata);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error("something went wrong");
      });
  };
  /* END get Sites Related to Farm */

  /* get Site Weather */
  const getSiteWeatherHandler = (sitedata) => {
    const data = {
      lat: sitedata.latitude,
      long: sitedata.longitude,
    };
    const strData = JSON.stringify(data);
    MainService.getWeatherReport(strData, username, parentId)
      .then((data) => {
        setWeatherPopup(!weatherPopup);
        setWeatherReport(data);
      })
      .catch((err) => {
        return NotificationManager.error("something went wrong");
      });
  };
  /* END get Site Weather */

  const dateBuilder = (d) => {
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    let day = days[d.getDay()];
    let date = d.getDate();
    let month = months[d.getMonth()];
    let year = d.getFullYear();

    return `${day} ${date} ${month} ${year}`;
  };

  return (
    <>
      <div className="container" id="mainContentList">
        <div className="MuiGrid-root">
          <div style={{ float: "right" }}>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={listViewClick}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={gridViewClick}
            ></Button>
          </div>
          <h2>Site Weather Details</h2>
          {listView && (
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow align="center">
                      <TableCell align="center" colSpan={1}>
                        Site Name
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Site Type
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Latitude
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Longitude
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getFarmSites.length > 0 ? (
                      getFarmSites &&
                      getFarmSites
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((siteupdates, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="center">
                                {siteupdates.sitename}
                              </TableCell>
                              <TableCell align="center">
                                {siteupdates.sitetype}
                              </TableCell>
                              <TableCell align="center">
                                {siteupdates.latitude}
                              </TableCell>
                              <TableCell align="center">
                                {siteupdates.longitude}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow align="center">
                        <TableCell align="center" colSpan={12}>
                          Sites Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={getFarmSites.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          {gridView && (
            <div className="cardStyleForDashboardItems">
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  {getFarmSites.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {getFarmSites &&
                        getFarmSites.map((siteupdates, index) => (
                          <div
                            key={index}
                            className="cardStyleForDashboardItemsBody"
                          >
                            <span>
                              <p>
                                <b>Site Name:</b>
                                {siteupdates.sitename}
                              </p>
                              <p>
                                <b>Site Type:</b>
                                {siteupdates.sitetype}
                              </p>
                            </span>
                            <p>
                              <b>Latitude:</b>
                              {siteupdates.latitude}
                            </p>
                            <p>
                              <b>Longitude:</b>
                              {siteupdates.longitude}
                            </p>
                            <div>
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => {
                                  getSiteWeatherHandler(siteupdates);
                                }}
                              >
                                Site Weather
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <h4>Sites Not Found.</h4>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {/* Weather Report */}
        <Dialog
          fullScreen={fullScreen}
          open={weatherPopup}
          aria-labelledby="weather-report"
        >
          <DialogTitle id="weather-report">{"Weather Report"}</DialogTitle>
          <DialogContent>
            {typeof getWeatherReport.main != "undefined" ? (
              <div className="wether">
                <div className="location-box">
                  <div className="location">
                    {getWeatherReport.name}, {getWeatherReport.sys.country}
                  </div>
                  <div className="date">{dateBuilder(new Date())}</div>
                </div>
                <div className="weather-box">
                  <div className="temp">
                    {Math.round(getWeatherReport.main.temp)}°c
                  </div>
                  <div className="weather">
                    {getWeatherReport.weather[0].main}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                setWeatherPopup(!weatherPopup);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
