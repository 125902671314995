import React, { useEffect, useState } from "react";
import { Box, Button, Fab } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchBar from "../../../../../containers/SearchBar/SearchBar";
import MatTable from "../../../../../containers/table/Table";
import MainService from "../../../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";

const fields = [
  "",
  "Vehicle Id",
  "Vehicle Status",

  "Geofence Exist",
  "Fencing Type",
  "Mapped Device",
  "Last Modified",
];

const VehicleManagement = (props) => {
  const [vehicleDetails, setVehicleDetails] = useState({ assets: [] });
  const [selectedItem, setSelectedItem] = useState("");
  const [assetCount, setAssetCount] = useState({});
  const [searchText, setSearchText] = useState("");
  const [userTypeValue, setUserTypeValue] = useState("");
  const [userTypeRole, setUserTypeRole] = useState("");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  const UserRole = localStorage.getItem("UserRole");

  useEffect(() => {
    getVehicleList();
    getAssetsCountInfo();
  }, []);

  useEffect(() => {
    getUserType();
  }, [userTypeValue]);

  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);

        console.log("USERTYPE", userTypeValue);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  //get vehicle list:
  const getVehicleList = async () => {
    const { assets } = await MainService.getVehicleList(username);

    const assetsWithMappedDevice = assets.map((asset) => {
      const mappedDevice =
        asset.points.length > 0 ? asset.points[0].endnode : "N/A";
      return {
        ...asset,
        coordinates: `Lat: ${asset.latitude}, Lng: ${asset.longitude}`,
        mappedDevice: mappedDevice,
      };
    });

    const filteredAssets = searchText
      ? assetsWithMappedDevice.filter((asset) =>
          asset.assetId.includes(searchText)
        )
      : assetsWithMappedDevice;

    setVehicleDetails({ assets: filteredAssets });
  };

  const onSelect = (item) => {
    setSelectedItem((prevSelectedItem) =>
      prevSelectedItem === item ? "" : item
    );
  };

  const handleClick = () => {
    if (props.isFleet) {
      console.log("clickeeeeeeeeingggggggg", selectedItem);
      props.showGeofenceDetailsHandler(true);
      props.setSelectedItem(selectedItem);
    } else {
      props.history.push(`/main/geofence/${selectedItem}`);
    }
  };

  const getAssetsCountInfo = async () => {
    const { assetCount } = await MainService.getAssetsCountInfo(username);
    setAssetCount(assetCount);
    console.log(assetCount, ".....assetCount...........");
  };

  return (
    <>
      {/* {userTypeValue === "LogisticsProvider" && ( */}
        <div className="container" id="mainContentList">
           
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <SearchBar
                    placeholder={"Search Registration No."}
                    onSearch={(text) => {
                      setSearchText(text);
                      getVehicleList();
                    }}
                  />
                  <Box>
                    <Button
                      id="basic-button"
                      aria-controls={selectedItem ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={selectedItem ? "true" : undefined}
                      onClick={handleClick}
                      variant="contained"
                      disabled={!selectedItem}
                    >
                      Modify
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ marginTop: "1rem" }}>
                  <MatTable
                    fields={fields}
                    rows={vehicleDetails.assets}
                    keys={[
                      "assetId",
                      "assetStatus",
                      "isGeoFence",
                      "geoFence",
                      "mappedDevice",
                      "lastModifiedData",
                    ]}
                    total={assetCount.totalCount}
                    unique="assetId"
                    onSelect={onSelect}
                    change_page={getVehicleList}
                    selected={selectedItem}
                  />
                </Box>
        </div>
      
    </>
  );
};

export default VehicleManagement;
