import React, { memo, Fragment, useReducer, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Box,
  Button,
  DialogActions,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";
import _ from "lodash";

const commonTypographyProps = {
  sx: {
    margin: "5px",
  },
};

const commonTypography = {
  sx: {
    color: "#1a237e",
    textAlign: "left",
    fontSize: "1rem",
    fontWeight: "bold",
    marginBottom: "10px",
  },
};

const initialState = {
  personalDetails: {
    no: "",
    name: "",
    phoneNo: "",
    emailId: "",
    city: "",
    state: "",
    pincode: "",
    landmark: "",
    panNumber: "",
    gstin_Number: "",
    // address: {
    //   addressline1: "",
    //   addressline2: "",
    //   addressline3: "",
    // },
  },
  deliveryAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    pincode: "",
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  supplierDetails: {
    supplierId: "",
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    pincode: "",
    country: "",
    emailId: "",
    phoneNumber: "",
    latitude: Number(),
    longitude: Number(),
    panNumber: Number(),
    gstin_Number: "",
    country: "",
    // address: {
    //   addressline1: "",
    //   addressline2: "",
    //   addressline3: "",
    // },
  },
  growerFarmingSiteAddress: {
    siteId: "",
    siteName: "",
    street: "",
    city: "",
    pincode: Number(),
    country: "",
    latitude: Number(),
    longitude: Number(),
  },
  assetDetails: {
    assetVerticalId: "",
    assetVerticalFriendlyName: "",
    category: "",
    legacyAssetType: "",
    modelId: "",
    cn: "",
    labelInstructions: "",
    origin: "",
    description: "",
  },
  agriRawProductDetails: {
    productCategory: "",
    productSubCategory: "",
    productModelId: "",
    cn: "",
    hsn: "",
    labelInstructions: "",
    grade: "",
    origin: "",
    quantity: Number(),
    unit: "",
    price: "",
    discount: "",
    totalDiscountablePrice: Number(),
  },
  finalPrice: Number(),
  assetPackage: "",
  paymentDetails: {
    transactionId: "",
    paymentDateAndTime: "",
    paymentStatus: "",
    paymentMode: "",
    paymentType: "",
    termsOfDelivery: "",
  },
  orderDocLinks: {
    reportUrl: "",
    reportNo: "",
    invoiceUrl: "",
    invoiceNo: "",
    quotationUrl: "",
    quotationNo: "",
    proformaInvoiceUrl: "",
    proformaInvoiceNo: "",
    lorryReceiptUrl: "",
    lorryReceiptNo: "",
    eWayBillUrl: "",
    eWayBillNo: "",
    deliveryChallanUrl: "",
    deliveryChallanNo: "",
    deliveryReceiptUrl: "",
    deliveryReceiptNo: "",
  },
  networkId: "",
  parentId: "",
  username: "",
  requestType: "cf-agri-raw-products-order-req",
  transport: "",
  certificationLink: "",
  qualityGuideLineLink: "",
  provenanceLink: "",
  fromDate: "",
  toDate: "",
  timeStamp: getFormattedTimestamp(),
};

function formReducer(state, action) {
  return { ...state, [action.field]: action.value };
}

function getFormattedTimestamp() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const RawProductsContractManufacturing = ({
  openModel,
  closeModel,
  productInfo,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [getUserDetails, setUserDetails] = useState({});

  const transport = [{ type: "yes" }, { type: "no" }];
  const grade = [
    { type: "A" },
    { type: "B" },
    { type: "C" },
    { type: "D" },
    { type: "F" },
  ];

  useEffect(() => {
    MainService.getConsumerUserInfo(parentId, username)
      .then((data) => {
        setUserDetails(data[0]);
      })
      .catch((e) => {
        return NotificationManager.error("No profile data found");
      });
  }, []);

  useEffect(() => {
    dispatch({
      field: "networkId",
      value: parentId,
    });
    dispatch({
      field: "parentId",
      value: parentId,
    });
    dispatch({
      field: "username",
      value: username,
    });
  }, []);

  useEffect(() => {
    const defaultPersonalDetails = {
      name: getUserDetails?.username,
      phoneNo: getUserDetails?.phone,
      emailId: getUserDetails?.email,
      city: getUserDetails?.city,
      state: getUserDetails?.state,
      panNumber: getUserDetails?.pancard,
    };
    dispatch({
      field: "personalDetails",
      value: { ...state.personalDetails, ...defaultPersonalDetails },
    });
  }, [getUserDetails]);

  useEffect(() => {
    if (productInfo) {
      const defaultProductDetails = {
        productCategory: productInfo?.categoryname,
        productSubCategory: productInfo?.contentsubcategory,
        productModelId: productInfo?.productid,
        origin: productInfo?.country,
        unit: productInfo?.unit,
        price: productInfo?.productcost,
        discount: productInfo?.discount,
        totalDiscountablePrice: productInfo?.discountedprice,
      };
      dispatch({
        field: "agriRawProductDetails",
        value: { ...state.agriRawProductDetails, ...defaultProductDetails },
      });
    }
  }, [productInfo]);

  useEffect(() => {
    if (productInfo) {
      const defaultAssetDetails = {
        assetVerticalId: productInfo?.assetverticalid,
        assetVerticalFriendlyName: productInfo?.assetverticalname,
        category: productInfo?.category,
        legacyAssetType: productInfo?.legacyassettype,
        modelId: productInfo?.modelid,
        origin: productInfo?.country,
      };
      dispatch({
        field: "assetDetails",
        value: { ...state.assetDetails, ...defaultAssetDetails },
      });
    }
  }, [productInfo]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // if (name.includes("personal.address.")) {
    //   const [identifier, parentName, nestedName] = name.split(".");
    //   dispatch({
    //     field: "personalDetails",
    //     value: {
    //       ...state.personalDetails,
    //       [parentName]: {
    //         ...state.personalDetails[parentName],
    //         [nestedName]: value,
    //       },
    //     },
    //   });
    // } else 
    if (name.includes("personalDetails.")) {
      const [parentNames, nestedNames] = name.split(".");
      dispatch({
        field: "personalDetails",
        value: { ...state[parentNames], [nestedNames]: value },
      });
    } else if (name.includes("deliveryAddress.")) {
      const [parentNamesDel, nestedNamesDel] = name.split(".");
      dispatch({
        field: "deliveryAddress",
        value: { ...state[parentNamesDel], [nestedNamesDel]: value },
      });
    } else if (name.includes("supplierDetails.")) {
      const [parentNamesSup, nestedNamesSup] = name.split(".");
      dispatch({
        field: "supplierDetails",
        value: { ...state[parentNamesSup], [nestedNamesSup]: value },
      });
    } 
    // else if (name.includes("supplier.address.")) {
    //   const [identifier, parentNameSupp, nestedNameSupp] = name.split(".");
    //   dispatch({
    //     field: "supplierDetails",
    //     value: {
    //       ...state.supplierDetails,
    //       [parentNameSupp]: {
    //         ...state.supplierDetails[parentNameSupp],
    //         [nestedNameSupp]: value,
    //       },
    //     },
    //   });
    // } 
    else if (name.includes("growerFarmingSiteAddress.")) {
      const [parentNamesPic, nestedNamesPic] = name.split(".");
      dispatch({
        field: "growerFarmingSiteAddress",
        value: { ...state[parentNamesPic], [nestedNamesPic]: value },
      });
    } else if (name.includes("assetDetails.")) {
      const [parentNamesAss, nestedNamesAss] = name.split(".");
      dispatch({
        field: "assetDetails",
        value: { ...state[parentNamesAss], [nestedNamesAss]: value },
      });
    } else if (name.includes("agriRawProductDetails.")) {
      const [parentNamesProd, nestedNamesProd] = name.split(".");
      dispatch({
        field: "agriRawProductDetails",
        value: { ...state[parentNamesProd], [nestedNamesProd]: value },
      });
    } else if (name.includes("paymentDetails.")) {
      const [parentNamesPay, nestedNamesPay] = name.split(".");
      dispatch({
        field: "paymentDetails",
        value: { ...state[parentNamesPay], [nestedNamesPay]: value },
      });
    } else if (name.includes("orderDocLinks.")) {
      const [parentNamesLink, nestedNamesLink] = name.split(".");
      dispatch({
        field: "orderDocLinks",
        value: { ...state[parentNamesLink], [nestedNamesLink]: value },
      });
    } else {
      dispatch({
        field: name,
        value: value,
      });
    }
  };

  const handleSubmit = () => {
    MainService.orderManagement("cf-agri-raw-products-order-req", state, parentId, username)
      .then((res) => {
        return NotificationManager.success(res.status);
      })
      .catch((err) => {
        return NotificationManager.error(err.Error);
      });
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={openModel}
        onClose={closeModel}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title">
          {"Create Product Item"}
        </DialogTitle> */}
        <DialogContent>
          <Typography variant="h5" {...commonTypography}>
            Personal Details
          </Typography>
          <Box
            bgcolor="#FFFFFF"
            border="1px solid #ddd"
            borderRadius="8px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          >
            <Box display="flex" flexDirection="row">
              {renderTextField("No", "personalDetails.no", state, handleChange)}
              {renderTextField(
                "Username",
                "personalDetails.name",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Phone",
                "personalDetails.phoneNo",
                state,
                handleChange,
                "number",
                "text",
                true
              )}
              {renderTextField(
                "Email",
                "personalDetails.emailId",
                state,
                handleChange,
                "email",
                "text",
                true
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "City",
                "personalDetails.city",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "State",
                "personalDetails.state",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Pincode",
                "personalDetails.pincode",
                state,
                handleChange,
                "number"
              )}
              {renderTextField(
                "Landmark",
                "personalDetails.landmark",
                state,
                handleChange
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "PAN Number",
                "personalDetails.panNumber",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "GST Number",
                "personalDetails.gstin_Number",
                state,
                handleChange
              )}
              {/* {renderTextFieldForAddress(
                "Address Line1",
                "personal.address.addressline1",
                state,
                handleChange
              )}
              {renderTextFieldForAddress(
                "Address Line2",
                "personal.address.addressline2",
                state,
                handleChange
              )}
              {renderTextFieldForAddress(
                "Address Line3",
                "personal.address.addressline3",
                state,
                handleChange
              )} */}
            </Box>
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Delivery Details
          </Typography>
          <Box
            bgcolor="#FFFFFF"
            border="1px solid #ddd"
            borderRadius="8px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          >
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Site ID",
                "deliveryAddress.siteId",
                state,
                handleChange
              )}
              {renderTextField(
                "Site Name",
                "deliveryAddress.siteName",
                state,
                handleChange
              )}
              {renderTextField(
                "Street",
                "deliveryAddress.street",
                state,
                handleChange
              )}
              {renderTextField(
                "City",
                "deliveryAddress.city",
                state,
                handleChange
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Pincode",
                "deliveryAddress.pincode",
                state,
                handleChange,
                "number"
              )}
              {renderTextField(
                "Country",
                "deliveryAddress.country",
                state,
                handleChange
              )}
              {renderTextField(
                "Latitude",
                "deliveryAddress.latitude",
                state,
                handleChange,
                "number"
              )}
              {renderTextField(
                "Longitude",
                "deliveryAddress.longitude",
                state,
                handleChange,
                "number"
              )}
            </Box>
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Supplier Details
          </Typography>
          <Box
            bgcolor="#FFFFFF"
            border="1px solid #ddd"
            borderRadius="8px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          >
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Site Id",
                "supplierDetails.siteId",
                state,
                handleChange
              )}
              {renderTextField(
                "Site Name",
                "supplierDetails.siteName",
                state,
                handleChange
              )}
              {renderTextField(
                "Street",
                "supplierDetails.street",
                state,
                handleChange
              )}
              {renderTextField(
                "City",
                "supplierDetails.city",
                state,
                handleChange
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Pincode",
                "supplierDetails.pincode",
                state,
                handleChange
              )}
              {renderTextField(
                "Email ID",
                "supplierDetails.emailId",
                state,
                handleChange,
                "email"
              )}
              {renderTextField(
                "Phone",
                "supplierDetails.phoneNumber",
                state,
                handleChange,
                "number"
              )}
              {renderTextField(
                "Latitude",
                "supplierDetails.latitude",
                state,
                handleChange,
                "number"
              )}
              {renderTextField(
                "Country",
                "supplierDetails.country",
                state,
                handleChange
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Longitude",
                "supplierDetails.longitude",
                state,
                handleChange,
                "number"
              )}
              {renderTextField(
                "PAN",
                "supplierDetails.panNumber",
                state,
                handleChange
              )}
              {renderTextField(
                "GST Number",
                "supplierDetails.gstin_Number",
                state,
                handleChange
              )}
              {renderTextField(
                "Supplier ID",
                "supplierDetails.supplierId",
                state,
                handleChange
              )}
            </Box>
            {/* <Box display="flex" flexDirection="row">
              {renderTextFieldForAddress(
                "Address Line1",
                "supplier.address.addressline1",
                state,
                handleChange
              )}
              {renderTextFieldForAddress(
                "Address Line2",
                "supplier.address.addressline2",
                state,
                handleChange
              )}
              {renderTextFieldForAddress(
                "Address Line3",
                "supplier.address.addressline3",
                state,
                handleChange
              )}
            </Box> */}
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Grower Farming Site Address
          </Typography>
          <Box
            bgcolor="#FFFFFF"
            border="1px solid #ddd"
            borderRadius="8px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          >
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Site ID",
                "growerFarmingSiteAddress.siteId",
                state,
                handleChange
              )}
              {renderTextField(
                "Site Name",
                "growerFarmingSiteAddress.siteName",
                state,
                handleChange
              )}
              {renderTextField(
                "Street",
                "growerFarmingSiteAddress.street",
                state,
                handleChange
              )}
              {renderTextField(
                "City",
                "growerFarmingSiteAddress.city",
                state,
                handleChange
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Pincode",
                "growerFarmingSiteAddress.pincode",
                state,
                handleChange,
                "number"
              )}
              {renderTextField(
                "Country",
                "growerFarmingSiteAddress.country",
                state,
                handleChange
              )}
              {renderTextField(
                "Latitude",
                "growerFarmingSiteAddress.latitude",
                state,
                handleChange,
                "number"
              )}
              {renderTextField(
                "Longitude",
                "growerFarmingSiteAddress.longitude",
                state,
                handleChange,
                "number"
              )}
            </Box>
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Asset Details
          </Typography>
          <Box
            bgcolor="#FFFFFF"
            border="1px solid #ddd"
            borderRadius="8px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          >
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Asset Vertical Id",
                "assetDetails.assetVerticalId",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Asset Vertical Friendly Name",
                "assetDetails.assetVerticalFriendlyName",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Category",
                "assetDetails.category",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Legacy Asset Type",
                "assetDetails.legacyAssetType",
                state,
                handleChange,
                "text",
                true
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Model Id",
                "assetDetails.modelId",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField("CN", "assetDetails.cn", state, handleChange)}
              {renderTextField(
                "Label Instructions",
                "assetDetails.labelInstructions",
                state,
                handleChange
              )}
              {renderTextField(
                "origin",
                "assetDetails.origin",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Description",
                "assetDetails.description",
                state,
                handleChange
              )}
            </Box>
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Agri Raw Product Details
          </Typography>
          <Box
            bgcolor="#FFFFFF"
            border="1px solid #ddd"
            borderRadius="8px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          >
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Product Category",
                "agriRawProductDetails.productCategory",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Product SubCategory",
                "agriRawProductDetails.productSubCategory",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Product ModelId",
                "agriRawProductDetails.productModelId",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "CN",
                "agriRawProductDetails.cn",
                state,
                handleChange
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "HSN",
                "agriRawProductDetails.hsn",
                state,
                handleChange
              )}
              {renderTextField(
                "Label Instructions",
                "agriRawProductDetails.labelInstructions",
                state,
                handleChange
              )}
              {renderDropdown(
                "Grade",
                "agriRawProductDetails.grade",
                state,
                handleChange,
                grade,
                "type",
                "type"
              )}
              {renderTextField(
                "Origin",
                "agriRawProductDetails.origin",
                state,
                handleChange,
                "text",
                true
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Quantity",
                "agriRawProductDetails.quantity",
                state,
                handleChange
              )}
              {renderTextField(
                "Unit",
                "agriRawProductDetails.unit",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Price",
                "agriRawProductDetails.price",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Discount",
                "agriRawProductDetails.discount",
                state,
                handleChange,
                "text",
                true
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Total Discountable Price",
                "agriRawProductDetails.totalDiscountablePrice",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField("FinalPrice", "finalPrice", state, handleChange)}
              {renderTextField(
                "Asset Package",
                "assetPackage",
                state,
                handleChange
              )}
            </Box>
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Payment Details
          </Typography>
          <Box
            bgcolor="#FFFFFF"
            border="1px solid #ddd"
            borderRadius="8px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          >
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "TransactionId",
                "paymentDetails.transactionId",
                state,
                handleChange
              )}
              {renderTextField(
                "Payment Date&Time",
                "paymentDetails.paymentDateAndTime",
                state,
                handleChange
              )}
              {renderTextField(
                "Payment Status",
                "paymentDetails.paymentStatus",
                state,
                handleChange
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Payment Mode",
                "paymentDetails.paymentMode",
                state,
                handleChange
              )}
              {renderTextField(
                "Payment Type",
                "paymentDetails.paymentType",
                state,
                handleChange
              )}
              {renderTextField(
                "Terms Of Delivery",
                "paymentDetails.termsOfDelivery",
                state,
                handleChange
              )}
            </Box>
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Doc Links
          </Typography>
          <Box
            bgcolor="#FFFFFF"
            border="1px solid #ddd"
            borderRadius="8px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          >
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Report Url",
                "orderDocLinks.reportUrl",
                state,
                handleChange
              )}
              {renderTextField(
                "Report No",
                "orderDocLinks.reportNo",
                state,
                handleChange
              )}
              {renderTextField(
                "Invoice Url",
                "orderDocLinks.invoiceUrl",
                state,
                handleChange
              )}
              {renderTextField(
                "Invoice No",
                "orderDocLinks.invoiceNo",
                state,
                handleChange
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Quotation Url",
                "orderDocLinks.quotationUrl",
                state,
                handleChange
              )}
              {renderTextField(
                "Quotation No",
                "orderDocLinks.quotationNo",
                state,
                handleChange
              )}
              {renderTextField(
                "Proforma Invoice Url",
                "orderDocLinks.proformaInvoiceUrl",
                state,
                handleChange
              )}
              {renderTextField(
                "Proforma Invoice No",
                "orderDocLinks.proformaInvoiceNo",
                state,
                handleChange
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Lorry Receipt Url",
                "orderDocLinks.lorryReceiptUrl",
                state,
                handleChange
              )}
              {renderTextField(
                "Lorry Receipt No",
                "orderDocLinks.lorryReceiptNo",
                state,
                handleChange
              )}
              {renderTextFieldForAddress(
                "eWay Bill Url",
                "orderDocLinks.eWayBillUrl",
                state,
                handleChange
              )}
              {renderTextFieldForAddress(
                "eWay Bill No",
                "orderDocLinks.eWayBillNo",
                state,
                handleChange
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Delivery Challan Url",
                "orderDocLinks.deliveryChallanUrl",
                state,
                handleChange
              )}
              {renderTextField(
                "Delivery Challan No",
                "orderDocLinks.deliveryChallanNo",
                state,
                handleChange
              )}
              {renderTextFieldForAddress(
                "Delivery Receipt Url",
                "orderDocLinks.deliveryReceiptUrl",
                state,
                handleChange
              )}
              {renderTextFieldForAddress(
                "Delivery Receipt No",
                "orderDocLinks.deliveryReceiptNo",
                state,
                handleChange
              )}
            </Box>
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Requeired Details
          </Typography>
          <Box
            bgcolor="#FFFFFF"
            border="1px solid #ddd"
            borderRadius="8px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          >
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "Network Id",
                "networkId",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Parent Id",
                "parentId",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Username",
                "username",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Request Type",
                "requestType",
                state,
                handleChange,
                "text",
                true
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderDropdown(
                "Transport",
                "transport",
                state,
                handleChange,
                transport,
                "type",
                "type"
              )}
              {renderTextField(
                "Certification Link",
                "certificationLink",
                state,
                handleChange
              )}
              {renderTextField(
                "Quality Guide Line Link",
                "qualityGuideLineLink",
                state,
                handleChange
              )}
              {renderTextField(
                "Provenance Link",
                "provenanceLink",
                state,
                handleChange
              )}
            </Box>
            <Box display="flex" flexDirection="row">
              {renderTextField(
                "From Date",
                "fromDate",
                state,
                handleChange,
                "date"
              )}
              {renderTextField(
                "To Date",
                "toDate",
                state,
                handleChange,
                "date"
              )}
              {renderTextField(
                "Time Stamp",
                "timeStamp",
                state,
                handleChange,
                "text",
                true
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
          <Button color="error" variant="outlined" onClick={closeModel}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const renderTextField = (
  label,
  name,
  state,
  handleChange,
  type = "text",
  isDisabled = false
) => {
  const value = _.get(state, name, "");
  return (
    <TextField
      key={name}
      label={label}
      name={name}
      type={type}
      value={value}
      onChange={handleChange}
      fullWidth
      size="small"
      disabled={isDisabled}
      {...commonTypographyProps}
    />
  );
};

const renderTextFieldForAddress = (
  label,
  name,
  state,
  handleChange,
  type = "text",
  isDisabled = false
) => {
  return (
    <TextField
      key={name}
      label={label}
      name={name}
      type={type}
      value={state[name]}
      onChange={handleChange}
      fullWidth
      size="small"
      disabled={isDisabled}
      {...commonTypographyProps}
    />
  );
};

const renderDropdown = (
  label,
  name,
  state,
  handleChange,
  options,
  valueKey,
  labelKey,
  isDisabled = false
) => {
  const value = _.get(state, name, "");
  return (
    <FormControl fullWidth size="small" disabled={isDisabled}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        {...commonTypographyProps}
      >
        {options?.map((option) => (
          <MenuItem key={option[valueKey]} value={option[valueKey]}>
            {option[labelKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default memo(RawProductsContractManufacturing);
