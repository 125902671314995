import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import countryList from "country-list";
import React, { Fragment, memo, useEffect, useReducer, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../../../Common/MainService";
import {
  renderDropdown,
  renderTextField,
  grade,
  packageTypes,
  availableornot,
  transport,
  modeOfPayment,
  commonTypography,
  border,
  filedResponsive,
} from "../../OrderCommon";
import OrderRequestTypeConfig from "../ProductOrder/OrderRequestTypeConfig";
import {
  initialState,
  initialStatecfagrirawproductsorderreq,
  initialstatecmproductorderreq,
  storageproductorderreq,
  transportproductorderreq,
} from "../ProductOrder/StateMaintananceOfProductOrder";
// import validateForm from "../../validateForm";

function formReducer(state, action) {
  switch (action.type) {
    case "EMPTY_STATE":
      return {};
    case "UPDATE_STATE":
      return { ...state, ...action.payload };
    default:
      return { ...state, [action.field]: action.value };
  }
}

const FormForOrderProduct = ({
  openModel,
  closeModel,
  verticalsIds,
  categoryTypes,
  assertTypes,
  models,
  categoryName,
  getSubCategories,
  reLoad,
  reqTypesBasedOnRoleandUser,
  props,
}) => {
  console.log("props props", props);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getReqVal, setReqVal] = useState("");
  const [getreqArr, setReqArr] = useState([]);
  const [getUserDetails, setUserDetails] = useState({});
  const [state, dispatch] = useReducer(formReducer, {});
  const [getCountryList, setCountryList] = useState([]);
  const getAppConfig = () => window.AppConfigData;
  const countries = countryList.getData();
  const countryOptions = countries?.map((country) => ({
    value: country?.code,
    label: country?.name,
  }));
  const userType = localStorage.getItem("userType");
  const userRole = localStorage.getItem("UserRole");
  const [selectedReqType, setSelectedReqType] = useState("");
  const [quoteDetails, setQuoteDetails] = useState([]);
  // const [errors, setErrors] = useState({});

  //Based on request type we need to  chnage state, means that paticular request type related state we need to assign component
  useEffect(() => {
    dispatch({ type: "EMPTY_STATE" });

    const payloadMap = {
      "PROCESSED-PRODUCTS": {
        "agri-buy-products-order-req": initialState,
        "cm-product-order-req": initialstatecmproductorderreq,
        "transport-product-order-req": transportproductorderreq,
        "storage-product-order-req": storageproductorderreq,
      },
      "RAW-PRODUCTS": {
        "agri-buy-products-order-req": initialState,
        "cf-agri-raw-products-order-req": initialStatecfagrirawproductsorderreq,
        "transport-product-order-req": transportproductorderreq,
        "storage-product-order-req": storageproductorderreq,
      },
      "SEMI-PROCESSED": {
        "agri-buy-products-order-req": initialState,
        "cm-product-order-req": initialstatecmproductorderreq,
        "transport-product-order-req": transportproductorderreq,
        "storage-product-order-req": storageproductorderreq,
      },
    };

    const initializeFormState = () => {
      const payload = payloadMap[categoryName]?.[getReqVal] || {};
      dispatch({ type: "UPDATE_STATE", payload });
    };

    initializeFormState();
  }, [getReqVal, categoryName]);

  // props data handling functionalty ex:- developer not passing any props we need to handle here
  useEffect(() => {
    let userConfig;
    if (
      reqTypesBasedOnRoleandUser === undefined ||
      reqTypesBasedOnRoleandUser === null ||
      typeof reqTypesBasedOnRoleandUser !== "object"
    ) {
      userConfig =
        OrderRequestTypeConfig[userRole]?.[userType]?.[categoryName] || [];
    } else {
      userConfig =
        reqTypesBasedOnRoleandUser[userRole]?.[userType]?.[categoryName] || [];
    }
    setReqVal(userConfig?.length > 0 ? userConfig[0]?.type : "");
    setSelectedReqType(userConfig?.length > 0 ? userConfig[0]?.type : "");
    setReqArr(userConfig);
  }, [userRole, userType, categoryName, reqTypesBasedOnRoleandUser]);

  // getting personal details here
  useEffect(() => {
    MainService.getConsumerUserInfo(parentId, username)
      .then((data) => {
        setUserDetails(data[0]);
      })
      .catch((e) => {
        return NotificationManager.error("No profile data found");
      });
    dispatch({
      field: "requestType",
      value: getReqVal,
    });
  }, [getReqVal]);

  // based on rfq id we are getting invoice id here
  useEffect(() => {
    if (state.rfqId === null || state.rfqId === undefined || state.rfqId === "")
      return;
    viewQuotesHandler();
  }, [state.rfqId]);

  const viewQuotesHandler = () => {
    MainService.viewQuotes(state.rfqId, parentId, username)
      .then((res) => {
        setQuoteDetails(res);
      })
      .catch((err) => {
        return NotificationManager.error(err?.message);
      });
  };

  // personal details auto populated here
  useEffect(() => {
    const defaultPersonalDetails = {
      networkId: getAppConfig().EnterpriseId,
      parentId: getUserDetails?.enterpriseid,
      username: getUserDetails?.username,
      name: getUserDetails?.username,
      phoneNo: getUserDetails?.phone,
      emailId: getUserDetails?.email,
      city: getUserDetails?.city,
      state: getUserDetails?.state,
      panNumber: getUserDetails?.pancard,
      latitude: getUserDetails?.latitude,
      longitude: getUserDetails?.longitude,
    };
    dispatch({
      field: "personalDetails",
      value: { ...state.personalDetails, ...defaultPersonalDetails },
    });
  }, [getUserDetails]);

  // product details auto populated here
  useEffect(() => {
    if (getReqVal) {
      const defaultProductDetails = {
        productCategory: categoryName,
        productSubCategory: "",
        productModelId: "",
        cn: "",
        hsn: "",
        labelInstructions: "",
        grade: "",
        origin: "",
        quantity: Number(),
        unit: "",
        price: "",
        discount: "",
        totalDiscountablePrice: Number(),
      };
      dispatch({
        field: "productDetails",
        value: [defaultProductDetails], // Set productDetails to an array
      });
    }
  }, [getReqVal, categoryName]);

  // supplier details auto populated here
  useEffect(() => {
    if (getReqVal) {
      const defaultSuppliersDetails = {
        supplierId: props?.supplierDetails?.supplierId ?? "",
        siteId: props?.supplierDetails?.siteId ?? "",
        siteName: props?.supplierDetails?.siteName ?? "",
        street: props?.supplierDetails?.street ?? "",
        city: props?.supplierDetails?.city ?? "",
        state: props?.supplierDetails?.state ?? "",
        pincode: props?.supplierDetails?.pincode ?? "",
        country: props?.supplierDetails?.country ?? "",
        emailId: props?.supplierDetails?.emailId ?? "",
        phoneNo: props?.supplierDetails?.phoneNo ?? "",
        latitude: props?.supplierDetails?.latitude ?? "",
        longitude: props?.supplierDetails?.longitude ?? "",
        panNumber: props?.supplierDetails?.panNumber ?? "",
        gstin_Number: props?.supplierDetails?.gstin_Number ?? "",
      };
      dispatch({
        field: "supplierDetails",
        value: { ...state.supplierDetails, ...defaultSuppliersDetails },
      });
    }
  }, [getReqVal, props?.supplierDetails]);

  // delivery address details auto populated here
  useEffect(() => {
    if (getReqVal) {
      const defaultDeliveryAddress = {
        siteId: props?.deliveryAddress?.siteId ?? "",
        siteName: props?.deliveryAddress?.siteName ?? "",
        street: props?.deliveryAddress?.street ?? "",
        city: props?.deliveryAddress?.city ?? "",
        state: props?.deliveryAddress?.state ?? "",
        pincode: props?.deliveryAddress?.pincode ?? "",
        country: props?.deliveryAddress?.country ?? "",
        latitude: props?.deliveryAddress?.latitude ?? "",
        longitude: props?.deliveryAddress?.longitude ?? "",
      };
      dispatch({
        field: "deliveryAddress",
        value: { ...state.deliveryAddress, ...defaultDeliveryAddress },
      });
    }
  }, [getReqVal, props?.deliveryAddress]);

  // pickup address details auto populated here
  useEffect(() => {
    if (getReqVal) {
      const defaultPickupAddress = {
        siteId: props?.pickUpAddress?.siteId ?? "",
        siteName: props?.pickUpAddress?.siteName ?? "",
        street: props?.pickUpAddress?.street ?? "",
        city: props?.pickUpAddress?.city ?? "",
        state: props?.pickUpAddress?.state ?? "",
        pincode: props?.pickUpAddress?.pincode ?? "",
        country: props?.pickUpAddress?.country ?? "",
        latitude: props?.pickUpAddress?.latitude ?? "",
        longitude: props?.pickUpAddress?.longitude ?? "",
      };
      dispatch({
        field: "pickUpAddress",
        value: { ...state.pickUpAddress, ...defaultPickupAddress },
      });
    }
  }, [getReqVal, props?.pickUpAddress]);

  // delete added product details handle here
  const handleDeleteProduct = (index) => {
    const updatedProductDetails = [...state.productDetails];
    updatedProductDetails.splice(index, 1); // Remove the product at the specified index
    dispatch({
      field: "productDetails",
      value: updatedProductDetails,
    });
  };

  // multiple products added handle here
  const handleAddProduct = () => {
    const newProduct = {
      productCategory: "",
      productSubCategory: "",
      productModelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      grade: "",
      origin: "",
      quantity: Number(),
      unit: "",
      price: "",
      discount: "",
      totalDiscountablePrice: Number(),
    };
    // Create a copy of the product details array
    const updatedProductDetails = [...state.productDetails];
    // Push the new product into the copied array
    updatedProductDetails.push(newProduct);
    // Dispatch the updated array with the default product details appended
    dispatch({
      field: "productDetails",
      value: updatedProductDetails.map((product, index) => {
        // If it's the last item in the array, add default product details
        if (index === updatedProductDetails.length - 1) {
          return {
            ...product,
            productCategory: categoryName,
          };
        }
        return product;
      }),
    });
  };

  // delete added asset details handle here
  const handleDeleteAsset = (index) => {
    const updatedAssetDetails = [...state.assetDetails];
    updatedAssetDetails.splice(index, 1); // Remove the asset at the specified index
    dispatch({
      field: "assetDetails",
      value: updatedAssetDetails,
    });
  };

  // multiple assets added handle here
  const handleAddAsset = () => {
    const newAsset = {
      assetVerticalId: "",
      assetVerticalFriendlyName: "",
      category: "",
      legacyAssetType: "",
      modelId: "",
      cn: "",
      hsn: "",
      labelInstructions: "",
      origin: "",
      description: "",
    };
    // Create a copy of the asset details array
    const updatedAssetDetails = Array.isArray(state.assetDetails)
      ? [...state.assetDetails]
      : [];
    // Push the new asset into the copied array
    updatedAssetDetails.push(newAsset);
    // Dispatch the updated array with the default asset details appended
    dispatch({
      field: "assetDetails",
      value: updatedAssetDetails.map((asset, index) => {
        // If it's the last item in the array, add default asset details
        if (index === updatedAssetDetails.length - 1) {
          return {
            ...asset,
          };
        }
        return asset;
      }),
    });
  };

  //form fileds changeing functionalty handle here
  const handleChange = (event, index) => {
    const { name, value } = event.target;
    // let updatedErrors = { ...errors }; // Define updatedErrors here
    if (name.includes("personalDetails.")) {
      const [parentNames, nestedNames] = name.split(".");
      dispatch({
        field: "personalDetails",
        value: { ...state[parentNames], [nestedNames]: value },
      });
    } else if (name.includes("companyAdditionalInformation.")) {
      const [parentNamesAddInfo, nestedNamesAddInfo] = name.split(".");
      dispatch({
        field: "companyAdditionalInformation",
        value: { ...state[parentNamesAddInfo], [nestedNamesAddInfo]: value },
      });
    } else if (name.includes("deliveryAddress.")) {
      const [parentNamesDel, nestedNamesDel] = name.split(".");
      dispatch({
        field: "deliveryAddress",
        value: { ...state[parentNamesDel], [nestedNamesDel]: value },
      });
    } else if (name.includes("supplierDetails.")) {
      const [parentNamesSup, nestedNamesSup] = name.split(".");
      dispatch({
        field: "supplierDetails",
        value: { ...state[parentNamesSup], [nestedNamesSup]: value },
      });
    } else if (name.includes("pickUpAddress.")) {
      const [parentNamesPicup, nestedNamesPicup] = name.split(".");
      dispatch({
        field: "pickUpAddress",
        value: { ...state[parentNamesPicup], [nestedNamesPicup]: value },
      });
    } else if (name.includes("manufacturingPlantAddress.")) {
      const [parentNamesPicup, nestedNamesPicup] = name.split(".");
      dispatch({
        field: "manufacturingPlantAddress",
        value: { ...state[parentNamesPicup], [nestedNamesPicup]: value },
      });
    } else if (name.includes("growerFarmingSiteAddress.")) {
      const [parentNamesGFS, nestedNamesGFS] = name.split(".");
      dispatch({
        field: "growerFarmingSiteAddress",
        value: { ...state[parentNamesGFS], [nestedNamesGFS]: value },
      });
    } else if (name.includes(`assetDetails[${index}].`)) {
      const updatedAssetDetails = Array.isArray(state.assetDetails)
        ? [...state.assetDetails]
        : [];
      // Split the name to get the property and nested property
      const [propertyName, nestedProperty] = name.split(".");
      // Check if nested property exists
      if (nestedProperty) {
        // If nested property exists, update it in the respective object
        updatedAssetDetails[index] = {
          ...updatedAssetDetails[index],
          [nestedProperty]: value,
        };
      } else {
        // If no nested property, update the property directly
        updatedAssetDetails[index][propertyName] = value;
      }
      // Dispatch updated product details to state
      dispatch({
        field: "assetDetails",
        value: updatedAssetDetails,
      });
    } else if (name.includes(`productDetails[${index}].`)) {
      const updatedProductDetails = [...state.productDetails];
      // Split the name to get the property and nested property
      const [propertyName, nestedProperty] = name.split(".");
      // Check if nested property exists
      if (nestedProperty) {
        // If nested property exists, update it in the respective object
        updatedProductDetails[index] = {
          ...updatedProductDetails[index],
          [nestedProperty]: value,
        };
      } else {
        // If no nested property, update the property directly
        updatedProductDetails[index][propertyName] = value;
      }
      // Dispatch updated product details to state
      dispatch({
        field: "productDetails",
        value: updatedProductDetails,
      });
    } else if (name.includes("transportDetails.")) {
      const [parentNamesReq, nestedNamesReq] = name.split(".");
      dispatch({
        field: "transportDetails",
        value: { ...state[parentNamesReq], [nestedNamesReq]: value },
      });
    } else if (name.includes("storageDetails.")) {
      const [parentNamesReq, nestedNamesReq] = name.split(".");
      dispatch({
        field: "storageDetails",
        value: { ...state[parentNamesReq], [nestedNamesReq]: value },
      });
    } else if (name.includes("requirements.")) {
      const [parentNamesReq, nestedNamesReq] = name.split(".");
      dispatch({
        field: "requirements",
        value: { ...state[parentNamesReq], [nestedNamesReq]: value },
      });
    } else {
      dispatch({
        field: name,
        value: value,
      });
    }
    // Clear the error for the field if it becomes valid
    // if (updatedErrors[name]) {
    //   updatedErrors = { ...updatedErrors, [name]: "" };
    //   setErrors(updatedErrors);
    // }
  };

  //form submit functionality here
  const handleSubmit = () => {
    // const formErrors = validateForm(state);
    // setErrors(formErrors);
    // if (Object.keys(formErrors).length === 0) {
    //   // Submit form if there are no errors
    //   MainService.orderManagement(getReqVal, state, parentId, username)
    //     .then((res) => {
    //       return NotificationManager.success(res?.status);
    //     })
    //     .catch((err) => {
    //       return NotificationManager.error(
    //         err?.message || "something went wrong"
    //       );
    //     })
    //     .finally(() => {
    //       reLoad();
    //     });
    //   console.log("Form submitted successfully");
    // }
    MainService.orderManagement(getReqVal, state, parentId, username)
      .then((res) => {
        return NotificationManager.success(res?.status);
      })
      .catch((err) => {
        return NotificationManager.error(
          err?.message || "something went wrong"
        );
      })
      .finally(() => {
        reLoad();
      });
  };

  //request type changing functionalty here
  const handleButtonClick = (type) => {
    setReqVal(type);
    setSelectedReqType(type);
  };

  return (
    <Fragment>
      <Dialog
        fullScreen
        open={openModel}
        onClose={closeModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              margin: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getreqArr &&
              getreqArr?.map((reqType) => (
                <Box
                  key={reqType.type}
                  sx={{
                    width: { xs: "100%", sm: "70%", md: "auto" },
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => handleButtonClick(reqType.type)}
                    sx={{
                      backgroundColor:
                        selectedReqType === reqType.type
                          ? "#3ECF8E"
                          : "inherit",
                      color:
                        selectedReqType === reqType.type
                          ? "primary"
                          : "inherit",
                      width: "100%",
                      height: "100%",
                      minWidth: 0,
                      minHeight: 0,
                    }}
                  >
                    {reqType.type}
                  </Button>
                </Box>
              ))}
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Generated Id's
          </Typography>
          <Box {...border}>
            <Box {...filedResponsive}>
              {renderTextField(
                "Rfq Id",
                "rfqId",
                state,
                handleChange,
                "text"
                // errors
              )}
              {renderDropdown(
                "Proforma Invoice Id",
                "proformaInvoiceId",
                state,
                handleChange,
                quoteDetails,
                "proformaInvoiceNo",
                "proformaInvoiceNo"
              )}
            </Box>
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Personal Details
          </Typography>
          <Box {...border}>
            <Box {...filedResponsive}>
              {renderTextField(
                "Network Id",
                "personalDetails.networkId",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Parent Id",
                "personalDetails.parentId",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Username",
                "personalDetails.username",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Site Name",
                "personalDetails.siteName",
                state,
                handleChange
              )}
            </Box>
            <Box {...filedResponsive}>
              {renderTextField("No", "personalDetails.no", state, handleChange)}
              {renderTextField(
                "Username",
                "personalDetails.name",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Phone",
                "personalDetails.phoneNo",
                state,
                handleChange,
                "number",
                "text",
                true
              )}
              {renderTextField(
                "Email",
                "personalDetails.emailId",
                state,
                handleChange,
                "email",
                "text",
                true
              )}
            </Box>
            <Box {...filedResponsive}>
              {renderTextField(
                "Street",
                "personalDetails.street",
                state,
                handleChange
              )}
              {renderTextField(
                "City",
                "personalDetails.city",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "State",
                "personalDetails.state",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Country",
                "personalDetails.country",
                state,
                handleChange
              )}
              {renderTextField(
                "Pincode",
                "personalDetails.pincode",
                state,
                handleChange,
                "number"
              )}
            </Box>
            <Box {...filedResponsive}>
              {renderTextField(
                "Landmark",
                "personalDetails.landmark",
                state,
                handleChange
              )}
              {renderTextField(
                "PAN Number",
                "personalDetails.panNumber",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Latitude",
                "personalDetails.latitude",
                state,
                handleChange
              )}
              {renderTextField(
                "Longitude",
                "personalDetails.longitude",
                state,
                handleChange
              )}
            </Box>
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Company Additional Information
          </Typography>
          <Box {...border}>
            <Box {...filedResponsive}>
              {renderTextField(
                "Company Name",
                "companyAdditionalInformation.companyName",
                state,
                handleChange
              )}
              {renderTextField(
                "Business Type",
                "companyAdditionalInformation.businessType",
                state,
                handleChange
              )}
              {renderTextField(
                "Country",
                "companyAdditionalInformation.country",
                state,
                handleChange
              )}
              {renderTextField(
                "Year Established",
                "companyAdditionalInformation.yearEstablished",
                state,
                handleChange,
                "date"
              )}
              {renderTextField(
                "Number Of Employees",
                "companyAdditionalInformation.numberOfEmployees",
                state,
                handleChange
              )}
              {renderTextField(
                "Annual Sales Revenue",
                "companyAdditionalInformation.annualSalesRevenue",
                state,
                handleChange
              )}
            </Box>
            <Box {...filedResponsive}>
              {renderTextField(
                "Company Webite",
                "companyAdditionalInformation.companyWebite",
                state,
                handleChange
              )}
              {renderDropdown(
                "MyCompany Has No Webite",
                "companyAdditionalInformation.myCompanyHasNoWebite",
                state,
                handleChange,
                availableornot,
                "type",
                "type"
              )}
              {renderTextField(
                "Pan Number",
                "companyAdditionalInformation.panNumber",
                state,
                handleChange
              )}
              {renderTextField(
                "GST Number",
                "companyAdditionalInformation.gstin_Number",
                state,
                handleChange
              )}
              {renderTextField(
                "Company Description",
                "companyAdditionalInformation.companyDescription",
                state,
                handleChange
              )}
            </Box>
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Delivery Details
          </Typography>
          <Box {...border}>
            <Box {...filedResponsive}>
              {renderTextField(
                "Site ID",
                "deliveryAddress.siteId",
                state,
                handleChange
              )}
              {renderTextField(
                "Site Name",
                "deliveryAddress.siteName",
                state,
                handleChange
              )}
              {renderTextField(
                "Street",
                "deliveryAddress.street",
                state,
                handleChange
              )}
              {renderTextField(
                "City",
                "deliveryAddress.city",
                state,
                handleChange
              )}
              {renderTextField(
                "State",
                "deliveryAddress.state",
                state,
                handleChange
              )}
            </Box>
            <Box {...filedResponsive}>
              {renderTextField(
                "Pincode",
                "deliveryAddress.pincode",
                state,
                handleChange,
                "number"
              )}
              {renderTextField(
                "Country",
                "deliveryAddress.country",
                state,
                handleChange
              )}
              {renderTextField(
                "Latitude",
                "deliveryAddress.latitude",
                state,
                handleChange,
                "number"
              )}
              {renderTextField(
                "Longitude",
                "deliveryAddress.longitude",
                state,
                handleChange,
                "number"
              )}
            </Box>
          </Box>
          <Typography variant="h5" {...commonTypography}>
            Supplier Details
          </Typography>
          <Box {...border}>
            <Box {...filedResponsive}>
              {renderTextField(
                "Site Id",
                "supplierDetails.siteId",
                state,
                handleChange
              )}
              {renderTextField(
                "Site Name",
                "supplierDetails.siteName",
                state,
                handleChange
              )}
              {renderTextField(
                "Street",
                "supplierDetails.street",
                state,
                handleChange
              )}
              {renderTextField(
                "City",
                "supplierDetails.city",
                state,
                handleChange
              )}
              {renderTextField(
                "State",
                "supplierDetails.state",
                state,
                handleChange
              )}
            </Box>
            <Box {...filedResponsive}>
              {renderTextField(
                "Pincode",
                "supplierDetails.pincode",
                state,
                handleChange
              )}
              {renderTextField(
                "Country",
                "supplierDetails.country",
                state,
                handleChange
              )}
              {renderTextField(
                "Email ID",
                "supplierDetails.emailId",
                state,
                handleChange,
                "email"
              )}
              {renderTextField(
                "Phone",
                "supplierDetails.phoneNo",
                state,
                handleChange,
                "number"
              )}
              {renderTextField(
                "Latitude",
                "supplierDetails.latitude",
                state,
                handleChange,
                "number"
              )}
            </Box>
            <Box {...filedResponsive}>
              {renderTextField(
                "Longitude",
                "supplierDetails.longitude",
                state,
                handleChange,
                "number"
              )}
              {renderTextField(
                "PAN",
                "supplierDetails.panNumber",
                state,
                handleChange
              )}
              {renderTextField(
                "GST Number",
                "supplierDetails.gstin_Number",
                state,
                handleChange
              )}
              {renderTextField(
                "Supplier ID",
                "supplierDetails.supplierId",
                state,
                handleChange
              )}
            </Box>
            {/* <Box {...filedResponsive}>
              {renderTextFieldForAddress(
                "Address Line1",
                "supplier.address.addressline1",
                state,
                handleChange
              )}
              {renderTextFieldForAddress(
                "Address Line2",
                "supplier.address.addressline2",
                state,
                handleChange
              )}
              {renderTextFieldForAddress(
                "Address Line3",
                "supplier.address.addressline3",
                state,
                handleChange
              )}
            </Box> */}
          </Box>
          {(() => {
            if (
              (categoryName === "PROCESSED-PRODUCTS" &&
                getReqVal === "agri-buy-products-order-req") ||
              (categoryName === "SEMI-PROCESSED" &&
                getReqVal === "agri-buy-products-order-req") ||
              (categoryName === "RAW-PRODUCTS" &&
                getReqVal === "agri-buy-products-order-req") ||
              (categoryName === "PROCESSED-PRODUCTS" &&
                getReqVal === "transport-product-order-req") ||
              (categoryName === "RAW-PRODUCTS" &&
                getReqVal === "transport-product-order-req") ||
              (categoryName === "SEMI-PROCESSED" &&
                getReqVal === "transport-product-order-req") ||
              (categoryName === "PROCESSED-PRODUCTS" &&
                getReqVal === "storage-product-order-req") ||
              (categoryName === "SEMI-PROCESSED" &&
                getReqVal === "storage-product-order-req") ||
              (categoryName === "RAW-PRODUCTS" &&
                getReqVal === "storage-product-order-req")
            ) {
              return (
                <>
                  <Typography variant="h5" {...commonTypography}>
                    Pickup Address
                  </Typography>
                  <Box {...border}>
                    <Box {...filedResponsive}>
                      {renderTextField(
                        "Site ID",
                        "pickUpAddress.siteId",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Site Name",
                        "pickUpAddress.siteName",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Street",
                        "pickUpAddress.street",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "City",
                        "pickUpAddress.city",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "State",
                        "pickUpAddress.state",
                        state,
                        handleChange
                      )}
                    </Box>
                    <Box {...filedResponsive}>
                      {renderTextField(
                        "Pincode",
                        "pickUpAddress.pincode",
                        state,
                        handleChange,
                        "number"
                      )}
                      {renderTextField(
                        "Country",
                        "pickUpAddress.country",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Latitude",
                        "pickUpAddress.latitude",
                        state,
                        handleChange,
                        "number"
                      )}
                      {renderTextField(
                        "Longitude",
                        "pickUpAddress.longitude",
                        state,
                        handleChange,
                        "number"
                      )}
                    </Box>
                  </Box>
                </>
              );
            }
          })()}
          {(() => {
            if (
              (categoryName === "PROCESSED-PRODUCTS" &&
                getReqVal === "cm-product-order-req") ||
              (categoryName === "SEMI-PROCESSED" &&
                getReqVal === "cm-product-order-req")
            ) {
              return (
                <>
                  <Typography variant="h5" {...commonTypography}>
                    Manufacturing Plant Address
                  </Typography>
                  <Box {...border}>
                    <Box {...filedResponsive}>
                      {renderTextField(
                        "Site ID",
                        "manufacturingPlantAddress.siteId",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Site Name",
                        "manufacturingPlantAddress.siteName",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Street",
                        "manufacturingPlantAddress.street",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "City",
                        "manufacturingPlantAddress.city",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "State",
                        "manufacturingPlantAddress.state",
                        state,
                        handleChange
                      )}
                    </Box>
                    <Box {...filedResponsive}>
                      {renderTextField(
                        "Pincode",
                        "manufacturingPlantAddress.pincode",
                        state,
                        handleChange,
                        "number"
                      )}
                      {renderTextField(
                        "Country",
                        "manufacturingPlantAddress.country",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Latitude",
                        "manufacturingPlantAddress.latitude",
                        state,
                        handleChange,
                        "number"
                      )}
                      {renderTextField(
                        "Longitude",
                        "manufacturingPlantAddress.longitude",
                        state,
                        handleChange,
                        "number"
                      )}
                    </Box>
                  </Box>
                </>
              );
            } else if (
              categoryName === "RAW-PRODUCTS" &&
              getReqVal === "cf-agri-raw-products-order-req"
            ) {
              return (
                <>
                  <Typography variant="h5" {...commonTypography}>
                    Grower Farming Site Address
                  </Typography>
                  <Box {...border}>
                    <Box {...filedResponsive}>
                      {renderTextField(
                        "Site ID",
                        "growerFarmingSiteAddress.siteId",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Site Name",
                        "growerFarmingSiteAddress.siteName",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Street",
                        "growerFarmingSiteAddress.street",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "City",
                        "growerFarmingSiteAddress.city",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "State",
                        "growerFarmingSiteAddress.state",
                        state,
                        handleChange
                      )}
                    </Box>
                    <Box {...filedResponsive}>
                      {renderTextField(
                        "Pincode",
                        "growerFarmingSiteAddress.pincode",
                        state,
                        handleChange,
                        "number"
                      )}
                      {renderTextField(
                        "Country",
                        "growerFarmingSiteAddress.country",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Latitude",
                        "growerFarmingSiteAddress.latitude",
                        state,
                        handleChange,
                        "number"
                      )}
                      {renderTextField(
                        "Longitude",
                        "growerFarmingSiteAddress.longitude",
                        state,
                        handleChange,
                        "number"
                      )}
                    </Box>
                  </Box>
                </>
              );
            }
          })()}
          <Box
            display="flex"
            alignItems="center"
            p={0.5} // Add padding as needed
          >
            <Typography variant="h5" {...commonTypography}>
              Asset Details
            </Typography>
            <Button
              onClick={handleAddAsset}
              sx={{
                color: "primary.main",
                marginLeft: "auto",
                border: "1px solid #ccc",
              }}
            >
              <AddIcon sx={{ color: "primary.main", marginRight: 1 }} /> Add
              More Assets
            </Button>
          </Box>
          {Array.isArray(state.assetDetails) &&
            state.assetDetails.map((asset, index) => (
              <Box {...border} key={index}>
                {/* Render delete icon */}
                <IconButton onClick={() => handleDeleteAsset(index)}>
                  <DeleteIcon />
                </IconButton>
                {/* Rest of the fields */}
                <Box {...filedResponsive}>
                  {renderDropdown(
                    "Asset Vertical Id",
                    `assetDetails[${index}].assetVerticalId`,
                    state,
                    (event) => handleChange(event, index),
                    verticalsIds,
                    "assetVerticalId",
                    "friendlyName"
                  )}
                  {renderDropdown(
                    "Asset Vertical Friendly Name",
                    `assetDetails[${index}].assetVerticalFriendlyName`,
                    state,
                    (event) => handleChange(event, index),
                    verticalsIds,
                    "friendlyName",
                    "friendlyName"
                  )}
                  {renderDropdown(
                    "Category",
                    `assetDetails[${index}].category`,
                    state,
                    (event) => handleChange(event, index),
                    categoryTypes,
                    "categoryType",
                    "categoryType"
                  )}
                  {renderDropdown(
                    "Legacy Asset Type",
                    `assetDetails[${index}].legacyAssetType`,
                    state,
                    (event) => handleChange(event, index),
                    assertTypes,
                    "assetType",
                    "assetType"
                  )}
                  {renderDropdown(
                    "Model Id",
                    `assetDetails[${index}].modelId`,
                    state,
                    (event) => handleChange(event, index),
                    models,
                    "modelid",
                    "modelid"
                  )}
                </Box>
                <Box {...filedResponsive}>
                  {renderTextField(
                    "CN",
                    `assetDetails[${index}].cn`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                  {renderTextField(
                    "HSN",
                    `assetDetails[${index}].hsn`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                  {renderTextField(
                    "Label Instructions",
                    `assetDetails[${index}].labelInstructions`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                  {renderTextField(
                    "origin",
                    `assetDetails[${index}].origin`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                  {renderTextField(
                    "Description",
                    `assetDetails[${index}].description`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                </Box>
              </Box>
            ))}
          <Box
            display="flex"
            alignItems="center"
            p={0.5} // Add padding as needed
          >
            <Typography variant="h5" {...commonTypography}>
              Product Details
            </Typography>
            <Button
              onClick={handleAddProduct}
              sx={{
                color: "primary.main",
                marginLeft: "auto",
                border: "1px solid #ccc",
              }}
            >
              <AddIcon sx={{ color: "primary.main", marginRight: 1 }} /> Add
              More Product
            </Button>
          </Box>
          {Array.isArray(state.productDetails) &&
            state.productDetails.map((product, index) => (
              <Box {...border} key={index}>
                {/* Render delete icon */}
                <IconButton onClick={() => handleDeleteProduct(index)}>
                  <DeleteIcon />
                </IconButton>
                {/* Rest of the fields */}
                <Box {...filedResponsive}>
                  {renderTextField(
                    "Product Category",
                    `productDetails[${index}].productCategory`,
                    state,
                    (event) => handleChange(event, index),
                    "text",
                    true
                  )}
                  {renderDropdown(
                    "Product SubCategory",
                    `productDetails[${index}].productSubCategory`,
                    state,
                    (event) => handleChange(event, index),
                    getSubCategories,
                    "contentsubcategoryname",
                    "contentsubcategoryname"
                  )}
                  {renderTextField(
                    "Product ModelId",
                    `productDetails[${index}].productModelId`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                  {renderTextField(
                    "CN",
                    `productDetails[${index}].cn`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                </Box>
                <Box {...filedResponsive}>
                  {renderTextField(
                    "HSN",
                    `productDetails[${index}].hsn`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                  {renderTextField(
                    "Label Instructions",
                    `productDetails[${index}].labelInstructions`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                  {renderDropdown(
                    "Grade",
                    `productDetails[${index}].grade`,
                    state,
                    (event) => handleChange(event, index),
                    grade,
                    "type",
                    "type"
                  )}
                  {renderTextField(
                    "Origin",
                    `productDetails[${index}].origin`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                </Box>
                <Box {...filedResponsive}>
                  {renderTextField(
                    "Quantity",
                    `productDetails[${index}].quantity`,
                    state,
                    (event) => handleChange(event, index),
                    "number"
                  )}
                  {renderTextField(
                    "Unit",
                    `productDetails[${index}].unit`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                  {renderTextField(
                    "Price",
                    `productDetails[${index}].price`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                  {renderTextField(
                    "Discount",
                    `productDetails[${index}].discount`,
                    state,
                    (event) => handleChange(event, index)
                  )}
                  {renderTextField(
                    "Total Discountable Price",
                    `productDetails[${index}].totalDiscountablePrice`,
                    state,
                    (event) => handleChange(event, index),
                    "number"
                  )}
                </Box>
              </Box>
            ))}

          {/* Transport req */}
          {(() => {
            if (getReqVal === "transport-product-order-req") {
              return (
                <>
                  <Typography variant="h5" {...commonTypography}>
                    Transport Details
                  </Typography>
                  <Box {...border}>
                    <Box {...filedResponsive}>
                      {renderTextField(
                        "Transport Type",
                        "transportDetails.transportType",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Mode Of Transport",
                        "transportDetails.modeOfTransport",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Preferred Transport Company",
                        "transportDetails.preferredTransportCompany",
                        state,
                        handleChange
                      )}
                    </Box>
                  </Box>
                </>
              );
            }
          })()}
          {/* End Transport req */}
          {/* Storage req */}
          {(() => {
            if (getReqVal === "storage-product-order-req") {
              return (
                <>
                  <Typography variant="h5" {...commonTypography}>
                    Storage Details
                  </Typography>
                  <Box {...border}>
                    <Box {...filedResponsive}>
                      {renderTextField(
                        "City",
                        "storageDetails.city",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Description Of Goods",
                        "storageDetails.descriptionOfGoods",
                        state,
                        handleChange
                      )}
                      {renderTextField(
                        "Preferred Start Date",
                        "storageDetails.preferredStartDate",
                        state,
                        handleChange,
                        "date"
                      )}
                      {renderTextField(
                        "Expected Duration Of Storage",
                        "storageDetails.expectedDurationOfStorage",
                        state,
                        handleChange,
                        "number"
                      )}
                    </Box>
                  </Box>
                </>
              );
            }
          })()}
          {/* End Storage req */}

          <Typography variant="h5" {...commonTypography}>
            Requirements
          </Typography>
          <Box {...border}>
            <Box {...filedResponsive}>
              {renderDropdown(
                "Asset Package",
                "requirements.assetPackage",
                state,
                handleChange,
                packageTypes,
                "type",
                "type"
              )}
              {(() => {
                if (getReqVal === "transport-product-order-req") {
                  return (
                    <>
                      {renderDropdown(
                        "Vehicle Insurance",
                        "requirements.vehicleInsurance",
                        state,
                        handleChange,
                        transport,
                        "type",
                        "type"
                      )}
                    </>
                  );
                }
              })()}
              {(() => {
                if (getReqVal === "storage-product-order-req") {
                  return (
                    <>
                      {renderDropdown(
                        "Storage Insurance",
                        "requirements.storageInsurance",
                        state,
                        handleChange,
                        transport,
                        "type",
                        "type"
                      )}
                    </>
                  );
                }
              })()}
              {(() => {
                if (
                  getReqVal !== "transport-product-order-req" &&
                  getReqVal !== "storage-product-order-req"
                ) {
                  return (
                    <>
                      {renderDropdown(
                        "Provenance",
                        "requirements.provenance",
                        state,
                        handleChange,
                        transport,
                        "type",
                        "type"
                      )}
                    </>
                  );
                }
              })()}
              {renderDropdown(
                "Agreement",
                "requirements.agreement",
                state,
                handleChange,
                transport,
                "type",
                "type"
              )}
              {(() => {
                if (
                  getReqVal === "cm-product-order-req" ||
                  getReqVal === "cf-agri-raw-products-order-req"
                ) {
                  return (
                    <>
                      {renderDropdown(
                        "Transport",
                        "requirements.transport",
                        state,
                        handleChange,
                        transport,
                        "type",
                        "type"
                      )}
                    </>
                  );
                }
              })()}
              {(() => {
                if (
                  getReqVal === "agri-buy-products-order-req" ||
                  getReqVal === "cf-agri-raw-products-order-req"
                ) {
                  return (
                    <>
                      {renderDropdown(
                        "Organic Certificate",
                        "requirements.organicCertificate",
                        state,
                        handleChange,
                        transport,
                        "type",
                        "type"
                      )}
                    </>
                  );
                }
              })()}
              {(() => {
                if (getReqVal === "transport-product-order-req") {
                  return (
                    <>
                      {renderTextField(
                        "Transport Guide Line Link",
                        "requirements.transportGuideLineLink",
                        state,
                        handleChange
                      )}
                    </>
                  );
                }
              })()}
              {(() => {
                if (getReqVal === "storage-product-order-req") {
                  return (
                    <>
                      {renderTextField(
                        "Storage Guide Line Link",
                        "requirements.storageGuideLineLink",
                        state,
                        handleChange
                      )}
                    </>
                  );
                }
              })()}
            </Box>
            <Box {...filedResponsive}>
              {(() => {
                if (getReqVal === "cm-product-order-req") {
                  return (
                    <>
                      {renderTextField(
                        "Packing And Labeling Intruction Link",
                        "requirements.packingAndLabelingIntructionLink",
                        state,
                        handleChange
                      )}
                    </>
                  );
                }
              })()}

              {(() => {
                if (getReqVal === "cm-product-order-req") {
                  return (
                    <>
                      {renderTextField(
                        "Manufacturing Guide Line Link",
                        "requirements.manufacturingGuideLineLink",
                        state,
                        handleChange
                      )}
                    </>
                  );
                }
              })()}
              {(() => {
                if (
                  getReqVal !== "transport-product-order-req" &&
                  getReqVal !== "storage-product-order-req"
                ) {
                  return (
                    <>
                      {renderTextField(
                        "Quality Guideline Link",
                        "requirements.qualityGuidelineLink",
                        state,
                        handleChange
                      )}
                    </>
                  );
                }
              })()}

              {(() => {
                if (getReqVal === "cf-agri-raw-products-order-req") {
                  return (
                    <>
                      {renderTextField(
                        "Farming Guide Line Link",
                        "requirements.farmingGuideLineLink",
                        state,
                        handleChange
                      )}
                    </>
                  );
                }
              })()}
              {renderTextField(
                "Optional Document Link",
                "requirements.optionalDocumentLink",
                state,
                handleChange
              )}
              {renderTextField(
                "From Date",
                "requirements.fromDate",
                state,
                handleChange,
                "date"
              )}
              {renderTextField(
                "To Date",
                "requirements.toDate",
                state,
                handleChange,
                "date"
              )}
            </Box>
          </Box>

          <Typography variant="h5" {...commonTypography}>
            Additional Information
          </Typography>
          <Box {...border}>
            <Box {...filedResponsive}>
              {renderTextField(
                "Total Amount",
                "totalAmount",
                state,
                handleChange,
                "number"
              )}
              {renderDropdown(
                "Payment Mode",
                "paymentMode",
                state,
                handleChange,
                modeOfPayment,
                "type",
                "type"
              )}
              {renderTextField(
                "Request Type",
                "requestType",
                state,
                handleChange,
                "text",
                true
              )}
              {renderTextField(
                "Time Stamp",
                "timeStamp",
                state,
                handleChange,
                "text",
                true
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
          <Button color="error" variant="outlined" onClick={closeModel}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default memo(FormForOrderProduct);
