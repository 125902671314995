import React from "react";
import '../MyOrders/MyOrderStyleComponent.css';
import PropsForMyOrdersComponent from "./PropsForMyOrdersComponent";
import MyOrdersComponentV0 from "./MyOrdersv0";

export default function MyOrdersComponent(props) {
    const userType = localStorage.getItem("userType");

    let allowedRequests = [];
    switch (userType) {
        case "AssetDeveloper":
            allowedRequests = [{"AssetsInvoiceManagement":[
                { val: "asset-buy", type: "asset-buy-order-req" },
                { val: "cf-agri-assets", type: "cf-agri-assets-order-req" },
              ]},
              {"ProductsInvoice":[
                {
                    val: "agri-buy-products",
                    type: "agri-buy-products-order-req",
                  },
    
                  {
                    val: "cf-agri-raw-products",
                    type: "cf-agri-raw-products-order-req",
                  },
              ]},
              {"AssetQuoteManagement":[
                { val: "asset-buy", type: "asset-buy-rfq-req" },
                { val: "cf-agri-assets", type: "cf-agri-assets-rfq-req" },
              ]},
              {"ProductQuoteManagement":[
                { val: "agri-buy-products", type: "agri-buy-products-rfq-req" },
                {
                  val: "cf-agri-raw-products",
                  type: "cf-agri-raw-products-rfq-req",
                },
              ]},

            ];
            break;
        case "FOOD-PROCESSOR":allowedRequests = [{"AssetsInvoiceManagement":[
            { val: "asset-buy", type: "asset-buy-order-req" },
            { val: "cf-agri-assets", type: "cf-agri-assets-order-req" },
            { val: "storage-asset", type: "storage-asset-order-req" },

          ]},
          {"ProductsInvoice":[
            {
                val: "agri-buy-products",
                type: "agri-buy-products-order-req",
              },

              {
                val: "cf-agri-raw-products",
                type: "cf-agri-raw-products-order-req",
              },
              {
                val: "storage-product",
                type: "storage-product-order-req",
              },


          ]},
          {"AssetQuoteManagement":[
            { val: "asset-buy", type: "asset-buy-rfq-req" },
            { val: "cf-agri-assets", type: "cf-agri-assets-rfq-req" },
            { val: "storage-asset", type: "storage-asset-rfq-req" },
          ]},
          {"ProductQuoteManagement":[
            { val: "agri-buy-products", type: "agri-buy-products-rfq-req" },
            {
              val: "cf-agri-raw-products",
              type: "cf-agri-raw-products-rfq-req",
            },
            { val: "cm-product", type: "cm-product-rfq-req" },

            { val: "storage-product", type: "storage-product-rfq-req" },

          ]},

        ];break;
        case "LogisticsProvider":allowedRequests = [{"AssetsInvoiceManagement":[
          {
            val: "transport-asset",
            type: "transport-asset-order-req",
          },

          ]},
          {"ProductsInvoice":[
            {
              val: "transport-product",
              type: "transport-product-order-req",
            },

          ]},
          {"AssetQuoteManagement":[
            { val: "transport-asset", type: "transport-asset-rfq-req" },

          ]},
          {"ProductQuoteManagement":[
            { val: "transport-product", type: "transport-product-rfq-req" },

          ]},

        ];break;
        
        default:
            
            allowedRequests = [{"AssetsInvoiceManagement":[
                { val: "asset-buy", type: "asset-buy-order-req" },
                { val: "cf-agri-assets", type: "cf-agri-assets-order-req" },
              ]},
              {"ProductsInvoice":[
                {
                    val: "agri-buy-products",
                    type: "agri-buy-products-order-req",
                  },
    
                  {
                    val: "cf-agri-raw-products",
                    type: "cf-agri-raw-products-order-req",
                  },
              ]},
              {"AssetQuoteManagement":[
                { val: "asset-buy", type: "asset-buy-rfq-req" },
                { val: "cf-agri-assets", type: "cf-agri-assets-rfq-req" },
              ]},
              {"ProductQuoteManagement":[
                { val: "agri-buy-products", type: "agri-buy-products-rfq-req" },
                {
                  val: "cf-agri-raw-products",
                  type: "cf-agri-raw-products-rfq-req",
                },
              ]},

            ];
            break;
    }

    return (
        <div>
            <MyOrdersComponentV0 allowedRequests={allowedRequests} />
        </div>
    );
}
