import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory, useParams } from "react-router-dom";
import { getCategoryID } from "../../Common/CommonContent/ContentedRelatedCommonFun";
import MainService from "../../Common/MainService";
import VendorsTable from "./VendorsTable";

const newStyles = {
  fontWeight: "bold",
  padding: "8px",
  textAlign: "center",
  backgroundColor: "aliceblue",
  position: "sticky",
};

const keyValue = [
  { keys: "Email", value: "email" },
  { keys: "Phone", value: "phone" },
  { keys: "User Name", value: "username" },
  { keys: "Domain", value: "domain" },
];

const ListOfVendors = () => {
  const { Id } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [5, 10, 25];
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [vendersList, setVendersList] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] =
    useState("F&B-CATALOG");
  const [catalogModel, setCatalogModel] = useState({
    data: [],
    model: false,
  });

  useEffect(() => {
    if (Id === null || Id === undefined || Id === "") return;
    getAllMachineProviders();
  }, [Id]);

  const getAllMachineProviders = () => {
    MainService.getGlobalUsers(Id)
      .then((data) => {
        setVendersList(data);
      })
      .catch((e) => {
        return NotificationManager.error("something went wrong");
      });
  };

  useMemo(async () => {
    await getCategoryID(selectedCategoryName)
      .then((data) => {
        setSelectedCategoryId(data);
      })
      .catch((err) => {
        return NotificationManager.error("something went wrong");
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = vendersList.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  const handleGoBack = () => {
    history.goBack(); // This function navigates back to the previous route
  };

  // button model
  const modelCatalogHandler = (data) => {
    MainService.getContentsBasedonContentCreator(
      data,
      selectedCategoryId,
      "contentowner"
    )
      .then((data) => {
        setCatalogModel({
          data: data,
          model: !catalogModel.model,
        });
      })
      .catch((err) => {
        return NotificationManager.error("something went wrong");
      });
  };

  return (
    <>
      {/* Back Button */}
      <Box sx={{ width: "100%" }}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => handleGoBack()}
        ></Button>
      </Box>
      {/* Table */}
      <Box>
        <TableContainer
          component={Paper}
          style={{ height: "370px", marginTop: "10px" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {keyValue &&
                  keyValue.map((header) => (
                    <TableCell key={header} style={newStyles}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{header.keys}</span>
                      </div>
                    </TableCell>
                  ))}
                <TableCell style={newStyles}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>Catalog's</span>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            {Array.isArray(vendersList) && vendersList?.length > 0 ? (
              <TableBody>
                {slicedData?.map((row, index) => (
                  <TableRow key={index}>
                    {keyValue?.map((column, i) => (
                      <TableCell
                        key={column + i}
                        style={{ fontWeight: 500, fontSize: "15px" }}
                      >
                        {row[column.value]}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Button
                        variant="outlined"
                        style={{ textTransform: "none" }}
                        onClick={() => {
                          modelCatalogHandler(row.username);
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                    No Data Found
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={vendersList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      {/* View Button Click Model Component */}
      <VendorsTable
        data={catalogModel?.data}
        openModel={catalogModel?.model}
        closeModel={modelCatalogHandler}
      />
    </>
  );
};

export default ListOfVendors;
