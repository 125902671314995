import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

export default function LogisticProvidersMarketplaceService(){
    return(
        <>
        <UpdatesBanner 
         className="MoDBanner text-white"
         bannerText="AgriTransport Marketplace Services"
        /> 
  

        </>
    )
}