import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { Fragment, memo, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Capitalize } from "../../Common/CommonContent/ContentedRelatedCommonFun";
import MainService from "../../Common/MainService";
import CreateContentSubCategory from "./CreateContentSubCategory";
import CreateContent from "./CreateContent";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

ViewSubcategories.defaultProps = {
  subCategories: [],
  modelShow: false,
  closeModel: () => {},
  reloadComponent: () => {},
  userName: "",
  categoryId: "",
  categoryName: "",
  parentID: "",
  reloadContent: () => {},
  contentFormate: "",
  contentOwners: [],
};

const keyVal = [
  // "categoryid",
  "categoryname",
  "contentsubcategoryid",
  "contentsubcategoryname",
];

const createContentNotRequeired = [];
const createSubcategoryNotRequeired = ["TRADE-DOC","SIGNED-AGREEMENT"];
const deleteContentNotRequeired = ["TRADE-DOC","SIGNED-AGREEMENT"];

function ViewSubcategories({
  subCategories,
  modelShow,
  closeModel,
  reloadComponent,
  userName,
  categoryId,
  categoryName,
  parentID,
  reloadContent,
  contentFormate,
  contentOwners,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const iconWidthRes = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const { user } = MainService.getLocalData();
  const { username } = user;
  //create sub category model
  const [openSubModel, closeSubModel] = useState(false);
  //create content handler
  const [subCategoryData, setSubCategoryData] = useState({});
  const [openContentModel, closeContentModel] = useState(false);

  /* Delete Sub-Category */
  const deleteSubCategoryHandler = async (subCatid) => {
    let subcatval = await subCatid;
    MainService.deleteContentSubCategory(username, subcatval)
      .then((res) => {
        reloadComponent();
        return NotificationManager.success(res["SUCCESS MESSAGE"] || "success");
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
  };
  /* EOD Delete Sub-Category */

  //create sub category model handler
  const createSubCategoryHandler = () => {
    closeSubModel(!openSubModel);
  };
  //create content handler
  const createContentHandler = (data) => {
    setSubCategoryData(data);
    closeContentModel(!openContentModel);
  };

  return (
    <Fragment>
      <Dialog
        open={modelShow}
        aria-labelledby="responsive-dialog-title"
        fullScreen
      >
        <DialogTitle id="responsive-dialog-title">
          <Button
            color="primary"
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={closeModel}
          >
            Back
          </Button>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left", //space-evenly
              gap: "1rem",
              flexDirection: "row",
              minWidth: "100%", // Added this line to set minimum width to 100%
            }}
          >
            {Array.isArray(subCategories) && subCategories?.length > 0 ? (
              subCategories &&
              subCategories?.map((subCat, index) => (
                <Box
                  key={index}
                  sx={{
                    minWidth: "45%", // Adjust as needed
                    maxWidth: {
                      sx: "100%",
                      sm: "100%",
                      md: "45%",
                      lg: "35%",
                      xl: "35%",
                    }, // Adjust as needed
                    borderRadius: "10px",
                    padding: "10px",
                    margin: "10px",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    textAlign: "center",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        {keyVal.map((key) => (
                          <TableRow key={key}>
                            <TableCell
                              style={{ fontWeight: 600, color: "#3c8a67" }}
                            >
                              {Capitalize(key)}
                            </TableCell>
                            <TableCell>{subCat[key]}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{ margin: "5px", width: "100%" }}>
                    {!deleteContentNotRequeired.includes(categoryName) && (
                      <Button
                        sx={{
                          margin: "5px",
                          width: "35%",
                          textTransform: "none",
                        }}
                        color="error"
                        type="button"
                        variant="outlined"
                        onClick={() => {
                          deleteSubCategoryHandler(subCat.contentsubcategoryid);
                        }}
                      >
                        Delete
                      </Button>
                    )}
                    {!createContentNotRequeired.includes(categoryName) && (
                      <Button
                        sx={{
                          margin: "5px",
                          width: "35%",
                          textTransform: "none",
                        }}
                        color="primary"
                        type="button"
                        variant="outlined"
                        onClick={() => {
                          createContentHandler(subCat);
                        }}
                      >
                        Create Content
                      </Button>
                    )}
                  </Box>
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  gap: "1rem",
                  flexDirection: "row",
                  minWidth: "100%", // Added this line to set minimum width to 100%
                }}
              >
                <Box
                  sx={{
                    maxWidth: "45%", // Adjust as needed
                    borderRadius: "10px",
                    padding: "10px",
                    margin: "10px",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    textAlign: "center",
                  }}
                >
                  No Data Found
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
        <Box
          className="container"
          id="mainContentList"
          style={{ alignItems: "right !importent" }}
        >
          {!createSubcategoryNotRequeired.includes(categoryName) && (
            <Fab
              className={classes.fab}
              color="primary"
              size={iconWidthRes ? "small" : "large"}
              aria-label="add"
              title="Create Sub Category"
              onClick={() => {
                createSubCategoryHandler();
              }}
            >
              <AddIcon></AddIcon>
            </Fab>
          )}

          <CreateContentSubCategory
            reLoad={reloadComponent}
            userName={userName}
            categoryId={categoryId}
            categoryName={categoryName}
            parentID={parentID}
            onOpen={openSubModel}
            onClose={createSubCategoryHandler}
          />

          <CreateContent
            props={subCategoryData}
            openModel={openContentModel}
            closeModel={createContentHandler}
            subCategoryList={subCategories}
            userName={userName}
            parentID={parentID}
            reLoad={reloadContent}
            formateContent={contentFormate}
            ownersOfContent={contentOwners}
          />
        </Box>
      </Dialog>
    </Fragment>
  );
}

export default memo(ViewSubcategories);
