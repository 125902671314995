import React from 'react';
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";


export default function DSNonBoardingSubsection() {
  return (
    <>
    <HomeSubSection
      className="rowReverseAbout borderedCards borderedCards--mod"
      className3="manufacturer-img"
      descriptionTitle="
      Offline Registration of Store Owners "
      description="In this service, agents serve as intermediaries to oversee the offline registration process for store owner. As a part of offline registration, agents ensure seamless integration of store owner into the network.
      "
    />
    <HomeSubSection
      className1="aboutBgGrey"
      className="rowReverseAbout--mod borderedCards borderedCards--mod"
      className3="manufacturing-registration"
      descriptionTitle="
      Publishing Store Unit Details"
      description="In this service, the agent takes responsibility for publishing detailed information regarding processing units on behalf of store owner. Following publication, rigorous verification procedures are conducted to ensure the accuracy and reliability of the information. 





      "
    />
  </>
  )
}
