import { Container } from '@material-ui/core'
import React from 'react'
import FoodProcessorClub from '../../Club/FoodProcessorClub'
import NetworkBanner from '../NetworkBanner/NetworkBanner'

export default function ExploreFoodProcessor() {
  return (
    <>
    <NetworkBanner bannerTitle="Provide Machinery  & Consultancy to Food Processors" className="foodprocessorMachinery"/>
    <Container className='subSectionWrapper'>
        <FoodProcessorClub/>
    </Container>
    </>
  )
}
