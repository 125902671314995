import React, { Component } from 'react';
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  Form,
  FormGroup,
  FormText,
  FormFeedback,
  Label,
  Input,
  Modal, ModalBody, ModalFooter, ModalHeader,
  Pagination, PaginationItem, PaginationLink,
} from 'reactstrap';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MainService from "../../Common/MainService";
import Badge from "@mui/material/Badge";
import { NotificationManager } from "react-notifications";

// import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
// import './Dashboard.css';
// import '../../Common/loader/loader.css';
// import { toast } from 'react-toastify';
import _ from 'lodash';
// import 'react-toastify/dist/ReactToastify.css';
// import '../../Common/loader/loader.css';
import AuthAxiosInstance from '../../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance, EMSServcieAxiosInstance } from '../../../../authAxiosInstance'
import socketIOClients from "socket.io-client";
// import networkId from '../../../../networkId';
import PieChart from '../Charts/PieChartClass';
// import Context from '../../../../context/context';




let prev = 0;
let next = 0;
let last = 0;
let first = 0;

var jsonconfig = window.appConfig;
const data = [{ "id": "not1", "deviceId": "GBRBBDIV", "deviceType": "GBRBB0001", "NotificaionName": "Notification1", "NotificationType": "Type1", "NotificationStatus": "Open" },
{ "id": "not2", "deviceId": "GBRBBDIV2", "deviceType": "GBRBB0002", "NotificaionName": "Notification2", "NotificationType": "Type2", "NotificationStatus": "Open" },
{ "id": "not1", "deviceId": "GBRBBDIV3", "deviceType": "GBRBB0003", "NotificaionName": "Notification3", "NotificationType": "Type3", "NotificationStatus": "Closed" },
{ "id": "not1", "deviceId": "GBRBBDIV4", "deviceType": "GBRBB0004", "NotificaionName": "Notification4", "NotificationType": "Type4", "NotificationStatus": "Open" },
{ "id": "not1", "deviceId": "GBRBBDIV5", "deviceType": "GBRBB0005", "NotificaionName": "Notification5", "NotificationType": "Type5", "NotificationStatus": "Closed" },
{ "id": "not1", "deviceId": "GBRBBDIV6", "deviceType": "GBRBB0006", "NotificaionName": "Notification6", "NotificationType": "Type6", "NotificationStatus": "Open" },
{ "id": "not1", "deviceId": "GBRBBDIV7", "deviceType": "GBRBB0007", "NotificaionName": "Notification7", "NotificationType": "Type7", "NotificationStatus": "Open" },
{ "id": "not1", "deviceId": "GBRBBDIV8", "deviceType": "GBRBB0008", "NotificaionName": "Notification8", "NotificationType": "Type8", "NotificationStatus": "Open" },];


class Dashboard extends Component {
  // static contextType = Context;

  constructor() {
    super();
    this.state = {
      showspinner: false,
      countDevices: { totalCount: 0, activeCount: 0, InactiveCount: 0 },
      devicetypelist: [],
      disabledstate: true,
      primary: false,
      danger: false,
      viewKeyModell: false,
      deletedata: {},
      profiledata: [],
      deviceuploaddata: [],
      editdevicedetails: [],
      devicelist: [],
      odevicelist: [],
      devicelistb: [],
      odevicelistb: [],
      currentpageno: 1,
      pageno: 1,
      pagesize: 5,
      searchkey: '',
      totaldevicecount: '',
      devicelisttypeid: 'all',
      accordion: [true],
      currentlist: [],
      typelist: [],
      modellist: [],
      viewData: '',
      startDate: '',
      endDate: '',
      currentDate: ''
    }
    // this.togglePrimary = this.togglePrimary.bind(this);
    //     this.handleClick = this.handleClick.bind(this);
    //     this.handleLastClick = this.handleLastClick.bind(this);
    //     this.handleFirstClick = this.handleFirstClick.bind(this);
    //     this.onclickdelete = this.onclickdelete.bind(this);
    //     this.toggleAccordion = this.toggleAccordion.bind(this);
    //     this.submitStartEndDate = this.submitStartEndDate.bind(this);


    // toast.configure({
    //     autoClose: 8000,
    //     draggable: false,
    //     //etc you get the idea
    // });
  }


  
  getAppConfig = () => {
    return window.AppConfigData;
  };

  componentDidMount() {
    this.getAllDevices();
    this.getAllDevicesTypes();
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    const userId = localStorage.getItem("user-id");
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/list`).then((resp) => {
      var totalCount = resp.data.length ? resp.data.length : 0;
      var activeCount = 0;
      var InactiveCount = 0;
      if (totalCount > 0) {
        activeCount = resp.data.filter((a) => a.deviceStatus === "ACTIVE").length;
        InactiveCount = resp.data.filter((a) => a.deviceStatus === "INACTIVE").length;
      }
      else {

      }
      this.setState({ countDevices: { totalCount, activeCount, InactiveCount } });
    }).catch((err) => {

    })
    const socket2 = socketIOClients((this.getAppConfig().ApiUrlMapp.SocketIOURL));
    socket2.on("presence", (pdata) => {
      const userId = localStorage.getItem("user-id");
      const { user } = MainService.getLocalData();
      const { username, parentId } = user;
      try {
        if (pdata.networkId.toString() === username) {
          let devicelistb = [...this.state.devicelistb];
          let odevicelistb = [...this.state.odevicelistb];
          let activeDev = 0;
          let totalDev = 0;
          devicelistb.map((d, i) => {
            if (d.deviceId === pdata.deviceId) {
              d.deviceStatus = pdata.status;

            }
            totalDev++;
            if (d.deviceStatus == "ACTIVE") {
              activeDev++;
            }

          });
          odevicelistb.map((od, i) => {
            if (od.deviceId === pdata.deviceId) {
              od.deviceStatus = pdata.status;
            }
          });
          this.setState({ countDevices: { totalCount: totalDev, activeCount: activeDev, InactiveCount: totalDev - activeDev } });
          this.setState({ devicelistb });
          this.setState({ odevicelistb });
        }
      }
      catch (cerror) {
        // toast.error("Something went wrong.", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");
      }
    })
  }

  getAllDevices() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    this.setState({ devicelist: data, odevicelist: data, totaldevicecount: data.length });
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/list`).then((resp) => {
      if (resp.data) {


        this.setState({ devicelist: data, devicelistb: resp.data, odevicelist: data, odevicelistb: resp.data, totaldevicecount: data.length });

      }
      else {
        this.setState({ devicelist: [], odevicelist: [] });
      }
    }).catch((err) => {

    })
  }
  getAllDevicesTypes() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/list`).then((resp) => {
      if (resp.data) {
        this.setState({ typelist: resp.data },
          () => {
          }
        );
      }
      else {
        this.setState({ typelist: [] });
      }
    }).catch((err) => {
      return NotificationManager.error("Some error occured");

    })
  }


  render() {
    const { devicetypelist, devicelist, pageno, pagesize,
      totaldevicecount } = this.state;


    if (devicelist.length > 0) {
      for (var i = 0; i < devicelist.length; i++) {
        for (var j = 0; j < devicetypelist.length; j++) {
          if (devicetypelist[j].dvt_id == devicelist[i].dv_type) {
            devicelist[i].dvt_name = devicetypelist[j].dvt_name;
          }
        }
      }
    }
    // Logic for displaying todos
    const indexOfLastTodo = pageno * pagesize;
    const indexOfFirstTodo = indexOfLastTodo - pagesize;
    const currentlist = devicelist.slice(indexOfFirstTodo, indexOfLastTodo);
    prev = pageno > 0 ? (pageno - 1) : 0;
    last = Math.ceil(totaldevicecount / pagesize);
    next = (last === pageno) ? pageno : pageno + 1;
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totaldevicecount / pagesize); i++) {
      pageNumbers.push(i);
    }
    //Logic to get the list of pagesize
    const pagesizelist = []
    if (parseInt(totaldevicecount) > 0) {
      for (let j = 1; j <= totaldevicecount; j++) {
        if (j % 5 === 0) {
          pagesizelist.push(j);
        }
      }
      pagesizelist.push("ShowAll");

    }
    else {
      pagesizelist.push("ShowAll");
    }

    const badgeStyle = {
      successBadge: {
        backgroundColor: "green",
        color: "white",
        borderRadius: "50%",
        padding: "5px",
      },
      dangerBadge: {
        backgroundColor: "red",
        color: "white",
        borderRadius: "50%",
        padding: "5px",
      },
    };

    return (
      <>
        <div style={{ marginTop: "1rem" }}>
          <PieChart totalDevices={this.state.countDevices.totalCount} activeDevice={this.state.countDevices.activeCount} inactiveDevices={this.state.countDevices.InactiveCount} />
        </div>
        <div className="animated fadeIn" >
          <div style={{ display: "flex" }}>
            <h2>Today's Notification  </h2><Badge
              style={{
                backgroundColor: "#2e56df",
                color: "white", // Text color
                borderRadius: "20%", // To make it circular
                padding: "5px", // Adjust the padding as needed
                height: "35px"
              }}
            >
              New
            </Badge>
          </div>
          <Row>
            <Col>
              {(data.length > 0) &&
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>SL No</TableCell>
                        <TableCell>DEVICE ID</TableCell>
                        <TableCell>DEVICE TYPE</TableCell>
                        <TableCell>NOTIFICATION NAME</TableCell>
                        <TableCell>NOTIFICATION TYPE</TableCell>
                        <TableCell>NOTIFICATION STATUS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data && data.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{row.deviceId}</TableCell>
                          <TableCell>{row.deviceType}</TableCell>
                          <TableCell>{row.NotificaionName}</TableCell>
                          <TableCell>{row.NotificationType}</TableCell>
                          <TableCell>
                            <Badge
                              key={index}
                              style={{
                                backgroundColor: row.NotificationStatus === "Closed" ? "#3ECF8E" : "#2e56df",
                                color: "white", // Text color
                                borderRadius: "20%", // To make it circular
                                padding: "5px", // Adjust the padding as needed
                              }}
                            >
                              {row.NotificationStatus}
                            </Badge>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              }

              <br></br>

              {(data.length == 0) &&

                <center>
                  <h3>No records found!!</h3>
                </center>

              }


            </Col>
          </Row>
          {this.state.showspinner === true &&

            <div className="sk-cube-grid ">
              <div className="sk-cube sk-cube1"></div>
              <div className="sk-cube sk-cube2"></div>
              <div className="sk-cube sk-cube3"></div>
              <div className="sk-cube sk-cube4"></div>
              <div className="sk-cube sk-cube5"></div>
              <div className="sk-cube sk-cube6"></div>
              <div className="sk-cube sk-cube7"></div>
              <div className="sk-cube sk-cube8"></div>
              <div className="sk-cube sk-cube9"></div>
            </div>}
        </div></>

    );
  }
}

export default Dashboard;
