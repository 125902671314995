import React from "react";
import FarmerImg from "../../../images/gbrCBN/CBNImg3.webp";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import AdvisorImg from "../../../images/AnimalVet1.jpg";
import TradersImg from "../../../images/gbrCBN/CBNImg2.webp";
import MachineryImg from "../../../images/gbrCBN/CBNImg4.webp";
import NutritionCompaniesImg from "../../../images/gbrCBN/CBNImg5.webp";
import NGOImg from "../../../images/gbrCBN/CBNImg6.webp";
import StorageProviderImg from "../../../images/gbrCBN/CBNImg8.webp";
import AgriInputImg from "../../../images/gbrCBN/CBNImg9.webp";
import TransporterImg from "../../../images/gbrCBN/CBNImg7.webp";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import DriverImg from "../../../images/gbrCBN/CBNImg10.webp";
import FarmersFranchiseImg from "../../../images/gbrCBN/CBNImg11.webp";
import OffTakerImg from "../../../images/gbrCBN/CBNImg12.webp";
import NetworkBanner from "../NetworkBanner/NetworkBanner";
import CBNServices from "../../OurServices/CBNServices";
import CBNBenefits from "../Benefits/CBNBenefits";
import CBN from "../../../images/CBN-Network.gif";

import GovernmentOrganizationImg from "../../../images/gbrHome/govenmentOrganization.webp";
// import FinancialServiceProviderImg from "../../../images/loan-business-finance-businessman-explain-business-report-from-data-analysis-bank-marketing.jpg";
import ProductionManagerImg from "../../../images/gbrPN/PNImg5.webp";
import CBNManager from "./CBNManager";

export default function CBNNetwork() {
  return (
    <>


<UpdatesBanner
        className="cbnbg text-white"
        bannerTitle ="Seed to Sale"
        bannerbodycopy1={[
          // "Introduce new line of business to manage assets and ",
          // <br className="d-lg-block d-none"></br>,
          // "Create your own network of Rural-Entrepreneur",

          "CBO & Agro Dealers can BUILD, OWN, MANAGE & OPERATE  to help farmers",   <br className="d-lg-block d-none"></br>,"with everything they need from seed to sale",
        ]}
        demo="true"
        bannerbodycopy="Local Trade | Quality Inputs | Insurance | Advisory | Capital"
        // bannerbodycopy={[
        //   "New Revenue Stream | Optimised resource management | Boosts agricultural productivity |",
        //   <br className="d-lg-block d-none"></br>,
        //   "Unlocks opportunities for investors and rural entrepreneurs",
        // ]}
        // buttonText2="Sign in"
        // href2="http://abn.gbrfarming.com/"
        // buttonText3="Sign up"
        // href3="http://abn.gbrfarming.com/auth/3/signup
      />



<div className="mt-5 ">
<h4 className="container-title text-center mx-md-4 ">
Community Based Network  Benefits          </h4> 
        <HomeSubSection
          fontBold="true"
          className="rowReverseAbout align-items-center"
          listItems={<CBNBenefits/>}
          description="Agribusiness can Build their Own Community network (Virtual Clubs) to obtain group organic certification and find the best partners for local trade

          "
          // descriptionTitle=" Community Based Network (CBN) & Benefits"
          src={CBN}
          // href1="/CBNNetwork"
          // buttoncont1="Explore Ecosystem for Producers"
          grid="true"
          btn2= "true"


        />
      </div>



      <CBNServices />
      <div className=" subSectionWrapper">
      <h2 className='text-center container-title'>CBN COMMUNITY MEMBERS</h2>

        {/* <h2 className="container-title text-center">Our Community</h2> */}

        {/* <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image16"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
          Agri-producers' Advisors"
          src={AdvisorImg}
          href2="auth/18/cbnsignup"
          buttoncont2="Register"
          href1="auth/18/cbnsignin"
          buttoncont1="Sign In"
          userType="Trainer"
        /> */}



        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image17"
          descriptionTitle="
            Traders"
          description="GBR is a technology company based in Bangalore that
          provides an alternate innovate options for agri-MSMEs
          Founders to maximize profit [reduce procurement cost &
          export] and raise easy investments through IAA.
          "
          src={TradersImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image19"
          descriptionTitle="
            Farm Machinery Providers"
          description="GBR is a technology company based in Bangalore that
    provides an alternate innovate options for agri-MSMEs
    Founders to maximize profit [reduce procurement cost &
    export] and raise easy investments through IAA.
    "
          src={MachineryImg}
          href2="auth/19/cbnsignup"
          buttoncont2="Register"
          href1="auth/19/cbnsignin"
          buttoncont1="Sign In"
          userType="Agri-producers-Machinery-Provider"
        />

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image20"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
            Nutrition Companies"
          src={NutritionCompaniesImg}
          href2="auth/20/cbnsignup"
          buttoncont2="Register"
          href1="auth/20/cbnsignin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image21"
          descriptionTitle="
            FPO,CS,NGO,SHG"
          description="GBR is a technology company based in Bangalore that
    provides an alternate innovate options for agri-MSMEs
    Founders to maximize profit [reduce procurement cost &
    export] and raise easy investments through IAA.
    "
          src={NGOImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image23"
          descriptionTitle="
            Storage Providers"
          description="GBR is a technology company based in Bangalore that
    provides an alternate innovate options for agri-MSMEs
    Founders to maximize profit [reduce procurement cost &
    export] and raise easy investments through IAA.
    "
          src={StorageProviderImg}
          href2="auth/21/cbnsignup"
          buttoncont2="Register"
          href1="auth/21/cbnsignin"
          buttoncont1="Sign In"
          userType="Storage Providers"
        />

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image24"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
            Agri Input Companies"
          src={AgriInputImg}
          href2="auth/22/cbnsignup"
          buttoncont2="Register"
          href1="auth/22/cbnsignin"
          buttoncont1="Sign In"
          userType="Agri Input Companies"
        />

        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
            Transporters"
          src={TransporterImg}
          href2="auth/23/cbnsignup"
          buttoncont2="Register"
          href1="auth/23/cbnsignin"
          buttoncont1="Sign In"
          userType="Transporters"
        /> */}
        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards borderedCards--mod"
          description="NGOs are non-profit organizations focused on supporting agricultural development and rural communities. They offer services like education, training, and technical assistance to farmers, helping them adopt sustainable practices and access markets, ultimately promoting food security and rural development.


            "
          descriptionTitle="
            NGO"
          src={TransporterImg}
          href4="https://cbn.gbrapp.com/"
          href5="https://cbn.gbrapp.com/auth/undefined/signup"
          buttoncont2="Sign Up"
          buttoncont1="Sign In"
          userType="Transporters"
          className3="ngo-img"
        />
        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          description="SHGs (Self-Help Groups) are community-based organizations formed by farmers to pool resources, share knowledge, and collectively engage in agricultural activities. These groups empower farmers, particularly women, by providing access to credit, training, and market linkages, thus enhancing their livelihoods and promoting socio-economic development in rural areas.


            "
          descriptionTitle="
            SHGs"
          src={TransporterImg}
          href4="https://cbn.gbrapp.com/"
          href5="https://cbn.gbrapp.com/auth/undefined/signup"
          buttoncont2="Sign Up"
          buttoncont1="Sign In"
          className3="shg-img"
        />

<HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards borderedCards--mod"
          description="FPOs are collective groups formed by farmers to improve their bargaining power, access resources, and market their produce together. They empower small farmers by pooling resources, sharing knowledge, and facilitating access to credit and markets, thereby enhancing livelihoods and promoting sustainable agriculture in rural areas.


            "
          descriptionTitle="
            FPOs"
          src={TransporterImg}
          href4="https://cbn.gbrapp.com/"
          href5="https://cbn.gbrapp.com/auth/undefined/signup"
          buttoncont2="Sign Up"
          buttoncont1="Sign In"
          className3="fpo-img"
        />

<HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          description="CS are farmer-led organizations that enable collective action to address agricultural challenges and improve economic conditions. They help farmers pool resources, access markets, and obtain inputs at lower costs, while providing essential services like credit and technical assistance, fostering rural development.


            "
          descriptionTitle="
            CS"
            href4="https://cbn.gbrapp.com/"
            href5="https://cbn.gbrapp.com/auth/undefined/signup"
            buttoncont2="Sign Up"
            buttoncont1="Sign In"
          className3="cs-img"
        />


<HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="agri-retailer-role"
          description="Agri-retailers are businesses that specialize in selling agricultural inputs such as seeds, fertilizers, pesticides, and farm equipment to farmers. They often provide a wide range of products and services tailored to the needs of the agricultural community, including agronomic advice, soil testing, and crop protection solutions. Agri-retailers play a crucial role in the agricultural supply chain, facilitating the distribution of essential inputs to farmers and supporting their efforts to maximize yields and profitability.
              "
          descriptionTitle="
              Agri Retailer"
          src={ProductionManagerImg}
          href4="https://cbn.gbrapp.com/"
          href5="https://cbn.gbrapp.com/auth/undefined/signup"
          buttoncont2="Sign Up"
          buttoncont1="Sign In"
        />

<HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image18"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
            Farmer"
          src={FarmerImg}
          href4="https://cbn.gbrapp.com/"
          href5="https://cbn.gbrapp.com/auth/undefined/signup"
          buttoncont2="Sign Up"
          buttoncont1="Sign In"
        />













        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image25"
          descriptionTitle="
            Drivers"
          description="GBR is a technology company based in Bangalore that
    provides an alternate innovate options for agri-MSMEs
    Founders to maximize profit [reduce procurement cost &
    export] and raise easy investments through IAA.
    "
          src={DriverImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image26"
          descriptionTitle="
              Farmer Franchise"
          description="GBR is a technology company based in Bangalore that
      provides an alternate innovate options for agri-MSMEs
      Founders to maximize profit [reduce procurement cost &
      export] and raise easy investments through IAA.
      "
          src={FarmersFranchiseImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image27"
          descriptionTitle="
            Off-Takers"
          description="If you’re a modern retail company, hotel, or restaurant that wants to purchase fresh and processed food from trusted partner, we can give you what you’re looking for on best price"
          description1="Tracceable food | Disease Free | Certified"
          src={OffTakerImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}
              </div>

<div className="subSectionWrapper main-wrapper2">
        <CBNManager/>
        </div>
    </>
  );
}
