import React from "react";
import { Container, Grid } from "@material-ui/core";
import Fade from "react-reveal";
import "./AboutSubSection.css";
export default function AboutSubSection({
  title,
  src,
  description,
  descriptionTitle,
  className,
  className1,
  description1,
  description2,
  description3,
  description4,
  description5,
  description6,
  description7,
  description8,
  description9,
  description10,
  description11,
  description12,
  description13,
  description14,
  description15,
  description16,
  description17,
  description18,
  description19,
  description20,
  content
}) {
  return (
    <div className={`${className1} aboutSubSection`}>
      <Container>
      
        <Grid
          container
          spacing={0}
          justifyContent="space-between"
          alignItems="center"
          className={className}
        >
          <Grid item md={7} sm={7} xs={12}>
          <h3 className="container-title" >
          {title}
        </h3>
            {descriptionTitle && (
              <p className="container-para">
                <b>{descriptionTitle}</b>
              </p>
            )}
            <p className="container-para">{description}</p>
            <div>{content}</div>
            {description16 && <p className="container-heading2 mb-0">{description16}</p>}
            {description1 && <p className="container-para">{description1}</p>}
            {description2 && <p className="container-para">{description2}</p>}
            {description3 && <p className="container-para">{description3}</p>}
            {description4 && <p className="container-para">{description4}</p>}
            {description5 && <p className="container-para">{description5}</p>}
            {description6 && <p className="container-para">{description6}</p>}
            {description7 && <p className="container-para">{description7}</p>}
            {description17 && <p className="container-heading2 mb-0">{description17}</p>}

            {description8 && <p className="container-para">{description8}</p>}
            {description9 && <p className="container-para">{description9}</p>}
            {description10&& <p className="container-para">{description10}</p>}
            {description11 && <p className="container-para">{description11}</p>}
            {description18 && <p className="container-heading2 mb-0">{description18}</p>}

            {description12 && <p className="container-para">{description12}</p>}
            {description13 && <p className="container-para">{description13}</p>}
            {description14 && <p className="container-para">{description14}</p>}
            {description15 && <p className="container-para">{description15}</p>}

            {description19 && <p className="container-heading2 mb-0">{description19}</p>}

{description20 && <p className="container-para">{description20}</p>}
           


          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <img src={src} width="100%" alt="Image" className="rowReverse" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
