import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@mui/material";
import React, { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import Loading from "../../AssetDeveloperRole/ModelProductManagement/Loading";
import { getCategoryID } from "../../Common/CommonContent/ContentedRelatedCommonFun";
import ViewSubcategories from "../../Common/CommonContent/ViewSubcategories";
import MainService from "../../Common/MainService";
const CreateTradeDoc = lazy(() => import("./CreateTradeDoc"));
const ProfileTable = lazy(() => import("./ProfileTable"));

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

const textSize = {
  sx: {
    textTransform: "none",
    background: "#3ECF8E",
    margin: "2px",
    width: { xs: "100%", sm: "70%", md: "auto" },
    "&:hover": {
      background: "#3ECF8E",
    },
  },
};

const UserTradeDocuments = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [postPopup, setShowPostPopup] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("TRADE-DOC");
  const [postList, setPostList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  // sub categories model condition
  const [openSubCategoryModel, closeSubCategoryModel] = useState(false);

  useMemo(async () => {
    await getCategoryID(selectedCategoryName)
      .then((data) => {
        setSelectedCategoryId(data);
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
  }, []);

  useEffect(() => {
    if (!selectedCategoryId) return;
    getSubCategories();
  }, [selectedCategoryId]);

  const getSubCategories = () => {
    MainService.getSubCategoriesForAD(parentId, selectedCategoryId)
      .then((data) => {
        setsubCategoriesList(data);
      })
      .catch((e) => {
        return NotificationManager.error(e || "something went wrong");
      });
  };

  const getAllContentsList = () => {
    setIsLoading(true);
    MainService.getContentsBasedonContentCreator(
      username,
      selectedCategoryId,
      "contentowner"
    )
      .then((data) => {
        setPostList(data);
        setIsLoading(false);
      })
      .catch((err) => {
        return NotificationManager.error(err || "something went wrong");
      });
  };

  useMemo(() => {
    if (!selectedCategoryId) return;
    if (!username) return;
    getAllContentsList();
  }, [selectedCategoryId]);

  /*Create Post */
  const closeCreateDocModelHandler = () => {
    setShowPostPopup(!postPopup);
  };

  // view subcategories
  const subCategoriesHandler = () => {
    closeSubCategoryModel(!openSubCategoryModel);
  };

  return (
    <>
      <Box className="container" id="mainContentList">
        {/* <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Create Advertisement"
          className={classes.fab}
          onClick={() => {
            closeCreateDocModelHandler();
          }}
        >
          <AddIcon></AddIcon>
        </Fab> */}
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Button
            {...textSize}
            color="primary"
            className="registerBtn"
            variant="contained"
            onClick={() => {
              subCategoriesHandler();
            }}
          >
            Sub Categories
          </Button>
        </Box>

        <Box className="MuiGrid-root">
          <Suspense fallback={<Loading />}>
            <ProfileTable
              data={postList}
              isLoading={isLoading}
              reLoad={getAllContentsList}
              categoryName={selectedCategoryName}
              requeiredDocumentStatusUpdateButton={false}
              requeiredDeleteContentButton={false}
              requeiredUpdateKycButton={false}
            />
          </Suspense>
        </Box>

        {/* <Suspense fallback={<Loading />}>
          <CreateTradeDoc
            categoryId={selectedCategoryId}
            categoryName={selectedCategoryName}
            closeModel={closeCreateDocModelHandler}
            openModel={postPopup}
            reload={getAllContentsList}
            parent={parentId}
            // userType={localStorage.getItem("userType")}
            docType="ownertrade"
            subCatList={subCategoriesList}
          />
        </Suspense> */}

        <ViewSubcategories
          subCategories={subCategoriesList}
          modelShow={openSubCategoryModel}
          closeModel={subCategoriesHandler}
          reloadComponent={getSubCategories}
          userName={username}
          categoryId={selectedCategoryId}
          categoryName={selectedCategoryName}
          parentID={parentId}
          reloadContent={getAllContentsList}
          contentFormate="DOC"
        />
      </Box>
    </>
  );
};

export default UserTradeDocuments;
