// requestTypesConfig.js

const OrderRequestTypeConfig = {
    "NULL": {
      "FOOD-PROCESSOR": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      "BROKER": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      "BRAND": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      // Add other user types if needed...
    },
    "BASIC": {
      "FOOD-PROCESSOR": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      "BROKER": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      "BRAND": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      // Add other user types if needed...
    },
    "STANDARD": {
      "FOOD-PROCESSOR": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      "BROKER": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      "BRAND": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      // Add other user types if needed...
    },
    "PREFFERED": {
      "FOOD-PROCESSOR": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      "BROKER": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      "BRAND": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      // Add other user types if needed...
    },
    "ADMIN": {
      "FOOD-PROCESSOR": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      "BROKER": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      "BRAND": {
        "PROCESSED-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "SEMI-PROCESSED": [
          { type: "agri-buy-products-order-req" },
          { type: "cm-product-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
        "RAW-PRODUCTS": [
          { type: "agri-buy-products-order-req" },
          { type: "cf-agri-raw-products-order-req" },
          { type: "transport-product-order-req" },
          { type: "storage-product-order-req" },
        ],
      },
      // Add other user types if needed...
    },
    // Add other roles if needed...
  };
  
  export default OrderRequestTypeConfig;
  