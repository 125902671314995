import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import prod1 from "../../images/cool.png";
import prod2 from "../../images/substance.png";
import prod4 from "../../images/dry.png";
import { Container } from "react-bootstrap";
import ExoploreProductBanner from "../ExploreProductBanner/ExoploreProductBanner";
import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import AgroCareMarketPlaceNavbar from "../AgroCareMarketPlaceNavbar/AgroCareMarketPlaceNavbar";
import ClubMainPage from "../Club/ClubMainPage";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
const CareAgroDealers = () => {
  return (
    <>
      <AgroCareMarketPlaceNavbar />
      <UpdatesBanner
        className="onMarketplaceBanner text-white"
        bannerText={[
          "Get in touch with best input manufactures  and at the same time   ",
          <br className="d-md-block d-none"></br>,
          "create & operate own network of agri-retailers and Farmers ",
        ]}
      />
      <div className="subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">FMC</h2>

        <ClubMainPage type={["FMC"]} explore="true" />
      </div>
      {/* <div className="subSectionWrapper">
      <h2 className='container-title mb-md-5 text-center'>PMC</h2>

        <ClubMainPage type={["PMC"]}  explore = "true"/>
      </div> */}
      <div className="subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">AIC</h2>

        <ClubMainPage type={["AIC"]} explore="true" />
      </div>

      <div className="subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">AHSP</h2>

        <ClubMainPage type={["AHSP"]} explore="true" />
      </div>
    </>
  );
};

export default CareAgroDealers;
