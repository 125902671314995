import React, { Fragment, useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import EventOnboarding from "../../../../resources/img/EventOnboarding.png";
import EventAttendanceLocation from "../../../../resources/img/EventAttendanceLocation.svg";
import Birth from "../../../../resources/img/EventBirth.png";
import HealthRecord from "../../../../resources/img/EventHealthRecord.png";
import Nutrition from "../../../../resources/img/EventNutrition.jpg";
import Weighing from "../../../../resources/img/EventWeighing.png";
import Parturition from "../../../../resources/img/EventParturition.png";
import Mortality from "../../../../resources/img/EventMortality.png";
import AssetOperationalStatus from "../../../../resources/img/EventAssetOperationalStatus.jpg";
import Patrolling from "../../../../resources/img/EventPatrolling.jpg";
import SellingCost from "../../../../resources/img/EventSellingCost.png";
import Feedback from "../../../../resources/img/EventFeedback.png";
import "./EventsComponent.css";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function EventsComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [showIcons, hideIcons] = useState(true);
  const [showOnboarding, hideOnboarding] = useState(false);
  const [showAttendanceLocation, hideAttendanceLocation] = useState(false);
  const [showViewAssetOnboarding, hideViewAssetOnboarding] = useState(false);
  const [getUpdateAssetOnboardingInfoPopup, setUpdateAssetOnboardingInfoPopup] =
    useState(false);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getViewAssetOnboarding, setViewAssetOnboarding] = useState([]);
  /********** Update Asset Onboarding Info ****************/
  const [getUpdateAssetId, setUpdateAssetId] = useState("");
  const [getWhiteLabelCost, setWhiteLabelCost] = useState("");
  const [getExistingAssetStatus, setExistingAssetStatus] = useState("");
  const [getUpdatedAssetStatus, setUpdatedAssetStatus] = useState("");
  /********** End Of Update Asset Onboarding Info ****************/
  /********** Attendance Location ****************/
  const [showNewAttendanceLocationPopup, hideNewAttendanceLocationPopup] =
    useState(false);
  const [getAttendanceLocationHistory, setAttendanceLocationHistory] = useState(
    []
  );
  const [getNewAttendanceLocationAssetid, setNewAttendanceLocationAssetid] =
    useState("");
  const [
    getNewAttendanceLocationAssetfriendlyName,
    setNewAttendanceLocationAssetfriendlyName,
  ] = useState("");
  const [getNewAttendanceLocationUserid, setNewAttendanceLocationUserid] =
    useState(username);
  const [getNewAttendanceLocationLatitude, setNewAttendanceLocationLatitude] =
    useState("");
  const [getNewAttendanceLocationLongitude, setNewAttendanceLocationLongitude] =
    useState("");
  const [getNewAttendanceLocationAltitude, setNewAttendanceLocationAltitude] =
    useState("");
  const [
    getNewAttendanceLocationDescription,
    setNewAttendanceLocationDescription,
  ] = useState("");
  const [getDeletedAttendanceAssetid, setDeletedAttendanceAssetid] =
    useState("");
  const [getDeletedAttendancePopup, setDeletedAttendancePopup] =
    useState(false);
  const [
    getUpdateAttendanceLocationInfoAssetid,
    setUpdateAttendanceLocationInfoAssetid,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoAssetfriendlyName,
    setUpdateAttendanceLocationInfoAssetfriendlyName,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoUserid,
    setUpdateAttendanceLocationInfoUserid,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoLatitude,
    setUpdateAttendanceLocationInfoLatitude,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoLongitude,
    setUpdateAttendanceLocationInfoLongitude,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoAltitude,
    setUpdateAttendanceLocationInfoAltitude,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoDescription,
    setUpdateAttendanceLocationInfoDescription,
  ] = useState("");
  const [showUpdateAttendaneLocationPopup, hideUpdateAttendaneLocationPopup] =
    useState(false);
  /********** End Of Attendance Location ****************/
  /********** Birth ****************/
  const [showBirth, hideBirth] = useState(false);
  const [showNewBirthInfoPopup, hideNewBirthInfoPopup] = useState(false);
  const [getBirthInfos, setBirthInfos] = useState([]);
  const [getNewBirthAssetid, setNewBirthAssetid] = useState("");
  const [getNewBirthAssetFriendlyName, setNewBirthAssetFriendlyName] =
    useState("");
  const [getNewBirthUserid, setNewBirthUserid] = useState(username);
  const [getNewBirthUserName, setNewBirthUserName] = useState("");
  const [getNewBirthSiteid, setNewBirthSiteid] = useState("");
  const [getNewBirthSiteName, setNewBirthSiteName] = useState("");
  const [getNewBirthLotid, setNewBirthLotid] = useState("");
  const [getNewBirthLotName, setNewBirthLotName] = useState("");
  const [getNewBirthLegacyAssetType, setNewBirthLegacyAssetType] = useState("");
  const [getNewBirthModelid, setNewBirthModelid] = useState("");
  const [getNewBirthModelFriendlyName, setNewBirthModelFriendlyName] =
    useState("");
  const [getNewBirthModelType, setNewBirthModelType] = useState("");
  const [getNewBirthModelURL, setNewBirthModelURL] = useState("");
  const [getNewBirthDOB, setNewBirthDOB] = useState("");
  const [getNewBirthGender, setNewBirthGender] = useState("");
  const [getNewBirthMaleParent, setNewBirthMaleParent] = useState("");
  const [getNewBirthFemaleParent, setNewBirthFemaleParent] = useState("");
  const [getNewBirthParentModelid, setNewBirthParentModelid] = useState("");
  const [getNewBirthLatitude, setNewBirthLatitude] = useState("");
  const [getNewBirthLongitude, setNewBirthLongitude] = useState("");
  const [getNewBirthAltitude, setNewBirthAltitude] = useState("");
  const [getNewBirthDescription, setNewBirthDescription] = useState("");
  const [getAllSites, setAllSites] = useState([]);
  const getDistrict = "District";
  const [getDeleteBirthInfoPopup, setDeleteBirthInfoPopup] = useState(false);
  const [getDeleteBirthInfo, setDeleteBirthInfo] = useState("");
  const [showUpdateBirthInfoPopup, hideUpdateBirthInfoPopup] = useState(false);
  const [getUpdateNewBirthAssetid, setUpdateNewBirthAssetid] = useState("");
  const [
    getUpdateNewBirthAssetFriendlyName,
    setUpdateNewBirthAssetFriendlyName,
  ] = useState("");
  const [getUpdateNewBirthUserid, setUpdateUpdateNewBirthUserid] = useState("");
  const [getUpdateNewBirthUserName, setUpdateNewBirthUserName] = useState("");
  const [getUpdateNewBirthSiteid, setUpdateNewBirthSiteid] = useState("");
  const [getUpdateNewBirthSiteName, setUpdateNewBirthSiteName] = useState("");
  const [getUpdateNewBirthLotid, setUpdateNewBirthLotid] = useState("");
  const [getUpdateNewBirthLotName, setUpdateNewBirthLotName] = useState("");
  const [getUpdateNewBirthLegacyAssetType, setUpdateNewBirthLegacyAssetType] =
    useState("");
  const [getUpdateNewBirthModelid, setUpdateNewBirthModelid] = useState("");
  const [
    getUpdateNewBirthModelFriendlyName,
    setUpdateNewBirthModelFriendlyName,
  ] = useState("");
  const [getUpdateNewBirthModelType, setUpdateNewBirthModelType] = useState("");
  const [getUpdateNewBirthModelURL, setUpdateNewBirthModelURL] = useState("");
  const [getUpdateNewBirthDOB, setUpdateNewBirthDOB] = useState("");
  const [getUpdateNewBirthGender, setUpdateNewBirthGender] = useState("");
  const [getUpdateNewBirthMaleParent, setUpdateNewBirthMaleParent] =
    useState("");
  const [getUpdateNewBirthFemaleParent, setUpdateNewBirthFemaleParent] =
    useState("");
  const [getUpdateNewBirthParentModelid, setUpdateNewBirthParentModelid] =
    useState("");
  const [getUpdateNewBirthLatitude, setUpdateNewBirthLatitude] = useState("");
  const [getUpdateNewBirthLongitude, setUpdateNewBirthLongitude] = useState("");
  const [getUpdateNewBirthAltitude, setUpdateNewBirthAltitude] = useState("");
  const [getUpdateNewBirthDescription, setUpdateNewBirthDescription] =
    useState("");
  /********** End Of Birth ****************/
  /********** Health Record ****************/
  const [showHealthRecords, hideHealthRecords] = useState(false);
  const [showNewHealthRecordPopup, hideNewHealthRecordPopup] = useState(false);
  const [getHealthRecords, setHealthRecords] = useState([]);

  const [getNewHealthAssetid, setNewHealthAssetid] = useState("");
  const [getNewHealthAssetFriendlyName, setNewHealthAssetFriendlyName] =
    useState("");
  const [getNewHealthUserid, setNewHealthUserid] = useState(username);
  const [getNewHealthMedicineDate, setNewHealthMedicineDate] = useState("");
  const [getNewHealthVaccinationDate, setNewHealthVaccinationDate] =
    useState("");
  const [getNewHealthExamine, setNewHealthExamine] = useState("");
  const [getNewHealthSurgicalProcedure, setNewHealthSurgicalProcedure] =
    useState("");
  const [getNewHealthVaccinationType, setNewHealthVaccinationType] =
    useState("");
  const [getNewHealthMedicineType, setNewHealthMedicineType] = useState("");
  const [getNewHealthDiseaseType, setNewHealthDiseaseType] = useState("");
  const [getNewHealthTreatmentDate, setNewHealthTreatmentDate] = useState("");
  const [getNewHealthLatitude, setNewHealthLatitude] = useState("");
  const [getNewHealthLongitude, setNewHealthLongitude] = useState("");
  const [getNewHealthAltitude, setNewHealthAltitude] = useState("");
  const [getNewHealthDescription, setNewHealthDescription] = useState("");

  const [getDeleteHealthAssetid, setDeleteHealthAssetid] = useState("");
  const [showDeleteHealthAssetPopup, hideDeleteHealthAssetPopup] =
    useState(false);

  const [showUpdateNewHealthRecordPopup, hideUpdateNewHealthRecordPopup] =
    useState(false);
  const [getUpdateNewHealthAssetid, setUpdateNewHealthAssetid] = useState("");
  const [
    getUpdateNewHealthAssetFriendlyName,
    setUpdateNewHealthAssetFriendlyName,
  ] = useState("");
  const [getUpdateNewHealthUserid, setUpdateNewHealthUserid] = useState("");
  const [getUpdateNewHealthMedicineDate, setUpdateNewHealthMedicineDate] =
    useState("");
  const [getUpdateNewHealthVaccinationDate, setUpdateNewHealthVaccinationDate] =
    useState("");
  const [getUpdateNewHealthExamine, setUpdateNewHealthExamine] = useState("");
  const [
    getUpdateNewHealthSurgicalProcedure,
    setUpdateNewHealthSurgicalProcedure,
  ] = useState("");
  const [getUpdateNewHealthVaccinationType, setUpdateNewHealthVaccinationType] =
    useState("");
  const [getUpdateNewHealthMedicineType, setUpdateNewHealthMedicineType] =
    useState("");
  const [getUpdateNewHealthDiseaseType, setUpdateNewHealthDiseaseType] =
    useState("");
  const [getUpdateNewHealthTreatmentDate, setUpdateNewHealthTreatmentDate] =
    useState("");
  const [getUpdateNewHealthLatitude, setUpdateNewHealthLatitude] = useState("");
  const [getUpdateNewHealthLongitude, setUpdateNewHealthLongitude] =
    useState("");
  const [getUpdateNewHealthAltitude, setUpdateNewHealthAltitude] = useState("");
  const [getUpdateNewHealthDescription, setUpdateNewHealthDescription] =
    useState("");
  /********** End Of Health Record ****************/
  /********** Nutrition ****************/
  const [showNutrition, hideNutrition] = useState(false);
  const [getNutritions, setNutritions] = useState([]);

  const [showAddNutritionPopup, hideAddNutritionPopup] = useState(false);
  const [getAddNutritionAssetid, setAddNutritionAssetid] = useState("");
  const [getAddNutritionAssetFriendlyName, setAddNutritionAssetFriendlyName] =
    useState("");
  const [getAddNutritionUserid, setAddNutritionUserid] = useState(username);
  const [getAddNutritionFeedType, setAddNutritionFeedType] = useState("");
  const [getAddNutritionSchedule, setAddNutritionSchedule] = useState("");
  const [getAddNutritionLatitude, setAddNutritionLatitude] = useState("");
  const [getAddNutritionLongitude, setAddNutritionLongitude] = useState("");
  const [getAddNutritionAltitude, setAddNutritionAltitude] = useState("");
  const [getAddNutritionDescription, setAddNutritionDescription] = useState("");

  const [showDeleteNutrition, hideDeleteNutrition] = useState(false);
  const [getDeleteNutritionAssetid, setDeleteNutritionAssetid] = useState("");

  const [showUpdateAddNutritionPopup, hideUpdateAddNutritionPopup] =
    useState(false);
  const [getUpdateAddNutritionAssetid, setUpdateAddNutritionAssetid] =
    useState("");
  const [
    getUpdateAddNutritionAssetFriendlyName,
    setUpdateAddNutritionAssetFriendlyName,
  ] = useState("");
  const [getUpdateAddNutritionUserid, setUpdateAddNutritionUserid] =
    useState("");
  const [getUpdateAddNutritionFeedType, setUpdateAddNutritionFeedType] =
    useState("");
  const [getUpdateAddNutritionSchedule, setUpdateAddNutritionSchedule] =
    useState("");
  const [getUpdateAddNutritionLatitude, setUpdateAddNutritionLatitude] =
    useState("");
  const [getUpdateAddNutritionLongitude, setUpdateAddNutritionLongitude] =
    useState("");
  const [getUpdateAddNutritionAltitude, setUpdateAddNutritionAltitude] =
    useState("");
  const [getUpdateAddNutritionDescription, setUpdateAddNutritionDescription] =
    useState("");
  const [
    getUpdateAddNutritionExistingFeedType,
    setUpdateAddNutritionExistingFeedType,
  ] = useState("");
  /********** End Of Nutrition ****************/
  /********** Weighing ****************/
  const [showWeighing, hideWeighing] = useState(false);
  const [getWeighingDetail, setWeighingDetail] = useState([]);

  const [showNewWeighingPopup, hideNewWeighingPopup] = useState(false);
  const [getNewWeighingAssetid, setNewWeighingAssetid] = useState("");
  const [getNewWeighingAssetFriendlyName, setNewWeighingAssetFriendlyName] =
    useState("");
  const [getNewWeighingUserid, setNewWeighingUserid] = useState(username);
  const [getNewWeighingWeightAtBirth, setNewWeighingWeightAtBirth] =
    useState("");
  const [getNewWeighingWeaningAge, setNewWeighingWeaningAge] = useState("");
  const [getNewWeighingWeaningWeight, setNewWeighingWeaningWeight] =
    useState("");
  const [getNewWeighingAgeAtSlaughter, setNewWeighingAgeAtSlaughter] =
    useState("");
  const [getNewWeighingCarcassWeight, setNewWeighingCarcassWeight] =
    useState("");
  const [getNewWeighingGender, setNewWeighingGender] = useState("");
  const [getNewWeighingLatitude, setNewWeighingLatitude] = useState("");
  const [getNewWeighingLongitude, setNewWeighingLongitude] = useState("");
  const [getNewWeighingAltitude, setNewWeighingAltitude] = useState("");
  const [getNewWeighingDescription, setNewWeighingDescription] = useState("");
  const [getNewWeighingMimetype, setNewWeighingMimetype] = useState("");
  const [getNewWeighingWidth, setNewWeighingWidth] = useState("");
  const [getNewWeighingHeight, setNewWeighingHeight] = useState("");
  const [getNewWeighingDepth, setNewWeighingDepth] = useState("");
  const [getNewWeighingImage, setNewWeighingImage] = useState("");

  const [showWeighingDeletePopup, hideWeighingDeletePopup] = useState(false);
  const [getWeighingDeleteAssetid, setWeighingDeleteAssetid] = useState("");

  const [showUpdateNewWeighingPopup, hideUpdateNewWeighingPopup] =
    useState(false);
  const [getUpdateNewWeighingAssetid, setUpdateNewWeighingAssetid] =
    useState("");
  const [
    getUpdateNewWeighingAssetFriendlyName,
    setUpdateNewWeighingAssetFriendlyName,
  ] = useState("");
  const [getUpdateNewWeighingUserid, setUpdateNewWeighingUserid] = useState("");
  const [getUpdateNewWeighingWeightAtBirth, setUpdateNewWeighingWeightAtBirth] =
    useState("");
  const [getUpdateNewWeighingWeaningAge, setUpdateNewWeighingWeaningAge] =
    useState("");
  const [getUpdateNewWeighingWeaningWeight, setUpdateNewWeighingWeaningWeight] =
    useState("");
  const [
    getUpdateNewWeighingAgeAtSlaughter,
    setUpdateNewWeighingAgeAtSlaughter,
  ] = useState("");
  const [getUpdateNewWeighingCarcassWeight, setUpdateNewWeighingCarcassWeight] =
    useState("");
  const [getUpdateNewWeighingGender, setUpdateNewWeighingGender] = useState("");
  const [getUpdateNewWeighingLatitude, setUpdateNewWeighingLatitude] =
    useState("");
  const [getUpdateNewWeighingLongitude, setUpdateNewWeighingLongitude] =
    useState("");
  const [getUpdateNewWeighingAltitude, setUpdateNewWeighingAltitude] =
    useState("");
  const [getUpdateNewWeighingDescription, setUpdateNewWeighingDescription] =
    useState("");
  const [getUpdateNewWeighingMimetype, setUpdateNewWeighingMimetype] =
    useState("");
  const [getUpdateNewWeighingWidth, setUpdateNewWeighingWidth] = useState("");
  const [getUpdateNewWeighingHeight, setUpdateNewWeighingHeight] = useState("");
  const [getUpdateNewWeighingDepth, setUpdateNewWeighingDepth] = useState("");
  const [getUpdateNewWeighingImage, setUpdateNewWeighingImage] = useState("");
  /********** End Of Weighing ****************/
  /********** Parturition ****************/
  const [showParturition, hideParturition] = useState(false);
  const [getParturitionDetail, setParturitionDetail] = useState([]);

  const [showNewParturitionPopup, hideNewParturitionPopup] = useState(false);
  const [getNewParturitionAssetid, setNewParturitionAssetid] = useState("");
  const [
    getNewParturitionAssetfriendlyName,
    setNewParturitionAssetfriendlyName,
  ] = useState("");
  const [getNewParturitionUserid, setNewParturitionUserid] = useState(username);
  const [
    getNewParturitionWeightAtFirstMating,
    setNewParturitionWeightAtFirstMating,
  ] = useState("");
  const [
    getNewParturitionAgeAtFirstParturition,
    setNewParturitionAgeAtFirstParturition,
  ] = useState("");
  const [
    getNewParturitionBodyConditionsIndex,
    setNewParturitionBodyConditionsIndex,
  ] = useState("");
  const [getNewParturitionNumberOfMatings, setNewParturitionNumberOfMatings] =
    useState("");
  const [getNewParturitionSupermogram, setNewParturitionSupermogram] =
    useState("");
  const [getNewParturitionBreed, setNewParturitionBreed] = useState("");
  const [getNewParturitionBreedPercentage, setNewParturitionBreedPercentage] =
    useState("");
  const [getNewParturitionBreederId, setNewParturitionBreederId] = useState("");
  const [getNewParturitionBreederAge, setNewParturitionBreederAge] =
    useState("");
  const [getNewParturitionBreedingDate, setNewParturitionBreedingDate] =
    useState("");
  const [getNewParturitionNextBreeding, setNewParturitionNextBreeding] =
    useState("");
  const [
    getNewParturitionExpectedKiddingDate,
    setNewParturitionExpectedKiddingDate,
  ] = useState("");
  const [getNewParturitionDeliveredDate, setNewParturitionDeliveredDate] =
    useState("");
  const [
    getNewParturitionTotalKidsDelivered,
    setNewParturitionTotalKidsDelivered,
  ] = useState("");
  const [getNewParturitionDisposal, setNewParturitionDisposal] = useState("");
  const [getNewParturitionLatitude, setNewParturitionLatitude] = useState("");
  const [getNewParturitionLongitude, setNewParturitionLongitude] = useState("");
  const [getNewParturitionAltitude, setNewParturitionAltitude] = useState("");
  const [getNewParturitionDescription, setNewParturitionDescription] =
    useState("");

  const [showParturitionDeletePopup, hideParturitionDeletePopup] =
    useState(false);
  const [getParturitionDeleteAssetid, setParturitionDeleteAssetid] =
    useState("");

  const [showUpdateNewParturitionPopup, hideUpdateNewParturitionPopup] =
    useState(false);
  const [getUpdateNewParturitionAssetid, setUpdateNewParturitionAssetid] =
    useState("");
  const [
    getUpdateNewParturitionAssetfriendlyName,
    setUpdateNewParturitionAssetfriendlyName,
  ] = useState("");
  const [getUpdateNewParturitionUserid, setUpdateNewParturitionUserid] =
    useState("");
  const [
    getUpdateNewParturitionWeightAtFirstMating,
    setUpdateNewParturitionWeightAtFirstMating,
  ] = useState("");
  const [
    getUpdateNewParturitionAgeAtFirstParturition,
    setUpdateNewParturitionAgeAtFirstParturition,
  ] = useState("");
  const [
    getUpdateNewParturitionBodyConditionsIndex,
    setUpdateNewParturitionBodyConditionsIndex,
  ] = useState("");
  const [
    getUpdateNewParturitionNumberOfMatings,
    setUpdateNewParturitionNumberOfMatings,
  ] = useState("");
  const [
    getUpdateNewParturitionSupermogram,
    setUpdateNewParturitionSupermogram,
  ] = useState("");
  const [getUpdateNewParturitionBreed, setUpdateNewParturitionBreed] =
    useState("");
  const [
    getUpdateNewParturitionBreedPercentage,
    setUpdateNewParturitionBreedPercentage,
  ] = useState("");
  const [getUpdateNewParturitionBreederId, setUpdateNewParturitionBreederId] =
    useState("");
  const [getUpdateNewParturitionBreederAge, setUpdateNewParturitionBreederAge] =
    useState("");
  const [
    getUpdateNewParturitionBreedingDate,
    setUpdateNewParturitionBreedingDate,
  ] = useState("");
  const [
    getUpdateNewParturitionNextBreeding,
    setUpdateNewParturitionNextBreeding,
  ] = useState("");
  const [
    getUpdateNewParturitionExpectedKiddingDate,
    setUpdateNewParturitionExpectedKiddingDate,
  ] = useState("");
  const [
    getUpdateNewParturitionDeliveredDate,
    setUpdateNewParturitionDeliveredDate,
  ] = useState("");
  const [
    getUpdateNewParturitionTotalKidsDelivered,
    setUpdateNewParturitionTotalKidsDelivered,
  ] = useState("");
  const [getUpdateNewParturitionDisposal, setUpdateNewParturitionDisposal] =
    useState("");
  const [getUpdateNewParturitionLatitude, setUpdateNewParturitionLatitude] =
    useState("");
  const [getUpdateNewParturitionLongitude, setUpdateNewParturitionLongitude] =
    useState("");
  const [getUpdateNewParturitionAltitude, setUpdateNewParturitionAltitude] =
    useState("");
  const [
    getUpdateNewParturitionDescription,
    setUpdateNewParturitionDescription,
  ] = useState("");
  /********** End Of Parturition ****************/

  useEffect(() => {
    getAllSitesHandler();
  }, [setAllSites]);

  const backToIcons = () => {
    hideIcons(true);
    hideOnboarding(false);
    hideViewAssetOnboarding(false);
    setUpdateAssetOnboardingInfoPopup(false);
    hideAttendanceLocation(false);
    hideNewAttendanceLocationPopup(false);
    setDeletedAttendancePopup(false);
    hideUpdateAttendaneLocationPopup(false);
    hideBirth(false);
    hideNewBirthInfoPopup(false);
    setDeleteBirthInfoPopup(false);
    hideUpdateBirthInfoPopup(false);
    hideHealthRecords(false);
    hideNewHealthRecordPopup(false);
    hideDeleteHealthAssetPopup(false);
    hideUpdateNewHealthRecordPopup(false);
    hideNutrition(false);
    hideAddNutritionPopup(false);
    hideWeighing(false);
    hideNewWeighingPopup(false);
    hideWeighingDeletePopup(false);
    hideUpdateNewWeighingPopup(false);
    hideParturition(false);
    hideNewParturitionPopup(false);
    hideParturitionDeletePopup(false);
    hideUpdateNewParturitionPopup(false);
  };

  /* Onboarding Api's */
  const onBoardingIconClick = () => {
    viewAssetOnboardingHandler();
    hideIcons(false);
    hideOnboarding(true);
    hideViewAssetOnboarding(true);
  };

  const viewAssetOnboardingHandler = () => {
    setIsLoading(true);
    MainService.ViewAssetOnboardings(username)
      .then((res) => {
        setIsLoading(false);
        setViewAssetOnboarding(res.readyForOnboardAssets);
      })
      .catch((err) => {
        return NotificationManager.err(err);
      });
  };

  const updateAssetOnboardingInfoPopup = (assets) => {
    setUpdateAssetId(assets.assetid);
    setWhiteLabelCost(assets.whitelabelcost);
    setExistingAssetStatus(assets.assetstatus);
    setUpdateAssetOnboardingInfoPopup(!getUpdateAssetOnboardingInfoPopup);
  };

  const updateReadyForOnboardAssetStatusHandler = () => {
    const updateAsset = {
      assetid: getUpdateAssetId,
      whitelabelcost: getWhiteLabelCost,
      assetstatus: getUpdatedAssetStatus,
    };
    const updateData = JSON.stringify(updateAsset);

    MainService.updateReadyForOnboardAssetStatus(updateData)
      .then((res) => {
        setUpdateAssetOnboardingInfoPopup(!getUpdateAssetOnboardingInfoPopup);
        NotificationManager.success("asset status updated");
        viewAssetOnboardingHandler();
      })
      .catch((err) => {
        return NotificationManager.console.error("Something Went to Wrong");
      });
  };
  /* End Of Onboarding Api's */

  /* Attendance Location Api's*/
  const attendanceLocationIconClick = () => {
    attendanceLocationHistoryHandler();
    hideIcons(false);
    hideAttendanceLocation(true);
  };

  const attendanceLocationHistoryHandler = () => {
    setIsLoading(true);
    MainService.AttendanceLocationHistory(username)
      .then((res) => {
        setIsLoading(false);
        setAttendanceLocationHistory(res.attendanceLocations);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const addAttendanceLocationHandler = () => {
    const attendanceLocation = {
      assetid: getNewAttendanceLocationAssetid,
      assetfriendlyname: getNewAttendanceLocationAssetfriendlyName,
      parentid: window.AppConfigData.EnterpriseId,
      userid: getNewAttendanceLocationUserid,
      latitude: getNewAttendanceLocationLatitude,
      longitude: getNewAttendanceLocationLongitude,
      altitude: getNewAttendanceLocationAltitude,
      description: getNewAttendanceLocationDescription,
    };
    const newattendanceLocation = JSON.stringify(attendanceLocation);
    console.log(
      "const newattendanceLocation = JSON.stringify(attendanceLocation)",
      newattendanceLocation
    );

    MainService.NewAttendanceLocation(newattendanceLocation)
      .then((res) => {
        hideNewAttendanceLocationPopup(false);
        attendanceLocationHistoryHandler();
        NotificationManager.success("attendance location added");
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const deleteAttendanceLocationsAssetId = (assetid) => {
    setDeletedAttendanceAssetid(assetid);
    setDeletedAttendancePopup(!getDeletedAttendancePopup);
  };

  const deleteAttendanceAsset = () => {
    MainService.DeleteNewAttendanceLocation(getDeletedAttendanceAssetid)
      .then((res) => {
        setDeletedAttendancePopup(false);
        attendanceLocationHistoryHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateAttendanceLocationsAssetInfo = (assetinfo) => {
    setUpdateAttendanceLocationInfoAssetid(assetinfo.assetid);
    setUpdateAttendanceLocationInfoAssetfriendlyName(
      assetinfo.assetfriendlyname
    );
    setUpdateAttendanceLocationInfoUserid(assetinfo.userid);
    setUpdateAttendanceLocationInfoLatitude(assetinfo.latitude);
    setUpdateAttendanceLocationInfoLongitude(assetinfo.longitude);
    setUpdateAttendanceLocationInfoAltitude(assetinfo.altitude);
    setUpdateAttendanceLocationInfoDescription(assetinfo.description);
    hideUpdateAttendaneLocationPopup(!showUpdateAttendaneLocationPopup);
  };

  const updateAttendanceLocationsHandler = () => {
    const updateAttendanceAsset = {
      assetid: getUpdateAttendanceLocationInfoAssetid,
      assetfriendlyname: getUpdateAttendanceLocationInfoAssetfriendlyName,
      latitude: getUpdateAttendanceLocationInfoLatitude,
      longitude: getUpdateAttendanceLocationInfoLongitude,
      altitude: getUpdateAttendanceLocationInfoAltitude,
      description: getUpdateAttendanceLocationInfoDescription,
    };
    const updateAttendance = JSON.stringify(updateAttendanceAsset);

    MainService.UpdateAttendanceLocation(updateAttendance)
      .then((res) => {
        hideUpdateAttendaneLocationPopup(false);
        attendanceLocationHistoryHandler();
        return NotificationManager.success("attendance location updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Attendance Location Api's*/

  /* Birth Api's*/
  const birthIconClick = () => {
    getBirthDetailsHandler();
    hideIcons(false);
    hideBirth(true);
  };

  const getBirthDetailsHandler = () => {
    setIsLoading(true);
    MainService.getBirthDetails(username)
      .then((res) => {
        setIsLoading(false);
        setBirthInfos(res.birthInfos);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const getAllSitesHandler = () => {
    MainService.viewAllSites(parentId,username)
      .then((res) => {
        setAllSites(res.sites);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const setNewBirthSiteidHandler = (e) => {
    setNewBirthSiteid(e.target.value);
    const siteidfilter = getAllSites.filter((sites) => {
      return sites.siteid === e.target.value;
    });
    setNewBirthSiteName(siteidfilter[0].sitename);
    setUpdateNewBirthSiteName(siteidfilter[0].sitename);
  };

  const createNewBirthInfo = () => {
    const birth = {
      assetid: getNewBirthAssetid,
      assetfriendlyname: getNewBirthAssetFriendlyName,
      parentid: window.AppConfigData.EnterpriseId,
      userid: getNewBirthUserid,
      username: getNewBirthUserName,
      siteid: getNewBirthSiteid,
      sitename: getNewBirthSiteName,
      lotid: getNewBirthLotid,
      lotname: getNewBirthLotName,
      legacyassettype: getNewBirthLegacyAssetType,
      modelid: getNewBirthModelid,
      modelfriendlyname: getNewBirthModelFriendlyName,
      modeltype: getNewBirthModelType,
      modelurl: getNewBirthModelURL,
      dob: getNewBirthDOB,
      gender: getNewBirthGender,
      maleparent: getNewBirthMaleParent,
      femaleparent: getNewBirthFemaleParent,
      parentmodelid: getNewBirthParentModelid,
      latitude: getNewBirthLatitude,
      longitude: getNewBirthLongitude,
      altitude: getNewBirthAltitude,
      description: getNewBirthDescription,
    };
    const birthinfo = JSON.stringify(birth);

    MainService.addBirthInfo(birthinfo)
      .then((res) => {
        hideNewBirthInfoPopup(false);
        getBirthDetailsHandler();
        return NotificationManager.success("birth info added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteNewBirthInfoAssetId = (assetid) => {
    setDeleteBirthInfo(assetid);
    setDeleteBirthInfoPopup(!getDeleteBirthInfoPopup);
  };

  const deleteBirthInfoAsset = () => {
    MainService.deleteBirthInfo(getDeleteBirthInfo)
      .then((res) => {
        setDeleteBirthInfoPopup(false);
        getBirthDetailsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateBirthInfoData = (assetinfo) => {
    setUpdateNewBirthAssetid(assetinfo.assetid);
    setUpdateNewBirthAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateUpdateNewBirthUserid(assetinfo.userid);
    setUpdateNewBirthUserName(assetinfo.username);
    setUpdateNewBirthSiteid(assetinfo.siteid);
    setUpdateNewBirthSiteName(assetinfo.sitename);
    setUpdateNewBirthLotid(assetinfo.lotid);
    setUpdateNewBirthLotName(assetinfo.lotname);
    setUpdateNewBirthLegacyAssetType(assetinfo.legacyassettype);
    setUpdateNewBirthModelid(assetinfo.modelid);
    setUpdateNewBirthModelFriendlyName(assetinfo.modelfriendlyname);
    setUpdateNewBirthModelType(assetinfo.modeltype);
    setUpdateNewBirthModelURL(assetinfo.modelurl);
    setUpdateNewBirthDOB(assetinfo.dob);
    setUpdateNewBirthGender(assetinfo.gender);
    setUpdateNewBirthMaleParent(assetinfo.maleparent);
    setUpdateNewBirthFemaleParent(assetinfo.femaleparent);
    setUpdateNewBirthParentModelid(assetinfo.parentmodelid);
    setUpdateNewBirthLatitude(assetinfo.latitude);
    setUpdateNewBirthLongitude(assetinfo.longitude);
    setUpdateNewBirthAltitude(assetinfo.altitude);
    setUpdateNewBirthDescription(assetinfo.description);

    hideUpdateBirthInfoPopup(!showUpdateBirthInfoPopup);
  };

  const updateNewBirthInfoHandler = () => {
    const updatebirth = {
      assetid: getUpdateNewBirthAssetid,
      assetfriendlyname: getUpdateNewBirthAssetFriendlyName,
      siteid: getUpdateNewBirthSiteid,
      sitename: getUpdateNewBirthSiteName,
      lotid: getUpdateNewBirthLotid,
      lotname: getUpdateNewBirthLotName,
      legacyassettype: getUpdateNewBirthLegacyAssetType,
      modelid: getUpdateNewBirthModelid,
      modelfriendlyname: getUpdateNewBirthModelFriendlyName,
      modeltype: getUpdateNewBirthModelType,
      modelurl: getUpdateNewBirthModelURL,
      dob: getUpdateNewBirthDOB,
      gender: getUpdateNewBirthGender,
      maleparent: getUpdateNewBirthMaleParent,
      femaleparent: getUpdateNewBirthFemaleParent,
      parentmodelid: getUpdateNewBirthParentModelid,
      latitude: getUpdateNewBirthLatitude,
      longitude: getUpdateNewBirthLongitude,
      altitude: getUpdateNewBirthAltitude,
      description: getUpdateNewBirthDescription,
    };
    const updatebirthinfo = JSON.stringify(updatebirth);
    MainService.updateBirthInfo(updatebirthinfo)
      .then((res) => {
        hideUpdateBirthInfoPopup(false);
        getBirthDetailsHandler();
        return NotificationManager.success("birth info updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Birth Api's*/

  /* Health Records Api's */
  const healthRecordsIconClick = () => {
    healthRecordsHandler();
    hideIcons(false);
    hideHealthRecords(true);
  };

  const healthRecordsHandler = () => {
    setIsLoading(true);
    MainService.getHealthRecords(username)
      .then((res) => {
        setIsLoading(false);
        setHealthRecords(res.healthRecord);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const addHealthRecordHandler = () => {
    const addHealthRecord = {
      assetid: getNewHealthAssetid,
      assetfriendlyname: getNewHealthAssetFriendlyName,
      parentid: window.AppConfigData.EnterpriseId,
      userid: getNewHealthUserid,
      medicinedate: getNewHealthMedicineDate,
      vaccinationdate: getNewHealthVaccinationDate,
      examine: getNewHealthExamine,
      surgicalprocedure: getNewHealthSurgicalProcedure,
      vaccinationtype: getNewHealthVaccinationType,
      medicinetype: getNewHealthMedicineType,
      diseasetype: getNewHealthDiseaseType,
      treatmentdate: getNewHealthTreatmentDate,
      latitude: getNewHealthLatitude,
      longitude: getNewHealthLongitude,
      altitude: getNewHealthAltitude,
      description: getNewHealthDescription,
    };
    const healthRecord = JSON.stringify(addHealthRecord);

    MainService.addHealthRecords(healthRecord)
      .then((res) => {
        hideNewHealthRecordPopup(false);
        healthRecordsHandler();
        return NotificationManager.success("health record added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteHealthRecordAssetId = (assetid) => {
    setDeleteHealthAssetid(assetid);
    hideDeleteHealthAssetPopup(!showDeleteHealthAssetPopup);
  };

  const deleteHealthRecordAsset = () => {
    MainService.deleteHealthRecord(getDeleteHealthAssetid)
      .then((res) => {
        hideDeleteHealthAssetPopup(false);
        healthRecordsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateHealthRecordInfo = (assetinfo) => {
    setUpdateNewHealthAssetid(assetinfo.assetid);
    setUpdateNewHealthAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateNewHealthUserid(assetinfo.userid);
    setUpdateNewHealthMedicineDate(assetinfo.medicinedate);
    setUpdateNewHealthVaccinationDate(assetinfo.vaccinationdate);
    setUpdateNewHealthExamine(assetinfo.examine);
    setUpdateNewHealthSurgicalProcedure(assetinfo.surgicalprocedure);
    setUpdateNewHealthVaccinationType(assetinfo.vaccinationtype);
    setUpdateNewHealthMedicineType(assetinfo.medicinetype);
    setUpdateNewHealthDiseaseType(assetinfo.diseasetype);
    setUpdateNewHealthTreatmentDate(assetinfo.treatmentdate);
    setUpdateNewHealthLatitude(assetinfo.latitude);
    setUpdateNewHealthLongitude(assetinfo.longitude);
    setUpdateNewHealthAltitude(assetinfo.altitude);
    setUpdateNewHealthDescription(assetinfo.description);

    hideUpdateNewHealthRecordPopup(!showUpdateNewHealthRecordPopup);
  };

  const updateHealthRecordHandler = () => {
    const updateHealthRecord = {
      assetid: getUpdateNewHealthAssetid,
      assetfriendlyname: getUpdateNewHealthAssetFriendlyName,
      medicinedate: getUpdateNewHealthMedicineDate,
      vaccinationdate: getUpdateNewHealthVaccinationDate,
      examine: getUpdateNewHealthExamine,
      surgicalprocedure: getUpdateNewHealthSurgicalProcedure,
      vaccinationtype: getUpdateNewHealthVaccinationType,
      medicinetype: getUpdateNewHealthMedicineType,
      diseasetype: getUpdateNewHealthDiseaseType,
      treatmentdate: getUpdateNewHealthTreatmentDate,
      latitude: getUpdateNewHealthLatitude,
      longitude: getUpdateNewHealthLongitude,
      altitude: getUpdateNewHealthAltitude,
      description: getUpdateNewHealthDescription,
    };
    const healthrecord = JSON.stringify(updateHealthRecord);

    MainService.updateHealthRecord(healthrecord)
      .then((res) => {
        hideUpdateNewHealthRecordPopup(false);
        healthRecordsHandler();
        return NotificationManager.success("health record updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Worng");
      });
  };
  /* End Of Health Records Api's */

  /* Nutrition Api's */
  const nutritionIconClick = () => {
    hideIcons(false);
    getNutritionHandler();
    hideNutrition(true);
  };

  const getNutritionHandler = () => {
    setIsLoading(true);
    MainService.getNutrition(username)
      .then((res) => {
        setIsLoading(false);
        setNutritions(res.nutritions);
      })
      .catch((err) => {
        return NotificationManager.err(err);
      });
  };

  const addNutritionHandler = () => {
    const addnutrition = {
      assetid: getAddNutritionAssetid,
      assetfriendlyname: getAddNutritionAssetFriendlyName,
      parentid: window.AppConfigData.EnterpriseId,
      userid: getAddNutritionUserid,
      feedtype: getAddNutritionFeedType,
      schedule: getAddNutritionSchedule,
      latitude: getAddNutritionLatitude,
      longitude: getAddNutritionLongitude,
      altitude: getAddNutritionAltitude,
      description: getAddNutritionDescription,
    };
    const addnutritionstr = JSON.stringify(addnutrition);

    MainService.addNutrition(addnutritionstr)
      .then((res) => {
        hideAddNutritionPopup(false);
        getNutritionHandler();
        return NotificationManager.success("nutrition added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteNutririonAssetid = (assetid) => {
    setDeleteNutritionAssetid(assetid);
    hideDeleteNutrition(true);
  };

  const deleteNutritionHandler = () => {
    MainService.deleteNutrition(getDeleteNutritionAssetid)
      .then((res) => {
        hideDeleteNutrition(false);
        getNutritionHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateNutririonInfo = (assetinfo) => {
    setUpdateAddNutritionAssetid(assetinfo.assetid);
    setUpdateAddNutritionAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateAddNutritionUserid(assetinfo.userid);
    setUpdateAddNutritionFeedType(assetinfo.feedtype);
    setUpdateAddNutritionSchedule(assetinfo.schedule);
    setUpdateAddNutritionLatitude(assetinfo.latitude);
    setUpdateAddNutritionLongitude(assetinfo.longitude);
    setUpdateAddNutritionAltitude(assetinfo.altitude);
    setUpdateAddNutritionDescription(assetinfo.description);
    setUpdateAddNutritionExistingFeedType(assetinfo.feedtype);

    hideUpdateAddNutritionPopup(!showUpdateAddNutritionPopup);
  };

  const updateNutritionHandler = () => {
    const updateNutrition = {
      assetid: getUpdateAddNutritionAssetid,
      assetfriendlyname: getUpdateAddNutritionAssetFriendlyName,
      feedtype: getUpdateAddNutritionFeedType,
      schedule: getUpdateAddNutritionSchedule,
      latitude: getUpdateAddNutritionLatitude,
      longitude: getUpdateAddNutritionLongitude,
      altitude: getUpdateAddNutritionAltitude,
      description: getUpdateAddNutritionDescription,
    };
    const updateNutritionStr = JSON.stringify(updateNutrition);

    MainService.updateNutrition(updateNutritionStr)
      .then((res) => {
        hideUpdateAddNutritionPopup(false);
        getNutritionHandler();
        return NotificationManager.success("nutrition info updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Nutrition Api's */

  /* Weighing Api's */
  const weighingIconClick = () => {
    hideIcons(false);
    weighingDetailsHandler();
    hideWeighing(true);
  };

  const weighingDetailsHandler = () => {
    setIsLoading(true);
    MainService.getWeighingDetails(username)
      .then((res) => {
        setIsLoading(false);
        setWeighingDetail(res.weighingDetails);
      })
      .catch((err) => {
        return NotificationManager.eror(err);
      });
  };

  const addWeighingHandler = () => {
    const addweighing = {
      assetid: getNewWeighingAssetid,
      assetfriendlyname: getNewWeighingAssetFriendlyName,
      parentid: window.AppConfigData.EnterpriseId,
      userid: getNewWeighingUserid,
      weightatbirth: getNewWeighingWeightAtBirth,
      weaningage: getNewWeighingWeaningAge,
      weaningweight: getNewWeighingWeaningWeight,
      ageatslaughter: getNewWeighingAgeAtSlaughter,
      carcassweight: getNewWeighingCarcassWeight,
      gender: getNewWeighingGender,
      latitude: getNewWeighingLatitude,
      longitude: getNewWeighingLongitude,
      altitude: getNewWeighingAltitude,
      description: getNewWeighingDescription,
      mimetype: getNewWeighingMimetype,
      width: getNewWeighingWidth,
      height: getNewWeighingHeight,
      depth: getNewWeighingDepth,
      imagedata: getNewWeighingImage,
    };
    const weighingStr = JSON.stringify(addweighing);
    console.log("const weighingStr = JSON.stringify(addweighing)", addweighing);
    MainService.addWeighingDetails(weighingStr)
      .then((res) => {
        hideNewWeighingPopup(false);
        weighingDetailsHandler();
        return NotificationManager.success("weighing details added");
      })
      .catch((err) => {
        return NotificationManager.error("Something went Worng");
      });
  };

  const deleteWeighingAssetid = (assetid) => {
    setWeighingDeleteAssetid(assetid);
    hideWeighingDeletePopup(true);
  };

  const deleteWeighingHandler = () => {
    MainService.deleteWeighingDetails(getWeighingDeleteAssetid)
      .then((res) => {
        hideWeighingDeletePopup(false);
        weighingDetailsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateWeighingInfo = (assetinfo) => {
    setUpdateNewWeighingAssetid(assetinfo.assetid);
    setUpdateNewWeighingAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateNewWeighingUserid(assetinfo.userid);
    setUpdateNewWeighingWeightAtBirth(assetinfo.weightatbirth);
    setUpdateNewWeighingWeaningAge(assetinfo.weaningage);
    setUpdateNewWeighingWeaningWeight(assetinfo.weaningweight);
    setUpdateNewWeighingAgeAtSlaughter(assetinfo.ageatslaughter);
    setUpdateNewWeighingCarcassWeight(assetinfo.carcassweight);
    setUpdateNewWeighingGender(assetinfo.gender);
    setUpdateNewWeighingLatitude(assetinfo.latitude);
    setUpdateNewWeighingLongitude(assetinfo.longitude);
    setUpdateNewWeighingAltitude(assetinfo.altitude);
    setUpdateNewWeighingDescription(assetinfo.description);

    hideUpdateNewWeighingPopup(true);
  };

  const addUpdateWeighingHandler = () => {
    const updateweighing = {
      assetid: getUpdateNewWeighingAssetid,
      assetfriendlyname: getUpdateNewWeighingAssetFriendlyName,
      weightatbirth: getUpdateNewWeighingWeightAtBirth,
      weaningage: getUpdateNewWeighingWeaningAge,
      weaningweight: getUpdateNewWeighingWeaningWeight,
      ageatslaughter: getUpdateNewWeighingAgeAtSlaughter,
      carcassweight: getUpdateNewWeighingCarcassWeight,
      gender: getUpdateNewWeighingGender,
      latitude: getUpdateNewWeighingLatitude,
      longitude: getUpdateNewWeighingLongitude,
      altitude: getUpdateNewWeighingAltitude,
      description: getUpdateNewWeighingDescription,
      imagedata: getUpdateNewWeighingImage,
    };
    const weighingStr = JSON.stringify(updateweighing);

    MainService.updateWeighingDetails(weighingStr)
      .then((res) => {
        hideUpdateNewWeighingPopup(false);
        weighingDetailsHandler();
        return NotificationManager.success("weighing details updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Weighing Api's */

  /* Parturition Api's */
  const parturitionIconClick = () => {
    hideIcons(false);
    getParturitionDetailsHandler();
    hideParturition(true);
  };

  const getParturitionDetailsHandler = () => {
    setIsLoading(true);
    MainService.getParturitionDetails(username)
      .then((res) => {
        setIsLoading(false);
        setParturitionDetail(res.parturitions);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const addParturitionHandler = () => {
    const newparturition = {
      assetid: getNewParturitionAssetid,
      assetfriendlyname: getNewParturitionAssetfriendlyName,
      parentid: window.AppConfigData.EnterpriseId,
      userid: getNewParturitionUserid,
      weightatfirstmating: getNewParturitionWeightAtFirstMating,
      ageatfirstparturition: getNewParturitionAgeAtFirstParturition,
      bodyconditionsindex: getNewParturitionBodyConditionsIndex,
      numberofmatings: getNewParturitionNumberOfMatings,
      supermogram: getNewParturitionSupermogram,
      breed: getNewParturitionBreed,
      breedpercentage: getNewParturitionBreedPercentage,
      breederid: getNewParturitionBreederId,
      breederage: getNewParturitionBreederAge,
      breedingdate: getNewParturitionBreedingDate,
      nextbreeding: getNewParturitionNextBreeding,
      expectedkiddingdate: getNewParturitionExpectedKiddingDate,
      delivereddate: getNewParturitionDeliveredDate,
      totalkidsdelivered: getNewParturitionTotalKidsDelivered,
      disposal: getNewParturitionDisposal,
      latitude: getNewParturitionLatitude,
      longitude: getNewParturitionLongitude,
      altitude: getNewParturitionAltitude,
      description: getNewParturitionDescription,
    };
    const newparturitionStr = JSON.stringify(newparturition);

    MainService.addParturitionDetails(newparturitionStr)
      .then((res) => {
        hideNewParturitionPopup(false);
        getParturitionDetailsHandler();
        return NotificationManager.success("parturition details added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteParturitionAssetid = (assetid) => {
    setParturitionDeleteAssetid(assetid);
    hideParturitionDeletePopup(true);
  };

  const deleteParturitionHandler = () => {
    MainService.deleteParturitionDetails(getParturitionDeleteAssetid)
      .then((res) => {
        hideParturitionDeletePopup(false);
        getParturitionDetailsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateParturitionInfo = (ParturitionInfo) => {
    setUpdateNewParturitionAssetid(ParturitionInfo.assetid);
    setUpdateNewParturitionAssetfriendlyName(ParturitionInfo.assetfriendlyname);
    setUpdateNewParturitionUserid(ParturitionInfo.userid);
    setUpdateNewParturitionWeightAtFirstMating(
      ParturitionInfo.weightatfirstmating
    );
    setUpdateNewParturitionAgeAtFirstParturition(
      ParturitionInfo.ageatfirstparturition
    );
    setUpdateNewParturitionBodyConditionsIndex(
      ParturitionInfo.bodyconditionsindex
    );
    setUpdateNewParturitionNumberOfMatings(ParturitionInfo.numberofmatings);
    setUpdateNewParturitionSupermogram(ParturitionInfo.supermogram);
    setUpdateNewParturitionBreed(ParturitionInfo.breed);
    setUpdateNewParturitionBreedPercentage(ParturitionInfo.breedpercentage);
    setUpdateNewParturitionBreederId(ParturitionInfo.breederid);
    setUpdateNewParturitionBreederAge(ParturitionInfo.breederage);
    setUpdateNewParturitionBreedingDate(ParturitionInfo.breedingdate);
    setUpdateNewParturitionNextBreeding(ParturitionInfo.nextbreeding);
    setUpdateNewParturitionExpectedKiddingDate(
      ParturitionInfo.expectedkiddingdate
    );
    setUpdateNewParturitionDeliveredDate(ParturitionInfo.delivereddate);
    setUpdateNewParturitionTotalKidsDelivered(
      ParturitionInfo.totalkidsdelivered
    );
    setUpdateNewParturitionDisposal(ParturitionInfo.disposal);
    setUpdateNewParturitionLatitude(ParturitionInfo.latitude);
    setUpdateNewParturitionLongitude(ParturitionInfo.longitude);
    setUpdateNewParturitionAltitude(ParturitionInfo.altitude);
    setUpdateNewParturitionDescription(ParturitionInfo.description);

    hideUpdateNewParturitionPopup(true);
  };

  const updateParturitionHandler = () => {
    const updateparturition = {
      assetid: getUpdateNewParturitionAssetid,
      assetfriendlyname: getUpdateNewParturitionAssetfriendlyName,
      weightatfirstmating: getUpdateNewParturitionWeightAtFirstMating,
      ageatfirstparturition: getUpdateNewParturitionAgeAtFirstParturition,
      bodyconditionsindex: getUpdateNewParturitionBodyConditionsIndex,
      numberofmatings: getUpdateNewParturitionNumberOfMatings,
      supermogram: getUpdateNewParturitionSupermogram,
      breed: getUpdateNewParturitionBreed,
      breedpercentage: getUpdateNewParturitionBreedPercentage,
      breederid: getUpdateNewParturitionBreederId,
      breederage: getUpdateNewParturitionBreederAge,
      breedingdate: getUpdateNewParturitionBreedingDate,
      nextbreeding: getUpdateNewParturitionNextBreeding,
      expectedkiddingdate: getUpdateNewParturitionExpectedKiddingDate,
      delivereddate: getUpdateNewParturitionDeliveredDate,
      totalkidsdelivered: getUpdateNewParturitionTotalKidsDelivered,
      disposal: getUpdateNewParturitionDisposal,
      latitude: getUpdateNewParturitionLatitude,
      longitude: getUpdateNewParturitionLongitude,
      altitude: getUpdateNewParturitionAltitude,
      description: getUpdateNewParturitionDescription,
    };
    const updateparturitionstr = JSON.stringify(updateparturition);

    MainService.updateParturitionDetails(updateparturitionstr)
      .then((res) => {
        hideUpdateNewParturitionPopup(false);
        getParturitionDetailsHandler();
        return NotificationManager.success("parturition info updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Parturition Api's */

  return (
    <Fragment>
      {showIcons === true ? (
        <Box className="containerbox">
          <Paper>
            <ImageList className="imagescontainer" cols={4}>
              <ImageListItem style={{ width: "100px", height: "120px" }}>
                <img
                  src={EventOnboarding}
                  srcSet={EventOnboarding}
                  alt="EventOnboarding"
                  loading="lazy"
                  onClick={() => {
                    onBoardingIconClick();
                  }}
                />
                <p>Onboarding</p>
              </ImageListItem>
              <ImageListItem style={{ width: "100px", height: "120px" }}>
                <img
                  src={EventAttendanceLocation}
                  srcSet={EventAttendanceLocation}
                  alt="EventAttendanceLocation"
                  loading="lazy"
                  onClick={() => {
                    attendanceLocationIconClick();
                  }}
                />
                <p>Attendance </p>
              </ImageListItem>
              
              <ImageListItem style={{ width: "100px", height: "120px" }}>
                <img
                  src={Birth}
                  srcSet={Birth}
                  alt="Birth"
                  loading="lazy"
                  onClick={() => {
                    birthIconClick();
                  }}
                />
                 <p>Birth</p>
              </ImageListItem>             
              <ImageListItem style={{ width: "100px", height: "120px" }}>
                <img
                  src={HealthRecord}
                  srcSet={HealthRecord}
                  alt="HelthRecord"
                  loading="lazy"
                  onClick={() => {
                    healthRecordsIconClick();
                  }}
                />
                <p>HealthRecord</p>
              </ImageListItem>
            </ImageList>
          </Paper>
          <Paper>
            <ImageList className="imagescontainer" cols={4}>
              <ImageListItem style={{ width: "100px", height: "120px" }}>
                <img
                  src={Nutrition}
                  srcSet={Nutrition}
                  alt="Nutrition"
                  loading="lazy"
                  onClick={() => {
                    nutritionIconClick();
                  }}
                />
                <p>Nutrition</p>
              </ImageListItem>
              <ImageListItem style={{ width: "100px", height: "120px" }}>
                <img
                  src={Weighing}
                  srcSet={Weighing}
                  alt="Weighing"
                  loading="lazy"
                  onClick={() => {
                    weighingIconClick();
                  }}
                />
                <p>Weighing</p>
              </ImageListItem>
              <ImageListItem style={{ width: "100px", height: "120px" }}>
                <img
                  src={Parturition}
                  srcSet={Parturition}
                  alt="Parturition"
                  loading="lazy"
                  onClick={() => {
                    parturitionIconClick();
                  }}
                />
                <p>parturition</p>
              </ImageListItem>
              <ImageListItem style={{ width: "100px", height: "120px" }}>
                <img
                  src={Mortality}
                  srcSet={Mortality}
                  alt="Mortality"
                  loading="lazy"
                />
                <p>Mortality</p>
              </ImageListItem>
            </ImageList>
          </Paper>
          <Paper>
            <ImageList className="imagescontainer" cols={4}>
              <ImageListItem style={{ width: "100px", height: "120px" }}>
                <img
                  src={AssetOperationalStatus}
                  srcSet={AssetOperationalStatus}
                  alt="AssetOperationalStatus"
                  loading="lazy"
                />
                <p>Asset Operational Status</p>
              </ImageListItem>
              <ImageListItem style={{ width: "100px", height: "120px" }}>
                <img
                  src={Patrolling}
                  srcSet={Patrolling}
                  alt="Patrolling"
                  loading="lazy"
                />
                <p>Patrolling</p>
              </ImageListItem>
              <ImageListItem style={{ width: "100px", height: "120px" }}>
                <img
                  src={SellingCost}
                  srcSet={SellingCost}
                  alt="SellingCost"
                  loading="lazy"
                />
                <p>SellingCost</p>
              </ImageListItem>
              <ImageListItem style={{ width: "100px", height: "120px" }}>
                <img
                  src={Feedback}
                  srcSet={Feedback}
                  alt="Feedback"
                  loading="lazy"
                />
                <p>Feedback</p>
              </ImageListItem>
            </ImageList>
          </Paper>
        </Box>
      ) : null}
      {/************************ Onboarding ****************************/}
      {showOnboarding === true ? (
        <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
          <div className="topHeadersWrapper">
            <div className="topHeaders">
              <Button
                style={{ marginRight: "10px" }}
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  backToIcons();
                }}
              >
                Back
              </Button>
            </div>
            <div className="topHeaders">
              <Button
                style={{ marginRight: "10px" }}
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  viewAssetOnboardingHandler();
                }}
              >
                View Asset Onboarding
              </Button>
              <Button
                style={{ marginRight: "10px" }}
                color="primary"
                className="registerBtn"
                variant="contained"
                // onClick={()=>{getAllSites("List")}}
              >
                Add Site Onboarding
              </Button>
              <Button
                style={{ marginRight: "10px" }}
                color="primary"
                className="registerBtn"
                variant="contained"
                // onClick={()=>{getAllSites("List")}}
              >
                View Site Onboarding
              </Button>
            </div>
          </div>
          <div className="MuiGrid-root">
            {showViewAssetOnboarding === true ? (
              <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="cardStyleForDashboardItems">
                    <h4 style={{ width: "100%", textAlign: "center" }}>
                      VIEW ASSET ONBOARDINGS
                    </h4>
                    {getViewAssetOnboarding.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {getViewAssetOnboarding.map((assets, index) => (
                          <div
                            key={index}
                            className="cardStyleForDashboardItemsBody"
                          >
                            <span>
                              <p>
                                <b>Asset Id:</b>
                                {assets.assetid}
                              </p>
                              <p>
                                <b>White label Cost:</b>
                                {assets.whitelabelcost}
                              </p>
                              <p>
                                <b>Asset Status:</b>
                                {assets.assetstatus}
                              </p>
                              <div style={{ width: "100%", margin: "5px" }}>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    updateAssetOnboardingInfoPopup(assets)
                                  }
                                >
                                  Update
                                </Button>
                              </div>
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <h4>VIEW ASSET ONBOARDINGS NOT FOUND.</h4>
                    )}
                  </div>
                )}
              </Box>
            ) : null}
          </div>
        </Box>
      ) : null}
      {/************************ End Of Onboarding ****************************/}

      {/************************  Attendance Location Api's ****************************/}
      {showAttendanceLocation === true ? (
        <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Consumer User"
            className={classes.fab}
            onClick={() => {
              hideNewAttendanceLocationPopup(!showNewAttendanceLocationPopup);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
          <div className="topHeadersWrapper">
            <div className="topHeaders">
              <Button
                style={{ marginRight: "10px" }}
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  backToIcons();
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="MuiGrid-root">
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    VIEW ATTENDANCE LOCATIONS
                  </h4>
                  {getAttendanceLocationHistory.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {getAttendanceLocationHistory.map((assets, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Id:</b>
                              {assets.assetid}
                            </p>
                            <p>
                              <b>Latitude:</b>
                              {assets.latitude}
                            </p>
                            <p>
                              <b>Longitude:</b>
                              {assets.longitude}
                            </p>
                            <p>
                              <b>Altitude:</b>
                              {assets.altitude}
                            </p>
                            <p>
                              <b>Timestamp:</b>
                              {assets.timestamp}
                            </p>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ marginRight: "10px" }}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  updateAttendanceLocationsAssetInfo(assets)
                                }
                              >
                                Update
                              </Button>
                              <Button
                                style={{ marginRight: "10px" }}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  deleteAttendanceLocationsAssetId(
                                    assets.assetid
                                  )
                                }
                              >
                                Delete
                              </Button>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>VIEW ATTENDANCE LOCATIONS NOT FOUND.</h4>
                  )}
                </div>
              )}
            </Box>
          </div>
        </Box>
      ) : null}
      {/************************ End Of Attendance Location ****************************/}

      {/************************ Birth ****************************/}
      {showBirth === true ? (
        <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Consumer User"
            className={classes.fab}
            onClick={() => {
              hideNewBirthInfoPopup(!showNewBirthInfoPopup);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
          <div className="topHeadersWrapper">
            <div className="topHeaders">
              <Button
                style={{ marginRight: "10px" }}
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  backToIcons();
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="MuiGrid-root">
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    VIEW BIRTH DETAILS
                  </h4>
                  {getBirthInfos.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {getBirthInfos.map((assets, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Id:</b>
                              {assets.assetid}
                            </p>
                            <p>
                              <b>Male Parent:</b>
                              {assets.maleparent}
                            </p>
                            <p>
                              <b>Female Parent:</b>
                              {assets.femaleparent}
                            </p>
                            <p>
                              <b>D.O.B:</b>
                              {assets.dob}
                            </p>
                            <p>
                              <b>Timestamp:</b>
                              {assets.timestamp}
                            </p>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ marginRight: "10px" }}
                                size="small"
                                variant="contained"
                                onClick={() => updateBirthInfoData(assets)}
                              >
                                Update
                              </Button>
                              <Button
                                style={{ marginRight: "10px" }}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  deleteNewBirthInfoAssetId(assets.assetid)
                                }
                              >
                                Delete
                              </Button>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>VIEW BIRTH DETAILS NOT FOUND.</h4>
                  )}
                </div>
              )}
            </Box>
          </div>
        </Box>
      ) : null}
      {/************************ End Of Birth ****************************/}

      {/************************ Health Records ****************************/}
      {showHealthRecords === true ? (
        <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Consumer User"
            className={classes.fab}
            onClick={() => {
              hideNewHealthRecordPopup(!showNewHealthRecordPopup);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
          <div className="topHeadersWrapper">
            <div className="topHeaders">
              <Button
                style={{ marginRight: "10px" }}
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  backToIcons();
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="MuiGrid-root">
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    VIEW HEALTH RECORDS
                  </h4>
                  {getHealthRecords.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {getHealthRecords.map((assets, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Id:</b>
                              {assets.assetid}
                            </p>
                            <p>
                              <b>Medicine Type:</b>
                              {assets.medicinetype}
                            </p>
                            <p>
                              <b>Disease Type:</b>
                              {assets.diseasetype}
                            </p>
                            <p>
                              <b>Timestamp:</b>
                              {assets.timestamp}
                            </p>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ marginRight: "10px" }}
                                size="small"
                                variant="contained"
                                onClick={() => updateHealthRecordInfo(assets)}
                              >
                                Update
                              </Button>
                              <Button
                                style={{ marginRight: "10px" }}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  deleteHealthRecordAssetId(assets.assetid)
                                }
                              >
                                Delete
                              </Button>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>VIEW HEALTH RECORDS NOT FOUND.</h4>
                  )}
                </div>
              )}
            </Box>
          </div>
        </Box>
      ) : null}
      {/************************ End Of Health Records ****************************/}

      {/************************ Nutrition ****************************/}
      {showNutrition === true ? (
        <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Consumer User"
            className={classes.fab}
            onClick={() => {
              hideAddNutritionPopup(!showAddNutritionPopup);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
          <div className="topHeadersWrapper">
            <div className="topHeaders">
              <Button
                style={{ marginRight: "10px" }}
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  backToIcons();
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="MuiGrid-root">
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    VIEW NUTRITION DETAILS
                  </h4>
                  {getNutritions.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {getNutritions.map((nutrition, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Id:</b>
                              {nutrition.assetid}
                            </p>
                            <p>
                              <b>Feed Type:</b>
                              {nutrition.feedtype}
                            </p>
                            <p>
                              <b>Schedule:</b>
                              {nutrition.schedule}
                            </p>
                            <p>
                              <b>Timestamp:</b>
                              {nutrition.timestamp}
                            </p>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ marginRight: "10px" }}
                                size="small"
                                variant="contained"
                                onClick={() => updateNutririonInfo(nutrition)}
                              >
                                Update
                              </Button>
                              <Button
                                style={{ marginRight: "10px" }}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  deleteNutririonAssetid(nutrition.assetid)
                                }
                              >
                                Delete
                              </Button>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>VIEW NUTRITION DETAILS NOT FOUND.</h4>
                  )}
                </div>
              )}
            </Box>
          </div>
        </Box>
      ) : null}
      {/************************ End Of Nutrition ****************************/}

      {/************************ Weighing ****************************/}
      {showWeighing === true ? (
        <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Consumer User"
            className={classes.fab}
            onClick={() => {
              hideNewWeighingPopup(!showNewWeighingPopup);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
          <div className="topHeadersWrapper">
            <div className="topHeaders">
              <Button
                style={{ marginRight: "10px" }}
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  backToIcons();
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="MuiGrid-root">
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    VIEW WEIGHING DETAILS
                  </h4>
                  {getWeighingDetail.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {getWeighingDetail.map((weighing, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Id:</b>
                              {weighing.assetid}
                            </p>
                            <p>
                              <b>Weaning Age:</b>
                              {weighing.weaningage}
                            </p>
                            <p>
                              <b>Weaning Weight:</b>
                              {weighing.weaningweight}
                            </p>
                            <p>
                              <b>Timestamp:</b>
                              {weighing.timestamp}
                            </p>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ marginRight: "10px" }}
                                size="small"
                                variant="contained"
                                onClick={() => updateWeighingInfo(weighing)}
                              >
                                Update
                              </Button>
                              <Button
                                style={{ marginRight: "10px" }}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  deleteWeighingAssetid(weighing.assetid)
                                }
                              >
                                Delete
                              </Button>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>VIEW WEIGHING DETAILS NOT FOUND.</h4>
                  )}
                </div>
              )}
            </Box>
          </div>
        </Box>
      ) : null}
      {/************************ End Of Weighing ****************************/}

      {/************************ Parturition ****************************/}
      {showParturition === true ? (
        <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Consumer User"
            className={classes.fab}
            onClick={() => {
              hideNewParturitionPopup(!showNewParturitionPopup);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
          <div className="topHeadersWrapper">
            <div className="topHeaders">
              <Button
                style={{ marginRight: "10px" }}
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  backToIcons();
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="MuiGrid-root">
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    VIEW PARTURITION DETAILS
                  </h4>
                  {getParturitionDetail.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {getParturitionDetail.map((parturitions, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Id:</b>
                              {parturitions.assetid}
                            </p>
                            <p>
                              <b>Breed:</b>
                              {parturitions.breed}
                            </p>
                            <p>
                              <b>Breeder Id:</b>
                              {parturitions.breederid}
                            </p>
                            <p>
                              <b>Timestamp:</b>
                              {parturitions.timestamp}
                            </p>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <Button
                                style={{ marginRight: "10px" }}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  updateParturitionInfo(parturitions)
                                }
                              >
                                Update
                              </Button>
                              <Button
                                style={{ marginRight: "10px" }}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  deleteParturitionAssetid(parturitions.assetid)
                                }
                              >
                                Delete
                              </Button>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>VVIEW PARTURITION DETAILS NOT FOUND.</h4>
                  )}
                </div>
              )}
            </Box>
          </div>
        </Box>
      ) : null}
      {/************************ End Of Parturition ****************************/}

      {/* Update Asset Onboarding Info */}
      <Dialog
        fullScreen={fullScreen}
        open={getUpdateAssetOnboardingInfoPopup}
        aria-labelledby="updateasset"
      >
        <DialogTitle id="updateasset">Update Asset Onboarding Info</DialogTitle>
        <DialogContent>
          <div>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="assetId">Asset id:</InputLabel>
              <Input
                disabled
                id="assetId"
                name="assetId"
                type="text"
                value={getUpdateAssetId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="WhiteLabelCost">
                White Label Cost:
              </InputLabel>
              <Input
                disabled
                id="WhiteLabelCost"
                name="WhiteLabelCost"
                type="text"
                value={getWhiteLabelCost}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="ExistingAssetStatus">
                Existing Asset Status:
              </InputLabel>
              <Input
                disabled
                id="ExistingAssetStatus"
                name="ExistingAssetStatus"
                type="text"
                value={getExistingAssetStatus}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputLabel>Updated Asset Status:</InputLabel>
              <Select
                id="updatestatus"
                name="updatestatus"
                type="text"
                value={getUpdatedAssetStatus}
                onChange={(e) => {
                  setUpdatedAssetStatus(e.target.value);
                }}
              >
                <MenuItem
                  key="ReadyForWhiteLabel"
                  value="Ready For White Label"
                >
                  Ready For White Label
                </MenuItem>
                <MenuItem key="Deboard" value="Deboard">
                  Deboard
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              updateReadyForOnboardAssetStatusHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setUpdateAssetOnboardingInfoPopup(
                !getUpdateAssetOnboardingInfoPopup
              );
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Update Asset Onboarding Info */}

      {/* New Attendance Location */}
      <Dialog
        fullScreen={fullScreen}
        open={showNewAttendanceLocationPopup}
        // onClose={handleClose}
        aria-labelledby="craete Consumer User"
      >
        <DialogTitle id="createConsumerUser">
          {"New Attendance Location"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="assetid">Asset id :</InputLabel>
            <Input
              id="assetid"
              name="assetid"
              type="text"
              value={getNewAttendanceLocationAssetid}
              onChange={(e) => {
                setNewAttendanceLocationAssetid(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="friendlyName">
              Asset friendly Name :
            </InputLabel>
            <Input
              id="friendlyName"
              name="friendlyName"
              type="text"
              value={getNewAttendanceLocationAssetfriendlyName}
              onChange={(e) => {
                setNewAttendanceLocationAssetfriendlyName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="userid">User id :</InputLabel>
            <Input
              disabled
              id="userid"
              name="userid"
              type="text"
              value={getNewAttendanceLocationUserid}
              // onChange={(e) => {
              //   setNewAttendanceLocationUserid(e.target.value)
              // }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Latitude">Latitude :</InputLabel>
            <Input
              id="Latitude"
              name="Latitude"
              type="text"
              value={getNewAttendanceLocationLatitude}
              onChange={(e) => {
                setNewAttendanceLocationLatitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Longitude">Longitude:</InputLabel>
            <Input
              id="Longitude"
              name="Longitude"
              type="text"
              value={getNewAttendanceLocationLongitude}
              onChange={(e) => {
                setNewAttendanceLocationLongitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Altitude">Altitude :</InputLabel>
            <Input
              id="Altitude"
              name="Altitude"
              type="text"
              value={getNewAttendanceLocationAltitude}
              onChange={(e) => {
                setNewAttendanceLocationAltitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <p htmlFor="address">Comments/Description:</p>
            <TextareaAutosize
              minRows={5}
              style={{ minwidth: 200 }}
              value={getNewAttendanceLocationDescription}
              onChange={(e) => {
                setNewAttendanceLocationDescription(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              addAttendanceLocationHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideNewAttendanceLocationPopup(!showNewAttendanceLocationPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of New Attendance Location */}

      {/* Delete Attendance Location */}
      <Dialog
        fullScreen={fullScreen}
        open={getDeletedAttendancePopup}
        // onClose={handleClose}
        aria-labelledby="craete Consumer User"
      >
        <DialogTitle id="createConsumerUser">
          {"Delete Attendance Location"}
        </DialogTitle>
        <DialogContent>Are You Sure ?</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              deleteAttendanceAsset();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setDeletedAttendancePopup(!getDeletedAttendancePopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Delete Attendance Location */}

      {/* Update Attendance Location Info */}
      <Dialog
        fullScreen={fullScreen}
        open={showUpdateAttendaneLocationPopup}
        // onClose={handleClose}
        aria-labelledby="craete Consumer User"
      >
        <DialogTitle id="createConsumerUser">
          {"Update Attendance Location Info"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="assetid">Asset id :</InputLabel>
            <Input
              disabled
              id="assetid"
              name="assetid"
              type="text"
              value={getUpdateAttendanceLocationInfoAssetid}
              // onChange={(e) => {
              //   setUpdateAttendanceLocationInfoAssetid(e.target.value)
              // }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="friendlyName">
              Asset friendly Name :
            </InputLabel>
            <Input
              id="friendlyName"
              name="friendlyName"
              type="text"
              value={getUpdateAttendanceLocationInfoAssetfriendlyName}
              onChange={(e) => {
                setUpdateAttendanceLocationInfoAssetfriendlyName(
                  e.target.value
                );
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="userid">User id :</InputLabel>
            <Input
              disabled
              id="userid"
              name="userid"
              type="text"
              value={getUpdateAttendanceLocationInfoUserid}
              // onChange={(e) => {
              //   setNewAttendanceLocationUserid(e.target.value)
              // }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Latitude">Latitude :</InputLabel>
            <Input
              id="Latitude"
              name="Latitude"
              type="text"
              value={getUpdateAttendanceLocationInfoLatitude}
              onChange={(e) => {
                setUpdateAttendanceLocationInfoLatitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Longitude">Longitude:</InputLabel>
            <Input
              id="Longitude"
              name="Longitude"
              type="text"
              value={getUpdateAttendanceLocationInfoLongitude}
              onChange={(e) => {
                setUpdateAttendanceLocationInfoLongitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Altitude">Altitude :</InputLabel>
            <Input
              id="Altitude"
              name="Altitude"
              type="text"
              value={getUpdateAttendanceLocationInfoAltitude}
              onChange={(e) => {
                setUpdateAttendanceLocationInfoAltitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <p htmlFor="address">Comments/Description:</p>
            <TextareaAutosize
              minRows={5}
              style={{ minwidth: 200 }}
              value={getUpdateAttendanceLocationInfoDescription}
              onChange={(e) => {
                setUpdateAttendanceLocationInfoDescription(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              updateAttendanceLocationsHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideUpdateAttendaneLocationPopup(
                !showUpdateAttendaneLocationPopup
              );
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Update Attendance Location Info */}

      {/* New Birth Info */}
      <Dialog
        fullScreen={fullScreen}
        open={showNewBirthInfoPopup}
        aria-labelledby="craete Consumer User"
      >
        <DialogTitle id="createConsumerUser">{"New Birth Info"}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="assetid">Asset ID :</InputLabel>
            <Input
              id="assetid"
              name="assetid"
              type="text"
              value={getNewBirthAssetid}
              onChange={(e) => {
                setNewBirthAssetid(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="friendlyName">
              Asset Friendly Name :
            </InputLabel>
            <Input
              id="friendlyName"
              name="friendlyName"
              type="text"
              value={getNewBirthAssetFriendlyName}
              onChange={(e) => {
                setNewBirthAssetFriendlyName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="userid">User ID :</InputLabel>
            <Input
              disabled
              id="userid"
              name="userid"
              type="text"
              value={getNewBirthUserid}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Latitude">User Name :</InputLabel>
            <Input
              id="Latitude"
              name="Latitude"
              type="text"
              value={getNewBirthUserName}
              onChange={(e) => {
                setNewBirthUserName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl
            variant="standard"
            fullWidth={true}
            className={classes.formControl}
          >
            <InputLabel>Site ID</InputLabel>
            <Select
              id="sitename"
              value={getNewBirthSiteid}
              onChange={(e) => setNewBirthSiteidHandler(e)}
              label="sitename"
            >
              {getAllSites &&
                getAllSites.map((sites, index) => (
                  <MenuItem key={`${index}`} value={sites.siteid}>
                    {sites.sitename}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="userid">Site Name :</InputLabel>
            <Input
              disabled
              id="userid"
              name="userid"
              type="text"
              value={getNewBirthSiteName}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="lotid">Lot ID :</InputLabel>
            <Input
              id="lotid"
              name="lotid"
              type="text"
              value={getNewBirthLotid}
              onChange={(e) => {
                setNewBirthLotid(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="lotname">Lot Name :</InputLabel>
            <Input
              id="lotname"
              name="lotname"
              type="text"
              value={getNewBirthLotName}
              onChange={(e) => {
                setNewBirthLotName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="assetType">Legacy Asset Type :</InputLabel>
            <Input
              id="assetType"
              name="assetType"
              type="text"
              value={getNewBirthLegacyAssetType}
              onChange={(e) => {
                setNewBirthLegacyAssetType(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="modelid">Model ID :</InputLabel>
            <Input
              id="modelid"
              name="modelid"
              type="text"
              value={getNewBirthModelid}
              onChange={(e) => {
                setNewBirthModelid(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="modelname">Model Friendly Name :</InputLabel>
            <Input
              id="modelname"
              name="modelname"
              type="text"
              value={getNewBirthModelFriendlyName}
              onChange={(e) => {
                setNewBirthModelFriendlyName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="modeltype">Model Type :</InputLabel>
            <Input
              id="modeltype"
              name="modeltype"
              type="text"
              value={getNewBirthModelType}
              onChange={(e) => {
                setNewBirthModelType(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="modelurl">Model URL :</InputLabel>
            <Input
              id="modelurl"
              name="modelurl"
              type="text"
              value={getNewBirthModelURL}
              onChange={(e) => {
                setNewBirthModelURL(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            {/* <InputLabel htmlFor="dob">DOB :</InputLabel> */}
            <Input
              id="dob"
              name="dob"
              type="date"
              value={getNewBirthDOB}
              onChange={(e) => {
                setNewBirthDOB(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="gender">Gender :</InputLabel>
            <Input
              id="gender"
              name="gender"
              type="text"
              value={getNewBirthGender}
              onChange={(e) => {
                setNewBirthGender(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="maleparent">Male Parent :</InputLabel>
            <Input
              id="maleparent"
              name="maleparent"
              type="text"
              value={getNewBirthMaleParent}
              onChange={(e) => {
                setNewBirthMaleParent(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="femaleparent">Female Parent :</InputLabel>
            <Input
              id="femaleparent"
              name="femaleparent"
              type="text"
              value={getNewBirthFemaleParent}
              onChange={(e) => {
                setNewBirthFemaleParent(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="parentmodelid">Parent Model Id :</InputLabel>
            <Input
              id="parentmodelid"
              name="parentmodelid"
              type="text"
              value={getNewBirthParentModelid}
              onChange={(e) => {
                setNewBirthParentModelid(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="latitude">Latitude :</InputLabel>
            <Input
              id="latitude"
              name="latitude"
              type="text"
              value={getNewBirthLatitude}
              onChange={(e) => {
                setNewBirthLatitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="longitude">Longitude :</InputLabel>
            <Input
              id="longitude"
              name="longitude"
              type="text"
              value={getNewBirthLongitude}
              onChange={(e) => {
                setNewBirthLongitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="altitude">Altitude :</InputLabel>
            <Input
              id="altitude"
              name="altitude"
              type="text"
              value={getNewBirthAltitude}
              onChange={(e) => {
                setNewBirthAltitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <p htmlFor="address">Comments/Description:</p>
            <TextareaAutosize
              minRows={5}
              style={{ minwidth: 200 }}
              value={getNewBirthDescription}
              onChange={(e) => {
                setNewBirthDescription(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              createNewBirthInfo();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideNewBirthInfoPopup(!showNewBirthInfoPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of New Birth Info */}

      {/* Delete Birth Info */}
      <Dialog
        fullScreen={fullScreen}
        open={getDeleteBirthInfoPopup}
        aria-labelledby="craete Consumer User"
      >
        <DialogTitle id="createConsumerUser">
          {"Delete Birth Details"}
        </DialogTitle>
        <DialogContent>Are You Sure ?</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              deleteBirthInfoAsset();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setDeleteBirthInfoPopup(!getDeleteBirthInfoPopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Delete Birth Info */}

      {/* Update Birth Info */}
      <Dialog
        fullScreen={fullScreen}
        open={showUpdateBirthInfoPopup}
        aria-labelledby="craete Consumer User"
      >
        <DialogTitle id="createConsumerUser">{"Update Birth Info"}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="assetid">Asset ID :</InputLabel>
            <Input
              id="assetid"
              name="assetid"
              type="text"
              value={getUpdateNewBirthAssetid}
              // onChange={(e) => {
              //   setUpdateNewBirthAssetid(e.target.value)
              // }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="friendlyName">
              Asset Friendly Name :
            </InputLabel>
            <Input
              id="friendlyName"
              name="friendlyName"
              type="text"
              value={getUpdateNewBirthAssetFriendlyName}
              onChange={(e) => {
                setUpdateNewBirthAssetFriendlyName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="userid">User ID :</InputLabel>
            <Input
              disabled
              id="userid"
              name="userid"
              type="text"
              value={getUpdateNewBirthUserid}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Latitude">User Name :</InputLabel>
            <Input
              id="Latitude"
              name="Latitude"
              type="text"
              value={getUpdateNewBirthUserName}
              // onChange={(e) => {
              //   setNewBirthUserName(e.target.value)
              // }}
            />
          </FormControl>
          <FormControl
            variant="standard"
            fullWidth={true}
            className={classes.formControl}
          >
            <InputLabel>Site ID</InputLabel>
            <Select
              id="sitename"
              value={getUpdateNewBirthSiteid}
              onChange={(e) => setNewBirthSiteidHandler(e)}
              label="sitename"
            >
              {getAllSites &&
                getAllSites.map((sites, index) => (
                  <MenuItem key={`${index}`} value={sites.siteid}>
                    {sites.sitename}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="userid">Site Name :</InputLabel>
            <Input
              disabled
              id="userid"
              name="userid"
              type="text"
              value={getUpdateNewBirthSiteName}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="lotid">Lot ID :</InputLabel>
            <Input
              id="lotid"
              name="lotid"
              type="text"
              value={getUpdateNewBirthLotid}
              onChange={(e) => {
                setUpdateNewBirthLotid(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="lotname">Lot Name :</InputLabel>
            <Input
              id="lotname"
              name="lotname"
              type="text"
              value={getUpdateNewBirthLotName}
              onChange={(e) => {
                setUpdateNewBirthLotName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="assetType">Legacy Asset Type :</InputLabel>
            <Input
              id="assetType"
              name="assetType"
              type="text"
              value={getUpdateNewBirthLegacyAssetType}
              onChange={(e) => {
                setUpdateNewBirthLegacyAssetType(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="modelid">Model ID :</InputLabel>
            <Input
              id="modelid"
              name="modelid"
              type="text"
              value={getUpdateNewBirthModelid}
              onChange={(e) => {
                setUpdateNewBirthModelid(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="modelname">Model Friendly Name :</InputLabel>
            <Input
              id="modelname"
              name="modelname"
              type="text"
              value={getUpdateNewBirthModelFriendlyName}
              onChange={(e) => {
                setUpdateNewBirthModelFriendlyName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="modeltype">Model Type :</InputLabel>
            <Input
              id="modeltype"
              name="modeltype"
              type="text"
              value={getUpdateNewBirthModelType}
              onChange={(e) => {
                setUpdateNewBirthModelType(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="modelurl">Model URL :</InputLabel>
            <Input
              id="modelurl"
              name="modelurl"
              type="text"
              value={getUpdateNewBirthModelURL}
              onChange={(e) => {
                setUpdateNewBirthModelURL(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="dob">DOB :</InputLabel>
            <Input
              id="dob"
              name="dob"
              type="date"
              // value={getUpdateNewBirthDOB}
              defaultValue={getUpdateNewBirthDOB}
              onChange={(e) => {
                setUpdateNewBirthDOB(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="gender">Gender :</InputLabel>
            <Input
              id="gender"
              name="gender"
              type="text"
              value={getUpdateNewBirthGender}
              onChange={(e) => {
                setUpdateNewBirthGender(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="maleparent">Male Parent :</InputLabel>
            <Input
              id="maleparent"
              name="maleparent"
              type="text"
              value={getUpdateNewBirthMaleParent}
              onChange={(e) => {
                setUpdateNewBirthMaleParent(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="femaleparent">Female Parent :</InputLabel>
            <Input
              id="femaleparent"
              name="femaleparent"
              type="text"
              value={getUpdateNewBirthFemaleParent}
              onChange={(e) => {
                setUpdateNewBirthFemaleParent(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="parentmodelid">Parent Model Id :</InputLabel>
            <Input
              id="parentmodelid"
              name="parentmodelid"
              type="text"
              value={getUpdateNewBirthParentModelid}
              onChange={(e) => {
                setUpdateNewBirthParentModelid(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="latitude">Latitude :</InputLabel>
            <Input
              id="latitude"
              name="latitude"
              type="text"
              value={getUpdateNewBirthLatitude}
              onChange={(e) => {
                setUpdateNewBirthLatitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="longitude">Longitude :</InputLabel>
            <Input
              id="longitude"
              name="longitude"
              type="text"
              value={getUpdateNewBirthLongitude}
              onChange={(e) => {
                setUpdateNewBirthLongitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="altitude">Altitude :</InputLabel>
            <Input
              id="altitude"
              name="altitude"
              type="text"
              value={getUpdateNewBirthAltitude}
              onChange={(e) => {
                setUpdateNewBirthAltitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <p htmlFor="address">Comments/Description:</p>
            <TextareaAutosize
              minRows={5}
              style={{ minwidth: 200 }}
              value={getUpdateNewBirthDescription}
              onChange={(e) => {
                setUpdateNewBirthDescription(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              updateNewBirthInfoHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideUpdateBirthInfoPopup(!showUpdateBirthInfoPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Update Birth Info */}

      {/* New Health Record */}
      <Dialog
        fullScreen={fullScreen}
        open={showNewHealthRecordPopup}
        aria-labelledby="newhealthrecord"
      >
        <DialogTitle id="newhealthrecord">{"New Health Record"}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="assetid">Asset ID :</InputLabel>
            <Input
              id="assetid"
              name="assetid"
              type="text"
              value={getNewHealthAssetid}
              onChange={(e) => {
                setNewHealthAssetid(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="friendlyName">
              Asset Friendly Name :
            </InputLabel>
            <Input
              id="friendlyName"
              name="friendlyName"
              type="text"
              value={getNewHealthAssetFriendlyName}
              onChange={(e) => {
                setNewHealthAssetFriendlyName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="userid">User ID :</InputLabel>
            <Input
              disabled
              id="userid"
              name="userid"
              type="text"
              value={getNewHealthUserid}
              // onChange ={(e)=>{setNewHealthUserid(e.target.value)}}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Medicine">Medicine Date :</InputLabel>
            <Input
              id="Medicine"
              name="Medicine"
              type="date"
              value={getNewHealthMedicineDate}
              onChange={(e) => {
                setNewHealthMedicineDate(e.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Vaccination">Vaccination Date :</InputLabel>
            <Input
              id="Vaccination"
              name="Vaccination"
              type="date"
              value={getNewHealthVaccinationDate}
              onChange={(e) => {
                setNewHealthVaccinationDate(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Examine">Examine :</InputLabel>
            <Input
              id="Examine"
              name="Examine"
              type="text"
              value={getNewHealthExamine}
              onChange={(e) => {
                setNewHealthExamine(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Surgical">Surgical Procedure :</InputLabel>
            <Input
              id="Surgical"
              name="Surgical"
              type="text"
              value={getNewHealthSurgicalProcedure}
              onChange={(e) => {
                setNewHealthSurgicalProcedure(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Vaccination">Vaccination Type :</InputLabel>
            <Input
              id="Vaccination"
              name="Vaccination"
              type="text"
              value={getNewHealthVaccinationType}
              onChange={(e) => {
                setNewHealthVaccinationType(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="MedicineType">Medicine Type :</InputLabel>
            <Input
              id="MedicineType"
              name="MedicineType"
              type="text"
              value={getNewHealthMedicineType}
              onChange={(e) => {
                setNewHealthMedicineType(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Disease">Disease Type :</InputLabel>
            <Input
              id="Disease"
              name="Disease"
              type="text"
              value={getNewHealthDiseaseType}
              onChange={(e) => {
                setNewHealthDiseaseType(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Treatment">Treatment Date :</InputLabel>
            <Input
              id="Treatment"
              name="Treatment"
              type="date"
              value={getNewHealthTreatmentDate}
              onChange={(e) => {
                setNewHealthTreatmentDate(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="latitude">Latitude :</InputLabel>
            <Input
              id="latitude"
              name="latitude"
              type="text"
              value={getNewHealthLatitude}
              onChange={(e) => {
                setNewHealthLatitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="longitude">Longitude :</InputLabel>
            <Input
              id="longitude"
              name="longitude"
              type="text"
              value={getNewHealthLongitude}
              onChange={(e) => {
                setNewHealthLongitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="altitude">Altitude :</InputLabel>
            <Input
              id="altitude"
              name="altitude"
              type="text"
              value={getNewHealthAltitude}
              onChange={(e) => {
                setNewHealthAltitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <p htmlFor="address">Comments/Description:</p>
            <TextareaAutosize
              minRows={5}
              style={{ minwidth: 200 }}
              value={getNewHealthDescription}
              onChange={(e) => {
                setNewHealthDescription(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              addHealthRecordHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideNewHealthRecordPopup(!showNewHealthRecordPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of New Health Record */}

      {/* Delete HEALTH RECORD */}
      <Dialog
        fullScreen={fullScreen}
        open={showDeleteHealthAssetPopup}
        aria-labelledby="craete Consumer User"
      >
        <DialogTitle id="createConsumerUser">
          {"Delete Health Record"}
        </DialogTitle>
        <DialogContent>Are You Sure ?</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              deleteHealthRecordAsset();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideDeleteHealthAssetPopup(!showDeleteHealthAssetPopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Delete HEALTH RECORD*/}

      {/* Update Health Record */}
      <Dialog
        fullScreen={fullScreen}
        open={showUpdateNewHealthRecordPopup}
        aria-labelledby="newhealthrecord"
      >
        <DialogTitle id="newhealthrecord">{"Update Health Record"}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="assetid">Asset ID :</InputLabel>
            <Input
              id="assetid"
              name="assetid"
              type="text"
              value={getUpdateNewHealthAssetid}
              // onChange={(e) => {
              //   setUpdateNewHealthAssetid(e.target.value)
              // }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="friendlyName">
              Asset Friendly Name :
            </InputLabel>
            <Input
              id="friendlyName"
              name="friendlyName"
              type="text"
              value={getUpdateNewHealthAssetFriendlyName}
              onChange={(e) => {
                setUpdateNewHealthAssetFriendlyName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="userid">User ID :</InputLabel>
            <Input
              disabled
              id="userid"
              name="userid"
              type="text"
              value={getUpdateNewHealthUserid}
              // onChange ={(e)=>{setUpdateNewHealthUserid(e.target.value)}}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Medicine">Medicine Date :</InputLabel>
            <Input
              id="Medicine"
              name="Medicine"
              type="date"
              // value={getUpdateNewHealthMedicineDate}
              defaultValue={getUpdateNewHealthMedicineDate}
              onChange={(e) => {
                setUpdateNewHealthMedicineDate(e.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Vaccination">Vaccination Date :</InputLabel>
            <Input
              id="Vaccination"
              name="Vaccination"
              type="date"
              // value={getUpdateNewHealthVaccinationDate}
              defaultValue={getUpdateNewHealthVaccinationDate}
              onChange={(e) => {
                setUpdateNewHealthVaccinationDate(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Examine">Examine :</InputLabel>
            <Input
              id="Examine"
              name="Examine"
              type="text"
              value={getUpdateNewHealthExamine}
              onChange={(e) => {
                setUpdateNewHealthExamine(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Surgical">Surgical Procedure :</InputLabel>
            <Input
              id="Surgical"
              name="Surgical"
              type="text"
              value={getUpdateNewHealthSurgicalProcedure}
              onChange={(e) => {
                setUpdateNewHealthSurgicalProcedure(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Vaccination">Vaccination Type :</InputLabel>
            <Input
              id="Vaccination"
              name="Vaccination"
              type="text"
              value={getUpdateNewHealthVaccinationType}
              onChange={(e) => {
                setUpdateNewHealthVaccinationType(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="MedicineType">Medicine Type :</InputLabel>
            <Input
              id="MedicineType"
              name="MedicineType"
              type="text"
              value={getUpdateNewHealthMedicineType}
              onChange={(e) => {
                setUpdateNewHealthMedicineType(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Disease">Disease Type :</InputLabel>
            <Input
              id="Disease"
              name="Disease"
              type="text"
              value={getUpdateNewHealthDiseaseType}
              onChange={(e) => {
                setUpdateNewHealthDiseaseType(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Treatment">Treatment Date :</InputLabel>
            <Input
              id="Treatment"
              name="Treatment"
              type="date"
              // value={getUpdateNewHealthTreatmentDate}
              defaultValue={getUpdateNewHealthTreatmentDate}
              onChange={(e) => {
                setUpdateNewHealthTreatmentDate(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="latitude">Latitude :</InputLabel>
            <Input
              id="latitude"
              name="latitude"
              type="text"
              value={getUpdateNewHealthLatitude}
              onChange={(e) => {
                setUpdateNewHealthLatitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="longitude">Longitude :</InputLabel>
            <Input
              id="longitude"
              name="longitude"
              type="text"
              value={getUpdateNewHealthLongitude}
              onChange={(e) => {
                setUpdateNewHealthLongitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="altitude">Altitude :</InputLabel>
            <Input
              id="altitude"
              name="altitude"
              type="text"
              value={getUpdateNewHealthAltitude}
              onChange={(e) => {
                setUpdateNewHealthAltitude(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <p htmlFor="address">Comments/Description:</p>
            <TextareaAutosize
              minRows={5}
              style={{ minwidth: 200 }}
              value={getUpdateNewHealthDescription}
              onChange={(e) => {
                setUpdateNewHealthDescription(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              updateHealthRecordHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideUpdateNewHealthRecordPopup(!showUpdateNewHealthRecordPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Update Health Record */}

      {/* New Nutrition details */}
      <Dialog
        fullScreen={fullScreen}
        open={showAddNutritionPopup}
        aria-labelledby="updateasset"
      >
        <DialogTitle id="updateasset">New Nutrition details</DialogTitle>
        <DialogContent>
          <div>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="assetId">Asset id:</InputLabel>
              <Input
                id="assetId"
                name="assetId"
                type="text"
                value={getAddNutritionAssetid}
                onChange={(e) => {
                  setAddNutritionAssetid(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
              <Input
                id="friendly"
                name="friendly"
                type="text"
                value={getAddNutritionAssetFriendlyName}
                onChange={(e) => {
                  setAddNutritionAssetFriendlyName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
              <Input
                id="ExistingAssetStatus"
                name="ExistingAssetStatus"
                type="text"
                value={getAddNutritionUserid}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputLabel>Select Feed Type:</InputLabel>
              <Select
                id="feedtype"
                name="feedtype"
                type="text"
                value={getAddNutritionFeedType}
                onChange={(e) => {
                  setAddNutritionFeedType(e.target.value);
                }}
              >
                <MenuItem key="Milk" value="Milk">
                  Milk
                </MenuItem>
                <MenuItem key="Colstrum" value="Colstrum">
                  Colstrum
                </MenuItem>
                <MenuItem key="Rough-Age" value="Rough-Age">
                  Rough-Age
                </MenuItem>
                <MenuItem key="Concentrate" value="Concentrate">
                  Concentrate
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputLabel>Select Schedule:</InputLabel>
              <Select
                id="Schedule"
                name="Schedule"
                type="text"
                value={getAddNutritionSchedule}
                onChange={(e) => {
                  setAddNutritionSchedule(e.target.value);
                }}
              >
                <MenuItem key="Morning" value="Morning">
                  Morning
                </MenuItem>
                <MenuItem key="Afternoon" value="Afternoon">
                  Afternoon
                </MenuItem>
                <MenuItem key="Evening" value="Evening">
                  Evening
                </MenuItem>
                <MenuItem key="Night" value="Night">
                  Night
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={getAddNutritionLatitude}
                onChange={(e) => {
                  setAddNutritionLatitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={getAddNutritionLongitude}
                onChange={(e) => {
                  setAddNutritionLongitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="altitude">Altitude :</InputLabel>
              <Input
                id="altitude"
                name="altitude"
                type="text"
                value={getAddNutritionAltitude}
                onChange={(e) => {
                  setAddNutritionAltitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Comments/Description:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={getAddNutritionDescription}
                onChange={(e) => {
                  setAddNutritionDescription(e.target.value);
                }}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              addNutritionHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideAddNutritionPopup(!showAddNutritionPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* New Nutrition details */}

      {/* Delete Nutrition */}
      <Dialog
        fullScreen={fullScreen}
        open={showDeleteNutrition}
        aria-labelledby="Delete Nutrition"
      >
        <DialogTitle id="Delete Nutrition">{"Delete Nutrition"}</DialogTitle>
        <DialogContent>Are You Sure ?</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              deleteNutritionHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideDeleteNutrition(!showDeleteNutrition);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Delete Nutrition*/}

      {/* Update Nutrition Info */}
      <Dialog
        fullScreen={fullScreen}
        open={showUpdateAddNutritionPopup}
        aria-labelledby="updateasset"
      >
        <DialogTitle id="updateasset">Update Nutrition Info</DialogTitle>
        <DialogContent>
          <div>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="assetId">Asset id:</InputLabel>
              <Input
                id="assetId"
                name="assetId"
                type="text"
                value={getUpdateAddNutritionAssetid}
                // onChange={(e)=>{setUpdateAddNutritionAssetid(e.target.value)}}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
              <Input
                id="friendly"
                name="friendly"
                type="text"
                value={getUpdateAddNutritionAssetFriendlyName}
                onChange={(e) => {
                  setUpdateAddNutritionAssetFriendlyName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
              <Input
                id="ExistingAssetStatus"
                name="ExistingAssetStatus"
                type="text"
                value={getUpdateAddNutritionUserid}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="ExistingFeedtype">
                Existing Feed type:
              </InputLabel>
              <Input
                id="ExistingFeedtype"
                name="ExistingFeedtype"
                type="text"
                value={getUpdateAddNutritionExistingFeedType}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputLabel>Select Feed Type:</InputLabel>
              <Select
                id="feedtype"
                name="feedtype"
                type="text"
                value={getUpdateAddNutritionFeedType}
                onChange={(e) => {
                  setUpdateAddNutritionFeedType(e.target.value);
                }}
              >
                <MenuItem key="Milk" value="Milk">
                  Milk
                </MenuItem>
                <MenuItem key="Colstrum" value="Colstrum">
                  Colstrum
                </MenuItem>
                <MenuItem key="Rough-Age" value="Rough-Age">
                  Rough-Age
                </MenuItem>
                <MenuItem key="Concentrate" value="Concentrate">
                  Concentrate
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputLabel>Select Schedule:</InputLabel>
              <Select
                id="Schedule"
                name="Schedule"
                type="text"
                value={getUpdateAddNutritionSchedule}
                onChange={(e) => {
                  setUpdateAddNutritionSchedule(e.target.value);
                }}
              >
                <MenuItem key="Morning" value="Morning">
                  Morning
                </MenuItem>
                <MenuItem key="Afternoon" value="Afternoon">
                  Afternoon
                </MenuItem>
                <MenuItem key="Evening" value="Evening">
                  Evening
                </MenuItem>
                <MenuItem key="Night" value="Night">
                  Night
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={getUpdateAddNutritionLatitude}
                onChange={(e) => {
                  setUpdateAddNutritionLatitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={getUpdateAddNutritionLongitude}
                onChange={(e) => {
                  setUpdateAddNutritionLongitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="altitude">Altitude :</InputLabel>
              <Input
                id="altitude"
                name="altitude"
                type="text"
                value={getUpdateAddNutritionAltitude}
                onChange={(e) => {
                  setUpdateAddNutritionAltitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Comments/Description:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={getUpdateAddNutritionDescription}
                onChange={(e) => {
                  setUpdateAddNutritionDescription(e.target.value);
                }}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              updateNutritionHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideUpdateAddNutritionPopup(!showUpdateAddNutritionPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Update Nutrition Info */}

      {/* New Weighing Details */}
      <Dialog
        fullScreen={fullScreen}
        open={showNewWeighingPopup}
        aria-labelledby="updateasset"
      >
        <DialogTitle id="updateasset">New Weighing Details</DialogTitle>
        <DialogContent>
          <div>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="assetId">Asset id:</InputLabel>
              <Input
                id="assetId"
                name="assetId"
                type="text"
                value={getNewWeighingAssetid}
                onChange={(e) => {
                  setNewWeighingAssetid(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
              <Input
                id="friendly"
                name="friendly"
                type="text"
                value={getNewWeighingAssetFriendlyName}
                onChange={(e) => {
                  setNewWeighingAssetFriendlyName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
              <Input
                id="ExistingAssetStatus"
                name="ExistingAssetStatus"
                type="text"
                value={getNewWeighingUserid}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Weight">Weight At Birth:</InputLabel>
              <Input
                id="Weight"
                name="Weight"
                type="text"
                value={getNewWeighingWeightAtBirth}
                onChange={(e) => {
                  setNewWeighingWeightAtBirth(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Weaning">Weaning Age:</InputLabel>
              <Input
                id="Weaning"
                name="Weaning"
                type="text"
                value={getNewWeighingWeaningAge}
                onChange={(e) => {
                  setNewWeighingWeaningAge(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="WeaningWeight">Weaning Weight:</InputLabel>
              <Input
                id="WeaningWeight"
                name="WeaningWeight"
                type="text"
                value={getNewWeighingWeaningWeight}
                onChange={(e) => {
                  setNewWeighingWeaningWeight(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Slaughter">Age At Slaughter:</InputLabel>
              <Input
                id="Slaughter"
                name="Slaughter"
                type="text"
                value={getNewWeighingAgeAtSlaughter}
                onChange={(e) => {
                  setNewWeighingAgeAtSlaughter(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Carcass">Carcass Weight:</InputLabel>
              <Input
                id="Carcass"
                name="Carcass"
                type="text"
                value={getNewWeighingCarcassWeight}
                onChange={(e) => {
                  setNewWeighingCarcassWeight(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Gender">Gender:</InputLabel>
              <Input
                id="Gender"
                name="Gender"
                type="text"
                value={getNewWeighingGender}
                onChange={(e) => {
                  setNewWeighingGender(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={getNewWeighingLatitude}
                onChange={(e) => {
                  setNewWeighingLatitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={getNewWeighingLongitude}
                onChange={(e) => {
                  setNewWeighingLongitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="altitude">Altitude :</InputLabel>
              <Input
                id="altitude"
                name="altitude"
                type="text"
                value={getNewWeighingAltitude}
                onChange={(e) => {
                  setNewWeighingAltitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Comments/Description:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={getNewWeighingDescription}
                onChange={(e) => {
                  setNewWeighingDescription(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Mimetype">Mimetype :</InputLabel>
              <Input
                id="Mimetype"
                name="Mimetype"
                type="text"
                value={getNewWeighingMimetype}
                onChange={(e) => {
                  setNewWeighingMimetype(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Width">Width :</InputLabel>
              <Input
                id="Width"
                name="Width"
                type="text"
                value={getNewWeighingWidth}
                onChange={(e) => {
                  setNewWeighingWidth(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Height">Height :</InputLabel>
              <Input
                id="Height"
                name="Height"
                type="text"
                value={getNewWeighingHeight}
                onChange={(e) => {
                  setNewWeighingHeight(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Depth">Depth :</InputLabel>
              <Input
                id="Depth"
                name="Depth"
                type="text"
                value={getNewWeighingDepth}
                onChange={(e) => {
                  setNewWeighingDepth(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Image">Image :</InputLabel>
              <Input
                id="Image"
                name="Image"
                accept="image/*"
                type="file"
                value={getNewWeighingImage}
                onChange={(e) => {
                  setNewWeighingImage(e.target.value);
                }}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              addWeighingHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideNewWeighingPopup(!showNewWeighingPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of New Weighing Details */}

      {/* Delete Weighing */}
      <Dialog
        fullScreen={fullScreen}
        open={showWeighingDeletePopup}
        aria-labelledby="Delete Weighing"
      >
        <DialogTitle id="Delete Weighing">{"Delete Weighing"}</DialogTitle>
        <DialogContent>Are You Sure ?</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              deleteWeighingHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideWeighingDeletePopup(!showWeighingDeletePopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Delete Weighing*/}

      {/* Update Weighing Info */}
      <Dialog
        fullScreen={fullScreen}
        open={showUpdateNewWeighingPopup}
        aria-labelledby="updateasset"
      >
        <DialogTitle id="updateasset">Update Weighing Info</DialogTitle>
        <DialogContent>
          <div>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="assetId">Asset id:</InputLabel>
              <Input
                id="assetId"
                name="assetId"
                type="text"
                value={getUpdateNewWeighingAssetid}
                // onChange={(e)=>{setUpdateNewWeighingAssetid(e.target.value)}}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
              <Input
                id="friendly"
                name="friendly"
                type="text"
                value={getUpdateNewWeighingAssetFriendlyName}
                onChange={(e) => {
                  setUpdateNewWeighingAssetFriendlyName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
              <Input
                id="ExistingAssetStatus"
                name="ExistingAssetStatus"
                type="text"
                value={getUpdateNewWeighingUserid}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Weight">Weight At Birth:</InputLabel>
              <Input
                id="Weight"
                name="Weight"
                type="text"
                value={getUpdateNewWeighingWeightAtBirth}
                onChange={(e) => {
                  setUpdateNewWeighingWeightAtBirth(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Weaning">Weaning Age:</InputLabel>
              <Input
                id="Weaning"
                name="Weaning"
                type="text"
                value={getUpdateNewWeighingWeaningAge}
                onChange={(e) => {
                  setUpdateNewWeighingWeaningAge(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="WeaningWeight">Weaning Weight:</InputLabel>
              <Input
                id="WeaningWeight"
                name="WeaningWeight"
                type="text"
                value={getUpdateNewWeighingWeaningWeight}
                onChange={(e) => {
                  setUpdateNewWeighingWeaningWeight(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Slaughter">Age At Slaughter:</InputLabel>
              <Input
                id="Slaughter"
                name="Slaughter"
                type="text"
                value={getUpdateNewWeighingAgeAtSlaughter}
                onChange={(e) => {
                  setUpdateNewWeighingAgeAtSlaughter(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Carcass">Carcass Weight:</InputLabel>
              <Input
                id="Carcass"
                name="Carcass"
                type="text"
                value={getUpdateNewWeighingCarcassWeight}
                onChange={(e) => {
                  setUpdateNewWeighingCarcassWeight(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Gender">Gender:</InputLabel>
              <Input
                id="Gender"
                name="Gender"
                type="text"
                value={getUpdateNewWeighingGender}
                onChange={(e) => {
                  setUpdateNewWeighingGender(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={getUpdateNewWeighingLatitude}
                onChange={(e) => {
                  setUpdateNewWeighingLatitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={getUpdateNewWeighingLongitude}
                onChange={(e) => {
                  setUpdateNewWeighingLongitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="altitude">Altitude :</InputLabel>
              <Input
                id="altitude"
                name="altitude"
                type="text"
                value={getUpdateNewWeighingAltitude}
                onChange={(e) => {
                  setUpdateNewWeighingAltitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Comments/Description:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={getUpdateNewWeighingDescription}
                onChange={(e) => {
                  setUpdateNewWeighingDescription(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Image">Image :</InputLabel>
              <Input
                id="Image"
                name="Image"
                accept="image/*"
                type="file"
                value={getUpdateNewWeighingImage}
                onChange={(e) => {
                  setUpdateNewWeighingImage(e.target.value);
                }}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              addUpdateWeighingHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideUpdateNewWeighingPopup(!showUpdateNewWeighingPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Update Weighing Info */}

      {/* New Parturition Details */}
      <Dialog
        fullScreen={fullScreen}
        open={showNewParturitionPopup}
        aria-labelledby="updateasset"
      >
        <DialogTitle id="updateasset">New Parturition Details</DialogTitle>
        <DialogContent>
          <div>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="assetId">Asset id:</InputLabel>
              <Input
                id="assetId"
                name="assetId"
                type="text"
                value={getNewParturitionAssetid}
                onChange={(e) => {
                  setNewParturitionAssetid(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
              <Input
                id="friendly"
                name="friendly"
                type="text"
                value={getNewParturitionAssetfriendlyName}
                onChange={(e) => {
                  setNewParturitionAssetfriendlyName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
              <Input
                id="ExistingAssetStatus"
                name="ExistingAssetStatus"
                type="text"
                value={getNewParturitionUserid}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Weight">Weight At First Mating:</InputLabel>
              <Input
                id="Weight"
                name="Weight"
                type="text"
                value={getNewParturitionWeightAtFirstMating}
                onChange={(e) => {
                  setNewParturitionWeightAtFirstMating(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Weaning">
                Age At First Parturition:
              </InputLabel>
              <Input
                id="Weaning"
                name="Weaning"
                type="text"
                value={getNewParturitionAgeAtFirstParturition}
                onChange={(e) => {
                  setNewParturitionAgeAtFirstParturition(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="WeaningWeight">
                Body Conditions Index:
              </InputLabel>
              <Input
                id="WeaningWeight"
                name="WeaningWeight"
                type="text"
                value={getNewParturitionBodyConditionsIndex}
                onChange={(e) => {
                  setNewParturitionBodyConditionsIndex(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Slaughter">Number Of Matings:</InputLabel>
              <Input
                id="Slaughter"
                name="Slaughter"
                type="text"
                value={getNewParturitionNumberOfMatings}
                onChange={(e) => {
                  setNewParturitionNumberOfMatings(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Carcass">Supermogram:</InputLabel>
              <Input
                id="Carcass"
                name="Carcass"
                type="text"
                value={getNewParturitionSupermogram}
                onChange={(e) => {
                  setNewParturitionSupermogram(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Gender">Breed:</InputLabel>
              <Input
                id="Gender"
                name="Gender"
                type="text"
                value={getNewParturitionBreed}
                onChange={(e) => {
                  setNewParturitionBreed(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Gender">Breed Percentage:</InputLabel>
              <Input
                id="Gender"
                name="Gender"
                type="text"
                value={getNewParturitionBreedPercentage}
                onChange={(e) => {
                  setNewParturitionBreedPercentage(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Gender">Breeder Id:</InputLabel>
              <Input
                id="Gender"
                name="Gender"
                type="text"
                value={getNewParturitionBreederId}
                onChange={(e) => {
                  setNewParturitionBreederId(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Gender">Breeder Age:</InputLabel>
              <Input
                id="Gender"
                name="Gender"
                type="text"
                value={getNewParturitionBreederAge}
                onChange={(e) => {
                  setNewParturitionBreederAge(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Gender">Breeding Date:</InputLabel>
              <Input
                id="Gender"
                name="Gender"
                type="date"
                value={getNewParturitionBreedingDate}
                onChange={(e) => {
                  setNewParturitionBreedingDate(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Gender">Next Breeding:</InputLabel>
              <Input
                id="Gender"
                name="Gender"
                type="date"
                value={getNewParturitionNextBreeding}
                onChange={(e) => {
                  setNewParturitionNextBreeding(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Gender">Expected Kidding Date:</InputLabel>
              <Input
                id="Gender"
                name="Gender"
                type="date"
                value={getNewParturitionExpectedKiddingDate}
                onChange={(e) => {
                  setNewParturitionExpectedKiddingDate(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Gender">Delivered Date:</InputLabel>
              <Input
                id="Gender"
                name="Gender"
                type="date"
                value={getNewParturitionDeliveredDate}
                onChange={(e) => {
                  setNewParturitionDeliveredDate(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Gender">Total Kids Delivered:</InputLabel>
              <Input
                id="Gender"
                name="Gender"
                type="text"
                value={getNewParturitionTotalKidsDelivered}
                onChange={(e) => {
                  setNewParturitionTotalKidsDelivered(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Gender">Disposal:</InputLabel>
              <Input
                id="Gender"
                name="Gender"
                type="text"
                value={getNewParturitionDisposal}
                onChange={(e) => {
                  setNewParturitionDisposal(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={getNewParturitionLatitude}
                onChange={(e) => {
                  setNewParturitionLatitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={getNewParturitionLongitude}
                onChange={(e) => {
                  setNewParturitionLongitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="altitude">Altitude :</InputLabel>
              <Input
                id="altitude"
                name="altitude"
                type="text"
                value={getNewParturitionAltitude}
                onChange={(e) => {
                  setNewParturitionAltitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Comments/Description:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={getNewParturitionDescription}
                onChange={(e) => {
                  setNewParturitionDescription(e.target.value);
                }}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              addParturitionHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideNewParturitionPopup(!showNewParturitionPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of New Parturition Details */}

      {/* Delete Weighing */}
      <Dialog
        fullScreen={fullScreen}
        open={showParturitionDeletePopup}
        aria-labelledby="Delete Parturition"
      >
        <DialogTitle id="Delete Parturition">
          {"Delete Parturition"}
        </DialogTitle>
        <DialogContent>Are You Sure ?</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              deleteParturitionHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideParturitionDeletePopup(!showParturitionDeletePopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Delete Weighing*/}

      {/* Update Parturition Info */}
      <Dialog
        fullScreen={fullScreen}
        open={showUpdateNewParturitionPopup}
        aria-labelledby="updateasset"
      >
        <DialogTitle id="updateasset">Update Parturition Info</DialogTitle>
        <DialogContent>
          <div>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="assetId">Asset id:</InputLabel>
              <Input
                id="assetId"
                name="assetId"
                type="text"
                value={getUpdateNewParturitionAssetid}
                onChange={(e) => {
                  setUpdateNewParturitionAssetid(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="friendly">Asset friendly Name:</InputLabel>
              <Input
                id="friendly"
                name="friendly"
                type="text"
                value={getUpdateNewParturitionAssetfriendlyName}
                onChange={(e) => {
                  setUpdateNewParturitionAssetfriendlyName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="ExistingAssetStatus">User id:</InputLabel>
              <Input
                id="ExistingAssetStatus"
                name="ExistingAssetStatus"
                type="text"
                value={getUpdateNewParturitionUserid}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Weight">Weight At First Mating:</InputLabel>
              <Input
                id="Weight"
                name="Weight"
                type="text"
                value={getUpdateNewParturitionWeightAtFirstMating}
                onChange={(e) => {
                  setUpdateNewParturitionWeightAtFirstMating(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Parturition">
                Age At First Parturition:
              </InputLabel>
              <Input
                id="Parturition"
                name="Parturition"
                type="text"
                value={getUpdateNewParturitionAgeAtFirstParturition}
                onChange={(e) => {
                  setUpdateNewParturitionAgeAtFirstParturition(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Index">Body Conditions Index:</InputLabel>
              <Input
                id="Index"
                name="Index"
                type="text"
                value={getUpdateNewParturitionBodyConditionsIndex}
                onChange={(e) => {
                  setUpdateNewParturitionBodyConditionsIndex(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="NumberOfMatings">
                Number Of Matings:
              </InputLabel>
              <Input
                id="NumberOfMatings"
                name="NumberOfMatings"
                type="text"
                value={getUpdateNewParturitionNumberOfMatings}
                onChange={(e) => {
                  setUpdateNewParturitionNumberOfMatings(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Supermogram">Supermogram:</InputLabel>
              <Input
                id="Supermogram"
                name="Supermogram"
                type="text"
                value={getUpdateNewParturitionSupermogram}
                onChange={(e) => {
                  setUpdateNewParturitionSupermogram(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Breed">Breed:</InputLabel>
              <Input
                id="Breed"
                name="Breed"
                type="text"
                value={getUpdateNewParturitionBreed}
                onChange={(e) => {
                  setUpdateNewParturitionBreed(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Percentage">Breed Percentage:</InputLabel>
              <Input
                id="Percentage"
                name="Percentage"
                type="text"
                value={getUpdateNewParturitionBreedPercentage}
                onChange={(e) => {
                  setUpdateNewParturitionBreedPercentage(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="BreederId">Breeder Id:</InputLabel>
              <Input
                id="BreederId"
                name="BreederId"
                type="text"
                value={getUpdateNewParturitionBreederId}
                onChange={(e) => {
                  setUpdateNewParturitionBreederId(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="BreederAge">Breeder Age:</InputLabel>
              <Input
                id="BreederAge"
                name="BreederAge"
                type="text"
                value={getUpdateNewParturitionBreederAge}
                onChange={(e) => {
                  setUpdateNewParturitionBreederAge(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="BreedingDate">Breeding Date:</InputLabel>
              <Input
                id="BreedingDate"
                name="BreedingDate"
                type="date"
                defaultValue={getUpdateNewParturitionBreedingDate}
                onChange={(e) => {
                  setUpdateNewParturitionBreedingDate(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="NextBreeding">Next Breeding:</InputLabel>
              <Input
                id="NextBreeding"
                name="NextBreeding"
                type="date"
                defaultValue={getUpdateNewParturitionNextBreeding}
                onChange={(e) => {
                  setUpdateNewParturitionNextBreeding(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Expected">Expected Kidding Date:</InputLabel>
              <Input
                id="Expected"
                name="Expected"
                type="date"
                defaultValue={getUpdateNewParturitionExpectedKiddingDate}
                onChange={(e) => {
                  setUpdateNewParturitionExpectedKiddingDate(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="DeliveredDate">Delivered Date:</InputLabel>
              <Input
                id="DeliveredDate"
                name="DeliveredDate"
                type="date"
                defaultValue={getUpdateNewParturitionDeliveredDate}
                onChange={(e) => {
                  setUpdateNewParturitionDeliveredDate(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Kids">Total Kids Delivered:</InputLabel>
              <Input
                id="Kids"
                name="Kids"
                type="text"
                value={getUpdateNewParturitionTotalKidsDelivered}
                onChange={(e) => {
                  setUpdateNewParturitionTotalKidsDelivered(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="Disposal">Disposal:</InputLabel>
              <Input
                id="Disposal"
                name="Disposal"
                type="text"
                value={getUpdateNewParturitionDisposal}
                onChange={(e) => {
                  setUpdateNewParturitionDisposal(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="latitude">Latitude :</InputLabel>
              <Input
                id="latitude"
                name="latitude"
                type="text"
                value={getUpdateNewParturitionLatitude}
                onChange={(e) => {
                  setUpdateNewParturitionLatitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="longitude">Longitude :</InputLabel>
              <Input
                id="longitude"
                name="longitude"
                type="text"
                value={getUpdateNewParturitionLongitude}
                onChange={(e) => {
                  setUpdateNewParturitionLongitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="altitude">Altitude :</InputLabel>
              <Input
                id="altitude"
                name="altitude"
                type="text"
                value={getUpdateNewParturitionAltitude}
                onChange={(e) => {
                  setUpdateNewParturitionAltitude(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <p htmlFor="address">Comments/Description:</p>
              <TextareaAutosize
                minRows={5}
                style={{ minwidth: 200 }}
                value={getUpdateNewParturitionDescription}
                onChange={(e) => {
                  setUpdateNewParturitionDescription(e.target.value);
                }}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              updateParturitionHandler();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              hideUpdateNewParturitionPopup(!showUpdateNewParturitionPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Of Update Parturition Info */}
    </Fragment>
  );
}
