import React, { useState, useEffect } from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import { Container } from "@material-ui/core";
import { Row,Col } from "react-bootstrap";
import {NavLink} from "react-router-dom";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./Blog.css";
export default function Blog() {
  const [blogList, setBlogList] = useState([]);
  useEffect(() => {
    blogApi();
  }, []);
  const blogApi = async () => {
    try {
      const res = await fetch("http://172.16.1.63:1337/api/blogs", {
        method: "GET",
      });

      const response = await res.json();
      setBlogList(response.data);
      console.log(response, "tyrydeydr");
    } catch (error) {
      console.error("Error fetching blog data:", error);
    }
  };

  console.log(blogList, "Tedhgfhjdgfdjkgvkg");
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="FarmerFranchiseBanner"
        bannerText="Blogs"
      />
      <Container className="subSectionWrapper">
        <Row>
      {blogList.map((blog) => (
        <Col md={4}>
        <NavLink to={`/blogDetail/${blog.id}`} className = "text-decoration-none" >
        <div className="blog-card-container"  key={blog.id}>
          <h3>{blog.attributes.blogTitle}</h3>
          <p>{blog.attributes.blogShortDescription}</p>
          <p>{blog.attributes.blogDate}</p>
          {/* {blog.attributes.blogDescription.map((paragraph, index) => (
            <p key={index}>{paragraph.children[0].text}</p>
          ))} */}
        </div>
        </NavLink>
        </Col>

      ))}
      </Row>
</Container>
      <FooterComponent />
    </>
  );
}
