import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row, Container,
  Pagination, PaginationItem, PaginationLink,
  Badge,
  Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import socketIOClients from "socket.io-client";
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../style.css';
import './Layout.css';
import AuthAxiosInstance from '../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance, EMSServcieAxiosInstance } from '../../../authAxiosInstance'
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import {
  FormControl, InputLabel, Select, MenuItem, Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  OutlinedInput,Input
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { NotificationManager } from "react-notifications";


var jsonconfig = window.appConfig;
class Gateway extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      propertyData: {},
      selectedDeviceId: "",
      changedData: {},
      propertyType: {},
      modelSelected: ''
    };
    // toast.configure({
    //   autoClose: 8000,
    //   draggable: false,
    //   //etc you get the idea
    // });
    this.setValue = this.setValue.bind(this);

  }
  getAppConfig = () => {
    return window.AppConfigData;
  };


  componentDidMount() {

    if (this.props.devId) {
      this.setState({ selectedDeviceId: this.props.devId });

    }


    this.getDeviceList();
    setTimeout(() => {
      this.selectDevice("reload");
    }, 1000);
  }


  getDeviceList() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/list`).then((resp) => {
      if (resp.data) {
        this.setState({ deviceList: resp.data }, () => {
          console.log(this.getAppConfig().ApiUrlMapp.SocketIOURL,"url")
          const socket = socketIOClients((this.getAppConfig().ApiUrlMapp.SocketIOURL));
          const filterData = this.state.deviceList.filter((d) => (d.deviceId === this.state.selectedDeviceId));
          //Commented the below code to not get live data in Property UI
          socket.on('livedata', (data) => {
            try {
              console.log(data,"devicedata");
              const filterData = this.state.deviceList.filter((d) => (d.deviceId === this.state.selectedDeviceId));
              if (filterData.length > 0) {
                const userId = localStorage.getItem("user-id");
                if (username === data.networkid.toString() && data.deviceid.toString() === filterData[0].deviceId) {

                  if (filterData[0].deviceType === data.devicetype) {
                    let propertyData = { ...this.state.propertyData };
                    if (filterData[0].deviceType === "AHUG") {
                      if (propertyData.AHUGproperty) {
                        propertyData.AHUGproperty.map((d, i) => {
                          data.AHUGproperty.map((da) => {
                            if (d.property === da.property) {
                              d.fvalue = da.value;
                            }
                          })
                        })
                        this.setState({ propertyData });
                      }
                    }

                    else if (filterData[0].deviceType === "EMMW") {
                      if (propertyData.EMMW) {
                        propertyData.EMMW.map((d, i) => {
                          data.EMMW.map((da) => {
                            if (d.property === da.property) {
                              d.fvalue = da.value;
                            }
                          })
                        })
                        this.setState({ propertyData });
                      }
                    }

                    else if (filterData[0].deviceType === "LG") {
                      if (propertyData.THS && propertyData.THS.length > 0 &&
                        propertyData.THS.length === data.THS.length) {
                        for (let k = 0; k < propertyData.THS.length; k++)
                          propertyData.THS[k].THSproperty.map((d, i) => {
                            data.THS[k].THSproperty.map((da) => {
                              if (d.property === da.property) {
                                d.fvalue = da.value;
                              }
                            })
                          })
                        this.setState({ propertyData });
                      }
                    }

                    else if (filterData[0].deviceType === "CCN") {
                      if (propertyData.CHILLER && propertyData.CHILLER.length > 0 && propertyData.CHILLER.length === data.CHILLER.length) {
                        for (let k = 0; k < propertyData.CHILLER.length; k++)
                          propertyData.CHILLER[k].CHILLERproperty.map((d, i) => {
                            data.CHILLER[k].CHILLERproperty.map((da) => {
                              if (d.property === da.property) {
                                d.fvalue = da.value;
                              }
                            })
                          })
                        this.setState({ propertyData });
                      }
                    }

                    else if (filterData[0].deviceType === "VCN") {
                      if (propertyData.VFD && propertyData.VFD.length > 0 && propertyData.VFD.length === data.VFD.length) {
                        for (let k = 0; k < propertyData.VFD.length; k++)
                          propertyData.VFD[k].VFDproperty.map((d, i) => {
                            data.VFD[k].VFDproperty.map((da) => {
                              if (d.property === da.property) {
                                d.fvalue = da.value;
                              }
                            })
                          })
                        this.setState({ propertyData });
                      }
                    }

                    else if (filterData[0].deviceType === "EMG") {
                      if (propertyData.EMG && propertyData.EMG.length > 0 &&
                        propertyData.EMG.length === data.EMG.length) {
                        for (let k = 0; k < propertyData.EMG.length; k++)
                          propertyData.EMG[k].EMproperty.map((d, i) => {
                            data.EMG[k].EMproperty.map((da) => {
                              if (d.property === da.property) {
                                d.fvalue = da.value;
                              }
                            })
                          })
                        this.setState({ propertyData });
                      }
                    }

                    else if (filterData[0].deviceType === "TCN") {
                      if (propertyData.Tstat && propertyData.Tstat.length > 0 && propertyData.Tstat.length === data.Tstat.length) {
                        for (let k = 0; k < propertyData.Tstat.length; k++)
                          propertyData.Tstat[k].Tstatproperty.map((d, i) => {
                            data.Tstat[k].Tstatproperty.map((da) => {
                              if (d.property === da.property) {
                                d.fvalue = da.value;
                              }
                            })
                          })
                        this.setState({ propertyData });
                      }
                    }

                    else {
                      if (propertyData.properties) {
                        propertyData.properties.map((d, i) => {
                          data.properties.map((da) => {
                            if (d.property === da.property) {
                              d.fvalue = da.value;
                            }
                          })
                        })
                        console.log(propertyData,"propdata")
                        this.setState({ propertyData });
                      }
                    }

                  }
                }
              }
            }
            catch (cerror) {

            }

          })
        });
      }
      else {
        this.setState({ deviceList: [] });
      }
    }).catch((err) => {

    })
  }

  selectDevice(type, evt) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    this.setState({ showspinner: true, changedData: {} });
    let val = (type === "reload") ? this.state.selectedDeviceId : type.target.value;
    const filterDevice = this.state.deviceList.filter((d) => (d.deviceId === val));

    if (filterDevice.length > 0) {
      this.setState({ selectedDeviceId: filterDevice[0].deviceId, modelSelected: filterDevice[0].model });
      this.getValueType(filterDevice[0].deviceType, filterDevice[0].model);

      DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${filterDevice[0].deviceId}/metadata?deviceType=${filterDevice[0].deviceType}&model=${filterDevice[0].model}`)

        .then((res) => {
          if (res && res.data && (Array.isArray(res.data) === false)) {
            EMSServcieAxiosInstance.get(`/ems/livedata/${username}/${filterDevice[0].deviceId}/properties`,
              { params: { deviceType: filterDevice[0].deviceType } })
              .then((re) => {
                EMSServcieAxiosInstance.get(`ems/device/${username}/${filterDevice[0].deviceId}/desiredvals`)
                  .then((cmdres) => {
                    var cmdresp = cmdres.data;
                    try {
                      if (re.data && re.data !== 'failed' && re.data !== 'error') {
                        if (re.data.updatedat) {
                          res.data.updatedat = re.data.updatedat;
                        }

                        if (filterDevice[0].deviceType === "AHUG" && res.data.AHUGproperty) {
                          res.data.AHUGproperty.map((dap, i) => {
                            dap.fvalue = dap.value;
                          })
                          res.data.AHUGproperty.map((d, i) => {
                            re.data.AHUGproperty.map((da) => {
                              if (d.property === da.property) {
                                d.value = da.value;
                                d.fvalue = da.value;
                                if (cmdresp.properties.length > 0) {
                                  cmdresp.properties.map((drs) => {
                                    if (drs.property === da.property) {
                                      d.value = drs.value;
                                    }
                                  })
                                }
                              }
                            })
                          })
                        }

                        if (filterDevice[0].deviceType === "EMMW" && res.data.EMMW) {
                          res.data.EMMW.map((dap, i) => {
                            dap.fvalue = dap.value;
                          })
                          res.data.EMMW.map((d, i) => {
                            re.data.EMMW.map((da) => {
                              if (d.property === da.property) {
                                d.value = da.value;
                                d.fvalue = da.value;
                                if (cmdresp.properties.length > 0) {
                                  cmdresp.properties.map((drs) => {
                                    if (drs.property === da.property) {
                                      d.value = drs.value;
                                    }
                                  })
                                }
                              }

                            })
                          })
                        }

                        if (filterDevice[0].deviceType === "TCN" && res.data.Tstat) {
                          for (let k = 0; k < res.data.Tstat.length; k++) {
                            res.data.Tstat[k].Tstatproperty.map((dap, i) => {
                              dap.fvalue = dap.value;
                            })
                            res.data.Tstat[k].Tstatproperty.map((d, i) => {
                              re.data.Tstat[k].Tstatproperty.map((da) => {
                                if (d.property === da.property) {
                                  d.value = da.value;
                                  d.fvalue = da.value;
                                  if (cmdresp.properties.length > 0) {
                                    cmdresp.properties.map((drs) => {
                                      if (drs.property === da.property) {
                                        d.value = drs.value;
                                      }
                                    })
                                  }
                                }

                              })
                            })
                            res.data.Tstat[k].TstatID = re.data.Tstat[k].TstatID;
                          }
                        }

                        if (filterDevice[0].deviceType === "VCN" && res.data.VFD) {
                          for (let k = 0; k < res.data.VFD.length; k++) {
                            res.data.VFD[k].VFDproperty.map((dap, i) => {
                              dap.fvalue = dap.value;
                            })
                            res.data.VFD[k].VFDproperty.map((d, i) => {
                              re.data.VFD[k].VFDproperty.map((da) => {
                                if (d.property === da.property) {
                                  d.value = da.value;
                                  d.fvalue = da.value;
                                  if (cmdresp.properties.length > 0) {
                                    cmdresp.properties.map((drs) => {
                                      if (drs.property === da.property) {
                                        d.value = drs.value;
                                      }
                                    })
                                  }
                                }
                              })
                            })
                            res.data.VFD[k].VFDID = re.data.VFD[k].VFDID;
                          }
                        }

                        if (filterDevice[0].deviceType === "EMG") {
                          for (let k = 0; k < res.data.EMG.length; k++) {
                            res.data.EMG[k].EMproperty.map((dap, i) => {
                              dap.fvalue = dap.value;
                            })
                            res.data.EMG[k].EMproperty.map((d, i) => {
                              re.data.EMG[k].EMproperty.map((da) => {
                                if (d.property === da.property) {
                                  d.value = da.value;
                                  d.fvalue = da.value;
                                  if (cmdresp.properties.length > 0) {
                                    cmdresp.properties.map((drs) => {
                                      if (drs.property === da.property) {
                                        d.value = drs.value;
                                      }
                                    })
                                  }
                                }
                              })
                            })
                            res.data.EMG[k].EMID = re.data.EMG[k].EMID;
                          }
                        }

                        if (filterDevice[0].deviceType === "CCN" && res.data.CHILLER) {


                          for (let k = 0; k < res.data.CHILLER.length; k++) {
                            res.data.CHILLER[k].CHILLERproperty.map((dap, i) => {
                              dap.fvalue = dap.value;
                            })
                            res.data.CHILLER[k].CHILLERproperty.map((d, i) => {
                              re.data.CHILLER[k].CHILLERproperty.map((da) => {
                                if (d.property === da.property) {
                                  d.value = da.value;
                                  d.fvalue = da.value;
                                  if (cmdresp.properties.length > 0) {
                                    cmdresp.properties.map((drs) => {
                                      if (drs.property === da.property) {
                                        d.value = drs.value;
                                      }
                                    })
                                  }
                                }
                              })
                            })
                            res.data.CHILLER[k].CHILLERID = re.data.CHILLER[k].CHILLERID;
                          }

                        }

                        if (filterDevice[0].deviceType === "LG" && res.data.THS) {

                          for (let k = 0; k < res.data.THS.length; k++) {
                            res.data.THS[k].THSproperty.map((dap, i) => {
                              dap.fvalue = dap.value;
                            })
                            res.data.THS[k].THSproperty.map((d, i) => {
                              re.data.THS[k].THSproperty.map((da) => {
                                if (d.property === da.property) {
                                  d.value = da.value;
                                  d.fvalue = da.value;
                                  if (cmdresp.properties.length > 0) {
                                    cmdresp.properties.map((drs) => {
                                      if (drs.property === da.property) {
                                        d.value = drs.value;
                                      }
                                    })
                                  }
                                }
                              })
                            })
                            res.data.THS[k].THSID = re.data.THS[k].THSID;
                          }
                        }

                        if (res.data.properties) {
                          res.data.properties.map((dap, i) => {
                            dap.fvalue = dap.value;
                          })
                          res.data.properties.map((d, i) => {
                            re.data.properties.map((da) => {
                              if (d.property === da.property) {
                                d.value = da.value;
                                d.fvalue = da.value;
                                if (cmdresp.properties.length > 0) {
                                  cmdresp.properties.map((drs) => {
                                    if (drs.property === da.property) {
                                      d.value = drs.value;
                                    }
                                  })
                                }
                              }
                            })
                          })
                        }


                      }
                      else {
                        this.setState({ showspinner: false, propertyData: {} });
                      }
                    }
                    catch (cerror) {
                      // toast.error("Something went wrong.", {
                      //   position: toast.POSITION.TOP_RIGHT
                      // });
                      return NotificationManager.error("Some error occured");
                    }
                    this.setState({ showspinner: false });
                    this.setState({ propertyData: res.data });
                  }).catch((err) => {

                  })

              }).catch((err) => {
                this.setState({ showspinner: false, propertyData: {} });
              })
          }
          else {
            this.setState({ showspinner: false, propertyData: {} });
          }

        }).catch((err) => {
          this.setState({ showspinner: false, propertyData: {} });
        })
    } else {
      this.setState({ selectedDeviceId: "", showspinner: false, propertyData: {} });
    }
  }

  getParsedValue(value, type) {
    switch (type) {
      case "string":
        return value;
        break;
      case "integer":
        return parseInt(value);
        break;
      case "float":
        return parseFloat(value);
        break;
      default:
        return value
    }

  }

  getValueType(valueType, model) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/modelinfo/${valueType}/${model}`).then((res) => {
      let typeJson = {};
      if (res.data) {
        var data = res.data.properties;
        let propnamearray = [];
        switch (valueType) {
          case 'AHUG':
            propnamearray = data.AHUGproperty;
            break;
          case 'CCN':
            propnamearray = data.CHILLER[0].CHILLERproperty;
            break;
          case 'LG':
            propnamearray = data.THS[0].THSproperty;
            break;
          case 'TCN':
            propnamearray = data.Tstat[0].Tstatproperty;
            break;
          case 'VCN':
            propnamearray = data.VFD[0].VFDproperty;
            break;
          case 'EMG':
            propnamearray = data.EMG[0].EMproperty;
            break;
          case 'EMMW':
            propnamearray = data.EMMW;
            break;
          default:
            propnamearray = data.properties;
            break;
          // code block
        }

        propnamearray.map((d) => {
          typeJson[d.property] = { type: d['value type'] ? d['value type'] : d['valuetype'], mandatory: d.mandatory };
        })

        // res.data.properties.map((d) => {
        //   typeJson[d.property] = { type: d['value type'], mandatory: d.mandatory };
        // })
        this.setState({ propertyType: typeJson });
      }
      else {
        this.setState({ propertyType: typeJson });
      }
    }).catch((err) => {

    });
  }

  setValue(id, evt) {
    let propertyData = { ...this.state.propertyData };
    const filterDevice = this.state.deviceList.filter((d) => (d.deviceId === this.state.selectedDeviceId));
    const devType = (filterDevice.length > 0) ? filterDevice[0].deviceType : "";
    if (Object.keys(this.state.propertyType).length > 0) {
      try {
        if (devType === "AHUG") {
          let changedData = { ...this.state.changedData };
          propertyData.AHUGproperty.map((d) => {
            if (d["property"] === evt.target.name) {
              d["value"] = evt.target.value;
            }
          });
          if (changedData["AHUGproperty"]) {
            let c = changedData["AHUGproperty"].filter((d) => d.property === evt.target.name);

            if (c.length === 0) {
              changedData["AHUGproperty"].push({ property: evt.target.name, value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type) });
            } else {
              changedData["AHUGproperty"].map((d) => {
                if (d.property === evt.target.name) {
                  d.value = this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type);
                }

              })
            }

          } else {
            changedData["AHUGproperty"] = [{ property: evt.target.name, value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type) }]
          }
          console.log("changedData", changedData);
          this.setState({ propertyData, changedData }, () => {
            console.log(this.state.changedData);
          });
        }
        else if (devType === "EMMW") {
          let changedData = { ...this.state.changedData };
          propertyData.EMMW.map((d) => {
            if (d["property"] === evt.target.name) {
              d["value"] = evt.target.value;
            }
          });
          if (changedData["EMMW"]) {
            let c = changedData["EMMW"].filter((d) => d.property === evt.target.name);

            if (c.length === 0) {
              changedData["EMMW"].push({ property: evt.target.name, value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type) });
            } else {
              changedData["EMMW"].map((d) => {
                if (d.property === evt.target.name) {
                  d.value = this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type);
                }

              })
            }

          } else {
            changedData["EMMW"] = [{ property: evt.target.name, value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type) }]
          }
          console.log("changedData", changedData);
          this.setState({ propertyData, changedData }, () => {
            console.log(this.state.changedData);
          });
        }
        else if (devType === "VCN") {
          let changedData = { ...this.state.changedData };
          let index = propertyData.VFD.findIndex(x => x.VFDID === id);
          propertyData.VFD[index].VFDproperty.map((d) => {
            if (d["property"] === evt.target.name) {
              d["value"] = evt.target.value;
            }
          });
          if (changedData["VFD"]) {
            let idchc = changedData["VFD"].filter((d) => d.VFDID === id);
            if (idchc.length === 0) {
              let vfdprop1 = [{
                property: evt.target.name,
                value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
              }];
              changedData.VFD.push({ VFDID: id, NumberofProperties: 1, VFDproperty: vfdprop1 });
            }
            else {
              let index1 = changedData.VFD.findIndex(x => x.VFDID === id);
              let c = changedData["VFD"][index1]["VFDproperty"].filter((d) => d.property === evt.target.name);

              if (c.length === 0) {
                changedData["VFD"][index1]["VFDproperty"].push({
                  property: evt.target.name,
                  value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
                });
                changedData["VFD"][index1]["NumberofProperties"] = changedData["VFD"][index1]["VFDproperty"].length;
              }
              else {
                changedData["VFD"][index1]["VFDproperty"].map((d) => {
                  if (d.property === evt.target.name) {
                    d.value = this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type);
                  }
                })
              }


            }
          } else {
            let vfdprop = [{
              property: evt.target.name,
              value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
            }]
            changedData.VFD = [{ VFDID: id, NumberofProperties: 1, VFDproperty: vfdprop }];
          }

          this.setState({ propertyData, changedData }, () => {
            console.log(this.state.changedData);
          });
        }
        else if (devType === "EMG") {
          let changedData = { ...this.state.changedData };
          let index = propertyData.EMG.findIndex(x => x.VFDID === id);
          propertyData.EMG[index].EMproperty.map((d) => {
            if (d["property"] === evt.target.name) {
              d["value"] = evt.target.value;
            }
          });
          if (changedData["EMG"]) {
            let idchc = changedData["EMG"].filter((d) => d.EMID === id);
            if (idchc.length === 0) {
              let vfdprop1 = [{
                property: evt.target.name,
                value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
              }];
              changedData.VFD.push({ EMID: id, NumberofProperties: 1, EMproperty: vfdprop1 });
            }
            else {
              let index1 = changedData.EMG.findIndex(x => x.EMID === id);
              let c = changedData["EMG"][index1]["EMproperty"].filter((d) => d.property === evt.target.name);

              if (c.length === 0) {
                changedData["EMG"][index1]["EMproperty"].push({
                  property: evt.target.name,
                  value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
                });
                changedData["EMG"][index1]["NumberofProperties"] = changedData["EMG"][index1]["EMproperty"].length;
              }
              else {
                changedData["EMG"][index1]["EMproperty"].map((d) => {
                  if (d.property === evt.target.name) {
                    d.value = this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type);
                  }
                })
              }


            }

          } else {
            let vfdprop = [{
              property: evt.target.name,
              value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
            }]
            changedData.EMG = [{ EMID: id, NumberofProperties: 1, EMproperty: vfdprop }];
          }
          console.log("changedData", changedData);
          this.setState({ propertyData, changedData }, () => {
            console.log(this.state.changedData);
          });
        }
        else if (devType === "CCN") {
          let changedData = { ...this.state.changedData };
          let index = propertyData.CHILLER.findIndex(x => x.CHILLERID === id);
          propertyData.CHILLER[index].CHILLERproperty.map((d) => {
            if (d["property"] === evt.target.name) {
              d["value"] = evt.target.value;
            }
          });
          if (changedData["CHILLER"]) {
            let idchc = changedData["CHILLER"].filter((d) => d.CHILLERID === id);
            if (idchc.length === 0) {
              let vfdprop1 = [{
                property: evt.target.name,
                value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
              }];
              changedData.CHILLER.push({ CHILLERID: id, NumberofProperties: 1, CHIULLERproperty: vfdprop1 });
            }
            else {
              let index1 = changedData.CHILLER.findIndex(x => x.CHILLERID === id);
              let c = changedData["CHILLER"][index1]["CHILLERproperty"].filter((d) => d.property === evt.target.name);

              if (c.length === 0) {
                changedData["CHILLER"][index1]["CHILLERproperty"].push({
                  property: evt.target.name,
                  value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
                });
                changedData["CHILLER"][index1]["NumberofProperties"] = changedData["CHILLER"][index1]["CHILLERproperty"].length;
              }
              else {
                changedData["CHILLER"][index1]["CHILLERproperty"].map((d) => {
                  if (d.property === evt.target.name) {
                    d.value = this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type);
                  }
                })
              }
            }

          } else {
            let vfdprop = [{
              property: evt.target.name,
              value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
            }]
            changedData.CHILLER = [{ CHILLERID: id, NumberofProperties: 1, CHILLERproperty: vfdprop }];
          }
          console.log("changedData", changedData);
          this.setState({ propertyData, changedData }, () => {
            console.log(this.state.changedData);
          });
        }

        else if (devType === "LG") {
          let changedData = { ...this.state.changedData };
          let index = propertyData.THS.findIndex(x => x.THSID === id);
          propertyData.THS[index].THSproperty.map((d) => {
            if (d["property"] === evt.target.name) {
              d["value"] = evt.target.value;
            }
          });
          if (changedData["THS"]) {
            let idchc = changedData["THS"].filter((d) => d.THSID === id);
            if (idchc.length === 0) {
              let vfdprop1 = [{
                property: evt.target.name,
                value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
              }];
              changedData.THS.push({ THSID: id, NumberofProperties: 1, THSproperty: vfdprop1 });
            }
            else {
              let index1 = changedData.THS.findIndex(x => x.THSID === id);
              let c = changedData["THS"][index1]["THSproperty"].filter((d) => d.property === evt.target.name);

              if (c.length === 0) {
                changedData["THS"][index1]["THSproperty"].push({
                  property: evt.target.name,
                  value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
                });
                changedData["THS"][index1]["NumberofProperties"] = changedData["THS"][index1]["THSproperty"].length;
              }
              else {
                changedData["THS"][index1]["THSproperty"].map((d) => {
                  if (d.property === evt.target.name) {
                    d.value = this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type);
                  }
                })
              }


            }

          } else {
            let vfdprop = [{
              property: evt.target.name,
              value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
            }]
            changedData.THS = [{ THSID: id, NumberofProperties: 1, THSproperty: vfdprop }];
          }
          console.log("changedData", changedData);
          this.setState({ propertyData, changedData }, () => {
            console.log(this.state.changedData);
          });
        }

        else if (devType === "TCN") {
          let changedData = { ...this.state.changedData };
          let index = propertyData.Tstat.findIndex(x => x.TstatID === id);
          propertyData.Tstat[index].Tstatproperty.map((d) => {
            if (d["property"] === evt.target.name) {
              d["value"] = evt.target.value;
            }
          });
          if (changedData["Tstat"]) {
            let idchc = changedData["Tstat"].filter((d) => d.TstatID === id);
            if (idchc.length === 0) {
              let vfdprop1 = [{
                property: evt.target.name,
                value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
              }];
              changedData.Tstat.push({ TstatID: id, NumberofProperties: 1, Tstatproperty: vfdprop1 });
            }
            else {
              let index1 = changedData.Tstat.findIndex(x => x.TstatID === id);
              let c = changedData["Tstat"][index1]["Tstatproperty"].filter((d) => d.property === evt.target.name);

              if (c.length === 0) {
                changedData["Tstat"][index1]["Tstatproperty"].push({
                  property: evt.target.name,
                  value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
                });
                changedData["Tstat"][index1]["NumberofProperties"] = changedData["Tstat"][index1]["Tstatproperty"].length;
              }
              else {
                changedData["Tstat"][index1]["Tstatproperty"].map((d) => {
                  if (d.property === evt.target.name) {
                    d.value = this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type);
                  }
                })
              }


            }

          } else {
            let vfdprop = [{
              property: evt.target.name,
              value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type)
            }]
            changedData.Tstat = [{ TstatID: id, NumberofProperties: 1, Tstatproperty: vfdprop }];
          }
          console.log("changedData", changedData);
          this.setState({ propertyData, changedData }, () => {
            console.log(this.state.changedData);
          });
        }

        else {
          let changedData = { ...this.state.changedData };
          propertyData.properties.map((d) => {
            if (d["property"] === evt.target.name) {
              d["value"] = evt.target.value;
            }
          });
          if (changedData["properties"]) {
            let c = changedData["properties"].filter((d) => d.property === evt.target.name);

            if (c.length === 0) {
              changedData["properties"].push({ property: evt.target.name, value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type) });
            } else {
              changedData["properties"].map((d) => {
                if (d.property === evt.target.name) {
                  d.value = this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type);
                }

              })
            }

          } else {
            changedData["properties"] = [{ property: evt.target.name, value: this.getParsedValue(evt.target.value, this.state.propertyType[evt.target.name].type) }]
          }
          console.log("changedData", changedData);
          this.setState({ propertyData, changedData }, () => {
            console.log(this.state.changedData);
          });
        }
      }
      catch (e) {
        // toast.error("Data mismatch. Please compare model info and its property.", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Data mismatch. Please compare model info and its property.");
      }
    }
    else {
      // toast.error("Please set model info first before updating.", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Please set model info first before updating.");

    }
  }

  setParams() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    try {
      if (Object.keys(this.state.changedData).length > 0) {
        const filterDevice = this.state.deviceList.filter((d) => (d.deviceId === this.state.selectedDeviceId));
        console.log(filterDevice, "lllllll");
        let data = {
          "deviceid": filterDevice[0].deviceId,
          "networkid": username,
          "devicetype": filterDevice[0].deviceType,
          "requestcmd": "SetParam",
          "NumberofProperties": this.state.changedData[Object.keys(this.state.changedData)].length,
          ...this.state.changedData
        }
        console.log(data, "data")

        if (filterDevice[0].deviceType === 'VCN') {
          delete data.NumberofProperties;
          data.NumberofVFD = this.state.changedData.VFD.length;
        }

        else if (filterDevice[0].deviceType === 'CCN') {
          delete data.NumberofProperties;
          data.NumberofCHILLER = this.state.changedData.CHILLER.length;
        }

        else if (filterDevice[0].deviceType === 'LG') {
          delete data.NumberofProperties;
          data.NumberofTHS = this.state.changedData.THS.length;
        }

        else if (filterDevice[0].deviceType === 'TCN') {
          delete data.NumberofProperties;
          data.NumberofTstat = this.state.changedData.Tstat.length;
        }

        else if (filterDevice[0].deviceType === 'EMG') {
          delete data.NumberofProperties;
          data.NumberofEM = this.state.changedData.EMG.length;
        }

        EMSServcieAxiosInstance.post(`/ems/device/${username}/${filterDevice[0].deviceId}/setparam`, data).then((res) => {
          // toast.success("Update parameter command sent to device.", {
          //   position: toast.POSITION.TOP_RIGHT
          // });
          return NotificationManager.success("Update parameter command sent to device.");
        }).catch((err) => {
          // toast.error("Some error occured while updating device.", {
          //   position: toast.POSITION.TOP_RIGHT
          // });
          return NotificationManager.error("Some error occured while updating device.");
        });
      }
      else {
        // toast.error("Nothing to update.", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Nothing to update.");
      }
    }
    catch (cerror) {
      // toast.error("Something went wrong.", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Some error occured");
    }
  }
  formatDateString(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
  }



  render() {
    try {
      const filterData = this.state.deviceList.length > 0 ? this.state.deviceList.filter((d) => (d.deviceId === this.state.selectedDeviceId)) : [];
      const devType = (filterData.length > 0) ? filterData[0].deviceType : "";



      return (
        <div className="animated fadeIn" >
          <Row>
            <Col xs="12" sm="12">
              <Card>
                <CardHeader>
                  <strong className="LabelHeader">Device Property</strong>
                </CardHeader>
                <CardBody>
                  <Col xs="12" md="12">
                    <FormGroup row>
                      {/* <Col md="4">
                      <Input type="select" name="device" value={this.state.selectedDeviceId}
                        placeholder="Select Device" className="dropdownwidth2"  onChange={this.selectDevice.bind(this)} style={{ width: "100%" }}>
                        <option value="">Select Device</option>
                        {this.state.deviceList.map((d, ind) => {
                          return <option key={ind + 1} value={d.deviceId}>{d.deviceName}</option>
                        })}
                      </Input>

                    </Col> */}
                      <FormControl fullWidth style={{marginTop:"10px"}}>
                        <InputLabel htmlFor="device">Select Device</InputLabel>
                        <Select
                          label="Select Device"
                          name="device"
                          value={this.state.selectedDeviceId}
                          onChange={this.selectDevice.bind(this)}
                        >
                          <MenuItem disabled value="">Select Device</MenuItem>
                          {this.state.deviceList.map((d, ind) => (
                            <MenuItem key={ind + 1} value={d.deviceId}>
                              {d.deviceName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* <Col md="4">
                <Input type="text" name="searchkey" className="btnrightalign searchbox_color" placeholder="Enter search Key" onChange={this.onSearchkeyChange.bind(this, 'searchkey')} />
                </Col> */}
                <div style={{display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                      <div md="6">
                        Model : <b>{this.state.modelSelected}</b>&nbsp;
                        Last Updated : <b>{this.state.propertyData.updatedat ? this.formatDateString(this.state.propertyData.updatedat) : this.state.propertyData.updatedat}</b>
                      </div>

                      <div>
                        <Button className="custom-button" onClick={this.selectDevice.bind(this, 'reload')}><RefreshIcon/></Button>
                        <Button type="button" style ={{marginLeft:"10px"}} className="custom-button" onClick={this.setParams.bind(this)}>Update</Button>
                      </div>
                      </div>
                    </FormGroup>
                  </Col>
                  {!this.state.showspinner &&
                    <TableContainer component={Paper}>
                      <Table hover responsive className="table-outline mb-0  d-sm-table table-md">
                        {Object.keys(this.state.propertyData).length > 0 &&
                          <TableHead>
                            <TableRow>
                              <TableCell>Property</TableCell>
                              <TableCell>Current Value</TableCell>
                              <TableCell>Unit</TableCell>
                              <TableCell>Desired Value</TableCell>
                            </TableRow>
                          </TableHead>
                        }
                        {Object.keys(this.state.propertyData).length === 0 &&
                          <center>
                            <h3>No records found!!</h3>
                          </center>
                        }
                        {Object.keys(this.state.propertyData).length > 0 &&
                          <tbody>
                            {(devType === 'AHUG') && (this.state.propertyData[`AHUGproperty`]) ? this.state.propertyData[`AHUGproperty`].map((d, ind) => {
                              return (
                                <tr className="RowStyle" key={ind}>
                                  <td><b>{d.property}</b></td>
                                  <td>{d.fvalue}</td>
                                  <td>{d.unit}</td>
                                  <td>{(d.editable === 1 || d.editable === true) && <Input type="text" name={d.property} value={d.value} onChange={this.setValue.bind(this, undefined)} />}

                                    {(d.editable === 2) ? <Input type="select" name={d.property} value={d.value} onChange={this.setValue.bind(this, undefined)}>

                                      {d.Desired.map((x, ind) => {
                                        return <option key={ind} value={x}>{x}</option>
                                      })}
                                    </Input> : ""}
                                  </td>
                                </tr>
                              )
                            }) : ''}

                            {(devType === 'EMMW') && (this.state.propertyData[`EMMW`]) ? this.state.propertyData[`EMMW`].map((d, ind) => {
                              return (
                                <tr className="RowStyle" key={ind}>
                                  <td><b>{d.property}</b></td>
                                  <td>{d.fvalue}</td>
                                  <td>{d.unit}</td>
                                  <td>{(d.editable === 1 || d.editable === true) && <Input type="text" name={d.property} value={d.value} onChange={this.setValue.bind(this, undefined)} />}

                                    {(d.editable === 2) ? <Input type="select" name={d.property} value={d.value} onChange={this.setValue.bind(this, undefined)}>
                                      {d.Desired.map((x, ind) => {
                                        return <option key={ind} value={x}>{x}</option>
                                      })}
                                    </Input> : ""}
                                  </td>
                                </tr>
                              )
                            }) : ''}

                            {(this.state.propertyData[`properties`]) ? this.state.propertyData[`properties`].map((d, ind) => {
                              return (
                                // <tr className="RowStyle" key={ind}>
                                //   <td><b>{d.property}</b></td>
                                //   <td>{d.fvalue}</td>
                                //   <td>{d.unit}</td>
                                //   <td>{(d.editable === 1 || d.editable === true) && <Input type="text" name={d.property} value={d.value} onChange={this.setValue.bind(this, undefined)} />}

                                //     {(d.editable === '2' || d.editable === 2) ? <Input type="select" name={d.property} value={d.value} onChange={this.setValue.bind(this, undefined)}>
                                //       {d.Desired?.map((x, ind) => {
                                //         return <option key={ind} value={x}>{x}</option>
                                //       })}
                                //     </Input> : ""}
                                //   </td>
                                // </tr>
                                <TableRow className="RowStyle">
                                  <TableCell>
                                    <b>{d.property}</b>
                                  </TableCell>
                                  <TableCell>{d.fvalue}</TableCell>
                                  <TableCell>{d.unit}</TableCell>
                                  <TableCell>
                                    {d.editable === 1 || d.editable === true ? (
                                      <OutlinedInput
                                        type="text"
                                        name={d.property}
                                        value={d.value}
                                        onChange={this.setValue.bind(undefined, this)}
                                        fullWidth
                                      />
                                    ) : d.editable === '2' || d.editable === 2 ? (
                                      <Select
                                        name={d.property}
                                        value={d.value}
                                        onChange={this.setValue.bind(undefined, this)}
                                        fullWidth
                                      >
                                        {d.Desired?.map((x, ind) => (
                                          <MenuItem key={ind} value={x}>
                                            {x}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                              )
                            }) : ''}

                            {(devType === 'TCN') &&
                              (this.state.propertyData[`Tstat`]) ? this.state.propertyData[`Tstat`].map((t, i) => {

                                return (
                                  <React.Fragment key={i}>
                                    <th colSpan={4} style={{ textAlign: "center" }}>Tstat Id - {t.TstatID}</th>
                                    {t[`Tstatproperty`].map((d, ind) => {
                                      return (
                                        <tr className="RowStyle" key={ind}>
                                          <td><b>{d.property}</b></td>
                                          <td>{d.fvalue}</td>
                                          <td>{d.unit}</td>
                                          <td>{(d.editable === 1 || d.editable === true) && <Input type="text"
                                            name={d.property} value={d.value}
                                            onChange={this.setValue.bind(this, t.TstatID)} />}

                                            {(d.editable === 2) ? <Input type="select" name={d.property} value={d.value}
                                              onChange={this.setValue.bind(this, t.TstatID)}>
                                              {d.Desired.map((x, ind) => {
                                                return <option key={ind} value={x}>{x}</option>
                                              })}
                                            </Input> : ""}
                                          </td>
                                        </tr>
                                      )
                                    })}

                                  </React.Fragment>
                                )

                              }) : ''}

                            {(devType === 'VCN') &&
                              (this.state.propertyData[`VFD`]) ? this.state.propertyData[`VFD`].map((t, i) => {

                                return (
                                  <React.Fragment key={i}>
                                    <th colSpan={4} style={{ textAlign: "center" }}>VFD Id - {t.VFDID}</th>
                                    {t[`VFDproperty`].map((d, ind) => {
                                      return (
                                        <tr className="RowStyle" key={ind}>
                                          <td><b>{d.property}</b></td>
                                          <td>{d.fvalue}</td>
                                          <td>{d.unit}</td>
                                          <td>{(d.editable === 1 || d.editable === true) && <Input type="text"
                                            name={d.property} value={d.value}
                                            onChange={this.setValue.bind(this, t.VFDID)} />}

                                            {(d.editable === 2) ? <Input type="select" name={d.property} value={d.value}
                                              onChange={this.setValue.bind(this, t.VFDID)}>
                                              {d.Desired.map((x, ind) => {
                                                return <option key={ind} value={x}>{x}</option>
                                              })}
                                            </Input> : ""}
                                          </td>
                                        </tr>
                                      )
                                    })}

                                  </React.Fragment>
                                )

                              }) : ''}

                            {(devType === 'EMG') &&
                              (this.state.propertyData[`EMG`]) ? this.state.propertyData[`EMG`].map((t, i) => {

                                return (
                                  <React.Fragment key={i}>
                                    <th colSpan={4} style={{ textAlign: "center" }}>EM Id - {t.EMID}</th>
                                    {t[`EMproperty`].map((d, ind) => {
                                      return (
                                        <tr className="RowStyle" key={ind}>
                                          <td><b>{d.property}</b></td>
                                          <td>{d.fvalue}</td>
                                          <td>{d.unit}</td>
                                          <td>{(d.editable === 1 || d.editable === true) && <Input type="text"
                                            name={d.property} value={d.value}
                                            onChange={this.setValue.bind(this, t.EMID)} />}

                                            {(d.editable === 2) ? <Input type="select" name={d.property} value={d.value}
                                              onChange={this.setValue.bind(this, t.EMID)}>
                                              {d.Desired.map((x, ind) => {
                                                return <option key={ind} value={x}>{x}</option>
                                              })}
                                            </Input> : ""}
                                          </td>
                                        </tr>
                                      )
                                    })}

                                  </React.Fragment>
                                )

                              }) : ''}







                            {(devType === 'CCN') &&
                              (this.state.propertyData[`CHILLER`]) ? this.state.propertyData[`CHILLER`].map((t, i) => {

                                return (
                                  <React.Fragment key={i}>
                                    <th colSpan={4} style={{ textAlign: "center" }}>Chiller Id - {t.CHILLERID}</th>
                                    {t[`CHILLERproperty`].map((d, ind) => {
                                      return (
                                        <tr className="RowStyle" key={ind}>
                                          <td><b>{d.property}</b></td>
                                          <td>{d.fvalue}</td>
                                          <td>{d.unit}</td>
                                          <td>{(d.editable === 1 || d.editable === true) && <Input type="text"
                                            name={d.property} value={d.value}
                                            onChange={this.setValue.bind(this, t.CHILLERID)} />}

                                            {(d.editable === 2) ? <Input type="select" name="Desired" value={d.value}>
                                              {d.Desired.map((x, ind) => {
                                                return <option key={ind} value={x}>{x}</option>
                                              })}
                                            </Input> : ""}
                                          </td>
                                        </tr>
                                      )
                                    })}

                                  </React.Fragment>
                                )

                              }) : ''}


                            {(devType === 'LG') &&
                              (this.state.propertyData[`THS`]) ? this.state.propertyData[`THS`].map((t, i) => {

                                return (
                                  <React.Fragment key={i}>
                                    <th colSpan={4} style={{ textAlign: "center" }}>THS Id - {t.THSID}</th>
                                    {t[`THSproperty`].map((d, ind) => {
                                      return (
                                        <tr className="RowStyle" key={ind}>
                                          <td><b>{d.property}</b></td>
                                          <td>{d.fvalue}</td>
                                          <td>{d.unit}</td>
                                          <td>{(d.editable === 1 || d.editable === true) && <Input type="text"
                                            name={d.property} value={d.value}
                                            onChange={this.setValue.bind(this, t.THSID)} />}

                                            {(d.editable === 2) ? <Input type="select" name="Desired" value={d.value}>
                                              {d.Desired.map((x, ind) => {
                                                return <option key={ind} value={x}>{x}</option>
                                              })}
                                            </Input> : ""}
                                          </td>
                                        </tr>
                                      )
                                    })}

                                  </React.Fragment>
                                )

                              }) : ''}



                          </tbody>
                        }

                      </Table>
                    </TableContainer>
                  }
                  {this.state.showspinner &&
                    <div className="sk-cube-grid">
                      <div className="sk-cube sk-cube1"></div>
                      <div className="sk-cube sk-cube2"></div>
                      <div className="sk-cube sk-cube3"></div>
                      <div className="sk-cube sk-cube4"></div>
                      <div className="sk-cube sk-cube5"></div>
                      <div className="sk-cube sk-cube6"></div>
                      <div className="sk-cube sk-cube7"></div>
                      <div className="sk-cube sk-cube8"></div>
                      <div className="sk-cube sk-cube9"></div>
                    </div>
                  }



                </CardBody>
              </Card>
            </Col>
          </Row>


        </div >
      );
    }
    catch (cerror) {
      // toast.error("Something went wrong.", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
    }
  }
}

export default Gateway;
