import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import RoleManagementComponent from "../AssetDeveloperRole/RoleManagement/RoleManagement";
import RoleProfileManagementComponent from "../AssetDeveloperRole/RoleProfileManagement/RoleProfileManagementComponent";
import TransportAssetManagement from "../AssetDeveloperRole/TransportManagement/TransportManagementComponent";
import MainService from "../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";

import AAMCSiteManagementComponent from "../AssetDeveloperRole/AAMCSiteManagement/AAMCSiteManagementComponent";
import DSNExecutiveComponent from "../DSNExecutive/DSNExecutiveComponent";
import DSNManagerComponent from "../DSNManager/DSNManagerComponent";
import DSNOperatorComponent from "../DSNOperator/DSNOperatorComponent";
import DSNQAComponent from "../DSNQA/DSNQAComponent";
import StoreOwnerComponent from "../StoreOwner/StoreOwnerComponent";
import RegisterUsersRequestsComponent from "../AssetDeveloperRole/RegisterUserRequests/RegisterUserRequestsComponent";
import AAMCAgentComponent from "../AssetDeveloperRole/AAMCAgent/AAMCAgetComponent";

export default function DSNComponent(props) {
  const [viewRole, setViewRole] = useState(false);
  const [viewDSNManager, setViewDSNManager] = useState(false);
  const [viewTransportAssetManagement, setViewTransportAssetManagement] =
    useState(false);
  
  const [viewDSNOperator, setViewDSNOperator] = useState(false);
  
  const [viewDSNQA, setViewDSNQA] = useState(false);
 
  const [viewSiteManagement, setViewSiteManagement] = useState(false);
  const [viewDSNExecutive, setViewDSNExecutive] = useState(false);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [roleNameValue, setRoleNameValue] = useState("");
  const [viewSuperRole, setViewSuperRole] = useState(false);
  const [viewStoreOwner, setViewStoreOwner] = useState(true);
  const [viewUserRequests, setViewUserRequests] = useState(false);
  const [viewAgents, setViewAgents] = useState(true);

  useEffect(() => {
    getRoleName();
  }, []);
  const getRoleName = () => {
    MainService.getRole(parentId, username)
      .then((res) => {
        setRoleNameValue(res.role);
        console.log("role P n/w res:", res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const viewAgentsClick = () => {
    setViewRole(false);
    setViewDSNManager(false);
    
    setViewDSNOperator(false);
   
    setViewDSNQA(false);
    
    setViewDSNExecutive(false);
    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportAssetManagement(false);
    setViewStoreOwner(false);
    setViewUserRequests(false);
    setViewAgents(true);
  };
  const viewRequestsClick = () => {
    setViewRole(false);
    setViewDSNManager(false);
    
    setViewDSNOperator(false);
   
    setViewDSNQA(false);
    
    setViewDSNExecutive(false);
    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportAssetManagement(false);
    setViewStoreOwner(false);
    setViewUserRequests(true);
    setViewAgents(false);
  };
  const viewStoreOwnerClick = () => {
    setViewRole(false);
    setViewDSNManager(false);
    
    setViewDSNOperator(false);
   
    setViewDSNQA(false);
    
    setViewDSNExecutive(false);
    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportAssetManagement(false);
    setViewStoreOwner(true);
    setViewUserRequests(false);
    setViewAgents(false);
  };

  const viewRoleClick = () => {
    setViewRole(true);
    setViewDSNManager(false);
    
    setViewDSNOperator(false);
   
    setViewDSNQA(false);
    
    setViewDSNExecutive(false);
    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewTransportAssetManagement(false);
    setViewStoreOwner(false);
    setViewUserRequests(false);
    setViewAgents(false);
  };

  const viewTransportAssetManagementClick = () => {
    setViewTransportAssetManagement(true);
    setViewDSNManager(false);
    
    setViewDSNOperator(false);
   
    setViewDSNQA(false);
    
    setViewDSNExecutive(false);
    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewStoreOwner(false);
    setViewUserRequests(false);
    setViewAgents(false);
  };
  const viewDSNManagerClick = () => {
    setViewRole(false);
    setViewDSNManager(true);
    
    setViewDSNOperator(false);
   
    setViewDSNQA(false);
    
    setViewTransportAssetManagement(false);
    setViewSiteManagement(false);
    setViewDSNExecutive(false);
    setViewSuperRole(false);
    setViewStoreOwner(false);
    setViewUserRequests(false);
    setViewAgents(false);
  };
  const viewDSNExecutiveClick = () => {
    setViewRole(false);
    setViewDSNManager(false);
    
    setViewDSNOperator(false);
   
    setViewDSNQA(false);
    
    setViewTransportAssetManagement(false);
    setViewSiteManagement(false);
    setViewDSNExecutive(true);
    setViewSuperRole(false);
    setViewStoreOwner(false);
    setViewUserRequests(false);
    setViewAgents(false);
  };
  
  const viewDSNOperatorClick = () => {
    setViewTransportAssetManagement(false);
    setViewRole(false);
    setViewDSNManager(false);
    
    setViewDSNOperator(true);
   
    setViewDSNQA(false);
    
    setViewDSNExecutive(false);
    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewStoreOwner(false);
    setViewUserRequests(false);
    setViewAgents(false);
  };
  
  const viewDSNQAClick = () => {
    setViewTransportAssetManagement(false);
    setViewRole(false);
    setViewDSNManager(false);
    
    setViewDSNOperator(false);
   
    setViewDSNQA(true);
    
    setViewDSNExecutive(false);
    setViewSiteManagement(false);

    setViewSuperRole(false);
    setViewStoreOwner(false);
    setViewUserRequests(false);
    setViewAgents(false);
  };
 

  const viewSiteManagementClick = () => {
    setViewTransportAssetManagement(false);
    setViewRole(false);
    setViewDSNManager(false);
    
    setViewDSNOperator(false);
   
    setViewDSNQA(false);
    
    setViewDSNExecutive(false);
    setViewSiteManagement(true);

    setViewSuperRole(false);
    setViewStoreOwner(false);
    setViewUserRequests(false);
    setViewAgents(false);
  };

  const viewSuperRoleClick = () => {
    setViewTransportAssetManagement(false);
    setViewRole(false);
    setViewDSNManager(false);
    
    setViewDSNOperator(false);
   
    setViewDSNQA(false);
    
    setViewDSNExecutive(false);
    setViewSiteManagement(false);

    setViewSuperRole(true);
    setViewStoreOwner(false);
    setViewUserRequests(false);
    setViewAgents(false);
  };

  return (
    <>
      <div className="topHeadersWrapper">
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSiteManagementClick();
            }}
          >
            Site Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSuperRoleClick();
            }}
          >
            Role Management
          </Button>
        </div>
        
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewRoleClick();
            }}
          >
            Role Profile Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewRequestsClick();
            }}
          >
            Requests
          </Button>
        </div>
        &nbsp;
        
      </div>
      &nbsp;
     {/*  <div className="topHeadersWrapper">
          <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewTransportAssetManagementClick();
            }}
          >
            Transport Asset Management
          </Button>
        </div>
        </div> */}
      <div className="container" id="mainContentList">
        <h2>SN Network</h2>
        &nbsp;&nbsp;
        
        <div className="topHeadersWrapper">
        <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewStoreOwnerClick();
              }}
            >
              StoreOwner
            </Button>
          </div>
          &nbsp;
        <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewDSNExecutiveClick();
              }}
            >
              StoreExecutive
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewDSNManagerClick();
              }}
            >
              StoreManager
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewDSNOperatorClick();
              }}
            >
              StoreOperator
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewDSNQAClick();
              }}
            >
              Store-QA
            </Button>
          </div>
          &nbsp;
          <div className="topHeaders">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                viewAgentsClick();
              }}
            >
              Agents
            </Button>
          </div>
        </div>
        &nbsp;
        
       
      </div>

      <div style={{ paddingTop: "20px" }}>
      {viewSiteManagement && <AAMCSiteManagementComponent siteType={["Store"]}/>}
        {viewDSNExecutive && <DSNExecutiveComponent/>}
        {viewTransportAssetManagement && <TransportAssetManagement />}
        {viewRole && <RoleProfileManagementComponent siteType={["Store"]} networkType="DSN"/>}

        {viewDSNOperator && <DSNOperatorComponent />}
        {viewDSNManager && <DSNManagerComponent />}
        {viewUserRequests && <RegisterUsersRequestsComponent  networkType="DSN"/>}
       {viewStoreOwner && <StoreOwnerComponent/>}
        {viewDSNQA && <DSNQAComponent />}
        {viewSuperRole && <RoleManagementComponent />}
        {viewAgents && <AAMCAgentComponent/>}
      </div>
    </>
  );
}
