import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import "./FarmSiteManagementComponent.css";
import Typography from "@material-ui/core/Typography";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from "react-google-maps";
import socketIOClient from "socket.io-client";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import { Grid } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";
import { RowingSharp } from "@material-ui/icons";
import Map from "../../Common/googleMap/Map";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // set last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "black",
    borderStyle: "solid",
  },
}));

export default function FarmSiteManagementComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  /* Ssites */
  const [getMappedAssetsType, setMappedAssetsType] = useState("");
  const [getMappedAssetsSiteid, setMappedAssetsSiteid] = useState("");
  const [getMappedAssetsSiteName, setMappedAssetsSiteName] = useState("");
  const [getBackToSites, setBackToSites] = useState("");
  const [getAssetBackButtton, setAssetBackButtton] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [viewAllListSites, setViewallListSites] = useState(false);
  const [viewAllGridSites, setViewallGridSites] = useState(true);
  const [viewAllMapSites, setViewallMapSites] = useState(false);
  const [showAssetList, setAssetList] = useState(false);
  const [showAssetGrid, setAssetGrid] = useState(false);
  const [showAssetMap, setAssetMap] = useState(false);
  const [showDistricts, setShowDistricts] = useState(false);
  const [mappedAssetShow, setMappedAssetShow] = useState(false);
  const [mapWithSite, setMapWithSites] = useState(false);
  const [showSiteRating, setShowSiteRating] = useState(false);
  const [getAllSites, setAllSites] = useState([]);
  const [getMappedAssets, setMappedAssets] = useState([]);
  const [getMapwithSite, setMapwithSite] = useState([]);
  const [getSiteId, setSiteId] = useState("");
  const [redirectSiteId, setRedirectSiteId] = useState("");
  const [getSiteRatings, setSiteRatings] = useState([]);
  const [getSiteRateSiteId, setSiteRateSiteId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  /* QR Code */
  const [assetVerticalIdForQr, setAssetVerticalIdForQr] = useState("");
  const [fgcolor, setFgcolor] = useState("000");
  const [bgcolor, setBgcolor] = useState("f0ffff");
  const [qrSizeValue, setQrSizeValue] = useState("400");
  const [qrCodeFile, setQrCodeFile] = useState("");
  const [qrCodeFileDownload, setQrCodeFileDownload] = useState("");
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [selectedSiteName, setSelectedSiteName] = useState("");
  const [siteDeleteDialog, setSiteDeleteDialog] = useState(false);
  const [generateQrClick, setGenerateQrClick] = useState(false);
  /* Create Frm Site */
  const [getSitePopUp, setSiteCreatePopUp] = useState(false);
  const [showCreateSitePopup, setShowCreateSitePopup] = useState(false);
  const [showCreateSiteDialog, setShowCreateSiteDialog] = useState(false);
  const [getSiteName, setSiteName] = useState("");
  const [getMailId, setMailId] = useState("");
  const [getPhone, setPhone] = useState("");
  const [getFarmerUserName, setFarmerUserName] = useState("");
  const [getSiteType, setSiteType] = useState("");
  const [getSiteData, setSiteData] = useState([]);
  const [nodeEmail, setNodeEmail] = useState("");
  const [getNodeId, setNodeId] = useState("");
  const [nodeMobileNumber, setNodePhoneNumber] = useState("");
  const [nodeUserName, setNodeUserName] = useState("");
  const [nodeType, setNodeType] = useState("");
  /* End Create Farm Site */
  /* Site Rating */
  const [getSiteRatePopup, setSiteRatePopUp] = useState(false);
  const [defaultSiteId, setDefaultSiteId] = useState("");
  const [setupdateSiteRating, getUpDateSiteRating] = useState("");
  /* End Of Site Rating */
  /* Site Info */
  const [siteInfoObj, setSiteInfoObj] = useState({});
  const [infoSiteId, getInfoSiteId] = useState("");
  const [setSiteInfoPopup, getSiteInfoPopUp] = useState(false);

  /* Site update */
  const [getSiteInfoName, setSiteInfoName] = useState("");
  const [getPhoneNumber, setPhoneNumber] = useState("");
  const [getEmail, setEmail] = useState("");
  const [getSiteInfoExistingSiteType, setSiteInfoExistingSiteType] =
    useState("");
  const [getUpdateSiteType, setUpdateSiteType] = useState("");
  const [getWebsiteUrl, setWebsiteUrl] = useState("");
  const [getLocAddress, setLocAddress] = useState("");
  const [getSiteInfoAssetVerticalId, setSiteInfoAssetVerticalId] = useState("");
  const [getIndustryType, setIndustryType] = useState("");
  const [getStreetName, setStreetName] = useState("");
  const [getStreetNumber, setStreetNumber] = useState("");
  const [getLocality, setLocality] = useState("");
  const [getArea, setArea] = useState("");
  const [getRegion, setRegion] = useState("");
  const [getCountry, setCountry] = useState("");
  const [getPostalCode, setPostalCode] = useState("");
  const [getLatitude, setLatitude] = useState("");
  const [getLongitude, setLongitude] = useState("");
  const [getAltitude, setAltitude] = useState("");
  const [getTimeZone, setTimeZone] = useState("");
  const [getCityCode, setCityCode] = useState("");
  const [getCityName, setCityName] = useState("");
  const [getCountryCode, setCountryCode] = useState("");
  const [getStdCode, setStdCode] = useState("");
  const [getSiteUpdatePopup, setSiteUpdatePopup] = useState(false);

  /* End Of Site Update */
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  
  /********** Birth ****************/
  const [showBirth, setBirth] = useState(false);
  const [showNewBirthInfoPopup, setNewBirthInfoPopup] = useState(false);
  const [getBirthInfos, setBirthInfos] = useState([]);

  const [getNewBirthAssetid, setNewBirthAssetid] = useState("");
  const [getNewBirthAssetFriendlyName, setNewBirthAssetFriendlyName] =
    useState("");
  const [getNewBirthUserid, setNewBirthUserid] = useState(username);
  const [getNewBirthUserName, setNewBirthUserName] = useState("");
  const [getNewBirthSiteid, setNewBirthSiteid] = useState("");
  const [getNewBirthSiteName, setNewBirthSiteName] = useState("");
  const [getNewBirthLotid, setNewBirthLotid] = useState("");
  const [getNewBirthLotName, setNewBirthLotName] = useState("");
  const [getNewBirthLegacyAssetType, setNewBirthLegacyAssetType] = useState("");
  const [getNewBirthModelid, setNewBirthModelid] = useState("");
  const [getNewBirthModelFriendlyName, setNewBirthModelFriendlyName] =
    useState("");
  const [getNewBirthModelType, setNewBirthModelType] = useState("");
  const [getNewBirthModelURL, setNewBirthModelURL] = useState("");
  const [getNewBirthDOB, setNewBirthDOB] = useState("");
  const [getNewBirthGender, setNewBirthGender] = useState("");
  const [getNewBirthMaleParent, setNewBirthMaleParent] = useState("");
  const [getNewBirthFemaleParent, setNewBirthFemaleParent] = useState("");
  const [getNewBirthParentModelid, setNewBirthParentModelid] = useState("");
  const [getNewBirthLatitude, setNewBirthLatitude] = useState("");
  const [getNewBirthLongitude, setNewBirthLongitude] = useState("");
  const [getNewBirthAltitude, setNewBirthAltitude] = useState("");
  const [getNewBirthDescription, setNewBirthDescription] = useState("");

  const [getDeleteBirthInfoPopup, setDeleteBirthInfoPopup] = useState(false);
  const [getDeleteBirthInfo, setDeleteBirthInfo] = useState("");

  const [showUpdateBirthInfoPopup, setUpdateBirthInfoPopup] = useState(false);
  const [getUpdateNewBirthAssetid, setUpdateNewBirthAssetid] = useState("");
  const [
    getUpdateNewBirthAssetFriendlyName,
    setUpdateNewBirthAssetFriendlyName,
  ] = useState("");
  const [getUpdateNewBirthUserid, setUpdateUpdateNewBirthUserid] = useState("");
  const [getUpdateNewBirthUserName, setUpdateNewBirthUserName] = useState("");
  const [getUpdateNewBirthSiteid, setUpdateNewBirthSiteid] = useState("");
  const [getUpdateNewBirthSiteName, setUpdateNewBirthSiteName] = useState("");
  const [getUpdateNewBirthLotid, setUpdateNewBirthLotid] = useState("");
  const [getUpdateNewBirthLotName, setUpdateNewBirthLotName] = useState("");
  const [getUpdateNewBirthLegacyAssetType, setUpdateNewBirthLegacyAssetType] =
    useState("");
  const [getUpdateNewBirthModelid, setUpdateNewBirthModelid] = useState("");
  const [
    getUpdateNewBirthModelFriendlyName,
    setUpdateNewBirthModelFriendlyName,
  ] = useState("");
  const [getUpdateNewBirthModelType, setUpdateNewBirthModelType] = useState("");
  const [getUpdateNewBirthModelURL, setUpdateNewBirthModelURL] = useState("");
  const [getUpdateNewBirthDOB, setUpdateNewBirthDOB] = useState("");
  const [getUpdateNewBirthGender, setUpdateNewBirthGender] = useState("");
  const [getUpdateNewBirthMaleParent, setUpdateNewBirthMaleParent] =
    useState("");
  const [getUpdateNewBirthFemaleParent, setUpdateNewBirthFemaleParent] =
    useState("");
  const [getUpdateNewBirthParentModelid, setUpdateNewBirthParentModelid] =
    useState("");
  const [getUpdateNewBirthLatitude, setUpdateNewBirthLatitude] = useState("");
  const [getUpdateNewBirthLongitude, setUpdateNewBirthLongitude] = useState("");
  const [getUpdateNewBirthAltitude, setUpdateNewBirthAltitude] = useState("");
  const [getUpdateNewBirthDescription, setUpdateNewBirthDescription] =
    useState("");
  /********** End Of Birth ****************/
  /********** Back Button ****************/
  const [getBackType, setBackType] = useState("");
  /********** End Of Back Button ****************/
  /********** Attendance Location ****************/
  const [assetAttendanceDialog, setAssetAttendanceDialog] = useState(false);
  const [assetAttendanceOnj, setAssetAttendanceOnj] = useState({});

  const [showAttendanceLocation, setAttendanceLocation] = useState(false);
  const [getAttendanceLocationHistory, setAttendanceLocationHistory] = useState(
    []
  );

  const [showNewAttendanceLocationPopup, setNewAttendanceLocationPopup] =
    useState(false);
  const [getNewAttendanceLocationAssetid, setNewAttendanceLocationAssetid] =
    useState("");
  const [
    getNewAttendanceLocationAssetfriendlyName,
    setNewAttendanceLocationAssetfriendlyName,
  ] = useState("");
  const [getNewAttendanceLocationUserid, setNewAttendanceLocationUserid] =
    useState(username);
  const [getNewAttendanceLocationLatitude, setNewAttendanceLocationLatitude] =
    useState("");
  const [getNewAttendanceLocationLongitude, setNewAttendanceLocationLongitude] =
    useState("");
  const [getNewAttendanceLocationAltitude, setNewAttendanceLocationAltitude] =
    useState("");
  const [
    getNewAttendanceLocationDescription,
    setNewAttendanceLocationDescription,
  ] = useState("");

  const [getDeletedAttendanceAssetid, setDeletedAttendanceAssetid] =
    useState("");
  const [getDeletedAttendancePopup, setDeletedAttendancePopup] =
    useState(false);

  const [
    getUpdateAttendanceLocationInfoAssetid,
    setUpdateAttendanceLocationInfoAssetid,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoAssetfriendlyName,
    setUpdateAttendanceLocationInfoAssetfriendlyName,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoUserid,
    setUpdateAttendanceLocationInfoUserid,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoLatitude,
    setUpdateAttendanceLocationInfoLatitude,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoLongitude,
    setUpdateAttendanceLocationInfoLongitude,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoAltitude,
    setUpdateAttendanceLocationInfoAltitude,
  ] = useState("");
  const [
    getUpdateAttendanceLocationInfoDescription,
    setUpdateAttendanceLocationInfoDescription,
  ] = useState("");
  const [showUpdateAttendaneLocationPopup, setUpdateAttendaneLocationPopup] =
    useState(false);
  /********** End Of Attendance Location ****************/
  /********** Health Record ****************/
  const [showHealthRecords, setHealthRecords] = useState(false);
  const [showNewHealthRecordPopup, setNewHealthRecordPopup] = useState(false);
  const [getHealthRecords, setHealthRecordsList] = useState([]);

  const [getNewHealthAssetid, setNewHealthAssetid] = useState("");
  const [getNewHealthAssetFriendlyName, setNewHealthAssetFriendlyName] =
    useState("");
  const [getNewHealthUserid, setNewHealthUserid] = useState(username);
  const [getNewHealthMedicineDate, setNewHealthMedicineDate] = useState("");
  const [getNewHealthVaccinationDate, setNewHealthVaccinationDate] =
    useState("");
  const [getNewHealthExamine, setNewHealthExamine] = useState("");
  const [getNewHealthSurgicalProcedure, setNewHealthSurgicalProcedure] =
    useState("");
  const [getNewHealthVaccinationType, setNewHealthVaccinationType] =
    useState("");
  const [getNewHealthMedicineType, setNewHealthMedicineType] = useState("");
  const [getNewHealthDiseaseType, setNewHealthDiseaseType] = useState("");
  const [getNewHealthTreatmentDate, setNewHealthTreatmentDate] = useState("");
  const [getNewHealthLatitude, setNewHealthLatitude] = useState("");
  const [getNewHealthLongitude, setNewHealthLongitude] = useState("");
  const [getNewHealthAltitude, setNewHealthAltitude] = useState("");
  const [getNewHealthDescription, setNewHealthDescription] = useState("");

  const [getDeleteHealthAssetid, setDeleteHealthAssetid] = useState("");
  const [showDeleteHealthAssetPopup, setDeleteHealthAssetPopup] =
    useState(false);

  const [showUpdateNewHealthRecordPopup, setUpdateNewHealthRecordPopup] =
    useState(false);
  const [getUpdateNewHealthAssetid, setUpdateNewHealthAssetid] = useState("");
  const [
    getUpdateNewHealthAssetFriendlyName,
    setUpdateNewHealthAssetFriendlyName,
  ] = useState("");
  const [getUpdateNewHealthUserid, setUpdateNewHealthUserid] = useState("");
  const [getUpdateNewHealthMedicineDate, setUpdateNewHealthMedicineDate] =
    useState("");
  const [getUpdateNewHealthVaccinationDate, setUpdateNewHealthVaccinationDate] =
    useState("");
  const [getUpdateNewHealthExamine, setUpdateNewHealthExamine] = useState("");
  const [
    getUpdateNewHealthSurgicalProcedure,
    setUpdateNewHealthSurgicalProcedure,
  ] = useState("");
  const [getUpdateNewHealthVaccinationType, setUpdateNewHealthVaccinationType] =
    useState("");
  const [getUpdateNewHealthMedicineType, setUpdateNewHealthMedicineType] =
    useState("");
  const [getUpdateNewHealthDiseaseType, setUpdateNewHealthDiseaseType] =
    useState("");
  const [getUpdateNewHealthTreatmentDate, setUpdateNewHealthTreatmentDate] =
    useState("");
  const [getUpdateNewHealthLatitude, setUpdateNewHealthLatitude] = useState("");
  const [getUpdateNewHealthLongitude, setUpdateNewHealthLongitude] =
    useState("");
  const [getUpdateNewHealthAltitude, setUpdateNewHealthAltitude] = useState("");
  const [getUpdateNewHealthDescription, setUpdateNewHealthDescription] =
    useState("");
  /********** End Of Health Record ****************/
  /********** Nutrition ****************/
  const [showNutrition, setNutrition] = useState(false);
  const [getNutritions, setNutritions] = useState([]);

  const [showAddNutritionPopup, setAddNutritionPopup] = useState(false);
  const [getAddNutritionAssetid, setAddNutritionAssetid] = useState("");
  const [getAddNutritionAssetFriendlyName, setAddNutritionAssetFriendlyName] =
    useState("");
  const [getAddNutritionUserid, setAddNutritionUserid] = useState(username);
  const [getAddNutritionFeedType, setAddNutritionFeedType] = useState("");
  const [getAddNutritionSchedule, setAddNutritionSchedule] = useState("");
  const [getAddNutritionLatitude, setAddNutritionLatitude] = useState("");
  const [getAddNutritionLongitude, setAddNutritionLongitude] = useState("");
  const [getAddNutritionAltitude, setAddNutritionAltitude] = useState("");
  const [getAddNutritionDescription, setAddNutritionDescription] = useState("");

  const [showDeleteNutrition, setDeleteNutrition] = useState(false);
  const [getDeleteNutritionAssetid, setDeleteNutritionAssetid] = useState("");

  const [showUpdateAddNutritionPopup, setUpdateAddNutritionPopup] =
    useState(false);
  const [getUpdateAddNutritionAssetid, setUpdateAddNutritionAssetid] =
    useState("");
  const [
    getUpdateAddNutritionAssetFriendlyName,
    setUpdateAddNutritionAssetFriendlyName,
  ] = useState("");
  const [getUpdateAddNutritionUserid, setUpdateAddNutritionUserid] =
    useState("");
  const [getUpdateAddNutritionFeedType, setUpdateAddNutritionFeedType] =
    useState("");
  const [getUpdateAddNutritionSchedule, setUpdateAddNutritionSchedule] =
    useState("");
  const [getUpdateAddNutritionLatitude, setUpdateAddNutritionLatitude] =
    useState("");
  const [getUpdateAddNutritionLongitude, setUpdateAddNutritionLongitude] =
    useState("");
  const [getUpdateAddNutritionAltitude, setUpdateAddNutritionAltitude] =
    useState("");
  const [getUpdateAddNutritionDescription, setUpdateAddNutritionDescription] =
    useState("");
  const [
    getUpdateAddNutritionExistingFeedType,
    setUpdateAddNutritionExistingFeedType,
  ] = useState("");
  /********** End Of Nutrition ****************/
  /********** Parturition ****************/
  const [showParturition, setParturition] = useState(false);
  const [getParturitionDetail, setParturitionDetail] = useState([]);

  const [showNewParturitionPopup, setNewParturitionPopup] = useState(false);
  const [getNewParturitionAssetid, setNewParturitionAssetid] = useState("");
  const [
    getNewParturitionAssetfriendlyName,
    setNewParturitionAssetfriendlyName,
  ] = useState("");
  const [getNewParturitionUserid, setNewParturitionUserid] = useState(username);
  const [
    getNewParturitionWeightAtFirstMating,
    setNewParturitionWeightAtFirstMating,
  ] = useState("");
  const [
    getNewParturitionAgeAtFirstParturition,
    setNewParturitionAgeAtFirstParturition,
  ] = useState("");
  const [
    getNewParturitionBodyConditionsIndex,
    setNewParturitionBodyConditionsIndex,
  ] = useState("");
  const [getNewParturitionNumberOfMatings, setNewParturitionNumberOfMatings] =
    useState("");
  const [getNewParturitionSupermogram, setNewParturitionSupermogram] =
    useState("");
  const [getNewParturitionBreed, setNewParturitionBreed] = useState("");
  const [getNewParturitionBreedPercentage, setNewParturitionBreedPercentage] =
    useState("");
  const [getNewParturitionBreederId, setNewParturitionBreederId] = useState("");
  const [getNewParturitionBreederAge, setNewParturitionBreederAge] =
    useState("");
  const [getNewParturitionBreedingDate, setNewParturitionBreedingDate] =
    useState("");
  const [getNewParturitionNextBreeding, setNewParturitionNextBreeding] =
    useState("");
  const [
    getNewParturitionExpectedKiddingDate,
    setNewParturitionExpectedKiddingDate,
  ] = useState("");
  const [getNewParturitionDeliveredDate, setNewParturitionDeliveredDate] =
    useState("");
  const [
    getNewParturitionTotalKidsDelivered,
    setNewParturitionTotalKidsDelivered,
  ] = useState("");
  const [getNewParturitionDisposal, setNewParturitionDisposal] = useState("");
  const [getNewParturitionLatitude, setNewParturitionLatitude] = useState("");
  const [getNewParturitionLongitude, setNewParturitionLongitude] = useState("");
  const [getNewParturitionAltitude, setNewParturitionAltitude] = useState("");
  const [getNewParturitionDescription, setNewParturitionDescription] =
    useState("");

  const [showParturitionDeletePopup, setParturitionDeletePopup] =
    useState(false);
  const [getParturitionDeleteAssetid, setParturitionDeleteAssetid] =
    useState("");

  const [showUpdateNewParturitionPopup, setUpdateNewParturitionPopup] =
    useState(false);
  const [getUpdateNewParturitionAssetid, setUpdateNewParturitionAssetid] =
    useState("");
  const [
    getUpdateNewParturitionAssetfriendlyName,
    setUpdateNewParturitionAssetfriendlyName,
  ] = useState("");
  const [getUpdateNewParturitionUserid, setUpdateNewParturitionUserid] =
    useState("");
  const [
    getUpdateNewParturitionWeightAtFirstMating,
    setUpdateNewParturitionWeightAtFirstMating,
  ] = useState("");
  const [
    getUpdateNewParturitionAgeAtFirstParturition,
    setUpdateNewParturitionAgeAtFirstParturition,
  ] = useState("");
  const [
    getUpdateNewParturitionBodyConditionsIndex,
    setUpdateNewParturitionBodyConditionsIndex,
  ] = useState("");
  const [
    getUpdateNewParturitionNumberOfMatings,
    setUpdateNewParturitionNumberOfMatings,
  ] = useState("");
  const [
    getUpdateNewParturitionSupermogram,
    setUpdateNewParturitionSupermogram,
  ] = useState("");
  const [getUpdateNewParturitionBreed, setUpdateNewParturitionBreed] =
    useState("");
  const [
    getUpdateNewParturitionBreedPercentage,
    setUpdateNewParturitionBreedPercentage,
  ] = useState("");
  const [getUpdateNewParturitionBreederId, setUpdateNewParturitionBreederId] =
    useState("");
  const [getUpdateNewParturitionBreederAge, setUpdateNewParturitionBreederAge] =
    useState("");
  const [
    getUpdateNewParturitionBreedingDate,
    setUpdateNewParturitionBreedingDate,
  ] = useState("");
  const [
    getUpdateNewParturitionNextBreeding,
    setUpdateNewParturitionNextBreeding,
  ] = useState("");
  const [
    getUpdateNewParturitionExpectedKiddingDate,
    setUpdateNewParturitionExpectedKiddingDate,
  ] = useState("");
  const [
    getUpdateNewParturitionDeliveredDate,
    setUpdateNewParturitionDeliveredDate,
  ] = useState("");
  const [
    getUpdateNewParturitionTotalKidsDelivered,
    setUpdateNewParturitionTotalKidsDelivered,
  ] = useState("");
  const [getUpdateNewParturitionDisposal, setUpdateNewParturitionDisposal] =
    useState("");
  const [getUpdateNewParturitionLatitude, setUpdateNewParturitionLatitude] =
    useState("");
  const [getUpdateNewParturitionLongitude, setUpdateNewParturitionLongitude] =
    useState("");
  const [getUpdateNewParturitionAltitude, setUpdateNewParturitionAltitude] =
    useState("");
  const [
    getUpdateNewParturitionDescription,
    setUpdateNewParturitionDescription,
  ] = useState("");
  /********** End Of Parturition ****************/
  /********** Mortality ****************/
  const [showMortality, setMortality] = useState(false);
  const [getMortalityDetails, setMortalityDetails] = useState([]);

  const [showNewMortalityPopuop, setNewMortalityPopuop] = useState(false);
  const [getNewMortalityAssetid, setNewMortalityAssetid] = useState("");
  const [getNewMortalityAssetFriendlyName, setNewMortalityAssetFriendlyName] =
    useState("");
  const [getNewMortalityUserid, setNewMortalityUserid] = useState(username);
  const [getNewMortalityModelid, setNewMortalityModelid] = useState("");
  const [getNewMortalityBreedPercentage, setNewMortalityBreedPercentage] =
    useState("");
  const [getNewMortalityGender, setNewMortalityGender] = useState("");
  const [getNewMortalityBirthDate, setNewMortalityBirthDate] = useState("");
  const [getNewMortalityExpiredDate, setNewMortalityExpiredDate] = useState("");
  const [getNewMortalityTotalAge, setNewMortalityTotalAge] = useState("");
  const [getNewMortalityCurrentValue, setNewMortalityCurrentValue] =
    useState("");
  const [getNewMortalityCauseOfDeath, setNewMortalityCauseOfDeath] =
    useState("");
  const [getNewMortalityLatitude, setNewMortalityLatitude] = useState("");
  const [getNewMortalityLongitude, setNewMortalityLongitude] = useState("");
  const [getNewMortalityAltitude, setNewMortalityAltitude] = useState("");
  const [getNewMortalityDescription, setNewMortalityDescription] = useState("");

  const [showMortalityDeletePopup, setMortalityDeletePopup] = useState(false);
  const [getMortalityDeleteAssetid, setMortalityDeleteAssetid] = useState("");

  const [showUpdateNewMortalityPopuop, setUpdateNewMortalityPopuop] =
    useState(false);
  const [getUpdateNewMortalityAssetid, setUpdateNewMortalityAssetid] =
    useState("");
  const [
    getUpdateNewMortalityAssetFriendlyName,
    setUpdateNewMortalityAssetFriendlyName,
  ] = useState("");
  const [getUpdateNewMortalityUserid, setUpdateNewMortalityUserid] =
    useState(username);
  const [getUpdateNewMortalityModelid, setUpdateNewMortalityModelid] =
    useState("");
  const [
    getUpdateNewMortalityBreedPercentage,
    setUpdateNewMortalityBreedPercentage,
  ] = useState("");
  const [getUpdateNewMortalityGender, setUpdateNewMortalityGender] =
    useState("");
  const [getUpdateNewMortalityBirthDate, setUpdateNewMortalityBirthDate] =
    useState("");
  const [getUpdateNewMortalityExpiredDate, setUpdateNewMortalityExpiredDate] =
    useState("");
  const [getUpdateNewMortalityTotalAge, setUpdateNewMortalityTotalAge] =
    useState("");
  const [getUpdateNewMortalityCurrentValue, setUpdateNewMortalityCurrentValue] =
    useState("");
  const [getUpdateNewMortalityCauseOfDeath, setUpdateNewMortalityCauseOfDeath] =
    useState("");
  const [getUpdateNewMortalityLatitude, setUpdateNewMortalityLatitude] =
    useState("");
  const [getUpdateNewMortalityLongitude, setUpdateNewMortalityLongitude] =
    useState("");
  const [getUpdateNewMortalityAltitude, setUpdateNewMortalityAltitude] =
    useState("");
  const [getUpdateNewMortalityDescription, setUpdateNewMortalityDescription] =
    useState("");
  /********** End Of Mortality ****************/
  /**********  Asset Map ,UnMap, Delete, Update, Create  ****************/
  const [mapPopUp, setMapPopUp] = useState(false);
  const [mapSiteId, setMapSiteId] = useState("");
  const [mapAssetId, setMapAssetId] = useState("");
  const [mapCategoryType, setMapCategoryType] = useState("");

  const [getShowDeletePopup, setShowDeletePopup] = useState(false);
  const [getMappedAssetDeleteAssetid, setMappedAssetDeleteAssetid] =
    useState("");
  const [getMappedAssetDeleteAssetType, setMappedAssetDeleteAssetType] =
    useState("");
  const [getMappedAssetDeleteAssetModelid, setMappedAssetDeleteAssetModelid] =
    useState("");
  const [
    getMappedAssetDeleteAssetVerticalid,
    setMappedAssetDeleteAssetVerticalid,
  ] = useState("");
  const [getMappedAssetDeleteAssetCategort, setMappedAssetDeleteAssetCategory] =
    useState("");

  const [getlegacyUpdatePopup, setLegacyUpdatePopup] = useState(false);
  const [assetVeriticalId, setAssetVerticalId] = useState("");
  const [assetTypeVal, setAssetTypeVal] = useState("");
  const [assetIdVal, setAssetIdVal] = useState("");
  const [assetFriName, setAssetFriName] = useState("");
  const [modelIdVal, setModelIdVal] = useState("");
  const [categoryVal, setCatgeryVal] = useState("");
  const [udnVal, setUdnVal] = useState("");
  const [whiteLabelVal, setWhiteLabelVal] = useState("");
  const [sellingCostVal, setSellingCostVal] = useState("");
  const [exAssetStatus, setExAssetStatus] = useState("");
  const [updateAssetStatus, setUpdateAssetStatus] = useState("");
  /********** End Of Asset Map UnMap Delete Update Create ****************/
  /**************************** Create Asset ********************************/
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [assetIdValue, setAssetIdValue] = useState("");
  const [legacyAssetFriendlyNameValue, setLegacyAssetFriendlyNameValue] =
    useState("");
  const [assetVerticalFriendlyNameValue, setAssetVerticalFriendlyNameValue] =
    useState("");
  const [assetDomainValue, setAssetDomainValue] = useState("");
  const [siteNameValue, setSiteNameValue] = useState("");
  const [zoneValue, setZoneValue] = useState("");
  const [lotNameValue, setLotNameValue] = useState("");
  const [manufacturerIdValue, setManufacturerIdValue] = useState(username);
  const [manufacturerUrlValue, setManufacturerUrlValue] = useState("");
  const [modelFriendlyNameValue, setModelFriendlyNameValue] = useState("");
  const [modelUrlValue, setModelUrlValue] = useState("");
  const [serialNoValue, setSerialNoValue] = useState("");
  const [udnValue, setUdnValue] = useState("");
  const [upcValue, setUpcValue] = useState("");
  const [motherBreedValue, setMotherBreedValue] = useState("");
  const [fatherBreedValue, setFatherBreedValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [widthValue, setWidthValue] = useState("");
  const [heightValue, setHeightValue] = useState("");
  const [depthValue, setDepthValue] = useState("");
  const [whiteLabelCostValue, setWhiteLabelCostValue] = useState("");
  const [sellingCostValue, setSellingCostValue] = useState("");
  const [acquireCostValue, setAcquireCostValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [altitudeValue, setAltitudeValue] = useState("");
  const [getCreateAssetPopup, setCreateAssetPopup] = useState(false);
  /********************************** End Of Create Asset***********************/
  /********** Site Feedback ****************/
  const [showSiteFeedbackModel, setShowSiteFeedbackModel] = useState(false);
  const [getSiteFeedbackSiteid, setSiteFeedbackSiteid] = useState("");

  const [getSiteCustomerExperience, setSiteCustomerExperience] = useState(0);
  const [getSiteCustomerExperienceHover, setSiteCustomerExperienceHover] =
    useState(-1);
  const [getSiteFeedbackCustomerExperience, setSiteFeedbackCustomerExperience] =
    useState("");

  const [getSiteProduct, setSiteProduct] = useState(0);
  const [getSiteProductHover, setSiteProductHover] = useState(-1);
  const [getSiteFeedbackProduct, setSiteFeedbackProduct] = useState("");

  const [getSiteCustomerSupport, setSiteCustomerSupport] = useState(0);
  const [getSiteCustomerSupportHover, setSiteCustomerSupportHover] =
    useState(-1);
  const [getSiteFeedbackCustomerSupport, setSiteFeedbackCustomerSupport] =
    useState("");

  const [getSiteDelivery, setSiteDelivery] = useState(0);
  const [getSiteDeliveryHover, setSiteDeliveryHover] = useState(-1);
  const [getSiteFeedbackDelivery, setSiteFeedbackDelivery] = useState("");

  const [getSiteServiceOthers, setSiteServiceOthers] = useState(0);
  const [getSiteServiceOthersHover, setSiteServiceOthersHover] = useState(-1);
  const [getSiteFeedbackServiceOthers, setSiteFeedbackServiceOthers] =
    useState("");

  const [getSiteFeedbackRemark, setSiteFeedbackRemark] = useState("");
  /********** End Of Site Feedback ****************/
  /********** Asset Feedback ****************/
  const [showAssetFeedbackModel, setShowAssetFeedbackModel] = useState(false);
  const [getAssetFeedbackAssetDeveloperid, setAssetFeedbackAssetDeveloperid] =
    useState(username);
  const [getAssetFeedbackAssetid, setAssetFeedbackAssetid] = useState("");

  const [getAssetCustomerExperience, setAssetCustomerExperience] = useState(0);
  const [getAssetCustomerExperienceHover, setAssetCustomerExperienceHover] =
    useState(-1);
  const [
    getAssetFeedbackCustomerExperience,
    setAssetFeedbackCustomerExperience,
  ] = useState("");

  const [getAssetProduct, setAssetProduct] = useState(0);
  const [getAssetProductHover, setAssetProductHover] = useState(-1);
  const [getAssetFeedbackProduct, setAssetFeedbackProduct] = useState("");

  const [getAssetCustomerSupport, setAssetCustomerSupport] = useState(0);
  const [getAssetCustomerSupportHover, setAssetCustomerSupportHover] =
    useState(-1);
  const [getAssetFeedbackCustomerSupport, setAssetFeedbackCustomerSupport] =
    useState("");

  const [getAssetDelivery, setAssetDelivery] = useState(0);
  const [getAssetDeliveryHover, setAssetDeliveryHover] = useState(-1);
  const [getAssetFeedbackDelivery, setAssetFeedbackDelivery] = useState("");

  const [getAssetServiceOthers, setAssetServiceOthers] = useState(0);
  const [getAssetServiceOthersHover, setAssetServiceOthersHover] = useState(-1);
  const [getAssetFeedbackServiceOthers, setAssetFeedbackServiceOthers] =
    useState("");

  const [getAssetFeedbackRemark, setAssetFeedbackRemark] = useState("");
  /********** End Of Asset Feedback ****************/
  /********** Site Issue ****************/
  const [showSiteIssue, getShowSiteIssue] = useState(false);
  const [getSiteIssueSolutionId, setSiteIssueSolutionId] = useState(username);
  const [getSiteIssueSiteId, setSiteIssueSiteId] = useState("");
  const [getSiteIssueDescription, setSiteIssueDescription] = useState("");

  const [showSiteIssueDetailsModel, getShowSiteIssueDetailsModel] =
    useState(false);
  const [getSiteIssueDetails, setSiteIssueDetails] = useState([]);
  /********** End Of Site Issue ****************/
  /********** Asset Issue ****************/
  const [showAssetIssue, getShowAssetIssue] = useState(false);
  const [getAssetIssueSolutionId, setAssetIssueSolutionId] = useState(username);
  const [getAssetIssueSiteId, setAssetIssueSiteId] = useState("");
  const [getAssetIssueAssetId, setAssetIssueAssetId] = useState("");
  const [getAssetIssueDescription, setAssetIssueDescription] = useState("");

  const [showAssetIssueDetailsModel, getShowAssetIssueDetailsModel] =
    useState(false);
  const [getAssetIssueDetails, setAssetIssueDetails] = useState([]);
  /********** End Of Asset Issue ****************/
  /********** Daily Report ****************/
  const [showDailyReports, setShowDailyReports] = useState(false);
  const [getDailyReports, setDailyReports] = useState([]);

  const [showAddDailyReportModel, getShowAddDailyReportModel] = useState(false);
  const [getAddDailyReportAssetid, setAddDailyReportAssetid] = useState("");
  const [getAddDailyReportNutrition, setAddDailyReportNutrition] = useState("");
  const [getAddDailyReportAttendance, setAddDailyReportAttendance] =
    useState("");
  const [getAddDailyReportPhoto, setAddDailyReportPhoto] = useState("");
  /********** End Of Daily Report ****************/

  /************    Site Alarms       **********/
  const [gridViewSiteAlarms, setGridViewSiteAlarms] = useState(false);
  const [siteAlarmPopup, setSiteAlarmPopup] = useState(false);
  const [sitealarmsList, setSiteAlarmsList] = useState([]);
  /******* End of Site Alarms ******** */
  /* Satrt of Create Site Alarm */
  const [selectedVerticalName, setSelectedVerticalName] = useState("");
  const [alarmCategoryValue, setAlarmCategoryValue] = useState("");
  const [alarmNameValue, setAlarmNameValue] = useState("");
  const [resourceId, setResourceId] = useState("");
  /* End of Site Alarm */
  /********** Site Group Nutrition ****************/
  const [showGropuNutritionView, setShowGropuNutritionView] = useState(false);
  const [getGroupNutritionDetails, setGroupNutritionDetails] = useState([]);

  const [showAddGroupNutritionModel, setShowAddGroupNutritionModel] =
    useState(false);
  const [getAddGroupNutritionSiteid, setAddGroupNutritionSiteid] = useState("");
  const [getAddGroupNutritionFriendlyname, setAddGroupNutritionFriendlyname] =
    useState("");
  const [getAddGroupNutritionSiteType, setAddGroupNutritionSiteType] =
    useState("");
  const [getAddGroupNutritionUserid, setAddGroupNutritionUserid] =
    useState(username);
  const [getAddGroupNutritionFeedType, setAddGroupNutritionFeedType] =
    useState("");
  const [getAddGroupNutritionSchedule, setAddGroupNutritionSchedule] =
    useState("");
  const [getAddGroupNutritionLatitude, setAddGroupNutritionLatitude] =
    useState("");
  const [getAddGroupNutritionLongitude, setAddGroupNutritionLongitude] =
    useState("");
  const [getAddGroupNutritionAltitude, setAddGroupNutritionAltitude] =
    useState("");
  const [getAddGroupNutritionDescription, setAddGroupNutritionDescription] =
    useState("");

  const [showDeleteGroupNutritionModel, setShowDeleteGroupNutritionModel] =
    useState(false);
  const [getDeleteGroupNutritionSiteid, setDeleteGroupNutritionSiteid] =
    useState("");

  const [showUpdateGroupNutritionModel, setShowUpdateGroupNutritionModel] =
    useState(false);
  const [getUpdateGroupNutritionSiteid, setUpdateGroupNutritionSiteid] =
    useState("");
  const [
    getUpdateGroupNutritionFriendlyname,
    setUpdateGroupNutritionFriendlyname,
  ] = useState("");
  const [getUpdateGroupNutritionSiteType, setUpdateGroupNutritionSiteType] =
    useState("");
  const [getUpdateGroupNutritionUserid, setUpdateGroupNutritionUserid] =
    useState("");
  const [getUpdateGroupNutritionFeedType, setUpdateGroupNutritionFeedType] =
    useState("");
  const [getUpdateGroupNutritionSchedule, setUpdateGroupNutritionSchedule] =
    useState("");
  const [getUpdateGroupNutritionLatitude, setUpdateGroupNutritionLatitude] =
    useState("");
  const [getUpdateGroupNutritionLongitude, setUpdateGroupNutritionLongitude] =
    useState("");
  const [getUpdateGroupNutritionAltitude, setUpdateGroupNutritionAltitude] =
    useState("");
  const [
    getUpdateGroupNutritionDescription,
    setUpdateGroupNutritionDescription,
  ] = useState("");

  const [
    prevgetUpdateGroupNutritionFeedType,
    prevsetUpdateGroupNutritionFeedType,
  ] = useState("");
  const [
    prevgetUpdateGroupNutritionSchedule,
    prevsetUpdateGroupNutritionSchedule,
  ] = useState("");
  /********** End Of Site Group Nutrition ****************/
  /**********Site Attendance Location ****************/
  const [showSiteAttendanceLocation, setSiteAttendanceLocation] =
    useState(false);
  const [getSiteAttendanceLocationHistory, setSiteAttendanceLocationHistory] =
    useState([]);

  const [
    showSiteNewAttendanceLocationPopup,
    setSiteNewAttendanceLocationPopup,
  ] = useState(false);
  const [
    getSiteNewAttendanceLocationAssetid,
    setSiteNewAttendanceLocationAssetid,
  ] = useState("");
  const [
    getSiteNewAttendanceLocationAssetfriendlyName,
    setSiteNewAttendanceLocationAssetfriendlyName,
  ] = useState("");
  const [
    getSiteNewAttendanceLocationUserid,
    setSiteNewAttendanceLocationUserid,
  ] = useState(username);
  const [
    getSiteNewAttendanceLocationLatitude,
    setSiteNewAttendanceLocationLatitude,
  ] = useState("");
  const [
    getSiteNewAttendanceLocationLongitude,
    setSiteNewAttendanceLocationLongitude,
  ] = useState("");
  const [
    getSiteNewAttendanceLocationAltitude,
    setSiteNewAttendanceLocationAltitude,
  ] = useState("");
  const [
    getSiteNewAttendanceLocationDescription,
    setSiteNewAttendanceLocationDescription,
  ] = useState("");

  const [getSiteDeletedAttendanceAssetid, setSiteDeletedAttendanceAssetid] =
    useState("");
  const [getSiteDeletedAttendancePopup, setSiteDeletedAttendancePopup] =
    useState(false);

  const [
    getSiteUpdateAttendanceLocationInfoAssetid,
    setSiteUpdateAttendanceLocationInfoAssetid,
  ] = useState("");
  const [
    getSiteUpdateAttendanceLocationInfoAssetfriendlyName,
    setSiteUpdateAttendanceLocationInfoAssetfriendlyName,
  ] = useState("");
  const [
    getSiteUpdateAttendanceLocationInfoUserid,
    setSiteUpdateAttendanceLocationInfoUserid,
  ] = useState("");
  const [
    getSiteUpdateAttendanceLocationInfoLatitude,
    setSiteUpdateAttendanceLocationInfoLatitude,
  ] = useState("");
  const [
    getSiteUpdateAttendanceLocationInfoLongitude,
    setSiteUpdateAttendanceLocationInfoLongitude,
  ] = useState("");
  const [
    getSiteUpdateAttendanceLocationInfoAltitude,
    setSiteUpdateAttendanceLocationInfoAltitude,
  ] = useState("");
  const [
    getSiteUpdateAttendanceLocationInfoDescription,
    setSiteUpdateAttendanceLocationInfoDescription,
  ] = useState("");
  const [
    showSiteUpdateAttendaneLocationPopup,
    setSiteUpdateAttendaneLocationPopup,
  ] = useState(false);
  /********** End Of Site Attendance Location ****************/
  /********** Grow,Sell,Loan,Vet Visit Requests ****************/
  const [getGrowSiteID, setGrowSiteID] = useState("");
  const [viewGrow, setViewGrow] = useState(false);
  const [getGrowReq, setGrowReq] = useState([]);

  const [showAddGrowReqModel, setShowAddGrowReqModel] = useState(false);
  const [getGrowReqSource, setGrowReqSource] = useState(username);
  const [getGrowReqAssetVerticalId, setGrowReqAssetVerticalId] = useState("");
  const [getGrowReqCategory, setGrowReqCategory] = useState("");
  const [getGrowReqLegacyAssetType, setGrowReqLegacyAssetType] = useState("");
  const [getGrowReqModelid, setGrowReqModelid] = useState("");
  const [getGrowReqPhone, setGrowReqPhone] = useState("");
  const [getGrowReqEmail, setGrowReqEmail] = useState("");
  const [getGrowReqCity, setGrowReqCity] = useState("");
  const [getGrowReqState, setGrowReqState] = useState("");
  const [getGrowReqAddress, setGrowReqAddress] = useState("");
  const [getGrowReqLatitude, setGrowReqLatitude] = useState("");
  const [getGrowReqLongitude, setGrowReqLongitude] = useState("");
  const [getGrowReqQuantity, setGrowReqQuantity] = useState("");

  const [getGrowUpdateRequestModel, setGrowUpdateRequestModel] =
    useState(false);
  const [getGrowId, setGrowId] = useState("");
  const [getGrowPreStatus, setGrowPreStatus] = useState("");
  const [getGrowPostStatus, setGrowPostStatus] = useState("");

  const [getCancelGrowUpdateRequestModel, setCancelGrowUpdateRequestModel] =
    useState(false);
  const [getCancelGrowId, setCancelGrowId] = useState("");
  const [getCancelGrowPreStatus, setCancelGrowPreStatus] = useState("");

  const [getSellSiteID, setSellSiteID] = useState("");
  const [viewSell, setViewSell] = useState(false);
  const [getSellReq, setSellReq] = useState([]);

  const [showAddSellReqModel, setShowAddSellReqModel] = useState(false);
  const [getSellReqSource, setSellReqSource] = useState(username);
  const [getSellReqAssetVerticalId, setSellReqAssetVerticalId] = useState("");
  const [getSellReqCategory, setSellReqCategory] = useState("");
  const [getSellReqLegacyAssetType, setSellReqLegacyAssetType] = useState("");
  const [getSellReqModelid, setSellReqModelid] = useState("");
  const [getSellReqPhone, setSellReqPhone] = useState("");
  const [getSellReqEmail, setSellReqEmail] = useState("");
  const [getSellReqCity, setSellReqCity] = useState("");
  const [getSellReqState, setSellReqState] = useState("");
  const [getSellReqAddress, setSellReqAddress] = useState("");
  const [getSellReqLatitude, setSellReqLatitude] = useState("");
  const [getSellReqLongitude, setSellReqLongitude] = useState("");
  const [getSellReqQuantity, setSellReqQuantity] = useState("");
  const [getSellReqCost, setSellReqCost] = useState("");

  const [getSellUpdateRequestModel, setSellUpdateRequestModel] =
    useState(false);
  const [getSellId, setSellId] = useState("");
  const [getSellPreStatus, setSellPreStatus] = useState("");
  const [getSellPostStatus, setSellPostStatus] = useState("");

  const [getCancelSellUpdateRequestModel, setCancelSellUpdateRequestModel] =
    useState(false);
  const [getCancelSellId, setCancelSellId] = useState("");
  const [getCancelSellPreStatus, setCancelSellPreStatus] = useState("");

  const [viewLoan, setViewLoan] = useState(false);
  const [viewVetVisit, setViewVetVisit] = useState(false);
  const [getLoanReq, setLoanReq] = useState([]);
  const [getVetReq, setVetReq] = useState([]);
  const [showAddLoanReqModel, setShowAddLoanReqModel] = useState(false);
  const [getLoanUpdateRequestModel, setLoanUpdateRequestModel] =
    useState(false);
  const [getLoanId, setLoanId] = useState("");
  const [getLoanPreStatus, setLoanPreStatus] = useState("");
  const [getLoanPostStatus, setLoanPostStatus] = useState("");
  const [getLoanReqModelid, setLoanReqModelid] = useState("");
  const [getLoanReqPhone, setLoanReqPhone] = useState("");
  const [getLoanReqEmail, setLoanReqEmail] = useState("");
  const [getLoanReqCity, setLoanReqCity] = useState("");
  const [getLoanReqState, setLoanReqState] = useState("");
  const [getLoanReqAddress, setLoanReqAddress] = useState("");
  const [getLoanReqLatitude, setLoanReqLatitude] = useState("");
  const [getLoanReqLongitude, setLoanReqLongitude] = useState("");
  const [getLoanReqQuantity, setLoanReqQuantity] = useState("");
  const [getLoanReqCost, setLoanReqCost] = useState("");

  const [getCancelLoanUpdateRequestModel, setCancelLoanUpdateRequestModel] =
    useState(false);
  const [getCancelLoanId, setCancelLoanId] = useState("");
  const [getCancelLoanPreStatus, setCancelLoanPreStatus] = useState("");

  const [showAddVetVisitReqModel, setShowAddVetVisitReqModel] = useState(false);
  const [getVetVisitReqLegacyAssetType, setVetVisitReqLegacyAssetType] =
    useState("");
  const [getVetVisitReqModelid, setVetVisitReqModelid] = useState("");
  const [getVetVisitReqPhone, setVetVisitReqPhone] = useState("");
  const [getVetVisitReqEmail, setVetVisitReqEmail] = useState("");
  const [getVetVisitReqCity, setVetVisitReqCity] = useState("");
  const [getVetVisitReqState, setVetVisitReqState] = useState("");
  const [getVetVisitReqLatitude, setVetVisitReqLatitude] = useState("");
  const [getVetVisitReqLongitude, setVetVisitReqLongitude] = useState("");
  const [getVetVisitReqQuantity, setVetVisitReqQuantity] = useState("");
  const [getVetVisitReqCost, setVetVisitReqCost] = useState("");
  const [getVetVisitReqAddress, setVetVisitReqAddress] = useState("");
  const [getVetVisitUpdateRequestModel, setVetVisitUpdateRequestModel] =
    useState(false);
  const [
    getCancelVetVisitUpdateRequestModel,
    setCancelVetVisitUpdateRequestModel,
  ] = useState(false);

  /********** End Of Grow,Sell,Loan,Vet Visit Requests ****************/
  /* Asset Live Monoitoring */
  const [markers, setMarkers] = useState([]);
  const [ssetMonitoringStatus, setAssetMonitoringStatus] = useState("");
  const [marker_position, setmarker_position] = useState({
    lat: 0,
    lng: 0,
  });
  const [assetPostionList, setAssetPositionList] = useState({});
  const [ssetMonitorTimestamp, setAssetMonitorTimestamp] = useState("");
  const [assetMonitoringView, setAssetMonitoringView] = useState(false);
  const [selectedAssetId,setSelectedAssetId] = useState("");
 /* Photo Upload */
 const [selectedCategoryName, setSelectedCategoryName] = useState("image");
 const [uploadPhotoPopup, setShowUploadPhotoPopup] = useState(false);
 const [uploadPhotoFile, setUploadPhotoFile] = useState(null);
 const [uploadPhotoFileDescription, setUploadPhotoFileDescription] =
   useState(null);
 const [uploadPhotoNameValue, setUploadPhotoNameValue] = useState("");
 const [priceValue, setPriceValue] = useState("");
 const [expiryDateValue, setExpiryDateValue] = useState("");
 const [discountValue, setDiscountValue] = useState("");
 const [selectedCategoryId, setSelectedCategoryId] = useState("");
 /* End of Photo Upload */

  const [userTypeValue,setUserTypeValue] = useState("");
  /* View All Sites */

  const getAllSitesData = (Type) => {
    setPage(0);
    setIsLoading(true);
    if (Type === "List") {
      setViewallListSites(true);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setShowSiteRating(false);
      setAssetList(false);
      setAssetGrid(false);
      setAssetMap(false);
      

      setBirth(false);
      setAttendanceLocation(false);
      setHealthRecords(false);
      setNutrition(false);
      setParturition(false);
      setMortality(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
      setViewGrow(false);
      setViewSell(false);
      setViewLoan(false);
      setViewVetVisit(false);
    } else if (Type === "Grid") {
      setViewallListSites(false);
      setViewallGridSites(true);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
  
      setShowSiteRating(false);
      setAssetList(false);
      setAssetGrid(false);
      setAssetMap(false);


      setBirth(false);
      setAttendanceLocation(false);
      setHealthRecords(false);
      setNutrition(false);
      setParturition(false);
      setMortality(false);
      setGridViewSiteAlarms(false);
      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
      setViewGrow(false);
      setViewSell(false);
      setViewLoan(false);
      setViewVetVisit(false);
    } else if (Type === "Map") {
      setViewallListSites(false);
      setViewallGridSites(false);
      setViewallMapSites(true);
      setAssetMonitoringView(false);
      setShowSiteRating(false);
      setAssetList(false);
      setAssetGrid(false);
      setAssetMap(false);
   

      setBirth(false);
      setAttendanceLocation(false);
      setHealthRecords(false);
      setNutrition(false);
      setParturition(false);
      setMortality(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
      setViewGrow(false);
      setViewSell(false);
      setViewLoan(false);
      setViewVetVisit(false);
    }

    
    MainService.viewAllFarms(username,"Farm")
      .then((res) => {
        setIsLoading(false);
        setAllSites(res.sites);
        getFilterUsers(res.sites);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  useMemo(() => {
    getAllSitesData();
  }, [setAllSites]);
  /* End View All Sites */

  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.sitename.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAllSites(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.sitename.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAllSites(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };
  useEffect(() => {
    getUserType();
    getCategory();
   
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getAllSitesList();
  }, [setSiteData]);

  useEffect(() => {
    getAssetVerticalsIdList();
  }, [setVerticalsIdList]);

  const getUserType = () => {
    
    MainService.getConsumerUserType(parentId,username)
      .then((data) => {
        console.log("userTypeValue:",data)
        setUserTypeValue(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const getCategory = () => {
    if (parentId !== "" && selectedCategoryName !== "") {
      MainService.getCategory(parentId, selectedCategoryName)
        .then((data) => {
          if (data) {
            setSelectedCategoryId(data);
          } else {
            setSelectedCategoryId("");
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error("error");
        });
    }
  };

  /* Pagination */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* End Pagination */

  /* Birth Api's*/


  const birthInfoButtonClickHandler = (assetid, assetType) => {
    setNewBirthAssetid(assetid);
    setAssetBackButtton(assetType);
    getBirthDetailsHandler();
    setBirth(true);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(false);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(false);
  };

  const getBirthDetailsHandler = () => {
    setIsLoading(true);
    MainService.getBirthDetails(username)
      .then((res) => {
        setIsLoading(false);
        setBirthInfos(res.birthInfos);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const setNewBirthSiteidHandler = (e) => {
    setUpdateNewBirthSiteid(e.target.value);
    const siteidfilter = getAllSites.filter((sites) => {
      return sites.siteid === e.target.value;
    });
    setUpdateNewBirthSiteName(siteidfilter[0].sitename);
  };

  const createNewBirthInfo = () => {
    const birth = {
      assetid: getNewBirthAssetid,
      assetfriendlyname: getNewBirthAssetFriendlyName,
      parentid: parentId,
      userid: getNewBirthUserid,
      username: getNewBirthUserName,
      siteid: getNewBirthSiteid,
      sitename: getNewBirthSiteName,
      lotid: getNewBirthLotid,
      lotname: getNewBirthLotName,
      legacyassettype: getNewBirthLegacyAssetType,
      modelid: getNewBirthModelid,
      modelfriendlyname: getNewBirthModelFriendlyName,
      modeltype: getNewBirthModelType,
      modelurl: getNewBirthModelURL,
      dob: getNewBirthDOB,
      gender: getNewBirthGender,
      maleparent: getNewBirthMaleParent,
      femaleparent: getNewBirthFemaleParent,
      parentmodelid: getNewBirthParentModelid,
      latitude: getNewBirthLatitude,
      longitude: getNewBirthLongitude,
      altitude: getNewBirthAltitude,
      description: getNewBirthDescription,
    };
    const birthinfo = JSON.stringify(birth);

    MainService.addBirthInfo(birthinfo)
      .then((res) => {
        setNewBirthInfoPopup(false);
        getBirthDetailsHandler();
        return NotificationManager.success("birth info added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteNewBirthInfoAssetId = (assetid) => {
    setDeleteBirthInfo(assetid);
    setDeleteBirthInfoPopup(!getDeleteBirthInfoPopup);
  };

  const deleteBirthInfoAsset = () => {
    MainService.deleteBirthInfo(getDeleteBirthInfo)
      .then((res) => {        
        getBirthDetailsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
      setDeleteBirthInfoPopup(!getDeleteBirthInfoPopup);
  };

  const updateBirthInfoData = (assetinfo) => {
    setUpdateNewBirthAssetid(assetinfo.assetid);
    setUpdateNewBirthAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateUpdateNewBirthUserid(assetinfo.userid);
    setUpdateNewBirthUserName(assetinfo.username);
    setUpdateNewBirthSiteid(assetinfo.siteid);
    setUpdateNewBirthSiteName(assetinfo.sitename);
    setUpdateNewBirthLotid(assetinfo.lotid);
    setUpdateNewBirthLotName(assetinfo.lotname);
    setUpdateNewBirthLegacyAssetType(assetinfo.legacyassettype);
    setUpdateNewBirthModelid(assetinfo.modelid);
    setUpdateNewBirthModelFriendlyName(assetinfo.modelfriendlyname);
    setUpdateNewBirthModelType(assetinfo.modeltype);
    setUpdateNewBirthModelURL(assetinfo.modelurl);
    setUpdateNewBirthDOB(assetinfo.dob);
    setUpdateNewBirthGender(assetinfo.gender);
    setUpdateNewBirthMaleParent(assetinfo.maleparent);
    setUpdateNewBirthFemaleParent(assetinfo.femaleparent);
    setUpdateNewBirthParentModelid(assetinfo.parentmodelid);
    setUpdateNewBirthLatitude(assetinfo.latitude);
    setUpdateNewBirthLongitude(assetinfo.longitude);
    setUpdateNewBirthAltitude(assetinfo.altitude);
    setUpdateNewBirthDescription(assetinfo.description);

    setUpdateBirthInfoPopup(!showUpdateBirthInfoPopup);
  };

  const updateNewBirthInfoHandler = () => {
    const updatebirth = {
      assetid: getUpdateNewBirthAssetid,
      assetfriendlyname: getUpdateNewBirthAssetFriendlyName,
      siteid: getUpdateNewBirthSiteid,
      sitename: getUpdateNewBirthSiteName,
      lotid: getUpdateNewBirthLotid,
      lotname: getUpdateNewBirthLotName,
      legacyassettype: getUpdateNewBirthLegacyAssetType,
      modelid: getUpdateNewBirthModelid,
      modelfriendlyname: getUpdateNewBirthModelFriendlyName,
      modeltype: getUpdateNewBirthModelType,
      modelurl: getUpdateNewBirthModelURL,
      dob: getUpdateNewBirthDOB,
      gender: getUpdateNewBirthGender,
      maleparent: getUpdateNewBirthMaleParent,
      femaleparent: getUpdateNewBirthFemaleParent,
      parentmodelid: getUpdateNewBirthParentModelid,
      latitude: getUpdateNewBirthLatitude,
      longitude: getUpdateNewBirthLongitude,
      altitude: getUpdateNewBirthAltitude,
      description: getUpdateNewBirthDescription,
    };
    const updatebirthinfo = JSON.stringify(updatebirth);
    MainService.updateBirthInfo(updatebirthinfo)
      .then((res) => {
        setUpdateBirthInfoPopup(false);
        getBirthDetailsHandler();
        return NotificationManager.success("birth info updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Birth Api's*/

  /* Attendance Location Api's*/
  const assetAttenanceClick = (assetId) => {
    MainService.getAttendanceLocationInfo(assetId)
      .then((res) => {
        console.log("attendance  innfo:", res);
        setAssetAttendanceOnj(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setAssetAttendanceDialog(!assetAttendanceDialog);
  };

  const attendanceLocationButtonClickHandler = (assetid, assetType) => {
    setNewAttendanceLocationAssetid(assetid);
    setAssetBackButtton(assetType);

    attendanceLocationHistoryHandler();
    setBirth(false);
    setAttendanceLocation(true);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(false);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(false);
  };

  const attendanceLocationHistoryHandler = () => {
    setIsLoading(true);
    MainService.AttendanceLocationHistory(username)
      .then((res) => {
        setIsLoading(false);
        setAttendanceLocationHistory(res.attendanceLocations);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const addAttendanceLocationHandler = () => {
    const attendanceLocation = {
      assetid: getNewAttendanceLocationAssetid,
      assetfriendlyname: getNewAttendanceLocationAssetfriendlyName,
      parentid: parentId,
      userid: getNewAttendanceLocationUserid,
      latitude: getNewAttendanceLocationLatitude,
      longitude: getNewAttendanceLocationLongitude,
      altitude: getNewAttendanceLocationAltitude,
      description: getNewAttendanceLocationDescription,
    };
    const newattendanceLocation = JSON.stringify(attendanceLocation);

    MainService.NewAttendanceLocation(newattendanceLocation)
      .then((res) => {
        setNewAttendanceLocationPopup(false);
        attendanceLocationHistoryHandler();
        NotificationManager.success("attendance location added");
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const deleteAttendanceLocationsAssetId = (assetid) => {
    setDeletedAttendanceAssetid(assetid);
    setDeletedAttendancePopup(!getDeletedAttendancePopup);
  };

  const deleteAttendanceAsset = () => {
    MainService.DeleteNewAttendanceLocation(getDeletedAttendanceAssetid)
      .then((res) => {
        setDeletedAttendancePopup(false);
        attendanceLocationHistoryHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateAttendanceLocationsAssetInfo = (assetinfo) => {
    setUpdateAttendanceLocationInfoAssetid(assetinfo.assetid);
    setUpdateAttendanceLocationInfoAssetfriendlyName(
      assetinfo.assetfriendlyname
    );
    setUpdateAttendanceLocationInfoUserid(assetinfo.userid);
    setUpdateAttendanceLocationInfoLatitude(assetinfo.latitude);
    setUpdateAttendanceLocationInfoLongitude(assetinfo.longitude);
    setUpdateAttendanceLocationInfoAltitude(assetinfo.altitude);
    setUpdateAttendanceLocationInfoDescription(assetinfo.description);
    setUpdateAttendaneLocationPopup(!showUpdateAttendaneLocationPopup);
  };

  const updateAttendanceLocationsHandler = () => {
    const updateAttendanceAsset = {
      assetid: getUpdateAttendanceLocationInfoAssetid,
      assetfriendlyname: getUpdateAttendanceLocationInfoAssetfriendlyName,
      latitude: getUpdateAttendanceLocationInfoLatitude,
      longitude: getUpdateAttendanceLocationInfoLongitude,
      altitude: getUpdateAttendanceLocationInfoAltitude,
      description: getUpdateAttendanceLocationInfoDescription,
    };
    const updateAttendance = JSON.stringify(updateAttendanceAsset);

    MainService.UpdateAttendanceLocation(updateAttendance)
      .then((res) => {
        setUpdateAttendaneLocationPopup(false);
        attendanceLocationHistoryHandler();
        return NotificationManager.success("attendance location updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Attendance Location Api's*/

  /* Health Records Api's */

  const healthRecordButtonClickHandler = (assetid, assetType) => {
    healthRecordsHandler();
    setNewHealthAssetid(assetid);
    setAssetBackButtton(assetType);
    attendanceLocationHistoryHandler();
    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(true);
    setNutrition(false);
    setParturition(false);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(false);
  };

  const healthRecordsHandler = () => {
    setIsLoading(true);
    MainService.getHealthRecords(username)
      .then((res) => {
        setIsLoading(false);
        setHealthRecordsList(res.healthRecord);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const addHealthRecordHandler = () => {
    const addHealthRecord = {
      assetid: getNewHealthAssetid,
      assetfriendlyname: getNewHealthAssetFriendlyName,
      parentid: parentId,
      userid: getNewHealthUserid,
      medicinedate: getNewHealthMedicineDate,
      vaccinationdate: getNewHealthVaccinationDate,
      examine: getNewHealthExamine,
      surgicalprocedure: getNewHealthSurgicalProcedure,
      vaccinationtype: getNewHealthVaccinationType,
      medicinetype: getNewHealthMedicineType,
      diseasetype: getNewHealthDiseaseType,
      treatmentdate: getNewHealthTreatmentDate,
      latitude: getNewHealthLatitude,
      longitude: getNewHealthLongitude,
      altitude: getNewHealthAltitude,
      description: getNewHealthDescription,
    };
    const healthRecord = JSON.stringify(addHealthRecord);

    MainService.addHealthRecords(healthRecord)
      .then((res) => {
        setNewHealthRecordPopup(false);
        healthRecordsHandler();
        return NotificationManager.success("health record added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteHealthRecordAssetId = (assetid) => {
    setDeleteHealthAssetid(assetid);
    setDeleteHealthAssetPopup(!showDeleteHealthAssetPopup);
  };

  const deleteHealthRecordAsset = () => {
    MainService.deleteHealthRecord(getDeleteHealthAssetid)
      .then((res) => {
        setDeleteHealthAssetPopup(false);
        healthRecordsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateHealthRecordInfo = (assetinfo) => {
    setUpdateNewHealthAssetid(assetinfo.assetid);
    setUpdateNewHealthAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateNewHealthUserid(assetinfo.userid);
    setUpdateNewHealthMedicineDate(assetinfo.medicinedate);
    setUpdateNewHealthVaccinationDate(assetinfo.vaccinationdate);
    setUpdateNewHealthExamine(assetinfo.examine);
    setUpdateNewHealthSurgicalProcedure(assetinfo.surgicalprocedure);
    setUpdateNewHealthVaccinationType(assetinfo.vaccinationtype);
    setUpdateNewHealthMedicineType(assetinfo.medicinetype);
    setUpdateNewHealthDiseaseType(assetinfo.diseasetype);
    setUpdateNewHealthTreatmentDate(assetinfo.treatmentdate);
    setUpdateNewHealthLatitude(assetinfo.latitude);
    setUpdateNewHealthLongitude(assetinfo.longitude);
    setUpdateNewHealthAltitude(assetinfo.altitude);
    setUpdateNewHealthDescription(assetinfo.description);

    setUpdateNewHealthRecordPopup(!showUpdateNewHealthRecordPopup);
  };

  const updateHealthRecordHandler = () => {
    const updateHealthRecord = {
      assetid: getUpdateNewHealthAssetid,
      assetfriendlyname: getUpdateNewHealthAssetFriendlyName,
      medicinedate: getUpdateNewHealthMedicineDate,
      vaccinationdate: getUpdateNewHealthVaccinationDate,
      examine: getUpdateNewHealthExamine,
      surgicalprocedure: getUpdateNewHealthSurgicalProcedure,
      vaccinationtype: getUpdateNewHealthVaccinationType,
      medicinetype: getUpdateNewHealthMedicineType,
      diseasetype: getUpdateNewHealthDiseaseType,
      treatmentdate: getUpdateNewHealthTreatmentDate,
      latitude: getUpdateNewHealthLatitude,
      longitude: getUpdateNewHealthLongitude,
      altitude: getUpdateNewHealthAltitude,
      description: getUpdateNewHealthDescription,
    };
    const healthrecord = JSON.stringify(updateHealthRecord);

    MainService.updateHealthRecord(healthrecord)
      .then((res) => {
        setUpdateNewHealthRecordPopup(false);
        healthRecordsHandler();
        return NotificationManager.success("health record updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Worng");
      });
  };
  /* End Of Health Records Api's */

  /* Nutrition Api's */

  const nutritionButtonClickHandler = (assetid, assetType) => {
    getNutritionHandler();
    setAddNutritionAssetid(assetid);
    setAssetBackButtton(assetType);
    attendanceLocationHistoryHandler();
    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(true);
    setParturition(false);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(false);
  };

  const getNutritionHandler = () => {
    setIsLoading(true);
    MainService.getNutrition(username)
      .then((res) => {
        setIsLoading(false);
        setNutritions(res.nutritions);
      })
      .catch((err) => {
        return NotificationManager.err(err);
      });
  };

  const addNutritionHandler = () => {
    const addnutrition = {
      assetid: getAddNutritionAssetid,
      assetfriendlyname: getAddNutritionAssetFriendlyName,
      parentid: parentId,
      userid: getAddNutritionUserid,
      feedtype: getAddNutritionFeedType,
      schedule: getAddNutritionSchedule,
      latitude: getAddNutritionLatitude,
      longitude: getAddNutritionLongitude,
      altitude: getAddNutritionAltitude,
      description: getAddNutritionDescription,
    };
    const addnutritionstr = JSON.stringify(addnutrition);

    MainService.addNutrition(addnutritionstr)
      .then((res) => {
        setAddNutritionPopup(false);
        getNutritionHandler();
        return NotificationManager.success("nutrition added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteNutririonAssetid = (assetid) => {
    setDeleteNutritionAssetid(assetid);
    setDeleteNutrition(true);
  };

  const deleteNutritionHandler = () => {
    MainService.deleteNutrition(getDeleteNutritionAssetid)
      .then((res) => {
        setDeleteNutrition(false);
        getNutritionHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateNutririonInfo = (assetinfo) => {
    setUpdateAddNutritionAssetid(assetinfo.assetid);
    setUpdateAddNutritionAssetFriendlyName(assetinfo.assetfriendlyname);
    setUpdateAddNutritionUserid(assetinfo.userid);
    setUpdateAddNutritionFeedType(assetinfo.feedtype);
    setUpdateAddNutritionSchedule(assetinfo.schedule);
    setUpdateAddNutritionLatitude(assetinfo.latitude);
    setUpdateAddNutritionLongitude(assetinfo.longitude);
    setUpdateAddNutritionAltitude(assetinfo.altitude);
    setUpdateAddNutritionDescription(assetinfo.description);
    setUpdateAddNutritionExistingFeedType(assetinfo.feedtype);

    setUpdateAddNutritionPopup(!showUpdateAddNutritionPopup);
  };

  const updateNutritionHandler = () => {
    const updateNutrition = {
      assetid: getUpdateAddNutritionAssetid,
      assetfriendlyname: getUpdateAddNutritionAssetFriendlyName,
      feedtype: getUpdateAddNutritionFeedType,
      schedule: getUpdateAddNutritionSchedule,
      latitude: getUpdateAddNutritionLatitude,
      longitude: getUpdateAddNutritionLongitude,
      altitude: getUpdateAddNutritionAltitude,
      description: getUpdateAddNutritionDescription,
    };
    const updateNutritionStr = JSON.stringify(updateNutrition);

    MainService.updateNutrition(updateNutritionStr)
      .then((res) => {
        setUpdateAddNutritionPopup(false);
        getNutritionHandler();
        return NotificationManager.success("nutrition info updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Nutrition Api's */

  /* Parturition Api's */

  const parturitionButtonClickHandler = (assetid, assetType) => {
    getParturitionDetailsHandler();
    setNewParturitionAssetid(assetid);
    setAssetBackButtton(assetType);
    attendanceLocationHistoryHandler();
    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(true);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(false);
  };

  const getParturitionDetailsHandler = () => {
    setIsLoading(true);
    MainService.getParturitionDetails(username)
      .then((res) => {
        setIsLoading(false);
        setParturitionDetail(res.parturitions);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const addParturitionHandler = () => {
    const newparturition = {
      assetid: getNewParturitionAssetid,
      assetfriendlyname: getNewParturitionAssetfriendlyName,
      parentid: parentId,
      userid: getNewParturitionUserid,
      weightatfirstmating: getNewParturitionWeightAtFirstMating,
      ageatfirstparturition: getNewParturitionAgeAtFirstParturition,
      bodyconditionsindex: getNewParturitionBodyConditionsIndex,
      numberofmatings: getNewParturitionNumberOfMatings,
      supermogram: getNewParturitionSupermogram,
      breed: getNewParturitionBreed,
      breedpercentage: getNewParturitionBreedPercentage,
      breederid: getNewParturitionBreederId,
      breederage: getNewParturitionBreederAge,
      breedingdate: getNewParturitionBreedingDate,
      nextbreeding: getNewParturitionNextBreeding,
      expectedkiddingdate: getNewParturitionExpectedKiddingDate,
      delivereddate: getNewParturitionDeliveredDate,
      totalkidsdelivered: getNewParturitionTotalKidsDelivered,
      disposal: getNewParturitionDisposal,
      latitude: getNewParturitionLatitude,
      longitude: getNewParturitionLongitude,
      altitude: getNewParturitionAltitude,
      description: getNewParturitionDescription,
    };
    const newparturitionStr = JSON.stringify(newparturition);

    MainService.addParturitionDetails(newparturitionStr)
      .then((res) => {
        setNewParturitionPopup(false);
        getParturitionDetailsHandler();
        return NotificationManager.success("parturition details added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteParturitionAssetid = (assetid) => {
    setParturitionDeleteAssetid(assetid);
    setParturitionDeletePopup(true);
  };

  const deleteParturitionHandler = () => {
    MainService.deleteParturitionDetails(getParturitionDeleteAssetid)
      .then((res) => {
        setParturitionDeletePopup(false);
        getParturitionDetailsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateParturitionInfo = (ParturitionInfo) => {
    setUpdateNewParturitionAssetid(ParturitionInfo.assetid);
    setUpdateNewParturitionAssetfriendlyName(ParturitionInfo.assetfriendlyname);
    setUpdateNewParturitionUserid(ParturitionInfo.userid);
    setUpdateNewParturitionWeightAtFirstMating(
      ParturitionInfo.weightatfirstmating
    );
    setUpdateNewParturitionAgeAtFirstParturition(
      ParturitionInfo.ageatfirstparturition
    );
    setUpdateNewParturitionBodyConditionsIndex(
      ParturitionInfo.bodyconditionsindex
    );
    setUpdateNewParturitionNumberOfMatings(ParturitionInfo.numberofmatings);
    setUpdateNewParturitionSupermogram(ParturitionInfo.supermogram);
    setUpdateNewParturitionBreed(ParturitionInfo.breed);
    setUpdateNewParturitionBreedPercentage(ParturitionInfo.breedpercentage);
    setUpdateNewParturitionBreederId(ParturitionInfo.breederid);
    setUpdateNewParturitionBreederAge(ParturitionInfo.breederage);
    setUpdateNewParturitionBreedingDate(ParturitionInfo.breedingdate);
    setUpdateNewParturitionNextBreeding(ParturitionInfo.nextbreeding);
    setUpdateNewParturitionExpectedKiddingDate(
      ParturitionInfo.expectedkiddingdate
    );
    setUpdateNewParturitionDeliveredDate(ParturitionInfo.delivereddate);
    setUpdateNewParturitionTotalKidsDelivered(
      ParturitionInfo.totalkidsdelivered
    );
    setUpdateNewParturitionDisposal(ParturitionInfo.disposal);
    setUpdateNewParturitionLatitude(ParturitionInfo.latitude);
    setUpdateNewParturitionLongitude(ParturitionInfo.longitude);
    setUpdateNewParturitionAltitude(ParturitionInfo.altitude);
    setUpdateNewParturitionDescription(ParturitionInfo.description);

    setUpdateNewParturitionPopup(true);
  };

  const updateParturitionHandler = () => {
    const updateparturition = {
      assetid: getUpdateNewParturitionAssetid,
      assetfriendlyname: getUpdateNewParturitionAssetfriendlyName,
      weightatfirstmating: getUpdateNewParturitionWeightAtFirstMating,
      ageatfirstparturition: getUpdateNewParturitionAgeAtFirstParturition,
      bodyconditionsindex: getUpdateNewParturitionBodyConditionsIndex,
      numberofmatings: getUpdateNewParturitionNumberOfMatings,
      supermogram: getUpdateNewParturitionSupermogram,
      breed: getUpdateNewParturitionBreed,
      breedpercentage: getUpdateNewParturitionBreedPercentage,
      breederid: getUpdateNewParturitionBreederId,
      breederage: getUpdateNewParturitionBreederAge,
      breedingdate: getUpdateNewParturitionBreedingDate,
      nextbreeding: getUpdateNewParturitionNextBreeding,
      expectedkiddingdate: getUpdateNewParturitionExpectedKiddingDate,
      delivereddate: getUpdateNewParturitionDeliveredDate,
      totalkidsdelivered: getUpdateNewParturitionTotalKidsDelivered,
      disposal: getUpdateNewParturitionDisposal,
      latitude: getUpdateNewParturitionLatitude,
      longitude: getUpdateNewParturitionLongitude,
      altitude: getUpdateNewParturitionAltitude,
      description: getUpdateNewParturitionDescription,
    };
    const updateparturitionstr = JSON.stringify(updateparturition);

    MainService.updateParturitionDetails(updateparturitionstr)
      .then((res) => {
        setUpdateNewParturitionPopup(false);
        getParturitionDetailsHandler();
        return NotificationManager.success("parturition info updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };
  /* End Of Parturition Api's */

  /*  Mortality Api's */

  const mortalityButtonClickHandler = (assetid, assetType) => {
    getMortalityDetailsHandler();
    setNewMortalityAssetid(assetid);
    setAssetBackButtton(assetType);
    attendanceLocationHistoryHandler();
    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(false);
    setMortality(true);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(false);
  };

  const getMortalityDetailsHandler = () => {
    setIsLoading(true);
    MainService.getMortalityDetails(username)
      .then((res) => {
        setIsLoading(false);
        setMortalityDetails(res.mortality);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const addMortalityHandler = () => {
    const addmortality = {
      assetid: getNewMortalityAssetid,
      assetfriendlyname: getNewMortalityAssetFriendlyName,
      parentid: parentId,
      userid: getNewMortalityUserid,
      modelid: getNewMortalityModelid,
      breedpercentage: getNewMortalityBreedPercentage,
      gender: getNewMortalityGender,
      birthdate: getNewMortalityBirthDate,
      expireddate: getNewMortalityExpiredDate,
      totalage: getNewMortalityTotalAge,
      currentvalue: getNewMortalityCurrentValue,
      causeofdeath: getNewMortalityCauseOfDeath,
      latitude: getNewMortalityLatitude,
      longitude: getNewMortalityLongitude,
      altitude: getNewMortalityAltitude,
      description: getNewMortalityDescription,
    };
    const addmortalitystr = JSON.stringify(addmortality);

    MainService.addMortalityDetails(addmortalitystr)
      .then((res) => {
        setNewMortalityPopuop(false);
        getMortalityDetailsHandler();
        return NotificationManager.success("mortality details added");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  const deleteMortalityAssetid = (assetid) => {
    setMortalityDeleteAssetid(assetid);
    setMortalityDeletePopup(true);
  };

  const deleteMortalityHandler = () => {
    MainService.deleteMortalityDetails(getMortalityDeleteAssetid)
      .then((res) => {
        setMortalityDeletePopup(false);
        getMortalityDetailsHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateMortalityInfo = (mortalityinfo) => {
    setUpdateNewMortalityAssetid(mortalityinfo.assetid);
    setUpdateNewMortalityAssetFriendlyName(mortalityinfo.assetfriendlyname);
    setUpdateNewMortalityUserid(mortalityinfo.userid);
    setUpdateNewMortalityModelid(mortalityinfo.modelid);
    setUpdateNewMortalityBreedPercentage(mortalityinfo.breedpercentage);
    setUpdateNewMortalityGender(mortalityinfo.gender);
    setUpdateNewMortalityBirthDate(mortalityinfo.birthdate);
    setUpdateNewMortalityExpiredDate(mortalityinfo.expireddate);
    setUpdateNewMortalityTotalAge(mortalityinfo.totalage);
    setUpdateNewMortalityCurrentValue(mortalityinfo.currentvalue);
    setUpdateNewMortalityCauseOfDeath(mortalityinfo.causeofdeath);
    setUpdateNewMortalityLatitude(mortalityinfo.latitude);
    setUpdateNewMortalityLongitude(mortalityinfo.longitude);
    setUpdateNewMortalityAltitude(mortalityinfo.altitude);
    setUpdateNewMortalityDescription(mortalityinfo.description);

    setUpdateNewMortalityPopuop(true);
  };

  const updateMortalityHandler = () => {
    const updatemortality = {
      assetid: getUpdateNewMortalityAssetid,
      assetfriendlyname: getUpdateNewMortalityAssetFriendlyName,
      modelid: getUpdateNewMortalityModelid,
      breedpercentage: getUpdateNewMortalityBreedPercentage,
      gender: getUpdateNewMortalityGender,
      birthdate: getUpdateNewMortalityBirthDate,
      expireddate: getUpdateNewMortalityExpiredDate,
      totalage: getUpdateNewMortalityTotalAge,
      currentvalue: getUpdateNewMortalityCurrentValue,
      causeofdeath: getUpdateNewMortalityCauseOfDeath,
      latitude: getUpdateNewMortalityLatitude,
      longitude: getUpdateNewMortalityLongitude,
      altitude: getUpdateNewMortalityAltitude,
      description: getUpdateNewMortalityDescription,
    };
    const updatemortalitystr = JSON.stringify(updatemortality);
    MainService.updateMortalityDetails(updatemortalitystr)
      .then((res) => {
        setUpdateNewMortalityPopuop(false);
        getMortalityDetailsHandler();
        return NotificationManager.success("mortality info updated");
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };
  /* End Of Mortality Api's */

  

  const viewMappedAssets = (siteid, Type, sitename) => {
   
    setMappedAssetsType(Type);
    setMappedAssetsSiteid(siteid);
    setMappedAssetsSiteName(sitename);

    setPage(0);
    setRedirectSiteId(siteid);
    setNewBirthSiteName(sitename);
    setNewBirthSiteid(siteid);
    setAssetIssueSiteId(siteid);
    if (Type === "List") {
      setViewallListSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setShowSiteRating(false);
      setMappedAssetShow(false);
      setAssetList(true);
      setAssetGrid(false);
      setAssetMap(false);

      setBirth(false);
      setAttendanceLocation(false);
      setHealthRecords(false);
      setNutrition(false);
      setParturition(false);
      setMortality(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
      setViewGrow(false);
      setViewSell(false);
      setViewLoan(false);
    } else if (Type === "Grid") {
      setViewallListSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setShowSiteRating(false);
      setMappedAssetShow(false);
      setAssetList(false);
      setAssetGrid(true);
      setAssetMap(false);

      setBirth(false);
      setAttendanceLocation(false);
      setHealthRecords(false);
      setNutrition(false);
      setParturition(false);
      setMortality(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
      setViewGrow(false);
      setViewSell(false);
      setViewLoan(false);
    } else if (Type === "Map") {
      setViewallListSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setShowSiteRating(false);
      setMappedAssetShow(false);
      setAssetList(false);
      setAssetGrid(false);
      setAssetMap(true);

      setBirth(false);
      setAttendanceLocation(false);
      setHealthRecords(false);
      setNutrition(false);
      setParturition(false);
      setMortality(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
      setViewGrow(false);
      setViewSell(false);
      setViewLoan(false);
    } else {
      setViewallListSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setShowSiteRating(false);

      setAssetList(false);
      setAssetGrid(false);
      setAssetMap(false);

      setBirth(false);
      setAttendanceLocation(false);
      setHealthRecords(false);
      setNutrition(false);
      setParturition(false);
      setMortality(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
      setViewGrow(false);
      setViewSell(false);
      setViewLoan(false);
    }

    MainService.mappedLegacyAssets(siteid, username)
      .then((res) => {
        setMappedAssets(res.assets);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  

  
  /* Site Delete Functionality */

  const deleteSiteHandler = (siteid, sitename) => {
    setSelectedSiteId(siteid);
    setSelectedSiteName(sitename);
    setSiteDeleteDialog(!siteDeleteDialog);
  };
  const deletSiteSubmitClcik = () => {
    MainService.siteDeleteHandler(selectedSiteId, selectedSiteName, username)
      .then((res) => {
        getAllSitesData("Grid");
        return NotificationManager.success("Successfully Deleted");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
      setSiteDeleteDialog(!siteDeleteDialog);
    
  };
  /* End Of Site Delete Functionality */

  /*Site Ratings */

  const siteRatingsHandler = (siteId) => {
    
    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(true);
    setSiteRateSiteId(siteId);

    MainService.siteRating(siteId)
      .then((res) => {
        setSiteRatings(res.ratings);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const addedSiteRatingHandler = (siteratesiteid) => {
    setDefaultSiteId(siteratesiteid);
    setSiteRatePopUp(!getSiteRatePopup);
  };

  const addorupdateSiteRating = () => {
    const siteRateData = {
      siteid: defaultSiteId,
      ratings: setupdateSiteRating,
    };
    const formData = JSON.stringify(siteRateData);
    MainService.addSiteRating(formData)
      .then((res) => {
        setSiteRatePopUp(!getSiteRatePopup);
        siteRatingsHandler(defaultSiteId);
        return NotificationManager.success("ratings added");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const closeSiteRatingModal = (
    <span
      onClick={() => {
        setSiteRatePopUp(!getSiteRatePopup);
      }}
    >
      X
    </span>
  );

  const siteRateAgreement = "RATINGS";
  const siteRateModel = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="siteId"> Id:</InputLabel>
        <Input
          disabled
          id="siteId"
          name="siteId"
          type="text"
          value={defaultSiteId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="siterating"> Ratings:</InputLabel>
        <Input
          id="siterating"
          name="siterating"
          type="text"
          value={setupdateSiteRating}
          onChange={(e) => getUpDateSiteRating(e.target.value)}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={addorupdateSiteRating}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setSiteRatePopUp(!getSiteRatePopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /*End Of Site Ratings */

  /* Create Node & Site */

  const getAllSitesList = () => {
    MainService.getAllSiteTypes(parentId)
      .then((res) => {
        setSiteData(res.sitetypes);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const siteCreateHandler = () => {
    var siteDetails = {
      farmerusername: getFarmerUserName,
      parentid: username,
      sitename: getSiteName,
      mailid: getFarmerUserName,
      phone: getPhone,
      sitetype: getSiteType,
      assetverticalid: selectedVerticalsId,
      assetverticalfriendlyname: assetVerticalFriendlyNameValue,
    };

    var siteBody = [];
    for (var property in siteDetails) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(siteDetails[property]);
      siteBody.push(encodedKey + "=" + encodedValue);
    }
    siteBody = siteBody.join("&");

    MainService.createSite(siteBody)
      .then((res) => {
   
        setViewallListSites(false);
        setViewallGridSites(true);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setShowSiteRating(false);
      setAssetList(false);
      setAssetGrid(false);
      setAssetMap(false);
      

      setBirth(false);
      setAttendanceLocation(false);
      setHealthRecords(false);
      setNutrition(false);
      setParturition(false);
      setMortality(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
      setViewGrow(false);
      setViewSell(false);
      setViewLoan(false);
      setViewVetVisit(false);
      getAllSitesData("Grid");
        return NotificationManager.success("Site Created");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSiteCreatePopUp(!getSitePopUp);
    
  };
  const closeSiteModal = (
    <span
      onClick={() => {
        setSiteCreatePopUp(!getSitePopUp);
      }}
    >
      X
    </span>
  );

  const siteCreationTitle = "Create New Site";
  const siteModelBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="farmerName"> Farmer User Name:</InputLabel>
        <Input
          id="farmerName"
          name="farmerName"
          type="text"
          value={getFarmerUserName}
          onChange={(e) => {
            setFarmerUserName(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="mailid"> Mail ID:</InputLabel>
        <Input
          disabled
          id="mailid"
          name="mailid"
          type="text"
          value={getFarmerUserName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="phone">Phone Number</InputLabel>
        <Input
          id="phone"
          name="phone"
          type="text"
          value={getPhone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
        <Input
          id="sitename"
          name="sitename"
          type="text"
          value={getSiteName}
          onChange={(e) => {
            setSiteName(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentid"> Parent Id:</InputLabel>
        <Input
          disabled
          id="parentid"
          name="parentid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>AssetVerticalId:</InputLabel>
        <Select
          id="assetVerticalID"
          name="assetVerticalID"
          type="text"
          value={selectedVerticalsId}
          onChange={(e) => {
            setSelectedVerticalsIdHandler(e);
          }}
        >
          {verticalsIdList.length > 0 ? (
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))
          ) : (
            <h4>Asset Vertical not found</h4>
          )}
        </Select>
      </FormControl>
      
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Type</InputLabel>
        <Input
        disabled
          id="selectedModel"
          value={getSiteType}
          label="Select Model"
        >
          {/* {getSiteData &&
            getSiteData.map((idObj, index) => (
              <MenuItem key={`${index}`} value={idObj.sitetype}>
                {idObj.sitetype}
              </MenuItem>
            ))} */}
        </Input>
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={siteCreateHandler}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setSiteCreatePopUp(!getSitePopUp);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
 
  /* End Of Create Node & Site */

  /* Start of Site Creation with Node Id */

  const createSitWithNodeId = () => {
    setShowCreateSitePopup(!showCreateSitePopup);
  };
  const siteAndNodeCreateHandler = () => {

    var details = {
      nodeid: getNodeId,
      parentid: username,
      sitename: getSiteName,
      mailid: nodeEmail,
      phone: nodeMobileNumber,
      sitetype: nodeType,
      assetverticalid: selectedVerticalsId,
      assetverticalfriendlyname: assetVerticalFriendlyNameValue,
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    MainService.createSiteWithNodeId(formBody)
      .then((res) => {
        
        setViewallListSites(false);
        setViewallGridSites(true);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setShowSiteRating(false);
      setAssetList(false);
      setAssetGrid(false);
      setAssetMap(false);
      

      setBirth(false);
      setAttendanceLocation(false);
      setHealthRecords(false);
      setNutrition(false);
      setParturition(false);
      setMortality(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
      setViewGrow(false);
      setViewSell(false);
      setViewLoan(false);
      setViewVetVisit(false);
        return NotificationManager.success("Site and Node Created");
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setShowCreateSiteDialog(!showCreateSiteDialog);
    getAllSitesData("Grid");
  };
  const createSiteHandler = () => {
    setSiteCreatePopUp(!getSitePopUp);
    findSiteTypeHandler();
  };

  const submitForNode = () => {
    MainService.getNodeInfoBasedOnNodeId(getNodeId)
      .then((data) => {
        if (data) {
          setNodeUserName(data.username);
          setNodePhoneNumber(data.phone);
          setNodeEmail(data.email);
          setNodeType(data.nodetype);
        } else {
          return NotificationManager.error("invalid node id");
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });

    setShowCreateSitePopup(!showCreateSitePopup);
    setShowCreateSiteDialog(!showCreateSiteDialog);
    getAllSitesData("Grid");
  };
  const closeSiteWithNodeModal = (
    <span
      onClick={() => {
        setShowCreateSitePopup(!showCreateSitePopup);
      }}
    >
      X
    </span>
  );

  const closeSiteCreationWithNodeModal = (
    <span
      onClick={() => {
        setShowCreateSiteDialog(!showCreateSiteDialog);
      }}
    >
      X
    </span>
  );
  const siteAndNodeCreationTitle = "Create New Node & Site";

  const siteCreationWithNodeModelBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentId"> Node ID:</InputLabel>
        <Input
          id="nodeid"
          name="nodeid"
          type="text"
          value={getNodeId}
          onChange={(e) => setNodeId(e.target.value)}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={submitForNode}
        >
          Submit
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowCreateSitePopup(!showCreateSitePopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  const siteCreationModelBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentId"> Node ID:</InputLabel>
        <Input
          disabled
          id="parentId"
          name="parentId"
          type="text"
          value={getNodeId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="farmerName"> Farmer User Name:</InputLabel>
        <Input
          id="farmerName"
          name="farmerName"
          type="text"
          value={nodeUserName}
          //onChange={(e) => setSiteName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="mailid"> Mail ID:</InputLabel>
        <Input
          id="mailid"
          name="mailid"
          type="text"
          value={nodeEmail}
          //onChange={(e) => setMailId(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="phone">Phone Number</InputLabel>
        <Input
          id="phone"
          name="phone"
          type="text"
          value={nodeMobileNumber}
          //onChange={(e) => setPhone(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
        <Input
          id="sitename"
          name="sitename"
          type="text"
          value={getSiteName}
          onChange={(e) => setSiteName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Parent Id:</InputLabel>
        <Input
          id="sitename"
          name="sitename"
          type="text"
          value={username}
          onChange={(e) => setSiteName(e.target.value)}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>AssetVerticalId:</InputLabel>
        <Select
          id="assetVerticalID"
          name="assetVerticalID"
          type="text"
          value={selectedVerticalsId}
          onChange={(e) => {
            setSelectedVerticalsIdHandler(e);
          }}
        >
          {verticalsIdList.length > 0 ? (
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))
          ) : (
            <h4>Asset Vertical not found</h4>
          )}
        </Select>
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="siteType">Site Type</InputLabel>
        <Input
          id="siteType"
          name="siteType"
          type="text"
          value={nodeType}
          //onChange={(e) => setPhone(e.target.value)}
        />
      </FormControl>

      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={siteAndNodeCreateHandler}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowCreateSiteDialog(!showCreateSiteDialog);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End of Site Creation with Node Id */

  /* Site Info */
  const siteInfoForQr = (infositeid) => {
    MainService.siteInfo(infositeid)
      .then((res) => {
        setAssetVerticalIdForQr(res.assetverticalid);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const siteInfoPopUpHandler = (infositeid) => {
    MainService.siteInfo(infositeid)
      .then((res) => {
        console.log("site info:", res);
        setSiteInfoObj(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });

    getInfoSiteId(infositeid);
    if (generateQrClick === false) {
      getSiteInfoPopUp(true);
    }
  };

  const closeSiteInfoModal = (
    <span
      onClick={() => {
        getSiteInfoPopUp(!setSiteInfoPopup);
      }}
    >
      X
    </span>
  );

  const siteInfoHeader = "SITE DETAILS";
  const SiteInfoBody = (
    <div>
      <p>
        <b>Site Name:</b>
        {siteInfoObj.sitename}
      </p>
      <p>
        <b>AssetverticalId:</b>
        {siteInfoObj.assetverticalid}
      </p>
      <p>
        <b>Displayname:</b>
        {siteInfoObj.displayname}
      </p>
      <p>
        <b>Designation:</b>
        {siteInfoObj.designation}
      </p>
      <p>
        <b>Industry Type:</b>
        {siteInfoObj.industrytype}
      </p>
      <p>
        <b>Contact Addr:</b>
        {siteInfoObj.contactaddr}
      </p>
      <p>
        <b>City Name:</b>
        {siteInfoObj.cityname}
      </p>
      <p>
        <b>City Code:</b>
        {siteInfoObj.citycode}
      </p>
      <p>
        <b>Country:</b>
        {siteInfoObj.country}
      </p>
      <p>
        <b>Countrycode:</b>
        {siteInfoObj.countrycode}
      </p>
      <p>
        <b>Street Name:</b>
        {siteInfoObj.streetname}
      </p>
      <p>
        <b>Street Number:</b>
        {siteInfoObj.streetnumber}
      </p>
      <p>
        <b>Area:</b>
        {siteInfoObj.area}
      </p>
      <p>
        <b>Region:</b>
        {siteInfoObj.region}
      </p>
      <p>
        <b>Postal Code:</b>
        {siteInfoObj.postalcode}
      </p>
      <p>
        <b>Subdivision:</b>
        {siteInfoObj.subdivision}
      </p>
      <p>
        <b>Locality:</b>
        {siteInfoObj.locality}
      </p>
      <p>
        <b>Latitude:</b>
        {siteInfoObj.latitude}
      </p>
      <p>
        <b>Longitude:</b>
        {siteInfoObj.longitude}
      </p>
      <p>
        <b>Altitude:</b>
        {siteInfoObj.altitude}
      </p>
      <p>
        <b>Timezone:</b>
        {siteInfoObj.timezone}
      </p>
      <br />
      <Button
        variant="outlined"
        style={{ left: "45%" }}
        onClick={() => {
          getSiteInfoPopUp(!setSiteInfoPopup);
        }}
      >
        Cancel
      </Button>
    </div>
  );
  /* End of Site Info */

  /* Start of Site Update */
  const updateSiteUpdateClick = (infositeid) => {
    getInfoSiteId(infositeid);
    MainService.siteInfo(infositeid)
      .then((res) => {
        setSiteInfoName(res.sitename);
        setSiteInfoExistingSiteType(res.subdivision);
        setWebsiteUrl(res.designation);
        setLocAddress(res.contactaddr);
        setSiteInfoAssetVerticalId(res.assetverticalid);
        setIndustryType(res.industrytype);
        setStreetName(res.streetname);
        setStreetNumber(res.streetnumber);
        setLocality(res.locality);
        setArea(res.area);
        setRegion(res.region);
        setCountry(res.country);
        setPostalCode(res.postalcode);
        setLatitude(res.latitude);
        setLongitude(res.longitude);
        setAltitude(res.altitude);
        setTimeZone(res.timezone);
        setCityCode(res.citycode);
        setCityName(res.cityname);
        setCountryCode(res.countrycode);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setSiteUpdatePopup(!getSiteUpdatePopup);
  };

  const updateSiteUpdateHandler = () => {
    const siteUpdateInfo = {
      phone: getPhoneNumber,
      email: getEmail,
      sitetype: getUpdateSiteType,
      websiteurl: getWebsiteUrl,
      locationaddress: getLocAddress?.replace(/\n/g, ''),
      streetname: getStreetName,
      streetnumber: getStreetNumber,
      locality: getLocality,
      area: getArea,
      region: getRegion,
      country: getCountry,
      postalcode: getPostalCode,
      latitude: getLatitude,
      longitude: getLongitude,
      altitude: getAltitude,
      timezone: getTimeZone,
      citycode: getCityCode,
      cityname: getCityName,
      countrycode: getCountryCode,
      stdcode: getStdCode,
    };

    const formData = JSON.stringify(siteUpdateInfo);

    MainService.updateSiteInfo(infoSiteId, formData)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    getAllSitesData();
    setSiteUpdatePopup(!getSiteUpdatePopup);
  };

  const closeSiteUpdateModal = (
    <span
      onClick={() => {
        setSiteUpdatePopup(!getSiteUpdatePopup);
      }}
    >
      X
    </span>
  );

  const siteUpdateHeader = "SITE UPDATE DETAILS";
  const SiteUpdateBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="parentId"> Admin ID:</InputLabel>
        <Input
          disabled
          id="parentId"
          name="parentId"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="siteid"> Site Id:</InputLabel>
        <Input
          disabled
          id="siteid"
          name="siteid"
          type="text"
          value={infoSiteId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitename"> Site Name:</InputLabel>
        <Input
          disabled
          id="sitename"
          name="sitename"
          type="text"
          value={getSiteInfoName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="phone"> Phone Number:</InputLabel>
        <Input
          id="phone"
          name="phone"
          type="number"
          value={getPhoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="email"> Email:</InputLabel>
        <Input
          id="email"
          name="email"
          type="text"
          value={setEmail}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sitetype"> Existing Site Type:</InputLabel>
        <Input
          disabled
          id="sitetype"
          name="sitetype"
          type="text"
          value={getSiteInfoExistingSiteType}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Update Site Type</InputLabel>
        <Select
          id="selectedModel"
          value={getUpdateSiteType}
          onChange={(e) =>{setUpdateSiteType(e.target.value)} }
          label="Select Model"
        >
          <MenuItem value="" disabled selected>
            <em>Select Any Site Type</em>
          </MenuItem>
          <MenuItem key="Farm" value="Farm">
            Farm
          </MenuItem>
          <MenuItem key="OpenFarm" value="OpenFarm">
            OpenFarm
          </MenuItem>
          <MenuItem key="PhysicalMarket" value="PhysicalMarket">
            Physical-Market
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="weburl"> Website URL:</InputLabel>
        <Input
          id="weburl"
          name="weburl"
          type="text"
          value={getWebsiteUrl}
          onChange={(e) => setWebsiteUrl(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="locaddress"> Location Address:</InputLabel>
        <Input
          id="locaddress"
          name="locaddress"
          type="text"
          value={getLocAddress}
          onChange={(e) => setLocAddress(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetvi"> Asset Vertical Id:</InputLabel>
        <Input
          disabled
          id="assetvi"
          name="assetvi"
          type="text"
          value={getSiteInfoAssetVerticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="industrytype">
          {" "}
          Industry Type (Asset Vertical Friendly Name):
        </InputLabel>
        <Input
          id="industrytype"
          name="industrytype"
          type="text"
          value={getIndustryType}
          onChange={(e) => setIndustryType(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="streetname"> Street Name:</InputLabel>
        <Input
          id="streetname"
          name="streetname"
          type="text"
          value={getStreetName}
          onChange={(e) => setStreetName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="streetnumber"> Street Number:</InputLabel>
        <Input
          id="streetnumber"
          name="streetnumber"
          type="text"
          value={getStreetNumber}
          onChange={(e) => setStreetNumber(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="locality"> Locality:</InputLabel>
        <Input
          id="locality"
          name="locality"
          type="text"
          value={getLocality}
          onChange={(e) => setLocality(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="area"> Area (State):</InputLabel>
        <Input
          id="area"
          name="area"
          type="text"
          value={getArea}
          onChange={(e) => setArea(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="region"> Region:</InputLabel>
        <Input
          id="region"
          name="region"
          type="text"
          value={getRegion}
          onChange={(e) => setRegion(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="country"> Country:</InputLabel>
        <Input
          id="country"
          name="country"
          type="text"
          value={getCountry}
          onChange={(e) => setCountry(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="pstcode"> Postal Code(Zip):</InputLabel>
        <Input
          id="pstcode"
          name="pstcode"
          type="text"
          value={getPostalCode}
          onChange={(e) => setPostalCode(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="latitude"> Latitude:</InputLabel>
        <Input
          id="latitude"
          name="latitude"
          type="text"
          value={getLatitude}
          onChange={(e) => setLatitude(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="longitude"> Longitude:</InputLabel>
        <Input
          id="longitude"
          name="longitude"
          type="text"
          value={getLongitude}
          onChange={(e) => setLongitude(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="altitude"> Altitude:</InputLabel>
        <Input
          id="altitude"
          name="altitude"
          type="text"
          value={getAltitude}
          onChange={(e) => setAltitude(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="timezone"> Time Zone:</InputLabel>
        <Input
          id="timezone"
          name="timezone"
          type="text"
          value={getTimeZone}
          onChange={(e) => setTimeZone(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="citycode"> City Code:</InputLabel>
        <Input
          id="citycode"
          name="citycode"
          type="text"
          value={getCityCode}
          onChange={(e) => setCityCode(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="cityname"> City Name:</InputLabel>
        <Input
          id="cityname"
          name="cityname"
          type="text"
          value={getCityName}
          onChange={(e) => setCityName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="countrycode"> Country Code:</InputLabel>
        <Input
          id="countrycode"
          name="countrycode"
          type="text"
          value={getCountryCode}
          onChange={(e) => setCountryCode(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="stdcode"> Std Code:</InputLabel>
        <Input
          id="stdcode"
          name="stdcode"
          type="text"
          value={getStdCode}
          onChange={(e) => setStdCode(e.target.value)}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateSiteUpdateHandler}
        >
          Update
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setSiteUpdatePopup(!getSiteUpdatePopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* End Of Site update Info */
  /*************** Site Management Map *************/
  const SiteManagementGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={getAllSites.index}
        defaultZoom={4}
        defaultCenter={marker_position}
        center={marker_position}
      >
        {getAllSites.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <button
                  type="button"
                  onClick={() => {
                    viewMappedAssets(value.siteid, "Map", value.sitename);
                  }}
                >
                  <div>{value.sitename}</div>
                </button>
              </InfoWindow>
            </Marker>
          ) : (
            ""
          );
        })}
      </GoogleMap>
    );
  });

  const AssetsGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={getMappedAssets.index}
        defaultZoom={4}
        defaultCenter={marker_position}
        center={marker_position}
      >
        {getMappedAssets.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <div>{value.assetType}</div>
              </InfoWindow>
            </Marker>
          ) : (
            ""
          );
        })}
      </GoogleMap>
    );
  });
  /*************** End Of Site Management Map *************/

  /****************** Map, UnMap, Update, Delete Asset Api's **********************/


  const addAssetToSiteMappingHandler = () => {
    const siteIdData = {
      siteid: mapSiteId,
    };
    const siteid = JSON.stringify(siteIdData);
    MainService.createMapping(username, mapAssetId, mapCategoryType, siteid)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
      setMapPopUp(!mapPopUp);
      setViewallListSites(false);
      setViewallGridSites(false);
      setViewallMapSites(false);
      setAssetMonitoringView(false);
      setShowSiteRating(false);

      setAssetList(false);
      setAssetGrid(false);
      setAssetMap(false);

      setBirth(false);
      setAttendanceLocation(false);
      setHealthRecords(false);
      setNutrition(false);
      setParturition(false);
      setMortality(false);

      setShowDailyReports(false);
      setShowGropuNutritionView(false);
      setSiteAttendanceLocation(false);
      setViewGrow(false);
      setViewSell(false);
      setViewLoan(false);
  };

  const unMapAssetHandler =(assetInfo) => {
    const siteId = assetInfo.siteId;
    const siteIdData = {
      siteid: siteId,
    };
    const siteidstr = JSON.stringify(siteIdData);
    MainService.createUnMapping(
      assetInfo.assetId,
      assetInfo.category,
      siteidstr,
      username
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
     
      viewMappedAssets(getMappedAssetsSiteid,getMappedAssetsType,getMappedAssetsSiteName);
  };

  const addedAssetDeleteHandler = (assetid) => {
    MainService.getLegacyAssetInfo(username, assetid)
      .then((res) => {
        setMappedAssetDeleteAssetid(res.assetId);
        setMappedAssetDeleteAssetType(res.assetType);
        setMappedAssetDeleteAssetModelid(res.modelId);
        setMappedAssetDeleteAssetVerticalid(res.assetverticalId);
        setMappedAssetDeleteAssetCategory(res.category);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setShowDeletePopup(!getShowDeletePopup);
  };

  const assetDeleteHandler = () => {
    MainService.assetDelete(
      getMappedAssetDeleteAssetid,
      getMappedAssetDeleteAssetType,
      getMappedAssetDeleteAssetModelid,
      getMappedAssetDeleteAssetVerticalid,
      getMappedAssetDeleteAssetCategort,
      username
    )
      .then((res) => {
        setShowDeletePopup(false);
        return NotificationManager.success("successfully deleted");
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };

  const updateLegacyAssetHandler = (assetid) => {
    MainService.getLegacyAssetInfo(username, assetid)
      .then((res) => {
        setAssetVerticalId(res.assetverticalId);
        setAssetTypeVal(res.assetType);
        setAssetIdVal(res.assetId);
        setAssetFriName(res.friendlyName);
        setModelIdVal(res.modelId);
        setCatgeryVal(res.category);
        setUdnVal(res.udn);
        setWhiteLabelVal(res.whitelabelcost);
        setSellingCostVal(res.sellingcost);
        setExAssetStatus(res.assetstatus);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setLegacyUpdatePopup(!getlegacyUpdatePopup);
  };

  const updateAssetHandler = () => {
    const formData = {
      status: updateAssetStatus,
    };
    const statusData = JSON.stringify(formData);

    MainService.assetStatusUpdate(assetIdVal, statusData)
      .then((res) => {
        setLegacyUpdatePopup(!getlegacyUpdatePopup);
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  /****************** End Of Map, UnMap, Update, Delete Asset Api's **********************/

  /*Create Asset */
  const cretaeAssetModel = () => {
    setCreateAssetPopup(!getCreateAssetPopup);
  };

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList(window.AppConfigData.EnterpriseId)
      .then((data) => {
        console.log("getAssetVerticalsIdList:",data);
        setVerticalsIdList(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  
  const findSiteTypeHandler = () => {
   
    let catname = getSiteData.filter((data) => {
      return data.sitetype === "Farm"
    });
    setSiteType(catname[0].sitetype);
  };

  const setSelectedVerticalsIdHandler = (e) => {
    getCategoryTypesList(e.target.value);
    setSelectedVerticalsId(e.target.value);
    setGrowReqAssetVerticalId(e.target.value);
    setSellReqAssetVerticalId(e.target.value);
    let catname = verticalsIdList.filter((data) => {
      return data.assetVerticalId === e.target.value;
    });
    setAssetVerticalFriendlyNameValue(catname[0].friendlyName);
  };

  const getCategoryTypesList = (assetVerticalId) => {
    MainService.getCategoryTypesList(assetVerticalId,window.AppConfigData.EnterpriseId)
      .then((data) => {
        setCategoryTypesList(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const setSelectedCategoryHandler = (e) => {
    getAssetTypesList(selectedVerticalsId, e.target.value);
    setSelectedCategory(e.target.value);
    setGrowReqCategory(e.target.value);
    setSellReqCategory(e.target.value);
  };

  const getAssetTypesList = (VerticalsId, Category) => {
    MainService.getAssetTypesList(VerticalsId, Category,window.AppConfigData.EnterpriseId)
      .then((data) => {
        setAssertTypesList(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const setSelectedAssertTypesHandler = (e) => {
    getModelsList(selectedVerticalsId, selectedCategory, e.target.value);
    setSelectedAssertTypes(e.target.value);
    setGrowReqLegacyAssetType(e.target.value);
    setSellReqLegacyAssetType(e.target.value);
    setVetVisitReqLegacyAssetType(e.target.value);
  };

  const getModelsList = (VerticalsId, Category, AssertTypes) => {
    MainService.getModelsList(VerticalsId, Category, AssertTypes,username)
      .then((data) => {
        setModelsList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const createAssetClick = () => {
    let createAssetData = {
      assetFriendlyName: legacyAssetFriendlyNameValue,
      assetVerticalId: selectedVerticalsId,
      categoryType: selectedCategory,
      legacyAssetType: selectedAssertTypes,
      assetDomain: assetDomainValue,
      siteId: getMappedAssetsSiteid,
      siteName: getMappedAssetsSiteName,
      lotId: lotNameValue,
      lotName: lotNameValue,
      manufacturerId: manufacturerIdValue,
      manufacturUrl: manufacturerUrlValue,
      modelId: selectedModel,
      modelName: selectedModel,
      modelType: selectedModel,
      modelUrl: modelUrlValue,
      serialNo: serialNoValue,
      udn: udnValue,
      upc: upcValue,
      motherBreed: motherBreedValue,
      fatherBreed: fatherBreedValue,
      description: descriptionValue,
      whitelableCost: whiteLabelCostValue,
      sellingcost: sellingCostValue,
      acquireCost: acquireCostValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      actorType: userTypeValue,
      consumerAdminId: parentId,
    };
    const legacyAssetData = JSON.stringify(createAssetData);
    MainService.createLegacyAsset(legacyAssetData, username, assetIdValue)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setCreateAssetPopup(!getCreateAssetPopup);
  };
  /*End Of Create Asset */

  /* Site Feedback */
  const labels = {
    1: "Very Poor",
    2: "Poor",
    3: "Fair",
    4: "Good",
    5: "Very Good",
  };

  const getLabelCustomerExperience = (getSiteCustomerExperience) => {
    return `${getSiteCustomerExperience} Star${
      getSiteCustomerExperience !== 1 ? "s" : ""
    }, 
      ${labels[getSiteCustomerExperience]}`;
  };

  const getLabelProduct = (getSiteProduct) => {
    return `${getSiteProduct} Star${getSiteProduct !== 1 ? "s" : ""}, 
      ${labels[getSiteProduct]}`;
  };

  const getLabelCustomerSupport = (getSiteCustomerSupport) => {
    return `${getSiteCustomerSupport} Star${
      getSiteCustomerSupport !== 1 ? "s" : ""
    }, 
      ${labels[getSiteCustomerSupport]}`;
  };

  const getLabelCustomerDelivery = (getSiteDelivery) => {
    return `${getSiteDelivery} Star${getSiteDelivery !== 1 ? "s" : ""}, 
      ${labels[getSiteDelivery]}`;
  };

  const getLabelServiceOthers = (getSiteServiceOthers) => {
    return `${getSiteServiceOthers} Star${
      getSiteServiceOthers !== 1 ? "s" : ""
    }, 
      ${labels[getSiteServiceOthers]}`;
  };

  const siteFeedback = (siteid) => {
    setSiteFeedbackSiteid(siteid);
    setShowSiteFeedbackModel(!showSiteFeedbackModel);
  };

  const siteFeedbackSubmit = () => {
    const sitefeedback = {
      siteid: getSiteFeedbackSiteid,
      "how_would_you_rate_your_overall_customer_experience?":
        getSiteFeedbackCustomerExperience,
      "how_satisfied_were_you_with_the_product?": getSiteFeedbackProduct,
      "how_satisfied_were_you_with_the_customer_support?":
        getSiteFeedbackCustomerSupport,
      "how_satisfied_were_you_with_the_timeliness_of_delivery?":
        getSiteFeedbackDelivery,
      "would_you_recommend_our_product_or_service_to_others?":
        getSiteFeedbackServiceOthers,
      remark: getSiteFeedbackRemark,
    };
    const feeback = JSON.stringify(sitefeedback);
    MainService.siteFeedbackDetails(username, feeback)
      .then((res) => {
        setShowSiteFeedbackModel(!showSiteFeedbackModel);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End Of Site Feedback */

  /* Site Feedback */
  const labelsAsset = {
    1: "Very Poor",
    2: "Poor",
    3: "Fair",
    4: "Good",
    5: "Very Good",
  };

  const getLabelAssetCustomerExperience = (getAssetCustomerExperience) => {
    return `${getAssetCustomerExperience} Star${
      getAssetCustomerExperience !== 1 ? "s" : ""
    }, 
      ${labels[getAssetCustomerExperience]}`;
  };

  const getLabelAssetProduct = (getAssetProduct) => {
    return `${getAssetProduct} Star${getAssetProduct !== 1 ? "s" : ""}, 
      ${labels[getAssetProduct]}`;
  };

  const getLabelAssetCustomerSupport = (getAssetCustomerSupport) => {
    return `${getAssetCustomerSupport} Star${
      getAssetCustomerSupport !== 1 ? "s" : ""
    }, 
      ${labels[getAssetCustomerSupport]}`;
  };

  const getLabelAssetCustomerDelivery = (getAssetDelivery) => {
    return `${getAssetDelivery} Star${getAssetDelivery !== 1 ? "s" : ""}, 
      ${labels[getAssetDelivery]}`;
  };

  const getLabelAssetServiceOthers = (getAssetServiceOthers) => {
    return `${getAssetServiceOthers} Star${
      getAssetServiceOthers !== 1 ? "s" : ""
    }, 
      ${labels[getAssetServiceOthers]}`;
  };

  const assetFeedback = (assetid) => {
    setAssetFeedbackAssetid(assetid);
    setShowAssetFeedbackModel(!showAssetFeedbackModel);
  };

  const assetFeedbackSubmit = () => {
    const assetfeedback = {
      assetdeveloperid: getAssetFeedbackAssetDeveloperid,
      assetid: getAssetFeedbackAssetid,
      "how_would_you_rate_your_overall_customer_experience?":
        getAssetFeedbackCustomerExperience,
      "how_satisfied_were_you_with_the_product?": getAssetFeedbackProduct,
      "how_satisfied_were_you_with_the_customer_support?":
        getAssetFeedbackCustomerSupport,
      "how_satisfied_were_you_with_the_timeliness_of_delivery?":
        getAssetFeedbackDelivery,
      "would_you_recommend_our_product_or_service_to_others?":
        getAssetFeedbackServiceOthers,
      remark: getSiteFeedbackRemark,
    };
    const data = JSON.stringify(assetfeedback);
    MainService.assetFeedbackDetails(username, data)
      .then((res) => {
        setShowAssetFeedbackModel(!showAssetFeedbackModel);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End Of Site Feedback */

  /* Site Issue */
  const createSiteIssue = (siteid) => {
    getShowSiteIssue(!showSiteIssue);
    setSiteIssueSiteId(siteid);
  };

  const siteIssueHandler = () => {
    const siteissue = {
      operatorid: window.AppConfigData.EnterpriseId,
      solutionid: getSiteIssueSolutionId,
      siteid: getSiteIssueSiteId,
      issuedescription: getSiteIssueDescription,
    };
    const data = JSON.stringify(siteissue);

    MainService.siteIssueCreate(username, data)
      .then((res) => {
        getShowSiteIssue(!showSiteIssue);
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const siteIssueDetails = (siteid) => {
    MainService.getSiteIssueData(username, siteid)
      .then((res) => {
        console.log("isssues site:",res);
        if(res.length>0)
        {
          
          setSiteIssueDetails(res);
        }
        else{
          setSiteIssueDetails([]);
        }
        
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    getShowSiteIssueDetailsModel(!showSiteIssueDetailsModel);
  };
  /* End Of Site Issue */

  /* End Of Asset Issue */
  const assetIssueModelHandler = (assetid) => {
    setAssetIssueAssetId(assetid);
    getShowAssetIssue(!showAssetIssue);
  };

  const assetIssueHandler = () => {
    var assetissue = {
      operatorid: getAssetIssueSolutionId,
      solutionid: window.AppConfigData.EnterpriseId,
      siteid: getAssetIssueSiteId,
      assetid: getAssetIssueAssetId,
      issuedescription: getAssetIssueDescription,
    };
    var data = JSON.stringify(assetissue);
    MainService.assetIssueCreate(username, data)
      .then((res) => {
        getShowAssetIssue(!showAssetIssue);
        viewMappedAssets(
          getMappedAssetsSiteid,
          getMappedAssetsType,
          getMappedAssetsSiteName
        );
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const assetIssueDetailsModel = (assetid) => {
    MainService.getAssetIssueData(getAssetIssueSiteId, assetid)
      .then((res) => {
        setAssetIssueDetails(res.issues);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    getShowAssetIssueDetailsModel(!showAssetIssueDetailsModel);
  };
  /* End Of Asset Issue */

  /*Photo Upload */
  const uploadPhotoClick = () => {
    setShowUploadPhotoPopup(!uploadPhotoPopup);
  };
  const submitUploadPhotoClick = () => {
    if(uploadPhotoFile)
    {
      const uploadPhotoData = new FormData();
      uploadPhotoData.append("file", uploadPhotoFile);
      uploadPhotoData.append("mimetype", uploadPhotoFile.type);
      uploadPhotoData.append("categoryid", selectedCategoryId);
      uploadPhotoData.append("categoryname", selectedCategoryName);
      //uploadPhotoData.append("contentsubcategory", subCategoryNameValue);
      uploadPhotoData.append("contentname", uploadPhotoNameValue);
      uploadPhotoData.append("assetdeveloperid", parentId);
      uploadPhotoData.append("contentdescription", descriptionValue);
      uploadPhotoData.append("contentcreator", username);
      uploadPhotoData.append("contentformat", "DOC");
      uploadPhotoData.append("price", priceValue);
      uploadPhotoData.append("filedescription", uploadPhotoFileDescription);
      uploadPhotoData.append("contentownerid", username);
      uploadPhotoData.append("unit", "INR");
      uploadPhotoData.append("discountedprice", discountValue);
      uploadPhotoData.append("expirydate", expiryDateValue);
      uploadPhotoData.append("event", "none");
      console.log("create uploadPhoto data:", uploadPhotoData);
      MainService.createContent(uploadPhotoData, parentId)
        .then((res) => {
          return NotificationManager.success("UploadPhoto uploaded");
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }else{
      return NotificationManager.error("please choose file");
    }
    
    setShowUploadPhotoPopup(!uploadPhotoPopup);
  };

  const closeUploadPhotoModal = (
    <span
      onClick={() => {
        setShowUploadPhotoPopup(!uploadPhotoPopup);
      }}
    >
      X
    </span>
  );
  const titleUploadPhoto = "Upload Photo";
  const uploadPhotoBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryName">Category Name :</InputLabel>
        <Input
          disabled
          id="categoryName"
          name="categoryName"
          type="text"
          value={selectedCategoryName.toUpperCase()}
        />
      </FormControl>
      

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="uploadPhotoName">UploadPhoto Name :</InputLabel>
        <Input
          id="uploadPhotoName"
          name="uploadPhotoName"
          type="text"
          value={uploadPhotoNameValue}
          onChange={(e) => {
            setUploadPhotoNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel htmlFor="contentOwner">Content Owner</InputLabel>
        <Input
          disabled
          id="contentOwner"
          name="contentOwner"
          type="text"
          value={username}
        ></Input>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="contentCreator"> Content Creator:</InputLabel>
        <Input
          disabled
          id="contentCreator"
          name="contentCreator"
          type="text"
          value={username}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="zone"> Content Approver:</InputLabel>
        <Input
          disabled
          id="contentApprover"
          name="contentApprover"
          type="text"
          value={parentId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="doc">file:</InputLabel>
        <Input
          id="doc"
          name="doc"
          type="file"
          onChange={(e) => {
            setUploadPhotoFile(e.target.files[0]);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="description"> Description:</InputLabel>
        <Input
          id="description"
          name="description"
          type="text"
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>
     
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={() => {
            submitUploadPhotoClick();
          }}
        >
          Submit
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowUploadPhotoPopup(!uploadPhotoPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* end of photo upload */

  /* Daily Reports Api's */
  const dailyReportsHandler = (siteid, Type) => {
    setAddDailyReportAssetid(siteid);
    setBackToSites(Type);
    siteAssetsDailyReports(siteid);
    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(false);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(true);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(false);
  };

  const siteAssetsDailyReports = (id) => {
    setIsLoading(true);
    MainService.dailyReportDetails(username,id)
      .then((res) => {
        if(res.length>0)
        {
          setIsLoading(false);
          setDailyReports(res);
        }else{
          setIsLoading(false);
          setDailyReports([]);
        }
        
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const createDailyReportHandler = () => {
    const report = {
      parentid: window.AppConfigData.EnterpriseId,
      userid: username,
      type: "Asset Developer",
      assetid: getAddDailyReportAssetid,
      nutritionRecorded: getAddDailyReportNutrition,
      attendanceRecorded: getAddDailyReportAttendance,
      photoUploaded: getAddDailyReportPhoto,
    };
    const data = JSON.stringify(report);
    MainService.createDailyReport(data)
      .then((res) => {
        siteAssetsDailyReports(username,getAddDailyReportAssetid);
        getShowAddDailyReportModel(!showAddDailyReportModel);
        return NotificationManager.success("daily report submitted");
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };
  /* End Of Daily Reports Api's */

  /* Site Alarms Api's */
  const siteAlarmsClick = (siteid, Type) => {
    siteAlarms(siteid);
    setBackToSites(Type);
    siteAssetsDailyReports(siteid);
    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(false);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(false);
    setGridViewSiteAlarms(true);
  };

  const siteAlarms = (siteid) => {
    setIsLoading(true);
    MainService.getSiteAlarms(parentId,siteid)
      .then((res) => {
        setSiteAlarmsList(res);

      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const submitSitealarmClick = () => {
    let alarmData = {
      source: parentId,
      assetverticalid: selectedVerticalsId,
      assetverticalname: selectedVerticalName,
      category: selectedCategory,
      legacyassettype: selectedAssertTypes,
      modelid: selectedModel,
      siteid: resourceId,
      alarmcategory: alarmCategoryValue,
      alarmname: alarmNameValue,
    };

    const alarmDataStringify = JSON.stringify(alarmData);
    MainService.addSiteAlarm(parentId, alarmDataStringify)
      .then((res) => {
        NotificationManager.success(res);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });

    setSiteAlarmPopup(!siteAlarmPopup);
  };
  /* End Of Site Alarms Api's */

  /* Site Group Nutrition  Api's */
  const groupNutritionHandler = (siteid, Type, sitename) => {
    setAddGroupNutritionSiteid(siteid);
    setAddGroupNutritionFriendlyname(sitename);
    groupNutritionDetails();
    setBackToSites(Type);
    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(false);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(true);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(false);
  };

  const groupNutritionDetails = () => {
    MainService.getGroupNutritionData(username)
      .then((res) => {
        setGroupNutritionDetails(res.nutritions);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const createGroupNutritionHandler = () => {
    const groupnutrition = {
      assetid: getAddGroupNutritionSiteid,
      assetfriendlyname: getAddGroupNutritionFriendlyname,
      parentid: window.AppConfigData.EnterpriseId,
      userid: getAddGroupNutritionUserid,
      feedtype: getAddGroupNutritionFeedType,
      schedule: getAddGroupNutritionSchedule,
      latitude: getAddGroupNutritionLatitude,
      longitude: getAddGroupNutritionLongitude,
      altitude: getAddGroupNutritionAltitude,
      description: getAddGroupNutritionDescription,
      assettype: getAddGroupNutritionSiteType,
    };
    const data = JSON.stringify(groupnutrition);
    MainService.addNutritionData(data)
      .then((res) => {
        setShowAddGroupNutritionModel(!showAddGroupNutritionModel);
        groupNutritionDetails();
        return NotificationManager.success("nutrition added");
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };

  const deleteAddedGroupNutrition = (assetid) => {
    setShowDeleteGroupNutritionModel(!showDeleteGroupNutritionModel);
    setDeleteGroupNutritionSiteid(assetid);
  };

  const groupNutritionDeleteHandler = () => {
    MainService.deleteGroupNutrition(getDeleteGroupNutritionSiteid)
      .then((res) => {
        setShowDeleteGroupNutritionModel(!showDeleteGroupNutritionModel);
        groupNutritionDetails();
        return NotificationManager.success("");
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };

  const updateAddedGroupNutrition = (nutrition) => {
    setShowUpdateGroupNutritionModel(!showUpdateGroupNutritionModel);
    setUpdateGroupNutritionSiteid(nutrition.assetid);
    setUpdateGroupNutritionFriendlyname(nutrition.assetfriendlyname);
    setUpdateGroupNutritionUserid(nutrition.userid);
    prevsetUpdateGroupNutritionFeedType(nutrition.feedtype);
    prevsetUpdateGroupNutritionSchedule(nutrition.schedule);
    setUpdateGroupNutritionLatitude(nutrition.latitude);
    setUpdateGroupNutritionLongitude(nutrition.longitude);
    setUpdateGroupNutritionAltitude(nutrition.altitude);
    setUpdateGroupNutritionDescription(nutrition.description);
  };

  const updateGroupNutritionHandler = () => {
    const updategroupnutrition = {
      assetid: getUpdateGroupNutritionSiteid,
      assetfriendlyname: getUpdateGroupNutritionFriendlyname,
      feedtype: getUpdateGroupNutritionFeedType,
      schedule: getUpdateGroupNutritionSchedule,
      latitude: getUpdateGroupNutritionLatitude,
      longitude: getUpdateGroupNutritionLongitude,
      altitude: getUpdateGroupNutritionAltitude,
      description: getUpdateGroupNutritionDescription,
    };
    const data = JSON.stringify(updategroupnutrition);
    MainService.updateGroupNutrition(data)
      .then((res) => {
        setShowUpdateGroupNutritionModel(!showUpdateGroupNutritionModel);
        groupNutritionDetails();
        return NotificationManager.success("nutrition info updated");
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };
  /* End Of Site Group Nutrition Api's */

  /* Site Attendance Location Api's*/

  const siteattendanceLocationButtonClickHandler = (siteid, Type, sitename) => {
    setSiteNewAttendanceLocationAssetid(siteid);
    setSiteNewAttendanceLocationAssetfriendlyName(sitename);
    siteattendanceLocationHistoryHandler();
    setBackToSites(Type);
    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(false);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(true);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(false);
  };

  const siteattendanceLocationHistoryHandler = () => {
    setIsLoading(true);
    MainService.siteAttendanceLocationHistory(username)
      .then((res) => {
        setIsLoading(false);
        setSiteAttendanceLocationHistory(res.attendanceLocations);
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const addSiteAttendanceLocationHandler = () => {
    const attendanceLocation = {
      assetid: getSiteNewAttendanceLocationAssetid,
      assetfriendlyname: getSiteNewAttendanceLocationAssetfriendlyName,
      parentid: window.AppConfigData.EnterpriseId,
      userid: getSiteNewAttendanceLocationUserid,
      latitude: getSiteNewAttendanceLocationLatitude,
      longitude: getSiteNewAttendanceLocationLongitude,
      altitude: getSiteNewAttendanceLocationAltitude,
      description: getSiteNewAttendanceLocationDescription,
    };
    const newattendanceLocation = JSON.stringify(attendanceLocation);

    MainService.siteNewAttendanceLocation(newattendanceLocation)
      .then((res) => {
        setSiteNewAttendanceLocationPopup(false);
        siteattendanceLocationHistoryHandler();
        NotificationManager.success("attendance location added");
      })
      .catch((err) => {
        NotificationManager.error(err);
      });
  };

  const deleteSiteAttendanceLocationsAssetId = (siteid) => {
    setSiteDeletedAttendanceAssetid(siteid);
    setSiteDeletedAttendancePopup(!getSiteDeletedAttendancePopup);
  };

  const deleteSiteAttendanceAsset = () => {
    MainService.siteDeleteNewAttendanceLocation(getSiteDeletedAttendanceAssetid)
      .then((res) => {
        setSiteDeletedAttendancePopup(false);
        siteattendanceLocationHistoryHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateSiteAttendanceLocationsAssetInfo = (siteinfo) => {
    setSiteUpdateAttendanceLocationInfoAssetid(siteinfo.assetid);
    setSiteUpdateAttendanceLocationInfoAssetfriendlyName(
      siteinfo.assetfriendlyname
    );
    setSiteUpdateAttendanceLocationInfoUserid(siteinfo.userid);
    setSiteUpdateAttendanceLocationInfoLatitude(siteinfo.latitude);
    setSiteUpdateAttendanceLocationInfoLongitude(siteinfo.longitude);
    setSiteUpdateAttendanceLocationInfoAltitude(siteinfo.altitude);
    setSiteUpdateAttendanceLocationInfoDescription(siteinfo.description);
    setSiteUpdateAttendaneLocationPopup(!showSiteUpdateAttendaneLocationPopup);
  };

  const updateSiteAttendanceLocationsHandler = () => {
    const updateAttendanceAsset = {
      assetid: getSiteUpdateAttendanceLocationInfoAssetid,
      assetfriendlyname: getSiteUpdateAttendanceLocationInfoAssetfriendlyName,
      latitude: getSiteUpdateAttendanceLocationInfoLatitude,
      longitude: getSiteUpdateAttendanceLocationInfoLongitude,
      altitude: getSiteUpdateAttendanceLocationInfoAltitude,
      description: getSiteUpdateAttendanceLocationInfoDescription,
    };
    const updateAttendance = JSON.stringify(updateAttendanceAsset);

    MainService.siteUpdateAttendanceLocation(updateAttendance)
      .then((res) => {
        setSiteUpdateAttendaneLocationPopup(false);
        siteattendanceLocationHistoryHandler();
        return NotificationManager.success("attendance location updated");
      })
      .catch((err) => {
        return NotificationManager.error("Something Went Wrong");
      });
  };

  /* End Of Site Attendance Location Api's*/

  /* Grow Request Api's*/

  const siteGrowRequestButtonClickHandler = (siteid, Type, sitename) => {
    setGrowSiteID(siteid);
    growRequestHandler(siteid);
    setBackToSites(Type);
    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(false);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(true);
    setViewSell(false);
    setViewLoan(false);
  };

  const growRequestHandler = () => {
    setIsLoading(true);
    MainService.siteGrowRequests(username)
      .then((res) => {
        setIsLoading(false);
        setGrowReq(res.growrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const createGrowRequest = () => {
    const growrequest = {
      source: getGrowReqSource,
      destination: window.AppConfigData.EnterpriseId,
      assetverticalid: getGrowReqAssetVerticalId,
      category: getGrowReqCategory,
      legacyassettype: getGrowReqLegacyAssetType,
      modelid: getGrowReqModelid,
      phone: getGrowReqPhone,
      email: getGrowReqEmail,
      city: getGrowReqCity,
      state: getGrowReqState,
      address: getGrowReqAddress?.replace(/\n/g, ''),
      lat: getGrowReqLatitude,
      longi: getGrowReqLongitude,
      quantity: getGrowReqQuantity,
    };
    const data = JSON.stringify(growrequest);
    MainService.addGrowRequest(data)
      .then((res) => {
        setShowAddGrowReqModel(!showAddGrowReqModel);
        growRequestHandler();
        return NotificationManager.success(res.requestid);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateGrowRequestModelHandler = (requestid, status) => {
    getGrowId(requestid);
    getGrowPreStatus(status);
    getGrowUpdateRequestModel(!setGrowUpdateRequestModel);
  };

  const growReqStatusHandler = () => {
    var growstatus = {
      requestid: getGrowId,
      existingGrowRequestStatus: getGrowPreStatus,
      updatedGrowRequestStatus: getGrowPostStatus,
    };
    var data = JSON.stringify(growstatus);
    MainService.siteGrowReqStatus(data)
      .then((res) => {
        setGrowUpdateRequestModel(!getGrowUpdateRequestModel);
        growRequestHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const cancelGrowRequestModelHandler = (requestid, status) => {
    setCancelGrowId(requestid);
    setCancelGrowPreStatus(status);
    setCancelGrowUpdateRequestModel(!getCancelGrowUpdateRequestModel);
  };

  const cancelGrowRequest = () => {
    var cancelgrow = {
      requestid: getCancelGrowId,
      existingRequestStatus: getCancelGrowPreStatus,
    };
    var data = JSON.stringify(cancelgrow);
    MainService.siteCancelGrowReqStatus(username, data)
      .then((res) => {
        setCancelGrowUpdateRequestModel(!getCancelGrowUpdateRequestModel);
        growRequestHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End Of Grow Request Api's*/

  /* Sell Request Api's*/
  const siteSellRequestButtonClickHandler = (siteid, Type, sitename) => {
    setSellSiteID(siteid);
    sellRequestHandler();
    setBackToSites(Type);
    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(false);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(true);
    setViewLoan(false);
  };

  const sellRequestHandler = () => {
    setIsLoading(true);
    MainService.siteSellRequests(username)
      .then((res) => {
        setIsLoading(false);
        setSellReq(res.sellrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const createSellRequest = () => {
    var sellrequest = {
      source: getSellReqSource,
      destination: parentId,
      assetverticalid: getSellReqAssetVerticalId,
      category: getSellReqCategory,
      legacyassettype: getSellReqLegacyAssetType,
      modelid: getSellReqModelid,
      phone: getSellReqPhone,
      email: getSellReqEmail,
      city: getSellReqCity,
      state: getSellReqState,
      address: getSellReqAddress?.replace(/\n/g, ''),
      lat: getSellReqLatitude,
      longi: getSellReqLongitude,
      quantity: getSellReqQuantity,
      totalsellingamount: getSellReqCost,
    };
    var data = JSON.stringify(sellrequest);

    MainService.addSellRequest(data)
      .then((res) => {
        setShowAddSellReqModel(!showAddSellReqModel);
        sellRequestHandler();
        return NotificationManager.success(res.requestid);
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };

  const updateSellRequestModelHandler = (requestid, status) => {
    setSellId(requestid);
    setSellPreStatus(status);
    setSellUpdateRequestModel(!getSellUpdateRequestModel);
  };

  const sellReqStatusHandler = () => {
    var sellstatus = {
      requestid: getSellId,
      existingSellRequestStatus: getSellPreStatus,
      updatedSellRequestStatus: getSellPostStatus,
    };
    var data = JSON.stringify(sellstatus);
    MainService.siteSellReqStatus(data).then((res) => {
      setSellUpdateRequestModel(!getSellUpdateRequestModel);
      sellRequestHandler();
      return NotificationManager.success(res);
    });
  };

  const cancelSellRequestModelHandler = (requestid, status) => {
    setCancelSellId(requestid);
    setCancelSellPreStatus(status);
    setCancelSellUpdateRequestModel(!getCancelSellUpdateRequestModel);
  };

  const cancelSellRequest = () => {
    var cancelsell = {
      requestid: getCancelSellId,
      existingRequestStatus: getCancelSellPreStatus,
    };
    var data = JSON.stringify(cancelsell);
    MainService.siteCancelSellReqStatus(username, data)
      .then((res) => {
        setCancelSellUpdateRequestModel(!getCancelSellUpdateRequestModel);
        sellRequestHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End Of Sell Request Api's*/

  /* Loan Request Api's*/
  const siteLoanRequestButtonClickHandler = (siteid, Type, sitename) => {
    loanRequestHandler();
    setBackToSites(Type);
    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(false);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(true);
  };
  const createLoanRequest = () => {
    var sellrequest = {
      source: username,
      destination: parentId,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: selectedCategory,
      modelid: getLoanReqModelid,
      phone: getLoanReqPhone,
      email: getLoanReqEmail,
      city: getLoanReqCity,
      state: getLoanReqState,
      address: getLoanReqAddress?.replace(/\n/g, ''),
      lat: getLoanReqLatitude,
      longi: getLoanReqLongitude,
      quantity: getLoanReqQuantity,
      totalsellingamount: getLoanReqCost,
    };
    var data = JSON.stringify(sellrequest);

    MainService.addLoanRequest(data)
      .then((res) => {
        setShowAddLoanReqModel(!showAddLoanReqModel);
        sellRequestHandler();
        return NotificationManager.success(res.requestid);
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };
  const loanRequestHandler = () => {
    setIsLoading(true);
    MainService.siteLoanRequests(username)
      .then((res) => {
        setIsLoading(false);
        setLoanReq(res.loanrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateLoanRequestModelHandler = (requestid, status) => {
    setLoanId(requestid);
    setLoanPreStatus(status);
    setLoanUpdateRequestModel(!getLoanUpdateRequestModel);
  };

  const loanReqStatusHandler = () => {
    var loanstatus = {
      requestid: getLoanId,
      existingLoanRequestStatus: getLoanPreStatus,
      updatedLoanRequestStatus: getLoanPostStatus,
    };
    var data = JSON.stringify(loanstatus);
    MainService.siteLoanReqStatus(data).then((res) => {
      setLoanUpdateRequestModel(!getLoanUpdateRequestModel);
      loanRequestHandler();
      return NotificationManager.success(res);
    });
  };

  const cancelLoanRequestModelHandler = (requestid, status) => {
    setCancelLoanId(requestid);
    setCancelLoanPreStatus(status);
    setCancelLoanUpdateRequestModel(!setCancelLoanUpdateRequestModel);
  };

  const cancelLoanRequest = () => {
    var cancelloan = {
      requestid: getCancelLoanId,
      existingRequestStatus: getCancelLoanPreStatus,
    };
    var data = JSON.stringify(cancelloan);
    MainService.siteCancelLoanReqStatus(username, data)
      .then((res) => {
        setCancelLoanUpdateRequestModel(!getCancelLoanUpdateRequestModel);
        loanRequestHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End Of Loan Request Api's*/

  /* VET Visit Request Api's*/
  const siteVetVisitRequestButtonClickHandler = (siteid, Type, sitename) => {
    vetVisitRequestHandler();
    setBackToSites(Type);
    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(false);
    setMortality(false);

    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setAssetMonitoringView(false);
    setShowSiteRating(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(false);
    setViewVetVisit(true);
  };

  const createVetRequest = () => {
    var sellrequest = {
      source: username,
      destination: parentId,
      assetverticalid: selectedVerticalsId,
      category: selectedCategory,
      legacyassettype: getVetVisitReqLegacyAssetType,
      modelid: getVetVisitReqModelid,
      phone: getVetVisitReqPhone,
      email: getVetVisitReqEmail,
      city: getVetVisitReqCity,
      state: getVetVisitReqState,
      address: getVetVisitReqAddress?.replace(/\n/g, ''),
      lat: getVetVisitReqLatitude,
      longi: getVetVisitReqLongitude,
      quantity: getVetVisitReqQuantity,
      totalsellingamount: getVetVisitReqCost,
    };
    var data = JSON.stringify(sellrequest);

    MainService.addVetRequest(data)
      .then((res) => {
        setShowAddVetVisitReqModel(!showAddVetVisitReqModel);
        sellRequestHandler();
        return NotificationManager.success(res.requestid);
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };
  const vetVisitRequestHandler = () => {
    setIsLoading(true);
    MainService.siteVetVisitRequests(username)
      .then((res) => {
        console.log("siteVetVisitRequests", res);
        setIsLoading(false);
        setVetReq(res.vetvisitrequests);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const updateVetVisitRequestModelHandler = (requestid, status) => {};
  const vetVisitRequestModelHandler = (requestid, status) => {
    setLoanId(requestid);
    setLoanPreStatus(status);
    setLoanUpdateRequestModel(!getLoanUpdateRequestModel);
  };

  const vetVisitReqStatusHandler = () => {
    var loanstatus = {
      requestid: getLoanId,
      existingLoanRequestStatus: getLoanPreStatus,
      updatedLoanRequestStatus: getLoanPostStatus,
    };
    var data = JSON.stringify(loanstatus);
    MainService.siteVetReqStatus(data).then((res) => {
      setLoanUpdateRequestModel(!getLoanUpdateRequestModel);
      loanRequestHandler();
      return NotificationManager.success(res);
    });
  };

  const cancelVetVisitRequestModelHandler = (requestid, status) => {
    setCancelLoanId(requestid);
    setCancelLoanPreStatus(status);
    setCancelLoanUpdateRequestModel(!getCancelLoanUpdateRequestModel);
  };

  const cancelVetVisitRequest = () => {
    var cancelloan = {
      requestid: getCancelLoanId,
      existingRequestStatus: getCancelLoanPreStatus,
    };
    var data = JSON.stringify(cancelloan);
    MainService.siteCancelVetReqStatus(username, data)
      .then((res) => {
        setCancelLoanUpdateRequestModel(!getCancelLoanUpdateRequestModel);
        loanRequestHandler();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  /* End Of VET Visit Api's*/
  /* QR Code */
  const submitGenerateQr = (site) => {
   
      let qrBody = {
        iwidth: qrSizeValue,
        fcolor: fgcolor,
        bcolor: bgcolor,
        cadminid: parentId,
        assetverticalid: assetVerticalIdForQr,
        sitetype: site.sitetype,
        bname: username,
        siteid: site.siteid,
        url:""
      };

      const qrBodyStringify = JSON.stringify(qrBody);
      MainService.generateSiteQrCode(qrBodyStringify)
        .then((data) => {
          getAllSitesData("Grid");
          return NotificationManager.success("QR Code generated successfully")
        })
        .catch((err) => {
          console.error(err);
          return NotificationManager.error(err);
        });
    
  };

  /* Asset Live Monitoring */

  const assetMonitoringHandler = (siteId,assetId,siteName) => {
    setSelectedAssetId(assetId);
    setSelectedSiteName(siteName);
    setSelectedSiteId(siteId);

    setAssetMonitoringView(true);
  
    setViewallListSites(false);
    setViewallGridSites(false);
    setViewallMapSites(false);
    setShowSiteRating(false);
    setMappedAssetShow(false);
    setAssetList(false);
    setAssetGrid(false);
    setAssetMap(false);

    setBirth(false);
    setAttendanceLocation(false);
    setHealthRecords(false);
    setNutrition(false);
    setParturition(false);
    setMortality(false);

    setShowDailyReports(false);
    setShowGropuNutritionView(false);
    setSiteAttendanceLocation(false);
    setViewGrow(false);
    setViewSell(false);
    setViewLoan(false);
    MainService.assetCurrentLocation(parentId,username, assetId)
    .then((res) => {
      console.log("current Location monitor:", res);
      setSelectedAssetId(res.assetId);
      setAssetMonitoringStatus(res.status);
      setMarkers([
        ...markers,
        { geoLocation: res.geoLocation },
      ]);
      setAssetMonitorTimestamp(res.ts);

      if (marker_position !== undefined) {
        const ENDPOINT = window.socketUrl;
        const socket = socketIOClient(ENDPOINT);
        socket.on(`tt/solution/${username}/site/${siteId}/asset/${assetId}/LOC`, (data) => {
          console.log(JSON.parse(data.payload).geoLocation);
          setMarkers([
            ...markers,
            { geoLocation: JSON.parse(data.payload).geoLocation },
          ]);
        });
        return () => {
          socket.disconnect();
        };
      }
    })
    .catch((e) => {
      return NotificationManager.error("location not found");
    });
  };

  

  const getLocationHandler =(props ) =>{
    console.log(props)
  }

  /* const AssetLiveGoogleMap = withGoogleMap(() => {
    return marker_position.lat === 0 && marker_position.lng === 0 ? (
      <GoogleMap
        defaultZoom={2}
        defaultCenter={marker_position}
        center={marker_position}
      >
        <Marker>
          <InfoWindow options={{ disableAutoPan: true }}>
            <div>{selectedAssetId}</div>
          </InfoWindow>
        </Marker>
      </GoogleMap>
    ) : (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={marker_position}
        center={marker_position}
      >
        <Marker
          position={{
            lat: parseFloat(marker_position.lat),
            lng: parseFloat(marker_position.lng),
          }}
        >
          <InfoWindow options={{ disableAutoPan: true }}>
            <div>{selectedAssetId}</div>
          </InfoWindow>
        </Marker>

        {Object.entries(assetPostionList).map(([key, value]) => {
          return value ? (
            <Marker position={{ lat: value.lat, lng: value.lng }}>
              <InfoWindow options={{ disableAutoPan: false }}>
                <div>{selectedAssetId}</div>
              </InfoWindow>
            </Marker>
          ) : (
            <Marker
              position={{
                lat: parseFloat(marker_position.lat),
                lng: parseFloat(marker_position.lng),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <div>{selectedAssetId}</div>
              </InfoWindow>
            </Marker>
          );
        })}
      </GoogleMap>
    );
  }); */

  /* End of Asset Monitoring */
  

  
  /* End of QR Code */
  return (
    <React.Fragment>
      <div className="container" id="mainContentList">
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                getAllSitesData("Grid");
              }}
            ></Button>
            <Button
              startIcon={<MapIcon />}
              size="large"
              onClick={() => {
                getAllSitesData("Map");
              }}
            ></Button>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                getAllSitesData("List");
              }}
            ></Button>
            
          </div>
        </div>
        <div className="MuiGrid-root">
          {viewAllListSites === true ? (
            <Box sx={{ width: "100%", marginTop: "10px" }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Site"
                className={classes.fab}
              >
                <AddIcon onClick={() => createSiteHandler()}></AddIcon>
              </Fab>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              {getAllSites.length > 0 ? (
                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: 430 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow align="center">
                          <TableCell align="center" colSpan={30}>
                            VIEW SITE LIST
                          </TableCell>
                        </TableRow>
                        <TableRow align="center">
                          <TableCell align="center" colSpan={1}>
                            Site ID
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Site Name
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Site Type
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            QR Code
                          </TableCell>
                          <TableCell align="center" colSpan={12}>
                            View
                          </TableCell>
                          <TableCell align="center" colSpan={7}>
                            Operate
                          </TableCell>
                          <TableCell align="center" colSpan={5}>
                            Action
                          </TableCell>
                          <TableCell align="center" colSpan={3}>
                            QR Code
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getAllSites
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((site, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="center">
                                  {site.siteid}
                                </TableCell>
                                <TableCell align="center">
                                  {site.sitename}
                                </TableCell>
                                <TableCell align="center">
                                  {site.sitetype}
                                </TableCell>
                                <TableCell><img src={site.siteqrcodelink} alt="img"></img></TableCell>
                                <TableCell/>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      viewMappedAssets(
                                        site.siteid,
                                        "Map",
                                        site.sitename
                                      )
                                    }
                                  >
                                    <MapIcon titleAccess="Assets Map View" />
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      viewMappedAssets(
                                        site.siteid,
                                        "Grid",
                                        site.sitename
                                      )
                                    }
                                  >
                                    <GridIcon titleAccess="Assets Grid View" />
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      viewMappedAssets(
                                        site.siteid,
                                        "List",
                                        site.sitename
                                      )
                                    }
                                  >
                                    <ListIcon titleAccess="Assets List View" />
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      siteInfoPopUpHandler(site.siteid)
                                    }
                                  >
                                    Site Info
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      siteInfoPopUpHandler(site.siteid)
                                    }
                                  >
                                    Node Info
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button size="small" variant="contained">
                                    Site Env Monitoring
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      siteRatingsHandler(site.siteid)
                                    }
                                  >
                                    Site Rating
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      updateSiteUpdateClick(site.siteid)
                                    }
                                  >
                                    Site Update
                                  </Button>
                                </TableCell>
                                {/* <TableCell align="center">
                                  <Button size="small" variant="contained">
                                    Site Health Status
                                  </Button>
                                </TableCell> */}
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      deleteSiteHandler(
                                        site.siteid,
                                        site.sitename
                                      )
                                    }
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      siteIssueDetails(site.siteid);
                                    }}
                                  >
                                    Site Issue Details
                                  </Button>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell align="center">
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      siteFeedback(site.siteid);
                                    }}
                                  >
                                    Site Feedback
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      createSiteIssue(site.siteid);
                                    }}
                                  >
                                    Site Issue
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    style={{
                                      marginRight: "10px",
                                      width: "45%",
                                    }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      dailyReportsHandler(site.siteid, "List");
                                    }}
                                  >
                                    Daily Report
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button size="small" variant="contained">
                                    Site Alarms
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      groupNutritionHandler(
                                        site.siteid,
                                        "Grid",
                                        site.sitename
                                      );
                                    }}
                                  >
                                    Group Nutrition
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      siteattendanceLocationButtonClickHandler(
                                        site.siteid,
                                        "Grid",
                                        site.sitename
                                      )
                                    }
                                  >
                                    Site Attendance
                                  </Button>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell align="center">
                                  <Button size="small" variant="contained">
                                    Grow Request
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button size="small" variant="contained">
                                    Sell Request
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button size="small" variant="contained">
                                    Loan Request
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button size="small" variant="contained">
                                    VET Visit Request
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                {site.siteqrcodelink !== ""  || site.siteqrcodelink !== "null" ? (
                                <div>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="registerBtn"
                                  >
                                    <a href={`${site.siteqrcodelink}`} target="_blank" download> 
                                            Download QR 
                                          </a>
                                  </Button>
                                </div>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="registerBtn"
                                  type="submit"
                                  onClick={(e) => submitGenerateQr(site)}
                                >
                                  Generate
                                </Button>
                              )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={getAllSites.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              ) : (
                <h4>No View Sites List</h4>
              )}
            </Box>
          ) : null}

          {viewAllGridSites === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <Box>
                  <Fab
                    color="primary"
                    size={"large"}
                    aria-label="add"
                    title="Node&Site Creation"
                    className={classes.fab}
                  >
                    <AddIcon onClick={() => createSiteHandler()}></AddIcon>
                  </Fab>
                  <Fab
                    style={{ marginBottom: "60px" }}
                    color="primary"
                    size={"large"}
                    aria-label="add"
                    title="Site Creation With Node"
                    className={classes.fab}
                  >
                    <AddIcon onClick={() => createSitWithNodeId()}></AddIcon>
                  </Fab>
                  <SearchBar
                    value={farmgridsearched}
                    onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                    onCancelSearch={() => cancelFarmGridSearch()}
                  />
                  <div className="cardStyleForDashboardItems">
                    <h4 style={{ width: "100%", textAlign: "center" }}>
                      View Site List
                    </h4>
                    {getAllSites.length > 0 ? (
                      <div className="cardStyleForDashboardItems">
                        {getAllSites.map((sites, index) => (
                          <div
                            key={index}
                            className="cardStyleForDashboardItemsBody"
                          >
                            <span>
                              <p>
                                <b>Site ID:</b>
                                {sites.siteid}
                              </p>
                              <p>
                                <b>Site Name:</b>
                                {sites.sitename}
                              </p>
                              <p>
                                <b>Site Type:</b>
                                {sites.sitetype}
                              </p>
                              <img src={sites.siteqrcodelink} alt="img"></img>
                              
                              {sites.siteqrcodelink !== ""  && sites.siteqrcodelink !== "null"? (
                                <div>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="registerBtn"
                                  >
                                    <a href={`${sites.siteqrcodelink}`} target="_blank" download> 
                                            Download QR 
                                          </a>
                                  </Button>
                                </div>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="registerBtn"
                                  type="submit"
                                  onClick={(e) => submitGenerateQr(sites)}
                                >
                                  Generate
                                </Button>
                              )}
                              <h3 className="cardactiontitle">View</h3>
                              <div style={{ width: "100%", margin: "5px" }}>
                                <Button
                                  style={{ marginRight: "10px", width: "30%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    viewMappedAssets(
                                      sites.siteid,
                                      "Map",
                                      sites.sitename
                                    )
                                  }
                                >
                                  <MapIcon titleAccess="Assets Map View" />
                                </Button>
                                <Button
                                  style={{ marginRight: "10px", width: "30%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    viewMappedAssets(
                                      sites.siteid,
                                      "Grid",
                                      sites.sitename
                                    )
                                  }
                                >
                                  <GridIcon titleAccess="Assets Grid View" />
                                </Button>
                                <Button
                                  style={{ marginRight: "10px", width: "30%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    viewMappedAssets(
                                      sites.siteid,
                                      "List",
                                      sites.sitename
                                    )
                                  }
                                >
                                  <ListIcon titleAccess="Assets List View" />
                                </Button>
                              </div>
                              <div style={{ width: "100%", margin: "5px" }}>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    siteInfoPopUpHandler(sites.siteid)
                                  }
                                >
                                  Site Info
                                </Button>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    siteInfoPopUpHandler(sites.siteid)
                                  }
                                >
                                  Node Info
                                </Button>
                              </div>
                              <div style={{ width: "100%", margin: "5px" }}>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    siteRatingsHandler(sites.siteid)
                                  }
                                >
                                  Site Rating
                                </Button>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    updateSiteUpdateClick(sites.siteid)
                                  }
                                >
                                  Site Update
                                </Button>
                              </div>
                              <div style={{ width: "100%", margin: "5px" }}>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                >
                                  Site Env Monitoring
                                </Button>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    deleteSiteHandler(
                                      sites.siteid,
                                      sites.sitename
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </div>
                              <div style={{ width: "100%", margin: "5px" }}>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    siteIssueDetails(sites.siteid);
                                  }}
                                >
                                  Site Issue Details
                                </Button>
                                {/* <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                >
                                  Site Health Status
                                </Button> */}
                              </div>
                              <h3 className="cardactiontitle">Operate</h3>
                              <div style={{ width: "100%", margin: "5px" }}>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    siteFeedback(sites.siteid);
                                  }}
                                >
                                  Site Feedback
                                </Button>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    createSiteIssue(sites.siteid);
                                  }}
                                >
                                  Site Issue
                                </Button>
                              </div>
                              <div style={{ width: "100%", margin: "5px" }}>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    dailyReportsHandler(sites.siteid, "Grid");
                                  }}
                                >
                                  Daily Report
                                </Button>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    siteAlarmsClick(sites.siteid, "Grid");
                                  }}
                                >
                                  Site Alarms
                                </Button>
                              </div>
                              <div style={{ width: "100%", margin: "5px" }}>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    groupNutritionHandler(
                                      sites.siteid,
                                      "Grid",
                                      sites.sitename
                                    );
                                  }}
                                >
                                  Group Nutrition
                                </Button>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    siteattendanceLocationButtonClickHandler(
                                      sites.siteid,
                                      "Grid",
                                      sites.sitename
                                    )
                                  }
                                >
                                  Site Attendance
                                </Button>
                              </div>
                              <h3 className="cardactiontitle">Actions</h3>
                              <div style={{ width: "100%", margin: "5px" }}>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    siteGrowRequestButtonClickHandler(
                                      sites.siteid,
                                      "Grid",
                                      sites.sitename
                                    )
                                  }
                                >
                                  Grow Requests
                                </Button>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    siteSellRequestButtonClickHandler(
                                      sites.siteid,
                                      "Grid",
                                      sites.sitename
                                    )
                                  }
                                >
                                  Sell Requests
                                </Button>
                              </div>
                              <div style={{ width: "100%", margin: "5px" }}>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    siteLoanRequestButtonClickHandler(
                                      sites.siteid,
                                      "Grid",
                                      sites.sitename
                                    )
                                  }
                                >
                                  Loan Requests
                                </Button>
                                <Button
                                  style={{ marginRight: "10px", width: "45%" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    siteVetVisitRequestButtonClickHandler(
                                      sites.siteid,
                                      "Grid",
                                      sites.sitename
                                    )
                                  }
                                >
                                  VET Visit Requests
                                </Button>
                              </div>
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <h4>No View Sites List.</h4>
                    )}
                  </div>
                </Box>
              )}
            </Box>
          ) : null}

          {viewAllMapSites === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Site"
                className={classes.fab}
              >
                <AddIcon onClick={() => createSiteHandler()}></AddIcon>
              </Fab>
              {getAllSites.length > 0 ? (
                <SiteManagementGoogleMap
                  containerElement={
                    <div style={{ height: `500px`, width: "100%" }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              ) : (
                <h4>Sites List Not Found</h4>
              )}
            </Box>
          ) : null}

          {showAssetList === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {getMappedAssets.length > 0 ? (
                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: 450 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow align="center">
                          <TableCell align="center" colSpan={23}>
                            MAPPED LEGACY ASSET DEVICES
                          </TableCell>
                        </TableRow>
                        <TableRow align="center">
                          <TableCell align="center" colSpan={1}>
                            Solution ID
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Site ID
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Site Name
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Asset ID
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Asset Type
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Category
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Asset Status
                          </TableCell>
                          <TableCell align="center" colSpan={6}>
                            View
                          </TableCell>
                          <TableCell align="center" colSpan={10}>
                            Operate
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getMappedAssets
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((assets, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="center">
                                  {assets.solutionId}
                                </TableCell>
                                <TableCell align="center">
                                  {assets.siteId}
                                </TableCell>
                                <TableCell align="center">
                                  {getMappedAssetsSiteName}
                                </TableCell>
                                <TableCell align="center">
                                  {assets.assetId}
                                </TableCell>
                                <TableCell align="center">
                                  {assets.assetType}
                                </TableCell>
                                <TableCell align="center">
                                  {assets.category}
                                </TableCell>
                                <TableCell align="center">
                                  {assets.assetStatus}
                                </TableCell>
                                <TableCell></TableCell>
                                
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {unMapAssetHandler(assets)}}
                                  >
                                    UnMap
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      updateLegacyAssetHandler(assets.assetId)
                                    }
                                  >
                                    Update
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      addedAssetDeleteHandler(assets.assetId)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button size="small" variant="contained">
                                    Asset Score
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button size="small" variant="contained"
                                  onClick={() =>
                                    assetMonitoringHandler(
                                      assets.siteId,
                                      assets.assetId,
                                      getMappedAssetsSiteName
                                    )
                                  }>
                                    Asset Monitoring
                                  </Button>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      assetAttenanceClick(
                                        assets.assetId,
                                        "List"
                                      )
                                    }
                                  >
                                    Asset Attendance
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      birthInfoButtonClickHandler(
                                        assets.assetId,
                                        "List"
                                      )
                                    }
                                  >
                                    Birth
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      nutritionButtonClickHandler(
                                        assets.assetId,
                                        "List"
                                      )
                                    }
                                  >
                                    Nutrition
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      mortalityButtonClickHandler(
                                        assets.assetId,
                                        "List"
                                      )
                                    }
                                  >
                                    Mortality
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      parturitionButtonClickHandler(
                                        assets.assetId,
                                        "List"
                                      )
                                    }
                                  >
                                    Parturition
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      healthRecordButtonClickHandler(
                                        assets.assetId,
                                        "List"
                                      )
                                    }
                                  >
                                    Health Record
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      assetFeedback(assets.assetId)
                                    }
                                  >
                                    Asset Feedback
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button size="small" variant="contained"
                                  onClick={()=>uploadPhotoClick()}>
                                    Photo Upload
                                  </Button>
                                </TableCell>
                                <TableCell align="center">
                                  <Button size="small" variant="contained">
                                    Asset Issue
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={getMappedAssets.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              ) : (
                <h4>No Mapped Legacy Asset Devices</h4>
              )}
            </Box>
          ) : null}

          {showAssetGrid === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Asset"
                className={classes.fab}
              >
                <AddIcon onClick={() => cretaeAssetModel()}></AddIcon>
              </Fab>
              <div className="cardStyleForDashboardItems">
                <h4 style={{ width: "100%", textAlign: "center" }}>
                  Mapped Legacy Asset Devices
                </h4>
                {getMappedAssets.length > 0 ? (
                  <div className="cardStyleForDashboardItems">
                    {getMappedAssets.map((assets, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Solution ID:</b>
                            {assets.solutionId}
                          </p>
                          <p>
                            <b>Site Name :</b>
                            {getMappedAssetsSiteName}
                          </p>
                          <p>
                            <b>Site ID:</b>
                            {assets.siteId}
                          </p>
                          <p>
                            <b>Asset ID:</b>
                            {assets.assetId}
                          </p>
                          <p>
                            <b>Asset Type:</b>
                            {assets.assetType}
                          </p>
                          <p>
                            <b>Model ID:</b>
                            {assets.modelId}
                          </p>
                          <p>
                            <b>Category:</b>
                            {assets.category}
                          </p>
                          <p>
                            <b>Asset Status:</b>
                            {assets.assetStatus}
                          </p>
                          <h3 className="cardactiontitle">View</h3>
                          <div style={{ width: "100%", margin: "5px" }}>
                            
                            <Button
                              style={{ marginRight: "10px", width: "40%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>{ unMapAssetHandler(assets)}}
                            >
                              UnMap
                            </Button>
                          </div>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px", width: "40%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                updateLegacyAssetHandler(assets.assetId)
                              }
                            >
                              Update
                            </Button>
                            <Button
                              style={{ marginRight: "10px", width: "40%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                addedAssetDeleteHandler(assets.assetId)
                              }
                            >
                              Delete
                            </Button>
                          </div>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px", width: "40%" }}
                              size="small"
                              variant="contained"
                            >
                              Asset Score
                            </Button>
                            <Button
                              style={{ marginRight: "10px", width: "40%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                assetMonitoringHandler(
                                  assets.siteId,
                                  assets.assetId,
                                  getMappedAssetsSiteName
                                )
                              }
                            >
                              Asset Monitoring
                            </Button>
                          </div>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px", width: "40%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                assetIssueDetailsModel(assets.assetId)
                              }
                            >
                              Asset Issue Details
                            </Button>
                          </div>
                          <h3 className="cardactiontitle">Operate</h3>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px", width: "60%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                assetAttenanceClick(
                                  assets.assetId,
                                  "Grid"
                                )
                              }
                            >
                              Asset Attendance
                            </Button>
                          </div>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px", width: "30%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                birthInfoButtonClickHandler(
                                  assets.assetId,
                                  "Grid"
                                )
                              }
                            >
                              Birth
                            </Button>
                            <Button
                              style={{ marginRight: "10px", width: "30%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                nutritionButtonClickHandler(
                                  assets.assetId,
                                  "Grid"
                                )
                              }
                            >
                              Nutrition
                            </Button>
                            <Button
                              style={{ marginRight: "10px", width: "30%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                mortalityButtonClickHandler(
                                  assets.assetId,
                                  "Grid"
                                )
                              }
                            >
                              Mortality
                            </Button>
                          </div>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px", width: "30%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                parturitionButtonClickHandler(
                                  assets.assetId,
                                  "Grid"
                                )
                              }
                            >
                              Parturition
                            </Button>
                            <Button
                              style={{ marginRight: "10px", width: "30%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                healthRecordButtonClickHandler(
                                  assets.assetId,
                                  "Grid"
                                )
                              }
                            >
                              Health Record
                            </Button>
                            <Button
                              style={{ marginRight: "10px", width: "30%" }}
                              size="small"
                              variant="contained"
                              onClick={() => assetFeedback(assets.assetId)}
                            >
                              Asset Feedback
                            </Button>
                          </div>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <Button
                              style={{ marginRight: "10px", width: "30%" }}
                              size="small"
                              variant="contained"
                              onClick={()=>uploadPhotoClick()}
                            >
                              Photo Upload
                            </Button>
                            <Button
                              style={{ marginRight: "10px", width: "30%" }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                assetIssueModelHandler(assets.assetId)
                              }
                            >
                              Asset Issue
                            </Button>
                          </div>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Mapped Legacy Asset Devices.</h4>
                )}
              </div>
            </Box>
          ) : null}

          {showAssetMap === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {getMappedAssets.length > 0 ? (
                <AssetsGoogleMap
                  containerElement={
                    <div style={{ height: `500px`, width: "100%" }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              ) : (
                <h4>No Mapped Legacy Asset Devices.</h4>
              )}
            </Box>
          ) : null}

          {showSiteRating === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Site Rating"
                className={classes.fab}
              >
                <AddIcon onClick={() => addedSiteRatingHandler(getSiteRateSiteId)}></AddIcon>
              </Fab>
              {getSiteRatings.length > 0 ? (
                <TableContainer component={Paper}>
                  <h2>RATINGS</h2>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">Id</StyledTableCell>
                        <StyledTableCell align="center">Rating</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getSiteRatings.map((row) => (
                        <StyledTableRow key={row.siteid}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {row.siteid}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.ratings}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <h4>Ratings Not Found</h4>
              )}
            </Box>
          ) : null}
          {/* Asset Monitoring */}
          {assetMonitoringView === true ? (
            
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <h3>Asset Live Monitoring</h3>
              <br />              
              <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      viewMappedAssets(
                        selectedSiteId,
                        "Grid",
                        selectedSiteName
                      );
                    }}
                  >
                    Back
                  </Button>
                  <br/>
              {/* <AssetLiveGoogleMap
                containerElement={
                  <div style={{ height: "800px", width: "100%" }} />
                }
                mapElement={<div style={{ height: "100%" }} />}
              /> */}
                <Map routeLocationData={markers} getLocationHandler={getLocationHandler}/>
              
            </Box>
          ) : null}
          {/************************ Birth ****************************/}
          {showBirth === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Asset Birth"
                className={classes.fab}
                onClick={() => {
                  setNewBirthInfoPopup(!showNewBirthInfoPopup);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      viewMappedAssets(
                        redirectSiteId,
                        getAssetBackButtton,
                        getNewBirthSiteName
                      );
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW BIRTH DETAILS
                      </h4>
                      {getBirthInfos.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getBirthInfos.map((assets, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Asset Id:</b>
                                  {assets.assetid}
                                </p>
                                <p>
                                  <b>Male Parent:</b>
                                  {assets.maleparent}
                                </p>
                                <p>
                                  <b>Female Parent:</b>
                                  {assets.femaleparent}
                                </p>
                                <p>
                                  <b>D.O.B:</b>
                                  {assets.dob}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {assets.timestamp}
                                </p>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => updateBirthInfoData(assets)}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      deleteNewBirthInfoAssetId(assets.assetid)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>VIEW BIRTH DETAILS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Birth ****************************/}
          {/************************ Nutrition ****************************/}
          {showNutrition === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Nutrition"
                className={classes.fab}
                onClick={() => {
                  setAddNutritionPopup(!showAddNutritionPopup);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      viewMappedAssets(
                        redirectSiteId,
                        getAssetBackButtton,
                        getNewBirthSiteName
                      );
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW NUTRITION DETAILS
                      </h4>
                      {getNutritions.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getNutritions.map((nutrition, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Asset Id:</b>
                                  {nutrition.assetid}
                                </p>
                                <p>
                                  <b>Feed Type:</b>
                                  {nutrition.feedtype}
                                </p>
                                <p>
                                  <b>Schedule:</b>
                                  {nutrition.schedule}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {nutrition.timestamp}
                                </p>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      updateNutririonInfo(nutrition)
                                    }
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      deleteNutririonAssetid(nutrition.assetid)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>VIEW NUTRITION DETAILS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Nutrition ****************************/}

          {/************************  Attendance Location ****************************/}
          {showAttendanceLocation === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Attendance Location"
                className={classes.fab}
                onClick={() => {
                  setNewAttendanceLocationPopup(
                    !showNewAttendanceLocationPopup
                  );
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      viewMappedAssets(
                        redirectSiteId,
                        getAssetBackButtton,
                        getNewBirthSiteName
                      );
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW ATTENDANCE LOCATIONS
                      </h4>
                      {getAttendanceLocationHistory.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getAttendanceLocationHistory.map((assets, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Asset Id:</b>
                                  {assets.assetid}
                                </p>
                                <p>
                                  <b>Latitude:</b>
                                  {assets.latitude}
                                </p>
                                <p>
                                  <b>Longitude:</b>
                                  {assets.longitude}
                                </p>
                                <p>
                                  <b>Altitude:</b>
                                  {assets.altitude}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {assets.timestamp}
                                </p>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      updateAttendanceLocationsAssetInfo(assets)
                                    }
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      deleteAttendanceLocationsAssetId(
                                        assets.assetid
                                      )
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>VIEW ATTENDANCE LOCATIONS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Attendance Location ****************************/}

          {/************************ Health Records ****************************/}
          {showHealthRecords === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Health Record"
                className={classes.fab}
                onClick={() => {
                  setNewHealthRecordPopup(!showNewHealthRecordPopup);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      viewMappedAssets(
                        redirectSiteId,
                        getAssetBackButtton,
                        getNewBirthSiteName
                      );
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW HEALTH RECORDS
                      </h4>
                      {getHealthRecords.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getHealthRecords.map((assets, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Asset Id:</b>
                                  {assets.assetid}
                                </p>
                                <p>
                                  <b>Medicine Type:</b>
                                  {assets.medicinetype}
                                </p>
                                <p>
                                  <b>Disease Type:</b>
                                  {assets.diseasetype}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {assets.timestamp}
                                </p>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      updateHealthRecordInfo(assets)
                                    }
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      deleteHealthRecordAssetId(assets.assetid)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>VIEW HEALTH RECORDS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Health Records ****************************/}

          

          {/************************ Parturition ****************************/}
          {showParturition === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Parturition"
                className={classes.fab}
                onClick={() => {
                  setNewParturitionPopup(!showNewParturitionPopup);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      viewMappedAssets(
                        redirectSiteId,
                        getAssetBackButtton,
                        getNewBirthSiteName
                      );
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW PARTURITION DETAILS
                      </h4>
                      {getParturitionDetail.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getParturitionDetail.map((parturitions, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Asset Id:</b>
                                  {parturitions.assetid}
                                </p>
                                <p>
                                  <b>Breed:</b>
                                  {parturitions.breed}
                                </p>
                                <p>
                                  <b>Breeder Id:</b>
                                  {parturitions.breederid}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {parturitions.timestamp}
                                </p>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      updateParturitionInfo(parturitions)
                                    }
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      deleteParturitionAssetid(
                                        parturitions.assetid
                                      )
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>VIEW PARTURITION DETAILS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Parturition ****************************/}

          {/************************ Mortality ****************************/}
          {showMortality === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Mortality"
                className={classes.fab}
                onClick={() => {
                  setNewMortalityPopuop(!showNewMortalityPopuop);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      viewMappedAssets(
                        redirectSiteId,
                        getAssetBackButtton,
                        getNewBirthSiteName
                      );
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW MORTALITY DETAILS
                      </h4>
                      {getMortalityDetails.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getMortalityDetails.map((mortality, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Asset Id:</b>
                                  {mortality.assetid}
                                </p>
                                <p>
                                  <b>Cause Of Death:</b>
                                  {mortality.causeofdeath}
                                </p>
                                <p>
                                  <b>Gender:</b>
                                  {mortality.gender}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {mortality.timestamp}
                                </p>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      updateMortalityInfo(mortality)
                                    }
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      deleteMortalityAssetid(mortality.assetid)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>VIEW MORTALITY DETAILS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Mortality ****************************/}

          {/************************ Daily Reports ****************************/}
          {showDailyReports === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Daily Report"
                className={classes.fab}
                onClick={() => {
                  getShowAddDailyReportModel(!showAddDailyReportModel);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      getAllSitesData(getBackToSites);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW DAILY REPORT DETAILS
                      </h4>
                      {getDailyReports.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getDailyReports.map((dailyreports, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Site Id:</b>
                                  {dailyreports.assetid}
                                </p>
                                <p>
                                  <b>Nutrition Recorded:</b>
                                  {dailyreports.nutritionrecorded}
                                </p>
                                <p>
                                  <b>Attendance Recorded:</b>
                                  {dailyreports.attendancerecorded}
                                </p>
                                <p>
                                  <b>Photo Uploaded :</b>
                                  {dailyreports.photouploaded}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {dailyreports.timestamp}
                                </p>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>VIEW DAILY REPORT DETAILS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}

          {/* Site Alarms */}
          {gridViewSiteAlarms === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Create Site Alarm"
                className={classes.fab}
                onClick={() => {
                  setSiteAlarmPopup(!siteAlarmPopup);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>

              <h3>List Of Site Alarms</h3>
              <br />
              <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      getAllSitesData(getBackToSites);
                    }}
                  >
                    Back
                  </Button>
              <div className="cardStyleForDashboardItems">
                {sitealarmsList.length>0 ? (
                  <div className="cardStyleForDashboardItems">
                    {sitealarmsList.map((site, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Alarm Id:</b>
                            {site.alarmid}
                          </p>
                          <p>
                            <b>Source Id:</b>
                            {site.source}
                          </p>
                          <p>
                            <b>Assetvertical Id:</b>
                            {site.assetverticalid}
                          </p>
                          <p>
                            <b>Assetvertical Name:</b>
                            {site.assetverticalname}
                          </p>
                          <p>
                            <b>Category:</b>
                            {site.category}
                          </p>
                          <p>
                            <b>Legacyasset Type:</b>
                            {site.legacyassettype}
                          </p>
                          <p>
                            <b>Model Id:</b>
                            {site.modelid}
                          </p>
                          <p>
                            <b>Site Id:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Alarm Type:</b>
                            {site.alarmtype}
                          </p>
                          <p>
                            <b>Alarm Category:</b>
                            {site.alarmcategory}
                          </p>
                          <p>
                            <b>Alarm Name:</b>
                            {site.alarmname}
                          </p>
                          <p>
                            <b>Alarm Status:</b>
                            {site.status}
                          </p>
                          <p>
                            <b>Timestamp:</b>
                            {site.timestamp}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Site Alarms Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Site Alarms */}
          {/************************ End Of Daily Reports ****************************/}

          {/************************ Site Group Nutrition Info ****************************/}
          {showGropuNutritionView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Daily Report"
                className={classes.fab}
                onClick={() => {
                  setShowAddGroupNutritionModel(!showAddGroupNutritionModel);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      getAllSitesData(getBackToSites);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW NUTRITION DETAILS
                      </h4>
                      {getGroupNutritionDetails.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getGroupNutritionDetails.map(
                            (groupnutrition, index) => (
                              <div
                                key={index}
                                className="cardStyleForDashboardItemsBody"
                              >
                                <span>
                                  <p>
                                    <b>Site Id:</b>
                                    {groupnutrition.assetid}
                                  </p>
                                  <p>
                                    <b>Site Friendly Name:</b>
                                    {groupnutrition.assetfriendlyname}
                                  </p>
                                  <p>
                                    <b>Feed Type:</b>
                                    {groupnutrition.feedtype}
                                  </p>
                                  <p>
                                    <b>Schedule :</b>
                                    {groupnutrition.schedule}
                                  </p>
                                  <p>
                                    <b>Timestamp:</b>
                                    {groupnutrition.timestamp}
                                  </p>
                                  <div style={{ width: "100%", margin: "5px" }}>
                                    <Button
                                      style={{ marginRight: "10px" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() =>
                                        updateAddedGroupNutrition(
                                          groupnutrition
                                        )
                                      }
                                    >
                                      Update
                                    </Button>
                                    <Button
                                      style={{ marginRight: "10px" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() =>
                                        deleteAddedGroupNutrition(
                                          groupnutrition.assetid
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <h4>VIEW NUTRITION DETAILS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Site Group Nutrition Info ****************************/}

          {/************************  Site Group Attendance Location ****************************/}
          {showSiteAttendanceLocation === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Site Attendance"
                className={classes.fab}
                onClick={() => {
                  setSiteNewAttendanceLocationPopup(
                    !showSiteNewAttendanceLocationPopup
                  );
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      getAllSitesData(getBackToSites);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        VIEW SITE ATTENDANCE LOCATIONS
                      </h4>
                      {getSiteAttendanceLocationHistory.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getSiteAttendanceLocationHistory.map(
                            (sites, index) => (
                              <div
                                key={index}
                                className="cardStyleForDashboardItemsBody"
                              >
                                <span>
                                  <p>
                                    <b>Site Id:</b>
                                    {sites.assetid}
                                  </p>
                                  <p>
                                    <b>Latitude:</b>
                                    {sites.latitude}
                                  </p>
                                  <p>
                                    <b>Longitude:</b>
                                    {sites.longitude}
                                  </p>
                                  <p>
                                    <b>Altitude:</b>
                                    {sites.altitude}
                                  </p>
                                  <p>
                                    <b>Timestamp:</b>
                                    {sites.timestamp}
                                  </p>
                                  <div style={{ width: "100%", margin: "5px" }}>
                                    <Button
                                      style={{ marginRight: "10px" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() =>
                                        updateSiteAttendanceLocationsAssetInfo(
                                          sites
                                        )
                                      }
                                    >
                                      Update
                                    </Button>
                                    <Button
                                      style={{ marginRight: "10px" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() =>
                                        deleteSiteAttendanceLocationsAssetId(
                                          sites.assetid
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <h4>VIEW SITE ATTENDANCE LOCATIONS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Site Group Attendance Location ****************************/}

          {/************************  Grow Requests ****************************/}
          {viewGrow === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Grow Request"
                className={classes.fab}
                onClick={() => {
                  setShowAddGrowReqModel(!showAddGrowReqModel);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      getAllSitesData(getBackToSites);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        ALL GROW REQUESTS
                      </h4>
                      {getGrowReq.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getGrowReq.map((grow, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Source:</b>
                                  {grow.source}
                                </p>
                                <p>
                                  <b>Destination:</b>
                                  {grow.destination}
                                </p>
                                <p>
                                  <b>Request Id:</b>
                                  {grow.requestid}
                                </p>
                                <p>
                                  <b>Asset Vertical Id:</b>
                                  {grow.assetverticalid}
                                </p>
                                <p>
                                  <b>Category:</b>
                                  {grow.category}
                                </p>
                                <p>
                                  <b>Legacy Asset Type:</b>
                                  {grow.legacyassettype}
                                </p>
                                <p>
                                  <b>Model Id:</b>
                                  {grow.modelid}
                                </p>
                                <p>
                                  <b>Phone:</b>
                                  {grow.phone}
                                </p>
                                <p>
                                  <b>Email:</b>
                                  {grow.email}
                                </p>
                                <p>
                                  <b>Quantity:</b>
                                  {grow.quantity}
                                </p>
                                <p>
                                  <b>Status:</b>
                                  {grow.status}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {grow.timestamp}
                                </p>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  {grow.status === "grow-cancel-request" ||
                                  grow.status === "rejected" ||
                                  grow.status === "fulfilled" ? null : (
                                    <Button
                                      style={{ margin: "5px", width: "50%" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        updateGrowRequestModelHandler(
                                          grow.requestid,
                                          grow.status
                                        );
                                      }}
                                    >
                                      Update Grow Request Status
                                    </Button>
                                  )}
                                </div>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  {(() => {
                                    if (grow.status === "new-grow-request") {
                                      return (
                                        <Button
                                          style={{
                                            margin: "5px",
                                            width: "50%",
                                          }}
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelGrowRequestModelHandler(
                                              grow.requestid,
                                              grow.status
                                            );
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      );
                                    }
                                  })()}
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>GROW REQUESTS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Grow Requests ****************************/}

          {/************************  Sell Requests ****************************/}
          {viewSell === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Sell Request"
                className={classes.fab}
                onClick={() => {
                  setShowAddSellReqModel(!showAddSellReqModel);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      getAllSitesData(getBackToSites);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        ALL SELL REQUESTS
                      </h4>
                      {getSellReq.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getSellReq.map((sell, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Source:</b>
                                  {sell.source}
                                </p>
                                <p>
                                  <b>Destination:</b>
                                  {sell.destination}
                                </p>
                                <p>
                                  <b>Request Id:</b>
                                  {sell.requestid}
                                </p>
                                <p>
                                  <b>Asset Vertical Id:</b>
                                  {sell.assetverticalid}
                                </p>
                                <p>
                                  <b>Category:</b>
                                  {sell.category}
                                </p>
                                <p>
                                  <b>Legacy Asset Type:</b>
                                  {sell.legacyassettype}
                                </p>
                                <p>
                                  <b>Model Id:</b>
                                  {sell.modelid}
                                </p>
                                <p>
                                  <b>Phone:</b>
                                  {sell.phone}
                                </p>
                                <p>
                                  <b>Email:</b>
                                  {sell.email}
                                </p>
                                <p>
                                  <b>Quantity:</b>
                                  {sell.quantity}
                                </p>
                                <p>
                                  <b>Status:</b>
                                  {sell.status}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {sell.timestamp}
                                </p>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  {sell.status === "sell-cancel-request" ||
                                  sell.status === "rejected" ||
                                  sell.status === "fulfilled" ? null : (
                                    <Button
                                      style={{ margin: "5px", width: "50%" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        updateSellRequestModelHandler(
                                          sell.requestid,
                                          sell.status
                                        );
                                      }}
                                    >
                                      Update Sell Request Status
                                    </Button>
                                  )}
                                </div>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  {(() => {
                                    if (sell.status === "new-sell-request") {
                                      return (
                                        <Button
                                          style={{
                                            margin: "5px",
                                            width: "50%",
                                          }}
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelSellRequestModelHandler(
                                              sell.requestid,
                                              sell.status
                                            );
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      );
                                    }
                                  })()}
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>SELL REQUESTS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Sell Requests ****************************/}

          {/************************  Loan Requests ****************************/}
          {viewLoan === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Loan Request"
                className={classes.fab}
                onClick={() => {
                  setShowAddLoanReqModel(!showAddLoanReqModel);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      getAllSitesData(getBackToSites);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        ALL Loan REQUESTS
                      </h4>
                      {getLoanReq.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getLoanReq.map((loan, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Source:</b>
                                  {loan.source}
                                </p>
                                <p>
                                  <b>Destination:</b>
                                  {loan.destination}
                                </p>
                                <p>
                                  <b>Request Id:</b>
                                  {loan.requestid}
                                </p>
                                <p>
                                  <b>Asset Vertical Id:</b>
                                  {loan.assetverticalid}
                                </p>
                                <p>
                                  <b>Category:</b>
                                  {loan.category}
                                </p>
                                <p>
                                  <b>Legacy Asset Type:</b>
                                  {loan.legacyassettype}
                                </p>
                                <p>
                                  <b>Model Id:</b>
                                  {loan.modelid}
                                </p>
                                <p>
                                  <b>Phone:</b>
                                  {loan.phone}
                                </p>
                                <p>
                                  <b>Email:</b>
                                  {loan.email}
                                </p>
                                <p>
                                  <b>Quantity:</b>
                                  {loan.quantity}
                                </p>
                                <p>
                                  <b>Status:</b>
                                  {loan.status}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {loan.timestamp}
                                </p>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  {loan.status === "loan-cancel-request" ||
                                  loan.status === "rejected" ||
                                  loan.status === "fulfilled" ? null : (
                                    <Button
                                      style={{ margin: "5px", width: "50%" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        updateLoanRequestModelHandler(
                                          loan.requestid,
                                          loan.status
                                        );
                                      }}
                                    >
                                      Update Loan Request Status
                                    </Button>
                                  )}
                                </div>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  {(() => {
                                    if (loan.status === "new-loan-request") {
                                      return (
                                        <Button
                                          style={{
                                            margin: "5px",
                                            width: "50%",
                                          }}
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelLoanRequestModelHandler(
                                              loan.requestid,
                                              loan.status
                                            );
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      );
                                    }
                                  })()}
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>LOAN REQUESTS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of Loan Requests ****************************/}

          {/************************  VET VISIT Requests ****************************/}
          {viewVetVisit === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <Fab
                color="primary"
                size={"large"}
                aria-label="add"
                title="Add Vet Visit Request"
                className={classes.fab}
                onClick={() => {
                  setShowAddVetVisitReqModel(!showAddVetVisitReqModel);
                }}
              >
                <AddIcon></AddIcon>
              </Fab>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    style={{ marginRight: "10px" }}
                    color="primary"
                    className="registerBtn"
                    variant="contained"
                    onClick={() => {
                      getAllSitesData(getBackToSites);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                  {isLoading ? (
                    <div className="cardStyleForDashboardItems">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      <h4 style={{ width: "100%", textAlign: "center" }}>
                        ALL VET VISIT REQUESTS
                      </h4>
                      {getVetReq.length > 0 ? (
                        <div className="cardStyleForDashboardItems">
                          {getVetReq.map((vet, index) => (
                            <div
                              key={index}
                              className="cardStyleForDashboardItemsBody"
                            >
                              <span>
                                <p>
                                  <b>Source:</b>
                                  {vet.source}
                                </p>
                                <p>
                                  <b>Destination:</b>
                                  {vet.destination}
                                </p>
                                <p>
                                  <b>Request Id:</b>
                                  {vet.requestid}
                                </p>
                                <p>
                                  <b>Asset Vertical Id:</b>
                                  {vet.assetverticalid}
                                </p>
                                <p>
                                  <b>Category:</b>
                                  {vet.category}
                                </p>
                                <p>
                                  <b>Legacy Asset Type:</b>
                                  {vet.legacyassettype}
                                </p>
                                <p>
                                  <b>Model Id:</b>
                                  {vet.modelid}
                                </p>
                                <p>
                                  <b>Phone:</b>
                                  {vet.phone}
                                </p>
                                <p>
                                  <b>Email:</b>
                                  {vet.email}
                                </p>
                                <p>
                                  <b>Quantity:</b>
                                  {vet.quantity}
                                </p>
                                <p>
                                  <b>Status:</b>
                                  {vet.status}
                                </p>
                                <p>
                                  <b>Timestamp:</b>
                                  {vet.timestamp}
                                </p>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  {vet.status === "vetvisit-cancel-request" ||
                                  vet.status === "rejected" ||
                                  vet.status === "fulfilled" ? null : (
                                    <Button
                                      style={{ margin: "5px", width: "50%" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        updateVetVisitRequestModelHandler(
                                          vet.requestid,
                                          vet.status
                                        );
                                      }}
                                    >
                                      Update Vet Visit Request Status
                                    </Button>
                                  )}
                                </div>
                                <div style={{ width: "100%", margin: "5px" }}>
                                  {(() => {
                                    if (vet.status === "new-vetvisit-request") {
                                      return (
                                        <Button
                                          style={{
                                            margin: "5px",
                                            width: "50%",
                                          }}
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            cancelVetVisitRequestModelHandler(
                                              vet.requestid,
                                              vet.status
                                            );
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      );
                                    }
                                  })()}
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4>VET Visit REQUESTS NOT FOUND.</h4>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            </Box>
          ) : null}
          {/************************ End Of VET Visit Requests ****************************/}

          {/* New Birth Info */}
          <Dialog
            fullScreen={fullScreen}
            open={showNewBirthInfoPopup}
            aria-labelledby="craete Consumer User"
          >
            <DialogTitle id="createConsumerUser">
              {"New Birth Info"}
            </DialogTitle>
            <DialogContent>
              {/* <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Asset ID :</InputLabel>
                  <Select
                    id="assetid"
                    value={getNewBirthAssetid}
                    onChange={(e) => setNewBirthAssetid(e.target.value)}
                    label="assetid"
                  >
                    {getMappedAssets &&
                      getMappedAssets.map((assets, index) => (
                        <MenuItem key={`${index}`} value={assets.assetId}>
                          {assets.assetId}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetid">Asset ID :</InputLabel>
                <Input
                  id="assetid"
                  name="assetid"
                  type="text"
                  value={getNewBirthAssetid}
                  // onChange={(e) => {
                  //   setNewBirthAssetid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Asset Friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getNewBirthAssetFriendlyName}
                  onChange={(e) => {
                    setNewBirthAssetFriendlyName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User ID :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getNewBirthUserid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Latitude">User Name :</InputLabel>
                <Input
                  id="Latitude"
                  name="Latitude"
                  type="text"
                  value={getNewBirthUserName}
                  onChange={(e) => {
                    setNewBirthUserName(e.target.value);
                  }}
                />
              </FormControl>
              {/* <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Site ID</InputLabel>
                  <Select
                    id="sitename"
                    value={getNewBirthSiteid}
                    onChange={(e) => setNewBirthSiteidHandler(e)}
                    label="sitename"
                  >
                    {getAllSites &&
                      getAllSites.map((sites, index) => (
                        <MenuItem key={`${index}`} value={sites.siteid}>
                          {sites.sitename}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteid">Site ID :</InputLabel>
                <Input
                  id="siteid"
                  name="siteid"
                  type="text"
                  value={getNewBirthSiteid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="sitename">Site Name :</InputLabel>
                <Input
                  id="sitename"
                  name="sitename"
                  type="text"
                  value={getNewBirthSiteName}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="lotid">Lot ID :</InputLabel>
                <Input
                  id="lotid"
                  name="lotid"
                  type="text"
                  value={getNewBirthLotid}
                  onChange={(e) => {
                    setNewBirthLotid(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="lotname">Lot Name :</InputLabel>
                <Input
                  id="lotname"
                  name="lotname"
                  type="text"
                  value={getNewBirthLotName}
                  onChange={(e) => {
                    setNewBirthLotName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetType">Legacy Asset Type :</InputLabel>
                <Input
                  id="assetType"
                  name="assetType"
                  type="text"
                  value={getNewBirthLegacyAssetType}
                  onChange={(e) => {
                    setNewBirthLegacyAssetType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="modelid">Model ID :</InputLabel>
                <Input
                  id="modelid"
                  name="modelid"
                  type="text"
                  value={getNewBirthModelid}
                  onChange={(e) => {
                    setNewBirthModelid(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="modelname">
                  Model Friendly Name :
                </InputLabel>
                <Input
                  id="modelname"
                  name="modelname"
                  type="text"
                  value={getNewBirthModelFriendlyName}
                  onChange={(e) => {
                    setNewBirthModelFriendlyName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="modeltype">Model Type :</InputLabel>
                <Input
                  id="modeltype"
                  name="modeltype"
                  type="text"
                  value={getNewBirthModelType}
                  onChange={(e) => {
                    setNewBirthModelType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="modelurl">Model URL :</InputLabel>
                <Input
                  id="modelurl"
                  name="modelurl"
                  type="text"
                  value={getNewBirthModelURL}
                  onChange={(e) => {
                    setNewBirthModelURL(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                {/* <InputLabel htmlFor="dob">DOB :</InputLabel> */}
                <Input
                  id="dob"
                  name="dob"
                  type="date"
                  value={getNewBirthDOB}
                  onChange={(e) => {
                    setNewBirthDOB(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="gender">Gender :</InputLabel>
                <Input
                  id="gender"
                  name="gender"
                  type="text"
                  value={getNewBirthGender}
                  onChange={(e) => {
                    setNewBirthGender(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="maleparent">Male Parent :</InputLabel>
                <Input
                  id="maleparent"
                  name="maleparent"
                  type="text"
                  value={getNewBirthMaleParent}
                  onChange={(e) => {
                    setNewBirthMaleParent(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="femaleparent">Female Parent :</InputLabel>
                <Input
                  id="femaleparent"
                  name="femaleparent"
                  type="text"
                  value={getNewBirthFemaleParent}
                  onChange={(e) => {
                    setNewBirthFemaleParent(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="parentmodelid">
                  Parent Model Id :
                </InputLabel>
                <Input
                  id="parentmodelid"
                  name="parentmodelid"
                  type="text"
                  value={getNewBirthParentModelid}
                  onChange={(e) => {
                    setNewBirthParentModelid(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={getNewBirthLatitude}
                  onChange={(e) => {
                    setNewBirthLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={getNewBirthLongitude}
                  onChange={(e) => {
                    setNewBirthLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                <Input
                  id="altitude"
                  name="altitude"
                  type="text"
                  value={getNewBirthAltitude}
                  onChange={(e) => {
                    setNewBirthAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getNewBirthDescription}
                  onChange={(e) => {
                    setNewBirthDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  createNewBirthInfo();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setNewBirthInfoPopup(!showNewBirthInfoPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of New Birth Info */}

          {/* Delete Birth Info */}
          <Dialog
            fullScreen={fullScreen}
            open={getDeleteBirthInfoPopup}
            aria-labelledby="craete Consumer User"
          >
            <DialogTitle id="createConsumerUser">
              {"Delete Birth Details"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  deleteBirthInfoAsset();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setDeleteBirthInfoPopup(!getDeleteBirthInfoPopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Birth Info */}

          {/* Delete Site  */}
          <Dialog
            fullScreen={fullScreen}
            open={siteDeleteDialog}
            aria-labelledby="craete Consumer User"
          >
            <DialogTitle id="createConsumerUser">{"Delete Site"}</DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  deletSiteSubmitClcik();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setSiteDeleteDialog(!siteDeleteDialog);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete site */}

          {/* Update Birth Info */}
          <Dialog
            fullScreen={fullScreen}
            open={showUpdateBirthInfoPopup}
            aria-labelledby="craete Consumer User"
          >
            <DialogTitle id="createConsumerUser">
              {"Update Birth Info"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetid">Asset ID :</InputLabel>
                <Input
                  id="assetid"
                  name="assetid"
                  type="text"
                  value={getUpdateNewBirthAssetid}
                  // onChange={(e) => {
                  //   setUpdateNewBirthAssetid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Asset Friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getUpdateNewBirthAssetFriendlyName}
                  onChange={(e) => {
                    setUpdateNewBirthAssetFriendlyName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User ID :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getUpdateNewBirthUserid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Latitude">User Name :</InputLabel>
                <Input
                  id="Latitude"
                  name="Latitude"
                  type="text"
                  value={getUpdateNewBirthUserName}
                  // onChange={(e) => {
                  //   setNewBirthUserName(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Site ID</InputLabel>
                <Select
                  id="sitename"
                  value={getUpdateNewBirthSiteid}
                  onChange={(e) => setNewBirthSiteidHandler(e)}
                  label="sitename"
                >
                  {getAllSites &&
                    getAllSites.map((sites, index) => (
                      <MenuItem key={`${index}`} value={sites.siteid}>
                        {sites.sitename}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">Site Name :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getUpdateNewBirthSiteName}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="lotid">Lot ID :</InputLabel>
                <Input
                  id="lotid"
                  name="lotid"
                  type="text"
                  value={getUpdateNewBirthLotid}
                  onChange={(e) => {
                    setUpdateNewBirthLotid(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="lotname">Lot Name :</InputLabel>
                <Input
                  id="lotname"
                  name="lotname"
                  type="text"
                  value={getUpdateNewBirthLotName}
                  onChange={(e) => {
                    setUpdateNewBirthLotName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetType">Legacy Asset Type :</InputLabel>
                <Input
                  id="assetType"
                  name="assetType"
                  type="text"
                  value={getUpdateNewBirthLegacyAssetType}
                  onChange={(e) => {
                    setUpdateNewBirthLegacyAssetType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="modelid">Model ID :</InputLabel>
                <Input
                  id="modelid"
                  name="modelid"
                  type="text"
                  value={getUpdateNewBirthModelid}
                  onChange={(e) => {
                    setUpdateNewBirthModelid(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="modelname">
                  Model Friendly Name :
                </InputLabel>
                <Input
                  id="modelname"
                  name="modelname"
                  type="text"
                  value={getUpdateNewBirthModelFriendlyName}
                  onChange={(e) => {
                    setUpdateNewBirthModelFriendlyName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="modeltype">Model Type :</InputLabel>
                <Input
                  id="modeltype"
                  name="modeltype"
                  type="text"
                  value={getUpdateNewBirthModelType}
                  onChange={(e) => {
                    setUpdateNewBirthModelType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="modelurl">Model URL :</InputLabel>
                <Input
                  id="modelurl"
                  name="modelurl"
                  type="text"
                  value={getUpdateNewBirthModelURL}
                  onChange={(e) => {
                    setUpdateNewBirthModelURL(e.target.value);
                  }}
                />
              </FormControl>
              
                <InputLabel htmlFor="dob">DOB :</InputLabel>
                <Input
                fullWidth={true} className="loginFields"
                  id="dob"
                  name="dob"
                  type="date"
                  // value={getUpdateNewBirthDOB}
                  defaultValue={getUpdateNewBirthDOB}
                  onChange={(e) => {
                    setUpdateNewBirthDOB(e.target.value);
                  }}
                />
              
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="gender">Gender :</InputLabel>
                <Input
                  id="gender"
                  name="gender"
                  type="text"
                  value={getUpdateNewBirthGender}
                  onChange={(e) => {
                    setUpdateNewBirthGender(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="maleparent">Male Parent :</InputLabel>
                <Input
                  id="maleparent"
                  name="maleparent"
                  type="text"
                  value={getUpdateNewBirthMaleParent}
                  onChange={(e) => {
                    setUpdateNewBirthMaleParent(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="femaleparent">Female Parent :</InputLabel>
                <Input
                  id="femaleparent"
                  name="femaleparent"
                  type="text"
                  value={getUpdateNewBirthFemaleParent}
                  onChange={(e) => {
                    setUpdateNewBirthFemaleParent(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="parentmodelid">
                  Parent Model Id :
                </InputLabel>
                <Input
                  id="parentmodelid"
                  name="parentmodelid"
                  type="text"
                  value={getUpdateNewBirthParentModelid}
                  onChange={(e) => {
                    setUpdateNewBirthParentModelid(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={getUpdateNewBirthLatitude}
                  onChange={(e) => {
                    setUpdateNewBirthLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={getUpdateNewBirthLongitude}
                  onChange={(e) => {
                    setUpdateNewBirthLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                <Input
                  id="altitude"
                  name="altitude"
                  type="text"
                  value={getUpdateNewBirthAltitude}
                  onChange={(e) => {
                    setUpdateNewBirthAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getUpdateNewBirthDescription}
                  onChange={(e) => {
                    setUpdateNewBirthDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateNewBirthInfoHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setUpdateBirthInfoPopup(!showUpdateBirthInfoPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Birth Info */}

          {/* New Attendance Location */}
          <Dialog
            fullScreen={fullScreen}
            open={showNewAttendanceLocationPopup}
            // onClose={handleClose}
            aria-labelledby="craete Consumer User"
          >
            <DialogTitle id="createConsumerUser">
              {"New Attendance Location"}
            </DialogTitle>
            <DialogContent>
              {/* <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Asset ID :</InputLabel>
                  <Select
                    id="assetid"
                    value={getNewAttendanceLocationAssetid}
                    onChange={(e) => setNewAttendanceLocationAssetid(e.target.value)}
                    label="assetid"
                  >
                    {getMappedAssets &&
                      getMappedAssets.map((assets, index) => (
                        <MenuItem key={`${index}`} value={assets.assetId}>
                          {assets.assetId}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetid">Asset id :</InputLabel>
                <Input
                  id="assetid"
                  name="assetid"
                  type="text"
                  value={getNewAttendanceLocationAssetid}
                  // onChange={(e) => {
                  //   setNewAttendanceLocationAssetid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Asset friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getNewAttendanceLocationAssetfriendlyName}
                  onChange={(e) => {
                    setNewAttendanceLocationAssetfriendlyName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User id :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getNewAttendanceLocationUserid}
                  // onChange={(e) => {
                  //   setNewAttendanceLocationUserid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Latitude">Latitude :</InputLabel>
                <Input
                  id="Latitude"
                  name="Latitude"
                  type="text"
                  value={getNewAttendanceLocationLatitude}
                  onChange={(e) => {
                    setNewAttendanceLocationLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Longitude">Longitude:</InputLabel>
                <Input
                  id="Longitude"
                  name="Longitude"
                  type="text"
                  value={getNewAttendanceLocationLongitude}
                  onChange={(e) => {
                    setNewAttendanceLocationLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Altitude">Altitude :</InputLabel>
                <Input
                  id="Altitude"
                  name="Altitude"
                  type="text"
                  value={getNewAttendanceLocationAltitude}
                  onChange={(e) => {
                    setNewAttendanceLocationAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getNewAttendanceLocationDescription}
                  onChange={(e) => {
                    setNewAttendanceLocationDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  addAttendanceLocationHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setNewAttendanceLocationPopup(
                    !showNewAttendanceLocationPopup
                  );
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of New Attendance Location */}

          {/* Delete Attendance Location */}
          <Dialog
            fullScreen={fullScreen}
            open={getDeletedAttendancePopup}
            // onClose={handleClose}
            aria-labelledby="craete Consumer User"
          >
            <DialogTitle id="createConsumerUser">
              {"Delete Attendance Location"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  deleteAttendanceAsset();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setDeletedAttendancePopup(!getDeletedAttendancePopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Attendance Location */}

          {/* Update Attendance Location Info */}
          <Dialog
            fullScreen={fullScreen}
            open={showUpdateAttendaneLocationPopup}
            // onClose={handleClose}
            aria-labelledby="craete Consumer User"
          >
            <DialogTitle id="createConsumerUser">
              {"Update Attendance Location Info"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetid">Asset id :</InputLabel>
                <Input
                  disabled
                  id="assetid"
                  name="assetid"
                  type="text"
                  value={getUpdateAttendanceLocationInfoAssetid}
                  // onChange={(e) => {
                  //   setUpdateAttendanceLocationInfoAssetid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Asset friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getUpdateAttendanceLocationInfoAssetfriendlyName}
                  onChange={(e) => {
                    setUpdateAttendanceLocationInfoAssetfriendlyName(
                      e.target.value
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User id :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getUpdateAttendanceLocationInfoUserid}
                  // onChange={(e) => {
                  //   setNewAttendanceLocationUserid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Latitude">Latitude :</InputLabel>
                <Input
                  id="Latitude"
                  name="Latitude"
                  type="text"
                  value={getUpdateAttendanceLocationInfoLatitude}
                  onChange={(e) => {
                    setUpdateAttendanceLocationInfoLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Longitude">Longitude:</InputLabel>
                <Input
                  id="Longitude"
                  name="Longitude"
                  type="text"
                  value={getUpdateAttendanceLocationInfoLongitude}
                  onChange={(e) => {
                    setUpdateAttendanceLocationInfoLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Altitude">Altitude :</InputLabel>
                <Input
                  id="Altitude"
                  name="Altitude"
                  type="text"
                  value={getUpdateAttendanceLocationInfoAltitude}
                  onChange={(e) => {
                    setUpdateAttendanceLocationInfoAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getUpdateAttendanceLocationInfoDescription}
                  onChange={(e) => {
                    setUpdateAttendanceLocationInfoDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateAttendanceLocationsHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setUpdateAttendaneLocationPopup(
                    !showUpdateAttendaneLocationPopup
                  );
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Attendance Location Info */}

          {/* New Health Record */}
          <Dialog
            fullScreen={fullScreen}
            open={showNewHealthRecordPopup}
            aria-labelledby="newhealthrecord"
          >
            <DialogTitle id="newhealthrecord">
              {"New Health Record"}
            </DialogTitle>
            <DialogContent>
              {/* <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Asset ID :</InputLabel>
                  <Select
                    id="assetid"
                    value={getNewHealthAssetid}
                    onChange={(e) => setNewHealthAssetid(e.target.value)}
                    label="assetid"
                  >
                    {getMappedAssets &&
                      getMappedAssets.map((assets, index) => (
                        <MenuItem key={`${index}`} value={assets.assetId}>
                          {assets.assetId}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetid">Asset ID :</InputLabel>
                <Input
                  id="assetid"
                  name="assetid"
                  type="text"
                  value={getNewHealthAssetid}
                  // onChange={(e) => {
                  //   setNewHealthAssetid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Asset Friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getNewHealthAssetFriendlyName}
                  onChange={(e) => {
                    setNewHealthAssetFriendlyName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User ID :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getNewHealthUserid}
                  // onChange ={(e)=>{setNewHealthUserid(e.target.value)}}
                />
              </FormControl>
              
                <InputLabel htmlFor="Medicine">Medicine Date :</InputLabel>
                <Input
                fullWidth={true} className="loginFields"
                  id="Medicine"
                  name="Medicine"
                  type="date"
                  value={getNewHealthMedicineDate}
                  onChange={(e) => {
                    setNewHealthMedicineDate(e.target.value);
                  }}
                />
              

              
                <InputLabel htmlFor="Vaccination">
                  Vaccination Date :
                </InputLabel>
                <Input
                fullWidth={true} className="loginFields"
                  id="Vaccination"
                  name="Vaccination"
                  type="date"
                  value={getNewHealthVaccinationDate}
                  onChange={(e) => {
                    setNewHealthVaccinationDate(e.target.value);
                  }}
                />
              
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Examine">Examine :</InputLabel>
                <Input
                  id="Examine"
                  name="Examine"
                  type="text"
                  value={getNewHealthExamine}
                  onChange={(e) => {
                    setNewHealthExamine(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Surgical">Surgical Procedure :</InputLabel>
                <Input
                  id="Surgical"
                  name="Surgical"
                  type="text"
                  value={getNewHealthSurgicalProcedure}
                  onChange={(e) => {
                    setNewHealthSurgicalProcedure(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Vaccination">
                  Vaccination Type :
                </InputLabel>
                <Input
                  id="Vaccination"
                  name="Vaccination"
                  type="text"
                  value={getNewHealthVaccinationType}
                  onChange={(e) => {
                    setNewHealthVaccinationType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="MedicineType">Medicine Type :</InputLabel>
                <Input
                  id="MedicineType"
                  name="MedicineType"
                  type="text"
                  value={getNewHealthMedicineType}
                  onChange={(e) => {
                    setNewHealthMedicineType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Disease">Disease Type :</InputLabel>
                <Input
                  id="Disease"
                  name="Disease"
                  type="text"
                  value={getNewHealthDiseaseType}
                  onChange={(e) => {
                    setNewHealthDiseaseType(e.target.value);
                  }}
                />
              </FormControl>
             
                <InputLabel htmlFor="Treatment">Treatment Date :</InputLabel>
                <Input
                fullWidth={true} className="loginFields"
                  id="Treatment"
                  name="Treatment"
                  type="date"
                  value={getNewHealthTreatmentDate}
                  onChange={(e) => {
                    setNewHealthTreatmentDate(e.target.value);
                  }}
                />
            
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={getNewHealthLatitude}
                  onChange={(e) => {
                    setNewHealthLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={getNewHealthLongitude}
                  onChange={(e) => {
                    setNewHealthLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                <Input
                  id="altitude"
                  name="altitude"
                  type="text"
                  value={getNewHealthAltitude}
                  onChange={(e) => {
                    setNewHealthAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getNewHealthDescription}
                  onChange={(e) => {
                    setNewHealthDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  addHealthRecordHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setNewHealthRecordPopup(!showNewHealthRecordPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of New Health Record */}

          {/* Delete HEALTH RECORD */}
          <Dialog
            fullScreen={fullScreen}
            open={showDeleteHealthAssetPopup}
            aria-labelledby="craete Consumer User"
          >
            <DialogTitle id="createConsumerUser">
              {"Delete Health Record"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  deleteHealthRecordAsset();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setDeleteHealthAssetPopup(!showDeleteHealthAssetPopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete HEALTH RECORD*/}

          {/* Update Health Record */}
          <Dialog
            fullScreen={fullScreen}
            open={showUpdateNewHealthRecordPopup}
            aria-labelledby="newhealthrecord"
          >
            <DialogTitle id="newhealthrecord">
              {"Update Health Record"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetid">Asset ID :</InputLabel>
                <Input
                  id="assetid"
                  name="assetid"
                  type="text"
                  value={getUpdateNewHealthAssetid}
                  // onChange={(e) => {
                  //   setUpdateNewHealthAssetid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Asset Friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getUpdateNewHealthAssetFriendlyName}
                  onChange={(e) => {
                    setUpdateNewHealthAssetFriendlyName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User ID :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getUpdateNewHealthUserid}
                  // onChange ={(e)=>{setUpdateNewHealthUserid(e.target.value)}}
                />
              </FormControl>
              
                <InputLabel htmlFor="Medicine">Medicine Date :</InputLabel>
                <Input
                fullWidth={true} className="loginFields"
                  id="Medicine"
                  name="Medicine"
                  type="date"
                  // value={getUpdateNewHealthMedicineDate}
                  defaultValue={getUpdateNewHealthMedicineDate}
                  onChange={(e) => {
                    setUpdateNewHealthMedicineDate(e.target.value);
                  }}
                />
             

              
                <InputLabel htmlFor="Vaccination">
                  Vaccination Date :
                </InputLabel>
                <Input
                fullWidth={true} className="loginFields"
                  id="Vaccination"
                  name="Vaccination"
                  type="date"
                  // value={getUpdateNewHealthVaccinationDate}
                  defaultValue={getUpdateNewHealthVaccinationDate}
                  onChange={(e) => {
                    setUpdateNewHealthVaccinationDate(e.target.value);
                  }}
                />
              
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Examine">Examine :</InputLabel>
                <Input
                  id="Examine"
                  name="Examine"
                  type="text"
                  value={getUpdateNewHealthExamine}
                  onChange={(e) => {
                    setUpdateNewHealthExamine(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Surgical">Surgical Procedure :</InputLabel>
                <Input
                  id="Surgical"
                  name="Surgical"
                  type="text"
                  value={getUpdateNewHealthSurgicalProcedure}
                  onChange={(e) => {
                    setUpdateNewHealthSurgicalProcedure(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Vaccination">
                  Vaccination Type :
                </InputLabel>
                <Input
                  id="Vaccination"
                  name="Vaccination"
                  type="text"
                  value={getUpdateNewHealthVaccinationType}
                  onChange={(e) => {
                    setUpdateNewHealthVaccinationType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="MedicineType">Medicine Type :</InputLabel>
                <Input
                  id="MedicineType"
                  name="MedicineType"
                  type="text"
                  value={getUpdateNewHealthMedicineType}
                  onChange={(e) => {
                    setUpdateNewHealthMedicineType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Disease">Disease Type :</InputLabel>
                <Input
                  id="Disease"
                  name="Disease"
                  type="text"
                  value={getUpdateNewHealthDiseaseType}
                  onChange={(e) => {
                    setUpdateNewHealthDiseaseType(e.target.value);
                  }}
                />
              </FormControl>
              
                <InputLabel htmlFor="Treatment">Treatment Date :</InputLabel>
                <Input
                fullWidth={true} className="loginFields"
                  id="Treatment"
                  name="Treatment"
                  type="date"
                  // value={getUpdateNewHealthTreatmentDate}
                  defaultValue={getUpdateNewHealthTreatmentDate}
                  onChange={(e) => {
                    setUpdateNewHealthTreatmentDate(e.target.value);
                  }}
                />
             
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={getUpdateNewHealthLatitude}
                  onChange={(e) => {
                    setUpdateNewHealthLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={getUpdateNewHealthLongitude}
                  onChange={(e) => {
                    setUpdateNewHealthLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                <Input
                  id="altitude"
                  name="altitude"
                  type="text"
                  value={getUpdateNewHealthAltitude}
                  onChange={(e) => {
                    setUpdateNewHealthAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getUpdateNewHealthDescription}
                  onChange={(e) => {
                    setUpdateNewHealthDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateHealthRecordHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setUpdateNewHealthRecordPopup(
                    !showUpdateNewHealthRecordPopup
                  );
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Health Record */}

          {/* New Nutrition details */}
          <Dialog
            fullScreen={fullScreen}
            open={showAddNutritionPopup}
            aria-labelledby="updateasset"
          >
            <DialogTitle id="updateasset">New Nutrition details</DialogTitle>
            <DialogContent>
              <div>
                {/* <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset ID :</InputLabel>
                    <Select
                      id="assetid"
                      value={getAddNutritionAssetid}
                      onChange={(e) => setAddNutritionAssetid(e.target.value)}
                      label="assetid"
                    >
                      {getMappedAssets &&
                        getMappedAssets.map((assets, index) => (
                          <MenuItem key={`${index}`} value={assets.assetId}>
                            {assets.assetId}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                  <Input
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={getAddNutritionAssetid}
                    // onChange={(e)=>{setAddNutritionAssetid(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendly">
                    Asset friendly Name:
                  </InputLabel>
                  <Input
                    id="friendly"
                    name="friendly"
                    type="text"
                    value={getAddNutritionAssetFriendlyName}
                    onChange={(e) => {
                      setAddNutritionAssetFriendlyName(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="ExistingAssetStatus">
                    User id:
                  </InputLabel>
                  <Input
                    id="ExistingAssetStatus"
                    name="ExistingAssetStatus"
                    type="text"
                    value={getAddNutritionUserid}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Select Feed Type:</InputLabel>
                  <Select
                    id="feedtype"
                    name="feedtype"
                    type="text"
                    value={getAddNutritionFeedType}
                    onChange={(e) => {
                      setAddNutritionFeedType(e.target.value);
                    }}
                  >
                    <MenuItem key="Milk" value="Milk">
                      Milk
                    </MenuItem>
                    <MenuItem key="Colstrum" value="Colstrum">
                      Colstrum
                    </MenuItem>
                    <MenuItem key="Rough-Age" value="Rough-Age">
                      Rough-Age
                    </MenuItem>
                    <MenuItem key="Concentrate" value="Concentrate">
                      Concentrate
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Select Schedule:</InputLabel>
                  <Select
                    id="Schedule"
                    name="Schedule"
                    type="text"
                    value={getAddNutritionSchedule}
                    onChange={(e) => {
                      setAddNutritionSchedule(e.target.value);
                    }}
                  >
                    <MenuItem key="Morning" value="Morning">
                      Morning
                    </MenuItem>
                    <MenuItem key="Afternoon" value="Afternoon">
                      Afternoon
                    </MenuItem>
                    <MenuItem key="Evening" value="Evening">
                      Evening
                    </MenuItem>
                    <MenuItem key="Night" value="Night">
                      Night
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getAddNutritionLatitude}
                    onChange={(e) => {
                      setAddNutritionLatitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getAddNutritionLongitude}
                    onChange={(e) => {
                      setAddNutritionLongitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getAddNutritionAltitude}
                    onChange={(e) => {
                      setAddNutritionAltitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="address">Comments/Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getAddNutritionDescription}
                    onChange={(e) => {
                      setAddNutritionDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  addNutritionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setAddNutritionPopup(!showAddNutritionPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* New Nutrition details */}

          {/* Delete Nutrition */}
          <Dialog
            fullScreen={fullScreen}
            open={showDeleteNutrition}
            aria-labelledby="Delete Nutrition"
          >
            <DialogTitle id="Delete Nutrition">
              {"Delete Nutrition"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  deleteNutritionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setDeleteNutrition(!showDeleteNutrition);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Nutrition*/}

          {/* Update Nutrition Info */}
          <Dialog
            fullScreen={fullScreen}
            open={showUpdateAddNutritionPopup}
            aria-labelledby="updateasset"
          >
            <DialogTitle id="updateasset">Update Nutrition Info</DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                  <Input
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={getUpdateAddNutritionAssetid}
                    // onChange={(e)=>{setUpdateAddNutritionAssetid(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendly">
                    Asset friendly Name:
                  </InputLabel>
                  <Input
                    id="friendly"
                    name="friendly"
                    type="text"
                    value={getUpdateAddNutritionAssetFriendlyName}
                    onChange={(e) => {
                      setUpdateAddNutritionAssetFriendlyName(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="ExistingAssetStatus">
                    User id:
                  </InputLabel>
                  <Input
                    id="ExistingAssetStatus"
                    name="ExistingAssetStatus"
                    type="text"
                    value={getUpdateAddNutritionUserid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="ExistingFeedtype">
                    Existing Feed type:
                  </InputLabel>
                  <Input
                    id="ExistingFeedtype"
                    name="ExistingFeedtype"
                    type="text"
                    value={getUpdateAddNutritionExistingFeedType}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Select Feed Type:</InputLabel>
                  <Select
                    id="feedtype"
                    name="feedtype"
                    type="text"
                    value={getUpdateAddNutritionFeedType}
                    onChange={(e) => {
                      setUpdateAddNutritionFeedType(e.target.value);
                    }}
                  >
                    <MenuItem key="Milk" value="Milk">
                      Milk
                    </MenuItem>
                    <MenuItem key="Colstrum" value="Colstrum">
                      Colstrum
                    </MenuItem>
                    <MenuItem key="Rough-Age" value="Rough-Age">
                      Rough-Age
                    </MenuItem>
                    <MenuItem key="Concentrate" value="Concentrate">
                      Concentrate
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Select Schedule:</InputLabel>
                  <Select
                    id="Schedule"
                    name="Schedule"
                    type="text"
                    value={getUpdateAddNutritionSchedule}
                    onChange={(e) => {
                      setUpdateAddNutritionSchedule(e.target.value);
                    }}
                  >
                    <MenuItem key="Morning" value="Morning">
                      Morning
                    </MenuItem>
                    <MenuItem key="Afternoon" value="Afternoon">
                      Afternoon
                    </MenuItem>
                    <MenuItem key="Evening" value="Evening">
                      Evening
                    </MenuItem>
                    <MenuItem key="Night" value="Night">
                      Night
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateAddNutritionLatitude}
                    onChange={(e) => {
                      setUpdateAddNutritionLatitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getUpdateAddNutritionLongitude}
                    onChange={(e) => {
                      setUpdateAddNutritionLongitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getUpdateAddNutritionAltitude}
                    onChange={(e) => {
                      setUpdateAddNutritionAltitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="address">Comments/Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getUpdateAddNutritionDescription}
                    onChange={(e) => {
                      setUpdateAddNutritionDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateNutritionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setUpdateAddNutritionPopup(!showUpdateAddNutritionPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Nutrition Info */}

          {/* New Parturition Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showNewParturitionPopup}
            aria-labelledby="updateasset"
          >
            <DialogTitle id="updateasset">New Parturition Details</DialogTitle>
            <DialogContent>
              <div>
                {/* <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset ID :</InputLabel>
                    <Select
                      id="assetid"
                      value={getNewParturitionAssetid}
                      onChange={(e) => setNewParturitionAssetid(e.target.value)}
                      label="assetid"
                    >
                      {getMappedAssets &&
                        getMappedAssets.map((assets, index) => (
                          <MenuItem key={`${index}`} value={assets.assetId}>
                            {assets.assetId}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                  <Input
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={getNewParturitionAssetid}
                    // onChange={(e)=>{setNewParturitionAssetid(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendly">
                    Asset friendly Name:
                  </InputLabel>
                  <Input
                    id="friendly"
                    name="friendly"
                    type="text"
                    value={getNewParturitionAssetfriendlyName}
                    onChange={(e) => {
                      setNewParturitionAssetfriendlyName(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="ExistingAssetStatus">
                    User id:
                  </InputLabel>
                  <Input
                    id="ExistingAssetStatus"
                    name="ExistingAssetStatus"
                    type="text"
                    value={getNewParturitionUserid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Weight">
                    Weight At First Mating:
                  </InputLabel>
                  <Input
                    id="Weight"
                    name="Weight"
                    type="text"
                    value={getNewParturitionWeightAtFirstMating}
                    onChange={(e) => {
                      setNewParturitionWeightAtFirstMating(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Weaning">
                    Age At First Parturition:
                  </InputLabel>
                  <Input
                    id="Weaning"
                    name="Weaning"
                    type="text"
                    value={getNewParturitionAgeAtFirstParturition}
                    onChange={(e) => {
                      setNewParturitionAgeAtFirstParturition(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="WeaningWeight">
                    Body Conditions Index:
                  </InputLabel>
                  <Input
                    id="WeaningWeight"
                    name="WeaningWeight"
                    type="text"
                    value={getNewParturitionBodyConditionsIndex}
                    onChange={(e) => {
                      setNewParturitionBodyConditionsIndex(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Slaughter">
                    Number Of Matings:
                  </InputLabel>
                  <Input
                    id="Slaughter"
                    name="Slaughter"
                    type="text"
                    value={getNewParturitionNumberOfMatings}
                    onChange={(e) => {
                      setNewParturitionNumberOfMatings(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Carcass">Supermogram:</InputLabel>
                  <Input
                    id="Carcass"
                    name="Carcass"
                    type="text"
                    value={getNewParturitionSupermogram}
                    onChange={(e) => {
                      setNewParturitionSupermogram(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">Breed:</InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewParturitionBreed}
                    onChange={(e) => {
                      setNewParturitionBreed(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">Breed Percentage:</InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewParturitionBreedPercentage}
                    onChange={(e) => {
                      setNewParturitionBreedPercentage(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">Breeder Id:</InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewParturitionBreederId}
                    onChange={(e) => {
                      setNewParturitionBreederId(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">Breeder Age:</InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewParturitionBreederAge}
                    onChange={(e) => {
                      setNewParturitionBreederAge(e.target.value);
                    }}
                  />
                </FormControl>
                
                  <InputLabel htmlFor="Gender">Breeding Date:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="Gender"
                    name="Gender"
                    type="date"
                    value={getNewParturitionBreedingDate}
                    onChange={(e) => {
                      setNewParturitionBreedingDate(e.target.value);
                    }}
                  />
                
                
                  <InputLabel htmlFor="Gender">Next Breeding:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="Gender"
                    name="Gender"
                    type="date"
                    value={getNewParturitionNextBreeding}
                    onChange={(e) => {
                      setNewParturitionNextBreeding(e.target.value);
                    }}
                  />
               
                
                  <InputLabel htmlFor="Gender">
                    Expected Kidding Date:
                  </InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="Gender"
                    name="Gender"
                    type="date"
                    value={getNewParturitionExpectedKiddingDate}
                    onChange={(e) => {
                      setNewParturitionExpectedKiddingDate(e.target.value);
                    }}
                  />
              
                
                  <InputLabel htmlFor="Gender">Delivered Date:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="Gender"
                    name="Gender"
                    type="date"
                    value={getNewParturitionDeliveredDate}
                    onChange={(e) => {
                      setNewParturitionDeliveredDate(e.target.value);
                    }}
                  />
                
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">
                    Total Kids Delivered:
                  </InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewParturitionTotalKidsDelivered}
                    onChange={(e) => {
                      setNewParturitionTotalKidsDelivered(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">Disposal:</InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewParturitionDisposal}
                    onChange={(e) => {
                      setNewParturitionDisposal(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getNewParturitionLatitude}
                    onChange={(e) => {
                      setNewParturitionLatitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getNewParturitionLongitude}
                    onChange={(e) => {
                      setNewParturitionLongitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getNewParturitionAltitude}
                    onChange={(e) => {
                      setNewParturitionAltitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="address">Comments/Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getNewParturitionDescription}
                    onChange={(e) => {
                      setNewParturitionDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  addParturitionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setNewParturitionPopup(!showNewParturitionPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of New Parturition Details */}

          {/* Delete Parturition */}
          <Dialog
            fullScreen={fullScreen}
            open={showParturitionDeletePopup}
            aria-labelledby="Delete Parturition"
          >
            <DialogTitle id="Delete Parturition">
              {"Delete Parturition"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  deleteParturitionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setParturitionDeletePopup(!showParturitionDeletePopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Parturition*/}

          {/* Update Parturition Info */}
          <Dialog
            fullScreen={fullScreen}
            open={showUpdateNewParturitionPopup}
            aria-labelledby="updateasset"
          >
            <DialogTitle id="updateasset">Update Parturition Info</DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                  <Input
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={getUpdateNewParturitionAssetid}
                    // onChange={(e)=>{setUpdateNewParturitionAssetid(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendly">
                    Asset friendly Name:
                  </InputLabel>
                  <Input
                    id="friendly"
                    name="friendly"
                    type="text"
                    value={getUpdateNewParturitionAssetfriendlyName}
                    onChange={(e) => {
                      setUpdateNewParturitionAssetfriendlyName(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="ExistingAssetStatus">
                    User id:
                  </InputLabel>
                  <Input
                    id="ExistingAssetStatus"
                    name="ExistingAssetStatus"
                    type="text"
                    value={getUpdateNewParturitionUserid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Weight">
                    Weight At First Mating:
                  </InputLabel>
                  <Input
                    id="Weight"
                    name="Weight"
                    type="text"
                    value={getUpdateNewParturitionWeightAtFirstMating}
                    onChange={(e) => {
                      setUpdateNewParturitionWeightAtFirstMating(
                        e.target.value
                      );
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Parturition">
                    Age At First Parturition:
                  </InputLabel>
                  <Input
                    id="Parturition"
                    name="Parturition"
                    type="text"
                    value={getUpdateNewParturitionAgeAtFirstParturition}
                    onChange={(e) => {
                      setUpdateNewParturitionAgeAtFirstParturition(
                        e.target.value
                      );
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Index">
                    Body Conditions Index:
                  </InputLabel>
                  <Input
                    id="Index"
                    name="Index"
                    type="text"
                    value={getUpdateNewParturitionBodyConditionsIndex}
                    onChange={(e) => {
                      setUpdateNewParturitionBodyConditionsIndex(
                        e.target.value
                      );
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="NumberOfMatings">
                    Number Of Matings:
                  </InputLabel>
                  <Input
                    id="NumberOfMatings"
                    name="NumberOfMatings"
                    type="text"
                    value={getUpdateNewParturitionNumberOfMatings}
                    onChange={(e) => {
                      setUpdateNewParturitionNumberOfMatings(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Supermogram">Supermogram:</InputLabel>
                  <Input
                    id="Supermogram"
                    name="Supermogram"
                    type="text"
                    value={getUpdateNewParturitionSupermogram}
                    onChange={(e) => {
                      setUpdateNewParturitionSupermogram(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Breed">Breed:</InputLabel>
                  <Input
                    id="Breed"
                    name="Breed"
                    type="text"
                    value={getUpdateNewParturitionBreed}
                    onChange={(e) => {
                      setUpdateNewParturitionBreed(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Percentage">
                    Breed Percentage:
                  </InputLabel>
                  <Input
                    id="Percentage"
                    name="Percentage"
                    type="text"
                    value={getUpdateNewParturitionBreedPercentage}
                    onChange={(e) => {
                      setUpdateNewParturitionBreedPercentage(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="BreederId">Breeder Id:</InputLabel>
                  <Input
                    id="BreederId"
                    name="BreederId"
                    type="text"
                    value={getUpdateNewParturitionBreederId}
                    onChange={(e) => {
                      setUpdateNewParturitionBreederId(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="BreederAge">Breeder Age:</InputLabel>
                  <Input
                    id="BreederAge"
                    name="BreederAge"
                    type="text"
                    value={getUpdateNewParturitionBreederAge}
                    onChange={(e) => {
                      setUpdateNewParturitionBreederAge(e.target.value);
                    }}
                  />
                </FormControl>
                
                  <InputLabel htmlFor="BreedingDate">Breeding Date:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="BreedingDate"
                    name="BreedingDate"
                    type="date"
                    defaultValue={getUpdateNewParturitionBreedingDate}
                    onChange={(e) => {
                      setUpdateNewParturitionBreedingDate(e.target.value);
                    }}
                  />
               
                
                  <InputLabel htmlFor="NextBreeding">Next Breeding:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="NextBreeding"
                    name="NextBreeding"
                    type="date"
                    defaultValue={getUpdateNewParturitionNextBreeding}
                    onChange={(e) => {
                      setUpdateNewParturitionNextBreeding(e.target.value);
                    }}
                  />
                
                
                  <InputLabel htmlFor="Expected">
                    Expected Kidding Date:
                  </InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="Expected"
                    name="Expected"
                    type="date"
                    defaultValue={getUpdateNewParturitionExpectedKiddingDate}
                    onChange={(e) => {
                      setUpdateNewParturitionExpectedKiddingDate(
                        e.target.value
                      );
                    }}
                  />
                
                
                  <InputLabel htmlFor="DeliveredDate">
                    Delivered Date:
                  </InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="DeliveredDate"
                    name="DeliveredDate"
                    type="date"
                    defaultValue={getUpdateNewParturitionDeliveredDate}
                    onChange={(e) => {
                      setUpdateNewParturitionDeliveredDate(e.target.value);
                    }}
                  />
            
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Kids">Total Kids Delivered:</InputLabel>
                  <Input
                    id="Kids"
                    name="Kids"
                    type="text"
                    value={getUpdateNewParturitionTotalKidsDelivered}
                    onChange={(e) => {
                      setUpdateNewParturitionTotalKidsDelivered(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Disposal">Disposal:</InputLabel>
                  <Input
                    id="Disposal"
                    name="Disposal"
                    type="text"
                    value={getUpdateNewParturitionDisposal}
                    onChange={(e) => {
                      setUpdateNewParturitionDisposal(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateNewParturitionLatitude}
                    onChange={(e) => {
                      setUpdateNewParturitionLatitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getUpdateNewParturitionLongitude}
                    onChange={(e) => {
                      setUpdateNewParturitionLongitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getUpdateNewParturitionAltitude}
                    onChange={(e) => {
                      setUpdateNewParturitionAltitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="address">Comments/Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getUpdateNewParturitionDescription}
                    onChange={(e) => {
                      setUpdateNewParturitionDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateParturitionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setUpdateNewParturitionPopup(!showUpdateNewParturitionPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Parturition Info */}
          {/* New Parturition Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showNewParturitionPopup}
            aria-labelledby="updateasset"
          >
            <DialogTitle id="updateasset">New Parturition Details</DialogTitle>
            <DialogContent>
              <div>
                {/* <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset ID :</InputLabel>
                    <Select
                      id="assetid"
                      value={getNewParturitionAssetid}
                      onChange={(e) => setNewParturitionAssetid(e.target.value)}
                      label="assetid"
                    >
                      {getMappedAssets &&
                        getMappedAssets.map((assets, index) => (
                          <MenuItem key={`${index}`} value={assets.assetId}>
                            {assets.assetId}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                  <Input
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={getNewParturitionAssetid}
                    // onChange={(e)=>{setNewParturitionAssetid(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendly">
                    Asset friendly Name:
                  </InputLabel>
                  <Input
                    id="friendly"
                    name="friendly"
                    type="text"
                    value={getNewParturitionAssetfriendlyName}
                    onChange={(e) => {
                      setNewParturitionAssetfriendlyName(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="ExistingAssetStatus">
                    User id:
                  </InputLabel>
                  <Input
                    id="ExistingAssetStatus"
                    name="ExistingAssetStatus"
                    type="text"
                    value={getNewParturitionUserid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Weight">
                    Weight At First Mating:
                  </InputLabel>
                  <Input
                    id="Weight"
                    name="Weight"
                    type="text"
                    value={getNewParturitionWeightAtFirstMating}
                    onChange={(e) => {
                      setNewParturitionWeightAtFirstMating(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Weaning">
                    Age At First Parturition:
                  </InputLabel>
                  <Input
                    id="Weaning"
                    name="Weaning"
                    type="text"
                    value={getNewParturitionAgeAtFirstParturition}
                    onChange={(e) => {
                      setNewParturitionAgeAtFirstParturition(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="WeaningWeight">
                    Body Conditions Index:
                  </InputLabel>
                  <Input
                    id="WeaningWeight"
                    name="WeaningWeight"
                    type="text"
                    value={getNewParturitionBodyConditionsIndex}
                    onChange={(e) => {
                      setNewParturitionBodyConditionsIndex(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Slaughter">
                    Number Of Matings:
                  </InputLabel>
                  <Input
                    id="Slaughter"
                    name="Slaughter"
                    type="text"
                    value={getNewParturitionNumberOfMatings}
                    onChange={(e) => {
                      setNewParturitionNumberOfMatings(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Carcass">Supermogram:</InputLabel>
                  <Input
                    id="Carcass"
                    name="Carcass"
                    type="text"
                    value={getNewParturitionSupermogram}
                    onChange={(e) => {
                      setNewParturitionSupermogram(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">Breed:</InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewParturitionBreed}
                    onChange={(e) => {
                      setNewParturitionBreed(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">Breed Percentage:</InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewParturitionBreedPercentage}
                    onChange={(e) => {
                      setNewParturitionBreedPercentage(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">Breeder Id:</InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewParturitionBreederId}
                    onChange={(e) => {
                      setNewParturitionBreederId(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">Breeder Age:</InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewParturitionBreederAge}
                    onChange={(e) => {
                      setNewParturitionBreederAge(e.target.value);
                    }}
                  />
                </FormControl>
                
                  <InputLabel htmlFor="Gender">Breeding Date:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="Gender"
                    name="Gender"
                    type="date"
                    value={getNewParturitionBreedingDate}
                    onChange={(e) => {
                      setNewParturitionBreedingDate(e.target.value);
                    }}
                  />
                
                
                  <InputLabel htmlFor="Gender">Next Breeding:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="Gender"
                    name="Gender"
                    type="date"
                    value={getNewParturitionNextBreeding}
                    onChange={(e) => {
                      setNewParturitionNextBreeding(e.target.value);
                    }}
                  />
                
               
                  <InputLabel htmlFor="Gender">
                    Expected Kidding Date:
                  </InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="Gender"
                    name="Gender"
                    type="date"
                    value={getNewParturitionExpectedKiddingDate}
                    onChange={(e) => {
                      setNewParturitionExpectedKiddingDate(e.target.value);
                    }}
                  />
                
                
                  <InputLabel htmlFor="Gender">Delivered Date:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="Gender"
                    name="Gender"
                    type="date"
                    value={getNewParturitionDeliveredDate}
                    onChange={(e) => {
                      setNewParturitionDeliveredDate(e.target.value);
                    }}
                  />
              
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">
                    Total Kids Delivered:
                  </InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewParturitionTotalKidsDelivered}
                    onChange={(e) => {
                      setNewParturitionTotalKidsDelivered(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">Disposal:</InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewParturitionDisposal}
                    onChange={(e) => {
                      setNewParturitionDisposal(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getNewParturitionLatitude}
                    onChange={(e) => {
                      setNewParturitionLatitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getNewParturitionLongitude}
                    onChange={(e) => {
                      setNewParturitionLongitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getNewParturitionAltitude}
                    onChange={(e) => {
                      setNewParturitionAltitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="address">Comments/Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getNewParturitionDescription}
                    onChange={(e) => {
                      setNewParturitionDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  addParturitionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setNewParturitionPopup(!showNewParturitionPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of New Parturition Details */}

          {/* Delete Parturition */}
          <Dialog
            fullScreen={fullScreen}
            open={showParturitionDeletePopup}
            aria-labelledby="Delete Parturition"
          >
            <DialogTitle id="Delete Parturition">
              {"Delete Parturition"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  deleteParturitionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setParturitionDeletePopup(!showParturitionDeletePopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Parturition*/}

          {/* Update Parturition Info */}
          <Dialog
            fullScreen={fullScreen}
            open={showUpdateNewParturitionPopup}
            aria-labelledby="updateasset"
          >
            <DialogTitle id="updateasset">Update Parturition Info</DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                  <Input
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={getUpdateNewParturitionAssetid}
                    // onChange={(e)=>{setUpdateNewParturitionAssetid(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendly">
                    Asset friendly Name:
                  </InputLabel>
                  <Input
                    id="friendly"
                    name="friendly"
                    type="text"
                    value={getUpdateNewParturitionAssetfriendlyName}
                    onChange={(e) => {
                      setUpdateNewParturitionAssetfriendlyName(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="ExistingAssetStatus">
                    User id:
                  </InputLabel>
                  <Input
                    id="ExistingAssetStatus"
                    name="ExistingAssetStatus"
                    type="text"
                    value={getUpdateNewParturitionUserid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Weight">
                    Weight At First Mating:
                  </InputLabel>
                  <Input
                    id="Weight"
                    name="Weight"
                    type="text"
                    value={getUpdateNewParturitionWeightAtFirstMating}
                    onChange={(e) => {
                      setUpdateNewParturitionWeightAtFirstMating(
                        e.target.value
                      );
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Parturition">
                    Age At First Parturition:
                  </InputLabel>
                  <Input
                    id="Parturition"
                    name="Parturition"
                    type="text"
                    value={getUpdateNewParturitionAgeAtFirstParturition}
                    onChange={(e) => {
                      setUpdateNewParturitionAgeAtFirstParturition(
                        e.target.value
                      );
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Index">
                    Body Conditions Index:
                  </InputLabel>
                  <Input
                    id="Index"
                    name="Index"
                    type="text"
                    value={getUpdateNewParturitionBodyConditionsIndex}
                    onChange={(e) => {
                      setUpdateNewParturitionBodyConditionsIndex(
                        e.target.value
                      );
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="NumberOfMatings">
                    Number Of Matings:
                  </InputLabel>
                  <Input
                    id="NumberOfMatings"
                    name="NumberOfMatings"
                    type="text"
                    value={getUpdateNewParturitionNumberOfMatings}
                    onChange={(e) => {
                      setUpdateNewParturitionNumberOfMatings(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Supermogram">Supermogram:</InputLabel>
                  <Input
                    id="Supermogram"
                    name="Supermogram"
                    type="text"
                    value={getUpdateNewParturitionSupermogram}
                    onChange={(e) => {
                      setUpdateNewParturitionSupermogram(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Breed">Breed:</InputLabel>
                  <Input
                    id="Breed"
                    name="Breed"
                    type="text"
                    value={getUpdateNewParturitionBreed}
                    onChange={(e) => {
                      setUpdateNewParturitionBreed(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Percentage">
                    Breed Percentage:
                  </InputLabel>
                  <Input
                    id="Percentage"
                    name="Percentage"
                    type="text"
                    value={getUpdateNewParturitionBreedPercentage}
                    onChange={(e) => {
                      setUpdateNewParturitionBreedPercentage(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="BreederId">Breeder Id:</InputLabel>
                  <Input
                    id="BreederId"
                    name="BreederId"
                    type="text"
                    value={getUpdateNewParturitionBreederId}
                    onChange={(e) => {
                      setUpdateNewParturitionBreederId(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="BreederAge">Breeder Age:</InputLabel>
                  <Input
                    id="BreederAge"
                    name="BreederAge"
                    type="text"
                    value={getUpdateNewParturitionBreederAge}
                    onChange={(e) => {
                      setUpdateNewParturitionBreederAge(e.target.value);
                    }}
                  />
                </FormControl>
                
                  <InputLabel htmlFor="BreedingDate">Breeding Date:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="BreedingDate"
                    name="BreedingDate"
                    type="date"
                    defaultValue={getUpdateNewParturitionBreedingDate}
                    onChange={(e) => {
                      setUpdateNewParturitionBreedingDate(e.target.value);
                    }}
                  />
                
                
                  <InputLabel htmlFor="NextBreeding">Next Breeding:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="NextBreeding"
                    name="NextBreeding"
                    type="date"
                    defaultValue={getUpdateNewParturitionNextBreeding}
                    onChange={(e) => {
                      setUpdateNewParturitionNextBreeding(e.target.value);
                    }}
                  />
            
                
                  <InputLabel htmlFor="Expected">
                    Expected Kidding Date:
                  </InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="Expected"
                    name="Expected"
                    type="date"
                    defaultValue={getUpdateNewParturitionExpectedKiddingDate}
                    onChange={(e) => {
                      setUpdateNewParturitionExpectedKiddingDate(
                        e.target.value
                      );
                    }}
                  />
              
                
                  <InputLabel htmlFor="DeliveredDate">
                    Delivered Date:
                  </InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="DeliveredDate"
                    name="DeliveredDate"
                    type="date"
                    defaultValue={getUpdateNewParturitionDeliveredDate}
                    onChange={(e) => {
                      setUpdateNewParturitionDeliveredDate(e.target.value);
                    }}
                  />
                
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Kids">Total Kids Delivered:</InputLabel>
                  <Input
                    id="Kids"
                    name="Kids"
                    type="text"
                    value={getUpdateNewParturitionTotalKidsDelivered}
                    onChange={(e) => {
                      setUpdateNewParturitionTotalKidsDelivered(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Disposal">Disposal:</InputLabel>
                  <Input
                    id="Disposal"
                    name="Disposal"
                    type="text"
                    value={getUpdateNewParturitionDisposal}
                    onChange={(e) => {
                      setUpdateNewParturitionDisposal(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateNewParturitionLatitude}
                    onChange={(e) => {
                      setUpdateNewParturitionLatitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getUpdateNewParturitionLongitude}
                    onChange={(e) => {
                      setUpdateNewParturitionLongitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getUpdateNewParturitionAltitude}
                    onChange={(e) => {
                      setUpdateNewParturitionAltitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="address">Comments/Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getUpdateNewParturitionDescription}
                    onChange={(e) => {
                      setUpdateNewParturitionDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateParturitionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setUpdateNewParturitionPopup(!showUpdateNewParturitionPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Parturition Info */}
          {/* New Mortality Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showNewMortalityPopuop}
            aria-labelledby="updateasset"
          >
            <DialogTitle id="updateasset">New Mortality Details</DialogTitle>
            <DialogContent>
              <div>
                {/* <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputLabel>Asset ID :</InputLabel>
                    <Select
                      id="assetid"
                      value={getNewMortalityAssetid}
                      onChange={(e) => setNewMortalityAssetid(e.target.value)}
                      label="assetid"
                    >
                      {getMappedAssets &&
                        getMappedAssets.map((assets, index) => (
                          <MenuItem key={`${index}`} value={assets.assetId}>
                            {assets.assetId}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                  <Input
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={getNewMortalityAssetid}
                    // onChange={(e)=>{setNewMortalityAssetid(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendly">
                    Asset friendly Name:
                  </InputLabel>
                  <Input
                    id="friendly"
                    name="friendly"
                    type="text"
                    value={getNewMortalityAssetFriendlyName}
                    onChange={(e) => {
                      setNewMortalityAssetFriendlyName(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="ExistingAssetStatus">
                    User id:
                  </InputLabel>
                  <Input
                    id="ExistingAssetStatus"
                    name="ExistingAssetStatus"
                    type="text"
                    value={getNewMortalityUserid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modelid">Model id:</InputLabel>
                  <Input
                    id="modelid"
                    name="modelid"
                    type="text"
                    value={getNewMortalityModelid}
                    onChange={(e) => {
                      setNewMortalityModelid(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="BreedPercentage">
                    Breed Percentage:
                  </InputLabel>
                  <Input
                    id="BreedPercentage"
                    name="BreedPercentage"
                    type="text"
                    value={getNewMortalityBreedPercentage}
                    onChange={(e) => {
                      setNewMortalityBreedPercentage(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">Gender:</InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getNewMortalityGender}
                    onChange={(e) => {
                      setNewMortalityGender(e.target.value);
                    }}
                  />
                </FormControl>
                
                  <InputLabel htmlFor="BirthDate">Birth Date:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="BirthDate"
                    name="BirthDate"
                    type="date"
                    value={getNewMortalityBirthDate}
                    onChange={(e) => {
                      setNewMortalityBirthDate(e.target.value);
                    }}
                  />
                
                
                  <InputLabel htmlFor="ExpiredDate">Expired Date:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="ExpiredDate"
                    name="ExpiredDate"
                    type="date"
                    value={getNewMortalityExpiredDate}
                    onChange={(e) => {
                      setNewMortalityExpiredDate(e.target.value);
                    }}
                  />
            
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="TotalAge">Total Age:</InputLabel>
                  <Input
                    id="TotalAge"
                    name="TotalAge"
                    type="text"
                    value={getNewMortalityTotalAge}
                    onChange={(e) => {
                      setNewMortalityTotalAge(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="CurrentValue">Current Value:</InputLabel>
                  <Input
                    id="CurrentValue"
                    name="CurrentValue"
                    type="text"
                    value={getNewMortalityCurrentValue}
                    onChange={(e) => {
                      setNewMortalityCurrentValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="CauseOfDeath">
                    Cause Of Death:
                  </InputLabel>
                  <Input
                    id="CauseOfDeath"
                    name="CauseOfDeath"
                    type="text"
                    value={getNewMortalityCauseOfDeath}
                    onChange={(e) => {
                      setNewMortalityCauseOfDeath(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getNewMortalityLatitude}
                    onChange={(e) => {
                      setNewMortalityLatitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getNewMortalityLongitude}
                    onChange={(e) => {
                      setNewMortalityLongitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getNewMortalityAltitude}
                    onChange={(e) => {
                      setNewMortalityAltitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="address">Comments/Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getNewMortalityDescription}
                    onChange={(e) => {
                      setNewMortalityDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  addMortalityHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setNewMortalityPopuop(!showNewMortalityPopuop);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of New Mortality Details */}

          {/* Delete Mortality */}
          <Dialog
            fullScreen={fullScreen}
            open={showMortalityDeletePopup}
            aria-labelledby="Delete Mortality"
          >
            <DialogTitle id="Delete Mortality">
              {"Delete Mortality"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  deleteMortalityHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setMortalityDeletePopup(!showMortalityDeletePopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Mortality*/}

          {/* Update Mortality Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showUpdateNewMortalityPopuop}
            aria-labelledby="updateasset"
          >
            <DialogTitle id="updateasset">Update Mortality Info</DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId">Asset id:</InputLabel>
                  <Input
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={getUpdateNewMortalityAssetid}
                    // onChange={(e)=>{setUpdateNewMortalityAssetid(e.target.value)}}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="friendly">
                    Asset friendly Name:
                  </InputLabel>
                  <Input
                    id="friendly"
                    name="friendly"
                    type="text"
                    value={getUpdateNewMortalityAssetFriendlyName}
                    onChange={(e) => {
                      setUpdateNewMortalityAssetFriendlyName(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="ExistingAssetStatus">
                    User id:
                  </InputLabel>
                  <Input
                    id="ExistingAssetStatus"
                    name="ExistingAssetStatus"
                    type="text"
                    value={getUpdateNewMortalityUserid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modelid">Model id:</InputLabel>
                  <Input
                    id="modelid"
                    name="modelid"
                    type="text"
                    value={getUpdateNewMortalityModelid}
                    onChange={(e) => {
                      setUpdateNewMortalityModelid(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="BreedPercentage">
                    Breed Percentage:
                  </InputLabel>
                  <Input
                    id="BreedPercentage"
                    name="BreedPercentage"
                    type="text"
                    value={getUpdateNewMortalityBreedPercentage}
                    onChange={(e) => {
                      setUpdateNewMortalityBreedPercentage(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="Gender">Gender:</InputLabel>
                  <Input
                    id="Gender"
                    name="Gender"
                    type="text"
                    value={getUpdateNewMortalityGender}
                    onChange={(e) => {
                      setUpdateNewMortalityGender(e.target.value);
                    }}
                  />
                </FormControl>
                
                  <InputLabel htmlFor="BirthDate">Birth Date:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="BirthDate"
                    name="BirthDate"
                    type="date"
                    defaultValue={getUpdateNewMortalityBirthDate}
                    onChange={(e) => {
                      setUpdateNewMortalityBirthDate(e.target.value);
                    }}
                  />
                
                
                  <InputLabel htmlFor="ExpiredDate">Expired Date:</InputLabel>
                  <Input
                  fullWidth={true} className="loginFields"
                    id="ExpiredDate"
                    name="ExpiredDate"
                    type="date"
                    defaultValue={getUpdateNewMortalityExpiredDate}
                    onChange={(e) => {
                      setUpdateNewMortalityExpiredDate(e.target.value);
                    }}
                  />
                
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="TotalAge">Total Age:</InputLabel>
                  <Input
                    id="TotalAge"
                    name="TotalAge"
                    type="text"
                    value={getUpdateNewMortalityTotalAge}
                    onChange={(e) => {
                      setUpdateNewMortalityTotalAge(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="CurrentValue">Current Value:</InputLabel>
                  <Input
                    id="CurrentValue"
                    name="CurrentValue"
                    type="text"
                    value={getUpdateNewMortalityCurrentValue}
                    onChange={(e) => {
                      setUpdateNewMortalityCurrentValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="CauseOfDeath">
                    Cause Of Death:
                  </InputLabel>
                  <Input
                    id="CauseOfDeath"
                    name="CauseOfDeath"
                    type="text"
                    value={getUpdateNewMortalityCauseOfDeath}
                    onChange={(e) => {
                      setUpdateNewMortalityCauseOfDeath(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={getUpdateNewMortalityLatitude}
                    onChange={(e) => {
                      setUpdateNewMortalityLatitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={getUpdateNewMortalityLongitude}
                    onChange={(e) => {
                      setUpdateNewMortalityLongitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={getUpdateNewMortalityAltitude}
                    onChange={(e) => {
                      setUpdateNewMortalityAltitude(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="address">Comments/Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getUpdateNewMortalityDescription}
                    onChange={(e) => {
                      setUpdateNewMortalityDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateMortalityHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setUpdateNewMortalityPopuop(!showUpdateNewMortalityPopuop);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Mortality Details */}

          {/* Mapped Asset Popup */}
          <Dialog
            fullScreen={fullScreen}
            open={mapPopUp}
            aria-labelledby="mapasset"
          >
            <DialogTitle id="mapasset">MAP Asset With Site</DialogTitle>
            <DialogContent>
              <div>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Site Id</InputLabel>
                  <Select
                    id="selectedModel"
                    value={mapSiteId}
                    onChange={(event) => setMapSiteId(event.target.value)}
                    label="Select Model"
                  >
                    {getAllSites &&
                      getAllSites.map((idObj, index) => (
                        <MenuItem key={`${index} `} value={idObj.siteid}>
                          {idObj.sitename}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId"> Asset Id:</InputLabel>
                  <Input
                    disabled
                    id="assetId"
                    name="assetId"
                    type="text"
                    value={mapAssetId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="categoryType">
                    {" "}
                    Category Type:
                  </InputLabel>
                  <Input
                    disabled
                    id="categoryType"
                    name="categoryType"
                    type="text"
                    value={mapCategoryType}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  addAssetToSiteMappingHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setMapPopUp(!mapPopUp);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Mapped Asset Popup */}

          {/* Delete Asset */}
          <Dialog
            fullScreen={fullScreen}
            open={getShowDeletePopup}
            aria-labelledby="DeleteAsset"
          >
            <DialogTitle id="DeleteAsset">{"DeleteAsset"}</DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  assetDeleteHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowDeletePopup(!getShowDeletePopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Asset */}

          {/* ASSET INFORMATION UPDATE */}
          <Dialog
            fullScreen={fullScreen}
            open={getlegacyUpdatePopup}
            aria-labelledby="assetupdate"
          >
            <DialogTitle id="assetupdate">ASSET INFORMATION</DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="solutionid">SOLUTION ID:</InputLabel>
                  <Input
                    disabled
                    id="solutionid"
                    name="solutionid"
                    type="text"
                    value={username}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetverticalid">
                    ASSET VERTICAL ID:
                  </InputLabel>
                  <Input
                    disabled
                    id="assetverticalid"
                    name="assetverticalid"
                    type="text"
                    value={assetVeriticalId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assettype">ASSET TYPE:</InputLabel>
                  <Input
                    disabled
                    id="assettype"
                    name="assettype"
                    type="text"
                    value={assetTypeVal}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetid">ASSET ID:</InputLabel>
                  <Input
                    disabled
                    id="assetid"
                    name="assetid"
                    type="text"
                    value={assetIdVal}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetfname">
                    ASSET FRIENDLY NAME:
                  </InputLabel>
                  <Input
                    disabled
                    id="assetfname"
                    name="assetfname"
                    type="text"
                    value={assetFriName}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modelid">MODEL ID:</InputLabel>
                  <Input
                    disabled
                    id="modelid"
                    name="modelid"
                    type="text"
                    value={modelIdVal}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="category">CATEGORY:</InputLabel>
                  <Input
                    disabled
                    id="category"
                    name="category"
                    type="text"
                    value={categoryVal}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="manufacturerid">
                    MANUFACTURER ID:
                  </InputLabel>
                  <Input
                    disabled
                    id="manufacturerid"
                    name="manufacturerid"
                    type="text"
                    value={username}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="udn">UDN:</InputLabel>
                  <Input
                    disabled
                    id="udn"
                    name="udn"
                    type="text"
                    value={udnVal}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
                  <Input
                    disabled
                    id="whitecost"
                    name="whitecost"
                    type="text"
                    value={whiteLabelVal}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
                  <Input
                    disabled
                    id="sellingcost"
                    name="sellingcost"
                    type="text"
                    value={sellingCostVal}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetstatus">
                    EXISTING ASSET STATUS:
                  </InputLabel>
                  <Input
                    disabled
                    id="assetstatus"
                    name="assetstatus"
                    type="text"
                    value={exAssetStatus}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>UPDATED ASSET STATUS</InputLabel>
                  <Select
                    id="selectedModel"
                    value={updateAssetStatus}
                    onChange={(event) =>
                      setUpdateAssetStatus(event.target.value)
                    }
                    label="Select Model"
                  >
                    <MenuItem selected>Select Any</MenuItem>
                    <MenuItem key="PrematureSale" value="PrematureSale">
                      Premature Sale
                    </MenuItem>
                    <MenuItem key="Lost" value="Lost">
                      Lost
                    </MenuItem>
                    <MenuItem key="Damaged" value="Damaged">
                      Damaged
                    </MenuItem>
                    <MenuItem key="UnderMaintenance" value="UnderMaintenance">
                      Under-Maintenance
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateAssetHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setLegacyUpdatePopup(!getlegacyUpdatePopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of ASSET INFORMATION UPDATE */}

          {/* Create Asset */}
          <Dialog
            fullScreen={fullScreen}
            open={getCreateAssetPopup}
            aria-labelledby="createasset"
          >
            <DialogTitle id="createasset">Create Asset</DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetDeveloperId">
                    AssetDeveloper ID:
                  </InputLabel>
                  <Input
                    disabled
                    id="assetDeveloperId"
                    name="assetDeveloperId"
                    type="text"
                    value={username}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetId">Legacy Asset ID :</InputLabel>
                  <Input
                    id="assetId"
                    name="assetId"
                    type="text"
                    placeholder="5533222"
                    value={assetIdValue}
                    onChange={(e) => {
                      setAssetIdValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="legacyAssetFriendlyName">
                    Legacy Asset Friendly Name :
                  </InputLabel>
                  <Input
                    id="legacyAssetFriendlyName"
                    name="legacyAssetFriendlyName"
                    placeholder="Betal Sheep"
                    type="text"
                    value={legacyAssetFriendlyNameValue}
                    onChange={(e) => {
                      setLegacyAssetFriendlyNameValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>AssetVerticalId:</InputLabel>
                  <Select
                    id="assetVerticalID"
                    name="assetVerticalID"
                    type="text"
                    value={selectedVerticalsId}
                    onChange={(e) => {
                      setSelectedVerticalsIdHandler(e);
                    }}
                  >
                    {verticalsIdList.length > 0 ? (
                      verticalsIdList.map((idObj, index) => (
                        <MenuItem
                          key={`${index} `}
                          value={idObj.assetVerticalId}
                        >
                          {idObj.friendlyName}
                        </MenuItem>
                      ))
                    ) : (
                      <h4>Asset Vertical not found</h4>
                    )}
                  </Select>
                </FormControl>
                
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Category Type*</InputLabel>
                  <Select
                    id="categoryType"
                    name="categoryType"
                    type="text"
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedCategoryHandler(e);
                    }}
                  >
                    {categoryTypesList &&
                      categoryTypesList.map((idObj, index) => (
                        <MenuItem key={`${index} `} value={idObj.categoryType}>
                          {idObj.categoryType}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Asset Type</InputLabel>
                  <Select
                    id="selectedAssertTypes"
                    value={selectedAssertTypes}
                    onChange={(e) => setSelectedAssertTypesHandler(e)}
                    label="Select Type"
                  >
                    {assertTypesList &&
                      assertTypesList.map((idObj, index) => (
                        <MenuItem key={`${index} `} value={idObj.assetType}>
                          {idObj.assetType}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Model</InputLabel>
                  <Select
                    id="selectedModel"
                    value={selectedModel}
                    onChange={(event) => setSelectedModel(event.target.value)}
                    label="Select Model"
                  >
                    {modelList &&
                      modelList.map((idObj, index) => (
                        <MenuItem key={`${index} `} value={idObj.modelid}>
                          {idObj.modelfriendlyname}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modelType"> Model Type:</InputLabel>
                  <Input
                    disabled
                    id="modelType"
                    name="modelType"
                    type="text"
                    value={selectedModel}
                    /* onChange={(e) => {
                        setModelTypeValue(e.target.value);
                      }} */
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Asset Domain</InputLabel>
                  <Select
                    id="assetDomain"
                    name="assetDomain"
                    type="text"
                    value={assetDomainValue}
                    onChange={(e) => {
                      setAssetDomainValue(e.target.value);
                    }}
                  >
                    <MenuItem selected>Select</MenuItem>
                    <MenuItem key="Farm" value="Farm">
                      Farm
                    </MenuItem>
                    <MenuItem key="Field" value="Field">
                      Field
                    </MenuItem>
                    <MenuItem key="Enterprise" value="Enterprise">
                      Enterprise
                    </MenuItem>
                    <MenuItem key="Home" value="Home">
                      Home
                    </MenuItem>
                    <MenuItem key="Personal" value="Personal">
                      Personal
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <InputLabel>Site Name*</InputLabel>
                  <Select
                    id="categoryType"
                    name="categoryType"
                    type="text"
                    value={getMappedAssetsSiteName}
                    
                  >
                    
                  </Select>
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="zone"> Zone (Lot Id):</InputLabel>
                  <Input
                    id="zone"
                    name="zone"
                    type="text"
                    value={zoneValue}
                    onChange={(e) => {
                      setZoneValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="lotName"> Lot Name:</InputLabel>
                  <Input
                    id="lotName"
                    name="lotName"
                    type="text"
                    value={lotNameValue}
                    onChange={(e) => {
                      setLotNameValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="manufacturerId">
                    {" "}
                    Manufacturer Id *:
                  </InputLabel>
                  <Input
                    disabled
                    id="manufacturerId"
                    name="manufacturerId"
                    type="text"
                    value={manufacturerIdValue}
                    onChange={(e) => {
                      setManufacturerIdValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="manufacturerUrl">
                    {" "}
                    Manufacturer Url:
                  </InputLabel>
                  <Input
                    id="manufacturerUrl"
                    name="manufacturerUrl"
                    type="text"
                    value={manufacturerUrlValue}
                    onChange={(e) => {
                      setManufacturerUrlValue(e.target.value);
                    }}
                  />
                </FormControl>
                
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="modelUrl"> Model Url:</InputLabel>
                  <Input
                    id="modelUrl"
                    name="modelUrl"
                    type="text"
                    value={modelUrlValue}
                    onChange={(e) => {
                      setModelUrlValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="serialNo"> Serial No:</InputLabel>
                  <Input
                    id="serialNo"
                    name="serialNo"
                    type="text"
                    value={serialNoValue}
                    onChange={(e) => {
                      setSerialNoValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="udn"> UDN*:</InputLabel>
                  <Input
                    id="udn"
                    name="udn"
                    type="text"
                    value={udnValue}
                    onChange={(e) => {
                      setUdnValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="upc"> UPC:</InputLabel>
                  <Input
                    id="upc"
                    name="upc"
                    type="text"
                    value={upcValue}
                    onChange={(e) => {
                      setUpcValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="motherBreed"> Mother Breed:</InputLabel>
                  <Input
                    id="motherBreed"
                    name="motherBreed"
                    type="text"
                    value={motherBreedValue}
                    onChange={(e) => {
                      setMotherBreedValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="fatherBreed"> Father Breed:</InputLabel>
                  <Input
                    id="fatherBreed"
                    name="fatherBreed"
                    type="text"
                    value={fatherBreedValue}
                    onChange={(e) => {
                      setFatherBreedValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="description"> Description:</InputLabel>
                  <Input
                    id="description"
                    name="description"
                    type="text"
                    value={descriptionValue}
                    onChange={(e) => {
                      setDescriptionValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="width"> Width:</InputLabel>
                  <Input
                    id="width"
                    name="width"
                    type="text"
                    value={widthValue}
                    onChange={(e) => {
                      setWidthValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="height"> Height:</InputLabel>
                  <Input
                    id="height"
                    name="height"
                    type="text"
                    value={heightValue}
                    onChange={(e) => {
                      setHeightValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="depth"> Depth:</InputLabel>
                  <Input
                    id="depth"
                    name="depth"
                    type="text"
                    value={depthValue}
                    onChange={(e) => {
                      setDepthValue(e.target.value);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="whiteLabelCost">
                    {" "}
                    WhiteLabel Cost:
                  </InputLabel>
                  <Input
                    id="whiteLabelCost"
                    name="whiteLabelCost"
                    type="text"
                    value={whiteLabelCostValue}
                    onChange={(e) => {
                      setWhiteLabelCostValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="sellingCost"> Selling Cost:</InputLabel>
                  <Input
                    id="sellingCost"
                    name="sellingCost"
                    type="text"
                    value={sellingCostValue}
                    onChange={(e) => {
                      setSellingCostValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="acquireCost"> Acquire Cost*:</InputLabel>
                  <Input
                    id="acquireCost"
                    name="acquireCost"
                    type="text"
                    value={acquireCostValue}
                    onChange={(e) => {
                      setAcquireCostValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="latitude"> Latitude:</InputLabel>
                  <Input
                    id="latitude"
                    name="latitude"
                    type="text"
                    value={latitudeValue}
                    onChange={(e) => {
                      setLatitudeValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="longitude">Longitude:</InputLabel>
                  <Input
                    id="longitude"
                    name="longitude"
                    type="text"
                    value={longitudeValue}
                    onChange={(e) => {
                      setLongitudeValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="altitude"> Altitude:</InputLabel>
                  <Input
                    id="altitude"
                    name="altitude"
                    type="text"
                    value={altitudeValue}
                    onChange={(e) => {
                      setAltitudeValue(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  createAssetClick();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setCreateAssetPopup(!getCreateAssetPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Create Asset */}

          {/* Site Feedback */}
          <Dialog
            fullScreen={fullScreen}
            open={showSiteFeedbackModel}
            aria-labelledby="sitefeedback"
          >
            <DialogTitle id="sitefeedback">
              {"Record Site Feedback"}
            </DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="siteid">Site id:</InputLabel>
                  <Input
                    id="siteid"
                    name="siteid"
                    type="text"
                    value={getSiteFeedbackSiteid}
                  />
                </FormControl>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    How would you rate your overall customer experience?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="customer-experience"
                      value={getSiteCustomerExperience}
                      precision={1}
                      getLabelText={getLabelCustomerExperience}
                      onChange={(event, value) => {
                        setSiteCustomerExperience(value);
                        setSiteFeedbackCustomerExperience(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteCustomerExperienceHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getSiteCustomerExperience !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            getSiteCustomerExperienceHover !== -1
                              ? getSiteCustomerExperienceHover
                              : getSiteCustomerExperience
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    how_satisfied_were_you_with_the_product?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="product"
                      value={getSiteProduct}
                      precision={1}
                      getLabelText={getLabelProduct}
                      onChange={(event, value) => {
                        setSiteProduct(value);
                        setSiteFeedbackProduct(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteProductHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getSiteProduct !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            getSiteProductHover !== -1
                              ? getSiteProductHover
                              : getSiteProduct
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    how_satisfied_were_you_with_the_customer_support?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="customer_support"
                      value={getSiteCustomerSupport}
                      precision={1}
                      getLabelText={getLabelCustomerSupport}
                      onChange={(event, value) => {
                        setSiteCustomerSupport(value);
                        setSiteFeedbackCustomerSupport(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteCustomerSupportHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getSiteCustomerSupport !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            getSiteCustomerSupportHover !== -1
                              ? getSiteCustomerSupportHover
                              : getSiteCustomerSupport
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    how_satisfied_were_you_with_the_timeliness_of_delivery?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="delivery"
                      value={getSiteDelivery}
                      precision={1}
                      getLabelText={getLabelCustomerDelivery}
                      onChange={(event, value) => {
                        setSiteDelivery(value);
                        setSiteFeedbackDelivery(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteDeliveryHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getSiteDelivery !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            getSiteDeliveryHover !== -1
                              ? getSiteDeliveryHover
                              : getSiteDelivery
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    would_you_recommend_our_product_or_service_to_others?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="serviceothers"
                      value={getSiteServiceOthers}
                      precision={1}
                      getLabelText={getLabelServiceOthers}
                      onChange={(event, value) => {
                        setSiteServiceOthers(value);
                        setSiteFeedbackServiceOthers(labels[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setSiteServiceOthersHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getSiteServiceOthers !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labels[
                            getSiteServiceOthersHover !== -1
                              ? getSiteServiceOthersHover
                              : getSiteServiceOthers
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="remark">Remark:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getSiteFeedbackRemark}
                    onChange={(e) => {
                      setSiteFeedbackRemark(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  siteFeedbackSubmit();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowSiteFeedbackModel(!showSiteFeedbackModel);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Site Feedback */}

          {/* Asset Feedback */}
          <Dialog
            fullScreen={fullScreen}
            open={showAssetFeedbackModel}
            aria-labelledby="assetfeedback"
          >
            <DialogTitle id="assetfeedback">
              {"Record Asset Feedback"}
            </DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetdevid">Asset id:</InputLabel>
                  <Input
                    id="assetdevid"
                    name="assetdevid"
                    type="text"
                    value={getAssetFeedbackAssetDeveloperid}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetid">Asset id:</InputLabel>
                  <Input
                    id="assetid"
                    name="assetid"
                    type="text"
                    value={getAssetFeedbackAssetid}
                  />
                </FormControl>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    How would you rate your overall customer experience?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="customerexperience"
                      value={getAssetCustomerExperience}
                      precision={1}
                      getLabelText={getLabelAssetCustomerExperience}
                      onChange={(event, value) => {
                        setAssetCustomerExperience(value);
                        setAssetFeedbackCustomerExperience(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetCustomerExperienceHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getAssetCustomerExperience !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labelsAsset[
                            getAssetCustomerExperienceHover !== -1
                              ? getAssetCustomerExperienceHover
                              : getAssetCustomerExperience
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    how_satisfied_were_you_with_the_product?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="product"
                      value={getAssetProduct}
                      precision={1}
                      getLabelText={getLabelAssetProduct}
                      onChange={(event, value) => {
                        setAssetProduct(value);
                        setAssetFeedbackProduct(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetProductHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getAssetProduct !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labelsAsset[
                            getAssetProductHover !== -1
                              ? getAssetProductHover
                              : getAssetProduct
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    how_satisfied_were_you_with_the_customer_support?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="customersupport"
                      value={getAssetCustomerSupport}
                      precision={1}
                      getLabelText={getLabelAssetCustomerSupport}
                      onChange={(event, value) => {
                        setAssetCustomerSupport(value);
                        setAssetFeedbackCustomerSupport(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetCustomerSupportHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getAssetCustomerSupport !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labelsAsset[
                            getAssetCustomerSupportHover !== -1
                              ? getAssetCustomerSupportHover
                              : getAssetCustomerSupport
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    how_satisfied_were_you_with_the_timeliness_of_delivery?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="delivery"
                      value={getAssetDelivery}
                      precision={1}
                      getLabelText={getLabelAssetCustomerDelivery}
                      onChange={(event, value) => {
                        setAssetDelivery(value);
                        setAssetFeedbackDelivery(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetDeliveryHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getAssetDelivery !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labelsAsset[
                            getAssetDeliveryHover !== -1
                              ? getAssetDeliveryHover
                              : getAssetDelivery
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <Typography>
                  <h4 style={{ width: "100%" }}>
                    would_you_recommend_our_product_or_service_to_others?
                  </h4>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="service-others"
                      value={getAssetServiceOthers}
                      precision={1}
                      getLabelText={getLabelAssetServiceOthers}
                      onChange={(event, value) => {
                        setAssetServiceOthers(value);
                        setAssetFeedbackServiceOthers(labelsAsset[value]);
                      }}
                      onChangeActive={(event, hover) => {
                        setAssetServiceOthersHover(hover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {getAssetServiceOthers !== null && (
                      <Box sx={{ ml: 2 }}>
                        {
                          labelsAsset[
                            getAssetServiceOthersHover !== -1
                              ? getAssetServiceOthersHover
                              : getAssetServiceOthers
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </Typography>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="remark">Remark:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getAssetFeedbackRemark}
                    onChange={(e) => {
                      setAssetFeedbackRemark(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  assetFeedbackSubmit();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowAssetFeedbackModel(!showAssetFeedbackModel);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Asset Feedback */}

          {/* Create Site Issue */}
          <Dialog
            fullScreen={fullScreen}
            open={showSiteIssue}
            aria-labelledby="siteissue"
          >
            <DialogTitle id="siteissue">Raise Site Issue</DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="username">User Name:</InputLabel>
                  <Input
                    id="username"
                    name="username"
                    type="text"
                    value={getSiteIssueSolutionId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="siteid">Site Id:</InputLabel>
                  <Input
                    id="siteid"
                    name="siteid"
                    type="text"
                    value={getSiteIssueSiteId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="address">Issue Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getSiteIssueDescription}
                    onChange={(e) => {
                      setSiteIssueDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  siteIssueHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  getShowSiteIssue(!showSiteIssue);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Create Site Issue */}

          {/* Site Issue Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showSiteIssueDetailsModel}
            aria-labelledby="siteissuedetails"
          >
            <DialogTitle id="siteissuedetails">
              {"Site Issue Details"}
            </DialogTitle>
            <DialogContent>
              {getSiteIssueDetails.length > 0 ? (
                <div>
                  {getSiteIssueDetails &&
                    getSiteIssueDetails.map((site, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Operator ID:</b>
                            {site.operatorid}
                          </p>
                          <p>
                            <b>User Name:</b>
                            {site.solutionid}
                          </p>
                          <p>
                            <b>Site ID:</b>
                            {site.siteid}
                          </p>
                          <p>
                            <b>Issue Description:</b>
                            {site.issuedescription}
                          </p>
                        </span>
                      </div>
                    ))}
                </div>
              ) : (
                <h4 className="cardStyleForDashboardItemsBody">
                  Issues Data Found
                </h4>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  getShowSiteIssueDetailsModel(!showSiteIssueDetailsModel);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Site Issue Details */}

          {/* Create Asset Issue */}
          <Dialog
            fullScreen={fullScreen}
            open={showAssetIssue}
            aria-labelledby="assetissue"
          >
            <DialogTitle id="assetissue">Raise Asset Issue</DialogTitle>
            <DialogContent>
              <div>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="username">User Name:</InputLabel>
                  <Input
                    id="username"
                    name="username"
                    type="text"
                    value={getAssetIssueSolutionId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="siteid">Site Id:</InputLabel>
                  <Input
                    id="siteid"
                    name="siteid"
                    type="text"
                    value={getAssetIssueSiteId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <InputLabel htmlFor="assetid">Asset Id:</InputLabel>
                  <Input
                    id="assetid"
                    name="assetid"
                    type="text"
                    value={getAssetIssueAssetId}
                  />
                </FormControl>
                <FormControl fullWidth={true} className="loginFields">
                  <p htmlFor="address">Issue Description:</p>
                  <TextareaAutosize
                    minRows={5}
                    style={{ minwidth: 200 }}
                    value={getAssetIssueDescription}
                    onChange={(e) => {
                      setAssetIssueDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  assetIssueHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  getShowAssetIssue(!showAssetIssue);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Create Asset Issue */}

          {/* Asset Issue Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showAssetIssueDetailsModel}
            aria-labelledby="assetissuedetails"
          >
            <DialogTitle id="assetissuedetails">
              {"Asset Issue Details"}
            </DialogTitle>
            <DialogContent>
              {getAssetIssueDetails.length > 0 ? (
                <div>
                  {getAssetIssueDetails &&
                    getAssetIssueDetails.map((asset, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Operator ID:</b>
                            {asset.operatorid}
                          </p>
                          <p>
                            <b>User Name:</b>
                            {asset.solutionid}
                          </p>
                          <p>
                            <b>Site ID:</b>
                            {asset.siteid}
                          </p>
                          <p>
                            <b>Site ID:</b>
                            {asset.siteid}
                          </p>
                          <p>
                            <b>Issue Description:</b>
                            {asset.issuedescription}
                          </p>
                        </span>
                      </div>
                    ))}
                </div>
              ) : (
                <h4 className="cardStyleForDashboardItemsBody">
                  No Data Found
                </h4>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  getShowAssetIssueDetailsModel(!showAssetIssueDetailsModel);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Asset Issue Details */}

          {/* Add Daily Report */}
          <Dialog
            fullScreen={fullScreen}
            open={showAddDailyReportModel}
            aria-labelledby="dailyreport"
          >
            <DialogTitle id="dailyreport">{"Daily Report"}</DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetid">Asset ID:</InputLabel>
                <Input
                  id="assetid"
                  name="assetid"
                  type="text"
                  value={getAddDailyReportAssetid}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Have you recorded the Nutrition today?</InputLabel>
                <Select
                  id="Nutrition"
                  value={getAddDailyReportNutrition}
                  onChange={(e) => setAddDailyReportNutrition(e.target.value)}
                  label="Nutrition"
                >
                  <MenuItem key="yes" value="yes">
                    Yes
                  </MenuItem>
                  <MenuItem key="no" value="no">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Have you recorded the Attendance today?</InputLabel>
                <Select
                  id="Attendance"
                  value={getAddDailyReportAttendance}
                  onChange={(e) => setAddDailyReportAttendance(e.target.value)}
                  label="Attendance"
                >
                  <MenuItem key="yes" value="yes">
                    Yes
                  </MenuItem>
                  <MenuItem key="no" value="no">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Have you uploaded the Photo today?</InputLabel>
                <Select
                  id="Photo"
                  value={getAddDailyReportPhoto}
                  onChange={(e) => setAddDailyReportPhoto(e.target.value)}
                  label="Photo"
                >
                  <MenuItem key="yes" value="yes">
                    Yes
                  </MenuItem>
                  <MenuItem key="no" value="no">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  createDailyReportHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  getShowAddDailyReportModel(!showAddDailyReportModel);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Add Daily Report */}

          {/* Group Nutrition Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showAddGroupNutritionModel}
            aria-labelledby="groupnutritiondetails"
          >
            <DialogTitle id="groupnutritiondetails">
              {"Group Nutrition details"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteid">Site ID :</InputLabel>
                <Input
                  id="siteid"
                  name="assetid"
                  type="text"
                  value={getAddGroupNutritionSiteid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Asset Friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getAddGroupNutritionFriendlyname}
                  onChange={(e) => {
                    setAddGroupNutritionFriendlyname(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User ID :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getAddGroupNutritionUserid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetType">Asset Type :</InputLabel>
                <Input
                  id="assetType"
                  name="assetType"
                  type="text"
                  value={getAddGroupNutritionSiteType}
                  onChange={(e) => {
                    setAddGroupNutritionSiteType(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Feed Type :</InputLabel>
                <Select
                  id="assetid"
                  value={getAddGroupNutritionFeedType}
                  onChange={(e) => setAddGroupNutritionFeedType(e.target.value)}
                  label="assetid"
                >
                  <MenuItem key="Milk" value="Milk">
                    Milk
                  </MenuItem>
                  <MenuItem key="Colstrum" value="Colstrum">
                    Colstrum
                  </MenuItem>
                  <MenuItem key="Rough-Age" value="Rough-Age">
                    Rough-Age
                  </MenuItem>
                  <MenuItem key="Concentrate" value="Concentrate">
                    Concentrate
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Schedule :</InputLabel>
                <Select
                  id="Schedule"
                  value={getAddGroupNutritionSchedule}
                  onChange={(e) => setAddGroupNutritionSchedule(e.target.value)}
                  label="Schedule"
                >
                  <MenuItem key="Morning" value="Morning">
                    Morning
                  </MenuItem>
                  <MenuItem key="Afternoon" value="Afternoon">
                    Afternoon
                  </MenuItem>
                  <MenuItem key="Evening" value="Evening">
                    Evening
                  </MenuItem>
                  <MenuItem key="Night" value="Night">
                    Night
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={getAddGroupNutritionLatitude}
                  onChange={(e) => {
                    setAddGroupNutritionLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={getAddGroupNutritionLongitude}
                  onChange={(e) => {
                    setAddGroupNutritionLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                <Input
                  id="altitude"
                  name="altitude"
                  type="text"
                  value={getAddGroupNutritionAltitude}
                  onChange={(e) => {
                    setAddGroupNutritionAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getAddGroupNutritionDescription}
                  onChange={(e) => {
                    setAddGroupNutritionDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  createGroupNutritionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowAddGroupNutritionModel(!showAddGroupNutritionModel);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Group Nutrition Details */}

          {/* Delete Group Nutrition Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showDeleteGroupNutritionModel}
            aria-labelledby="deletenutrition"
          >
            <DialogTitle id="deletenutrition">
              {"Delete Group Nutrition"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  groupNutritionDeleteHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowDeleteGroupNutritionModel(
                    !showDeleteGroupNutritionModel
                  );
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Group Nutrition Details */}

          {/* Update Group Nutrition Details */}
          <Dialog
            fullScreen={fullScreen}
            open={showUpdateGroupNutritionModel}
            aria-labelledby="groupnutritiondetails"
          >
            <DialogTitle id="groupnutritiondetails">
              {"Update Group Nutrition details"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteid">Site ID :</InputLabel>
                <Input
                  id="siteid"
                  name="assetid"
                  type="text"
                  value={getUpdateGroupNutritionSiteid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Asset Friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getUpdateGroupNutritionFriendlyname}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User ID :</InputLabel>
                <Input
                  id="userid"
                  name="userid"
                  type="text"
                  value={getUpdateGroupNutritionUserid}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="prevfeedtype">Prev Feed Type:</InputLabel>
                <Input
                  id="prevfeedtype"
                  name="prevfeedtype"
                  type="text"
                  value={prevgetUpdateGroupNutritionFeedType}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Feed Type :</InputLabel>
                <Select
                  id="assetid"
                  value={getUpdateGroupNutritionFeedType}
                  onChange={(e) =>
                    setUpdateGroupNutritionFeedType(e.target.value)
                  }
                  label="assetid"
                >
                  <MenuItem key="Milk" value="Milk">
                    Milk
                  </MenuItem>
                  <MenuItem key="Colstrum" value="Colstrum">
                    Colstrum
                  </MenuItem>
                  <MenuItem key="Rough-Age" value="Rough-Age">
                    Rough-Age
                  </MenuItem>
                  <MenuItem key="Concentrate" value="Concentrate">
                    Concentrate
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="prevshedule">Prev Schedule:</InputLabel>
                <Input
                  id="prevshedule"
                  name="prevshedule"
                  type="text"
                  value={prevgetUpdateGroupNutritionSchedule}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Schedule :</InputLabel>
                <Select
                  id="Schedule"
                  value={getUpdateGroupNutritionSchedule}
                  onChange={(e) =>
                    setUpdateGroupNutritionSchedule(e.target.value)
                  }
                  label="Schedule"
                >
                  <MenuItem key="Morning" value="Morning">
                    Morning
                  </MenuItem>
                  <MenuItem key="Afternoon" value="Afternoon">
                    Afternoon
                  </MenuItem>
                  <MenuItem key="Evening" value="Evening">
                    Evening
                  </MenuItem>
                  <MenuItem key="Night" value="Night">
                    Night
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={getUpdateGroupNutritionLatitude}
                  onChange={(e) => {
                    setUpdateGroupNutritionLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={getUpdateGroupNutritionLongitude}
                  onChange={(e) => {
                    setUpdateGroupNutritionLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="altitude">Altitude :</InputLabel>
                <Input
                  id="altitude"
                  name="altitude"
                  type="text"
                  value={getUpdateGroupNutritionAltitude}
                  onChange={(e) => {
                    setUpdateGroupNutritionAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getUpdateGroupNutritionDescription}
                  onChange={(e) => {
                    setUpdateGroupNutritionDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateGroupNutritionHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowUpdateGroupNutritionModel(
                    !showUpdateGroupNutritionModel
                  );
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Group Nutrition Details */}

          {/* New Site Group Attendance Location */}
          <Dialog
            fullScreen={fullScreen}
            open={showSiteNewAttendanceLocationPopup}
            aria-labelledby="siteattendance"
          >
            <DialogTitle id="siteattendance">
              {"New Site Attendance Location"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="assetid">Site id :</InputLabel>
                <Input
                  id="assetid"
                  name="assetid"
                  type="text"
                  value={getSiteNewAttendanceLocationAssetid}
                  // onChange={(e) => {
                  //   setSiteNewAttendanceLocationAssetid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Site friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getSiteNewAttendanceLocationAssetfriendlyName}
                  onChange={(e) => {
                    setSiteNewAttendanceLocationAssetfriendlyName(
                      e.target.value
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User id :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getSiteNewAttendanceLocationUserid}
                  // onChange={(e) => {
                  //   setNewAttendanceLocationUserid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Latitude">Latitude :</InputLabel>
                <Input
                  id="Latitude"
                  name="Latitude"
                  type="text"
                  value={getSiteNewAttendanceLocationLatitude}
                  onChange={(e) => {
                    setSiteNewAttendanceLocationLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Longitude">Longitude:</InputLabel>
                <Input
                  id="Longitude"
                  name="Longitude"
                  type="text"
                  value={getSiteNewAttendanceLocationLongitude}
                  onChange={(e) => {
                    setSiteNewAttendanceLocationLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Altitude">Altitude :</InputLabel>
                <Input
                  id="Altitude"
                  name="Altitude"
                  type="text"
                  value={getSiteNewAttendanceLocationAltitude}
                  onChange={(e) => {
                    setSiteNewAttendanceLocationAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getSiteNewAttendanceLocationDescription}
                  onChange={(e) => {
                    setSiteNewAttendanceLocationDescription(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  addSiteAttendanceLocationHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setSiteNewAttendanceLocationPopup(
                    !showSiteNewAttendanceLocationPopup
                  );
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of New Site Group Attendance Location */}

          {/* Delete Site Group Attendance Location */}
          <Dialog
            fullScreen={fullScreen}
            open={getSiteDeletedAttendancePopup}
            aria-labelledby="sitedelete"
          >
            <DialogTitle id="sitedelete">
              {"Delete Site Attendance Location"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  deleteSiteAttendanceAsset();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setSiteDeletedAttendancePopup(!getSiteDeletedAttendancePopup);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Delete Site Group Attendance Location */}

          {/* Update Site Group Attendance Location Info */}
          <Dialog
            fullScreen={fullScreen}
            open={showSiteUpdateAttendaneLocationPopup}
            aria-labelledby="siteattandanceupdate"
          >
            <DialogTitle id="siteattandanceupdate">
              {"Update Site Attendance Location Info"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteid">Site id :</InputLabel>
                <Input
                  disabled
                  id="siteid"
                  name="siteid"
                  type="text"
                  value={getSiteUpdateAttendanceLocationInfoAssetid}
                  // onChange={(e) => {
                  //   setSiteUpdateAttendanceLocationInfoAssetid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="friendlyName">
                  Site friendly Name :
                </InputLabel>
                <Input
                  id="friendlyName"
                  name="friendlyName"
                  type="text"
                  value={getSiteUpdateAttendanceLocationInfoAssetfriendlyName}
                  onChange={(e) => {
                    setSiteUpdateAttendanceLocationInfoAssetfriendlyName(
                      e.target.value
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="userid">User id :</InputLabel>
                <Input
                  disabled
                  id="userid"
                  name="userid"
                  type="text"
                  value={getSiteUpdateAttendanceLocationInfoUserid}
                  // onChange={(e) => {
                  //   setSiteNewAttendanceLocationUserid(e.target.value)
                  // }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Latitude">Latitude :</InputLabel>
                <Input
                  id="Latitude"
                  name="Latitude"
                  type="text"
                  value={getSiteUpdateAttendanceLocationInfoLatitude}
                  onChange={(e) => {
                    setSiteUpdateAttendanceLocationInfoLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Longitude">Longitude:</InputLabel>
                <Input
                  id="Longitude"
                  name="Longitude"
                  type="text"
                  value={getSiteUpdateAttendanceLocationInfoLongitude}
                  onChange={(e) => {
                    setSiteUpdateAttendanceLocationInfoLongitude(
                      e.target.value
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Altitude">Altitude :</InputLabel>
                <Input
                  id="Altitude"
                  name="Altitude"
                  type="text"
                  value={getSiteUpdateAttendanceLocationInfoAltitude}
                  onChange={(e) => {
                    setSiteUpdateAttendanceLocationInfoAltitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Comments/Description:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getSiteUpdateAttendanceLocationInfoDescription}
                  onChange={(e) => {
                    setSiteUpdateAttendanceLocationInfoDescription(
                      e.target.value
                    );
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateSiteAttendanceLocationsHandler();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setSiteUpdateAttendaneLocationPopup(
                    !showSiteUpdateAttendaneLocationPopup
                  );
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Update Site Group Attendance Location Info */}

          {/* Add Grow Request */}
          <Dialog
            fullScreen={fullScreen}
            open={showAddGrowReqModel}
            aria-labelledby="addgrow"
          >
            <DialogTitle id="addgrow">{"Add Grow Request"}</DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="Source">Source:</InputLabel>
                <Input
                  id="Source"
                  name="Source"
                  type="text"
                  value={getGrowReqSource}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Vertical ID :</InputLabel>
                <Select
                  id="assetverticalid"
                  name="assetverticalid"
                  type="text"
                  value={getGrowReqAssetVerticalId}
                  onChange={(e) => {
                    setSelectedVerticalsIdHandler(e);
                  }}
                >
                  {verticalsIdList &&
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Category Type</InputLabel>
                <Select
                  id="categoryType"
                  name="categoryType"
                  type="text"
                  value={getGrowReqCategory}
                  onChange={(e) => {
                    setSelectedCategoryHandler(e);
                  }}
                >
                  {categoryTypesList &&
                    categoryTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.categoryType}>
                        {idObj.categoryType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Type</InputLabel>
                <Select
                  id="selectedAssertTypes"
                  value={getGrowReqLegacyAssetType}
                  onChange={(e) => setSelectedAssertTypesHandler(e)}
                  label="Select Type"
                >
                  {assertTypesList &&
                    assertTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetType}>
                        {idObj.assetType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Model</InputLabel>
                <Select
                  id="selectedModel"
                  value={getGrowReqModelid}
                  onChange={(event) => setGrowReqModelid(event.target.value)}
                  label="Select Model"
                >
                  {modelList &&
                    modelList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.modelid}>
                        {idObj.modelfriendlyname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="phone">Phone :</InputLabel>
                <Input
                  id="phone"
                  name="phone"
                  type="phone"
                  value={getGrowReqPhone}
                  onChange={(e) => {
                    setGrowReqPhone(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="email">Email :</InputLabel>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  value={getGrowReqEmail}
                  onChange={(e) => {
                    setGrowReqEmail(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="city">City :</InputLabel>
                <Input
                  id="city"
                  name="city"
                  type="text"
                  value={getGrowReqCity}
                  onChange={(e) => {
                    setGrowReqCity(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="state">State :</InputLabel>
                <Input
                  id="state"
                  name="state"
                  type="text"
                  value={getGrowReqState}
                  onChange={(e) => {
                    setGrowReqState(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={getGrowReqLatitude}
                  onChange={(e) => {
                    setGrowReqLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={getGrowReqLongitude}
                  onChange={(e) => {
                    setGrowReqLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="quantity">Quantity :</InputLabel>
                <Input
                  id="quantity"
                  name="quantity"
                  type="text"
                  value={getGrowReqQuantity}
                  onChange={(e) => {
                    setGrowReqQuantity(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Address:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getGrowReqAddress}
                  onChange={(e) => {
                    setGrowReqAddress(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  createGrowRequest();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowAddGrowReqModel(!showAddGrowReqModel);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Add Grow Request */}

          {/* Grow Request Update */}
          <Dialog
            fullScreen={fullScreen}
            open={getGrowUpdateRequestModel}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Update Grow Request Status"}
            </DialogTitle>
            <DialogContent style={{ minWidth: "600px" }}>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
                <Input
                  id="approvalid"
                  name="approvalid"
                  placeholder="Request Id"
                  type="text"
                  value={getGrowId}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="prestatus">Existing Status:</InputLabel>
                <Input
                  id="prestatus"
                  name="prestatus"
                  placeholder="Existing Status"
                  type="text"
                  value={getGrowPreStatus}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="status">Update Status</InputLabel>
                <Select
                  id="status"
                  value={getGrowPostStatus}
                  onChange={(e) => {
                    setGrowPostStatus(e.target.value);
                  }}
                  label="Select"
                >
                  {(() => {
                    switch (getGrowPreStatus) {
                      case "new-grow-request":
                        return (
                          <MenuItem key="in-progress" value="in-progress">
                            in-progress
                          </MenuItem>
                        );
                        break;
                      case "in-progress":
                        return [
                          <MenuItem key="accepted" value="accepted">
                            accepted
                          </MenuItem>,
                          <MenuItem key="rejected" value="rejected">
                            rejected
                          </MenuItem>,
                        ];
                        break;
                      case "accepted":
                        return (
                          <MenuItem key="approved" value="approved">
                            approved
                          </MenuItem>
                        );
                        break;
                      case "approved":
                        return (
                          <MenuItem key="fulfilled" value="fulfilled">
                            fulfilled
                          </MenuItem>
                        );
                        break;
                    }
                  })()}
                  {/* <MenuItem selected disabled>
                        Select Any Status
                      </MenuItem>
                      <MenuItem key="in-progress" value="in-progress">
                        in-progress
                      </MenuItem>
                      <MenuItem key="approved" value="approved">
                        approved
                      </MenuItem>
                      <MenuItem key="accepted" value="accepted">
                        accepted
                      </MenuItem>
                      <MenuItem key="rejected" value="rejected">
                        rejected
                      </MenuItem>
                      <MenuItem key="fulfilled" value="fulfilled">
                        fulfilled
                      </MenuItem> */}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={growReqStatusHandler}
              >
                Submit
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  setGrowUpdateRequestModel(!getGrowUpdateRequestModel);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Grow Request Update */}

          {/* Cancel Grow Request */}
          <Dialog
            fullScreen={fullScreen}
            open={getCancelGrowUpdateRequestModel}
            aria-labelledby="canclegrow"
          >
            <DialogTitle id="canclegrow">{"Cancel Grow Request"}</DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  cancelGrowRequest();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setCancelGrowUpdateRequestModel(
                    !getCancelGrowUpdateRequestModel
                  );
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Cancel Grow Request */}

          {/* Add Sell Request */}
          <Dialog
            fullScreen={fullScreen}
            open={showAddSellReqModel}
            aria-labelledby="addsell"
          >
            <DialogTitle id="addsell">{"Add Sell Request"}</DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="username">User Name :</InputLabel>
                <Input
                  id="username"
                  name="username"
                  type="text"
                  value={getSellReqSource}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Vertical ID :</InputLabel>
                <Select
                  id="assetverticalid"
                  name="assetverticalid"
                  type="text"
                  value={getSellReqAssetVerticalId}
                  onChange={(e) => {
                    setSelectedVerticalsIdHandler(e);
                  }}
                >
                  {verticalsIdList &&
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Category Type</InputLabel>
                <Select
                  id="categoryType"
                  name="categoryType"
                  type="text"
                  value={getSellReqCategory}
                  onChange={(e) => {
                    setSelectedCategoryHandler(e);
                  }}
                >
                  {categoryTypesList &&
                    categoryTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.categoryType}>
                        {idObj.categoryType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Type</InputLabel>
                <Select
                  id="selectedAssertTypes"
                  value={getSellReqLegacyAssetType}
                  onChange={(e) => setSelectedAssertTypesHandler(e)}
                  label="Select Type"
                >
                  {assertTypesList &&
                    assertTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetType}>
                        {idObj.assetType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Model</InputLabel>
                <Select
                  id="selectedModel"
                  value={getSellReqModelid}
                  onChange={(event) => setSellReqModelid(event.target.value)}
                  label="Select Model"
                >
                  {modelList &&
                    modelList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.modelid}>
                        {idObj.modelfriendlyname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="phone">Phone :</InputLabel>
                <Input
                  id="phone"
                  name="phone"
                  type="phone"
                  value={getSellReqPhone}
                  onChange={(e) => {
                    setSellReqPhone(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="email">Email :</InputLabel>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  value={getSellReqEmail}
                  onChange={(e) => {
                    setSellReqEmail(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="city">City :</InputLabel>
                <Input
                  id="city"
                  name="city"
                  type="text"
                  value={getSellReqCity}
                  onChange={(e) => {
                    setSellReqCity(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="state">State :</InputLabel>
                <Input
                  id="state"
                  name="state"
                  type="text"
                  value={getSellReqState}
                  onChange={(e) => {
                    setSellReqState(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={getSellReqLatitude}
                  onChange={(e) => {
                    setSellReqLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={getSellReqLongitude}
                  onChange={(e) => {
                    setSellReqLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="quantity">Quantity :</InputLabel>
                <Input
                  id="quantity"
                  name="quantity"
                  type="text"
                  value={getSellReqQuantity}
                  onChange={(e) => {
                    setSellReqQuantity(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="amount">Total Selling Amount :</InputLabel>
                <Input
                  id="amount"
                  name="amount"
                  type="text"
                  value={getSellReqCost}
                  onChange={(e) => {
                    setSellReqCost(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Address:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getSellReqAddress}
                  onChange={(e) => {
                    setSellReqAddress(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  createSellRequest();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowAddSellReqModel(!showAddSellReqModel);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Add Sell Request */}

          {/* Sell Request Update */}
          <Dialog
            fullScreen={fullScreen}
            open={getSellUpdateRequestModel}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Update Sell Request Status"}
            </DialogTitle>
            <DialogContent style={{ minWidth: "600px" }}>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
                <Input
                  id="approvalid"
                  name="approvalid"
                  placeholder="Request Id"
                  type="text"
                  value={getSellId}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="prestatus">Existing Status:</InputLabel>
                <Input
                  id="prestatus"
                  name="prestatus"
                  placeholder="Existing Status"
                  type="text"
                  value={getSellPreStatus}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="status">Update Status</InputLabel>
                <Select
                  id="status"
                  value={getSellPostStatus}
                  onChange={(e) => {
                    setSellPostStatus(e.target.value);
                  }}
                  label="Select"
                >
                  {(() => {
                    switch (getSellPreStatus) {
                      case "new-sell-request":
                        return (
                          <MenuItem key="in-progress" value="in-progress">
                            in-progress
                          </MenuItem>
                        );
                        break;
                      case "in-progress":
                        return [
                          <MenuItem key="accepted" value="accepted">
                            accepted
                          </MenuItem>,
                          <MenuItem key="rejected" value="rejected">
                            rejected
                          </MenuItem>,
                        ];
                        break;
                      case "accepted":
                        return (
                          <MenuItem key="approved" value="approved">
                            approved
                          </MenuItem>
                        );
                        break;
                      case "approved":
                        return (
                          <MenuItem key="fulfilled" value="fulfilled">
                            fulfilled
                          </MenuItem>
                        );
                        break;
                    }
                  })()}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={sellReqStatusHandler}
              >
                Submit
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  setSellUpdateRequestModel(!getSellUpdateRequestModel);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Sell Request Update */}

          {/* Cancel Sell Request */}
          <Dialog
            fullScreen={fullScreen}
            open={getCancelSellUpdateRequestModel}
            aria-labelledby="canclegrow"
          >
            <DialogTitle id="canclegrow">{"Cancel Sell Request"}</DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  cancelSellRequest();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setCancelSellUpdateRequestModel(
                    !getCancelSellUpdateRequestModel
                  );
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Cancel Sell Request */}
          {/* Add Loan Request */}
          <Dialog
            fullScreen={fullScreen}
            open={showAddLoanReqModel}
            aria-labelledby="addLoan"
          >
            <DialogTitle id="addLoan">{"Add Loan Request"}</DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="username">User Name :</InputLabel>
                <Input
                  id="username"
                  name="username"
                  type="text"
                  value={username}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Vertical ID :</InputLabel>
                <Select
                  id="assetverticalid"
                  name="assetverticalid"
                  type="text"
                  value={selectedVerticalsId}
                  onChange={(e) => {
                    setSelectedVerticalsIdHandler(e);
                  }}
                >
                  {verticalsIdList &&
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Category Type</InputLabel>
                <Select
                  id="categoryType"
                  name="categoryType"
                  type="text"
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategoryHandler(e);
                  }}
                >
                  {categoryTypesList &&
                    categoryTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.categoryType}>
                        {idObj.categoryType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Type</InputLabel>
                <Select
                  id="selectedAssertTypes"
                  value={selectedAssertTypes}
                  onChange={(e) => setSelectedAssertTypesHandler(e)}
                  label="Select Type"
                >
                  {assertTypesList &&
                    assertTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetType}>
                        {idObj.assetType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Model</InputLabel>
                <Select
                  id="selectedModel"
                  value={getLoanReqModelid}
                  onChange={(event) => setLoanReqModelid(event.target.value)}
                  label="Select Model"
                >
                  {modelList &&
                    modelList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.modelid}>
                        {idObj.modelfriendlyname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="phone">Phone :</InputLabel>
                <Input
                  id="phone"
                  name="phone"
                  type="phone"
                  value={getLoanReqPhone}
                  onChange={(e) => {
                    setLoanReqPhone(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="email">Email :</InputLabel>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  value={getLoanReqEmail}
                  onChange={(e) => {
                    setLoanReqEmail(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="city">City :</InputLabel>
                <Input
                  id="city"
                  name="city"
                  type="text"
                  value={getLoanReqCity}
                  onChange={(e) => {
                    setLoanReqCity(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="state">State :</InputLabel>
                <Input
                  id="state"
                  name="state"
                  type="text"
                  value={getLoanReqState}
                  onChange={(e) => {
                    setLoanReqState(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={getLoanReqLatitude}
                  onChange={(e) => {
                    setLoanReqLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={getLoanReqLongitude}
                  onChange={(e) => {
                    setLoanReqLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="quantity">Quantity :</InputLabel>
                <Input
                  id="quantity"
                  name="quantity"
                  type="text"
                  value={getLoanReqQuantity}
                  onChange={(e) => {
                    setLoanReqQuantity(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="amount">Total Selling Amount :</InputLabel>
                <Input
                  id="amount"
                  name="amount"
                  type="text"
                  value={getLoanReqCost}
                  onChange={(e) => {
                    setLoanReqCost(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Address:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getLoanReqAddress}
                  onChange={(e) => {
                    setLoanReqAddress(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  createLoanRequest();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowAddLoanReqModel(!showAddLoanReqModel);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Add Loan Request */}
          {/* Loan Request Update */}
          <Dialog
            fullScreen={fullScreen}
            open={getLoanUpdateRequestModel}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Update Loan Request Status"}
            </DialogTitle>
            <DialogContent style={{ minWidth: "600px" }}>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
                <Input
                  id="approvalid"
                  name="approvalid"
                  placeholder="Request Id"
                  type="text"
                  value={getLoanId}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="prestatus">Existing Status:</InputLabel>
                <Input
                  id="prestatus"
                  name="prestatus"
                  placeholder="Existing Status"
                  type="text"
                  value={getLoanPreStatus}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="status">Update Status</InputLabel>
                <Select
                  id="status"
                  value={getLoanPostStatus}
                  onChange={(e) => {
                    setLoanPostStatus(e.target.value);
                  }}
                  label="Select"
                >
                  {(() => {
                    switch (getLoanPreStatus) {
                      case "new-loan-request":
                        return (
                          <MenuItem key="in-progress" value="in-progress">
                            in-progress
                          </MenuItem>
                        );
                        break;
                      case "in-progress":
                        return [
                          <MenuItem key="accepted" value="accepted">
                            accepted
                          </MenuItem>,
                          <MenuItem key="rejected" value="rejected">
                            rejected
                          </MenuItem>,
                        ];
                        break;
                      case "accepted":
                        return (
                          <MenuItem key="approved" value="approved">
                            approved
                          </MenuItem>
                        );
                        break;
                      case "approved":
                        return (
                          <MenuItem key="fulfilled" value="fulfilled">
                            fulfilled
                          </MenuItem>
                        );
                        break;
                    }
                  })()}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={loanReqStatusHandler}
              >
                Submit
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  setLoanUpdateRequestModel(!getLoanUpdateRequestModel);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Loan Request Update */}

          {/* Cancel Loan Request */}
          <Dialog
            fullScreen={fullScreen}
            open={getCancelLoanUpdateRequestModel}
            aria-labelledby="canclegrow"
          >
            <DialogTitle id="canclegrow">{"Cancel Loan Request"}</DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  cancelLoanRequest();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setCancelLoanUpdateRequestModel(
                    !getCancelLoanUpdateRequestModel
                  );
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Cancel Loan Request */}
          {/* Add Vet Visit Request */}
          <Dialog
            fullScreen={fullScreen}
            open={showAddVetVisitReqModel}
            aria-labelledby="addVetVisit"
          >
            <DialogTitle id="addVetVisit">
              {"Add Vet Visit Request"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="username">User Name :</InputLabel>
                <Input
                  id="username"
                  name="username"
                  type="text"
                  value={getSellReqSource}
                />
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Vertical ID :</InputLabel>
                <Select
                  id="assetverticalid"
                  name="assetverticalid"
                  type="text"
                  value={getSellReqAssetVerticalId}
                  onChange={(e) => {
                    setSelectedVerticalsIdHandler(e);
                  }}
                >
                  {verticalsIdList &&
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                        {idObj.friendlyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Category Type</InputLabel>
                <Select
                  id="categoryType"
                  name="categoryType"
                  type="text"
                  value={getSellReqCategory}
                  onChange={(e) => {
                    setSelectedCategoryHandler(e);
                  }}
                >
                  {categoryTypesList &&
                    categoryTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.categoryType}>
                        {idObj.categoryType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Type</InputLabel>
                <Select
                  id="selectedAssertTypes"
                  value={getVetVisitReqLegacyAssetType}
                  onChange={(e) => setSelectedAssertTypesHandler(e)}
                  label="Select Type"
                >
                  {assertTypesList &&
                    assertTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetType}>
                        {idObj.assetType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Model</InputLabel>
                <Select
                  id="selectedModel"
                  value={getVetVisitReqModelid}
                  onChange={(event) =>
                    setVetVisitReqModelid(event.target.value)
                  }
                  label="Select Model"
                >
                  {modelList &&
                    modelList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.modelid}>
                        {idObj.modelfriendlyname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="phone">Phone :</InputLabel>
                <Input
                  id="phone"
                  name="phone"
                  type="phone"
                  value={getVetVisitReqPhone}
                  onChange={(e) => {
                    setVetVisitReqPhone(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="email">Email :</InputLabel>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  value={getVetVisitReqEmail}
                  onChange={(e) => {
                    setVetVisitReqEmail(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="city">City :</InputLabel>
                <Input
                  id="city"
                  name="city"
                  type="text"
                  value={getVetVisitReqCity}
                  onChange={(e) => {
                    setVetVisitReqCity(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="state">State :</InputLabel>
                <Input
                  id="state"
                  name="state"
                  type="text"
                  value={getVetVisitReqState}
                  onChange={(e) => {
                    setVetVisitReqState(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="latitude">Latitude :</InputLabel>
                <Input
                  id="latitude"
                  name="latitude"
                  type="text"
                  value={getVetVisitReqLatitude}
                  onChange={(e) => {
                    setVetVisitReqLatitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="longitude">Longitude :</InputLabel>
                <Input
                  id="longitude"
                  name="longitude"
                  type="text"
                  value={getVetVisitReqLongitude}
                  onChange={(e) => {
                    setVetVisitReqLongitude(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="quantity">Quantity :</InputLabel>
                <Input
                  id="quantity"
                  name="quantity"
                  type="text"
                  value={getVetVisitReqQuantity}
                  onChange={(e) => {
                    setVetVisitReqQuantity(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="amount">Total Selling Amount :</InputLabel>
                <Input
                  id="amount"
                  name="amount"
                  type="text"
                  value={getVetVisitReqCost}
                  onChange={(e) => {
                    setVetVisitReqCost(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <p htmlFor="address">Address:</p>
                <TextareaAutosize
                  minRows={5}
                  style={{ minwidth: 200 }}
                  value={getVetVisitReqAddress}
                  onChange={(e) => {
                    setVetVisitReqAddress(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  createVetRequest();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowAddVetVisitReqModel(!showAddVetVisitReqModel);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Add Vet Visit Request */}

          {/* Vet Visit Request Update */}
          <Dialog
            fullScreen={fullScreen}
            open={getVetVisitUpdateRequestModel}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Update Vet Visit Request Status"}
            </DialogTitle>
            <DialogContent style={{ minWidth: "600px" }}>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="approvalid">Request Id:</InputLabel>
                <Input
                  id="approvalid"
                  name="approvalid"
                  placeholder="Request Id"
                  type="text"
                  value={setSellId}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="prestatus">Existing Status:</InputLabel>
                <Input
                  id="prestatus"
                  name="prestatus"
                  placeholder="Existing Status"
                  type="text"
                  value={setSellPreStatus}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="status">Update Status</InputLabel>
                <Select
                  id="status"
                  value={setSellPostStatus}
                  onChange={(e) => {
                    getSellPostStatus(e.target.value);
                  }}
                  label="Select"
                >
                  {(() => {
                    switch (setSellPreStatus) {
                      case "new-sell-request":
                        return (
                          <MenuItem key="in-progress" value="in-progress">
                            in-progress
                          </MenuItem>
                        );
                        break;
                      case "in-progress":
                        return [
                          <MenuItem key="accepted" value="accepted">
                            accepted
                          </MenuItem>,
                          <MenuItem key="rejected" value="rejected">
                            rejected
                          </MenuItem>,
                        ];
                        break;
                      case "accepted":
                        return (
                          <MenuItem key="approved" value="approved">
                            approved
                          </MenuItem>
                        );
                        break;
                      case "approved":
                        return (
                          <MenuItem key="fulfilled" value="fulfilled">
                            fulfilled
                          </MenuItem>
                        );
                        break;
                    }
                  })()}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={vetVisitReqStatusHandler}
              >
                Submit
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  setVetVisitUpdateRequestModel(!getVetVisitUpdateRequestModel);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Vet visit Request Update */}

          {/* Cancel Vet Visit Request */}
          <Dialog
            fullScreen={fullScreen}
            open={getCancelVetVisitUpdateRequestModel}
            aria-labelledby="canclegrow"
          >
            <DialogTitle id="canclegrow">
              {"Cancel Vet Visit Request"}
            </DialogTitle>
            <DialogContent>Are You Sure ?</DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  cancelVetVisitRequest();
                }}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setCancelVetVisitUpdateRequestModel(
                    !getCancelVetVisitUpdateRequestModel
                  );
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Of Vet Visit  Request */}
          {/* Create Site Alarm */}
          <Dialog
            fullScreen={fullScreen}
            open={siteAlarmPopup}
            onClose={() => {
              setSiteAlarmPopup(!siteAlarmPopup);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Create Site Alarm"}
            </DialogTitle>
            <DialogContent style={{ minWidth: "600px" }}>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="solutionId">Source:</InputLabel>
                <Input
                  disabled
                  id="solutionId"
                  name="solutionId"
                  type="text"
                  value={parentId}
                />
              </FormControl>

              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>AssetVertical Id:</InputLabel>
                <Select
                  id="assetVerticalID"
                  name="assetVerticalID"
                  type="text"
                  value={selectedVerticalsId}
                  onChange={(event) => {
                    setSelectedVerticalsIdHandler(event);
                  }}
                >
                  {verticalsIdList &&
                    verticalsIdList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                        {idObj.assetVerticalFriendlyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Vertical Friendly Name :</InputLabel>
                <Input
                  disabled
                  id="avName"
                  name="avName"
                  type="text"
                  value={selectedVerticalName}
                />
              </FormControl>

              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Category</InputLabel>
                <Select
                  id="selectedCategory"
                  value={selectedCategory}
                  onChange={(event) => {
                    setSelectedCategory(event.target.value);
                  }}
                  label="Select Category"
                >
                  {categoryTypesList &&
                    categoryTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.categoryType}>
                        {idObj.categoryType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Asset Type</InputLabel>
                <Select
                  id="selectedAssertTypes"
                  value={selectedAssertTypes}
                  onChange={(event) => {
                    setSelectedAssertTypes(event.target.value);
                  }}
                  label="Select Type"
                >
                  {assertTypesList &&
                    assertTypesList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.assetType}>
                        {idObj.assetType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
              >
                <InputLabel>Model</InputLabel>
                <Select
                  id="selectedModel"
                  value={selectedModel}
                  onChange={(event) => {
                    setSelectedModel(event.target.value);
                  }}
                  label="Select Model"
                >
                  {modelList &&
                    modelList.map((idObj, index) => (
                      <MenuItem key={`${index} `} value={idObj.modelid}>
                        {idObj.modelfriendlyname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteId">Site Id:</InputLabel>
                <Input
                  disabled
                  id="siteId"
                  name="siteId"
                  type="text"
                  value={resourceId}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteId">Alarm Category:</InputLabel>
                <Input
                  id="siteId"
                  name="siteId"
                  type="text"
                  value={alarmCategoryValue}
                  onChange={(e) => {
                    setAlarmCategoryValue(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} className="loginFields">
                <InputLabel htmlFor="siteId">Alarm Name:</InputLabel>
                <Input
                  id="siteId"
                  name="siteId"
                  type="text"
                  value={alarmNameValue}
                  onChange={(e) => {
                    setAlarmNameValue(e.target.value);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                className="registerBtn"
                variant="contained"
                onClick={() => {
                  submitSitealarmClick();
                }}
              >
                Submit
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  setSiteAlarmPopup(!siteAlarmPopup);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Asset Attendance */}
        <Dialog
          fullScreen={fullScreen}
          open={assetAttendanceDialog}
          //onClose={setAssetAttendanceDialog(assetAttendanceDialog)}
          aria-labelledby="assetAttendance"
        >
          <DialogTitle id="assetAttendance">
            {"View Asset Attendance"}
          </DialogTitle>
          <DialogContent>
            <h4>Asset Attendance</h4>

            <p>
              <span> Altitude :{assetAttendanceOnj.altitude}</span>
            </p>
            <p>Latitude : {assetAttendanceOnj.latitude}</p>
            <p>Longitude : {assetAttendanceOnj.longitude}</p>
            <p>Assetfriendlyname : {assetAttendanceOnj.assetfriendlyname}</p>
            <p>Parentid :{assetAttendanceOnj.parentid}</p>
            <p>Userid : {assetAttendanceOnj.userid}</p>
            <p>Assetid : {assetAttendanceOnj.assetid}</p>
            <p>
              <span>Description :{assetAttendanceOnj.description}</span>
            </p>
            <p>
              Timestamp:
              {assetAttendanceOnj.timestamp}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setAssetAttendanceDialog(!assetAttendanceDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Of Asset Attendance  */}
        </div>
      </div>
      <CustomPopup
        closeButton={closeSiteWithNodeModal}
        isOpen={showCreateSitePopup}
        closeModal={showCreateSitePopup}
        content={siteCreationWithNodeModelBody}
        title={siteAndNodeCreationTitle}
      />
      <CustomPopup
        closeButton={closeSiteCreationWithNodeModal}
        isOpen={showCreateSiteDialog}
        closeModal={showCreateSiteDialog}
        content={siteCreationModelBody}
        title={siteAndNodeCreationTitle}
      />
      <CustomPopup
        closeButton={closeSiteModal}
        isOpen={getSitePopUp}
        closeModal={getSitePopUp}
        content={siteModelBody}
        title={siteCreationTitle}
      />
      <CustomPopup
        closeButton={closeSiteRatingModal}
        isOpen={getSiteRatePopup}
        closeModal={getSiteRatePopup}
        content={siteRateModel}
        title={siteRateAgreement}
      />
      <CustomPopup
        closeButton={closeSiteInfoModal}
        isOpen={setSiteInfoPopup}
        closeModal={setSiteInfoPopup}
        content={SiteInfoBody}
        title={siteInfoHeader}
      />
      <CustomPopup
        closeButton={closeSiteUpdateModal}
        isOpen={getSiteUpdatePopup}
        closeModal={getSiteUpdatePopup}
        content={SiteUpdateBody}
        title={siteUpdateHeader}
      />
      <CustomPopup
            closeButton={closeUploadPhotoModal}
            isOpen={uploadPhotoPopup}
            closeModal={uploadPhotoPopup}
            content={uploadPhotoBody}
            title={titleUploadPhoto}
          />
    </React.Fragment>
  );
}
