import React from "react";
import { Row, Col } from "react-bootstrap";
import "./CTA.css";
import { NavLink } from "react-router-dom";

export default function CTA({
  heading,
  href,
  btnText,
  onClick,
  click,
  data,
  href2,
  secondBtn,
  href3,
  btnText2,
}) {
  return (
    <div className="cta-container text-center">
      <Row className="align-items-center justify-content-center">
        <Col md={12}>
          <h2 className="container-title mb-4 text-center">{heading}</h2>
          {href && (
            <NavLink
              onClick={onClick}
              className="d-inline-block me-2"
              to={{ pathname: href, state: { customData: data } }}
            >
              {btnText}{" "}
            </NavLink>
          )}

          {secondBtn && (
            <NavLink onClick={onClick} className="d-inline-block " to={href3}>
              {btnText2}{" "}
            </NavLink>
          )}
          {href2 && (
            <a href={href2} target="_blank">
              {btnText}{" "}
            </a>
          )}

          {click && <button onClick={onClick}>{btnText} </button>}
        </Col>
      </Row>
    </div>
  );
}
