import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";

const categoriesWithPriceField = [
  "jobs",
  "CLUB-UPDATES",
  "BRANDING",
  "Tenders",
  "price-discovery",
  "STORAGE",
  "TRANSPORT",
  "FINANCE",
  "buy-input",
];
const ownerConditionPropsData = [
  "Tenders",
  "site-updates",
  "price-discovery",
  "SIGNED-AGREEMENT",
];
const ownerVerticalCondition = ["buy-input", "ADVISORY"];
const defaultOwnerConditionNotRequeired = [
  "Tenders",
  "site-updates",
  "price-discovery",
  "buy-input",
  "ADVISORY",
  "SIGNED-AGREEMENT",
];
const consentRequeired = ["TRADE-DOC", "SIGNED-AGREEMENT"];

function CreateContent({
  props,
  openModel,
  closeModel,
  subCategoryList,
  userName,
  parentID,
  reLoad,
  formateContent,
  ownersOfContent,
}) {
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [nameValue, setNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [expiryDateValue, setExpiryDateValue] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [fileDescription, setFileDescription] = useState(null);
  const [subCategoryForForm, setSubCategoryForForm] = useState("");
  const [defaultOwnerName, setDefaultOwnerName] = useState("");
  const getAppConfig = () => window.AppConfigData;
  // verticals
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [selectedVerticalsName, setSelectedVerticalsName] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [getAssetModel, setAssetModel] = useState([]);
  // consent
  const [consentChecked, setConsentChecked] = useState(false);
  const [consentError, setConsentError] = useState("");

  useEffect(() => {
    setSubCategoryForForm(props?.contentsubcategoryname);
  }, [props?.contentsubcategoryname]);

  useEffect(() => {
    if (!defaultOwnerConditionNotRequeired.includes(props?.categoryname)) {
      setDefaultOwnerName(userName);
    }
  }, [props?.categoryname]);

  // upload content
  const formSubmitHandler = () => {
    // Validate Content
    if (uploadFile === null) {
      return NotificationManager.error("Please Choose File");
    }
    if (nameValue === "") {
      return NotificationManager.error("Enter Name");
    }
    if (subCategoryForForm === "") {
      return NotificationManager.error("Select Sub Category");
    }

    // Validate Consent
    if (consentRequeired.includes(props?.categoryname)) {
      if (!consentChecked) {
        setConsentError("Consent is required");
        return;
      } else {
        setConsentError(""); // Clear the error if the consent is checked
      }
    }

    const postData = new FormData();
    postData.append("file", uploadFile);
    postData.append("mimetype", uploadFile.type);
    postData.append("categoryid", props?.categoryid);
    postData.append("categoryname", props?.categoryname);
    postData.append("contentsubcategory", subCategoryForForm);
    postData.append("contentname", nameValue);
    postData.append("assetdeveloperid", parentID);
    postData.append("contentdescription", descriptionValue);
    postData.append("contentcreator", userName);
    postData.append("contentformat", formateContent);
    postData.append("price", priceValue);
    postData.append("filedescription", fileDescription);
    postData.append("contentownerid", defaultOwnerName);
    postData.append("unit", "INR");
    postData.append("discountedprice", discountValue);
    postData.append("expirydate", expiryDateValue);
    postData.append("event", "none");

    MainService.createContent(postData, parentId)
      .then((res) => {
        if (consentRequeired.includes(props?.categoryname)) {
          if (res.status === 200) {
            consentSubmitHandler(res.data.contentid);
          }
        }
        reLoad();
        closeModel();
        NotificationManager.success(res.status || "uploaded");
        clearFields();
      })
      .catch((err) => {
        NotificationManager.error("something went wrong");
      });
  };
  // upload consent
  const consentSubmitHandler = async (contentid) => {
    let contentId = await contentid;
    const consentValue = consentChecked ? "Yes" : "No";
    const data = JSON.stringify({
      consent: consentValue,
    });
    MainService.profileDocumentStatus("consent", contentId, data)
      .then((res) => {
        clearFields();
        NotificationManager.success(res || "success");
      })
      .catch((err) => {
        NotificationManager.error(err || "something went wrong");
      });
  };
  // clear fields
  const clearFields = () => {
    setNameValue("");
    setDescriptionValue("");
    setPriceValue("");
    setExpiryDateValue("");
    setDiscountValue("");
    setUploadFile(null);
    setFileDescription(null);
    setConsentChecked(false);
  };

  // Asset Verticals
  useEffect(() => {
    getAssetVerticalsIdList();
  }, []);

  useEffect(() => {
    if (!selectedVerticalsId) return;
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  useEffect(() => {
    if (!selectedCategory) return;
    getAssetTypesList();
  }, [selectedVerticalsId, selectedCategory]);

  useEffect(() => {
    if (!selectedAssertTypes) return;
    getModelList();
  }, [selectedVerticalsId, selectedCategory, selectedAssertTypes]);

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList(getAppConfig().EnterpriseId)
      .then((data) => {
        const [firstItem = {}] = data; // Destructuring with default value
        setVerticalsIdList(data);
        setSelectedVerticalsId(firstItem?.assetVerticalId || "");
        setSelectedVerticalsName(firstItem?.assetVerticalFriendlyName || "");
      })
      .catch(() => {
        return NotificationManager.error("something went wrong");
      });
  };
  const getCategoryTypesList = () => {
    MainService.getCategoryTypesList(
      selectedVerticalsId.replace(" ", ""),
      getAppConfig().EnterpriseId
    )
      .then((data) => {
        const [firstCategory = {}] = data; // Destructuring with default value
        setSelectedCategory(firstCategory?.categoryType || "");
        setCategoryTypesList(data);
      })
      .catch(() => {
        return NotificationManager.error("something went wrong");
      });
  };
  const getAssetTypesList = () => {
    MainService.getAssetTypesList(
      selectedVerticalsId.replace(" ", ""),
      selectedCategory.replace(" ", ""),
      getAppConfig().EnterpriseId
    )
      .then((data) => {
        const [firstAssetType = {}] = data; // Destructuring with default value
        setSelectedAssertTypes(firstAssetType?.assetType || "");
        setAssertTypesList(data);
        setAssetModel(
          data?.map((keyChange) => ({ assetmodel: keyChange.assetType }))
        );
      })
      .catch(() => {
        return NotificationManager.error("something went wrong");
      });
  };
  const getModelList = () => {
    MainService.getModelsList(
      selectedVerticalsId.replace(" ", ""),
      selectedCategory.replace(" ", ""),
      selectedAssertTypes.replace(" ", ""),
      username
    )
      .then((data) => {
        if (data?.length > 0) {
          const [firstModel = {}] = data; // Destructuring with default value
          setSelectedModel(firstModel?.modelid || "");
          setModelsList(data);
          setAssetModel((prevState) => [
            ...prevState,
            ...data?.map((keyChange) => ({ assetmodel: keyChange.modelid })),
          ]);
        } else {
          setSelectedModel("");
          setModelsList([]);
        }
      })
      .catch(() => {
        return NotificationManager.error("something went wrong");
      });
  };
  // END Of Asset Verticals

  return (
    <>
      <Dialog
        open={openModel}
        onClose={closeModel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Upload Content "}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                size="small"
                label="Category"
                disabled
                id="category"
                name="category"
                type="text"
                value={props?.categoryname?.toUpperCase()}
              />
            </FormControl>
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                size="small"
                label="Sub Category"
                id="subCategory"
                name="subCategory"
                select
                value={subCategoryForForm}
                onChange={(e) => {
                  setSubCategoryForForm(e.target.value);
                }}
              >
                {subCategoryList &&
                  subCategoryList?.map((idObj, index) => (
                    <MenuItem
                      key={`${index} `}
                      value={idObj?.contentsubcategoryname}
                    >
                      {idObj?.contentsubcategoryname}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
            <TextField
              size="small"
              fullWidth
              label="Content Name"
              id="name"
              name="name"
              type="text"
              value={nameValue}
              onChange={(e) => {
                setNameValue(e.target.value);
              }}
            />
            {(() => {
              if (ownerConditionPropsData.includes(props?.categoryname)) {
                return (
                  <FormControl fullWidth size="small" margin="dense">
                    <TextField
                      size="small"
                      label="Content Owner"
                      id="contentOwner"
                      name="contentOwner"
                      select
                      value={defaultOwnerName}
                      onChange={(e) => {
                        setDefaultOwnerName(e.target.value);
                      }}
                    >
                      {ownersOfContent &&
                        ownersOfContent?.map((idObj, index) => (
                          <MenuItem key={`${index} `} value={idObj?.siteid}>
                            {idObj?.sitename}
                          </MenuItem>
                        ))}
                    </TextField>
                  </FormControl>
                );
              } else if (ownerVerticalCondition.includes(props?.categoryname)) {
                return (
                  <>
                    <FormControl fullWidth size="small" margin="dense">
                      <TextField
                        size="small"
                        id="selectedVerticalsId"
                        name="selectedVerticalsId"
                        value={selectedVerticalsId}
                        onChange={(event) => {
                          setSelectedVerticalsId(event.target.value);
                        }}
                        label="Asset Vertical"
                        select
                      >
                        {verticalsIdList &&
                          verticalsIdList?.map((idObj, index) => (
                            <MenuItem
                              key={`${index} `}
                              value={idObj.assetVerticalId}
                            >
                              {idObj.friendlyName}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                    <FormControl fullWidth size="small" margin="dense">
                      <TextField
                        size="small"
                        id="selectedCategory"
                        name="selectedCategory"
                        value={selectedCategory}
                        onChange={(event) => {
                          setSelectedCategory(event.target.value);
                        }}
                        label="Category"
                        select
                      >
                        {categoryTypesList &&
                          categoryTypesList?.map((idObj, index) => (
                            <MenuItem
                              key={`${index} `}
                              value={idObj.categoryType}
                            >
                              {idObj.categoryType}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                    <FormControl fullWidth size="small" margin="dense">
                      <TextField
                        size="small"
                        id="selectedAssertTypes"
                        name="selectedAssertTypes"
                        value={selectedAssertTypes}
                        onChange={(event) => {
                          setSelectedAssertTypes(event.target.value);
                        }}
                        label="Asset Type"
                        select
                      >
                        {assertTypesList &&
                          assertTypesList?.map((idObj, index) => (
                            <MenuItem key={`${index} `} value={idObj.assetType}>
                              {idObj.assetType}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                    <FormControl fullWidth size="small" margin="dense">
                      <TextField
                        size="small"
                        id="selectedModel"
                        name="selectedModel"
                        value={defaultOwnerName}
                        onChange={(event) => {
                          setDefaultOwnerName(event.target.value);
                        }}
                        label="Content Owner/Model"
                        select
                      >
                        {getAssetModel &&
                          getAssetModel.map((idObj, index) => (
                            <MenuItem
                              key={`${index} `}
                              value={idObj.assetmodel}
                            >
                              {idObj.assetmodel}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                  </>
                );
              } else {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label="Content Owner"
                    disabled
                    id="contentOwner"
                    name="contentOwner"
                    type="text"
                    value={defaultOwnerName}
                  />
                );
              }
            })()}
            <TextField
              size="small"
              fullWidth
              label="Content Uploader"
              disabled
              id="contentUploader"
              name="contentUploader"
              type="text"
              value={userName}
            />
            <TextField
              size="small"
              fullWidth
              label="Content Approver"
              disabled
              id="contentApprover"
              name="contentApprover"
              type="text"
              value={parentID}
            />
            <TextField
              size="small"
              fullWidth
              label="Description"
              id="description"
              name="description"
              type="text"
              value={descriptionValue}
              onChange={(e) => {
                setDescriptionValue(e.target.value);
              }}
            />
            {categoriesWithPriceField.includes(props?.categoryname) && (
              <TextField
                size="small"
                fullWidth
                label="Price"
                id="price"
                name="price"
                type="number"
                value={priceValue}
                onChange={(e) => {
                  setPriceValue(e.target.value);
                }}
              />
            )}
            <TextField
              size="small"
              fullWidth
              label="Expiry Date"
              id="expiryDate"
              name="expiryDate"
              type="date"
              value={expiryDateValue}
              onChange={(e) => {
                setExpiryDateValue(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              size="small"
              fullWidth
              id="doc"
              name="doc"
              type="file"
              onChange={(e) => {
                setUploadFile(e.target.files[0]);
              }}
            />
            {consentRequeired.includes(props?.categoryname) && (
              <FormControl fullWidth size="small" margin="dense">
                <FormControlLabel
                  size="small"
                  control={
                    <Checkbox
                      checked={consentChecked}
                      onChange={() => setConsentChecked(!consentChecked)}
                      color="primary"
                    />
                  }
                  label="I consent to the terms and conditions"
                />
                {consentError && (
                  <Typography variant="caption" color="error">
                    {consentError}
                  </Typography>
                )}
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              color="primary"
              type="submit"
              onClick={() => {
                formSubmitHandler();
              }}
            >
              Submit
            </Button>
            <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              color="error"
              onClick={closeModel}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(CreateContent);
