export const validatePAN = (panNumber) => {
  const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
  return panRegex.test(panNumber);
};

export const validateGSTIN = (gstinNumber) => {
  const gstinRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/;
  return gstinRegex.test(gstinNumber);
};

export const validateIFSC = (ifscCode) => {
  const ifscRegex = /^[A-Za-z]{4}0[A-Z0-9]{6}$/;
  return ifscRegex.test(ifscCode);
};
