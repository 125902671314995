import React from "react";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import FarmersServices from "../Farmer/FarmersServices";
import "./CS.css";
import CSNeeds from "./CSNeeds";

export default function Farmers() {
  return (
    <>
      <UpdatesBanner className="ap-cs" bannerText="CS" />
      <CSNeeds />
      <FarmersServices />
    </>
  );
}
