import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./CBOClubs.css";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import ClubMainPage from "../Club/ClubMainPage";
export default function CBOClubs() {
  const userTypes = ["CBO"];

 
  return (
    <>
      <UpdatesBanner
        className="cbo-club-banner-bg  text-white"
        bannerText="Join Clubs of your choice"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
      />

      <div className="subSectionWrapper">
        <ClubMainPage type={userTypes} explore= "club" />
      </div>
    </>
  );
}
