/* global google */
import React, { useEffect, useState } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  DirectionsRenderer,
} from "react-google-maps";
import Geocode from "react-geocode";

const RouteMap = withScriptjs(
  withGoogleMap((props) => {
    console.log("All routes", props);
    const places = props.markers;

    console.log(places, "places..............");

    const [directions, setDirection] = React.useState({});

    useEffect(() => {
      if (places.length === 2) {
        const waypoints = places.map((p) => ({
          location: { lat: p.latitude, lng: p.longitude },
          stopover: true,
        }));

        const origin = waypoints.shift().location;
        const destination = waypoints.pop().location;

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: window.google.maps.TravelMode.DRIVING,
            waypoints: waypoints,
            optimizeWaypoints: true,
          },
          (result, status) => {
           
            if (result && result.routes && result.routes.length > 0 && result.routes[0].overview_path) {
              let latLng = [];
              result.routes[0].overview_path.forEach((p) => {
                return latLng.push({ lat: p.lat(), lng: p.lng() });
              });
              if (props.getSteps) {
                props.getSteps(latLng);
              }

              if (status === google.maps.DirectionsStatus.OK) {
                setDirection(result);
              }
            }
          }
        );
      }
    }, [places]);

    const handleEvent = (e) => {
      props.selectedPlace(e.latLng.lat(), e.latLng.lng());
    };

    return (
      <GoogleMap
        defaultCenter={props.defaultCenter}
        defaultZoom={props.defaultZoom}
        onClick={(e) => handleEvent(e)}
      >
        {props.markers.map((marker, index) => {
          const position = { lat: marker.latitude, lng: marker.longitude };
          return <Marker  key={index} position={position} />;
        })}
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
    );
  })
);

export default RouteMap;
