import React from "react";
import ExoploreNetworkBanner from "./ExploreNetworkBanner";
import ExploreNetworkContent from "./ExploreNetworkContent";
import { Container } from "@material-ui/core";

export default function ExploreNetwork() {
  return (
    <>
      <ExoploreNetworkBanner />
      <Container className="mb-5">
        <ExploreNetworkContent />
      </Container>
    </>
  );
}
