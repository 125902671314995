import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory, useParams } from "react-router-dom";
import { Capitalize } from "../../Common/CommonContent/ContentedRelatedCommonFun";
import MainService from "../../Common/MainService";

const newStyles = {
  fontWeight: "bold",
  padding: "8px",
  textAlign: "center",
  backgroundColor: "aliceblue",
  position: "sticky",
};

const ViewQuotes = () => {
  const { Id } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [5, 10, 25];
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getQuoteDetails, setQuoteDetails] = useState([]);
  const [getQuoteRequestModel, setQuoteRequestModel] = useState(false);

  useEffect(() => {
    if (Id === null || Id === undefined || Id === "") return;
    viewQuotesHandler();
  }, [Id]);

  const viewQuotesHandler = () => {
    MainService.viewQuotes(Id, parentId, username)
      .then((res) => {
        setQuoteDetails(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //   quote request
  const riseQuoteRequestHandler = () => {
    setQuoteRequestModel(!getQuoteRequestModel);
  };

  const handleGoBack = () => {
    history.goBack(); // This function navigates back to the previous route
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => handleGoBack()}
        ></Button>
      </Box>
      <Box>
        <TableContainer
          component={Paper}
          style={{ height: "370px", marginTop: "10px" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(getQuoteDetails[0] || {}).map((header) => (
                  <TableCell key={header} style={newStyles}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>{Capitalize(header)}</span>
                    </div>
                  </TableCell>
                ))}
                {/* <TableCell style={newStyles}>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(getQuoteDetails) && getQuoteDetails.length > 0 ? (
                getQuoteDetails.map((row, index) => (
                  <TableRow key={index}>
                    {Object.values(row).map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                    {/* <TableCell>
                      <Button
                        sx={{
                          textTransform: "none",
                          fontSize: "10px",
                          border: "1px solid gray",
                        }}
                        // onClick={() => riseQuoteRequestHandler()}
                      >
                        Order
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={1}>
                    <Typography variant="h6" style={{ textAlign: "center" }}>
                      No data available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={getQuoteDetails.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default ViewQuotes;
