import React, { useState, useEffect } from "react";
import MainService from "../../Common/MainService";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { NotificationManager } from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 250,
  },
  root: {
    margin: 10,
    height: 500, // Set a fixed height for the Card
    display: "flex",
    flexDirection: "column",
    overflow: "scroll", // Hide overflow content
  },
  contentContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: 5,
    justifyContent: "space-between",
  },
  media: {
    width: 300,
    height: 250,
  },
  gridRoot: {
    flexGrow: 1,
  },
  // ... (existing styles remain unchanged)
}));

export default function FarmUpdatesComponent() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [appList, setAppList] = useState([]);
  const [farmUpdatesFromAllList, setFarmUpdatesFromAllList] = useState([]);
  const [selectedGrowerId, setSelectedGrowerId] = useState("");
  const [, setselectedGrowerName] = useState("");
  const [isSelectedGrowerId, setIsSelectedGrowerId] = useState(false);
  const [growerIdList, setGrowerIdList] = useState([]);
  const [likeCountValue] = useState(1);
  const { user } = MainService.getLocalData();
  const { parentId } = user;
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("SITE-UPDATES");

  useEffect(() => {
    getCategory();
    getGrowerIdList();
  }, []);

  const getCategory = () => {
    MainService.getCategory(window.AppConfigData.EnterpriseId, selectedCategoryName)
      .then((data) => {
        if (data) {
          setSelectedCategoryId(data);
          getFarmDataFromAll(data);
        } else {
          setSelectedCategoryId("");
        }
      })
      .catch((e) => {
        return NotificationManager.error("");
      });
  };

  const getFarmData = (selectedGrowerId) => {
    setIsLoading(true);
    MainService.getFarmUpdates(selectedGrowerId, selectedCategoryId)
      .then((data) => {
        setIsLoading(false);
        setAppList(data);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error(e);
      });
  };

  const getFarmDataFromAll = (selectedCategoryId) => {
    setIsSelectedGrowerId(false);
    setIsLoading(true);
    MainService.getFarmUpdatesFromAll(selectedCategoryId)
      .then((data) => {
        setIsLoading(false);
        setFarmUpdatesFromAllList(data);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error(e);
      });
  };

  const getGrowerIdList = () => {
    MainService.getAllSolutions(parentId, parentId)
      .then((data) => {
        if (data.length > 0) {
          setselectedGrowerName(data[0].solutionname);
        }
        setGrowerIdList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const likeCountClick = (contentid) => {
    let likeCountBody = {
      likeCountValue,
    };
    const likeCountBodyData = JSON.stringify(likeCountBody);
    MainService.likeCount(likeCountBodyData, contentid)
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    if (isSelectedGrowerId === true) {
      getFarmData(selectedGrowerId);
    } else {
      getFarmDataFromAll(selectedCategoryId);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12} sm={12} className="topHeadersWrapper">
          <span>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Select Association
              </InputLabel>
              <Select
                style={{ minWidth: "120px" }}
                id="selectedGrowerId"
                value={selectedGrowerId}
                onChange={(event) => {
                  setSelectedGrowerId(event.target.value);
                }}
                onClick={(e) => {
                  setIsSelectedGrowerId(true);
                  setIsLoading(true);
                  if (e.target.value === "all") {
                    getFarmDataFromAll(selectedCategoryId);
                  } else {
                    setSelectedGrowerId(e.target.value);
                    getFarmData(e.target.value);
                  }
                }}
                label="Select Grower"
              >
                <MenuItem selected="true" value="all">
                  All
                </MenuItem>
                {growerIdList.map((idObj) => (
                  <MenuItem key={idObj.solutionid} value={idObj.solutionid}>
                    {idObj.solutionname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
        </Grid>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <Grid item md={12} sm={12} xs={12}>
            {isSelectedGrowerId ? (
              <Grid item md={12} sm={12} xs={12}>
                <Grid md={12} sm={12} xs={12}>
                  <h3>Farm Updates From &nbsp;"{selectedGrowerId}" &nbsp;</h3>
                </Grid>
                <br />

                {appList.length > 0 ? (
                  <Grid className={classes.contentContainer}>
                    {appList.map((data, index) => (
                      <Grid item md={4} xs={12} sm={4} key={index}>
                        <Card className={classes.root} variant="outlined">
                          <CardActionArea>
                            <CardMedia
                              className={classes.media}
                              image={data.locationurl}
                              title="farm location"
                            />
                            <CardContent>
                              <Typography>
                                {Object.keys(data).length > 0 ? (
                                  Object.entries(data).map(
                                    ([key, value]) => (
                                      <span key={key}>
                                        <p>
                                          {" "}
                                          {key} : {value}
                                        </p>
                                      </span>
                                    )
                                  )
                                ) : (
                                  <p>No Data Found</p>
                                )}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <h4>Not Found</h4>
                )}
              </Grid>
            ) : (
              <Grid item md={12} sm={12} xs={12}>
                <Grid item md={12} sm={12} xs={12}>
                  <h3>Farm Updates From All</h3>
                </Grid>
                {farmUpdatesFromAllList.length > 0 ? (
                  <Grid className={classes.contentContainer}>
                    {farmUpdatesFromAllList.map((data, index) => (
                      <Grid item md={4} sm={4} xs={12} key={index}>
                        <Card className={classes.root} variant="outlined">
                          <CardActionArea>
                            <CardMedia
                              className={classes.media}
                              image={data.locationurl}
                              title="farm location"
                            />
                            <CardContent>
                              <Typography>
                                {Object.keys(data).length > 0 ? (
                                  Object.entries(data).map(
                                    ([key, value]) => (
                                      <span key={key}>
                                        <p>
                                          {" "}
                                          {key} : {value}
                                        </p>
                                      </span>
                                    )
                                  )
                                ) : (
                                  <p>No Data Found</p>
                                )}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <h4>Not Found</h4>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}
