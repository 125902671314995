import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../../../Common/MainService";
import QuoteRequestModel from "../../../QuotationManagement/QuoteRequestModel";
import FormForOrderProduct from "../../Order/ProductOrder/FormForOrderProduct";

const newStyles = {
  fontWeight: "bold",
  padding: "8px",
  textAlign: "center",
  backgroundColor: "aliceblue",
  position: "sticky",
};

const keysData = [
  { key: "Name", val: "name" },
  { key: "Mobile", val: "phoneNo" },
  { key: "Status", val: "orderStatus" },
  { key: "TimeStamp", val: "timeStamp" },
  { key: "Products Details", val: "detailsOfProduct" },
];

const ProductsInvoiceTable = ({ reqType, categoryType }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [5, 10, 25];
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rfqStatusFilter, setRfqStatusFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState("timeStamp");
  const [sortOrder, setSortOrder] = useState("asc");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getQuoteDetails, setQuoteDetails] = useState([]);
  const [getQuoteRequestModel, setQuoteRequestModel] = useState(false);
  const [reqId, setRfqId] = useState("");
  const [transportModel, setTransportModel] = useState({
    openModel: false,
    transportDetails: [],
    setReqType: "",
  });
  const userType = localStorage.getItem("userType");

  useEffect(() => {
    if (reqType) {
      getQuoteDataLoopforProductsHandler();
    }
  }, [
    reqType,
    categoryType,
    rfqStatusFilter,
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
  ]);

  const getQuoteDataLoopforProductsHandler = useCallback(() => {
    MainService.orderProductDataGetByLoopBasedonUsername(
      reqType,
      categoryType,
      rfqStatusFilter,
      page,
      rowsPerPage,
      sortBy,
      sortOrder,
      parentId,
      username
    )
      .then((res) => {
        let newArray;
        if (Array.isArray(res) && res.length > 0) {
          newArray = res?.map((item) => ({
            orderId: item?.requestDetails.orderId,
            name: item?.requestDetails?.personalDetails?.name || "",
            phoneNo: item?.requestDetails?.personalDetails?.phoneNo || "",
            emailId: item?.requestDetails?.personalDetails?.emailId || "",
            detailsOfProduct:
              item?.requestDetails?.productDetails?.map((product) => ({
                productCategory: product?.productCategory || "",
                contentsubcategoryname: product?.productSubCategory || "",
                origin: product?.origin || "",
                grade: product?.grade || "",
                quantity: product?.quantity || "",
              })) ?? [],
            orderStatus: item?.requestDetails?.orderStatus || "",
            timeStamp: item?.requestDetails?.timeStamp || "",
            requestType: item?.requestDetails?.requestType || "",
            pickUpAddress: item?.requestDetails?.pickUpAddress ?? {},
            deliveryAddress: item?.requestDetails?.deliveryAddress ?? {},
            supplierDetails: item?.requestDetails?.supplierDetails ?? {},
          }));
        } else {
          newArray = [];
        }
        setQuoteDetails(newArray);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err && err.message) {
          NotificationManager.error(err.message); // Assuming 'message' is the property you want to display
        } else {
          NotificationManager.error("An error occurred");
        }
      });
  }, [
    reqType,
    categoryType,
    rfqStatusFilter,
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
    parentId,
    username,
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = () => {
    setRfqStatusFilter("");
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  // quote request
  const riseQuoteRequestHandler = () => {
    setQuoteRequestModel(!getQuoteRequestModel);
  };

  // Transport model handler
  const orderDetailsHandler = (data, reqType) => {
    setTransportModel({
      openModel: !transportModel?.openModel,
      transportDetails: data ?? [],
      setReqType: reqType,
    });
  };

  return (
    <>
      <Box>
        <IconButton onClick={handleClick}>
          <FilterListIcon />
          <Box component="span" sx={{ fontSize: "15px" }}>
            Filter
          </Box>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            <FormControl
              size="small"
              variant="outlined"
              sx={{
                width: "100%",
              }}
            >
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={rfqStatusFilter}
                onChange={(e) => setRfqStatusFilter(e.target.value)}
              >
                <MenuItem value="created">Created</MenuItem>
                <MenuItem value="open">Open</MenuItem>
                <MenuItem value="close">Close</MenuItem>
              </Select>
            </FormControl>
          </MenuItem>
          <MenuItem>
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
                handleFilter();
              }}
            >
              Clear
            </Button>
          </MenuItem>
        </Menu>
        <TableContainer
          component={Paper}
          style={{ height: "370px", marginTop: "15px" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {keysData &&
                  keysData?.map((header) => (
                    <TableCell
                      key={header}
                      style={{
                        ...newStyles,
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{header.key}</span>
                        {header.val === "timeStamp" && (
                          <Tooltip
                            title={`Sort by ${header.key} ${
                              sortOrder === "asc" ? "descending" : "ascending"
                            }`}
                          >
                            <IconButton onClick={() => handleSort(header.val)}>
                              {sortOrder === "asc" ? (
                                <ArrowUpwardIcon />
                              ) : (
                                <ArrowDownwardIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                  ))}
                <TableCell
                  style={{
                    ...newStyles,
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                  }}
                  colSpan={5}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                  </TableCell>
                </TableRow>
              ) : Array.isArray(getQuoteDetails) &&
                getQuoteDetails.length > 0 ? (
                getQuoteDetails.map((row, index) => (
                  <TableRow key={index}>
                    {keysData &&
                      keysData.map((key) => {
                        if (key.val === "detailsOfProduct") {
                          return (
                            <TableCell key={key.val}>
                              {row.detailsOfProduct.map(
                                (product, productIndex) => (
                                  <TableRow key={productIndex}>
                                    <TableCell
                                      key={productIndex}
                                    >{`Origin ${product.origin}`}</TableCell>
                                    <TableCell
                                      key={productIndex}
                                    >{`Grade ${product.grade}`}</TableCell>
                                    <TableCell
                                      key={productIndex}
                                    >{`Quantity ${product.quantity}`}</TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={key.val}>{row[key.val]}</TableCell>
                          );
                        }
                      })}
                    <TableCell>
                      <Button
                        sx={{
                          textTransform: "none",
                          fontSize: "10px",
                          border: "1px solid gray",
                        }}
                        onClick={() => {
                          riseQuoteRequestHandler();
                          setRfqId(row.orderId);
                        }}
                        disabled={row.orderStatus === "close"}
                      >
                        Invoice Request
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        sx={{
                          textTransform: "none",
                          fontSize: "10px",
                          border: "1px solid gray",
                        }}
                        onClick={() => {}}
                        disabled={
                          reqType === "agri-buy-products-order-req" ||
                          reqType === "cm-product-order-req" ||
                          reqType === "transport-product-order-req" ||
                          reqType === "storage-product-order-req"
                        }
                      >
                        CF
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        sx={{
                          textTransform: "none",
                          fontSize: "10px",
                          border: "1px solid gray",
                        }}
                        onClick={() => {}}
                        disabled={
                          reqType === "agri-buy-products-order-req" ||
                          reqType === "cf-agri-raw-products-order-req" ||
                          reqType === "transport-product-order-req" ||
                          reqType === "storage-product-order-req"
                        }
                      >
                        CM
                      </Button>
                    </TableCell>
                    {/* <TableCell>
                      <Button
                        sx={{
                          textTransform: "none",
                          fontSize: "10px",
                          border: "1px solid gray",
                        }}
                        onClick={() => {
                          orderDetailsHandler(
                            row,
                            "agri-buy-products-order-req"
                          );
                        }}
                        disabled={
                          reqType === "agri-buy-products-order-req" ||
                          reqType === "cm-product-order-req" ||
                          reqType === "cf-agri-raw-products-order-req" ||
                          reqType === "transport-product-order-req" ||
                          reqType === "storage-product-order-req"
                        }
                      >
                        Bulk Buy
                      </Button>
                    </TableCell> */}
                    <TableCell>
                      <Button
                        sx={{
                          textTransform: "none",
                          fontSize: "10px",
                          border: "1px solid gray",
                        }}
                        onClick={() => {
                          orderDetailsHandler(
                            row,
                            "transport-product-order-req"
                          );
                        }}
                        disabled={reqType === "transport-product-order-req"}
                      >
                        Transport
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography variant="h6" style={{ textAlign: "center" }}>
                      Order Data Not Found.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={getQuoteDetails.length < 5 ? getQuoteDetails.length : -1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      {/* Invoice component model */}
      <QuoteRequestModel
        openModel={getQuoteRequestModel}
        closeModel={riseQuoteRequestHandler}
        rfqId={reqId}
        componentName="invoice"
      />

      {/* Transport order model */}
      <FormForOrderProduct
        openModel={transportModel?.openModel}
        closeModel={orderDetailsHandler}
        verticalsIds={[]}
        categoryTypes={[]}
        assertTypes={[]}
        models={[]}
        categoryName={
          transportModel?.transportDetails?.detailsOfProduct?.[0]
            ?.productCategory ?? ""
        }
        getSubCategories={
          transportModel?.transportDetails?.detailsOfProduct ?? []
        }
        reLoad={() => {}}
        reqTypesBasedOnRoleandUser={{
          NULL: {
            [userType]: {
              "PROCESSED-PRODUCTS": [{ type: transportModel?.setReqType }],
              "RAW-PRODUCTS": [{ type: transportModel?.setReqType }],
              "SEMI-PROCESSED": [{ type: transportModel?.setReqType }],
            },
          },
          BASIC: {
            [userType]: {
              "PROCESSED-PRODUCTS": [{ type: transportModel?.setReqType }],
              "RAW-PRODUCTS": [{ type: transportModel?.setReqType }],
              "SEMI-PROCESSED": [{ type: transportModel?.setReqType }],
            },
          },
          STANDARD: {
            [userType]: {
              "PROCESSED-PRODUCTS": [{ type: transportModel?.setReqType }],
              "RAW-PRODUCTS": [{ type: transportModel?.setReqType }],
              "SEMI-PROCESSED": [{ type: transportModel?.setReqType }],
            },
          },
          PREFFERED: {
            [userType]: {
              "PROCESSED-PRODUCTS": [{ type: transportModel?.setReqType }],
              "RAW-PRODUCTS": [{ type: transportModel?.setReqType }],
              "SEMI-PROCESSED": [{ type: transportModel?.setReqType }],
            },
          },
          ADMIN: {
            [userType]: {
              "PROCESSED-PRODUCTS": [{ type: transportModel?.setReqType }],
              "RAW-PRODUCTS": [{ type: transportModel?.setReqType }],
              "SEMI-PROCESSED": [{ type: transportModel?.setReqType }],
            },
          },
        }}
        props={transportModel?.transportDetails}
      />
    </>
  );
};

export default memo(ProductsInvoiceTable);
