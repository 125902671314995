import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import RoleManagementComponent from "../AssetDeveloperRole/RoleManagement/RoleManagement";
import RoleProfileManagementComponent from "../AssetDeveloperRole/RoleProfileManagement/RoleProfileManagementComponent";
import AAMCSiteManagementComponent from "../../Main/AssetDeveloperRole/AAMCSiteManagement/AAMCSiteManagementComponent";
import MainService from "../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import FPNUsersComponent from "./FPNUsersComponent";

export default function FPNComponent(props) {
  const [viewRole, setViewRole] = useState(false);  
  const [viewSiteManagement, setViewSiteManagement] = useState(false);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [roleNameValue, setRoleNameValue] = useState("");
  const [viewSuperRole, setViewSuperRole] = useState(false);
  const [viewUsers,setViewUsers] = useState(true);

  useEffect(() => {
    getRoleName();
  }, []);
  const getRoleName = () => {
    MainService.getRole(parentId, username)
      .then((res) => {
        setRoleNameValue(res.role);
        console.log("role P n/w res:", res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const viewRoleClick = () => {
    setViewRole(true);   
    setViewSiteManagement(false);
    setViewSuperRole(false);
    setViewUsers(false);
  };
  const viewSiteManagementClick = () => {
    setViewRole(false);   
    setViewSiteManagement(true);
    setViewSuperRole(false);
    setViewUsers(false);
  };
  const viewSuperRoleClick = () => {
    setViewRole(false);   
    setViewSiteManagement(false);
    setViewSuperRole(true);
    setViewUsers(false);
  };
  const viewUserManagementClick = () => {
    setViewRole(false);   
    setViewSiteManagement(false);
    setViewSuperRole(false);
    setViewUsers(true);
  };

  return (
    <>
      <div className="topHeadersWrapper">
      <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewUserManagementClick();
            }}
          >
            User Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSiteManagementClick();
            }}
          >
            Site Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewSuperRoleClick();
            }}
          >
            Role Management
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              viewRoleClick();
            }}
          >
            Role Profile Management
          </Button>
        </div>
        &nbsp;
      </div>



      <div style={{ paddingTop: "20px" }}>
      {viewSiteManagement && <AAMCSiteManagementComponent siteType={["OrganicFarm"]} />}
        {viewRole && <RoleProfileManagementComponent siteType={["OrganicFarm"]} networkType="FPN"/>}
        {viewSuperRole && <RoleManagementComponent />}
        {viewUsers&& <FPNUsersComponent/>}
      </div>
    </>
  );
}