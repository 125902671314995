import React, { useEffect, useState } from "react";
import MainService from "../../Common/MainService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Modal,
  Fade,
  Backdrop,
  Paper,
  TablePagination,
} from "@mui/material";

const StyledTableHead = {
  backgroundColor: "lavender", // Change background color
  color: "#333",
  fontWeight: "bold",
  textAlign: "center",
};

const BookingReqComp = () => {
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [bookingRequests, setBookingRequests] = useState([]);
  const [selectedActions, setSelectedActions] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [statusValue, setStatusValue] = useState(""); // State for status value
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const history = useHistory();

  const handleBookingReq = async () => {
    try {
      const response = await MainService.getBookingRequests(username);
      setBookingRequests(response);
      console.log(response, "GET ALL BOOKING REQ::::::::::");
    } catch (error) {
      console.error("Error getting booking req", error);
    }
  };

  const updateBookingReqStatus = async () => {
    try {
      const requestBody = {
        status: statusValue,
      };

      const updateReqStatus = await MainService.updateBookingReqStatus(
        selectedOrderId,
        requestBody
      );

      // Update the status in the local state
      const updatedRequests = bookingRequests.map((booking) => {
        if (booking.orderid === selectedOrderId) {
          return { ...booking, status: statusValue };
        }
        return booking;
      });
      setBookingRequests(updatedRequests);

      // Close modal
      handleCloseModal();
    } catch (error) {
      console.error("error updating booking req status", error);
    }
  };

  useEffect(() => {
    handleBookingReq();
    // updateBookingReqStatus();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAction = (action, orderId) => {
    setSelectedActions({
      ...selectedActions,
      [orderId]: action,
    });
    console.log("Action:", action, "order ID:", orderId);
    // Redirect to /AddBooking page if "Create Booking" is selected
    if (action === "Create Booking") {
      history.push("/main/addBooking");
    }

    if (action === "Update Status") {
      setOpenModal(true);
      setSelectedOrderId(orderId);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOrderId(null);
    setStatusValue("");
  };

  const handleStatusUpdate = () => {
    // Perform status update logic here
    console.log(
      "Updating status for order ID:",
      selectedOrderId,
      "to",
      statusValue
    );
    // Close modal
    updateBookingReqStatus();
    handleCloseModal();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "#f0f0f0",
          // padding: "10px",
          borderRadius: "8px",
          // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          // margin: "20px",
        }}
      >
        <h1
          style={{
            color: "#333",
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            fontSize: "2rem",
          }}
        >
          List of Booking Request
        </h1>
      </Box>

      <TableContainer component={Paper} sx={{ maxHeight: 490 }}>
        <Table>
          <TableHead style={StyledTableHead}>
            <TableRow>
              <TableCell>Sr.No.</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Order ID</TableCell>
              <TableCell>Trip ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>More Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? bookingRequests.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : bookingRequests
            ).map((bookingreq, index) => (
              <TableRow key={index}
              sx={{
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
              >
                <TableCell>{page * rowsPerPage + index + 1}</TableCell>

                <TableCell>{bookingreq.source}</TableCell>

                <TableCell>{bookingreq.orderid}</TableCell>
                <TableCell>{bookingreq.tripid}</TableCell>
                <TableCell>{bookingreq.status}</TableCell>

                <TableCell>{bookingreq.timestamp}</TableCell>
                <TableCell>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Options
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedActions[bookingreq.orderid] || ""}
                        label="select action"
                        onChange={(e) =>
                          handleAction(e.target.value, bookingreq.orderid)
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Update Status">Update Status</MenuItem>
                        <MenuItem value="Create Invoice">
                          Create Invoice
                        </MenuItem>
                        <MenuItem value="Create Booking">
                          Create Booking
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
        component="div"
        count={bookingRequests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Modal for updating status */}
      <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
        <Fade in={openModal}>
          <Box
            sx={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              maxWidth: "400px",
              margin: "auto",
              marginTop: "100px",
            }}
          >
            <h2>Update Status</h2>
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status-select"
                value={statusValue}
                onChange={(e) => setStatusValue(e.target.value)}
              >
                <MenuItem value="accept">Accept</MenuItem>
                <MenuItem value="reject">Reject</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={handleStatusUpdate}
              style={{ marginTop: "10px" }}
            >
              Update
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default BookingReqComp;
