import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { Capitalize } from "./ContentedRelatedCommonFun";

const newStyles = {
  fontWeight: "bold",
  padding: "8px",
  textAlign: "center",
  backgroundColor: "aliceblue",
  color: "#3c8a67",
};

const ContentDynamicTable = ({ columns, data, actions }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  return (
    <Paper sx={{ m: 1 }}>
      <TableContainer sx={{ maxHeight: "300px" }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell key={column} style={newStyles}>
                  {Capitalize(column)}
                </TableCell>
              ))}
              {actions && actions?.length > 0 && (
                <TableCell style={newStyles}>Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          {Array.isArray(slicedData) && slicedData?.length > 0 ? (
            <TableBody>
              {slicedData?.map((row, index) => (
                <TableRow key={index}>
                  {columns?.map((column, i) => (
                    <TableCell
                      key={column + i}
                      style={{ fontWeight: 500, fontSize: "15px" }}
                    >
                      {row[column]}
                    </TableCell>
                  ))}
                  {actions && actions?.length > 0 && (
                    <TableCell>
                      {actions?.map((action, actionIndex) => (
                        <Button
                          key={actionIndex}
                          color={
                            action.label === "Delete" ? "error" : "primary"
                          }
                          variant="outlined"
                          onClick={() => action.click(row)}
                        >
                          {action.label}
                        </Button>
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                  No Data Found
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ContentDynamicTable;
