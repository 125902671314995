import React, { useState, useEffect, useMemo } from "react";
import { Button } from "@mui/material";
import LogisticProvidersComponent from "../ViewLogisticProviders/LogisticsProviderComponent.js";
import ViewAssetDistributersComponent from "../ViewAssetDistributers/ViewAssetDistributersComponent";
import ViewAssetOEMComponent from "../ViewAssetOEM/ViewAssetOEMComponent";
import ViewBuyersComponent from "../ViewBuyers/ViewBuyersComponent";
import ViewAgentsComponent from "../ViewAgents/ViewAgentsComponent";
import ViewMSMEAdvisorComponent from "../MSMEAdvisor/MSMEAdvisorComponent";
import ViewFMCComponent from "../FMC/FMCComonent";
import ViewPMCComponent from "../PMC/PMCComponent";
import ViewAHSPComponent from "../AHSP/AHSPComponent";
import ViewFoodProcessorComponent from "../FoodProcessor/FoodProcessorComponent";
import ViewAssetCertifiedConsultantComponent from "../AssetCertifiedConsultant/AssetCertifiedConsultantComponent";
import Main from "../../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import GovtDepotComponent from "../GovtDepot/GovtDepotComponent.js";
import ViewClinicAdminsComponent from "../ViewClinicAdmins/ViewClinicAdminsComponent.js";
import MerchantExporterComponent from "../MerchantExporter/MerchantExporterComponent.js";
import BrokerComponent from "../ViewBroker/ViewBrokersComponent";
import AssetDeveloperComponent from "../ViewAssetDevelopers/ViewAssetDevelopersComponent";
import ViewAgriMinersComponent from "../ViewAgriMiner/ViewAgriMinerComponent";
import StorageCompanyComponent from "../StorageCompany/StorageCompanyComponent";
import CBOComponent from "../CBO/CBOComponent";
import FMCDealerComponent from "../Dealer/FMCDealerComponent";
import PMCDealerComponent from "../Dealer/PMCDealerComponent";
import OFCComponent from "../OFC/OFCComponent";
import AICComponent from "../AIC/AICComponent";
import BiogasEquipCompanyComponent from "../BiogasEquipCompany/BiogasEqipCompanyComponent.js";
import AIRComponent from "../AIR/AIRComponent.js";
import PHPComponent from "../PHP/PHPComponent.js";
import DeviceMakerComponent from "../DeviceMaker/DeviceMakerComonent.js";
import "../MyOrders/MyOrderStyleComponent.css";

export default function GlobalUsersComponent(props) {
  const [userTypeValue, setUserTypeValue] = useState("");
  const { user } = Main.getLocalData();
  const { username, parentId } = user;
  const [activeComponent, setActiveComponent] = useState(props.userTypes ? props.userTypes[0] : "");
  const UserRole = localStorage.getItem("UserRole");

  useEffect(() => {
    getUserType();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserType = async () => {
    try {
      const data = await Main.getConsumerUserType(parentId, username);
      setUserTypeValue(data);
    } catch (error) {
      NotificationManager.error(error);
    }
  };

  const handleClick = (component) => {
    setActiveComponent(component);
  };

  const renderActiveComponent = (activeComponent) => {
    switch (activeComponent) {
      case "LogisticsProvider":
        return <LogisticProvidersComponent />;
      case "AgriAssetDistributor":
        return <ViewAssetDistributersComponent />;
      case "AssetOEM":
        return <ViewAssetOEMComponent />;
      case "Buyer":
        return <ViewBuyersComponent />;
      case "Agent":
        return <ViewAgentsComponent />;
      case "MSME-ADVISOR":
        return <ViewMSMEAdvisorComponent />;
      case "FMC":
        return <ViewFMCComponent />;
      case "PMC":
        return <ViewPMCComponent />;
      case "AHSP":
        return <ViewAHSPComponent />;
      case "FOOD-PROCESSOR":
        return <ViewFoodProcessorComponent />;
      case "AssetCertifiedConsultant":
        return <ViewAssetCertifiedConsultantComponent />;
      case "GovtDept":
        return <GovtDepotComponent />;
      case "ClinicAdmin":
        return <ViewClinicAdminsComponent />;
      case "MerchantExporter":
        return <MerchantExporterComponent />;
      case "Broker":
        return <BrokerComponent />;
      case "AssetDeveloper":
        return <AssetDeveloperComponent />;
      case "AgriMiner":
        return <ViewAgriMinersComponent />;
      case "StorageCompany":
        return <StorageCompanyComponent />;
      case "CBO":
        return <CBOComponent />;
      case "FMCDealer":
        return <FMCDealerComponent />;
      case "PMCDealer":
        return <PMCDealerComponent />;
      case "OFC":
        return <OFCComponent />;
      case "AIC":
        return <AICComponent />;
      case "BiogasEquipCompany":
        return <BiogasEquipCompanyComponent />;
      case "AIR":
        return <AIRComponent />;
      case "PHP":
        return <PHPComponent />;
      case "DeviceMaker":
        return <DeviceMakerComponent />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="container">
        <div className="container">
          <div className="topHeadersWrapper">
            {props?.userTypes?.map((type) => (
              <div className="topHeaders" key={type}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => handleClick(type)}
                  style={{ margin: "5px" }}
                  disabled={activeComponent === type}
                  className={activeComponent === type ? "activeButton" : ""}
                >
                  {type}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Render active component */}
      <div>{activeComponent && renderActiveComponent(activeComponent)}</div>
    </>
  );
}
