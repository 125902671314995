import React from 'react';
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";


export default function AROnboardingServiceSection() {
  return (
    <>
    <HomeSubSection
      className="rowReverseAbout borderedCards borderedCards--mod"
      className3="retailers-img"
      descriptionTitle="
      Offline Registration of Agri-Retailers "
      description="In this service, agents act as intermediaries to facilitate the offline registration process for agri-retailers. Through this system, the agent manages the registration requests, ensuring smooth onboarding of agri-retailers into ARN network. This process enhances efficiency and accessibility, strengthening partnerships and collaborations within the agricultural supply chain.
      "
    />
    <HomeSubSection
      className1="aboutBgGrey"
      className="rowReverseAbout--mod borderedCards borderedCards--mod"
      className3="retail-registration"
      descriptionTitle="
      Publishing Retail Shop Details"
      description="In this service, the agent facilitates the publication of detailed information regarding retail shops operated by agri-retailers. Following publication, rigorous verification processes are conducted to ensure accuracy and reliability. This initiative fosters transparency and trust within the agricultural retail sector, facilitating informed decisions and sustainable partnerships."
    />
  </>
  )
}
