import React from 'react'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import CMonboardingServiceSection from './CMonboardingServiceSection'
export default function ManufacturingOnboard() {
  return (
    <>
     <UpdatesBanner 
      className="onBoardingBanner text-white"
      bannerText="Plant Onboarding Service"/>
      <div className='subSectionWrapper'>
        <CMonboardingServiceSection/>
      </div>
    </>  )
}
