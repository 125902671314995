import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardContent, Grid } from "@mui/material";
import React from "react";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
  },
  title: {
    fontSize: 14,
  },
}));

const BrandDashboard = () => {
  const classes = useStyles();

  return (
    <>
      <Card sx={{ mb: "1rem" }}>
        <Box sx={{ p: 2, display: "flex" }}>
          <Grid container spacing={0}>
            <Grid item md={12} xs={12}>
              <Grid container className={classes.gridRoot} spacing={2}>
                <Grid item md={6} xs={12}>
                  <Card className={classes.root} variant="outlined">
                    <CardContent>
                      <Link
                        to={`/main/market`}
                        style={{ textDecoration: "none" }}
                      >
                        MANAGE INVENTORY
                      </Link>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Card className={classes.root} variant="outlined">
                    <CardContent>
                      <Link
                        to={`/main/socialmarketplace`}
                        style={{ textDecoration: "none" }}
                      >
                        MANAGE RFQ
                      </Link>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};
export default BrandDashboard;
