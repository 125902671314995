import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import StarIcon from "@material-ui/icons/Star";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import { TextareaAutosize } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 250,
  },
  gridRoot: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  menuPaper: {
    width: 200,
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
  menuPaperForm: {
    minHeight: 50,
    maxHeight: 150,
    marginTop: 45,
  },
}));

export default function FeedbacksComponent() {
  const classes = useStyles();
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [, setSiteList] = useState([]);
  const [siteModelsList, setModelsList] = useState([]);
  const [assetsData, setAssetsData] = useState([]);
  const [growerIdList, setGrowerIdList] = useState([]);
  const [selectedGrowserId, setSelectedGrowserId] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("AssetDeveloper");
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [selectedAssetSiteId, setSelectedAssetSiteId] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [selectedModelSiteId, setSelectedModelSiteId] = useState("");
  const [selectedModelId, setSelectedModelId] = useState("");
  const [selectedSchemeModelId, setSelectedSchemeModelId] = useState("");
  const [, setSelectedSchemeAssetdeveloperId] = useState("");
  const [isSelectedGrowserId, setIsSelectedGrowserId] = useState(true);
  const [isSelectedSiteName, setIsSelectedSiteName] = useState(false);
  const [isSelectedSAssetId, setIsSelectedAssetId] = useState(false);
  const [isSelectedSModelId, setIsSelectedModelId] = useState(false);
  const [isSelectedSchemeModelId, setIsSelectedSchemeModelId] = useState(false);
  const [duplicateSiteList, setDuplicateSiteList] = useState([]);
  const [duplicateModelList, setDuplicateModelList] = useState([]);
  const [noDataFound, setNoDataFound] = useState("");
  const [assetDeveloperFeedbacksList, setAssetDeveloperFeedbacksList] =
    useState([]);
  const [siteFeedbacksList, setSiteFeedbacksList] = useState([]);
  const [schemeFeedbacksList, setSchemeFeedbacksList] = useState([]);
  const [assetFeedbacksList, setAssetFeedbacksList] = useState([]);

  useEffect(() => {
    getGrowerIdList();
    getAllSolutionsSite();
    myAssetsList();
    getInvestedModels();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAssetDeveloperFeedbacksList(selectedGrowserId);
  }, [selectedGrowserId]);
  useEffect(() => {
    getSiteFeedbacksList(selectedSiteId);
  }, [selectedSiteId]);
  useEffect(() => {
    getAssetFeedbacksList(selectedAssetId);
  }, [selectedAssetId]);
  useEffect(() => {
    getSchemeFeedbacksList(selectedSchemeModelId);
  }, [selectedSchemeModelId]);

  const getAssetDeveloperFeedbacksList = (selectedGrowserId) => {
    if (selectedGrowserId !== undefined && selectedGrowserId !== "") {
      MainService.getSolutionFeedbacks(selectedGrowserId)
        .then((data) => {
          if (data.length > 0) {
            setAssetDeveloperFeedbacksList(data);
          } else {
            setAssetDeveloperFeedbacksList([]);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  const getSiteFeedbacksList = (selectedSiteId) => {
    if (selectedSiteId !== undefined && selectedSiteId !== "") {
      MainService.getSiteFeedbacks(selectedSiteId)
        .then((data) => {
          if (data.length > 0) {
            setSiteFeedbacksList(data);
          } else {
            setSiteFeedbacksList([]);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  const getAssetFeedbacksList = (username, selectedAssetId) => {
    if (selectedAssetId !== undefined && selectedAssetId !== "") {
      MainService.getAssetFeedback(selectedAssetId)
        .then((data) => {
          if (data.length > 0) {
            setAssetFeedbacksList(data);
          } else {
            setAssetFeedbacksList([]);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  const getSchemeFeedbacksList = (selectedSchemeModelId) => {
    if (selectedSchemeModelId !== undefined && selectedSchemeModelId !== "") {
      MainService.getModelFeedbacks(selectedSchemeModelId)
        .then((data) => {
          if (data.length > 0) {
            setSchemeFeedbacksList(data);
          } else {
            setSchemeFeedbacksList([]);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  const getAllSolutionsSite = () => {
    MainService.getAllAssociatedSolutionSites(username)
      .then((data) => {
        if (data.length > 0) {
          removeDuplicateSites(data);
          setSelectedSiteId(data[0].siteid);
          getSiteFeedbacksList(data[0].siteid);
        } else {
          setNoDataFound("No Data Found");
        }
        setSiteList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getInvestedModels = () => {
    MainService.getInvestedModels(username)
      .then((data) => {
        if (data.length > 0) {
          // removeDuplicateModels(data);
          setSelectedSchemeAssetdeveloperId(data[0].assetdeveloperid);
          setSelectedSchemeModelId(data[0].modelid);
          getSchemeFeedbacksList(data[0].modelid);
        } else {
          setNoDataFound("No Data Found");
        }
        setModelsList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const myAssetsList = () => {
    MainService.myAssetsList(username)
      .then((data) => {
        if (data.length > 0) {
          removeDuplicateModels(data);
          setSelectedAssetId(data[0].assetId);
          setSelectedAssetSiteId(data[0].siteId);
          setSelectedModelId(data[0].modelId);
          setSelectedModelSiteId(data[0].siteId);
          getAssetFeedbacksList(username, data[0].assetId);
        } else {
          setNoDataFound("No Data Found");
        }

        setAssetsData(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  function removeDuplicateSites(arr) {
    const result = arr.filter(
      (key, index, self) =>
        index === self.findIndex((t) => t.sitename === key.sitename)
    );

    setDuplicateSiteList(result);
  }
  function removeDuplicateModels(arr) {
    const result = arr.filter(
      (key, index, self) =>
        index === self.findIndex((t) => t.modelName === key.modelName)
    );

    setDuplicateModelList(result);
  }
  const getGrowerIdList = () => {
    // MainService.getAllSolutions()  //if feedback required to be given for all solutions

    MainService.getAllAssociatedSolutions(username)
      .then((data) => {
        if (data.length > 0) {
          // setSelectedGrowserId(data[0].solutionid);  //if feedback required to be given for all solutions
          setSelectedGrowserId(data[0].assetdeveloperid);
          getAssetDeveloperFeedbacksList(data[0].assetdeveloperid);
        } else {
          setNoDataFound("No Data Found");
        }
        setGrowerIdList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const aamcChangeHandler = (event) => {
    setSelectedGrowserId(event.target.value);
    MainService.getConsumerUserType(parentId, event.target.value)
      .then((res) => {
        setSelectedUserType(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <span>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Select AAMC
              </InputLabel>
              <Select
                id="selectedGrowserId"
                value={selectedGrowserId}
                onChange={(event) => {
                  aamcChangeHandler(event);
                }}
                onClick={(e) => {
                  setIsSelectedAssetId(false);
                  setIsSelectedGrowserId(true);
                  setIsSelectedSiteName(false);
                  setIsSelectedModelId(false);
                  setIsSelectedSchemeModelId(false);
                }}
                label="Select Grower"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {growerIdList.map((idObj, index) => (
                  <MenuItem key={index} value={idObj.assetdeveloperid}>
                    {idObj.assetdeveloperid}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
          <span>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Select Farm
              </InputLabel>
              <Select
                id="selectedSiteName"
                value={selectedSiteId}
                onChange={(event) => {
                  setSelectedSiteId(event.target.value);
                }}
                onClick={(event) => {
                  setIsSelectedSiteName(true);
                  setIsSelectedAssetId(false);
                  setIsSelectedGrowserId(false);
                  setIsSelectedModelId(false);
                  setIsSelectedSchemeModelId(false);
                }}
                label="Select site"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {duplicateSiteList.map((idObj, index) => (
                  <MenuItem key={index} value={idObj.siteid}>
                    {idObj.siteid}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>

          <span>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Select Asset
              </InputLabel>
              <Select
                id="selectedAssetId"
                value={selectedAssetId}
                name={selectedAssetSiteId}
                onChange={(event) => {
                  setSelectedAssetId(event.target.value);
                  setSelectedAssetSiteId(event.target.name);
                }}
                onClick={(event) => {
                  setIsSelectedAssetId(true);
                  setIsSelectedSiteName(false);
                  setIsSelectedGrowserId(false);
                  setIsSelectedModelId(false);
                  setIsSelectedSchemeModelId(false);
                }}
                label="Select Asset"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {assetsData.map((idObj, index) => (
                  <MenuItem key={index} value={idObj.assetId}>
                    {idObj.friendlyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>

          <span>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Select Scheme
              </InputLabel>
              <Select
                id="selectedSchemeModelId"
                value={selectedSchemeModelId}
                onChange={(event) => {
                  setSelectedSchemeModelId(event.target.value);
                }}
                onClick={(event) => {
                  setIsSelectedSchemeModelId(true);
                  setIsSelectedModelId(false);
                  setIsSelectedAssetId(false);
                  setIsSelectedSiteName(false);
                  setIsSelectedGrowserId(false);
                }}
                label="Select scheme"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {siteModelsList.map((idObj, index) => (
                  <MenuItem key={index} value={idObj.modelid}>
                    {idObj.modelid}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <h2>Feedbacks</h2>
          <br />
        </Grid>
        {!selectedGrowserId &&
        !selectedSiteId &&
        !selectedAssetId &&
        !selectedSchemeModelId ? (
          <div style={{ textAlign: "center" }}>
            <h4 >No Feedbacks Found </h4>
          </div>
          
        ) : (
          <>
            {isSelectedGrowserId && selectedGrowserId ? (
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  Asset Developer Feedbacks
                </Typography>

                {assetDeveloperFeedbacksList.length > 0 ? (
                  assetDeveloperFeedbacksList.map((feedback, index) => (
                    <Card key={index} style={{ marginBottom: "16px" }}>
                      <CardContent>
                        {Object.entries(feedback).map(([key, value]) => (
                          <div key={key}>
                            <Typography variant="subtitle1">
                              <b>{key}:</b> {value}
                            </Typography>
                          </div>
                        ))}
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography variant="h6">No Feedbacks Found</Typography>
                )}
              </Grid>
            ) : (
              <Typography variant="h4">
                {noDataFound}
                <br />
              </Typography>
            )}

            {isSelectedSiteName && selectedSiteId ? (
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  Site Feedbacks
                </Typography>

                {siteFeedbacksList.length > 0 ? (
                  siteFeedbacksList.map((feedback, index) => (
                    <Card key={index} style={{ marginBottom: "16px" }}>
                      <CardContent>
                        {Object.entries(feedback).map(([key, value]) => (
                          <div key={key}>
                            <Typography variant="subtitle1">
                              <b>{key}:</b> {value}
                            </Typography>
                          </div>
                        ))}
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography variant="h6">No Feedbacks Found</Typography>
                )}
              </Grid>
            ) : (
              <Typography variant="h4">
                {noDataFound}
                <br />
              </Typography>
            )}
            {isSelectedSAssetId && selectedAssetId ? (
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  Asset Feedbacks
                </Typography>

                {assetFeedbacksList.length > 0 ? (
                  assetFeedbacksList.map((feedback, index) => (
                    <Card key={index} style={{ marginBottom: "16px" }}>
                      <CardContent>
                        {Object.entries(feedback).map(([key, value]) => (
                          <div key={key}>
                            <Typography variant="subtitle1">
                              <b>{key}:</b> {value}
                            </Typography>
                          </div>
                        ))}
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography variant="h6">No Feedbacks Found</Typography>
                )}
              </Grid>
            ) : (
              <Typography variant="h4">
                {noDataFound}
                <br />
              </Typography>
            )}

            {isSelectedSchemeModelId && selectedSchemeModelId ? (
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  Scheme Feedbacks
                </Typography>

                {schemeFeedbacksList.length > 0 ? (
                  schemeFeedbacksList.map((feedback, index) => (
                    <Card key={index} style={{ marginBottom: "16px" }}>
                      <CardContent>
                        {Object.entries(feedback).map(([key, value]) => (
                          <div key={key}>
                            <Typography variant="subtitle1">
                              <b>{key}:</b> {value}
                            </Typography>
                          </div>
                        ))}
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography variant="h6">No Feedbacks Found</Typography>
                )}
              </Grid>
            ) : (
              <Typography variant="h4">
                {noDataFound}
                <br />
              </Typography>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
