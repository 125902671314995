import React from "react";
import Icon5 from "../../images/gbrFarmerBenefits/save-money.svg";
import Icon7 from "../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon8 from "../../images/gbrFarmerBenefits/smart-farm.svg";
import Icon10 from "../../images/global-market.svg";
import img1 from "../../images/optionalChoice.png";
import img2 from "../../images/improvement.png";
import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import Icon1 from "../../images/CustomerBenefit/productivity.svg"
import Icon2 from "../../images/CustomerBenefit/reputation-management.svg"

import Icon3 from "../../images/CustomerBenefit/productivity (1).svg"
import Icon4 from "../../images/CustomerBenefit/traceability.svg";

export default function AdvisorsBenefits() {
  const benefits = [
   
    
     
     {
        image: Icon2,
        title: "Better Client Engagement ",
        bodycopy:
          "Better Client Engagement for advisors is facilitated by easy communication and feedback mechanisms, fostering stronger relationships with farmers.          "
      },

      {
        image: Icon2,
        title: "Increase Clientile ",
        bodycopy:
          "Increasing the client base benefits advisors by boosting their income, enhancing their professional reputation, and providing more opportunities for business growth and networking."
      },

     
  ];
  return (
    <>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title"> Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </>

  );
}
