import React, { Component } from 'react';
import { Line } from "react-chartjs-2";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row, Container
} from 'reactstrap';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../style.css';
import './Layout.css';
import AuthAxiosInstance from '../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance, EMSServcieAxiosInstance } from '../../../authAxiosInstance'
import socketIOClient from "socket.io-client";
import networkId from '../../../networkId';
import MainService from "../Common/MainService";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import { NotificationManager } from "react-notifications";


var jsonconfig = window.appConfig;

class CompareMultiProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showspinner: false,
      data: {},
      deviceList: [],
      selectDevId: 0,
      listProperty: [],
      selectedProperty: [],
      date: {},
      validDate: {},
      selectedDevices: [],
      currentlist: [],
      compareclicked: false
    };

    // toast.configure({
    //   autoClose: 8000,
    //   draggable: false,
    //   //etc you get the idea
    // });
  }

  getAppConfig = () => {
    return window.AppConfigData;
  };

  componentDidMount() {
    //this.getlayoutlist();
    const socket = socketIOClient((this.getAppConfig().ApiUrlMapp.SocketIOURL));

    socket.on("livedata", (livedata) => {
      //const filterData = this.state.deviceList.filter((d) => (d.deviceId ==  this.state.selectedDevices[this.state.selectedDevices.length - 1]));
      const currentlist = [...this.state.currentlist];
      try {
        const userId = localStorage.getItem("user-id");
        const { user } = MainService.getLocalData();
        const { username, parentId } = user;
        if (username === livedata.networkid.toString()) {
          if (currentlist.length > 0) {
            //if (filterData[0].deviceType === livedata.devicetype) {
            let selectedProperty = [...this.state.currentlist];
            let data = { ...this.state.data };
            if (selectedProperty.length > 0) {
              let propnamearray = [];
              switch (livedata.devicetype) {
                case 'AHUG':
                  propnamearray = livedata.AHUGproperty;
                  break;
                case 'EMMW':
                  propnamearray = livedata.EMMW;
                  break;
                case 'CCN':
                  propnamearray = livedata.CHILLER[0].CHILLERproperty;
                  break;
                case 'LG':
                  propnamearray = livedata.THS[0].THSproperty;
                  break;
                case 'TCN':
                  propnamearray = livedata.Tstat[0].Tstatproperty;
                  break;
                case 'VCN':
                  propnamearray = livedata.VFD[0].VFDproperty;
                  break;
                case 'EMG':
                  propnamearray = livedata.EMG[0].EMproperty;
                  break;
                default:
                  propnamearray = livedata.properties;
                  break;
                // code block
              }
              selectedProperty.map((d, i) => {
                propnamearray.map((da) => {
                  if (d.property === da.property && d.deviceid === livedata.deviceid.toString()) {
                    if (Object.keys(data).length > 0 && data.datasets.length > 0) {

                      const index = data.datasets.findIndex((element, index) => {
                        let a = element.label.split(' in (');
                        if (a[0].split('->')[1] === da.property && a[0].split('->')[0] === d.device) {
                          return true
                        }
                      });

                      data.datasets[index].data = [...data.datasets[index].data];

                      if (data.datasets[index].data.length === 0) {
                        for (let i = 0; i < data.labels.length; i++) {
                          data.datasets[index].data.push('-');
                        }
                      }
                      data.datasets[index].data.push(da.value);
                      data.labels = [...data.labels];
                      var current = new Date();
                      let time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
                      if ((data.datasets[index].data.length - 1) % currentlist.length === 0) {
                        data.labels.push(time);
                      }
                    }
                  }
                })
              })

              this.setState({ data });



            }
            //}
          }
        }
      }
      catch (e) {
        // toast.error("Something went wrong!!", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");
      }
    });

    this.loadDeviceList();
  }


  loadDeviceList() {
    const userId = localStorage.getItem("user-id");
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/list`).then((resp) => {
      if (resp.data)
        this.setState({ deviceList: resp.data });
      else
        this.setState({ deviceList: [] });
    }).catch((err) => {

    })
  }

  selectDevice(evt, e) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    try {

      if (this.state.compareclicked === true) {
        // toast.info("To add a new property now. Click on reset button and add again.", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("To add a new property now. Click on reset button and add again.");
      }
      let dev_id = evt.target.value;
      this.setState({ selectDevId: evt.target.value, selectedPropertyID: '' });
      this.setState({ compareclicked: false });


      this.setState({ listProperty: [] });

      const { selectedDevices } = this.state;
      if (selectedDevices.includes(dev_id)) {
        //Perform Remove operation
        const ind = selectedDevices.indexOf(dev_id);
        selectedDevices.splice(ind, 1);
      } else {
        //perform insert operation
        selectedDevices.push(dev_id);
      }
      this.setState({ selectedDevices });
      if (selectedDevices.length > 0) {
        const filterDevice = this.state.deviceList.filter((d) => (d.deviceId == selectedDevices[selectedDevices.length - 1]));

        this.setState({ showspinner: true });
        //DeviceMetadataAxiosInstance.get(`/ems/livedata/${networkId.network}/${selectedDevices[selectedDevices.length - 1]}/properties?deviceType=${filterDevice[0].deviceType}`).then((res) => {
          DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${filterDevice[0].deviceId}/metadata?deviceType=${filterDevice[0].deviceType}&model=${filterDevice[0].model}`)
          .then((res) => {
            console.log("Response Data", res.data);
            this.setState({ showspinner: false });
            if (res.data && res.data !== 'failed' && res.data !== 'error') {
              console.log("filterDevice[0]", filterDevice[0]);
              if (filterDevice[0].deviceType == "AHUG") {
                this.setState({ listProperty: res.data.AHUGproperty ? res.data.AHUGproperty : [] }, () => {
                  console.log(this.state.listProperty);
                });

              }
              else if (filterDevice[0].deviceType == "EMMW") {
                this.setState({ listProperty: res.data.EMMW ? res.data.EMMW : [] }, () => {
                  console.log(this.state.listProperty);
                });
              }
              else if (filterDevice[0].deviceType == "CCN") {
                console.log("Here")
                this.setState({ listProperty: res.data.CHILLER[0].CHILLERproperty ? res.data.CHILLER[0].CHILLERproperty : [] }, () => {
                  console.log(this.state.listProperty);
                });
              }
              else if (filterDevice[0].deviceType == "LG") {
                console.log("Here")
                this.setState({ listProperty: res.data.THS[0].THSproperty ? res.data.THS[0].THSproperty : [] }, () => {
                  console.log(this.state.listProperty);
                });
              }
              else if (filterDevice[0].deviceType == "TCN") {
                this.setState({ listProperty: res.data.Tstat[0].Tstatproperty ? res.data.Tstat[0].Tstatproperty : [] }, () => {
                  console.log(this.state.listProperty);
                });
              }
              else if (filterDevice[0].deviceType == "VCN") {
                this.setState({ listProperty: res.data.VFD[0].VFDproperty ? res.data.VFD[0].VFDproperty : [] }, () => {
                  console.log(this.state.listProperty);
                });
              }
              else if (filterDevice[0].deviceType == "EMG") {
                if (res.data.EMG[0].EMproperty.length > 0) {
                  this.setState({ listProperty: res.data.EMG[0].EMproperty ? res.data.EMG[0].EMproperty : [] }, () => {
                    console.log(this.state.listProperty);
                  });
                }
                else {
                  this.setState({ listProperty: [] });
                  // toast.error("Data not found.", {
                  //   position: toast.POSITION.TOP_RIGHT
                  // });
                  return NotificationManager.error("Data not found");
                }
              }
              else {
                this.setState({ listProperty: res.data.properties ? res.data.properties : [] }, () => {
                  console.log(this.state.listProperty);
                });
              }

            }
            else {
              this.setState({ listProperty: [] });
              // toast.error("Data not found.", {
              //   position: toast.POSITION.TOP_RIGHT
              // });
              return NotificationManager.error("Data not found");
            }

          }).catch((err) => {
            this.setState({ showspinner: false });
            this.setState({ listProperty: [] });
            // toast.error("Some error occurred.", {
            //   position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");
          });
      } else {
        this.setState({ listProperty: [] });
      }

    }
    catch (cerror) {
      this.setState({ showspinner: false });
      this.setState({ listProperty: [] });
      // toast.error("Something went wrong.", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Some error occured");
    }
  }

  selectProperty(evt) {
    try {
      if (evt.target.value == '') {
        return;
      }
      if (this.state.compareclicked === true) {
        // toast.info("To add a new property now. Click on reset button and add again.", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("To add a new property now. Click on reset button and add again.");

      }
      this.setState({ compareclicked: false });
      const selectedProperty = this.state.selectedProperty.filter((d) => (d.property == evt.target.value));
      const filterproperty = this.state.listProperty.filter((d) => (d.property == evt.target.value));
      this.setState({ selectedPropertyID: evt.target.value });

      const selectedDeviceTemp = this.state.deviceList.filter((d) => (d.deviceId == this.state.selectDevId));

      const currentlist = [...this.state.currentlist];
      if (currentlist.length === 5) {
        // toast.info("Maximum 5 graphs can be added at a time to compare!!", {
        //     position: toast.POSITION.TOP_RIGHT
        //   });
        return NotificationManager.error("Maximum 5 graphs can be added at a time to compare!!");

      }
      let o = {};
      o.device = selectedDeviceTemp[0].deviceName;
      o.deviceid = selectedDeviceTemp[0].deviceId;
      o.property = evt.target.value;
      o.unit = filterproperty[0].unit ? filterproperty[0].unit : '';
      for (let i = 0; i < currentlist.length; i++) {
        if (currentlist[i].device === o.device && currentlist[i].property === o.property) {
          // toast.info("Already added in the list!!", {
          //   position: toast.POSITION.TOP_RIGHT
          //   });
          //break;
          return NotificationManager.error("Already added in the list!!");

        }
      }

      currentlist.push(o);
      this.setState({ currentlist });
    }
    catch (cerror) {
      // toast.error("Something went wrong.", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Some error occured");
    }


  }


  getHistoryData(a) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    try {
      this.setState({ showspinner: true, data: {} });
      let devlength = this.state.currentlist.length;
      let graphdata = [];
      let currentdate = new Date();
      let todate = currentdate.toISOString().split('T')[0] + 'T23:59:59';;
      currentdate.setDate(currentdate.getDate() - 2);
      let fromdate = currentdate.toISOString();
      let count = 0;
      for (let i = 0; i < devlength; i++) {
        let labels = [];
        let property = this.state.currentlist[i].property;
        let unit = this.state.currentlist[i].unit;
        let devname = this.state.currentlist[i].device;;
        const filterDevice = this.state.deviceList.filter((d) => (d.deviceId == this.state.currentlist[i].deviceid));
        EMSServcieAxiosInstance.get(`/ems/history/${username}/${filterDevice[0].deviceId}?deviceType=${filterDevice[0].deviceType}&property=${property}&fromDate=${fromdate}&toDate=${todate}`)
          .then((res) => {
            count++;
            const dataset = [];


            if (res.data && res.data.length > 0) {
              res.data.sort(function (a, b) {
                var c = new Date(a.updatedat);
                var d = new Date(b.updatedat);
                return d - c;
              });
              if (filterDevice[0].deviceType === "AHUG") {
                const index = res.data[0].AHUGproperty.findIndex((element, index) => {
                  if (element.property === property) {
                    return true
                  }
                });
                if (isNaN(res.data[0].AHUGproperty[0].value)) {
                  // toast.info(`The value of ${devname}->${property} is not in correct format. So, cannot plot the graph.`, {
                  //   position: toast.POSITION.TOP_RIGHT
                  // });
                }
                //var labels = [];
                res.data.slice(0, 100).map((d) => {
                  let date = new Date(d.updatedat);
                  let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                  labels.push(decideDate);
                  dataset.push(d.AHUGproperty[index].value);
                });
              }
              else if (filterDevice[0].deviceType === "EMMW") {
                const index = res.data[0].EMMW.findIndex((element, index) => {
                  if (element.property === property) {
                    return true
                  }
                });
                if (isNaN(res.data[0].EMMW[0].value)) {
                  // toast.info(`The value of ${devname}->${property} is not in correct format. So, cannot plot the graph.`, {
                  //   position: toast.POSITION.TOP_RIGHT
                  // });
                }
                //var labels = [];
                res.data.slice(0, 100).map((d) => {
                  let date = new Date(d.updatedat);
                  let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                  labels.push(decideDate);
                  dataset.push(d.EMMW[index].value === "-" ? 0 : d.EMMW[index].value);
                });
              }
              else if (filterDevice[0].deviceType === "CCN") {
                //var labels = [];
                const index = res.data[0].CHILLER[0].CHILLERproperty.findIndex((element, index) => {
                  if (element.property === property) {
                    return true
                  }
                });
                if (isNaN(res.data[0].CHILLER[0].CHILLERproperty[0].value)) {
                  // toast.info(`The value of ${devname}->${property} is not in correct format. So, cannot plot the graph.`, {
                  //   position: toast.POSITION.TOP_RIGHT
                  // });
                }
                res.data.slice(0, 100).map((d) => {
                  let date = new Date(d.updatedat);
                  let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                  labels.push(decideDate);
                  dataset.push(d.CHILLER[0].CHILLERproperty[index].value);
                });
              }
              else if (filterDevice[0].deviceType === "LG") {
                //var labels = [];
                const index = res.data[0].THS[0].THSproperty.findIndex((element, index) => {
                  if (element.property === property) {
                    return true
                  }
                });
                if (isNaN(res.data[0].THS[0].THSproperty[0].value)) {
                  // toast.info(`The value of ${devname}->${property} is not in correct format. So, cannot plot the graph.`, {
                  //   position: toast.POSITION.TOP_RIGHT
                  // });
                }
                res.data.slice(0, 100).map((d) => {
                  let date = new Date(d.updatedat);
                  let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                  labels.push(decideDate);
                  dataset.push(d.THS[0].THSproperty[index].value);
                });
              }
              else if (filterDevice[0].deviceType === "TCN") {
                //var labels = [];
                const index = res.data[0].Tstat[0].Tstatproperty.findIndex((element, index) => {
                  if (element.property === property) {
                    return true
                  }
                });
                if (isNaN(res.data[0].Tstat[0].Tstatproperty[0].value)) {
                  // toast.info(`The value of ${devname}->${property} is not in correct format. So, cannot plot the graph.`, {
                  //   position: toast.POSITION.TOP_RIGHT
                  // });
                }
                res.data.slice(0, 100).map((d) => {
                  let date = new Date(d.updatedat);
                  let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                  labels.push(decideDate);
                  dataset.push(d.Tstat[0].Tstatproperty[index].value);
                });
              }
              else if (filterDevice[0].deviceType === "VCN") {
                //var labels = [];
                const index = res.data[0].VFD[0].VFDproperty.findIndex((element, index) => {
                  if (element.property === property) {
                    return true
                  }
                });
                if (isNaN(res.data[0].VFD[0].VFDproperty[0].value)) {
                  // toast.info(`The value of ${devname}->${property} is not in correct format. So, cannot plot the graph.`, {
                  //   position: toast.POSITION.TOP_RIGHT
                  // });
                }
                res.data.slice(0, 100).map((d) => {
                  let date = new Date(d.updatedat);
                  let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                  labels.push(decideDate);
                  dataset.push(d.VFD[0].VFDproperty[index].value);
                });
              }
              else if (filterDevice[0].deviceType === "EMG") {
                //var labels = [];
                const index = res.data[0].EMG[0].EMproperty.findIndex((element, index) => {
                  if (element.property === property) {
                    return true
                  }
                });
                if (isNaN(res.data[0].EMG[0].EMproperty[0].value)) {
                  // toast.info(`The value of ${devname}->${property} is not in correct format. So, cannot plot the graph.`, {
                  //   position: toast.POSITION.TOP_RIGHT
                  // });
                }
                res.data.slice(0, 100).map((d) => {
                  let date = new Date(d.updatedat);
                  let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                  labels.push(decideDate);
                  dataset.push(d.EMG[0].EMproperty[index].value);
                });
              }
              else {
                //var labels = [];
                const index = res.data[0].properties.findIndex((element, index) => {
                  if (element.property === property) {
                    return true
                  }
                });
                if (isNaN(res.data[0].properties[0].value)) {
                  // toast.info(`The value of ${devname}->${property} is not in correct format. So, cannot plot the graph.`, {
                  //   position: toast.POSITION.TOP_RIGHT
                  // });
                }
                res.data.slice(0, 100).map((d) => {
                  let date = new Date(d.updatedat);
                  let decideDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                  labels.push(decideDate);
                  dataset.push(d.properties[index].value);

                });
              }
            }
            else {
              // toast.info(`Data not found for ${devname}->${property}. If live data available, it will start displaying.`, {
              //   position: toast.POSITION.TOP_RIGHT
              // });
            }

            let obj = {};
            obj.label = filterDevice[0].deviceName + '->' + property + (unit ? ` in (${unit})` : '');
            obj.fill = false;
            obj.lineTension = 0.5;
            obj.borderColor = `rgba(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)}, 1)`;
            obj.backgroundColor = obj.borderColor;
            obj.borderWidth = 2;
            obj.data = dataset;
            obj.pointRadius = 0.2;
            graphdata.push(obj);
            if (count == devlength) {
              const data = {
                labels: labels,
                datasets: graphdata
              }
              this.setState({ showspinner: false });
              this.setState({ data });
            }


          }).catch((err) => {
            this.setState({ showspinner: false });
            // toast.error('Something went wrong.', {
            //   position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Some error occured");
          });
      }
    }
    catch (cerror) {
      // toast.error("Something went wrong.", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Some error occured");
    }

  }

  compare() {
    this.getHistoryData('a');
    this.setState({ compareclicked: true });
  }

  resetclick() {
    this.setState({ currentlist: [], compareclicked: false, selectedPropertyID: '', selectDevId: '' });
  }



  render() {
    const { currentlist } = this.state;
    return (
      <div className="animated fadeIn" >
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <strong className="LabelHeader">Compare Multiple Live Data</strong>
                <div className="card-header-actions">
                  {/* <Button className="float-right badge badge-warning badge-pill" >Edit &nbsp;<i className="fa fa-edit float-right"></i></Button> */}
                </div>
              </CardHeader>
              <CardBody>

              <Grid container spacing={2} className='m-r-15'>


              <Grid item xs={6} md={6}>
                    <label style={{marginLeft:"10px"}} className="m-r--15">Select Device</label>
                    {/* <Input disabled={this.state.showspinner} type="select" name="device" value={this.state.selectDevId} placeholder="Select Device" className="dropdownwidth2" onChange={this.selectDevice.bind(this)} style={{ width: "100%" }}>
                        <option value="">Select Device</option>
                        {this.state.deviceList.map((d, ind) => {
                          return <option key={ind + 1} value={d.deviceId}>{d.deviceName}</option>
                        })}

                      </Input> */}
                    <Select
                      disabled={this.state.showspinner}
                      name="device"
                      value={this.state.selectDevId}
                      fullWidth
                      onChange={this.selectDevice.bind(this)}
                      variant='outlined'
                    >
                      <MenuItem disabled value="">Select Device</MenuItem>
                      {this.state.deviceList.map((d, ind) => (
                        <MenuItem key={ind + 1} value={d.deviceId}>
                          {d.deviceName}
                        </MenuItem>
                      ))}
                    </Select>

                  </Grid>


                  <Grid item xs={6} md={6}>
                    <label style={{marginLeft:"10px"}} className="m-r--15">Select Property</label>
                    {/* <Input disabled={this.state.showspinner} type="select" value={this.state.selectedPropertyID} className="dropdownwidth2" name="pagesize" onChange={this.selectProperty.bind(this)} style={{ width: "100%" }}>
                      <option value="">Select Property</option>
                      {this.state.listProperty.map((d, ind) => {
                        return <option key={ind + 1} value={d.property}>{d.property}</option>
                      })}

                    </Input> */}
                    <Select
                      disabled={this.state.showspinner}
                      name="pagesize"
                      value={this.state.selectedPropertyID}
                      fullWidth
                      onChange={this.selectProperty.bind(this)}
                      style={{ width: '100%' }}
                      variant='outlined'
                    >
                      <MenuItem disabled value="">Select Property</MenuItem>
                      {this.state.listProperty.map((d, ind) => (
                        <MenuItem key={ind + 1} value={d.property}>
                          {d.property}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>




                </Grid>
                <Row>&nbsp;</Row>
                <Row className='m-r-15'>
                  {(currentlist.length > 0) &&
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>SL No</TableCell>
                          <TableCell>Device</TableCell>
                          <TableCell>Property</TableCell>
                          {/* Uncomment this part if you want to include a Delete column */}
                          {/* <TableCell>Delete</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.currentlist.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{row.device}</TableCell>
                            <TableCell>{row.property}</TableCell>
                            {/* Uncomment this part if you want to include a Delete column */}
                            {/* <TableCell>
                                <button onClick={() => this.handleDelete(row.id)}>Delete</button>
                              </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  }
                  {(currentlist.length > 0) &&
                    <div className='bottomdiv' >
                      <Button disabled={this.state.showspinner} className='custom-button' onClick={this.compare.bind(this)} style={{ marginRight: '20px' }}>COMPARE</Button>
                      <Button disabled={this.state.showspinner} className='custom-button' onClick={this.resetclick.bind(this)}>RESET</Button>
                    </div>


                  }
                </Row>


                <Row>
                  <Col xs="12" sm="12" lg="12" md="12">
                    <Row>
                      {!this.state.showspinner &&
                        <Col md="12">

                          {this.state.currentlist.length > 0 && this.state.listProperty.length > 0 && this.state.compareclicked &&
                            <Line
                              data={this.state.data}
                              options={{
                                title: {
                                  display: true,
                                  fontSize: 20

                                },
                                legend: {
                                  display: true,
                                  position: 'right'
                                },
                                scales: {
                                  yAxes: [{
                                    scaleLabel: {
                                      display: true,
                                      labelString: '',
                                      fontColor: 'black' // Set the font color to black
                                    },
                                    ticks: {
                                      fontColor: 'black' // Set the tick font color to black
                                    }
                                  }],
                                  xAxes: [{
                                    scaleLabel: {
                                      display: true,
                                      labelString: 'Date/time',
                                      fontColor: 'black' // Set the font color to black
                                    },
                                    ticks: {
                                      fontColor: 'black' // Set the tick font color to black
                                    }
                                  }],
                                }
                              }}
                            />
                          }


                        </Col>
                      }
                    </Row>
                    <Row>
                      {this.state.showspinner &&
                        <div className="sk-cube-grid">
                          <div className="sk-cube sk-cube1"></div>
                          <div className="sk-cube sk-cube2"></div>
                          <div className="sk-cube sk-cube3"></div>
                          <div className="sk-cube sk-cube4"></div>
                          <div className="sk-cube sk-cube5"></div>
                          <div className="sk-cube sk-cube6"></div>
                          <div className="sk-cube sk-cube7"></div>
                          <div className="sk-cube sk-cube8"></div>
                          <div className="sk-cube sk-cube9"></div>
                        </div>
                      }
                    </Row>

                  </Col>


                </Row>

              </CardBody>
            </Card>

          </Col>
        </Row>


      </div>
    );
  }
}

export default CompareMultiProperty;
