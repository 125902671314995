import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import AssetQuoteTable from "./AssetQuoteManagementTable";

const AssetQuoteManagement = ({ reqTypes }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [getReqType, setReqType] = useState("");
  const [selectedReqType, setSelectedReqType] = useState("");
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [assetVerticalFriendlyNameValue, setAssetVerticalFriendlyNameValue] =
    useState("");
  const [getModelFriendlyName, setModelFriendlyName] = useState("");
  const [allReqTypes, setAllReqTypes] = useState([]);
  const getAppConfig = () => window.AppConfigData;

  useEffect(() => {
    const requestTypes = [
      { val: "asset-buy", type: "asset-buy-rfq-req" },
      { val: "cf-agri-assets", type: "cf-agri-assets-rfq-req" },
      { val: "transport-asset", type: "transport-asset-rfq-req" },
      { val: "storage-asset", type: "storage-asset-rfq-req" },
    ];

    const reqConditionalTypes =
      reqTypes && Array.isArray(reqTypes) && reqTypes.length > 0
        ? reqTypes
        : requestTypes;

    setSelectedReqType(reqConditionalTypes[0]?.type);
    setReqType(reqConditionalTypes[0]?.type);
    setAllReqTypes(reqConditionalTypes);
  }, [reqTypes]);

  /* Asset Verticals */

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList(getAppConfig().EnterpriseId)
      .then((data) => {
        const [filteredData] = data.filter(
          ({ friendlyName }) =>
            !["MACHINERY", "TRANSPORTATION"].includes(friendlyName)
        );

        // setSelectedVerticalsId(filteredData?.assetVerticalId || "");
        // setAssetVerticalFriendlyNameValue(filteredData?.friendlyName || "");
        setVerticalsIdList(filteredData ? [filteredData] : []);
      })
      .catch((e) => NotificationManager.error(e));
  };

  useMemo(() => {
    getAssetVerticalsIdList();
  }, []);

  const getCategoryTypesList = () => {
    if (!selectedVerticalsId) return;

    MainService.getCategoryTypesList(
      selectedVerticalsId.trim(),
      getAppConfig().EnterpriseId
    )
      .then((data) => {
        // setSelectedCategory(data?.[0]?.categoryType || "");
        setCategoryTypesList(data || []);
      })
      .catch((e) => NotificationManager.error(e));
  };

  useMemo(() => {
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  const getAssetTypesList = () => {
    if (!selectedVerticalsId || !selectedCategory) return;

    MainService.getAssetTypesList(
      selectedVerticalsId.trim(),
      selectedCategory.trim(),
      getAppConfig().EnterpriseId
    )
      .then((data) => {
        // setSelectedAssertTypes(data?.[0]?.assetType || "");
        setAssertTypesList(data || []);
      })
      .catch((e) => {
        NotificationManager.error(e);
      });
  };

  useMemo(() => {
    getAssetTypesList();
  }, [selectedCategory]);

  const getModelsList = () => {
    if (!selectedVerticalsId || !selectedCategory || !selectedAssertTypes)
      return;

    MainService.getModelsList(
      selectedVerticalsId.trim(),
      selectedCategory.trim(),
      selectedAssertTypes.trim(),
      username
    )
      .then(([data]) => {
        // setSelectedModel(data?.modelid || "");
        // setModelFriendlyName(data?.modelfriendlyname || "");
        setModelsList(data ? [data] : []);
      })
      .catch((e) => {
        NotificationManager.error(e);
      });
  };

  useMemo(() => {
    getModelsList();
  }, [selectedAssertTypes]);

  const assetVerticalHandler = (value) => {
    setAssetVerticalFriendlyNameValue(value);
    const [selectedVertical] =
      verticalsIdList.filter(({ friendlyName }) => friendlyName === value) ||
      [];
    setSelectedVerticalsId(selectedVertical?.assetVerticalId || "");
  };

  /* End Asset Verticals */

  const handleButtonClick = (type) => {
    setReqType(type);
    setSelectedReqType(type);
  };

  return (
    <>
      <Box className="container" id="mainContentList">
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            margin: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {allReqTypes &&
            allReqTypes?.map((reqType) => (
              <Box
                key={reqType.type}
                sx={{
                  width: { xs: "100%", sm: "70%", md: "auto" },
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handleButtonClick(reqType.type)}
                  sx={{
                    backgroundColor:
                      selectedReqType === reqType.type ? "#3ECF8E" : "inherit",
                    color:
                      selectedReqType === reqType.type ? "primary" : "inherit",
                  }}
                >
                  {reqType.val}
                </Button>
              </Box>
            ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            margin: "15px",
            alignItems: "center",
            justifyContent: "left",
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Sector
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={assetVerticalFriendlyNameValue}
                onChange={(e) => {
                  assetVerticalHandler(e.target.value);
                }}
                label="Select Verticals"
              >
                {verticalsIdList?.length > 0 ? (
                  verticalsIdList &&
                  verticalsIdList?.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.friendlyName}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Sector Found</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Category
              </InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList?.length > 0 ? (
                  categoryTypesList &&
                  categoryTypesList?.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Categorys Found</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                Asset Type
              </InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList?.length > 0 ? (
                  assertTypesList &&
                  assertTypesList?.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Asset Types Found</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box className="MuiGrid-root">
          <AssetQuoteTable
            reqType={getReqType}
            assetVerticalName={assetVerticalFriendlyNameValue}
            selectedCategory={selectedCategory}
            selectedAssertTypes={selectedAssertTypes}
            selectedModel={selectedModel}
          />
        </Box>
      </Box>
    </>
  );
};

export default AssetQuoteManagement;
