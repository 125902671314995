import { Box, Button, Divider, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import ParentUnsignedAgreements from "./ParentUnsignedAgreements";
import UserTradeDocuments from "./UserTradeDocuments";
import ChildTradeDocuments from "./ChildTradeDocuments";

const ProfileTradeDoc = () => {
  const [userTradeDocs, setUserTradeDocs] = useState(true);
  const [childTradeDocs, setChildTradeDocs] = useState(false);
  const [selectedReqType, setSelectedReqType] = useState("userdocs");

  const componentMapping = [
    {
      condition: userTradeDocs,
      component: <UserTradeDocuments />,
      key: "userdocs",
    },
    {
      condition: childTradeDocs,
      component: <ChildTradeDocuments />,
      key: "childdocs",
    },
  ];

  const data = [
    { title: "My Trade Documents", type: "userdocs" },
    { title: "Child Trade Documents", type: "childdocs" },
  ];

  const showHandler = (Type) => {
    setSelectedReqType(Type);
    const typeMappings = {
      userdocs: setUserTradeDocs,
      childdocs: setChildTradeDocs,
    };

    Object.keys(typeMappings).forEach((key) => {
      const setterFunction = typeMappings[key];
      if (Type === key) {
        setterFunction(true);
      } else {
        setterFunction(false);
      }
    });
  };

  const renderComponent = (condition, component) => {
    return condition ? component : null;
  };
  return (
    <>
      <Box className="container" id="mainContentList">
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          {data?.map((val, i) => (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                showHandler(val.type);
              }}
              sx={{
                textTransform: "none",
                backgroundColor:
                  selectedReqType === val.type ? "#3ECF8E" : "inherit",
                color: selectedReqType === val.type ? "primary" : "inherit",
              }}
            >
              {val.title}
            </Button>
          ))}
        </Box>
        <Divider sx={{ width: "100%" }}>
          <Typography sx={{ color: "#3ECF8E", padding: "5px 10px" }}>
            Document Types
          </Typography>
        </Divider>
        <Box className="MuiGrid-root">
          {componentMapping.map(({ condition, component, key }) => (
            <Fragment key={key}>
              {renderComponent(condition, component)}
            </Fragment>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ProfileTradeDoc;
