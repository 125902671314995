import React, { useState, useEffect } from "react";
import './CommunityComponent.css';
import { Button } from "@mui/material";
import ViewFarmersComponent from "../ViewFarmers/ViewFarmersComponent";
import ViewSupplyShopComponent from "../SupplyShop/SupplyShopComponent";
import ViewInputRetailerComponent from "../InputRetailer/InputRetailer";
import RoleProfileManagementComponent from "../RoleProfileManagement/RoleProfileManagementComponent";
import Main from "../../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AAMCSiteManagementComponent from "../AAMCSiteManagement/AAMCSiteManagementComponent";
import RoleManagementComponent from "../RoleManagement/RoleManagement";
import RegisterUsersRequestsComponent from "../RegisterUserRequests/RegisterUserRequestsComponent";
import CBOManagerComponent from "../../CBO/CBOManager/CBOManagerComponent";
import CBORepresentativeComponent from "../../CBO/CBORepresentative/CBORepresentativeComponent";
import AgriRetailerComponent from "../../CBO/AgriRetailer/AgriRetailerComponent";

export default function CBOUsersManagementComponent(props) {
  const [activeComponent, setActiveComponent] = useState("ViewFarmers");
  const { user } = Main.getLocalData();
  const { username, parentId } = user;
  const [userTypeValue, setUserTypeValue] = useState("");
  const UserRole = localStorage.getItem("UserRole");
  const [siteType, setSiteType] = useState("Farm");

  useEffect(() => {
    getUserType();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserType = () => {
    Main.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const viewComponent = (componentName) => {
    setActiveComponent(componentName);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "ViewFarmers":
        return <ViewFarmersComponent userType="Farmer" />;
      case "ViewSHG":
        return <ViewFarmersComponent userType="SHG" />;
      case "ViewFPO":
        return <ViewFarmersComponent userType="FPO" />;
      case "ViewCS":
        return <ViewFarmersComponent userType="CS" />;
      case "ViewNGO":
        return <ViewFarmersComponent userType="NGO" />;
      case "ViewTrader":
        return <ViewFarmersComponent userType="Trader" />;
      case "CBORepresentative":
        return <CBORepresentativeComponent />;
      case "ViewSupplyShop":
        return <ViewSupplyShopComponent />;
      case "ViewInputRetailer":
        return <ViewInputRetailerComponent />;
      case "CBOManager":
        return <CBOManagerComponent />;
      case "AgriRetailer":
        return <AgriRetailerComponent />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="container" id="mainContentList">
      <h2>User Management</h2>
        {(userTypeValue === "AssetDeveloper" ||
          userTypeValue === "ENERGY-PROCESSOR" ||
          userTypeValue === "CBO" ||
          userTypeValue === "AIC" ||
          userTypeValue === "AHSP") && (
          <div className="container" id="mainContentList">
            <div className="topHeadersWrapper">
              {(UserRole === "BASIC" ||
                UserRole === "STANDARD" ||
                UserRole === "PREFFERED" ||
                UserRole === "PREMIUM" ||
                UserRole === "ADMIN") && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => viewComponent("ViewFarmers")}
                  className={activeComponent === "ViewFarmers" ? "activeButton" : ""}
                >
                  Farmers
                </Button>
              )}
              &nbsp;
              {(UserRole === "STANDARD" ||
                UserRole === "PREFFERED" ||
                UserRole === "PREMIUM" ||
                UserRole === "ADMIN") && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => viewComponent("ViewSHG")}
                  className={activeComponent === "ViewSHG" ? "activeButton" : ""}
                >
                  SHG
                </Button>
              )}
              &nbsp;
              {(UserRole === "PREFFERED" ||
                UserRole === "PREMIUM" ||
                UserRole === "ADMIN") && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => viewComponent("ViewFPO")}
                  className={activeComponent === "ViewFPO" ? "activeButton" : ""}
                >
                  FPO
                </Button>
              )}
              &nbsp;
              {(UserRole === "PREFFERED" ||
                UserRole === "PREMIUM" ||
                UserRole === "ADMIN") && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => viewComponent("ViewCS")}
                  className={activeComponent === "ViewCS" ? "activeButton" : ""}
                >
                  CS
                </Button>
              )}
              &nbsp;
              {(UserRole === "STANDARD" ||
                UserRole === "PREFFERED" ||
                UserRole === "PREMIUM" ||
                UserRole === "ADMIN") && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => viewComponent("ViewNGO")}
                  className={activeComponent === "ViewNGO" ? "activeButton" : ""}
                >
                  NGO
                </Button>
              )}
              &nbsp;
              {UserRole === "ADMIN" && (
                <>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => viewComponent("ViewTrader")}
                    className={activeComponent === "ViewTrader" ? "activeButton" : ""}
                  >
                    Trader
                  </Button>
                  &nbsp;
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => viewComponent("CBORepresentative")}
                    className={activeComponent === "CBORepresentative" ? "activeButton" : ""}
                  >
                    Representative
                  </Button>
                  &nbsp;
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => viewComponent("CBOManager")}
                    className={activeComponent === "CBOManager" ? "activeButton" : ""}
                  >
                    Manager
                  </Button>
                  &nbsp;
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => viewComponent("AgriRetailer")}
                    className={activeComponent === "AgriRetailer" ? "activeButton" : ""}
                  >
                    Agri Retailer
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
        {userTypeValue === "FOOD-PROCESSOR" && (
          <div className="container" id="mainContentList">
            <div className="topHeadersWrapper">
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewFarmers")}
                className={activeComponent === "ViewFarmers" ? "activeButton" : ""}
              >
                Farmers
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewSHG")}
                className={activeComponent === "ViewSHG" ? "activeButton" : ""}
              >
                SHG
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewFPO")}
                className={activeComponent === "ViewFPO" ? "activeButton" : ""}
              >
                FPO
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewCS")}
                className={activeComponent === "ViewCS" ? "activeButton" : ""}
              >
                CS
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewNGO")}
                className={activeComponent === "ViewNGO" ? "activeButton" : ""}
              >
                NGO
              </Button>
            </div>
          </div>
        )}
        {userTypeValue === "AssetCertifiedConsultant" && (
          <div className="container" id="mainContentList">
            <div className="topHeadersWrapper">
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewFarmers")}
                className={activeComponent === "ViewFarmers" ? "activeButton" : ""}
              >
                Farmers
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewSHG")}
                className={activeComponent === "ViewSHG" ? "activeButton" : ""}
              >
                SHG
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewFPO")}
                className={activeComponent === "ViewFPO" ? "activeButton" : ""}
              >
                FPO
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewCS")}
                className={activeComponent === "ViewCS" ? "activeButton" : ""}
              >
                CS
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewNGO")}
                className={activeComponent === "ViewNGO" ? "activeButton" : ""}
              >
                NGO
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewTrader")}
                className={activeComponent === "ViewTrader" ? "activeButton" : ""}
              >
                Trader
              </Button>
            </div>
          </div>
        )}
        {userTypeValue === "BROKER" && (
          <div className="container" id="mainContentList">
            <div className="topHeadersWrapper">
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewFarmers")}
                className={activeComponent === "ViewFarmers" ? "activeButton" : ""}
              >
                Farmers
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewSHG")}
                className={activeComponent === "ViewSHG" ? "activeButton" : ""}
              >
                SHG
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewFPO")}
                className={activeComponent === "ViewFPO" ? "activeButton" : ""}
              >
                FPO
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewCS")}
                className={activeComponent === "ViewCS" ? "activeButton" : ""}
              >
                CS
              </Button>
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={() => viewComponent("ViewTrader")}
                className={activeComponent === "ViewTrader" ? "activeButton" : ""}
              >
                Trader
              </Button>
            </div>
          </div>
        )}
      </div>

      <div>{renderComponent()}</div>
    </>
  );
}
