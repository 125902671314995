import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box } from "@mui/material";
import React, { Suspense, lazy, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import Loading from "../../AssetDeveloperRole/ModelProductManagement/Loading";
import { getCategoryID } from "../../Common/CommonContent/ContentedRelatedCommonFun";
import MainService from "../../Common/MainService";
const CreateTradeDoc = lazy(() => import("./CreateTradeDoc"));
const ProfileTable = lazy(() => import("./ProfileTable"));

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

const ParentUnsignedAgreements = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [postPopup, setShowPostPopup] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] =
    useState("UNSIGNED-AGREEMENT");
  const [postList, setPostList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();

  useMemo(async () => {
    await getCategoryID(selectedCategoryName)
      .then((data) => {
        if (data?.length > 0) {
          setSelectedCategoryId(data);
        } else {
          setSelectedCategoryId("");
        }
      })
      .catch((e) => {
        return NotificationManager.error("error");
      });
  }, []);

  const getAllContentsList = () => {
    setIsLoading(true);
    MainService.getContentsBasedonContentCreator(
      username,
      selectedCategoryId,
      "contentowner"
    )
      .then((data) => {
        setPostList(data);
        setIsLoading(false);
      })
      .catch((e) => {
        return NotificationManager.error("");
      });
  };

  useMemo(() => {
    if (!selectedCategoryId) return;
    if (!username) return;
    getAllContentsList();
  }, [selectedCategoryId]);

  return (
    <>
      <Box className="container" id="mainContentList">
        <Box className="MuiGrid-root">
          <Suspense fallback={<Loading />}>
            <ProfileTable
              data={postList}
              isLoading={isLoading}
              reLoad={getAllContentsList}
              requeiredDocumentStatusUpdateButton={false}
              requeiredDeleteContentButton={false}
              requeiredUpdateKycButton={false}
            />
          </Suspense>
        </Box>
      </Box>
    </>
  );
};

export default ParentUnsignedAgreements;
