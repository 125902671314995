import React from "react";
import { Modal } from "react-bootstrap";
import "./ClubDetails.css";

export default function ClubDetails(props) {
  const data = Object.assign({},props.userInfo[0]);
  return (
    <div className="club-details-modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="details-header text-white">
          <Modal.Title id="contained-modal-title-vcenter" className="details-title me-3 me-md-5">
            More Details about the club
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="details-body">
         <p>Username: {data.username}</p>
         <p>Address: {data.address}</p>
         <p>Nominee: {data.nominee}</p>
         <p>Latitude: {data.latitude}</p>
         <p>Longitude: {data.longitude}</p>
         <p>Message: {data.message}</p>
         <p>State: {data.state}</p>
         <p>City: {data.city}</p>
         <p>Usertype: {data.usertype}</p>
         <p>Status: {data.status}</p>
         <p>Domain: {data.domain}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
}
