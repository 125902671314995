import React from "react";
import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
import LandingPageBanner from "../LandingPageBanner/LandingPageBanner";
import LandingPageChallenge from "../LandingPageChallenge/LandingPageChallenge";
import LandingPageSolution from "../LandingPageSolution/LandingPageSolution";
// import ChallengesSection from "./ChallengesSection/ChallengesSection";
// import SolutionEcosystem from "./SolutionEcosystem/SolutionEcosystem";
import OurServices from "../../OurServices/OurServices"
import CTA from "../../CTA/CTA";
import { Container } from "react-bootstrap";
import LandingPageBenefits from "../LandingPageBenefits/LandingPageBenefits";
import MSMEServices from "../MSMEServices/MSMEServices";

export default function LandingPage() {
  return (
    <>
    <HeaderComponent />
    <LandingPageBanner />
    <div className="">
      {/* <Container> */}
        <MSMEServices/>
        {/* </Container> */}
      </div>
    <div className="main-wrapper2">
      <LandingPageChallenge />
    </div>
    <LandingPageSolution />
    <OurServices />

    {/* <LandingPageBenefits/> */}
    <Container>
    <div className="subSectionWrapper">
          <CTA
            href="/agribusiness"
            heading="Explore Core Agri-food business & Join"
            btnText="Explore Now"
         
          />
        </div>
        </Container>
    <FooterComponent />
  </>
  )
}
