import React from "react";
import Schemes from "./Schemes";

const MsmeSchemes = () => {
  return (
    <>
      <Schemes categoryname="MSME_GOVT_SCHEMES" />
    </>
  );
};

export default MsmeSchemes;
