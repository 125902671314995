import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import Main from "../../Common/MainService";
import RegisterUsersRequestsComponent from "../RegisterUserRequests/RegisterUserRequestsComponent.js";
import GlobalUsersComponent from "./GlobalUsersComponent.js";
import "../MyOrders/MyOrderStyleComponent.css";

export default function GlobalNetworkComponent(props) {
  const { user } = Main.getLocalData();
  const { username, parentId } = user;
  const UserRole = localStorage.getItem("UserRole");

  const [activeComponent, setActiveComponent] = useState("Users");

  const handleComponentChange = (component) => {
    setActiveComponent(component);
  };

  return (
    <>
      <div className="topHeadersWrapper">
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              handleComponentChange("Users");
            }}
            style={{ margin: "5px" }}
            disabled={activeComponent === "Users"}
            className={activeComponent === "Users" ? "activeButton" : ""}
          >
            Users
          </Button>
        </div>
        &nbsp;
        <div className="topHeaders">
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={() => {
              handleComponentChange("Requests");
            }}
            style={{ margin: "5px" }}
            disabled={activeComponent === "Requests"}
            className={activeComponent === "Requests" ? "activeButton" : ""}
          >
            Requests
          </Button>
        </div>
      </div>

      <div style={{ paddingTop: "20px" }}>
        {activeComponent === "Users" && (
          <GlobalUsersComponent userTypes={props.userTypes} />
        )}
        {activeComponent === "Requests" && (
          <RegisterUsersRequestsComponent networkType="Global" />
        )}
      </div>
    </>
  );
}
