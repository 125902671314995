import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, Polyline,InfoWindow  } from "@react-google-maps/api";

const TripMonitorMap = ({ geopath, routeLocationdata,driverId }) => {
  const [tripLocation, setTripLocation] = useState(routeLocationdata);
  console.log(routeLocationdata, ".....GEOLOCATION MARKERS.....");
  console.log(geopath, "geopath details....................");
  console.log(driverId, "DRIVERIDDDD:::")


  const [markerPosition, setMarkerPosition] = useState(null);

  const customMarkerIcon = {
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: "green",
    fillOpacity: 1.0,
    strokeWeight: 2,
    strokeColor: "white",
    scale: 8,
  };
  useEffect(() => {
    // Update the marker position when routeLocationdata changes
    if (routeLocationdata && routeLocationdata.length > 0) {
      const latestLocation =
        routeLocationdata[routeLocationdata.length - 1].geoLocation;
      setMarkerPosition({ lat: latestLocation.lat, lng: latestLocation.lng });
    }
  }, [routeLocationdata]);

  const calculateBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    geopath.forEach(({ lat, lng }) =>
      bounds.extend(new window.google.maps.LatLng(lat, lng))
    );
    return bounds;
  };

  const center = {
    lat: calculateBounds().getCenter().lat(),
    lng: calculateBounds().getCenter().lng(),
  };

  const mapContainerStyle = {
    width: "100%",
    height: "60vh",
  };

  const infoWindowOffset = { x: 0, y: -40 };

  return (
    <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={7}>
      {geopath && geopath.length > 0 && (
        <Polyline
          path={geopath.map(({ lat, lng }) => ({ lat, lng }))}
          options={{
            strokeColor: "rgb(0, 123, 255)",
            strokeOpacity: 1.0,
            strokeWeight: 6,
          }}
        />
      )}

      {/* Display the moving marker */}
      {markerPosition && (
         <>
         <Marker
           position={markerPosition}
          //  label={`START`}
           icon={customMarkerIcon}
         />
         {markerPosition && (
          <InfoWindow
          position={{
            lat: markerPosition.lat + infoWindowOffset.y / 111111,
            lng: markerPosition.lng + infoWindowOffset.x / (111111 * Math.cos(markerPosition.lat * Math.PI / 180)),
          }}
          options={{
            disableAutoPan: true,
          }}
          >
             <div>
              {driverId}
             </div>
           </InfoWindow>
         )}
       </>
      )}

      {/* {routeLocationdata?.map((li) =>
        console.log("TRIP GEOLOCATION:::::", li?.geoLocation)
      )} */}
      {/* Display markers at the start and end positions */}
      {geopath && geopath.length > 0 && (
        <>
          <Marker
            position={{ lat: geopath[0].lat, lng: geopath[0].lng }}
            label="A"
          />
          <Marker
            position={{
              lat: geopath[geopath.length - 1].lat,
              lng: geopath[geopath.length - 1].lng,
            }}
            label="B"
          />
        </>
      )}
    </GoogleMap>
  );
};

export default TripMonitorMap;
