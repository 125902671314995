import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import { Box } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MainService from "../../Common/MainService";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import LegacyAssetManagementComponent from "../LegacyAssetManagement copy/LegacyAssetManagementComponent";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(2),
    zIndex: 10,
    color: "#fff",
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "black",
    borderStyle: "solid",
  },
}));

export default function ResourceManagementComponent() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const [roleResourcesList, setRoleResourcesList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [listViewAssets, setListViewAssets] = useState(false);
  const [gridViewAssets, setGridViewAssets] = useState(false);
  const [listViewVerticals, setListViewVerticals] = useState(false);
  const [gridViewVerticals, setGridViewVerticals] = useState(true);
  const [listViewCategories, setListViewCategories] = useState(false);
  const [gridViewCategories, setGridViewCategories] = useState(false);
  const [listViewAssetTypes, setListViewAssetTypes] = useState(false);
  const [gridViewAssetTypes, setGridViewAssetTypes] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [assetTypesList, setAssetTypesList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [resourceName, setResourceName] = useState("");
  const [filterUsers, setFilterUsers] = useState([]);
  const [selectedVarticalId, setSelectedVerticalId] = useState("");
  const [selectedVarticalName, setSelectedVerticalsName] = useState("");
  const [selectedCategoryType, setSelectedCategoryType] = useState("");
  const [selectedAssetType, setSelectedAssetType] = useState("");
  const [selectedModel, setSelectedModel] = useState("");

  const [searched, setSearched] = useState("");
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [roleName, setRoleName] = useState("");

  useEffect(() => {
    getRoleNameofOperator();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getRoleNameofOperator = () => {
    MainService.getRoleNameofOperator(parentId, username)
      .then((res) => {
        console.log("role resp:", res);
        setRoleName(res);
        getRoleResources(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };
  const getRoleResources = (roleName) => {
    MainService.getRoleResources(parentId, roleName)
      .then((res) => {
        console.log("resouces list:", res.role.resources);
        setRoleResourcesList(res.role.resources);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const resourceListViewClick = () => {
    setListViewCategories(false);
    setListViewVerticals(true);
    setListViewAssets(false);
    setListViewAssetTypes(false);
    setGridViewCategories(false);
    setGridViewAssets(false);
    setGridViewAssetTypes(false);
    setGridViewVerticals(false);
  };
  const resourceGridViewClick = () => {
    setListViewCategories(false);
    setListViewVerticals(false);
    setListViewAssets(false);
    setListViewAssetTypes(false);
    setGridViewCategories(false);
    setGridViewAssets(false);
    setGridViewAssetTypes(false);
    setGridViewVerticals(true);
  };
  const categoriesListViewClick = (catg, verticalid, verticalname) => {
    console.log("setSelectedVerticalId", verticalid);
    setSelectedVerticalId(verticalid);
    setSelectedVerticalsName(verticalname);
    setCategoriesList(catg);
    setListViewCategories(true);
    setListViewVerticals(false);
    setListViewAssets(false);
    setListViewAssetTypes(false);
    setGridViewCategories(false);
    setGridViewAssets(false);
    setGridViewAssetTypes(false);
    setGridViewVerticals(false);
  };
  const categoriesGridViewClick = (catg, verticalid, verticalname) => {
    setSelectedVerticalId(verticalid);
    setSelectedVerticalsName(verticalname);
    setCategoriesList(catg);
    setListViewCategories(false);
    setListViewVerticals(false);
    setListViewAssets(false);
    setListViewAssetTypes(false);
    setGridViewCategories(true);
    setGridViewAssets(false);
    setGridViewAssetTypes(false);
    setGridViewVerticals(false);
  };
  const assetTypesListViewClick = (assettypes, catg) => {
    setSelectedCategoryType(catg);
    setAssetTypesList(assettypes);
    setListViewCategories(false);
    setListViewVerticals(false);
    setListViewAssets(false);
    setListViewAssetTypes(true);
    setGridViewCategories(false);
    setGridViewAssets(false);
    setGridViewAssetTypes(false);
    setGridViewVerticals(false);
  };
  const assetTypesGridViewClick = (assettypes, catg) => {
    setSelectedCategoryType(catg);
    setAssetTypesList(assettypes);
    setListViewCategories(false);
    setListViewVerticals(false);
    setListViewAssets(false);
    setListViewAssetTypes(false);
    setGridViewCategories(false);
    setGridViewAssets(false);
    setGridViewAssetTypes(true);
    setGridViewVerticals(false);
  };
  const assetsListViewClick = (assettype) => {
    setSelectedAssetType(assettype);
    getModels(selectedVarticalId, selectedCategoryType, assettype);
    setListViewCategories(false);
    setListViewVerticals(false);
    setListViewAssets(true);
    setListViewAssetTypes(false);
    setGridViewCategories(false);
    setGridViewAssets(false);
    setGridViewAssetTypes(false);
    setGridViewVerticals(false);
  };
  const assetsGridViewClick = (assettype) => {
    setSelectedAssetType(assettype);
    getModels(selectedVarticalId, selectedCategoryType, assettype);
    setListViewCategories(false);
    setListViewVerticals(false);
    setListViewAssets(false);
    setListViewAssetTypes(false);
    setGridViewCategories(false);
    setGridViewAssets(true);
    setGridViewAssetTypes(false);
    setGridViewVerticals(false);
  };
  const getModels = (VarticalId, CategoryType, Assettype) => {
    if (VarticalId !== "" && CategoryType !== "" && Assettype !== "") {
      console.log(VarticalId, CategoryType, Assettype);
      MainService.getModelsList(
        VarticalId.replace(" ", ""),
        CategoryType.replace(" ", ""),
        Assettype.replace(" ", ""),
        username
      )
        .then((data) => {
          console.log("models list:", data);
          if (data.length > 0) {
            setSelectedModel(data[0].modelid);
            setModelsList(data);
          } else {
            setSelectedModel("");
            setModelsList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };
  const backToVerticals = () => {
    setListViewCategories(false);
    setListViewVerticals(false);
    setListViewAssets(false);
    setListViewAssetTypes(false);
    setGridViewCategories(false);
    setGridViewAssets(false);
    setGridViewAssetTypes(false);
    setGridViewVerticals(true);
  };
  const backToCatgories = () => {
    setListViewCategories(false);
    setListViewVerticals(false);
    setListViewAssets(false);
    setListViewAssetTypes(false);
    setGridViewCategories(true);
    setGridViewAssets(false);
    setGridViewAssetTypes(false);
    setGridViewVerticals(false);
  };

  const backToAssetTypes = () => {
    setListViewCategories(false);
    setListViewVerticals(false);
    setListViewAssets(false);
    setListViewAssetTypes(false);
    setGridViewCategories(false);
    setGridViewAssets(false);
    setGridViewAssetTypes(true);
    setGridViewVerticals(false);
  };

  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = filterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    //setRoleResourceAssetsList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = filterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    //setRoleResourceAssetsList(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  return (
    <div>
      <React.Fragment>

        <div className="container" id="mainContentList">
          {listViewVerticals || gridViewVerticals &&
          <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={resourceListViewClick}
            ></Button>
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={resourceGridViewClick}
            ></Button>
          </div>
        </div>}
          

          {/* Start of Verticals */}
          {listViewVerticals === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <div className="MuiGrid-root">
                <div className="cardStyleForDashboardItems">
                  <div className="cardStyleForDashboardItems">
                    {roleResourcesList ? (
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer sx={{ maxHeight: 350 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell colSpan={9} align="center">
                                  List Of Resources
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={1}>
                                  Resource Id
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Resource Name
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Vertcal Id
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Vertical Name
                                </TableCell>

                                <TableCell align="center" colSpan={4}>
                                  View Categories
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {roleResourcesList
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((site, index) => {
                                  return (
                                    <TableRow hover tabIndex={-1} key={index}>
                                      <TableCell align="center">
                                        {site.resourceid}
                                      </TableCell>
                                      <TableCell align="center">
                                        {site.resourcename}
                                      </TableCell>
                                      <TableCell align="center">
                                        {site.verticalid}
                                      </TableCell>
                                      <TableCell align="center">
                                        {site.verticalname}
                                      </TableCell>

                                      <TableCell align="center"></TableCell>
                                      <TableCell align="center">
                                        <Button
                                          startIcon={<ListIcon />}
                                          size="large"
                                          onClick={() => {
                                            categoriesListViewClick(
                                              site.categorytypes,
                                              site.verticalid,
                                              site.verticalname
                                            );
                                          }}
                                        ></Button>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Button
                                          startIcon={<GridIcon />}
                                          size="large"
                                          onClick={() => {
                                            categoriesGridViewClick(
                                              site.categorytypes,
                                              site.verticalid,
                                              site.verticalname
                                            );
                                          }}
                                        ></Button>
                                      </TableCell>
                                      <TableCell align="center"></TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={roleResourcesList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <h4>No Resources List Found</h4>
                    )}
                  </div>
                </div>
              </div>
            </Box>
          ) : null}

          {gridViewVerticals === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <h3>List Of Resources</h3>
              <br />
              <div className="cardStyleForDashboardItems">
                {roleResourcesList.length > 0 ? (
                  <div className="cardStyleForDashboardItems">
                    {roleResourcesList.map((site, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Resource Id:</b>
                            {site.resourceid}
                          </p>
                          <p>
                            <b>Resource Name:</b>
                            {site.resourcename}
                          </p>
                          <p>
                            <b>Vertical Id:</b>
                            {site.verticalid}
                          </p>
                          <p>
                            <b>Vertical Name:</b>
                            {site.verticalname}
                          </p>

                          <h4 className="cardactiontitle">View Categories</h4>
                          <div>
                            <Button
                              title="Categories List View"
                              startIcon={<ListIcon />}
                              size="large"
                              onClick={() => {
                                categoriesListViewClick(
                                  site.categorytypes,
                                  site.verticalid,
                                  site.verticalname
                                );
                              }}
                            ></Button>

                            <Button
                              title="Categories Grid View"
                              startIcon={<GridIcon />}
                              size="large"
                              onClick={() => {
                                categoriesGridViewClick(
                                  site.categorytypes,
                                  site.verticalid,
                                  site.verticalname
                                );
                              }}
                            ></Button>
                          </div>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Resources Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Vericals */}

          {/* Start of Categories */}
          {listViewCategories === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    variant="contained"
                    onClick={() => {
                      backToVerticals("Grid");
                    }}
                  >
                    Back to Vertical
                  </Button>
                </div>
              </div>
              <div className="MuiGrid-root">
                <div className="cardStyleForDashboardItems">
                  <div className="cardStyleForDashboardItems">
                    {categoriesList ? (
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer sx={{ maxHeight: 350 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell colSpan={5} align="center">
                                  List Of Categories
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={1}>
                                  Asset Type
                                </TableCell>

                                <TableCell align="center" colSpan={4}>
                                  View AssetTypes
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {categoriesList
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((site, index) => {
                                  return (
                                    <TableRow hover tabIndex={-1} key={index}>
                                      <TableCell align="center">
                                        {site.categorytype}
                                      </TableCell>

                                      <TableCell align="center"></TableCell>
                                      <TableCell align="center">
                                        <Button
                                          startIcon={<ListIcon />}
                                          size="large"
                                          onClick={() => {
                                            assetTypesListViewClick(
                                              site.assettypes,
                                              site.categorytype
                                            );
                                          }}
                                        ></Button>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Button
                                          startIcon={<GridIcon />}
                                          size="large"
                                          onClick={() => {
                                            assetTypesGridViewClick(
                                              site.assettypes,
                                              site.categorytype
                                            );
                                          }}
                                        ></Button>
                                      </TableCell>
                                      <TableCell align="center"></TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={categoriesList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <h4>No Categories Found</h4>
                    )}
                  </div>
                </div>
              </div>
            </Box>
          ) : null}

          {gridViewCategories === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <h3>List Of Categories</h3>
              <br />
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    variant="contained"
                    onClick={() => {
                      backToVerticals("Grid");
                    }}
                  >
                    Back to Vertical
                  </Button>
                </div>
              </div>
              <br />
              <div className="cardStyleForDashboardItems">
                {categoriesList.length > 0 ? (
                  <div className="cardStyleForDashboardItems">
                    {categoriesList.map((site, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>Categorytype:</b>
                            {site.categorytype}
                          </p>

                          <h4 className="cardactiontitle">View AssetTypes</h4>
                          <div>
                            <Button
                              title="AssetTypes List View"
                              startIcon={<ListIcon />}
                              size="large"
                              onClick={() => {
                                assetTypesListViewClick(
                                  site.assettypes,
                                  site.categorytype
                                );
                              }}
                            ></Button>

                            <Button
                              title="AssetTypes Grid View"
                              startIcon={<GridIcon />}
                              size="large"
                              onClick={() => {
                                assetTypesGridViewClick(
                                  site.assettypes,
                                  site.categorytype
                                );
                              }}
                            ></Button>
                          </div>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No Categories Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Categories */}
          {/* Start of Asset Types */}
          {listViewAssetTypes === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    variant="contained"
                    onClick={() => {
                      backToCatgories("Grid");
                    }}
                  >
                    Back to Categories
                  </Button>
                </div>
              </div>
              <br />
              <div className="MuiGrid-root">
                <div className="cardStyleForDashboardItems">
                  <div className="cardStyleForDashboardItems">
                    {assetTypesList ? (
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer sx={{ maxHeight: 350 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell colSpan={5} align="center">
                                  List Of AssetTypes
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={1}>
                                  Asset Type
                                </TableCell>

                                <TableCell align="center" colSpan={4}>
                                  View Assets
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {assetTypesList
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((site, index) => {
                                  return (
                                    <TableRow hover tabIndex={-1} key={index}>
                                      <TableCell align="center">
                                        {site.assettype}
                                      </TableCell>

                                      <TableCell align="center"></TableCell>
                                      <TableCell align="center">
                                        <Button
                                          startIcon={<ListIcon />}
                                          size="large"
                                          onClick={() => {
                                            assetsListViewClick(site.assettype);
                                          }}
                                        ></Button>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Button
                                          startIcon={<GridIcon />}
                                          size="large"
                                          onClick={() => {
                                            assetsGridViewClick(site.assettype);
                                          }}
                                        ></Button>
                                      </TableCell>
                                      <TableCell align="center"></TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={assetTypesList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <h4>No AssetTypes Found</h4>
                    )}
                  </div>
                </div>
              </div>
            </Box>
          ) : null}

          {gridViewAssetTypes === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <h3>List Of AssetTypes</h3>
              <br />
              <div className="topHeadersWrapper">
                <div className="topHeaders">
                  <Button
                    variant="contained"
                    onClick={() => {
                      backToCatgories("Grid");
                    }}
                  >
                    Back to Categories
                  </Button>
                </div>
              </div>
              <br />
              <div className="cardStyleForDashboardItems">
                {assetTypesList.length > 0 ? (
                  <div className="cardStyleForDashboardItems">
                    {assetTypesList.map((site, index) => (
                      <div
                        key={index}
                        className="cardStyleForDashboardItemsBody"
                      >
                        <span>
                          <p>
                            <b>AssetType:</b>
                            {site.assettype}
                          </p>

                          <h4 className="cardactiontitle">View Assets</h4>
                          <div>
                            <Button
                              title="AssetTypes List View"
                              startIcon={<ListIcon />}
                              size="large"
                              onClick={() => {
                                assetsListViewClick(site.assettype);
                              }}
                            ></Button>

                            <Button
                              title="AssetTypes Grid View"
                              startIcon={<GridIcon />}
                              size="large"
                              onClick={() => {
                                assetsGridViewClick(site.assettype);
                              }}
                            ></Button>
                          </div>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h4>No AssetTypes Found.</h4>
                )}
              </div>
            </Box>
          ) : null}
          {/* End of Asset Types */}
          {/* Start of Assets */}
          {listViewAssets === true || gridViewAssets === true ? (
            <>
              
            
              <div className="topHeadersWrapper">
                <div className="topHeaders" style={{paddingTop:"10px"}}>
                <Button
                variant="contained"
                onClick={() => {
                  backToAssetTypes("Grid"); 
                }}
              >
                Back to AssetType
              </Button>
                </div>
                {/* <div className="topHeaders">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel
                      style={{
                        transform: "translate(14px, -6px) scale(0.75)",
                        color: "rgb(43, 144, 99)",
                      }}
                    >
                      Model
                    </InputLabel>
                    <Select
                      id="selectedModel"
                      value={selectedModel}
                      onChange={(event) => {
                        setSelectedModel(event.target.value);
                      }}
                      label="Select Model"
                    >
                      {modelsList &&
                        modelsList.map((idObj, index) => (
                          <MenuItem key={`${index} `} value={idObj.modelid}>
                            {idObj.modelfriendlyname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div> */}
              </div>
              <br />
              <LegacyAssetManagementComponent
                selectedVerticalsId={selectedVarticalId}
                selectedVerticalName={selectedVarticalName}
                selectedCategory={selectedCategoryType}
                selectedAssertTypes={selectedAssetType}
                selectedModel={selectedModel}
              />
            </>
          ) : null}
          {/* End of Assets */}
        </div>
      </React.Fragment>
    </div>
  );
}
