import Fab from "@material-ui/core/Fab";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import ListIcon from "@material-ui/icons/List";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import SearchBar from "material-ui-search-bar";
import React, { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import CustomPopup from "../../../Common/CustomPopup/CustomPopupComponent";
import MainService from "../../../Common/MainService";
import Loading from "../../ModelProductManagement/Loading";
import PlaceholderCardList from "../../ModelProductManagement/PlaceHolderCardList";
const ContentSubCategoryCreate = lazy(() =>
  import("../../ModelProductManagement/ContentSubCategoryCreate")
);
const UploadContent = lazy(() =>
  import("../../ModelProductManagement/UploadContent")
);
const UploadCertificate = lazy(() =>
  import("../../ModelProductManagement/UploadCertificate")
);
const DeleteSubCategoryOfContent = lazy(() =>
  import("../../ModelProductManagement/DeleteSubCategoryOfContent")
);
const CreateProductItem = lazy(() =>
  import("../../ModelProductManagement/CreateProductItem")
);
const DeleteProduct = lazy(() =>
  import("../../ModelProductManagement/DeleteProduct")
);
const UpdateModelImage = lazy(() =>
  import("../../ModelProductManagement/UpdateModelImage")
);
const UpdateCertificate = lazy(() =>
  import("../../ModelProductManagement/UpdateCertificate")
);
const UpdateQR = lazy(() => import("../../ModelProductManagement/UpdateQR"));
const UpdateStatus = lazy(() =>
  import("../../ModelProductManagement/UpdateStatus")
);
const UpdateDiscount = lazy(() =>
  import("../../ModelProductManagement/UpdateDiscount")
);
const UpdateProductCost = lazy(() =>
  import("../../ModelProductManagement/UpdateProductCost")
);
const UpdateProductRating = lazy(() =>
  import("../../ModelProductManagement/UpdateProductRating")
);
const UpdateItemCount = lazy(() =>
  import("../../ModelProductManagement/UpdateItemCount")
);
const UpdateHistoryUrl = lazy(() =>
  import("../../ModelProductManagement/UpdateHistoryUrl")
);
const UpdateLabCertificate = lazy(() =>
  import("../../ModelProductManagement/UpdateLabCertificate")
);
const ProductVideo = lazy(() =>
  import("../../ModelProductManagement/ProductVideo")
);
const ProductItems = lazy(() =>
  import("../../ModelProductManagement/ProductItems")
);
const ProductCustomCard = lazy(() =>
  import("../../ModelProductManagement/ProductsCustomised/ProductCustomCard")
);
const UploadModelProductInfo = lazy(() =>
  import(
    "../../ModelProductManagement/UploadModelProductInfo/UploadModelProductInfo"
  )
);
const ProductCustomTable = lazy(() =>
  import("../../ModelProductManagement/ProductsCustomised/ProductCustomTable")
);
const BuyUrl = lazy(() => import("../../ModelProductManagement/BuyUrl"));
const GenerateProductQrCode = lazy(() =>
  import("../../ModelProductManagement/GenerateProductQrCode")
);

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

const textSize = {
  sx: {
    textTransform: "none",
    background: "#3ECF8E",
    margin: "2px",
    width: { xs: "100%", sm: "70%", md: "auto" },
    "&:hover": {
      background: "#3ECF8E",
    },
  },
};

const displayFields = [
  "productid",
  "productcost",
  "discount",
  "discountedprice",
  "unit",
  "country",
  "itemcount",
  "status",
];

export default function PmcMachinery({ categoryType, compType }) {
  const classes = useStyles();
  const theme = useTheme();
  const iconWidthRes = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const [isLoading, setIsLoading] = useState(true);
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [assetPopup, setShowAssetPopup] = useState(false);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [productIdValue, setProductIdValue] = useState("");
  const [productFriendlyNameValue, setProductFriendlyNameValue] = useState("");
  const [assetVerticalFriendlyNameValue, setAssetVerticalFriendlyNameValue] =
    useState("");
  const [getProductCostValue, setProductCostValue] = useState();
  const [getUnitValue, setUnitValue] = useState("");
  const [getDiscountValue, setDiscountValue] = useState("");
  const [getDiscountedPrice, setDiscountedPrice] = useState(0);
  const [getCountery, setCountery] = useState("");
  const [getModelFriendlyName, setModelFriendlyName] = useState("");
  const [setFilterProducts, getFilterProducts] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [getProductsData, setProductsData] = useState([]);
  const [getListView, setListView] = useState(false);
  const [getGridView, setGridView] = useState(true);
  const [getProductItemModel, setProductItemModel] = useState(false);
  /* Add Product Item */
  const [getItemConsumerAdminId, setItemConsumerAdminId] = useState("");
  const [getItemBusinessId, setItemBusinessId] = useState("");
  const [getItemProductId, setItemProductId] = useState("");
  const [getAssetType, setAssetType] = useState("");
  /* Delete Product'S */
  const [getDeleteProduct, setDeleteProduct] = useState(false);
  const [getDeleteProductBusinessId, setDeleteProductBusinessId] = useState("");
  const [getDeleteProductId, setDeleteProductId] = useState("");
  /* Upload Content */
  const [getUploadContentModel, setUploadContentModel] = useState(false);
  const [getUploadcontentProps, setUploadcontentProps] = useState([]);
  /* Upload Certificate */
  const [getUploadCertificateModel, setUploadCertificateModel] =
    useState(false);
  const [getUploadCertificateProps, setUploadCertificateProps] = useState([]);
  /* Update Product Image */
  const [getImageUpdateModel, setImageUpdateModel] = useState(false);
  const [getUpdateImageAdminId, setUpdateImageAdminId] = useState("");
  const [getUpdateImageBusinessId, setUpdateImageBusinessId] = useState("");
  const [getUpdateImageProductId, setUpdateImageProductId] = useState("");
  /* Update Product Certificate */
  const [getCertificateUpdateModel, setCertificateUpdateModel] =
    useState(false);
  /* Update Product QR Code */
  const [getQRUpdateModel, setQRUpdateModel] = useState(false);
  /* Update Product Status */
  const [getStatusUpdateModel, setStatusUpdateModel] = useState(false);
  /* Update Discount */
  const [getDiscountUpdateModel, setDiscountUpdateModel] = useState(false);
  const [getDiscountOriginalPrice, setDiscountOriginalPrice] = useState("");
  /* Update Product Cost */
  const [getProductCostUpdateModel, setProductCostUpdateModel] =
    useState(false);
  /* Update Product Cost */
  const [getRatingUpdateModel, setRatingUpdateModel] = useState(false);
  /* Update Item Count */
  const [getItemCountUpdateModel, setItemCountUpdateModel] = useState(false);
  /** Update Product History URL */
  const [getHistoryURLUpdateModel, setHistoryURLUpdateModel] = useState(false);
  /**Update Lab Certificate */
  const [getLabCertificateUpdateModel, setLabCertificateUpdateModel] =
    useState(false);
  /** Product Video */
  const [getVideoUpdateModel, setVideoUpdateModel] = useState(false);
  /** Product Items */
  const [getProductItemUpdateModel, setProductItemUpdateModel] =
    useState(false);
  const [getProductItemsStr, setProductItemsStr] = useState([]);
  /** Get Categorys */
  const [getCategoriesList, setCategoriesList] = useState("");
  const [getCategoriesNameList, setCategoriesNameList] = useState("");
  const [getSubCategoriesList, setSubCategoriesList] = useState([]);
  const [getSubCategoryForForm, setSubCategoryForForm] = useState("");
  const [getSubCategoryApiFilter, setSubCategoryApiFilter] = useState("");
  const [getCreateSubCategoryProps, setCreateSubCategoryProps] = useState([]);
  const [getSubCategoryModel, setSubCategoryModel] = useState(false);
  const [getDeleteSubCategoryModel, setDeleteSubCategoryModel] =
    useState(false);
  const [storeProductItem, setStoreProductItem] = useState({});
  /** Update Model Product Info Model */
  const [getModelProductInfo, setModelProductInfo] = useState({});
  const [getInfo, setInfo] = useState([]);
  const [getUpdateInfoModel, setUpdateInfoModel] = useState(false);
  const [getUpdateBuyModel, setUpdateBuyModel] = useState(false);
  const [getGenerateQrModel, setGenerateQrModel] = useState(false);
  const [getGenerateQrData, setGenerateQrData] = useState({});
  //common props setState
  const [commonProps, setCommonProps] = useState({});
  const getAppConfig = () => window.AppConfigData;

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterProducts?.filter((row) => {
      return row.category.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setProductsData(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  useEffect(() => {
    if (
      !parentId ||
      !username ||
      !selectedVerticalsId ||
      !selectedCategory ||
      !selectedAssertTypes ||
      !selectedModel ||
      !getCategoriesList
    )
      return;
    getAllModelProducts();
    setSubCategoryApiFilter("");
  }, [
    parentId,
    username,
    selectedVerticalsId,
    selectedCategory,
    selectedAssertTypes,
    selectedModel,
    getCategoriesList,
  ]);

  useEffect(() => {
    if (!username || !getCategoriesList) return;
    subCategoryList();
  }, [username, getCategoriesList]);

  const categorysList = () => {
    MainService.getListOfCategorys()
      .then((res) => {
        const filterCategory = res.filter((data) => {
          return data.categoryname.includes(categoryType);
        });
        setCreateSubCategoryProps(Object.assign({}, filterCategory[0]));
        setCategoriesList(filterCategory[0]?.categoryid);
        setCategoriesNameList(filterCategory[0]?.categoryname);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  useMemo(() => {
    if (!username) return;
    categorysList();
  }, []);

  const subCategoryList = () => {
    MainService.getCategoryRelatedSubCategories(username, getCategoriesList)
      .then((res) => {
        if (res.length > 0) {
          setSubCategoryForForm(res[0]?.contentsubcategoryname);
          setSubCategoriesList(res);
        } else {
          setSubCategoriesList(res);
        }
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  };

  /* Upload Content */
  const uploadContentHandler = (product) => {
    setUploadContentModel(!getUploadContentModel);
    setUploadcontentProps(product);
  };
  const closeContentHandler = () => {
    setUploadContentModel(!getUploadContentModel);
  };
  /* EOD Upload Content */
  /** Create Product Certificate */
  const uploadCertificateHandler = (product) => {
    setUploadCertificateProps(product);
    setUploadCertificateModel(!getUploadCertificateModel);
  };
  const closeContentCertificateHandler = () => {
    setUploadCertificateModel(!getUploadCertificateModel);
  };
  /** EOD Create Product Certificate */

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList(getAppConfig().EnterpriseId)
      .then((data) => {
        if (compType === "PMC" || compType === "FMC") {
          const targetFriendlyName =
            compType === "PMC" ? "PROCESSING-MACHINERY" : "AGRI-MACHINERY";
          const filterVerticalIds = data.filter(
            (val) => val.friendlyName === targetFriendlyName
          );
          if (filterVerticalIds?.length > 0) {
            setSelectedVerticalsId(filterVerticalIds[0]?.assetVerticalId);
            setAssetVerticalFriendlyNameValue(
              filterVerticalIds[0]?.friendlyName
            );
            setVerticalsIdList(filterVerticalIds);
          } else {
            setSelectedVerticalsId("");
          }
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  useMemo(() => {
    if (!getCategoriesList) return;
    getAssetVerticalsIdList();
  }, [getCategoriesList]);

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(
        selectedVerticalsId.replace(" ", ""),
        getAppConfig().EnterpriseId
      )
        .then((data) => {
          if (data?.length > 0) {
            setSelectedCategory(data[0]?.categoryType);
            setCategoryTypesList(data);
          } else {
            setSelectedCategory("");
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        getAppConfig().EnterpriseId
      )
        .then((data) => {
          if (data?.length > 0) {
            setSelectedAssertTypes(data[0]?.assetType);
            setAssertTypesList(data);
          } else {
            setSelectedAssertTypes("");
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    getAssetTypesList();
  }, [selectedCategory]);

  const getModelsList = () => {
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", ""),
        username
      )
        .then((data) => {
          if (data?.length > 0) {
            setSelectedModel(data[0]?.modelid);
            setModelFriendlyName(data[0]?.modelfriendlyname);
            setModelsList(data);
          } else {
            setSelectedModel("");
            setModelsList([]);
            setModelFriendlyName("");
          }
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    getModelsList();
  }, [selectedAssertTypes]);
  /** Get Products */
  const getAllModelProducts = (Type) => {
    setIsLoading(true);
    if (Type === "List") {
      setListView(true);
      setGridView(false);
    } else if (Type === "Grid") {
      setListView(false);
      setGridView(true);
    }
    MainService.getModelProductsBasedOnModel(
      parentId,
      username,
      selectedVerticalsId.replace(/ /g, ""),
      selectedCategory.replace(/ /g, ""),
      selectedAssertTypes.replace(/ /g, ""),
      selectedModel.replace(/ /g, ""),
      getCategoriesList
    )
      .then((data) => {
        setProductsData(data?.products);
        getFilterProducts(data?.products);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error(e || "something went wrong");
      });
  };
  const getAllModelProductsOfSubCaegory = (subcatval) => {
    setIsLoading(true);
    setSubCategoryApiFilter(subcatval);
    MainService.getModelProductsBasedOnContentSubcategory(
      parentId,
      username,
      selectedVerticalsId.replace(/ /g, ""),
      selectedCategory.replace(/ /g, ""),
      selectedAssertTypes.replace(/ /g, ""),
      selectedModel.replace(/ /g, ""),
      getCategoriesList,
      subcatval
    )
      .then((data) => {
        setProductsData(data?.products);
        getFilterProducts(data?.products);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error(e);
      });
  };
  /** EOD Get Products */
  /* Create Product */
  const createProductHandler = () => {
    if (productIdValue === "") {
      return NotificationManager.warning("Please Enter Product Model Id");
    }
    let createProductData = {
      consumeradminid: parentId,
      businessid: username,
      assetverticalid: selectedVerticalsId.replace(/ /g, ""),
      assetverticalname: assetVerticalFriendlyNameValue,
      category: selectedCategory.replace(/ /g, ""),
      legacyassettype: selectedAssertTypes.replace(/ /g, ""),
      modelid: selectedModel.replace(/ /g, ""),
      modelfriendlyname: getModelFriendlyName,
      assetid: "null",
      assetfriendlyname: "null",
      productid: productIdValue,
      productfriendlyname: productFriendlyNameValue,
      productcost: Number(getProductCostValue),
      unit: getUnitValue,
      discount: getDiscountValue,
      discountedprice: Number(getDiscountedPrice),
      country: getCountery,
      state: "null",
      district: "null",
      categoryname: getCategoriesNameList.toUpperCase(),
    };
    const modelProductData = JSON.stringify(createProductData);
    MainService.addModelProduct(
      parentId,
      username,
      getCategoriesList,
      getSubCategoryForForm,
      modelProductData
    )
      .then((res) => {
        setShowAssetPopup(!assetPopup);
        getAllModelProducts();
        return NotificationManager.success("product info stored");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const addProductsClickHandler = () => {
    setShowAssetPopup(!assetPopup);
  };

  const setDiscountValueHandler = (e) => {
    setDiscountValue(e.target.value);
    const price = Math.ceil(
      getProductCostValue - (getProductCostValue * e.target.value) / 100
    );
    setDiscountedPrice(price);
  };

  const closeAgreementModal = (
    <span
      onClick={() => {
        setShowAssetPopup(!assetPopup);
      }}
    >
      X
    </span>
  );

  const titleAgreement = "Product Model";
  const agreementBody = (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel>Sub Category</InputLabel>
        <Select
          id="SubCategory"
          name="SubCategory"
          type="text"
          value={getSubCategoryForForm}
          onChange={(e) => {
            setSubCategoryForForm(e.target.value);
          }}
          label="Sub Category"
        >
          {getSubCategoriesList?.length > 0 ? (
            getSubCategoriesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.contentsubcategoryname}>
                {idObj.contentsubcategoryname}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No data found</MenuItem>
          )}
        </Select>
      </FormControl>
      <FormControl fullWidth size="small">
        <TextField
          size="small"
          label="Accessories Model ID"
          id="productid"
          name="productid"
          type="text"
          placeholder="Livasa5299"
          value={productIdValue}
          onChange={(e) => {
            setProductIdValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth size="small">
        <TextField
          size="small"
          label="Accessories Model Friendly Name"
          id="ProductFriendlyName"
          name="ProductFriendlyName"
          placeholder="Livasa Honey Extract Machine"
          type="text"
          value={productFriendlyNameValue}
          onChange={(e) => {
            setProductFriendlyNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth size="small">
        <TextField
          size="small"
          label="Accessories Cost"
          id="productcost"
          name="productcost"
          type="text"
          value={getProductCostValue}
          onChange={(e) => {
            setProductCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth size="small">
        <TextField
          size="small"
          label="Unit"
          id="unit"
          name="unit"
          type="text"
          value={getUnitValue}
          onChange={(e) => {
            setUnitValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth size="small">
        <TextField
          size="small"
          label="Discount"
          id="discount"
          name="discount"
          type="text"
          value={getDiscountValue}
          onChange={(e) => {
            setDiscountValueHandler(e);
          }}
        />
      </FormControl>
      <FormControl fullWidth size="small">
        <TextField
          size="small"
          label="Discounted Price"
          disabled
          id="discountprice"
          name="discountprice"
          type="text"
          value={getDiscountedPrice}
        />
      </FormControl>
      <FormControl fullWidth size="small">
        <TextField
          label="Country"
          size="small"
          id="country"
          name="country"
          type="text"
          value={getCountery}
          onChange={(e) => {
            setCountery(e.target.value);
          }}
        />
      </FormControl>
      <Box sx={{ display: "flex", gap: "5px" }}>
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          onClick={(e) => createProductHandler()}
        >
          Create
        </Button>
        <Button
          variant="outlined"
          color="error"
          type="submit"
          onClick={() => {
            setShowAssetPopup(!assetPopup);
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
  /* EOD Create Product */
  /* ** Create Product Item ** */
  const createProductItemHandler = (product) => {
    setProductItemModel(!getProductItemModel);
    setItemConsumerAdminId(product.consumeradminid);
    setItemBusinessId(product.businessid);
    setItemProductId(product.productid);
    setAssetType(product.legacyassettype);
  };
  const closeCreateProductItemModel = () => {
    setProductItemModel(!getProductItemModel);
  };
  /*** EOD Create Product Item ** */
  /*** Delete Product ***/
  const deleteProductData = (product) => {
    setDeleteProduct(!getDeleteProduct);
    setDeleteProductBusinessId(product.businessid);
    setDeleteProductId(product.productid);
  };
  const closeDeleteProductModel = () => {
    setDeleteProduct(!getDeleteProduct);
  };
  /*** EOD Delete Product ***/

  /** Generate QR **/
  const generateQRHandler = (product) => {
    setGenerateQrModel(!getGenerateQrModel);
    setGenerateQrData(product);
  };
  /** EOD Generate QR **/

  /** Update Image Url */
  const updateImageUrlHandler = (product) => {
    setImageUpdateModel(!getImageUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };
  const updateImageModel = () => {
    setImageUpdateModel(!getImageUpdateModel);
  };
  /** EOD Update Image Url */

  /** Update Certificate */
  const updateCertificateUrlHandler = (product) => {
    setCertificateUpdateModel(!getCertificateUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };
  const closeUpdateCertModel = () => {
    setCertificateUpdateModel(!getCertificateUpdateModel);
  };
  /** EOD Update Certificate */

  /** Update QR Code */
  const updateQRCodeUrlHandler = (product) => {
    setCommonProps(product);
    setQRUpdateModel(!getQRUpdateModel);
  };
  const closeQRCodeModel = () => {
    setQRUpdateModel(!getQRUpdateModel);
  };
  /** EOD Update QR Code */

  /** Update Status */
  const updateStatusHandler = (product) => {
    setCommonProps(product);
    setStatusUpdateModel(!getStatusUpdateModel);
  };
  const closeUpdateStatusModel = () => {
    setStatusUpdateModel(!getStatusUpdateModel);
  };
  /** EOD Update Status */

  /** Update Discount Price */
  const updateDiscountHandler = (product) => {
    setDiscountUpdateModel(!getDiscountUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
    setDiscountOriginalPrice(product.productcost);
  };
  const closeUpdateDiscountModel = () => {
    setDiscountUpdateModel(!getDiscountUpdateModel);
  };
  /** EOD Update Discount Price */

  /** Update Product Cost */
  const updateProductCostHandler = (product) => {
    setProductCostUpdateModel(!getProductCostUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };
  const closeProductCostModel = () => {
    setProductCostUpdateModel(!getProductCostUpdateModel);
  };
  /** EOD Update Product Cost */

  /** Update Product Rating */
  const updateRatingHandler = (product) => {
    setRatingUpdateModel(!getRatingUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };
  const closeProductRatingModel = () => {
    setRatingUpdateModel(!getRatingUpdateModel);
  };
  /** EOD Update Product Rating */

  /** Update Item Count */
  const updateItemCountHandler = (product) => {
    setCommonProps(product);
    setItemCountUpdateModel(!getItemCountUpdateModel);
  };
  const closeItemCountModel = () => {
    setItemCountUpdateModel(!getItemCountUpdateModel);
  };
  /** EOD Update Item Count */

  /** Decrement, Increment Item Count */
  const incrementItemCountHandler = (product) => {
    MainService.incrementItemCount(
      parentId,
      product.businessid,
      product.productid,
      username
    )
      .then((res) => {
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const decrementItemCountHandler = (product) => {
    MainService.decrementItemCount(
      parentId,
      product.businessid,
      product.productid,
      username
    )
      .then((res) => {
        getAllModelProducts();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /**EOD Decrement and Increment */

  /** Update Product History URL */
  const productHistoryURLHandler = (product) => {
    setHistoryURLUpdateModel(!getHistoryURLUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };
  const closeHistoryUrlModel = () => {
    setHistoryURLUpdateModel(!getHistoryURLUpdateModel);
  };
  /**EOD Update Product History URL */

  /**Update Lab Certificate */
  const updateLabCertificateHandler = (product) => {
    setLabCertificateUpdateModel(!getLabCertificateUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };
  const closeUpdateLabCertModel = () => {
    setLabCertificateUpdateModel(!getLabCertificateUpdateModel);
  };
  /**EOD Lab Certificate */

  /** Product Video */
  const productVideoHandler = (product) => {
    setVideoUpdateModel(!getVideoUpdateModel);
    setUpdateImageAdminId(product.consumeradminid);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };
  const closeVideoModel = () => {
    setVideoUpdateModel(!getVideoUpdateModel);
  };
  /** EOD Product Video */

  /** Product Items */
  const productItemsHandler = (product) => {
    setStoreProductItem(product);
    MainService.getProductItems(
      parentId,
      product.businessid,
      product.productid,
      username
    )
      .then((res) => {
        setProductItemUpdateModel(true);
        setProductItemsStr(res.productitems);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const closeItemModel = () => {
    setProductItemUpdateModel(!getProductItemUpdateModel);
  };
  /**EOD Product Items*/

  /** Update Model Product Info Model */
  const createModelInfoHandler = (product) => {
    setModelProductInfo(product);
  };
  const getModelInfoHandler = (product) => {
    MainService.getContentsBasedonContentCreator(
      product.productid,
      product.categoryid,
      "contentowner"
    )
      .then((data) => {
        setInfo(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const updateInfoUrlHandler = (product) => {
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };
  const updateModelProductInfoHandler = (product) => {
    setUpdateInfoModel(!getUpdateInfoModel);
    createModelInfoHandler(product);
    getModelInfoHandler(product);
    updateInfoUrlHandler(product);
  };
  const closeUpdateModelProductInfo = () => {
    setUpdateInfoModel(!getUpdateInfoModel);
  };
  /** EOD Update Model Product Info Model */

  /** Product Buy */
  const productBuyHandler = (product) => {
    setUpdateBuyModel(!getUpdateBuyModel);
    setUpdateImageBusinessId(product.businessid);
    setUpdateImageProductId(product.productid);
  };
  const closeBuyModel = () => {
    setUpdateBuyModel(!getUpdateBuyModel);
  };
  /** EOD Product Buy */

  /** Create Sub-Category */
  const createSubCategoryModel = () => {
    setSubCategoryModel(!getSubCategoryModel);
  };
  const closeSubCategoryModel = () => {
    setSubCategoryModel(!getSubCategoryModel);
  };
  const deleteSubCategoryModel = () => {
    setDeleteSubCategoryModel(!getDeleteSubCategoryModel);
  };
  const closeDeleteSubCategoryModel = () => {
    setDeleteSubCategoryModel(!getDeleteSubCategoryModel);
  };
  /** EOD Create Sub-Category */

  return (
    <>
      <Box className="container" id="mainContentList" sx={{ width: "100%" }}>
        <Fab
          color="primary"
          size={iconWidthRes ? "small" : "large"}
          aria-label="add"
          title="Create Product"
          className={classes.fab}
          onClick={() => addProductsClickHandler()}
        >
          <AddIcon></AddIcon>
        </Fab>
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "70%",
              md: "auto",
            },
            my: "0.5rem",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Button
            {...textSize}
            color="primary"
            variant="contained"
            onClick={() => {
              deleteSubCategoryModel();
            }}
          >
            Sub Categories
          </Button>
          <FormControl variant="outlined" size="small" className="postdropdown">
            <InputLabel
              style={{
                color: "rgb(43, 144, 99)",
                fontSize: "15px",
              }}
            >
              Sub Category Filter
            </InputLabel>
            <Select
              id="SubCategory"
              value={getSubCategoryApiFilter}
              onChange={(e) => {
                getAllModelProductsOfSubCaegory(e.target.value);
              }}
              label="Sub Category"
            >
              {getSubCategoriesList?.length > 0 ? (
                getSubCategoriesList.map((idObj, index) => (
                  <MenuItem
                    key={`${index} `}
                    value={idObj.contentsubcategoryname}
                  >
                    {idObj.contentsubcategoryname}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No sub categories</MenuItem>
              )}
            </Select>
          </FormControl>
          <Button
            startIcon={<GridIcon />}
            size="large"
            onClick={() => {
              getAllModelProducts("Grid");
            }}
          ></Button>
          <Button
            startIcon={<ListIcon />}
            size="large"
            onClick={() => {
              getAllModelProducts("List");
            }}
          ></Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                IAA Sector
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(e) => {
                  setSelectedVerticalsId(e.target.value);
                  let verticalFriendlyName = verticalsIdList.filter((val) => {
                    return val.assetVerticalId === e.target.value;
                  });
                  setAssetVerticalFriendlyNameValue(
                    verticalFriendlyName[0].friendlyName
                  );
                }}
                label="Select Verticals"
              >
                {verticalsIdList.length > 0 ? (
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <h4>Sector not found</h4>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                IAA Category
              </InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                IAA Asset Type
              </InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypes(event.target.value);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "70%", md: "auto" } }}>
            <FormControl
              variant="outlined"
              size="small"
              className="postdropdown subCatbutton"
            >
              <InputLabel
                style={{
                  color: "rgb(43, 144, 99)",
                  fontSize: "15px",
                }}
              >
                IAA Model
              </InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModel(event.target.value);
                  let modelFriendlyName = modelList.filter((val) => {
                    return val.modelid === event.target.value;
                  });
                  setModelFriendlyName(modelFriendlyName[0].modelfriendlyname);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box>
          {getListView === true ? (
            <Box>
              <Box sx={{ m: "0" }}>
                {isLoading ? (
                  <Box>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                  </Box>
                ) : (
                  <Suspense fallback={<Loading />}>
                    <ProductCustomTable
                      getTableProducts={getProductsData}
                      displayFields={displayFields}
                      productType={categoryType}
                    />
                  </Suspense>
                )}
              </Box>
            </Box>
          ) : null}

          {getGridView === true ? (
            <Box sx={{ m: "0" }}>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "40%",
                    lg: "40%",
                    xl: "40%",
                  },
                  my: "0.5rem",
                }}
              >
                <SearchBar
                  value={farmgridsearched}
                  onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                  onCancelSearch={() => cancelFarmGridSearch()}
                />
              </Box>
              {isLoading ? (
                <PlaceholderCardList
                  numberOfCards={getProductsData.length || 4}
                  heightVal={500}
                />
              ) : (
                <Suspense fallback={<Loading />}>
                  <ProductCustomCard
                    productsData={getProductsData}
                    createproductItem={createProductItemHandler}
                    deleteproductData={deleteProductData}
                    generateQRCode={generateQRHandler}
                    uploadContent={uploadContentHandler}
                    uploadCertificate={uploadCertificateHandler}
                    updateImageUrl={updateImageUrlHandler}
                    updateCertificateUrl={updateCertificateUrlHandler}
                    updateQRCodeUrl={updateQRCodeUrlHandler}
                    updateStatus={updateStatusHandler}
                    updateProductCost={updateProductCostHandler}
                    updateDiscount={updateDiscountHandler}
                    updateRating={updateRatingHandler}
                    updateItemCount={updateItemCountHandler}
                    incrementItemCount={incrementItemCountHandler}
                    decrementItemCount={decrementItemCountHandler}
                    productHistoryURL={productHistoryURLHandler}
                    updateLabCertificate={updateLabCertificateHandler}
                    productVideo={productVideoHandler}
                    productItems={productItemsHandler}
                    updateModelProductInfoModel={updateModelProductInfoHandler}
                    productBuy={productBuyHandler}
                    productType={categoryType}
                  />
                </Suspense>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>

      <Suspense fallback={<Loading />}>
        <UploadContent
          props={getUploadcontentProps}
          modelShow={getUploadContentModel}
          parent={parentId}
          closeModel={closeContentHandler}
          reloadComponent={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UploadCertificate
          props={getUploadCertificateProps}
          modelShow={getUploadCertificateModel}
          parent={parentId}
          closeModel={closeContentCertificateHandler}
          reloadComponent={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <ContentSubCategoryCreate
          props={getCreateSubCategoryProps}
          modelShow={getSubCategoryModel}
          closeModel={closeSubCategoryModel}
          reloadComponent={subCategoryList}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <DeleteSubCategoryOfContent
          subCategories={getSubCategoriesList}
          modelShow={getDeleteSubCategoryModel}
          closeModel={closeDeleteSubCategoryModel}
          reloadComponent={subCategoryList}
          createSubCategoryModel={createSubCategoryModel}
          categoryType={categoryType}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <CreateProductItem
          Consumeradminid={getItemConsumerAdminId}
          Businessid={getItemBusinessId}
          Productid={getItemProductId}
          Assettype={getAssetType}
          reLoad={getAllModelProducts}
          closeModel={closeCreateProductItemModel}
          openModel={getProductItemModel}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <DeleteProduct
          deleteProductBusinessId={getDeleteProductBusinessId}
          deleteProductId={getDeleteProductId}
          openModel={getDeleteProduct}
          closeModel={closeDeleteProductModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateModelImage
          updateImageBusinessId={getUpdateImageBusinessId}
          updateImageProductId={getUpdateImageProductId}
          openModel={getImageUpdateModel}
          closeModel={updateImageModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateCertificate
          updateCertBusinessId={getUpdateImageBusinessId}
          updateCertProductId={getUpdateImageProductId}
          openModel={getCertificateUpdateModel}
          closeModel={closeUpdateCertModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateQR
          props={commonProps}
          openModel={getQRUpdateModel}
          closeModel={closeQRCodeModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateStatus
          props={commonProps}
          openModel={getStatusUpdateModel}
          closeModel={closeUpdateStatusModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateDiscount
          discountOriginalPrice={getDiscountOriginalPrice}
          updateDisBusinessId={getUpdateImageBusinessId}
          updateDisProductId={getUpdateImageProductId}
          openModel={getDiscountUpdateModel}
          closeModel={closeUpdateDiscountModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateProductCost
          updateCostBusinessId={getUpdateImageBusinessId}
          updateCostProductId={getUpdateImageProductId}
          openModel={getProductCostUpdateModel}
          closeModel={closeProductCostModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateProductRating
          updateRatingBusinessId={getUpdateImageBusinessId}
          updateRatingProductId={getUpdateImageProductId}
          openModel={getRatingUpdateModel}
          closeModel={closeProductRatingModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateItemCount
          props={commonProps}
          openModel={getItemCountUpdateModel}
          closeModel={closeItemCountModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateHistoryUrl
          updateHistoryBusinessId={getUpdateImageBusinessId}
          updateHistoryProductId={getUpdateImageProductId}
          openModel={getHistoryURLUpdateModel}
          closeModel={closeHistoryUrlModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UpdateLabCertificate
          updateLabCertiBusinessId={getUpdateImageBusinessId}
          updateLabCertiProductId={getUpdateImageProductId}
          openModel={getLabCertificateUpdateModel}
          closeModel={closeUpdateLabCertModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <ProductVideo
          updateVideoBusinessId={getUpdateImageBusinessId}
          updateVideoProductId={getUpdateImageProductId}
          openModel={getVideoUpdateModel}
          closeModel={closeVideoModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <ProductItems
          productItems={getProductItemsStr}
          openModel={getProductItemUpdateModel}
          closeModel={closeItemModel}
          itemHandler={productItemsHandler}
          storeProducts={storeProductItem}
          compType={compType}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <UploadModelProductInfo
          props={getModelProductInfo}
          info={getInfo}
          updateInfoBusinessId={getUpdateImageBusinessId}
          updateInfoProductId={getUpdateImageProductId}
          reLoad={getAllModelProducts}
          getUpdateInfoModel={getUpdateInfoModel}
          closeUpdateModelProductInfo={closeUpdateModelProductInfo}
          getModelInfoHandler={getModelInfoHandler}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <BuyUrl
          updateVideoBusinessId={getUpdateImageBusinessId}
          updateVideoProductId={getUpdateImageProductId}
          openModel={getUpdateBuyModel}
          closeModel={closeBuyModel}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <GenerateProductQrCode
          props={getGenerateQrData}
          openModel={getGenerateQrModel}
          closeModel={generateQRHandler}
          reLoad={getAllModelProducts}
        />
      </Suspense>

      <CustomPopup
        closeButton={closeAgreementModal}
        isOpen={assetPopup}
        closeModal={assetPopup}
        content={agreementBody}
        title={titleAgreement}
      />
    </>
  );
}
