import React, { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Input,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import AddressForm from '../../Invoicecomponents/components/AddressForm';
import InvoiceTable from '../../Invoicecomponents/components/InvoiceTable';

const tempewayBillLable = [
  { id: 'eway', label: 'e-way no' },
  { id: 'viewLink', label: 'View Link', type: 'button' },
];

const EwayBill = () => {
  const [activeComponent, setActiveComponent] = useState('ewaylist');
  const [ewayList, setEwayList] = useState([]);
  return (
    <Fragment>
      {activeComponent === 'ewaylist' && (
        <Box>
          <Typography
            sx={{
              fontWeight: '800',
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
              textAlign: 'center',
              mb: '0.5rem',
              color: '#1976d2',
            }}
          >
            e-Way Bill Management
          </Typography>
          <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          <InvoiceTable columnData={tempewayBillLable} rowDataList={ewayList} />
          <Stack>
            <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={() => setActiveComponent('addEway')}>
                Add e-Way Bill
              </Button>
            </ListItem>
          </Stack>
        </Box>
      )}
      {activeComponent === 'addEway' && (
        <Box sx={{ p: { xs: '1rem 0rem', md: '0' } }}>
          <Box>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
                textAlign: 'center',
                mb: '0.5rem',
                color: '#1976d2',
              }}
            >
              E-way Bill
            </Typography>
            <Divider sx={{ mb: '1rem', background: '#1976d2' }} />
          </Box>

          <Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.4rem',
                  color: '#1976d2',
                  borderBottom: '1px solid',
                  mb: 1,
                }}
              >
                E-Way Bill Details
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <Box sx={{ display: 'flex', '&>*': { flex: 1 } }}>
                <Box
                  sx={{
                    display: 'flex',
                    '&>*': { flex: 1 },
                    gap: 2,
                  }}
                >
                  <Box sx={{ background: '#0000000f', p: 1 }}>
                    <Input
                      placeholder='Please enter e-way bill no'
                      name='ewayno'
                      id='ewayno'
                      multiline
                      sx={{ display: 'flex' }}
                    />
                    <Input
                      placeholder='Please enter generated by'
                      name='generatedby'
                      id='generatedby'
                      multiline
                      sx={{ display: 'flex' }}
                    />
                    <Input
                      placeholder='Please enter valid upto'
                      name='validupto'
                      id='validupto'
                      multiline
                      sx={{ display: 'flex' }}
                    />
                    <Input
                      placeholder='Please enter mode'
                      name='mode'
                      id='mode'
                      multiline
                      sx={{ display: 'flex' }}
                    />
                  </Box>

                  <Box sx={{ background: '#0000000f', p: 1 }}>
                    <Input
                      placeholder='Please enter approx distance'
                      name='distance'
                      id='distance'
                      multiline
                      sx={{ display: 'flex' }}
                    />
                    <Input
                      placeholder='Please enter type'
                      name='type'
                      id='type'
                      multiline
                      sx={{ display: 'flex' }}
                    />
                    <Input
                      placeholder='Please enter transaction type'
                      name='transaction'
                      id='transaction'
                      multiline
                      sx={{ display: 'flex' }}
                    />
                    <Input
                      placeholder='Please enter document details'
                      name='document'
                      id='document'
                      multiline
                      sx={{ display: 'flex' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 1 }}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.4rem',
                  color: '#1976d2',
                  borderBottom: '1px solid',
                  mb: 1,
                }}
              >
                Address Details
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <Box sx={{ display: 'flex', '&>*': { flex: 1 } }}>
                <Box
                  sx={{
                    display: 'flex',
                    '&>*': { flex: 1 },
                    gap: 2,
                  }}
                >
                  <Box>
                    <Typography>Dispatch from : :</Typography>
                    <AddressForm addressDetails={{}} dispatch={{}} />
                  </Box>

                  <Box>
                    <Typography>Ship To : :</Typography>
                    <AddressForm addressDetails={{}} dispatch={{}} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 1 }}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.4rem',
                  color: '#1976d2',
                  borderBottom: '1px solid',
                  mb: 1,
                }}
              >
                Goods Details
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <Box sx={{ display: 'flex', '&>*': { flex: 1 } }}>
                <Box
                  sx={{
                    display: 'flex',
                    '&>*': { flex: 1 },
                    gap: 2,
                  }}
                >
                  hello
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};

export default EwayBill;
