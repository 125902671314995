import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import ListIcon from "@material-ui/icons/List";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import ContentDynamicCard from "../../Common/CommonContent/ContentDynamicCard";
import ContentDynamicTable from "../../Common/CommonContent/ContentDynamicTable";
import MainService from "../../Common/MainService";
import PlaceholderCardList from "../ModelProductManagement/PlaceHolderCardList";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

const dynamicKey = [
  "aboutusLink",
  "CustomerCare_number",
  "CustomerCare_email",
  "CustomerCare_fax",
  "Address",
];

const dynamicKeyTable = [
  "aboutusLink",
  "CustomerCare_number",
  "CustomerCare_email",
  "CustomerCare_fax",
  "Address",
];

export default function SettingsInfoComponent(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [customerSupportList, setCustomerSupportList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [createCustomerCareDialog, setCreateCustomerCareDialog] =
    useState(false);
  const [updateCustomerCareDialog, setUpdateCustomerCareDialog] =
    useState(false);
  const [deleteCustomerCareDialog, setDeleteCustomerCareDialog] =
    useState(false);
  const [setAboutUsLink, getAboutUsLink] = useState("");
  const [setCustomerCareNumber, getCustomerCareNumber] = useState("");
  const [setCustomerCareEmail, getCustomerCareEmail] = useState("");
  const [setCustomerCareFax, getCustomerCareFax] = useState("");
  const [setAddress, getAddress] = useState("");
  const [selectedCustomerCareAboutUsLink, setSelectedCustomerCareAboutUsLink] =
    useState("");
  const [selectedCustomerCareAddress, setSelectedCustomerCareAddress] =
    useState("");
  const [selectedCustomerCareEmail, setSelectedCustomerCareEmail] =
    useState("");
  const [selectedCustomerCareFax, setSelectedCustomerCareFax] = useState("");
  const [selectedCustomerCareNumber, setSelectedCustomerCareNumber] =
    useState("");
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);

  useEffect(() => {
    getCustomerSupportList();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* View All Info */
  const getCustomerSupportList = () => {
    setIsLoading(true);
    MainService.settingsInfoBasedonParent(username)
      .then((res) => {
        setCustomerSupportList(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        return NotificationManager.error("err");
      });
  };
  /* End All Info */
  const handleClose = () => {};

  const updateCustomerCareDialogClick = (selectedCustomerCare) => {
    setSelectedCustomerCareAboutUsLink(selectedCustomerCare.aboutusLink);
    setSelectedCustomerCareNumber(selectedCustomerCare.CustomerCare_number);
    setSelectedCustomerCareEmail(selectedCustomerCare.CustomerCare_email);
    setSelectedCustomerCareFax(selectedCustomerCare.CustomerCare_fax);
    setSelectedCustomerCareAddress(selectedCustomerCare.Address);

    setUpdateCustomerCareDialog(!updateCustomerCareDialog);
  };
  const deleteCustomerCareDialogClick = () => {
    setDeleteCustomerCareDialog(!deleteCustomerCareDialog);
  };

  const createCustomerCareSubmit = () => {
    if (
      setAboutUsLink &&
      setCustomerCareNumber &&
      setCustomerCareEmail &&
      setCustomerCareFax &&
      setAddress
    ) {
      let customerCareData = {
        parentid: username,
        aboutusLink: setAboutUsLink,
        CustomerCare_number: setCustomerCareNumber,
        CustomerCare_email: setCustomerCareEmail,
        CustomerCare_fax: setCustomerCareFax,
        Address: setAddress.replace(/\n/g, ""),
      };
      const customerCareDataString = JSON.stringify(customerCareData);

      MainService.createCustomerCare(customerCareDataString)
        .then((res) => {
          getCustomerSupportList();
          return NotificationManager.success(res);
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
    } else {
      return NotificationManager.error("please fill all fields");
    }

    setCreateCustomerCareDialog(!createCustomerCareDialog);
  };

  const updateCustomerCareSubmit = () => {
    let customerCareData = {
      parentid: username,
      aboutusLink: selectedCustomerCareAboutUsLink,
      CustomerCare_number: selectedCustomerCareNumber,
      CustomerCare_email: selectedCustomerCareEmail,
      CustomerCare_fax: selectedCustomerCareFax,
      Address: selectedCustomerCareAddress.replace(/\n/g, ""),
    };
    const customerCareDataString = JSON.stringify(customerCareData);

    MainService.updateCustomerCare(customerCareDataString)
      .then((res) => {
        getCustomerSupportList();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setUpdateCustomerCareDialog(!updateCustomerCareDialog);
  };

  const deleteCustomerCareSubmit = () => {
    MainService.deleteCustomerCare(username)
      .then((res) => {
        getCustomerSupportList();
        return NotificationManager.success(res);
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
    setDeleteCustomerCareDialog(!deleteCustomerCareDialog);
  };

  const listViewClick = () => {
    setListView(true);
    setGridView(false);
  };
  
  const gridViewClick = () => {
    setListView(false);
    setGridView(true);
  };

  const actions = [
    {
      label: "Update",
      click: (item) => updateCustomerCareDialogClick(item),
    },
    {
      label: "Delete",
      click: () => deleteCustomerCareDialogClick(),
    },
  ];

  return (
    <>
      <Box className="container" id="mainContentList">
        {isLoading === false && customerSupportList?.length === 0 && (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Settings Info"
            className={classes.fab}
            onClick={() => {
              setCreateCustomerCareDialog(!createCustomerCareDialog);
            }}
          >
            <AddIcon></AddIcon>
          </Fab>
        )}

        <Box style={{ float: "right" }}>
          <Button
            startIcon={<ListIcon />}
            size="large"
            onClick={listViewClick}
          ></Button>
          <Button
            startIcon={<GridIcon />}
            size="large"
            onClick={gridViewClick}
          ></Button>
        </Box>

        {listView &&
          (isLoading ? (
            <Box>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          ) : (
            <ContentDynamicTable
              columns={dynamicKeyTable}
              data={customerSupportList}
              actions={actions}
            />
          ))}
        {gridView &&
          (isLoading ? (
            <PlaceholderCardList numberOfCards={1} heightVal={300} />
          ) : (
            <ContentDynamicCard
              dynamicKey={dynamicKey}
              data={customerSupportList}
              actions={actions}
            />
          ))}

        {/* Create */}
        <Dialog
          open={createCustomerCareDialog}
          onClose={handleClose}
          aria-labelledby="craete Customer Care"
        >
          <DialogTitle id="craeteCustomerCare">
            {"Create Customer Care"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                size="small"
                label="About Us link"
                id="about"
                name="about"
                type="text"
                value={setAboutUsLink}
                onChange={(e) => {
                  getAboutUsLink(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                size="small"
                label="Customer Care Number"
                id="number"
                name="number"
                type="text"
                value={setCustomerCareNumber}
                onChange={(e) => {
                  getCustomerCareNumber(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                size="small"
                label="Customer Care Email"
                id="email"
                name="email"
                type="text"
                value={setCustomerCareEmail}
                onChange={(e) => {
                  getCustomerCareEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                size="small"
                label="Customer Care Fax"
                id="fax"
                name="fax"
                type="text"
                value={setCustomerCareFax}
                onChange={(e) => {
                  getCustomerCareFax(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                multiline
                minRows={4}
                label="Address"
                placeholder="Address"
                value={setAddress}
                onChange={(e) => {
                  getAddress(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent the default action for the Enter key
                  }
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                createCustomerCareSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setCreateCustomerCareDialog(!createCustomerCareDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Update */}
        <Dialog
          open={updateCustomerCareDialog}
          onClose={handleClose}
          aria-labelledby="update Customer Care"
        >
          <DialogTitle id="updateCustomerCare">
            {"Update Settings Info"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                size="small"
                label="About Us link"
                id="about"
                name="about"
                type="text"
                value={selectedCustomerCareAboutUsLink}
                onChange={(e) => {
                  setSelectedCustomerCareAboutUsLink(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                size="small"
                label="Customer Care Number"
                id="number"
                name="number"
                type="text"
                value={selectedCustomerCareNumber}
                onChange={(e) => {
                  setSelectedCustomerCareNumber(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                size="small"
                label="Customer Care Email"
                id="email"
                name="email"
                type="text"
                value={selectedCustomerCareEmail}
                onChange={(e) => {
                  setSelectedCustomerCareEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                size="small"
                label="Customer Care Fax"
                id="fax"
                name="fax"
                type="text"
                value={selectedCustomerCareFax}
                onChange={(e) => {
                  setSelectedCustomerCareFax(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                multiline
                minRows={4}
                label="Address"
                placeholder="Address"
                value={selectedCustomerCareAddress}
                onChange={(e) => {
                  setSelectedCustomerCareAddress(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent the default action for the Enter key
                  }
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ textTransform: "none" }}
              color="primary"
              variant="outlined"
              onClick={() => {
                updateCustomerCareSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              sx={{ textTransform: "none" }}
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateCustomerCareDialog(!updateCustomerCareDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete */}
        <Dialog
          open={deleteCustomerCareDialog}
          onClose={handleClose}
          aria-labelledby="update Customer Care"
        >
          <DialogContent>Are you sure ?</DialogContent>
          <DialogActions>
            <Button
              sx={{ textTransform: "none" }}
              color="error"
              variant="outlined"
              onClick={() => {
                deleteCustomerCareSubmit();
              }}
            >
              Yes
            </Button>
            <Button
              sx={{ textTransform: "none" }}
              color="primary"
              variant="outlined"
              onClick={() => {
                setDeleteCustomerCareDialog(!deleteCustomerCareDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
