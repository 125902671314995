import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardActions, CardContent, Button, Typography, Grid } from "@material-ui/core";
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";

// Import your images
import image1 from "../../../../resources/img/DashboardImages/MyAssets.svg";
import image2 from "../../../../resources/img/DashboardImages/MyFarms.svg";
import image3 from "../../../../resources/img/DashboardImages/MyNetwork.svg";
import image4 from "../../../../resources/img/DashboardImages/MyProducts.svg";
import image5 from "../../../../resources/img/DashboardImages/MyOrders.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 175,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 15, // Rounded corners
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", // Shadow effect
    transition: "0.3s", // Smooth transition on hover
    '&:hover': {
      boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)", // Increase shadow on hover
    },
  },
  media: {
    minHeight: 175,
    width:'auto',
    justifyContent:'center'
    //paddingTop: '56.25%', // 16:9
  },
  largeCardMedia:{
    minHeight:350,
    width:'100%',
    // objectFit:'contain'
  },
  largeCard: {
    height: '100%', // Match height with smaller cards
    width:'100%'
  },
  showDetailsButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

function DashboardPageComponentForAD() {
  const history = useHistory();
  const classes = useStyles();
  const { user } = MainService.getLocalData();
  const { username,parentId } = user;
  const [solutionList, setSolutionList] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    growers: "10 / 20",
    myAssets: 10,
    myFarmsCount: 10,
    myNetworkCount: 10,
    myProductsCount: 10,
    myPotentialCustomersCount: 10,
  });

  useEffect(() => {
    fetchData();
    getAllSolutions();
  }, []);

  const getAllSolutions = () => {
    MainService.getAllSolutions(parentId,parentId)
      .then((responseData) => {
        if (responseData && responseData.length > 0) {
          setSelectedSolution({
            ...responseData[0],
            label: responseData[0].solutionname,
            value: responseData[0].solutionid,
          });
        }
        setSolutionList(responseData);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  const fetchData = () => {
    setIsLoading(true);
    MainService.getDashboardData(parentId,username)
      .then((responseData) => {
        /* setData({
          growers: `${responseData.AssociatedAssetDevelopersCount} / ${responseData.TotalAssetDevelopersCount}`,
          myAssets: responseData.WhitelabelledAssetsCount,
          myFarmsCount: responseData.MyFarmsCount,
          myNetworkCount: responseData.MyNetworkCount,
          myProductsCount: responseData.MyProductsCount,
          myPotentialCustomersCount: responseData.MyPotentialCustomersCount,
        }); */
      })
      .catch((e) => {
        NotificationManager.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleButtonClick = (item) => {
    let path;
    switch (item) {
      case "My Farms":
        path = "./myFarms";
        break;
      case "My Network":
        path = "./productionNetwork";
        break;
      case "My Products":
        path = "./market";
        break;
      case "My Orders":
        path = "./myOrders";
        break;
      default:
        path = "./dashboardForAd";
    }
    history.push(path);
  };

  // Define image mapping based on card height
  const leftCardImageMapping = {
    'My Assets': image1, // Image for large left card
  };

  const rightCardImageMapping = {
    'My Farms': image2, // Image for small right card
    'My Network': image3, // Image for small right cards
    'My Products': image4, // Image for small right card
    'My Orders': image5, // Image for small right cards
  };

  // Function to get image based on card height
  const getImageForLeftCard = (label) => {
    return leftCardImageMapping[label] || null; // Setting to null if no image found for the height
  };

  const getImageForRightCard = (label) => {
    return rightCardImageMapping[label] || null; // Setting to null if no image found for the height
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2>Dashboard</h2>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card className={`${classes.root} ${classes.largeCard}`} variant="outlined">
          <CardContent>
            <Typography gutterBottom variant="h5" component="h3">
              My Assets
            </Typography>            
            <Typography variant="body2" color="textSecondary" component="p">
              {isLoading ? <CircularProgress /> : data.myAssets}
            </Typography>
            <img src={getImageForLeftCard('My Assets')} alt="My Assets" className={classes.largeCardMedia} onClick={() => history.push("./legacyAssetManagement")}/>
          </CardContent>
         
          {/* <CardActions>
            <Button size="small" color="primary" onClick={() => history.push("./legacyAssetManagement")} className={classes.showDetailsButton}>
              show details
            </Button>
          </CardActions> */}
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          {[
            { label: "My Farms", count: data.myFarmsCount },
            { label: "My Network", count: data.myNetworkCount },
            { label: "My Products", count: data.myProductsCount },
            { label: "My Orders", count: data.myPotentialCustomersCount },
          ].map(({ label, count }) => (
            <Grid item key={label} xs={6} md={6}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h3">
                    {label}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {isLoading ? <CircularProgress /> : count}
                  </Typography>
                  <img src={getImageForRightCard(label)} alt={label} className={classes.media} onClick={() => handleButtonClick(label)} />
                </CardContent>
                
                {/* <CardActions>
                  <Button size="small" color="primary" onClick={() => handleButtonClick(label)} className={classes.showDetailsButton}>
                    Show Details
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
        </Grid>
      </Grid>
  );
}

export default DashboardPageComponentForAD;
