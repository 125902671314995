import React from 'react'
import '../../AssetDeveloperRole/MyOrders/MyOrderStyleComponent.css';
import GlobalNetworkComponent from '../../AssetDeveloperRole/GlobalNetwork/GlobalNetworkComponent';

const OFCGlobalNetwork = () => {
  const UserRole = localStorage.getItem("UserRole");

  let allowedUserTypes = [];
  switch (UserRole) {
      case "BASIC":
          allowedUserTypes = ["StorageCompany","AssetCertifiedConsultant","LogisticsProvider","FMC","PMC","FOOD-PROCESSOR","MerchantExporter"];
          break;
      case "STANDARD":allowedUserTypes = ["StorageCompany","AssetCertifiedConsultant","LogisticsProvider","FMC","PMC","FOOD-PROCESSOR","MerchantExporter"];break;
      case "PREFERRED":allowedUserTypes = ["StorageCompany","AssetCertifiedConsultant","LogisticsProvider","FMC","PMC","FOOD-PROCESSOR","MerchantExporter"];break;
      case "PREMIUM":allowedUserTypes =   ["StorageCompany","AssetCertifiedConsultant","LogisticsProvider","FMC","PMC","FOOD-PROCESSOR","MerchantExporter"];break;
      case "ADMIN":
          allowedUserTypes =  ["StorageCompany","AssetCertifiedConsultant","LogisticsProvider","FMC","PMC","FOOD-PROCESSOR","MerchantExporter"];break;
          break;
      default:
          console.error("Unexpected UserRole:", UserRole);
          // Fallback behavior: Set default userTypes or handle the error
          allowedUserTypes = ["StorageCompany","AssetCertifiedConsultant","LogisticsProvider","FMC","PMC","FOOD-PROCESSOR","MerchantExporter"]; // Example default behavior
          break;
  }
  return (
    <div>
    <GlobalNetworkComponent userTypes={allowedUserTypes} />
</div>
  )
}

export default OFCGlobalNetwork