import React from "react";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import "./SHG.css";
import SHGNeeds from "./SHGNeeds";
import SHGServices from "./SHGServices";

export default function SHG() {
  return (
    <>
      <UpdatesBanner className="ap-shg" bannerText="NGOs" />
      <SHGNeeds />
      <SHGServices />
    </>
  );
}
