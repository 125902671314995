import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MainService from "../Common/MainService";
import { CartItemContext } from "../../../context/CartItemContext";
import CachedIcon from "@material-ui/icons/Cached";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { NotificationManager } from "react-notifications";
import Box from "@mui/material/Box";
import CssBaseline from '@material-ui/core/CssBaseline';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    paddingTop: 0, // Set paddingTop to 0
    paddingBottom: 0, // Set paddingBottom to 0
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft:0
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function MainDrawer ({
  mainDashboardItemHandler,
  reLoadHandler,
  toggleChangePasswordHandler,
  logoutHandler,
  cartHandler,
  getMenuListHandler
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false); 
  const { cartState } = useContext(CartItemContext);
  const [userTypeValue, setUserTypeValue] = useState("");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  useEffect(() => {
    getUserType();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        console.log("get consumer user type:", data);
        localStorage.setItem("userType", data);
        setUserTypeValue(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
       <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          {
            open === false&&
            <Typography variant="h6" noWrap>
            ABN
          </Typography>
          }
          
          <Box
          style={{
            display: "flex",
            alignItems: "center", // Align items in the center vertically
            justifyContent: "flex-end", // Align items to the end horizontally
            flex: 1, // Allow the Box to take up the remaining space
          }}
        >
          <IconButton color="inherit" title="Refresh" onClick={reLoadHandler}>
            <CachedIcon style={{ color: "#FFF" }} />
          </IconButton>
          <IconButton
            color="inherit"
            title="Change Password"
            onClick={toggleChangePasswordHandler}
          >
            <VpnKeyIcon style={{ color: "#FFF" }} />
          </IconButton>
          {userTypeValue == "AssetOEM" && (
            <div style={{ position: "relative" }}>
              <IconButton color="inherit" title="Cart" onClick={cartHandler}>
                <ShoppingCartIcon style={{ color: "#FFF" }} />
              </IconButton>
              {cartState.length > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    padding: "2px 6px",
                    color: "#FFF",
                    fontSize: "12px",
                  }}
                >
                  {cartState.length}
                </span>
              )}
            </div>
          )}

          <IconButton
            edge="end"
            title="Logout"
            aria-label="account of the current user"
            aria-haspopup="true"
            color="inherit"
            onClick={logoutHandler}
          >
            <ExitToAppIcon style={{ color: "#FFF" }} />
          </IconButton>
        </Box>
        </Toolbar>
        
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.drawerHeader}>
        <Typography variant="h6" noWrap style={{ flexGrow: 1, textAlign: 'center' }} >
            ABN
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        {getMenuListHandler()}
      </Drawer>
      <main className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {mainDashboardItemHandler()}
      </main>
    </div>
  );
}

