import * as React from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import { Chip, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import XMLParser from 'react-xml-parser';
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';


const VerticalUsecaseDeveloper = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openCreateDailog, setOpenCreateDailog] = React.useState(false);
    const [opendedicatedDailog, setopendedicatedDailog] = React.useState(false);
    const [deleteDailogOpen, setDeleteDailogOpen] = React.useState(false);
    const [updateDailogOpen, setUpdateDailogOpen] = React.useState(false);
    const [deviceDetailsOpen, setDeviceDetailsOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [deleteServer, setDeleteServer] = React.useState({});
    const [updateServer, setUpdateServer] = React.useState({});
    const [deviceDetails, setDeviceDetails] = React.useState({});
    const [selectedStatus, setSelectedStatus] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [xmlData, setXmlData] = React.useState('');
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    const [FormData, setFormData] = React.useState({
        deviceMakerId: '',
        activatedVerticalId: '',
        verticalType: '',
        solutionUsecaseId: '',
        solutionUsecase: '',
        version: '',
        status: '',
    });

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    React.useEffect(() => {

        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        MainService.getVerticalUsecases(username)
            .then((res) => {
                console.log(res)
                setXmlData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);

                return NotificationManager.error("Something went wrong");
            });
    };

    const parsedData = new XMLParser().parseFromString(xmlData);

    const tableData = parsedData?.children?.filter((element) => element.name === 'capability')
        .map((device, index) => ({
            devicemakerid: device.getElementsByTagName('devicemakerid')[0]?.value || '',
            verticaltype: device.getElementsByTagName('verticaltype')[0]?.value || '',
            activatedverticalid: device.getElementsByTagName('activatedverticalid')[0]?.value || '',
            solutionusecaseid: device.getElementsByTagName('solutionusecaseid')[0]?.value || '',
            solutionusecase: device.getElementsByTagName('solutionusecase')[0]?.value || '',
            status: device.getElementsByTagName('status')[0]?.value || '',
            version: device.getElementsByTagName('version')[0]?.value || '',
            description: device.getElementsByTagName('description')[0]?.value || '',
        }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleCreateClick = (event) => {

        setOpenCreateDailog(true);
    };

    const handleCreateClose = () => {
        setOpenCreateDailog(false);
        setFormData({
            deviceMakerId: '',
            activatedVerticalId: '',
            verticalType: '',
            solutionUsecaseId: '',
            solutionUsecase: '',
            version: '',
            status: '',
        });
    };

    const handleCreateFormChange = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = name === 'deviceCloudServerType' ? value.replace(/\s/g, '') : value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: sanitizedValue,
        }));
    };

    const handleCreateSubmit = async (event) => {
        event.preventDefault();
        const xmlPayload = `<?xml version="1.0" encoding="UTF-8" ?>
        <publish-info>
                <developerid>${username}</developerid>
                <devicemakerid>${FormData.deviceMakerId}</devicemakerid>
                <activatedverticalid>${FormData.activatedVerticalId}</activatedverticalid>
                <verticaltype>${FormData.verticalType}</verticaltype>
                <solutionusecaseid>${FormData.activatedVerticalId + FormData.solutionUsecaseId}</solutionusecaseid>
                <solutionusecase>${FormData.solutionUsecase}</solutionusecase>
                <version>${FormData.version}</version>
                <status>${FormData.status}</status>
        </publish-info>`;

        MainService.createVerticalUsecase(xmlPayload, username)
            .then((res) => {
                handleCreateClose();
                fetchData();
                return NotificationManager.success("Vertical Usecase Created");
            })
            .catch((err) => {
                return NotificationManager.error("Something went wrong");
            });

    };

    const handleSearchChange = (event) => {
        const newSearchQuery = event.target.value;
        setSearchQuery(newSearchQuery);
        setPage(0);
    };

    const searchTable = (row) => {
        const lowercasedQuery = searchQuery.toLowerCase();
        return Object.values(row).some(
            (value) =>
                typeof value === 'string' && value.toLowerCase().includes(lowercasedQuery)
        );
    };

    const filteredData = tableData?.filter(searchTable);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const visibleData = filteredData?.slice(startIndex, endIndex);

    const handleUpdate = (row) => {
        setUpdateServer(row);
        setUpdateDailogOpen(true);
    };

    const handleDetailsShow = (row) => {
        setDeviceDetails(row);
        setDeviceDetailsOpen(true);
    };

    const handleUpdateSubmit = async () => {

        MainService.updateVerticalUsecaseStatus(username, updateServer.activatedverticalid, updateServer.verticaltype, updateServer.solutionusecase, selectedStatus)
            .then((res) => {
                setUpdateDailogOpen(false);
                // Handle the response
                fetchData();
                return NotificationManager.success("Vertical Usecase Status Updated");
            })
            .catch((err) => {
                return NotificationManager.error("Something went wrong");
            });
    }

    const handleDeleteSubmit = async (row) => {

        MainService.deleteVerticalUsecase(username, deleteServer.activatedverticalid, deleteServer.verticaltype, deleteServer.solutionusecase)
            .then((res) => {
                handleDeleteDailogClose();
                // Handle the response
                fetchData();
                return NotificationManager.success("Vertical Usecase Deleted");
            })
            .catch((err) => {
                handleDeleteDailogClose();
                return NotificationManager.error("Something went wrong");
            });

    };
    const handleDeleteDailogClose = () => {
        setDeleteDailogOpen(false);
    }

    const handleUpdateDailogClose = () => {
        setUpdateDailogOpen(false);
    }

    const handleDetailsClose = () => {
        setDeviceDetailsOpen(false);
    }

    const skeletonRows = Array.from({ length: 5 }, (_, index) => (
        <TableRow key={index}>
            <TableCell colSpan={7}>
                <Box sx={{ width: '100%' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </Box>
            </TableCell>
            <TableCell><Chip
                label={
                    <Skeleton
                        animation="wave"
                        height={10}
                        width={30}
                        style={{ marginBottom: 6 }}
                    />
                }
            /></TableCell>
            <TableCell sx={{ display: 'flex' }}>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={30}
                    width={60}
                    style={{ margin: 5 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={30}
                    width={60}
                    style={{ margin: 5 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={30}
                    width={60}
                    style={{ margin: 5 }}
                />
            </TableCell>
        </TableRow>
    ));

    return (
        <div>
            <span><h3>Usecases List</h3></span>
            <Grid container spacing={2} display="flex" justifyContent="space-between">
                <Grid item xs={6} md={4}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid display="flex" justifyContent="flex-end" item xs={6} md={8}>
                    <Button
                        onClick={handleCreateClick}
                        variant='outlined'
                        style={{ color: '#3ecf8e', borderColor: '#3ecf8e', height: '2.5rem' }}
                        size='small'
                    >
                        <AddIcon />Create Usecase
                    </Button>
                </Grid>
            </Grid>
            {isLoading ? <div>
                <TableContainer component={Paper}>
                    <Table responsive>
                        <TableHead>
                            <TableRow>
                                <TableCell>Device Maker Id</TableCell>
                                <TableCell>Vertical Type</TableCell>
                                <TableCell>Activated Vertical Id</TableCell>
                                <TableCell>Solution Usecase Id</TableCell>
                                <TableCell>Solution Usecase</TableCell>
                                <TableCell>Version</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {skeletonRows}
                            </>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData ? filteredData.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div> : <div>
                <TableContainer component={Paper}>
                    <Table responsive>
                        <TableHead>
                            <TableRow>
                                <TableCell>Device Maker Id</TableCell>
                                <TableCell>Vertical Type</TableCell>
                                <TableCell>Activated Vertical Id</TableCell>
                                <TableCell>Solution Usecase Id</TableCell>
                                <TableCell>Solution Usecase</TableCell>
                                <TableCell>Version</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(visibleData?.length === 0 || visibleData === undefined) ? <TableRow>
                                <TableCell colSpan={9}>
                                    <h3 style={{ textAlign: 'center' }}>No Servers Found</h3>
                                </TableCell>
                            </TableRow> :
                                <>
                                    {visibleData?.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.devicemakerid}</TableCell>
                                            <TableCell>{row.verticaltype}</TableCell>
                                            <TableCell>{row.activatedverticalid}</TableCell>
                                            <TableCell>{row.solutionusecaseid}</TableCell>
                                            <TableCell>{row.solutionusecase}</TableCell>
                                            <TableCell>{row.version}</TableCell>
                                            <TableCell><Chip label={row.status} color={row.status === 'in-development' ? "info" : "success"} variant='outlined'></Chip></TableCell>
                                            <TableCell style={{ display: 'flex' }}>
                                                <Tooltip title="View" arrow placement="top">
                                                    <Button variant="outlined" color="primary" onClick={() => handleDetailsShow(row)}>
                                                        <VisibilityIcon />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Update Status" arrow placement="top">
                                                    <Button variant="outlined" color="secondary" style={{ marginLeft: '0.5rem' }} onClick={() => handleUpdate(row)}>
                                                        <DriveFileRenameOutlineIcon />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Delete" arrow placement="top">
                                                    <Button variant="outlined" color="error" style={{ marginLeft: '0.5rem' }}
                                                        onClick={() => {
                                                            setDeleteDailogOpen(true)
                                                            setDeleteServer(row)
                                                        }}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData ? filteredData.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>}
            <Dialog open={openCreateDailog} onClose={handleCreateClose}>
                <DialogTitle>Create Use Case</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleCreateSubmit}>
                        <TextField
                            label="Device Maker Id"
                            name="deviceMakerId"
                            value={FormData.deviceMakerId}
                            onChange={handleCreateFormChange}
                            fullWidth
                            margin="normal"
                            inputProps={{ pattern: '^\\S*$' }}
                            required
                        />
                        <TextField
                            label="Activated Vertical Id"
                            name="activatedVerticalId"
                            value={FormData.activatedVerticalId}
                            onChange={handleCreateFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Vertical Type</InputLabel>
                            <Select
                                label="Vertical Type"
                                name="verticalType"
                                value={FormData.verticalType}
                                onChange={handleCreateFormChange}
                                required
                            >
                                <MenuItem value="SMART-HOME">SMART-HOME</MenuItem>
                                <MenuItem value="SMART-CITY">SMART-CITY</MenuItem>
                                <MenuItem value="SMART-RETAIL">SMART-RETAIL</MenuItem>
                                <MenuItem value="SMART-OFFICE">SMART-OFFICE</MenuItem>
                                <MenuItem value="SMART-HOSPITAL">SMART-HOSPITAL</MenuItem>
                                <MenuItem value="SMART-BUILDING">SMART-BUILDING</MenuItem>
                                <MenuItem value="SAMRT-UTILITIES">SAMRT-UTILITIES</MenuItem>
                                <MenuItem value="SMART-OIL-GAS">SMART-OIL-GAS</MenuItem>
                                <MenuItem value="SMART-INDUSTRIAL">SMART-INDUSTRIAL</MenuItem>
                                <MenuItem value="SMART-AGRICULTURE">SMART-AGRICULTURE</MenuItem>
                                <MenuItem value="SMART-MANUFACTURING">SMART-MANUFACTURING</MenuItem>
                                <MenuItem value="GENERIC">GENERIC</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Solution Use Case Id"
                            name="solutionUsecaseId"
                            value={FormData.solutionUsecaseId}
                            onChange={handleCreateFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Solution Use Case Name"
                            name="solutionUsecase"
                            value={FormData.port}
                            onChange={handleCreateFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Version"
                            name="version"
                            value={FormData.version}
                            onChange={handleCreateFormChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Status</InputLabel>
                            <Select
                                label="Status"
                                name="status"
                                value={FormData.status}
                                onChange={handleCreateFormChange}
                                required
                            >
                                <MenuItem value="in-development">In Development</MenuItem>
                            </Select>
                        </FormControl>
                        <DialogActions>
                            <Button type='submit' variant="contained" style={{ backgroundColor: '#3ecf8e', borderColor: '#3ecf8e' }} >
                                Submit
                            </Button>
                            <Button onClick={handleCreateClose} variant='outlined' color="error">
                                Cancel
                            </Button>

                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog open={deleteDailogOpen} onClose={handleDeleteDailogClose}>
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDailogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteSubmit} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={updateDailogOpen} onClose={handleUpdateDailogClose}>
                <DialogTitle>Update Usecase Status</DialogTitle>
                <DialogContent>
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={selectedStatus}
                        onChange={handleStatusChange}
                        fullWidth
                    >
                        <MenuItem value="in-development">In Development</MenuItem>
                        <MenuItem value="in-qa">In QA</MenuItem>
                        <MenuItem value="in-qa-progress">In QA Progress</MenuItem>
                        <MenuItem value="in-qa-success">In QA Success</MenuItem>
                        <MenuItem value="in-qa-fail">In QA Fail</MenuItem>
                        <MenuItem value="in-voilation">In Voilation</MenuItem>
                        <MenuItem value="in-deprecation">In Deprecation</MenuItem>
                        <MenuItem value="verified">Verified</MenuItem>
                        <MenuItem value="published">Published</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateDailogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateSubmit} color="secondary" autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deviceDetailsOpen} onClose={handleDetailsClose}>
                <DialogTitle>Vertical Usecase Details</DialogTitle>
                <DialogContent sx={{ padding: "2rem" }}>
                    <Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Device Maker Id:</strong> {deviceDetails.devicemakerid}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Vertical Type:</strong> {deviceDetails.verticaltype}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Activated Vertical Id:</strong> {deviceDetails.activatedverticalid}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Solution Usecase Id:</strong> {deviceDetails.solutionusecaseid}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Solution Usecase:</strong> {deviceDetails.solutionusecase}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Version:</strong> {deviceDetails.version}
                    </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                        <strong>Status:</strong> {deviceDetails.status}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDetailsClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default VerticalUsecaseDeveloper
