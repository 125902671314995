import * as React from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CardContent from '@mui/material/CardContent';
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";
import Container from '@mui/material/Container';
import AuthAxiosInstance from '../../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance, EMSServcieAxiosInstance } from '../../../../authAxiosInstance'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const OTAManagementConfiguration = () => {
    const [modelList, setModelList] = React.useState([]);
    const [deviceType, setDeviceType] = React.useState([]);
    const [type, selectedType] = React.useState("");
    const [model, selectedModel] = React.useState("");
    const [imageType, setselectedType] = React.useState('');
    const [formData, setFormData] = React.useState({
        module: '',
        data: '',
    });
    const [errors, setErrors] = React.useState({});
    const [touchedFields, setTouchedFields] = React.useState({});
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    React.useEffect(() => {
        getdata();
        // fetchData();

    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleBlur = (event) => {
        const { name } = event.target;
        setTouchedFields({
            ...touchedFields,
            [name]: true,
        });

        if (name === 'module') {
            let error = '';
            if (formData.module) {
            } else {
                error = 'Required';
            }
            setErrors({
                ...errors,
                [name]: error,
            });
        }

        // Validate field on blur
        if (name === 'data') {
            let error = '';
            try {
                JSON.parse(formData.data);
            } catch (parseError) {
                error = 'Invalid JSON format';
            }
            setErrors({
                ...errors,
                [name]: error,
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setTouchedFields({
            module: true,
            data: true,
        });

        // Validate all fields on submit
        let validationErrors = {};
        if (!formData.module) {
            validationErrors.module = 'Module is required';
        }
        if (!formData.data) {
            validationErrors.data = 'Data is required';
        } else {
            try {
                JSON.parse(formData.data);
            } catch (parseError) {
                validationErrors.data = 'Invalid JSON format';
            }
        }

        // Update errors state with validation errors
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            // Form is valid, proceed with submission
            console.log(formData);
            if (model) {
                const rfq = {
                    "parentid": parentId,
                    "partnerid": username,
                    "solutionid": model,
                    "type": imageType,
                    "protocol": "FTP",
                    "version": "",
                    "module": formData.module,
                    "data": formData.data
                }
                console.log(rfq, "rfq");
                MainService.updateFirmwareAppConfig(username, rfq)
                    .then((res) => {
                        setFormData({ data: "", module: "" });
                        return NotificationManager.success("Configuration updated");
                    })
                    .catch((err) => {
                        setFormData({ data: "", module: "" });
                        return NotificationManager.error("Something went wrong");
                    });
            }
        }
    };


    const getdata = () => {
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/deviceTypes/list`).then((res) => {

            if (res.data)
                setDeviceType(res.data);
            else
                setDeviceType([]);
        }).catch((err) => {
            setDeviceType([]);
        });

    }
    const selectDevice = (evt) => {
        //const filterModelDevice = deviceList.filter((d) => (d.deviceType === evt.target.value));
        selectedType(evt.target.value);
        //setModelList(filterModelDevice);
        setModelList([]);
        selectedModel("");
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${evt.target.value}/models`).then((res) => {
            setModelList(res.data);
        }).catch((err) => {
            setModelList([]);
        });
    }

    const selectImageType = (evt) => {
        //const filterModelDevice = deviceList.filter((d) => (d.deviceType === evt.target.value));
        setselectedType(evt.target.value);
        //setModelList(filterModelDevice);
    }

    const selectModel = (evt) => {
        selectedModel(evt.target.value);
    }

    return (
        <div>
            <Grid container spacing={2} >
                <Grid item xs={12} md={3} sx={{ marginBottom: "1rem" }}>
                    <FormControl fullWidth>
                        <InputLabel size='small'>Partner Id </InputLabel>
                        <Select label="PartnerId" size="small" value={username} disabled >
                            <MenuItem value={username}>
                                {username}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} sx={{ marginBottom: "1rem" }}>
                    <FormControl fullWidth>
                        <InputLabel size='small'>Device Type</InputLabel>
                        <Select label="Device Type" size="small" value={type} onChange={selectDevice.bind(this)}>
                            {deviceType.map((d, i) => (
                                <MenuItem value={d} key={i + 1}>
                                    {d}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel size='small'>Device Model</InputLabel>
                        <Select label="Device Model" size="small" value={model} onChange={selectModel.bind(this)}>
                            {modelList.map((d, i) => (
                                <MenuItem value={d} key={i + 1}>
                                    {d}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} sx={{ marginBottom: "1rem" }}>
                    <FormControl fullWidth>
                        <InputLabel size='small'>Image Type</InputLabel>
                        <Select label="Image Type" size="small" value={imageType} onChange={selectImageType.bind(this)}>
                            <MenuItem disabled value="APP" >App</MenuItem>
                            <MenuItem disabled value="PATCH">Patch</MenuItem>
                            <MenuItem value="CONFIG" >Configuration</MenuItem>
                            <MenuItem disabled value="FIRMWARE" >Firmware</MenuItem>
                            <MenuItem disabled value="OS">OS</MenuItem>
                            <MenuItem disabled value="CORE" >Core</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {type && model && imageType &&
                <Grid>
                    <Card sx={{ padding: "0 1rem 1rem", boxShadow: '4px 5px 10px rgba(0, 0, 0, 0.5)'}}>
                        <CardContent>
                            <h3>Configuration Update</h3>
                            <Container>
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="module"
                                                label="Module"
                                                variant="outlined"
                                                fullWidth
                                                value={formData.module}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touchedFields.module && !!errors.module}
                                                helperText={touchedFields.module && errors.module}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="data"
                                                label="Data"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                minRows={4}
                                                value={formData.data}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touchedFields.data && !!errors.data}
                                                helperText={touchedFields.data && errors.data}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant='outlined'
                                                style={{ color: '#3ecf8e', borderColor: '#3ecf8e', height: '2.5rem', marginLeft: "5px" }}
                                            >
                                                Update Configuration
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Container>
                        </CardContent>

                    </Card>

                </Grid>
            }

        </div>
    )
}

export default OTAManagementConfiguration