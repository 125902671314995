import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  ListItem,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InvoiceTable from '../components/InvoiceTable';
import CommanTable from '../components/CommanTable';
import { formattedValue, uniqueNumber } from '../utils/genrateUniqueNumber';
import UserForm from '../components/UserForm';
import InvoiceItemForm from '../components/InvoiceItemForm';
import ItemDataTable from '../components/ItemDataTable';
import MainService from '../../../Common/MainService';
import { componentDetailsContext } from './SellerAccountManagement';
import LoadingPage from '../../loadingpage/LoadingPage';
import { formatDate } from '../../../../../utils/formatTime';
import dayjs from 'dayjs';

const tempDiliveryChallanListDetials = [
  { id: 'CahllanNo', label: 'Challan Number' },
  { id: 'buyerName', label: 'Buyer Name' },
  { id: 'date', label: 'Date' },
  { id: 'status', label: 'Status' },
  { id: 'viewLink', label: 'View Link', type: 'button' },
];

const tempChallanDetailsColumn = [
  { id: 'logo', label: 'Logo', type: 'logo' },
  { id: 'deliveryChallanNo', label: 'Challan Number' },
  { id: 'invNo', label: 'Invoice Number' },
  { id: 'invIssueDate', label: 'Invoice Date', type: 'date' },
  { id: 'deliveryChallanState', label: 'State Name' },
  { id: 'deliveryChallanStateCode', label: 'State Code' },
];

const tempBuyerData = {
  supplierName: '',
  sup_gstin: '',
  sup_panNo: '',
  sup_email: '',
  supplierContact: '',
  supplierAdressline1: '',
  supplierAdressline2: '',
  supplierAdressline3: '',
  supplierState: '',
  supplierStateCode: '',
};

const tempSupplier = {
  buyerName: '',
  buyer_cell: {
    buyer_cell1: '',
    buyer_cell2: '',
  },
  buyer_email: '',
  buyer_website: '',
  buyer_panNo: '',
  buyer_gstin: '',
  buyerAdressline1: '',
  buyerAdressline2: '',
  buyerAdressline3: '',
};

const tempTranportDetailsLabel = [
  { id: 'transporter', label: 'Transporter' },
  { id: 'vehicleType', label: 'Vehicle Type' },
  { id: 'vehicleNo', label: 'Vehicle No' },
];

const tempTranportDetailsLabelextra = [
  { id: 'transporterId', label: 'Transporter Id' },
  { id: 'lrNo', label: 'L.R. No' },
  { id: 'eWayNo', label: 'E-way No' },
];

const tempTranportDetails = [
  {
    transporter: '',
    vehicleType: '',
    vehicleNo: '',
    transporterId: '',
    lrNo: '',
    eWayNo: '',
  },
];

const tempTermConditionLabel = [{ id: 'term', label: 'Terms & Conditions:' }];
const tempTermConditionValue = [];

const DeliveryChallan = ({ proxyId = '' }) => {
  const { invoiceTypeDetails, setInvoiceTypeDetails, buttonStatusHandler } =
    useContext(componentDetailsContext);

  const [challanList, setChallanList] = useState([]);
  const [activeListContent, setActiveListContent] = useState('challanlist');
  const [imageFile, setImageFile] = useState({});
  const [tempImage, setTempImage] = useState({});
  const [buyerDetails, setBuyerDetails] = useState(tempBuyerData);
  const [supplierDetails, setSupplierDetails] = useState(tempSupplier);
  const [transportDetails, setTransportDetails] = useState(tempTranportDetails);

  const [challanDetails, setChallanDetails] = useState([
    {
      deliveryChallanNo: '',
      invNo: 'Inv123453',
      deliveryChallanDate: formattedValue(),
      invIssueDate: '2023-06-15',
      deliveryChallanState: 'West Bengal',
      deliveryChallanStateCode: '14',
    },
  ]);
  const [invoiceItem, setInvoiceItem] = useState({
    description: '',
    hsn_sac: '',
    quantity: '',
    unit: '',
    price_per_unit: '',
    totalGst: {
      sGst: 0,
      cGst: 0,
      iGst: 0,
    },
  });
  const [invoiceItemDataList, setiInvoiceItemDataList] = useState([]);
  const [addInvoiceItemError, setAddInvoiceItemError] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [contentdescription, setContentdescription] = useState('');
  const [termsConditions, setTermsConditions] = useState(
    tempTermConditionValue
  );
  const [showAlert, setShowAlert] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);

  const [isDocumentDetailsLoaded, setIsDocumentDetailsLoaded] = useState(true);
  const [documentDetailsError, setDocumentDetailsError] = useState('');
  const [documentStatus, setDocumentStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [orderIdProxy, setOrderIdProxy] = useState('');
  const [imageSize, setImageSize] = useState(false);
  const [validImageFormat, setValidImageFormat] = useState(false);

  const {
    user: { parentId, username, orgid, suborgid },
  } = MainService.getLocalData();

  const todayDate = new Date();
  const docGeneratedDate = dayjs(todayDate).format('YYYY-MM-DD');

  useEffect(() => {
    const challanNo = 'DC-' + uniqueNumber();
    setChallanDetails([
      {
        deliveryChallanNo: challanNo,
        invNo: '',
        deliveryChallanDate: formattedValue(),
        invIssueDate: '',
        deliveryChallanState: '',
        deliveryChallanStateCode: '',
      },
    ]);
    setOrderIdProxy(proxyId);
    return () => {
      setChallanDetails([]);
    };
  }, [activeListContent]);

  //image file change handler
  const imageFileChangeHandler = (event, columnId) => {
    const { files } = event.target;

    Array.from(files).forEach((file) => {
      if (
        file.size > 1024 * 1024 ||
        !['image/jpeg', 'image/png', 'image/gif'].includes(file.type)
      ) {
        setImageSize(file.size > 1024 * 1024);
        setValidImageFormat(
          !['image/jpeg', 'image/png', 'image/gif'].includes(file.type)
        );
        setImageFile((prevData) => {
          const updatedData = { ...prevData };
          delete updatedData[columnId];
          return updatedData;
        });
        setTempImage((prevData) => {
          const updatedData = { ...prevData };
          updatedData[columnId] = '';
          return updatedData;
        });
        event.target.value = '';
        return;
      }

      setImageFile((prevData) => ({
        ...prevData,
        [columnId]: file,
      }));

      const reader = new FileReader();
      reader.onload = () => {
        setTempImage((prevData) => ({
          ...prevData,
          [columnId]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    });
  };

  const imageChangeHandler = (columnId) => {
    const fileInput = document.getElementById(columnId);
    fileInput.click();
  };

  //challan details change handler
  const challanDetailsChangeHandler = (index, field, value) => {
    setChallanDetails((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  // user form input change handler
  const formUserInputChangeHandler = (event, setFormDataInput) => {
    const { name, value } = event.target;
    setFormDataInput((formDataInput) => {
      if (name === 'buyer_cell1' || name === 'buyer_cell2') {
        return {
          ...formDataInput,
          buyer_cell: {
            ...formDataInput.buyer_cell,
            [name]: value,
          },
        };
      } else {
        return {
          ...formDataInput,
          [name]: value,
        };
      }
    });
  };

  //Description change Handler
  const descriptionItemChangeHandler = (
    index,
    field,
    value,
    setDescription
  ) => {
    setDescription((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  // add invoice item
  const invoiceItemDetailsChangeHandler = (event) => {
    const { name, value } = event.target;
    let updatedItem;
    if (name.includes('totalGst.')) {
      const gstName = name.split('.')[1];

      if (gstName === 'cGst' || gstName === 'sGst') {
        updatedItem = {
          ...invoiceItem,
          totalGst: {
            ...invoiceItem.totalGst,
            [gstName]: value !== '' ? parseFloat(value) : '',
            iGst: 0,
          },
        };
      } else {
        updatedItem = {
          ...invoiceItem,
          totalGst: {
            ...invoiceItem.totalGst,
            iGst: value !== '' ? parseFloat(value) : '',
            cGst: 0,
            sGst: 0,
          },
        };
      }
    } else {
      updatedItem = {
        ...invoiceItem,
        [name]:
          name === 'quantity' || name === 'price_per_unit'
            ? parseFloat(value)
            : value,
      };
    }
    setInvoiceItem(updatedItem);
  };

  const addInvoiceItemHandler = () => {
    const isObjectEmpty = (obj) =>
      Object.values(obj).some((value) => value === '');
    const isInvoiceItemEmpty = isObjectEmpty(invoiceItem);

    if (isInvoiceItemEmpty) {
      setAddInvoiceItemError(true);
      return;
    }
    setiInvoiceItemDataList([...invoiceItemDataList, invoiceItem]);
  };

  // delete invoice item
  const deleteItemHandler = (row, index) => {
    setiInvoiceItemDataList((prevDtata) => {
      const newDetails = [...prevDtata];
      newDetails.splice(index, 1);
      return newDetails;
    });
  };

  // terms and condition add
  const addTermConditionHandler = () => {
    const data = {
      term: '',
    };
    setTermsConditions([...termsConditions, data]);
  };

  const termsConditionsChangeHandler = (index, field, value) => {
    setTermsConditions((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  const deleteTerrmHandler = (
    index,
    itemDetails,
    setItemDetails,
    isDescriptionItem
  ) => {
    if (itemDetails.length === 1 && isDescriptionItem) {
      return;
    }
    setItemDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails.splice(index, 1);
      return newDetails;
    });
  };

  const activeContentSetHandler = (contentName) => {
    setActiveListContent(contentName);
  };

  // generate document contruction
  const documentFormDataConstructed = () => {
    console.log('save challan');

    const deliveryChallanDataDetails = {
      supplierDetails: {
        supplierName: supplierDetails.buyerName,
        sup_cell: {
          sup_cell1: supplierDetails.buyer_cell.buyer_cell1,
          sup_cell2: supplierDetails.buyer_cell.buyer_cell2,
        },
        sup_email: supplierDetails.buyer_email,
        sup_gstin: supplierDetails.buyer_gstin,
        sup_panNo: supplierDetails.buyer_panNo,
        supplieradressline1: supplierDetails.buyerAdressline1,
        supplieradressline2: supplierDetails.buyerAdressline2,
        supplieradressline3: supplierDetails.buyerAdressline3,
        sup_website: supplierDetails.buyer_website,
      },
      receiverDetails: {
        receiverName: buyerDetails.supplierName,
        receiverGstin: buyerDetails.sup_gstin,
        receiverPanNo: buyerDetails.sup_panNo,
        receiverEmail: buyerDetails.sup_email,
        receiverState: challanDetails[0].deliveryChallanState,
        receiverContactNo: buyerDetails.supplierContact,
        receiverAdressline1: buyerDetails.supplierAdressline1,
        receiverAdressline2: buyerDetails.supplierAdressline2,
        receiverAdressline3: buyerDetails.supplierAdressline3,
      },
      deliveryChallanDetails: challanDetails[0],
      transportDetails: transportDetails[0],
      stateToShipDetails: {
        stateToshipName: challanDetails[0].deliveryChallanState,
        stateToshipCode: challanDetails[0].deliveryChallanStateCode,
      },
      isGstPayable: false,
      discPercent: discount,
      items: invoiceItemDataList,
      termsConditions: [...termsConditions.map((obj) => obj.term)],
    };

    const data = {
      docId: challanDetails[0].deliveryChallanNo,
      networkId: window.AppConfigData.EnterpriseId,
      parentId: parentId,
      orgId: orgid,
      proxyId: proxyId,
      docCreatorId: username,
      type: 'deliveryChallan',
      docCreatorName: username,
      docCreateDate: challanDetails[0].deliveryChallanDate,
      docReceiverName: buyerDetails.supplierName,
      status: 'created',
      deliveryChallanDataDetails,
    };

    const contentInfo = {
      contentname: challanDetails[0].deliveryChallanNo,
      contentcreator: username,
      contentownerid: username,
      contentdescription: contentdescription,
      expirydate: challanDetails[0].deliveryChallanDate,
      assetdeveloperid: window.AppConfigData.EnterpriseId,
    };

    const generatedDateInfo = {
      docGeneratedDate: docGeneratedDate,
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(deliveryChallanDataDetails));
    formData.append('contentInfo', JSON.stringify(contentInfo));
    formData.append('generatedDateInfo', JSON.stringify(generatedDateInfo));
    formData.append('deliveryChallanLogo', imageFile.logo || '');
    formData.append('sup_sign_Img', imageFile.sign || '');
    return { data, formData };
  };

  // save challan
  const saveChallanHandler = async ({ data }) => {
    console.log('click', data);
    if (Object.keys(data).length === 0) {
      return;
    }
    setIsDocumentDetailsLoaded(false);
    try {
      const response = await MainService.saveDocumentsApi(
        parentId,
        orgid,
        suborgid,
        username,
        data
      );
      if (response?.status === 201) {
        console.log('respo', response);
        const formattedDate = formatDate(
          new Date(response.data.docDetails.docCreateDate),
          'dd-MM-yyyy'
        );

        const tempProformaInvoiceList = {
          viewLink: response.data.docDetails.LocationUrl,
          CahllanNo: challanDetails[0].invNo,
          buyerName: buyerDetails.supplierName,
          date: formattedDate,
          status: response.data.docDetails.status,
        };
        setChallanList((prevList) => [...prevList, tempProformaInvoiceList]);
        setDocumentDetailsError('Delivery challan submitted.');
        setTimeout(() => {
          cancelButtonHandler();
        }, 300);
        setActiveListContent('challanlist');

        return response;
      } else {
        console.log('error in document response', response);
        // setErrorMesg(response);
        setDocumentDetailsError(response);
      }

      setIsDocumentDetailsLoaded(true);
      console.log('response', response);
      return response;
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(
          'Network connection issue. Please check your internet connection.'
        );
      }
      console.log('error', error.message);
      return error.message;
    } finally {
      setIsDocumentDetailsLoaded(true);
    }
  };

  const fetchGeneratedDocument = async (formData, type) => {
    const response = await MainService.generateDocumentsApi(
      parentId,
      orgid,
      suborgid,
      username,
      formData,
      type
    );

    if (response?.status === 200) {
      const tempPurchaseList = {
        viewLink: response.data.LocationUrl,
        status: response.data.status,
        // orderId: purchaseOrderDetails.purchaseOrderID,
        CahllanNo: response.data.docId,
        buyerName: buyerDetails.supplierName,
        date: response.data.docGeneratedDate,
      };

      setChallanList((prevList) =>
        prevList.map((item) =>
          item.CahllanNo === tempPurchaseList.CahllanNo
            ? tempPurchaseList
            : item
        )
      );
      setDocumentDetailsError('Purchase order generated.');
      handleDownload(response.data.LocationUrl, response.data.orderId);
      setTimeout(() => {
        cancelButtonHandler();
      }, 300);
    } else {
      setDocumentDetailsError(response);
    }
    setIsDocumentDetailsLoaded(true);
    console.log('already created', response);
  };
  // generate purchase order
  const generateDocumentHandler = async ({ data, formData }) => {
    console.log('click', data, formData);
    if (Object.keys(data).length === 0) {
      return;
    }
    setIsDocumentDetailsLoaded(false);
    console.log('document status', documentStatus);
    try {
      if (data.status === 'created') {
        console.log('already created');
        const response = await fetchGeneratedDocument(formData, data.type);
      } else {
        console.log('need to create');
        // setDocumentStatus('created');
        const saveResponse = await saveChallanHandler({ data });
        console.log('saveResponse', saveResponse);
        if (saveResponse?.status === 201) {
          setIsDocumentDetailsLoaded(false);
          const response = await fetchGeneratedDocument(formData, data.type);
          console.log('response genrate', response);
        }
      }
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(
          'Network connection issue. Please check your internet connection.'
        );
        setDocumentDetailsError(
          'Network connection issue. Please check your internet connection.'
        );
      }
      console.log('error', error.message);
      setDocumentDetailsError(error.message);
    } finally {
      setIsDocumentDetailsLoaded(true);
    }
  };

  // download file
  const handleDownload = (url, contenId) => {
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', contenId);

      // Open the new tab
      const newTab = window.open('', '_blank');
      if (newTab) {
        newTab.document.body.appendChild(link);
        // Trigger the download
        link.click();
      } else {
        // Handle case where new tab couldn't be opened
        console.error('Failed to open new tab');
      }
    }, 100);
  };

  const cancelButtonHandler = () => {
    setActiveListContent('challanlist');
    setImageFile({});
    setTempImage({});
    setBuyerDetails(tempBuyerData);
    setSupplierDetails(tempSupplier);
    setTransportDetails(tempTranportDetails);
    setChallanDetails([
      {
        deliveryChallanNo: '',
        invNo: 'Inv123453',
        deliveryChallanDate: formattedValue(),
        invIssueDate: '2023-06-15',
        deliveryChallanState: 'West Bengal',
        deliveryChallanStateCode: '14',
      },
    ]);
    setInvoiceItem({
      description: '',
      hsn_sac: '',
      quantity: '',
      unit: '',
      price_per_unit: '',
      totalGst: {
        sGst: 0,
        cGst: 0,
        iGst: 0,
      },
    });
    setiInvoiceItemDataList([]);
    setAddInvoiceItemError(false);
    setDiscount(0);
    setSaveButtonStatus(false);
    setContentdescription('');
    setTermsConditions(tempTermConditionValue);
    setShowAlert(false);
    setFormSubmitError(false);
    setInvoiceTypeDetails({});
  };

  // fetching the data based on the id
  // fetching data from server based on document id

  const fetchDocumentDetails = async (docId) => {
    setIsDocumentDetailsLoaded(false);
    try {
      const response = await MainService.documentDetailsApi(
        parentId,
        orgid,
        suborgid,
        username,
        docId
      );
      if (!response) {
        console.log('error');
        setDocumentDetailsError(
          'Document details not found, try refreshing the page.'
        );
      }
      console.log('response of challan', response);
      if (response?.status === 200) {
        const {
          supplierDetails,
          receiverDetails,
          deliveryChallanDetails,
          transportDetails,
          stateToShipDetails,
          isGstPayable,
          discPercent,
          items,
          termsConditions,
        } = response.data.doc.deliveryChallanDataDetails || {};
        const transformedArray = termsConditions?.map((item) => ({
          term: item,
        }));
        setBuyerDetails({
          supplierName: receiverDetails.receiverName,
          sup_gstin: receiverDetails.receiverGstin,
          sup_panNo: receiverDetails.receiverPanNo,
          sup_email: receiverDetails.receiverEmail,
          supplierContact: receiverDetails.receiverContactNo,
          supplierAdressline1: receiverDetails.receiverAdressline1,
          supplierAdressline2: receiverDetails.receiverAdressline2,
          supplierAdressline3: receiverDetails.receiverAdressline3,
          supplierState: receiverDetails.receiverState,
          supplierStateCode: stateToShipDetails.stateToshipCode,
        });
        setSupplierDetails({
          buyerName: supplierDetails.supplierName,
          buyer_cell: {
            buyer_cell1: supplierDetails.sup_cell.sup_cell1,
            buyer_cell2: supplierDetails.sup_cell2,
          },
          buyer_email: supplierDetails.sup_email,
          buyer_website: supplierDetails.sup_website,
          buyer_panNo: supplierDetails.sup_panNo,
          buyer_gstin: supplierDetails.sup_gstin,
          buyerAdressline1: supplierDetails.supplieradressline1,
          buyerAdressline2: supplierDetails.supplieradressline2,
          buyerAdressline3: supplierDetails.supplieradressline3,
        });
        setTermsConditions(transformedArray);
        setChallanDetails([deliveryChallanDetails]);

        setTransportDetails([transportDetails]);
        setiInvoiceItemDataList(items);
      }
    } finally {
      setIsDocumentDetailsLoaded(true);
    }
  };

  const getDocumentDetailsHander = useCallback(async ({ CahllanNo }) => {
    // setActiveListContent('addchallan');
    console.log('click', CahllanNo);

    // console.log(' setActiveListContent(', challanNo, invoiceTypeDetails);
    // if (invoiceTypeDetails.invNo) {
    //   setActiveListContent('addchallan');
    //   // setDocumentStatus(challanStatus);
    // }
    setActiveListContent('addchallan');
    await fetchDocumentDetails(CahllanNo);
  }, []);

  // useEffect(() => {
  //   getDocumentDetailsHander();
  // }, [getDocumentDetailsHander]);

  // Fetch invoice list and handle loading and errors
  const fetchChallanList = useCallback(async () => {
    setIsLoading(true);
    setDocumentDetailsError('');
    try {
      const response = await MainService.documentListApi(
        parentId,
        orgid,
        suborgid,
        username,
        'deliveryChallan'
      );

      if (response && response.data && response.data.docs) {
        const initialData = response.data.docs.map((item) => ({
          CahllanNo: item.docId,
          buyerName: item.docReceiverName,
          date: formatDate(item.createdAt),
          status: item.status,
          viewLink: item.LocationUrl,
        }));
        setChallanList(initialData);
      }
    } catch (error) {
      console.error('Error fetching invoice list:', error);
      setDocumentDetailsError(
        'An error occurred while fetching the invoice list.'
      );
    } finally {
      setIsLoading(false);
    }
  }, [parentId, orgid, suborgid, username]);

  useEffect(() => {
    fetchChallanList();
    return () => {
      setChallanList([]);
    };
  }, [fetchChallanList]);

  // snackbar action handler
  const snackbarActionHandler = (
    <React.Fragment>
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={() => setDocumentDetailsError('')}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </React.Fragment>
  );

  console.log('challan list list ))))))))', challanList);

  return (
    <>
      {activeListContent === 'challanlist' && (
        <Box>
          <Typography
            sx={{
              fontWeight: '800',
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
              textAlign: 'center',
              mb: '0.5rem',
              color: '#1976d2',
            }}
          >
            Delivery Challan Management
          </Typography>
          <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          <InvoiceTable
            columnData={tempDiliveryChallanListDetials}
            rowDataList={challanList}
            documentDetailsHander={getDocumentDetailsHander}
          />
          <Stack>
            <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={() => activeContentSetHandler('addchallan')}>
                Add Delivery Challan
              </Button>
            </ListItem>
          </Stack>
        </Box>
      )}
      {activeListContent === 'addchallan' && (
        <Box sx={{ p: { xs: '1rem 0rem', md: '0' } }}>
          <Box>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
                textAlign: 'center',
                mb: '0.5rem',
                color: '#1976d2',
              }}
            >
              Delivery Challan
            </Typography>
            <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          </Box>
          <Box>
            <Box>
              <CommanTable
                columnsData={tempChallanDetailsColumn}
                rowsData={challanDetails}
                image={tempImage}
                imageChangeHandler={imageChangeHandler}
                imageFileChangeHandler={imageFileChangeHandler}
                inputFieldChangeHandler={challanDetailsChangeHandler}
                saveButtonStatus={saveButtonStatus}
              />
            </Box>

            <Box
              component='form'
              sx={{
                // background: 'lightblue',
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                p: '1rem',
                // width: { xs: '100%', md: '70%', lg: '50%' },
                mx: 'auto',
                mb: '1rem',
                borderRadius: '0.5rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  '& > * ': { background: '#fff', alignItems: 'center' },
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: '1.5rem',
                }}
              >
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  Challan Description
                </Typography>

                <TextField
                  error={saveButtonStatus && contentdescription.length === 0}
                  id='contentdescription'
                  label='Please enter challan description'
                  variant='standard'
                  sx={{ display: 'flex', flex: 1, '&>*': { width: '100%' } }}
                  onChange={(event) =>
                    setContentdescription(event.target.value)
                  }
                />
              </Box>
            </Box>
            <Box>
              <UserForm
                saveButtonStatus={saveButtonStatus}
                buyerDetails={supplierDetails}
                supplierDetails={buyerDetails}
                userInputBuyerChangehandler={(event) =>
                  formUserInputChangeHandler(event, setSupplierDetails)
                }
                userInputSupplierChangehandler={(event) =>
                  formUserInputChangeHandler(event, setBuyerDetails)
                }
                invoice={true}
              />
            </Box>
            <Box>
              <CommanTable
                columnsData={tempTranportDetailsLabel}
                rowsData={transportDetails}
                inputFieldChangeHandler={(index, field, value) =>
                  descriptionItemChangeHandler(
                    index,
                    field,
                    value,
                    setTransportDetails
                  )
                }
                saveButtonStatus={saveButtonStatus}
              />
            </Box>
            <Box>
              <CommanTable
                columnsData={tempTranportDetailsLabelextra}
                rowsData={transportDetails}
                inputFieldChangeHandler={(index, field, value) =>
                  descriptionItemChangeHandler(
                    index,
                    field,
                    value,
                    setTransportDetails
                  )
                }
                saveButtonStatus={saveButtonStatus}
              />
            </Box>
            <Box>
              <InvoiceItemForm
                invoiceItem={invoiceItem}
                invoiceItemDetailsChangeHandler={
                  invoiceItemDetailsChangeHandler
                }
                addInvoiceItemHandler={addInvoiceItemHandler}
                addInvoiceItemError={addInvoiceItemError}
                saveButtonStatus={saveButtonStatus}
                invoiceType={true}
              />
              <ItemDataTable
                discount={discount}
                invoiceItemDataList={invoiceItemDataList}
                deleteItemHandler={deleteItemHandler}
                setDiscount={setDiscount}
              />
            </Box>

            <Box sx={{ display: 'flex', gap: '1rem', '&>*': { flex: 1 } }}>
              <Box sx={{ flex: 2 }}>
                <CommanTable
                  columnsData={tempTermConditionLabel}
                  rowsData={termsConditions}
                  tableType={'editable'}
                  addItemHandler={addTermConditionHandler}
                  inputFieldChangeHandler={termsConditionsChangeHandler}
                  deleteItemHandler={(rowData, index) =>
                    deleteTerrmHandler(
                      index,
                      termsConditions,
                      setTermsConditions,
                      false
                    )
                  }
                  saveButtonStatus={saveButtonStatus}
                />
              </Box>
              <Box>
                <Box
                  component='form'
                  sx={{
                    padding: '1rem',
                    borderRadius: '1rem',
                    boxShadow:
                      'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                    mx: 'auto',
                    '& > *:not(:last-child)': { paddingBottom: '0.5rem' },
                    background: '#F6F4F3',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '1rem',
                      alignItems: 'center',
                      flexDirection: { xs: 'column', md: 'row' },
                      '&>*': {
                        height: '10rem',
                        borderRadius: '0.5rem',
                        overflow: 'hidden',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        border: '1px solid',
                        flex: 1,
                      }}
                    >
                      {tempImage['sign'] && (
                        <img
                          src={tempImage.sign}
                          style={{ width: '100%', height: '100%' }}
                          onClick={() => imageChangeHandler('sign')}
                        />
                      )}
                      {!tempImage['sign'] && (
                        <label
                          style={{
                            display: 'flex',
                            padding: '1rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                            cursor: 'pointer',
                            textAlign: 'center',
                          }}
                          htmlFor='sign'
                        >
                          Please choose signature
                        </label>
                      )}

                      <input
                        id='sign'
                        name='sign'
                        type='file'
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                          display: 'none',
                        }}
                        onChange={(event) =>
                          imageFileChangeHandler(event, 'sign')
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            {showAlert && (
              <Alert severity='success' sx={{ mt: '0.5rem' }}>
                Delivery Challan successfully submitted.
              </Alert>
            )}
            {formSubmitError && (
              <Alert severity='error' sx={{ mt: '0.5rem' }}>
                Please fill the required field.
              </Alert>
            )}

            <Box>
              <Stack>
                <ListItem sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={cancelButtonHandler}>Cancel</Button>

                  <Button
                    onClick={() =>
                      saveChallanHandler(documentFormDataConstructed())
                    }
                  >
                    Save
                  </Button>

                  <Button
                    onClick={() =>
                      generateDocumentHandler(documentFormDataConstructed())
                    }
                  >
                    Generate
                  </Button>
                </ListItem>
              </Stack>
            </Box>
          </Box>
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={documentDetailsError ? true : false}
        autoHideDuration={5000}
        onClose={() => setDocumentDetailsError('')}
        message={documentDetailsError}
        action={snackbarActionHandler}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={imageSize}
        autoHideDuration={2000}
        onClose={() => setImageSize(false)}
        message={'image size is too large, please select below 1 mb.'}
        // action={snackbarActionHandler}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={validImageFormat}
        autoHideDuration={2000}
        onClose={() => setValidImageFormat(false)}
        message={
          ' Invalid image format. Please select a JPEG, PNG, or GIF image.'
        }
      />
      {!isDocumentDetailsLoaded && <LoadingPage />}
    </>
  );
};

export default DeliveryChallan;
