import React ,{useState, useEffect} from 'react'
import { Box, Stack, TextField, Typography , Paper, Button, Select,MenuItem,FormControl, InputLabel,Snackbar, styled} from '@mui/material'
import axios from 'axios';
import ContractFarmingComponent from '../ContractFarming/ContractFarmingComponent';
import MainService from '../../Common/MainService';
import { NotificationManager } from "react-notifications";
import Main from '../../Common/MainService';


const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
}));

const AddOrder = ({optType, listing} ) => {
  const [requestType, setRequestType] = useState("" || optType);
  const [idDisabled, setIdDisabled] = useState(false);
  const [dateDisabled, setDateDisabled] = useState(false);
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [data, setData] = useState([]);
  const {user} = MainService.getLocalData();
  const {parentId, username} = user;
  const [userTypeValue, setUserTypeValue] = useState("");
  
  const [orderData, setOrderData] = useState({
    networkId: '',
    parentId: '',
    username: '',
    buyerName: '',
    city: '',
    requestType: requestType,
    personalDetails: {
      phone: '',
      username: '',
      email: '',
      city: '',
      state: '',
      address: ''
    },
    DeliveryAddress: {
      siteId: '',
      siteName: '',
      Street: '',
      city: '',
      pincode: '',
      country: ''
    },
    supplierDetails: {
      siteId: '',
      siteName: '',
      Street: '',
      City: '',
      pincode: '',
      fromLocationLat: '',
      toLocationlgn: ''
    },
    pickUp_Address: {
      siteId: '',
      siteName: '',
      Street: '',
      City: '',
      pincode: '',
      country: '',
      pickupLocationLat: '',
      pickupLocationLng: ''
    },
    productItemDetails: {
      category: '',
      legacyAssetType: '',
      modelId: '',
      cn: '',
      labelIntructions: '',
      Origin: '',
      assetVerticalId: '',
      assetVerticalFriendlyName: ''
    },
    productDetails: {
      productCategory: '',
      productSubCategory: '',
      productModelId: '',
      cn: '',
      hsn: '',
      labelIntructions: '',
      Origin: '',
      quantity: '',
      price: '',
      discount: '',
      totalDiscountablePrice: ''
    },
    finalPrice: '',
    assetPackage: '',
    PaymentDetails: {
      transactionId: '',
      'Payment time & date': '',
      'Payment Status': '',
      'Payment mode': '',
      'Payment type': '',
      'Terms Of Delivery': ''
    },
    deliveryType: '',
    transport: '',
    supplierAssetDeveloper: '',
    supplierProductionManager: '',
    certificationLink: '',
    qualityLink: '',
    requiredDocumentLink: '',
    provenanceLink: '',
    fromDate: '',
    toDate: '',
    status: '',
    timeStamp: ''
  });
 
  // const getUserType = () => {
  //   Main.getConsumerUserType(parentId, username)
  //     .then((data) => {
  //       localStorage.setItem("userType", data);
  //       setUserTypeValue(data);
  //     })
  //     .catch((e) => {
  //       return NotificationManager.error(e);
  //     });
  // };
  const getUserType = () => {
    return new Promise((resolve, reject) => {
      Main.getConsumerUserType(parentId, username)
        .then((data) => {
          localStorage.setItem("userType", data);
          setUserTypeValue(data);
          console.log('User type set:', data);
          resolve(); // Resolve the Promise when user type is set
        })
        .catch((e) => {
          reject(e); // Reject the Promise on error
          NotificationManager.error(e);
        });
    });
  };
  const createNewOrder = () =>{
   
    MainService.createOrder(requestType,orderData)
     .then((response) => {
     setData(response);
     console.log('orderrrrrrrrrrrrr',response);
     })
     .catch((error)=> {
       return NotificationManager.error(error);
     })  
     }

  const handleSnackbarClose = () => {
    setSuccessMessageOpen(false);
  };

  return (
        <div>
      <FormControl sx={{ m: 1, minWidth: 180 }}>
        <InputLabel >Request Type</InputLabel>
        <Select
         variant='standard'
          value={requestType}
          onChange={(e) => {
            setRequestType(e.target.value);
            setIdDisabled(e.target.value === 'contractFarming' || e.target.value === 'contractManufacturing' || e.target.value === 'buyer' || e.target.value === 'assetDistributorBuyer');
            setDateDisabled( e.target.value === 'buyer'|| e.target.value === 'assetDistributorBuyer');
          }}
        >
          <MenuItem value = ""><em></em></MenuItem>
         {(listing === "CF" || listing === "all" ) && <MenuItem value="contractFarming">Contract Farming </MenuItem> }
         {(listing ==="CM" ||  listing === "all")  &&  <MenuItem value="contractManufacturing">Contract Manufacturing </MenuItem> }
          {(listing ==="deli" ||  listing === "all")  && <MenuItem value="delivery">delivery</MenuItem>}
          {(listing ==="buy" ||  listing === "all")  && <MenuItem value="buyer">buyer</MenuItem> }
          {(listing ==="distributor" ||  listing === "all")  &&  <MenuItem value="assetDistributorBuyer">asset distributor buyer</MenuItem> }
        </Select>
      </FormControl>

       <Button
            variant="contained"
            color="success"
            size="large"
            sx={{ position: "fixed"  , top: "100px", right: "40px", }}
            onClick={createNewOrder}
          >
            Submit
          </Button>
          <Snackbar
        open={successMessageOpen}
        autoHideDuration={4000} // Duration in milliseconds
        onClose={handleSnackbarClose}
        message="Order created successfully!"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        style={{
          marginTop: '60px' 
        }}
      />
         
      <Typography variant="h4" align='center'>Create Order</Typography>

      <Box mt={4}>
    <Stack spacing={4}>
        <Stack direction='row' spacing={2}>
        
      <TextField
        label="networkId"
        variant='standard'
        value={orderData.networkId}
        onChange={e => setOrderData({ ...orderData, networkId: e.target.value})}
      />
      <TextField
        label="parentId"
        variant='standard'
       
      />
      <TextField
        label="username"
        variant='standard'
      />
      <TextField
        label="buyerName"
        variant='standard'
      />
      <TextField
        label="city"
        variant='standard'
      />
        </Stack>
      </Stack> 
    </Box>
    <Paper elevation={3} variant="elevation" sx={{ p: 3, mt: 4 }}>

    {/* Personal Details  */}
    <Typography variant="h5">Personal Information</Typography>
    <Box mt={4}>
      <Stack spacing={4}>
      <Stack direction='row' spacing={2}>
      <TextField
        label="phone"
        variant='outlined'
      />
      <TextField
        label="username"
        variant='outlined'
      />
      <TextField
        label="email"
        variant='outlined'
      />
      <TextField
        label="city"
        variant='outlined'
      />
      <TextField
        label="state"
        variant='outlined'
      />
      <TextField
        label="address"
        variant='outlined'
      />
      </Stack>
        </Stack>
        </Box>
            </Paper>
{/* Delivery Details  */}
     <Paper elevation={3} variant="elevation" sx={{ p: 3, mt: 4 }}>
    <Typography variant="h5">Delivery Address</Typography>
    <Box mt={4}>
      <Stack spacing={4}>
      <Stack direction='row' spacing={2}>
      <TextField
        label="siteId"
        variant='outlined'
      />
      <TextField
        label="siteName"
        variant='outlined'
      />
      <TextField
        label="Street"
        variant='outlined'
      />
      <TextField
        label="city"
        variant='outlined'
      />
      <TextField
        label="pincode"
        variant='outlined'
      />
      <TextField
        label="country"
        variant='outlined'
      />
      </Stack>
        </Stack>
        </Box>
            </Paper>

    {/* supplier Details */}
        <Paper elevation={3} variant="elevation" sx={{ p: 3, mt: 4 }}>
    <Typography variant="h5">Supplier Address</Typography>
    <Box mt={4}>
      <Stack spacing={4}>
      <Stack direction='row' spacing={2}>
      <TextField
        label="siteId"
        variant='outlined'
      />
      <TextField
        label="siteName"
        variant='outlined'
      />
      <TextField
        label="Street"
        variant='outlined'
      />
      <TextField
        label="city"
        variant='outlined'
      />
      </Stack>
       
      <Stack direction='row' spacing={2}>
      <TextField
        label="pincode"
        variant='outlined'
      />
      <TextField
        label="fromLocationLat"
        variant='outlined'
      />
      <TextField
        label="toLocationLat"
        variant='outlined'
      />
      </Stack>
        </Stack>
        </Box>
            </Paper>

             {/* Pick Up Address */}

         <Paper elevation={3} variant="elevation" sx={{ p: 3, mt: 4 }}>
      <Typography variant="h5">PickUp Address</Typography>
    <Box mt={4}>
      <Stack spacing={4}>
      <Stack direction='row' spacing={2}>
      <TextField
        label="siteId"
        variant='outlined'
      />
      <TextField
        label="siteName"
        variant='outlined'
      />
      <TextField
        label="Street"
        variant='outlined'
      />
      <TextField
        label="city"
        variant='outlined'
      />

      </Stack>
      <Stack direction='row' spacing={2}>
      <TextField
        label="pincode"
        variant='outlined'
      />
      <TextField
        label="country"
        variant='outlined'
      />
      <TextField
        label="pickupLocationLat"
        variant='outlined'
      />
      <TextField
        label="pickupLocationLng"
        variant='outlined'
      />
      </Stack>
        </Stack>
        </Box>
            </Paper>

              {/* Product Item Details  */}

         <Paper elevation={3} variant="elevation" sx={{ p: 3, mt: 4 }}>
      <Typography variant="h5">Product Item Details</Typography>
    <Box mt={4}>
      <Stack spacing={4}>
      <Stack direction='row' spacing={2}>
      <TextField
        label="category"
        variant='outlined'
      />
      <TextField
        label="legacyAssetType"
        variant='outlined'
      />
      <TextField
        label="modelId"
        variant='outlined'
      />
      <TextField
        label="cn"
        variant='outlined'
      />
      <TextField
        label="labelIntructions"
        variant='outlined'
      />
      <TextField
        label="Origin"
        variant='outlined'
      />
      <TextField
        label="assetVerticalId"
        variant='outlined'
      />
      <TextField
        label="assetVerticalFriendlyName"
        variant='outlined'
      />
      </Stack>
        </Stack>
        </Box>
            </Paper>

              {/* Product Details  */}

         <Paper elevation={3} variant="elevation" sx={{ p: 3, mt: 4 }}>
      <Typography variant="h5">Product  Details</Typography>
    <Box mt={4}>
      <Stack spacing={4}>
      <Stack direction='row' spacing={2}>
      <TextField
        label="productCategory"
        variant='outlined'
      />
      <TextField
        label="productSubCategory"
        variant='outlined'
      />
      <TextField
        label="productModelId"
        variant='outlined'
      />
      <TextField
        label="cn"
        variant='outlined'
      />
      <TextField
        label="labelIntructions"
        variant='outlined'
      />
      </Stack>
      <Stack direction='row' spacing={2}>
      <TextField
        label="Origin"
        variant='outlined'
      />
      <TextField
        label="quantity"
        variant='outlined'
        type='number'
        inputProps={{
          min: requestType === 'assetDistributorBuyer' ? 25 : 1,
        }}
       
      />
      <TextField
        label="price"
        variant='outlined'
      />
       <TextField
        label="discount"
        variant='outlined'
      />
      <TextField
        label="totalDiscountablePrice"
        variant='outlined'
      />
      </Stack>
        </Stack>
        </Box>
            </Paper>
        <Paper elevation={3} variant="elevation" sx={{ p: 3, mt: 4 }}>
        <Stack spacing={4}>
      <Stack direction='row' spacing={2}>
            <TextField
            label="finalPrice"
            variant='outlined'
             />
            <TextField
            label="assetPackage"
            variant='outlined'
            />
             </Stack>
        </Stack>
    </Paper>
    
    {/* Transaction Details */}
    <StyledPaper elevation={3} variant="elevation" sx={{ p: 3, mt: 4 }}>
      <Typography variant="h5">Payment Details</Typography>
    <Box mt={4}>
      <Stack spacing={4}>
      <Stack direction='row' spacing={2}>
      <TextField
        label="transactionId"
        variant='outlined'
        value={orderData.PaymentDetails.transactionId}
        onChange={(e) => {
          const updatedPaymentDetails = { ...orderData.PaymentDetails, transactionId: e.target.value };
          setOrderData({ ...orderData, PaymentDetails: updatedPaymentDetails });
        }}
      />
      <TextField
        label="Payment time & date"
        variant='outlined'
      />
      <FormControl  sx={{ m: 1, minWidth: 150 }} >
    <InputLabel>Payment Status</InputLabel>
    <Select
      value={orderData.PaymentDetails['Payment Status']}
      onChange={(e) => {
        const updatedPaymentDetails = { ...orderData.PaymentDetails, 'Payment Status': e.target.value };
        setOrderData({ ...orderData, PaymentDetails: updatedPaymentDetails });
      }}
      variant='outlined'
      label="Payment Status" 
      autoWidth  
    >
      <MenuItem value = ""><em></em></MenuItem>
      <MenuItem value="Success">Success</MenuItem>
      <MenuItem value="Pending">Pending</MenuItem>
      <MenuItem value="Failed">Failed</MenuItem>
     
    </Select>
  </FormControl>
  </Stack>
  <Stack direction='row' spacing={2}>
      <TextField
        label="Payment mode"
        variant='outlined'
      />
      <TextField
        label="Payment type"
        variant='outlined'
      />
      <TextField
        label="Terms Of Delivery"
        variant='outlined'
      />
    </Stack>
        </Stack>
           </Box>
             </StyledPaper>

    <Paper elevation={3} variant="elevation" sx={{ p: 3, mt: 4 }}>
    <Stack spacing={4}>
      <Stack direction='row' spacing={2}>
      <TextField
        label="deliveryType"
        variant='outlined'
        disabled={idDisabled}
      />
      <TextField
        label="transport"
        variant='outlined'
      />
       <TextField
        label="supplierAssetDeveloper"
        variant='outlined'
      />
      <TextField
        label="supplierProductionManager"
        variant='outlined'
      />
      
      <TextField
        label="certificationLink"
        variant='outlined'
      />
      </Stack>
      <Stack direction='row' spacing={2}>
       <TextField
        label="qualityLink"
        variant='outlined'
      />
      <TextField
        label="requiredDocumentLink"
        variant='outlined'
      />
       <TextField
        label="provenanceLink"
        variant='outlined'
      />
       <TextField
        label="fromDate"
        variant='outlined'
        disabled ={dateDisabled}
        value={orderData.fromDate}
        onChange={(e) => {
          console.log('fromDate changed:', e.target.value)
          setOrderData({ ...orderData, fromDate: e.target.value})
        }}
       
      />
      <TextField
        label="toDate"
        variant='outlined'
        disabled ={dateDisabled}
        value={orderData.toDate}
        onChange={e => setOrderData({ ...orderData, toDate: e.target.value})}
      
      />
       
      </Stack>
      </Stack>
     </Paper>
     {/* <ContractFarmingComponent fromDate={orderData.fromDate}  /> */}
    </div>
  )
 
}

export default AddOrder

