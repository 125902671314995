import React from "react";
import institutionalLendersImg from "../../images/app3.png";
import communitySupportersImg from "../../images/app6.png";
import intelligentBuildingsImg from "../../images/community (1).png";
import smartCitiesImg from "../../images/app4.png";
import distributorImg from "../../images/app5.png";
import providersImg from "../../images/app6.png";
import ServiceTypes from "../ServiceTypes/ServiceTypes";

export default function ExploreNetworkContent() {
  return (
    <div className="subSectionWrapper">
    <div className="explore-network-container">
      <ServiceTypes
        href3="/customized-abn-network"
        alt="Icon"
        id="ABN-Network"
        src={institutionalLendersImg}
        title="ABN Network"
        description1=" Mobile App is the only todays tool for experience and brand
            building."
        button="true"
        button3Cnt="Explore ABN Service & Service Providers"

      />
      </div>

      <div className="explore-network-container">
      <ServiceTypes
        href3="/CBNNetwork"
        alt="Icon"
        id="CBN-Network"
        src={intelligentBuildingsImg}
        title="CBN Network"
        description1=" Mobile App is the only todays tool for experience and brand
            building."
        button="true"
        button3Cnt="Explore CBN Service & Service Providers"
      />
      </div>
      <div className="explore-network-container">
      <ServiceTypes
        href3="/DTNNetwork"
        alt="Icon"
        id="DTN-Network"
        src={communitySupportersImg}
        title="DTN Network"
        description1=" Mobile App is the only todays tool for experience and brand
            building."
        button="true"
        button3Cnt="Explore DTN Service & Service Providers"

      />
      </div>
     
    </div>
  );
}
