import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import prod2 from "../../images/cool.png";
import { Container, Spinner } from "react-bootstrap";
import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
import { NavHashLink } from "react-router-hash-link";

const CategoryBanner = ({
  MarketCategoryType,
  categoryImage,
  quote,
  userType,
  bannerMessage,
  services
}) => {
  const accessToken = localStorage.getItem("GlobalAccess");
  const [activeCategory, setActiveCategory] = useState(null);
  const [category, setCategory] = useState([]);
  const [categoryType, setCategoryType] = useState(MarketCategoryType);
  const [loading, setLoading] = useState(true); // New loading state
  const [error, setError] = useState(false); // Error state for no data case

  // Fetch categories based on category type
  const getAllCategories = async () => {
    setLoading(true); // Start loading
    setError(false); // Reset error state
    try {
      const url = `${window.appConfig.getAllCategoriesBasedonCategoryTypeID}${window.appConfig.Relam}${window.appConfig.TokenRelam}/${window.appConfig.Relam}${MarketCategoryType}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await fetch(url, { method: "GET", headers });
      const data = await response.json();
      console.log(data, "resttttttttttttttttttt");
      const allCategoryoFCategoryType = data.categoryList.filter((category) => {
        return category.type === categoryType;
      });

      setCategory(data.categoryList);

      if (data.categoryList.length > 0) {
        setActiveCategory(data.categoryList[0]);
      } else {
        setError(true); // No categories found
      }
    } catch (err) {
      console.log(err, "error");
      setError(true); // Error while fetching
    } finally {
      setLoading(false); // End loading after request
    }
  };

  // Handle category change
  const handleCategoryChangeHandler = (selectedCategory) => {
    setActiveCategory(selectedCategory);
  };

  useEffect(() => {
    getAllCategories();
  }, [categoryType]);

  const renderProcessedProducts = () => {
    return (
      <div id="tendersContainer" className="container-fluid">
      
        {activeCategory && (
          <>
            <h3 className="container-title" id={activeCategory.categoryname}>
              {activeCategory.categoryname}
            </h3>
            <ProcessedProducts
              quote={quote}
              key={activeCategory.categoryname}
              type={activeCategory.categoryname}
              userType={userType}
              categoryType={categoryType}
              services={services}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className="serviceContainer ExploreBannerContainer"
        id="ExploreBannerContainer"
      >


        <div className="gridSection gridSection--mmod1">
        {bannerMessage && (
          <>
            <h1 className="container-title mb-2 text-white">
              Ensured Quality | Tracebility | Provenance
            </h1>
            <p className="container-para text-white">
              Ensure food saftey across globe
            </p>
          </>
        )}
          {loading ? (
            <div className="loading-container">
              <Spinner animation="border" /> Loading...
            </div>
          ) : category.length ? (
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
              direction="row"
            >
              {category.map((e) => (
                <Grid
                  id="complementaryServices_link"
                  className="hoverEffect"
                  item
                  md={2}
                  sm={4}
                  xs={6}
                  key={e.categoryid}
                >
                  <NavHashLink
                    onClick={() => handleCategoryChangeHandler(e)}
                    to={`#${e.categoryname}`}
                  >
                    <img
                      src={categoryImage[e.categoryname] || prod2}
                      alt={e.categoryname}
                    />
                    <h4>{e.categoryname}</h4>
                  </NavHashLink>
                </Grid>
              ))}
            </Grid>
          ) : (
            <h2>No category found</h2>
          )}
        </div>
      </div>

      <div className="explore-product-wrapper">
        <Container>
          
    
          {renderProcessedProducts()}</Container>
      </div>
    </>
  );
};

export default CategoryBanner;
