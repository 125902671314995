import React, { Component } from "react";
import "./ProcessedProduct.css";
import ReactPaginate from "react-paginate";
import Form from 'react-bootstrap/Form';
import { BsHandThumbsUp } from "react-icons/bs";
import NotificationManager from "react-notifications/lib/NotificationManager"; 
import ReactPlayer from "react-player/lazy";
import { Container } from "@material-ui/core";

class ProcessedProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelProducts: [],
      categories : [],
      offset: 0,
      tensdersPageCount: 1,
      perPage: 6,
      currentPage: 0,
      suppliers : [],
      msmeSuppliers : [],
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      subcategoryValues : [],
      subcategoryData : [],
      assetTypesData : [],
      models : [],

      supplierUserName  : "",
      verticalId : "",
      categoryTypeValue : "",
      assetTypeValues : "",
      modelValue : "",
      prodcutCategoryIdVlaue :"",

      contentSubCategories : [],
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  };
  
  componentDidMount() {
    this.supplierUsers();
    this.msmeSupplierUsers();
  };

  componentDidUpdate(prevProps, prevState){

    if(!this.state.prodcutCategoryIdVlaue || !this.state.verticalId) return;
    if(prevState.verticalId !== this.state.verticalId){
        this.getCategories(this.state.verticalId);
    };

    if(!this.state.verticalId || !this.state.categoryTypeValue) return;
    if(prevState.verticalId !== this.state.verticalId || prevState.categoryTypeValue !== this.state.categoryTypeValue){
        this.getAssetTypes(this.state.verticalId, this.state.categoryTypeValue);
    };

    if(!this.state.verticalId || !this.state.categoryTypeValue || !this.state.assetTypeValues) return;
    if(prevState.verticalId !== this.state.verticalId || prevState.categoryTypeValue !== this.state.categoryTypeValue || prevState.assetTypeValues !== this.state.assetTypeValues){
        this.getModels(this.state.verticalId, this.state.categoryTypeValue, this.state.assetTypeValues);
    };
    if(!this.state.supplierUserName || !this.state.verticalId || !this.state.categoryTypeValue || !this.state.assetTypeValues || !this.state.modelValue || !this.state.prodcutCategoryIdVlaue) return;
    if(prevState.supplierUserName !== this.state.supplierUserName || prevState.verticalId !== this.state.verticalId || prevState.categoryTypeValue !== this.state.categoryTypeValue || prevState.assetTypeValues !== this.state.assetTypeValues || prevState.modelValue !== this.state.modelValue){
      this.allSupplierProducts();
    };
  };

  allSupplierProducts(){
      fetch(
        `${window.appConfig.getProducts}${window.appConfig.Relam}${this.state.supplierUserName}/${this.state.verticalId}/${this.state.categoryTypeValue}/${this.state.assetTypeValues}/${this.state.modelValue}/${this.state.prodcutCategoryIdVlaue}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.state.InitialAccessToken,
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            modelProducts : result.products,
            tensdersPageCount: Math.ceil(
              result.products.length / this.state.perPage
            ),
          });
          this.getProductSubCategories();
        }).catch(err=>{
          return NotificationManager.error("err");
        })
  };

  getContentSubCategoryData(subcatVal){
    fetch(
      `${window.appConfig.getProducts}${window.appConfig.Relam}${this.state.supplierUserName}/${this.state.verticalId}/${this.state.categoryTypeValue}/${this.state.assetTypeValues}/${this.state.modelValue}/${this.state.prodcutCategoryIdVlaue}/${subcatVal}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          modelProducts : result.products,
          tensdersPageCount: Math.ceil(
            result.products.length / this.state.perPage
          ),
        });
      }).catch(err=>{
        return NotificationManager.error("err");
      })
  };

  getProductCategory(){
    fetch(
      `${window.appConfig.getContentCategories}${window.appConfig.Relam}${window.appConfig.Relam}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
    .then((res) => res.json())
    .then((result) => {
      const postid = result.categoryList.filter(data=>{
        return data.categoryname === "PROCESSED-PRODUCTS";
      });
      this.setState({
        prodcutCategoryIdVlaue: postid[0].categoryid,
      });
      this.getAssetVerticalHandler();
    })
    .catch((err) => {
      console.log("err", err)
    });
  };

  getProductSubCategories(){
    fetch(
      `${window.appConfig.getContentSubcategories}${window.appConfig.Relam}${this.state.supplierUserName}/${this.state.prodcutCategoryIdVlaue}`,
      {
        method : "GET",
        headers : {
          "Content-Type" : "application/json",
          Authorization : "Bearer " + this.state.InitialAccessToken,
        },
      }
    ).then(res=>res.json())
    .then(res=>{
      this.setState({
        contentSubCategories : res.contentsubcategories,
      });
    }).catch(err=>{
      return NotificationManager.error("err");
    })
  };

  supplierUsers(){
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}GovtDept`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
    .then((res) => res.json())
    .then((result) => {
      this.getProductCategory();
      this.setState({
        suppliers: result.users,
        supplierUserName : result.users[0].username,
      });
    })
    .catch((err) => {
      console.log("err", err)
    });
  };

  msmeSupplierUsers(){
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}FOOD-PROCESSOR`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
    .then((res) => res.json())
    .then((result) => {
      this.setState({
        msmeSuppliers: result.users,
      });
    })
    .catch((err) => {
      console.log("err", err)
    });
  };

  supplierUser(username) {
    this.setState({supplierUserName : username});
  };

  getAssetVerticalHandler(){
    fetch(
        `${window.appConfig.getAssetVerticals}${window.appConfig.Relam}${window.appConfig.Relam}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          categories : result.assetVerticals,
          verticalId : result.assetVerticals[0].assetVerticalId,
        });
      })
      .catch((err) => console.log("err", err));
  };

  sectorVerticalIdsOnClick(e){
    this.setState({ 
        verticalId : e.target.value,
     });
  };

  getCategories(assetVerticalId){
    fetch(
        `${window.appConfig.getCategories}${window.appConfig.Relam}${window.appConfig.Relam}${assetVerticalId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
            subcategoryValues : result.categoryTypes,
            categoryTypeValue : result.categoryTypes[0].categoryType,
        });
      })
      .catch((err) => console.log("err", err));
  };

  categoriesOnClick(e){
    this.setState({
        categoryTypeValue : e.target.value,
    });
  };

  getAssetTypes(verticalId, categoryValue){
    fetch(
        `${window.appConfig.getAssetType}${window.appConfig.Relam}${window.appConfig.Relam}${verticalId}/${categoryValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
            assetTypesData : result.assetTypes,
            assetTypeValues : result.assetTypes[0].assetType,
        });
      })
      .catch((err) => console.log("err", err));
  };

  assetTypesOnclick(e){
    this.setState({
        assetTypeValues : e.target.value,
    });
  };

  getModels(verticalId, categoryValue, assetType){
    fetch(
        `${window.appConfig.getModel}${window.appConfig.Relam}${window.appConfig.Relam}${verticalId}/${categoryValue}/${assetType}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("modelValue",result)
        this.setState({
            models : result.models,
            modelValue : result.models[0].modelid,
        });
      })
      .catch((err) => console.log("err", err));
  };

  modelOnclick(e){
    this.setState({
        modelValue : e.target.value,
    });
  };

  likeCount(product){
    fetch(
      `${window.appConfig.productLikeCount}${product.consumeradminid}/${product.businessid}/${product.productid}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        Authorization: "Bearer " + this.state.InitialAccessToken,
      },
    }
  )
    .then((res) => res.text())
    .then((result) => {
      return NotificationManager.success(result);
    })
    .catch((err) => console.log("err", err));
  }

  /*Pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        
      }
    );
  };
  /*End of Pagination */

  render() {
    const {modelProducts, contentSubCategories, suppliers, msmeSuppliers} = this.state;
    const allSuppliers = [...suppliers, ...msmeSuppliers];
    const modelProductsList = modelProducts.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    return (
      <>
      <Container className="products-page-container">
      <div id="tendersContainer" className="container-fluid">
        <div className="homepageNavbar ">

          <div className="dropdown">
              <div className="navbarRight">
                <Form.Select
                    onChange={(e)=>this.supplierUser(e.target.value)}
                >
                {allSuppliers &&
                  allSuppliers.map((filter, i) => (
                    <option value={filter.username}>{filter.username}</option>
                  ))}
                </Form.Select>
              </div>
          </div> 
          <div className="dropdown">
              <div className="navbarRight">
                <Form.Select
                    onChange={(e)=>this.sectorVerticalIdsOnClick(e)}
                >
                {this.state.categories &&
                  this.state.categories.map((filter, i) => (
                    <option value={filter.assetVerticalId}>{filter.friendlyName}</option>
                  ))}
                </Form.Select>
              </div>
          </div> 
          <div className="dropdown">
              <div className="navbarRight">
                <Form.Select
                    onChange={(e)=>this.categoriesOnClick(e)}
                >
                {this.state.subcategoryValues &&
                  this.state.subcategoryValues.map((filter, i) => (
                    <option value={filter.categoryType}>{filter.categoryType}</option>
                  ))}
                </Form.Select>
              </div>
          </div> 
          <div className="dropdown">
              <div className="navbarRight">
                <Form.Select
                    onChange={(e)=>this.assetTypesOnclick(e)}
                >
                {this.state.assetTypesData &&
                  this.state.assetTypesData.map((filter, i) => (
                    <option value={filter.assetType}>{filter.assetType}</option>
                  ))}
                </Form.Select>
              </div>
          </div> 
          <div className="dropdown">
              <div className="navbarRight">
                <Form.Select
                    onChange={(e)=>this.modelOnclick(e)}
                >
                {this.state.models &&
                  this.state.models.map((filter, i) => (
                    <option value={filter.modelid}>{filter.modelid}</option>
                  ))}
                </Form.Select>
            </div>
          </div> 
          <div className="dropdown">
              <div className="navbarRight">
                <Form.Select
                    onChange={(e)=>this.getContentSubCategoryData(e.target.value)}
                >
                <option value="">Select Sub-Category</option>
                {contentSubCategories &&
                  contentSubCategories.map((filter, i) => (
                    <option value={filter.contentsubcategoryname}>{filter.contentsubcategoryname}</option>
                  ))}
                </Form.Select>
              </div>
        </div>
        </div>
        {/* <div className="dropdown">
              <div className="navbarRight">
                <Form.Select
                    onChange={(e)=>this.getContentSubCategoryData(e.target.value)}
                >
                <option value="">Select Sub-Category</option>
                {contentSubCategories &&
                  contentSubCategories.map((filter, i) => (
                    <option value={filter.contentsubcategoryname}>{filter.contentsubcategoryname}</option>
                  ))}
                </Form.Select>
              </div>
        </div> */}
        <div className="adminTendersDiv">
          <h3
          className="container-heading1"
            
          >
             PROCESS PRODUCTS OF <b><i>{this.state.supplierUserName.toUpperCase()}</i></b>
          </h3>
          {/* View All Products */}
            {modelProductsList.length !== 0 ? 
            <div className="cardStyleForDashboardItems">
              {modelProductsList.map((product, index) => (
                <div className="videosWrapper card">
                   <div className="cardContent">
                      <div className="card-body">
                        <span>
                            <p>
                                <b>Business Id:</b>
                                {product.businessid}
                            </p>
                            <p>
                                <b>Product Id:</b>
                                {product.productid}
                            </p>
                            <p>
                                <b>Product Name:</b>
                                {product.productfriendlyname}
                            </p>
                            <p>
                                <b>Origin:</b>
                                {product.country}
                            </p>
                            <p>
                                <b>Asset Type:</b>
                                {product.legacyassettype}
                            </p>
                            <p>
                                <b>Breed:</b>
                                {product.modelid}
                            </p>
                            <p>
                                <b>Model Friendly Name:</b>
                                {product.modelfriendlyname}
                            </p>
                            <p>
                                <b>Product Cost:</b>
                                {product.productcost} {product.unit}
                            </p>
                            {(()=>{
                                switch(product.discount){
                                    case "" :
                                        break;
                                    case 0 :
                                        break;
                                    default:
                                        return [<div>
                                            <p>
                                                <b>Discount:</b>
                                                {product.discount}
                                            </p>
                                            <p>
                                                <b>Discounted Price:</b>
                                                {product.discountedprice} {product.unit}
                                            </p>
                                        </div>]
                                };
                            })()}
                            <p>
                                <b>Item Count:</b>
                                {product.itemcount}
                            </p>
                            <div className="product-videoimage">
                            {(()=>{
                               switch(product.productimageurl){
                                case "" :
                                    break;
                                case "null" :
                                    break;
                                default:
                                    return <p>
                                    <img src={product.productimageurl} className="img-thumbnail"></img>
                                    </p>
                            };
                            })()}
                            {(()=>{
                                switch(product.productqrcodeurl){
                                    case "" :
                                        break;
                                    case "null" :
                                        break;
                                    default:
                                        return <p>
                                        <img src={product.productqrcodeurl} className="img-qr"></img>
                                        </p>
                                };
                            })()}
                            </div>
                            <div className="product-videoimage">
                            {(()=>{
                              switch(product.qacertificateurl){
                                case "" :
                                    break;
                                case "null" :
                                    break;
                                default:
                                    return <p>
                                    <img src={product.qacertificateurl} className="img-thumbnail"></img>
                                    </p>
                              };
                            })()}
                            {(()=>{
                              switch(product.labcertificate){
                                case "" :
                                    break;
                                case "null" :
                                    break;
                                default:
                                    return <p>
                                    <img src={product.labcertificate} className="img-thumbnail"></img>
                                    </p>
                              };
                            })()}
                            </div>
                            {(()=>{
                              switch(product.productvideourl){
                                case "" :
                                    break;
                                case "null" :
                                    break;
                                default:
                                    return <p>
                                      <ReactPlayer
                                          className="product-video"
                                          url={product.productvideourl}
                                          pip={true}
                                          controls={true}
                                          playing={false}
                                          muted={true}
                                      />
                                  </p>
                            };
                            })()}
                            {(()=>{
                              switch(product.producthistoryurl){
                                case "" :
                                    break;
                                case "null" :
                                    break;
                                default:
                                    return <p>
                                    <a href={product.producthistoryurl} target="_blank">Product History</a>
                                    </p>
                            };
                            })()}
                            <p>
                                <b>Status:</b>
                                {product.status}
                            </p>
                            <p>
                              <button onClick={()=>{this.likeCount(product)}}>
                                <BsHandThumbsUp size={30}/>
                              </button><br/>
                              <h5>{product.likecount}</h5>
                            </p>
                        </span>
                      </div>
                    </div>
                </div>
              ))}
              <div className="paginationComponent">
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.tensdersPageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
            :
            <div className="card">
            <p className="card-body container-heading2">
                <b>PRODUCTS NOT FOUND</b>
            </p>
            </div>}
        </div>
      </div>
      </Container>
      </>
    );
  }
}

export default ProcessedProduct;
