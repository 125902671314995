import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MainService from "../../Common/MainService";
import { NotificationManager } from "react-notifications";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';


const OTACloudServerManagement = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [openCreateDailog, setOpenCreateDailog] = React.useState(false);
    const [openUpdateDailog, setOpenUpdateDailog] = React.useState(false);
    const [deleteDailogOpen, setDeleteDailogOpen] = React.useState(false);
    const [updateDailogOpen, setUpdateDailogOpen] = React.useState(false);
    const [deviceDetailsOpen, setDeviceDetailsOpen] = React.useState(false);
    const [deleteServer, setDeleteServer] = React.useState({});
    const [deviceDetails, setDeviceDetails] = React.useState({});
    const [selectedStatus, setSelectedStatus] = React.useState('published');
    const [serverData, setServerData] = React.useState('');
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    const [isLoading, setIsLoading] = React.useState(false);


    const [formData, setFormData] = React.useState({
        networkId: username,
        parentId: parentId,
        serverId: "",
        friendlyName: "",
        host: "",
        port: "",
        otaTopic: "",
        statusTopic: "",
        identityLink: ""
    });


    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    React.useEffect(() => {

        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        MainService.getOTACloudList(parentId, username)
            .then((res) => {
                setServerData(res)
                setIsLoading(false);

            })
            .catch((err) => {
                setIsLoading(false);
                return NotificationManager.error("Something went wrong");
            });
    };


    const handleCreateClose = () => {
        setOpenCreateDailog(false);
        setFormData({
            networkId: "",
            parentId: "",
            serverId: "",
            friendlyName: "",
            host: "",
            port: "",
            otaTopic: "",
            statusTopic: "",
            identityLink: ""
        });
    };


    const handleAddFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCreateSubmit = async (event) => {
        event.preventDefault();

        MainService.createOTAServer(username, parentId, formData)
            .then((res) => {
                handleCreateClose();
                fetchData();
                return NotificationManager.success("OTA Server Created");
            })
            .catch((err) => {
                return NotificationManager.error("Something went wrong");
            });

    };

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();

        MainService.createOTAServer(username, parentId, formData)
            .then((res) => {
                handleUpdateClose();
                fetchData();
                return NotificationManager.success("OTA Server Updated");
            })
            .catch((err) => {
                return NotificationManager.error("Something went wrong");
            });
    }

    const handleDeleteSubmit = async (row) => {

        MainService.deleteDeviceCloud(username, deleteServer.deviceCloudServerType, deleteServer.tenancyType, deleteServer.version)
            .then((res) => {
                handleDeleteDailogClose();
                // Handle the response
                fetchData();
                return NotificationManager.success("Device cloud server Deleted");
            })
            .catch((err) => {
                handleDeleteDailogClose();
                return NotificationManager.error("Something went wrong");
            });

    };
    const handleDeleteDailogClose = () => {
        setDeleteDailogOpen(false);
    }

    const handleUpdateDailogClose = () => {
        setUpdateDailogOpen(false);
    }

    const handleDeviceDetailsClose = () => {
        setDeviceDetailsOpen(false);
    }

    const handleUpdateOTA = () => {
        setOpenUpdateDailog(true)
        setFormData({
            networkId: username,
            parentId: parentId,
            serverId: serverData.serverId,
            friendlyName: serverData.friendlyName,
            host: serverData.host,
            port: serverData.port,
            otaTopic: serverData.otaTopic,
            statusTopic: serverData.statusTopic,
            identityLink: serverData.identityLink

        })
    }

    const handleUpdateClose = () => {
        setOpenUpdateDailog(false)
        setFormData({
            networkId: "",
            parentId: "",
            serverId: "",
            friendlyName: "",
            host: "",
            port: "",
            otaTopic: "",
            statusTopic: "",
            identityLink: ""
        });
    }




    return (
        <div >
            <span><h3>OTA Server Management</h3></span>
            {isLoading ? (
                // <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height:'50vh'}}><CircularProgress style={{ color: '#3ecf8e' }} /></div>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={6}
                >
                    <Card sx={{ width: '45%', height: '90%',borderRadius: '15px', 'box-shadow': '0 0 10px rgba(0,0,0,0.8)' }}>
                        <CardContent sx={{ padding: "2rem" }}>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                            <Divider />
                            <Typography component="div" variant='h3'>
                                <Skeleton />
                            </Typography>
                            <Typography component="div" variant='h3'>
                                <Skeleton />
                            </Typography>
                            <Typography component="div" variant='h3'>
                                <Skeleton />
                            </Typography>
                            <Typography component="div" variant='h3'>
                                <Skeleton />
                            </Typography>
                            <Typography component="div" variant='h3'>
                                <Skeleton />
                            </Typography>
                            <Typography component="div" variant='h3'>
                                <Skeleton />
                            </Typography>
                            <Typography component="div" variant='h3'>
                                <Skeleton />
                            </Typography>
                            <div style={{ display:'flex', justifyContent:'center',textAlign: 'center', marginTop: "1.5rem" }}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    height={30}
                                    width={100}
                                    style={{ margin: 5 }}
                                />
                            </div>

                        </CardContent>
                    </Card>
                </Box>

            ) : (
                // Render your component with the loaded data
                <div>
                    <Grid display="flex" justifyContent="flex-end" item xs={6} md={8}>
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={() => setOpenCreateDailog(true)}
                            variant='outlined'
                            style={{ color: '#3ecf8e', borderColor: '#3ecf8e', height: '2.5rem', marginLeft: "5px" }}
                            size='small'
                        >
                            <AddIcon />Add Server
                        </Button>
                    </Grid>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Card sx={{ minWidth: '45%',borderRadius: '15px', 'box-shadow': '0 0 10px rgba(0,0,0,0.8)' }}>
                            <CardContent sx={{ padding: "2rem" }}>
                                <Typography variant="h5" component="div" style={{ marginBottom: "1rem", justifyContent: "center", alignItems: "center" }}>
                                    <b style={{ color: "#3ecf8e" }}>{serverData.friendlyName}</b>
                                </Typography>
                                <Divider />
                                <Typography sx={{ padding: "0.6rem" }} color="text.secondary">
                                    <b>Network ID:</b> {serverData.networkId}
                                </Typography>
                                <Typography sx={{ padding: "0.6rem" }} color="text.secondary">
                                    <b>Parent ID:</b> {serverData.parentId}
                                </Typography>
                                <Typography sx={{ padding: "0.6rem" }} color="text.secondary">
                                    <b>Server ID:</b> {serverData.serverId}
                                </Typography>
                                <Typography sx={{ padding: "0.6rem" }} color="text.secondary">
                                    <b>Host:</b> {serverData.host}
                                </Typography>
                                <Typography sx={{ padding: "0.6rem" }} color="text.secondary">
                                    <b>Port:</b> {serverData.port}
                                </Typography>
                                <Typography sx={{ padding: "0.6rem" }} color="text.secondary">
                                    <b>OTA Topic:</b> {serverData.otaTopic}
                                </Typography>
                                <Typography sx={{ padding: "0.6rem" }} color="text.secondary">
                                    <b>Status Topic:</b> {serverData.statusTopic}
                                </Typography>
                                <Typography sx={{ padding: "0.6rem" }} color="text.secondary">
                                    <b>Identity Link:</b>{serverData.identityLink}
                                </Typography>
                                <div style={{ textAlign: 'center', marginTop: "1.5rem" }}>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleUpdateOTA}
                                        // variant='outlined'
                                        style={{ backgroundColor: '#3ecf8e', color: "white", borderColor: '#3ecf8e', height: '2.5rem' }}
                                        size='small'
                                    >
                                        <UpgradeIcon />Update OTA Server
                                    </Button>
                                </div>

                            </CardContent>
                        </Card>
                    </Box>

                    <Dialog open={openCreateDailog} onClose={handleCreateClose}>
                        <DialogTitle>Create OTA Server</DialogTitle>
                        <DialogContent>
                            <form onSubmit={handleCreateSubmit}>
                                <TextField
                                    label="Network Id"
                                    name="networkId"
                                    value={username}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                    disabled
                                />
                                <TextField
                                    label="Parent Id"
                                    name="parentId"
                                    value={parentId}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                    disabled
                                />
                                <TextField
                                    label="Server Id"
                                    name="serverId"
                                    value={formData.serverId}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <TextField
                                    label="Friendly Name"
                                    name="friendlyName"
                                    value={formData.friendlyName}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <TextField
                                    label="Host"
                                    name="host"
                                    value={formData.host}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <TextField
                                    label="Port"
                                    name="port"
                                    value={formData.port}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <TextField
                                    label="OTA Topic"
                                    name="otaTopic"
                                    value={formData.otaTopic}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <TextField
                                    label="Status Topic"
                                    name="statusTopic"
                                    value={formData.statusTopic}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <TextField
                                    label="Identity Link"
                                    name="identityLink"
                                    value={formData.identityLink}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <DialogActions>
                                    <Button type='submit' variant="contained" style={{ backgroundColor: '#3ecf8e', borderColor: '#3ecf8e' }} >
                                        Submit
                                    </Button>
                                    <Button onClick={handleCreateClose} variant='outlined' color="error">
                                        Cancel
                                    </Button>

                                </DialogActions>
                            </form>
                        </DialogContent>

                    </Dialog>

                    <Dialog open={openUpdateDailog} onClose={handleUpdateClose}>
                        <DialogTitle>Update OTA Server</DialogTitle>
                        <DialogContent>
                            <form onSubmit={handleUpdateSubmit}>
                                <TextField
                                    label="Network Id"
                                    name="networkId"
                                    value={username}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                    disabled
                                />
                                <TextField
                                    label="Parent Id"
                                    name="parentId"
                                    value={parentId}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                    disabled
                                />
                                <TextField
                                    label="Server Id"
                                    name="serverId"
                                    value={formData.serverId}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                    disabled
                                />
                                <TextField
                                    label="Friendly Name"
                                    name="friendlyName"
                                    value={formData.friendlyName}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <TextField
                                    label="Host"
                                    name="host"
                                    value={formData.host}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <TextField
                                    label="Port"
                                    name="port"
                                    value={formData.port}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <TextField
                                    label="OTA Topic"
                                    name="otaTopic"
                                    value={formData.otaTopic}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <TextField
                                    label="Status Topic"
                                    name="statusTopic"
                                    value={formData.statusTopic}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <TextField
                                    label="Identity Link"
                                    name="identityLink"
                                    value={formData.identityLink}
                                    onChange={handleAddFormChange}
                                    fullWidth
                                    margin="dense"
                                    required
                                />
                                <DialogActions>
                                    <Button type='submit' variant="contained" style={{ backgroundColor: '#3ecf8e', borderColor: '#3ecf8e' }} >
                                        Submit
                                    </Button>
                                    <Button onClick={handleUpdateClose} variant='outlined' color="error">
                                        Cancel
                                    </Button>

                                </DialogActions>
                            </form>
                        </DialogContent>

                    </Dialog>

                    <Dialog open={deleteDailogOpen} onClose={handleDeleteDailogClose}>
                        <DialogTitle>Delete Confirmation</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete this item?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteDailogClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleDeleteSubmit} color="secondary" autoFocus>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={updateDailogOpen} onClose={handleUpdateDailogClose}>
                        <DialogTitle>Update Cloud Server</DialogTitle>
                        <DialogContent>
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={selectedStatus}
                                onChange={handleStatusChange}
                                fullWidth
                                defaultValue="published"
                            >
                                <MenuItem selected value="published">Published</MenuItem>
                            </Select>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleUpdateDailogClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleUpdateSubmit} color="secondary" autoFocus>
                                Update
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={deviceDetailsOpen} onClose={handleDeviceDetailsClose}>
                        <DialogTitle>Device Cloud Server Details</DialogTitle>
                        <DialogContent sx={{ padding: "2rem" }}>
                            {/* {Object.entries(deviceDetails).map(([key, value]) => (
                        <Typography sx={{marginTop:'5px'}} key={key} variant="body1">
                            <strong>{key}:</strong> {value}
                        </Typography>
                    ))} */}
                            <Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>Oem Id:</strong> {deviceDetails.id}
                            </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>Device Cloud Server Type:</strong> {deviceDetails.deviceCloudServerType}
                            </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>Friendly Name:</strong> {deviceDetails.friendlyName}
                            </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>Tenancy Type:</strong> {deviceDetails.tenancyType}
                            </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>Deployment Type:</strong> {deviceDetails.deploymentType}
                            </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>Base Url:</strong> {deviceDetails.baseUrl}
                            </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>Region:</strong> {deviceDetails.region}
                            </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>IP Address:</strong> {deviceDetails.ipAddress}
                            </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>Port:</strong> {deviceDetails.port}
                            </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>Version:</strong> {deviceDetails.version}
                            </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>DCS Image Link:</strong> {deviceDetails.dcsImgLink}
                            </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>OEM Identity Access Link:</strong> {deviceDetails.oemIdentityAccessLink}
                            </Typography><Typography sx={{ marginTop: '7px' }} variant="body1">
                                <strong>Status:</strong> {deviceDetails.status}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeviceDetailsClose} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}

        </div>
    )
}

export default OTACloudServerManagement