import React, { Component } from 'react';
//import { Link,withRouter  } from 'react-router-dom';
import { Line } from "react-chartjs-2";
import GaugeChart from 'react-gauge-chart';
import { NotificationManager } from "react-notifications";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row, Container,
} from 'reactstrap';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { masterService } from '../Common/';
import '../style.css';
import './Layout.css';
import AuthAxiosInstance from '../../../authAxiosInstance';
import { DeviceMetadataAxiosInstance, EMSServcieAxiosInstance } from '../../../authAxiosInstance'
import Category from './containers/DeviceCategory/DeviceCategory';
import socketIOClient from "socket.io-client";
import MainService from "../Common/MainService";
import networkId from '../../../networkId';
import Grid from '@material-ui/core/Grid';


const chartStyle = {
  height: 150,
}

var jsonconfig = window.appConfig;

class ViewProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showspinner: false,
      data: {},
      deviceList: [],
      selectDevId: 0,
      listProperty: [],
      selectedProperty: [],
      date: {},
      validDate: {},
      selectedDevices: [],
      
    };

    // toast.configure({
    //   autoClose: 8000,
    //   draggable: false,
    //   //etc you get the idea
    // });
  }

  getAppConfig = () => {
    return window.AppConfigData;
  };

  componentDidMount() {
    //this.getlayoutlist();
    const socket = socketIOClient((this.getAppConfig().ApiUrlMapp.SocketIOURL));

    socket.on("livedata", (data) => {
      try{
        console.log("data is", data);
        const filterData = this.state.deviceList.filter((d) => (d.deviceId ==  this.state.selectedDevices[this.state.selectedDevices.length - 1]));
        const userId = localStorage.getItem("user-id");
        const { user } = MainService.getLocalData();
        const { username, parentId } = user;
        
        if (filterData.length > 0) {
          if(username === data.networkid.toString() && data.deviceid.toString() === filterData[0].deviceId){
          if (filterData[0].deviceType === data.devicetype) {
            let selectedProperty = [ ...this.state.selectedProperty ];
            let propnamearray = [];
            switch(filterData[0].deviceType) {
              case 'AHUG':
                propnamearray = data.AHUGproperty;
                break;
              case 'CCN':
                propnamearray = data.CHILLER[0].CHILLERproperty;
                break;
              case 'LG':
                propnamearray = data.THS[0].THSproperty;
                break;
              case 'TCN':
                propnamearray = data.Tstat[0].Tstatproperty;
                break;
              case 'VCN':
                propnamearray = data.VFD[0].VFDproperty;
                break;
              case 'EMG':
                propnamearray = data.EMG[0].EMproperty;
                break;
              case 'EMMW':
                propnamearray = data.EMMW;
                break;
              default:
                propnamearray = data.properties;
                break;
                // code block
            }
            
            if (selectedProperty.length > 0) {
              selectedProperty.map((d, i) => {
                propnamearray.map((da) => {
                  if (d.property === da.property) {
                    d.value = da.value;
                  }
                })
              })
              this.setState({ selectedProperty });
            }
            else{
              var t = this.state.selectedProperty;

            }
            
          }
        }
      }
      }
      catch(cerror){
        // toast.error("Something went wrong.", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");
      }
    });

    this.loadDeviceList();
  }


  loadDeviceList() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    
    const userId = localStorage.getItem("user-id");
    DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/list`).then((resp) => {
      if(resp.data)
          this.setState({ deviceList: resp.data });
      else
          this.setState({ deviceList: [] });
    }).catch((err) => {

    })
  }

  selectDate(type, evt) {
     let date = {...this.state.date};
     let validDate = {...this.state.validDate};
     date[type] = evt.target.value+"T00:00:00";
     validDate[type] = evt.target.value;
     this.setState({date, validDate});
  }

  selectDevice(dev_id,e) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    
try{
    this.setState({listProperty: [], selectedProperty: []});
    
    let selectedDevices = [];
    selectedDevices.push(dev_id);
    this.setState({selectedDevices : selectedDevices});

    if (selectedDevices.length > 0) {
      const filterDevice = this.state.deviceList.filter((d) => (d.deviceId ==  selectedDevices[selectedDevices.length - 1]));

      this.setState({showspinner: true});
      //AuthAxiosInstance.get(`/ems/livedata/${networkId.network}/${selectedDevices[selectedDevices.length - 1]}/properties?deviceType=${filterDevice[0].deviceType}`).then((res) => {
        DeviceMetadataAxiosInstance.get(`/admin/deviceprofile/${username}/${filterDevice[0].deviceId}/metadata?deviceType=${filterDevice[0].deviceType}&model=${filterDevice[0].model}`)
        .then((res) => {
        
        this.setState({showspinner: false});
        if (res.data && res.data !== 'failed' && res.data !== 'error') {
          console.log("filterDevice[0]", filterDevice[0]);
          if (filterDevice[0].deviceType == "AHUG") {
            this.setState({ listProperty: res.data.AHUGproperty ? res.data.AHUGproperty : [] }, () => {
              console.log(this.state.listProperty);
            });
            
          }
          else if(filterDevice[0].deviceType == "EMMW"){
            this.setState({ listProperty: res.data.EMMW ? res.data.EMMW : []  });
          }
          else if (filterDevice[0].deviceType == "CCN") {
            this.setState({ listProperty: res.data.CHILLER[0].CHILLERproperty ? res.data.CHILLER[0].CHILLERproperty : [] }, () => {
              console.log(this.state.listProperty);
            });
          }
          else if (filterDevice[0].deviceType == "LG") {
            this.setState({ listProperty: res.data.THS[0].THSproperty ? res.data.THS[0].THSproperty : [] }, () => {
              console.log(this.state.listProperty);
            });
          }
          else if (filterDevice[0].deviceType == "TCN") {
            this.setState({ listProperty: res.data.Tstat[0].Tstatproperty ? res.data.Tstat[0].Tstatproperty :[] }, () => {
              console.log(this.state.listProperty);
            });
          }
          else if(filterDevice[0].deviceType == "VCN"){
            this.setState({ listProperty: res.data.VFD[0].VFDproperty ? res.data.VFD[0].VFDproperty :[] }, () => {
              console.log(this.state.listProperty);
            });
          }
          else if(filterDevice[0].deviceType == "EMG"){
            if(res.data.EMG[0] && res.data.EMG[0].EMproperty.length > 0){
            this.setState({ listProperty: res.data.EMG[0].EMproperty ? res.data.EMG[0].EMproperty : [] }, () => {
              console.log(this.state.listProperty);
            });
          }
          else{
            this.setState({listProperty: []});
            // toast.error("Data not found.", {
            //   position: toast.POSITION.TOP_RIGHT
            // });
            return NotificationManager.error("Data not found");
          }
          }
          else{
            this.setState({ listProperty: res.data.properties ? res.data.properties : []}, () => {
              console.log(this.state.listProperty);
            });
          }
         
        }
        else{
          this.setState({listProperty: []});
          // toast.error("Data not found.", {
          //   position: toast.POSITION.TOP_RIGHT
          // });
          return NotificationManager.error("Data not found");
        }

      }).catch((err) => {
        this.setState({showspinner: false});
        this.setState({listProperty: []});
        // toast.error("Some error occurred.", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");
      });
    } else {
      this.setState({listProperty: []});
    }
  }
  catch(cerror){
    this.setState({listProperty: []});
    // toast.error("Something went wrong.", {
    //   position: toast.POSITION.TOP_RIGHT
    // });
    return NotificationManager.error("Some error occured");
  }

  }

  selectProperty(evt) {
    const selectedProperty = this.state.selectedProperty.filter((d) => (d.property == evt));
    const filterproperty = this.state.listProperty.filter((d) => (d.property == evt));
    const unit = filterproperty[0].unit ? filterproperty[0].unit : '';
    if (selectedProperty.length == 0) {
      this.getHistoryData(evt, unit);
    } else {
          const ind = this.state.selectedProperty.indexOf(selectedProperty[0]);
          const property = [...this.state.selectedProperty];
          property.splice(ind, 1);
          this.setState({selectedProperty: property});
    } 
    
  }


  getHistoryData(property, unit) {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    try{
    this.setState({showspinner: true});
    let currentdate = new Date();
    let todate = currentdate.toISOString().split('T')[0]+'T23:59:59';
    currentdate.setDate(currentdate.getDate() - 2);
    let fromdate = currentdate.toISOString();
    const filterDevice = this.state.deviceList.filter((d) => (d.deviceId ==  this.state.selectedDevices[this.state.selectedDevices.length - 1]));
    EMSServcieAxiosInstance.get(`/ems/history/${username}/${filterDevice[0].deviceId}?deviceType=${filterDevice[0].deviceType}&property=${property}&fromDate=${fromdate}&toDate=${todate}`)
      .then((res) => {
            this.setState({showspinner: false});
            if(res.data.length > 0){
            res.data.sort(function(a, b) {
              var c = new Date(a.updatedat);
              var d = new Date(b.updatedat);
              return d-c;
            });
            if (filterDevice[0].deviceType === "AHUG") {
              const index = res.data[0].AHUGproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].AHUGproperty[index];
              const resultArray = [...this.state.selectedProperty];
              //results.value = "70";
              results.unit = unit;
              resultArray.push(results);
              this.setState({selectedProperty: resultArray});
            }
            else if (filterDevice[0].deviceType === "EMMW") {
              const index = res.data[0].EMMW.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].EMMW[index];
              const resultArray = [...this.state.selectedProperty];
              results.unit = unit;
              //results.value = "70";
              resultArray.push(results);
              this.setState({selectedProperty: resultArray});
            }
            else if(filterDevice[0].deviceType === "CCN") {
              const index = res.data[0].CHILLER[0].CHILLERproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].CHILLER[0].CHILLERproperty[index];
              const resultArray = [...this.state.selectedProperty];
              results.unit = unit;
              resultArray.push(results);
              this.setState({selectedProperty: resultArray});
            }
            else if(filterDevice[0].deviceType === "LG") {
              const index = res.data[0].THS[0].THSproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].THS[0].THSproperty[index];
              const resultArray = [...this.state.selectedProperty];
              results.unit = unit;
              resultArray.push(results);
              this.setState({selectedProperty: resultArray});
            }
            else if(filterDevice[0].deviceType === "TCN"){
              const index = res.data[0].Tstat[0].Tstatproperty.findIndex((element, index) => {
                if (element.property === property) {
                  return true
                }
              });
              const results = res.data[0].Tstat[0].Tstatproperty[index];
              const resultArray = [...this.state.selectedProperty];
              results.unit = unit;
              resultArray.push(results);
              this.setState({selectedProperty: resultArray});
            }
            
          else if(filterDevice[0].deviceType === "VCN"){
            const index = res.data[0].VFD[0].VFDproperty.findIndex((element, index) => {
              if (element.property === property) {
                return true
              }
            });
            const results = res.data[0].VFD[0].VFDproperty[index];
            const resultArray = [...this.state.selectedProperty];
            //results.value = "70";
            results.unit = unit;
            resultArray.push(results);
            this.setState({selectedProperty: resultArray});
          }
          else if(filterDevice[0].deviceType === "EMG"){
            const index = res.data[0].EMG[0].EMproperty.findIndex((element, index) => {
              if (element.property === property) {
                return true
              }
            });
            const results = res.data[0].EMG[0].EMproperty[index];
            const resultArray = [...this.state.selectedProperty];
            results.unit = unit;
            //results.value = "70";
            resultArray.push(results);
            this.setState({selectedProperty: resultArray});
          }
          else{
            const index = res.data[0].properties.findIndex((element, index) => {
              if (element.property === property) {
                return true
              }
            });
            const results = res.data[0].properties[index];
            const resultArray = [...this.state.selectedProperty];
            //results.value = "70";
            results.unit = unit;
            resultArray.push(results);
            this.setState({selectedProperty: resultArray});
          }
        }
        else{
          let dumobj = {property: property, value: 'NA', unit: unit};
          const resultArray = [...this.state.selectedProperty];
          resultArray.push(dumobj);
          this.setState({selectedProperty: resultArray});
          // toast.info("No data found. If live data available, it will start displaying.", {
          //   position: toast.POSITION.TOP_RIGHT
          // });
          return NotificationManager.error("No data found. If live data available, it will start displaying.");
        } 
            
      }).catch((err) => {
        this.setState({showspinner: false});
        // toast.error("Some error occurred", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return NotificationManager.error("Some error occured");
      })
    }
    catch(cerror){
      // toast.error("Something went wrong.", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      return NotificationManager.error("Some error occured");
    }
  }



  render() {
    try{
    return (
      <div className="animated fadeIn" >
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <strong className="LabelHeader">Live Data</strong>
                <div className="card-header-actions">
                  {/* <Button className="float-right badge badge-warning badge-pill" >Edit &nbsp;<i className="fa fa-edit float-right"></i></Button> */}
                </div>
              </CardHeader>
              <CardBody>
               
                   <div style={{display:"flex"}}>
                      <div style={{width:"25%"}}>
                        <Category 
                        devices={this.state.deviceList} 
                        properties={this.state.listProperty} 
                        selectDevice={this.selectDevice.bind(this)}
                        selectProperty={this.selectProperty.bind(this)}
                        devicetype='radio'
                        propertytype='checkbox'/>
                      </div>

                      <div style={{width:"75%"}}>
                          <Row>
                          <Grid container spacing={3}>
                         {
                          
                            this.state.selectedProperty && this.state.selectedProperty.map((d, ind) => {
                              return (
                                <Grid item xs={12} sm={6} md={4} key={ind+1}>
                                    {/*<Meter {...d}/>*/}
                                    <h6 className="text-center">{d.property} {d.unit ? `(${d.unit})` : ''}</h6>
                                    {isNaN(d.value/100) &&
                                      <GaugeChart
                                      id="gaugechart"
                                      style={chartStyle}
                                      needleColor={'#fff'}
                                      needleBaseColor={'#fff'}
                                      //colors={d.value.toLowerCase() === 'on' ? [ "#00FF00","#f86c6b" ] : 
                                      //(d.value.toLowerCase() === 'off' ? [ "#00ff0091","#FF0000" ] : [ "#00FF00","#FF0000" ])}
                                      //colors={d.value.toLowerCase() === 'on' ? [ "#00FF00","#00FF00" ] : 
                                      //(d.value.toLowerCase() === 'off' ? [ "#FF0000","#FF0000" ] : [ "#00FF00","#FF0000" ])}
                                      //nrOfLevels={2}
                                      nrOfLevels={20}
                                      animDelay={0}
                                      textColor={'#000'}
                                      formatTextValue={value =>  d.value}
                                    />
                                    }

                                    {!isNaN(d.value/100) &&
                                      <GaugeChart
                                      id="gaugechart"
                                      style={chartStyle}
                                      percent={d.value > 100 ? (d.value > 1000 ? 1 : d.value/1000) : d.value/100}
                                      nrOfLevels={20}
                                      animDelay={0}
                                      textColor={'#000'}
                                      formatTextValue={value => d.value }
                                    />
                                    }
                                    
                                </Grid>
                              )
                            })

                           


                         } 
                         </Grid>
                       
                          </Row>
                          
                         <Row>
                         {this.state.showspinner &&
                        <div className="sk-cube-grid">
                          <div className="sk-cube sk-cube1"></div>
                          <div className="sk-cube sk-cube2"></div>
                          <div className="sk-cube sk-cube3"></div>
                          <div className="sk-cube sk-cube4"></div>
                          <div className="sk-cube sk-cube5"></div>
                          <div className="sk-cube sk-cube6"></div>
                          <div className="sk-cube sk-cube7"></div>
                          <div className="sk-cube sk-cube8"></div>
                          <div className="sk-cube sk-cube9"></div>
                        </div>
                      }
                         </Row>
                         
                      </div>

                    
                      
                    
                     
                   </div>
                
                
              </CardBody>
            </Card>

          </Col>
        </Row>

        
      </div>
    );
                    }
                    catch(cerror){
                      // toast.error("Something went wrong.", {
                      //   position: toast.POSITION.TOP_RIGHT
                      // });
                    }
  }
}

export default ViewProperty;
