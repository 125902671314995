import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../../images/Agri-producer_Needs_19-02-2024-removebg-preview (1).png";
import Advice from "../../../images/communication.svg";
import Profit from "../../../images/increase.svg";
import Quality from "../../../images/quality-control.svg";



import "./ChallangesSection.css";

export default function ChallengesSection() {
  const challanges =[
    {
      title:"Quality Inputs",
      description:"Quality inputs are essential for farmers to achieve optimal yield, crop health, and profitability while meeting global food demands and ensuring agricultural sustainability.",
      image:Quality
    },
    {
      title:"Profitable Sale",
      description:"Profitable sales are crucial for farmers as they ensure income stability, cover production expenses, and support sustainable farming practices, ultimately securing their livelihoods.",
      image:Profit
    },
    {
      title:"Actionable Advisory",
      description:"Actionable advisory is essential for farmers to make informed decisions, optimize resource usage, and mitigate risks, thereby improving productivity and profitability while ensuring sustainable agricultural practices.",
      image:Advice
    },
    
  //   {
  //   title:"Extra Source of Income",
  //   description:"An additional stream of earnings is sought after by agri-producers to bolster financial stability and tackle income uncertainties.",
  //   image:Finance
  // },

  // {
  //   title:"Marketing ",
  //   description:"Farmers lack effective marketing tools, highlighting a crucial need for promoting and selling their products. ",
  //   image:Marketing
  // },
  // {
  //   title:"Loan",
  //   description:"Securing loans is a vital necessity for agri-producers, to address their financial needs and to enable them to invest in and improve their agricultural practices for sustained productivity.",
  //   image:loan
  // },
  // {
  //   title:"Ecosystem",
  //   description:"The absence of a supportive community in agriculture hampers knowledge sharing. Combined with market access and certification issues, agri-producers face uncertainties, limiting profitability.",
  //   image:Ecosystem
  // },

  // {
  //   title:"Brand ",
  //   description:"Branding empowers agri-producers to establish unique identities for their products, enabling differentiation and market recognition. ",
  //   image:Brand
  // }
//  {
//     title:"Easy Technology adoption ",
//     description:"Affordable and user-friendly integrated agricultural technologies, enhancing productivity and efficiency on farms, are increasingly sought after by agri-producers.",
//     image:Technology
//   }
]
  return (
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        Farmers Needs
      </h2>

      <Row className="align-items-center">
        <Col md={4}>
          <img src={challangeImg} alt="challanges" className="mb-md-0 mb-4" />
        </Col>

        <Col md={8}>
         

          <Row className="challangesRow justify-content-between ">
            {challanges.map((e)=>{
              return <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 ">
                <img src={e.image} alt="Challange Icon" className="mb-3" />
                <h6 className="mb-2">{e.title}</h6>
                <p className="mb-0">
                 {e.description}
                </p>
              </div>
            </Col>
           
            })}          
          </Row>
   
        </Col>
      </Row>
    </Container>
  );
}
