import React from 'react'
import '../../AssetDeveloperRole/MyOrders/MyOrderStyleComponent.css';
import GlobalNetworkComponent from '../../AssetDeveloperRole/GlobalNetwork/GlobalNetworkComponent';

const PMCDealerGlobalNetwork = () => {
    const UserRole = localStorage.getItem("UserRole");

    let allowedUserTypes = [];
    switch (UserRole) {
        case "BASIC":
            allowedUserTypes = ["FOOD-PROCESSOR","OFC","AssetDeveloper","FMC","DeviceMaker"];
            break;
        case "STANDARD":allowedUserTypes = ["FOOD-PROCESSOR","OFC","AssetDeveloper","FMC","DeviceMaker"];break;
        case "PREFERRED":allowedUserTypes = ["FOOD-PROCESSOR","OFC","AssetDeveloper","FMC","DeviceMaker"];break;
        case "PREMIUM":allowedUserTypes =   ["FOOD-PROCESSOR","OFC","AssetDeveloper","FMC","DeviceMaker"];break;
        case "ADMIN":
            allowedUserTypes =  ["FOOD-PROCESSOR","OFC","AssetDeveloper","FMC","DeviceMaker"];break;
            break;
        default:
            console.error("Unexpected UserRole:", UserRole);
            // Fallback behavior: Set default userTypes or handle the error
            allowedUserTypes = ["FOOD-PROCESSOR","OFC","AssetDeveloper","FMC","DeviceMaker"]; // Example default behavior
            break;
    }
  return (
    <div>
    <GlobalNetworkComponent userTypes={allowedUserTypes} />
</div> 
  )
}

export default PMCDealerGlobalNetwork